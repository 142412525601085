import React from 'react'
import RecuperarSenha from './recuperar-senha'
import RedefinirSenha from './redefinir-senha'

export default function RecuperacaoDeSenha(props) {
  const {
    recuperarSenha,
    redefinirSenha,
    history
  } = props

  const identificador = window.location.href.split('recuperar-senha/')[1]

  return (
    <>
      {!identificador ?
        (() => (
          <RecuperarSenha
            recuperarSenha={recuperarSenha}
            history={history}
          />
        ))() :
        (() => (
          <RedefinirSenha
            redefinirSenha={redefinirSenha}
            identificador={identificador}
            history={history}
          />
        ))()
      }
    </>
  )
}