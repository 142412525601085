import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import Breadcrumb from '../../breadcrumb'
import Spinner from '../../spinner'
import ListaDeProfissionais from './lista-de-profissionais'
import SeletorDeDataDaAgenda from './seletor-de-data-da-agenda'
import Calendario from './calendario'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import avatar from '../../../design/img/no-thumb.png'
import Agendamento from '../agendamento/com-redux'
import ListaDeEspera from '../lista-de-espera/com-redux'
import LegendaDasUnidades from './legenda-das-unidades'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import { formatarDataParaFormatoUniversal } from '../../../bibliotecas/data'
import {
  LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTAR_OPERADORAS,
  LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  SELECIONAR_PROFISSIONAL_DA_AGENDA,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO
} from '../../../acoes/tipos'

const estiloDoModalDeAgendamento = {
  content: {
    overflowY: 'scroll',
    maxHeight: '95%',
    width: '1100px'
  }
}
const estiloDoModalDeListaDeEspera = {
  content: {
    overflowY: 'scroll',
    width: '1100px',
  }
}

export default function Agenda(props) {
  const {
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarDadosSelecionadosDoAgendamento,
    alterarDataDoCalendario,
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarTermoDeProfissionaisDeSaudeDaAgenda,
    alterarVisualizacaoDoCalendario,
    cancelarAgendamento,
    calendario,
    confirmarAgendamento,
    excluirAgendamento,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    exibirDemaisProfissionais,
    filtros,
    fotos,
    fotoDoPaciente,
    identificadorDoUsuarioLogado,
    limparDiasDoProfissionalDaAgenda,
    match,
    motivosDeBloqueio,
    motivosDeCancelamento,
    profissional,
    profissionaisDeSaudeFiltrados,
    recuperarAgendamentoPeloIdentificador,
    recuperarAgendaSemanalDoProfissional,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    selecionarUnidadesDaAgenda,
    unidadeDoUsuarioLogado,
    unidades,
  } = props

  const { identificadorDoProfissional, dia } = match.params

  const referenciaDoFiltro = useRef(null)
  const [exibirFiltro, setExibirFiltro] = useState(false)
  const [exibirNovoAgendamento, setExibirNovoAgendamento] = useState(false)
  const [exibirListaDeEspera, setExibirListaDeEspera] = useState(false)

  const podeVerAgendaDeOutrosProfissionais = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE])

  useCliqueForaDoElemento(referenciaDoFiltro, () => setExibirFiltro(false))

  useEffect(() => {
    const profissional = identificadorDoProfissional ? parseInt(identificadorDoProfissional) : null
    const data = dia ? dia : ''

    if (!profissional || profissional.identificador !== profissional) {
      selecionarProfissionalDaAgenda(profissional)
      if (profissional && profissional.identificador) limparDiasDoProfissionalDaAgenda(profissional.identificador)
    }

    if (!calendario.data || moment(calendario.data).format('YYYY-MM-DD') !== data) {
      alterarDataDoCalendario(moment(data).toDate())
    }
  }, [profissional, selecionarProfissionalDaAgenda, alterarDataDoCalendario, calendario.data, limparDiasDoProfissionalDaAgenda, identificadorDoProfissional, dia])

  useEffect(() => {
    const identificadorDoAgendamento = null
    const identificadorDoProfissional = profissional && profissional.identificador
    const dia = calendario.data

    if (!identificadorDoProfissional || !dia) return
    if (profissional && profissional.dias && profissional.dias[moment(dia).format('YYYY-MM-DD')]) return

    if (identificadorDoProfissional && dia) {
      recuperarAgendaSemanalDoProfissional(identificadorDoProfissional, moment(dia).format('YYYY-MM-DD'), identificadorDoAgendamento, unidadeDoUsuarioLogado)
    }
  }, [profissional, calendario.data, recuperarAgendaSemanalDoProfissional, unidadeDoUsuarioLogado])

  const adicionarFiltro = filtro => {
    const novosFiltros = [...filtros.unidadesFiltradas, filtro]
    selecionarUnidadesDaAgenda(novosFiltros)
  }

  const removerFiltro = filtro => {
    const novosFiltros = filtros.unidadesFiltradas.filter(x => x !== filtro)
    selecionarUnidadesDaAgenda(novosFiltros)
  }

  const abrirNovoAgendamentoNoHorario = item => {
    if (item && profissional.ativo) {
      if (moment().isSameOrBefore(moment(item.data), 'days')) {
        let data = item.data
        data = formatarDataParaFormatoUniversal(data) + ' ' + item.inicio

        const dadosSelecionadosDoAgendamento = {
          data,
          duracaoDoAgendamento: item.duracaoDoAgendamento,
          unidade: item.unidade.identificador,
          tipoDeHorario: item.tipoDeHorario
        }

        alterarDadosSelecionadosDoAgendamento(dadosSelecionadosDoAgendamento)
        abrirNovoAgendamento()
      }
    }
  }

  function abrirNovoAgendamento() {
    setExibirNovoAgendamento(true)
  }

  function fecharNovoAgendamento() {
    setExibirNovoAgendamento(false)
  }

  function abrirListaDeEspera() {
    setExibirListaDeEspera(true)
  }

  function fecharListaDeEspera() {
    setExibirListaDeEspera(false)
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <Modal
        isOpen={exibirNovoAgendamento}
        style={estiloDoModalDeAgendamento}
        className='modal'
        contentLabel='Modal para Agendamento'
      >
        <Agendamento
          match={match}
          fechar={fecharNovoAgendamento}
          motivosDeBloqueio={motivosDeBloqueio}
          profissional={profissional}
        />
      </Modal>
      <Modal
        isOpen={exibirListaDeEspera}
        style={estiloDoModalDeListaDeEspera}
        className='modal'
        contentLabel='Modal para Lista de espera'
      >
        <ListaDeEspera
          match={match}
          fecharListaDeEspera={fecharListaDeEspera}
          profissional={profissional}
          abrirNovoAgendamento={abrirNovoAgendamento}
        />
      </Modal>
      <div className='container'>
        <Spinner operacoes={[RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
          RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
          LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
          SELECIONAR_PROFISSIONAL_DA_AGENDA,
          LISTAR_OPERADORAS,
          LISTAR_FERIADOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
          {({ processando }) => (
            <div className='main-content-form'>
              <div className='header-page-form mb-2'>
                <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
                  <r-cell span={4} span-md={2} span-lg={3}>
                    <Breadcrumb />
                  </r-cell>
                  <r-cell span={4} span-md={4} span-lg={9}>
                    <LegendaDasUnidades itens={unidades} />
                  </r-cell>
                </r-grid>
              </div>
              <div className='header-agendamento'>
                {profissional &&
                  <div className='user-doctor'>
                    <img
                      alt={profissional.nome}
                      className='user-doctor__img'
                      src={(profissional.foto && fotos[profissional.foto]) || avatar}
                    />
                    <div className='user-doctor__info'>
                      <p className='user-doctor__name'>{profissional.nome}</p>
                      {profissional.profissao &&
                        <p className='user-doctor__specialty'>
                          <strong>{profissional.profissao.nome}</strong>
                          {profissional.profissao.especialidades.length > 0 &&
                            <span> | </span>
                          }
                          {profissional.profissao.especialidades.map(especialidade => <span key={especialidade}> {especialidade}</span>)}
                        </p>
                      }
                      {exibirDemaisProfissionais &&
                        <p className='botao-editar-paciente user-doctor__specialty outros' onClick={exibirDemaisProfissionais}>
                          Exibir demais profissionais
                        </p>
                      }
                    </div>
                  </div>
                }

                <div className='header-agendamento-actions mt-3 mt-lg-0'>
                  <div className='tab-agenda mr-lg-1'>
                    <div className={`tab-agenda__semana ${calendario.visualizacao === 'semana' && 'is-active'}`}
                      onClick={() => alterarVisualizacaoDoCalendario('semana')}>Semana</div>
                    <div className={`tab-agenda__dia ${calendario.visualizacao === 'dia' && 'is-active'}`}
                      onClick={() => alterarVisualizacaoDoCalendario('dia')}>Dia</div>
                  </div>

                  <div className='box-filter-custom'>
                    {unidades.length > 1 &&
                      <div className='custom-select mr-lg-1' ref={referenciaDoFiltro}>
                        <div
                          className={`filter-list ${exibirFiltro && 'is-active'}`}
                          onClick={() => setExibirFiltro(!exibirFiltro)}
                        >
                          <div className='filter-list__toggle'>{filtros.unidadesFiltradas.length === 0 ? 'Nenhuma Unidade selecionada' : 'Escolher Unidades'}</div>
                          <div className='filter-list__drop'>
                            <div className='filter-list__drop__arrow'></div>
                            {unidades.map(u => (
                              <div className='checkbox' key={`check_unidades_${u.identificador}`}>
                                <input
                                  className='checkbox-input'
                                  onChange={evento => evento.target.checked ? adicionarFiltro(u.identificador) : removerFiltro(u.identificador)}
                                  id={`check_unidades_${u.identificador}`}
                                  name={`check_unidades_${u.identificador}`}
                                  type='checkbox'
                                  defaultChecked={filtros.unidadesFiltradas.includes(u.identificador)}
                                />
                                <label className='checkbox-label' htmlFor={`check_unidades_${u.identificador}`}><span>{u.nome}</span></label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    }
                    {calendario.data &&
                      <SeletorDeDataDaAgenda
                        alterarDataDoCalendario={alterarDataDoCalendario}
                        calendario={calendario}
                        history={props.history}
                        match={match}
                      />
                    }
                  </div>
                  <div className='filter-agendamento d-lg-none'>
                    {profissional &&
                      <>
                        <span className='filter-agendamento__name'>Lista de Espera</span>
                        <button className='filter-agendamento__btn' onClick={abrirListaDeEspera}><i className='icon icon-plus'></i></button>
                      </>
                    }
                    {profissional &&
                      <Permissao permissoes={[permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]}>
                        <span className='filter-agendamento__name'>Novo Agendamento</span>
                        <button className='filter-agendamento__btn' onClick={abrirNovoAgendamento}><i className='icon icon-plus'></i></button>
                      </Permissao>
                    }
                  </div>
                  {profissional &&
                    <button type='button' className='button --light d-none d-lg-block ml-lg-2' onClick={abrirListaDeEspera}>Lista de Espera</button>
                  }
                  {profissional &&
                    <Permissao permissoes={[permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]}>
                      <button type='button' className='button --primary --plus d-none d-lg-block ml-lg-2' onClick={abrirNovoAgendamento}>Novo Agendamento</button>
                    </Permissao>
                  }
                </div>
              </div>
              <div className='box-panel --narrow'>
                {podeVerAgendaDeOutrosProfissionais && !exibirDemaisProfissionais &&
                  <ListaDeProfissionais
                    alterarTermoDeProfissionaisDeSaudeDaAgenda={alterarTermoDeProfissionaisDeSaudeDaAgenda}
                    data={calendario.data}
                    filtros={filtros}
                    fotos={fotos}
                    history={props.history}
                    match={match}
                    profissional={profissional}
                    profissionaisDeSaudeFiltrados={profissionaisDeSaudeFiltrados}
                  />
                }
                <div className={`box-panel__content p-0 ${podeVerAgendaDeOutrosProfissionais && !exibirDemaisProfissionais ? '' : 'vw-100'}`}>
                  {profissional && profissional.dias &&
                    <Calendario
                      abrirNovoAgendamentoNoHorario={abrirNovoAgendamentoNoHorario}
                      alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      calendario={calendario}
                      cancelarAgendamento={cancelarAgendamento}
                      confirmarAgendamento={confirmarAgendamento}
                      excluirAgendamento={excluirAgendamento}
                      excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
                      filtros={filtros}
                      fotoDoPaciente={fotoDoPaciente}
                      identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
                      match={match}
                      motivosDeBloqueio={motivosDeBloqueio}
                      motivosDeCancelamento={motivosDeCancelamento}
                      processando={processando}
                      profissional={profissional}
                      recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
                      recuperarAgendaSemanalDoProfissional={() => recuperarAgendaSemanalDoProfissional(profissional.identificador, moment(calendario.data).format('YYYY-MM-DD'), null, unidadeDoUsuarioLogado)}
                      recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
                      removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
                      selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
                      selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
                      unidades={unidades}
                    />
                  }
                </div>
              </div>
            </div>
          )}
        </Spinner>
      </div>
    </div>
  )
}