import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'react-modal'
import moment from 'moment'
import { alterarStatusDoUsuario } from '../../../acoes/usuarios/usuario'
import '../style.css'

export default function NotificacaoProfissionalPossuiAgendamentosFuturos({ notificacao, history }) {
  const dispatch = useDispatch()
  const [exibirModal, setExibirModal] = useState(false)
  const { metaDados } = notificacao

  useEffect(() => {
    if (notificacao) {
      setExibirModal(true)
    }
  }, [notificacao])

  const sim = async (identificador) => {
    setExibirModal(false)
    const inativou = await dispatch(alterarStatusDoUsuario(identificador, 'inativo', true))

    if (inativou) {
      history.push(`/configuracoes/usuarios/lista`)
    }
  }

  const nao = () => {
    setExibirModal(false)
  }

  const naoEIrParaAgenda = (identificador) => {
    history.push(`/agendamentos/agenda/${identificador}/data/${moment().format('YYYY-MM-DD')}`)
    setExibirModal(false)
  }
  const dados = useMemo(() => {
    const identificador = metaDados['identificador']
    const tipo = metaDados['tipo']
    const nome = metaDados['profissional']
    const quantidadeDeAgendamentos = metaDados['quantidade_de_agendamentos']

    return { identificador, tipo, nome, quantidadeDeAgendamentos }
  }, [metaDados])

  return (
    <Modal
      isOpen={exibirModal}
      className='notificacao_com_intermedio_do_usuario__modal'
    >
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={12}>
          <div className='form-choice-alerta my-1'>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <ul>
                <li>O profissional <strong>{dados.nome}</strong> possui <strong>{dados.quantidadeDeAgendamentos}</strong> agendamento(s) futuros.</li>
                <li>Favor reagendar os agendamentos.</li>
                <li>Tem certeza que quer inativá-lo?</li>
              </ul>
            </div>
          </div>
          <div className='list-btn'>
            <button className='button --light' type='button' onClick={nao}>Não</button>
            <button className='button --light' type='button' onClick={() => naoEIrParaAgenda(dados.identificador)}>Não. Ir para agenda do profissional.</button>
            <button className='button --danger' type='button' onClick={() => sim(dados.identificador)}>Sim</button>
          </div>
        </r-cell>
      </r-grid>
    </Modal>
  )
}