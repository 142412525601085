import React, { useState } from 'react'
import { Element } from 'react-scroll'
import Modal from 'react-modal'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Checkbox } from '../../../formik/formulario'
import { formatarHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import moment from 'moment'
import avatar from '../../../../design/img/no-thumb.png'
import ContatosDoAgendamento from '../../agendamento/contatos-do-agendamento'

import {
  EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO
} from '../../../../acoes/tipos'

export default function ConcluirAgendamentos({
  agendamentosRealizados,
  fechar,
  convenio,
  excluirAgendamento,
  formatarDiaDaSemana,
  fotosDosPacientes,
  limpar,
  paciente
}) {

  const [exibirExcluirAgendamento, setExibirExcluirAgendamento] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState('')
  const excluirRecorrentes = usePossuiAsPermissoes([permissoes.REMOVER_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE])

  const estiloDoModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2px'
    }
  }

  function abrirExcluir(item) {
    setAgendamentoSelecionado(item)
    setExibirExcluirAgendamento(true)
  }

  function fecharExcluir() { setExibirExcluirAgendamento(false) }

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia).format('dddd'))
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  const renderizarAgendamentosRecorrentes = agendamentos => {
    return (
      <>
        {agendamentos.map(x =>
          <div className='form-choice-recorrentes' key={x.identificador}>
            <div className='form-choice-recorrentes__item'>
              <i className='icon icon-calendar-empty'></i><strong>{diaDaSemana(x.inicio)}</strong>
            </div>
            <div className='form-choice-recorrentes__item'>
              <i className='icon icon-clock'></i><strong>{formatarHoraParaFormatoLocal(x.inicio)} às {formatarHoraParaFormatoLocal(x.fim)}</strong>
            </div>
            {excluirRecorrentes && <button className='form-choice-recorrentes__bt-close' type='button' aria-label='Fechar' onClick={() => abrirExcluir(x)}><i className='icon icon-close'></i></button>}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirExcluirAgendamento}
        style={estiloDoModal}
      >
        <Excluir
          agendamentoSelecionado={agendamentoSelecionado}
          excluirAgendamento={excluirAgendamento}
          fecharExcluir={fecharExcluir}
        />
      </Modal>
      <FormularioDoFormik
        reinicializar={true}
      >
        <fieldset>
          <h2 className='form-title'>Agendamento(s) Adicionado(s)</h2>
          <div className='user-paciente-agenda-modal'>
            <div className='foto-do-paciente'>
              <img
                alt={paciente.nome}
                src={(paciente.foto && fotosDosPacientes[paciente.foto]) || avatar}
              />
            </div>
            <div>
              <div className='user-paciente-agenda-modal__name'>{paciente.nome}</div>
              <div className='user-paciente-agenda-modal__info'>
                <p><strong>Data de Nascimento:</strong>{' '}{moment(paciente.dataDeNascimento).format('DD/MM/YYYY')}</p>
                {convenio && <p><strong>Convênio:</strong>{' '}{convenio}</p>}
                {paciente.associacao && <p><strong>Associação:</strong>{' '}{paciente.associacao.nome}</p>}
                {paciente.programas && paciente.programas.length > 0 && <p><strong>Programa(s):</strong>{' '}{paciente.programas.join(', ')}</p>}
              </div>
            </div>
          </div>
          <hr className='separator mt-1 mb-1'></hr>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={12} span-md={12} span-lg={12}>
              <Element style={{
                height: '400px',
                overflowY: 'scroll',
              }}>
                {agendamentosRealizados && agendamentosRealizados.map(x => (
                  <div key={x.identificador} className='unity-infos agendamentos'>
                    <div className='unity-infos__item'>
                      <ContatosDoAgendamento agendamento={x} />
                    </div>
                    <div className='unity-infos__item agendamentos'>
                      <Permissao permissoes={[permissoes.REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]}>
                        <button
                          type='button'
                          className='excluir'
                          onClick={() => abrirExcluir(x)}
                        >
                        </button>
                      </Permissao>
                      <p><i className='icon icon-calendar-empty'></i><strong>{diaDaSemana(x.inicio)}</strong></p>
                      <p><i className='icon icon-clock'></i><strong>{formatarHoraParaFormatoLocal(x.inicio)} às {formatarHoraParaFormatoLocal(x.fim)}</strong></p>
                      <p><strong>Unidade:</strong>{' '}{x.unidade.nome}</p>
                      <p><strong>Tipo do Agendamento:</strong>{' '}{x.tipo.nome}</p>
                      <p><strong>Profissional:</strong>{' '}{x.profissional.tratamento ? x.profissional.tratamento.concat(' ', x.profissional.nome) : x.profissional.nome}{', '}{x.profissional.profissao.nome}</p>
                      {x.observacoes && <p><strong>Observações:</strong>{' '}{x.observacoes}</p>}
                    </div>
                    <div className='ml-10'>
                      {x.recorrencia && renderizarAgendamentosRecorrentes(x.agendamentos)}
                    </div>
                  </div>
                ))}
              </Element>
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' onClick={() => fechar()} className='button --light minw-auto'>Continuar Agendando</button>
            <button type='button' onClick={() => limpar()} className='button --primary'>Concluir</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </>
  )
}

function Excluir({ agendamentoSelecionado, excluirAgendamento, fecharExcluir }) {
  const excluirAgendamentoSelecionado = async formulario => {
    const dados = {
      identificadorDoProfissional: agendamentoSelecionado.profissional.identificador,
      identificador: agendamentoSelecionado.identificador,
      todos: formulario.excluirRecorrentes
    }

    const excluiu = await excluirAgendamento(dados)
    if (excluiu) {
      fecharExcluir()
    }
  }

  return (
    <Spinner operacoes={[EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          excluirRecorrentes: false
        }}
        acao={excluirAgendamentoSelecionado}
      >
        <fieldset>
          <h2 className='form-title'>Tem certeza que deseja excluir o agendamento?</h2>
          {agendamentoSelecionado.recorrencia &&
            <Checkbox
              nome='excluirRecorrentes'
              titulo='Excluir todos recorrentes'
            />
          }
          <div className='list-btn'>
            <button className='button --light' type='button' onClick={() => fecharExcluir()}>Sair</button>
            <button className='button --danger' type='submit'>Excluir Agendamento(s)</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}