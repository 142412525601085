import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
} from '../tipos'

const erroAoListarAgendamentosAguardandoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  erro,
  parametros
})

const listouAgendamentosAguardandoAtendimento = (paginaDeDados, filtros, ordenacao) => ({
  type: LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  paginaDeDados,
  filtros,
  ordenacao
})

export const listarAgendamentosAguardandoAtendimento = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO })

  try {
    const resultado = await api.listarAgendamentosAguardandoAtendimento(filtros, pagina, ordenacao)

    dispatch(listouAgendamentosAguardandoAtendimento(resultado.data.dados, filtros, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
    
  } catch (erro) {
    dispatch(erroAoListarAgendamentosAguardandoAtendimento(erro, { filtros, pagina, ordenacao}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os agendamentos aguardando atendimento.')))
  }
}

const atualizouListaDosAgendamentosAguardandoAtendimento = (paginaDeDados, filtros) => ({
  type: ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  paginaDeDados,
  filtros
})

const erroAoAtualizarListaDosAgendamentosAguardandoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  erro,
  parametros
})

export const atualizarListaDosAgendamentosAguardandoAtendimento = (filtros, pagina) => async dispatch => {
  dispatch({ type: ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO })

  try {
    const resultado = await api.listarAgendamentosAguardandoAtendimento(filtros, pagina)

    dispatch(atualizouListaDosAgendamentosAguardandoAtendimento(resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoAtualizarListaDosAgendamentosAguardandoAtendimento(erro, { filtros, pagina}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível atualizar a lista dos agendamentos aguardando atendimento.')))
  }
}