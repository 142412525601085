import Profissoes from './index'
import acoes from '../../acoes'
import { connect } from 'react-redux'

const mapStatesToProps = state => {
  const profissaoAtual = state.profissoes.itemAtual

  let profissao = {
    nome: '',
    academica: false,
    conselho: '',
    especialidades: [],
    especialidadeSelecionada: ''
  }

  if (profissaoAtual) {
    profissao.identificador = profissaoAtual.identificador
    profissao.nome = profissaoAtual.nome
    profissao.academica = profissaoAtual.academica
    profissao.conselho = profissaoAtual.conselho
    profissao.especialidades = profissaoAtual.especialidades
    profissao.especialidadeSelecionada = profissaoAtual.especialidadeSelecionada
  }

  return {
    filtros: state.profissoes.filtros,
    ordenacao: state.profissoes.ordenacao,
    paginaDeDados: state.profissoes.paginaDeDados,
    profissao
  }
}

export default connect(mapStatesToProps, acoes)(Profissoes)