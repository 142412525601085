import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../breadcrumb'
import moment from 'moment'
import CampoDePesquisa from '../../campo-de-pesquisa'
import DicaDePesquisa from '../../dica-de-pesquisa'
import Spinner from '../../spinner'
import { Link } from 'react-router-dom'
import { Coluna, Tabela } from '../../tabela'
import { formatarDataParaFormatoLocal, formatarDataParaFormatoDiaDaSemana, formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'
import { LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO } from '../../../acoes/tipos'

export default function Lista(props) {
  const {
    atualizar,
    fotos,
    identificadorDoProfissional,
    paginaDeDados,
    pesquisar,
    unidadeAtual
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  const hoje = formatarDataParaFormatoLocal(moment().startOf('day'))
  const hojeDiaDaSemana = formatarDataParaFormatoDiaDaSemana(moment().startOf('day'))

  const retornarHorarioDeChegada = item => item.status.find(x => x.nome.toUpperCase() === 'AGUARDANDO ATENDIMENTO')?.dataEHora

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarTermo = termo => alterarFiltros({ ...filtros, termo })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const calcularTempoEspera = (item) => {
    const horarioChegada = retornarHorarioDeChegada(item);
    let horarioFormatado = null;

    if (horarioChegada) {
      const ms = item.status[0].nome.toUpperCase() === 'ATENDIDO' ? moment(item.status[0].dataEHora).diff(moment(horarioChegada)) : moment().diff(moment(horarioChegada));
      const horarioUTC = moment.utc(ms);
      horarioFormatado = formatarHoraParaFormatoLocal(horarioUTC);
    }

    return horarioFormatado;
  }

  useEffect(() => {
    if (!unidadeAtual) return

    const filtro = {
      ...filtros,
      unidade: unidadeAtual.identificador,
      identificadorDoProfissional: identificadorDoProfissional
    }

    pesquisar(filtro, pagina, ordenacao)
    const identificador = setInterval(() => atualizar(filtro, pagina), 60000)

    return () => clearInterval(identificador)
  }, [atualizar, filtros, pagina, ordenacao, pesquisar, unidadeAtual, identificadorDoProfissional])

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-center'>
              <r-cell span={4} span-md={2} span-lg={4}>
                <Breadcrumb />
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <div className="data-info">
                  <div className="data-info__item">
                    <p><strong>{hojeDiaDaSemana},</strong> {hoje}</p>
                  </div>
                  <div className="data-info__item">
                    {unidadeAtual && <strong>{`Unidade ${unidadeAtual.nome}`}</strong>}
                  </div>
                </div>
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <DicaDePesquisa
                  dica='A pesquisa é realizada em todas as colunas.'
                  exemplo='Maria 01/01/1930'
                >
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={filtros ? filtros.termo : ''}
                  />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO]}>
            <Tabela
              acoes={[
                item => {
                  const statusAtual = item.status && item.status.length ? item.status[0] : null
                  const statusPermitidoAtendimento = statusAtual && statusAtual.nome && statusAtual.nome.toUpperCase() === 'AGUARDANDO ATENDIMENTO'

                  return (statusPermitidoAtendimento &&
                    <Link
                      to={`/cadastros/pacientes/${item.paciente.identificador}/prontuario/novo/origem/${item.identificador}`}
                      className='table-items__bt --edit'
                      title='Ir para o prontuário'
                      key={item.identificador}
                    >
                      <i className='icon icon-doctor'></i>
                    </Link>
                  )
                }
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
              filtros={filtros}
              marcacaoDeInativo={item => {
                const statusAtual = item.status && item.status.length ? item.status[0] : null
                const statusPermitidoAtendimento = statusAtual && statusAtual.nome && statusAtual.nome.toUpperCase() === 'AGUARDANDO ATENDIMENTO'

                return !statusPermitidoAtendimento
              }}
            >
              <Coluna
                className='col-xs-2 col-md-1 col-lg-1'
                foto={true}
                fotos={fotos}
                identificadorDaFoto={item => item.paciente.foto}
              />
              <Coluna
                className='col-xs-6 col-md-9 col-lg-2'
                principal={true}
                nome='Paciente'
                renderizar={item => item.paciente.nome}
              />
              <Coluna
                className='col-lg-1'
                nome='Nascimento'
                campoDeDados='dataDeNascimento'
                renderizar={item => moment(item.paciente.dataDeNascimento).format("DD/MM/YYYY")}
              />
              <Coluna
                className='col-lg-2'
                nome='Tipo'
                renderizar={item =>
                  <p className='table-items__status'>
                    <span className='table-items__status__bg' style={{ background: item.cor.codigo }}></span>
                    <span className='table-items__status__text'>{item.tipo}</span>
                  </p>
                }
              />
              <Coluna
                className='col-lg-1'
                nome='Modalidade'
                campoDeDados='modalidade'
                renderizar={item => item.modalidade.charAt(0).toUpperCase() + item.modalidade.slice(1)}
              />
              <Coluna
                className='col-lg-1'
                nome='Agendamento'
                renderizar={item =>
                  item.status[0].nome.toUpperCase() === 'ATENDIDO' ?
                    <span style={{ color: '#bfbfbf' }}>{formatarHoraParaFormatoLocal(item.inicio)}</span> :
                    formatarHoraParaFormatoLocal(item.inicio)
                }
              />
              <Coluna
                className='col-lg-1'
                nome='Chegada'
                renderizar={item => {
                  const corTexto = item.status[0].nome.toUpperCase() === 'ATENDIDO' ?
                    '#bfbfbf' :
                    (formatarHoraParaFormatoLocal(item.inicio) >= formatarHoraParaFormatoLocal(retornarHorarioDeChegada(item)) ? null : '#e74c3c')
                  return (corTexto ?
                    <span style={{ color: corTexto }}>{formatarHoraParaFormatoLocal(retornarHorarioDeChegada(item))}</span> :
                    formatarHoraParaFormatoLocal(retornarHorarioDeChegada(item))
                  )
                }}
              />
              <Coluna
                className='col-lg-1'
                nome='Espera'
                renderizar={item => calcularTempoEspera(item)}
              />
              <Coluna
                campoDeDados='status'
                className='col-lg-1'
                nome='Status'
                renderizar={item =>
                  item.status[0].nome.toUpperCase() === 'ATENDIDO' ?
                    <span style={{ color: '#bfbfbf' }}>{item.status[0].nome}</span> :
                    item.status[0].nome
                }
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}