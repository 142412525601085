import React from 'react'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'

export default function Resumo({ valor }) {
  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      {valor.length > 0 &&
        <Tabela
          chave='identificador'
          className='--secondary mb-2'
          dados={valor}
          expandida={true}
          exibirBotaoMais={false}
          titulo={false}
        >
          <LinhaExpandida className='col-xs-10 col-lg-11'>
            <AgrupamentoDeColunas>
              <Coluna
                campoDeDados='tipo'
                icone='icon-name'
                nome='TIPO'
              />
              <Coluna
                icone='icon-graduation-cap'
                nome='PROFISSÃO/ESPECIALIDADE'
                renderizar={item => item.especialidade ? `${item.especialidade} - ${item.profissao}` : item.profissao}
              />
              <Coluna
                icone='icon-doctor'
                nome='PROFISSIONAL'
                renderizar={item => item.profissional}
              />
              <Coluna
                icone='icon-doctor'
                nome='PROFISSIONAL EXTERNO'
                renderizar={item => item.profissionalExterno}
              />
              <Coluna
                icone='icon-attention-circled'
                nome='Motivo de prioridade'
                renderizar={item => item.motivoDePrioridade}
              />
              <Coluna
                icone='icon-graduation-cap'
                nome='GRUPO DE CUIDADO'
                renderizar={item => item.grupoDeCuidado}
              />
              <Coluna
                icone='icon-doc-text'
                nome='ENCAMINHAMENTO'
                className='table-items-expanded-100__box-item'
                renderizar={item => <div><pre className='font-react-menstion'>{item.texto}</pre></div>}
              />
            </AgrupamentoDeColunas>
          </LinhaExpandida>
        </Tabela>
      }
    </r-cell>
  )
}