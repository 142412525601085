import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { marcarAtendimentoComoPrivado } from '../../../acoes/prontuario/linha-do-tempo'

export default function AtendimentoPrivado(props) {
  const { notificacao } = props
  const { texto, metaDados } = notificacao

  const dispatch = useDispatch()

  useEffect(() => dispatch(marcarAtendimentoComoPrivado(metaDados.paciente, metaDados.atendimento, metaDados.profissional)), [dispatch, metaDados])

  return (
    <div>
      <span>{texto}</span>
    </div>
  )
}