import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MOTIVOS_DE_INTERNACAO,
  LISTOU_MOTIVOS_DE_INTERNACAO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO,
  LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ADICIONAR_MOTIVO_DE_INTERNACAO,
  ADICIONOU_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO,
  RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ALTERAR_MOTIVO_DE_INTERNACAO,
  ALTEROU_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO,
  REMOVER_MOTIVO_DE_INTERNACAO,
  REMOVEU_MOTIVO_DE_INTERNACAO,
  ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO
} from './tipos'

const listouMotivosDeInternacao = motivosDeInternacao => ({
  type: LISTOU_MOTIVOS_DE_INTERNACAO,
  motivosDeInternacao
})

const erroAoListarMotivosDeInternacao = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO,
  erro
})

export const listarMotivosDeInternacao = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_INTERNACAO })

  try {
    const resultado = await api.listarMotivosDeInternacao()

    dispatch(listouMotivosDeInternacao(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeInternacao(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de internação.')))
  }
}

const listouMotivosDeInternacaoDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarMotivosDeInternacaoDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  erro,
  parametros
})

export const listarMotivosDeInternacaoDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO })

  try {
    const resultado = await api.listarMotivosDeInternacao(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouMotivosDeInternacaoDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeInternacaoDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de internação.')))
  }
}

const adicionouMotivoDeInternacao = motivoDeInternacao => ({
  type: ADICIONOU_MOTIVO_DE_INTERNACAO,
  motivoDeInternacao
})

const erroAoAdicionarMotivoDeInternacao = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO,
  erro,
  parametros
})

export const adicionarMotivoDeInternacao = (motivo) => async dispatch => {
  dispatch({ type: ADICIONAR_MOTIVO_DE_INTERNACAO })

  try {
    const resultado = await api.adicionarMotivoDeInternacao(motivo)

    dispatch(adicionouMotivoDeInternacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarMotivoDeInternacao(erro, { motivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o motivo de internação.')))

    return false
  }
}

const recuperouMotivoDeInternacaoPeloIdentificador = motivoDeInternacao => ({
  type: RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  motivoDeInternacao
})

const erroAoRecuperarMotivoDeInternacaoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarMotivoDeInternacaoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarMotivoDeInternacaoPeloIdentificador(identificador)

    dispatch(recuperouMotivoDeInternacaoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarMotivoDeInternacaoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o motivo de internação por identificador.')))
  }
}

const alterouMotivoDeInternacao = motivoDeInternacao => ({
  type: ALTEROU_MOTIVO_DE_INTERNACAO,
  motivoDeInternacao
})

const erroAoAlterarMotivoDeInternacao = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO,
  erro,
  parametros
})

export const alterarMotivoDeInternacao = motivo => async dispatch => {
  dispatch({ type: ALTERAR_MOTIVO_DE_INTERNACAO })

  try {
    const resultado = await api.alterarMotivoDeInternacao(motivo)

    dispatch(alterouMotivoDeInternacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarMotivoDeInternacao(erro, { motivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o motivo de internação.')))

    return false
  }
}

const removeuMotivoDeInternacao = motivoDeInternacao => ({
  type: REMOVEU_MOTIVO_DE_INTERNACAO,
  motivoDeInternacao
})

const erroAoRemoverMotivoDeInternacao = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO,
  erro,
  parametros
})

export const removerMotivoDeInternacao = identificador => async dispatch => {
  dispatch({ type: REMOVER_MOTIVO_DE_INTERNACAO })

  try {
    const resultado = await api.removerMotivoDeInternacao(identificador)

    dispatch(removeuMotivoDeInternacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverMotivoDeInternacao(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o motivo de internação.')))

    return false
  }
}