import React from 'react'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import {Formulario as FormularioDoFormik, DatePicker, Input, MonthPicker, YearPicker, Radio, Select} from '../../../formik/formulario'

import {
  ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  LISTAR_PROFISSOES,
  LISTAR_TIPOS_DE_CONSULTA_EXTERNA
} from '../../../../acoes/tipos'

export default function Formulario({ fechar, profissoes, tiposDeConsultaExterna, selecionada, ...rest }) {
  const periodos = [
    'Dia',
    'Mês/Ano',
    'Ano'
  ]

  const salvar = async (valores, { resetForm }) => {
    const especialidadeProfissao = valores.profissao.split('#').filter(x => x)
    const profissao = especialidadeProfissao.length > 1 ? especialidadeProfissao[1] : especialidadeProfissao[0]
    const especialidade = especialidadeProfissao.length > 1 ? especialidadeProfissao[0].replace('GENERALISTA', '') : ''

    const dados = {
      ...valores,
      profissao,
      especialidade,
      precisaoDaData: valores.precisaoDaData,
      data: valores.data,
    }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE, ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE, ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE, LISTAR_PROFISSOES, LISTAR_TIPOS_DE_CONSULTA_EXTERNA]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : '',
          profissao: selecionada ? selecionada.profissao : '',
          precisaoDaData: selecionada ? selecionada.precisaoDaData : '',
          data: selecionada ? selecionada.data : '',
          tipo: selecionada ? selecionada.tipo : '',
          observacoes: selecionada ? selecionada.observacoes : ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          precisaoDaData: Yup.string().required('Obrigatório.'),
          data: Yup.string()
            .when('precisaoDaData', {
              is: val => val === 'Dia' || val === 'Mês/Ano' || val === 'Ano',
              then: Yup.string().nullable().required('Obrigatório.')
            }),
          profissao: Yup.string().required('Obrigatório.'),
          tipo: Yup.string().required('Obrigatório.'),
        })}
        acao={salvar}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <fieldset>
            <h2 className='form-title'>{selecionada ? 'Alterar Consulta externa' : 'Adicionar Consulta externa'}</h2>
            <r-grid columns-md={6} columns-lg={12} className='mb-2'>
              <r-cell span={4} span-md={3} span-lg={4}>
                <Select
                  nome='precisaoDaData'
                  titulo='Período'
                  tabIndex={1}
                  itens={periodos}
                  campoCodigo=''
                  campoDescricao=''
                />
              </r-cell>
              {values.precisaoDaData === 'Dia' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <DatePicker
                    nome='data'
                    titulo='Dia'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Mês/Ano' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <MonthPicker
                    nome='data'
                    titulo='Mês/Ano'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Ano' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <YearPicker
                    nome='data'
                    titulo='Ano'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={6} span-lg={12}>
                <Select
                  nome='profissao'
                  titulo='Especialidade *'
                  tabIndex={3}
                  itens={profissoes}
                  campoCodigo='codigo'
                  campoDescricao='label'
                />
              </r-cell>
              <r-cell span={4} span-md={6} span-lg={12}>
                <label className='form-label'>Tipo da consulta *</label>
                <div className='d-flex'>
                  {tiposDeConsultaExterna.map(x =>
                    <div key={`tipo_${x.codigo}`}>
                      <Radio
                        nome='tipo'
                        classname='mr-3'
                        titulo={x.nome}
                        valor={x.nome}
                        onChange={() => setFieldValue('tipo', x.nome)}
                      />
                    </div>
                  )}
                </div>
                {(errors.tipo && touched.tipo) ? <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={6} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  titulo='Observações'
                  tabIndex={4}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn mt-4'>
              <button type='button' className='button --light' onClick={fechar}>Cancelar</button>
              <button type='submit' className='button --primary'>{selecionada ? 'Salvar alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}