import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import CampoDePesquisa from '../campo-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import DicaDePesquisa from '../dica-de-pesquisa'

import { LISTAR_TEMPLATES } from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar,
    permissaoInclusao,
    permissaoAlteracao,
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros.compartilhado.length) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = termo => alterarFiltros({ ...filtros, termo })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-center'>
              <r-cell span={4} span-md={2} span-lg={6}>
                <Breadcrumb />
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={2}>
                <div className='header-page-tools'>
                  {permissaoInclusao &&
                    <Link
                      to={`/configuracoes/templates/novo`}
                      className='button --primary --plus'
                    >
                      Adicionar
                </Link>
                  }
                </div>
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <DicaDePesquisa
                  dica='Pesquisa por todos os campos.'
                  exemplo='Meu template @@paciente Dr. João Silva '
                >
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={filtros ? filtros.termo : ''}
                  />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_TEMPLATES]}>
            <Tabela
              acoes={[
                item => (permissaoAlteracao &&
                  <Link
                    to={`/configuracoes/templates/${item.identificador}`}
                    className='table-items__bt --edit'
                    title='Editar'
                    key={item.identificador}
                  >
                    <i className='icon icon-pencil'></i>
                  </Link>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                campoDeDados='nome'
                className='col-xs-8 col-md-10 col-lg-4'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-3'
                exibirOrdenacao={true}
                nome='Criado por'
                campoDeDados='quemCriou.nome'
                renderizar={item => item.quemCriou.nome}
              />
              <Coluna
                className='col-lg-2'
                exibirOrdenacao={true}
                nome='Componente'
                campoDeDados='componente'
              />
              <Coluna
                campoDeDados='compartilhado'
                className='col-lg-2'
                nome='Compartilhado'
                filtros={['Sim', 'Não']}
                renderizar={item => <p className='table-items__mobile-value'>{item.compartilhado ? 'Sim' : 'Não'}</p>}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}