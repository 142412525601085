import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { ADICIONAR_DIAGNOSTICO_DO_PACIENTE, LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO } from '../../../../acoes/tipos'

export default function AdicionarDiagnostico({
  adicionarDiagnosticoDoPaciente,
  fechar,
  diagnosticos,
  identificadorDoPaciente
}) {

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_DIAGNOSTICO_DO_PACIENTE])

  const adicionarDiagnostico = async formulario => {
    const dados = {
      diagnostico: formulario.diagnostico,
      observacoes: formulario.observacoes
    }

    const adicionou = await adicionarDiagnosticoDoPaciente(identificadorDoPaciente, dados)

    if (adicionou) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_DIAGNOSTICO_DO_PACIENTE, LISTAR_DIAGNOSTICOS_PARA_O_PRONTUARIO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          diagnostico: '',          
          observacoes: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          diagnostico: Yup.string().required('Obrigatório.'),          
        })}
        acao={adicionarDiagnostico}
      >
        <fieldset>
          <h2 className='form-title'>Adicionar Diagnóstico</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Select
                nome='diagnostico'
                titulo='Diagnóstico *'
                tabIndex={1}
                itens={diagnosticos}
                campoCodigo='identificador'
                campoDescricao='nome'
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as='textarea'
                tabIndex={2}
                nome='observacoes'
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}