import React, { useCallback, useEffect, useState } from 'react'

const MODO_DE_LEITURA = 'LEITURA'
const MODO_DE_ESCRITA = 'ESCRITA'

export default function CampoDaTarefa(props) {
  const { children, gravar, validar, titulo, icone, gravarAoSelecionar = false, acao, acoes = [], tituloAcao, classnameAcao = '' } = props
  const [valorInicial, setValorInicial] = useState(props.valor)
  const [valor, setValor] = useState(props.valor)
  const [valorDoComponente, setValorDoComponente] = useState(props.valor)
  const [modo, setModo] = useState(MODO_DE_LEITURA)
  const [erro, setErro] = useState(null)
  const [tentarNovamente, setTentarNovamente] = useState(false)
  const [foco, setFoco] = useState(false)

  const atualizar = useCallback(valor => {
    setValorDoComponente(valor)
    setValorInicial(valor)
  }, [])

  useEffect(() => setValor(props.valor), [props.valor])

  useEffect(() => {
    if (modo === MODO_DE_LEITURA && valorInicial !== valor) {
      atualizar(valor)
    }
  }, [modo, valorInicial, valor, atualizar])

  if (!children) return children

  const definir = async novoValor => {
    const valorInserido = gravarAoSelecionar ? novoValor : valorDoComponente
    const mensagemDeErro = validar ? validar(valorInserido) : null

    if (mensagemDeErro) {
      setErro(mensagemDeErro)
      return
    } else {
      setErro(null)
    }

    if (valorInserido !== valor) {
      const definiu = await gravar(valorInserido)

      if (!definiu) {
        setErro('Não foi possível salvar os dados')
        setTentarNovamente(true)
      } else {
        atualizar(valorInserido)
        setTentarNovamente(false)
      }
    }

    setModo(MODO_DE_LEITURA)
  }

  const onChange = novoValor => {
    setValorDoComponente(novoValor)
    if (gravarAoSelecionar) {
      definir(novoValor)
    }
  }

  const onClickAcao = acaoDoBotao => {
    setModo(MODO_DE_LEITURA)
    acaoDoBotao()
  }

  const alerta = valorInicial !== valor && modo === MODO_DE_ESCRITA

  return (
    <div className={`card-componente form-group ${erro && 'has-error'} ${alerta && 'has-warning'}`}>
      {icone && typeof icone === 'string' && <i className={`icon ${icone}`}></i>}
      {icone && typeof icone === 'object' && <div className='icon'>{icone}</div>}
      <div className='componente-select-acao'>
        <label className='titulo'>{titulo}</label>
        {acoes.length > 0 && acoes.map((x, index) => <button key={index} className={classnameAcao ? classnameAcao : 'button button-light-select-acao'} title={x.tituloSecundario} onClick={() => onClickAcao(x.acao)}>{x.titulo}</button>) }
        {acao && <button className={classnameAcao ? classnameAcao : 'button button-light-select-acao'} onClick={() => onClickAcao(acao)}>{tituloAcao}</button>}
      </div>
      {children({
        ...children.props,
        valor: valorDoComponente,
        onChange: evento => {
          const novoValor = evento && evento.target ? evento.target.value : evento
          onChange(novoValor)
        },
        onBlur: () => {
          if(!gravarAoSelecionar) {
           definir()
          }
          setFoco(false)
        },
        onFocus: () => {
          setModo(MODO_DE_ESCRITA)
          setFoco(true)
        },
        isValid: erro !== null,
        isWarning: alerta,
        foco: foco
      })}
      {alerta && <MensagemDeAtualizacaoDeValor atualizar={() => atualizar(valor)} ignorar={() => setValorInicial(valor)} />}
      {erro && <MensagemDeErro mensagem={erro} acao={tentarNovamente ? definir : undefined} />}
    </div>
  )
}

function MensagemDeErro({ mensagem, acao }) {
  return (
    <span className='form-tip'>
      {mensagem}{' '}
      {acao && <button className='tarefas-button-danger' onClick={acao}>Tentar Novamente</button>}
    </span>
  )
}

function MensagemDeAtualizacaoDeValor({ atualizar, ignorar }) {
  return (
    <>
      <label className='form-label-warning'>Campo alterado por outra pessoa deseja atualizar?</label>
      <button className='tarefas-button-warning yes' onClick={atualizar}>Sim</button>{' '}
      <button className='tarefas-button-warning no' onClick={ignorar}>Não</button>
    </>
  )
}