import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import * as Yup from 'yup'
import FileSaver from 'file-saver'

import {
  RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  ALTERAR_ORIENTACAO,
  ADICIONAR_ORIENTACAO,
  REMOVER_ORIENTACAO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  ADICIONAR_ARQUIVO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    adicionarArquivo,
    fazerDownloadDoArquivoDaOrientacao,
    history,
    match,
    orientacao,
    recuperarPeloIdentificador,
    remover,
    salvar,
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_ORIENTACAO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_ORIENTACAO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_ORIENTACAO])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a orientação ${orientacao.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(orientacao)
      }, {
        label: 'Não'
      }]
    })
  }

  const adicionarArquivoDaOrientacao = async (evento, setFieldValue, arquivos) => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const arquivoAdicionado = await adicionarArquivo(arquivo, 'orientacao')

    if (arquivoAdicionado) {
      setFieldValue('arquivos', [...arquivos, arquivoAdicionado])
    }
  }

  const confirmarERemoverArquivo = (arquivo, setFieldValue, arquivos) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o arquivo ${arquivo.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => setFieldValue('arquivos', arquivos.filter(x => x.identificador !== arquivo.identificador))
      }, {
        label: 'Não'
      }]
    })
  }

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoArquivoDaOrientacao(orientacao.identificador, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[
          RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
          ALTERAR_ORIENTACAO,
          ADICIONAR_ORIENTACAO,
          REMOVER_ORIENTACAO,
          FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
          ADICIONAR_ARQUIVO
        ]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: orientacao.identificador || null,
              nome: orientacao.nome || '',
              arquivos: orientacao.arquivos || [],
              observacoes: orientacao.observacoes || ''
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório')
            })}
          >
            {({ setFieldValue, values }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{orientacao.identificador ? 'Alterar Orientação' : 'Adicionar Orientação'}</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    <r-cell span={4} span-md={6} span-lg={8}>
                      <Input
                        type='text'
                        nome='nome'
                        tabIndex={1}
                        titulo='Nome *'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <div className='form-summary'>
                        <div className='form-summary-files'>
                          {values.arquivos.length > 0 && values.arquivos.map((x, index) =>
                            <div className='file-upload-item' key={index}>
                              <div className='file-upload-item__name'>{x.nome}</div>
                              {orientacao.identificador &&
                                <button
                                  className='file-upload-item__bt'
                                  title='Fazer Download'
                                  type='button'
                                  onClick={() => fazerDownload(x)}
                                >
                                  <i className='icon icon-download-cloud'></i>
                                </button>
                              }
                              <button
                                className='file-upload-item__bt --remove'
                                title='Remover'
                                type='button'
                                onClick={() => confirmarERemoverArquivo(x, setFieldValue, values.arquivos)}
                              >
                                <i className='icon icon-remove'></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='mt-2'>
                        <label
                          htmlFor='arquivos'
                          className='button --light w-sm-100'
                        >
                          Adicionar Arquivo
                          <i className='icon icon-doc ml-1'></i>
                        </label>
                        <input
                          type='file'
                          name='arquivos'
                          id='arquivos'
                          tabIndex={2}
                          onChange={evento => adicionarArquivoDaOrientacao(evento, setFieldValue, values.arquivos)}
                          onClick={evento => { evento.target.value = null }}
                        />
                      </div>
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={3}
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                </fieldset>
                {identificador ?
                  <div className='list-btn-unidades'>
                    {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                    {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  </div>
                  :
                  <div className='list-btn'>
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                  </div>
                }
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}