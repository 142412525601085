import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { confirmAlert } from 'react-confirm-alert'
import { useProntuario } from '../../contexto'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import { FieldArray } from 'formik'
import Formulario from './formulario'
import FileSaver from 'file-saver'
import arquivoPdf from '../../../../design/img/pdf-file.svg'
import outrosArquivos from '../../../../design/img/file.svg'

export default function Exames({ valor = [], atendimento }) {
  return (
    <FieldArray
      name='exames'
      render={({ push, remove, replace }) =>
        <Exame
          adicionar={push}
          alterar={replace}
          remover={remove}
          adicionados={valor}
          atendimento={atendimento}
        />
      }
    />
  )
}

function Exame({ adicionados, atendimento, ...rest }) {
  const {
    arquivosDoExame,
    tiposDeExame,
    fazerDownloadDoExameDoAtendimento,
    adicionarArquivo,
    listarTiposDeExame
  } = useProntuario()

  const [selecionado, setSelecionado] = useState(null)

  useEffect(() => { listarTiposDeExame() }, [listarTiposDeExame])

  const [exibirModal, setExibirModal] = useState(false)

  const abrirFormulario = () => { setExibirModal(true) }

  const fecharFormulario = () => {
    setExibirModal(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  useEffect(() => setExibirModal(selecionado !== null), [selecionado])

  const adicionar = registro => {
    rest.adicionar(registro)
    setExibirModal(false)
  }

  const salvar = registro => {
    const index = adicionados.findIndex(x => x.identificador === registro.identificador)
    rest.alterar(index, registro)
  }

  const remover = identificador => {
    const index = adicionados.findIndex(x => x.identificador === identificador)
    rest.remover(index)
  }

  const confirmarERemover = registro => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o exame ${registro.tipo} do dia ${formatarDataParaFormatoLocal(registro.data)}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(registro.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <Modal
        className='modal'
        contentLabel='Modal do formulário de exames'
        isOpen={exibirModal}
      >
        <Formulario
          fechar={fecharFormulario}
          tiposDeExame={tiposDeExame}
          arquivosDoExame={arquivosDoExame}
          adicionarArquivo={adicionarArquivo}
          fazerDownloadDoExameDoAtendimento={fazerDownloadDoExameDoAtendimento}
          identificadorDoPaciente={atendimento.paciente.identificador}
          identificadorDoAtendimento={atendimento.identificador}
          salvar={selecionado ? salvar : adicionar}
          selecionado={selecionado}
        />
      </Modal>
      <>
        {adicionados.length > 0 &&
          <Tabela
            acoes={[
              item =>
                <div key='remover'>
                  <button
                    onClick={() => confirmarERemover(item)}
                    type='button'
                    className='table-items-expanded__bt --remove'
                    title='Remover'
                  >
                    <i className='icon icon-minus'></i>
                  </button>
                </div>
              ,
              item =>
                <div key='editar'>
                  <button
                    type='button'
                    onClick={() => setSelecionado(item)}
                    className='table-items-expanded__bt --edit'
                    title='Editar'
                  >
                    <i className='icon icon-pencil'></i>
                  </button>
                </div>
            ]}
            chave='identificador'
            className='--secondary my-1'
            dados={adicionados}
            expandida={true}
            titulo={false}
            dadosAbertos={adicionados.map(y => y.identificador)}
          >
            <Coluna
              className='col-lg-2'
              nome='Tipo'
              campoDeDados='tipo'
              principal={true}
            />
            <Coluna
              nome='Data'
              className='col-lg-1'
              renderizar={item => formatarDataParaFormatoLocal(item.data)}
            />
            <LinhaExpandida className='col-xs-10 col-lg-11'>
              <AgrupamentoDeColunas>
                <Coluna
                  icone='icon-doc-text'
                  className='col-lg-4'
                  nome='Tipo'
                  campoDeDados='tipo'
                />
                <Coluna
                  icone='icon-date'
                  nome='Data'
                  className='col-lg-2'
                  renderizar={item => formatarDataParaFormatoLocal(item.data)}
                />
                <Coluna
                  icone='icon-folder-empty'
                  nome='Arquivos'
                  className='col-lg-3'
                  renderizar={item => item.arquivos.length > 0 &&
                    <Arquivos
                      key={`arquivo_${item.arquivos[0].arquivo}`}
                      registro={item}
                      arquivosDoExame={arquivosDoExame}
                      fazerDownloadDoExameDoAtendimento={fazerDownloadDoExameDoAtendimento}
                      identificadorDoAtendimento={atendimento.identificador}
                      identificadorDoPaciente={atendimento.paciente.identificador}
                    />
                  }
                />
                <Coluna
                  icone='icon-doc-text'
                  nome='Síntese'
                  className='table-items-expanded-100__box-item'
                  renderizar={item => item.sintese && <div><pre>{item.sintese}</pre></div>}
                />
              </AgrupamentoDeColunas>
            </LinhaExpandida>
          </Tabela>
        }
        <r-cell span={4} span-md={3} span-lg={6}>
          <button
            className='button --light w-sm-100'
            type='button'
            onClick={abrirFormulario}>
            Adicionar Exame
          </button>
        </r-cell>
      </>
    </r-cell>
  )
}

function Arquivos({ registro, arquivosDoExame, fazerDownloadDoExameDoAtendimento, identificadorDoAtendimento, identificadorDoPaciente }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDoArquivo = useRef(null)
  const width = registro.arquivos.length > 3 ? 381 : registro.arquivos.length * 127
  const posicaoAEsquerda = referenciaDoArquivo.current && referenciaDoArquivo.current.offsetLeft
  const imagens = ['.JPG', '.JPEG', '.PNG', '.JFIF']

  useCliqueForaDoElemento(referenciaDoArquivo, () => setVisivel(false))

  if (!registro) return

  const abrirTooltipArquivo = item => {
    if (visivel === item.arquivos[0].identificador) {
      setVisivel(false)
    } else {
      setVisivel(item.arquivos[0].identificador)
      if (item) {
        item.arquivos.forEach(x => {
          if (!x.url) {
            return fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, x.identificador)
          }
        })
      }
    }
  }

  const recuperarThumbnail = (extensao, url) => {
    if (imagens.find(x => x === extensao)) {
      return <img src={url} alt='exame' />
    } else if (extensao === '.PDF') {
      return <img src={arquivoPdf} alt='exame' />
    } else {
      return <img src={outrosArquivos} alt='exame' />
    }
  }

  const fazerDownload = async exame => {
    const dados = await fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, exame.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), exame.nome)
    }
  }

  const alertaDownload = () => {
    confirmAlert({
      title: 'Alerta',
      message: `Para fazer o download do arquivo é necessário salvar a seção.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  return (
    <div ref={referenciaDoArquivo}>
      <button type='button' className='p-0'>
        <i className={`icon-folder-empty text${visivel ? '-primary' : '-secondary'}`}
          onClick={() => abrirTooltipArquivo(registro)}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop tooltip-sumario custom-scrollbar' style={{ width: width, left: posicaoAEsquerda }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              <div className='form-summary'>
                <div className='form-summary-images'>
                  {registro.arquivos.map((x, index) => {
                    const url = arquivosDoExame && arquivosDoExame[x.identificador] ? arquivosDoExame[x.identificador].url : x.url
                    const extensao = x.extensao.toUpperCase()
                    return (
                      <div key={index}>
                        <>
                          {url ?
                            <>
                              <div className='form-summary-image exames'>
                                {recuperarThumbnail(extensao, url)}
                                <div className='form-summary-image-tools'>
                                  <button
                                    className='form-summary-image-zoom'
                                    aria-label='Download'
                                    type='button'
                                    onClick={() => x.identificador && !x.url ? fazerDownload(x) : alertaDownload()}
                                  >
                                    <i className='icon-download-cloud'></i>
                                  </button>
                                </div>
                              </div>
                              <p className='exames nome' title={x.nome}>{x.nome}</p>
                            </>
                            : 'Carregando...'
                          }
                        </>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}