import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function IconeStatus(props) {
  const {    
    item,
  } = props

  if (item === undefined) return null

  const status = ['agendado',  'confirmado',   'aguardando atendimento', 'em atendimento', 'atendido',        'cancelado',  'reagendado']
  const icones = ['icon-date', 'icon-checked', 'icon-sala',              'icon-doctor',    'icon-ok-circled', 'icon-block', 'icon-block']
  
  const idx = status.findIndex(s => s === item.status[0].nome.toLowerCase())

  if (idx === -1) return null

  function calculaCorDoIcone() {    
    if (item.status[0].nome === 'Cancelado') return '#000000'
    return ''
  }

  return (
    <i 
      className={`icon ${icones[idx]}`}
      data-tip
      data-for={`info_icone_${item.identificador}`}
      style={{ color: `${calculaCorDoIcone()}` }}
    >
      <ReactTooltip
        id={`info_icone_${item.identificador}`}
        place='top'
        effect='solid'
        type='info'
        key={item.identificador}
      >
        <span>{item.status[0].nome}</span>
      </ReactTooltip>
    </i>
  )
}  
