import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import Modal from 'react-modal'
import { registrarFirestoreDeTarefasDoPaciente/*, registrarFirestoreDeTarefasDoUsuario*/ } from '../../acoes/tarefas/firestore'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import { useFirestore } from '../../configuracoes/firestore/provedor'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { diferencaEntreDataEAtualNoFormatoSegundosENanosegundosReduzido, formatarSegundosENanosegundosParaMoment } from '../../bibliotecas/data'
import EditarTarefa from './editar-tarefa'
import Sidebar from './sidebar'
import { ehUmNumeroInteiroValido } from '../../bibliotecas/validacoes'

const estiloDoModal = {
  content: {
    height: '95%',
    width: '95%',
  }
}

export default function BarraDeTarefas() {
  const dispatch = useDispatch()
  const location = useLocation()
  const match = useRouteMatch()
  const { firestore } = useFirestore()
  const { usuario } = useUsuarioLogado()

  const { pacientes } = useSelector(state => state.prontuario)
  const { itemAtual } = useSelector(state => state.pacientes)
  const { itensDoPaciente, tipos } = useSelector(state => state.tarefas)

  const [exibirEditarTarefa, setExibirEditarTarefa] = useState(false)
  const [identificadorDaTarefa, setIdentificadorDaTarefa] = useState(null)

  const urls = recuperarPartesDaUrl(location.pathname)
  const telaDePaciente = location.pathname.includes('/cadastros/pacientes')
  const telaDeProntuario = telaDePaciente && location.pathname.includes('/prontuario')

  const ehTelaDeCadastro = telaDePaciente && !telaDeProntuario

  const identificador = telaDePaciente && urls[3] ? urls[3].substring(21) : null
  const identificadorDoPaciente = !ehUmNumeroInteiroValido(identificador) ? null : (telaDeProntuario ? pacientes[identificador]?.cabecalho.identificacao.identificadorPublico : itemAtual.identificadorPublico)
  const empresa = usuario.token.empresa
  const titulo = identificadorDoPaciente ? 'Tarefas do Paciente' : 'Minhas Tarefas'

  const listaTarefas = identificadorDoPaciente ? converterObjetoParaArray(itensDoPaciente) : []

  useEffect(() => {
    let desregistrar
    if (identificadorDoPaciente) {
      desregistrar = dispatch(registrarFirestoreDeTarefasDoPaciente(firestore, empresa, identificadorDoPaciente))
    }
    return () => desregistrar && desregistrar()
  }, [dispatch, firestore, empresa, identificadorDoPaciente])

  function recuperarNome(item) {
    const responsavel = item.responsavel?.nome
    const paciente = item.paciente?.nome

    return identificadorDoPaciente ? responsavel : paciente
  }

  function abrirEditarTarefa(identificador) {
    setIdentificadorDaTarefa(identificador)
    setExibirEditarTarefa(true)
  }

  const listaTarefasAjustadas = listaTarefas.map(item => {
    const prazo = recuperarPrazo(item)
    return ({ ...item, prazo: prazo[0], atrasado: prazo[1] })
  })

  if (!telaDePaciente || !identificadorDoPaciente) return null

  return (
    <>
      <Modal
        isOpen={exibirEditarTarefa}
        style={estiloDoModal}
        className='modal'
        contentLabel='Modal para editar tarefa'
      >
        <EditarTarefa
          identificadorDaTarefa={identificadorDaTarefa}
          fechar={() => setExibirEditarTarefa(false)}
          match={match}
        />
      </Modal>
      <Sidebar
        tarefas={listaTarefasAjustadas}
        tipos={tipos}
        titulo={titulo}
        recuperarNome={recuperarNome}
        inicioComponente={!telaDeProntuario ? 120 : (urls.length === 6 ? 47 : 68)}
        abrirEditarTarefa={abrirEditarTarefa}
        identificadorDoPaciente={identificadorDoPaciente}
        ehTelaDeCadastro={ehTelaDeCadastro}
      />
    </>
  )
}

const recuperarPartesDaUrl = url => {
  const urls = url.split('/').filter(x => x)
  let partesDaUrl = ['/']

  urls.forEach((_, index) => {
    const url = `/${urls.slice(0, index + 1).join('/')}`
    partesDaUrl.push(url)
  })

  return partesDaUrl
}

function recuperarPrazo(item, horarioAtual) {
  const { prazo, finalizadaEm } = item
  if (!prazo && horarioAtual) return null

  const prazoFormatado = formatarSegundosENanosegundosParaMoment(prazo)
  const finalizadaEmFormatado = formatarSegundosENanosegundosParaMoment(finalizadaEm)

  let valor = diferencaEntreDataEAtualNoFormatoSegundosENanosegundosReduzido(prazo)
  let atrasado = prazoFormatado.isSameOrBefore(horarioAtual)

  if (finalizadaEm) {
    valor = finalizadaEmFormatado.from(prazoFormatado, true)
    atrasado = prazoFormatado.isSameOrBefore(finalizadaEmFormatado)
  }

  if (valor.includes('anos')) {
    valor = 'indefinido'
  }

  return [valor, atrasado]
}