import React from 'react'
import { Diagram } from '../../../../design/icones'

export default function Status({ valor }) {
  if (!valor) return null
  
  return (
    <div className='card-cabecalho'>
      <div className='icon'><Diagram size={23} /></div>
      <div className='valor'>{valor}</div>
    </div>
  )
}