import api from '../api-major'

const listarUnidadesDoUsuario = identificadorDoUsuario => api.get(`/usuarios/${identificadorDoUsuario}/unidades?tamanhoDaPagina=10000&ordem=nome&ativa=true`)

const adicionarUnidadeDoUsuario = (identificadorDoUsuario, identificadorDaUnidade) => api.post(`/usuarios/${identificadorDoUsuario}/unidades`, { unidadeDaEmpresa: identificadorDaUnidade })

const removerUnidadeDoUsuario = (identificadorDoUsuario, identificadorDaUnidade) => api.delete(`/usuarios/${identificadorDoUsuario}/unidades/${identificadorDaUnidade}`)

const listarUnidadesDaEmpresaDoUsuario = () => api.get(`/empresa/unidades?tamanhoDaPagina=1000&ordem=nome`)

export default {
    adicionarUnidadeDoUsuario,
    listarUnidadesDaEmpresaDoUsuario,
    listarUnidadesDoUsuario,
    removerUnidadeDoUsuario,
}