import { LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER: {
      return action.tiposDeAtividadeFisicaDeLazer
    }

    default: {
      return state
    }
  }
}