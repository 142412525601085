import React, { useEffect } from 'react'

export default function ValidarAssinaturaDigital(props) {
  useEffect(() => {
    (async () => {
      await new Promise(r => setTimeout(r, 5000))
      window.location.replace('https://assinaturadigital.iti.gov.br')
    })()
  }, [])

  return (

    <div className='page-validar-assinatura-digital'>
      <div className='box-campos'>
        <div className='logo' />
        <h1>Você será redirecionado em alguns segundos...</h1>
        <p>Caso o redirecionamento não aconteça automaticamente, clique no link abaixo:</p>
        <a href='https://assinaturadigital.iti.gov.br/'>assinaturadigital.iti.gov.br</a>
      </div>
    </div>
  )
}