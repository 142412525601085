import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function EstabelecimentosDeSaude(props) {
  const {
    adicionarEstabelecimentoDeSaude,
    estabelecimento,
    filtros,
    history,
    listarEstabelecimentosDeSaudeDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarEstabelecimentoDeSaudePeloIdentificador,
    removerEstabelecimentoDeSaude,
    alterarEstabelecimentoDeSaude
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
      status: formulario.status ? 'aprovado' : 'nao_aprovado',
      cnes: formulario.cnes ? parseInt(formulario.cnes) : ''
    }

    const acao = dados.identificador ? alterarEstabelecimentoDeSaude : adicionarEstabelecimentoDeSaude
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/estabelecimentos-de-saude`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_ESTABELECIMENTOS_DE_SAUDE]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarEstabelecimentosDeSaudeDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_ESTABELECIMENTO_DE_SAUDE]}>
              <Formulario
                {...props}
                salvar={salvar}
                estabelecimento={estabelecimento}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_ESTABELECIMENTO_DE_SAUDE]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarEstabelecimentoDeSaudePeloIdentificador}
                salvar={salvar}
                estabelecimento={estabelecimento}
                remover={removerEstabelecimentoDeSaude}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}