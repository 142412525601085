import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE,
  LISTAR_SUGESTOES_DE_PACIENTE,
  LISTOU_SUGESTOES_DE_PACIENTE,
  ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO,
  LISTAR_TOKENS_DE_SUGESTAO,
  LISTOU_TOKENS_DE_SUGESTAO,
  ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO,
  LISTAR_TOKENS_DE_EXEMPLO,
  LISTOU_TOKENS_DE_EXEMPLO,
  ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE,
  LISTAR_COMPONENTES_COM_TEMPLATE,
  LISTOU_COMPONENTES_COM_TEMPLATE,
  ADICIONAR_TEMPLATE,
  ADICIONOU_TEMPLATE,
  ERRO_AO_ADICIONAR_TEMPLATE,
  RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  RECUPEROU_TEMPLATE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  SALVAR_TEMPLATE,
  SALVOU_TEMPLATE,
  ERRO_AO_SALVAR_TEMPLATE,
  LISTAR_TEMPLATES,
  LISTOU_TEMPLATES,
  ERRO_AO_LISTAR_TEMPLATES,
  EXCLUIR_TEMPLATE,
  EXCLUIU_TEMPLATE,
  ERRO_AO_EXCLUIR_TEMPLATE,
  LISTAR_TEMPLATES_DO_PRONTUARIO,
  LISTOU_TEMPLATES_DO_PRONTUARIO,
  ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO,
  ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  ADICIONOU_TEMPLATE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO,
} from './tipos'

const listouProfissionaisDeSaudeDeSugestao = profissionaisDeSaude => ({
  type: LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  profissionaisDeSaude,
})

const erroAoListarProfissionaisDeSaudeDeSugestao = erro => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  erro
})

export const listarProfissionaisDeSaudeDeSugestao = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO })

  try {
    const resultado = await api.listarProfissionaisDeSaude()

    dispatch(listouProfissionaisDeSaudeDeSugestao(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissionaisDeSaudeDeSugestao(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais de saúde de sugestão.')))
  }
}

const listouSugestoesDePaciente = sugestoes => ({
  type: LISTOU_SUGESTOES_DE_PACIENTE,
  sugestoes,
})

const erroAoListarSugestoesDePaciente = erro => ({
  type: ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE,
  erro
})

export const listarSugestoesDePaciente = paciente => async dispatch => {
  dispatch({ type: LISTAR_SUGESTOES_DE_PACIENTE })

  try {
    const resultado = await api.listarTokensDePaciente(paciente)

    dispatch(listouSugestoesDePaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSugestoesDePaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as sugestões do paciente.')))
  }
}

const listouTokensDeSugestao = tokens => ({
  type: LISTOU_TOKENS_DE_SUGESTAO,
  tokens,
})

const erroAoListarTokensDeSugestao = erro => ({
  type: ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO,
  erro
})

export const listarTokensDeSugestao = () => async dispatch => {
  dispatch({ type: LISTAR_TOKENS_DE_SUGESTAO })

  try {
    const resultado = await api.listarTokensDeTemplates()

    dispatch(listouTokensDeSugestao(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTokensDeSugestao(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tokens de sugestão.')))
  }
}

const listouTokensDeExemplo = sugestoes => ({
  type: LISTOU_TOKENS_DE_EXEMPLO,
  sugestoes,
})

const erroAoListarTokensDeExemplo = erro => ({
  type: ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO,
  erro
})

export const listarTokensDeExemplo = () => async dispatch => {
  dispatch({ type: LISTAR_TOKENS_DE_EXEMPLO })

  try {
    const resultado = await api.listarTokensDeExemplo()

    dispatch(listouTokensDeExemplo(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTokensDeExemplo(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tokens de exemplo.')))
  }
}

const listouComponentesComTemplate = componentes => ({
  type: LISTOU_COMPONENTES_COM_TEMPLATE,
  componentes,
})

const erroAoListarComponentesComTemplate = erro => ({
  type: ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE,
  erro
})

export const listarComponentesComTemplate = () => async dispatch => {
  dispatch({ type: LISTAR_COMPONENTES_COM_TEMPLATE })

  try {
    const resultado = await api.listarComponentesComTemplate()

    dispatch(listouComponentesComTemplate(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComponentesComTemplate(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os componentes com template.')))
  }
}

const adicionouTemplate = template => ({
  type: ADICIONOU_TEMPLATE,
  template,
})

const erroAoAdicionarTemplate = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TEMPLATE,
  erro,
  parametros
})

export const adicionarTemplate = template => async dispatch => {
  dispatch({ type: ADICIONAR_TEMPLATE })

  try {
    const resultado = await api.adicionarTemplate(template)

    dispatch(adicionouTemplate(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarTemplate(erro, { template }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar template.')))

    return false
  }
}

const adicionouTemplateDoProntuario = template => ({
  type: ADICIONOU_TEMPLATE_DO_PRONTUARIO,
  template,
})

const erroAoAdicionarTemplateDoProntuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  erro,
  parametros
})

export const adicionarTemplateDoProntuario = template => async dispatch => {
  dispatch({ type: ADICIONAR_TEMPLATE_DO_PRONTUARIO })

  try {
    const resultado = await api.adicionarTemplate(template)

    dispatch(adicionouTemplateDoProntuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarTemplateDoProntuario(erro, { template }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar template do prontuário.')))

    return false
  }
}

const recuperouTemplatePorIdentificador = template => async dispatch => {
  dispatch({ type: RECUPEROU_TEMPLATE_POR_IDENTIFICADOR, template })
}

const erroAoRecuperarTemplatePorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarTemplatePorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_TEMPLATE_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarTemplatePorIdentificador(identificador)

    dispatch(recuperouTemplatePorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarTemplatePorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o template por identificador.')))
  }
}

const salvouTemplate = template => ({
  type: SALVOU_TEMPLATE,
  template,
})

const erroAoSalvarTemplate = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_TEMPLATE,
  erro,
  parametros
})

export const salvarTemplate = template => async dispatch => {
  dispatch({ type: SALVAR_TEMPLATE })

  try {
    const resultado = await api.salvarTemplate(template)

    dispatch(salvouTemplate(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarTemplate(erro, { template }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o template.')))

    return false
  }
}

const listouTemplates = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_TEMPLATES,
  filtros,
  ordenacao,
  paginaDeDados
})

const erroAoListarTemplates = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TEMPLATES,
  erro,
  parametros
})

export const listarTemplates = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_TEMPLATES })

  try {
    const resultado = await api.listarTemplates(filtros, pagina, ordenacao, 10)

    dispatch(listouTemplates(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTemplates(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os templates.')))
  }
}

const excluiuTemplate = identificador => ({
  type: EXCLUIU_TEMPLATE,
  identificador,
})

const erroAoExcluirTemplate = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_TEMPLATE,
  erro,
  parametros
})

export const excluirTemplate = identificador => async dispatch => {
  dispatch({ type: EXCLUIR_TEMPLATE })

  try {
    const resultado = await api.excluirTemplate(identificador)

    dispatch(excluiuTemplate(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirTemplate(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível excluir o template.')))

    return false
  }
}

const listouTemplatesDoProntuario = templates => ({
  type: LISTOU_TEMPLATES_DO_PRONTUARIO,
  templates
})

const erroAoListarTemplatesDoProntuario = erro => ({
  type: ERRO_AO_LISTAR_TEMPLATES_DO_PRONTUARIO,
  erro
})

export const listarTemplatesDoProntuario = () => async dispatch => {
  dispatch({ type: LISTAR_TEMPLATES_DO_PRONTUARIO })

  try {
    const resultado = await api.listarTemplates()

    dispatch(listouTemplatesDoProntuario(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTemplatesDoProntuario(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os templates do prontuário.')))
  }
}