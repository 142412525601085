import { LISTOU_PROPRIETARIOS_DE_TELEFONES } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PROPRIETARIOS_DE_TELEFONES: {
      return action.proprietariosDeTelefones
    }

    default: {
      return state
    }
  }
}