import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { 
  LISTAR_RESULTADOS_HBSAG, 
  LISTOU_RESULTADOS_HBSAG, 
  ERRO_AO_LISTAR_RESULTADOS_HBSAG,
  LISTAR_RESULTADOS_ANTI_HCV,
  LISTOU_RESULTADOS_ANTI_HCV,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV,
  LISTAR_RESULTADOS_ANTI_HIV,
  LISTOU_RESULTADOS_ANTI_HIV,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV,
  LISTAR_RESULTADOS_ANTI_HBC,
  LISTOU_RESULTADOS_ANTI_HBC,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC,
} from './tipos'

const listouResultadosHbsag = resultadosHbsag => ({
  type: LISTOU_RESULTADOS_HBSAG,
  resultadosHbsag
})

const erroAoListarResultadosHbsag = erro => ({
  type: ERRO_AO_LISTAR_RESULTADOS_HBSAG,
  erro
})

export const listarResultadosHbsag = () => async dispatch => {
  dispatch({ type: LISTAR_RESULTADOS_HBSAG })

  try {
    const resultado = await api.listarResultadosHbsag()

    dispatch(listouResultadosHbsag(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarResultadosHbsag(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os resultados da HBsAg.')))
  }
}

const listouResultadosAntiHcv = resultadosAntiHcv => ({
  type: LISTOU_RESULTADOS_ANTI_HCV,
  resultadosAntiHcv
})

const erroAoListarResultadosAntiHcv = erro => ({
  type: ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV,
  erro
})

export const listarResultadosAntiHcv = () => async dispatch => {
  dispatch({ type: LISTAR_RESULTADOS_ANTI_HCV })

  try {
    const resultado = await api.listarResultadosAntiHcv()

    dispatch(listouResultadosAntiHcv(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarResultadosAntiHcv(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os resultados do Anti-HCV.')))
  }
}

const listouResultadosAntiHiv = resultadosAntiHiv => ({
  type: LISTOU_RESULTADOS_ANTI_HIV,
  resultadosAntiHiv
})

const erroAoListarResultadosAntiHiv = erro => ({
  type: ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV,
  erro
})

export const listarResultadosAntiHiv = () => async dispatch => {
  dispatch({ type: LISTAR_RESULTADOS_ANTI_HIV })

  try {
    const resultado = await api.listarResultadosAntiHiv()

    dispatch(listouResultadosAntiHiv(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarResultadosAntiHiv(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os resultados do Anti-HIV.')))
  }
}

const listouResultadosAntiHbc = resultadosAntiHbc => ({
  type: LISTOU_RESULTADOS_ANTI_HBC,
  resultadosAntiHbc
})

const erroAoListarResultadosAntiHbc = erro => ({
  type: ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC,
  erro
})

export const listarResultadosAntiHbc = () => async dispatch => {
  dispatch({ type: LISTAR_RESULTADOS_ANTI_HBC })

  try {
    const resultado = await api.listarResultadosAntiHbc()

    dispatch(listouResultadosAntiHbc(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarResultadosAntiHbc(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os resultados do Anti-HBC.')))
  }
}