import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
} from '../../tipos'

const listouBloqueiosNaAgendaDoProfissionalDeSaude = (paginaDeDados, filtros) => ({
  paginaDeDados,
  filtros,
  type: LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
})

const erroAoListarBloqueiosNaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const listarBloqueiosNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, filtros, pagina) => async dispatch => {
  dispatch({ filtros, type: LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.listarBloqueiosNaAgendaDoProfissionalDeSaude(identificadorDoProfissional, filtros, pagina)

    dispatch(listouBloqueiosNaAgendaDoProfissionalDeSaude(resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarBloqueiosNaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, filtros, pagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os bloqueios na agenda do profissional de saúde.')))
  }
}

export const adicionarBloqueioNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, bloqueio) => async dispatch => {
  dispatch({ type: ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.adicionarBloqueioNaAgendaDoProfissionalDeSaude(identificadorDoProfissional, bloqueio)
    dispatch(adicionouBloqueioNaAgendaDoProfissionalDeSaude(resultado.data.dados, identificadorDoProfissional))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarBloqueioNaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, bloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do bloqueio, na agenda do profissional de saúde.')))

    return false
  }
}

const adicionouBloqueioNaAgendaDoProfissionalDeSaude = (bloqueios, identificadorDoProfissional) => ({
  type: ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  bloqueios,
  identificadorDoProfissional
})

const erroAoAdicionarBloqueioNaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarBloqueioNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, bloqueio) => async dispatch => {
  dispatch({ type: ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.alterarBloqueioNaAgendaDoProfissionalDeSaude(identificadorDoProfissional, bloqueio)
    dispatch(alterouBloqueioNaAgendaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarBloqueioNaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, bloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do bloqueio na agenda do profissional de saúde.')))

    return false
  }
}

const alterouBloqueioNaAgendaDoProfissionalDeSaude = bloqueio => ({
  type: ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  bloqueio
})

const erroAoAlterarBloqueioNaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, serie) => async dispatch => {
  dispatch({ type: ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, serie)
    dispatch(alterouSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, serie }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados da série de bloqueio na agenda do profissional de saúde.')))

    return false
  }
}

const alterouSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = serie => ({
  type: ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  serie
})

const erroAoAlterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirBloqueioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDoBloqueio) => async dispatch => {
  dispatch({ type: EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    let resultado = await api.excluirBloqueioDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificadorDoBloqueio)
    dispatch(excluiuBloqueioDaAgendaDoProfissionalDeSaude(identificadorDoBloqueio))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Bloqueio removido com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirBloqueioDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificadorDoBloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um bloqueio da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuBloqueioDaAgendaDoProfissionalDeSaude = identificadorDoBloqueio => ({
  type: EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  identificadorDoBloqueio
})

const erroAoExcluirBloqueioDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDoBloqueio) => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificadorDoBloqueio)
    dispatch(excluiuSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(identificadorDoBloqueio))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Série de Bloqueios removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificadorDoBloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir uma série de bloqueios da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = identificadorDoBloqueio => ({
  type: EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  identificadorDoBloqueio
})

const erroAoExcluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})