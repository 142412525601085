import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
} from '../tipos'

const gerouGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado = (filtros, dados) => ({
  type: GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  filtros,
  dados,
})

const erroAoGerarDeInternacoesPorFraturaDeFemurMetaVersusRealizado = (erro, parametros) => ({
  type: ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  erro,
  parametros
})

export const gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado = filtros => async dispatch => {
  dispatch({ type: GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO })

  try {
    const resultado = await api.gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado(filtros)

    dispatch(gerouGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado(filtros, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoGerarDeInternacoesPorFraturaDeFemurMetaVersusRealizado(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível gerar o gráfico de internações por fratura de femur meta versus realizado.')))
  }
}

const gerouGraficoDeReinternacoesMetaVersusRealizado = (filtros, dados) => ({
  type: GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  filtros,
  dados,
})

const erroAoGerarDeReinternacoesMetaVersusRealizado = (erro, parametros) => ({
  type: ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  erro,
  parametros
})

export const gerarGraficoDeReinternacoesMetaVersusRealizado = filtros => async dispatch => {
  dispatch({ type: GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO })

  try {
    const resultado = await api.gerarGraficoDeReinternacoesMetaVersusRealizado(filtros)

    dispatch(gerouGraficoDeReinternacoesMetaVersusRealizado(filtros, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoGerarDeReinternacoesMetaVersusRealizado(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível gerar o gráfico de reinternações meta versus realizado.')))
  }
}

const listouProgramasDoRelatorioResumoDeInternacoes = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  programas
})

const erroAoListarProgramasDoRelatorioResumoDeInternacoes = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  erro
})

export const listarProgramasDoRelatorioResumoDeInternacoes = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioResumoDeInternacoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioResumoDeInternacoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as operadoras do relatório de resumo de internações.')))
  }
}

const fezDownloadDeInternacoesPorFraturaDeFemur = () => ({
  type: FEZ_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
})

const erroAoFazerDownloadDeInternacoesPorFraturaDeFemur = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR,
  erro,
  parametros
})

export const fazerDownloadDeInternacoesPorFraturaDeFemur = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_INTERNACOES_POR_FRATURA_DE_FEMUR })

  try {
    const resultado = await api.fazerDownloadDeInternacoesPorFraturaDeFemur(filtros)

    dispatch(fezDownloadDeInternacoesPorFraturaDeFemur())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDeInternacoesPorFraturaDeFemur(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download de internações por fratura de femur.')))

    return null
  }
}