import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import { Select } from '../../formik/formulario'
import BirdId from '../../../design/img/birdid.svg'
import Vidaas from '../../../design/img/vidaas.png'
import { formatarCpf } from '../../../bibliotecas/formatacao'

const certificados = [
  { nome: 'BirdId', avatar: BirdId, link: 'https://www.birdid.com.br/' },
  { nome: 'ViDaaS', avatar: Vidaas, link: 'https://certificado-em-nuvem.validcertificadora.com.br/' }
]

const temposDeSessao = [
  { codigo: '00:00:00', nome: 'Sem sessão' },
  { codigo: '01:00:00', nome: '1 Hora' },
  { codigo: '02:00:00', nome: '2 Horas' },
  { codigo: '04:00:00', nome: '4 Horas' },
  { codigo: '08:00:00', nome: '8 Horas' },
  { codigo: '12:00:00', nome: '12 Horas' }
]

export default function FormularioDeAssinaturaDigitalEmNuvem(props) {
  const {
    assinarComUrlDeAutenticacao, 
    etapas, 
    fecharModal, 
    labelBotaoAssinar,
    lacuna,
    linkExterno,
    usuario
  } = props

  const certificadosParaSelecao = useMemo(() => {
    return usuario.certificadosEmNuvem.lista.map(x => ({ codigo: x.nomeDoServico, nome: x.nomeDoServico + ' (' + x.provedor + ')' }))
  }, [usuario.certificadosEmNuvem.lista])  

  const certificadoSelecionado = useMemo(() => {
    if (lacuna.itemAtual)
      return lacuna.itemAtual

    if (certificadosParaSelecao.length === 1)
      return certificadosParaSelecao[0].codigo

    return ''
  }, [lacuna, certificadosParaSelecao])  

  return (
    <FormularioDoFormik
      reinicializar={true}
      valoresIniciais={{
        servico: certificadoSelecionado,
        tempo: '12:00:00'
      }}
      acao={assinarComUrlDeAutenticacao}
      esquemaDeValidacoes={Yup.object().shape({
        servico: Yup.string().required('Obrigatório')
      })}
    >
      {etapas &&
        <h2 className='verificando-permissoes text-black-50'>{etapas}</h2>
      }
      {!etapas &&
        <fieldset>
          {certificadosParaSelecao && certificadosParaSelecao.length === 0 &&
            <>
              {!usuario.cpf &&
                <div className='form-choice-alerta'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-alerta__item'>
                    <ul>
                      <li>O seu <strong>CPF</strong> não foi informado no cadastro.</li>
                      <li>Para realizar a assinatura digital, atualize seu cadastro e <strong>efetue o login</strong> novamente.</li>
                    </ul>
                  </div>
                </div>
              }
              {usuario.cpf &&
                <>
                  <div className='form-choice-alerta'>
                    <div className='form-choice-alerta__item'>
                      <i className='icon icon-attention-circled'></i>
                    </div>
                    <div className='form-choice-alerta__item'>
                      <ul>
                        <li>Nenhum certificado em nuvem válido encontrado para o CPF {formatarCpf(usuario.cpf, 11)}.</li>
                      </ul>
                    </div>
                  </div>
                  <h1 className='escolher-assinador-digital py-1'>
                    <ul>
                      Certificados em nuvem homologados:
                    </ul>
                    {certificados.map(c =>
                      <ul className='py-2' key={c.nome}>
                        <div className='menu-account__pill' onClick={() => linkExterno(c.link)} style={{ cursor: 'pointer' }}>
                          <div className='menu-account__pill__info'>
                            <p className='menu-account__pill__unity'>{c.nome}</p>
                          </div>
                          <img className='escolher-assinador-digital-avatar' src={c.avatar} alt={c.nome} />
                        </div>
                      </ul>
                    )}
                  </h1>
                </>
              }
              <div className='list-btn'>
                <button className='button --light' onClick={fecharModal}>Fechar</button>
                {!usuario.cpf && <Link to={`/configuracoes/usuarios/${usuario.identificador}`} className='button --primary'>Atualizar o CPF</Link>}
              </div>
            </>
          }
          {certificadosParaSelecao && certificadosParaSelecao.length > 0 &&
            <>
              <r-grid columns-lg='12' columns-md='12'>
                <r-cell span-md={6}>
                  <Select
                    nome='servico'
                    campoCodigo='codigo'
                    campoDescricao='nome'
                    itens={certificadosParaSelecao}
                    titulo='Certificado *'
                    tabIndex={1}
                  />
                </r-cell>
                <r-cell span-md={6}>
                  <Select
                    nome='tempo'
                    campoCodigo='codigo'
                    campoDescricao='nome'
                    itens={temposDeSessao}
                    titulo='Tempo de Sessão *'
                    tabIndex={2}
                  />
                </r-cell>
              </r-grid>
              <div className='list-btn'>
                <button className='button --light' onClick={fecharModal}>Fechar</button>
                <button className='button --primary' type='submit'>{labelBotaoAssinar}</button>
              </div>
            </>
          }
        </fieldset>
      }
    </FormularioDoFormik>
  )
}