import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RESULTADOS_DAS_TRIAGENS,
  LISTOU_RESULTADOS_DAS_TRIAGENS,
  ERRO_AO_LISTAR_RESULTADOS_DAS_TRIAGENS
} from '../tipos'

const listouResultadosDasTriagens = resultadosTriagens => ({
  type: LISTOU_RESULTADOS_DAS_TRIAGENS,
  resultadosTriagens
})

const erroAoListarResultadosDasTriagens = erro => ({
  type: ERRO_AO_LISTAR_RESULTADOS_DAS_TRIAGENS,
  erro
})

export const listarResultadosDasTriagens = () => async dispatch => {
  dispatch({ type: LISTAR_RESULTADOS_DAS_TRIAGENS })

  try {
    const resultado = await api.listarResultadosDasTriagens()

    dispatch(listouResultadosDasTriagens(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarResultadosDasTriagens(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os resultados das triagens.')))
  }
}
