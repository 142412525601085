import {
  IMPORTOU_PACIENTES, LIMPAR_IMPORTACAO_DE_PACIENTES
} from '../../acoes/tipos'

export default (state = {}, action) => {
  switch (action.type) {
    case IMPORTOU_PACIENTES: {
      return action.dados
    }

    case LIMPAR_IMPORTACAO_DE_PACIENTES: {
      return {}
    }

    default: {
      return state
    }
  }
}