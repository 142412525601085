import {
  LISTOU_UNIDADES_DO_USUARIO,
  LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO,
} from '../../acoes/tipos'

const estadoInicial = {
  habilitadas: [],
  unidades: {},
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_UNIDADES_DO_USUARIO: {
      return {
        ...state,
        paginaDeDados: action.unidades,
        habilitadas: action.unidades.dados.map(x => x.identificador),
      }
    }

    case LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO: {
      return {
        ...state,
        unidades: action.unidades,
      }
    }

    default: {
      return state
    }
  }
}