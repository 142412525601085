import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, YearPicker, MonthPicker, Radio, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import {ADICIONAR_QUEDA_DO_PACIENTE, ALTERAR_QUEDA_DO_PACIENTE, LISTAR_LOCAIS_DE_QUEDAS} from '../../../../acoes/tipos'

export default function Formulario({ fechar, locais, selecionada, ...rest }) {
  const periodos = [
    'Dia',
    'Mês/Ano',
    'Ano'
  ]

  const salvar = async (valores, { resetForm }) => {
    const salvou = await rest.salvar(valores)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[LISTAR_LOCAIS_DE_QUEDAS, ADICIONAR_QUEDA_DO_PACIENTE, ALTERAR_QUEDA_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : '',
          local: selecionada ? selecionada.local : '',
          oQueEstavaFazendoNoMomentoDaQueda: selecionada ? selecionada.oQueEstavaFazendoNoMomentoDaQueda : '',
          ligouParaAClinica: selecionada ? selecionada.ligouParaAClinica : '',
          precisouIrAoProntoAtendimentoOuHospital: selecionada ? selecionada.precisouIrAoProntoAtendimentoOuHospital : '',
          procurouAlgumMedico: selecionada ? selecionada.procurouAlgumMedico : '',
          precisaoDaData: selecionada ? selecionada.precisaoDaData : '',
          data: selecionada ? selecionada.data : ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          precisaoDaData: Yup.string().required('Obrigatório.'),
          data: Yup.string().nullable().required('Obrigatório.'),
          local: Yup.string().required('Obrigatório.'),
          ligouParaAClinica: Yup.boolean().required('Deve ser informado.'),
          precisouIrAoProntoAtendimentoOuHospital: Yup.boolean().required('Deve ser informado.'),
          procurouAlgumMedico: Yup.boolean().required('Deve ser informado.')
        })}
        acao={salvar}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>{selecionada ? 'Alterar Queda' : 'Adicionar  Queda'}</h2>
            <r-grid columns-md={6} columns-lg={12}>
              <r-cell span={2} span-md={3} span-lg={4}>
                <Select
                  nome='precisaoDaData'
                  titulo='Período *'
                  tabIndex={1}
                  itens={periodos}
                  campoCodigo=''
                  campoDescricao=''
                />
              </r-cell>
              {values.precisaoDaData === 'Dia' &&
                <r-cell span={2} span-md={3} span-lg={4}>
                  <DatePicker
                    nome='data'
                    titulo='Dia *'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Mês/Ano' &&
                <r-cell span={2} span-md={3} span-lg={4}>
                  <MonthPicker
                    nome='data'
                    titulo='Mês *'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Ano' &&
                <r-cell span={2} span-md={3} span-lg={4}>
                  <YearPicker
                    nome='data'
                    titulo='Ano *'
                    tabIndex={2}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={12} span-lg={12}>
                <Select
                  nome='local'
                  titulo='Local da Queda *'
                  tabIndex={3}
                  itens={locais}
                  campoCodigo='nome'
                  campoDescricao='nome'
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <label className='form-label'>Ligou para a Clínica? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='ligouParaAClinica'
                    titulo='Sim'
                    valor={true}
                    classname='mr-3'
                    onChange={() => setFieldValue('ligouParaAClinica', true)}
                    tabIndex={4}
                  />
                  <Radio
                    nome='ligouParaAClinica'
                    titulo='Não'
                    valor={false}
                    onChange={() => setFieldValue('ligouParaAClinica', false)}
                    tabIndex={5}
                  />
                </div>
                {(errors.ligouParaAClinica && touched.ligouParaAClinica) ?
                  <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <label className='form-label'>Precisou ir para o Pronto Atendimento ou Hospital? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='precisouIrAoProntoAtendimentoOuHospital'
                    titulo='Sim'
                    valor={true}
                    classname='mr-3'
                    onChange={() => setFieldValue('precisouIrAoProntoAtendimentoOuHospital', true)}
                    tabIndex={6}
                  />
                  <Radio
                    nome='precisouIrAoProntoAtendimentoOuHospital'
                    titulo='Não'
                    valor={false}
                    onChange={() => setFieldValue('precisouIrAoProntoAtendimentoOuHospital', false)}
                    tabIndex={7}
                  />
                </div>
                {(errors.precisouIrAoProntoAtendimentoOuHospital && touched.precisouIrAoProntoAtendimentoOuHospital) ?
                  <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <label className='form-label'>Procurou Algum Médico? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='procurouAlgumMedico'
                    titulo='Sim'
                    classname='mr-3'
                    onChange={() => setFieldValue('procurouAlgumMedico', true)}
                    valor={true}
                    tabIndex={8}
                  />
                  <Radio
                    nome='procurouAlgumMedico'
                    titulo='Não'
                    onChange={() => setFieldValue('procurouAlgumMedico', false)}
                    valor={false}
                    tabIndex={9}
                  />
                </div>
                {(errors.procurouAlgumMedico && touched.procurouAlgumMedico) ?
                  <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  titulo='O que estava fazendo no momento da queda?'
                  nome='oQueEstavaFazendoNoMomentoDaQueda'
                  tabIndex={10}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
              <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}