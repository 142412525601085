import React from 'react'
import ReactDatePicker from 'react-datepicker'
import CampoDaTarefa from './campo-da-tarefa'
import { ComponenteLeitura } from '../card'
import { CalendarioComRelogio } from '../../../design/icones'
import {
  formatarSegundosENanosegundosParaFormatoLocal,
  formatarSegundosENanosegundosParaMoment
} from '../../../bibliotecas/data'

export default function Momento({ rotulo, obrigatorio, valor, leitura, definir }) {
  const icone = <CalendarioComRelogio />

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        valor={formatarSegundosENanosegundosParaFormatoLocal(valor)}
      />
    )
  }

  const valorFormatado = valor && formatarSegundosENanosegundosParaMoment(valor).isValid() ? formatarSegundosENanosegundosParaMoment(valor).toDate() : valor

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valorFormatado || ''}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <ReactDatePicker
          value={valor}
          selected={valor}
          onChange={onChange}
          onFocus={onFocus}
          onSelect={onBlur}
          onCalendarClose={onBlur}
          showYearDropdown
          dateFormatCalendar='MMMM'
          dateFormat='dd/MM/yyyy, HH:mm'
          showTimeSelect
          timeCaption='Hora'
          timeFormat='HH:mm'
          timeIntervals={5}
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          wrapperClassName='w-100'
        />
      }
    </CampoDaTarefa>
  )
}