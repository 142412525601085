import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
} from '../tipos'

const listouCidadesParaOPedidoDeContato = cidades => ({
  type: LISTOU_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  cidades
})

const erroAoListarCidadesParaOPedidoDeContato = erro => ({
  type: ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  erro
})

export const listarCidadesParaOPedidoDeContato = () => async dispatch => {
  dispatch({ type: LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO })

  try {
    const resultado = await api.listarCidadesParaOPedidoDeContato()

    dispatch(listouCidadesParaOPedidoDeContato(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarCidadesParaOPedidoDeContato(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as cidades para o pedido de contato.')))

    return false
  }
}