import React from 'react'
import { useParams } from 'react-router-dom'
import constantes from '../../configuracoes/constantes'

export default function TeleconsultaAsclepius() {
  const { identificadorDaEmpresa, identificadorDaUnidade, identificadorDoAgendamento, identificadorDaTeleconsulta, sujeito } = useParams()

  const URL = `${constantes.urlDaApiAsclepius}/${sujeito}?empresa=${identificadorDaEmpresa}&unidade=${identificadorDaUnidade}&agendamento=${identificadorDoAgendamento}&teleconsulta=${identificadorDaTeleconsulta}`

  return (
    <iframe
      src={URL}
      style={{ width: '100vw', height: '100vh', border: 'none' }}
      title='Teleconsulta'
      allow="camera;microphone"
    />
  )
}