import api from '../../api'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import notificacao from '../../bibliotecas/notificacao'
import {
  ALTERAR_FILTROS_DO_TAREFAS,
  ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS,
  DEFINIR_ABA_ATUAL,
  DEFINIR_DASHBOARD_DA_TAREFA_ATUAL,
  DEFINIR_TIPO_DA_TAREFA_ATUAL,
  ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS
} from '../tipos'

export const definirTipoDaTarefaAtual = identificador => async dispatch => {
  dispatch({ type: DEFINIR_TIPO_DA_TAREFA_ATUAL, identificador })
}

export const definirDashboardDaTarefaAtual = dashboard => async dispatch => {
  dispatch({ type: DEFINIR_DASHBOARD_DA_TAREFA_ATUAL, dashboard })
}

export const definirAbaAtual = (aba, identificadorDoTipo) => async dispatch => {
  dispatch({ type: DEFINIR_ABA_ATUAL, aba, identificadorDoTipo })
}

export const alterarTermoDoFiltroDePacientesDoTarefas = (termo, identificadorDoTipo) => async dispatch => {
  dispatch({ type: ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS, termo, identificadorDoTipo })
}

export const alterarFiltrosDoTarefas = (filtrosAtuais, identificadorDoTipo) => async dispatch => {
  dispatch({ type: ALTERAR_FILTROS_DO_TAREFAS, filtrosAtuais, identificadorDoTipo })
}

const listouPacientesDosFiltrosDoTarefas = (pacientes, identificadorDoTipo) => ({
  type: LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  pacientes,
  identificadorDoTipo
})

const erroAoListarPacientesDosFiltrosDoTarefas = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  erro,
  parametros
})

export const listarPacientesDosFiltrosDoTarefas = (filtros, pagina, ordem, tamanhoDaPagina, identificadorDoTipo) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS })

    try {
      const retorno = await api.listarPacientesDoTarefas(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouPacientesDosFiltrosDoTarefas(retorno.data.dados, identificadorDoTipo))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarPacientesDosFiltrosDoTarefas(erro, { filtros, pagina, ordem, tamanhoDaPagina, identificadorDoTipo }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes do tarefas.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
    }
  }

  return resultado
}