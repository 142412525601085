import api from '../api-major'

const listarProximosAgendamentosMedicosDoPaciente = (paciente) => {
  const parametroOrdem = '&ordem=+inicio'

  return api.get(`pacientes/${paciente}/agendamentos/medicos/proximos?tamanhoDaPagina=1000${parametroOrdem}`)
}

export default {
  listarProximosAgendamentosMedicosDoPaciente
}