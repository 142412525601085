import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ESTADOS_CIVIS,
  LISTOU_ESTADOS_CIVIS,
  ERRO_AO_LISTAR_ESTADOS_CIVIS
} from './tipos'

const listouEstadosCivis = estadosCivis => ({
  type: LISTOU_ESTADOS_CIVIS,
  estadosCivis
})

const erroAoListarEstadosCivis = erro => ({
  type: ERRO_AO_LISTAR_ESTADOS_CIVIS,
  erro
})

export const listarEstadosCivis = () => async dispatch => {
  dispatch({ type: LISTAR_ESTADOS_CIVIS })

  try {
    const resultado = await api.listarEstadosCivis()

    dispatch(listouEstadosCivis(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEstadosCivis(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estados civis.')))
  }
}