import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Formulario as Form, Input, Select, Telefone } from '../../formik/formulario'
import {
  ADICIONAR_AGENDAMENTO,
  ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  LISTAR_PACIENTES_DO_AGENDAMENTO,
  RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  SALVAR_AGENDAMENTO,
} from '../../../acoes/tipos'

export default function Formulario(props) {
  const {
    adicionar,
    alterar,
    editar,
    remover,
    agendar,
    fechar,
    limparDadosDoPaciente,
    pacientes,
    pesquisarPacientes,
    selecionarPaciente,
    valoresIniciais,
    emEspera = []
  } = props

  const [valor, setValor] = useState('')

  const selecionarPacienteDaLista = async (identificador, setFieldValue) => {
    if (identificador) {
      const paciente = pacientes.find(x => x.identificador === identificador)
      selecionarPaciente(paciente)

    } else {
      setFieldValue('telefone1', '')
      setFieldValue('telefone2', '')

      setValor('')
      limparDadosDoPaciente()
    }
  }

  const renderizarItemAutoCompletePaciente = ({ value, label, dataDeNascimento, medicoDeReferencia, unidadePreferencial }) => {
    function extrairNomeResumido(nome) {
      if (!nome) return ''
      if (nome.length <= 35) return nome

      const nomes = nome.split(' ')

      if (nomes.length === 1) {
        return nomes[0]
      }

      const primeiroNome = nomes[0]
      const segundoNome = nomes.length > 2 ? nomes[1] : ''
      const ultimoNome = nomes[nomes.length - 1]

      return `${primeiroNome} ${segundoNome} ${ultimoNome}`
    }

    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          <div className='data-de-nascimento'>
            <span>Nasc.: {moment(dataDeNascimento).format('DD/MM/YYYY')}</span>
          </div>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${medicoDeReferencia ? 'possui-medico' : ''}`}>
          {medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Médico(a): {extrairNomeResumido(medicoDeReferencia.nome)}</span>
            </div>
          }
          {!medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Não possui médico de referência.</span>
            </div>
          }
          <div className='unidade-do-paciente'>
            {unidadePreferencial &&
              <span>Unidade: {unidadePreferencial.nome}</span>
            }
          </div>
        </div>
      </div>
    )
  }

  const pesquisarPacientesAutocomplete = async termo => {
    setValor(termo)
    if (!termo || termo.length <= 2) return

    await pesquisarPacientes(termo)
  }

  const optionsPaciente = pacientes.map(x => ({
    value: x.identificador,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento,
    medicoDeReferencia: x.medicoDeReferencia,
    unidadePreferencial: x.unidadePreferencial
  }))

  const divFormulario = document.getElementById('adicionar-paciente')

  if (divFormulario) {
    const heightFormulario = divFormulario.getBoundingClientRect().height.toString() + 'px';
    document.getElementById('lista-de-pacientes').style.height = heightFormulario;
  }

  return (
    <>
      <Spinner operacoes={[LISTAR_PACIENTES_DO_AGENDAMENTO]}>
        {({ processando }) => (
          <Spinner operacoes={[
            ADICIONAR_AGENDAMENTO,
            SALVAR_AGENDAMENTO,
            RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
            ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
            ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
            REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
            ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA
          ]}>
            <Form
              reinicializar={true}
              valoresIniciais={{
                identificador: valoresIniciais.identificador || '',
                paciente: valoresIniciais.paciente.identificador || '',
                telefone1: valoresIniciais.telefone1 || '',
                telefone2: valoresIniciais.telefone2 || '',
                observacoes: valoresIniciais.observacoes || '',
              }}
              acao={valoresIniciais.posicaoNaListaDeEspera ? alterar : adicionar}
              esquemaDeValidacoes={Yup.object().shape({
                paciente: Yup.string().required('Obrigatório'),
                telefone1: Yup.string()
                  .when("telefone2", {
                    is: val => val === '' || val === undefined,
                    then: Yup.string().required('Obrigatório.')
                  })
                  .nullable()
                  .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
                telefone2: Yup.string()
                  .nullable()
                  .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
              })}
            >
              {({ setFieldValue }) => (
                <>
                  <fieldset>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={3} span-md={5} span-lg={11}>
                        <h2 className='form-title'>Lista de Espera</h2>
                      </r-cell>
                      <r-cell span={1} span-md={1} span-lg={1} class='flex align-items-start'>
                        <button type='button' className='text-right text-dark w-100' onClick={fechar}>
                          <i className='icon icon-close'></i>
                        </button>
                      </r-cell>
                    </r-grid>
                    <div className='lista-de-espera'>
                      <r-grid columns-md={6} columns-lg={12} class='divider'>
                        <r-cell span={4} span-md={6} span-lg={12} id='lista-de-pacientes'>
                          <div className='lista-de-espera__pacientes custom-scrollbar'>
                            <div className='my-2 mx-2'>
                              <div>
                                {emEspera && emEspera.map((x, index) =>
                                  <div
                                    className='lista-de-espera__pacientes__item'
                                    key={x.identificador}
                                  >
                                    <div> 
                                      <div className={`lista-de-espera__pacientes__indicator ${index + 1 > 9 && 'lista-de-espera__pacientes__indicator-duplo-digito'}`}>{index + 1}</div>
                                      <div className='lista-de-espera__pacientes__info mtp-5'>
                                        <i
                                          className='icon-info'
                                          data-tip
                                          data-for={`info_icone_${x.identificador}`}
                                          onMouseOver={() => ReactTooltip.rebuild()}
                                          style={{ marginTop: 0 }}
                                        >
                                        </i>
                                      </div>
                                    </div>
                                    <div className='lista-de-espera__pacientes__item__dados'>
                                      <div>
                                        <p className='lista-de-espera__pacientes__item__nome' title={x.paciente.nome}>{x.paciente.nome}</p>
                                        <p className='lista-de-espera__pacientes__item__data'>{x.telefone1 && x.telefone2 ? x.telefone1 + ' • ' + x.telefone2 : x.telefone1 ? x.telefone1 : x.telefone2}</p>
                                        {x.observacoes && <pre className='lista-de-espera__pacientes__item__data'>{x.observacoes}</pre>}
                                      </div>
                                      <div className='lista-de-espera__pacientes__item__acoes'>
                                        <button type='button' className='button --danger-light' disabled={valoresIniciais.posicaoNaListaDeEspera} onClick={() => remover(x)}><i title='Remover da lista' className='icon icon-remove' /></button>
                                        <button type='button' className='button --light' disabled={valoresIniciais.posicaoNaListaDeEspera} onClick={() => editar(x, index)}><i title='Alterar da lista' className='icon icon-edit' /></button>
                                        <button type='button' className='button --light' disabled={valoresIniciais.posicaoNaListaDeEspera || processando} onClick={() => agendar(x)}>Agendar</button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </r-cell>
                      </r-grid>
                      <div>
                        <div id='adicionar-paciente'>
                          <h3 className='subtitle-lista-de-espera'>{valoresIniciais.posicaoNaListaDeEspera ? 'Alterar da lista' : 'Adicionar à lista'}</h3>
                          <r-grid columns-md={6} columns-lg={12}>
                            <r-cell span={4} span-md={6} span-lg={12}>
                              {valoresIniciais.posicaoNaListaDeEspera &&
                                <div className='lista-de-espera__pacientes__item' style={{ padding: '0px' }}>
                                  <div className={`lista-de-espera__pacientes__indicator ${valoresIniciais.posicaoNaListaDeEspera > 9 && 'lista-de-espera__pacientes__indicator-duplo-digito'}`}>{valoresIniciais.posicaoNaListaDeEspera}</div>
                                  <div className='lista-de-espera__pacientes__item__dados'>
                                    <p className='lista-de-espera__pacientes__item__nome'>{valoresIniciais.paciente.nome}</p>
                                  </div>
                                </div>
                              }
                              {!valoresIniciais.posicaoNaListaDeEspera &&
                                <Select
                                  nome='paciente'
                                  tabIndex={1}
                                  titulo='Paciente *'
                                  itens={pacientes}
                                  campoCodigo='identificador'
                                  campoDescricao='nome'
                                  formatOptionLabel={renderizarItemAutoCompletePaciente}
                                  onChange={v => selecionarPacienteDaLista(v, setFieldValue)}
                                  onInputChange={pesquisarPacientesAutocomplete}
                                  options={optionsPaciente}
                                  isLoading={processando}
                                  inputValue={valoresIniciais.paciente.identificador ? '' : valor}
                                  filterOption={() => true}
                                  onFocus={() => pesquisarPacientesAutocomplete(valor)}
                                />
                              }
                            </r-cell>
                            <r-cell span={2} span-md={3} span-lg={6}>
                              <Telefone
                                type='text'
                                nome='telefone1'
                                tabIndex={2}
                                titulo='Telefone 1*'
                              />
                            </r-cell>
                            <r-cell span={2} span-md={3} span-lg={6}>
                              <Telefone
                                type='text'
                                nome='telefone2'
                                tabIndex={3}
                                titulo='Telefone 2'
                              />
                            </r-cell>
                            <r-cell span={4} span-md='row' span-lg='row'>
                              <Input
                                as='textarea'
                                nome='observacoes'
                                tabIndex={4}
                                titulo='Observações'
                              />
                            </r-cell>
                          </r-grid>
                          <div className='list-btn justify-content-end'>
                            {!valoresIniciais.posicaoNaListaDeEspera && <button type='submit' className='button --primary'>Adicionar Paciente</button>}
                            {valoresIniciais.posicaoNaListaDeEspera &&
                              <>
                                <button onClick={() => editar()} className='button --light'>Cancelar</button>
                                <button type='submit' className='button --primary'>Alterar Paciente</button>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </>
              )}
            </Form>
          </Spinner>
        )}
      </Spinner>
    </>
  )
}