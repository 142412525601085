import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_INTENSIDADES_DAS_DORES_DA_TRIAGEM,
  LISTOU_INTENSIDADES_DAS_DORES_DA_TRIAGEM,
  ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES_DA_TRIAGEM
} from '../tipos'

const listouIntensidadesDasDoresDaTriagem = intensidadesDasDores => ({
  type: LISTOU_INTENSIDADES_DAS_DORES_DA_TRIAGEM,
  intensidadesDasDores
})

const erroAoListarIntensidadesDasDoresDaTriagem = erro => ({
  type: ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES_DA_TRIAGEM,
  erro
})

export const listarIntensidadesDasDoresDaTriagem = () => async dispatch => {
  dispatch({ type: LISTAR_INTENSIDADES_DAS_DORES_DA_TRIAGEM })

  try {
    const resultado = await api.listarIntensidadesDasDoresDaTriagem()

    dispatch(listouIntensidadesDasDoresDaTriagem(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarIntensidadesDasDoresDaTriagem(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as intensidades das dores da triagem.')))
  }
}
