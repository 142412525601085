import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS,
  LISTOU_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS,
  ERRO_AO_LISTAR_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS
} from '../tipos'

const listouCondicoesRelacionadasAosEstadosHabituais = condicoesRelacionadas => ({
  type: LISTOU_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS,
  condicoesRelacionadas
})

const erroAoListarCondicoesRelacionadasAosEstadosHabituais = erro => ({
  type: ERRO_AO_LISTAR_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS,
  erro
})

export const listarCondicoesRelacionadasAosEstadosHabituais = () => async dispatch => {
  dispatch({ type: LISTAR_CONDICOES_RELACIONADAS_AOS_ESTADOS_HABITUAIS })

  try {
    const resultado = await api.listarCondicoesRelacionadasAosEstadosHabituais()

    dispatch(listouCondicoesRelacionadasAosEstadosHabituais(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCondicoesRelacionadasAosEstadosHabituais(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as condições relacionadas aos estados habituais.')))
  }
}
