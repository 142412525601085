import {
  ALTEROU_CONFIGURACAO_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO
} from '../../acoes/tipos'

const estadoInicial = {
  configuracoes: {
    agenda: []
  },
  configuracoesVigentes: {
    agenda: []
  }
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_CONFIGURACOES_DO_USUARIO: {
      return {
        ...state,
        configuracoes: {
          ...action.configuracoes
        },
      }
    }

    case RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO: {
      return {
        ...state,
        configuracoesVigentes: {
          ...action.configuracoesVigentes
        },
      }
    }

    case ALTEROU_CONFIGURACAO_DO_USUARIO: {
      return {
        ...state,
        ...action.configuracoes,
      }
    }

    default: {
      return state
    }
  }
}