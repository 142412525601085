import { useEffect, useState } from 'react'
import moment from 'moment'

export default function TempoDoAtendimento({ inicio }) {
  const calcularTempo = () => moment.utc(moment(new Date()).diff(moment(inicio))).format('HH:mm:ss')
  const [tempo, setTempo] = useState(calcularTempo)

  useEffect(() => {
    const identificador = setInterval(() => setTempo(calcularTempo), 1000)
    return () => clearInterval(identificador)
  })

  return tempo
}