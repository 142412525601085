import acoes from '../../../acoes'
import { connect } from 'react-redux'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import NovoAgendamento from './index'
import {ordenarAlfabeticamente} from '../../../bibliotecas/ordenacao'

const recuperarFotosDosProfissionais = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const recuperarFotosDosPacientes = (dados = [], imagens = {}) => {
  let fotos = {}
  
  if (dados.foto) {
    fotos[dados.foto] = imagens[dados.foto]
  }

  return fotos
}

const mapStateToProps = state => {
  let convenios = Object.keys(state.agendamentos.wizard.passo1.convenios).map(key => state.agendamentos.wizard.passo1.convenios[key])
    .map(x => ({ identificador: x.identificador, descricao: `${x.numeroDaCarteira} - ${x.operadora}`, operadora: x.operadora }))

  convenios.push({ identificador: 'naoCadastrado', descricao: 'Não Cadastrado', operadora: '' })
  convenios.push({ identificador: 'particular', descricao: 'Particular', operadora: '' })

  function ordenarHorarios(a, b) {
    if (a.data < b.data)
      return -1

    if (a.data > b.data)
      return 1

    return 0
  }

  return {
    agendamentosRealizados: converterObjetoParaArray(state.agendamentos.wizard.passo3.agendamentosRealizados),
    convenios,
    dataBaseHorariosLivres: state.agendamentos.wizard.passo3.dataBaseHorariosLivres,
    filtros: state.agendamentos.wizard.passo2.filtros,
    fotosDosPacientes: recuperarFotosDosPacientes(state.agendamentos.wizard.passo1.paciente, state.imagens),
    fotosDosProfissionais: recuperarFotosDosProfissionais(state.agendamentos.wizard.passo2.profissionaisDeSaude, state.imagens),
    grausDeRelacoes: state.grausDeRelacoes,
    horariosLivres: converterObjetoParaArray(state.agendamentos.wizard.passo3.horariosLivres).sort(ordenarHorarios),
    ordem: state.agendamentos.wizard.pacientes.ordem,
    pacientes: state.agendamentos.wizard.pacientes.pacientes,
    paciente: state.agendamentos.wizard.passo1.paciente,
    pacienteSemPrograma: state.agendamentos.wizard.passo3.semPrograma,
    profissoes: state.agendamentos.wizard.passo2.profissoes,
    profissionaisDeSaude: state.agendamentos.wizard.passo2.profissionaisDeSaude,
    proprietariosDeTelefones: state.proprietariosDeTelefones,
    proximosAgendamentos: state.agendamentos.wizard.passo3.proximosAgendamentos,
    recorrenciasDoAgendamento: state.agendamentos.recorrencias,
    sexos: state.sexos,
    tiposDeAgendamento: (state.agendamentos.wizard.passo2.tiposDeAgendamento).sort(ordenarAlfabeticamente),
    unidades: state.agendamentos.wizard.passo3.unidades
  }
}

export default connect(mapStateToProps, acoes)(NovoAgendamento)