import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_MOMENTO_DA_TAREFA,
  DEFINIU_MOMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA
} from '../tipos'

const definiuMomentoDaTarefa = (identificadorDaTarefa, campo, conteudo) => ({
  type: DEFINIU_MOMENTO_DA_TAREFA,
  identificadorDaTarefa,
  campo,
  conteudo,
})

const erroAoDefinirMomentoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA,
  erro,
  parametros,
})

export const definirMomentoDaTarefa = (identificadorDaTarefa, campo, conteudo) => async dispatch => {
  dispatch({ type: DEFINIR_MOMENTO_DA_TAREFA })

  try {
    const resultado = await api.definirMomentoDaTarefa(identificadorDaTarefa, campo, conteudo)

    dispatch(definiuMomentoDaTarefa(identificadorDaTarefa, campo, conteudo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirMomentoDaTarefa(erro, { identificadorDaTarefa, campo, conteudo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o momento da tarefa.')))

    return false
  }
}