import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import {useProntuario} from '../../../contexto'
import {AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela} from '../../../../tabela'
import {formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal} from '../../../../../bibliotecas/data'
import Permissao, {usePossuiAsPermissoes} from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Spinner from '../../../../spinner'
import moment from 'moment'
import Formulario from './formulario'
import Resumo from './resumo'

import {
  LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE
} from '../../../../../acoes/tipos'

export default function AvaliacaoDaSolidao({ identificadorDoPaciente, avaliacoesDaSolidaoDoPaciente }) {
  const {
    listarFrequenciasNaEscalaDaSolidao,
    frequenciasNaEscalaDaSolidao,
    listarAvaliacoesDaSolidao,
    adicionarAvaliacaoDaSolidao,
    alterarAvaliacaoDaSolidao,
    removerAvaliacaoDaSolidao
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE])

  useEffect(() => {
    if (avaliacoesDaSolidaoDoPaciente === undefined) {
      listarAvaliacoesDaSolidao(identificadorDoPaciente)
    }
  }, [listarAvaliacoesDaSolidao, identificadorDoPaciente, avaliacoesDaSolidaoDoPaciente])

  useEffect(() => { listarFrequenciasNaEscalaDaSolidao() }, [listarFrequenciasNaEscalaDaSolidao])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirResumo(item) {
    setSelecionada(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarAvaliacaoDaSolidao(identificadorDoPaciente, item) : await adicionarAvaliacaoDaSolidao(identificadorDoPaciente, item)
  }

  if (avaliacoesDaSolidaoDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário da Avaliação da Solidão'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          valores={recuperarValorInicialAvaliacaoDaSolidao(selecionada)}
          frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo da Avaliação da Solidão'
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperarValorInicialAvaliacaoDaSolidao(selecionada)}
          frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção da Avaliação da Solidão'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerAvaliacaoDaSolidao(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  (
                    <button
                      className='table-items-expanded__bt --edit'
                      title='Ver resumo'
                      onClick={() => abrirResumo(item)}
                      type='button'
                      key={`questionario_${item.identificador}`}
                    >
                      <i className='icon icon-doc-text'></i>
                    </button>
                  )
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={avaliacoesDaSolidaoDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros de Avaliação da Solidão.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Total'
                campoDeDados='total'
                renderizar={item => item.resultado > 0 ? item.resultado : '0'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-12'>
                <AgrupamentoDeColunas>
                  <Coluna
                    nome='DATA'
                    renderizar={item => formatarDataParaFormatoLocal(item.data)}
                  />
                  <Coluna
                    nome='FALTA DE COMPANHIA'
                    campoDeDados='faltaDeCompanhia'
                  />
                  <Coluna
                    nome='DEIXADO DE LADO'
                    campoDeDados='deixadoDeLado'
                  />
                  <Coluna
                    nome='ISOLADO DAS OUTRAS PESSOAS'
                    campoDeDados='isoladoDasOutrasPessoas'
                  />
                  <Coluna
                    nome='TOTAL'
                    renderizar={item => item.resultado > 0 ? item.resultado : '0'}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirAvaliacao = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a Avaliação da Solidão?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirAvaliacao}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperarValorInicialAvaliacaoDaSolidao = selecionada => {
  if (selecionada) {
    return {
      ...selecionada
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    faltaDeCompanhia: undefined,
    deixadoDeLado: undefined,
    isoladoDasOutrasPessoas: undefined,
    resultado: '',
  }
}