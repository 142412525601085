import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Associacoes(props) {
  const {
    adicionarAssociacao,
    associacao,
    filtros,
    history,
    listarAssociacoesDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarAssociacaoPeloIdentificador,
    removerAssociacao,
    alterarAssociacao
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
    }

    const acao = dados.identificador ? alterarAssociacao : adicionarAssociacao
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/associacoes`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_ASSOCIACOES]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarAssociacoesDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_ASSOCIACAO]}>
              <Formulario
                {...props}
                salvar={salvar}
                associacao={associacao}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_ASSOCIACAO]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarAssociacaoPeloIdentificador}
                salvar={salvar}
                associacao={associacao}
                remover={removerAssociacao}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}