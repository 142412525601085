import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'
import { listarProximosAgendamentosMedicosDoPaciente } from '../../../acoes/agendamentos/proximos-agendamentos-medicos-do-paciente'
import {usePossuiAsPermissoes} from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import './style.css'

export default function ProximosAgendamentos({ paciente }) {

  const dispatch = useDispatch()
  const [proximosAgendamentos, setProximosAgendamentos] = useState([])
  
  const podeListarAgendamentos = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE])

  useEffect(() => {
    (async () => {
      if (paciente && podeListarAgendamentos) {
        const agendamentos = await dispatch(listarProximosAgendamentosMedicosDoPaciente(paciente))
        setProximosAgendamentos(agendamentos)
      }

    })()
  }, [dispatch, paciente, podeListarAgendamentos])

  function formatarDiaDaSemana(dia) {
    return dia.charAt(0).toUpperCase() + dia.slice(1)
  }

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia).format('dddd'))
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  if (!proximosAgendamentos || proximosAgendamentos.length === 0) {
    return null
  }

  return (
    <>
      <div className='proximo-agendamento-item'>
        <h2><strong>{proximosAgendamentos.length > 1 ? 'Próximos agendamentos médicos' : 'Próximo agendamento médico'}:</strong></h2>
      </div>
      {proximosAgendamentos.map((agendamento, index) => (
        <div className='proximo-agendamento-item' key={index}>
          <p>
            <i className='icon icon-doctor'></i>{agendamento.profissional.tratamento ? agendamento.profissional.tratamento : ''} {agendamento.profissional.nome}
          </p>
          <p>
            <i className='icon icon-card'></i>{agendamento.tipo.nome}
          </p>
          <p>
            <i className='icon icon-calendar-empty'></i>{diaDaSemana(agendamento.inicio)}
          </p>
          <p>
            <i className='icon icon-clock'></i>{formatarHoraParaFormatoLocal(agendamento.inicio)}
          </p>
        </div>
      ))}
    </>
  )
}