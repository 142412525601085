import React from 'react'
import * as Yup from 'yup'
import { Formulario as FormularioDoFormik, Input } from '../../formik/formulario'
import { ADICIONAR_TEMPLATE_DO_PRONTUARIO } from '../../../acoes/tipos'
import Spinner from '../../spinner'

export default function AdicionarTemplate(props) {
  const { componente, template, fechar } = props

  const salvar = async formulario => {
    let dados = {
      ...formulario,
      template: template,
      componente: componente,
      compartilhado: false,
    }

    const executou = await props.salvar(dados)

    if (executou) {
      fechar()
    }

  }

  return (
    <>
      <Spinner operacoes={[ADICIONAR_TEMPLATE_DO_PRONTUARIO]}>
        <FormularioDoFormik
          valoresIniciais={{
            nome: ''
          }}
          esquemaDeValidacoes={Yup.object().shape({
            nome: Yup.string().required('Obrigatório.'),
          })}
          acao={salvar}
        >
          <fieldset>
            <h2 className='form-title'>Adicionar Template</h2>
            <div className='form-choice-alerta'>
              <div className='form-choice-alerta__item'><i className='icon icon-attention-circled'></i></div>
              <div className='form-choice-alerta__item'>
                Os Tokens não serão salvos neste template.<br />
                Para utilização de Tokens, acessar o menu Configurações / Templates.
              </div>
            </div>
            <Input
              nome='nome'
              tabIndex={1}
              titulo='Nome *'
            />
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
              <button type='submit' className='button --primary'>Adicionar</button>
            </div>
          </fieldset>
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}