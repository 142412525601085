import React, { useState, useEffect } from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, DatePicker, Input, Select, Radio } from '../../../formik/formulario'
import { FieldArray, ErrorMessage, Field } from 'formik'
import { filtroPadrao } from '../../../../bibliotecas/texto'
import StylesSelect from '../../../formik/select'
import * as Yup from 'yup'

import { ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE, ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE, LISTAR_MICRORGANISMOS, LISTAR_ANTIBIOTICOS } from '../../../../acoes/tipos'

export default function Formulario({
  fechar,
  microrganismos,
  antibioticos,
  selecionada,
  ...rest
}) {

  const microrganismosDaInfeccaoSelecionada = selecionada ? selecionada.microrganismos.length > 0 ? selecionada.microrganismos.map(x => ({
      microrganismo: x.microrganismo.nome,
      antibioticosSensiveis: x.antibioticos.filter(y => y.sensibilidade === 'Sensível'),
      antibioticosResistentes: x.antibioticos.filter(y => y.sensibilidade === 'Resistente'),
      antibioticosResistenciaIntermediaria: x.antibioticos.filter(y => y.sensibilidade === 'Resistência Intermediária')
    })) : [{
      microrganismo: '',
      antibioticosSensiveis: [],
      antibioticosResistentes: [],
      antibioticosResistenciaIntermediaria: [],
    }] : [] 
  
  const salvar = async (valores, { resetForm }) => {
    const formatarAntibioticos = index => {
      const antibioticosSensiveis = valores.microrganismos[index].antibioticosSensiveis.length > 0 ?
        valores.microrganismos[index].antibioticosSensiveis.map(x => ({ antibiotico: x.antibiotico.nome, sensibilidade: 'Sensível' })) : []

      const antibioticosResistentes = valores.microrganismos[index].antibioticosResistentes.length > 0 ?
        valores.microrganismos[index].antibioticosResistentes.map(x => ({ antibiotico: x.antibiotico.nome, sensibilidade: 'Resistente' })) : []

      const antibioticosResistenciaIntermediaria = valores.microrganismos[index].antibioticosResistenciaIntermediaria.length > 0 ?
        valores.microrganismos[index].antibioticosResistenciaIntermediaria.map(x => ({ antibiotico: x.antibiotico.nome, sensibilidade: 'Resistência Intermediária' })) : []

      return [].concat(antibioticosSensiveis, antibioticosResistentes, antibioticosResistenciaIntermediaria)
    }

    const dados = {
      ...selecionada,
      ...valores,
      microrganismos: valores.temResultadoDeUrocultura ? valores.microrganismos.map((x, index) => ({ microrganismo: x.microrganismo, antibioticos: formatarAntibioticos(index) })) : []
    }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const adicionarAntibioticoSensivel = (arrayHelpers, values, index, setFieldValue) => {
    arrayHelpers.push({ antibiotico: { nome: values.microrganismos[index].antibioticoSensivel }, sensibilidade: 'Sensível' })
    setFieldValue(`microrganismos.${index}.antibioticoSensivel`, '')
  }

  const adicionarAntibioticResistente = (arrayHelpers, values, index, setFieldValue) => {
    arrayHelpers.push({ antibiotico: { nome: values.microrganismos[index].antibioticoResistente }, sensibilidade: 'Resistente' })
    setFieldValue(`microrganismos.${index}.antibioticoResistente`, '')
  }

  const adicionarAntibioticoResistenciaIntermediaria = (arrayHelpers, values, index, setFieldValue) => {
    arrayHelpers.push({ antibiotico: { nome: values.microrganismos[index].antibioticoResistenciaIntermediaria }, sensibilidade: 'Resistência Intermerdiária' })
    setFieldValue(`microrganismos.${index}.antibioticoResistenciaIntermediaria`, '')
  }

  const renderizarMicrorganismos = (index, values, remove, setFieldValue) => {
    const removerAntibioticosAdicionados = (antibioticosAdicionados = [], antibioticos = []) => {
      if (antibioticosAdicionados.length === 0) return antibioticos

      const filtro = antibiotico => {
        return antibioticosAdicionados.findIndex(x => x.antibiotico.nome === antibiotico.nome) === -1
      }

      return antibioticos.filter(filtro)
    }

    const antibioticosAdicionados = values.microrganismos[index].antibioticosSensiveis.concat(values.microrganismos[index].antibioticosResistentes).concat(values.microrganismos[index].antibioticosResistenciaIntermediaria)
    const antibioticosFiltrados = removerAntibioticosAdicionados(antibioticosAdicionados, antibioticos)

    return (
      <r-grid columns-md={12} columns-lg={12} class='mt-3 row-gap-0' key={index}>
        {values.microrganismos.length > 1 &&
          <r-cell span={4} span-md={12} span-lg={12}>
            <hr className='separator'></hr>
            <div className='form-choice mt-2'>
              <button className='form-choice__bt-close' aria-label='Remover' onClick={() => remove(index)} type='button'><i className='icon icon-close'></i></button>
            </div>
          </r-cell>
        }
        <r-cell span={4} span-md={12} span-lg={12}>
          <SelectMicrorganismos
            nome={`microrganismos.${index}.microrganismo`}
            titulo='Microrganismos *'
            itens={microrganismos}
            campoCodigo='nome'
            campoDescricao='nome'
          />
        </r-cell>
        <r-cell span={4} span-md={12} span-lg={12} class='mt-24'>
          <h4 className='form-title'>Antibióticos:</h4>
        </r-cell>
        <r-cell span={4} span-md={12} span-lg={4}>
          <FieldArray
            name={`microrganismos.${index}.antibioticosSensiveis`}
            render={arrayHelpers => (
              <>
                <div className='grid-antibioticos'>
                  <Select
                    nome={`microrganismos.${index}.antibioticoSensivel`}
                    titulo='Sensíveis'
                    itens={antibioticosFiltrados}
                    isDisabled={!values.microrganismos[index].microrganismo}
                    campoCodigo='nome'
                    campoDescricao='nome'
                  />
                  <button
                    type='button'
                    className='button --plus-short button-add-antibioticos'
                    disabled={!values.microrganismos[index].antibioticoSensivel}
                    onClick={() => adicionarAntibioticoSensivel(arrayHelpers, values, index, setFieldValue)}
                  >
                    <i className='icon icon-plus'></i>
                  </button>
                </div>
                {values.microrganismos[index].antibioticosSensiveis && values.microrganismos[index].antibioticosSensiveis.length > 0 &&
                  <div className='mt-8'>
                    {values.microrganismos[index].antibioticosSensiveis.map((antibioticoSensivel, index) =>
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item --full'>{antibioticoSensivel.antibiotico.nome}</div>
                        <button className='form-choice__bt-close' aria-label='Remover' onClick={() => arrayHelpers.remove(index)} type='button'><i className='icon icon-close'></i></button>
                      </div>
                    )}
                  </div>
                }
              </>
            )}
          />
        </r-cell>
        <r-cell span={4} span-md={12} span-lg={4}>
          <FieldArray
            name={`microrganismos.${index}.antibioticosResistentes`}
            render={arrayHelpers => (
              <>
                <div className='grid-antibioticos'>
                  <Select
                    nome={`microrganismos.${index}.antibioticoResistente`}
                    titulo='Resistentes'
                    itens={antibioticosFiltrados}
                    isDisabled={!values.microrganismos[index].microrganismo}
                    campoCodigo='nome'
                    campoDescricao='nome'
                  />
                  <button
                    type='button'
                    className='button --plus-short button-add-antibioticos'
                    disabled={!values.microrganismos[index].antibioticoResistente}
                    onClick={() => adicionarAntibioticResistente(arrayHelpers, values, index, setFieldValue)}
                  >
                    <i className='icon icon-plus'></i>
                  </button>
                </div>
                {values.microrganismos[index].antibioticosResistentes && values.microrganismos[index].antibioticosResistentes.length > 0 &&
                  <div className='mt-8'>
                    {values.microrganismos[index].antibioticosResistentes.map((antibioticoResistente, index) =>
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item --full'>{antibioticoResistente.antibiotico.nome}</div>
                        <button className='form-choice__bt-close' aria-label='Remover' type='button' onClick={() => arrayHelpers.remove(index)}><i className='icon icon-close'></i></button>
                      </div>
                    )}
                  </div>
                }
              </>
            )}
          />
        </r-cell>
        <r-cell span={4} span-md={12} span-lg={4}>
          <FieldArray
            name={`microrganismos.${index}.antibioticosResistenciaIntermediaria`}
            render={arrayHelpers => (
              <>
                <div className='grid-antibioticos'>
                  <Select
                    nome={`microrganismos.${index}.antibioticoResistenciaIntermediaria`}
                    titulo='Resistência Intermediária'
                    itens={antibioticosFiltrados}
                    isDisabled={!values.microrganismos[index].microrganismo}
                    campoCodigo='nome'
                    campoDescricao='nome'
                  />
                  <button
                    type='button'
                    className='button --plus-short button-add-antibioticos'
                    disabled={!values.microrganismos[index].antibioticoResistenciaIntermediaria}
                    onClick={() => adicionarAntibioticoResistenciaIntermediaria(arrayHelpers, values, index, setFieldValue)}
                  >
                    <i className='icon icon-plus'></i>
                  </button>
                </div>
                {values.microrganismos[index].antibioticosResistenciaIntermediaria && values.microrganismos[index].antibioticosResistenciaIntermediaria.length > 0 &&
                  <div className='mt-8'>
                    {values.microrganismos[index].antibioticosResistenciaIntermediaria.map((antibioticoResistenciaIntermediaria, index) =>
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item --full'>{antibioticoResistenciaIntermediaria.antibiotico.nome}</div>
                        <button className='form-choice__bt-close' aria-label='Remover' onClick={() => arrayHelpers.remove(index)} type='button'><i className='icon icon-close'></i></button>
                      </div>
                    )}
                  </div>
                }
              </>
            )}
          />
        </r-cell>
      </r-grid>
    )
  }

  const limparFormularioDeMicrorganismos = (setFieldValue) => {
    setFieldValue('temResultadoDeUrocultura', false)
    setFieldValue('microrganismos', [
      {
        microrganismo: '',
        antibioticosSensiveis: [],
        antibioticosResistentes: [],
        antibioticosResistenciaIntermediaria: [],
      }
    ])
  }

  return (
    <Spinner operacoes={[ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE, ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE, LISTAR_MICRORGANISMOS, LISTAR_ANTIBIOTICOS]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : '',
          dataDaInfeccaoOuDoExameDeUrina: selecionada ? selecionada.dataDaInfeccaoOuDoExameDeUrina : '',
          observacoes: selecionada && selecionada.observacoes !== null ? selecionada.observacoes : '',
          bacteriuriaAssintomatica: selecionada ? selecionada.bacteriuriaAssintomatica : '',
          temResultadoDeUrocultura: selecionada ? selecionada.temResultadoDeUrocultura : '',
          microrganismos: selecionada ? microrganismosDaInfeccaoSelecionada : [
            {
              microrganismo: '',
              antibioticosSensiveis: [],
              antibioticosResistentes: [],
              antibioticosResistenciaIntermediaria: [],
            }
          ]
        }}
        esquemaDeValidacoes={Yup.object().shape({
          dataDaInfeccaoOuDoExameDeUrina: Yup.string().required('Obrigatório.').nullable(),
          bacteriuriaAssintomatica: Yup.boolean().required('Deve ser informado.'),
          temResultadoDeUrocultura: Yup.boolean().required('Deve ser informado.'),
          microrganismos: Yup.array()
            .when('temResultadoDeUrocultura', {
              is: val => val === true,
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    microrganismo: Yup.string().required('Obrigatório. ')
                  })
                )
            })
        })}
        acao={salvar}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>{selecionada ? 'Alterar  Infecção do Trato Urinário' : 'Adicionar  Infecção do Trato Urinário'}</h2>
            <r-grid columns-md={12} columns-lg={12} class='align-end'>
              <r-cell span={4} span-md={6} span-lg={4}>
                <DatePicker
                  nome='dataDaInfeccaoOuDoExameDeUrina'
                  titulo='Data da Infecção ou Exame Urinário *'
                  tabIndex={1}
                  maxDate={new Date()}
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <label className='form-label'>Bacteriúria Assintomática? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='bacteriuriaAssintomatica'
                    titulo='Sim'
                    valor={true}
                    classname='mr-3'
                    onChange={() => setFieldValue('bacteriuriaAssintomatica', true)}
                    tabIndex={2}
                  />
                  <Radio
                    nome='bacteriuriaAssintomatica'
                    titulo='Não'
                    valor={false}
                    tabIndex={3}
                    onChange={() => setFieldValue('bacteriuriaAssintomatica', false)}
                  />
                </div>
                {(errors.bacteriuriaAssintomatica && touched.bacteriuriaAssintomatica) ?
                  <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <label className='form-label'>Tem Resultado de Urocultura? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='temResultadoDeUrocultura'
                    titulo='Sim'
                    classname='mr-3'
                    valor={true}
                    onChange={() => setFieldValue('temResultadoDeUrocultura', true)}
                    tabIndex={4}
                  />
                  <Radio
                    nome='temResultadoDeUrocultura'
                    titulo='Não'
                    valor={false}
                    tabIndex={5}
                    onChange={() => limparFormularioDeMicrorganismos(setFieldValue)}
                  />
                </div>
                {(errors.temResultadoDeUrocultura && touched.temResultadoDeUrocultura) ?
                  <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
            </r-grid>
            {values.temResultadoDeUrocultura &&
              <FieldArray
                name="microrganismos"
              >
                {({ push, remove }) => (
                  <>
                    {values.microrganismos.map((x, index) => renderizarMicrorganismos(index, values, remove, setFieldValue))}
                    <r-grid columns-md={12} columns-lg={12} class='mt-3'>
                      <r-cell span={4} span-md={12} span-lg={12} class='d-flex justify-content-center'>
                        <button
                          className='button --light'
                          type='button'
                          onClick={() => push({
                            microrganismo: '',
                            antibioticosSensiveis: [],
                            antibioticosResistentes: [],
                            antibioticosResistenciaIntermediaria: []
                          })}
                        >
                          Adicionar Outro Microrganismo
                        </button>
                      </r-cell>
                    </r-grid>
                  </>
                )}
              </FieldArray>
            }
            <r-grid columns-md={12} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  titulo='Observações'
                  nome='observacoes'
                  tabIndex={6}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
              <button type='submit' disabled={values.temResultadoDeUrocultura && !values.microrganismos.map(x => x.microrganismo)} className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

export function SelectMicrorganismos({ nome, titulo, campoCodigo, campoDescricao, itens = [], ...rest }) {
  const [erro, setErro] = useState(false)

  const optionsSelect = campoCodigo ?
    itens.map(x => ({ label: x[campoDescricao], value: x[campoCodigo] })) :
    itens.map(x => ({ label: x, value: x }))

  return (
    <div className={`form-group ${erro && 'has-error'}`}>
      <label className='form-label' htmlFor={nome}>{titulo}</label>
      <Field
        {...rest}
        name={nome}
        options={optionsSelect}
        component={SelectMicrorganismosFormik}
        setErro={setErro}
      />
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function SelectMicrorganismosFormik(props) {
  const { field: { value, name }, form: { setFieldValue, setFieldTouched, errors, touched }, titulo, options = [], setErro, ...rest } = props

  const onBlur = evento => {
    setFieldTouched(name, true)
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = option => {
    const valorAlterado = (option ? option.value : '')
    setFieldValue(name, valorAlterado)
    rest.onChange && rest.onChange(valorAlterado)
  }

  const separaString = name.split('.')
  const validacao = separaString && errors && touched
    && (errors[separaString[0]] && errors[separaString[0]][separaString[1]]) &&
    (touched[separaString[0]] && touched[separaString[0]][separaString[1]])

  useEffect(() => {
    if (validacao) {
      setErro(true)
    }
  }, [validacao, setErro])

  const filter = rest.filterOption ? rest.filterOption : (option, rawInput) => filtroPadrao(rawInput, option.label)
  const valor = rest.value ? rest.value : options.find(x => x.value === value)

  return (
    <StylesSelect
      {...rest}
      {...props.field}
      criador={false}
      filterOption={filter}
      key={`${name}_${valor}`}
      options={options}
      value={valor}
      onChange={onChange}
      isClearable={true}
      isMulti={false}
      isValid={validacao}
      placeholder=''
      onBlur={onBlur}
      formatCreateLabel={userInput => `Criar opção para '${userInput}'`}
    />
  )
}