import { connect } from 'react-redux'
import acoes from '../../acoes'
import Setores from './index'

const mapStateToProps = state => {
  const setorAtual = state.setores.itemAtual

  let setor = {
    nome: '',
    observacoes: ''
  }

  if (setorAtual) {
    setor.identificador = setorAtual.identificador
    setor.nome = setorAtual.nome
    setor.observacoes = setorAtual.observacoes
  }

  return {
    filtros: state.setores.filtros,
    ordenacao: state.setores.ordenacao,
    paginaDeDados: state.setores.paginaDeDados,
    setor,
  }
}

export default connect(mapStateToProps, acoes)(Setores)