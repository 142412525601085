import React from 'react'

export default function Informacao({ texto }) {
  const textoDaInformacao = texto ? texto : ''

  return (
    <div className='form-choice-info'>
      <div className='form-choice-info__item'>
        <i className='icon icon-attention-circled'></i>
      </div>
      <div className='form-choice-info__item'><pre>{textoDaInformacao}</pre></div>
    </div>
  )
}