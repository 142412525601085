import React, { forwardRef } from 'react'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'

const SemanalCustomInput = (props, ref) => {
  const {
    dataInicial,
    dataFinal,   
    onClick 
  } = props

  const dataFormatada = (formatarDataParaFormatoLocal(dataInicial) || '') + '  ~  ' + (formatarDataParaFormatoLocal(dataFinal) || '')
    return (
      <input ref={ref} type="text" autoComplete="off" className="date-period__content" value={dataFormatada} onClick={onClick} readOnly />
    )
  }

  export default forwardRef(SemanalCustomInput)
