import api from '../api-major'

const listarTiposDeAtendimento = () => api.get(`/tipos-de-atendimento?ativos=true&tamanhoDaPagina=1000&ordem=nome`)

const listarTiposDeAtendimentoHabilitadosParaOUsuario = (identificadorDoUsuario, identificadorDoAgendamento) => {
  const agendamento = identificadorDoAgendamento ? `&agendamento=${identificadorDoAgendamento}` : ''
  return api.get(`/usuarios/${identificadorDoUsuario}/tipos-de-atendimento?tamanhoDaPagina=1000&ordem=tipo.nome${agendamento}`)
}

const habilitarTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, identificadorDoTipoDeAtendimento, privado) => api.post(`/usuarios/${identificadorDoUsuario}/tipos-de-atendimento/`, { tipoDeAtendimento: identificadorDoTipoDeAtendimento, privado })
const desabilitarTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, identificadorDoTipoDeAtendimento) => api.delete(`/usuarios/${identificadorDoUsuario}/tipos-de-atendimento/${identificadorDoTipoDeAtendimento}`)

export default {
  listarTiposDeAtendimento,
  listarTiposDeAtendimentoHabilitadosParaOUsuario,
  habilitarTipoDeAtendimentoDoUsuario,
  desabilitarTipoDeAtendimentoDoUsuario,
}