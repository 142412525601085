
import React, { createContext, useContext } from 'react'

const ContextoDoProntuario = createContext()

export const ProvedorDeContextoDoProntuario = ({children, ...rest}) => {
  return <ContextoDoProntuario.Provider  value={{ ...rest }}>{children}</ContextoDoProntuario.Provider>
}

export const useProntuario = () => {
  return useContext(ContextoDoProntuario)
}