import React, { useState, Children, useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import tabelaVazia from '../../design/img/empty-data.svg'

import './style.css'

export function Coluna(props) {
  const {
    className,
    nome,
    exibirTitulo = false,
  } = props

  return (
    <div
      className={`table-items__head ${!exibirTitulo && 'd-none'} d-lg-inline-flex ${className}`}
      key={`${nome}`}
    >
      {(() => nome)()}
    </div>
  )
}

export function Tabela(props) {
  const {
    acaoDoBotaoAdicionar,
    acoes = [],
    className,
    classNameAdicionar,
    chave,
    children,
    dados,
    exibirBotaoAdicionar = false,
    exibirMensagem = true,
    handleOnDragEndDrop,
    mensagemTabelaVazia = 'Não há resultados.',
    nome,
    paginaDeDados,
    processando,
    titulo = true,
  } = props

  const [abertos, setAbertos] = useState([])

  const chaves = chave && chave.split('.')
  const colunas = Children.toArray(children).filter(x => x.type === Coluna)

  const escolheChave = useCallback(item => {
    let valor = item

    chaves.forEach(item => {
      valor = valor[item]
    })

    return valor
  }, [chaves])

  const mais = identificador => {
    if (abertos.includes(identificador)) {
      setAbertos(abertos.filter(x => x !== identificador))
    } else {
      setAbertos([...abertos, identificador])
    }
  }

  const renderizarLinha = (item, index) => {
    return (
      <>
        {colunas.map(coluna => {
          const key = chave ? escolheChave(item) : index
          if (coluna.props.principal) {
            return (<div className={`table-items__data --name ${coluna.props.className}`} key={`${coluna.props.nome}_${key}`}>
              <p className={`table-items__mobile-label-name ${abertos.includes(key) && 'is-active'}`}>{coluna.props.nome}</p>
              <strong>{coluna.props.renderizar ? coluna.props.renderizar(item) : item[coluna.props.campoDeDados]}</strong>
              <div className={`table-items__mobile ${abertos.includes(key) && 'is-active'}`}>
                {colunas.filter(x => !x.props.principal).map((coluna) =>
                  <div key={`mobile_${coluna.props.nome}_${key}`}>
                    {((coluna.props.renderizar && coluna.props.renderizar(item)) || item[coluna.props.campoDeDados]) &&
                      <div className='table-items__mobile-item'>
                        <p className='table-items__mobile-label'>{coluna.props.nome}</p>
                        {coluna.props.renderizar ? coluna.props.renderizar(item) : <p className='table-items__mobile-value'>{item[coluna.props.campoDeDados]}</p>}
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>)
          }

          return (
            <div
              className={`table-items__data ${coluna.props.className} d-none d-lg-inline-flex`}
              key={`${coluna.props.nome}_${key}`}
            >
              {coluna.props.renderizar ?
                coluna.props.renderizar(item)
                : item[coluna.props.campoDeDados]}
            </div>
          )
        })}
      </>
    )
  }

  if ((!paginaDeDados || !paginaDeDados.dados) && !dados) {
    return null
  }

  const itensDaTabela = dados ? dados : paginaDeDados.dados

  const renderizarAcoes = (item, index) => {
    const key = chave ? escolheChave(item) : index

    return (
      <div className={`table-items__tools`}>
        {acoes.map(acao => acao(item))}        
        <GripVertical />        
        <button
          onClick={() => mais(key)}
          className={`table-items__bt ${abertos.includes(key) && 'is-active'} d-lg-none`}
          title='Mais'
          type='button'
        >
          <i className='icon icon-more'></i>
        </button>
      </div>
    )
  }

  const renderizarItem = (item, index) => {
    const key = chave ? escolheChave(item) : index

    return (
      <Draggable key={key.toString()} draggableId={key.toString()} index={index}>
        {(provided) => (
          <div className={`table-items__row ${(abertos.includes(key)) && 'is-active'}`} key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {renderizarLinha(item, index)}
            <div className='table-items__data justify-content-end justify-content-lg-end col-xs-4 col-md-2 col-lg-1'>
              {renderizarAcoes(item, index)}
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEndDrop}>
      <Droppable droppableId={nome}>
        {(provided) => (
          <div className={`table-items ${className} ${processando && 'is-loading'}`} {...provided.droppableProps} ref={provided.innerRef}>
            {titulo &&
              <div className={`table-items__row is-head `}>
                {colunas.map(coluna => coluna)}
                {exibirBotaoAdicionar &&
                  <div className={`table-items__head d-lg-inline-flex justify-content-end ${classNameAdicionar}`}>
                    <button className='button --plus-short' type='button' title='Adicionar' onClick={() => acaoDoBotaoAdicionar()}>
                      <i className='icon icon-plus'></i>
                    </button>
                  </div>
                }
              </div>
            }
            {itensDaTabela.map((item, index) => renderizarItem(item, index))}
            {exibirMensagem && itensDaTabela.length === 0 &&
              <div className='table-items__result'>
                <button className='icon-tabela-vazia'>
                  <img src={tabelaVazia} alt='tabelaVazia' />
                </button>
                <p>{mensagemTabelaVazia}</p>
              </div>
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const GripVertical = () => {
  return (
    <div className='tabela-dnd-grip-vertical' title='Arraste e solte para trocar de posição'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
        <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      </svg>
    </div>
  )
}