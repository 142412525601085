import React from 'react'
import moment from 'moment'
import Spinner from '../../../spinner'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'

import {
  EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE
} from '../../../../acoes/tipos'

export default function ConfirmacaoDeExclusao({ horario, fechar, excluir, excluirSerie }) {
  const { data, tipo, inicio, fim, unidade, tamanhoDoSlot } = horario

  return (
    <Spinner operacoes={[
      EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE
    ]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>Confirmação de Exclusão de Horário da Agenda</h2>
                <div className='form-choice-info my-1'>
                  <div className='form-choice-info__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-info__item'>
                    <ul>
                      <strong>Horário da Agenda</strong>: {tipo} dia {moment(data).format('DD/MM/YYYY')} das {inicio.substring(0, 5)} às {fim.substring(0, 5)} na unidade <strong>{unidade.nome}</strong> (Slot: {moment.duration(tamanhoDoSlot, "HH:mm:ss").asMinutes()} minutos).
                    </ul>
                  </div>
                </div>
                <ResumoDaRecorrencia {...horario.serie} semana={construirArrayDeSemana(horario.serie)} />
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Cancelar</button>
                  <button className='button --danger' type='button' onClick={() => excluir(horario.identificador)}>Excluir o Horário</button>
                  <button className='button --danger-light' type='button' onClick={() => excluirSerie(horario.identificador)}>Excluir a Série de Horários</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}