import api from './api-major'

const listarProfissionaisExternos = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroParceiro = filtros.parceiro !== undefined && filtros.parceiro.length === 1 ? `&parceiro=${filtros.parceiro[0] === 'Sim'}` : ''
  const parametroAtivo = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativo=${filtros.ativo[0] === 'Sim'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/profissionais-externos${paginacao}${tamanhoDaPaginaFormatado}${parametroParceiro}${parametroAtivo}${parametroNome}${ordem}`)
}
const adicionarProfissionalExterno = profissional => api.post(`/profissionais-externos/`, profissional)
const recuperarProfissionalExternoPeloIdentificador = identificador => api.get(`/profissionais-externos/${identificador}`)
const alterarProfissionalExterno = profissional => api.put(`/profissionais-externos/${profissional.identificador}`, profissional)
const removerProfissionalExterno = identificador => api.delete(`/profissionais-externos/${identificador}`)

export default {
  listarProfissionaisExternos,
  adicionarProfissionalExterno,
  recuperarProfissionalExternoPeloIdentificador,
  alterarProfissionalExterno,
  removerProfissionalExterno
}