import React from 'react'
import moment from 'moment'
import { DatePicker, CampoNumerico } from '../../../formik/formulario'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'

export default function Albumina({ metadados, historico = [], valor, leitura }) {
  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || algumCampoInformado

  const validarAlbumina = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 1, 7) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 1, 7)
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Albumina: </strong>
            {valor.valor.toString().replace('.', ',')} g/dL no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={5} span-lg={5}>
      <r-grid columns-md={5} columns-lg={5}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='albumina.valor'
            titulo={`Albumina ${obrigatorio ? '*' : ''}`}
            escala='g/dL'
            numeroDeCasasDecimais={1}
            validar={validarAlbumina}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Albumina'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        <r-cell span={4} span-md={2} span-lg={2}>
          <DatePicker
            nome='albumina.data'
            titulo={`Data ${obrigatorio ? '*' : ''}`}
            maxDate={moment().toDate()}
            validar={validarData}
          />
        </r-cell>
      </r-grid>
    </r-cell>
  )
}