import {
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA
} from '../../acoes/tipos'

const estadoInicial = {
  enfermeiros: [],
  filtros: {},
  medicos: [],
  programas: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        celulas: action.celulas,
      }
    }

    case LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        programas: action.programas,
      }
    }

    case LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        medicos: action.medicos.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
      }
    }

    case LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        enfermeiros: action.enfermeiros.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
      }
    }

    case FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        filtros: action.filtros
      }
    }

    case LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA: {
      return {
        ...state,
        filtros: {}
      }
    }

    default: {
      return state
    }
  }
}