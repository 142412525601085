import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Input, Formulario as FormularioDoFormik, Cor, Toglle, Cep, Select, Telefone } from '../../formik/formulario'
import { formatarCep } from '../../../bibliotecas/formatacao'
import { ehUmCepValido } from '../../../bibliotecas/validacoes'
import { confirmAlert } from 'react-confirm-alert'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { v4 as uuidv4 } from 'uuid'
import { FieldArray } from 'formik'
import AbasDaUnidade from './abas'
import Cabecalho from './cabecalho'

import {
  ADICIONAR_UNIDADE_DA_EMPRESA,
  SALVAR_UNIDADE_DA_EMPRESA,
  ATIVAR_UNIDADE_DA_EMPRESA,
  DESATIVAR_UNIDADE_DA_EMPRESA,
  LISTAR_UNIDADES_DA_EMPRESA,
  REMOVER_UNIDADE_DA_EMPRESA,
  RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  LISTAR_UNIDADES_FEDERATIVAS,
  LISTAR_FUSOS_HORARIOS,
  LISTAR_TIPOS_DE_UNIDADE,
} from '../../../acoes/tipos'


export default function Formulario(props) {
  const {
    ativar,
    desativar,
    history,
    match,
    remover,
    selecionarUnidade,
    recuperarEndereco,
    listarFusosHorarios,
    fusosHorarios,
    listarUnidadesFederativas,
    unidadesFederativas,
    listarTiposDeUnidade,
    tiposDeUnidade,
    salvar,
    unidade = {},
  } = props

  const { identificador } = match.params
  const exibirAtivar = usePossuiAsPermissoes([permissoes.ATIVAR_UNIDADE_DA_EMPRESA]) && !unidade.ativa && unidade.identificador
  const exibirDesativar = usePossuiAsPermissoes([permissoes.DESATIVAR_UNIDADE_DA_EMPRESA]) && unidade.ativa && unidade.identificador
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_UNIDADE_DA_EMPRESA])
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_UNIDADE_DA_EMPRESA])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_UNIDADE_DA_EMPRESA])

  const [cep, setCep] = useState('')
  const [unidadeFederativaSelecionada, setUnidadeFederativaSelecionada] = useState(null)
  const [cidades, setCidades] = useState([])
  const [reinicializar, setReinicializar] = useState(true)

  useEffect(() => { listarFusosHorarios() }, [listarFusosHorarios])
  useEffect(() => { listarUnidadesFederativas() }, [listarUnidadesFederativas])
  useEffect(() => { listarTiposDeUnidade() }, [listarTiposDeUnidade])

  useEffect(() => {
    identificador && selecionarUnidade(identificador)
  }, [identificador, selecionarUnidade])

  useEffect(() => {
    if (unidadeFederativaSelecionada) {
      const encontrada = unidadesFederativas.find(x => x.sigla === unidadeFederativaSelecionada)
      if (encontrada) {
        setCidades(encontrada.cidades)
      } else {
        setCidades([])
      }
    } else {
      setCidades([])
    }
  }, [unidadeFederativaSelecionada, unidadesFederativas])

  useEffect(() => {
    if (unidade.endereco) {
      setUnidadeFederativaSelecionada(unidade.endereco.unidadeFederativa)

      if (unidade.endereco.cep && unidade.endereco.unidadeFederativa) {
        setCep(formatarCep(unidade.endereco.cep))
      } else {
        setCep(unidade.endereco.cep)
      }
    }
  }, [unidade.endereco])

  const aoSairDoCep = async (cepAtualizado, setFieldValue) => {
    setReinicializar(false)

    if (cepAtualizado === cep) return

    let endereco = {}

    if (cepAtualizado && ehUmCepValido(cepAtualizado)) {
      endereco = await recuperarEndereco(cepAtualizado)
    }

    if (endereco) {
      setFieldValue('logradouro', endereco.logradouro || '')
      setFieldValue('bairro', endereco.bairro || '')
      setFieldValue('unidadeFederativa', endereco.uf || '')
      setFieldValue('cidade', endereco.localidade || '')
      setFieldValue('numero', '')
      setFieldValue('complemento', '')
      setUnidadeFederativaSelecionada(endereco.uf || '')
    }

    setCep(cepAtualizado)
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a unidade ${unidade.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: async () => await remover(unidade.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEAtivar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja ativar a unidade ${unidade.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => ativar(unidade.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEDesativar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja desativar a unidade ${unidade.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => desativar(unidade.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  const renderizarEnderecosIP = (index, values, remove) => {
    return (
      <React.Fragment key={index}>
        <r-cell span={4} span-md='1-3' span-lg='1-2'>
          <Input
            type='text'
            nome={`enderecosIP.${index}.nome`}
            titulo='Nome'
          />
        </r-cell>
        <r-cell span={4} span-md='3' span-lg='5'>
          <Input
            type='text'
            nome={`enderecosIP.${index}.enderecoIP`}
            titulo='Endereço IP'
          />
        </r-cell>
        {values.enderecosIP.length > 1 &&
          <r-cell span={1} span-md='1' span-lg='1'>
            <button
              type='button'
              onClick={() => remove(index)}
              className='excluir-telefone'
            />
          </r-cell>
        }
      </React.Fragment>
    )
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_UNIDADE_DA_EMPRESA,
      ATIVAR_UNIDADE_DA_EMPRESA,
      DESATIVAR_UNIDADE_DA_EMPRESA,
      LISTAR_UNIDADES_DA_EMPRESA,
      SALVAR_UNIDADE_DA_EMPRESA,
      REMOVER_UNIDADE_DA_EMPRESA,
      RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
      LISTAR_UNIDADES_FEDERATIVAS,
      LISTAR_FUSOS_HORARIOS,
      LISTAR_TIPOS_DE_UNIDADE,
    ]}>
      <FormularioDoFormik
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          sigla: Yup.string()
            .required('Obrigatório')
            .min(2, 'Mínimo 2 caracteres')
            .max(2, 'Máximo 2 caracteres'),
          cor: Yup.string().required('Obrigatório'),
          telefone: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999').required('Obrigatório.'),
          cep: Yup.string().ehUmCepValido('CEP deve ser válido. Exemplo: 99999-999'),
          fusoHorario: Yup.string().required('Obrigatório'),
          tipo: Yup.string().required('Obrigatório'),
          enderecosIP: Yup.array()
            .when('bloquearAcessoPorIP', {
              is: val => val === true,
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    nome: Yup.string().required('Obrigatório.'),
                    enderecoIP: Yup.string().required('Obrigatório.').ehUmIPValido('Endereço IP inválido.')
                  })
                )
            }),
          logradouro: Yup.string()
            .when('cep', {
              is: val => val,
              then: Yup.string().required('Obrigatório.')
            }),
          numero: Yup.string()
            .when('cep', {
              is: val => val,
              then: Yup.string().required('Obrigatório.')
            }),
          bairro: Yup.string()
            .when('cep', {
              is: val => val,
              then: Yup.string().required('Obrigatório.')
            }),
        })}
        reinicializar={reinicializar}
        valoresIniciais={{
          identificador: unidade.identificador || undefined,
          nome: unidade.nome || '',
          sigla: unidade.sigla || '',
          cor: unidade.cor || '',
          telefone: unidade.telefone || '',
          observacoes: unidade.observacoes || '',
          ativa: unidade.ativa !== undefined ? unidade.ativa : true,
          cep: unidade.endereco ? (unidade.endereco.cep && unidade.endereco.unidadeFederativa ? formatarCep(unidade.endereco.cep) : unidade.endereco.cep) : '',
          logradouro: unidade.endereco ? unidade.endereco.logradouro : '',
          numero: unidade.endereco ? unidade.endereco.numero : '',
          complemento: unidade.endereco ? unidade.endereco.complemento : '',
          bairro: unidade.endereco ? unidade.endereco.bairro : '',
          unidadeFederativa: unidade.endereco ? unidade.endereco.unidadeFederativa : '',
          cidade: unidade.endereco ? unidade.endereco.cidade : '',
          fusoHorario: unidade.fusoHorario || '',
          tipo: unidade.tipo || '',
          bloquearAcessoPorIP: unidade.bloquearAcessoPorIP,
          enderecosIP: unidade.ips && unidade.ips.length > 0 ?
            unidade.ips.map(x => ({
              identificadorPublico: x.identificadorPublico || '',
              nome: x.nome || '',
              enderecoIP: x.enderecoIP || ''
            })) :
            [{
              identificadorPublico: uuidv4(),
              nome: '',
              enderecoIP: ''
            }]
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            {unidade.identificador && <Cabecalho unidade={unidade} />}
            <fieldset className='mt-2'>
              <r-grid columns-md={12} columns-lg={12}>
                {unidade.identificador &&
                  <r-cell span={4} span-md={6} span-lg={9}>
                    <AbasDaUnidade identificador={identificador} url={match.url} />
                  </r-cell>
                }
                <r-cell span={4} span-md={12} span-lg={4}>
                  <Input
                    type='text'
                    nome='nome'
                    tabIndex={1}
                    titulo='Nome *'
                  />
                </r-cell>
                <r-cell span={2} span-md={3} span-lg={1}>
                  <Input
                    type='text'
                    nome='sigla'
                    tabIndex={2}
                    titulo='Sigla *'
                  />
                </r-cell>
                <r-cell span={2} span-md={3} span-lg={2}>
                  <Cor
                    nome='cor'
                    tabIndex={3}
                    titulo='Cor *'
                  />
                </r-cell>
                <r-cell span={4} span-md={3} span-lg={2}>
                  <Telefone
                    nome='telefone'
                    tabIndex={4}
                    titulo='Telefone *'
                  />
                </r-cell>
                <r-cell span={4} span-md={3} span-lg={2}>
                  <Select
                    nome='tipo'
                    titulo='Tipo *'
                    itens={tiposDeUnidade}
                    campoCodigo='nome'
                    campoDescricao='nome'
                    tabIndex={5}
                  />
                </r-cell>
                {unidade.identificador &&
                  <r-cell span={4} span-md={12} span-lg={1}>
                    <div className='form-toggle-list'>
                      <Toglle
                        className='form-toggle-2'
                        nome='ativa'
                        titulo='Ativa'
                        disabled={true}
                      />
                    </div>
                  </r-cell>
                }
              </r-grid>
            </fieldset>
            <fieldset className='mt-2'>
              <r-grid columns-md='12' columns-lg='12'>
                <r-cell span={4} span-md='2' span-lg='2'>
                  <Cep
                    type='text'
                    nome='cep'
                    tabIndex={6}
                    titulo='CEP *'
                    onBlur={() => aoSairDoCep(values.cep, setFieldValue)}
                    disabled={!podeEditar || !podeAdicionar}
                  />
                </r-cell>
                <r-cell span={4} span-md='4' span-lg='5'>
                  <Input
                    type='text'
                    nome='logradouro'
                    tabIndex={7}
                    titulo={`Logradouro ${values.cep ? '*' : ''}`}
                    disabled={!podeEditar || !podeAdicionar}
                  />
                </r-cell>
                <r-cell span={1} span-md='2' span-lg='1'>
                  <Input
                    type='text'
                    nome='numero'
                    tabIndex={8}
                    titulo={`Número ${values.cep ? '*' : ''}`}
                    disabled={!podeEditar || !podeAdicionar}
                  />
                </r-cell>
                <r-cell span={3} span-md='4' span-lg='4'>
                  <Input
                    type='text'
                    nome='complemento'
                    tabIndex={9}
                    titulo='Complemento'
                    disabled={!podeEditar || !podeAdicionar}
                  />
                </r-cell>
                <r-cell span={1} span-md='2' span-lg='2'>
                  <Select
                    nome='unidadeFederativa'
                    tabIndex={10}
                    titulo={`UF ${values.cep ? '*' : ''}`}
                    itens={unidadesFederativas}
                    campoCodigo='sigla'
                    campoDescricao='sigla'
                    onChange={setUnidadeFederativaSelecionada}
                    isDisabled={values.cep}
                  />
                </r-cell>
                <r-cell span={3} span-md='3' span-lg='3'>
                  <Select
                    nome='cidade'
                    tabIndex={11}
                    titulo={`Cidade ${values.cep ? '*' : ''}`}
                    itens={cidades}
                    isDisabled={values.cep}
                  />
                </r-cell>
                <r-cell span={4} span-md='3' span-lg='3'>
                  <Input
                    type='text'
                    nome='bairro'
                    tabIndex={12}
                    titulo={`Bairro ${values.cep ? '*' : ''}`}
                    disabled={!podeEditar || !podeAdicionar}
                  />
                </r-cell>
                <r-cell span={4} span-md='4' span-lg='4'>
                  <Select
                    nome='fusoHorario'
                    tabIndex={13}
                    titulo='Fuso Horário *'
                    itens={fusosHorarios}
                    campoCodigo='nome'
                    campoDescricao='nome'
                  />
                </r-cell>
              </r-grid>
            </fieldset>
            <fieldset className='mt-2'>
              <r-grid columns-md='12' columns-lg='12'>
                <r-cell span={4} span-md={12} span-lg={12}>
                  <div className='form-toggle-list'>
                    <Toglle
                      className='form-toggle-2'
                      nome='bloquearAcessoPorIP'
                      tabIndex={14}
                      titulo='Bloquear acesso por IP'
                    />
                  </div>
                </r-cell>
                {values.bloquearAcessoPorIP &&
                  <FieldArray
                    name='enderecosIP'
                  >
                    {({ push, remove }) => (
                      <>
                        {values.enderecosIP.map((x, index) => renderizarEnderecosIP(index, values, remove))}
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <button
                            className='button --light'
                            type='button'
                            onClick={() => push({
                              identificadorPublico: uuidv4(),
                              nome: '',
                              enderecoIP: '',
                            })}
                          >
                            Adicionar Endereço IP
                          </button>
                        </r-cell>
                      </>
                    )}
                  </FieldArray>
                }
              </r-grid>
            </fieldset>
            <fieldset className='mt-2'>
              <r-grid columns-md='12' columns-lg='12'>
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Input
                    as='textarea'
                    nome='observacoes'
                    tabIndex={15}
                    titulo='Observações'
                  />
                </r-cell>
              </r-grid>
            </fieldset>
            <Botoes
              remover={remover}
              exibirExcluir={exibirExcluir}
              exibirDesativar={exibirDesativar}
              podeEditar={podeEditar}
              podeAdicionar={podeAdicionar}
              exibirAtivar={exibirAtivar}
              confirmarERemover={confirmarERemover}
              confirmarEDesativar={confirmarEDesativar}
              confirmarEAtivar={confirmarEAtivar}
              history={history}
            />
          </>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Botoes({ remover, exibirExcluir, exibirDesativar, podeEditar, podeAdicionar, exibirAtivar, confirmarERemover, confirmarEDesativar, confirmarEAtivar, history }) {
  if (remover) {
    return (
      <div className='list-btn-unidades'>
        {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
        {exibirDesativar && <button onClick={confirmarEDesativar} type='button' className='button --danger-light'>Desativar</button>}
        {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
        {exibirAtivar && <button onClick={confirmarEAtivar} type='button' className='button --light'>Ativar</button>}
        <button onClick={() => history.push(`/configuracoes/empresa/unidades/lista`)} type='button' className='button --light'>Voltar</button>
      </div>
    )
  }

  return (
    <div className='list-btn'>
      <button onClick={() => history.push(`/configuracoes/empresa/unidades/lista`)} type='button' className='button --light'>Voltar</button>
      {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
    </div>
  )
}