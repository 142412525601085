import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TAMANHOS_DE_PAGINAS,
  LISTOU_TAMANHOS_DE_PAGINAS,
  ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS
} from './tipos'

const listouTamanhosDePaginas = tamanhosDePaginas => ({
  type: LISTOU_TAMANHOS_DE_PAGINAS,
  tamanhosDePaginas
})

const erroAoListarTamanhosDePaginas = erro => ({
  type: ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS,
  erro
})

export const listarTamanhosDePaginas = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TAMANHOS_DE_PAGINAS })

    try {
      const resultado = await api.listarTamanhosDePaginas()
      
      dispatch(listouTamanhosDePaginas(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTamanhosDePaginas(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tamanhos de páginas.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TAMANHOS_DE_PAGINAS
  }

  return acao
}
