import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
  RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
} from '../tipos'

const listouTiposDeAtendimentoDoUsuarioLogado = tiposDeAtendimento => ({
  type: LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  tiposDeAtendimento,
})

const erroAoListarTiposDeAtendimentoDoUsuarioLogado = (erro, parametros) => ({
  erro,
  type: ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  parametros,
})

export const listarTiposDeAtendimentoDoUsuarioLogado = (identificador, identificadorDoAgendamento) => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO })

  try {
    const resultado = await api.listarTiposDeAtendimentoHabilitadosParaOUsuario(identificador, identificadorDoAgendamento)

    dispatch(listouTiposDeAtendimentoDoUsuarioLogado(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeAtendimentoDoUsuarioLogado(erro, { identificador, identificadorDoAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de atendimento do usuário logado.')))
  }
}

const recuperouVersaoDoTipoDeAtendimento = (identificador, versao) => ({
  type: RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  identificador,
  versao,
})

const erroAoRecuperarVersaoDoTipoDeAtendimento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  erro,
  parametros,
})

export const recuperarVersaoDoTipoDeAtendimento = (identificador, versao) => async dispatch => {
  dispatch({ type: RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO })

  try {
    const resultado = await api.recuperarVersaoDoTipoDeAtendimento(identificador, versao)

    dispatch(recuperouVersaoDoTipoDeAtendimento(identificador, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados
  } catch (erro) {
    dispatch(erroAoRecuperarVersaoDoTipoDeAtendimento(erro, { identificador, versao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a versão do tipo de atendimento.')))
  }
}

const recuperouTipoDeAtendimentoPorIdentificador = tipoDeAtendimento => ({
  type: RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
  tipoDeAtendimento,
})

const erroAoRecuperarTipoDeAtendimentoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
  erro,
  parametros,
})

export const recuperarTipoDeAtendimentoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarTipoDeAtendimentoPorIdentificador(identificador)

    dispatch(recuperouTipoDeAtendimentoPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarTipoDeAtendimentoPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o tipo de atendimento pelo identificador.')))
  }
}