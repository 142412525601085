import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'

import {
  ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ADICIONOU_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ALTEROU_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  LISTOU_MEDICAMENTOS_DO_PRONTUARIO,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  LISTAR_POSOLOGIAS,
  LISTOU_POSOLOGIAS,
  ERRO_AO_LISTAR_POSOLOGIAS,
  LISTAR_MEDICAMENTOS_DO_PACIENTE,
  LISTOU_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE,
  ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE
} from '../../tipos'

const adicionouMedicamentoDoPaciente = (identificadorDoPaciente, medicamento) => ({
  type: ADICIONOU_MEDICAMENTO_DO_PACIENTE,
  identificadorDoPaciente,
  medicamento
})

const erroAoAdicionarMedicamentoDoPaciente = erro => ({
  type: ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  erro
})

export const adicionarMedicamentoDoPaciente = (identificadorDoPaciente, medicamento) => async dispatch => {
  dispatch({ type: ADICIONAR_MEDICAMENTO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarMedicamentoDoPaciente(identificadorDoPaciente, medicamento)
    dispatch(adicionouMedicamentoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(listarMedicamentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return true
  } catch (erro) {
    dispatch(erroAoAdicionarMedicamentoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o medicamento do paciente.')))
    return false
  }
}

const alterouMedicamentoDoPaciente = (identificadorDoPaciente, identificadorDoMedicamento, medicamento) => ({
  type: ALTEROU_MEDICAMENTO_DO_PACIENTE,
  identificadorDoPaciente,
  identificadorDoMedicamento,
  medicamento
})

const erroAoAlterarMedicamentoDoPaciente = erro => ({
  type: ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE,
  erro
})

export const alterarMedicamentoDoPaciente = (identificadorDoPaciente, identificadorDoMedicamento, medicamento) => async dispatch => {
  dispatch({ type: ALTERAR_MEDICAMENTO_DO_PACIENTE })

  try {
    const resultado = await api.alterarMedicamentoDoPaciente(identificadorDoPaciente, identificadorDoMedicamento, medicamento)
    dispatch(alterouMedicamentoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(listarMedicamentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return true
  } catch (erro) {
    dispatch(erroAoAlterarMedicamentoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o medicamento do paciente.')))
    return false
  }
}

const alterouStatusDoMedicamentoDoPaciente = (identificadorDoPaciente, identificadorDoMedicamento, dados) => ({
  type: ALTEROU_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  identificadorDoPaciente,
  identificadorDoMedicamento,
  dados
})

const erroAoAlterarStatusDoMedicamentoDoPaciente = erro => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  erro
})

export const alterarStatusDoMedicamentoDoPaciente = (identificadorDoPaciente, identificadorDoMedicamento, dados) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE })

  try {
    const resultado = await api.alterarStatusDoMedicamentoDoPaciente(identificadorDoPaciente, identificadorDoMedicamento, dados)
    dispatch(alterouStatusDoMedicamentoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(listarMedicamentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoMedicamentoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o status do medicamento do paciente.')))
    return false
  }
}

const alterouPosicaoDosMedicamentosDoPaciente = (identificadorDoPaciente, itensOrdenados) => ({
  type: ALTEROU_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  identificadorDoPaciente,
  itensOrdenados
})

const erroAoAlterarPosicaoDosMedicamentosDoPaciente = erro => ({
  type: ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  erro
})

export const alterarPosicaoDosMedicamentosDoPaciente = (identificadorDoPaciente, identificadorDoMedicamento, posicao, itensOrdenados) => async dispatch => {
  dispatch({ type: ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE })

  try {
    const resultado = await api.alterarPosicaoDosMedicamentosDoPaciente(identificadorDoPaciente, identificadorDoMedicamento, posicao)
    dispatch(alterouPosicaoDosMedicamentosDoPaciente(identificadorDoPaciente, itensOrdenados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPosicaoDosMedicamentosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a posição do medicamento do paciente.')))
    return false
  }
}

const listouMedicamentoDoPaciente = (identificadorDoPaciente, medicamentosDoPaciente) => ({
  type: LISTOU_MEDICAMENTOS_DO_PACIENTE,
  identificadorDoPaciente,
  medicamentosDoPaciente
})

const erroAoListarMedicamentoDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE,
  erro
})

export const listarMedicamentoDoPaciente = (identificadorDoPaciente) => async dispatch => {
  dispatch({ type: LISTAR_MEDICAMENTOS_DO_PACIENTE })

  try {
    const resultado = await api.listarMedicamentosDoPaciente(identificadorDoPaciente)
    dispatch(listouMedicamentoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarMedicamentoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os medicamentos do paciente.')))
    return false
  }
}

const listouMedicamentosDoProntuario = medicamentos => ({
  type: LISTOU_MEDICAMENTOS_DO_PRONTUARIO,
  medicamentos,
})

const erroAoListarMedicamentosDoProntuario = erro => ({
  type: ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  erro,
})

export const listarMedicamentosDoProntuario = () => async dispatch => {
  dispatch({ type: LISTAR_MEDICAMENTOS_DO_PRONTUARIO })

  try {
    const resultado = await api.listarMedicamentos({ ativo: ["Sim"] }, 1, '', 1000000)

    dispatch(listouMedicamentosDoProntuario(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarMedicamentosDoProntuario(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os medicamentos.')))
  }
}

const listouPosologias = posologias => ({
  type: LISTOU_POSOLOGIAS,
  posologias,
})

const erroAoListarPosologias = erro => ({
  type: ERRO_AO_LISTAR_POSOLOGIAS,
  erro,
})

export const listarPosologias = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_POSOLOGIAS })

    try {
      const resultado = await api.listarPosologias()

      dispatch(listouPosologias(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarPosologias(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os posologias.')))
    }
  }

  acao.meta = {
    cache: LISTAR_POSOLOGIAS
  }

  return acao
}