import api from '../api-major'
import moment from 'moment'

const listarAgendamentosAguardandoAtendimento = (filtros, pagina, ordenacao) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+inicio,+paciente.nome,+identificador'
  const parametroTamanhoDaPagina = '&tamanhoDaPagina=10'
  const parametroProfissional = filtros.identificadorDoProfissional ? `&profissionais=${filtros.identificadorDoProfissional}` : ''
  const parametroStatus = `&status=aguardando_atendimento,atendido,em_atendimento,confirmado,agendado,reagendado,nao_compareceu`
  const parametroInicio = `&inicio=${moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}`
  const parametroFim = `&fim=${moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`
  const parametroUnidades = `&unidades=${[filtros.unidade]}`
  const parametroTermo = filtros && filtros.termo ? `&paciente=${filtros.termo}` : ''

  return api.get(`/agenda/agendamentos${paginacao}${parametroTamanhoDaPagina}&turnos=MANHA,TARDE,NOITE,MADRUGADA&diasDaSemana=DOM,SEG,TER,QUA,QUI,SEX,SAB${parametroOrdem}${parametroProfissional}${parametroStatus}${parametroInicio}${parametroFim}${parametroUnidades}${parametroTermo}`)
}

export default {
  listarAgendamentosAguardandoAtendimento,
}   