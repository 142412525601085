import React from 'react'
import { MultiplaEscolha, MultiplaSelecao } from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import { colocarPrimeiraLetraMaiuscula } from '../../../../../bibliotecas/texto'
import CorpoHumano from '../../../../../design/img/dor-avaliacao-fisica-e-funcional.png'
import Spinner from '../../../../spinner'

import {
  LISTAR_DURACOES_DAS_DORES,
  LISTAR_LOCAIS_DAS_DORES,  
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA
} from '../../../../../acoes/tipos'

const renderizarTitulo = valor => valor.descricao

export default function Resumo({ duracoesDasDores, locaisDasDores, tiposDeAtividadeFisicaDeLazer, execucoesDoTesteDeFisioterapia, selecionado, valores, fechar, ...rest }) {
  return (
    <Spinner operacoes={[
      LISTAR_DURACOES_DAS_DORES,
      LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
      LISTAR_LOCAIS_DAS_DORES,
      LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
    ]}>
      <r-grid columns-md={6} columns-lg={12} class='form form-group'>
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className='form-title'>Resumo da Avaliação Física e Funcional</h2>
            <Paciente paciente={valores.paciente} />
            <Data data={valores.data} />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-fisioterapia'>
              <Cabecalho />
              <QueixaPrincipal valor={valores.queixaPrincipal} />
              <DeambulaComAuxilioDeDispositivoEMarcapassoEProteseMetalica valor={valores} />
              <AtividadeFisicaDeLazer tiposDeAtividadeFisicaDeLazer={tiposDeAtividadeFisicaDeLazer} valor={valores.atividadeFisicaDeLazer} />
              <Dor duracoesDasDores={duracoesDasDores} locaisDasDores={locaisDasDores} valor={valores.dor} />
              <AtividadeEParticipacao valor={valores.problemaImpedeRealizacaoDeAtividadesNormais} />
              <Quedas valor={valores.quedas} />
              <Testes tug={valores.tug} sentaELevanta5Vezes={valores.sentaELevanta5Vezes} velocidadeDaMarchaDe4Metros={valores.velocidadeDaMarchaDe4Metros} execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia} />
              <Equilibrio valor={valores.equilibrio} execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia} />
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            </div>
          </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-zarit paciente-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div>
        <strong>AVALIAÇÃO FÍSICA E FUNCIONAL</strong>
      </div>
    </r-cell>
  )
}

function QueixaPrincipal({ valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <div className='pergunta'>
        <span><strong>Queixa principal: </strong><br />{valor}</span>
      </div>
    </r-cell>
  )
}

function DeambulaComAuxilioDeDispositivoEMarcapassoEProteseMetalica({ valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='deambulaComAuxilioDeDispositivo'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='1. Deambula com auxílio de dispositivo?'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.deambulaComAuxilioDeDispositivo}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='marcapasso'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='2. Marcapasso?'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.marcapasso}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='proteseMetalica'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='3. Prótese metálica?'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.proteseMetalica}
      />
      {valor.localDaProteseMetalica &&
        <div className='pergunta'>
          <span><strong>Local da prótese metálica: </strong>{valor.localDaProteseMetalica}</span>
        </div>
      }
    </r-cell>
  )
}

function AtividadeFisicaDeLazer({ tiposDeAtividadeFisicaDeLazer, valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>ATIVIDADE FÍSICA DE LAZER</p>
      <MultiplaEscolha
        className='pergunta'
        nome='atividadeFisicaDeLazer.realizaAtividadeFisicaDeLazer'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='4. Realiza atividade física de lazer?'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.realizaAtividadeFisicaDeLazer}
      />
      {valor.realizaAtividadeFisicaDeLazer &&
        <>
          <div className='pergunta'>
            <span><strong>5. Minutos por semana: </strong>{valor.tempoPorSemana}</span>
          </div>
          <MultiplaSelecao
            className='pergunta'
            nome='atividadeFisicaDeLazer.tipos'
            opcoes={tiposDeAtividadeFisicaDeLazer.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='6. Tipo:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={valor.tipos}
          />
        </>
      }
    </r-cell>
  )
}

function Dor({ duracoesDasDores, locaisDasDores, valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <div className='dor'>
        <div>
          <MultiplaEscolha
            className='pergunta'
            nome='dor.dor'
            opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
            pergunta='7. Dor:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={valor.dor}
          />
          {valor.dor &&
            <img
              alt='Corpo Humano'
              src={CorpoHumano}
            />
          }
        </div>
        {valor.dor &&
          <MultiplaSelecao
            className='pergunta'
            nome='dor.locais'
            opcoes={locaisDasDores.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='8. Local:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={valor.locais}
          />
        }
      </div>
      {valor.dor &&
        <div className='dor'>          
          <MultiplaEscolha
            className='pergunta'
            nome='dor.duracao'
            opcoes={duracoesDasDores.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='9. Duração:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={valor.duracao}
          />
          <div className='componente-zarit paciente-leitura mb-1'><strong>10. Intensidade:</strong> {colocarPrimeiraLetraMaiuscula(valor.intensidade)}</div>
        </div>
      }
    </r-cell>
  )
}

function AtividadeEParticipacao({ valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>ATIVIDADE E PARTICIPAÇÃO</p>
      <MultiplaEscolha
        className='pergunta'
        nome='problemaImpedeRealizacaoDeAtividadesNormais'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='11. Esse problema que o trouxe a fisioterapia (dor, desconforto, rigidez) o impede de realizar atividades normais (por exemplo trabalho, atividades domésticas e de lazer)?'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor}
      />
    </r-cell>
  )
}

function Quedas({ valor }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>QUEDAS</p>
      <div className='pergunta'>
        <span><strong>12. Número de quedas nos últimos 12 meses: </strong>{valor.numeroDeQuedasNosUltimos12Meses}</span>
      </div>
      <MultiplaEscolha
        className='pergunta'
        nome='quedas.medoDeCair'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='13. Medo de cair:'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.medoDeCair}
      />
    </r-cell>
  )
}

function Testes({ tug, sentaELevanta5Vezes, velocidadeDaMarchaDe4Metros, execucoesDoTesteDeFisioterapia }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span-md={6} span-lg={4}>
          <MultiplaEscolha
            className='pergunta'
            nome='tug.execucao'
            opcoes={execucoesDoTesteDeFisioterapia.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='14. TUG (Time Up and Go):'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={tug.execucao}
          />
        </r-cell>
        <r-cell span-md={6} span-lg={2}>
          <div className='pergunta'>
            <span><strong>Tempo: </strong>{tug.tempo ? tug.tempo + 's' : ''}</span>
          </div>
        </r-cell>
      </r-grid>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span-md={6} span-lg={4}>
          <MultiplaEscolha
            className='pergunta'
            nome='sentaELevanta5Vezes.execucao'
            opcoes={execucoesDoTesteDeFisioterapia.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='15. Senta-Levanta 5 vezes:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={sentaELevanta5Vezes.execucao}
          />
        </r-cell>
        <r-cell span-md={6} span-lg={2}>
          <div className='pergunta'>
            <span><strong>Tempo: </strong>{sentaELevanta5Vezes.tempo ? sentaELevanta5Vezes.tempo + 's' : ''}</span>
          </div>
        </r-cell>
      </r-grid>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span-md={6} span-lg={4}>
          <MultiplaEscolha
            className='pergunta'
            nome='velocidadeDaMarchaDe4Metros.execucao'
            opcoes={execucoesDoTesteDeFisioterapia.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
            pergunta='16. Velocidade de Marcha:'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={velocidadeDaMarchaDe4Metros.execucao}
          />
        </r-cell>
        <r-cell span-md={6} span-lg={2}>
          <div className='pergunta'>
            <span><strong>Tempo: </strong>{velocidadeDaMarchaDe4Metros.tempo ? velocidadeDaMarchaDe4Metros.tempo + 's' : ''}</span>
          </div>
        </r-cell>
      </r-grid>
    </r-cell>
  )
}

function Equilibrio({ valor, execucoesDoTesteDeFisioterapia }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>EQUILÍBRIO</p>
      <MultiplaEscolha
        className='pergunta'
        nome='equilibrio.romberg'
        opcoes={execucoesDoTesteDeFisioterapia.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
        pergunta='17. Romberg:'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.romberg}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='equilibrio.semiTamdem'
        opcoes={execucoesDoTesteDeFisioterapia.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
        pergunta='18. Semi Tamdem:'
        renderizarTitulo={renderizarTitulo}
        leitura={true}
        valor={valor.semiTamdem}
      />
    </r-cell>
  )
}