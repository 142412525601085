import api from '../api-major'

const fazerDownloadDoArquivoDeAtendimento = ({ identificadorDoPaciente, identificadorDoAtendimento, tamanhoDaPagina, orientacaoDaPagina,
  incluirImagens, incluirAnexos, umAtendimentoPorPagina }) => {

  if (identificadorDoAtendimento) {
    return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/pdf?tamanhoDaPagina=${tamanhoDaPagina}` +
      `&orientacaoDaPagina=${orientacaoDaPagina}&incluirImagens=${incluirImagens}&incluirAnexos=${incluirAnexos}`, {
      responseType: 'arraybuffer'
    })
  } else {
    return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/pdf?tamanhoDaPagina=${tamanhoDaPagina}&orientacaoDaPagina=${orientacaoDaPagina}` +
      `&incluirImagens=${incluirImagens}&incluirAnexos=${incluirAnexos}&umAtendimentoPorPagina=${umAtendimentoPorPagina}`, {
      responseType: 'arraybuffer'
    })
  }
}

const adicionarInformacoes = (atendimento, informacoes) =>  api.post(`/pacientes/${atendimento.paciente.identificador}/prontuario/${atendimento.identificador}/informacoes-adicionais`, informacoes)
const listarAtendimentosFinalizados = (identificador, termo) =>  api.get(`/pacientes/${identificador}/prontuario?tamanhoDaPagina=100000&status=finalizado_pelo_profissional,finalizado_automaticamente,cancelado_apos_finalizar&termo=${termo || ''}&ordem=-inicio`)

export default {
  fazerDownloadDoArquivoDeAtendimento,
  adicionarInformacoes,
  listarAtendimentosFinalizados,
}