import api from './api-major'

const listarOperadoras = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? '&tamanhoDaPagina=10' : '&tamanhoDaPagina=100000'

  return api.get(`/operadoras${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarOperadora = operadora => api.post(`/operadoras/`, operadora)
const recuperarOperadoraPeloIdentificador = identificador => api.get(`/operadoras/${identificador}`)
const alterarOperadora = operadora => api.put(`/operadoras/${operadora.identificador}`, operadora)
const removerOperadora = identificador => api.delete(`/operadoras/${identificador}`)

export default {
  listarOperadoras,
  adicionarOperadora,
  recuperarOperadoraPeloIdentificador,
  alterarOperadora,
  removerOperadora
}