import {
  LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS
} from '../../acoes/tipos'

export default (state, action) => {
  switch (action.type) {
    case LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS: {
      return action.setores
    }

    default: {
      return state
    }
  }
}