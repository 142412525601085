import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
} from '../../tipos'

const listouInstrucoesDeAgendamentosDoProfissionalDeSaude = (paginaDeDados, filtros) => ({
  paginaDeDados,
  filtros,
  type: LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
})

const erroAoListarInstrucoesDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const listarInstrucoesDeAgendamentosDoProfissionalDeSaude = (identificadorDoProfissional, filtros, pagina) => async dispatch => {
  dispatch({ filtros, type: LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE })

  try {
    let resultado = await api.listarInstrucoesDeAgendamentosDoProfissionalDeSaude(identificadorDoProfissional, filtros, pagina)

    dispatch(listouInstrucoesDeAgendamentosDoProfissionalDeSaude(resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarInstrucoesDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, filtros, pagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as instruções de agendamentos do profissional de saúde.')))
  }
}

export const adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, instrucao) => async dispatch => {
  dispatch({ type: ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE })

  try {
    let resultado = await api.adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, instrucao)
    dispatch(adicionouInstrucaoDeAgendamentoDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarInstrucaoDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, instrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar instrução do profissional de saúde.')))

    return false
  }
}

const adicionouInstrucaoDeAgendamentoDoProfissionalDeSaude = intrucoes => ({
  type: ADICIONOU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  intrucoes
})

const erroAoAdicionarInstrucaoDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, instrucao) => async dispatch => {
  dispatch({ type: ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE })

  try {
    let resultado = await api.alterarInstrucaoDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, instrucao)
    dispatch(alterouInstrucaoDeAgendamentoDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarInstrucaoDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, instrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar instrução do profissional de saúde.')))

    return false
  }
}

const alterouInstrucaoDeAgendamentoDoProfissionalDeSaude = instrucao => ({
  type: ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  instrucao
})

const erroAoAlterarInstrucaoDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, serie) => async dispatch => {
  dispatch({ type: ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, serie)
    dispatch(alterouSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, serie }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados da série de instruções de agendamento na agenda do profissional de saúde.')))

    return false
  }
}

const alterouSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = serie => ({
  type: ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  serie
})

const erroAoAlterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, identificador) => async dispatch => {
  dispatch({ type: EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE })

  try {
    let resultado = await api.excluirInstrucaoDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, identificador)
    dispatch(excluiuInstrucaoDeAgendamentoDoProfissionalDeSaude(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Instrução removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirInstrucaoDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um bloqueio da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuInstrucaoDeAgendamentoDoProfissionalDeSaude = identificador => ({
  type: EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  identificador
})

const erroAoExcluirInstrucaoDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDaInstrucao) => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, identificadorDaInstrucao)
    dispatch(excluiuSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(identificadorDaInstrucao))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Série de Instruções removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificadorDaInstrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir uma série de instruções de agendamento da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = identificadorDaInstrucao => ({
  type: EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  identificadorDaInstrucao
})

const erroAoExcluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})