import React, { memo } from 'react'
import { CampoNumerico } from '../../../formik/formulario'
import { deveSerUmValorEntre, deveSerInformado } from '../../../../bibliotecas/validacoes'

function Altura({ metadados, leitura, valor }) {
  const obrigatorio = metadados?.obrigatorio === 'true'

  const validar = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 30, 300) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 30, 300)
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Altura: </strong>
            {valor.toString().replace('.', ',')} cm
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={2} span-lg={2}>
      <CampoNumerico
        nome='altura'
        titulo={`Altura ${obrigatorio ? '*' : ''}`}
        escala='cm'
        numeroDeCasasDecimais={1}
        validar={validar}
      />
    </r-cell>
  )
}

export default memo(Altura)