import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { SelectReact } from '../../formik/select'
import { useTarefas } from '../contexto'
import { LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS } from '../../../acoes/tipos'
import Spinner from '../../spinner'
import { extrairNomeResumido } from '../../../bibliotecas/texto'

export default function Paciente({ alterar, valor }) {
  const { listarPacientesDosFiltrosDoTarefas, pacientesDosFiltros, alterarTermoDoFiltroDePacientesDoTarefas, termoDoFiltroDePacientes, tipoDaTarefaAtual } = useTarefas()

  useEffect(() => {
    if (termoDoFiltroDePacientes) {
      listarPacientesDosFiltrosDoTarefas({ termo: termoDoFiltroDePacientes }, 1, { nome: 'nome', ordem: '+' }, 50, tipoDaTarefaAtual)
    }
  }, [listarPacientesDosFiltrosDoTarefas, termoDoFiltroDePacientes, tipoDaTarefaAtual])

  const renderizarItem = ({ value, label, dataDeNascimento, medicoDeReferencia }) => {
    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          <div className='data-de-nascimento'>
            <span>Nasc.: {moment(dataDeNascimento).format('DD/MM/YYYY')}</span>
          </div>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${medicoDeReferencia ? 'possui-medico' : ''}`}>
          {medicoDeReferencia &&
           <div className='medico-do-paciente'>
             <span>Médico(a): {extrairNomeResumido(medicoDeReferencia.nome)}</span>
           </div>
          }
          {!medicoDeReferencia &&
           <div className='medico-do-paciente'>
             <span>Não possui médico de referência.</span>
           </div>
          }
        </div>
      </div>
    )
  }

  const optionsPaciente = useMemo(() => pacientesDosFiltros.map(x => ({
    value: x.identificador,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento,
    medicoDeReferencia: x.medicoDeReferencia,
    unidadePreferencial: x.unidadePreferencial
  })), [pacientesDosFiltros])

  const pesquisarPacientes = termo => {
    if (!termo || termo.length <= 2) return

    alterarTermoDoFiltroDePacientesDoTarefas(termo, tipoDaTarefaAtual)
    listarPacientesDosFiltrosDoTarefas({ termo }, 1, { nome: 'nome', ordem: '+' }, 50, tipoDaTarefaAtual)
  }

  return (
    <Spinner operacoes={[LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS]}>
      {({ processando }) =>
        <div className='form-group'>
          <label className='form-label'>Paciente</label>
          <SelectReact
            nome='paciente'
            id='paciente'
            onChange={alterar}
            options={optionsPaciente}
            noOptionsMessage='Digite 3 caracteres para pesquisar'
            onInputChange={pesquisarPacientes}
            formatOptionLabel={renderizarItem}
            valor={valor}
            isLoading={processando}
          />
        </div>
      }
    </Spinner>
  )
}