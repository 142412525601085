import {  
  LISTOU_PROGRAMAS_DO_PACIENTE,  
  RECUPEROU_PROGRAMA_DO_PACIENTE,
} from '../../acoes/tipos'

const estadoInicial = {
  itens: [],  
  selecionado: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_PROGRAMA_DO_PACIENTE: {
      return {
        ...state,
        selecionado: action.programa,
      }
    }

    case LISTOU_PROGRAMAS_DO_PACIENTE: {
      return {
        selecionado: {},
        itens: action.programas,
      }
    }    

    default: {
      return state
    }
  }
}