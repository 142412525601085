import moment from 'moment'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

import {
  LISTOU_AVALIACOES_RESPONDIDAS,
  RECUPEROU_AVALIACAO_RESPONDIDA,
  ALTEROU_STATUS_DE_UMA_RESPOSTA,
} from '../../acoes/tipos'

const estadoInicial = {
  filtros: {
    inicio: formatarDataParaFormatoUniversal(moment().add(-1, 'months')),
    fim: formatarDataParaFormatoUniversal(moment()),
  },

  paginaDeDados: {},
  itemAtual: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_AVALIACOES_RESPONDIDAS: {
      return {
        ...state,
        filtros: action.filtros,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case RECUPEROU_AVALIACAO_RESPONDIDA: {
      return {
        ...state,
        itemAtual: {
          ...action.avaliacao,
          perguntas: action.avaliacao.perguntas.map(x => ({ ...x, ativa: x.status === 'Ativa' }))
        }
      }
    }

    case ALTEROU_STATUS_DE_UMA_RESPOSTA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          perguntas: state.itemAtual.perguntas.map(x => ({ ...x, ativa: x.identificador === action.pergunta ? !x.ativa : x.ativa }))
        }
      }
    }

    default: {
      return state
    }
  }
}