import React, { useEffect, useState } from 'react'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, DatePicker, Select } from '../../formik/formulario'
import { Coluna, Tabela } from '../../tabela'
import * as Yup from 'yup'
import moment from 'moment'
import { formatarDataParaFormatoUniversal, formatarDataParaFormatoLocal, dataIgualOuAnterior } from '../../../bibliotecas/data'
import { formatarCpf } from '../../../bibliotecas/formatacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  LISTAR_AVALIACOES_RESPONDIDAS,
  LISTAR_PACIENTES_DO_NPS,
  LISTAR_PROFISSIONAIS_DO_NPS
} from '../../../acoes/tipos'

export default function Respostas(props) {
  const {
    listarRespostas,
    history,
    listarPacientes,
    listarProfissionais,
    pacientes,
    profissionais,
    respostas: {
      paginaDeDados,
      ...restanteRespostas
    }
  } = props

  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(restanteRespostas.filtros)

  useEffect(() => {
    listarRespostas(filtros, pagina)
  }, [listarRespostas, filtros, pagina])

  const listar = numeroDaPagina => {
    setPagina(numeroDaPagina)
  }

  const pesquisarAvaliacoesRespondidas = dados => {
    setFiltros({
      ...dados,
      inicio: formatarDataParaFormatoUniversal(dados.inicio),
      fim: formatarDataParaFormatoUniversal(dados.fim)
    })
  }

  const renderizarItemPaciente = ({ value, label, dataDeNascimento, cpf }) => {
    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          <div className='data-de-nascimento'>
            <span>Nasc.: {dataDeNascimento}</span>
          </div>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes`}>
          {cpf &&
            <div className='medico-do-paciente'>
              <span>CPF: {cpf}</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const pesquisarPacientes = termo => {
    if (termo.length > 2) {
      listarPacientes({ termo: termo }, 1, { nome: 'nome', ordem: '+' }, 50)
    }
  }

  const optionsPaciente = pacientes && pacientes.map(x => ({
    value: x.identificadorPublico,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento && formatarDataParaFormatoLocal(new Date(x.dataDeNascimento)),
    cpf: x.cpf && formatarCpf(x.cpf, 11)
  }))

  const renderizarItemProfissional = ({ value, label }) => {
    return (
      <div key={value}>
        <div className='container-item-auto-complete-profissional'>
          <div className='nome'>
            <span>{label}</span>
          </div>
        </div>
      </div>
    )
  }

  const pesquisarProfissionais = termo => {
    if (termo.length > 2) {
      listarProfissionais({ nome: termo }, 1, { nome: 'nome', ordem: '+' }, 50)
    }
  }

  const optionsProfissional = profissionais && profissionais.map(x => ({
    value: x.identificadorPublico,
    label: x.nome,
  }))

  function abrirAvaliacao(item) {
    history.push(`/configuracoes/nps/respostas/${item.identificador}`)
  }

  return (
    <>
      <Spinner operacoes={[
        LISTAR_PACIENTES_DO_NPS,
        LISTAR_PROFISSIONAIS_DO_NPS
      ]}>
        {({ processando }) => (
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              paciente: filtros ? filtros.paciente : '',
              profissional: filtros ? filtros.profissional : '',
              inicio: filtros ? filtros.inicio : '',
              fim: filtros ? filtros.fim : '',
            }}
            acao={pesquisarAvaliacoesRespondidas}
            esquemaDeValidacoes={Yup.object().shape({
              inicio: Yup.string()
                .nullable()
                .required('Obrigatório')
                .test(
                  "inicio_teste",
                  "Deve ser menor que o fim.",
                  function (value) {
                    const { fim } = this.parent;
                    return !value || dataIgualOuAnterior(value, fim);
                  }
                ),
              fim: Yup.string()
                .nullable()
                .required('Obrigatório')
                .test(
                  "fim_teste",
                  "Deve ser maior que o início.",
                  function (value) {
                    const { inicio } = this.parent;
                    return !value || dataIgualOuAnterior(inicio, value);
                  }
                )
            })}

          >
            {({ values }) => (
              <div className='mt-2'>
                <fieldset>
                  <h2 className='form-title'>Alterar Status de Avaliações Respondidas</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <Select
                        nome='paciente'
                        tabIndex={1}
                        titulo='Paciente'
                        itens={pacientes}
                        campoCodigo='identificadorPublico'
                        campoDescricao='nome'
                        formatOptionLabel={renderizarItemPaciente}
                        options={optionsPaciente}
                        isLoading={processando}
                        filterOption={() => true}
                        onInputChange={pesquisarPacientes}
                        noOptionsMessage='Digite 3 caracteres para pesquisar'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <Select
                        nome='profissional'
                        tabIndex={2}
                        titulo='Profissional'
                        itens={profissionais}
                        campoCodigo='identificadorPublico'
                        campoDescricao='nome'
                        formatOptionLabel={renderizarItemProfissional}
                        options={optionsProfissional}
                        isLoading={processando}
                        filterOption={() => true}
                        onInputChange={pesquisarProfissionais}
                        noOptionsMessage='Digite 3 caracteres para pesquisar'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <DatePicker
                        nome='inicio'
                        tabIndex={3}
                        titulo='Início do Período'
                        maxDate={moment().toDate()}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <DatePicker
                        nome='fim'
                        tabIndex={4}
                        titulo='Fim do Período'
                        minDate={moment(values.inicio).toDate()}
                        maxDate={moment().toDate()}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg={2}>
                      <button type='submit' className='button --light w-100 mt-24'>Pesquisar</button>
                    </r-cell>
                  </r-grid>
                </fieldset>
              </div>
            )}
          </FormularioDoFormik>
        )}
      </Spinner>
      <Spinner operacoes={[LISTAR_AVALIACOES_RESPONDIDAS]}>
        <Tabela
          acoes={[
            item => (
              <Permissao key='editar' permissoes={[permissoes.LISTAR_AVALIACOES_DO_NPS_RESPONDIDAS]}>
                <button
                  className='table-items__bt --edit'
                  title='Editar'
                  type='button'
                  onClick={() => abrirAvaliacao(item)}
                >
                  <i className='icon icon-pencil'></i>
                </button>
              </Permissao>
            ),
          ]}
          listar={listar}
          chave='identificador'
          className='--secondary my-2'
          paginaDeDados={paginaDeDados}
        >
          <Coluna
            principal={true}
            className='col-lg-3'
            nome='Paciente'
            campoDeDados='paciente'
          />
          <Coluna
            className='col-lg-3'
            nome='Profissional'
            renderizar={item => item.atendimento.profissional.nome}
          />
          <Coluna
            className='col-lg-2'
            nome='Data do Atendimento'
            renderizar={item => item.atendimento && moment(item.atendimento.inicio).format('DD/MM/YYYY')}
          />
          <Coluna
            className='col-lg-2'
            nome='Respondido em'
            renderizar={item => item.respondidaEm && moment(item.respondidaEm).format('DD/MM/YYYY')}
          />
        </Tabela>
      </Spinner>
    </>
  )
}