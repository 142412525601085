import React, {useState} from 'react'
import {DatePicker, Formulario as FormularioDoFormik, MultiplaEscolha} from '../../../../formik/formulario'
import {contemValor} from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const buscarPontuacao = (frequenciasNaEscalaDaSolidao, valor) => frequenciasNaEscalaDaSolidao.length > 0 && frequenciasNaEscalaDaSolidao.find(x => x.nome === valor).pontuacao
const renderizarTitulo = valor => {  return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Formulario({ frequenciasNaEscalaDaSolidao, selecionada, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const [valoresAtualizados, setValoresAtualizados] = useState(valores)

  return (
    <Spinner operacoes={[
      ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
      ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
      LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO
    ]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={valores}
        acao={salvar}
      >
        {({ setFieldValue, values }) => {
          const setValue = (nome, valor) => {
            setValoresAtualizados({ ...valoresAtualizados, [nome]: valor })
            setFieldValue(nome, valor)
          }
          return (
            <fieldset>
              <h2 className='form-title'>{selecionada ? 'Alterar Avaliação da Solidão' : 'Adicionar Avaliação da Solidão'}</h2>
              <Data />
              <br />
              <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
                <Cabecalho />
                <FaltaDeCompanhia frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={values.faltaDeCompanhia} onChange={setValue} />
                <DeixadoDeLado frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={values.deixadoDeLado} onChange={setValue} />
                <IsoladoDasOutrasPessoas frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={values.isoladoDasOutrasPessoas} onChange={setValue} />
                <Resultado valores={valoresAtualizados} frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} />
              </r-grid>
              <div className='list-btn'>
                <button type='button' className='button --light' onClick={fechar}>Fechar</button>
                <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
              </div>
            </fieldset>
          )
        }}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>ESCALA DE SOLIDÃO - UCLA-3</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function FaltaDeCompanhia({ frequenciasNaEscalaDaSolidao, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='faltaDeCompanhia'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Com que frequência você sente que te falta companhia?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function DeixadoDeLado({ frequenciasNaEscalaDaSolidao, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='deixadoDeLado'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Com que frequência você se sente deixado de lado?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function IsoladoDasOutrasPessoas({ frequenciasNaEscalaDaSolidao, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='isoladoDasOutrasPessoas'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Com que frequência você se sente isolado das outras pessoas?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Resultado({ valores, frequenciasNaEscalaDaSolidao }) {
  const faltaDeCompanhia = valores.faltaDeCompanhia ? buscarPontuacao(frequenciasNaEscalaDaSolidao, valores.faltaDeCompanhia) : 0
  const deixadoDeLado = valores.deixadoDeLado ? buscarPontuacao(frequenciasNaEscalaDaSolidao, valores.deixadoDeLado) : 0
  const isoladoDasOutrasPessoas = valores.isoladoDasOutrasPessoas ? buscarPontuacao(frequenciasNaEscalaDaSolidao, valores.isoladoDasOutrasPessoas) : 0

  const total = faltaDeCompanhia + deixadoDeLado + isoladoDasOutrasPessoas


  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(total || total === 0) &&
       <div className='resultado-questionario'>
         <div><strong>RESULTADO</strong></div>
         <div><strong>{total}</strong></div>
       </div>
      }
    </r-cell>
  )
}