import React, {useEffect, useState} from 'react'
import {Formulario as FormularioDoFormik, CampoNumerico, Checkbox, DatePicker, MultiplaEscolha} from '../../../../formik/formulario'
import {contemValor, ehUmNumeroDecimalValido, numeroDeCasasDecimais} from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'
import moment from 'moment'

import {
  ADICIONAR_IVCF_DO_PACIENTE,
  ALTERAR_IVCF_DO_PACIENTE,
  LISTAR_NIVEIS_DE_SAUDE_RELATIVA
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const deveSerNumerico = valor => contemValor(valor) && !ehUmNumeroDecimalValido(valor) ? 'Deve ser um número' : undefined
const deveSerUmValorEntre = (valor, valorMinimo, valorMaximo) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  valor = valor ? parseFloat(valor.toString().replace(',', '.')) : valor

  return valor >= valorMinimo && valor <= valorMaximo ? undefined : `Deve ser um valor entre ${valorMinimo} e ${valorMaximo}.`
}

const deveSerUmValorMaiorQue = (valor, valorMinimo) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  valor = valor ? parseFloat(valor.toString().replace(',', '.')) : valor

  return valor >= valorMinimo ? undefined : `Deve ser um valor maior que ${valorMinimo}.`
}

const maximoDeCasasDecimais = (valor, casasDecimais) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  return numeroDeCasasDecimais(valor) <= casasDecimais ? undefined : `Máximo de ${casasDecimais} casa(s) decimal(is).`
}

const renderizarTitulo = valor => valor.descricao

export default function Formulario({ idadeDoPaciente, niveisDeSaudeRelativa, selecionado, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const dados = {
      ...valores,
      mobilidade: {
        ...valores.mobilidade,
        capacidadeAerobicaEOuMuscular: {
          ...valores.mobilidade.capacidadeAerobicaEOuMuscular,
          tempoGastoParaPercorrerQuatroMetros: moment.utc(parseFloat(valores.mobilidade.capacidadeAerobicaEOuMuscular.tempoGastoParaPercorrerQuatroMetros.toString().replace(',', '.')) * 1000).format(moment.HTML5_FMT.TIME_MS),
        }
      }
    }

    const {
      paciente,
      usuario,
      ...formulario
    } = dados

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_IVCF_DO_PACIENTE, ALTERAR_IVCF_DO_PACIENTE, LISTAR_NIVEIS_DE_SAUDE_RELATIVA]}>
      <FormularioDoFormik
        reinicializar={true}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar IVCF' : 'Adicionar IVCF'}</h2>
          <Data />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-ivcf'>
            <Cabecalho />
            <Idade idade={idadeDoPaciente} />
            <AutoPercepcaoDaSaude niveisDeSaudeRelativa={niveisDeSaudeRelativa} />
            <AtividadesDeVidaDiaria />
            <Cognicao />
            <Humor />
            <Mobilidade valores={valores.mobilidade} />
            <Comunicacao />
            <ComorbidadesMultiplas />
          </r-grid>
          {(!valores.versao || valores.versao === '3') && 
            <>
              <br />
              <r-grid columns-md={6} columns-lg={12} class='componente-ivcf'>
                <CabecalhoComplementar />
                <Moradia />
              </r-grid>
            </> 
          }
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>ÍNDICE DE VULNERABILIDADE CLÍNICO-FUNCIONAL-20</strong>
      </div>
      <div className='titulo-pontuacao'><strong>Pontuação</strong></div>
    </r-cell>
  )
}

function Idade({ idade }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='idade'>
      <div className='agrupamento'><strong>IDADE</strong></div>
      <div className='pergunta'>
        <strong>1. Qual é a sua idade?</strong>
        <div className='resposta'>R.: {idade} anos.</div>
      </div>
      <div className='opcoes-de-respostas'>
        <div>{idade && idade >= 60 && idade <= 74 ? '(x)' : <>(&nbsp;&nbsp;)</>} 60 a 74 anos⁰</div>
        <div>{idade && idade >= 75 && idade <= 84 ? '(x)' : <>(&nbsp;&nbsp;)</>} 75 a 84 anos¹</div>
        <div>{idade && idade >= 85 ? '(x)' : <>(&nbsp;&nbsp;)</>} ≥ 85 anos³</div>
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function AutoPercepcaoDaSaude({ niveisDeSaudeRelativa }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='auto-percepcao-da-saude'>
      <div className='agrupamento'><strong>AUTO-PERCEPÇÃO DA SAÚDE</strong></div>
      <MultiplaEscolha
        className='pergunta'
        nome='autoPercepcaoDaSaude.nivelDeSaudeRelativa'
        opcoes={niveisDeSaudeRelativa.map(x => ({ codigo: x.nome, descricao: x.nome }))}
        pergunta='2. Em geral, comparando com outras pessoas da sua idade, você diria que sua saúde é: '
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao' />
    </r-cell>
  )
}

function AtividadesDeVidaDiaria() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='atividades-de-vida-diaria'>
      <div className='titulo-vertical'>
        <div><strong>ATIVIDADES DE</strong></div>
        <div><strong>VIDA DIÁRIA</strong></div>
      </div>
      <div className='itens'>
        <AvdInstrumental
          pontuacao={4}
        />
        <AvdBasica
          pontuacao={6}
        />
      </div>
    </r-cell>
  )
}

function AvdInstrumental() {
  return (
    <div className='avd-instrumental'>
      <div className='agrupamento'>
        <div className='titulo-avd-instrumental'>AVD Instrumental</div>
        <div className='detalhes'>Resposta positiva vale 4 pontos cada. Todavia, a pontuação máxima do item é de 4 pontos, mesmo que o idoso tenha respondido sim para todas as questões 3, 4 e 5. </div>
      </div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='deixou-de-fazer-compras'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeFazerCompras'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não faz compras por outros motivos que não a saúde' }]}
          pergunta='3. Por causa de sua saúde ou condição física, você deixou de fazer compras?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='deixou-de-controlar-suas-financas'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeControlarSuasFinancas'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não controla o dinheiro por outros motivos que não a saúde' }]}
          pergunta='4. Por causa de sua saúde ou condição física, você deixou de controlar seu dinheiro, gastos ou pagar as contas de sua casa?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='deixou-de-realizar-pequenos-trabalhos-domesticos'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeRealizarPequenosTrabalhosDomesticos'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não faz mais pequenos trabalhos domésticos por outros motivos que não a saúde' }]}
          pergunta='5. Por causa de sua saúde ou condição física, você deixou de realizar pequenos trabalhos domésticos, como lavar louça, arrumar a casa ou fazer limpeza leve?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao'>Máximo 4 pts</div>
    </div>
  )
}

function AvdBasica() {
  return (
    <div className='avd-basica'>
      <div className='agrupamento'>
        AVD Básica
      </div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='deixou-de-tomar-banho-sozinho'
          nome='atividadesDeVidaDiaria.avdBasica.deixouDeTomarBanhoSozinho'
          opcoes={[{ codigo: true, descricao: 'Sim⁶' }, { codigo: false, descricao: 'Não' }]}
          pergunta='6. Por causa de sua saúde ou condição física, você deixou de tomar banho sozinho?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function Cognicao() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cognicao'>
      <div className='agrupamento'><strong>COGNIÇÃO</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='estah-ficando-esquecido'
          nome='cognicao.estahFicandoEsquecido'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='7. Algum familiar ou amigo falou que você está ficando esquecido?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='esquecimento-estah-piorando'
          nome='cognicao.esquecimentoEstahPiorando'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='8. Este esquecimento está piorando nos últimos meses?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='esquecimento-impede-realizacao-de-atividades-do-cotidiano'
          nome='cognicao.esquecimentoImpedeRealizacaoDeAtividadesDoCotidiano'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='9. Este esquecimento está impedindo a realização de alguma atividade do cotidiano?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function Humor() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='humor'>
      <div className='agrupamento'><strong>HUMOR</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='estah-com-desanimo-tristeza-ou-desesperanca'
          nome='humor.ficouComDesanimoTristezaOuDesesperanca'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='10. No último mês, você ficou com desânimo, tristeza ou desesperança?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='perdeu-interesse-em-atividades-prazerosas'
          nome='humor.perdeuInteresseEmAtividadesPrazerosas'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='11. No último mês, você perdeu o interesse ou prazer em atividades anteriormente prazerosas?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function Mobilidade({ valores = [] }) {
  const { capacidadeAerobicaEOuMuscular } = valores

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='mobilidade'>
      <div className='titulo-vertical'>
        <div><strong>MOBILIDADE</strong></div>
      </div>
      <div className='itens'>
        <AlcancePreensaoEPinca />
        <CapacidadeAerobicaEOuMuscular valor={capacidadeAerobicaEOuMuscular} />
        <Marcha />
        <ContinenciaEsfincteriana />
      </div>
    </r-cell>
  )
}

function AlcancePreensaoEPinca() {
  return (
    <div className='alcance-preensao-e-pinca'>
      <div className='agrupamento'>Alcance, preensão e pinça</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='eh-incapaz-de-elevar-os-bracos-acima-do-ombro'
          nome='mobilidade.alcancePreensaoEPinca.ehIncapazDeElevarOsBracosAcimaDoOmbro'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='12. Você é incapaz de elevar os braços acima do nível do ombro?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='eh-incapaz-de-manusear-pequenos-objetos'
          nome='mobilidade.alcancePreensaoEPinca.ehIncapazDeManusearPequenosObjetos'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='13. Você é incapaz de manusear ou segurar pequenos objetos?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function CapacidadeAerobicaEOuMuscular({ valor = {} }) {
  const { altura, circunferenciaDaPanturrilha, tempoGastoParaPercorrerQuatroMetros, peso, imc } = valor

  return (
    <div className='capacidade-aerobica-e-ou-muscular edicao'>
      <div className='agrupamento'>Capacidade aeróbica e/ou muscular</div>
      <div className='pergunta'>
        <div className='texto-da-pergunta'><strong>14. Informe os dados abaixo:</strong></div>
        <div className='condicoes'>
          <TevePercaDePesoNaoIntencional />
          <IndiceDeMassaCorporal altura={altura} peso={peso} imc={imc} />
          <CircunferenciaDaPanturrilha circunferenciaDaPanturrilha={circunferenciaDaPanturrilha}/>
          <TempoGastoParaPercorrerQuatroMetros tempoGastoParaPercorrerQuatroMetros={tempoGastoParaPercorrerQuatroMetros} />
        </div>
      </div>
      <div className='pontuacao'>Máximo 2 pts</div>
    </div>
  )
}

function TevePercaDePesoNaoIntencional() {
  return (
    <MultiplaEscolha
      className='teve-perca-de-peso-nao-intencional'
      nome='mobilidade.capacidadeAerobicaEOuMuscular.tevePerdaDePesoNaoIntencional'
      opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
      pergunta={<>Perda de peso não intencional de 4,5 kg ou 5% do peso corporal no último ano <span className='negrito'>ou</span> 6 kg nos últimos 6 meses <span className='negrito'>ou</span> 3 kg no último mês;</>}
      renderizarTitulo={renderizarTitulo}
      validar={obrigatorio}
    />
  )
}

function IndiceDeMassaCorporal({ altura, peso, imc }) {
  const [pesoInformado, setPesoInformado] = useState(peso)
  const [alturaInformada, setAlturaInformada] = useState(altura)
  const [imcCalculado, setImcCalculado] = useState(imc)

  useEffect(() => {
    const alturaPossuiInconsistencias = obrigatorio(alturaInformada) || deveSerNumerico(alturaInformada) || deveSerUmValorEntre(alturaInformada, 30, 240)
    const pesoPossuiInconsistencias = obrigatorio(pesoInformado) || deveSerNumerico(pesoInformado) || deveSerUmValorEntre(pesoInformado, 2, 350)

    if (!alturaPossuiInconsistencias && !pesoPossuiInconsistencias) {
      const pesoFormatado = pesoInformado.toString().replace(',', '.')
      const alturaFormatada = alturaInformada.toString().replace(',', '.')
      const alturaEmMetros = alturaFormatada / 100

      setImcCalculado(Math.round((pesoFormatado / (alturaEmMetros * alturaEmMetros)) * 100) / 100)
    } else {
      setImcCalculado(null)
    }
  }, [alturaInformada, pesoInformado])


  const validacaoPeso = valor => {
    if (!valor) {
      return obrigatorio(valor)
    } else {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 2, 350) ||
        maximoDeCasasDecimais(valor, 2))
    }
  }

  const validacaoAltura = valor => {
    if (!valor) {
      return obrigatorio(valor)
    } else {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 30, 240) ||
        maximoDeCasasDecimais(valor, 1))
    }
  }

  return (
    <div className='indice-de-massa-corporal'>
      <span>Índice de Massa Corporal (IMC) menor que 22 kg/m²?</span>
      <div className='dados-para-calculo-imc'>
        <CampoNumerico
          nome='mobilidade.capacidadeAerobicaEOuMuscular.peso'
          escala='kg'
          titulo='Peso'
          margem='5px'
          onChange={setPesoInformado}
          validar={validacaoPeso}
          numeroDeCasasDecimais={1}
        />
        <CampoNumerico
          nome='mobilidade.capacidadeAerobicaEOuMuscular.altura'
          escala='cm'
          titulo='Altura'
          onChange={setAlturaInformada}
          validar={validacaoAltura}
        />
        {imcCalculado &&
          <div className='resultado-calculo-imc'>
            <span>IMC calculado: </span>
            <span>{imcCalculado.toString().replace('.', ',')} kg/m²</span>
          </div>
        }
      </div>
      <div className='container-resposta-imc' >
        {imcCalculado && <span className='resposta-imc'>R.: {imcCalculado < 22 ? 'SIM²' : 'NÃO'}</span>}
      </div>
    </div>
  )
}

function CircunferenciaDaPanturrilha({ circunferenciaDaPanturrilha }) {
  const [circunferencia, setCircunferencia] = useState(circunferenciaDaPanturrilha)

  const validacao = valor => {
    if (!valor) {
      return obrigatorio(valor)
    } else {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorMaiorQue(valor, 1) ||
        maximoDeCasasDecimais(valor, 2))
    }
  }

  const mostrarResultado = () => {
    if (circunferencia && !validacao(circunferencia)) {
      return <span className='resposta-imc'>R.: {circunferencia < 31 ? 'SIM²' : 'NÃO'}</span>
    } else {
      return ''
    }
  }

  return (
    <div className='circunferencia-da-panturrilha'>
      <span>{'Circunferência da panturrilha < 31 cm?'}</span>
      <div className='visualizacao'>
        <CampoNumerico
          nome='mobilidade.capacidadeAerobicaEOuMuscular.circunferenciaDaPanturrilha'
          escala='cm'
          margem='5px'
          onChange={setCircunferencia}
          validar={validacao}
          numeroDeCasasDecimais={1}
        />
      </div>
      <div className='container-resposta-imc' >
        {mostrarResultado()}
      </div>
    </div>
  )
}

function TempoGastoParaPercorrerQuatroMetros({ tempoGastoParaPercorrerQuatroMetros }) {
  const [tempo, setTempo] = useState(tempoGastoParaPercorrerQuatroMetros)

  const validacao = valor => {
    if (!valor) {
      return obrigatorio(valor)
    } else {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 1, 300) ||
        maximoDeCasasDecimais(valor, 2))
    }
  }

  const mostrarResultado = () => {
    if (tempo && !validacao(tempo)) {
      return <span className='resposta-imc'>R.: {tempo > 5 ? 'SIM²' : 'NÃO'}</span>
    } else {
      return ''
    }
  }

  return (
    <div className='tempo-gasto-para-percorrer-quatro-metros'>
      <span>Tempo gasto no teste de velocidade da marcha (4 metros) {'>'} 5 segundos? </span>
      <div className='visualizacao'>
        <CampoNumerico
          nome='mobilidade.capacidadeAerobicaEOuMuscular.tempoGastoParaPercorrerQuatroMetros'
          escala='s'
          margem='5px'
          onChange={setTempo}
          validar={validacao}
          numeroDeCasasDecimais={1}
        />
      </div>
      <div className='container-resposta-imc' >
        {mostrarResultado()}
      </div>
    </div>
  )
}

function Marcha() {
  return (
    <div className='marcha'>
      <div className='agrupamento'>Marcha</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-dificuldade-para-caminhar'
          nome='mobilidade.marcha.temDificuldadeParaCaminhar'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='15. Você tem dificuldade para caminhar capaz de impedir a realização de alguma atividade do cotidiano?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='teve-quedas-recente'
          nome='mobilidade.marcha.teveQuedasRecentes'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='16. Você teve duas ou mais quedas no último ano?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function ContinenciaEsfincteriana() {
  return (
    <div className='continencia-esfincteriana'>
      <div className='agrupamento'>Continência esfincteriana</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='perdeu-urina-ou-fezes-sem-querer'
          nome='mobilidade.continenciaEsfincteriana.perdeUrinaOuFezesSemQuerer'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='17. Você perde urina ou fezes, sem querer, em algum momento?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function Comunicacao() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='comunicacao'>
      <div className='titulo-vertical'>
        <div><strong>COMUNICAÇÃO</strong></div>
      </div>
      <div className='itens'>
        <Visao />
        <Audicao />
      </div>
    </r-cell>
  )
}

function Visao() {

  return (
    <div className='visao'>
      <div className='agrupamento'>Visão</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-problemas-de-visao'
          nome='comunicacao.visao.temProblemasDeVisao'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='18. Você tem problemas de visão capazes de impedir a realização de alguma atividade do cotidiano? É permitido o uso de óculos ou lentes de contato'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function Audicao() {
  return (
    <div className='audicao'>
      <div className='agrupamento'>Audição</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-problemas-de-audicao'
          nome='comunicacao.audicao.temProblemasDeAudicao'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='19. Você tem problemas de audição capazes de impedir a realização de alguma atividade do cotidiano? É permitido o uso de aparelhos de audição.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </div>
  )
}

function ComorbidadesMultiplas() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='comorbidades-multiplas'>
      <div className='titulo-vertical'>
        <div><strong>COMORBIDADES</strong></div>
        <div><strong>MÚLTIPLAS</strong></div>
      </div>
      <div className='alguma-das-tres-condicoes-relacionadas'>
        <div className='agrupamento'>
          <div>Polipatologia</div>
          <div>Polifarmácia</div>
          <div>Internação recente<br />{'(<'}6 meses)</div>
        </div>
        <div className='pergunta'>
          <strong>20. Você tem alguma das três condições abaixo relacionadas?</strong>
          <div>
            <Checkbox
              classname='estilo-checkbox'
              nome='comorbidadesMultiplas.polipatologia.temCincoOuMaisDoencasCronicas'
              titulo='Cinco ou mais doenças crônicas;'
            />
          </div>
          <div>
            <Checkbox
              classname='estilo-checkbox'
              nome='comorbidadesMultiplas.polifarmacia.usaCincoOuMaisMedicamentos'
              titulo='Uso regular de cinco ou mais medicamentos diferentes, todo dia;'
            />
          </div>
          <div>
            <Checkbox
              classname='estilo-checkbox'
              nome='comorbidadesMultiplas.internacaoRecente.teveInternacaoRecente'
              titulo='Internação recente, nos últimos 6 meses.'
            />
          </div>
        </div>
        <div className='pontuacao'><span>Máximo 4 pts</span></div>
      </div>
    </r-cell>
  )
}

function CabecalhoComplementar() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>INFORMAÇÕES COMPLEMENTARES AO IVCF</strong>
      </div>
    </r-cell>
  )
}

function Moradia() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='moradia'>
      <div className='agrupamento'><strong>MORADIA</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='mora-sozinho'
          nome='moradia.moraSozinho'
          opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
          pergunta='1. Você mora sozinho?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}