import React, { useMemo, useEffect } from 'react'
import Spinner from '../../spinner'
import { Link } from 'react-router-dom'
import Permissao from '../../seguranca/permissao'
import { Tabela, Coluna } from '../../tabela/'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { LISTAR_UNIDADES_DA_EMPRESA } from '../../../acoes/tipos'
import * as permissoes from '../../seguranca/permissoes'

export default function Lista({ history, listarUnidadesDaEmpresa, match, ...rest }) {
  useEffect(() => { listarUnidadesDaEmpresa() }, [listarUnidadesDaEmpresa])
  
  const unidades = useMemo(() => {
    return converterObjetoParaArray(rest.unidades)
  }, [rest.unidades])

  return (
    <>
      <Spinner operacoes={[LISTAR_UNIDADES_DA_EMPRESA]}>
        <Tabela
          className='table-items mt-1 mb-4'
          dados={unidades}
          chave='identificador'
          acoes={[
            item => (
              <Permissao key='editar' permissoes={[permissoes.ALTERAR_UNIDADE_DA_EMPRESA]}>
                <Link
                  to={`/configuracoes/empresa/unidades/${item.identificador}`}
                  className='table-items__bt --edit'
                  title='Editar'
                >
                  <i className='icon icon-pencil'></i>
                </Link>
              </Permissao>
            )
          ]}
        >
          <Coluna
            campoDeDados='nome'
            className='col-xs-8 col-md-10 col-lg-4'
            principal={true}
            nome='Nome'
          />
          <Coluna
            className='col-lg-2'
            nome='Sigla'
            campoDeDados='sigla'
          />
          <Coluna
            className='col-lg-2'
            nome='Cor'
            renderizar={item => <span className='table-items__bcolor' style={{ background: item.cor }}>{item.cor}</span>}
          />
          <Coluna
            className='col-lg-2'
            nome='Tipo'
            campoDeDados='tipo'
          />
          <Coluna
            className='col-lg-1'
            nome='Ativa'
            renderizar={item => <p className='table-items__mobile-value'>{item.ativa ? 'Sim' : 'Não'}</p>}
          />
        </Tabela>
      </Spinner>
    </>
  )
}