import React, { useEffect } from 'react'
import { useProntuario } from '../../contexto'
import { Select } from '../../../formik/formulario'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'

export default function PrevisaoDeRetorno({ metadados, valor, leitura }) {
  const { previsoesDeRetorno, listarPrevisoesDeRetorno } = useProntuario()

  useEffect(() => { listarPrevisoesDeRetorno() }, [listarPrevisoesDeRetorno])

  const obrigatorio = metadados?.obrigatorio === 'true'

  const validarPrevisaoDeRetorno = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null
    const retornoDoPaciente = previsoesDeRetorno && previsoesDeRetorno.length > 0 && previsoesDeRetorno.filter(x => x.codigo === valor)[0].nome

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Previsão de Retorno: </strong>
            {retornoDoPaciente}
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={3} span-lg={3}>
      <Select
        nome='previsaoDeRetorno'
        titulo={`Previsão de Retorno ${obrigatorio ? '*' : ''}`}
        itens={previsoesDeRetorno}
        campoCodigo='codigo'
        campoDescricao='nome'
        validar={validarPrevisaoDeRetorno}
      />
    </r-cell>
  )
}