import React, { useRef, useLayoutEffect, useState } from 'react'
import { Bar, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function GraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado({ dados, download }) {
  const TooltipInternacoesPorFraturaDeFemur = (props) => {
    const { active, dados, payload } = props

    if (active) {
      const { label } = props
      const dadosDaData = dados.find(x => x.data === label)
      const payloadMeta = payload.find(x => x.dataKey === 'metaAcumulada')
      const payloadInternacoesPorFraturaDeFemurAcumuladas = payload.find(x => x.dataKey === 'resultadoAcumulado')

      if (!dadosDaData) {
        return null
      }

      return (
        <div className='tooltip-personalizado'>
          <p className='data'>Data: {dadosDaData.data}</p>
          <p>Pacientes: {dadosDaData.pacientes}</p>
          <p style={{ color: payloadInternacoesPorFraturaDeFemurAcumuladas.color }}>Internações do dia: {dadosDaData.resultado}</p>
          <p>Meta do dia: {dadosDaData.meta}</p>
          <p style={{ color: payloadInternacoesPorFraturaDeFemurAcumuladas.color }}>Internações acumuladas: {dadosDaData.resultadoAcumulado}</p>
          <p style={{ color: payloadMeta.color }}>Meta acumulada: {dadosDaData.metaAcumulada}</p>
        </div>
      )
    }

    return null
  }

  const graficos = useRef(null);
  const [laguraDosGraficos, setLaguraDosGraficos] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setLaguraDosGraficos(graficos.current ? graficos.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateSize);
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <div className='container'>
      <div className='action-bar mt-4'>
        <h2 className='title-adicionar'>Internações por fratura de femur (meta versus realizado)</h2>
        <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_INTERNACOES]}>
          {dados.length > 0 &&
            <button onClick={download} className='botao-download'>Download</button>
          }
        </Permissao>
      </div>
      <div className='main-content-form'>
        <div key={laguraDosGraficos} className='graficos' ref={graficos}>
          {dados.length > 0 &&
            <ComposedChart
              width={laguraDosGraficos}
              height={400}
              data={dados}
              margin={{
                top: 8,
                right: 16,
                bottom: 8,
                left: 0,
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='data' />
              <YAxis type='number' domain={[0, recuperarLimiteEixoY(dados)]} />
              <Tooltip content={<TooltipInternacoesPorFraturaDeFemur dados={dados} />} />
              <Legend />
              <Bar
                barSize={20}
                dataKey='resultado'
                fill='#413ea0'
                name='Internações'
              />
              <Line
                activeDot={{ r: 5 }}
                dataKey='metaAcumulada'
                dot={false}
                name='Meta'
                stroke='#C5192F'
                strokeWidth={1.3}
                type='monotone'
              />
              <Line
                activeDot={{ r: 5 }}
                dataKey='resultadoAcumulado'
                dot={false}
                name='Realizado'
                stroke='#413ea0'
                strokeWidth={1.3}
                type='monotone'
              />
            </ComposedChart>
          }
        </div>
      </div>
    </div>
  )
}

const recuperarLimiteEixoY = dados => {
  const resultadoAcumulado = parseInt(dados[dados.length - 1].resultadoAcumulado)
  const metaAcumulada = parseInt(dados[dados.length - 1].metaAcumulada)

  return (resultadoAcumulado > metaAcumulada ? resultadoAcumulado : metaAcumulada) + 1
}