import axios from 'axios'

const clientes = {}

const criarClienteDoAxios = urlBase => {
  const api = axios.create({
    baseURL: urlBase
  })

  api.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config

      if (error.response.status === 401 && !originalRequest._retry) {
        const erro = {
          ...error,
          mensagens: [{
            tipo: 'Alerta Mínimo',
            texto: 'Sua sessão expirou. Digite seu email e senha novamente para entrar no sistema.'
          }]
        }

        throw erro
      }

      if (error.response.request.responseType === 'arraybuffer') {
        return Promise.reject(JSON.parse(Buffer.from(error.response.data).toString('utf8')))
      }

      return Promise.reject(error.response)
    }
  )

  return api
}

export const recuperarClienteAxios = url => {
  if (!clientes[url]) {
    clientes[url] = criarClienteDoAxios(url)
  }
  return clientes[url]
}

export const configurarToken = token => {
  Object.keys(clientes).forEach(key => {
    clientes[key].defaults.headers.common['Authorization'] = token
  })
}

export const removerConfiguracaoDeToken = () => {
  Object.keys(clientes).forEach(key => {
    delete clientes[key].defaults.headers.common['Authorization']
  })
}