import api from '../api-major'

const cancelarAtendimento = (atendimento, dadosDoCancelamento) => api.put(`/pacientes/${atendimento.paciente.identificador}/prontuario/${atendimento.identificador}/status`, dadosDoCancelamento)

const finalizarAtendimento = atendimento => api.put(`/pacientes/${atendimento.paciente.identificador}/prontuario/${atendimento.identificador}/status`, { status: "finalizado_pelo_profissional" })

const iniciarAtendimento = (identificadorDoPaciente, dadosDoAtendimento) => api.post(`/pacientes/${identificadorDoPaciente}/prontuario`, dadosDoAtendimento)

const listarAtendimentosIniciados = () => api.get('/usuario/atendimentos/iniciados?tamanhoDaPagina=1000000')

const listarAtendimentosNaoAssinados = (pagina, ordenacao, tamanhoDaPagina = 30) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-Fim'
  const tamanhoDaPaginaFormatodo = `&tamanhoDaPagina=${tamanhoDaPagina}`

  return api.get(`/usuario/atendimentos/nao-assinados${paginacao}${tamanhoDaPaginaFormatodo}${ordem}`)
}

const recuperarAtendimento = (identificadorDoPaciente, identificador) => api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificador}`)

const salvarSecaoDoAtendimento = (atendimento, dadosDaSecao) => api.put(`/pacientes/${atendimento.paciente.identificador}/prontuario/${atendimento.identificador}`, dadosDaSecao)

export default {
  cancelarAtendimento,
  finalizarAtendimento,
  iniciarAtendimento,
  listarAtendimentosIniciados,
  listarAtendimentosNaoAssinados,
  recuperarAtendimento,
  salvarSecaoDoAtendimento,
}