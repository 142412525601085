import React, { useEffect, useState } from 'react'
import Spinner from '../spinner'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import { contemValor } from '../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../bibliotecas/data'
import * as Yup from 'yup'
import queryString from 'query-string'
import { RECUPERAR_AVALIACAO, RESPONDER_AVALIACAO } from '../../acoes/tipos'

export default function Avaliacao(props) {
  const {
    avaliacao,
    responderAvaliacao,
    location,
    history,
    recuperarAvaliacao,
    identificadorDaEmpresa,
    identificadorDaAvaliacao,
    logotipoDaEmpresa
  } = props

  const [status, setStatus] = useState(false)
  const parametros = queryString.parse(location.search)

  useEffect(() => {
    (async () => {
      const status = await recuperarAvaliacao(identificadorDaEmpresa, identificadorDaAvaliacao)
      setStatus(status)
    })()
  }, [identificadorDaEmpresa, identificadorDaAvaliacao, recuperarAvaliacao])

  const renderizarDeAcordoComStatus = () => {
    if (status === 'sucesso') {
      return (
        <Enviada
          avaliacao={avaliacao}
          responder={responderAvaliacao}
          identificadorDaEmpresa={identificadorDaEmpresa}
          identificadorDaAvaliacao={identificadorDaAvaliacao}
          parametros={parametros}
          history={history}
          logotipoDaEmpresa={logotipoDaEmpresa}
        />
      )
    }

    return <Mensagem logotipoDaEmpresa={logotipoDaEmpresa} texto={status} />
  }

  return (
    <Spinner operacoes={[RECUPERAR_AVALIACAO]}>
      {({ processando }) => (
        <>
          {processando ?
            <div className='container-nps'>
              <div className='logo'><img src={logotipoDaEmpresa} alt='Logotipo da empresa' /></div>
              <h2>Carregando...</h2>
            </div>
            : renderizarDeAcordoComStatus()}
        </>
      )}
    </Spinner>
  )
}

function Mensagem({ logotipoDaEmpresa, texto }) {
  return (
    <div className='container-nps'>
      <div className='logo'><img src={logotipoDaEmpresa} alt='Logotipo da empresa' /></div>
      {texto &&
        <h2>{texto}</h2>
      }
      {!texto &&
        <>
          <h2>Algo de errado aconteceu :(</h2>
          <h2>Acesse o e-mail recebido e tente novamente.</h2>
        </>
      }
    </div>
  )
}

function Enviada(props) {
  const {
    identificadorDaEmpresa,
    identificadorDaAvaliacao,
    avaliacao,
    responder,
    parametros,
    history,
    logotipoDaEmpresa
  } = props

  const avaliacoes = [
    { nota: '0', cor: '#b72025' },
    { nota: '1', cor: '#d62027' },
    { nota: '2', cor: '#f05223' },
    { nota: '3', cor: '#f36f21' },
    { nota: '4', cor: '#faa823' },
    { nota: '5', cor: '#ffca27' },
    { nota: '6', cor: '#ecdb12' },
    { nota: '7', cor: '#e8e73d' },
    { nota: '8', cor: '#c5d92d' },
    { nota: '9', cor: '#afd136' },
    { nota: '10', cor: '#64b64d' }
  ]

  const paciente = avaliacao ? avaliacao.atendimento.paciente.split(' ')[0] : ''
  const dataDoAtendimento = avaliacao ? formatarDataParaFormatoLocal(avaliacao.atendimento.data) : ''
  const nomeDaEmpresa = avaliacao ? avaliacao.atendimento.empresa : ''

  const enviar = async formulario => {
    const respostas = formulario.perguntas.map(x => ({ codigo: x.codigo, resposta: x.valor }))
    const avaliacao = {
      avaliacao: formulario.avaliacao,
      canal: parametros.canal || 'email',
      destinatario: parametros.destinatario,
      respostas
    }
    const respondeu = await responder(identificadorDaEmpresa, avaliacao)

    if (respondeu) {
      history.push(`/nps/${identificadorDaEmpresa}/${identificadorDaAvaliacao}/agradecimento`)
    }
  }

  const renderizarPerguntas = (index, item, setFieldValue, values, errors, touched) => {
    const componente = item.tipo === 'nota_de_0_a_10' ? EscalaDeAvaliacao : TextoDeAvaliacao

    return (
      <React.Fragment key={index}>
        <Field
          component={componente}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          nome={`perguntas.${index}.valor`}
          pergunta={item.texto}
          tipo={item.tipo}
          avaliacoes={avaliacoes}
        />
      </React.Fragment>
    )
  }

  const pontuacaoVindaDoCanal = parametros.pontuacao || parametros.nota
  const perguntaVindaDoCanal = parametros.pergunta || 'NOTA_DA_CLINICA'

  const ordenarPerguntas = (a, b) => {
    return a.posicao > b.posicao
  }

  return (
    <Spinner operacoes={[RESPONDER_AVALIACAO]}>
      <FormularioDoFormik
        valoresIniciais={{
          avaliacao: identificadorDaAvaliacao ? identificadorDaAvaliacao : '',
          perguntas:
            avaliacao.perguntas.map(x => ({
              ...x,
              valor: x.codigo === perguntaVindaDoCanal ? pontuacaoVindaDoCanal : ''
            })).sort(ordenarPerguntas)
        }}
        acao={enviar}
        esquemaDeValidacoes={Yup.object().shape({
          perguntas: Yup.array()
            .of(
              Yup.object().shape({
                valor: Yup.string()
                  .when('obrigatoria', {
                    is: val => val,
                    then: Yup.string().required('Obrigatório.')
                  }),
              })
            ),
        })}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <div className='container-nps'>
            <div className='logo'><img src={logotipoDaEmpresa} alt='Logotipo da empresa' /></div>
            <div className='text-justify my-1'><h1>Olá {paciente}, nos conte como foi o seu atendimento do dia {dataDoAtendimento} na {nomeDaEmpresa}.</h1></div>
            <FieldArray
              name='perguntas'
            >
              <div className='pergunta my-3'>
                {values.perguntas.map((item, index) => renderizarPerguntas(index, item, setFieldValue, values, errors, touched))}
              </div>
            </FieldArray>
            <div>
              <button type='submit' className='botao-enviar'><i className='icon icon-check' />Enviar</button>
            </div>
          </div>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

function EscalaDeAvaliacao({ nome, avaliacoes, pergunta, setFieldValue, values, errors }) {
  const recuperarValor = (caminho, objeto) => {
    if (!contemValor(caminho)) return null
    if (!objeto) return null

    let valor = objeto

    for (const item of caminho.split('.')) {
      valor = valor[item]

      if (!valor) {
        break
      }
    }

    return valor
  }

  const erro = recuperarValor(nome, errors)
  const valor = recuperarValor(nome, values)

  const onChange = nota => { setFieldValue(nome, nota) }

  const selecionar = nota => {
    let notaEscolhida = ''

    if (valor !== nota) {
      notaEscolhida = nota
    } else {
      notaEscolhida = ''
    }

    onChange(notaEscolhida)
  }

  return (
    <div className={`form-group ${erro && 'has-error'}`}>
      <p>{pergunta}</p>
      <div className='escala'>
        {avaliacoes.map((avaliacao, index) => (
          <div
            className={valor ? (avaliacao.nota === valor ? 'escala_nota selecionada' : 'escala_nota nao-selecionada') : 'escala_nota'}
            key={index}
            style={{ backgroundColor: avaliacao.cor }}
            onClick={() => selecionar(avaliacao.nota)}
          >
            {avaliacao.nota}
          </div>
        ))}
      </div>
      <ErrorMessage name={nome} className='form-tip' component='span' />
    </div>
  )
}

function TextoDeAvaliacao({ nome, pergunta }) {
  return (
    <div className='mt-2'>
      <Input
        titulo={pergunta}
        nome={nome}
        as='textarea'
      />
    </div>
  )
}