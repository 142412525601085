import React from 'react'
import Senha from '../senha'
import * as Yup from 'yup'
import Breadcrumb from '../breadcrumb'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import { Formulario as FormularioDoFormik, Input} from '../formik/formulario'
import Spinner from '../spinner'
import { ALTERAR_SENHA_DO_USUARIO } from '../../acoes/tipos'

export default function AlterarSenha({ alterarSenhaDoUsuario, history }) {
  const { usuario } = useUsuarioLogado()
  const alterar = formulario => alterarSenhaDoUsuario(usuario.email, formulario.senha, formulario.novaSenha)

  return (
    <div className='page-alterar-senha'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[ALTERAR_SENHA_DO_USUARIO]}>
            <FormularioDoFormik
              acao={alterar}
              valoresIniciais={{
                senha: '',
                novaSenha: '',
                repetirSenha: ''
              }}
              esquemaDeValidacoes={Yup.object().shape({
                senha: Yup.string().required('Obrigatório'),
                novaSenha: Yup.string().required('Obrigatório'),
                repetirSenha: Yup.string()
                  .required('Obrigatório')
                  .equalTo(Yup.ref('novaSenha'), 'Valor diferente de "Nova senha".'),
              })}
            >
              <fieldset>
                <h2 className='form-title'>Alterar Senha</h2>
                <r-grid columns-lg='12' columns-md='6'>
                  <r-cell span={4} span-md={3}>
                    <Input
                      titulo='Senha atual *'
                      component={Senha}
                      nome='senha'
                      tabIndex={1}
                    />
                  </r-cell>
                  <r-cell span={4} span-md='1-3'>
                    <Input
                      titulo='Nova senha *'
                      component={Senha}
                      nome='novaSenha'
                      tabIndex={2}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={3}>
                    <Input
                      titulo='Confirmar nova senha *'
                      component={Senha}
                      nome='repetirSenha'
                      tabIndex={3}
                    />
                  </r-cell>
                </r-grid>
              </fieldset>
              <div className='list-btn'>
                <button type='button' tabIndex={4} onClick={() => history.goBack()} className='button --light'>Cancelar</button>
                <button type='submit' tabIndex={5} className='button --primary'>Salvar Alterações</button>
              </div>
            </FormularioDoFormik>
          </Spinner>
        </div>
      </div>
    </div>
  )
}