import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import { Cpf, DatePicker, Formulario as FormularioDoFormik, Input, Select, Telefone } from '../../formik/formulario'
import { SelectReact } from '../../formik/select'
import CampoDaTarefa from './campo-da-tarefa'
import { useTarefas } from '../contexto'
import { ComponenteLeitura } from '../card'
import { Stethoscope, User } from '../../../design/icones'
import { extrairNomeResumido } from '../../../bibliotecas/texto'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA, LISTAR_SEXOS } from '../../../acoes/tipos'

export default function Paciente({ definir, obrigatorio, processando, valor, leitura, rotulo, preCadastro, exibirTelefones }) {
  const icone = <User />
  const titulo = rotulo ?? 'Paciente'

  const [identificadorDoPaciente, setIdentificadorDoPaciente] = useState('')
  const [termoPesquisado, setTermoPesquisado] = useState('')
  const [valorDefault, setValorDefault] = useState('')
  const [exibirPreCadastroDoPaciente, setExibirPreCadastroDoPaciente] = useState(false)
  const [abrirTelefones, setAbrirTelefones] = useState(false)

  const {
    listarPacientesDoTarefas,
    pacientes,
    recuperarPacientePorIdentificadorPublico,
    adicionarPreCadastroDoPacienteDaTarefa,
    listarSexos,
    sexos
  } = useTarefas()

  const termo = valor && valor.nome ? (valor.dataDeNascimento ? `${valor.nome} ${formatarDataParaFormatoLocal(valor.dataDeNascimento)}` : valor.nome) : undefined
  const identificador = valor && valor.identificador

  useEffect(() => {
    if (termo || termoPesquisado) {
      const termoDaPesquisa = termoPesquisado ? termoPesquisado : termo
      listarPacientesDoTarefas({ termo: termoDaPesquisa }, 1, { nome: 'nome', ordem: '+' }, 50)
    }
  }, [listarPacientesDoTarefas, termo, termoPesquisado])

  const renderizarAutoCompletePaciente = ({ value, label, dataDeNascimento, medicoDeReferencia }) => {
    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          <div className='data-de-nascimento'>
            <span>Nasc.: {moment(dataDeNascimento).format('DD/MM/YYYY')}</span>
          </div>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${medicoDeReferencia ? 'possui-medico' : ''}`}>
          {medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Médico(a): {extrairNomeResumido(medicoDeReferencia.nome)}</span>
            </div>
          }
          {!medicoDeReferencia &&
            <div className='medico-do-paciente'>
              <span>Não possui médico de referência.</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const optionsPaciente = useMemo(() => pacientes.map(x => ({
    value: x.identificador,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento,
    medicoDeReferencia: x.medicoDeReferencia,
  })), [pacientes])

  const pesquisarPacientesAutocomplete = termo => {
    setTermoPesquisado(termo)
    setValorDefault(termo)

    if (!termo || termo.length <= 2) return

    listarPacientesDoTarefas({ termo }, 1, { nome: 'nome', ordem: '+' }, 50)
  }

  useEffect(() => {
    (async () => {
      if (leitura && identificador) {
        const paciente = await recuperarPacientePorIdentificadorPublico(identificador)

        if (paciente) {
          setIdentificadorDoPaciente(paciente.identificador)
        }
      }
    })()
  }, [leitura, identificador, recuperarPacientePorIdentificadorPublico])

  if (leitura) {
    if (!valor) return null

    const telefones = valor.telefones ?? []

    function ordenarTelefones(a, b) {
      return a.proprietario.localeCompare(b.proprietario)
    }

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        renderizar={() =>
          <>
            <div className='valor tab'>
              <p>{valor.nome}</p>
              <Link
                to={`/cadastros/pacientes/${identificadorDoPaciente}/prontuario`}
                title='Prontuário'
                target='_blank'
                className={`link-prontuario ${identificadorDoPaciente ? '' : 'disabled'}`}
              >
                <div><Stethoscope size={20}/></div>
              </Link>
            </div>
            {exibirTelefones && telefones.length > 0 &&
              <div className='telefones-do-paciente'>
                <i className='icon icon-smartphone'></i>
                <button
                  onClick={() => setAbrirTelefones(!abrirTelefones)}
                  title={abrirTelefones ? 'Minimizar telefones' : 'Exibir telefones'}
                  type='button'
                  className='titulo'
                >
                  <label>Telefones</label>
                </button>
                {abrirTelefones &&
                  <div className='valor'>
                    {telefones.sort(ordenarTelefones).map((item, index) =>
                      <React.Fragment key={index}>
                        <span>
                          <strong>
                            {item.proprietario}
                            {item.proprietario !== 'Paciente' &&
                              <>{' '}{item.nome}{' ('}{item.grauDeRelacao}{')'}</>
                            }
                          </strong>
                          {': '}
                          {item.numero}
                        </span>
                        <br/>
                      </React.Fragment>
                    )}
                  </div>
                }
              </div>
            }
          </>
        }
      />
    )
  }

  function abrir() {
    setExibirPreCadastroDoPaciente(true)
  }

  function fechar() {
    setExibirPreCadastroDoPaciente(false)
  }

  return (
    <>
      <Modal
        isOpen={exibirPreCadastroDoPaciente}
        className='modal'
      >
        <PreCadastroDoPaciente
          adicionarPreCadastroDoPaciente={adicionarPreCadastroDoPacienteDaTarefa}
          termoPesquisado={termoPesquisado || valorDefault}
          pesquisarPacientes={pesquisarPacientesAutocomplete}
          fechar={fechar}
          listarSexos={listarSexos}
          sexos={sexos}
        />
      </Modal>
      <CampoDaTarefa
        gravar={definir}
        valor={identificador ? identificador : valor}
        icone={icone}
        gravarAoSelecionar={true}
        titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
        acao={preCadastro && abrir}
        tituloAcao='Pré-cadastro'
        classnameAcao={`button button-select-acao py-1 ${valor && 'is-disabled'}`}
      >
        {({onChange, onBlur, onFocus, valor, isValid, isWarning}) =>
          <SelectReact
            nome='paciente'
            id='paciente'
            onChange={onChange}
            options={optionsPaciente}
            onInputChange={pesquisarPacientesAutocomplete}
            formatOptionLabel={renderizarAutoCompletePaciente}
            noOptionsMessage='Digite 3 caracteres para pesquisar'
            valor={valor || ''}
            onBlur={onBlur}
            onFocus={onFocus}
            inputValue={identificador ? '' : (termoPesquisado || valorDefault)}
            filterOption={() => true}
            onMenuClose={() => setValorDefault(termoPesquisado)}
            isLoading={processando}
            isValid={isValid}
            isWarning={isWarning}
          />
        }
      </CampoDaTarefa>
    </>
  )
}

function PreCadastroDoPaciente(props) {
  const {
    adicionarPreCadastroDoPaciente,
    termoPesquisado,
    pesquisarPacientes,
    listarSexos,
    fechar,
    sexos
  } = props

  useEffect(() => { listarSexos() }, [listarSexos])

  const adicionar = async formulario => {
    const dados = {
      ...formulario,
      telefones: [{
        identificador: uuidv4(),
        proprietario: 'paciente',
        nome: '',
        grauDeRelacao: '',
        numero: formulario.telefone
      }],
      origemDosDados: 'prontuario',
      preCadastro: true
    }

    const adicionou = await adicionarPreCadastroDoPaciente(dados)

    if (adicionou) {
      pesquisarPacientes(formulario.nome)
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA, LISTAR_SEXOS]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          nome: termoPesquisado ? termoPesquisado : '',
          nomeDaMae: '',
          telefone: '',
          sexo: '',
          dataDeNascimento: '',
          observacoes: '',
        }}
        acao={adicionar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          nomeDaMae: Yup.string().required('Obrigatório'),
          dataDeNascimento: Yup.string().required('Obrigatório').nullable(),
          sexo: Yup.string().required('Obrigatório'),
          telefone: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.').required('Obrigatório'),
          cpf: ''
        })}
      >
        <fieldset>
          <h2 className='form-title'>Pré-Cadastro de Paciente</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12} class="form-item">
              <p className="form-item__choice">Caso queira fazer o cadastro completo do paciente, acesse o link
                <strong>
                  <Link to={`/cadastros/pacientes/novo`} target="_blank">{' '}[Cadastro do Paciente]</Link>.
                </strong>
              </p>
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome="nome"
                tabIndex={1}
                titulo="Nome *"
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome="nomeDaMae"
                tabIndex={2}
                titulo="Nome da Mãe *"
              />
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={3}>
              <Select
                nome="sexo"
                tabIndex={3}
                titulo="Sexo *"
                itens={sexos}
                campoCodigo="codigo"
                campoDescricao="nome"
              />
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={3}>
              <DatePicker
                nome="dataDeNascimento"
                tabIndex={4}
                titulo="Data de Nascimento *"
                maxDate={new Date()}
              />
            </r-cell>
            <r-cell span={4} span-md={4} span-lg={3}>
              <Telefone
                nome="telefone"
                tabIndex={5}
                titulo="Telefone *"
              />
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={3}>
              <Cpf
                nome="cpf"
                tabIndex={6}
                titulo="CPF"
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as="textarea"
                nome="observacoes"
                tabIndex={7}
                titulo="Observações"
              />
            </r-cell>
          </r-grid>
          <div className="list-btn">
            <button type="button" onClick={() => fechar()} className="button --light">Cancelar</button>
            <button type="submit" className='button --primary'>Adicionar Pré-Cadastro</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}