import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RESPOSTAS_VALIDAS_SOBRE_VOMITO,
  LISTOU_RESPOSTAS_VALIDAS_SOBRE_VOMITO,
  ERRO_AO_LISTAR_RESPOSTAS_VALIDAS_SOBRE_VOMITO
} from '../tipos'

const listouRespostasValidasSobreVomito = respostasVomito => ({
  type: LISTOU_RESPOSTAS_VALIDAS_SOBRE_VOMITO,
  respostasVomito
})

const erroAoListarRespostasValidasSobreVomito = erro => ({
  type: ERRO_AO_LISTAR_RESPOSTAS_VALIDAS_SOBRE_VOMITO,
  erro
})

export const listarRespostasValidasSobreVomito = () => async dispatch => {
  dispatch({ type: LISTAR_RESPOSTAS_VALIDAS_SOBRE_VOMITO })

  try {
    const resultado = await api.listarRespostasValidasSobreVomito()

    dispatch(listouRespostasValidasSobreVomito(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRespostasValidasSobreVomito(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as respostas válidas sobre vômito.')))
  }
}
