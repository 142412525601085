import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_PROFISSOES,
  LISTOU_PROFISSOES,
  ERRO_AO_LISTAR_PROFISSOES,
  LISTAR_PROFISSOES_MULTIDISCIPLINARES,
  LISTOU_PROFISSOES_MULTIDISCIPLINARES,
  ERRO_AO_LISTAR_PROFISSOES_MULTIDISCIPLINARES,
  LISTAR_PROFISSOES_DO_CADASTRO,
  LISTOU_PROFISSOES_DO_CADASTRO,
  ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO,
  ADICIONAR_PROFISSAO,
  ADICIONOU_PROFISSAO,
  ERRO_AO_ADICIONAR_PROFISSAO,
  REMOVER_PROFISSAO,
  REMOVEU_PROFISSAO,
  ERRO_AO_REMOVER_PROFISSAO,
  ALTERAR_PROFISSAO,
  ALTEROU_PROFISSAO,
  ERRO_AO_ALTERAR_PROFISSAO,
  RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  RECUPEROU_PROFISSAO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  LISTAR_ESPECIALIDADES,
  LISTOU_ESPECIALIDADES,
  ERRO_AO_LISTAR_ESPECIALIDADES,
  ADICIONAR_ESPECIALIDADE,
  ADICIONOU_ESPECIALIDADE,
  ERRO_AO_ADICIONAR_ESPECIALIDADE,
  REMOVER_ESPECIALIDADE,
  REMOVEU_ESPECIALIDADE,
  ERRO_AO_REMOVER_ESPECIALIDADE,
  ALTERAR_ESPECIALIDADE,
  ALTEROU_ESPECIALIDADE,
  ERRO_AO_ALTERAR_ESPECIALIDADE,
  RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  RECUPERAR_PROFISSAO_MEDICA,
  RECUPEROU_PROFISSAO_MEDICA,
  ERRO_AO_RECUPERAR_PROFISSAO_MEDICA,
} from './tipos'

const listouProfissoes = profissoes => ({
  type: LISTOU_PROFISSOES,
  profissoes
})

const erroAoListarProfissoes = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES,
  erro
})

export const listarProfissoes = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES })

  try {
    const resultado = await api.listarProfissoes()

    dispatch(listouProfissoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissões.')))
  }
}

const listouProfissoesMultidisciplinares = profissoes => ({
  type: LISTOU_PROFISSOES_MULTIDISCIPLINARES,
  profissoes
})

const erroAoListarProfissoesMultidisciplinares = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES_MULTIDISCIPLINARES,
  erro
})

export const listarProfissoesMultidisciplinares = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES_MULTIDISCIPLINARES })

  try {
    const resultado = await api.listarProfissoesMultidisciplinares()

    dispatch(listouProfissoesMultidisciplinares(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissoesMultidisciplinares(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissões multidisciplinares.')))
  }
}

const erroAoListarProfissoesDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO,
  erro,
  parametros
})

const listouProfissoesDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_PROFISSOES_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarProfissoesDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES_DO_CADASTRO })

  try {
    const resultado = await api.listarProfissoes(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouProfissoesDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissoesDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissões.')))
  }
}

const adicionouProfissao = profissao => ({
  type: ADICIONOU_PROFISSAO,
  profissao
})

const erroAoAdicionarProfissao = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PROFISSAO,
  erro,
  parametros
})

export const adicionarProfissao = profissao => async dispatch => {
  dispatch({ type: ADICIONAR_PROFISSAO })

  try {
    const resultado = await api.adicionarProfissao(profissao)

    dispatch(adicionouProfissao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarProfissao(erro, { profissao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar profissão.')))

    return null
  }
}

const removeuProfissao = identificador => ({
  type: REMOVEU_PROFISSAO,
  identificador,
})

const erroAoRemoverProfissao = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PROFISSAO,
  erro,
  parametros
})

export const removerProfissao = identificador => async dispatch => {
  dispatch({ type: REMOVER_PROFISSAO })
  try {
    const resultado = await api.removerProfissao(identificador)

    dispatch(removeuProfissao(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverProfissao(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a profissão.')))

    return false
  }
}

const alterouProfissao = profissao => ({
  type: ALTEROU_PROFISSAO,
  profissao,
})

const erroAoAlterarProfissao = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PROFISSAO,
  erro,
  parametros
})

export const alterarProfissao = profissao => async dispatch => {
  dispatch({ type: ALTERAR_PROFISSAO })

  try {
    const resultado = await api.alterarProfissao(profissao)

    dispatch(alterouProfissao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoAlterarProfissao(erro, { profissao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar a profissão.')))
  }
}

const recuperouProfissaoPorIdentificador = profissao => async dispatch => {
  dispatch({ type: RECUPEROU_PROFISSAO_POR_IDENTIFICADOR, profissao })
}

const erroAoRecuperarProfissaoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarProfissaoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PROFISSAO_POR_IDENTIFICADOR })
  try {
    const resultado = await api.recuperarProfissaoPorIdentificador(identificador)

    dispatch(recuperouProfissaoPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
    
  } catch (erro) {
    dispatch(erroAoRecuperarProfissaoPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar profissão por identificador.')))
  }
}

const adicionouEspecialidade = especialidade => ({
  type: ADICIONOU_ESPECIALIDADE,
  especialidade
})

const erroAoAdicionarEspecialidade = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ESPECIALIDADE,
  erro,
  parametros
})

export const adicionarEspecialidade = (identificadorDaProfissao, especialidade) => async dispatch => {
  dispatch({ type: ADICIONAR_ESPECIALIDADE })

  try {
    const resultado = await api.adicionarEspecialidade(identificadorDaProfissao, especialidade)

    dispatch(adicionouEspecialidade(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarEspecialidade(erro, { identificadorDaProfissao, especialidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar especialidade.')))

    return false
  }
}

const listouEspecialidades = especialidades => ({
  type: LISTOU_ESPECIALIDADES,
  especialidades,
})

const erroAoListarEspecialidades = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ESPECIALIDADES,
  erro,
  parametros
})

export const listarEspecialidades = identificadorDaProfissao => async dispatch => {
  dispatch({ type: LISTAR_ESPECIALIDADES })

  try {
    const resultado = await api.listarEspecialidades(identificadorDaProfissao)

    dispatch(listouEspecialidades(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEspecialidades(erro, { identificadorDaProfissao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as especialidades da profissão.')))
  }
}

const alterouEspecialidade = especialidade => ({
  type: ALTEROU_ESPECIALIDADE,
  especialidade,
})

const erroAoAlterarEspecialidade = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_ESPECIALIDADE,
  erro,
  parametros
})

export const alterarEspecialidade = (identificadorDaProfissao, especialidade) => async dispatch => {
  dispatch({ type: ALTERAR_ESPECIALIDADE })

  try {
    const resultado = await api.alterarEspecialidade(identificadorDaProfissao, especialidade)

    dispatch(alterouEspecialidade(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarEspecialidade(erro, { identificadorDaProfissao, especialidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar a especialidade da profissão.')))

    return false
  }
}

const removeuEspecialidade = identificador => ({
  type: REMOVEU_ESPECIALIDADE,
  identificador,
})

const erroAoRemoverEspecialidade = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_ESPECIALIDADE,
  erro,
  parametros
})

export const removerEspecialidade = (identificadorDaProfissao, identificador) => async dispatch => {
  dispatch({ type: REMOVER_ESPECIALIDADE })

  try {
    const resultado = await api.removerEspecialidade(identificadorDaProfissao, identificador)

    dispatch(removeuEspecialidade(identificadorDaProfissao, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverEspecialidade(erro, { identificadorDaProfissao, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a especialidade da profissão.')))

    return false
  }
}

const recuperouEspecialidadePorIdentificador = especialidade => async dispatch => {
  dispatch({ type: RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR, especialidade })
}

const erroAoRecuperarEspecialidadePorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarEspecialidadePorIdentificador = (identificadorDaProfissao, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR })
  try {
    const resultado = await api.recuperarEspecialidadePorIdentificador(identificadorDaProfissao, identificador)

    dispatch(recuperouEspecialidadePorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarEspecialidadePorIdentificador(erro, { identificadorDaProfissao, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar especialidade por identificador.')))
  }
}

const recuperouProfissaoMedica = profissao => async dispatch => {
  dispatch({ type: RECUPEROU_PROFISSAO_MEDICA, profissao })
}

const erroAoRecuperarProfissaoMedica = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PROFISSAO_MEDICA,
  erro,
  parametros
})

export const recuperarProfissaoMedica = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PROFISSAO_MEDICA })
  try {
    const resultado = await api.recuperarProfissaoMedica(identificador)

    dispatch(recuperouProfissaoMedica(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoRecuperarProfissaoMedica(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar profissão médica.')))
  }
}