import {
  LISTOU_PROFISSIONAIS_DE_SAUDE,
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PROFISSIONAIS_DE_SAUDE: {
      return action.profissionaisDeSaude.map(x => ({...x, label: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
    }
    default: {
      return state
    }
  }
}