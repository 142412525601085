import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import FileSaver from 'file-saver'
import { FieldArray } from 'formik'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { confirmAlert } from 'react-confirm-alert'
import { useProntuario } from '../../contexto'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Formulario from './formulario'

export default function Orientacoes({ valor = [] }) {
  return (
    <FieldArray
      name='orientacoes'
      render={({ push, remove, replace }) =>
        <Orientacao
          adicionar={push}
          adicionados={valor}
          alterar={replace}          
          remover={remove}
        />
      }
    />
  )
}

function Orientacao({ adicionados, ...rest }) {
  const {    
    fazerDownloadDoArquivoDaOrientacao,
    listarOrientacoes,
    orientacoes,    
  } = useProntuario()

  const [selecionada, setSelecionada] = useState(null)
  const [exibirFormulario, setExibirFormulario] = useState(false)
  const registrosDaTabela = [...adicionados]

  useEffect(() => { listarOrientacoes() }, [listarOrientacoes])  
  
  const abrirFormulario = (item) => {
    selecionarItem(item)
    setExibirFormulario(true)
  }

  const fecharFormulario = () => {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const adicionar = registro => {
    rest.adicionar(registro)
    setExibirFormulario(false)
  }

  const [identificador, setIdentificador] = useState('')

  const salvar = registro => {
    if (identificador >= 0) {
      rest.alterar(identificador, registro)
    }
  }

  const remover = registro => {
    const index = adicionados.indexOf(registro)

    if (index >= 0) {
      rest.remover(index)
    }
  }  

  const confirmarERemover = registro => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover a orientação ${registro.orientacao}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(registro)
      }, {
        label: 'Não'
      }]
    })
  }

  const selecionarItem = item => {    
    if (item) {
      setSelecionada(item)
      setIdentificador(adicionados.indexOf(item))
    } else {
      setSelecionada(null)
      setIdentificador('')
    }
  }

  const fazerDownload = async (orientacao, arquivo) => {
    const dados = await fazerDownloadDoArquivoDaOrientacao(orientacao.identificador, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <Modal
        className='modal'
        contentLabel='Modal para adição de orientação'
        isOpen={exibirFormulario}
      >
        <Formulario
          fazerDownloadDoArquivoDaOrientacao={fazerDownloadDoArquivoDaOrientacao}
          fechar={fecharFormulario}
          orientacoes={orientacoes}          
          salvar={selecionada ? salvar : adicionar}
          selecionada={selecionada}          
        />
      </Modal>      
      <>
        {registrosDaTabela.length > 0 &&
          <Tabela
            acoes={[
              item =>
                <div key='remover'>
                  <button
                    onClick={() => confirmarERemover(item)}
                    type='button'
                    className='table-items-expanded__bt --remove'
                    title='Remover'
                  >
                    <i className='icon icon-minus'></i>
                  </button>
                </div>
              ,
              item =>
                <div key='editar'>
                  <button
                    type='button'
                    onClick={() => abrirFormulario(item)}
                    className='table-items-expanded__bt --edit'
                    title='Editar'
                  >
                    <i className='icon icon-pencil'></i>
                  </button>
                </div>              
            ]}
            className='--secondary mb-2'
            dados={registrosDaTabela}
            expandida={true}
            titulo={false}
            exibirBotaoMais={false}
          >
            <LinhaExpandida className='col-xs-10 col-lg-11'>
              <AgrupamentoDeColunas>
                <Coluna
                  icone='icon-name'
                  nome='ORIENTAÇÃO'
                  campoDeDados='orientacao'
                  className='table-items-expanded-40'
                />      
                <Coluna
                  icone='icon-download-cloud'
                  className='col-lg-2'
                  nome='Arquivos'
                  renderizar={item =>
                    <Arquivos
                      fazerDownload={fazerDownload}
                      orientacoes={orientacoes}
                      registro={item} />
                  }
                />          
                <Coluna
                  icone='icon-doc-text'
                  nome='OBSERVAÇÕES'
                  className='table-items-expanded-100__box-item'
                  renderizar={item => item.observacoes && <div><pre>{item.observacoes}</pre></div>}
                />
              </AgrupamentoDeColunas>
            </LinhaExpandida>
          </Tabela>
        }
        <r-cell span={4} span-md={3} span-lg={12}>
          <button
            className='button --light w-sm-100'
            type='button'
            onClick={() => abrirFormulario(null)}
          >
            Adicionar Orientação
          </button>
        </r-cell>
      </>
    </r-cell>
  )
}

function Arquivos({ fazerDownload, registro, orientacoes }) {
  const [visivel, setVisivel] = useState(false)
  const referencialDosArquivos = useRef(null)
  const posicaoAEsquerda = referencialDosArquivos.current && referencialDosArquivos.current.offsetLeft

  useCliqueForaDoElemento(referencialDosArquivos, () => setVisivel(false))

  if (!registro || !orientacoes) return null

  const orientacao = orientacoes.filter(x => x.nome === registro.orientacao)[0]  
  const arquivos = orientacao.arquivos

  if (!arquivos || arquivos.length === 0)  return null

  return (
    <div ref={referencialDosArquivos}>
      <button
        className='indicator-tooltip'
        type='button'
      >
        <i className={`icon-arrow${visivel ? '-up' : '-down'}`}
          onClick={() => setVisivel(!visivel)}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop custom-scrollbar' 
          style={{ 
            left: posicaoAEsquerda,
            height: 'auto'
           }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              <div className='form-summary'>
                <div className='form-summary-files'>
                  {arquivos.length > 0 && arquivos.map((x, index) =>
                    <div className='file-upload-item' key={index} style={{ cursor: 'pointer' }}>
                      <div className='file-upload-item__name' onClick={() => fazerDownload(orientacao, x)}>
                        {x.nome}
                      </div>                   
                    </div>
                  )}
                </div>
              </div>  
            </div>
          </div>
        </div>
      }
    </div>
  )
}