import React, {useEffect, useState, useRef} from 'react'
import Modal from 'react-modal'
import {useProntuario} from '../../contexto'
import {Coluna, Tabela} from '../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import Permissao, {usePossuiAsPermissoes} from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import {
  LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE
} from '../../../../acoes/tipos'

export default function RegistrosDocumentaisDoPsicologo({ identificadorDoPaciente, registrosDocumentaisDoPsicologoDoPaciente }) {
  const {
    listarRegistrosDocumentaisDoPsicologoDoPaciente,
    adicionarRegistroDocumentalDoPsicologo,
    alterarRegistroDocumentalDoPsicologo,
    removerRegistroDocumentalDoPsicologo
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)

  const referenciaDoInfo = useRef(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE])

  useCliqueForaDoElemento(referenciaDoInfo, () => setExibirTooltipInfo(false))

  useEffect(() => {
    if (registrosDocumentaisDoPsicologoDoPaciente === undefined) {
      listarRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente)
    }
  },[listarRegistrosDocumentaisDoPsicologoDoPaciente, identificadorDoPaciente, registrosDocumentaisDoPsicologoDoPaciente])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarRegistroDocumentalDoPsicologo(identificadorDoPaciente, item) : await adicionarRegistroDocumentalDoPsicologo(identificadorDoPaciente, item)
  }

  if (registrosDocumentaisDoPsicologoDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de registros documentais do psicólogo'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de registro documental do psicólogo'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionado={selecionado}
          remover={valor => removerRegistroDocumentalDoPsicologo(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                     <div className='tooltip__drop custom-scrollbar right-20' style={{ height: '200px' }}>
                       <div className='tooltip__drop__body'>
                         <div className='tooltip__drop__title'>
                           Histórico
                           <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}>
                             <i className='icon icon-close'></i>
                           </button>
                         </div>
                         <div className='tooltip__drop__description'>
                           <span>Usuário: {item.usuario.nome}</span> <br />
                           {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br/></>}
                           <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                            <>
                              <strong>Texto:</strong><br />
                              <pre>{item.texto}</pre>
                            </>
                         </div>
                       </div>
                     </div>
                    }
                  </div>
                ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={registrosDocumentaisDoPsicologoDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros documentais do psicólogo do paciente.'
              classNameAdicionar='col-xs-6 col-lg-9'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionado, remover, fechar }) {
  const excluirRegistroDocumentalDoPsicologo = async () => {
    const excluiu = await remover(selecionado.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover o registro documental do psicólogo?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirRegistroDocumentalDoPsicologo}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}