import powerBI from './power-bi'

const estadoInicial = {
  powerBI: {}
}

export default (state = estadoInicial, action) => {
  return {
    powerBI: powerBI(state.powerBI, action),
  }
}