import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  ADICIONAR_SALA_NA_UNIDADE,
  ADICIONOU_SALA_NA_UNIDADE,
  ERRO_AO_ADICIONAR_SALA_NA_UNIDADE,
  ALTERAR_SALA_DA_UNIDADE,
  ALTEROU_SALA_DA_UNIDADE,
  ERRO_AO_ALTERAR_SALA_DA_UNIDADE,
  LISTAR_SALAS_DA_UNIDADE,
  LISTOU_SALAS_DA_UNIDADE,
  ERRO_AO_LISTAR_SALAS_DA_UNIDADE,
  RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  REMOVER_SALA_DA_UNIDADE,
  REMOVEU_SALA_DA_UNIDADE,
  ERRO_AO_REMOVER_SALA_DA_UNIDADE,
} from '../tipos'

const adicionouSalaNaUnidade = sala => ({
  type: ADICIONOU_SALA_NA_UNIDADE,
  sala,
})

const erroAoAdicionarSalaNaUnidade = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_SALA_NA_UNIDADE,
  erro,
  parametros,
})

export const adicionarSalaNaUnidade = (identificadorDaUnidade, sala) => async dispatch => {
  dispatch({ type: ADICIONAR_SALA_NA_UNIDADE })

  try {
    const resultado = await api.adicionarSalaNaUnidade(identificadorDaUnidade, sala)
    dispatch(adicionouSalaNaUnidade(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarSalaNaUnidade(erro, { identificadorDaUnidade, sala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar a sala na unidade.')))
    return false
  }
}

const alterouSalaDaUnidade = sala => ({
  type: ALTEROU_SALA_DA_UNIDADE,
  sala,
})

const erroAoAlterarSalaDaUnidade = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SALA_DA_UNIDADE,
  erro,
  parametros,
})

export const alterarSalaDaUnidade = (identificadorDaUnidade, sala) => async dispatch => {
  dispatch({ type: ALTERAR_SALA_DA_UNIDADE })

  try {
    const resultado = await api.alterarSalaDaUnidade(identificadorDaUnidade, sala)

    dispatch(alterouSalaDaUnidade(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSalaDaUnidade(erro, { identificadorDaUnidade, sala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar a sala da unidade.')))

    return false
  }
}

const listouSalasDaUnidade = salas => ({
  type: LISTOU_SALAS_DA_UNIDADE,
  salas,
})

const erroAoListarSalasDaUnidade = erro => ({
  type: ERRO_AO_LISTAR_SALAS_DA_UNIDADE,
  erro
})

export const listarSalasDaUnidade = (identificadorDaUnidade) => async dispatch => {
  dispatch({ type: LISTAR_SALAS_DA_UNIDADE })

  try {
    const resultado = await api.listarSalasDaUnidade(identificadorDaUnidade)

    dispatch(listouSalasDaUnidade(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSalasDaUnidade(erro, { identificadorDaUnidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as salas da unidade.')))
  }
}

const removeuSalaDaUnidade = (identificadorDaUnidade, identificadorDaSala) => ({
  type: REMOVEU_SALA_DA_UNIDADE,
  identificadorDaUnidade,
  identificadorDaSala,
})

const erroAoRemoverSalaDaUnidade = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_SALA_DA_UNIDADE,
  erro,
  parametros,
})

export const removerSalaDaUnidade = (identificadorDaUnidade, identificadorDaSala) => async dispatch => {
  dispatch({ type: REMOVER_SALA_DA_UNIDADE })

  try {
    const resultado = await api.removerSalaDaUnidade(identificadorDaUnidade, identificadorDaSala)

    dispatch(removeuSalaDaUnidade(identificadorDaUnidade, identificadorDaSala))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverSalaDaUnidade(erro, { identificadorDaUnidade, identificadorDaSala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover a sala da unidade.')))
    return false
  }
}

const recuperouSalaDaUnidadePeloIdentificador = sala => async dispatch => {
  dispatch({ type: RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR, sala })
}

const erroAorecuperarSalaDaUnidadePeloIdentificador = (erro, parametros) => async dispatch => {
  dispatch({ type: ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR, erro, parametros })
}

export const recuperarSalaDaUnidadePeloIdentificador = (identificadorDaUnidade, identificadorDaSala) => async dispatch => {
  dispatch({ type: RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarSalaDaUnidadePeloIdentificador(identificadorDaUnidade, identificadorDaSala)

    dispatch(recuperouSalaDaUnidadePeloIdentificador(resultado.data.dados, (identificadorDaUnidade, identificadorDaSala)))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAorecuperarSalaDaUnidadePeloIdentificador(erro, { identificadorDaUnidade, identificadorDaSala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a sala da unidade pelo identificador.')))
  }
}