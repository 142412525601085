import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE
} from '../../../tipos'

const listouEscalasAmbientaisDeRiscoDeQuedasDoPaciente = (identificadorDoPaciente, escalas) => ({
  type: LISTOU_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  identificadorDoPaciente,
  escalas
})

const erroAoListarEscalasAmbientaisDeRiscoDeQuedasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  erro,
  parametros
})

export const listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE })

  try {
    const resultado = await api.listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente(identificadorDoPaciente)

    dispatch(listouEscalasAmbientaisDeRiscoDeQuedasDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEscalasAmbientaisDeRiscoDeQuedasDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as Escalas Ambientais do paciente.')))
  }
}

const adicionouEscalaAmbientalDeRiscoDeQuedasDoPaciente = escala => ({
  type: ADICIONOU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  escala
})

const erroAoAdicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificadorDoPaciente, escala) => async dispatch => {
  dispatch({ type: ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE })

  try {
    const resultado = await api.adicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente(identificadorDoPaciente, escala)

    dispatch(adicionouEscalaAmbientalDeRiscoDeQuedasDoPaciente(resultado.data.dados))
    dispatch(listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente(erro, { escala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar a Escala Ambiental de Risco de Quedas do paciente.')))

    return false
  }
}

const alterouEscalaAmbientalDeRiscoDeQuedasDoPaciente = escala => ({
  type: ALTEROU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  escala
})

const erroAoAlterarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  erro,
  parametros
})

export const alterarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificadorDoPaciente, escala) => async dispatch => {
  dispatch({ type: ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE })

  try {
    const resultado = await api.alterarEscalaAmbientalDeRiscoDeQuedasDoPaciente(identificadorDoPaciente, escala)

    dispatch(alterouEscalaAmbientalDeRiscoDeQuedasDoPaciente(resultado.data.dados))
    dispatch(listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarEscalaAmbientalDeRiscoDeQuedasDoPaciente(erro, { escala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar a Escala Ambiental de Risco de Quedas do paciente.')))

    return false
  }
}

const removeuEscalaAmbientalDeRiscoDeQuedasDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverEscalaAmbientalDeRiscoDeQuedasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  erro,
  parametros
})

export const removerEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificadorDoPaciente, identificadorDaEscala) => async dispatch => {
  dispatch({ type: REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE })

  try {
    const resultado = await api.removerEscalaAmbientalDeRiscoDeQuedasDoPaciente(identificadorDoPaciente, identificadorDaEscala)

    dispatch(removeuEscalaAmbientalDeRiscoDeQuedasDoPaciente(identificadorDoPaciente))
    dispatch(listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverEscalaAmbientalDeRiscoDeQuedasDoPaciente(erro, { identificadorDoPaciente, identificadorDaEscala }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover a Escala Ambiental de Risco de Quedas do paciente.')))

    return false
  }
}