import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  ADICIONOU_PROFISSIONAL_EXTERNO,
  ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO,
  ADICIONAR_PROFISSIONAL_EXTERNO,
  RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ALTEROU_PROFISSIONAL_EXTERNO,
  ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO,
  ALTERAR_PROFISSIONAL_EXTERNO,
  REMOVEU_PROFISSIONAL_EXTERNO,
  ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO,
  REMOVER_PROFISSIONAL_EXTERNO,
  LISTOU_PROFISSIONAIS_EXTERNOS,
  LISTAR_PROFISSIONAIS_EXTERNOS,
  ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS
} from './tipos'

const erroAoListarProfissionaisExternos = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS,
  erro,
  parametros
})

const listouProfissionaisExternos = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_PROFISSIONAIS_EXTERNOS,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarProfissionaisExternos = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_EXTERNOS })

  try {
    const resultado = await api.listarProfissionaisExternos(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouProfissionaisExternos(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissionaisExternos(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais externos.')))
  }
}

const adicionouProfissionalExterno = profissional => ({
  type: ADICIONOU_PROFISSIONAL_EXTERNO,
  profissional
})

const erroAoAdicionarProfissionalExterno = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO,
  erro,
  parametros
})

export const adicionarProfissionalExterno = profissional => async dispatch => {
  dispatch({ type: ADICIONAR_PROFISSIONAL_EXTERNO })

  try {
    const resultado = await api.adicionarProfissionalExterno(profissional)

    dispatch(adicionouProfissionalExterno(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarProfissionalExterno(erro, { profissional }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o profissional.')))

    return false
  }
}

const recuperouProfissionalExternoPeloIdentificador = profissional => ({
  type: RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  profissional
})

const erroAoRecuperarProfissionalExternoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarProfissionalExternoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarProfissionalExternoPeloIdentificador(identificador)

    dispatch(recuperouProfissionalExternoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarProfissionalExternoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o profissional pelo identificador.')))
  }
}

const alterouProfissionalExterno = profissional => ({
  type: ALTEROU_PROFISSIONAL_EXTERNO,
  profissional
})

const erroAoAlterarProfissionalExterno = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO,
  erro,
  parametros
})

export const alterarProfissionalExterno = profissional => async dispatch => {
  dispatch({ type: ALTERAR_PROFISSIONAL_EXTERNO })

  try {
    const resultado = await api.alterarProfissionalExterno(profissional)

    dispatch(alterouProfissionalExterno(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarProfissionalExterno(erro, { profissional }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o profissional.')))

    return false
  }
}

const removeuProfissionalExterno = profissional => ({
  type: REMOVEU_PROFISSIONAL_EXTERNO,
  profissional
})

const erroAoRemoverProfissionalExterno = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO,
  erro,
  parametros
})

export const removerProfissionalExterno = identificador => async dispatch => {
  dispatch({ type: REMOVER_PROFISSIONAL_EXTERNO })

  try {
    const resultado = await api.removerProfissionalExterno(identificador)

    dispatch(removeuProfissionalExterno(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverProfissionalExterno(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o profissional.')))

    return false
  }
}