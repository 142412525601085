import {
  ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO,
  LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO,
  LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO
} from '../../../acoes/tipos'

import moment from 'moment'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'

const estadoInicial = {
  agendamentosRealizados: {},
  dataBaseHorariosLivres: moment().format('YYYY-MM-DD'),
  horariosLivres: {},
  unidades: [],
  proximosAgendamentos: null,
  semPrograma: null,
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        unidades: action.unidades
      }
    }

    case LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO: {
      let horariosLivres = { ...state.horariosLivres }

      const horarios = [...action.horariosLivres]
      const agora = moment()

      horarios.forEach(horario => {
        const chave = moment(horario.inicio).format('YYYY-MM-DD')

        if (moment(horario.inicio).isBefore(agora, 'minutes')) {
          return
        }

        if (!horariosLivres[chave]) {
          horariosLivres[chave] = {
            data: chave,
            profissionais: {}
          }
        }

        const unidades = horariosLivres[chave].profissionais[horario.profissional.identificador] &&
          horariosLivres[chave].profissionais[horario.profissional.identificador].unidades

        horariosLivres[chave] = {
          data: chave,
          profissionais: {
            ...horariosLivres[chave].profissionais,
            [horario.profissional.identificador]: {
              ...horario.profissional,
              unidades: {
                ...horariosLivres[chave].profissionais[horario.profissional.identificador] &&
                horariosLivres[chave].profissionais[horario.profissional.identificador].unidades,
                [horario.unidade.identificador]: {
                  ...horario.unidade,
                  horarios: (unidades && unidades[horario.unidade.identificador] && unidades[horario.unidade.identificador].horarios ?
                    [...unidades[horario.unidade.identificador].horarios, horario] : [horario])
                }
              }
            }
          }
        }
      })

      return {
        ...state,
        dataBaseHorariosLivres: state.dataBaseHorariosLivres,
        horariosLivres
      }
    }

    case IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO: {
      return {
        ...state,
        semPrograma: action.semPrograma
      }
    }

    case ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO: {
      return {
        ...state,
        proximosAgendamentos: action.proximos
      }
    }

    case ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO: {
      let agendamentosRealizados = { ...state.agendamentosRealizados }
      const agendamento = [action.agendamento]

      agendamento.forEach(agendamento =>
        agendamentosRealizados[agendamento.identificador] = {
          ...agendamento
        }
      )

      return {
        ...state,
        agendamentosRealizados
      }
    }

    case EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO: {
      let agendamentosRealizados = { ...state.agendamentosRealizados }
      const arrayDeAgendamentos = converterObjetoParaArray(agendamentosRealizados)
      const agendamentoEscolhido = arrayDeAgendamentos.filter(x => x.identificador === action.dadosParaExcluirAgendamento.identificador)

      if (agendamentoEscolhido.find(x => x.agendamentos.length === 0)) {
        delete agendamentosRealizados[action.dadosParaExcluirAgendamento.identificador]
      } else if (arrayDeAgendamentos.find(x => x.agendamentos.length > 0)) {
        if (action.dadosParaExcluirAgendamento.todos) {
          delete agendamentosRealizados[action.dadosParaExcluirAgendamento.identificador]
        } else {
          arrayDeAgendamentos.forEach(agendamento =>
            agendamentosRealizados[agendamento.identificador] = {
              ...agendamento,
              agendamentos: agendamento.agendamentos.filter(x => x.identificador !== action.dadosParaExcluirAgendamento.identificador)
            }
          )
        }
      }

      return {
        ...state,
        agendamentosRealizados
      }
    }

    case LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        horariosLivres: [],
        proximosAgendamentos: null,
        semPrograma: null
      }
    }

    case LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        horariosLivres: {},
        agendamentosRealizados: {},
        proximosAgendamentos: null,
        semPrograma: null
      }
    }

    default: {
      return state
    }
  }
}