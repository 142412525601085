import React, { useEffect, useState, useCallback } from 'react'
import Spinner from '../spinner'
import { Input, Formulario as Form, Select, Checkbox, Telefone, Cpf, InputFoto, Toglle } from '../formik/formulario'
import { confirmAlert } from 'react-confirm-alert'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import * as Yup from 'yup'

import {
  RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  SALVAR_USUARIO,
  ADICIONAR_USUARIO,
  LISTAR_PROFISSOES,
  RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  ADICIONAR_FOTO_DO_USUARIO, ALTERAR_STATUS_DO_USUARIO
} from '../../acoes/tipos'

export default function Editar(props) {
  const {
    adicionarFotoDoUsuario,
    ativarStatus,
    history,
    identificadorDoUsuarioLogado,
    inativarStatus,
    match,
    profissoes,
    recuperarPorIdentificador,
    recuperarFotoOriginalDoUsuarioPorIdentificador,
    removerFotoDoUsuario,
    salvar,
    sexos,
    tratamentos,
    unidadesFederativas,
    usuario = {},
  } = props

  const { identificador } = match.params
  const [exibirDadosDoProfissional, setExibirDadosDoProfissional] = useState(false)
  const [profissaoSelecionada, setProfissaoSelecionada] = useState(null)
  const exibirAtivar = usePossuiAsPermissoes([permissoes.ATIVAR_USUARIO]) && !usuario.ativo && usuario.identificador
  const exibirInativar = usePossuiAsPermissoes([permissoes.DESATIVAR_USUARIO]) && usuario.ativo && usuario.identificador
  const podeAlterarOutroUsuario = usePossuiAsPermissoes([permissoes.ALTERAR_OUTRO_USUARIO])
  const podeAlterarUsuario = (identificadorDoUsuarioLogado === usuario.identificador) ? true : podeAlterarOutroUsuario

  const selecionarProfissao = useCallback(nome => {
    const profissao = profissoes.find(x => x.nome === nome)
    setProfissaoSelecionada(profissao)
  }, [profissoes])

  useEffect(() => {
    if (usuario.profissao) {
      setExibirDadosDoProfissional(usuario.profissao !== '')
      selecionarProfissao(usuario.profissao)
    } else {
      setExibirDadosDoProfissional(false)
      setProfissaoSelecionada(null)
    }
  }, [usuario.profissao, selecionarProfissao])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  const confirmarEAtivar = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja ativar o usuário ${usuario.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => ativarStatus(item)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEInativar = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja inativar o usuário ${usuario.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => inativarStatus(item)
      }, {
        label: 'Não'
      }]
    })
  }

  const carregou = usuario.identificador && (identificador === usuario.identificador.toString())

  return (
    <Spinner operacoes={[RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR, ADICIONAR_FOTO_DO_USUARIO]}>
      {({ processando }) => (
        <Spinner operacoes={[RECUPERAR_USUARIO_POR_IDENTIFICADOR, SALVAR_USUARIO, ADICIONAR_USUARIO, LISTAR_PROFISSOES, ALTERAR_STATUS_DO_USUARIO]}>
          <Form
            reinicializar={true}
            valoresIniciais={{
              identificador: (carregou && usuario.identificador) || '',
              tratamento: (carregou && usuario.tratamento) || '',
              ativo: (carregou && usuario.ativo) || '',
              nome: (carregou && usuario.nome) || '',
              sexo: (carregou && usuario.sexo) || '',
              cpf: (carregou && usuario.cpf) || '',
              foto: (carregou && usuario.foto) || '',
              email: (carregou && usuario.email) || '',
              telefone1: (carregou && usuario.telefone1) || '',
              telefone2: (carregou && usuario.telefone2) || '',
              informarDadosProfissionais: exibirDadosDoProfissional,
              profissao: (carregou && profissaoSelecionada && profissaoSelecionada.nome) || '',
              especialidades: (carregou && profissaoSelecionada && profissaoSelecionada.especialidades.length > 0) ? usuario.especialidades : '',
              conselho: (carregou && profissaoSelecionada && profissaoSelecionada.conselho) || '',
              unidadeFederativaDoConselhoProfissional: (carregou && usuario.unidadeFederativaDoConselhoProfissional) || '',
              numeroDoRegistroNoConselhoProfissional: (carregou && usuario.numeroDoRegistroNoConselhoProfissional) || '',
              observacoes: (carregou && usuario.observacoes) || '',
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório.'),
              sexo: Yup.string().required('Obrigatório.'),
              cpf: Yup.string().ehUmCpfValido('CPF deve ser válido.')
                .when('profissao', {
                  is: val => val === 'MÉDICO(A)',
                  then: Yup.string().required('Obrigatório.'),
                }),
              email: Yup.string().ehUmEmailValido('E-mail deve ser válido.').required('Obrigatório.'),
              telefone1: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.'),
              telefone2: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.'),
              profissao: exibirDadosDoProfissional ? Yup.string().required('Obrigatório.') : '',
              unidadeFederativaDoConselhoProfissional: exibirDadosDoProfissional ? Yup.string().required('Obrigatório.') : '',
              numeroDoRegistroNoConselhoProfissional: exibirDadosDoProfissional ?
                Yup.number()
                  .typeError('Dever ser numérico')
                  .positive('Deve ser positivo')
                  .integer('Deve ser inteiro')
                  .required('Obrigatório.')
                : '',
            })}
          >
            {({ values }) => (
              <>
                <fieldset className='mt-1'>
                  <h2 className='form-title'>Dados Pessoais</h2>
                  <div className='with-thumb'>
                    <div className='with-thumb-sidebar'>
                      <InputFoto
                        bytes={carregou && usuario.bytesDaFoto}
                        carregandoFoto={processando}
                        habilitarFuncoes={podeAlterarUsuario}
                        nome='foto'
                        recuperarFotoOriginal={() => recuperarFotoOriginalDoUsuarioPorIdentificador(usuario.identificador)}
                        remover={() => removerFotoDoUsuario(usuario.identificador)}
                        selecionar={foto => foto && adicionarFotoDoUsuario(foto)}
                      />
                    </div>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={2} span-lg={2}>
                        <Select
                          nome='tratamento'
                          tabIndex={1}
                          titulo='Tratamento'
                          itens={tratamentos}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          isDisabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={6}>
                        <Input
                          nome='nome'
                          tabIndex={2}
                          titulo='Nome *'
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={2} span-md={2} span-lg={2}>
                        <Select
                          nome='sexo'
                          tabIndex={3}
                          titulo='Sexo *'
                          itens={sexos}
                          campoCodigo='codigo'
                          campoDescricao='nome'
                          isDisabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={2} span-md={3} span-lg={3}>
                        <Cpf
                          nome='cpf'
                          tabIndex={4}
                          titulo={values.profissao === 'MÉDICO(A)' ? 'CPF *' : 'CPF'}
                          validate={true}
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Toglle
                          nome='ativo'
                          tabIndex={5}
                          titulo='Ativo'
                          disabled={true}
                        />
                      </r-cell>
                    </r-grid>
                  </div>
                </fieldset>
                <div className='mt-2'>
                  <fieldset>
                    <h2 className='form-title'>Dados de Contato</h2>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={2} span-lg={6}>
                        <Input
                          nome='email'
                          tabIndex={6}
                          titulo='E-mail *'
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={3}>
                        <Telefone
                          nome='telefone1'
                          tabIndex={7}
                          titulo='Telefone #1'
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={2} span-lg={3}>
                        <Telefone
                          nome='telefone2'
                          tabIndex={8}
                          titulo='Telefone #2'
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                    </r-grid>
                  </fieldset>
                </div>
                <div className='mt-2'>
                  <fieldset>
                    <h2 className='form-title'>Dados Profissionais</h2>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <Checkbox
                          nome='informarDadosProfissionais'
                          tabIndex={9}
                          titulo='Informar dados de Profissional de Saúde'
                          onChange={setExibirDadosDoProfissional}
                          classname={!podeAlterarUsuario ? 'is-disabled' : ''}
                        />
                      </r-cell>
                      {exibirDadosDoProfissional &&
                        <>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            <Select
                              nome='profissao'
                              tabIndex={10}
                              titulo='Profissão de Saúde *'
                              itens={profissoes}
                              campoCodigo='nome'
                              campoDescricao='nome'
                              onChange={selecionarProfissao}
                              isDisabled={!podeAlterarUsuario}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            <Select
                              nome='especialidades'
                              tabIndex={11}
                              titulo='Especialidade(s)'
                              isMulti
                              campoCodigo='nome'
                              campoDescricao='nome'
                              itens={profissaoSelecionada ? profissaoSelecionada.especialidades : []}
                              isDisabled={!podeAlterarUsuario || !(profissaoSelecionada && profissaoSelecionada.especialidades.length > 0)}
                            />
                          </r-cell>
                          <r-cell span={2} span-md={2} span-lg={2}>
                            <Input
                              nome='conselho'
                              tabIndex={12}
                              titulo='Conselho'
                              style={{ border: profissaoSelecionada ? 'solid 1px #393042' : '' }}
                              value={profissaoSelecionada ? profissaoSelecionada.conselho : ''}
                              disabled={!podeAlterarUsuario}
                            />
                          </r-cell>
                          <r-cell span={2} span-md={2} span-lg={2}>
                            <Select
                              nome='unidadeFederativaDoConselhoProfissional'
                              tabIndex={13}
                              titulo='UF *'
                              itens={unidadesFederativas}
                              campoCodigo='sigla'
                              campoDescricao='sigla'
                              isDisabled={!podeAlterarUsuario}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={2} span-lg={2}>
                            <Input
                              nome='numeroDoRegistroNoConselhoProfissional'
                              tabIndex={14}
                              titulo='Registro *'
                              disabled={!podeAlterarUsuario}
                            />
                          </r-cell>
                        </>
                      }
                    </r-grid>
                  </fieldset>
                </div>
                <div className='mt-2'>
                  <fieldset>
                    <h2 className='form-title'>Observação</h2>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={6} span-md={6} span-lg={12}>
                        <Input
                          as='textarea'
                          nome='observacoes'
                          tabIndex={15}
                          titulo='Observações'
                          disabled={!podeAlterarUsuario}
                        />
                      </r-cell>
                    </r-grid>
                  </fieldset>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={() => history.push(`/configuracoes/usuarios`)}>Voltar</button>
                  {exibirInativar && <button onClick={confirmarEInativar} type='button' className='button --light'>Inativar</button>}
                  {exibirAtivar && <button onClick={confirmarEAtivar} type='button' className='button --light'>Ativar</button>}
                  {podeAlterarUsuario && <button type='submit' className='button --primary'>Salvar Usuário</button>}
                </div>
              </>
            )}
          </Form>
        </Spinner>
      )}
    </Spinner>
  )
}