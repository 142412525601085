import React, {useEffect, useMemo} from 'react'
import CampoDaTarefa from './campo-da-tarefa'
import {SelectReact} from '../../formik/select'
import {useTarefas} from '../contexto'
import {ComponenteLeitura} from '../card'
import {ExclamationOctagon} from '../../../design/icones'
import chroma from 'chroma-js'

export default function Urgencia({ definir, obrigatorio, processando, valor, leitura, rotulo }) {
  const icone = <ExclamationOctagon />
  const titulo = rotulo ?? 'Urgência'

  const { listarUrgenciasDoTarefas, urgencias } = useTarefas()

  useEffect(() => {
    if (!urgencias) {
      listarUrgenciasDoTarefas()
    }
  }, [listarUrgenciasDoTarefas, urgencias])

  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo) {
      case 'vermelho':
        return '#BD3035'
      case 'laranja':
        return '#ED7A17'
      case 'amarelo':
        return '#F9C72D'
      case 'verde':
        return '#70C968'
      default:
        return ''
    }
  }

  const opcoesDeUrgencias = useMemo(() => {
    if (!urgencias) return []
    return urgencias.map(x => ({
      value: x.codigo,
      label: x.nome,
      color: corDaUrgencia(x.codigo)
    }))
  }, [urgencias])

  if (leitura) {
    if (!valor) return null

    const cor = corDaUrgencia(valor.codigo)

    const color = chroma(cor)
    const corDaFonte = chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        renderizar={() =>
          <span
            className='table-items__bcolor valor w-auto'
            style={{ background: cor, color: corDaFonte}}
          >
            {valor.nome}
          </span>
        }
      />
    )
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={(valor && valor.codigo) ? valor.codigo : valor}
      gravarAoSelecionar={true}
      icone={icone}
      titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor, isValid, isWarning }) =>
        <SelectReact
          nome='urgencia'
          id='urgencia'
          onChange={onChange}
          options={opcoesDeUrgencias}
          valor={valor || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          isLoading={processando}
          isValid={isValid}
          isWarning={isWarning}
          selectColor={true}
        />
      }
    </CampoDaTarefa>
  )
}