import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'

import {  
  LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
} from '../../tipos'

const listouHorariosDaAgendaDoProfissionalDeSaude = (paginaDeDados, filtros) => ({
  paginaDeDados,
  filtros,
  type: LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
})

const erroAoListarHorariosDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const listarHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, filtros) => async dispatch => {
  dispatch({ filtros, type: LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.listarHorariosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, filtros)

    dispatch(listouHorariosDaAgendaDoProfissionalDeSaude(resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarHorariosDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os horários da agenda do profissional de saúde.')))
  }
}

export const adicionarHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, horario) => async dispatch => {
  dispatch({ type: ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.adicionarHorarioDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, horario)

    dispatch(adicionouHorarioDaAgendaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarHorarioDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, horario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar o horário da agenda do profissional de saúde.')))

    return false
  }
}

const adicionouHorarioDaAgendaDoProfissionalDeSaude = horario => ({
  type: ADICIONOU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  horario
})

const erroAoAdicionarHorarioDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, horario) => async dispatch => {
  dispatch({ type: ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.alterarHorarioDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, horario)

    dispatch(alterouHorarioDaAgendaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarHorarioDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, horario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar o horário da agenda do profissional de saúde.')))

    return false
  }
}

const alterouHorarioDaAgendaDoProfissionalDeSaude = horario => ({
  type: ALTEROU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  horario
})

const erroAoAlterarHorarioDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, serie) => async dispatch => {
  dispatch({ type: ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, serie)

    dispatch(alterouSerieDeHorariosDaAgendaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSerieDeHorariosDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, serie }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados da série de horários da agenda do profissional de saúde.')))

    return false
  }
}

const alterouSerieDeHorariosDaAgendaDoProfissionalDeSaude = serie => ({
  type: ALTEROU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  serie
})

const erroAoAlterarSerieDeHorariosDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificador) => async dispatch => {
  dispatch({ type: EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.excluirHorarioDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificador)

    dispatch(excluiuHorarioDaAgendaDoProfissionalDeSaude(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Horário da agenda removido com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirHorarioDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um horário da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuHorarioDaAgendaDoProfissionalDeSaude = identificador => ({
  type: EXCLUIU_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  identificador
})

const erroAoExcluirHorarioDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})

export const excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDoHorario) => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE })

  try {
    const resultado = await api.excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificadorDoHorario)
    
    dispatch(excluiuSerieDeHorariosDaAgendaDoProfissionalDeSaude(identificadorDoHorario))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Série de horários da agenda removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeHorariosDaAgendaDoProfissionalDeSaude(erro, { identificadorDoProfissional, identificadorDoHorario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir uma série de horários da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuSerieDeHorariosDaAgendaDoProfissionalDeSaude = identificadorDoHorario => ({
  type: EXCLUIU_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  identificadorDoHorario
})

const erroAoExcluirSerieDeHorariosDaAgendaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  erro,
  parametros,
})