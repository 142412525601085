import agenda from './agenda/'
import perfil from './perfis'
import unidade from './unidades'
import tiposDeAtendimento from './tipos-de-atendimento'
import configuracoes from './configuracoes'

import {
  RECUPEROU_USUARIO_POR_IDENTIFICADOR,
  SALVOU_USUARIO,
  LISTOU_USUARIOS,
  REMOVER_FOTO_DO_USUARIO
} from '../../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: '',
    ativo: ['Ativo'],
    erro: false,
  },
  itemAtual: {
    agenda: undefined,
    unidade: undefined,
    perfil: undefined,
    tiposDeAtendimento: undefined,
    configuracoes: undefined,
  },
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
}

const usuarios = (state, action) => {
  switch (action.type) {
    case RECUPEROU_USUARIO_POR_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          ...action.usuario
        },
      }
    }

    case SALVOU_USUARIO: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          ...action.usuario
        },
      }
    }

    case LISTOU_USUARIOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: {},
        ordenacao,
        paginaDeDados: { 
          ...paginaDeDados, 
          dados: action.paginaDeDados.dados.map(x => ({...x, nome: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
        },
      }
    }

    case REMOVER_FOTO_DO_USUARIO: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          foto: null
        }
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = usuarios(state, action)

  return {
    ...dados,
    itemAtual: {
      ...dados.itemAtual,
      agenda: agenda(dados.itemAtual.agenda, action),
      unidade: unidade(dados.itemAtual.unidade, action),
      perfil: perfil(dados.itemAtual.perfil, action),
      tiposDeAtendimento: tiposDeAtendimento(dados.itemAtual.tiposDeAtendimento, action),
      configuracoes: configuracoes(dados.itemAtual.configuracoes, action)
    }
  }
}