import React, { useState, useCallback } from 'react'
import * as Yup from 'yup'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import Senha from '../senha'
import { ehUmEmailValido } from '../../bibliotecas/validacoes'
import { Input, Formulario as Form, Select, Checkbox, Telefone, Cpf, InputFoto } from '../formik/formulario'

import {
  RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  SALVAR_USUARIO,
  ADICIONAR_USUARIO,
  LISTAR_PROFISSOES,
  ADICIONAR_FOTO_DO_USUARIO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    adicionarFotoDoUsuario,
    history,
    logins,
    usuario = {},
    salvar,
    sexos,
    tratamentos,
    unidadesFederativas,
    profissoes,
    recuperarLogin,
    removerFotoDoUsuario
  } = props

  const [exibirDadosDoProfissional, setExibirDadosDoProfissional] = useState(false)
  const [profissaoSelecionada, setProfissaoSelecionada] = useState(null)
  const [email, setEmail] = useState(null)

  const informarSenha = !email || logins[email] === undefined

  const selecionarProfissao = useCallback(nome => {
    const profissao = profissoes.find(x => x.nome === nome)
    setProfissaoSelecionada(profissao)
  }, [profissoes])

  const recuperarLoginDoFormulario = email => {
    setEmail(null)
    if (!email) return
    if (!ehUmEmailValido(email)) return
    setEmail(email)
    recuperarLogin(email)
  }

  return (
    <div className='page-paciente-dados'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[ADICIONAR_FOTO_DO_USUARIO]}>
            {({ processando }) => (
              <Spinner operacoes={[RECUPERAR_USUARIO_POR_IDENTIFICADOR, SALVAR_USUARIO, ADICIONAR_USUARIO, LISTAR_PROFISSOES]}>
                <Form
                  reinicializar={true}
                  valoresIniciais={{
                    identificador: '',
                    tratamento: '',
                    nome: '',
                    sexo: '',
                    cpf: '',
                    email: '',
                    foto: '',
                    telefone1: '',
                    telefone2: '',
                    informarDadosProfissionais: '',
                    profissao: '',
                    especialidades: '',
                    conselho: '',
                    unidadeFederativaDoConselhoProfissional: '',
                    numeroDoRegistroNoConselhoProfissional: '',
                    senha: '',
                    confirmacao: '',
                    observacoes: '',
                  }}
                  acao={salvar}
                  esquemaDeValidacoes={Yup.object().shape({
                    nome: Yup.string().required('Obrigatório.'),
                    sexo: Yup.string().required('Obrigatório.'),
                    cpf: Yup.string().ehUmCpfValido('CPF deve ser válido.')
                      .when('profissao', {
                        is: val => val === 'MÉDICO(A)',
                        then: Yup.string().required('Obrigatório.'),
                      }),
                    email: Yup.string().ehUmEmailValido('E-mail deve ser válido.').required('Obrigatório.'),
                    telefone1: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.'),
                    telefone2: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999.'),
                    profissao: exibirDadosDoProfissional ? Yup.string().required('Obrigatório.') : '',
                    unidadeFederativaDoConselhoProfissional: exibirDadosDoProfissional ? Yup.string().required('Obrigatório.') : '',
                    numeroDoRegistroNoConselhoProfissional: exibirDadosDoProfissional ? Yup.number()
                      .typeError('Dever ser numérico')
                      .positive('Deve ser positivo')
                      .integer('Deve ser inteiro').required('Obrigatório.') : '',
                    senha: informarSenha ? Yup.string().required('Obrigatório.') : '',
                    confirmacao: informarSenha ? Yup.string().oneOf([Yup.ref('senha'), null], 'Confirmação deve ser o mesmo valor da senha.') : ''
                  })}
                >
                  {({ values }) => (
                    <>
                      <fieldset>
                        <h2 className='form-title'>Dados Pessoais</h2>
                        <div className='with-thumb'>
                          <div className='with-thumb-sidebar'>
                            <InputFoto
                              habilitarFuncoes={true}
                              bytes={usuario.bytesDaFoto}
                              nome='foto'
                              selecionar={foto => foto && adicionarFotoDoUsuario(foto)}
                              remover={foto => foto && removerFotoDoUsuario(foto)}
                              carregandoFoto={processando}
                            />
                          </div>
                          <r-grid columns-md={6} columns-lg={12}>
                            <r-cell span={4} span-md={3} span-lg={2}>
                              <Select
                                nome='tratamento'
                                tabIndex={1}
                                titulo='Tratamento'
                                itens={tratamentos}
                                campoCodigo='nome'
                                campoDescricao='nome'
                              />
                            </r-cell>
                            <r-cell span={4} span-md={4} span-lg={6}>
                              <Input
                                nome='nome'
                                tabIndex={2}
                                titulo='Nome *'
                              />
                            </r-cell>
                            <r-cell span={2} span-md={3} span-lg={2}>
                              <Select
                                nome='sexo'
                                tabIndex={3}
                                titulo='Sexo *'
                                itens={sexos}
                                campoCodigo='codigo'
                                campoDescricao='nome'
                              />
                            </r-cell>
                            <r-cell span={2} span-md={3} span-lg={2}>
                              <Cpf
                                nome='cpf'
                                tabIndex={4}
                                titulo={values.profissao === 'MÉDICO(A)' ? 'CPF *' : 'CPF'}
                                validate={true}
                              />
                            </r-cell>
                          </r-grid>
                        </div>
                      </fieldset>
                      <div className='mt-2'>
                        <fieldset>
                          <h2 className='form-title'>Dados de Contato</h2>
                          <r-grid columns-md={6} columns-lg={12}>
                            <r-cell span={4} span-md={2} span-lg={6}>
                              <Input
                                nome='email'
                                tabIndex={5}
                                titulo='E-mail *'
                                onBlur={e => recuperarLoginDoFormulario(e.target.value)}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={2} span-lg={3}>
                              <Telefone
                                nome='telefone1'
                                tabIndex={6}
                                titulo='Telefone #1'
                              />
                            </r-cell>
                            <r-cell span={4} span-md={2} span-lg={3}>
                              <Telefone
                                nome='telefone2'
                                tabIndex={7}
                                titulo='Telefone #2'
                              />
                            </r-cell>
                          </r-grid>
                        </fieldset>
                      </div>
                      <div className='mt-2'>
                        <fieldset>
                          <h2 className='form-title'>Dados Profissionais</h2>
                          <r-grid columns-md={6} columns-lg={12}>
                            <r-cell span={4} span-md={6} span-lg={12}>
                              <Checkbox
                                nome='informarDadosProfissionais'
                                tabIndex={8}
                                titulo='Informar dados de Profissional de Saúde'
                                onChange={setExibirDadosDoProfissional}
                              />
                            </r-cell>
                            {exibirDadosDoProfissional &&
                              <>
                                <r-cell span={4} span-md={6} span-lg={6}>
                                  <div className='form-group'>
                                    <Select
                                      nome='profissao'
                                      tabIndex={9}
                                      titulo='Profissão de Saúde *'
                                      itens={profissoes}
                                      campoCodigo='nome'
                                      campoDescricao='nome'
                                      onChange={selecionarProfissao}
                                    />
                                  </div>
                                </r-cell>
                                <r-cell span={4} span-md={6} span-lg={6}>
                                  <Select
                                    nome='especialidades'
                                    tabIndex={10}
                                    titulo='Especialidade'
                                    isMulti
                                    campoCodigo='nome'
                                    campoDescricao='nome'
                                    itens={profissaoSelecionada ? profissaoSelecionada.especialidades : []}
                                    isDisabled={!(profissaoSelecionada && profissaoSelecionada.especialidades.length > 0)}
                                  />
                                </r-cell>
                                <r-cell span={2} span-md={2} span-lg={2}>
                                  <div className='form-group'>
                                    <Input
                                      nome='conselho'
                                      tabIndex={11}
                                      titulo='Conselho'
                                      style={{ border: profissaoSelecionada ? 'solid 1px #393042' : '' }}
                                      value={profissaoSelecionada ? profissaoSelecionada.conselho : ''}
                                    />
                                  </div>
                                </r-cell>
                                <r-cell span={2} span-md={2} span-lg={2}>
                                  <Select
                                    nome='unidadeFederativaDoConselhoProfissional'
                                    tabIndex={12}
                                    titulo='UF *'
                                    itens={unidadesFederativas}
                                    campoCodigo='sigla'
                                    campoDescricao='sigla'
                                  />
                                </r-cell>
                                <r-cell span={4} span-md={2} span-lg={2}>
                                  <Input
                                    nome='numeroDoRegistroNoConselhoProfissional'
                                    tabIndex={13}
                                    titulo='Registro *'
                                  />
                                </r-cell>
                              </>
                            }
                          </r-grid>
                        </fieldset>
                      </div>
                      <div className='mt-2 page-alterar-senha'>
                        <fieldset>
                          <h2 className='form-title'>Autenticação</h2>
                          {!informarSenha &&
                            <div className='form-choice-alerta'>
                              <div className='form-choice-alerta__item'>
                                <i className='icon icon-attention-circled'></i>
                              </div>
                              <div className='form-choice-alerta__item'>
                                Este e-mail já possui dados de autenticação cadastrados. Não é necessário cadastrar novamente a senha.
                              </div>
                            </div>
                          }
                          {informarSenha &&
                            <r-grid columns-md={6} columns-lg={12}>
                              <r-cell span={4} span-md={3} span-lg={3}>
                                <Input
                                  component={Senha}
                                  nome='senha'
                                  tabIndex={14}
                                  titulo='Senha *'
                                />
                              </r-cell>
                              <r-cell span={4} span-md={3} span-lg={3}>
                                <Input
                                  component={Senha}
                                  nome='confirmacao'
                                  tabIndex={15}
                                  titulo='Confirmar Senha'
                                />
                              </r-cell>
                            </r-grid>
                          }
                        </fieldset>
                      </div>
                      <div className='mt-2'>
                        <fieldset>
                          <h2 className='form-title'>Observação</h2>
                          <r-grid columns-md={6} columns-lg={12}>
                            <r-cell span={6} span-md={6} span-lg={12}>
                              <Input
                                as='textarea'
                                nome='observacoes'
                                tabIndex={16}
                                titulo='Observações'
                              />
                            </r-cell>
                          </r-grid>
                        </fieldset>
                      </div>
                      <div className='list-btn'>
                        <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                        <button type='submit' className='button --primary'>Salvar Usuário</button>
                      </div>
                    </>
                  )}
                </Form>
              </Spinner>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}