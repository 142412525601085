import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { ALTERAR_STATUS_DE_CONEXAO_DE_REDE, ALTEROU_STATUS_DE_CONEXAO_DE_REDE } from './tipos'

export const alterarStatusDeConexaoDeRede = (status) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DE_CONEXAO_DE_REDE })

  if (status) {
    dispatch(alterouStatusDeConexaoDeRede(status))
    dispatch(adicionarNotificacoesDaApi(status))
  } else {
    dispatch(alterouStatusDeConexaoDeRede(status))
    dispatch(adicionarNotificacoesDaApi(true, notificacao.deErro('Verifique sua conexão de internet.')))
  }
}

const alterouStatusDeConexaoDeRede = status => ({
  type: ALTEROU_STATUS_DE_CONEXAO_DE_REDE,
  status
})