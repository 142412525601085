import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO,  
  LISTAR_PACIENTES_DO_AGENDAMENTO,
  LISTOU_PACIENTES_DO_AGENDAMENTO,
} from '../tipos'

const listouPacientesDoAgendamento = (filtros, ordem, pacientes) => ({
  type: LISTOU_PACIENTES_DO_AGENDAMENTO,
  filtros,
  ordem,
  pacientes,
})

const erroAoListarPacientesDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const listarPacientesDoAgendamento = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PACIENTES_DO_AGENDAMENTO })

    try {
      const retorno = await api.listarPacientes(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouPacientesDoAgendamento(filtros, ordem, retorno.data.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarPacientesDoAgendamento(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes do agendamento.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 300,
      key: LISTAR_PACIENTES_DO_AGENDAMENTO,
    }
  }

  return resultado
}