import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import { LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE, REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE } from '../../../../acoes/tipos'

const renderizarStatus = status => {
  switch (status) {
    case 'Alta': {
      return '#1ABC9C'
    }
    case 'Em observação': {
      return '#F1C40F'
    }
    case 'Evoluiu para internação': {
      return '#f277c8'
    }
    case 'Informado pela operadora': {
      return '#555555'
    }
    default: {
      return '#cc0a11'
    }
  }
}

export default function IdasAoProntoAtendimento({ identificadorDoPaciente, idasAoProntoAtendimentoDoPaciente }) {
  const {
    listarIdasAoProntoAtendimentoDoPaciente,
    statusDeIdaAoProntoAtendimento,
    listarStatusDeIdaAoProntoAtendimento,
    motivosDeIdaAoProntoAtendimento,
    listarMotivosDeIdaAoProntoAtendimento,
    estabelecimentosDeSaude,
    listarEstabelecimentosDeSaude,
    adicionarIdaAoPa,
    alterarIdaAoPa,
    removerIdaAoPa
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const [estabelecimentos, setEstabelecimentos] = useState([])

  const referenciaDoInfo = useRef(null)

  const podeListarStatus = usePossuiAsPermissoes([permissoes.LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO])
  const podeListarEstabelecimentosDeSaude = usePossuiAsPermissoes([permissoes.LISTAR_ESTABELECIMENTOS_DE_SAUDE])
  const podeListarMotivos = usePossuiAsPermissoes([permissoes.LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO])

  const podeListarParametros = podeListarStatus && podeListarEstabelecimentosDeSaude && podeListarMotivos

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE])
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE])

  useCliqueForaDoElemento(referenciaDoInfo, () => setExibirTooltipInfo(false))

  useEffect(() => {
    if (idasAoProntoAtendimentoDoPaciente === undefined) {
      listarIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente)
    }
  }, [listarIdasAoProntoAtendimentoDoPaciente, identificadorDoPaciente, idasAoProntoAtendimentoDoPaciente])

  useEffect(() => listarStatusDeIdaAoProntoAtendimento(), [listarStatusDeIdaAoProntoAtendimento])
  useEffect(() => listarMotivosDeIdaAoProntoAtendimento(), [listarMotivosDeIdaAoProntoAtendimento])
  useEffect(() => listarEstabelecimentosDeSaude(), [listarEstabelecimentosDeSaude])

  useEffect(() => {
    if (selecionada && !estabelecimentosDeSaude.find(x => x.nome === selecionada.estabelecimentoDeSaude)) {
      const estabelecimentos = [...estabelecimentosDeSaude, { nome: selecionada.estabelecimentoDeSaude }]
      setEstabelecimentos(estabelecimentos)
    } else {
      setEstabelecimentos(estabelecimentosDeSaude)
    }
  }, [selecionada, estabelecimentosDeSaude])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
      setEstabelecimentos(estabelecimentosDeSaude)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarIdaAoPa(identificadorDoPaciente, item) : await adicionarIdaAoPa(identificadorDoPaciente, item)
  }

  if (idasAoProntoAtendimentoDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de idas ao pronto atendimento'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          motivos={motivosDeIdaAoProntoAtendimento}
          estabelecimentosDeSaude={estabelecimentos}
          status={statusDeIdaAoProntoAtendimento}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de ida ao pronto atendimento'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerIdaAoPa(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  podeAlterar && podeListarParametros &&
                  (
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  )
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                      <div className='tooltip__drop custom-scrollbar right20'>
                        <div className='tooltip__drop__body'>
                          <div className='tooltip__drop__title border-bottom-none'>
                            Histórico
                            <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i></button>
                          </div>
                          <div className='tooltip__drop__description'>
                            {item.status === 'Informado pela operadora' &&
                              <div>
                                <span><strong>Última alteração: </strong>{item.usuario.nome}</span> <br />
                                {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                                <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                              </div>
                            }
                            {item.descricoes.map(descricao =>
                              <div className='border-top-active' key={descricao.identificador} >
                                {descricao.descricao &&
                                  <>
                                    <strong>Descrição: </strong><br />
                                    <pre>{descricao.descricao}</pre>
                                  </>
                                }
                                <span><em>{descricao.usuario.nome}</em>{descricao.usuario.profissao ? <> | <em>{descricao.usuario.profissao.nome}</em></> : ''}</span> <br />
                                <span><em>Data e Hora: {formatarDataEHoraParaFormatoLocal(descricao.dataEHora)}</em></span> <br />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={idasAoProntoAtendimentoDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarParametros}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros de idas ao pronto atendimento.'
              classNameAdicionar='col-xs-6 col-lg-2'
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                nome='Data de admissão'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataParaFormatoLocal(item.inicio)}
              />
              <Coluna
                className='col-lg-2'
                nome='Data de saída'
                renderizar={item => formatarDataParaFormatoLocal(item.fim)}
              />
              <Coluna
                className='col-lg-2'
                nome='Estabelecimento'
                campoDeDados='estabelecimentoDeSaude'
              />
              <Coluna
                className='col-lg-2 ajusta-indicator'
                nome='Motivo'
                renderizar={item => item.motivos.length > 1 ?
                  <>
                    {item.motivos[0]}
                    <Motivos key={`motivo_${item.identificador}`} registro={item} />
                  </> : item.motivos.length > 0 && item.motivos[0]}
              />
              <Coluna
                className='col-lg-2'
                nome='Status'
                renderizar={item =>
                  <p className='table-items__status'>
                    <span className='table-items__status__bg' style={{ backgroundColor: renderizarStatus(item.status) }}></span>
                    <span className='table-items__status__text'>{item.status}</span>
                  </p>
                }
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-doc-text'
                    campoDeDados='status'
                    nome='Status'
                  />
                  <Coluna
                    icone='icon-date'
                    nome='Admissão'
                    renderizar={item => formatarDataParaFormatoLocal(item.inicio)}
                  />
                  <Coluna
                    icone='icon-date'
                    nome='Saída'
                    className='table-items-expanded-45'
                    renderizar={item => item.fim ? formatarDataParaFormatoLocal(item.fim) : <>&nbsp;</>}
                  />
                  <Coluna
                    icone='icon-estabelecimento-de-saude'
                    nome='Estabelecimento'
                    campoDeDados='estabelecimentoDeSaude'
                  />
                  <Coluna
                    icone='icon-name'
                    nome='Motivo'
                    className='table-items-expanded-45'
                    renderizar={item => item.motivos.length > 1 ?
                      <>
                        {item.motivos[0]}
                        <Motivos key={`motivo_${item.identificador}`} registro={item} />
                      </>
                      : item.motivos.length > 0 && item.motivos[0]}
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='Descrição mais recente'
                    className='table-items-expanded-100__box-item'
                    renderizar={item => item.descricoes.length > 0 && <pre>{item.descricoes[0].descricao}</pre>}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirIdaAoPa = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                {selecionada && <h2>{`Tem certeza que deseja remover a ida ao pronto atendimento do dia ${formatarDataParaFormatoLocal(selecionada && selecionada.inicio)}?`}</h2>}
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirIdaAoPa}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function Motivos({ registro }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDosMotivos = useRef(null)
  const posicaoAEsquerda = referenciaDosMotivos.current && referenciaDosMotivos.current.offsetLeft

  useCliqueForaDoElemento(referenciaDosMotivos, () => setVisivel(false))

  if (!registro) return

  return (
    <>
      <button
        className='indicator-tooltip'
        type='button'
      >
        <i className={`icon-arrow${visivel ? '-up' : '-down'}`}
          onClick={() => setVisivel(!visivel)}
          ref={referenciaDosMotivos}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop tooltip-motivos custom-scrollbar' style={{ left: posicaoAEsquerda }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              {registro.motivos.map((x, index) =>
                <span key={index}>- {x} <br /></span>
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}