import React, { useEffect } from 'react'
import FileSaver from 'file-saver'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import { Switch } from 'react-router-dom'
import { formatarDataEHoraParaFormatoUniversal } from '../../../bibliotecas/data'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Formulario from './formulario'

export default function Pacientes(props) {
  const {
    celulas,
    enfermeiros,
    fazerDownloadDePacientes,
    filtros,
    listarCelulasDoRelatorioDePacientes,
    listarOperadorasDoRelatorioDePacientes,
    listarMedicosDoRelatorioDePacientes,
    listarEnfermeirosDoRelatorioDePacientes,
    listarProgramasDoRelatorioDePacientes,
    match,
    medicos,
    operadoras,
    programas,
    limparFiltrosDoRelatorioDePacientes
  } = props

  useEffect(() => {
    listarCelulasDoRelatorioDePacientes()
    listarOperadorasDoRelatorioDePacientes()
    listarMedicosDoRelatorioDePacientes()
    listarEnfermeirosDoRelatorioDePacientes()
    listarProgramasDoRelatorioDePacientes()
  }, [listarCelulasDoRelatorioDePacientes,
    listarOperadorasDoRelatorioDePacientes,
    listarMedicosDoRelatorioDePacientes,
    listarEnfermeirosDoRelatorioDePacientes,
    listarProgramasDoRelatorioDePacientes
  ])

  const gerarNome = (tipo) => {
    const agora = formatarDataEHoraParaFormatoUniversal(new Date()).split('-').join('_').split(' ').join('_').split(':').join('_')

    return `${tipo}_em_${agora}.csv`
  }

  const download = async formulario => {
    const dados = {
      ...formulario,
      operadora: formulario.operadora === 'todas' ? '' : formulario.operadora,
      programa: formulario.programa === 'todos' ? '' : formulario.programa
    }

    const retorno = await fazerDownloadDePacientes(dados)
    const nome = gerarNome('pacientes', formulario)

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'text/csv' })
      FileSaver.saveAs(arquivo, nome)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_PACIENTES]}>
              <div className='relatorios'>
                <Formulario
                  {...props}
                  celulas={celulas}
                  download={download}
                  enfermeiros={enfermeiros}
                  filtrosIniciais={filtros}
                  medicos={medicos}
                  operadoras={operadoras}
                  programas={programas}
                  limparFiltrosDoRelatorioDePacientes={limparFiltrosDoRelatorioDePacientes}
                />
              </div>
            </Permissao>
          )
        }
      />
    </Switch>
  )
}