import {
  LISTOU_PROFISSIONAIS_DO_NPS
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PROFISSIONAIS_DO_NPS: {
      return action.profissionais
    }

    default: {
      return state
    }
  }
}