import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_COMENTARIO_DA_TAREFA,
  ADICIONOU_COMENTARIO_DA_TAREFA,
  ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA,
  ALTERAR_COMENTARIO_DA_TAREFA,
  ALTEROU_COMENTARIO_DA_TAREFA,
  ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA,
  REMOVER_COMENTARIO_DA_TAREFA,
  REMOVEU_COMENTARIO_DA_TAREFA,
  ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA,
} from '../tipos'

const adicionouComentarioDaTarefa = (identificadorDaTarefa, comentario) => ({
  identificadorDaTarefa,
  comentario,
  type: ADICIONOU_COMENTARIO_DA_TAREFA,
})

const erroAoAdicionarComentarioDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_COMENTARIO_DA_TAREFA,
  erro,
  parametros,
})

export const adicionarComentarioDaTarefa = (identificadorDaTarefa, comentario) => async dispatch => {
  dispatch({ type: ADICIONAR_COMENTARIO_DA_TAREFA })

  try {
    const resultado = await api.adicionarComentarioDaTarefa(identificadorDaTarefa, comentario)

    dispatch(adicionouComentarioDaTarefa(identificadorDaTarefa, comentario))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarComentarioDaTarefa(erro, { identificadorDaTarefa, comentario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adiconar o comentário da tarefa.')))

    return false
  }
}

const alterouComentarioDaTarefa = (identificadorDaTarefa, identificadorDoComentario, comentario) => ({
  identificadorDaTarefa,
  identificadorDoComentario,
  comentario,
  type: ALTEROU_COMENTARIO_DA_TAREFA,
})

const erroAoAlterarComentarioDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_COMENTARIO_DA_TAREFA,
  erro,
  parametros,
})

export const alterarComentarioDaTarefa = (identificadorDaTarefa, identificador, comentario) => async dispatch => {
  dispatch({ type: ALTERAR_COMENTARIO_DA_TAREFA })

  try {
    const resultado = await api.alterarComentarioDaTarefa(identificadorDaTarefa, identificador, comentario)

    dispatch(alterouComentarioDaTarefa(identificadorDaTarefa, identificador, comentario))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarComentarioDaTarefa(erro, { identificadorDaTarefa, identificador, comentario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o comentário da tarefa.')))

    return false
  }
}

const removeuComentarioDaTarefa = (identificadorDaTarefa, identificador) => ({
  identificadorDaTarefa,
  identificador,
  type: REMOVEU_COMENTARIO_DA_TAREFA,
})

const erroAoRemoverComentarioDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_COMENTARIO_DA_TAREFA,
  erro,
  parametros,
})

export const removerComentarioDaTarefa = (identificadorDaTarefa, identificador) => async dispatch => {
  dispatch({ type: REMOVER_COMENTARIO_DA_TAREFA })

  try {
    const resultado = await api.removerComentarioDaTarefa(identificadorDaTarefa, identificador)

    dispatch(removeuComentarioDaTarefa(identificadorDaTarefa, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverComentarioDaTarefa(erro, { identificadorDaTarefa, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover o comentário da tarefa.')))

    return false
  }
}