import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { 
  LISTOU_PREVISOES_DE_RETORNO,
  ERRO_AO_LISTAR_PREVISOES_DE_RETORNO,
  LISTAR_PREVISOES_DE_RETORNO
} from './tipos'

const listouPrevisoesDeRetorno = previsoesDeRetorno => ({
  type: LISTOU_PREVISOES_DE_RETORNO,
  previsoesDeRetorno
})

const erroAoListarPrevisoesDeRetorno = erro => ({
  type: ERRO_AO_LISTAR_PREVISOES_DE_RETORNO,
  erro
})

export const listarPrevisoesDeRetorno = () => async dispatch => {
  dispatch({ type: LISTAR_PREVISOES_DE_RETORNO })

  try {
    const resultado = await api.listarPrevisoesDeRetorno()

    dispatch(listouPrevisoesDeRetorno(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPrevisoesDeRetorno(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as previsões de retorno.')))
  }
}