import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTOU_UNIDADES_DO_USUARIO_LOGADO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  LISTAR_UNIDADES_DO_USUARIO_LOGADO
} from '../../tipos'

const listouUnidadesDoUsuarioLogado = unidades => ({
  type: LISTOU_UNIDADES_DO_USUARIO_LOGADO,
  unidades,
})

const erroAoListarUnidadesDoUsuarioLogado = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  erro
})

export const listarUnidadesDoUsuarioLogado = identificador => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DO_USUARIO_LOGADO })

  try {
    const resultado = await api.listarUnidadesDoUsuario(identificador)

    dispatch(listouUnidadesDoUsuarioLogado(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUnidadesDoUsuarioLogado(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as unidades do usuário logado.')))
  }
}