export default function verificarSecaoComExame(index, item, componentes) {
  const ehExame = item?.metadados?.exame === 'true'
  let ehUmaSecaoComExame = false

  if (!ehExame) {

    let contadorDeExamesDaSecao = 0
    for (let i = index + 1; i < componentes.length; i++) {
      if (componentes[i]?.metadados?.exame === 'true') contadorDeExamesDaSecao++
      if (!componentes[i].metadados || !componentes[i].metadados.exame || !componentes[i].metadados.exame === 'true') break
    }

    if (contadorDeExamesDaSecao > 1) ehUmaSecaoComExame = true    
  }

  return ehUmaSecaoComExame
}