import {
  LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  LISTOU_SUGESTOES_DE_PACIENTE,
  LISTOU_TOKENS_DE_SUGESTAO,
  LISTOU_TOKENS_DE_EXEMPLO,
  LISTOU_COMPONENTES_COM_TEMPLATE,
  ADICIONOU_TEMPLATE,
  RECUPEROU_TEMPLATE_POR_IDENTIFICADOR,
  SALVOU_TEMPLATE,
  LISTOU_TEMPLATES,
  EXCLUIU_TEMPLATE,
  LISTOU_TEMPLATES_DO_PRONTUARIO,
  ADICIONOU_TEMPLATE_DO_PRONTUARIO,
} from '../acoes/tipos'

const estadoInicial = {
  profissionaisDeSaude: [],
  sugestoes: [],
  tokens: [],
  componentes: [],
  templates: [],
  selecionado: {},
  filtros: {
    termo: '',
    compartilhado: ['Sim', 'Não'],
  },
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO: {
      return {
        ...state,
        profissionaisDeSaude: action.profissionaisDeSaude
      }
    }

    case LISTOU_TOKENS_DE_EXEMPLO:
    case LISTOU_SUGESTOES_DE_PACIENTE: {
      return {
        ...state,
        sugestoes: action.sugestoes
      }
    }

    case LISTOU_TOKENS_DE_SUGESTAO: {
      return {
        ...state,
        tokens: action.tokens
      }
    }

    case LISTOU_COMPONENTES_COM_TEMPLATE: {
      return {
        ...state,
        componentes: action.componentes
      }
    }

    case ADICIONOU_TEMPLATE: {
      return {
        ...state,
        selecionado: {}
      }
    }

    case RECUPEROU_TEMPLATE_POR_IDENTIFICADOR: {
      return {
        ...state,
        selecionado: action.template
      }
    }

    case SALVOU_TEMPLATE: {
      return {
        ...state,
        selecionado: {}
      }
    }

    case LISTOU_TEMPLATES: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        selecionado: {},
        ordenacao,
        paginaDeDados
      }
    }

    case EXCLUIU_TEMPLATE: {
      return {
        ...state,
        selecionado: {}
      }
    }

    case LISTOU_TEMPLATES_DO_PRONTUARIO: {
      return {
        ...state,
        templates: action.templates,
      }
    }

    case ADICIONOU_TEMPLATE_DO_PRONTUARIO: {
      return {
        ...state,
        templates: [
          ...state.templates,
          action.template,
        ]
      }
    }

    default: {
      return state
    }
  }
}