import { LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL } from '../../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL: {
      return action.feriadosTrabalhados
    }

    default: {
      return state
    }
  }
}