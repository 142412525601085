import React from 'react'
import { WhatsApp } from '../../../design/icones'

export default function ContatosDoAgendamento({ agendamento = {} }) {
  const renderContato = (tipoContato, proprietario, nome, telefone, grauDeRelacao, whatsApp) => {
    if (!telefone) return null

    const label = `${proprietario}${proprietario !== 'Paciente' ? ` ${nome}` : ''}`
    const grauRelacao = `${proprietario !== 'Paciente' ? ` (${grauDeRelacao})` : ''}`

    return (
      <p key={tipoContato}>
        <strong>{label}{grauRelacao}{telefone ? ': ' : ''}</strong>
        {telefone}{' '}
        {whatsApp && <WhatsApp size={14} />}
      </p>
    )
  }

  if (!agendamento.telefone1 && !agendamento.telefone2 && !agendamento.email) {
    return null
  }

  return (
      <div>
        {renderContato(
          'telefone1',
          agendamento.proprietarioDoTelefone1,
          agendamento.nomeDoTelefone1,
          agendamento.telefone1,
          agendamento.grauDeRelacaoDoTelefone1,
          agendamento.telefone1EhWhatsapp
        )}
        {renderContato(
          'telefone2',
          agendamento.proprietarioDoTelefone2,
          agendamento.nomeDoTelefone2,
          agendamento.telefone2,
          agendamento.grauDeRelacaoDoTelefone2,
          agendamento.telefone2EhWhatsapp
        )}
        {agendamento.email && <p><strong>E-mail:</strong>{' '}{agendamento.email}</p>}
    </div>
  )
}