import axios from 'axios'
import api from '../../api'
import configuracoes from '../../configuracoes'
import constantes from '../../configuracoes/constantes'
import notificacao from '../../bibliotecas/notificacao'
import { listarUnidadesDoUsuarioLogado } from './logado/unidades'
import { recuperarEmpresa } from '../empresa/'
import { recuperarFotoDoUsuarioLogadoPorIdentificador } from './foto'
import { adicionarNotificacoesDaApi, adicionarNotificacao } from '../notificacoes'
import { recuperarCertificadosEmNuvemDoUsuario, recuperarSessaoComCertificadoEmNuvem } from '../lacuna'

import {
  AUTENTICAR_USUARIO,
  REAUTENTICAR_USUARIO,
  AUTENTICOU_USUARIO,
  NAO_AUTENTICOU_USUARIO,
  LOGOUT,
  LISTAR_USUARIOS,
  LISTOU_USUARIOS,
  ERRO_AO_LISTAR_USUARIOS,
  ADICIONAR_USUARIO,
  ADICIONOU_USUARIO,
  ERRO_AO_ADICIONAR_USUARIO,
  RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  RECUPEROU_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  SALVAR_USUARIO,
  SALVOU_USUARIO,
  ERRO_AO_SALVAR_USUARIO,
  ALTERAR_SENHA_DO_USUARIO,
  ALTEROU_SENHA_DO_USUARIO,
  ERRO_AO_ALTERAR_SENHA_DO_USUARIO,
  RECUPERAR_VALIDADE_DO_TOKEN,
  RECUPEROU_VALIDADE_DO_TOKEN,
  ERRO_AO_RECUPERAR_VALIDADE_DO_TOKEN,
  LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  CANCELAR_LOGIN,
  RECUPERAR_LOGIN,
  RECUPEROU_LOGIN,
  ERRO_AO_RECUPERAR_LOGIN,
  ALTEROU_STATUS_DO_USUARIO,
  ERRO_AO_ALTERAR_STATUS_DO_USUARIO,
  ALTERAR_STATUS_DO_USUARIO,
  ALTERAR_FILTROS_DE_USUARIOS,
  RECUPERAR_SENHA,
  RECUPEROU_SENHA,
  ERRO_AO_RECUPERAR_SENHA,
  REDEFINIR_SENHA,
  REDEFINIU_SENHA,
  ERRO_AO_REDEFINIR_SENHA,
  ALTERAR_UNIDADE_DO_LOGIN,
  ALTEROU_UNIDADE_DO_LOGIN,
  ERRO_AO_ALTERAR_UNIDADE_DO_LOGIN,
  LISTAR_EMPRESAS_HABILITADAS,
  LISTOU_EMPRESAS_HABILITADAS,
  ERRO_AO_LISTAR_EMPRESAS_HABILITADAS,
  AUTENTICOU_USUARIO_NO_FIRESTORE,
  ERRO_AO_AUTENTICAR_USUARIO_NO_FIRESTORE,
  AUTENTICAR_USUARIO_NO_FIRESTORE,
} from '../tipos'
import { useFirebase } from "../../configuracoes/firestore";

const autenticouUsuario = (token, usuario) => {
  const identificadorDoToken = `token`
  localStorage.setItem(identificadorDoToken, token)
  configuracoes.configurarToken(`Bearer ${token}`)

  return {
    type: AUTENTICOU_USUARIO,
    usuario
  }
}

const naoAutenticouUsuario = (erro, credenciais) => {
  const identificadorDoToken = `token`
  localStorage.removeItem(identificadorDoToken)

  configuracoes.removerConfiguracaoDeToken()

  return {
    type: NAO_AUTENTICOU_USUARIO,
    erro,
    parametros: { credenciais }
  }
}

export const autenticarUsuario = credenciais => async dispatch => {
  dispatch({ type: AUTENTICAR_USUARIO })

  try {
    const resultado = await api.autenticarUsuario(credenciais)
    const { token } = resultado.data.dados.token
    const outroResultado = await api.recuperarUsuarioAutenticado(token)
    const usuario = outroResultado.data.dados

    const modalNovidadesExibidoKeys = Object.entries(localStorage).filter(([key, _]) => {
      return key.startsWith(constantes.releaseExibido)
    }).map(([key, value]) => {
      return { codigo: key, valor: value }
    })

    localStorage.clear()

    modalNovidadesExibidoKeys.forEach(({ codigo, valor }) => {
      localStorage.setItem(codigo, valor)
    })

    await dispatch(autenticouUsuario(token, usuario))
    await dispatch(listarUnidadesDoUsuarioLogado(usuario.identificador))
    await dispatch(autenticarUsuarioNoFirestore())
    dispatch(recuperarEmpresa())

    if (usuario.foto) {
      const foto = await dispatch(recuperarFotoDoUsuarioLogadoPorIdentificador(usuario.identificador))
      localStorage.setItem(`foto_usuario_${credenciais.email}`, foto)
    }

    dispatch(recuperarUsuarioPorIdentificador(usuario.identificador))
    dispatch(adicionarNotificacoesDaApi(outroResultado))
    dispatch(recuperarCertificadosEmNuvemDoUsuario())
    dispatch(recuperarSessaoComCertificadoEmNuvem())

    return true
  } catch (erro) {
    dispatch(naoAutenticouUsuario(erro, credenciais))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível autenticar o usuário.')))

    return false
  }
}

export const reautenticarUsuario = novoToken => async dispatch => {
  dispatch({ type: REAUTENTICAR_USUARIO })

  const token = novoToken ? novoToken : localStorage.getItem(`token`)

  if (!token) {
    dispatch(naoAutenticouUsuario({}))

    return false
  }

  try {
    const resultado = await api.recuperarUsuarioAutenticado(token)
    const usuario = resultado.data.dados

    await dispatch(autenticouUsuario(token, usuario))
    await dispatch(autenticarUsuarioNoFirestore())
    await dispatch(recuperarSessaoComCertificadoEmNuvem())

    dispatch(recuperarEmpresa())
    dispatch(listarEmpresasHabilitadas())

    if (usuario.foto) {
      dispatch(recuperarFotoDoUsuarioLogadoPorIdentificador(usuario.identificador))
    }

    dispatch(listarUnidadesDoUsuarioLogado(usuario.identificador))
    dispatch(recuperarUsuarioPorIdentificador(usuario.identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))
    dispatch(recuperarCertificadosEmNuvemDoUsuario())

    return true
  } catch (erro) {
    dispatch(naoAutenticouUsuario(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível reautenticar o usuário.')))

    return false
  }
}

const autenticouUsuarioNoFirestore = () => ({
  type: AUTENTICOU_USUARIO_NO_FIRESTORE
})

const erroAoAutenticarUsuarioNoFirestore = erro => ({
  type: ERRO_AO_AUTENTICAR_USUARIO_NO_FIRESTORE,
  erro,
})

export const autenticarUsuarioNoFirestore = () => async dispatch => {
  dispatch({ type: AUTENTICAR_USUARIO_NO_FIRESTORE })

  try {
    const resultado = await api.recuperarTokenDoFirestore()
    const token = resultado.data.dados
    const firebase = useFirebase()

    await firebase.auth().signInWithCustomToken(token)

    dispatch(autenticouUsuarioNoFirestore())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAutenticarUsuarioNoFirestore(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao autenticar o usuário no firestore.')))

    return false
  }
}

const recuperouValidadeDoToken = validade => ({
  type: RECUPEROU_VALIDADE_DO_TOKEN,
  validade
})

const erroAoRecuperarValidadeDoToken = erro => ({
  type: ERRO_AO_RECUPERAR_VALIDADE_DO_TOKEN,
  erro
})

export const recuperarValidadeDoToken = () => async dispatch => {
  dispatch({ type: RECUPERAR_VALIDADE_DO_TOKEN })

  try {
    const resultado = await api.recuperarValidadeDoToken()
    const validade = resultado.data.resultado

    dispatch(recuperouValidadeDoToken(validade))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarValidadeDoToken(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a validade do token.')))
  }
}

export const logout = () => async dispatch => {
  localStorage.removeItem(`token`)
  localStorage.removeItem(`sessao_iniciada`)

  delete axios.defaults.headers.common['Authorization']

  api.efetuarLogout()
  dispatch({ type: LOGOUT })
  return true
}

const listouUsuarios = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_USUARIOS,
  filtros,
  ordenacao,
  paginaDeDados,
})

const erroAoListarUsuarios = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_USUARIOS,
  erro,
  parametros,
})

export const listarUsuarios = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_USUARIOS })

  try {
    const resultado = await api.listarUsuarios(filtros, pagina, ordenacao)
    dispatch(listouUsuarios(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUsuarios(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os usuários.')))
  }
}

export const alterarFiltrosDeUsuarios = filtros => ({
  type: ALTERAR_FILTROS_DE_USUARIOS,
  filtros,
})

export const adicionarUsuario = usuario => async dispatch => {
  dispatch({ type: ADICIONAR_USUARIO })

  try {
    const resultado = await api.adicionarUsuario(usuario)
    dispatch(adicionouUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarUsuario(erro, { usuario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do usuário.')))

    return false
  }
}

const adicionouUsuario = usuario => ({
  type: ADICIONOU_USUARIO,
  usuario
})

const erroAoAdicionarUsuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_USUARIO,
  erro,
  parametros,
})

const recuperouUsuarioPorIdentificador = usuario => async dispatch => {
  dispatch({ type: RECUPEROU_USUARIO_POR_IDENTIFICADOR, usuario })
}

const erroAoRecuperarUsuarioPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  erro,
  parametros,
})

export const recuperarUsuarioPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_USUARIO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarUsuarioPorIdentificador(identificador)

    dispatch(recuperouUsuarioPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarUsuarioPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar usuário por identificador.')))

    return false
  }
}

const salvouUsuario = usuario => ({
  type: SALVOU_USUARIO,
  usuario,
})

const erroAoSalvarUsuario = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_USUARIO,
  erro,
  parametros,
})

export const salvarUsuario = (usuario, identificador) => async dispatch => {
  dispatch({ type: SALVAR_USUARIO })

  try {
    const resultado = await api.salvarUsuario(usuario)

    dispatch(salvouUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    if (resultado.data.dados.foto && identificador) {
      dispatch(recuperarFotoDoUsuarioLogadoPorIdentificador(resultado.data.dados.identificador))
    }

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoSalvarUsuario(erro, { usuario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o usuário.')))

    return false
  }
}

const alterouSenhaDoUsuario = () => ({
  type: ALTEROU_SENHA_DO_USUARIO
})

const erroAoAlterarSenhaUsuario = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SENHA_DO_USUARIO,
  erro,
  parametros
})

export const alterarSenhaDoUsuario = (email, senha, novaSenha) => async dispatch => {
  dispatch({ type: ALTERAR_SENHA_DO_USUARIO })

  try {
    let resultado = await api.alterarSenhaDoUsuario(email, senha, novaSenha)

    dispatch(alterouSenhaDoUsuario())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSenhaUsuario(erro, { email, senha, novaSenha }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar senha do usuário.')))

    return false
  }
}

const listouEmpresasHabilitadasParaOLogin = empresas => ({
  type: LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  empresas
})

const erroAoListarEmpresasHabilitadasParaOLogin = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  erro,
  parametros,
})

export const listarEmpresasHabilitadasParaOLogin = (email, senha) => async dispatch => {
  dispatch({ type: LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN })

  try {
    const resultado = await api.listarEmpresasHabilitadasParaOLogin(email, senha)
    const { empresas, erro } = resultado.data.dados

    if (erro) {
      dispatch(adicionarNotificacao(notificacao.deErro(erro)))
      dispatch(erroAoListarEmpresasHabilitadasParaOLogin(erro, { email, senha }))
      return
    }

    if (!empresas || empresas.length === 0) {
      dispatch(adicionarNotificacao(notificacao.deErro('Usuário não possui empresa ativa.')))
    }

    dispatch(listouEmpresasHabilitadasParaOLogin(empresas))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return empresas
  } catch (erro) {
    dispatch(erroAoListarEmpresasHabilitadasParaOLogin(erro, { email, senha }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as empresas habilitadas para o login.')))
  }
}

const listouEmpresasHabilitadas = empresas => ({
  type: LISTOU_EMPRESAS_HABILITADAS,
  empresas
})

const erroAoListarEmpresasHabilitadas = erro => ({
  type: ERRO_AO_LISTAR_EMPRESAS_HABILITADAS,
  erro,
})

export const listarEmpresasHabilitadas = () => async dispatch => {
  dispatch({ type: LISTAR_EMPRESAS_HABILITADAS })

  try {
    const resultado = await api.listarEmpresasHabilitadas()
    const empresas = resultado.data.dados

    dispatch(listouEmpresasHabilitadas(empresas))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return empresas
  } catch (erro) {

    dispatch(erroAoListarEmpresasHabilitadas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as empresas habilitadas do usuário.')))
  }
}

export const cancelarLogin = () => ({ type: CANCELAR_LOGIN })

const recuperouLogin = login => async dispatch => {
  dispatch({ type: RECUPEROU_LOGIN, login })
}

const erroAoRecuperarLogin = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_LOGIN,
  erro,
  parametros,
})

export const recuperarLogin = email => async dispatch => {
  dispatch({ type: RECUPERAR_LOGIN })

  try {
    const resultado = await api.recuperarLogin(email)
    dispatch(recuperouLogin(resultado.data.dados))
  } catch (erro) {
    dispatch(erroAoRecuperarLogin(erro, { email }))
  }
}

const alterouStatusDoUsuario = status => ({
  type: ALTEROU_STATUS_DO_USUARIO,
  status
})

const erroAoAlterarStatusDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_USUARIO,
  erro,
  parametros,
})

export const alterarStatusDoUsuario = (identificador, status, confirmarQueQuerDesativar) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_USUARIO })

  try {
    const resultado = await api.alterarStatusDoUsuario(identificador, status, confirmarQueQuerDesativar)

    dispatch(alterouStatusDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoUsuario(erro, { identificador, status }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar status do usuário')))

    return false
  }
}

const recuperouSenha = () => ({
  type: RECUPEROU_SENHA
})

const erroAoRecuperarSenha = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_SENHA,
  erro,
  parametros
})

export const recuperarSenha = email => async dispatch => {
  dispatch({ type: RECUPERAR_SENHA })

  try {
    await api.recuperarSenha(email)
    dispatch(recuperouSenha())
    dispatch(adicionarNotificacoesDaApi('', notificacao.deSucesso(`Um link para redefinir a senha foi enviado para o e-mail ${email}`)))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarSenha(erro, { email }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a senha do email informado.')))

    return false
  }
}

const redefiniuSenha = () => ({
  type: REDEFINIU_SENHA
})

const erroAoRedefinirSenha = (erro, parametros) => ({
  type: ERRO_AO_REDEFINIR_SENHA,
  erro,
  parametros
})

export const redefinirSenha = (email, novaSenha, identificador) => async dispatch => {
  dispatch({ type: REDEFINIR_SENHA })

  try {
    await api.redefinirSenha(email, novaSenha, identificador)

    dispatch(redefiniuSenha())
    dispatch(adicionarNotificacoesDaApi('', notificacao.deSucesso(`Senha redefinida com sucesso!`)))

    return true
  } catch (erro) {
    dispatch(erroAoRedefinirSenha(erro, { email, novaSenha, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao redefinir a senha.')))

    return false
  }
}

const alterouUnidadeDoLogin = (empresa, unidade) => ({
  type: ALTEROU_UNIDADE_DO_LOGIN,
  empresa,
  unidade
})

const erroAoAlterarUnidadeDoLogin = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_UNIDADE_DO_LOGIN,
  erro,
  parametros,
})

export const alterarUnidadeDoLogin = (empresa, unidade) => async dispatch => {
  dispatch({ type: ALTERAR_UNIDADE_DO_LOGIN })

  try {
    const resultado = await api.alterarUnidadeDoLogin(empresa, unidade)
    await api.efetuarLogout()

    await dispatch(reautenticarUsuario(resultado.data.dados.token))
    await dispatch(alterouUnidadeDoLogin(empresa, unidade))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarUnidadeDoLogin(erro, { empresa, unidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a unidade do login.')))
  }
}