import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_IVCFS_DO_PACIENTE,
  LISTOU_IVCFS_DO_PACIENTE,
  ERRO_AO_LISTAR_IVCFS_DO_PACIENTE,
  ADICIONAR_IVCF_DO_PACIENTE,
  ADICIONOU_IVCF_DO_PACIENTE,
  ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE,
  ALTERAR_IVCF_DO_PACIENTE,
  ALTEROU_IVCF_DO_PACIENTE,
  ERRO_AO_ALTERAR_IVCF_DO_PACIENTE,
  REMOVER_IVCF_DO_PACIENTE,
  REMOVEU_IVCF_DO_PACIENTE,
  ERRO_AO_REMOVER_IVCF_DO_PACIENTE
} from '../../../tipos'

const listouIvcfsDoPaciente = (identificadorDoPaciente, ivcfs) => ({
  type: LISTOU_IVCFS_DO_PACIENTE,
  identificadorDoPaciente,
  ivcfs
})

const erroAoListarIvcfsDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_IVCFS_DO_PACIENTE,
  erro,
  parametros
})

export const listarIvcfsDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_IVCFS_DO_PACIENTE })

  try {
    const resultado = await api.listarIvcfsDoPaciente(identificadorDoPaciente)

    dispatch(listouIvcfsDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarIvcfsDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os IVCFs do paciente.')))
  }
}

const adicionouIvcfDoPaciente = ivcf => ({
  type: ADICIONOU_IVCF_DO_PACIENTE,
  ivcf
})

const erroAoAdicionarIvcfDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_IVCF_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarIvcfDoPaciente = (identificadorDoPaciente, ivcf) => async dispatch => {
  dispatch({ type: ADICIONAR_IVCF_DO_PACIENTE })

  try {
    const resultado = await api.adicionarIvcfDoPaciente(identificadorDoPaciente, ivcf)

    dispatch(adicionouIvcfDoPaciente(resultado.data.dados))
    dispatch(listarIvcfsDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarIvcfDoPaciente(erro, { ivcf }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar IVCF do paciente.')))

    return false
  }
}

const alterouIvcfDoPaciente = ivcf => ({
  type: ALTEROU_IVCF_DO_PACIENTE,
  ivcf
})

const erroAoAlterarIvcfDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_IVCF_DO_PACIENTE,
  erro,
  parametros
})

export const alterarIvcfDoPaciente = (identificadorDoPaciente, ivcf) => async dispatch => {
  dispatch({ type: ALTERAR_IVCF_DO_PACIENTE })

  try {
    const resultado = await api.alterarIvcfDoPaciente(identificadorDoPaciente, ivcf)

    dispatch(alterouIvcfDoPaciente(resultado.data.dados))
    dispatch(listarIvcfsDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarIvcfDoPaciente(erro, { ivcf }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar IVCF do paciente.')))

    return false
  }
}

const removeuIvcfDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_IVCF_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverIvcfDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_IVCF_DO_PACIENTE,
  erro,
  parametros
})

export const removerIvcfDoPaciente = (identificadorDoPaciente, identificadorDoIvcf) => async dispatch => {
  dispatch({ type: REMOVER_IVCF_DO_PACIENTE })

  try {
    const resultado = await api.removerIvcfDoPaciente(identificadorDoPaciente, identificadorDoIvcf)

    dispatch(removeuIvcfDoPaciente(identificadorDoPaciente))
    dispatch(listarIvcfsDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverIvcfDoPaciente(erro, { identificadorDoPaciente, identificadorDoIvcf }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover IVCF do paciente.')))

    return false
  }
}