import React, { useEffect, useMemo } from 'react'
import { SelectReact } from '../../formik/select'
import {extrairNomeResumido} from '../../../bibliotecas/texto'
import CampoDaTarefa from './campo-da-tarefa'
import { ComponenteLeitura } from '../card'
import { Responsavel as IconeResponsavel } from '../../../design/icones'
import { useTarefas } from '../contexto'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'

const groupStyles = {
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  color: 'black',
  backgroundColor: '#EBECF0',
  display: 'inline-block',
  minWidth: 1,
  lineHeight: '1',
  padding: '0.5em 0.5em',
  borderRadius: '1em',
}

export default function Responsavel({ definir, obrigatorio, rotulo, tipo, processando, valor, valorPaciente, leitura }) {
  const { usuario } = useUsuarioLogado()

  const {
    listarProfissionaisResponsaveisDoTarefas,
    profissionaisResponsaveis,
    listarSetoresResponsaveisDoTarefas,
    setoresResponsaveis
  } = useTarefas()

  const icone = <IconeResponsavel />
  const titulo = rotulo ?? 'Responsável'
  const lista = tipo ?? 'ambos'

  useEffect(() => {
    if (lista !== "setor" && !profissionaisResponsaveis) {
      listarProfissionaisResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarProfissionaisResponsaveisDoTarefas, profissionaisResponsaveis, lista])

  useEffect(() => {
    if (lista !== "profissional" && !setoresResponsaveis) {
      listarSetoresResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarSetoresResponsaveisDoTarefas, setoresResponsaveis, lista])

  const renderizarItem = ({ value, label, profissao }) => {
    return (
      <div key={value}>
        <div>
          <strong>{extrairNomeResumido(label)}</strong>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${profissao ? 'possui-medico' : ''}`}>
          {profissao &&
            <div className='medico-do-paciente'>
              <span>{profissao.nome}</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const opcoesDeProfissionais = useMemo(() => {
    if (!profissionaisResponsaveis) return []
    return profissionaisResponsaveis.filter(responsavel => responsavel.ativo).map(x => ({
      value: "profissional__" + x.identificadorPublico,
      label: x.nome,
      profissao: x.profissao
    }))
  }, [profissionaisResponsaveis])

  const opcoesDeSetores = useMemo(() => {
    if (!setoresResponsaveis) return []
    return setoresResponsaveis.map(x => ({
      value: "setor__" + x.identificador,
      label: x.nome
    }))
  }, [setoresResponsaveis])

  const opcoesDeResponsaveis = () => {
    return [
      { grupo: 'Setores', options: opcoesDeSetores },
      { grupo: 'Profissionais', options: opcoesDeProfissionais }
    ]
  }

  const formatarGrupos = opcoesDeResponsaveis => (
    <div style={groupStyles}>
      <span>{opcoesDeResponsaveis.grupo}</span>
    </div>
  )

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        valor={valor.nome}
      />
    )
  }

  const acoesDosBotoes = []

  if (opcoesDeProfissionais.some(o => o.value === `profissional__${usuario.token.identificador}`)) {
    const definirEu = () => definir(opcoesDeProfissionais.filter(o => o.value === `profissional__${usuario.token.identificador}`)[0].value)
    acoesDosBotoes.splice(1, 0, { titulo: 'Eu', tituloSecundario: 'Usuário logado', acao: definirEu })
  }

  if (valorPaciente) {
    const medicoDeReferencia = valorPaciente.medicoDeReferencia && `profissional__${valorPaciente.medicoDeReferencia}`
    const enfermeiroDeReferencia = valorPaciente.enfermeiroDeReferencia && `profissional__${valorPaciente.enfermeiroDeReferencia}`

    if (medicoDeReferencia && opcoesDeProfissionais.some(o => o.value === medicoDeReferencia)) {
      const definirMedicoDeReferencia = () => definir(opcoesDeProfissionais.filter(o => o.value === medicoDeReferencia)[0].value)
      acoesDosBotoes.splice(0, 0, { titulo: 'MR', tituloSecundario: 'Médico(a) de referência', acao: definirMedicoDeReferencia })
    }

    if (enfermeiroDeReferencia && opcoesDeProfissionais.some(o => o.value === enfermeiroDeReferencia)) {
      const definirEnfermeiroDeReferencia = () => definir(opcoesDeProfissionais.filter(o => o.value === enfermeiroDeReferencia)[0].value)
      acoesDosBotoes.unshift({ titulo: 'ER', tituloSecundario: 'Enfermeiro(a) de referência', acao: definirEnfermeiroDeReferencia })
    }
  }

  const valorCombinado = valor && valor.identificador && `${valor.tipo}__${valor.identificador}`

  if (lista !== "ambos") {
    return (
      <CampoDaTarefa
        gravar={definir}
        valor={valorCombinado ? valorCombinado : valor}
        gravarAoSelecionar={true}
        icone={icone}
        titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
        acoes={acoesDosBotoes}
      >
        {({ onChange, onBlur, onFocus, valor, isValid, isWarning }) =>
          <SelectReact
            nome='responsavel'
            id='responsavel'
            onChange={onChange}
            options={lista === "profissional" ? opcoesDeProfissionais : opcoesDeSetores}
            formatOptionLabel={renderizarItem}
            valor={valor}
            onBlur={onBlur}
            onFocus={onFocus}
            isLoading={processando}
            isValid={isValid}
            isWarning={isWarning}
          />
        }
      </CampoDaTarefa>
    )
  } else {
    return (
      <CampoDaTarefa
        gravar={definir}
        valor={valorCombinado ? valorCombinado : valor}
        gravarAoSelecionar={true}
        icone={icone}
        titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
        acoes={acoesDosBotoes}
      >
        {({ onChange, onBlur, onFocus, valor, isValid, isWarning }) =>
          <SelectReact
            nome='responsavel'
            id='responsavel'
            onChange={onChange}
            options={opcoesDeResponsaveis()}
            formatOptionLabel={renderizarItem}
            formatGroupLabel={formatarGrupos}
            valor={valor}
            onBlur={onBlur}
            onFocus={onFocus}
            isLoading={processando}
            isValid={isValid}
            isWarning={isWarning}
          />
        }
      </CampoDaTarefa>
    )
  }
}