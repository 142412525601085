import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO
} from './tipos'

const listouMotivosDeIdaAoProntoAtendimento = motivosDeIdaAoProntoAtendimento => ({
  type: LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  motivosDeIdaAoProntoAtendimento
})

const erroAoListarMotivosDeIdaAoProntoAtendimento = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  erro
})

export const listarMotivosDeIdaAoProntoAtendimento = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.listarMotivosDeIdaAoProntoAtendimento()

    dispatch(listouMotivosDeIdaAoProntoAtendimento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeIdaAoProntoAtendimento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de ida ao pronto atendimento.')))
  }
}

const listouMotivosDeIdaAoProntoAtendimentoDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarMotivosDeIdaAoProntoAtendimentoDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  erro,
  parametros
})

export const listarMotivosDeIdaAoProntoAtendimentoDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO })

  try {
    const resultado = await api.listarMotivosDeIdaAoProntoAtendimento(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouMotivosDeIdaAoProntoAtendimentoDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeIdaAoProntoAtendimentoDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de ida ao pronto atendimento.')))
  }
}

const adicionouMotivoDeIdaAoProntoAtendimento = motivoDeIdaAoProntoAtendimento => ({
  type: ADICIONOU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  motivoDeIdaAoProntoAtendimento
})

const erroAoAdicionarMotivoDeIdaAoProntoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  erro,
  parametros
})

export const adicionarMotivoDeIdaAoProntoAtendimento = motivo => async dispatch => {
  dispatch({ type: ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.adicionarMotivoDeIdaAoProntoAtendimento(motivo)

    dispatch(adicionouMotivoDeIdaAoProntoAtendimento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarMotivoDeIdaAoProntoAtendimento(erro, { motivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o motivo de ida ao pronto atendimento.')))

    return false
  }
}

const recuperouMotivoDeIdaAoProntoAtendimentoPeloIdentificador = motivoDeIdaAoProntoAtendimento => ({
  type: RECUPEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  motivoDeIdaAoProntoAtendimento
})

const erroAoRecuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador(identificador)

    dispatch(recuperouMotivoDeIdaAoProntoAtendimentoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o motivo de ida ao pronto atendimento por identificador.')))
  }
}

const alterouMotivoDeIdaAoProntoAtendimento = motivoDeIdaAoProntoAtendimento => ({
  type: ALTEROU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  motivoDeIdaAoProntoAtendimento
})

const erroAoAlterarMotivoDeIdaAoProntoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  erro,
  parametros
})

export const alterarMotivoDeIdaAoProntoAtendimento = motivo => async dispatch => {
  dispatch({ type: ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.alterarMotivoDeIdaAoProntoAtendimento(motivo)

    dispatch(alterouMotivoDeIdaAoProntoAtendimento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarMotivoDeIdaAoProntoAtendimento(erro, { motivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o motivo de ida ao pronto atendimento.')))

    return false
  }
}

const removeuMotivoDeIdaAoProntoAtendimento = motivoDeIdaAoProntoAtendimento => ({
  type: REMOVEU_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  motivoDeIdaAoProntoAtendimento
})

const erroAoRemoverMotivoDeIdaAoProntoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  erro,
  parametros
})

export const removerMotivoDeIdaAoProntoAtendimento = identificador => async dispatch => {
  dispatch({ type: REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.removerMotivoDeIdaAoProntoAtendimento(identificador)

    dispatch(removeuMotivoDeIdaAoProntoAtendimento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverMotivoDeIdaAoProntoAtendimento(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o motivo de internação.')))

    return false
  }
}