import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_URGENCIA_DA_TAREFA,
  DEFINIU_URGENCIA_DA_TAREFA,
  ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA,
  ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS,
  LISTAR_URGENCIAS_DO_TAREFAS,
  LISTOU_URGENCIAS_DO_TAREFAS
} from '../tipos'

const definiuUrgenciaDaTarefa = (identificadorDaTarefa, urgencia) => ({
  type: DEFINIU_URGENCIA_DA_TAREFA,
  identificadorDaTarefa,
  urgencia,
})

const erroAoDefinirUrgenciaDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_URGENCIA_DA_TAREFA,
  erro,
  parametros,
})

export const definirUrgenciaDaTarefa = (identificadorDaTarefa, urgencia) => async dispatch => {
  dispatch({ type: DEFINIR_URGENCIA_DA_TAREFA })

  try {
    const resultado = await api.definirUrgenciaDaTarefa(identificadorDaTarefa, urgencia)

    dispatch(definiuUrgenciaDaTarefa(identificadorDaTarefa, urgencia))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirUrgenciaDaTarefa(erro, { identificadorDaTarefa, urgencia }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir a urgência da tarefa.')))

    return false
  }
}

const listouUrgenciasDoTarefas = urgencias => ({
  type: LISTOU_URGENCIAS_DO_TAREFAS,
  urgencias
})

const erroAoListarUrgenciasDoTarefas = erro => ({
  type: ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS,
  erro
})

export const listarUrgenciasDoTarefas = () => async dispatch => {
  dispatch({ type: LISTAR_URGENCIAS_DO_TAREFAS })

  try {
    const resultado = await api.listarUrgenciasDoTarefas()
    dispatch(listouUrgenciasDoTarefas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarUrgenciasDoTarefas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as urgências do tarefas.')))

    return false
  }
}