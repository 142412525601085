import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function LegendaUnidade(props) {
  const {
    exibir = true,
    item,
  } = props

  if (!exibir || item === undefined) return null

  return (
    <div
      className="box-agenda__unity"
      style={{ '--event-color': `${item.unidade.cor}` }}
      data-tip
      data-for={`info_${item.unidade.identificador}`}
    >
      <ReactTooltip
        id={`info_${item.unidade.identificador}`}
        place='top'
        effect='solid'
        type='info'
        key={item.unidade.identificador}
      >
        <span>{item.unidade.nome}</span>
      </ReactTooltip>
    </div>
  )
}  
