import api from '../../../api-major'

const adicionarCasp16DoPaciente = (identificador, casp16) => api.post(`/pacientes/${identificador}/casps16`, casp16)
const alterarCasp16DoPaciente = (identificador, casp16) => api.put(`/pacientes/${identificador}/casps16/${casp16.identificador}`, casp16)
const removerCasp16DoPaciente = (identificador, identificadorDoCasp16) => api.delete(`/pacientes/${identificador}/casps16/${identificadorDoCasp16}`)
const listarCasps16DoPaciente = identificador => api.get(`/pacientes/${identificador}/casps16`)

export default { 
  adicionarCasp16DoPaciente,
  alterarCasp16DoPaciente,
  removerCasp16DoPaciente,
  listarCasps16DoPaciente
}