import api from '../api-major'

const adicionarProgramaDoPaciente = (identificador, programa) => api.post(`/pacientes/${identificador}/programas`, programa)
const alterarProgramaDoPaciente = (identificador, programa) => api.put(`/pacientes/${identificador}/programas/${programa.identificador}`, programa)
const listarProgramasDoPaciente = identificador => api.get(`/pacientes/${identificador}/programas?tamanhoDaPagina=1000&ordem=-identificador`)
const removerProgramaDoPaciente = (identificadorDoPaciente, identificador) => api.delete(`/pacientes/${identificadorDoPaciente}/programas/${identificador}`)
const recuperarProgramaDoPaciente = (identificadorDoPaciente, identificador) => api.get(`/pacientes/${identificadorDoPaciente}/programas/${identificador}`)

export default {
  adicionarProgramaDoPaciente,
  alterarProgramaDoPaciente,
  listarProgramasDoPaciente,
  recuperarProgramaDoPaciente,
  removerProgramaDoPaciente,
}