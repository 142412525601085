import {
  LISTOU_SITUACOES_ASSOCIADAS_A_QUEDA
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_SITUACOES_ASSOCIADAS_A_QUEDA: {
      return action.situacoesQueda
    }

    default: {
      return state
    }
  }
}
