import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { DatePicker, CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import GraficoPopup from '../grafico-popup'
import Grafico from './grafico'

export default function RelacaoAlbuminaCreatinina({ metadados, historico = [], valor, leitura, exibirDataExame, dataExame, setFieldValue }) {
  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame

  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)

  useEffect(() => {
    if (exibirDataExame && valor.valor) {
      setFieldValue('relacaoAlbuminaCreatinina.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  const validarRelacaoAlbuminaCreatinina = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 0, 2000) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 0, 2000)
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Relação albumina/creatinina (Microalbuminúria): </strong>
            {valor.valor.toString().replace('.', ',')} mg/g ou mcg/mg de creatinina no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 2
    return 4
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <ReactTooltip
            id='relacaoAlbuminaCreatinina'
            place='top'
            effect='solid'
            key='relacaoAlbuminaCreatinina'
            type='info'
          >
            <span>Microalbuminúria</span>
          </ReactTooltip>
          <label data-tip data-for='relacaoAlbuminaCreatinina' className='form-label'>{`Relação albumina/creatinina ${obrigatorio ? '*' : ''}`}</label>
          <CampoNumerico
            nome='relacaoAlbuminaCreatinina.valor'
            numeroDeCasasDecimais={1}
            minW='55px'
            validar={validarRelacaoAlbuminaCreatinina}
          >
            <ReactTooltip
              id='EscalaRelacaoAlbuminaCreatinina'
              place='top'
              effect='solid'
              key='EscalaRelacaoAlbuminaCreatinina'
              type='info'
            >
              <span>mg/g ou mcg/mg de creatinina</span>
            </ReactTooltip>
            <div data-tip data-for='EscalaRelacaoAlbuminaCreatinina' className='form-input-group-append' style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>mg/g</div>
            {historico.length > 0 &&
              <GraficoPopup titulo='Relação albumina/creatinina (Microalbuminúria)'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='relacaoAlbuminaCreatinina.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}