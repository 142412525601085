import api from '../../api-major'

const listarTiposDeAgendamentoDasConfiguracoesDaAgenda = () =>
  api.get(`/tipos-de-agendamento?tamanhoDaPagina=10000&ordem=nome&ativo=true`)
const adicionarTipoDeAgendamentoDoProfissional = (identificadorDoProfissional, identificadorDoTipoDeAgendamento) =>
  api.post(`/profissionais-de-saude/${identificadorDoProfissional}/tipos-de-agendamento/`, { tipoDeAgendamento: identificadorDoTipoDeAgendamento })
const removerTipoDeAgendamentoDoProfissional = (identificadorDoProfissional, identificadorDoTipoDeAgendamento) =>
  api.delete(`/profissionais-de-saude/${identificadorDoProfissional}/tipos-de-agendamento/${identificadorDoTipoDeAgendamento}`)

const listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda = (identificador, filtros, pagina, ordenacao) => {
  const paginacao = pagina ? `&pagina=${pagina}` : '&pagina=1'
  const parametroAtivo = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativo=${filtros.ativo[0] === 'Sim'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = '&tamanhoDaPagina=10000'

  return api.get(`/tipos-de-agendamento?${paginacao}${tamanhoDaPaginaFormatado}${parametroAtivo}${ordem}&profissional=${identificador}`)
}

export default {
  adicionarTipoDeAgendamentoDoProfissional,
  listarTiposDeAgendamentoDasConfiguracoesDaAgenda,
  listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda,
  removerTipoDeAgendamentoDoProfissional,
}