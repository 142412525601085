import { connect } from 'react-redux'
import acoes from '../../acoes'
import PlanosDeCuidado from './index'

const mapStateToProps = state => {
  const planoDeCuidadoAtual = state.planosDeCuidado.itemAtual

  let planoDeCuidado = {
    nome: '',
    observacoes: ''
  }

  if (planoDeCuidadoAtual) {
    planoDeCuidado.identificador = planoDeCuidadoAtual.identificador
    planoDeCuidado.nome = planoDeCuidadoAtual.nome
    planoDeCuidado.observacoes = planoDeCuidadoAtual.observacoes
  }

  return {
    filtros: state.planosDeCuidado.filtros,
    ordenacao: state.planosDeCuidado.ordenacao,
    paginaDeDados: state.planosDeCuidado.paginaDeDados,
    planoDeCuidado,
    powerBI: state.integracoes.powerBI,
  }
}

export default connect(mapStateToProps, acoes)(PlanosDeCuidado)