import * as Yup from 'yup'
import {
  ehUmNumeroDeTelefoneValido,
  ehUmaDataValida,
  ehUmaHoraValida,
  ehUmCpfValido,
  ehUmEmailValido,
  ehUmCepValido,
  ehUmAlfanumericoValido,
  ehUmNumeroDecimalValido,
  ehUmNumeroInteiroValido,
  ehUmPercentualValido,
  ehUmIPValido
} from '../bibliotecas/validacoes'

export const configurarYup = () => {
  function validarNumeroDeTelefone(msg) {
    return this.test({
      name: 'ehUmNumeroDeTelefoneValido',
      exclusive: false,
      message: msg,
      test: ehUmNumeroDeTelefoneValido
    })
  }

  function validarCpf(msg) {
    return this.test({
      name: 'ehUmCpfValido',
      exclusive: false,
      message: msg,
      test: ehUmCpfValido
    })
  }

  function equalTo(ref, msg) {
    return this.test({
      name: 'equalTo',
      exclusive: false,
      message: msg,
      params: { reference: ref.path },
      test: function (value) {
        return value === this.resolve(ref)
      }
    })
  }

  function validarData(msg) {
    return this.test({
      name: 'ehUmaDataValida',
      exclusive: false,
      message: msg,
      test: ehUmaDataValida
    })
  }

  function validarHora(msg) {
    return this.test({
      name: 'ehUmaHoraValida',
      exclusive: false,
      message: msg,
      test: ehUmaHoraValida
    })
  }

  function validarEmail(msg) {
    return this.test({
      name: 'ehUmEmailValido',
      exclusive: false,
      message: msg,
      test: ehUmEmailValido
    })
  }

  function validarCep(msg) {
    return this.test({
      name: 'ehUmCepValido',
      exclusive: false,
      message: msg,
      test: ehUmCepValido
    })
  }

  function validarAlfaNumerico(msg) {
    return this.test({
      name: 'ehUmAlfanumericoValido',
      exclusive: false,
      message: msg,
      test: ehUmAlfanumericoValido
    })
  }

  function validarDecimal(msg) {
    return this.test({
      name: 'ehUmNumeroDecimalValido',
      exclusive: false,
      message: msg,
      test: ehUmNumeroDecimalValido
    })
  }

  function validarInteiro(msg) {
    return this.test({
      name: 'ehUmNumeroInteiroValido',
      exclusive: false,
      message: msg,
      test: ehUmNumeroInteiroValido
    })
  }

  function validarPercentual(msg) {
    return this.test({
      name: 'ehUmPercentualValido',
      exclusive: false,
      message: msg,
      test: ehUmPercentualValido
    })
  }

  function validarIP(msg) {
    return this.test({
      name: 'ehUmIPValido',
      exclusive: false,
      message: msg,
      test: ehUmIPValido
    })
  }

  Yup.addMethod(Yup.string, 'ehUmNumeroDeTelefoneValido', validarNumeroDeTelefone)
  Yup.addMethod(Yup.string, 'ehUmCpfValido', validarCpf)
  Yup.addMethod(Yup.string, 'equalTo', equalTo)
  Yup.addMethod(Yup.string, 'ehUmaDataValida', validarData)
  Yup.addMethod(Yup.string, 'ehUmaHoraValida', validarHora)
  Yup.addMethod(Yup.string, 'ehUmEmailValido', validarEmail)
  Yup.addMethod(Yup.string, 'ehUmCepValido', validarCep)
  Yup.addMethod(Yup.string, 'ehUmAlfanumericoValido', validarAlfaNumerico)
  Yup.addMethod(Yup.string, 'ehUmNumeroDecimalValido', validarDecimal)
  Yup.addMethod(Yup.string, 'ehUmNumeroInteiroValido', validarInteiro)
  Yup.addMethod(Yup.string, 'ehUmPercentualValido', validarPercentual)
  Yup.addMethod(Yup.string, 'ehUmIPValido', validarIP)
}