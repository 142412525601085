import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTOU_MEDICAMENTOS,
  ERRO_AO_LISTAR_MEDICAMENTOS,
  LISTAR_MEDICAMENTOS,
  ADICIONAR_MEDICAMENTO,
  ADICIONOU_MEDICAMENTO,
  ERRO_AO_ADICIONAR_MEDICAMENTO,
  RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  ALTEROU_MEDICAMENTO,
  ERRO_AO_ALTERAR_MEDICAMENTO,
  ALTERAR_MEDICAMENTO,
  REMOVEU_MEDICAMENTO,
  ERRO_AO_REMOVER_MEDICAMENTO,
  LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  LISTOU_CONCENTRACOES_DO_MEDICAMENTO,
  ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVEU_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  ALTEROU_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  REMOVER_MEDICAMENTO,
  ALTERAR_STATUS_DO_MEDICAMENTO,
  ALTEROU_STATUS_DO_MEDICAMENTO,
  ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO
} from './tipos'

const listouMedicamentos = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_MEDICAMENTOS,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarMedicamentos = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_MEDICAMENTOS,
  erro,
  parametros
})

export const listarMedicamentos = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_MEDICAMENTOS })

  try {
    const resultado = await api.listarMedicamentos(filtros, pagina, ordenacao)

    dispatch(listouMedicamentos(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMedicamentos(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os medicamentos.')))
  }
}

const adicionouMedicamento = medicamento => ({
  type: ADICIONOU_MEDICAMENTO,
  medicamento
})

const erroAoAdicionarMedicamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_MEDICAMENTO,
  erro,
  parametros
})

export const adicionarMedicamento = medicamento => async dispatch => {
  dispatch({ type: ADICIONAR_MEDICAMENTO })

  try {
    const resultado = await api.adicionarMedicamento(medicamento)
    dispatch(adicionouMedicamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarMedicamento(erro, { medicamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar medicamento.')))

    return null
  }
}

const recuperouMedicamentoPorIdentificador = medicamento => async dispatch => {
  dispatch({ type: RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR, medicamento })
}

const erroAoRecuperarMedicamentoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarMedicamentoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarMedicamentoPorIdentificador(identificador)

    dispatch(recuperouMedicamentoPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
    
  } catch (erro) {
    dispatch(erroAoRecuperarMedicamentoPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar medicamento por identificador.')))
  }
}

const alterouMedicamento = medicamento => ({
  type: ALTEROU_MEDICAMENTO,
  medicamento,
})

const erroAoAlterarMedicamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_MEDICAMENTO,
  erro,
  parametros
})

export const alterarMedicamento = medicamento => async dispatch => {
  dispatch({ type: ALTERAR_MEDICAMENTO })

  try {
    const resultado = await api.alterarMedicamento(medicamento)

    dispatch(alterouMedicamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return resultado.data.dados.identificador

  } catch (erro) {
    dispatch(erroAoAlterarMedicamento(erro, { medicamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o medicamento.')))

    return null
  }
}

const alterouStatusDoMedicamento = medicamento => ({
  type: ALTEROU_STATUS_DO_MEDICAMENTO,
  medicamento,
})

const erroAoAlterarStatusDoMedicamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO,
  erro,
  parametros
})

export const alterarStatusDoMedicamento = medicamento => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_MEDICAMENTO })

  try {
    const resultado = await api.alterarMedicamento(medicamento)

    dispatch(alterouStatusDoMedicamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoMedicamento(erro, { medicamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o status do medicamento.')))

    return false
  }
}

const removeuMedicamento = identificador => ({
  type: REMOVEU_MEDICAMENTO,
  identificador,
})

const erroAoRemoverMedicamento = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_MEDICAMENTO,
  erro,
  parametros
})

export const removerMedicamento = identificador => async dispatch => {
  dispatch({ type: REMOVER_MEDICAMENTO })

  try {
    const resultado = await api.removerMedicamento(identificador)

    dispatch(removeuMedicamento(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {

    dispatch(erroAoRemoverMedicamento(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o medicamento.')))

    return false
  }
}

const listouConcentracoesDoMedicamento = concentracoes => ({
  concentracoes,
  type: LISTOU_CONCENTRACOES_DO_MEDICAMENTO,
})

const erroAoListarConcentracoesDoMedicamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  erro,
  parametros
})

export const listarConcentracoesDoMedicamento = identificadorDoMedicamento => async dispatch => {
  dispatch({ type: LISTAR_CONCENTRACOES_DO_MEDICAMENTO })

  try {
    const resultado = await api.listarConcentracoesDoMedicamento(identificadorDoMedicamento)

    dispatch(listouConcentracoesDoMedicamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarConcentracoesDoMedicamento(erro, { identificadorDoMedicamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as concentrações do medicamento.')))
  }
}

const adicionouConcentracaoDoMedicamento = concentracao => ({
  type: ADICIONOU_CONCENTRACAO_DO_MEDICAMENTO,
  concentracao
})

const erroAoAdicionarConcentracaoDoMedicamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  erro,
  parametros
})

export const adicionarConcentracaoDoMedicamento = (identificadorDoMedicamento, concentracao) => async dispatch => {
  dispatch({ type: ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO })

  try {
    const resultado = await api.adicionarConcentracaoDoMedicamento(identificadorDoMedicamento, concentracao)
    dispatch(adicionouConcentracaoDoMedicamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarConcentracaoDoMedicamento(erro, { identificadorDoMedicamento, concentracao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar medicamento.')))

    return false
  }
}

const alterouConcentracaoDoMedicamento = concentracao => ({
  type: ALTEROU_CONCENTRACAO_DO_MEDICAMENTO,
  concentracao,
})

const erroAoAlterarConcentracaoDoMedicamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  erro,
  parametros
})

export const alterarConcentracaoDoMedicamento = (identificadorDoMedicamento, concentracao) => async dispatch => {
  dispatch({ type: ALTERAR_CONCENTRACAO_DO_MEDICAMENTO })

  try {
    const resultado = await api.alterarConcentracaoDoMedicamento(identificadorDoMedicamento, concentracao)

    dispatch(alterouConcentracaoDoMedicamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarConcentracaoDoMedicamento(erro, { identificadorDoMedicamento, concentracao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar a concentração do medicamento.')))

    return false
  }
}

const removeuConcentracaoDoMedicamento = identificador => ({
  type: REMOVEU_CONCENTRACAO_DO_MEDICAMENTO,
  identificador,
})

const erroAoRemoverConcentracaoDoMedicamento = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  erro,
  parametros
})

export const removerConcentracaoDoMedicamento = (identificadorDoMedicamento, identificadorDaConcentracao) => async dispatch => {
  dispatch({ type: REMOVER_CONCENTRACAO_DO_MEDICAMENTO })

  try {
    const resultado = await api.removerConcentracaoDoMedicamento(identificadorDoMedicamento, identificadorDaConcentracao)

    dispatch(removeuConcentracaoDoMedicamento(identificadorDoMedicamento, identificadorDaConcentracao))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverConcentracaoDoMedicamento(erro, { identificadorDoMedicamento, identificadorDaConcentracao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a concentração do medicamento.')))

    return false
  }
}