import { connect } from 'react-redux'
import acoes from '../../acoes'
import moment from 'moment'
import TiposDeAgendamento from './index'

const mapStateToProps = state => {
  const tipoDeAgendamentoAtual = state.tiposDeAgendamento.itemAtual

  let tipoDeAgendamento = {
    ativo: true,
    procedimento: false,
    nome: '',
    tempo: '',
    cor: null,
    modalidade: '',    
    codigoDaAns: '',
    tiposDeHorario: []
  }

  if (tipoDeAgendamentoAtual) {
    tipoDeAgendamento.identificador = tipoDeAgendamentoAtual.identificador
    tipoDeAgendamento.ativo = tipoDeAgendamentoAtual.ativo
    tipoDeAgendamento.procedimento = tipoDeAgendamentoAtual.procedimento
    tipoDeAgendamento.nome = tipoDeAgendamentoAtual.nome
    tipoDeAgendamento.tempo = moment.duration(tipoDeAgendamentoAtual.tempo, "HH:mm:ss").asMinutes()
    tipoDeAgendamento.cor = tipoDeAgendamentoAtual.cor
    tipoDeAgendamento.modalidade = tipoDeAgendamentoAtual.modalidade    
    tipoDeAgendamento.codigoDaAns = tipoDeAgendamentoAtual.codigoDaAns
    tipoDeAgendamento.observacoes = tipoDeAgendamentoAtual.observacoes
    tipoDeAgendamento.tiposDeHorario = tipoDeAgendamentoAtual.tiposDeHorario
  }

  return {
    filtros: state.tiposDeAgendamento.filtros,
    ordenacao: state.tiposDeAgendamento.ordenacao,
    paginaDeDados: state.tiposDeAgendamento.paginaDeDados,
    modalidades: state.modalidadesDosTiposDeAgendamento,    
    tiposDeHorarios: state.tiposDeHorarios,
    tipoDeAgendamento,
  }
}

export default connect(mapStateToProps, acoes)(TiposDeAgendamento)