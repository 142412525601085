import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
  LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA
} from '../../tipos'

const listouUnidadesDoUsuarioDoHorarioDaAgenda = unidades => ({
  type: LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
  unidades,
})

const erroAoListarUnidadesDoUsuarioDoHorarioDaAgenda = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
  erro,
  parametros,
})

export const listarUnidadesDoUsuarioDoHorarioDaAgenda = identificador => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA })

  try {
    const resultado = await api.listarUnidadesDoUsuario(identificador)

    dispatch(listouUnidadesDoUsuarioDoHorarioDaAgenda(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUnidadesDoUsuarioDoHorarioDaAgenda(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as unidades do usuário da agenda.')))
  }
}