import React from 'react'
import moment from 'moment'
import Spinner from '../../../spinner'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'

import {
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
} from '../../../../acoes/tipos'

export default function ConfirmacaoDeExclusao({ instrucao, fechar, excluir, excluirSerie }) {
  return (
    <Spinner operacoes={[
      EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
      EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
    ]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Confirmação de Exclusão de Instrução de Agendamento`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-alerta__item'>
                    <ul>
                      <strong>Instrução</strong>: {`${instrucao.texto} do dia ${moment(instrucao.data).format('DD/MM/YYYY')}`}.
                    </ul>
                  </div>

                </div>
                {instrucao.serie && <ResumoDaRecorrencia {...instrucao.serie} semana={construirArrayDeSemana(instrucao.serie)} />}
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Cancelar</button>
                  <button className='button --danger' type='button' onClick={() => excluir(instrucao.identificador)}>Excluir a Instrução</button>
                  {instrucao.serie && <button className='button --danger-light' type='button' onClick={() => excluirSerie(instrucao.identificador)}>Excluir a Série de Instruções</button>}
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}