import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import versao from '../../configuracoes/versao'
import Novidades from './novidades'
import constantes from '../../configuracoes/constantes'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import useLocalStorage from '../../hooks/useLocalStorage'

export default function Rodape() {
  const { usuario } = useUsuarioLogado()
  const RELEASE_EXIBIDO = constantes.releaseExibido + usuario.email
  const [releaseId, setReleaseId] = useLocalStorage(RELEASE_EXIBIDO)

  const [modalAberto, setModalAberto] = useState(false)

  function fecharNovidades() {
    setModalAberto(false)
    setReleaseId(versao.numero)
  }

  useEffect(() => {
    function exibirNovidades() {
      const modalExibido = releaseId === versao.numero

      if (!modalExibido) {
        setModalAberto(true)
        setReleaseId(versao.numero)
      }
    }

    exibirNovidades()
  }, [setModalAberto, releaseId, setReleaseId])

  const estiloDoModalDeNovidades = {
    content: {
      overflowY: 'scroll',
      maxHeight: '95%',
      width: '80%',
    }
  }

  return (
    <>
      <Modal
        isOpen={modalAberto}
        style={estiloDoModalDeNovidades}
        className='modal'
        contentLabel='Modal para Novidades'
      >
        <Novidades
          fecharNovidades={fecharNovidades}
          numero={versao.numero}
        />
      </Modal>
      <footer className='main-footer'>
        <div className='container'>
          <p>LifeCode ©2019 Criado por Mais 60 Saúde - </p>
          <p className='button-light-novidades' onClick={() => setModalAberto(true)}>Versão {versao.numero}</p>
        </div>
      </footer>
    </>
  )
}