import {
  LISTOU_PREVISOES_DE_RETORNO,
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PREVISOES_DE_RETORNO: {
      return action.previsoesDeRetorno
    }

    default: {
      return state
    }
  }
}
