import React, { useEffect } from 'react'
import avatar from '../../design/img/no-thumb.png'

export default function Cabecalho(props) {
  const {
    fotos,
    recuperarPorIdentificador,
    usuario = {},
    identificador,
  } = props

  useEffect(() => {
    recuperarPorIdentificador(identificador)
  }, [identificador, recuperarPorIdentificador])

  return (
    <div className='user-paciente mt-2'>
      <img
        alt={usuario.nome}
        className='table-items-expanded__avatar'
        src={(usuario.foto && fotos[usuario.foto]) || usuario.bytesDaFoto || avatar}
      />
      <p>{usuario.nome}</p>
    </div>
  )
}