import React from 'react'
import { CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'

export default function VelocidadeDaMarcha({ metadados, historico = [], leitura, valor }) {
  const obrigatorio = metadados?.obrigatorio === 'true'

  const validar = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 0.2, 6) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 0.2, 6)
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Velocidade da Marcha: </strong>
            {valor.toString().replace('.', ',')} m/s
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={2} span-lg={2}>
      <CampoNumerico
        nome='velocidadeDaMarcha'
        titulo={`Velocidade da Marcha ${obrigatorio ? '*' : ''}`}
        escala='m/s'
        numeroDeCasasDecimais={1}
        validar={validar}
      >
        {historico.length > 0 &&
          <GraficoPopup titulo='Velocidade da Marcha'>
            <Grafico historico={historico} />
          </GraficoPopup>
        }
      </CampoNumerico>
    </r-cell>
  )
}