import { LISTOU_MOTIVOS_DE_PRIORIDADE } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_MOTIVOS_DE_PRIORIDADE: {
      return action.motivosDePrioridade
    }

    default: {
      return state
    }
  }
}