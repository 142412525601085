import { connect } from 'react-redux'
import acoes from '../../acoes'
import Empresa from './index'

const mapStateToProps = state => ({
  bytesDaFoto: state.empresa.logotipo ? state.imagens[state.empresa.logotipo] : null,
  empresa: state.empresa,
  fusosHorarios: state.fusosHorarios,
  unidadesFederativas: state.unidadesFederativas,
  tiposDeSala: state.tiposDeSala,
  tiposDeUnidade: state.tiposDeUnidade,
})

export default connect(mapStateToProps, acoes)(Empresa)