import { converterParaUrl } from '../bibliotecas/imagem'
import { FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO } from '../acoes/tipos'

export default (state = null, action) => {
  switch (action.type) {
    case FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO: {
      return {
        ...state,
        [action.identificador]: {
          url: converterParaUrl(action.conteudo),
        }
      }
    }

    default: {
      return state
    }
  }
}