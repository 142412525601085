import api from './api-major'

const listarOrientacoes = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/orientacoes${paginacao}${tamanhoDaPaginaFormatado}${parametroTermo}${ordem}`)
}
const adicionarOrientacao = orientacao => api.post(`/orientacoes/`, orientacao)
const recuperarOrientacaoPeloIdentificador = identificador => api.get(`/orientacoes/${identificador}`)
const alterarOrientacao = orientacao => api.put(`/orientacoes/${orientacao.identificador}`, orientacao)
const removerOrientacao = identificador => api.delete(`/orientacoes/${identificador}`)
const fazerDownloadDoArquivoDaOrientacao = (identificadorDaOrientacao, identificadorDoArquivo) => {
  return api.get(`/orientacoes/${identificadorDaOrientacao}/arquivos/${identificadorDoArquivo}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  listarOrientacoes,
  adicionarOrientacao,
  recuperarOrientacaoPeloIdentificador,
  alterarOrientacao,
  removerOrientacao,
  fazerDownloadDoArquivoDaOrientacao
}