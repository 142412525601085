import { connect } from 'react-redux'
import acoes from '../../acoes/index'
import Login from './index'

const recuperarLogotipos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.logotipo).forEach(item => {
    fotos[item.identificadorPublico] = imagens[item.identificadorPublico]
  })

  return fotos
}

const mapStateToProps = state => {
  return {
    logotipos: recuperarLogotipos(state.login.logado.empresas, state.imagens)
  }
}

export default connect(mapStateToProps, acoes)(Login)