import React, { memo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { abrirTeleconsultaNoProntuario } from '../../../../acoes/prontuario/teleconsulta'
import './style.css'
import Modal from "react-modal";
import DadosDeContato from "./dados-de-contato";

function VideoConsulta({
  identificadorDoAtendimento,
  ehRemotoPorVideoENaoTemTeleconsulta = false,
  recuperarTeleconsultaDoAtendimento,
  dados,
  paciente
}) {

  const [exibirDadosDeContato, setExibirDadosDeContato] = useState(false)

  function abrirDadosDeContato(item) {
    setExibirDadosDeContato(true)
  }

  function fecharDadosDeContato() {
    setExibirDadosDeContato(false)
  }

  const links = dados?.links
  const dispatch = useDispatch()
  const [descricao, setDescricao] = useState(
      links
      ? 'Iniciar Vídeo Consulta'
      : 'Carregando Vídeo Consulta...'
  )

  useEffect(() => {
    if (links) {
      setDescricao('Iniciar Vídeo Consulta')
    }
  }, [links])

  useEffect(() => {
    if (identificadorDoAtendimento && !links) {
      const intervalos = [1000, 10000, 20000, 30000, 40000, 50000, 60000]
      const tempos = intervalos.map((intervalo) => {
        return setTimeout(() => {
          if (!links) {
            recuperarTeleconsultaDoAtendimento(identificadorDoAtendimento)
          }
        }, intervalo)
      })

      return () => {
        tempos.forEach(clearTimeout)
      }
    }
  }, [
    identificadorDoAtendimento,
    recuperarTeleconsultaDoAtendimento,
    links,
  ])

  function handleAbrir() {
    dispatch(abrirTeleconsultaNoProntuario(identificadorDoAtendimento))
    setDescricao('Reabrir a Vídeo Consulta')
  }

  return (
      <>
        <Modal
            isOpen={exibirDadosDeContato}
            contentLabel='Modal para consulta de dados de contato'
            className='modal'
        >
          <DadosDeContato
              paciente={paciente}
              fechar={fecharDadosDeContato}
          />
        </Modal>
        <r-cell span={4} span-md='row' span-lg='row' class='text-align-center'>
          <div className='unity-infos__item teleconsulta'>
            {/*{!dados?.agendadoPara &&*/}
            {/*    <div>*/}
            {/*      <strong>Esta é uma vídeo consulta sem agendamento. Portanto, o paciente não recebeu, automaticamente, o link de acesso.</strong>*/}
            {/*      <div>Por favor, envie o link ao paciente.</div>*/}
            {/*      <button type='button' className='button --light --plus-short inline-flex' onClick={abrirDadosDeContato}>Dados de contato*/}
            {/*      </button>*/}
            {/*      <hr className='separator mt-1 mb-1'></hr>*/}
            {/*    </div>*/}
            {/*}*/}
            <div>
              <strong>Links para a vídeo consulta:</strong> {'Paciente'}
              <button
                  title='Copiar'
                  className='botao-historico'
                  type='button'
                  disabled={ehRemotoPorVideoENaoTemTeleconsulta}
                  onClick={() => navigator.clipboard.writeText(links.paciente)}
              >
                <i className='icon icon-copy' />
              </button>{' '}
              {'Convidado'}
              <button
                  title='Copiar'
                  className='botao-historico'
                  type='button'
                  disabled={ehRemotoPorVideoENaoTemTeleconsulta}
                  onClick={() => navigator.clipboard.writeText(links.convidado)}
              >
                <i className='icon icon-copy' />
              </button>
            </div>
            <div className='mtp-5 '>
              <button
                  type='button'
                  className='button --primary w-sm-100 font-weight-normal'
                  disabled={ehRemotoPorVideoENaoTemTeleconsulta}
                  onClick={handleAbrir}
              >
                {descricao}
              </button>
            </div>
          </div>
        </r-cell>
      </>
  )
}

export default memo(VideoConsulta)
