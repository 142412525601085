import { connect } from 'react-redux'
import { ProvedorDeAutenticacao } from './index'
import acoes from '../../../acoes'

const mapStateToProps = state => {
  const logado = state.login.logado
  const { itemAtual } = state.usuarios

  if (!logado.identificador) {
    return {}
  }

  const usuarioComSessaoIniciada = localStorage.getItem('sessao_iniciada')

  return {
    usuario: {
      nome: logado.nome,
      email: logado.email,
      identificador: logado.identificador,
      permissoes: logado.permissoes,
      empresa: state.empresa,
      empresaLogada: logado.empresa,
      token: logado.token,
      unidade: {
        identificador: logado.unidade,
        nome: logado.unidades[logado.unidade] ? logado.unidades[logado.unidade].nome : '',
        telefone: logado.unidades[logado.unidade] ? logado.unidades[logado.unidade].telefone : '',
        endereco: logado.unidades[logado.unidade] ? logado.unidades[logado.unidade].endereco : ''
      },
      foto: logado.foto,
      profissao: logado.profissao,
      empresas: logado.empresas,
      atendimentos: logado.atendimentos,
      cpf: itemAtual.cpf,
      sexo: itemAtual.sexo,
      telefone: itemAtual.telefone1,
      ehAdministrador: logado.ehAdministrador,
      certificadosEmNuvem: {
        ...logado.certificadosEmNuvem,
        existe: logado.certificadosEmNuvem.lista && logado.certificadosEmNuvem.lista.length > 0,
      },
      temSessaoEmAberto: usuarioComSessaoIniciada === 'true',
      temPermissaoVisualizarTarefasQualidade: // 1 - BH; 9 - SP
        logado.identificador === 81 || // Alana Karen Fonseca Silva - 1
        logado.identificador === 638 || // Alana Karen Fonseca Silva - 9
        logado.identificador === 61 || // Ana Carolina Silva Matozinhos - 1
        logado.identificador === 591 || // Ana Carolina Silva Matozinhos - 9
        logado.identificador === 1411 || // Tatianne Siqueira de Francisco - 9
        logado.identificador === 592 || // Gabriela Perpetuo Nicolau - 9
        logado.identificador === 2 || // Lênio - 1
        logado.identificador === 582 || // Lênio - 9
        logado.identificador === 570 || // Mariza Batista de Jesus - 1
        logado.identificador === 603 || // Mariza Batista de Jesus - 9
        logado.identificador === 200 || // Raquel - 1
        logado.identificador === 593 || // Raquel - 9
        logado.identificador === 1 || // Ricardo - 1
        logado.identificador === 851 || // Ricardo - 9
        logado.identificador === 162 || // Thiago - 1
        logado.identificador === 594 // Thiago - 9
    }
  }
}

export default connect(mapStateToProps, { logout: acoes.logout, adicionarNotificacao: acoes.adicionarNotificacao })(ProvedorDeAutenticacao)