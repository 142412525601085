import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
} from '../../tipos'

const listouFeriadosTrabalhadosDoProfissional = feriadosTrabalhados => ({
  feriadosTrabalhados,
  type: LISTOU_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
})

const erroAoListarFeriadosTrabalhadosDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const listarFeriadosTrabalhadosDoProfissional = profissional => async dispatch => {
  dispatch({ type: LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL })

  try {
    const resultado = await api.listarFeriadosTrabalhadosDoProfissional(profissional)

    dispatch(listouFeriadosTrabalhadosDoProfissional(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarFeriadosTrabalhadosDoProfissional(erro, { profissional }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os feriados trabalhados do profissional.')))
  }
}

const adicionouFeriadoTrabalhadoDoProfissional = feriadoTrabalhado => ({
  type: ADICIONOU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  feriadoTrabalhado
})

const erroAoAdicionarFeriadoTrabalhadoDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const adicionarFeriadoTrabalhadoDoProfissional = (profissional, feriado) => async dispatch => {
  dispatch({ type: ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL })

  try {
    const resultado = await api.adicionarFeriadoTrabalhadoDoProfissional(profissional, feriado)
    dispatch(adicionouFeriadoTrabalhadoDoProfissional(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Feriado trabalhado adicionado com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarFeriadoTrabalhadoDoProfissional(erro, { profissional, feriado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar um feriado trabalhado do profissional.')))

    return false
  }
}

const removeuFeriadoTrabalhadoDoProfissional = profissional => ({
  type: REMOVEU_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  profissional
})

const erroAoRemoverFeriadoTrabalhadoDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const removerFeriadoTrabalhadoDoProfissional = (profissional, feriado) => async dispatch => {
  dispatch({ type: REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL })

  try {
    const resultado = await api.removerFeriadoTrabalhadoDoProfissional(profissional, feriado)

    dispatch(removeuFeriadoTrabalhadoDoProfissional(profissional))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Feriado trabalhado removido com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverFeriadoTrabalhadoDoProfissional(erro, { profissional, feriado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um feriado trabalhado do profissional.')))

    return false
  }
}

