const tamanhoDaFoto = {
  miniatura: 'miniatura',
  pequeno: 'pequeno', 
  medio: 'médio', 
  grande: 'grande', 
  original: 'original'  
}

const timeoutDasNotificacoes = {
  alerta: 600,
  erro: 600,
  sucesso: 5,
  informacao: 5,
  log: 5,
  alerta_minimo: 10,
}

const notificacoes = { 
  ALERTA: 'ALERTA',
  ALERTA_MINIMO: 'ALERTA_MINIMO',
  ERRO: 'ERRO',
  INFORMACAO: 'INFORMACAO',
  LOG: 'LOG',
  SUCESSO: 'SUCESSO',
}

const numeroTarefasPorPagina = 50

const releaseExibido = 'novidades_exibido_'

const urlDaApiAsclepius = process.env.REACT_APP_URL_DA_API_ASCLEPIUS
const urlDaApiMajor = process.env.REACT_APP_URL_DA_API_MAJOR
const urlDaApiHercules = process.env.REACT_APP_URL_DA_API_HERCULES
const urlDaApiNarcissus = process.env.REACT_APP_URL_DA_API_NARCISSUS
const urlDaApiMemedBackend = process.env.REACT_APP_URL_DA_API_MEMED_BACKEND
const urlDaApiMemed = process.env.REACT_APP_URL_DA_API_MEMED
const urlDaApiPrometheus = process.env.REACT_APP_URL_DA_API_PROMETHEUS
const urlDaApiHygeia = process.env.REACT_APP_URL_DA_API_HYGEIA
const urlDoViaCep = process.env.REACT_APP_URL_DO_VIA_CEP

const licensaDaLacuna = process.env.REACT_APP_CONFIGURACOES_LACUNA_WEBPKI_LICENSE

const configuracoesDoFirestoreDoTarefas = {
  apiKey: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_APIKEY,
  authDomain: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_AUTHDOMAIN,
  projectId: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_PROJECTID,
  storageBucket: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_CONFIGURACOES_FIRESTORE_TAREFAS_APPID,
}

export default {
  configuracoesDoFirestoreDoTarefas,
  licensaDaLacuna,
  notificacoes,
  numeroTarefasPorPagina,
  releaseExibido,
  tamanhoDaFoto,
  timeoutDasNotificacoes,
  urlDaApiAsclepius,
  urlDaApiMajor,
  urlDaApiHercules,
  urlDaApiNarcissus,
  urlDaApiPrometheus,
  urlDoViaCep,
  urlDaApiMemedBackend,
  urlDaApiMemed,
  urlDaApiHygeia,
}