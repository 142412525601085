import { RECUPEROU_AVALIACAO } from '../../acoes/tipos'
import pacientes from './pacientes'
import profissionais from './profissionais'
import profissoes from './profissoes'
import respostas from './respostas'
import tiposDeAtendimento from './tipos-de-atendimento'

const estadoInicial = {
  avaliacao: '',
  pacientes: [],
  profissionais: [],
  profissoes: [],
  respostas: undefined,
  tiposDeAtendimento: []
}

const avaliacao = (state, action) => {
  switch (action.type) {
    case RECUPEROU_AVALIACAO: {
      return action.avaliacao
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  return {
    avaliacao: avaliacao(state.avaliacao, action),
    pacientes: pacientes(state.pacientes, action),
    profissionais: profissionais(state.profissionais, action),
    profissoes: profissoes(state.profissoes, action),
    respostas: respostas(state.respostas, action),
    tiposDeAtendimento: tiposDeAtendimento(state.tiposDeAtendimento, action)
  }
}