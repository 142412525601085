import pacientes from './pacientes'
import passo1 from './passo1'
import passo2 from './passo2'
import passo3 from './passo3'

const estadoInicial = {
  passo1: undefined,
  passo2: undefined,
  passo3: undefined,
  pacientes: undefined
}

export default (state = estadoInicial, action) => {
  return {
    passo1: passo1(state.passo1, action),
    passo2: passo2(state.passo2, action),
    passo3: passo3(state.passo3, action),
    pacientes: pacientes(state.pacientes, action)
  }
}