
import React, { createContext, useContext } from 'react'

const ContextoDoFirestore = createContext()

export const ProvedorDoFirestore = ({ firestore, children }) => {
  return <ContextoDoFirestore.Provider value={{ firestore }}>{children}</ContextoDoFirestore.Provider>
}

export const useFirestore = () => {
  return useContext(ContextoDoFirestore)
}