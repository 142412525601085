import React from 'react'
import { useProntuario } from '../../contexto'
import FileSaver from 'file-saver'

export default function ResumoDeAnexosDoAtendimento({ valor, identificadorDoPaciente, atendimento }) {
  const { fazerDownloadDoAnexoDoAtendimento } = useProntuario()

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoAnexoDoAtendimento(identificadorDoPaciente, atendimento.identificador, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  if (valor.length === 0) return null

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <div className='form-summary'>
        <div className='form-summary-files'>
          {valor.length > 0 && valor.map((x, index) =>
            <div className='file-upload-item' key={index}>
              <div className='file-upload-item__name'>{x.nome}</div>
              <button
                className='file-upload-item__bt'
                title='Fazer Download'
                type='button'
                onClick={() => fazerDownload(x)}
              >
                <i className='icon icon-download-cloud'></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </r-cell>
  )
}