import {SelectReact} from '../../formik/select'
import React, {useMemo} from 'react'

export default function Status({alterar, status, valor }) {
  const opcoesDeStatus = useMemo(() => {
    if (!status) return []
    let itens = []

    const ultimaVersao = status.versoes.slice(-1)
    ultimaVersao.map(v => v.status.map(s => s.nome)).forEach(t => { itens = itens.concat(t) })

    return itens.map(x => ({ value: x, label: x }))
  }, [status])

  return (
    <div className='form-group'>
      <label className='form-label'>Status</label>
      <SelectReact
        nome='status'
        onChange={alterar}
        options={opcoesDeStatus}
        valor={valor}
        isMulti={true}
        isOptionDisabled={() => valor.length > 9}
      />
    </div>
  )
}