import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {recuperarConfiguracoesVigentesDoUsuario} from '../usuarios/configuracoes'
import {
  ALTERAR_DATA_DO_CALENDARIO,
  ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ALTERAR_VISUALIZACAO_DO_CALENDARIO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL,
  SELECIONAR_PROFISSIONAL_DA_AGENDA,
  SELECIONAR_UNIDADES_DA_AGENDA,
} from '../tipos'

export const selecionarProfissionalDaAgenda = identificadorDoProfissional => ({
  type: SELECIONAR_PROFISSIONAL_DA_AGENDA,
  identificadorDoProfissional,
})

const listouProfissionaisDeSaudeDaAgenda = profissionaisDeSaude => ({
  type: LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  profissionaisDeSaude,
})

const erroAoListarProfissionaisDeSaudeDaAgenda = erro => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  erro,
})

export const listarProfissionaisDeSaudeDaAgenda = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA })

  try {
    const resultado = await api.listarProfissionaisDeSaudeDaAgenda()

    dispatch(listouProfissionaisDeSaudeDaAgenda(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados.dados[0].identificador
  } catch (erro) {
    dispatch(erroAoListarProfissionaisDeSaudeDaAgenda(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais de saúde da agenda.')))

    return false
  }
}

export const filtrarProfissionaisDeSaudeDaAgenda = (nome) => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA })

  try {
    const resultado = await api.filtrarProfissionaisDeSaudeDaAgenda(nome)

    dispatch(listouProfissionaisDeSaudeDaAgenda(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarProfissionaisDeSaudeDaAgenda(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível filtrar o profissional de saúde da agenda.')))

    return false
  }
}

export const alterarTermoDeProfissionaisDeSaudeDaAgenda = (termo, filtros) => ({
  type: ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  termo,
  filtros
})

export const selecionarUnidadesDaAgenda = unidades => ({
  type: SELECIONAR_UNIDADES_DA_AGENDA,
  unidades,
})

export const alterarVisualizacaoDoCalendario = visualizacao => async dispatch => {
  dispatch({
    type: ALTERAR_VISUALIZACAO_DO_CALENDARIO,
    visualizacao,
  })
}

export const alterarDataDoCalendario = data => async dispatch => {
  dispatch({
    type: ALTERAR_DATA_DO_CALENDARIO,
    data,
  })
}

const recuperouAgendaSemanalDoProfissional = (agenda, data, identificadorDoAgendamento) => ({
  type: RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL,
  agenda,
  data,
  identificadorDoAgendamento,
})

const erroAoRecuperarAgendaSemanalDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const recuperarAgendaSemanalDoProfissional = (identificadorDoProfissional, data, identificadorDoAgendamento, unidadeDoUsuarioLogado) => async dispatch => {
  dispatch({ type: RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL })

  try {
    const resultado = await api.recuperarAgendaSemanalDoProfissional(identificadorDoProfissional, data)

    dispatch(recuperouAgendaSemanalDoProfissional(resultado.data.dados, data, identificadorDoAgendamento))
    dispatch(recuperarConfiguracoesVigentesDoUsuario(identificadorDoProfissional, unidadeDoUsuarioLogado))

    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarAgendaSemanalDoProfissional(erro, { identificadorDoProfissional, data, identificadorDoAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a agenda semanal do profissional.')))
  }
}

export const limparDiasDoProfissionalDaAgenda = identificadorDoProfissional => ({
  type: LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA, 
  identificadorDoProfissional 
})
