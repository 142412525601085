import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
} from '../../tipos'

const listouIdasAoProntoAtendimentoDoPaciente = (identificadorDoPaciente, idasAoPa) => ({
  type: LISTOU_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  identificadorDoPaciente,
  idasAoPa
})

const erroAoListarIdasAoProntoAtendimentoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  erro,
  parametros
})

export const listarIdasAoProntoAtendimentoDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE })

  try {
    const resultado = await api.listarIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente)

    dispatch(listouIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarIdasAoProntoAtendimentoDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as idas ao pronto atendimento do paciente.')))
  }
}

const adicionouIdaAoProntoAtendimentoDoPaciente = idaAoPa => ({
  type: ADICIONOU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  idaAoPa
})

const erroAoAdicionarIdaAoProntoAtendimentoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarIdaAoProntoAtendimentoDoPaciente = (identificadorDoPaciente, idaAoPa) => async dispatch => {
  dispatch({ type: ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarIdaAoProntoAtendimentoDoPaciente(identificadorDoPaciente, idaAoPa)

    dispatch(adicionouIdaAoProntoAtendimentoDoPaciente(resultado.data.dados))
    dispatch(listarIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarIdaAoProntoAtendimentoDoPaciente(erro, { idaAoPa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar ida ao pronto atendimento do paciente.')))

    return false
  }
}

const alterouIdaAoProntoAtendimentoDoPaciente = idaAoPa => ({
  type: ALTEROU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  idaAoPa
})

const erroAoAlterarIdaAoProntoAtendimentoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarIdaAoProntoAtendimentoDoPaciente = (identificadorDoPaciente, idaAoPa) => async dispatch => {
  dispatch({ type: ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE })

  try {
    const resultado = await api.alterarIdaAoProntoAtendimentoDoPaciente(identificadorDoPaciente, idaAoPa)

    dispatch(alterouIdaAoProntoAtendimentoDoPaciente(resultado.data.dados))
    dispatch(listarIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarIdaAoProntoAtendimentoDoPaciente(erro, { idaAoPa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar ida ao pronto atendimento do paciente.')))

    return false
  }
}

const removeuIdaAoProntoAtendimentoDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverIdaAoProntoAtendimentoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  erro,
  parametros
})

export const removerIdaAoProntoAtendimentoDoPaciente = (identificadorDoPaciente, identificadorDaIdaAoPa) => async dispatch => {
  dispatch({ type: REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE })

  try {
    const resultado = await api.removerIdaAoProntoAtendimentoDoPaciente(identificadorDoPaciente, identificadorDaIdaAoPa)

    dispatch(removeuIdaAoProntoAtendimentoDoPaciente(identificadorDoPaciente))
    dispatch(listarIdasAoProntoAtendimentoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverIdaAoProntoAtendimentoDoPaciente(erro, { identificadorDoPaciente, identificadorDaIdaAoPa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover ida ao pronto atendimento do paciente.')))

    return false
  }
}