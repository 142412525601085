import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  RECUPERAR_PROFISSAO_POR_IDENTIFICADOR,
  LISTAR_ESPECIALIDADES,
  RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR
} from '../../../acoes/tipos'

export default function ListaDeEspecialidades(props) {
  const {
    especialidades,
    history,
    listarEspecialidades,
    recuperarProfissaoPorIdentificador,
    profissao,
    match
  } = props

  const { identificador } = match.params

  useEffect(() => {
    (async () => {
      await recuperarProfissaoPorIdentificador(identificador)
      if (identificador)
        await listarEspecialidades(identificador)
    })()
  }, [identificador, recuperarProfissaoPorIdentificador, listarEspecialidades])

  return (
    <div className='page-pacientes-lista'>
      <div className='header-page-tools'>
        <div className='user-paciente mt-2'>
          <p>{profissao.nome}</p>
          <Permissao permissoes={[permissoes.ADICIONAR_ESPECIALIDADE]}>
            <Link to={`/cadastros/profissoes/${identificador}/especialidades/novo`}
              className='button --primary --plus'>
              Adicionar
            </Link>
          </Permissao>
        </div>
      </div>
      <div className='main-content-form'>
        <Spinner operacoes={[RECUPERAR_PROFISSAO_POR_IDENTIFICADOR, LISTAR_ESPECIALIDADES, RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR]}>
          {especialidades.length > 0 && especialidades.filter(x => x.nome).length > 0 &&
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_ESPECIALIDADE]}>
                    <Link
                      to={`/cadastros/profissoes/${identificador}/especialidades/${item.especialidade}`}
                      className='table-items__bt --edit'
                      title='Editar'
                      key={item.especialidade}
                    >
                      <i className='icon icon-pencil'></i>
                    </Link>
                  </Permissao>
                )
              ]}
              chave='nome'
              className='mt-2 mb-4'
              dados={especialidades}
            >
              <Coluna
                campoDeDados='nome'
                className='col-xs-8 col-md-10 col-lg-11'
                principal={true}
                nome='Nome'
              />
            </Tabela>
          }
        </Spinner>
        <div className='list-btn'>
          <button
            className='button --light'
            type='button'
            onClick={() => history.push(`/cadastros/profissoes/lista`)}
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  )
}