import React, {useState, useEffect, useMemo } from 'react'
import {
    dataIgualOuAnterior,
    dataIgualOuPosterior,
    diferencaEmMesesEntreDatas,
    formatarDataComPrecisaoParaFormatoLocal,
    formatarDataParaFormatoLocal
} from '../../../../bibliotecas/data'
import Spinner from '../../../spinner'
import moment from 'moment'
import tabelaVazia from '../../../../design/img/empty-data.svg'
import {
    LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
    LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
    LISTAR_MOTIVOS_DE_INTERNACAO,
    LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE,
    LISTAR_TIPOS_DE_ATENDIMENTO,
    LISTAR_VACINAS
} from '../../../../acoes/tipos'
import ReactTooltip from "react-tooltip";
import {useProntuario} from "../../contexto";
import Filtros from "./filtros";
import Modal from "react-modal";

export default function TimelineDoPaciente({ identificadorDoPaciente, eventos = [], filtros }) {
    const {
        listarEventosDaTimelineDoPaciente,
        tiposDeAtendimento,
        profissoes,
        vacinas,
        motivosDeIdaAoProntoAtendimento,
        motivosDeInternacao
    } = useProntuario()

    useEffect(() => {
        listarEventosDaTimelineDoPaciente(identificadorDoPaciente, filtros)
    }, [listarEventosDaTimelineDoPaciente, filtros, identificadorDoPaciente])

    const historicoReduzido = useMemo(() => {
    const atendimentos = eventos.atendimentos ?
            eventos.atendimentos.map(x => ({
              profissao: x.usuario.profissao ? x.usuario.profissao.nome : 'SEM PROFISSÃO',
              data: new Date(x.inicio),
              tipo: x.tipo.nome,
              profissional: x.usuario.nome,
              evento: "ATENDIMENTO"
            }))
            : []

    const idasAoPa = eventos.idasAoProntoAtendimento ?
        eventos.idasAoProntoAtendimento.map(x => ({
          ...x,
          evento: "IDA AO PRONTO ATENDIMENTO",
          data: new Date(x.inicio),
            })
        ) : []

    const vacinas = eventos.vacinas ?
        eventos.vacinas.map(x => ({
          ...x,
          evento: "VACINA",
          data: new Date(x.data),
        })) :
        []

    const internacoes = eventos.internacoes ?
        eventos.internacoes.map(x => ({
          ...x,
          evento: "INTERNAÇÃO",
          data: new Date(x.inicio),
        })) :
        []

    return  [...atendimentos, ...idasAoPa, ...vacinas, ...internacoes]
    }, [eventos])

  const [exibirFiltros, setExibirFiltros] = useState(false)

  const abrirFiltros = () => setExibirFiltros(true)
  const fecharFiltros = () => setExibirFiltros(false)

  const renderizarCasoVazio = mensagem => {
    return (
      <div className='table-items__result'>
        <button className='icon-tabela-vazia'>
          <img src={tabelaVazia} alt='tabelaVazia' />
        </button>
        <p>{mensagem}</p>
      </div>
    )
  }

  return (
      <>
          <Modal
          isOpen={exibirFiltros}
          className='modal'
          contentLabel='Modal para selecionar filtros'
      >
          <Filtros
              filtros={filtros}
              fechar={fecharFiltros}
              filtrar={listarEventosDaTimelineDoPaciente}
              identificador={identificadorDoPaciente}
              tiposDeAtendimento={tiposDeAtendimento}
              profissoes={profissoes}
              vacinas={vacinas}
              motivosDeIdaAoProntoAtendimento={motivosDeIdaAoProntoAtendimento}
              motivosDeInternacao={motivosDeInternacao}
          />
      </Modal>
        <Spinner operacoes={[
            LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
            LISTAR_TIPOS_DE_ATENDIMENTO,
            LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
            LISTAR_MOTIVOS_DE_INTERNACAO,
            LISTAR_VACINAS,
            LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE
        ]}>
          {({ processando }) => (
            processando ? <div className='table-items-expanded is-loading abas-qgcc'></div> :
              historicoReduzido.length === 0 ? renderizarCasoVazio('O paciente não possui eventos para os filtros informados.') :
                  <>
                      <r-grid columns-md={6} columns-lg={6} className='mb-2'>
                          <r-cell span={4} span-md={1} span-lg='11-12'>
                              <button
                                  data-tip
                                  data-for={'filtro'}
                                  type='button'
                                  className='button --light mt-8'
                                  onClick={abrirFiltros}>
                                  <i className="icon icon-filter"/>
                                  Filtrar
                              </button>
                              <ReactTooltip
                                  id={`filtro`}
                                  place="top"
                                  effect="solid"
                                  type="info"
                              >
                                  <div
                                      style={{
                                          marginBottom: 8,
                                          borderBottomStyle: 'solid',
                                          borderBottomWidth: '1px',
                                          borderBottomColor: 'rgb(232, 232, 232)'
                                      }}
                                  >
                                      <strong>Filtros aplicados</strong>
                                  </div>
                                  <span>Início: {formatarDataParaFormatoLocal(filtros.inicio)}</span> <br />
                                  <span>Fim: {formatarDataParaFormatoLocal(filtros.fim)}</span> <br />
                                  {filtros.tiposDeAtendimento?.length > 0 && <><span>Tipos de atendimento: {tiposDeAtendimento.filter(x => filtros.tiposDeAtendimento.includes(x.identificador)).map(x => x.nome).join(', ')}</span> <br /></>}
                                  {filtros.profissoes?.length > 0 && <><span>Profissões: {profissoes.filter(x => filtros.profissoes.includes(x.identificador)).map(x => x.nome).join(', ')}</span> <br /></>}
                                  {filtros.vacinas?.length > 0 && <><span>Vacinas: {vacinas.filter(x => filtros.vacinas.includes(x.codigo)).map(x => x.nome).join(', ')}</span> <br /></>}
                                  {filtros.motivosDeIdaAoProntoAtendimento?.length > 0 && <><span>Motivos de ida ao PA: {filtros.motivosDeIdaAoProntoAtendimento.join('; ')}</span> <br /></>}
                                  {filtros.motivosDeInternacao?.length > 0 && <><span>Motivos de internação: {filtros.motivosDeInternacao.join('; ')}</span> <br /></>}
                              </ReactTooltip>
                          </r-cell>
                      </r-grid>
                      {historicoReduzido.length > 0 ?
                      <RenderizarTimeline dados={historicoReduzido} dataInicial={new Date(filtros.inicio)} dataFinal={new Date(filtros.fim)}/>
                      : renderizarCasoVazio('O paciente não possui eventos com os filtros selecionados.')}
                  </>
          )}
        </Spinner>
    </>
  )
}

function RenderizarTimeline ({ dados = [], dataInicial, dataFinal }) {
      function calcularPosicaoRelativa(dataEvento) {
        const inicio = new Date(dataInicial).getTime()
        const fim = new Date(dataFinal).getTime()
        const data = new Date(dataEvento).getTime()

        const duracaoTotal = fim - inicio;
        const diferencaEvento = data - inicio;

        return (diferencaEvento / duracaoTotal) * 100;
      }

    function gerarInicioDeMeses(inicio, fim) {
        const datas = []
        let dataAtual = new Date(inicio.getFullYear(), inicio.getMonth(), 1)

        const diferencaMeses = diferencaEmMesesEntreDatas(inicio, fim)

        while (dataAtual <= fim) {
            if(dataIgualOuPosterior(dataAtual, inicio) && dataIgualOuAnterior(dataAtual, fim)) datas.push(new Date(dataAtual))

            if (diferencaMeses <= 12) {
                dataAtual.setMonth(dataAtual.getMonth() + 1)
            } else if (diferencaMeses <= 18) {
                dataAtual.setMonth(dataAtual.getMonth() + 2)
            } else if (diferencaMeses <= 24) {
                dataAtual.setMonth(dataAtual.getMonth() + 3)
            } else if (diferencaMeses <= 36) {
                dataAtual.setMonth(dataAtual.getMonth() + 4)
            } else if (diferencaMeses <= 48) {
                dataAtual.setMonth(dataAtual.getMonth() + 6)
            } else {
                dataAtual.setMonth(dataAtual.getMonth() + 12)
            }
        }

        return datas
    }

  return (
      <>
      <r-grid columns-md={6} columns-lg={12} className='mb-2'>
          <r-cell span={4} span-md={3} span-lg={3}>
              <div className="timeline-indicadores">
                  <div className="atendimentos">
                      <i className="icone icon-doctor" style={{ fontSize: 18 }}></i>
                      <div className="dados">
                          <p className="indicador">{dados.filter(x => x.evento === "ATENDIMENTO").length}</p>
                          <p className="evento">atendimento(s)</p>
                      </div>
                  </div>
                  <div className="vacinas">
                      <i className="icone icon-eyedropper" style={{ fontSize: 18 }}></i>
                      <div className="dados">
                          <p className="indicador">{dados.filter(x => x.evento === "VACINA").length}</p>
                          <p className="evento">vacina(s)</p>
                      </div>
                  </div>
                  <div className="idas-ao-pa">
                      <i className="icone icon-sala" style={{ fontSize: 18 }}></i>
                      <div className="dados">
                          <p className="indicador">{dados.filter(x => x.evento === "IDA AO PRONTO ATENDIMENTO").length}</p>
                          <p className="evento">ida(s) ao PA</p>
                      </div>
                  </div>
                  <div className="internacoes">
                      <i className="icone icon-estabelecimento-de-saude" style={{ fontSize: 18 }}></i>
                      <div className="dados">
                          <p className="indicador">{dados.filter(x => x.evento === "INTERNAÇÃO").length}</p>
                          <p className="evento">internação(ões)</p>
                      </div>
                  </div>
              </div>
          </r-cell>
          <r-cell span={4} span-md={3} span-lg={9}>
              <div className="timeline-container">
                  <div className="timeline">
                      {dados.filter(x => x.evento === "ATENDIMENTO").map((dado, index) => {
                          return (
                              <div
                                  className="timeline-event"
                                  style={{ left: `${calcularPosicaoRelativa(dado.data)}%`, position: 'absolute' }}
                                  data-date={dado.data}
                                  key={index}
                              >
                                  <div
                                      className="box-panel__sidebar__indicator dot dados-atendimento"
                                      data-tip
                                      data-for={`atendimento_${index}`}
                                  >
                                      <ReactTooltip
                                          id={`atendimento_${index}`}
                                          place="top"
                                          effect="solid"
                                          type="info"
                                          className="tamanho-tooltip-diagnostico"
                                      >
                                          <div
                                              style={{
                                                  marginBottom: 8,
                                                  borderBottomStyle: 'solid',
                                                  borderBottomWidth: '1px',
                                                  borderBottomColor: 'rgb(232, 232, 232)'
                                              }}
                                          >
                                              <strong>{dado.evento} | {formatarDataParaFormatoLocal(dado.data)}</strong>
                                          </div>
                                          <span>Tipo: {dado.tipo}</span> <br />
                                          <span>Profissão: {dado.profissao}</span> <br />
                                          <span>Profissional: {dado.profissional}</span> <br />
                                      </ReactTooltip>
                                  </div>
                              </div>
                          )
                      })}
                      {gerarInicioDeMeses(dataInicial, dataFinal).map(data => (
                              <div
                                  className="month-divider-wide"
                                  data-date={data}
                                  style={{ left: `${calcularPosicaoRelativa(moment(data))}%` }}
                                  key={data}
                              ></div>
                      ))}
                  </div>
              </div>
              <div className="timeline-container">
                  <div className="timeline">
                      {dados.filter(x => x.evento === "VACINA").map((dado, index) => {
                          return (
                              <div
                                  className="timeline-event"
                                  style={{left: `${calcularPosicaoRelativa(dado.data)}%`, position: 'absolute'}}
                                  data-date={dado.data}
                                  key={index}>
                                  <div
                                      className="box-panel__sidebar__indicator dot dados-vacina"
                                      data-tip
                                      data-for={`vacina_${index}`}
                                  >
                                      <ReactTooltip
                                          id={`vacina_${index}`}
                                          place='top'
                                          effect='solid'
                                          type='info'
                                          className='tamanho-tooltip-diagnostico'
                                      >
                                          <div
                                              style={{
                                                  marginBottom: 8,
                                                  borderBottomStyle: 'solid',
                                                  borderBottomWidth: '1px',
                                                  borderBottomColor: 'rgb(232, 232, 232)'
                                              }}>
                                              <strong>{dado.evento} | {formatarDataParaFormatoLocal(dado.data)}</strong>
                                          </div>
                                          <span>Vacina: {dado.vacina}</span> <br/>
                                      </ReactTooltip>
                                  </div>
                              </div>
                          )
                      })}
                  </div>
              </div>
              <div className="timeline-container">
                  <div className="timeline">
                      {dados.filter(x => x.evento === "IDA AO PRONTO ATENDIMENTO").map((dado, index) => {
                          return (
                              <div
                                  className="timeline-event"
                                  style={{left: `${calcularPosicaoRelativa(dado.data)}%`, position: 'absolute'}}
                                  data-date={dado.data}
                                  key={index}>
                                  <div
                                      className="box-panel__sidebar__indicator dot dados-ida-ao-pa"
                                      data-tip
                                      data-for={`ida_ao_pa_${index}`}
                                  >
                                      <ReactTooltip
                                          id={`ida_ao_pa_${index}`}
                                          place='top'
                                          effect='solid'
                                          type='info'
                                          className='tamanho-tooltip-diagnostico'
                                      >
                                          <div
                                              style={{
                                                  marginBottom: 8,
                                                  borderBottomStyle: 'solid',
                                                  borderBottomWidth: '1px',
                                                  borderBottomColor: 'rgb(232, 232, 232)'
                                              }}>
                                              <strong>{dado.evento} | {formatarDataParaFormatoLocal(dado.data)}</strong>
                                          </div>

                                          <span>Motivo(s): {dado.motivos.join(", ")}</span> <br/>
                                          <span>Estabelecimento: {dado.estabelecimentoDeSaude}</span> <br/>
                                          <span>Status: {dado.status}</span>
                                      </ReactTooltip>
                                  </div>
                              </div>
                          )
                      })}
                  </div>
              </div>
              <div className="timeline-container">
                  <div className="timeline">
                      {dados.filter(x => x.evento === "INTERNAÇÃO").map((dado, index) => {
                          return (
                              <div
                                  className="timeline-event"
                                  style={{left: `${calcularPosicaoRelativa(dado.data)}%`, position: 'absolute'}}
                                  data-date={dado.data}
                                  key={index}>
                                  <div
                                      className="box-panel__sidebar__indicator dot dados-internacao"
                                      data-tip
                                      data-for={`internacao_${index}`}
                                  >
                                      <ReactTooltip
                                          id={`internacao_${index}`}
                                          place='top'
                                          effect='solid'
                                          type='info'
                                          className='tamanho-tooltip-diagnostico'
                                      >
                                          <div
                                              style={{
                                                  marginBottom: 8,
                                                  borderBottomStyle: 'solid',
                                                  borderBottomWidth: '1px',
                                                  borderBottomColor: 'rgb(232, 232, 232)'
                                              }}>
                                              <strong>{dado.evento} | {formatarDataParaFormatoLocal(dado.data)}</strong>
                                          </div>

                                          <span>Motivo(s): {dado.motivos.map(x => x.motivo.nome).join(", ")}</span> <br/>
                                          <span>Estabelecimento: {dado.estabelecimentoDeSaude}</span> <br/>
                                          <span>Status: {dado.status}</span> <br/>
                                          <span>Tipo: {dado.tipo}</span>
                                      </ReactTooltip>
                                  </div>
                              </div>
                          )
                      })}
                      {gerarInicioDeMeses(dataInicial, dataFinal).map(data => (
                          <div
                              className="meses-descricao"
                              style={{ left: `${calcularPosicaoRelativa(moment(data))}%` }}
                              key={data}
                          >{formatarDataComPrecisaoParaFormatoLocal(data, 'MÊS/ANO')}</div>
                      ))}
                  </div>
              </div>
          </r-cell>
      </r-grid>
    </>
)}