import React from 'react'
import chroma from 'chroma-js'
import { ExclamationOctagon, Tag } from '../../../design/icones'
import Tipo from './campos/tipo'
import Responsavel from './campos/responsavel'
import Status from './campos/status'
import Prazo from './campos/prazo'

export default function Intercorrencia({ item, nome }) {
  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo) {
      case 'vermelho':
        return '#BD3035'
      case 'laranja':
        return '#ED7A17'
      case 'amarelo':
        return '#F9C72D'
      case 'verde':
        return '#70C968'
      default:
        return ''
    }
  }

  const cor = corDaUrgencia(item.urgencia?.codigo)
  const color = cor && chroma(cor)
  const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

  return (
    <div className='card card-tarefas'>
      <div className='card-title'>
        <Tipo valor={item.tipo.nome} cor={item.corDoTipo} />
        <Prazo valor={item.prazo} atrasado={item.atrasado} />
      </div>
      <Status valor={item.status} />
      <Responsavel valor={nome} />
      <div className='card-title'>
        <div className='card-componente-sidebar'>
          <div className='icon'><Tag /></div>
          <label className='titulo'>Categoria</label>
          <div className='valor'>{item.categoria?.nome}</div>
        </div>
        <div className='card-componente-sidebar'>
          <div className='icon'><ExclamationOctagon /></div>
          <label className='titulo'>Urgência</label>
          <div className='valor'>
            <span
              className='table-items__bcolor-card-sidebar valor card-tipo-menor'
              style={{ background: cor, color: corDaFonte, fontSize: '10px' }}
            >
              {item.urgencia?.nome}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}