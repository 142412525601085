import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  DEFINIR_PACIENTE_DA_TAREFA,
  DEFINIU_PACIENTE_DA_TAREFA,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA,
  ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS,
  LISTAR_PACIENTES_DO_TAREFAS,
  LISTOU_PACIENTES_DO_TAREFAS,
} from '../tipos'

const definiuPacienteDaTarefa = (identificadorDaTarefa, paciente) => ({
  identificadorDaTarefa,
  paciente,
  type: DEFINIU_PACIENTE_DA_TAREFA,
})

const erroAoDefinirPacienteDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_PACIENTE_DA_TAREFA,
  erro,
  parametros,
})

export const definirPacienteDaTarefa = (identificadorDaTarefa, paciente) => async dispatch => {
  dispatch({ type: DEFINIR_PACIENTE_DA_TAREFA })

  try {
    const resultado = await api.definirPacienteDaTarefa(identificadorDaTarefa, paciente)

    dispatch(definiuPacienteDaTarefa(identificadorDaTarefa, paciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirPacienteDaTarefa(erro, { identificadorDaTarefa, paciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o paciente da tarefa.')))

    return false
  }
}

const listouPacientesDoTarefas = pacientes => ({
  type: LISTOU_PACIENTES_DO_TAREFAS,
  pacientes,
})

const erroAoListarPacientesDoTarefas = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS,
  erro,
  parametros
})

export const listarPacientesDoTarefas = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PACIENTES_DO_TAREFAS })

    try {
      const retorno = await api.listarPacientesDoTarefas(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouPacientesDoTarefas(retorno.data.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarPacientesDoTarefas(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes do tarefas.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PACIENTES_DO_TAREFAS,
    }
  }

  return resultado
}

const adicionouPreCadastroDoPacienteDaTarefa = preCadastro => ({
  type: ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  preCadastro
})

const erroAoAdicionarPreCadastroDoPacienteDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  erro,
  parametros,
})

export const adicionarPreCadastroDoPacienteDaTarefa = preCadastro => async dispatch => {
  dispatch({ type: ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA })
  try {
    const resultado = await api.adicionarPreCadastroDoPaciente(preCadastro)

    dispatch(adicionouPreCadastroDoPacienteDaTarefa(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados
  } catch (erro) {
    dispatch(erroAoAdicionarPreCadastroDoPacienteDaTarefa(erro, preCadastro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do pré-cadastro do paciente.')))

    return false
  }
}

