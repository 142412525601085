import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../spinner'
import Assinado from '../assinatura-fisico/assinado'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'
import { ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM, RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM, RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO } from '../../../acoes/tipos'
import {
  assinarAtendimentoComCertificadoEmNuvem,
  recuperarUrlDeAutenticacaoDoCertificadoEmNuvem,
  recuperarCertificadosEmNuvemDoUsuario
} from '../../../acoes/lacuna'
import FormularioDeAssinaturaDigitalEmNuvem from './formulario'

export default function AssinaturaDigitalEmNuvem({ fecharModal, origem }) {
  const dispatch = useDispatch()

  const { usuario } = useUsuarioLogado()
  const { lacuna } = useSelector(state => state.prontuario)

  const [segundaTentavida, setSegundaTentativa] = useState(false)
  const [etapas, setEtapas] = useState(null)
  const [labelBotaoAssinar, setLabelBotaoAssinar] = useState('Assinar')

  const assinarComUrlDeAutenticacao = async ({ servico, tempo }) => {
    const tempoDaSessao = !tempo || tempo === '00:00:00' ? '00:01:00' : tempo

    var url = await dispatch(recuperarUrlDeAutenticacaoDoCertificadoEmNuvem(servico, tempoDaSessao, lacuna.identificadores.atendimento, origem))

    if (url) {
      window.location.href = url
    }
  }

  useEffect(() => {
    (async () => {
      if (usuario.temSessaoEmAberto) {
        setEtapas('Assinando, por favor aguarde ...')

        const assinou = await dispatch(assinarAtendimentoComCertificadoEmNuvem(lacuna.identificadores.atendimento))

        if (!assinou) {
          setLabelBotaoAssinar('Reautenticar')
          setEtapas('')
        }
      }

    })()
  }, [dispatch, usuario.temSessaoEmAberto, lacuna.identificadores.atendimento])

  useEffect(() => {
    if (!usuario.temSessaoEmAberto && usuario.certificadosEmNuvem.lista.length === 0 && !segundaTentavida) {
      setSegundaTentativa(true)
      dispatch(recuperarCertificadosEmNuvemDoUsuario())
    }
  }, [dispatch, usuario.temSessaoEmAberto, usuario.certificadosEmNuvem.lista, segundaTentavida])

  const linkExterno = (url) => {
    window.open(url, '_blank')
  }


  return (
    <div className='main-content-form'>
      <div className='form mt-2 resumo-do-atendimento'>
        <div className='assinatura-digital-atendimento'>
          <h2 className='form-title'>{lacuna.finalizado ? 'Atendimento Assinado com Certificado em Nuvem' : 'Assinar com Certificado em Nuvem'}</h2>
          <hr className='separator mt-1'></hr>
          <div className='page-alterar-senha'>
            <Spinner operacoes={[RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM, RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO]}>
              {({ processando }) => {
                if (processando) {
                  return (
                    <h2 className='verificando-permissoes text-black-50'>Inicializando a comunicação com provedor de assinaturas...</h2>
                  )

                } else if (lacuna.finalizado) {
                  return <Assinado lacuna={lacuna} fecharModal={fecharModal} />

                } else {

                  return (
                    <Spinner operacoes={[ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM]}>
                      {({ processando }) => {
                        if (processando) {
                          return <h2 className='verificando-permissoes text-black-50'>Assinando, por favor aguarde ...</h2>
                        } else {
                          return (
                            <FormularioDeAssinaturaDigitalEmNuvem
                              assinarComUrlDeAutenticacao={assinarComUrlDeAutenticacao}
                              etapas={etapas}
                              fecharModal={fecharModal}
                              labelBotaoAssinar={labelBotaoAssinar}
                              lacuna={lacuna}
                              linkExterno={linkExterno}
                              usuario={usuario}
                            />
                          )
                        }
                      }}
                    </Spinner>
                  )
                }
              }}
            </Spinner>
          </div>
        </div>
      </div>
    </div>
  )
}