import React from 'react'
import { FileText } from '../../../design/icones'
import Responsavel from './campos/responsavel'
import Status from './campos/status'
import Tipo from './campos/tipo'
import Prazo from './campos/prazo'

export default function Intercorrencia({ item, nome }) {
  return (
    <div className='card card-tarefas'>
      <div className='card-title'>
        <Tipo valor={item.tipo.nome} cor={item.corDoTipo} />
        <Prazo valor={item.prazo} atrasado={item.atrasado} />
      </div>
      <Status valor={item.status} />
      <Responsavel valor={nome} />
      <div className='card-title'>
        <div className='card-componente-sidebar'>
          <div className='icon'><FileText /></div>
          <label className='titulo'>Descrição</label>
          <div className='valor reticencias'>{item.descricao}</div>
        </div>
      </div>
    </div>
  )
}