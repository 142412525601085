import React, { useMemo } from 'react'
import moment from 'moment'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import { diferencaEmMinutosEntreDatas } from '../../bibliotecas/data'

export default function AlertaGeral(props) {
  const { usuario } = useUsuarioLogado()

  const HOJE = moment()
  const atendimentosEmAberto = useMemo(() =>
    usuario.atendimentos.filter(a => a.inicio && diferencaEmMinutosEntreDatas(a.inicio, HOJE) >= 1440).map(a => a), [usuario.atendimentos, HOJE])

  if (atendimentosEmAberto.length === 0) return null

  return (
    <div className='alerta-geral'>
      <span>ATENÇÃO: {atendimentosEmAberto.length === 1 ? `Existe 1 atendimento` : `Existem ${atendimentosEmAberto.length} atendimentos`} em andamento há mais de 24 horas.</span>
    </div>
  )
}