import api from '../../api-major'

const adicionarConsultaExternaDoPaciente = (identificador, consultaExterna) => api.post(`/pacientes/${identificador}/consultas-externas`, consultaExterna)
const alterarConsultaExternaDoPaciente = (identificador, consultaExterna) => api.put(`/pacientes/${identificador}/consultas-externas/${consultaExterna.identificador}`, consultaExterna)
const removerConsultaExternaDoPaciente = (identificador, identificadorDaConsultaExterna) => api.delete(`/pacientes/${identificador}/consultas-externas/${identificadorDaConsultaExterna}`)
const listarConsultasExternasDoPaciente = identificador => api.get(`/pacientes/${identificador}/consultas-externas`)

export default { 
  adicionarConsultaExternaDoPaciente,
  alterarConsultaExternaDoPaciente,
  removerConsultaExternaDoPaciente,
  listarConsultasExternasDoPaciente
}