import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_LOCAIS_DE_QUEDAS,
  LISTOU_LOCAIS_DE_QUEDAS,
  ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS
} from './tipos'

const listouLocaisDeQuedas = locaisDeQuedas => ({
  type: LISTOU_LOCAIS_DE_QUEDAS,
  locaisDeQuedas
})

const erroAoListarLocaisDeQuedas = erro => ({
  type: ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS,
  erro
})

export const listarLocaisDeQuedas = () => async dispatch => {
  dispatch({ type: LISTAR_LOCAIS_DE_QUEDAS })

  try {
    const resultado = await api.listarLocaisDeQuedas()

    dispatch(listouLocaisDeQuedas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarLocaisDeQuedas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os locais de quedas.')))
  }
}