import React from 'react'
import Spinner from '../../spinner'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../../formik/formulario'
import { formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'
import moment from 'moment'
import FotoDoPaciente from '../foto-do-paciente'
import ContatosDoAgendamento from '../agendamento/contatos-do-agendamento'
import LinksDaTeleconsulta from '../agendamento/links-da-teleconsulta'

import { CONFIRMAR_CHEGADA_DO_PACIENTE, RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR } from '../../../acoes/tipos'

export default function ConfirmarChegada({
  fechar,
  agendamento,
  confirmarChegadaDoPaciente,
  fotos,
  pesquisar,
  filtros,
  pagina,
  ordenacao
}) {

  const identificadorDoPaciente = agendamento.paciente.identificador

  function formatarDiaDaSemana(dia) {
    return dia.charAt(0).toUpperCase() + dia.slice(1)
  }

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia).format('dddd'))
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  const confirmarChegada = async formulario => {
    const dados = {
      ...formulario,
      identificadorDoProfissional: agendamento.profissional.identificador,
      identificador: agendamento.identificador,
    }

    const confirmou = await confirmarChegadaDoPaciente(dados)

    if (confirmou) {
      fechar()
      pesquisar(filtros, pagina, ordenacao)
    }
  }

  const recuperarLink = mensagem => {
    if (mensagem.metaDados.tipo === 'AGENDAMENTO_COM_PRE_CADASTRO') {
      return <Link to={`/cadastros/pacientes/${identificadorDoPaciente}`} target='_blank'>{' '}[Ir para o Cadastro de Pacientes]</Link>
    } else if (mensagem.metaDados.tipo === 'AGENDAMENTO_SEM_CONVENIO') {
      return <Link to={`/cadastros/pacientes/${identificadorDoPaciente}/convenios`} target='_blank'>{' '}[Ir para os Convênios do Paciente]</Link>
    } else {
      return ''
    }
  }

  const renderizarAlerta = mensagens => {
    return (
      <>
        {mensagens.map((x, index) => x.tipo === 'Alerta' ?
          <div className='form-choice-alerta' key={index}>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
              <strong>
                {recuperarLink(x)}
              </strong>
            </div>
          </div>
          :
          <div className='form-choice-erro' key={index}>
            <div className='form-choice-erro__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-erro__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
        )}
        <hr className='separator mt-1 mb-1'></hr>
      </>
    )
  }

  function recuperarNomeDoProfissional(profissional) {
    let nomeDoProfissional = profissional.tratamento ? profissional.tratamento.concat(' ', profissional.nome) : profissional.nome

    if (profissional.profissao.especialidades.length === 0) {
      nomeDoProfissional += ` (${profissional.profissao.nome})`
    }

    if (profissional.profissao.especialidades.length > 0) {
      const especialidades = profissional.profissao.especialidades.join(', ')
      nomeDoProfissional += ` (${profissional.profissao.nome} - ${especialidades})`
    }

    return `${nomeDoProfissional}`
  }

  return (
    <Spinner operacoes={[CONFIRMAR_CHEGADA_DO_PACIENTE, RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          chegouDeAmbulancia: false,
          observacoes: '',
        }}
        acao={confirmarChegada}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>Confirmar Chegada do Paciente</h2>
            {agendamento.mensagens.length > 0 && renderizarAlerta(agendamento.mensagens)}
            {agendamento.bloqueado && renderizarAlerta([{ tipo: 'Erro', texto: 'Este horário está bloqueado. Por favor, <strong>reagende ou cancele este agendamento</strong>.' }])}
            <div className='user-paciente-agenda-modal'>
              <FotoDoPaciente
                paciente={agendamento.paciente}
                fotos={fotos}
              />
              <div>
                <p className='user-paciente-agenda-modal__name'>{agendamento.paciente.nome}</p>
                <div className='user-paciente-agenda-modal__info'>
                  <p><strong>Idade:</strong>{' '}{agendamento.paciente.idade}</p>
                  <p><strong>Sexo:</strong>{' '}{agendamento.paciente.sexo}</p>
                  {agendamento.convenio && <p><strong>Convênio:</strong>{' '}{agendamento.convenio.operadora}{' - '}{agendamento.convenio.numeroDaCarteira}</p>}
                  {agendamento.paciente.associacao && <p><strong>Associação:</strong>{' '}{agendamento.paciente.associacao}</p>}
                </div>
              </div>
            </div>
            <hr className='separator mt-1 mb-1'></hr>
            <div className='unity-infos'>
              <div className='unity-infos__item'>
                <ContatosDoAgendamento agendamento={agendamento} />
              </div>
              <div className='unity-infos-item'>
                <p>
                  <strong><i className='icon icon-calendar-empty'></i>{diaDaSemana(agendamento.inicio)}</strong>
                </p>
                <p>
                  <strong><i className='icon icon-clock'></i>{formatarHoraParaFormatoLocal(agendamento.inicio)} às {formatarHoraParaFormatoLocal(agendamento.fim)}</strong>
                </p>
              </div>
              <div className='unity-infos__item'>
                <p><strong>Unidade:</strong>{' '}{agendamento.unidade.nome}</p>
                <p><strong>Tipo:</strong>{' '}{agendamento.tipo.nome}</p>
                <p><strong>Profissional:</strong>{' '}{recuperarNomeDoProfissional(agendamento.profissional)}</p>
              </div>
              <div className='unity-infos__item'>
                <span>
                  <strong>Status:</strong>{' '}{agendamento.status[0].nome}
                  <button
                    data-tip
                    data-for={`info_${agendamento.identificador}`}
                    key={`info_${agendamento.identificador}`}
                    type='button'
                    className='botao-historico'
                    onMouseOver={() => ReactTooltip.rebuild()}
                  >
                    <i className='icon icon-history'></i>
                  </button>
                </span>
              </div>
              <LinksDaTeleconsulta agendamento={agendamento.identificadorPublico} />
            </div>
            <r-grid columns-md={6} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={6} span-lg={12}>
                <div className='form-toggle-list'>
                  <Toglle
                    className='form-toggle-2'
                    nome='chegouDeAmbulancia'
                    tabIndex={1}
                    titulo='Chegou de Ambulância?'
                  />
                </div>
                {(errors.chegouDeAmbulancia && touched.chegouDeAmbulancia) ? <label className='form-error'>{errors.chegouDeAmbulancia}</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={2}
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' onClick={() => fechar()} className='button --light'>Fechar</button>
              <button type='submit' className='button --primary'>Confirmar Chegada</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}