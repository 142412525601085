import React, { useEffect } from 'react'
import FileSaver from 'file-saver'
import { Switch } from 'react-router-dom'
import moment from 'moment'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import { formatarDataParaFormatoUniversal, formatarDataEHoraParaFormatoUniversal } from '../../../bibliotecas/data'
import Formulario from './formulario'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import GraficoDeReinternacoesMetaVersusAcumuldado from './grafico-de-reinternacoes'
import GraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado from './grafico-de-internacoes-por-fratura-de-femur'

export default function ResumoDeInternacoes(props) {
  const {
    dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado = [],
    dadosGraficoDeReinternacoesMetaVersusRealizado = [],
    fazerDownloadDeInternacoesPorFraturaDeFemur,
    fazerDownloadDeReinternacoes,
    filtros,
    gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado,
    gerarGraficoDeReinternacoesMetaVersusRealizado,
    listarProgramasDoRelatorioResumoDeInternacoes,
    match,
    programas
  } = props

  const permissoesParaGerarGraficoDeInternacoesPorFraturaDeFemur = usePossuiAsPermissoes([permissoes.GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO])
  const permissoesParaGerarGraficoDeReinternacoes = usePossuiAsPermissoes([permissoes.GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO])

  const filtrosIniciais = {
    ...filtros,
    dataDeInicio: filtros.dataDeInicio || moment().startOf('month').format('YYYY-MM-DD'),
    dataDeFim: filtros.dataDeFim || moment().format('YYYY-MM-DD'),
  }

  useEffect(() => {
    listarProgramasDoRelatorioResumoDeInternacoes()
  }, [listarProgramasDoRelatorioResumoDeInternacoes])

  const gerarNome = tipo => {
    const inicio = formatarDataParaFormatoUniversal(filtros.dataDeInicio).split('-').join('_')
    const fim = formatarDataParaFormatoUniversal(filtros.dataDeFim).split('-').join('_')
    const agora = formatarDataEHoraParaFormatoUniversal(new Date()).split('-').join('_').split(' ').join('_').split(':').join('_')

    return `${tipo}_de_${inicio}_ateh_${fim}_em_${agora}.csv`
  }

  const downloadDeInternacoesPorFraturaDeFemur = async () => {
    const retorno = await fazerDownloadDeInternacoesPorFraturaDeFemur(filtros)
    const nome = gerarNome('internacoes_por_fratura_de_femur')

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'text/csv' })
      FileSaver.saveAs(arquivo, nome)
    }
  }

  const downloadDeReinternacoes = async () => {
    const retorno = await fazerDownloadDeReinternacoes(filtros)
    const nome = gerarNome('reinternacoes')

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'text/csv' })
      FileSaver.saveAs(arquivo, nome)
    }
  }

  const gerar = filtros => {
    if (permissoesParaGerarGraficoDeInternacoesPorFraturaDeFemur) {
      gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado(filtros)
    }

    if (permissoesParaGerarGraficoDeReinternacoes) {
      gerarGraficoDeReinternacoesMetaVersusRealizado(filtros)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <div className='relatorios'>
              <Formulario
                {...props}
                filtrosIniciais={filtrosIniciais}
                gerar={gerar}
                programas={programas}
              />
              {dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado.length > 0 &&
                dadosGraficoDeReinternacoesMetaVersusRealizado.length > 0 &&
                <>
                  {permissoesParaGerarGraficoDeInternacoesPorFraturaDeFemur &&
                    <GraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado
                      dados={dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado}
                      download={downloadDeInternacoesPorFraturaDeFemur}
                    />
                  }
                  {permissoesParaGerarGraficoDeReinternacoes &&
                    <GraficoDeReinternacoesMetaVersusAcumuldado
                      dados={dadosGraficoDeReinternacoesMetaVersusRealizado}
                      download={downloadDeReinternacoes}
                    />
                  }
                </>
              }
            </div>
          )
        }
      />
    </Switch>
  )
}