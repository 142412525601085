import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import { 
  DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA, 
  DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA, 
  ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA 
} from '../tipos'

const definiuTelefoneDeContatoDaTarefa = (identificadorDaTarefa, telefoneDeContato) => ({
  identificadorDaTarefa,
  telefoneDeContato,
  type: DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA,
})

const erroAoDefinirTelefonDeDeContatoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA,
  erro,
  parametros,
})

export const definirTelefoneDeContatoDaTarefa = (identificadorDaTarefa, telefoneDeContato) => async dispatch => {
  dispatch({ type: DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA })

  try {
    const resultado = await api.definirTelefoneDeContatoDaTarefa(identificadorDaTarefa, telefoneDeContato)

    dispatch(definiuTelefoneDeContatoDaTarefa(identificadorDaTarefa, telefoneDeContato))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirTelefonDeDeContatoDaTarefa(erro, { identificadorDaTarefa, telefoneDeContato }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir telefone de contato da tarefa.')))

    return false
  }
}