import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'react-modal'
import Breadcrumb from '../../breadcrumb'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Resumo from '../atendimento/resumo'
import AssinaturaComCertificadoFisico from '../assinatura-fisico'
import AssinaturaComCertificadoEmNuvem from '../assinatura-em-nuvem'
import AssinaturaComCertificadoEmNuvemEmLote from '../assinatura-em-lote'
import { listarAtendimentosNaoAssinados, assinarAtendimentoFinalizado } from '../../../acoes/atendimentos-nao-assinados'
import { recuperarVersaoDoTipoDeAtendimento } from '../../../acoes/prontuario/tipos-de-atendimento'
import { recuperarCertificadosEmNuvemDoUsuario, recuperarSessaoComCertificadoEmNuvem } from '../../../acoes/lacuna'
import { LISTAR_ATENDIMENTOS_NAO_ASSINADOS } from '../../../acoes/tipos'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'

export default function Lista(props) {
  const { paginaDeDados, history, iniciarEmLote } = props

  const dispatch = useDispatch()
  const { usuario } = useUsuarioLogado()

  const [origem, setOrigem] = useState('naoassinados')
  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(iniciarEmLote ? iniciarEmLote : paginaDeDados.pagina)
  const [exibirFinalizarAtendimento, setExibirFinalizarAtendimento] = useState(false)
  const [exibirAssinaturaComCertificadoFisico, setExibirAssinaturaComCertificadoFisico] = useState(false)
  const [exibirAssinaturaComCertificadoEmNuvem, setExibirAssinaturaComCertificadoEmNuvem] = useState(false)
  const [exibirAssinaturaEmLote, setExibirAssinaturaEmLote] = useState(false)
  const [atendimento, setAtendimento] = useState({})
  const [secoes, setSecoes] = useState()

  useEffect(() => {
    (async () => await dispatch(listarAtendimentosNaoAssinados(pagina, ordenacao)))()
  }, [pagina, ordenacao, dispatch])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const abrirAssinarAtendimento = async (item) => {
    setOrigem('naoassinados')
    const versao = await dispatch(recuperarVersaoDoTipoDeAtendimento(item.tipo.identificador, item.tipo.versao))

    setAtendimento(item)
    setSecoes(versao.secoes)
    setExibirFinalizarAtendimento(true)
  }

  function fecharAssinarAtendimento() {
    setExibirFinalizarAtendimento(false)
  }

  const abrirAssinaturaComCertificadoFisico = async () => {
    await dispatch(assinarAtendimentoFinalizado(atendimento.paciente.identificador, atendimento))
    setExibirAssinaturaComCertificadoFisico(true)
  }

  const abrirAssinaturaComCertificadoEmNuvem = async emLote => {
    await dispatch(assinarAtendimentoFinalizado(emLote ? '' : atendimento.paciente?.identificador, emLote ? {} : atendimento))
    setExibirAssinaturaComCertificadoEmNuvem(true)
  }

  function fecharAssinaturaDigital() {
    fecharAssinarAtendimento()
    setExibirAssinaturaComCertificadoFisico(false)
    setExibirAssinaturaComCertificadoEmNuvem(false)
    dispatch(listarAtendimentosNaoAssinados(pagina, ordenacao))
  }

  function recuperarCertificadosEmNuvem() {
    dispatch(recuperarCertificadosEmNuvemDoUsuario())
  }

  const iniciarAssinaturaEmLote = async () => {
    const sessaoIniciada = await dispatch(recuperarSessaoComCertificadoEmNuvem())

    if (sessaoIniciada) {
      setExibirAssinaturaEmLote(true)

    } else {
      setOrigem(iniciarEmLote ? iniciarEmLote : paginaDeDados.pagina)
      setAtendimento({})
      abrirAssinaturaComCertificadoEmNuvem(true)
    }
  }

  function fecharAssinaturaDigitalEmLote() {
    setExibirAssinaturaEmLote(false)
    history.push('/atendimentos/nao-assinados')
    dispatch(listarAtendimentosNaoAssinados())
  }

  useEffect(() => {
    if (iniciarEmLote && usuario.temSessaoEmAberto && paginaDeDados.dados?.length > 0) {
      setExibirAssinaturaEmLote(true)
    }
  }, [iniciarEmLote, paginaDeDados.dados, usuario.temSessaoEmAberto])

  return (
    <Spinner operacoes={[LISTAR_ATENDIMENTOS_NAO_ASSINADOS]}>
      {({ processando }) => (
        <div className='page-configuracoes-agendamento'>
          <Modal
            isOpen={exibirFinalizarAtendimento}
            className='modal modal-finalizar-atendimento'
            contentLabel='Modal para finalização do atendimento'
          >
            <Resumo
              atendimento={atendimento}
              secoes={secoes}
              assinarAtendimento={true}
              fecharModal={fecharAssinarAtendimento}
              abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
              abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
              recuperarCertificadosEmNuvemDoUsuario={recuperarCertificadosEmNuvem}
            />
          </Modal>
          <Modal
            isOpen={exibirAssinaturaComCertificadoFisico}
            className='modal'
            contentLabel='Modal para Assinatura Digital'
          >
            <AssinaturaComCertificadoFisico
              fecharModal={fecharAssinaturaDigital}
            />
          </Modal>
          <Modal
            isOpen={exibirAssinaturaComCertificadoEmNuvem}
            className='modal'
            contentLabel='Modal para Assinatura Digital'
          >
            <AssinaturaComCertificadoEmNuvem
              fecharModal={fecharAssinaturaDigital}
              origem={origem}
            />
          </Modal>
          <Modal
            isOpen={exibirAssinaturaEmLote}
            className='modal'
            contentLabel='Modal para assinatura de atendimentos em lote'
          >
            <AssinaturaComCertificadoEmNuvemEmLote
              fecharModal={fecharAssinaturaDigitalEmLote}
              dados={paginaDeDados.dados}
            />
          </Modal>
          <div className='container'>
            <div className='main-content-form'>
              <div className='header-page-form'>
                <Breadcrumb />
                {paginaDeDados?.dados?.length > 0 &&
                  <div className='header-page-tools'>
                    <div className='header-page-tools'>
                      <button
                        className={`button --primary ${processando && 'is-loading'}`}
                        onClick={iniciarAssinaturaEmLote}>
                        Assinar {paginaDeDados.dados.length} {paginaDeDados.dados.length === 1 ? 'atendimento' : 'atendimentos'}
                      </button>
                    </div>
                  </div>
                }
              </div>
              <Tabela
                acoes={[
                  item => (
                    <Permissao key='alterar' permissoes={[permissoes.ASSINAR_ATENDIMENTO]}>
                      <button
                        className='table-items__bt --edit'
                        title='Assinar'
                        type='button'
                        onClick={() => abrirAssinarAtendimento(item)}
                      >
                        <i className='icon icon-checked'></i>
                      </button>
                    </Permissao>
                  )
                ]}
                chave='identificador'
                className='mt-2 mb-4'
                listar={listar}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                processando={processando}
              >
                <Coluna
                  className='col-xs-8 col-md-10 col-lg-6'
                  principal={true}
                  exibirOrdenacao={true}
                  nome='Paciente'
                  campoDeDados='paciente.nome'
                  renderizar={item => item.paciente.nome}
                />
                <Coluna
                  nome='Tipo'
                  campoDeDados='tipo.tipo.nome'
                  exibirOrdenacao={true}
                  className='col-lg-3'
                  renderizar={item => item.tipo.nome}
                />
                <Coluna
                  nome='Data'
                  className='col-lg-2'
                  exibirOrdenacao={true}
                  campoDeDados='inicio'
                  renderizar={item => formatarDataEHoraParaFormatoLocal(item.inicio)}
                />
              </Tabela>
            </div>
          </div>
        </div>
      )}
    </Spinner>
  )
}