import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_STATUS_DOS_AGENDAMENTOS,
  LISTOU_STATUS_DOS_AGENDAMENTOS,
  ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS,
} from '../tipos'

const listouStatusDosAgendamentos = statusDosAgendamentos => ({
  type: LISTOU_STATUS_DOS_AGENDAMENTOS,
  statusDosAgendamentos
})

const erroAoListarStatusDosAgendamentos = erro => ({
  type: ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS,
  erro
})

export const listarStatusDosAgendamentos = () => async dispatch => {
  dispatch({ type: LISTAR_STATUS_DOS_AGENDAMENTOS })

  try {
    const resultado = await api.listarStatusDosAgendamentos()

    dispatch(listouStatusDosAgendamentos(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarStatusDosAgendamentos(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os status dos agendamentos.')))
  }
}