import React, { useEffect } from 'react'
import avatar from '../../design/img/no-thumb.png'

export default function Cabecalho(props) {
  const {
    children,
    fotos,   
    identificador,
    paciente = {},
    recuperarPorIdentificador,
  } = props

  useEffect(() => {
    if (!paciente.identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [paciente.identificador, identificador, recuperarPorIdentificador])

  return (
    <div className='user-paciente mt-2'>
      <img
        alt={paciente.nome}
        className='table-items-expanded__avatar'
        src={(paciente.foto && fotos[paciente.foto]) || paciente.bytesDaFoto || avatar}
      />
      <p>{paciente.nome}</p>
      {children}
    </div>
  )
}