import React, { createContext, useContext } from 'react'

const ContextoDeAutenticacao = createContext()

export const ProvedorDeAutenticacao = ({ usuario, logout, adicionarNotificacao, children }) => {
  return <ContextoDeAutenticacao.Provider value={{ usuario, logout, adicionarNotificacao }}>{children}</ContextoDeAutenticacao.Provider>
}

export const useUsuarioLogado = () => {
  return useContext(ContextoDeAutenticacao)
}