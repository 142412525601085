import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_DATA_DA_TAREFA,
  DEFINIU_DATA_DA_TAREFA,
  ERRO_AO_DEFINIR_DATA_DA_TAREFA
} from '../tipos'

const definiuDataDaTarefa = (identificadorDaTarefa, campo, conteudo) => ({
  type: DEFINIU_DATA_DA_TAREFA,
  identificadorDaTarefa,
  campo,
  conteudo,
})

const erroAoDefinirDataDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_DATA_DA_TAREFA,
  erro,
  parametros,
})

export const definirDataDaTarefa = (identificadorDaTarefa, campo, conteudo) => async dispatch => {
  dispatch({ type: DEFINIR_DATA_DA_TAREFA })

  try {
    const resultado = await api.definirDataDaTarefa(identificadorDaTarefa, campo, conteudo)

    dispatch(definiuDataDaTarefa(identificadorDaTarefa, campo, conteudo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirDataDaTarefa(erro, { identificadorDaTarefa, campo, conteudo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir a data da tarefa.')))

    return false
  }
}