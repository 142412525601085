import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA
} from '../tipos'

const listouCelulasDoRelatorioDePacientesDoPrograma = celulas => ({
  type: LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  celulas
})

const erroAoListarCelulasDoRelatorioDePacientesDoPrograma = erro => ({
  type: ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  erro
})

export const listarCelulasDoRelatorioDePacientesDoPrograma = () => async dispatch => {
  dispatch({ type: LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA })

  try {
      const resultado = await api.listarCelulas()

      dispatch(listouCelulasDoRelatorioDePacientesDoPrograma(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
      dispatch(erroAoListarCelulasDoRelatorioDePacientesDoPrograma(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as células do relatório de pacientes do programa.')))
  }
}

const listouProgramasDoRelatorioDePacientesDoPrograma = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  programas
})

const erroAoListarProgramasDoRelatorioDePacientesDoPrograma = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  erro
})

export const listarProgramasDoRelatorioDePacientesDoPrograma = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDePacientesDoPrograma(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDePacientesDoPrograma(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os programas do relatório de pacientes do programa.')))
  }
}

const listouMedicosDoRelatorioDePacientesDoPrograma = medicos => ({
  type: LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  medicos
})

const erroAoListarMedicosDoRelatorioDePacientesDoPrograma = erro => ({
  type: ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  erro
})

export const listarMedicosDoRelatorioDePacientesDoPrograma = () => async dispatch => {
  dispatch({ type: LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA })

  try {
    const resultado = await api.listarMedicos()

    dispatch(listouMedicosDoRelatorioDePacientesDoPrograma(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMedicosDoRelatorioDePacientesDoPrograma(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os médicos do relatório de pacientes do programa.')))
  }
}

const listouEnfermeirosDoRelatorioDePacientesDoPrograma = enfermeiros => ({
  type: LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  enfermeiros
})

const erroAoListarEnfermeirosDoRelatorioDePacientesDoPrograma = erro => ({
  type: ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  erro
})

export const listarEnfermeirosDoRelatorioDePacientesDoPrograma = () => async dispatch => {
  dispatch({ type: LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA })

  try {
    const resultado = await api.listarEnfermeiros()
    dispatch(listouEnfermeirosDoRelatorioDePacientesDoPrograma(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEnfermeirosDoRelatorioDePacientesDoPrograma(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar enfermeiro do relatório de pacientes do programa.')))
  }
}

const fezDownloadDePacientesDoPrograma = filtros => ({
  type: FEZ_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  filtros
})

const erroAoFazerDownloadDePacientesDoPrograma = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  erro,
  parametros
})

export const fazerDownloadDePacientesDoPrograma = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA })

  try {
    const resultado = await api.fazerDownloadDePacientesDoPrograma(filtros)

    dispatch(fezDownloadDePacientesDoPrograma(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDePacientesDoPrograma(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do relatório de pacientes do programa.')))

    return null
  }
}

export const limparFiltrosDoRelatorioDePacientesDoPrograma = () => ({ type: LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA })