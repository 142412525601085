import React, { useEffect, useState } from 'react'
import Breadcrumb from '../breadcrumb'
import { Link } from 'react-router-dom'
import CampoDePesquisa from '../campo-de-pesquisa'
import DicaDePesquisa from '../dica-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, ColunaFoto, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../tabela'
import { LISTAR_USUARIOS, RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR } from '../../acoes/tipos'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

export default function Lista(props) {
  const {
    fotos,
    paginaDeDados,
    pesquisar,
    recuperarFotoOriginalDoUsuarioPorIdentificador
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros.ativo.length) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = nome => alterarFiltros({ ...filtros, nome })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
              <r-cell span={4} span-md={2} span-lg={6}>
                <Breadcrumb />
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={2}>
                <Permissao permissoes={[permissoes.ADICIONAR_USUARIO]}>
                  <Link
                    to={`/configuracoes/usuarios/novo`}
                    className='w-100 button --primary --plus'
                  >
                    Adicionar
                  </Link>
                </Permissao>
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <DicaDePesquisa
                  className='form-search'
                  dica='A pesquisa é realizada em todas as colunas.'
                  exemplo='Médico cardiologia'
                >
                  <CampoDePesquisa pesquisar={alterarTermo} valor={filtros ? filtros.nome : ''} />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_USUARIOS, RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_USUARIO, permissoes.ALTERAR_CONFIGURACAO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
                    <Link
                      to={`/configuracoes/usuarios/${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </Link>
                  </Permissao>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              expandida={true}
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                className='col-xs-2 col-md-1'
                foto={true}
                fotos={fotos}
                identificadorDaFoto={item => item.foto}
                recuperarFotoOriginal={item => recuperarFotoOriginalDoUsuarioPorIdentificador(item.identificador)}
              />
              <Coluna
                campoDeDados='nome'
                className='col-xs-6 col-lg-3'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-2'
                nome='Profissão'
                campoDeDados='profissao'
                renderizar={item => item.profissao ? item.profissao.nome : ''}
              />
              <Coluna
                className='col-lg-3'
                nome='Especialidade'
                campoDeDados='especialidades'
                renderizar={item => item.profissao ? item.profissao.especialidades.join(', ') : ''}
              />
              <Coluna
                campoDeDados='ativo'
                className='col-lg-2'
                filtros={['Ativo', 'Inativo']}
                nome='Status'
                renderizar={item => item.ativo ? 'Ativo' : 'Inativo'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-9'>
                <ColunaFoto
                  className='pr-0 col-xs-2 col-md-1'
                  nome='foto'
                  fotos={fotos}
                  identificadorDaFoto={item => item.foto}
                  recuperarFotoOriginal={item => recuperarFotoOriginalDoUsuarioPorIdentificador(item.identificador)}
                />
                <AgrupamentoDeColunas>
                  <Coluna
                    campoDeDados='nome'
                    icone='icon-name'
                    nome='NOME'
                  />
                  <Coluna
                    icone='icon-doctor'
                    nome='PROFISSÃO'
                    renderizar={item => item.profissao && item.profissao.nome}
                  />
                  <Coluna
                    icone='icon-especialidade'
                    nome='ESPECIALIDADE(S)'
                    renderizar={item => item.profissao && item.profissao.especialidades.join(', ')}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='CONSELHO'
                    renderizar={item => item.profissao && item.profissao.conselho && `${item.profissao.conselho.nome} - ${item.profissao.conselho.numeroDoRegistro}`}
                  />
                  <Coluna
                    campoDeDados='telefone1'
                    icone='icon-phone'
                    nome='TELEFONE'
                  />
                  <Coluna
                    campoDeDados='telefone2'
                    icone='icon-phone'
                    nome='TELEFONE'
                  />
                  <Coluna
                    campoDeDados='email'
                    icone='icon-mail'
                    nome='E-MAIL'
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}