import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  LISTOU_TIPOS_DE_CONSULTA_EXTERNA,
  ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
} from './tipos'

const listouTiposDeConsultaExterna = tiposDeConsultaExterna => ({
  type: LISTOU_TIPOS_DE_CONSULTA_EXTERNA,
  tiposDeConsultaExterna,
})

const erroAoListarTiposDeConsultaExterna = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  erro,
})

export const listarTiposDeConsultaExterna = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_CONSULTA_EXTERNA })

    try {
      const resultado = await api.listarTiposDeConsultaExterna()

      dispatch(listouTiposDeConsultaExterna(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTiposDeConsultaExterna(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de consulta externa.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TIPOS_DE_CONSULTA_EXTERNA
  }

  return acao
}