import React, { memo } from 'react'
import ReactTooltip from 'react-tooltip'
import { CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { ehVerdadeiro } from '../../../../bibliotecas/texto'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'

function PressaoArterial({ metadados, historico = [], valor, leitura, validacaoAoFinalizar = false }) {
  const algumCampoInformado = valor && (valor.pressaoArterialSistolica || valor.pressaoArterialDiastolica)
  const obrigatorio = ehVerdadeiro(metadados?.obrigatorio) || algumCampoInformado
  const obrigatorioAoFinalizar = ehVerdadeiro(metadados?.obrigatorio_ao_finalizar)

  const validarSistolica = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 1, 300) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 1, 300)
  }

  const validarDiastolica = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 1, 300) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 1, 300)
  }

  if (leitura) {
    if (!valor && obrigatorioAoFinalizar) return <li>Pressão Arterial é obrigatória ao finalizar o atendimento.</li>
    if (!valor || validacaoAoFinalizar) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Pressão Arterial - Sentado: </strong>
            {valor.pressaoArterialSistolica}/{valor.pressaoArterialDiastolica}
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={6} span-lg={6}>
      <r-grid columns-md={6} columns-lg={6}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <ReactTooltip
            id='pressaoArterialSistolica'
            place='top'
            effect='solid'
            key='pressaoArterialSistolica'
            type='info'
          >
            <span>Sentado</span>
          </ReactTooltip>
          <label data-tip data-for='pressaoArterialSistolica' className='form-label'>{`Pressão Arterial Sistólica ${obrigatorioAoFinalizar ? '**' : (obrigatorio ? '*' : '')}`}</label>
          <CampoNumerico
            nome='pressaoArterial.pressaoArterialSistolica'
            escala='mmHg'
            validar={validarSistolica}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Pressão Arterial'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        <r-cell span={4} span-md={2} span-lg={2}>
          <ReactTooltip
            id='pressaoArterialDiastolica'
            place='top'
            effect='solid'
            key='pressaoArterialDiastolica'
            type='info'
          >
            <span>Sentado</span>
          </ReactTooltip>
          <label data-tip data-for='pressaoArterialDiastolica' className='form-label'>{`Pressão Arterial Diastólica ${obrigatorioAoFinalizar ? '**' : (obrigatorio ? '*' : '')}`}</label>
          <CampoNumerico
            nome='pressaoArterial.pressaoArterialDiastolica'
            escala='mmHg'
            validar={validarDiastolica}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Pressão Arterial'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
      </r-grid>
    </r-cell>
  )
}

export default memo(PressaoArterial)