import { LISTOU_TIPOS_DE_HORARIOS } from '../../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_HORARIOS: {
      return action.tiposDeHorarios
    }

    default: {
      return state
    }
  }
}