import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Cabecalho from './cabecalho'
import Spinner from '../spinner'
import { Formulario as Form, Select } from '../formik/formulario'
import { confirmAlert } from 'react-confirm-alert'
import { ordemDoServidor } from '../../bibliotecas/ordenacao'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {
  LISTAR_UNIDADES_DO_USUARIO,
  LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  ADICIONAR_UNIDADE_DO_USUARIO,
  REMOVER_UNIDADE_DO_USUARIO
} from '../../acoes/tipos'

export default function Unidades(props) {
  const {
    adicionarUnidadeDoUsuario,
    fotos,
    history,
    listarEmpresasHabilitadas,
    listarUnidadesDaEmpresaDoUsuario,
    listarUnidadesDoUsuario,
    match,
    recuperarPorIdentificador,
    removerUnidadeDoUsuario,
    unidades: {
      unidades,
      paginaDeDados,
      habilitadas
    },
    usuario = {},
  } = props

  const { identificador } = match.params
  const [selecionado, setSelecionado] = useState('')
  const podeHabilitarUnidade = usePossuiAsPermissoes([permissoes.HABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO])
  const podeDesabilitarUnidade = usePossuiAsPermissoes([permissoes.DESABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO])

  useEffect(() => {
    const listar = async () => {
      if (identificador) {
        await listarUnidadesDaEmpresaDoUsuario()
        listarUnidadesDoUsuario(identificador)
      }
    }

    listar()
  }, [listarUnidadesDaEmpresaDoUsuario, listarUnidadesDoUsuario, identificador])

  const recuperUnidadesDoUsuario = dados => ({
    dados: Object.keys(dados)
      .map(key => dados[key])
      .filter(x => habilitadas.findIndex(i => i === x.identificador) === -1)
      .sort(ordemDoServidor)
      .map(x => ({ identificador: x.identificador, nome: x.nome }))
  })

  const unidadesDisponiveisParaOUsuario = unidades && recuperUnidadesDoUsuario(unidades)

  const adicionar = async () => {
    const adicionou = await adicionarUnidadeDoUsuario(identificador, selecionado)

    if (adicionou) {
      setSelecionado('')
      listarUnidadesDoUsuario(identificador)
      listarEmpresasHabilitadas()
    }
  }

  const removerSelecionado = async item => {
    const removeu = await removerUnidadeDoUsuario(identificador, item.identificador)

    if (removeu) {
      setSelecionado('')
      listarUnidadesDoUsuario(identificador)
      listarEmpresasHabilitadas()
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a unidade: ${item.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[LISTAR_UNIDADES_DO_USUARIO, LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO, ADICIONAR_UNIDADE_DO_USUARIO, REMOVER_UNIDADE_DO_USUARIO]}>
      <Form
        valoresIniciais={{
          unidades: selecionado ? selecionado : ''
        }}
        acao={adicionar}
        reinicializar={true}
      >
        <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
        <div className='mt-2 mb-4'>
          <div>
            <fieldset>
              <h2 className='form-title'>{podeHabilitarUnidade ? 'Adicionar Unidade' : 'Unidade'}</h2>
              <r-grid columns-md={6} columns-lg={12} class='align-end'>
                {podeHabilitarUnidade &&
                  <>
                    <r-cell span={4} span-md={4} span-lg={5}>
                      <Select
                        nome='unidades'
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        itens={unidadesDisponiveisParaOUsuario.dados}
                        tabIndex={1}
                        titulo='Unidade *'
                        onChange={setSelecionado}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg={2}>
                      <button
                        type='submit'
                        className={!selecionado ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                        disabled={!selecionado}
                      >
                        Adicionar
                      </button>
                    </r-cell>
                  </>
                }
                <r-cell span={4} span-md={6} span-lg={'1-7'} >
                  {paginaDeDados.dados &&
                    paginaDeDados.dados.map((unidade, index) => (
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item'>{unidade.nome}</div>
                        {unidade.observacoes &&
                          <div className='form-choice__item'>
                            <span className='form-choice__toggle'>Observações
                              <i
                                className='icon icon-arrow-down'
                                data-tip
                                data-for={`info_${unidade.identificador}`}
                                style={{ cursor: 'pointer' }}
                                key={`info_${unidade.identificador}`}>
                              </i>
                            </span>
                            <ReactTooltip
                              id={`info_${unidade.identificador}`}
                              place='bottom'
                              effect='solid'
                              type='info'
                              key={unidade.identificador}
                            >
                              <span>{unidade.observacoes}</span>
                            </ReactTooltip>
                          </div>
                        }
                        {podeDesabilitarUnidade && <button className='form-choice__bt-close' type='button' aria-label='Fechar' onClick={() => confirmarERemover(unidade)}><i className='icon icon-close'></i></button>}
                      </div>
                    ))}
                </r-cell>
              </r-grid>
            </fieldset>
            <div className='list-btn'>
              <button
                className='button --light'
                type='button'
                onClick={() => history.push(`/configuracoes/usuarios`)}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Spinner>
  )
}