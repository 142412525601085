import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  LISTAR_ENCAMINHAMENTOS,
  LISTOU_ENCAMINHAMENTOS,
  ERRO_AO_LISTAR_ENCAMINHAMENTOS,
  LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  LISTOU_TIPOS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  LISTAR_STATUS_DE_ENCAMINHAMENTO,
  LISTOU_STATUS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO,
  ALTERAR_STATUS_DO_ENCAMINHAMENTO,
  ALTEROU_STATUS_DO_ENCAMINHAMENTO,
  ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO
} from './tipos'

const listouEncaminhamentos = (filtros, pagina, paginaDeDados, ordenacao) => ({
  type: LISTOU_ENCAMINHAMENTOS,
  filtros,
  pagina,
  paginaDeDados, 
  ordenacao
})

const erroAoListarEncaminhamentos = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ENCAMINHAMENTOS,
  erro,
  parametros
})

export const listarEncaminhamentos = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_ENCAMINHAMENTOS })

  try {
    const resultado = await api.listarEncaminhamentos(filtros, pagina, ordenacao)

    dispatch(listouEncaminhamentos(filtros, pagina, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEncaminhamentos(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os encaminhamentos.')))

  }
}

const listouTiposDeEncaminhamento = tiposDeEncaminhamento => ({
  type: LISTOU_TIPOS_DE_ENCAMINHAMENTO,
  tiposDeEncaminhamento
})

const erroAoListarTiposDeEncaminhamento = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  erro
})

export const listarTiposDeEncaminhamento = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_ENCAMINHAMENTO })

  try {
    const resultado = await api.listarTiposDeEncaminhamento()

    dispatch(listouTiposDeEncaminhamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarTiposDeEncaminhamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de encaminhamento.')))
  }
}

const listouStatusDeEncaminhamento = statusDeEncaminhamento => ({
  type: LISTOU_STATUS_DE_ENCAMINHAMENTO,
  statusDeEncaminhamento
})

const erroAoListarStatusDeEncaminhamento = erro => ({
  type: ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO,
  erro
})

export const listarStatusDeEncaminhamento = () => async dispatch => {
  dispatch({ type: LISTAR_STATUS_DE_ENCAMINHAMENTO })

  try {
    const resultado = await api.listarStatusDeEncaminhamento()

    dispatch(listouStatusDeEncaminhamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarStatusDeEncaminhamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os status de encaminhamento.')))
  }
}

const alterouStatusDoEncaminhamento = encaminhamento => ({
  type: ALTEROU_STATUS_DO_ENCAMINHAMENTO,
  encaminhamento
})

const erroAoAlterarStatusDoEncaminhamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_ENCAMINHAMENTO,
  erro,
  parametros
})

export const alterarStatusDoEncaminhamento = encaminhamento => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_ENCAMINHAMENTO })

  try {
    const resultado = await api.alterarStatusDoEncaminhamento(encaminhamento)

    dispatch(alterouStatusDoEncaminhamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoEncaminhamento(erro, { encaminhamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o status do encaminhamento.')))

    return false
  }
}