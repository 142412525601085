import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  LISTOU_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  ERRO_AO_LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
} from './tipos'

const listouMotivosDeInativacaoDoPaciente = motivos => ({
  type: LISTOU_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  motivos,
})

const erroAoListarMotivosDeInativacaoDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  erro,
})

export const listarMotivosDeInativacaoDoPaciente = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE })

    try {
      const resultado = await api.listarMotivosDeInativacaoDoPaciente()

      dispatch(listouMotivosDeInativacaoDoPaciente(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarMotivosDeInativacaoDoPaciente(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de inativação do paciente.')))
    }
  }

  acao.meta = {
    cache: LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE
  }

  return acao
}