import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import moment from 'moment'
import Breadcrumb from '../../breadcrumb'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, Select, Input } from '../../formik/formulario'
import { Coluna, Tabela } from '../../tabela'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import ConfirmarChegada from './confirmar-chegada'
import InformarSaida from './informar-saida'
import AlterarAgendamento from '../confirmar-agendamento/visualizar-agendamento'
import DicaDePesquisa from '../../dica-de-pesquisa'

import { LISTAR_AGENDAMENTOS_DO_DIA } from '../../../acoes/tipos'

export default function Lista(props) {
  const {
    agendamento,
    confirmarChegadaDoPaciente,
    cancelarAgendamento,
    excluirAgendamento,
    fotos,
    identificadorDoUsuarioLogado,
    informarSaidaDoPaciente,
    match,
    motivosDeCancelamento,
    motivosParaNaoTerSidoAtendido,
    paginaDeDados,
    pesquisar,
    profissional,
    profissionais,
    recuperarAgendamentoPeloIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    unidadeDoUsuario
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [exibirAgendamento, setExibirAgendamento] = useState(false)
  const [exibirConfirmacaoDeChegada, setExibirConfirmacaoDeChegada] = useState(false)
  const [exibirInformarSaida, setExibirInformarSaida] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState('')
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [exibirCancelar, setExibirCancelar] = useState(false)
  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_CHEGADA_DO_PACIENTE])
  const podeInformarSaida = usePossuiAsPermissoes([permissoes.REGISTRAR_SAIDA_DO_PACIENTE_SEM_SER_ATENDIDO])
  const podePesquisar = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE])

  const estiloDoModal = {
    content: {
      height: exibirCancelar || exibirExcluir ? '95%' : '',
    }
  }

  const [filtros, setFiltros] = useState({
    ...props.filtros,
    unidade: unidadeDoUsuario
  })

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pagina, ordenacao, pesquisar])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const aplicarFiltros = formulario => {
    alterarFiltros({
      ...filtros,
      profissionais: formulario.profissionais,
      termo: formulario.paciente
    })
  }

  function abrirAlterar(agendamentoSelecionado) {
    const recuperar = async () => {
      const recuperou = await recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)

      if (recuperou) {
        setAgendamentoSelecionado(agendamentoSelecionado)
        setExibirAgendamento(true)
      }
    }
    recuperar()
  }

  function fecharAlterar() {
    setExibirAgendamento(false)
    setExibirCancelar(false)
    setExibirExcluir(false)
    pesquisar(filtros, pagina, ordenacao)
  }

  function abrirConfirmar(agendamentoSelecionado) {
    const recuperar = async () => {
      const recuperou = await recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)

      if (recuperou)
        setExibirConfirmacaoDeChegada(true)
    }
    recuperar()
  }

  function fecharConfirmar() {
    setExibirConfirmacaoDeChegada(false)
  }

  function abrirInformarSaida(agendamentoSelecionado) {
    const recuperar = async () => {
      const recuperou = await recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)

      if (recuperou)
        setExibirInformarSaida(true)
    }
    recuperar()
  }

  function fecharInformarSaida() {
    setExibirInformarSaida(false)
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <Modal
        isOpen={exibirAgendamento}
        style={estiloDoModal}
        className='modal'
        contentLabel='Modal para visualização do agendamento'
      >
        <AlterarAgendamento
          agendamento={agendamento}
          agendamentoSelecionado={agendamentoSelecionado}
          cancelarAgendamento={cancelarAgendamento}
          excluirAgendamento={excluirAgendamento}
          fotos={fotos}
          identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
          match={match}
          motivosDeCancelamento={motivosDeCancelamento}
          fechar={fecharAlterar}
          profissional={profissional}
          recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
          selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
          selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
          setExibirCancelar={setExibirCancelar}
          exibirCancelar={exibirCancelar}
          setExibirExcluir={setExibirExcluir}
          exibirExcluir={exibirExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirConfirmacaoDeChegada}
        className='modal'
        contentLabel='Modal para confirmação de chegada'
      >
        <ConfirmarChegada
          fechar={fecharConfirmar}
          confirmarChegadaDoPaciente={confirmarChegadaDoPaciente}
          agendamento={agendamento}
          fotos={fotos}
          pesquisar={pesquisar}
          filtros={filtros}
          match={match}
          pagina={pagina}
          ordenacao={ordenacao}
        />
      </Modal>
      <Modal
        isOpen={exibirInformarSaida}
        className='modal'
        contentLabel='Modal para confirmação de chegada'
      >
        <InformarSaida
          fechar={fecharInformarSaida}
          informarSaidaDoPaciente={informarSaidaDoPaciente}
          motivosParaNaoTerSidoAtendido={motivosParaNaoTerSidoAtendido}
          agendamento={agendamento}
          fotos={fotos}
          pesquisar={pesquisar}
          filtros={filtros}
          pagina={pagina}
          ordenacao={ordenacao}
        />
      </Modal>
      <ReactTooltip
        id={`info_${agendamento.identificador}`}
        place='right'
        effect='solid'
        type='info'
        key={agendamento.identificador}
        className='unity-infos__item'
      >
        <div
          style={{
            marginBottom: 8,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'rgb(232, 232, 232)'
          }}><strong>Histórico do Status</strong></div>
        {agendamento && agendamento.status.map(x =>
          <span key={x.dataEHora}>
            <span><p><strong>{x.nome}</strong></p></span>
            <span><p>{x.observacoes}</p></span>
            <span><em>{x.usuario.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(x.dataEHora)}</em></span>
          </span>
        )}
      </ReactTooltip>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <FormularioDoFormik
            acao={aplicarFiltros}
            valoresIniciais={{
              profissionais: filtros.profissionais,
              paciente: filtros.termo,
            }}
          >
            <div className='mt-1'>
              <r-grid columns-md={12} columns-lg={12}>
                <r-cell span={4} span-md={5} span-lg={5}>
                  <DicaDePesquisa
                    dica='A pesquisa é realizada por nome ou data de nascimento.'
                    exemplo='José 01/04/1960'
                  >
                    <Input
                      nome='paciente'
                      titulo='Paciente'
                      placeholder='Informe o nome ou data de nascimento do paciente'
                      tabIndex={3}
                    />
                  </DicaDePesquisa>
                </r-cell>
                <r-cell span={4} span-md={5} span-lg={5}>
                  <Select
                    nome='profissionais'
                    titulo='Profissional'
                    itens={profissionais}
                    campoCodigo='identificador'
                    campoDescricao='nome'
                    tabIndex={4}
                    isMulti
                  />
                </r-cell>
                <r-cell span={4} span-md={2} span-lg={2}>
                  {podePesquisar && <button className='button --primary w-100 minw-auto alinha-botao' type='submit'>Pesquisar</button>}
                </r-cell>
              </r-grid>
            </div>
          </FormularioDoFormik>
          <Spinner operacoes={[LISTAR_AGENDAMENTOS_DO_DIA]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar'
                    permissoes={[permissoes.ALTERAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
                    permissoes.CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
                    permissoes.REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]}
                  >
                    <button
                      className='table-items__bt --edit'
                      title='Editar'
                      type='button'
                      onClick={() => abrirAlterar(item)}
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ),
                item => {
                  const permitidoConfirmar = item.status[0].nome &&
                    (item.status[0].nome === 'Agendado' || item.status[0].nome === 'Reagendado' || item.status[0].nome === 'Confirmado')
                  return (permitidoConfirmar && podeConfirmar && (
                    <button
                      className='table-items__bt --edit'
                      title='Confirmar'
                      type='button'
                      key='confirmar'
                      onClick={() => abrirConfirmar(item)}
                    >
                      <i className='icon icon-checked'></i>
                    </button>
                  ))
                },
                item => {
                  const permitidoInformarSaida = item.status[0].nome && item.status[0].nome === 'Aguardando atendimento'
                  return (permitidoInformarSaida && podeInformarSaida && (
                    <button
                      className='table-items__bt --remove'
                      title='Informar Saída'
                      type='button'
                      key='informarSaida'
                      onClick={() => abrirInformarSaida(item)}
                    >
                      <i className='icon icon-block estilo-block'></i>
                    </button>
                  ))
                }
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
              filtros={filtros}
              marcacaoDeErro={item => item.bloqueado && item.status[0].nome !== 'Cancelado'}
            >
              <Coluna
                className='col-xs-2 col-md-1 col-lg-1'
                foto={true}
                fotos={fotos}
                identificadorDaFoto={item => item.paciente.foto}
              />
              <Coluna
                className='col-xs-6 col-md-9 col-lg-2'
                principal={true}
                nome='Paciente'
                renderizar={item => item.paciente.nome}
              />
              <Coluna
                className='col-lg-1'
                nome='Nascimento'
                renderizar={item => item.paciente.dataDeNascimento && moment(item.paciente.dataDeNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              />
              <Coluna
                className='col-lg-2'
                nome='Profissional'
                renderizar={item => item.profissional.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Tipo'
                renderizar={item =>
                  <p className='table-items__status'>
                    <span className='table-items__status__bg' style={{ background: item.cor.codigo }}></span>
                    <span className='table-items__status__text'>{item.tipo}</span>
                  </p>
                }
              />
              <Coluna
                className='col-lg-2'
                nome='Status'
                renderizar={item => item.status[0].nome}
              />
              <Coluna
                className='col-lg-1'
                campoDeDados='inicio'
                nome='Data/Hora'
                exibirOrdenacao={true}
                renderizar={item => item.inicio && moment(item.inicio, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}