import {
  LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS
} from '../acoes/tipos'

const estadoInicial = {
  especialidadesMedicasInternas: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS: {
      return {
        ...state,
        especialidadesMedicasInternas: action.especialidades.dados
      }
    }

    default: {
      return state
    }
  }
}