import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA,
  LISTOU_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA,
  ERRO_AO_LISTAR_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA
} from '../tipos'

const listouSintomasAssociadosAoLocalDaDorMusculoesqueletica = sintomasDorMusculoesqueletica => ({
  type: LISTOU_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA,
  sintomasDorMusculoesqueletica
})

const erroAoListarSintomasAssociadosAoLocalDaDorMusculoesqueletica = erro => ({
  type: ERRO_AO_LISTAR_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA,
  erro
})

export const listarSintomasAssociadosAoLocalDaDorMusculoesqueletica = () => async dispatch => {
  dispatch({ type: LISTAR_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA })

  try {
    const resultado = await api.listarSintomasAssociadosAoLocalDaDorMusculoesqueletica()

    dispatch(listouSintomasAssociadosAoLocalDaDorMusculoesqueletica(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSintomasAssociadosAoLocalDaDorMusculoesqueletica(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os sintomas associados ao local da dor musculoesquelética.')))
  }
}