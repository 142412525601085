import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ASSOCIACOES,
  LISTOU_ASSOCIACOES,
  ERRO_AO_LISTAR_ASSOCIACOES,
  ADICIONOU_ASSOCIACAO,
  ERRO_AO_ADICIONAR_ASSOCIACAO,
  ADICIONAR_ASSOCIACAO,
  RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  ALTEROU_ASSOCIACAO,
  ERRO_AO_ALTERAR_ASSOCIACAO,
  ALTERAR_ASSOCIACAO,
  REMOVEU_ASSOCIACAO,
  ERRO_AO_REMOVER_ASSOCIACAO,
  REMOVER_ASSOCIACAO,
  LISTOU_ASSOCIACOES_DO_CADASTRO,
  LISTAR_ASSOCIACOES_DO_CADASTRO,
  ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO
} from './tipos'

const listouAssociacoes = associacoes => ({
  type: LISTOU_ASSOCIACOES,
  associacoes,
})

const erroAoListarAssociacoes = erro => ({
  type: ERRO_AO_LISTAR_ASSOCIACOES,
  erro,
})

export const listarAssociacoes = () => async dispatch => {
  dispatch({ type: LISTAR_ASSOCIACOES })

  try {
    const resultado = await api.listarAssociacoes()

    dispatch(listouAssociacoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAssociacoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as associações.')))
  }
}


const erroAoListarAssociacoesDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO,
  erro,
  parametros
})

const listouAssociacoesDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_ASSOCIACOES_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarAssociacoesDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_ASSOCIACOES_DO_CADASTRO })

  try {
    const resultado = await api.listarAssociacoes(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouAssociacoesDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAssociacoesDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as associações.')))
  }
}

const adicionouAssociacao = associacao => ({
  type: ADICIONOU_ASSOCIACAO,
  associacao
})

const erroAoAdicionarAssociacao = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ASSOCIACAO,
  erro,
  parametros
})

export const adicionarAssociacao = associacao => async dispatch => {
  dispatch({ type: ADICIONAR_ASSOCIACAO })

  try {
    const resultado = await api.adicionarAssociacao(associacao)

    dispatch(adicionouAssociacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAssociacao(erro, { associacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar a associação.')))

    return false
  }
}

const recuperouAssociacaoPeloIdentificador = associacao => ({
  type: RECUPEROU_ASSOCIACAO_PELO_IDENTIFICADOR,
  associacao
})

const erroAoRecuperarAssociacaoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarAssociacaoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarAssociacaoPeloIdentificador(identificador)

    dispatch(recuperouAssociacaoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarAssociacaoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a associação por identificador.')))
  }
}

const alterouAssociacao = associacao => ({
  type: ALTEROU_ASSOCIACAO,
  associacao
})

const erroAoAlterarAssociacao = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_ASSOCIACAO,
  erro,
  parametros
})

export const alterarAssociacao = associacao => async dispatch => {
  dispatch({ type: ALTERAR_ASSOCIACAO })

  try {
    const resultado = await api.alterarAssociacao(associacao)

    dispatch(alterouAssociacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarAssociacao(erro, { associacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a associação.')))

    return false
  }
}

const removeuAssociacao = associacao => ({
  type: REMOVEU_ASSOCIACAO,
  associacao
})

const erroAoRemoverAssociacao = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_ASSOCIACAO,
  erro,
  parametros
})

export const removerAssociacao = identificador => async dispatch => {
  dispatch({ type: REMOVER_ASSOCIACAO })

  try {
    const resultado = await api.removerAssociacao(identificador)

    dispatch(removeuAssociacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverAssociacao(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a associação.')))

    return false
  }
}