import api from './api-major'

const listarEncaminhamentos = (filtros = {}, pagina, ordenacao) => {
  const {
    inicio,
    fim,
    status,
    profissionalQueEncaminhou,
    profissionalEncaminhado,
    paciente,
    prioridade,
    tipo,
    tamanhoDaPagina
  } = filtros

  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+paciente.nome,+encaminhamento'
  const parametroTamanhoDaPagina = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'
  const parametroPaciente = paciente ? `&paciente=${paciente}` : ''
  const parametroQuemEncaminhou = profissionalQueEncaminhou ? `&encaminhadoPor=${profissionalQueEncaminhou}` : ''
  const parametroEncaminhadoPara = profissionalEncaminhado ? `&encaminhadoPara=${profissionalEncaminhado}` : ''
  const parametroPrioridade = `&priorizado=${prioridade}`
  const parametroStatus = status ? `&status=${status}` : ''
  const parametroInicio = inicio ? `&inicio=${inicio}` : ''
  const parametroFim = inicio ? `&fim=${fim}` : ''
  const parametroTipo = tipo ? `&tipo=${tipo}` : ''

  return api.get(`/encaminhamentos${paginacao}${parametroOrdem}${parametroTamanhoDaPagina}${parametroInicio}${parametroFim}${parametroTipo}${parametroPaciente}${parametroQuemEncaminhou}${parametroEncaminhadoPara}${parametroPrioridade}${parametroStatus}`)
}

const listarTiposDeEncaminhamento = () => api.get('/tipos-de-encaminhamento?pagina=1&tamanhoDaPagina=1000&ordem=nome')
const listarStatusDeEncaminhamento = () => api.get('/status-de-encaminhamento?pagina=1&tamanhoDaPagina=1000&ordem=nome')
const listarEspecialidadesMedicasInternas = () => api.get('/configuracoes/encaminhamentos/especialidades-medicas-internas')

const alterarStatusDoEncaminhamento = (encaminhamento) =>
  api.put(`/pacientes/${encaminhamento.identificadorDoPaciente}/prontuario/${encaminhamento.identificadorDoAtendimento}/encaminhamentos/${encaminhamento.identificadorDoEncaminhamento}/status`, encaminhamento)

const fazerDownloadDoEncaminhamento = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDoEncaminhamento, tamanhoDaPagina, adicionarData) => {
  tamanhoDaPagina = tamanhoDaPagina ? tamanhoDaPagina : 'A4'
  adicionarData = adicionarData ? true : false

  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/encaminhamentos/${identificadorDoEncaminhamento}?tamanhoDaPagina=${tamanhoDaPagina}&adicionarData=${adicionarData}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  alterarStatusDoEncaminhamento,
  fazerDownloadDoEncaminhamento,
  listarEncaminhamentos,
  listarEspecialidadesMedicasInternas,
  listarTiposDeEncaminhamento,
  listarStatusDeEncaminhamento,
}