import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  RECUPERAR_AVALIACAO,
  RECUPEROU_AVALIACAO,
  ERRO_AO_RECUPERAR_AVALIACAO,
  RESPONDER_AVALIACAO,
  RESPONDEU_AVALIACAO,
  ERRO_AO_RESPONDER_AVALIACAO
} from '../tipos'

const recuperouAvaliacao = avaliacao => ({
  type: RECUPEROU_AVALIACAO,
  avaliacao,
})

const erroAoRecuperarAvaliacao = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_AVALIACAO,
  erro,
  parametros
})

export const recuperarAvaliacao = (identificadorDaEmpresa, identificadorDaAvaliacao) => async dispatch => {
  dispatch({ type: RECUPERAR_AVALIACAO })

  try {
    const resultado = await api.recuperarAvaliacao(identificadorDaEmpresa, identificadorDaAvaliacao)

    dispatch(recuperouAvaliacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return 'sucesso'
  } catch (erro) {
    dispatch(erroAoRecuperarAvaliacao(erro, { identificadorDaEmpresa, identificadorDaAvaliacao }))

    if (erro?.data?.mensagens?.length > 0) {
      return erro.data.mensagens[0].texto
    }

    return false
  }
}

const respondeuAvaliacao = avaliacao => async dispatch => {
  dispatch({ type: RESPONDEU_AVALIACAO, avaliacao })
}

const erroAoResponderAvaliacao = (erro, parametros) => ({
  type: ERRO_AO_RESPONDER_AVALIACAO,
  erro,
  parametros
})

export const responderAvaliacao = (identificadorDaEmpresa, avaliacao) => async dispatch => {
  dispatch({ type: RESPONDER_AVALIACAO })

  try {
    const resultado = await api.responderAvaliacao(identificadorDaEmpresa, avaliacao)

    dispatch(respondeuAvaliacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoResponderAvaliacao(erro, { identificadorDaEmpresa, avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir a avaliação.')))

    return false
  }
}