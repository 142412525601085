import React from 'react'
import {DatePicker, Formulario as FormularioDoFormik, MultiplaEscolha} from '../../../../formik/formulario'
import {contemValor} from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_CASP_16_DO_PACIENTE,
  ALTERAR_CASP_16_DO_PACIENTE,
  LISTAR_RESPOSTAS_DO_CASP16
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const renderizarTitulo = valor => { return <>{valor.nome}<sup>{valor.valor}</sup> </> }

export default function Formulario({ respostasDoCasp16, selecionado, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  let valorCrescente = 0
  let valorDecrescente = 3
  const opcoesComValoresCrescentes = respostasDoCasp16.map(x => ({ codigo: x.codigo, nome: x.nome, valor: valorCrescente++ }))
  const opcoesComValoresDecrescentes = respostasDoCasp16.map(x => ({ codigo: x.codigo, nome: x.nome, valor: valorDecrescente-- }))

  return (
    <Spinner operacoes={[ADICIONAR_CASP_16_DO_PACIENTE, ALTERAR_CASP_16_DO_PACIENTE, LISTAR_RESPOSTAS_DO_CASP16]}>
      <FormularioDoFormik
        reinicializar={true}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar CASP-16' : 'Adicionar CASP-16'}</h2>
          <Data />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-casp-16'>
            <Cabecalho />
            <Controle opcoesComValoresCrescentes={opcoesComValoresCrescentes} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
            <Autonomia opcoesComValoresCrescentes={opcoesComValoresCrescentes} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
            <AutoRealizacao opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
            <Prazer opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>CASP-16</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function Controle({ opcoesComValoresCrescentes, opcoesComValoresDecrescentes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>CONTROLE</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='controle.minhaIdadeMeImpedeDeFazerAsCoisasQueEuGostariaDeFazer'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C1. Minha idade me impede de fazer as coisas que eu gostaria de fazer.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='controle.euSintoQueOQueAconteceComigoEstaForaDoMeuControle'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C2. Eu sinto que o que acontece comigo, está fora do meu controle.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='controle.euMeSintoLivreParaPlanejarOFuturo'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='C3. Eu me sinto livre para planejar o futuro.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='controle.euMeSintoExcluidoDeTudo'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C4. Eu me sinto excluído de tudo.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function Autonomia({ opcoesComValoresCrescentes, opcoesComValoresDecrescentes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>AUTONOMIA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='autonomia.euPossoFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='A1. Eu posso fazer as coisas que quero.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='autonomia.asResponsabilidadesFamiliaresMeImpedemDeFazerOQueEuQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A2. As responsabilidades familiares me impedem de fazer o que quero.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='autonomia.euMeSintoLivreParaFazerAsCoisas'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='A3. Eu me sinto livre para fazer as coisas.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='autonomia.minhaSaudeMeImpedeDeFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A4. Minha saúde me impede de fazer as coisas que quero.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='autonomia.aFaltaDeDinheiroMeImpedeDeFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A5. A falta de dinheiro me impede de fazer as coisas que quero.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function AutoRealizacao({ opcoesComValoresDecrescentes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>AUTO REALIZAÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='autoRealizacao.euMeSintoCheioDeEnergiaHojeEmDia'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR1. Eu me sinto cheio de energia hoje em dia.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='autoRealizacao.euEscolhoFazerCoisasQueNuncaFizAntes'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR2. Eu escolho fazer coisas que nunca fiz antes.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='autoRealizacao.euSintoQueAVidaEstahCheiaDeOportunidades'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR3. Eu sinto que a vida está cheia de oportunidades.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='autoRealizacao.euSintoQueOMeuFuturoPareceBom'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR4. Eu sinto que o meu futuro parece bom.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}

function Prazer({ opcoesComValoresDecrescentes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>PRAZER</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='prazer.euFicoAnimadoACadaDia'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P1. Eu fico animado a cada dia.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='prazer.euSintoQueMinhaVidaTemSentido'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P2. Eu sinto que minha vida tem sentido.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='prazer.euGostoDasCoisasQueFaco'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P3. Eu gosto das coisas que faço.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
      <div className='pontuacao' />
    </r-cell>
  )
}