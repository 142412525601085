import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
} from '../tipos'

export const alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, instrucao) => async dispatch => {
  dispatch({ type: ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.alterarInstrucaoDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, instrucao)
    
    dispatch(alterouInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, instrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar instrução do profissional de saúde.')))

    return false
  }
}

const alterouInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = instrucao => ({
  type: ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  instrucao
})

const erroAoAlterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, instrucao) => async dispatch => {
  dispatch({ type: ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, instrucao)

    dispatch(alterouSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, instrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar os dados da série de instrução de agendamento do profissional de saúde.')))

    return false
  }
}

const alterouSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = instrucoes => ({
  type: ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  instrucoes
})

const erroAoAlterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, identificador) => async dispatch => {
  dispatch({ type: EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })
  try {
    const resultado = await api.excluirInstrucaoDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, identificador)

    dispatch(excluiuInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(identificadorDoProfissional, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Instrução removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir a instrução de agendamento do profissional de saúde.')))

    return false
  }
}

const excluiuInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, identificador) => ({
  type: EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  identificadorDoProfissional,
  identificador
})

const erroAoExcluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, identificadorDaInstrucao) => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude(identificadorDoProfissional, identificadorDaInstrucao)

    const instrucoes = resultado.data.dados
    instrucoes.forEach(x => {
      dispatch(excluiuSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(x.identificador, identificadorDoProfissional))
    })
    
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Série de instruções removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, identificadorDaInstrucao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir uma série de instruções de agendamento da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificador, identificadorDoProfissional) => ({
  type: EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  identificador,
  identificadorDoProfissional
})

const erroAoExcluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros
})