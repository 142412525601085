import { formatarDataComPrecisaoParaFormatoLocal } from '../bibliotecas/data'
import api from './api-major'

const listarFeriados = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroAno = filtros.ano ? `&ano=${formatarDataComPrecisaoParaFormatoLocal(filtros.ano, 'ANO')}` : ''
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''  
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10'

  return api.get(`/feriados${paginacao}${tamanhoDaPaginaFormatado}${parametroAno}${parametroNome}${ordem}`)
}
const adicionarFeriado = feriado => api.post(`/feriados`, feriado)
const recuperarFeriadoPorIdentificador = identificador => api.get(`/feriados/${identificador}`)
const salvarFeriado = feriado => api.put(`/feriados/${feriado.identificador}`, feriado)
const salvarSerieDeFeriados = feriado => api.put(`/feriados/${feriado.identificador}/serie`, feriado)
const excluirFeriado = identificador => api.delete(`feriados/${identificador}`)
const excluirSerieDeFeriados = identificador => api.delete(`feriados/${identificador}/serie`)

export default {
  adicionarFeriado,
  excluirFeriado,
  excluirSerieDeFeriados,
  recuperarFeriadoPorIdentificador,
  listarFeriados,
  salvarFeriado,
  salvarSerieDeFeriados
}