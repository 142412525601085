import api from './api-major'

const iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (paciente, atendimento, certificado) => {
  return api.post(`/usuario/assinaturas`, { paciente, atendimento, certificado })
}

const finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (atendimento, identificadorDoArquivo, certificado, assinatura) => {
  return api.put(`/usuario/assinaturas`, { atendimento, identificadorDoArquivo, certificado, assinatura })
}

const recuperarCertificadosEmNuvemDoUsuario = () => {
  return api.get(`/usuario/certificados/em-nuvem`)
}

const recuperarUrlDeAutenticacaoDoCertificadoEmNuvem = (servico, tempo, atendimento, origem) => {
  return api.get(`/usuario/certificados/${servico}/url-de-autenticacao?tempoDaSessao=${tempo}&dados=${atendimento ? atendimento : ''};${origem}`)
}

const iniciarSessaoComCertificadoEmNuvem = (codigo, dados) => {
  return api.post(`/usuario/certificados/sessao`, { codigo, dados })
}

const recuperarSessaoComCertificadoEmNuvem = () => {
  return api.get(`/usuario/certificados/sessao`)
}

const assinarAtendimentoComCertificadoEmNuvem = atendimento => {
  return api.post(`/usuario/assinaturas/em-nuvem`, { atendimento })
}

export default {
  assinarAtendimentoComCertificadoEmNuvem,
  iniciarSessaoComCertificadoEmNuvem,
  iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico,
  finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico,
  recuperarCertificadosEmNuvemDoUsuario,
  recuperarSessaoComCertificadoEmNuvem,
  recuperarUrlDeAutenticacaoDoCertificadoEmNuvem,
}