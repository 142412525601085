import React from 'react'
import moment from 'moment'
import '../style.css'

export default function NotificacaoAgendamentoParaOutraUnidade(props) {
  const { fechar, funcoesEmExecucao, history, notificacao } = props
  const { metaDados } = notificacao

  const logout = () => {
    props.logout()

    funcoesEmExecucao.forEach(funcao => {
      clearInterval(funcao)
    })

    history.push('/login')
    fechar()
  }

  const redirecionarParaAgendamento = () => {
    history.push(`/agenda/${metaDados.profissional}/${moment(metaDados.dataEHoraDoAgendamento).format('YYYY-MM-DD')}/${metaDados.agendamento}`)
    fechar()
  }

  return (
    <div>
      <span>{notificacao.texto}</span>
      <div>
        <br />
        <button type='button' className='button button-light-select-horario-agendamento' onClick={logout} >Trocar unidade</button> {' '}
        <button type='button' className='button button-light-select-horario-agendamento' onClick={redirecionarParaAgendamento}>Alterar agendamento</button>
      </div>
    </div>
  )
}