import React, { useState, useEffect, memo } from 'react'
import Modal from 'react-modal'
import { FieldArray } from 'formik'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { confirmAlert } from 'react-confirm-alert'
import { useProntuario } from '../../contexto'
import FormularioMultiprofissional from './formulario-multiprofissional'
import FormularioMedico from "./formulario-medico";
import Impressao from './impressao'

function Encaminhamentos({ identificadorDoAtendimento, identificadorDoPaciente, tokensDeSugestao }) {
  return (
    <FieldArray
      name='encaminhamentos'
      render={(props) => {
        const { push, remove, replace, form: { values: { encaminhamentos = [] } } } = props

        return (
          <Encaminhamento
            adicionar={push}
            adicionados={encaminhamentos}
            alterar={replace}
            identificadorDoAtendimento={identificadorDoAtendimento}
            identificadorDoPaciente={identificadorDoPaciente}
            remover={remove}
            tokensDeSugestao={tokensDeSugestao}
          />
        )
      }}
    />
  )
}

const Encaminhamento = memo(function Encaminhamento(props) {
  const {
    adicionados,
    identificadorDoAtendimento,
    identificadorDoPaciente,
    tokensDeSugestao,
    ...rest
  } = props

  const {
    adicionarTemplateDoProntuario,
    estabelecimentosDeSaude,
    especialidadesMedicas,
    especialidadesMedicasInternas,
    fazerDownloadDoEncaminhamento,
    gruposDeCuidado,
    listarGruposDeCuidado,
    listarEstabelecimentosDeSaude,
    listarEspecialidadesMedicasInternas,
    listarMotivosDePrioridade,
    listarProfissionaisExternos,
    listarProfissionaisInternos,
    listarProfissoes,
    listarProfissoesMultidisciplinares,
    listarTiposDeEncaminhamento,
    motivosDePrioridade,
    profissionaisExternos,
    profissionaisInternos,
    profissoes,
    profissoesMultidisciplinares,
    recuperarProfissaoMedica,
    tamanhosDePaginas,
    tiposDeEncaminhamento,
  } = useProntuario()

  const [selecionada, setSelecionada] = useState(null)
  const [exibirFormularioMultiprofissional, setExibirFormularioMultiprofissional] = useState(false)
  const [exibirFormularioMedico, setExibirFormularioMedico] = useState(false)
  const registrosDaTabela = [...adicionados]

  useEffect(() => { listarEstabelecimentosDeSaude() }, [listarEstabelecimentosDeSaude])
  useEffect(() => { listarProfissionaisExternos({ativo: ['Sim']}) }, [listarProfissionaisExternos])
  useEffect(() => { listarProfissionaisInternos(true) }, [listarProfissionaisInternos])
  useEffect(() => { listarProfissoes() }, [listarProfissoes])
  useEffect(() => { listarTiposDeEncaminhamento() }, [listarTiposDeEncaminhamento])
  useEffect(() => { listarGruposDeCuidado({ ativo: ['Sim'] }) }, [listarGruposDeCuidado])
  useEffect(() => { listarProfissoesMultidisciplinares() }, [listarProfissoesMultidisciplinares])
  useEffect(() => { recuperarProfissaoMedica() }, [recuperarProfissaoMedica])
  useEffect(() => { listarMotivosDePrioridade() }, [listarMotivosDePrioridade])

  const abrirFormularioMultiprofissional = (item) => {
    setSelecionada(item)
    selecionarItem(item)
    setExibirFormularioMultiprofissional(true)
  }

  const fecharFormularioMultiprofissional = () => {
    setExibirFormularioMultiprofissional(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const abrirFormularioMedico = (item) => {
    setSelecionada(item)
    selecionarItem(item)
    setExibirFormularioMedico(true)
  }

  const fecharFormularioMedico = () => {
    setExibirFormularioMedico(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const abrirFormularioDeEdicao = item => {
    if(item.tipo.toLowerCase().includes("multiprofissional")) return abrirFormularioMultiprofissional(item)

    return abrirFormularioMedico(item)
  }

  const [exibirImpressao, setExibirImpressao] = useState(false)

  const abrirImpressao = (item) => {
    setSelecionada(item)
    selecionarItem(item)
    setExibirImpressao(true)
  }

  const fecharImpressao = () => setExibirImpressao(false)

  const adicionar = registro => {
    rest.adicionar(registro)
    setExibirFormularioMultiprofissional(false)
    setExibirFormularioMedico(false)
  }

  const [identificador, setIdentificador] = useState('')

  const salvar = registro => {
    if (identificador >= 0) {
      rest.alterar(identificador, registro)
    }
  }

  const remover = registro => {
    const index = adicionados.indexOf(registro)

    if (index >= 0) {
      rest.remover(index)
    }
  }

  const imprimir = async (tamanhoDaPagina, adicionarData) => {
    const retorno = await fazerDownloadDoEncaminhamento(identificadorDoPaciente, identificadorDoAtendimento, selecionada.identificador, tamanhoDaPagina, adicionarData)

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'application/pdf' });
      const url = URL.createObjectURL(arquivo);

      window.open(url);

      fecharImpressao()
    }
  }

  const confirmarERemover = registro => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o encaminhamento ${registro.texto}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(registro)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEImprimir = () => {
    confirmAlert({
      closeOnClickOutside: false,
      title: 'Alerta',
      message: `Para imprimir o encaminhamento é necessário salvar o atendimento.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  const enfermeiro = profissoes.find(x => x.nome.toUpperCase() === 'ENFERMEIRO(A)')
  const medico = profissoes.find(x => x.nome.toUpperCase() === 'MÉDICO(A)')

  let profissoesComGeneralistas = profissoes.filter(x => x.nome.toUpperCase() !== 'ENFERMEIRO(A)')

  profissoesComGeneralistas && profissoesComGeneralistas.push({ ...enfermeiro, especialidades: ['GENERALISTA'] })
  profissoesComGeneralistas && profissoesComGeneralistas.push({ ...medico, especialidades: [] })

  const retornarEspecialidades = () => {
    if (especialidadesMedicas.length === 0) return []

    let internas = []
    let externas = []

    especialidadesMedicas.forEach(x => {
      if (especialidadesMedicasInternas.map(y => y.identificador).includes(x.identificador)) {
        const especialidade = {...x, label: x.nome, value: x.nome }
        internas.push(especialidade)
      } else {
        externas.push({...x, label: x.nome, value: x.nome })
      }
    })

    return [
      { grupo: 'Especialidades internas', options: internas, interna: true },
      { grupo: 'Especialidades externas', options: externas, interna: false }
    ]
  }

  const selecionarItem = item => {
    if (item) {
      setIdentificador(adicionados.indexOf(item))
    } else {
      setIdentificador('')
    }
  }

  const renderizarTipo = tipo => {
    switch(tipo){
      case 'equipe_multiprofissional_individual' : return 'Equipe Multiprofissional Individual'
      case 'equipe_multiprofissional_coletiva' : return 'Equipe Multiprofissional Coletiva'
      case 'especialidade_medica_interna' : return 'Especialidade Médica Interna'
      case 'especialidade_medica_externa' : return 'Especialidade Médica Externa'
      default : return tipo
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <Modal
          className='modal'
          contentLabel='Modal para adição de encaminhamento médico'
          isOpen={exibirFormularioMedico}
      >
        <FormularioMedico
            adicionarTemplateDoProntuario={adicionarTemplateDoProntuario}
            fechar={fecharFormularioMedico}
            especialidades={retornarEspecialidades()}
            especialidadesMedicasInternas={especialidadesMedicasInternas}
            listarEspecialidadesMedicasInternas={listarEspecialidadesMedicasInternas}
            profissionaisExternos={profissionaisExternos}
            profissionaisInternos={profissionaisInternos}
            salvar={selecionada ? salvar : dados => adicionar(dados)}
            selecionada={selecionada}
            tiposDeEncaminhamento={tiposDeEncaminhamento}
            tokensDeSugestao={tokensDeSugestao}
            setExibirFormulario={setExibirFormularioMedico}
            estabelecimentosDeSaude={estabelecimentosDeSaude}
        />
      </Modal>
      <Modal
        className='modal'
        contentLabel='Modal para adição de encaminhamento multiprofissional'
        isOpen={exibirFormularioMultiprofissional}
      >
        <FormularioMultiprofissional
          adicionarTemplateDoProntuario={adicionarTemplateDoProntuario}
          fechar={fecharFormularioMultiprofissional}
          gruposDeCuidado={gruposDeCuidado}
          profissoes={profissoesMultidisciplinares}
          salvar={selecionada ? salvar : dados => adicionar(dados)}
          selecionada={selecionada}
          tiposDeEncaminhamento={tiposDeEncaminhamento}
          tokensDeSugestao={tokensDeSugestao}
          setExibirFormulariol={setExibirFormularioMultiprofissional}
          estabelecimentosDeSaude={estabelecimentosDeSaude}
          motivosDePrioridade={motivosDePrioridade}
        />
      </Modal>
      <Modal
        className='modal'
        contentLabel='Modal para impressão de encaminhamento'
        isOpen={exibirImpressao}
        style={{ content: { width: '400px' } }}
      >
        <Impressao
          fechar={fecharImpressao}
          imprimir={imprimir}
          tamanhosDePaginas={tamanhosDePaginas}
        />
      </Modal>
      <>
        {registrosDaTabela.length > 0 &&
          <Tabela
            acoes={[
              item =>
                <div key='remover'>
                  <button
                    onClick={() => confirmarERemover(item)}
                    type='button'
                    className='table-items-expanded__bt --remove'
                    title='Remover'
                  >
                    <i className='icon icon-minus'></i>
                  </button>
                </div>
              ,
              item =>
                <div key='editar'>
                  <button
                    type='button'
                    onClick={() => abrirFormularioDeEdicao(item)}
                    className='table-items-expanded__bt --edit'
                    title='Editar'
                  >
                    <i className='icon icon-pencil'></i>
                  </button>
                </div>
              ,
              item =>
                <div key='imprimir'>
                  <button
                    type='button'
                    onClick={() => item.identificador && !item.codigoDeRastreio ? abrirImpressao(item) : confirmarEImprimir()}
                    className='table-items-expanded__bt --edit'
                    title='Imprimir'
                  >
                    <i className='icon icon-print'></i>
                  </button>
                </div>
            ]}
            className='--secondary mb-2'
            dados={registrosDaTabela}
            expandida={true}
            titulo={false}
            exibirBotaoMais={false}
          >
            <LinhaExpandida className='col-xs-10 col-lg-11'>
              <AgrupamentoDeColunas>
                <Coluna
                    icone='icon-name'
                    nome='TIPO'
                    renderizar={item => renderizarTipo(item.tipo)}
                />
                <Coluna
                    icone='icon-graduation-cap'
                    nome='PROFISSÃO/ESPECIALIDADE'
                    renderizar={item => item.especialidade ? `${item.especialidade} - ${item.profissao}` : item.profissao}
                />
                <Coluna
                    icone='icon-doctor'
                    nome='PROFISSIONAL'
                    renderizar={item => item.profissional}
                />
                <Coluna
                    icone='icon-doctor'
                    nome='PROFISSIONAL EXTERNO'
                    renderizar={item => item.profissionalExterno}
                />
                <Coluna
                    icone='icon-attention-circled'
                    nome='Motivo de prioridade'
                    renderizar={item => item.motivoDePrioridade}
                />
                <Coluna
                    icone='icon-graduation-cap'
                    nome='GRUPO DE CUIDADO'
                    renderizar={item => item.grupoDeCuidado}
                />
                <Coluna
                  icone='icon-doc-text'
                  nome='ENCAMINHAMENTO'
                  className='table-items-expanded-100__box-item'
                  renderizar={item => <div><pre className='font-react-menstion'>{item.texto}</pre></div>}
                />
              </AgrupamentoDeColunas>
            </LinhaExpandida>
          </Tabela>
        }
        <r-cell span={4} span-md={3} span-lg={12}  class='d-flex justify-content-lg-start'>
          <button
            className='button --light w-sm-100 mr-1'
            type='button'
            onClick={() => abrirFormularioMedico(null)}
          >
            Encaminhamento Médico
          </button>
          <button
              className='button --light w-sm-100 mr-1'
              type='button'
              onClick={() => abrirFormularioMultiprofissional(null)}
          >
            Encaminhamento Multiprofissional
          </button>
        </r-cell>
      </>
    </r-cell>
  )
})

export default memo(Encaminhamentos)