import {
  LISTOU_AGENDAMENTOS_DO_DIA
} from '../../acoes/tipos'

const  estadoInicial = {
  filtros: {
    unidades: '',
    profissionais: [],
    termo: '',
  },
  ordenacao: { nome: 'inicio', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_AGENDAMENTOS_DO_DIA: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        ordenacao,
        paginaDeDados: {
          ...paginaDeDados,
          dados: action.paginaDeDados.dados.map(x => ({
            ...x,
            profissional: {
              ...x.profissional,
              nome: x.profissional.tratamento ? x.profissional.tratamento + ' ' + x.profissional.nome : x.profissional.nome
            }
          }))
        },
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}