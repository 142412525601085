import React, {Children, useCallback, useState} from 'react'
import avatar from '../../../../design/img/no-thumb.png'
import Edicao from './edicao'

export function Tabela(props) {
  const {
    acoes = [],
    alterar,
    className,
    chave,
    children,
    dados,
    edicao,
    usuario,
  } = props

  const [selecionado, setSelecionado] = useState(null)
  const chaves = chave && chave.split('.')
  const colunas = Children.toArray(children).filter(x => x.type === Coluna)

  const escolheChave = useCallback(item => {
    let valor = item

    chaves.forEach(item => {
      valor = valor[item]
    })

    return valor
  }, [chaves])

  const itensDaTabela = dados

  const renderizarAcoes = (item, index) => {
    const mesmoUsuario = item.quem.identificador === usuario.token.identificador
    const acoesDoItem = (mesmoUsuario && edicao) ? [item => (
      <div key='editar'>
        <button
          type='button'
          onClick={() => setSelecionado(item)}
          className='table-items-expanded__bt --edit'
          title='Editar'
        >
          <i className='icon icon-pencil'></i>
        </button>
      </div>
    ), ...acoes] : []

    return (
      <div className={`table-items-expanded__tools`}>
        {acoesDoItem.map(acao => acao(item))}
      </div>
    )
  }

  const renderizarItem = (item, index) => {
    const key = chave ? escolheChave(item) : index

    return (
      <div className={`table-items-expanded__collapsable is-active`} key={key}>
        <div className='table-items-expanded__collapsable-content'>
          <div className={`table-items-expanded__box col-xs-12 col-lg-12`} style={{ padding: '2px 8px' }}>
            <Colunas
              alterar={alterar}
              itens={colunas}
              item={item}
              selecionado={selecionado}
              fechar={setSelecionado}
            />
          </div>
        </div>
        {renderizarAcoes(item, index)}
      </div>
    )
  }

  return (
    <div className={`table-items-expanded ${className}`}>
      {itensDaTabela.map((item, index) => renderizarItem(item, index))}
    </div>
  )
}

export function Coluna({ children }) {
  return children
}

function Colunas({ itens, item, selecionado, alterar, fechar }) {
  const colunas = itens

  return colunas.map((coluna, index) => {
    if (!item[coluna.props.campoDeDados] && !coluna.props.renderizar) {
      return null
    }

    const valor = item[coluna.props.campoDeDados] ? item[coluna.props.campoDeDados] : coluna.props.renderizar(item)
    const editar = selecionado && coluna.props.comentario && selecionado.identificador === item.identificador

    if (!valor) {
      return null
    }

    return (
      <div className={`table-items-expanded__box-item table-task ${coluna.props.className && coluna.props.className}`} key={index}>
        <div className={`${editar ? '' : 'table-items-expanded__data p-0'}`} key={item.identificador}>
          {coluna.props.foto && <Foto item={item} fotos={coluna.props.fotos} identificador={item.quem.identificador} />}
          {coluna.props.icone && <Icone icone={coluna.props.icone} />}
          {!editar && <div className='table-items-expanded__box-value table-task-box-value'>{item[coluna.props.campoDeDados] || coluna.props.renderizar(item)}</div>}
          {editar && <Edicao alterar={alterar} item={item} fechar={fechar} />}
        </div>
      </div>
    )
  })
}

function Foto({ nome, fotos, identificador }) {
  const src = fotos[identificador]

  return (
    <div className='form-table'>
      <div className='form-table-images'>
        <div className='form-table-image'>
          <img src={src || avatar} alt={nome} />
        </div>
      </div>
    </div>
  )
}

function Icone({ icone }) {
  return (
    <div className='form-table-icon'>
      {icone}
    </div>

  )
}