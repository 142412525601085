import React, { useState, useEffect } from 'react'
import Spinner from '../../../spinner'
import Modal from 'react-modal'
import { Formulario as FormularioDoFormik, Select, DatePicker, Input } from '../../../formik/formulario'
import { FieldArray } from 'formik'
import { dataIgualOuAnterior, formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import * as Yup from 'yup'
import moment from 'moment'
import FileSaver from 'file-saver'
import arquivoPdf from '../../../../design/img/pdf-file.svg'
import arquivo from '../../../../design/img/file.svg'

import {
  ADICIONAR_INTERNACAO_DO_PACIENTE,
  ALTERAR_INTERNACAO_DO_PACIENTE,
  ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  LISTAR_MOTIVOS_DE_INTERNACAO,
  LISTAR_STATUS_DE_INTERNACOES,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE
} from '../../../../acoes/tipos'

export default function Formulario({
  fechar,
  status,
  tiposDeInternacao,
  motivos,
  estabelecimentos,
  adicionarSumarioDeAltaDaInternacao,
  fazerDownloadDoSumarioDeAltaDaInternacao,
  arquivosDaInternacao,
  identificadorDoPaciente,
  selecionada,
  ...rest
}) {

  const [exibirDescricoes, setExibirDescricoes] = useState(false)

  const salvar = async (valores, { resetForm }) => {
    const formatarMotivos = () => {
      const formataMotivoPrincipal = [{ motivo: valores.motivoPrincipal, principal: true }]
      const formataMotivosSecundarios = valores.motivosSecundarios.map(x => ({ motivo: x.nome, principal: false }))

      return formataMotivoPrincipal.concat(formataMotivosSecundarios)
    }

    const dados = {
      ...selecionada,
      ...valores,
      tipo: valores.tipo ? valores.tipo : null,
      motivos: valores.motivoPrincipal ? formatarMotivos(valores.motivoPrincipal, valores.motivosSecundarios) : [],
      arquivos: valores.arquivos.length > 0 ? valores.arquivos.map(x => ({ arquivo: x.arquivo.identificador, tipo: 'sumario_de_alta' })) : []
    }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const removerMotivosAdicionados = (motivosAdicionados = [], motivos = [], motivoPrincipal) => {
    const motivosFiltrados = motivos.filter(x => x.nome !== motivoPrincipal)

    if (motivosAdicionados.length === 0) return motivosFiltrados

    const filtro = motivo => {
      return motivosAdicionados.findIndex(x => x.nome === motivo.nome) === -1
    }

    return motivosFiltrados.filter(filtro)
  }

  const selecionarStatus = (status, setFieldValue) => {
    if (status === 'Internado' || status === 'Informado pela operadora' || status === 'Internado (com transferência hospitalar)') {
      setFieldValue('fim', '')
    }
  }

  const adicionarMotivoSecundario = (motivoSelecionado, setFieldValue, arrayHelpers) => {
    arrayHelpers.push({ nome: motivoSelecionado })
    setFieldValue('motivoSecundario', '')
  }


  const renderizarAlerta = inicio => {
    return (
      <div className='form-choice-alerta'>
        <div className='form-choice-alerta__item'>
          <i className='icon icon-attention-circled'></i>
        </div>
        <div className='form-choice-alerta__item'>{`Continuando a internação do dia ${formatarDataParaFormatoLocal(inicio)}`}</div>
      </div>
    )
  }

  return (
    <Spinner operacoes={[ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO]}>
      {({ processando }) => (
        <>
          <Spinner operacoes={[
            ADICIONAR_INTERNACAO_DO_PACIENTE,
            ALTERAR_INTERNACAO_DO_PACIENTE,
            LISTAR_MOTIVOS_DE_INTERNACAO,
            LISTAR_STATUS_DE_INTERNACOES,
            LISTAR_ESTABELECIMENTOS_DE_SAUDE
          ]}>
            <FormularioDoFormik
              valoresIniciais={{
                identificador: selecionada ? selecionada.identificador : '',
                status: selecionada ? selecionada.status : '',
                tipo: selecionada && selecionada.tipo !== null ? selecionada.tipo : '',
                inicio: selecionada ? selecionada.inicio : '',
                fim: selecionada ? selecionada.fim : '',
                estabelecimentoDeSaude: selecionada ? selecionada.estabelecimentoDeSaude : '',
                motivoPrincipal: selecionada ? selecionada.motivoPrincipal.nome : '',
                motivosSecundarios: selecionada ? selecionada.motivosSecundarios : [],
                descricao: '',
                arquivos: selecionada ? selecionada.arquivos : []
              }}
              esquemaDeValidacoes={Yup.object().shape({
                status: Yup.string().required('Obrigatório.'),
                tipo: Yup.string()
                  .when('inicio', {
                    is: val => val && !dataIgualOuAnterior(val, '2020-01-02'),
                    then: Yup.string().required('Obrigatório.')
                  }),
                estabelecimentoDeSaude: Yup.string().required('Obrigatório.'),
                motivoPrincipal: Yup.string()
                  .when('status', {
                    is: val => val !== 'Informado pela operadora',
                    then: Yup.string().required('Obrigatório.')
                  }),
                descricao: Yup.string()
                  .when('status', {
                    is: val => val !== 'Informado pela operadora',
                    then: Yup.string().required('Obrigatório.')
                  }),
                inicio: Yup.string().required('Obrigatório.').nullable()
                  .when('status', {
                    is: val => val !== 'Internado' && val !== 'Informado pela operadora' && val !== 'Internado (com transferência hospitalar)',
                    then: Yup.string().required('Obrigatório.').nullable()
                      .test(
                        "fim_teste",
                        "Deve ser menor que o fim.",
                        function (value) {
                          const { fim } = this.parent;
                          return !value || dataIgualOuAnterior(value, fim);
                        }
                      )
                  }),
                fim: Yup.string().nullable()
                  .when('status', {
                    is: val => val !== 'Internado' && val !== 'Informado pela operadora' && val !== 'Internado (com transferência hospitalar)',
                    then: Yup.string()
                      .required('Obrigatório.')
                      .nullable()
                      .test(
                        "fim_teste",
                        "Deve ser maior que o início",
                        function (value) {
                          const { inicio } = this.parent;
                          return !value || dataIgualOuAnterior(inicio, value);
                        }
                      ),
                  })
              })}
              acao={salvar}
            >
              {({ values, setFieldValue }) => (
                <fieldset>
                  <h2 className='form-title'>{selecionada ? 'Alterar Internação' : 'Adicionar Internação'}</h2>
                  {selecionada && !selecionada.fim &&
                    <>
                      {renderizarAlerta(selecionada.inicio)}
                      <hr className='separator mt-1 mb-1'></hr>
                    </>
                  }
                  <r-grid columns-md={12} columns-lg={12} class='align-end'>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <Select
                        nome='status'
                        titulo='Status *'
                        tabIndex={1}
                        itens={status}
                        campoCodigo='nome'
                        campoDescricao='nome'
                        onChange={item => selecionarStatus(item, setFieldValue)}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <Select
                        nome='tipo'
                        titulo='Tipo *'
                        tabIndex={2}
                        itens={tiposDeInternacao}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={2} span-md={6} span-lg={!(values.status === 'Internado' || values.status === 'Informado pela operadora' || values.status === 'Internado (com transferência hospitalar)') ? 6 : 5}>
                      <DatePicker
                        nome='inicio'
                        titulo='Início *'
                        tabIndex={3}
                        maxDate={new Date()}
                      />
                    </r-cell>
                    {!(values.status === 'Internado' || values.status === 'Informado pela operadora' || values.status === 'Internado (com transferência hospitalar)') &&
                      <r-cell span={2} span-md={6} span-lg={6}>
                        <DatePicker
                          nome='fim'
                          titulo='Fim *'
                          tabIndex={4}
                          minDate={moment(values.inicio).toDate()}
                          maxDate={new Date()}
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={12} span-lg={!(values.status === 'Internado' || values.status === 'Informado pela operadora' || values.status === 'Internado (com transferência hospitalar)') ? 12 : 7}>
                      <Select
                        nome='estabelecimentoDeSaude'
                        titulo='Estabelecimento de Saúde *'
                        tabIndex={5}
                        itens={estabelecimentos}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={5}>
                      <Select
                        nome='motivoPrincipal'
                        titulo={values.status !== 'Informado pela operadora' ? 'Motivo Principal *' : 'Motivo Principal'}
                        tabIndex={6}
                        itens={motivos}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={10} span-lg={5}>
                      <Select
                        nome='motivoSecundario'
                        titulo='Motivo Secundário'
                        tabIndex={7}
                        itens={removerMotivosAdicionados(values.motivosSecundarios, motivos, values.motivoPrincipal)}
                        isDisabled={!values.motivoPrincipal}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <FieldArray
                      name='motivosSecundarios'
                      render={arrayHelpers => (
                        <>
                          <r-cell span={4} span-md={2} span-lg={2}>
                            <button
                              type='button'
                              className={values.motivoSecundario ? 'button --primary w-100 minw-auto mt-24' : 'button --primary is-disabled w-100 minw-auto mt-24'}
                              tabIndex={8}
                              onClick={() => adicionarMotivoSecundario(values.motivoSecundario, setFieldValue, arrayHelpers)}
                            >
                              Adicionar
                            </button>
                          </r-cell>
                          {values.motivosSecundarios && values.motivosSecundarios.length > 0 &&
                            <r-cell span={4} span-md={12} span-lg='6-12'>
                              {values.motivosSecundarios.map((motivo, index) =>
                                <div className='form-choice' key={index}>
                                  <div className='form-choice__item --full'>{motivo.nome}</div>
                                  <button className='form-choice__bt-close' type='button' onClick={() => arrayHelpers.remove(index)}><i className='icon icon-close'></i></button>
                                </div>
                              )}
                            </r-cell>
                          }
                        </>
                      )}
                    />
                    {selecionada && selecionada.descricoes.length > 0 &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <div className='form-choice'>
                          <div className='form-choice__item --full'><strong>Ver descrições anteriores</strong></div>
                          <button
                            className='indicator-tooltip form-choice__bt-close'
                            type='button'
                          >
                            <i className={`icon-arrow${exibirDescricoes ? '-up' : '-down'} ml-0`}
                              onClick={() => setExibirDescricoes(!exibirDescricoes)}
                            ></i>
                          </button>
                        </div>
                        {exibirDescricoes && selecionada.descricoes.map((descricao, index) =>
                          <div className='form-choice pr-0' key={index}>
                            <div className='form-choice__item --full'>
                              <span><em>{descricao.usuario.nome}</em>{descricao.usuario.profissao ? <> | <em>{descricao.usuario.profissao.nome}</em></> : ''}</span> <br />
                              <span><em>Data e Hora: {formatarDataEHoraParaFormatoLocal(descricao.dataEHora)}</em></span> <br />
                              <hr className='separator my-1' style={{ backgroundColor: '#528ce3' }} />
                              <pre>{descricao.descricao}</pre>
                            </div>
                          </div>
                        )}
                      </r-cell>
                    }
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        titulo={values.status !== 'Informado pela operadora' ? 'Descrição *' : 'Descrição'}
                        nome='descricao'
                        tabIndex={9}
                      />
                    </r-cell>
                    {(values.status !== 'Internado' && values.status !== 'Internado (com transferência hospitalar)' && values.status !== 'Informado pela operadora') &&
                      <FieldArray
                        name='arquivos'
                        render={({ push, remove }) =>
                          <r-cell span={4} span-md={12} span-lg={12}>
                            <RenderizarSumarioDeAlta
                              adicionar={push}
                              remover={remove}
                              selecionada={selecionada}
                              adicionarSumarioDeAltaDaInternacao={adicionarSumarioDeAltaDaInternacao}
                              fazerDownloadDoSumarioDeAltaDaInternacao={fazerDownloadDoSumarioDeAltaDaInternacao}
                              arquivosDaInternacao={arquivosDaInternacao}
                              listaDeSumarios={values.arquivos}
                              identificadorDoPaciente={identificadorDoPaciente}
                              processando={processando}
                            />
                          </r-cell>
                        }
                      />
                    }
                  </r-grid>
                  <hr className='separator mt-1 mb-1'></hr>
                  <div className='list-btn'>
                    <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
                    <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
                  </div>
                </fieldset>
              )}
            </FormularioDoFormik>
          </Spinner>
        </>
      )}
    </Spinner>
  )
}

function RenderizarSumarioDeAlta(props) {
  const {
    selecionada,
    adicionar,
    remover,
    adicionarSumarioDeAltaDaInternacao,
    fazerDownloadDoSumarioDeAltaDaInternacao,
    arquivosDaInternacao,
    listaDeSumarios,
    identificadorDoPaciente,
    processando
  } = props

  const adicionarSumarioDeAlta = async evento => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const leitor = new FileReader();

    const identificador = await adicionarSumarioDeAltaDaInternacao(arquivo, 'internacao')

    leitor.onloadend = () => {
      if (identificador) {
        adicionar({ arquivo: { identificador: identificador, tipo: 'sumario_de_alta', url: leitor.result } })
      }
    }

    leitor.readAsDataURL(arquivo)
  }

  useEffect(() => {
    if (selecionada && selecionada.identificador && selecionada.arquivos.length > 0) {
      selecionada.arquivos.forEach(x => {
        if (!x.arquivo.url && x.arquivo.extensao !== '.PDF') {
          return fazerDownloadDoSumarioDeAltaDaInternacao(identificadorDoPaciente, selecionada.identificador, x.arquivo.identificador)
        }
      })
    }
  }, [fazerDownloadDoSumarioDeAltaDaInternacao, identificadorDoPaciente, selecionada])

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoSumarioDeAltaDaInternacao(identificadorDoPaciente, selecionada.identificador, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  return (
    <>
      <label className='form-label'>Sumário de Alta</label>
      <div className='form-summary'>
        <div className='form-summary-group'>
          <label
            htmlFor='sumario'
            className={processando ? 'button --primary is-loading m0' : 'button --primary m0'}
          >
            Adicionar Sumário
            <i className='icon icon-folder-empty mleft-5'></i>
          </label>
          <input
            accept='image/*,application/pdf'
            type='file'
            name='sumario'
            id='sumario'
            tabIndex={10}
            onChange={(evento) => adicionarSumarioDeAlta(evento)}
            onClick={evento => { evento.target.value = null }}
          />
        </div>
        <div className='form-summary-images internacao'>
          {listaDeSumarios.length > 0 && listaDeSumarios.map((x, index) =>
            <div key={x.arquivo.identificador}>
              <SumarioDeAlta
                sumarioDeAlta={x}
                remover={remover}
                arquivosDaInternacao={arquivosDaInternacao}
                index={index}
                fazerDownload={fazerDownload}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function SumarioDeAlta({ sumarioDeAlta = undefined, arquivosDaInternacao, remover, index, fazerDownload }) {
  const [exibir, setExibir] = useState(false)

  const estiloDoModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2px',
      width: '30%'
    }
  }

  function abrirImagem() {
    setExibir(true)
  }

  function fecharImagem() {
    setExibir(false)
  }

  function ExibirImagem({ foto, fechar }) {
    return (
      <div className='modal-foto'>
        <i className='icon icon-close'
          onClick={fechar}>
        </i>
        <img alt='foto' src={foto} />
      </div>
    )
  }

  const formatosImagens = ['.PNG', '.GIF', '.JPEG', '.JPG', '.JFIF', '.WEBP']

  const url = sumarioDeAlta && arquivosDaInternacao && arquivosDaInternacao[sumarioDeAlta.arquivo.identificador] ?
    arquivosDaInternacao[sumarioDeAlta.arquivo.identificador].url : sumarioDeAlta.arquivo.url

  function ExibirAnexos() {
    if (formatosImagens.includes(sumarioDeAlta.arquivo.extensao)) {
      return (
        <div className='form-summary-image' key={index}>
          {url ?
            <>
              <img src={url} alt='sumario' />
              <div className='form-summary-image-tools'>
                <button className='form-summary-image-zoom' aria-label='Ampliar' type='button' onClick={abrirImagem}><i className='icon icon-search'></i></button>
                <button className='form-summary-image-remove' aria-label='Remover' type='button' onClick={() => remover(index)}><i className='icon icon-remove'></i></button>
              </div>
            </>
            : 'Carregando...'}
        </div>
      )
    } else if (sumarioDeAlta.arquivo.extensao === '.PDF') {
      return (
        <div className='form-summary-image' key={index}>
          <img src={arquivoPdf} alt='sumario' />
          {sumarioDeAlta.arquivo.nome &&
            <>
              <label className='form-label'>{sumarioDeAlta.arquivo.nome.slice(0, sumarioDeAlta.arquivo.nome.lastIndexOf('.'))}</label>
              <div className='form-summary-image-tools'>
                {sumarioDeAlta.arquivo.extensao &&
                  <button className='form-summary-image-zoom' aria-label='Baixar' type='button' onClick={() => fazerDownload(sumarioDeAlta.arquivo)}><i className='icon icon-download-cloud'></i></button>
                }
                <button className='form-summary-image-remove' aria-label='Remover' type='button' onClick={() => remover(index)}><i className='icon icon-remove'></i></button>
              </div>
            </>
          }
        </div>
      )
    } else {
      return (
        <div className='form-summary-image' key={index}>
          <img src={arquivo} alt='sumario' />
          {sumarioDeAlta.arquivo.nome &&
            <>
              <label className='form-label'>{sumarioDeAlta.arquivo.nome.slice(0, sumarioDeAlta.arquivo.nome.lastIndexOf('.'))}</label>
              <div className='form-summary-image-tools'>
                {sumarioDeAlta.arquivo.extensao &&
                  <button className='form-summary-image-zoom' aria-label='Baixar' type='button' onClick={() => fazerDownload(sumarioDeAlta.arquivo)}><i className='icon icon-download-cloud'></i></button>
                }
                <button className='form-summary-image-remove' aria-label='Remover' type='button' onClick={() => remover(index)}><i className='icon icon-remove'></i></button>
              </div>
            </>
          }
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        isOpen={exibir}
        onRequestClose={fecharImagem}
        style={estiloDoModal}
      >
        <ExibirImagem
          foto={url}
          fechar={fecharImagem}
        />
      </Modal>

      <ExibirAnexos />
    </>
  )
}