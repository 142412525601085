import React, { useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import FileSaver from 'file-saver'
import Spinner from '../../../spinner'
import { FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO } from '../../../../acoes/tipos'
import { Formulario as FormularioDoFormik, Select, Input } from '../../../formik/formulario'

export default function Formulario(props) {
  const {
    fazerDownloadDoArquivoDaOrientacao,
    fechar,
    orientacoes,    
    selecionada,    
    ...rest
  } = props

  const salvar = (valores, { resetForm }) => {
    resetForm({})

    const dados = {
      ...valores,
    }

    rest.salvar(dados)
    fechar()
  }  
  
  const [dirty, setDirty] = useState(false)  
  const [observacaoDaOrientacao, setObservacaoDaOrientacao] = useState(null)
  const [arquivosDaOrientacao, setArquivosDaOrientacao] = useState([])
  const [identificadorDaOrientacao, setIdentificadorDaOrientacao] = useState(null)

  const controlarDados = (isDirty) => {    
    setDirty(isDirty)
  }  

  const alterouOrientacao = useCallback((orientacao) => {  
    setObservacaoDaOrientacao(null)
    setArquivosDaOrientacao([])
    setIdentificadorDaOrientacao(null)

    if (orientacao && orientacao !== '') {      
      const orientacaoSelecionada = orientacoes.filter(x => x.nome === orientacao)          
      
      setObservacaoDaOrientacao(orientacaoSelecionada[0].observacoes)
      setArquivosDaOrientacao(orientacaoSelecionada[0].arquivos)
      setIdentificadorDaOrientacao(orientacaoSelecionada[0].identificador)
    }
  }, [orientacoes, setObservacaoDaOrientacao, setArquivosDaOrientacao])  

  useEffect(() => {
    if (!dirty && selecionada && selecionada.orientacao) {
      alterouOrientacao(selecionada.orientacao)
    }
  }, [selecionada, alterouOrientacao, dirty])  

  const fazerDownload = async arquivo => {    
    const dados = await fazerDownloadDoArquivoDaOrientacao(identificadorDaOrientacao, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }  

  return (  
    <Spinner operacoes={[FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO]}>
      <FormularioDoFormik        
        controlarDadosSalvos={isDirty => controlarDados(isDirty)}
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : null,
          orientacao: selecionada ? selecionada.orientacao : '',
          observacoes: selecionada ? selecionada.observacoes : '',          
        }}
        esquemaDeValidacoes={Yup.object().shape({
          orientacao: Yup.string().required('Obrigatório.'),          
        })}
        acao={salvar}
      >        
        <fieldset>
          <h2 className='form-title'>Adicionar Orientações</h2>
          <r-grid columns-md={6} columns-lg={12} className='mb-2'>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Select
                nome='orientacao'
                titulo='Orientação *'
                tabIndex={1}
                itens={orientacoes}
                campoCodigo='nome'
                campoDescricao='nome'
                onChange={evento => alterouOrientacao(evento)}
              />
            </r-cell>                            
            {observacaoDaOrientacao &&
              <r-cell span={4} span-md={12} span-lg={12}>
                <div className='form-item'>
                  <pre className='form-item__choice'>{' '}{observacaoDaOrientacao}</pre>
                </div>
              </r-cell>
            }
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={2}
                titulo='Observações'
              />
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <div className='form-summary'>
                <div className='form-summary-files'>
                  {arquivosDaOrientacao.length > 0 && arquivosDaOrientacao.map((x, index) =>
                    <div className='file-upload-item' key={index}>
                      <div className='file-upload-item__name'>{x.nome}</div>                      
                      <button
                        className='file-upload-item__bt'
                        title='Fazer Download'
                        type='button'
                        onClick={() => fazerDownload(x)}
                      >
                        <i className='icon icon-download-cloud'></i>
                      </button>                      
                    </div>
                  )}
                </div>
              </div>              
            </r-cell>
          </r-grid>
          <div className='list-btn mt-4'>
            <button type='button' className='button --light' onClick={fechar}>Cancelar</button>
            <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>        
      </FormularioDoFormik>
    </Spinner>
  )
}