import {  
  LISTOU_CONVENIOS_DO_PACIENTE,  
  RECUPEROU_CONVENIO_DO_PACIENTE,
} from '../../acoes/tipos'

const estadoInicial = {
  itens: [],  
  selecionado: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_CONVENIO_DO_PACIENTE: {
      return {
        ...state,
        selecionado: action.convenio,
      }
    }

    case LISTOU_CONVENIOS_DO_PACIENTE: {
      return {
        selecionado: {},
        itens: action.convenios,
      }
    }    

    default: {
      return state
    }
  }
}