import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  FAZER_DOWNLOAD_DA_PRESCRICAO,
  FEZ_DOWNLOAD_DA_PRESCRICAO,
  ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO
} from './tipos'

const fezDownloadDaPrescricao = (identificadorDaPrescricao, bytesDaPrescricao) => ({
  type: FEZ_DOWNLOAD_DA_PRESCRICAO,
  identificadorDaPrescricao,
  bytesDaPrescricao,
})

const erroAoFazerDownloadDaPrescricao = (erro, identificadorDaPrescricao) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DA_PRESCRICAO,
  identificadorDaPrescricao,
  erro,
})

export const fazerDownloadDaPrescricao = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDaPrescricao, tamanhoDaPagina, adicionarData) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DA_PRESCRICAO,
    identificadorDaPrescricao
  })

  try {
    const resultado = await api.fazerDownloadDaPrescricao(identificadorDoPaciente, identificadorDoAtendimento, identificadorDaPrescricao, tamanhoDaPagina, adicionarData)

    dispatch(fezDownloadDaPrescricao(identificadorDaPrescricao, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDaPrescricao(erro, identificadorDaPrescricao))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download da imagem da prescricao.')))

    return null
  }
}