import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import constantes from '../../configuracoes/constantes'
import Breadcrumb from '../breadcrumb'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Lista from './lista'
import Formulario from './formulario'
import Editar from './editar-usuario'
import Agenda from './agenda/agenda'
import Bloqueios from './agenda/bloqueios'
import Instrucoes from './agenda/instrucoes'
import TiposDeAgendamentos from './agenda/tipos-de-agendamentos'
import FeriadosTrabalhados from './agenda/feriados-trabalhados'
import Perfil from './perfil'
import Unidades from './unidades'
import TiposDeAtendimento from './tipos-de-atendimento'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'

export default function Usuarios(props) {
  const {
    adicionarBloqueioNaAgendaDoProfissionalDeSaude,
    adicionarFeriadoTrabalhadoDoProfissional,
    adicionarFotoDoUsuario,
    adicionarHorarioDaAgendaDoProfissionalDeSaude,
    adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude,
    adicionarPerfilDoUsuario,
    adicionarTipoDeAgendamentoDoProfissional,
    adicionarUnidadeDoUsuario,
    adicionarUsuario,
    agenda,
    alterarBloqueioNaAgendaDoProfissionalDeSaude,
    alterarHorarioDaAgendaDoProfissionalDeSaude,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude,
    alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude,
    alterarFiltrosDasIntrucoesDeAgendamentosDoProfissionalDeSaude,
    alterarFiltrosDosBloqueiosNaAgendaDoProfissionalDeSaude,
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaude,
    alterarStatusDoUsuario,
    conselho,
    desabilitarTipoDeAtendimentoDoUsuario,
    excluirBloqueioDaAgendaDoProfissionalDeSaude,
    excluirHorarioDaAgendaDoProfissionalDeSaude,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaude,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude,
    excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude,
    feriados,
    filtros,
    fotos,
    habilitarTipoDeAtendimentoDoUsuario,
    history,
    identificadorDoUsuarioLogado,
    listarBloqueiosNaAgendaDoProfissionalDeSaude,
    listarEmpresasHabilitadas,
    listarFeriados,
    listarFeriadosTrabalhadosDoProfissional,
    listarHorariosDaAgendaDoProfissionalDeSaude,
    listarInstrucoesDeAgendamentosDoProfissionalDeSaude,
    listarMotivosDeBloqueioDaAgenda,
    listarPerfis,
    listarPerfisDoUsuario,
    listarProfissoes,
    listarTiposDeAgendamentoDasConfiguracoesDaAgenda,
    listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda,
    listarTiposDeAtendimento,
    listarTiposDeAtendimentoHabilitadosParaOUsuario,
    listarTratamentos,
    listarUnidadesDoUsuario,
    listarUnidadesDaEmpresaDoUsuario,
    listarUnidadesFederativas,
    listarUsuarios,
    listarSexos,
    location,
    logins,
    match,
    ordenacao,
    paginaDeDados,
    perfil,
    profissoes,
    recuperarFotoDoUsuarioPorIdentificador,
    recuperarFotoOriginalDoUsuarioPorIdentificador,
    recuperarLogin,
    removerFotoDoUsuario,
    removerFeriadoTrabalhadoDoProfissional,
    removerImagemDaMemoriaPorIdentificador,
    removerPerfilDoUsuario,
    removerTipoDeAgendamentoDoProfissional,
    removerUnidadeDoUsuario,
    recuperarUsuarioPorIdentificador,
    salvarUsuario,
    sexos,
    tratamentos,
    tiposDeAtendimento,
    tiposDeAtendimentoDoUsuario,
    usuario,
    unidades,
    unidadesFederativas
  } = props

  useEffect(() => {
    listarProfissoes()
    listarUnidadesFederativas()
    listarSexos()
    listarTratamentos()
  }, [listarProfissoes, listarUnidadesFederativas, listarSexos, listarTratamentos])

  useEffect(() => {
    paginaDeDados.dados && paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
    })

    return () => paginaDeDados.dados && paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })
  }, [paginaDeDados.dados, recuperarFotoDoUsuarioPorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    if (usuario.foto) {
      recuperarFotoDoUsuarioPorIdentificador(usuario.identificador, usuario.foto, constantes.tamanhoDaFoto.original)
    }

    return () => usuario.foto && removerImagemDaMemoriaPorIdentificador(usuario.foto)
  }, [usuario.foto, usuario.identificador, recuperarFotoDoUsuarioPorIdentificador, removerImagemDaMemoriaPorIdentificador])

  const salvar = async formulario => {
    const dados = {
      ...formulario,
      profissao: formulario.informarDadosProfissionais ? formulario.profissao : '',
      especialidades: formulario.informarDadosProfissionais ? formulario.especialidades : '',
      conselho: formulario.informarDadosProfissionais ? formulario.conselho : '',
      unidadeFederativaDoConselhoProfissional: formulario.informarDadosProfissionais ? formulario.unidadeFederativaDoConselhoProfissional : '',
      numeroDoRegistroNoConselhoProfissional: formulario.informarDadosProfissionais ? formulario.numeroDoRegistroNoConselhoProfissional : '',
    }

    const novoRegistro = !dados.identificador
    const verificarUsuarioLogado = dados.identificador === identificadorDoUsuarioLogado
    const acao = novoRegistro ? adicionarUsuario : salvarUsuario
    const identificadorDoUsuario = await acao(dados, verificarUsuarioLogado)

    if (identificadorDoUsuario) {
      history.push(`/configuracoes/usuarios/${identificadorDoUsuario}`)
    }
  }

  const inativarStatus = async () => {
    const inativou = await alterarStatusDoUsuario(usuario.identificador, 'inativo')

    if (inativou) {
      history.push(`/configuracoes/usuarios/lista`)
    }
  }

  const ativarStatus = async () => {
    const ativou = await alterarStatusDoUsuario(usuario.identificador, 'ativo')

    if (ativou) {
      history.push(`/configuracoes/usuarios/lista`)
    }
  }

  const abaAtiva = (props) => {
    const abas = {
      '/agenda': 1,
      '/agenda/bloqueios': 1,
      '/agenda/feriados-trabalhados': 1,
      '/agenda/instrucoes': 1,
      '/agenda/tipos-de-agendamento': 1,
      '/unidades': 2,
      '/perfil': 3,
      '/tipos-de-atendimento': 4,
    }
    const url = location.pathname.replace(match.url + '/' + props.match.params.identificador, '')
    return abas[url] || 0
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_USUARIOS]}>
              <Lista
                {...props}
                filtros={filtros}
                fotos={fotos}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                pesquisar={listarUsuarios}
                recuperarFotoOriginalDoUsuarioPorIdentificador={recuperarFotoOriginalDoUsuarioPorIdentificador}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_USUARIO]}>
              <Formulario
                {...props}
                adicionarFotoDoUsuario={adicionarFotoDoUsuario}
                conselho={conselho}
                logins={logins}
                profissoes={profissoes}
                removerFotoDoUsuario={removerFotoDoUsuario}
                recuperarLogin={recuperarLogin}
                salvar={salvar}
                sexos={sexos}
                tratamentos={tratamentos}
                unidadesFederativas={unidadesFederativas}
              />
            </Permissao>
          )
        }
      />

      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/:identificador`,
          `${match.path}/:identificador/agenda`,
          `${match.path}/:identificador/agenda/bloqueios`,
          `${match.path}/:identificador/agenda/feriados-trabalhados`,
          `${match.path}/:identificador/agenda/instrucoes`,
          `${match.path}/:identificador/agenda/tipos-de-agendamento`,
          `${match.path}/:identificador/unidades`,
          `${match.path}/:identificador/perfil`,
          `${match.path}/:identificador/tipos-de-atendimento`,
        ]}
        render={
          props => {
            const identificador = props.match.params.identificador
            return (

              <div className='page-paciente-dados'>
                <div className='container'>
                  <div className='main-content-form'>
                    <div className='header-page-form'>
                      <Breadcrumb />
                    </div>

                    <Abas abaInicial={abaAtiva(props)} ajusteRedirecionamento={true}>
                      <div className='header-page-bar mt-0'>
                        <div className='header-page-nav'>
                          <ListaDeAbas className='tab'>
                            <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/${identificador}`)}>
                              Dados Gerais
                            </Aba>
                            {usuario.profissao ?
                              <Aba className={usuario.profissao ? 'tab-item' : 'tab-item disabled'} aoClicar={() => history.push(`${match.url}/${identificador}/agenda`)}>
                                Agenda
                              </Aba>
                              : null
                            }
                            <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/${identificador}/unidades`)}>
                              Unidades
                            </Aba>
                            <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/${identificador}/perfil`)}>
                              Perfil
                            </Aba>
                            <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/${identificador}/tipos-de-atendimento`)}>
                              Tipos de Atendimento
                            </Aba>
                          </ListaDeAbas>
                        </div>
                      </div>

                      <Paineis>

                        <Painel>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ALTERAR_USUARIO]}>
                                  <Editar
                                    {...props}
                                    adicionarFotoDoUsuario={adicionarFotoDoUsuario}
                                    ativarStatus={ativarStatus}
                                    conselho={conselho}
                                    fotos={fotos}
                                    identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
                                    inativarStatus={inativarStatus}
                                    profissoes={profissoes}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    recuperarFotoOriginalDoUsuarioPorIdentificador={recuperarFotoOriginalDoUsuarioPorIdentificador}
                                    removerFotoDoUsuario={removerFotoDoUsuario}
                                    salvar={salvar}
                                    sexos={sexos}
                                    tratamentos={tratamentos}
                                    unidadesFederativas={unidadesFederativas}
                                    usuario={usuario}
                                  />
                                </Permissao>
                              )
                            }
                          />
                        </Painel>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/agenda`}
                              render={
                                props => (
                                  <Agenda
                                    {...props}
                                    adicionarHorarioDaAgendaDoProfissionalDeSaude={adicionarHorarioDaAgendaDoProfissionalDeSaude}
                                    alterarHorarioDaAgendaDoProfissionalDeSaude={alterarHorarioDaAgendaDoProfissionalDeSaude}
                                    alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude={alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude}
                                    excluirHorarioDaAgendaDoProfissionalDeSaude={excluirHorarioDaAgendaDoProfissionalDeSaude}
                                    excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude={excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude}
                                    feriados={feriados}
                                    feriadosTrabalhados={agenda.feriadosTrabalhados}
                                    fotos={fotos}
                                    horarios={agenda.horarios}
                                    listarFeriados={listarFeriados}
                                    listarFeriadosTrabalhadosDoProfissional={listarFeriadosTrabalhadosDoProfissional}
                                    listarHorariosDaAgendaDoProfissionalDeSaude={listarHorariosDaAgendaDoProfissionalDeSaude}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    usuario={usuario}                                                                     
                                  />
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/agenda/bloqueios`}
                              render={
                                props => (
                                  <Bloqueios
                                    {...props}
                                    adicionarBloqueioNaAgendaDoProfissionalDeSaude={adicionarBloqueioNaAgendaDoProfissionalDeSaude}
                                    alterarBloqueioNaAgendaDoProfissionalDeSaude={alterarBloqueioNaAgendaDoProfissionalDeSaude}
                                    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude}
                                    alterarFiltrosDosBloqueiosNaAgendaDoProfissionalDeSaude={alterarFiltrosDosBloqueiosNaAgendaDoProfissionalDeSaude}
                                    bloqueios={agenda.bloqueios}
                                    feriados={feriados}
                                    feriadosTrabalhados={agenda.feriadosTrabalhados}
                                    fotos={fotos}
                                    listarBloqueiosNaAgendaDoProfissionalDeSaude={listarBloqueiosNaAgendaDoProfissionalDeSaude}
                                    listarFeriados={listarFeriados}
                                    listarFeriadosTrabalhadosDoProfissional={listarFeriadosTrabalhadosDoProfissional}
                                    listarMotivosDeBloqueioDaAgenda={listarMotivosDeBloqueioDaAgenda}
                                    motivos={agenda.bloqueios.motivos}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    remover={excluirBloqueioDaAgendaDoProfissionalDeSaude}
                                    removerSerie={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude}
                                    usuario={usuario}
                                  />
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/agenda/instrucoes`}
                              render={
                                props => (
                                  <Instrucoes
                                    {...props}
                                    adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude={adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude}
                                    alterarFiltrosDasIntrucoesDeAgendamentosDoProfissionalDeSaude={alterarFiltrosDasIntrucoesDeAgendamentosDoProfissionalDeSaude}
                                    alterarInstrucaoDeAgendamentoDoProfissionalDeSaude={alterarInstrucaoDeAgendamentoDoProfissionalDeSaude}
                                    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude}
                                    feriados={feriados}
                                    feriadosTrabalhados={agenda.feriadosTrabalhados}
                                    fotos={fotos}
                                    instrucoes={agenda.instrucoes}
                                    listarFeriados={listarFeriados}
                                    listarFeriadosTrabalhadosDoProfissional={listarFeriadosTrabalhadosDoProfissional}
                                    listarInstrucoesDeAgendamentosDoProfissionalDeSaude={listarInstrucoesDeAgendamentosDoProfissionalDeSaude}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    remover={excluirInstrucaoDeAgendamentoDoProfissionalDeSaude}
                                    removerSerie={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude}
                                    usuario={usuario}
                                  />
                                )
                              }
                            />                          
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/agenda/tipos-de-agendamento`}
                              render={
                                props => (
                                  <TiposDeAgendamentos
                                    {...props}
                                    adicionarTipoDeAgendamentoDoProfissional={adicionarTipoDeAgendamentoDoProfissional}
                                    fotos={fotos}
                                    tiposDeAgendamento={agenda.tiposDeAgendamento}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    removerTipoDeAgendamentoDoProfissional={removerTipoDeAgendamentoDoProfissional}
                                    listarTiposDeAgendamentoDasConfiguracoesDaAgenda={listarTiposDeAgendamentoDasConfiguracoesDaAgenda}
                                    listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda={listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda}
                                    usuario={usuario}
                                  />
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/agenda/feriados-trabalhados`}
                              render={
                                props => (
                                  <FeriadosTrabalhados
                                    {...props}
                                    feriadosTrabalhados={agenda.feriadosTrabalhados}
                                    adicionarFeriadoTrabalhadoDoProfissional={adicionarFeriadoTrabalhadoDoProfissional}
                                    removerFeriadoTrabalhadoDoProfissional={removerFeriadoTrabalhadoDoProfissional}
                                    fotos={fotos}
                                    listarFeriadosTrabalhadosDoProfissional={listarFeriadosTrabalhadosDoProfissional}
                                    listarFeriados={listarFeriados}
                                    feriados={feriados}
                                    recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                    usuario={usuario}
                                  />
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                        <Painel>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/unidades`}
                            render={
                              props => (
                                <Unidades
                                  {...props}
                                  recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                  usuario={usuario}
                                  fotos={fotos}
                                  adicionarUnidadeDoUsuario={adicionarUnidadeDoUsuario}
                                  removerUnidadeDoUsuario={removerUnidadeDoUsuario}
                                  listarEmpresasHabilitadas={listarEmpresasHabilitadas}
                                  listarUnidadesDaEmpresaDoUsuario={listarUnidadesDaEmpresaDoUsuario}
                                  listarUnidadesDoUsuario={listarUnidadesDoUsuario}
                                  unidades={unidades}
                                />
                              )
                            }
                          />
                        </Painel>
                        <Painel>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/perfil`}
                            render={
                              props => (
                                <Perfil
                                  {...props}
                                  recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                  usuario={usuario}
                                  fotos={fotos}
                                  adicionarPerfilDoUsuario={adicionarPerfilDoUsuario}
                                  removerPerfilDoUsuario={removerPerfilDoUsuario}
                                  listarPerfis={listarPerfis}
                                  listarPerfisDoUsuario={listarPerfisDoUsuario}
                                  perfil={perfil}
                                />
                              )
                            }
                          />
                        </Painel>
                        <Painel>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/tipos-de-atendimento`}
                            render={
                              props => (
                                <TiposDeAtendimento
                                  {...props}
                                  recuperarPorIdentificador={recuperarUsuarioPorIdentificador}
                                  usuario={usuario}
                                  fotos={fotos}
                                  habilitarTipoDeAtendimentoDoUsuario={habilitarTipoDeAtendimentoDoUsuario}
                                  desabilitarTipoDeAtendimentoDoUsuario={desabilitarTipoDeAtendimentoDoUsuario}
                                  listarTiposDeAtendimento={listarTiposDeAtendimento}
                                  listarTiposDeAtendimentoHabilitadosParaOUsuario={listarTiposDeAtendimentoHabilitadosParaOUsuario}
                                  tiposDeAtendimento={tiposDeAtendimento}
                                  tiposDeAtendimentoDoUsuario={tiposDeAtendimentoDoUsuario}
                                />
                              )
                            }
                          />
                        </Painel>
                      </Paineis>
                    </Abas>
                  </div>
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}