export const converterParaUrl = dados => window.URL.createObjectURL(new Blob([dados]))

export const recuperarFotoDoCache = async identificadorDaFoto => {
  const foto = await localStorage.getItem(`cache_lifecode_${identificadorDaFoto}`)
  if (!foto) return null
  return window.URL.createObjectURL(b64toBlob(foto))
}

export const gravarFotoNoCache = (dados, identificadorDaFoto) => {  
  try {
    localStorage.setItem(`cache_lifecode_${identificadorDaFoto}`, converterParaImagem(dados))    
  } catch (error) {
    localStorage.removeItem(`cache_lifecode_${identificadorDaFoto}`)
  }
  return converterParaUrl(dados)
}

export const converterParaImagem = (dados) => {
  let array = new Uint8Array(dados)
  let b64encoded = btoa([].reduce.call(array, 
      function (p, c) {
          return p + String.fromCharCode(c) 
      }, ''
  ))  
  return b64encoded
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
