import apiDoMajor from '../api-major'
import apiDoHercules from '../api-hercules'
import constantes from '../../configuracoes/constantes'

const adicionarUsuario = usuario => apiDoMajor.post('/usuarios', usuario)

const autenticarUsuario = credenciais => {
  const dados = {
    email: credenciais.email,
    senha: credenciais.senha,
    empresa: credenciais.empresa,
    unidade: credenciais.unidade
  }

  return apiDoMajor.post('/autenticacoes/passo-2', dados)
}

const listarUsuarios = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const parametroStatus = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativos=${filtros.ativo[0] === 'Ativo'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10'

  return apiDoMajor.get(`/usuarios${paginacao}${parametroNome}${parametroStatus}${ordem}${tamanhoDaPaginaFormatado}`)
}

const recuperarTokenDoFirestore = () => apiDoHercules.get('/token')

const recuperarUsuarioAutenticado = token => {
  const configuracoes = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return apiDoMajor.get('/token/dados', configuracoes)
}

const salvarUsuario = usuario => apiDoMajor.put('/usuarios/' + usuario.identificador, usuario)

const recuperarUsuarioPorIdentificador = identificador => apiDoMajor.get(`/usuarios/${identificador}`)

const alterarSenhaDoUsuario = (email, senha, novaSenha) => apiDoMajor.put(`/usuarios/${email}/senha`,
  { senha, novaSenha }
)

const listarEmpresasHabilitadasParaOLogin = (email, senha) => {
  return apiDoMajor.post(`/autenticacoes/passo-1`, { email, senha })
}

const listarEmpresasHabilitadas = () => apiDoMajor.get(`/empresas/habilitadas`)

const recuperarFotoDoUsuarioPorIdentificador = (identificador, tamanho = constantes.tamanhoDaFoto.miniatura) => apiDoMajor.get(`/usuarios/${identificador}/foto?tamanho=${tamanho}`, {
  responseType: 'arraybuffer'
})

const recuperarLogin = email => apiDoMajor.get(`/logins/${email}/`)

const alterarStatusDoUsuario = (identificador, status, confirmarQueQuerDesativar) => apiDoMajor.put(`/usuarios/${identificador}/status`, { status, confirmarQueQuerDesativar })

const adicionarPerfilDoUsuario = (identificadorDoUsuario, perfil) => {
  return apiDoMajor.post(`/usuarios/${identificadorDoUsuario}/perfis`,
    { identificadorDoUsuario, perfil })
}

const listarPerfisDoUsuario = identificador => apiDoMajor.get(`/usuarios/${identificador}/perfis?tamanhoDaPagina=1000&ordem=+nome`)

const removerPerfilDoUsuario = (identificadorDoUsuario, perfil) => {
  return apiDoMajor.delete(`/usuarios/${identificadorDoUsuario}/perfis/${perfil}`,
    { identificadorDoUsuario, perfil })
}

const recuperarSenha = email => apiDoMajor.post(`/logins/${email}/pedidos-de-troca-de-senha`)

const redefinirSenha = (email, novaSenha, pedidoDeTrocaDeSenha) => apiDoMajor.put(`/logins/${email}/senha`, { novaSenha, pedidoDeTrocaDeSenha })

const alterarUnidadeDoLogin = (empresa, unidade) => apiDoMajor.put(`/token`, { empresa, unidade })

const efetuarLogout = () => apiDoMajor.post(`/logout`, {})

export default {
  adicionarUsuario,
  autenticarUsuario,
  alterarSenhaDoUsuario,
  efetuarLogout,
  listarEmpresasHabilitadasParaOLogin,
  listarEmpresasHabilitadas,
  listarUsuarios,
  recuperarLogin,
  recuperarFotoDoUsuarioPorIdentificador,
  recuperarUsuarioAutenticado,
  recuperarUsuarioPorIdentificador,
  salvarUsuario,
  alterarStatusDoUsuario,
  adicionarPerfilDoUsuario,
  listarPerfisDoUsuario,
  removerPerfilDoUsuario,
  recuperarSenha,
  recuperarTokenDoFirestore,
  redefinirSenha,
  alterarUnidadeDoLogin
}