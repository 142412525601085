import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE
} from '../../../tipos'

const listouAvaliacoesDaSobrecargaDoCuidadorDoPaciente = (identificadorDoPaciente, avaliacoes) => ({
  type: LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  identificadorDoPaciente,
  avaliacoes
})

const erroAoListarAvaliacoesDaSobrecargaDoCuidadorDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  erro,
  parametros
})

export const listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE })

  try {
    const resultado = await api.listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente)

    dispatch(listouAvaliacoesDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAvaliacoesDaSobrecargaDoCuidadorDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as avaliações da sobrecarga do cuidador do paciente.')))
  }
}

const adicionouAvaliacaoDaSobrecargaDoCuidadorDoPaciente = avaliacao => ({
  type: ADICIONOU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  avaliacao
})

const erroAoAdicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE })

  try {
    const resultado = await api.adicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(adicionouAvaliacaoDaSobrecargaDoCuidadorDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar avaliação da sobrecarga do cuidador do paciente.')))

    return false
  }
}

const alterouAvaliacaoDaSobrecargaDoCuidadorDoPaciente = avaliacao => ({
  type: ALTEROU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  avaliacao
})

const erroAoAlterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  erro,
  parametros
})

export const alterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE })

  try {
    const resultado = await api.alterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(alterouAvaliacaoDaSobrecargaDoCuidadorDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar avaliação da sobrecarga do cuidador do paciente.')))

    return false
  }
}

const removeuAvaliacaoDaSobrecargaDoCuidadorDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  erro,
  parametros
})

export const removerAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificadorDoPaciente, identificadorDaAvaliacao) => async dispatch => {
  dispatch({ type: REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE })

  try {
    const resultado = await api.removerAvaliacaoDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente, identificadorDaAvaliacao)

    dispatch(removeuAvaliacaoDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente))
    dispatch(listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverAvaliacaoDaSobrecargaDoCuidadorDoPaciente(erro, { identificadorDoPaciente, identificadorDaAvaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover avaliação da sobrecarga do cuidador do paciente.')))

    return false
  }
}