import React, { useEffect } from 'react'
import FileSaver from 'file-saver'
import { Switch } from 'react-router-dom'
import moment from 'moment'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import { formatarDataParaFormatoUniversal, formatarDataEHoraParaFormatoUniversal } from '../../../bibliotecas/data'
import Formulario from './formulario'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function Internacoes(props) {
  const {
    fazerDownloadDeInternacoes,
    filtros,
    listarProgramasDoRelatorioDeInternacoes,
    match,
    programas
  } = props

  const filtrosIniciais = {
    ...filtros,
    dataDeInicio: filtros.dataDeInicio || moment().startOf('month').format('YYYY-MM-DD'),
    dataDeFim: filtros.dataDeFim || moment().format('YYYY-MM-DD'),
  }

  useEffect(() => {
    listarProgramasDoRelatorioDeInternacoes()
  }, [listarProgramasDoRelatorioDeInternacoes])

  const gerarNome = (tipo, formulario) => {
    const inicio = formatarDataParaFormatoUniversal(formulario.dataDeInicio).split('-').join('_')
    const fim = formatarDataParaFormatoUniversal(formulario.dataDeFim).split('-').join('_')
    const agora = formatarDataEHoraParaFormatoUniversal(new Date()).split('-').join('_').split(' ').join('_').split(':').join('_')

    return `${tipo}_de_${inicio}_ateh_${fim}_em_${agora}.csv`
  }

  const download = async formulario => {
    const retorno = await fazerDownloadDeInternacoes(formulario)
    const nome = gerarNome('internacoes', formulario)

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'text/csv' })
      FileSaver.saveAs(arquivo, nome)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <div className='relatorios'>
              <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_INTERNACOES, permissoes.LISTAR_PROGRAMAS]}>
                <Formulario
                  {...props}
                  filtrosIniciais={filtrosIniciais}
                  download={download}
                  programas={programas}
                />
              </Permissao>
            </div>
          )
        }
      />
    </Switch>
  )
}