import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import { useUsuarioLogado } from '../../../../seguranca/provedor-de-autenticacao'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import { Interpretacao } from '../../../../../design/icones'
import * as permissoes from '../../../../seguranca/permissoes'
import Modal from 'react-modal'
import moment from 'moment'
import Formulario from './formulario'
import Resumo from './resumo'
import InterpretacaoMedica from './interpretacao-medica'

import { LISTAR_MINI_MENTAIS, REMOVER_MINI_MENTAL } from '../../../../../acoes/tipos'

export default function AvaliacaoFisicaEFuncional({ identificadorDoPaciente, miniMentais }) {
  const {
    adicionarArquivo,
    listarEstadosDaMemoriaAtual,
    estadosDaMemoriaAtual,
    listarEstadosDaMemoriaComparadoHaUmAnoAtras,
    estadosDaMemoriaComparadoHaUmAnoAtras,
    listarMiniMentais,
    adicionarMiniMental,
    alterarMiniMental,
    recuperarDesenhoDoMiniMental,
    removerMiniMental,
    adicionarInterpretacaoMedicaDoMiniMental,
    removerInterpretacaoMedicaDoMiniMental
  } = useProntuario()

  const { usuario } = useUsuarioLogado()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [exibirInterpretacaoMedica, setExibirInterpretacaoMedica] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_MINI_MENTAL])

  useEffect(() => {
    if (miniMentais === undefined) {
      listarMiniMentais(identificadorDoPaciente)
    }
  }, [listarMiniMentais, identificadorDoPaciente, miniMentais])

  useEffect(() => { listarEstadosDaMemoriaAtual() }, [listarEstadosDaMemoriaAtual])
  useEffect(() => { listarEstadosDaMemoriaComparadoHaUmAnoAtras() }, [listarEstadosDaMemoriaComparadoHaUmAnoAtras])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirInterpretacaoMedica(item) {
    setSelecionado(item)
    setExibirInterpretacaoMedica(true)
  }

  function fecharInterpretacaoMedica() {
    setExibirInterpretacaoMedica(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarMiniMental(identificadorDoPaciente, item) : await adicionarMiniMental(identificadorDoPaciente, item)
  }

  const adicionarInterpretacaoMedica = async item => {
    return await adicionarInterpretacaoMedicaDoMiniMental(identificadorDoPaciente, selecionado.identificador, item)
  }

  const removerInterpretacaoMedica = async () => {
    return await removerInterpretacaoMedicaDoMiniMental(identificadorDoPaciente, selecionado.identificador)
  }

  if (miniMentais === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário do Mini Mental'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          adicionarArquivo={adicionarArquivo}
          fechar={fecharFormulario}
          identificadorDoPaciente={identificadorDoPaciente}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicialMiniMental(selecionado)}
          estadosDaMemoriaAtual={estadosDaMemoriaAtual}
          estadosDaMemoriaComparadoHaUmAnoAtras={estadosDaMemoriaComparadoHaUmAnoAtras}
          recuperarDesenhoDoMiniMental={
            selecionado && selecionado.identificador && selecionado.secaoLinguagem.desenhoDoMiniMental ?
              () => recuperarDesenhoDoMiniMental(identificadorDoPaciente, selecionado.identificador, selecionado.secaoLinguagem.desenhoDoMiniMental) :
              false}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo do Mini Mental'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicialMiniMental(selecionado)}
          estadosDaMemoriaAtual={estadosDaMemoriaAtual}
          estadosDaMemoriaComparadoHaUmAnoAtras={estadosDaMemoriaComparadoHaUmAnoAtras}
          recuperarDesenhoDoMiniMental={() => recuperarDesenhoDoMiniMental(identificadorDoPaciente, selecionado.identificador, selecionado.secaoLinguagem.desenhoDoMiniMental)}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção do Mini Mental'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerMiniMental(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirInterpretacaoMedica}
        contentLabel='Modal para interpretação médica do Mini Mental'
        className='modal'
        style={{ content: { width: '1100px' } }}
      >
        <InterpretacaoMedica
          selecionada={selecionado}
          fechar={fecharInterpretacaoMedica}
          valores={recuperaValorInicialMiniMental(selecionado)}
          salvar={adicionarInterpretacaoMedica}
          remover={removerInterpretacaoMedica}
          estadosDaMemoriaAtual={estadosDaMemoriaAtual}
          estadosDaMemoriaComparadoHaUmAnoAtras={estadosDaMemoriaComparadoHaUmAnoAtras}
          recuperarDesenhoDoMiniMental={() => recuperarDesenhoDoMiniMental(identificadorDoPaciente, selecionado.identificador, selecionado.secaoLinguagem.desenhoDoMiniMental)}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_MINI_MENTAIS]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_MINI_MENTAL]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item => {
                  const podeInterpretar = item.secaoInterpretacaoMedica === null || (usuario.identificador === item.secaoInterpretacaoMedica?.interpretadoPor.identificador)

                  return (podeInterpretar &&
                    <Permissao key='interpretar' permissoes={[permissoes.ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]}>
                      <button
                        className='table-items-expanded__bt --edit'
                        title='Interpretar'
                        onClick={() => abrirInterpretacaoMedica(item)}
                        type='button'
                        key={`interpretar_${item.identificador}`}
                      >
                        <i className='icon'><Interpretacao /></i>
                      </button>
                    </Permissao>
                  )
                }
                ,
                item =>
                  <button
                    className='table-items-expanded__bt --edit'
                    title='Ver resumo'
                    onClick={() => abrirResumo(item)}
                    type='button'
                    key={`questionario_${item.identificador}`}
                  >
                    <i className='icon icon-doc-text'></i>
                  </button>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_MINI_MENTAL]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                      key={`edit_${item.identificador}`}
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={miniMentais}
              exibirBotaoAdicionar={podeAdicionar}
              expandida={true}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros do Mini Mental.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Resultado'
                campoDeDados='pontuacaoTotal'
              />
              <LinhaExpandida className='col-xs-10 col-lg-12'>
                <AgrupamentoDeColunas>
                  <Coluna
                    nome='DATA'
                    renderizar={item => formatarDataParaFormatoLocal(item.data)}
                  />
                  <Coluna
                    nome='MEMÓRIA'
                    renderizar={item => (item.secaoMemoria.pontuacaoEstadoDaMemoriaAtual + item.secaoMemoria.pontuacaoEstadoDaMemoriaComparadoHaUmAnoAtras).toString() || '0'}
                  />
                  <Coluna
                    nome='ORIENTAÇÃO TEMPORAL'
                    renderizar={item => item.secaoOrientacaoTemporal.pontuacaoOrientacaoTemporal.toString() || '0'}
                  />
                  <Coluna
                    nome='ORIENTAÇÃO ESPACIAL'
                    renderizar={item => item.secaoOrientacaoEspacial.pontuacaoOrientacaoEspacial.toString() || '0'}
                  />
                  <Coluna
                    nome='MEMÓRIA IMEDIATA'
                    renderizar={item => item.secaoRegistroDaMemoriaImediata.pontuacaoRegistroDaMemoriaImediata.toString() || '0'}
                  />
                  <Coluna
                    nome='ATENÇÃO E CÁLCULO'
                    renderizar={item => item.secaoAtencaoECalculo.pontuacaoAtencaoECalculo.toString() || '0'}
                  />
                  <Coluna
                    nome='MEMÓRIA RECENTE'
                    renderizar={item => item.secaoMemoriaRecente.pontuacaoMemoriaRecente.toString() || '0'}
                  />
                  <Coluna
                    nome='LINGUAGEM'
                    renderizar={item => (item.secaoLinguagem.pontuacaoNomearCanetaERelogio + item.secaoLinguagem.pontuacaoRepetirFrase + item.secaoLinguagem.pontuacaoLerPapel + item.secaoLinguagem.pontuacaoComandosPapel + item.secaoLinguagem.pontuacaoFraseCompleta + item.secaoLinguagem.pontuacaoDesenhoPentagonos).toString() || '0'}
                  />
                  <Coluna
                    nome='RESULTADO'
                    campoDeDados='pontuacaoTotal'
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirMiniMental = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_MINI_MENTAL]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover o Mini Mental?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirMiniMental}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicialMiniMental = selecionado => {
  if (selecionado) {
    return {
      ...selecionado,
      secaoInterpretacaoMedica: {
        ...selecionado.secaoInterpretacaoMedica,
        interpretacaoMedica: selecionado.secaoInterpretacaoMedica === null ? '' : selecionado.secaoInterpretacaoMedica.interpretacaoMedica
      }
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    secaoMemoria: {
      estadoDaMemoriaAtual: undefined,
      estadoDaMemoriaComparadoHaUmAnoAtras: undefined,
    },
    secaoOrientacaoTemporal: {
      diaDaSemana: false,
      diaDoMes: false,
      mes: false,
      ano: false,
      horaAproximada: false
    },
    secaoOrientacaoEspacial: {
      onde: false,
      lugar: false,
      bairro: false,
      cidade: false,
      horaEstadoAproximada: false
    },
    secaoRegistroDaMemoriaImediata: {
      arvore: false,
      mesa: false,
      cachorro: false,
      repeticoes: undefined,
    },
    secaoAtencaoECalculo: {
      cemMenosSete: false,
      noventaETresMenosSete: false,
      oitentaESeisMenosSete: false,
      setentaENoveMenosSete: false,
      setentaEDoisMenosSete: false
    },
    secaoMemoriaRecente: {
      arvore: false,
      mesa: false,
      cachorro: false,
    },
    secaoLinguagem: {
      caneta: false,
      relogio: false,
      repetirFrase: undefined,
      lerPapel: undefined,
      pegarPapel: false,
      dobrarPapel: false,
      colocarPapelNoChao: false,
      fraseCompleta: undefined,
      desenhoPentagonos: undefined,
      desenhoDoMiniMental: undefined,
    },
    secaoInterpretacaoMedica: {
      interpretacaoMedica: ''
    }
  }
}