import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import { ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS, LISTOU_USUARIOS_BLOQUEADOS, LISTAR_USUARIOS_BLOQUEADOS } from '../tipos'

const listouUsuariosBloqueados = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_USUARIOS_BLOQUEADOS,
  filtros,
  ordenacao,
  paginaDeDados,
})

const erroAoListarUsuariosBloqueados = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS,
  erro,
  parametros,
})

export const listarUsuariosBloqueados = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_USUARIOS_BLOQUEADOS })

  try {
    const resultado = await api.listarUsuariosBloqueados(filtros, pagina, ordenacao)
    dispatch(listouUsuariosBloqueados(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUsuariosBloqueados(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os usuários bloqueados.')))
  }
}