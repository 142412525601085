import { connect } from 'react-redux'
import acoes from '../../acoes/index'
import Nps from './index'

const mapStatesToProps = state => {
  return {
    avaliacao: state.nps.avaliacao,
    imagens: state.imagens
  }
}

export default connect(mapStatesToProps, acoes)(Nps)