import {
  LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT
} from '../../acoes/tipos'

const estadoInicial = {
  frequencias: [],
  avaliacoes: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT: {
      return {
        ...state,
        frequencias: action.frequenciasNaEscalaZarit
      }
    }

    case LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT: {
      return {
        ...state,
        avaliacoes: action.avaliacoesDaSobrecargaDoCuidador
      }
    }

    default: {
      return state
    }
  }
}