import React, { useEffect } from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, Input } from '../../../formik/formulario'
import { FieldArray } from 'formik'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import arquivoPdf from '../../../../design/img/pdf-file.svg'
import outrosArquivos from '../../../../design/img/file.svg'

import {
  ADICIONAR_ARQUIVO,
  LISTAR_TIPOS_DE_EXAME
} from '../../../../acoes/tipos'

export default function Formulario({
  fechar,
  tiposDeExame,
  adicionarArquivo,
  fazerDownloadDoExameDoAtendimento,
  arquivos,
  selecionado,
  identificadorDoPaciente,
  identificadorDoAtendimento,
  arquivosDoExame,
  ...rest
}) {

  const salvar = (valores, { resetForm }) => {
    resetForm({})
    rest.salvar(valores)
    fechar()
  }

  return (
    <Spinner operacoes={[ADICIONAR_ARQUIVO]}>
      {({ processando }) => (
        <>
          <Spinner operacoes={[LISTAR_TIPOS_DE_EXAME]}>
            <FormularioDoFormik
              valoresIniciais={{
                identificador: selecionado ? selecionado.identificador : uuidv4(),
                tipo: selecionado ? selecionado.tipo : '',
                data: selecionado ? selecionado.data : '',
                sintese: selecionado ? selecionado.sintese : '',
                arquivos: selecionado ? selecionado.arquivos : []
              }}
              esquemaDeValidacoes={Yup.object().shape({
                tipo: Yup.string().required('Obrigatório.'),
                data: Yup.string().required('Obrigatório.').nullable()
              })}
              acao={salvar}
            >
              {({ values }) => (
                <fieldset>
                  <h2 className='form-title'>{selecionado ? 'Alterar Exame' : 'Adicionar Exame'}</h2>
                  <r-grid columns-md={12} columns-lg={12}>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <Select
                        nome='tipo'
                        titulo='Tipo *'
                        tabIndex={1}
                        itens={tiposDeExame}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={2} span-md={6} span-lg={6}>
                      <DatePicker
                        nome='data'
                        titulo='Data *'
                        tabIndex={2}
                        maxDate={new Date()}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        titulo='Síntese'
                        nome='sintese'
                        tabIndex={3}
                      />
                    </r-cell>
                    <FieldArray
                      name='arquivos'
                      render={({ push, remove }) =>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <RenderizarArquivos
                            adicionarArquivoDoExame={push}
                            remover={remove}
                            adicionarArquivo={adicionarArquivo}
                            fazerDownloadDoExameDoAtendimento={fazerDownloadDoExameDoAtendimento}
                            arquivosAdicionados={values.arquivos}
                            processando={processando}
                            identificadorDoAtendimento={identificadorDoAtendimento}
                            identificadorDoPaciente={identificadorDoPaciente}
                            arquivosDoExame={arquivosDoExame}
                          />
                        </r-cell>
                      }
                    />
                  </r-grid>
                  <hr className='separator mt-1 mb-1'></hr>
                  <div className='list-btn'>
                    <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
                    <button type='submit' className={`button --primary ${(values.arquivos.length === 0 && values.sintese === '') && 'is-disabled'}`}>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
                  </div>
                </fieldset>
              )}
            </FormularioDoFormik>
          </Spinner>
        </>
      )}
    </Spinner>
  )
}

function RenderizarArquivos(props) {
  const {
    remover,
    adicionarArquivo,
    adicionarArquivoDoExame,
    fazerDownloadDoExameDoAtendimento,
    arquivosAdicionados,
    identificadorDoAtendimento,
    identificadorDoPaciente,
    processando,
    arquivosDoExame
  } = props

  const adicionar = async evento => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const leitor = new FileReader();
    const arquivoAdicionado = await adicionarArquivo(arquivo, 'atendimento')

    leitor.onloadend = () => {
      if (arquivoAdicionado) {
        adicionarArquivoDoExame({ ...arquivoAdicionado, url: leitor.result })
      }
    }

    leitor.readAsDataURL(arquivo)
  }

  return (
    <>
      <label className='form-label'>Arquivos</label>
      <div className='form-summary'>
        <div className='form-summary-group'>
          <label
            htmlFor='sumario'
            className={processando ? 'button --primary is-loading m0' : 'button --primary m0'}
          >
            Adicionar Arquivo
            <i className='icon icon-folder-empty mleft-5'></i>
          </label>
          <input
            type='file'
            name='sumario'
            id='sumario'
            tabIndex={4}
            onChange={(evento) => adicionar(evento)}
            onClick={evento => { evento.target.value = null }}
          />
        </div>
        <div className='form-summary-images'>
          {arquivosAdicionados.length > 0 && arquivosAdicionados.map((x, index) =>
            <div key={x.identificador}>
              <Arquivo
                arquivo={x}
                remover={remover}
                listaDeArquivos={arquivosDoExame}
                index={index}
                fazerDownloadDoExameDoAtendimento={fazerDownloadDoExameDoAtendimento}
                identificadorDoAtendimento={identificadorDoAtendimento}
                identificadorDoPaciente={identificadorDoPaciente}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function Arquivo({ arquivo = undefined, listaDeArquivos, remover, index, identificadorDoAtendimento, identificadorDoPaciente, fazerDownloadDoExameDoAtendimento }) {
  useEffect(() => {
    if (!arquivo.url) {
      fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, arquivo.identificador)
    }
  }, [fazerDownloadDoExameDoAtendimento, arquivo, identificadorDoAtendimento, identificadorDoPaciente])

  const url = arquivo && listaDeArquivos && listaDeArquivos[arquivo.identificador] ? listaDeArquivos[arquivo.identificador].url : arquivo.url
  const extensao = arquivo.extensao.toUpperCase()
  const imagens = ['.JPG', '.JPEG', '.PNG', '.JFIF']

  const recuperarThumbnail = () => {
    if (imagens.find(x => x === extensao)) {
      return <img src={url} alt='exame' />
    } else if (extensao === '.PDF') {
      return <img src={arquivoPdf} alt='exame' />
    } else {
      return <img src={outrosArquivos} alt='exame' />
    }
  }

  const fazerDownload = async exame => {
    const dados = await fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, exame.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), exame.nome)
    }
  }

  const alertaDownload = () => {
    confirmAlert({
      title: 'Alerta',
      message: `Para fazer o download do arquivo é necessário salvar a seção.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  return (
    <>
      {url ?
        <>
          <div className='form-summary-image exames'>
            {recuperarThumbnail()}
            <div className='form-summary-image-tools'>
              <button
                className='form-summary-image-zoom'
                aria-label='Download'
                type='button'
                onClick={() => arquivo.identificador && !arquivo.url ? fazerDownload(arquivo) : alertaDownload()}
              >
                <i className='icon icon-download-cloud'></i>
              </button>
              <button className='form-summary-image-remove' aria-label='Remover' type='button' onClick={() => remover(index)}><i className='icon icon-remove'></i></button>
            </div>
          </div>
          <p className='exames nome' title={arquivo.nome}>{arquivo.nome}</p>
        </>
        : 'Carregando...'
      }
    </>
  )
}