import React, { useMemo } from 'react'

export default function NotificacaoQuandoOPacienteJaTemAgendamentosNosProximosMeses({ proximosAgendamentos = [] }) {
  const todosAgendamentosDoPaciente = useMemo(() => {
    if (!proximosAgendamentos || proximosAgendamentos.length === 0) {
      return {}
    }

    const agendamentos = Object.keys(proximosAgendamentos).filter(key => key.startsWith('agendamento_')).map(key => proximosAgendamentos[key])
    const horarios = Object.keys(proximosAgendamentos).filter(key => key.startsWith('horario_')).map(key => proximosAgendamentos[key])
    const tipos = Object.keys(proximosAgendamentos).filter(key => key.startsWith('tipo_')).map(key => proximosAgendamentos[key])
    const usuarios = Object.keys(proximosAgendamentos).filter(key => key.startsWith('profissional_')).map(key => proximosAgendamentos[key])

    const resultado = agendamentos.map((agendamento, index) => {
      return { agendamento, horario: horarios[index], tipo: tipos[index], usuario: usuarios[index] }
    })

    return resultado;
  }, [proximosAgendamentos])

  if (!proximosAgendamentos || proximosAgendamentos.length === 0) { 
    return null 
  }

  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={6} span-lg={12}>
        <div className='form-choice-alerta my-1'>
          <div className='form-choice-alerta__item'>
            <i className='icon icon-attention-circled'></i>
          </div>
          <div className='form-choice-alerta__item'>
            <h2>
              Paciente {proximosAgendamentos.nome_do_paciente} possui{' '}
              {todosAgendamentosDoPaciente.length === 1 ? 'o' : 'os'} seguinte
              {todosAgendamentosDoPaciente.length === 1 ? '' : 's'} agendamento
              {todosAgendamentosDoPaciente.length === 1 ? '' : 's'} nos próximos 5 meses:
            </h2>
            {todosAgendamentosDoPaciente.map((at) => (
              <ul key={at.agendamento}>
                <li>{at.tipo}: {at.usuario} em {at.horario}</li>
              </ul>
            ))}
          </div>
        </div>
      </r-cell>
    </r-grid>
  )
}