import React from 'react'
import moment from 'moment'
import CampoDePesquisa from '../../campo-de-pesquisa'
import avatar from '../../../design/img/no-thumb.png'

export default function ListaDeProfissionais(props) {
  const {
    alterarTermoDeProfissionaisDeSaudeDaAgenda,
    data,
    filtros,
    fotos = [],
    history,
    profissional,
    profissionaisDeSaudeFiltrados
  } = props

  const selecionarProfissional = identificador => {
    history.push(`/agendamentos/agenda/${identificador}/data/${moment(data).format('YYYY-MM-DD')}`)
  }

  const renderizarProfissional = item => {
    return (
      <div key={item.identificador} className={`box-panel__sidebar__item --user-doctor ${profissional && profissional.identificador === item.identificador ? 'is-selected' : ''}`}>
        <div className="user-doctor" onClick={() => selecionarProfissional(item.identificador)}>
          <img
            alt={item.nome}
            className='user-doctor__img'
            src={(item.foto && fotos[item.foto]) || avatar}
          />
          <div className="user-doctor__info">
            <p className="user-doctor__name">{item.nome}</p>
            <p className="user-doctor__specialty">
              <strong>{item.profissao.nome}</strong>
              {item.profissao.especialidades.length > 0 &&
                <span> | </span>
              }
              {item.profissao.especialidades.map(especialidade => <span key={especialidade}> {especialidade}</span>)}
            </p>
            {!item.ativo && <p className='user-doctor__specialty status'><strong>USUÁRIO INATIVO</strong></p>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="box-panel__sidebar">
      <div className="box-panel__sidebar__actions">
        <CampoDePesquisa
          autoPesquisar={true}
          pesquisar={alterarTermoDeProfissionaisDeSaudeDaAgenda}
          placeholder='Nome, Profissão ou Especialidade'
          valor={filtros.termo ? filtros.termo : ''}
          filtrosAplicados={filtros.ativo}
          filtros={['Ativo', 'Inativo']}
          filtrar={true}
        />
      </div>
      <div className="box-panel__sidebar__items">
        {profissionaisDeSaudeFiltrados.length === 0 &&
          <div className='container-nenhum-profissional-encontrado'>
            <span>Nenhum profissional encontrado</span>
          </div>
        }
        {profissionaisDeSaudeFiltrados.length > 0 &&
          profissionaisDeSaudeFiltrados.map(item => renderizarProfissional(item))
        }
      </div>
    </div>
  )
}