import api from './api-major'

const fazerDownloadDaPrescricao = (identificadorDoPaciente, identificadorDoAtendimento, identificador, tamanhoDaPagina, adicionarData) => {
  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/prescricoes/${identificador}?tamanhoDaPagina=${tamanhoDaPagina}&adicionarData=${adicionarData}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  fazerDownloadDaPrescricao,
}