import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Moment from 'moment'
import * as Yup from 'yup'
import Modal from 'react-modal'
import AbasDaAgenda from './abas'
import Cabecalho from '../cabecalho'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, DatePicker, Select } from '../../formik/formulario'
import { Coluna, Tabela } from '../../tabela'
import { extendMoment } from 'moment-range'
import { formatarDataParaFormatoUniversal, formatarDataEHoraParaFormatoLocal, dataIgualOuAnterior, formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import FormularioBloqueio from './bloqueio/formulario'
import SeletorDeDataDaInstrucao from './seletor-de-data'
import chroma from 'chroma-js'

import {
  LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
} from '../../../acoes/tipos'

const moment = extendMoment(Moment)

export default function Bloqueios(props) {
  const {
    adicionarBloqueioNaAgendaDoProfissionalDeSaude,
    alterarBloqueioNaAgendaDoProfissionalDeSaude,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude,
    feriados = [],
    feriadosTrabalhados,
    fotos,
    listarBloqueiosNaAgendaDoProfissionalDeSaude,
    listarFeriados,
    listarFeriadosTrabalhadosDoProfissional,
    listarMotivosDeBloqueioDaAgenda,
    history,
    match,
    motivos,
    recuperarPorIdentificador,
    remover,
    removerSerie,
    usuario = {},
    bloqueios: {
      paginaDeDados,
      ...restanteBloqueios
    },
  } = props

  const { identificador } = match.params
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState({
    ...restanteBloqueios.filtros,
    inicio: formatarDataParaFormatoUniversal(moment().startOf('month')),
    fim: formatarDataParaFormatoUniversal(moment().endOf('month'))
  })
  const [exibirAdicionarBloqueio, setExibirAdicionarBloqueio] = useState(false)
  const [exibirAlterarBloqueio, setExibirAlterarBloqueio] = useState(false)
  const [bloqueioSelecionado, setBloqueioSelecionado] = useState('')
  const [exibirCalendario, setExibirCalendario] = useState(true)
  const [exibirTabela, setExibirTabela] = useState(false)
  const [ultimaDataCalendario, setUltimaDataCalendario] = useState()
  const diasDaSemana = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"]

  const podeAdicionarBloqueio = usePossuiAsPermissoes([permissoes.ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE])

  useEffect(() => {
    listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, filtros, pagina)
  }, [listarBloqueiosNaAgendaDoProfissionalDeSaude, identificador, filtros, pagina])

  useEffect(() => {
    listarMotivosDeBloqueioDaAgenda()
  }, [listarMotivosDeBloqueioDaAgenda])

  useEffect(() => {
    listarFeriados({ ano: moment(filtros.data).year().toString() }, 1, { ordem: "+", nome: "data" }, 1000)
  }, [listarFeriados, filtros])

  useEffect(() => {
    listarFeriadosTrabalhadosDoProfissional(identificador)
  }, [listarFeriadosTrabalhadosDoProfissional, identificador])

  const listar = numeroDaPagina => {
    setPagina(numeroDaPagina)
  }

  const pesquisarBloqueiosDeAgendamentosCalendario = dados => {
    setFiltros({
      ...dados,
      inicio: formatarDataParaFormatoUniversal(moment(dados).startOf('month')),
      fim: formatarDataParaFormatoUniversal(moment(dados).endOf('month'))
    })
  }

  const pesquisarBloqueiosDeAgendamentosTabela = dados => {
    setFiltros({
      ...dados,
      inicio: formatarDataParaFormatoUniversal(dados.inicio),
      fim: formatarDataParaFormatoUniversal(dados.fim)
    })
  }

  const removerSelecionado = async identificadorDoBloqueio => {
    const removeu = await remover(identificador, identificadorDoBloqueio)

    if (removeu) {
      fecharAlterar()
      listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, filtros, pagina)
    }
  }

  const removerSerieSelecionada = async identificadorDoBloqueio => {
    const removeu = await removerSerie(identificador, identificadorDoBloqueio)

    if (removeu) {
      fecharAlterar()
      listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, filtros, pagina)
    }
  }

  const recuperarMotivo = codigo => {
    if (!codigo) {
      return ''
    }
    const motivo = motivos.find(x => x.codigo === codigo)

    return motivo ? motivo.nome : ''
  }

  function abrirAdicionar() {
    setExibirAdicionarBloqueio(true);
  }

  function fecharAdicionar() {
    setExibirAdicionarBloqueio(false);
  }

  function abrirAlterar(bloqueioSelecionado) {
    setBloqueioSelecionado(bloqueioSelecionado)
    setExibirAlterarBloqueio(true)
  }

  function fecharAlterar() {
    setExibirAlterarBloqueio(false)
    setBloqueioSelecionado('')
  }

  async function alterarParaCalendario() {
    setFiltros({
      ...filtros,
      texto: '',
      inicio: formatarDataParaFormatoUniversal(moment(ultimaDataCalendario).startOf('month')),
      fim: formatarDataParaFormatoUniversal(moment(ultimaDataCalendario).endOf('month'))
    })

    await listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, { inicio: formatarDataParaFormatoUniversal(moment(ultimaDataCalendario).startOf('month')), fim: formatarDataParaFormatoUniversal(moment(ultimaDataCalendario).endOf('month')) })

    setExibirCalendario(true)
    setExibirTabela(false)
  }

  function alterarParaTabela() {
    setUltimaDataCalendario(filtros.inicio)
    setExibirCalendario(false)
    setExibirTabela(true)
  }

  const preencherCalendario = dados => {
    if (!dados || dados.length === 0) return null

    const dataDoBloqueioDoMes = dados[0].data
    const semanas = {}
    const diaDoMes = moment(dataDoBloqueioDoMes).startOf('month')
    const ultimoDiaDoMes = moment(dataDoBloqueioDoMes).endOf('month')

    while (diaDoMes.isBefore(ultimoDiaDoMes) || diaDoMes.isSame(ultimoDiaDoMes, 'day')) {
      const semanaDoAno = diaDoMes.week()
      const diaDaSemana = diaDoMes.format('ddd').toUpperCase()

      if (!semanas[semanaDoAno]) {
        semanas[semanaDoAno] = {}
      }

      if (!semanas[semanaDoAno][diaDaSemana]) {
        semanas[semanaDoAno][diaDaSemana] = []
      }

      semanas[semanaDoAno][diaDaSemana].push(formatarDataParaFormatoLocal(diaDoMes))
      diaDoMes.add(1, 'day')
    }

    const semanasOrdenadas = Object.keys(semanas).sort((semanaA, semanaB) => {
      const diaA = diasDaSemana
        .map((dia) => semanas[semanaA][dia])
        .find((registro) => registro) || []

      const diaB = diasDaSemana
        .map((dia) => semanas[semanaB][dia])
        .find((registro) => registro) || []

      const dataA = moment(diaA[0], 'DD/MM/YYYY')
      const dataB = moment(diaB[0], 'DD/MM/YYYY')

      return dataA.isBefore(dataB) ? -1 : 1
    })

    dados.forEach(registro => {
      const semanaDoAno = moment(registro.data).week()
      const diaDaSemana = moment(registro.data).format('ddd').toUpperCase()

      semanas[semanaDoAno][diaDaSemana].push(registro)
    })

    return semanasOrdenadas.map((semana, index) => (
      <tr key={index}>
        {diasDaSemana.map((dia, index) => {
          const registro = semanas[semana][dia]

          if (!registro) {
            return <td key={index} />
          }

          const diaAtual = registro[0]
          const ehHoje = moment(diaAtual, 'DD/MM/YYYY').isSame(moment(), 'day')

          feriados.forEach(function (item, index, object) {
            var ehTrabalhado = feriadosTrabalhados.some(x => x.feriado.data === item.data)

            if (ehTrabalhado) {
              object.splice(index, 1)
            }
          })

          const ehFeriado = feriados.some(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))

          return (
            <td key={index} className={`${ehHoje ? 'dia-atual-destaque' : ''} ${ehFeriado ? 'feriado-destaque' : ''}`}>
              {renderizarHorario(registro)}
            </td>
          )
        })}
      </tr>
    ))
  }

  const renderizarHorario = (registro) => {
    const diaAtual = registro[0]
    const ehHoje = moment(diaAtual, 'DD/MM/YYYY').isSame(moment(), 'day')
    const ehFeriado = feriados.some(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))
    const feriado = feriados.find(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))
    const horarios = Array.from(registro.values()).slice(1)
    const color = chroma('#f3f3f3')
    const corDaFonte = chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

    return (
      <>
        <span title={`${ehFeriado ? feriado.nome : ''}`} className={`${ehHoje ? 'dia-atual' : ''}`}>{ehFeriado ? `${diaAtual} (Feriado)` : diaAtual}</span>
        {horarios.map(x => (
          <div className='calendar-week__event calendario-agenda-bloqueio' style={{ cursor: 'pointer' }} key={x.identificador} onClick={() => abrirAlterar(x)}>
            <div className='calendar-week__event__time' style={{ color: corDaFonte, display: 'flex', justifyContent: 'space-between' }}>
              <p className='calendar-week__event__time' style={{ color: corDaFonte }}>{`${moment(x.inicio, 'HH:mm:ss').format('HH:mm')} às ${moment(x.fim, 'HH:mm:ss').format('HH:mm')}`}</p>
              <span className='calendar-week__event__local' key='informacao' data-tip data-for={`info_${x.identificador}`}>
                <i className='icon icon-info' style={{ color: corDaFonte }}></i>
                <ReactTooltip
                  id={`info_${x.identificador}`}
                  place='right'
                  effect='solid'
                  type='info'
                  key={x.identificador}
                  className='unity-infos__item'
                >
                  <div
                    style={{
                      marginBottom: 8,
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                      borderBottomColor: 'rgb(232, 232, 232)'
                    }}><strong>Última alteração</strong></div>
                  <span>Usuário: {x.ultimaAlteracao.usuario.nome}</span> <br />
                  <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(x.ultimaAlteracao.dataEHora)}</span>
                </ReactTooltip>
              </span>
            </div>
            <p className='calendar-week__event__local font-weight-bolder' style={{ color: corDaFonte }}>{recuperarMotivo(x.motivo)}</p>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarBloqueio}
        className='modal'
      >
        <FormularioBloqueio
          adicionar={adicionarBloqueioNaAgendaDoProfissionalDeSaude}
          aposFechar={() => listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, filtros)}
          fechar={fecharAdicionar}
          identificadorDoProfissional={identificador}
          motivosDeBloqueio={motivos}
        />
      </Modal>
      <Modal
        isOpen={exibirAlterarBloqueio}
        className='modal'
      >
        <FormularioBloqueio
          alterar={alterarBloqueioNaAgendaDoProfissionalDeSaude}
          alterarSerie={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude}
          aposFechar={() => listarBloqueiosNaAgendaDoProfissionalDeSaude(identificador, filtros)}
          bloqueio={bloqueioSelecionado}
          fechar={fecharAlterar}
          identificadorDoProfissional={identificador}
          motivosDeBloqueio={motivos}
          remover={removerSelecionado}
          removerSerie={removerSerieSelecionada}
        />
      </Modal>
      {exibirCalendario &&
        <Spinner operacoes={[LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA, LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
          {({ processando }) => (
            <FormularioDoFormik
              processando={processando}
              reinicializar={true}
            >
              <>
                <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
                <div className='mt-2 mb-4'>
                  <fieldset>
                    <h2 className='form-title'>Agenda</h2>
                    <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                      <r-cell span={4} span-md={6} span-lg={9}>
                        <AbasDaAgenda identificador={identificador} url={match.url} usuario={usuario} />
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={3} class='d-sm-none d-lg-flex justify-content-end'>
                        {podeAdicionarBloqueio && <button type='button' className='button --primary w-100' onClick={abrirAdicionar}>Adicionar</button>}
                      </r-cell>
                    </r-grid>
                    <div className='d-flex align-items-center justify-content-between mt-3 mb-2 mb-lg-2 mb-sm-3'>
                      <h2 className='form-title mb-0'>Bloqueios da Agenda</h2>
                      {podeAdicionarBloqueio && <button type='button' className='button --primary d-none d-sm-block d-lg-none' onClick={abrirAdicionar}>Adicionar</button>}
                    </div>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={3} span-lg={3} class='flex align-items-center'>
                        <SeletorDeDataDaInstrucao
                          data={filtros.inicio}
                          pesquisar={pesquisarBloqueiosDeAgendamentosCalendario}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={2}>
                        <button type='button' className='button --primary w-100' onClick={alterarParaTabela}>Pesquisar por motivo</button>
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <div className='calendar-week'>
                          <div className='calendar-week__wrapper'>
                            <div className='calendar-week__content'>
                              <table className='calendar-week__table'>
                                <thead className='calendar-week__header'>
                                  <tr>
                                    {diasDaSemana.map((x, index) => (
                                      <th key={index}>
                                        {x}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody className='calendar-week__body dias-calendario-configuaracao-da-agenda'>
                                  {preencherCalendario(paginaDeDados.dados)}
                                </tbody>
                                {!processando && paginaDeDados?.dados?.length === 0 &&
                                  <tbody className='calendar-week__body dias-calendario-configuaracao-da-agenda'>
                                    <tr>
                                      <td colSpan='7'>
                                        <p>Não há horários de bloqueio do profissional.</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </r-cell>
                    </r-grid>
                  </fieldset>
                  <div className='list-btn'>
                    <button
                      className='button --light'
                      type='button'
                      onClick={() => history.push(`/configuracoes/usuarios`)}
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </>


            </FormularioDoFormik>
          )}
        </Spinner>
      }
      {exibirTabela &&
        <Spinner operacoes={[LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA, LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              inicio: filtros ? filtros.inicio : '',
              fim: filtros ? filtros.fim : '',
              motivo: filtros.motivo || '',
            }}
            acao={pesquisarBloqueiosDeAgendamentosTabela}
            esquemaDeValidacoes={Yup.object().shape({
              inicio: Yup.string().nullable(),
              fim: Yup.string()
                .nullable()
                .test(
                  "fim_teste",
                  "Deve ser maior que o início.",
                  function (value) {
                    const { inicio } = this.parent;
                    return !value || dataIgualOuAnterior(inicio, value);
                  }
                ),
              motivo: Yup.string(),
            })}
          >
            {({ values }) => (
              <>
                <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
                <div className='mt-2 mb-4'>
                  <fieldset>
                    <h2 className='form-title'>Agenda</h2>
                    <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                      <r-cell span={4} span-md={6} span-lg={9}>
                        <AbasDaAgenda identificador={identificador} url={match.url} usuario={usuario} />
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={3} class='d-sm-none d-lg-flex justify-content-end'>
                        {podeAdicionarBloqueio && <button type='button' className='button --primary w-100' onClick={abrirAdicionar}>Adicionar</button>}
                      </r-cell>
                    </r-grid>
                    <div className='d-flex align-items-center justify-content-between mt-3 mb-2 mb-lg-2 mb-sm-3'>
                      <h2 className='form-title mb-0'>Bloqueios da Agenda</h2>
                      {podeAdicionarBloqueio && <button type='button' className='button --primary d-none d-sm-block d-lg-none' onClick={abrirAdicionar}>Adicionar</button>}
                    </div>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={3} span-lg={2}>
                        <DatePicker
                          nome='inicio'
                          tabIndex={1}
                          titulo='Início do Período'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={2}>
                        <DatePicker
                          nome='fim'
                          tabIndex={1}
                          titulo='Fim do Período'
                          minDate={moment(values.inicio).toDate()}
                        />
                      </r-cell>
                      <r-cell span={4} span-md={4} span-lg={4}>
                        <Select
                          nome='motivo'
                          tabIndex={2}
                          titulo='Motivo do Bloqueio'
                          itens={motivos}
                          campoCodigo='codigo'
                          campoDescricao='nome'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={1} span-lg={2}>
                        <button type='submit' className='button --light w-100 mt-24'>Pesquisar</button>
                      </r-cell>
                      <r-cell span={4} span-md={1} span-lg={2}>
                        <button type='button' className='button --primary w-100 mt-24' onClick={alterarParaCalendario}>Calendário</button>
                      </r-cell>
                    </r-grid>
                    <Tabela
                      acoes={[
                        item => (
                          <Permissao key='editar' permissoes={[permissoes.ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
                            <button
                              className='table-items__bt --edit'
                              title='Editar'
                              type='button'
                              onClick={() => abrirAlterar(item)}
                            >
                              <i className='icon icon-pencil'></i>
                            </button>
                          </Permissao>
                        ),
                        item => (
                          <div key='informacao'>
                            <button
                              data-tip
                              data-for={`info_${item.identificador}`}
                              key={`info_${item.identificador}`}
                              className='table-items__bt --edit'
                              type='button'
                            >
                              <i className='icon icon-info'></i>
                            </button>
                            <ReactTooltip
                              id={`info_${item.identificador}`}
                              place='left'
                              effect='solid'
                              type='info'
                              key={item.identificador}
                            >
                              <div
                                style={{
                                  marginBottom: 8,
                                  borderBottomStyle: 'solid',
                                  borderBottomWidth: '1px',
                                  borderBottomColor: 'rgb(232, 232, 232)'
                                }}><strong>Última alteração</strong></div>
                              {item.observacoes && <><span>Observações: {item.observacoes} </span> <br /></>}
                              <span>Usuário: {item.ultimaAlteracao.usuario.nome}</span> <br />
                              <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.ultimaAlteracao.dataEHora)}</span>
                            </ReactTooltip>
                          </div>
                        ),
                      ]}
                      listar={listar}
                      chave='identificador'
                      className='--secondary my-4'
                      paginaDeDados={paginaDeDados}
                    >
                      <Coluna
                        principal={true}
                        className='col-lg-4'
                        nome='Data'
                        renderizar={item => item.data && <strong>{moment(item.data).format('DD/MM/YYYY')}</strong>}
                      />
                      <Coluna
                        className='col-lg-2'
                        nome='Início'
                        renderizar={item => item.inicio && moment(item.inicio, 'HH:mm:ss').format('HH:mm')}
                      />
                      <Coluna
                        className='col-lg-2'
                        nome='Fim'
                        renderizar={item => item.fim && moment(item.fim, 'HH:mm:ss').format('HH:mm')}
                      />
                      <Coluna
                        className='col-lg-2'
                        nome='Motivo'
                        renderizar={item => recuperarMotivo(item.motivo)}
                      />
                    </Tabela>
                  </fieldset>
                  <div className='list-btn'>
                    <button
                      className='button --light'
                      type='button'
                      onClick={() => history.push(`/configuracoes/usuarios`)}
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      }
    </>
  )
}