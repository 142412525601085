import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_CONSULTORES_DO_PACIENTE,
  LISTOU_CONSULTORES_DO_PACIENTE,
  ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE,
} from '../tipos'

const listouConsultoresDoPaciente = consultores => ({
  type: LISTOU_CONSULTORES_DO_PACIENTE,
  consultores,
})

const erroAoListarConsultoresDoPaciente = erro => ({
    type: ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE,
    erro,
})

export const listarConsultoresDoPaciente = (filtros, pagina, ordem, tamanhoDaPagina) => async dispatch => {
    dispatch({ type: LISTAR_CONSULTORES_DO_PACIENTE })

    try {
        let resultado = await api.listarUsuarios(filtros, pagina, ordem, tamanhoDaPagina)

        dispatch(listouConsultoresDoPaciente(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoListarConsultoresDoPaciente(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os consultores do paciente.')))
  }
}