import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import * as Yup from 'yup'
import { MenuItem } from 'react-pro-sidebar'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../spinner'
import { Formulario as FormularioDoFormik, Select } from '../formik/formulario'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { CRIAR_TAREFA_DO_PACIENTE, LISTAR_TIPOS_DE_TAREFA } from '../../acoes/tipos'
import { listarTiposDeTarefa } from '../../acoes/tarefas/tipos'
import { criarTarefaDoPaciente } from '../../acoes/tarefas'
import { PlusCircle } from '../../design/icones'

export default function SeletorDeTipoDeTarefa({ abrirEditarTarefa, identificadorDoPaciente, ehTelaDeCadastro }) {
  const dispatch = useDispatch()

  const { tipos } = useSelector(state => state.tarefas)

  const tiposDeTarefa = useMemo(() => {
    return converterObjetoParaArray(tipos).map((t) => {
      const ultimaVersao = t.versoes[t.versoes.length - 1]

      return ({
        ...t,
        criar: ehTelaDeCadastro ? ultimaVersao?.configuracoes?.criar_no_cadastro === 'true' : ultimaVersao?.configuracoes?.criar_no_prontuario === 'true'
      })
    })
  }, [tipos, ehTelaDeCadastro])

  const [exibirModal, setExibirModal] = useState(false)

  useEffect(() => {
    dispatch(listarTiposDeTarefa())
  }, [dispatch])

  const criar = async formulario => {
    const tipo = tipos[formulario.tipo] || {}
    const tipoCompleto = tipo && tipos[tipo.identificador]
    const ultimaVersao = tipoCompleto && tipoCompleto.versoes[tipoCompleto.versoes.length - 1]

    const criou = await dispatch(criarTarefaDoPaciente(identificadorDoPaciente, {
      identificador: uuidv4(),
      tipo: {
        identificador: tipoCompleto.identificador,
        nome: tipoCompleto.nome,
        numero: ultimaVersao.numero
      }
    }))

    if (criou) {
      setExibirModal(false)
      abrirEditarTarefa(criou)
    }
  }

  return (
    <>
      <Modal
        isOpen={exibirModal}
        className='modal-selecionar-tipo-de-tarefa'
      >
        <Spinner operacoes={[LISTAR_TIPOS_DE_TAREFA, CRIAR_TAREFA_DO_PACIENTE]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{ tipo: '' }}
            acao={criar}
            esquemaDeValidacoes={Yup.object().shape({
              tipo: Yup.string().required('Obrigatório')
            })}
          >
            <fieldset>
              <h2 className='form-title'>Selecionar Tipo de Tarefa</h2>
              <Select
                nome='tipo'
                campoCodigo='identificador'
                campoDescricao='nome'
                itens={tiposDeTarefa.filter(t => t.criar)}
                titulo='Tipo de Tarefa *'
              />
              <div className='list-btn'>
                <button className='button --light' type='button' onClick={() => setExibirModal(false)}>Cancelar</button>
                <button className='button --primary' type='submit'>Adicionar</button>
              </div>
            </fieldset>
          </FormularioDoFormik>
        </Spinner>
      </Modal>
      <MenuItem
        icon={<PlusCircle size='22' />}
        onClick={() => setExibirModal(true)}
      >
        Adicionar Tarefa
      </MenuItem>
    </>
  )
}