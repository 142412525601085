import { WithContext as ReactTags } from 'react-tag-input'
import React, { useMemo, useState } from 'react'
import Spinner from '../../spinner/'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO, ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO } from '../../../acoes/tipos'

export default function Tags(props) {
  const {
    adicionarTag,
    identificador,
    paciente,
    removerTag,
  } = props

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_TAG_NO_PACIENTE])

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const tags = useMemo(() => {
    return paciente.tags ? paciente.tags.map(x => ({ id: x.tag, text: x.tag })) : []
  }, [paciente.tags])

  const sugestoes = useMemo(() => {
    return paciente.tagsDePacientes.map(x => ({ id: x, text: x }))
  }, [paciente.tagsDePacientes])

  const [tagsDoPaciente, setTagsDoPaciente] = useState(tags)

  const removerTagDoPaciente = index => {
    const tag = tags[index]
    setTagsDoPaciente(tagsDoPaciente.filter(x => x.text !== tag.text))
    removerTag(identificador, tag.text)
  }

  const adicionarTagDoPaciente = tag => {
    setTagsDoPaciente([...tagsDoPaciente, tag])
    adicionarTag(identificador, tag.text)
  }

  return (
    <Spinner operacoes={[REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO, ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO]}>
      {({ processando }) => (
        <div className='tags-do-paciente-no-prontuario'>
          <ReactTags
            classNames={{
              tagInput: processando ? 'tag tag-input is-disabled' : 'tag tag-input',
              tagInputField: processando ? 'tag tag-input is-disabled' : 'tag tag-input'
            }}
            allowDeleteFromEmptyInput={false}
            allowDragDrop={false}
            tags={tags}
            suggestions={sugestoes}
            delimiters={[KeyCodes.comma, KeyCodes.enter]}
            handleDelete={processando ? undefined : removerTagDoPaciente}
            handleAddition={adicionarTagDoPaciente}
            placeholder={'Adicionar Tag'}
            readOnly={!podeAdicionar}
          />
        </div>
      )}
    </Spinner>
  )
}