import api from './api-major'

const listarResultadosHbsag = () => api.get('/resultados-de-hbsag?tamanhoDaPagina=1000')
const listarResultadosAntiHcv = () => api.get('/resultados-de-anti-hcv?tamanhoDaPagina=1000')
const listarResultadosAntiHiv = () => api.get('/resultados-de-anti-hiv?tamanhoDaPagina=1000')
const listarResultadosAntiHbc = () => api.get('/resultados-de-anti-hbc?tamanhoDaPagina=1000')

export default {
  listarResultadosHbsag,
  listarResultadosAntiHcv,
  listarResultadosAntiHiv,
  listarResultadosAntiHbc
}