import React from 'react'
import { Bug, ListaDeBugs } from './bugs'
import { ListaDeReleases, Release } from './releases'
import { Funcionalidade, Imagem, Paragrafo, Titulo, Topico, Video } from './funcionalidade'

/*
1 • Mais 60 Saúde
2 • Villaggio
3 • Hanka Robotics
5 • Mais 60/Premium
6 • Consultórios Médicos
7 • +60 Virtual
8 • ASMUBE
9 • UN - Unimed Nacional
*/

export default function Novidades({ fecharNovidades, numero }) {
  return (
    <ListaDeReleases fechar={fecharNovidades} numero={numero}>
      <Release numero='2024-12-04'>
        <Funcionalidade>
          <Titulo>Questionário do Idoso!</Titulo>
          <Paragrafo>O novo formulário já está disponível para preenchimento no prontuário.</Paragrafo>
          <Paragrafo>No prontuário do paciente, na aba Questionários, ao lado de IVCF.</Paragrafo>
          <Imagem link='/releases/2024_12_04__questionario_do_idoso_lista.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Nova Timeline do Prontuário do Paciente!</Titulo>
          <Paragrafo>Reformulação da Timeline, melhorando a visualização de eventos assistenciais importantes para o plano de cuidados do paciente.</Paragrafo>
          <Imagem link='/releases/2024_12_04__timeline.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Triagem na Linha do Tempo!</Titulo>
          <Paragrafo>Agora as triagens do paciente aparecem na linha do tempo junto aos atendimentos!</Paragrafo>
          <Imagem link='/releases/2024_12_04__triagem_na_linha_do_tempo.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-11-23'>
        <Funcionalidade>
          <Titulo>Informe na chegada do paciente se ele chegou de ambulância.</Titulo>
          <Paragrafo>Inclusão da pergunta "Chegou de ambulância?" na chegada do paciente do agendamento.</Paragrafo>
          <Imagem link='/releases/2024_11_23__chegou_de_ambulancia.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug que obrigava CPF ao inativar paciente.</Bug>
          <Bug>Corração do bug que impedia realização de agendamentos após às 21h.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-10-31'>
        <Funcionalidade>
          <Titulo>Novo módulo de Triagem!</Titulo>
          <Paragrafo>Inclusão da Triagem para a realização dentro do LifeCode e orientar o fluxo de atendimento do Pronto Cuidar.</Paragrafo>
          <Imagem link='/releases/2024_10_31__triagem.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>CPF é obrigatório!</Titulo>
          <Paragrafo>A partir de agora, o CPF é obrigatório para novos cadastros. Essa modificação visa um cadastro mais completo e evita duplicatas.</Paragrafo>
          <Topico>Para cadastros antigos, o preenchimento não é obrigatório, mas é recomendado para garantir a completude do cadastro.</Topico>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novos motivos de encerramento do programa do paciente.</Titulo>
          <Paragrafo>Inclusão dos motivos de saída do programa: "Alta administrativa", "Suspensão - Admnistrativa" e "Suspensão - Não cumprimento do Plano de Cuidados". </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Nova vacina disponível para adição!</Titulo>
          <Paragrafo>Inclusão da vacina Vírus sincicial respiratório (VSR).</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2024-09-25'>
        <Funcionalidade>
          <Titulo>Alteração na inativação dos pacientes</Titulo>
          <Paragrafo>Ampliamos os motivos para inativação do paciente, agora é possível inativar por óbito ou por cadastro duplicado.</Paragrafo>
          <Imagem link='/releases/2024-09-25__motivos_de_inativacao_do_paciente.png' />
        </Funcionalidade>
      </Release>
      <Release numero='2024-09-11'>
        <Funcionalidade>
          <Titulo>Envio da pesquisa de Net Promoter Score (NPS) via WhatsApp</Titulo>
          <Paragrafo>Ampliamos os canais de envio de NPS, além dos disparos por e-mail também será enviado mensagem de WhatsApp aos pacientes solicitando avaliação do atendimento prestado.</Paragrafo>
          <Paragrafo>Atenção: lembrem-se de manterem os dados de telefone e email corretos e atualizados nos cadastros.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Inativação de respostas das pesquisas de NPS</Titulo>
          <Paragrafo>Nas configurações de NPS, é possível listar as respostas e alterar o status de alguma resposta específica para "Cancelada", retirando-a do cálculo de NPS.</Paragrafo>
          <Paragrafo>É possível ativar novamente uma resposta, voltando-a para o cálculo do NPS.</Paragrafo>
          <Paragrafo>Pode ser acessado em Configurações -> NPS -> Respostas</Paragrafo>
          <Imagem link='/releases/2024-09-11__respostas_do_nps.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[9]}>
          <Titulo>Dashboard de Plano de Cuidados Contratual UN</Titulo>
          <Paragrafo>Objetiva-se acompanhar do plano de cuidados mínimo dos pacientes previsto em contrato.</Paragrafo>
          <Paragrafo>Dados sobre atendimentos médicos, da enfermagem e da equipe multi.</Paragrafo>
          <Paragrafo>Dados sobre futuros agendamentos multi e da equipe médica.</Paragrafo>
          <Paragrafo>Pode ser acessado em Relatórios -> Dashboards -> Planos de Cuidados -> Planos de Cuidados Contratual UN</Paragrafo>
          <Imagem link='/releases/2024-09-11__plano_de_cuidados_contratual_un.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[9]}>
          <Titulo>Dashboard de Indicadores Contratuais UN</Titulo>
          <Paragrafo>Objetiva-se acompanhar a performance dos indicadores assistenciais previstos em contrato.</Paragrafo>
          <Paragrafo>Dados sobre idas ao pronto atendimento das autorizações enviadas pela operadora e dos registros no LifeCode.</Paragrafo>
          <Paragrafo>Dados sobre internações das autorizações enviadas pela operadora e dos registros no LifeCode.</Paragrafo>
          <Paragrafo>Pode ser acessado em Relatórios -> Dashboards -> Indicadores Contratuais UN</Paragrafo>
          <Imagem link='/releases/2024-09-11__indicadores_contratuais_un.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-08-27'>
        <Funcionalidade empresas={[8]}>
          <Titulo>Adição do dashboard de Plano de cuidados enfermagem</Titulo>
          <Paragrafo>Foi adicionado o dashboard de Plano de cuidados da enfermagem, contendo informações da situação dos pacientes e seus agendamentos.</Paragrafo>
          <Imagem link='/releases/2024-08-27__plano-de-cuidados-enfermagem.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-08-14'>
        <Funcionalidade>
            <Titulo>Atalhos para o prontuário</Titulo>
            <Paragrafo>Comandos podem ser acionados para aprimorar a navegabilidade do prontuário:</Paragrafo>
            <Topico>Salvar atendimento: ctrl + s</Topico>
            <Topico>Finalizar atendimento: shift + alt + f</Topico>
            <Topico>Abrir prescrição MEMED: shift + alt + m</Topico>
            <Topico>Ir à seção Anamnese: shift + alt + e</Topico>
            <Topico>Ir à seção Conduta: shift + alt + c</Topico>
          </Funcionalidade>
      </Release>

      <Release numero='2024-08-07'>
        <Funcionalidade empresas={[9]}>
          <Titulo>Adição da Tarefa de Encaminhamento Multiprofissional Individual</Titulo>
          <Paragrafo>Novo fluxo do tarefas para acompanhar os agendamentos e atendimentos dos encaminhamentos individuais realizados para a equipe multiprofissional.</Paragrafo>
          <Imagem link='/releases/2024-08-07__fluxo_encaminhamento_multiprofissional_individual.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Alterações no Encaminhamento Multiprofissional Individual</Titulo>
          <Paragrafo>O fluxo de agendamento foi alterado, transitando agora para o status PENDENTE caso o paciente falte ou o agendamento seja cancelado.</Paragrafo>
          <Paragrafo>Novas tarefas de Encaminhamento Multiprofissional Individual não serão criadas se o paciente já possuir uma em aberto para a mesma profissão.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Sala de espera</Titulo>
          <Paragrafo>Para iniciar atendimentos do tipo "Evolução Presencial", é necessário que o paciente esteja na Sala de Espera.</Paragrafo>
          <Paragrafo>Agora, somente a equipe de recepção pode confirmar a chegada do paciente.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Ajustes nos encaminhamentos médicos no prontuário</Titulo>
          <Paragrafo>Os títulos dos agrupamentos das especialidades agora estão na forma simplificada</Paragrafo>
          <Topico>Especialidades médicas internas tornam-se especialidades internas</Topico>
          <Topico>Especialidades médicas externas tornam-se especialidades externas</Topico>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Tamanho do slot da agenda na configuração do horário</Titulo>
          <Paragrafo>Agora o tamanho do slot é informado na configuração do horário da agenda do profissional.</Paragrafo>
          <Imagem link='/releases/2024-08-07__tamanho_do_slot.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Nova vacina contra dengue</Titulo>
          <Paragrafo>Foi adicionada a vacina Qdenga no prontuário.</Paragrafo>
        </Funcionalidade>        
        <Funcionalidade empresas={[1, 9]}>
          <Titulo>Fluxo Confirmação de Agendamento</Titulo>
          <Paragrafo>
            Novo fluxo automático do Tarefas para monitoramento dos agendamentos pendentes de confirmação!
          </Paragrafo>
          <Imagem link='/releases/2024-08-07__fluxo_confirmacao_de_agendamento.png' />
        </Funcionalidade>        
        <ListaDeBugs>
          <Bug>Correção do bug que permitia a alteração da unidade do horário da agenda sem emitir um alerta sobre possíveis agendamentos.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-07-18'>
        <Funcionalidade empresas={[1]}>
          <Titulo>Alterações na Captação UNIMED BH</Titulo>
          <Paragrafo>Novos status que validam o IVCF incluído no prontuário, caso o paciente tenha como critério de admissão o IVCF.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade empresas={[9]}>
          <Titulo>Alterações na Captação UN</Titulo>
          <Paragrafo>Novos status que validam os critérios de admissão do paciente: idade e IVCF.</Paragrafo>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção da seleção de agendamento particular ao Agendar/Alterar.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-06-28'>
        <Funcionalidade>
          <Titulo>Nova tela para listar pedidos de contato</Titulo>
          <Paragrafo>A aba "Configurações" agora inclui um novo submenu que direciona para uma tela de listagem e exclusão de pedidos de contato.</Paragrafo>
          <Imagem link='/releases/2024-06-28__tela_pedidos_de_contato.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração na forma de adicionar tipos de horário</Titulo>
          <Paragrafo>Agora os tipos de horário serão adicionados diretamente na tela de criação ou alteração de um tipo de agendamento. Para cada tipo de agendamento, é necessário pelo menos um tipo de horário associado. No momento do agendamento, só será possível realizar agendamentos para tipos configurados para o tipo do horário desejado.</Paragrafo>
          <Imagem link='/releases/2024-06-28__alteracao_tipos_de_horario.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Visualização das instruções e bloqueios da agenda</Titulo>
          <Paragrafo>Agora é possível visualizar a data e o usuário da última alteração das instruções e bloqueios da agenda do profissional.</Paragrafo>
          <Imagem link='/releases/2024-06-28__visualizacao_bloqueios_e_instrucoes.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do alerta ao informar LDL abaixo de 50mg/dL nos exames do paciente.</Bug>
          <Bug>Correção do alerta ao informar hemoglobina entre 12 e 18g/dL nos exames do paciente.</Bug>
          <Bug>Alteração na prioridade do status de Agendado para Bloqueado no relatório de horas dos profissionais.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-06-13'>
        <Funcionalidade>
          <Titulo>Visualização dos horários da agenda</Titulo>
          <Paragrafo>Agora é possível visualizar a data e o usuário da última alteração dos horários.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Visualização dos agendamentos na Sala de Espera</Titulo>
          <Paragrafo>Os agendamentos cancelados agora não ficam mais visíveis na sala de espera.</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2024-06-11'>
        <Funcionalidade>
          <Titulo>Visualização mensal das instruções</Titulo>
          <Topico>Agora as instruções serão exibidas inicialmente na forma de calendário, semelhante à visualização da configuração da agenda.</Topico>
          <Topico>A busca por texto e a exibição na forma de tabela continua disponível através do botão Pesquisa por texto.</Topico>
          <Imagem link='/releases/2024-06-11__visualizacao_instrucoes.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Visualização mensal dos bloqueios</Titulo>
          <Topico>Agora os bloqueios serão exibidos inicialmente na forma de calendário, semelhante à visualização da configuração da agenda.</Topico>
          <Topico>A busca por motivo de bloqueio e a exibição na forma de tabela continua disponível através do botão Pesquisa por motivo.</Topico>
          <Imagem link='/releases/2024-06-11__visualizacao_bloqueios.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>CPF obrigatório para médicos</Titulo>
          <Paragrafo>Passa a ser obrigatório informar o CPF no cadastro de usuário quando a profissão é "Médico(a)".</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2024-05-18'>
        <Funcionalidade>
          <Titulo>Alerta para paciente sem programa ativo</Titulo>
          <Paragrafo>Ao salvar um agendamento pelo convênio para um paciente sem nenhum programa ativo, o usuário é alertado que esse paciente não possui programa ativo.</Paragrafo>
          <Imagem link='/releases/2024-05-18__paciente_sem_programa.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Inativação de Usuário</Titulo>
          <Paragrafo>Ao inativar um usuário, será alertado quanto aos agendamentos futuros.</Paragrafo>
          <Paragrafo>Todos os seus bloqueios, instruções e horários serão excluídos automaticamente.</Paragrafo>
          <Imagem link='/releases/2024-05-18__inativa_usuario_com_agendamento_futuro.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Adição do dashboard de Tarefa de encaminhamento multiprofissional</Titulo>
          <Paragrafo>Foi adicionado o dashboard de Tarefa de encaminhamento multiprofissional onde é possível acompanhar as tarefas criadas e seus prazos, além disso, é possível verificar os horários de agendamento disponível para cada profissão encaminhada na tarefa.</Paragrafo>
          <Imagem link='/releases/2024-05-18__tarefa_de_encaminhamento_multiprofissional.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-05-09'>
        <Funcionalidade>
          <Titulo>Adição do dashboard de Gestão da Agenda</Titulo>
          <Paragrafo>Foi adicionado o dashboard de Gestão da Agenda com informações das agendas dos profissionais, da lista de espera e das vagas para primeira consulta</Paragrafo>
          <Imagem link='/releases/2024-05-09__gestao_da_agenda.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Remoção dos dashboards de Controle de Diabetes e Frequência do Cuidado Continuado</Titulo>
          <Paragrafo>Os dashboards de Controle de Diabetes e Frequência do Cuidado Continuado foram removidos. As informações do Controle de Diabetes podem ser encontradas no dashboard de Manutenção do Cuidado e os dados de Frequência do Cuidado Continuado no dashboard de Plano de Cuidados Médicos.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Adição do dashboard de Confirmações de agendamentos</Titulo>
          <Paragrafo>Foi adicionado o dashboard Confirmações de agendamentos, contendo informações para automatizar o envio das planilhas de confirmação do agendamento pelo whatsapp.</Paragrafo>
          <Imagem link='/releases/2024-05-09__confirmacoes_de_agendamentos.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Dashboard de Plano de Cuidados - Consultas Médicas</Titulo>
          <Paragrafo>
            Capacidades médicas atualizadas automaticamente de acordo com a configuração da agenda no dashboard.
            Os tipos considerados para cálculo da capacidade são: Atendimento e Captação.
          </Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2024-04-25'>
        <Funcionalidade>
          <Titulo>Campo obrigatório ao adicionar uma ida ao pronto atendimento</Titulo>
          <Topico>O campo data fim é de preenchimento obrigatório caso o status seja “Informado pela operadora”.</Topico>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alterações na sala de espera</Titulo>
          <Topico>Agora todos os agendamentos do dia são exibidos na sala de espera.</Topico>
          <Topico>Agendamentos que estejam com status diferente de aguardando atendimento serão exibidos com uma tonalidade diferente.</Topico>
          <Topico>Foram adicionadas as colunas Modalidade e Espera.</Topico>
          <Imagem link='/releases/2024-04-25__sala_de_espera.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-04-20'>
        <Funcionalidade empresas={[1]}>
          <Titulo>Remoção dos Dashboards</Titulo>
          <Topico>Navegação da Enfermagem</Topico>
          <Topico>Orçamento</Topico>
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Dashboard de Plano de cuidados médicos</Titulo>
          <Paragrafo>
            Foi adicionado o dashboard de Plano de cuidados médico contendo informações dos pacientes como agendamentos e situação no plano de cuidados.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Dashboard de Tarefa de Navegação ADC</Titulo>
          <Paragrafo>
            Foi adicionado o dashboard de Tarefa de Navegação ADC onde é possível acompanhar as tarefas criadas e seus prazos.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Melhorias na configuração de horários da agenda</Titulo>
          <Topico>Dia de hoje é destacado na cor cinza.</Topico>
          <Topico>Feriados são destacados na cor azul.</Topico>
          <Topico>É possível visualizar o nome do feriado ao passar o cursor sobre a data no calendário.</Topico>
          <Imagem link='/releases/2024-04-20__melhorias_agenda.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Contatos do paciente no agendamento</Titulo>
          <Paragrafo>
            Foram adicionados novos campos sobre os telefones e um campo para informar E-mail nas telas de agendamento.
          </Paragrafo>
          <Paragrafo>
            Agora é possível informar quem é o proprietário do telefone e se ele também é WhatsApp.
          </Paragrafo>
          <Paragrafo>
            Se a modalidade do agendamento for "remoto", é necessário que pelo menos um dos telefones seja WhatsApp ou que o E-mail tenha sido informado.
          </Paragrafo>
          <Imagem link='/releases/2024-04-20__contatos_agendamento.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração no registro de óbito do paciente</Titulo>
          <Paragrafo>
            Agora os programas do paciente serão automaticamente encerrados ao registrar o óbito do paciente.
          </Paragrafo>
          <Paragrafo>
            A data de encerramento pode variar de acordo com as condições:
          </Paragrafo>
          <Topico>Caso a data do óbito informada for mês/ano, o encerramento ocorrerá do primeiro dia do mês informado.</Topico>
          <Topico>Caso a data do óbito informada for um dia específico, o encerramento ocorrerá no dia informado.</Topico>
          <Topico>Caso a data de início do programa for posterior à data de óbito informada, o encerramento ocorrerá na data de início do programa.</Topico>
          <Paragrafo>
            IMPORTANTE! Não é possível restaurar os programas encerrados devido ao registro de óbito.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração na tela de cadastro e prontuário do paciente</Titulo>
          <Topico>Agora um alerta deixa evidente a necessidade de acompanhamento do paciente em ambas as telas.</Topico>
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Agora é possível ver os telefones do paciente no Tarefas</Titulo>
          <Paragrafo>Disponível para as seguintes tarefas:</Paragrafo>
          <Topico>Captação Unimed BH</Topico>
          <Topico>Encaminhamento Multiprofissional</Topico>
          <Topico>Gestão de Qualidade do NPS</Topico>
          <Topico>Navegação</Topico>
          <Topico>Navegação ADC</Topico>
          <Topico>Retenção Nível 3</Topico>
          <Imagem link='/releases/2024-04-20__telefone_do_paciente_no_tarefas.gif' />
        </Funcionalidade>
        <Funcionalidade empresas={[8]}>
          <Titulo>Agora é possível ver os telefones do paciente no módulo de Tarefas.</Titulo>
          <Paragrafo>Disponível para a seguinte tarefa:</Paragrafo>
          <Topico>Navegação</Topico>
          <Imagem link='/releases/2024-04-20__telefone_do_paciente_no_tarefas.gif' />
        </Funcionalidade>
        <Funcionalidade empresas={[9]}>
          <Titulo>Agora é possível ver os telefones do paciente no módulo de Tarefas.</Titulo>
          <Paragrafo>Disponível para as seguintes tarefas:</Paragrafo>
          <Topico>Captação UN</Topico>
          <Topico>Gestão de Qualidade do NPS</Topico>
          <Topico>Navegação</Topico>
          <Imagem link='/releases/2024-04-20__telefone_do_paciente_no_tarefas.gif' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug em que o médico de referência não era exibido no agendamento.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-03-28'>
        <Funcionalidade>
          <Titulo>Feriados trabalhados</Titulo>
          <Paragrafo>
            Nova funcionalidade que permite adicionar feriados trabalhados para cada profissional.
          </Paragrafo>
          <Paragrafo>Agora é possível:</Paragrafo>
          <Topico>Cadastrar feriados trabalhados para cada profissional através de uma nova aba na configuração da agenda.</Topico>
          <Topico>Realizar agendamentos e atendimentos nos feriados cadastrados.</Topico>
          <Topico>Agendamentos podem ser realizados pelo calendário ou wizard normalmente.</Topico>
          <Topico>Os relatórios foram alterados para contabilizar as horas dos feriados trabalhados.</Topico>
          <Paragrafo>
            IMPORTANTE! Alterar ou remover algum feriado irá refletir diretamente nos feriados trabalhados do profissional.
          </Paragrafo>
          <Imagem link='/releases/2024-03-28__feriados_trabalhados_agenda.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração de Paciente no Agendamento</Titulo>
          <Paragrafo>
            Agora não é mais permitido a alteração do paciente no agendamento.
          </Paragrafo>
          <Paragrafo>
            Em vez de editar diretamente o paciente, os usuários deverão cancelar o agendamento existente e, em seguida, criar um novo agendamento com o paciente desejado.
          </Paragrafo>
        </Funcionalidade>
      </Release>


      <Release numero='2024-03-16'>
        <Funcionalidade>
          <Titulo>Avaliação Física e Funcional</Titulo>
          <Paragrafo>Remoção do campo "Descrição da Dor" do questionário no prontuário.</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2024-03-12'>
        <ListaDeBugs>
          <Bug>Correção da tela em branco ao entrar na Sala de Espera.</Bug>
          <Bug>Validação de profissional inativo durante o cadastro de um agendamento.</Bug>
          <Bug>Validação de caracteres acentuados no campo de e-mail durante o cadastro de Pacientes, Usuários e Empresas.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-03-06'>
        <Funcionalidade>
          <Titulo>Associação de Tipo de Horário no Cadastro de Tipo de Agendamento</Titulo>
          <Paragrafo>
            Agora, ao cadastrar um Tipo de Agendamento, os usuários devem também especificar quais Tipos de Horários estão disponíveis para ele.
          </Paragrafo>
          <Paragrafo>
            Essa adição oferece um controle sobre os agendamentos, pois restringe quais tipos de agendamentos podem ser feitos com base no Tipo de Horário configurado para o médico ou profissional de saúde responsável pela agenda.
          </Paragrafo>
          <Imagem link='/releases/2024-03-06__tipo-de-horario-do-tipo-de-agendamento.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-03-03'>
        <Funcionalidade empresas={[9]}>
          <Titulo>Dashboard de Atendimentos Mensais</Titulo>
          <Paragrafo>
            Foi adicionado o novo dashboard de Atendimentos Mensais contendo as informações para o relatório de dados do NPS para a Unimed Nacional.
          </Paragrafo>
          <Imagem link='/releases/2024-03-03__dashboard_atendimentos_mensais.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo cadastro de Horários da Agenda do Profissional</Titulo>
          <Paragrafo>
            Nova funcionalidade que aprimora significativamente o processo de cadastro de horários na agenda dos profissionais. Agora, ao realizar o cadastro dos horários da agenda, é necessário fornecer as seguintes informações:
          </Paragrafo>
          <Topico>Período da Agenda: Defina a data de início e fim para o período desejado.</Topico>
          <Topico>Repetição Semanal: Escolha entre repetição semanal ou pulos na programação.</Topico>
          <Topico>Dias da Semana: Indique os dias da semana em que os horários serão aplicados.</Topico>
          <Topico>Turno(s): Especifique o horário de início e fim, o tipo de horário e a unidade associada.</Topico>
          <Paragrafo>
            Cada conjunto de horários cadastrados é denominado de "série". Você pode criar quantas séries forem necessárias para atender às demandas da sua agenda.
          </Paragrafo>
          <Paragrafo>
            Uma das melhorias mais notáveis é a nova visualização da agenda, agora apresentada no modo calendário. Isso torna a compreensão e a gestão dos horários muito mais intuitivas e eficientes.
          </Paragrafo>
          <Paragrafo>
            Quanto à gestão dos registros, agora você tem mais controle. A exclusão ou alteração pode ser realizada em um único registro (dia/turno) ou em toda a série cadastrada. É importante observar que registros no passado permanecem intactos e não podem ser excluídos ou alterados.
          </Paragrafo>
          <Imagem link='/releases/2024-03-03__serie-configuracao-agenda.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção na impressão dos encaminhamentos do atendimento.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-02-17'>
        <Funcionalidade>
          <Titulo>Data de Consultas Externas</Titulo>
          <Paragrafo>
            Agora é possível adicionar apenas o ano de uma consulta externa.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alerta quando há agendamentos nos próximos 5 meses </Titulo>
          <Paragrafo>
            Ao adicionar um novo agendamento, o usuário é alertado quando já existe algum agendamento médico para esse paciente nos próximos 5 meses e precisa confirmar que deseja adicionar o agendamento.
          </Paragrafo>
          <Imagem link='/releases/2024-02-17__alerta_proximos_agendamentos.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug ao alterar a posição dos medicamentos do paciente.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-02-07'>
        <ListaDeBugs>
          <Bug>Correção do bug ao alterar status de um encaminhamento.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-02-06'>
        <ListaDeBugs>
          <Bug>Correção do bug ao alterar algum questionário no prontuário do paciente.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-02-04'>
        <Funcionalidade>
          <Titulo>Posição dos diagnósticos do paciente</Titulo>
          <Paragrafo>
            Agora é possível alterar o posicionamento dos diagnósticos do paciente no prontuário.
          </Paragrafo>
          <Imagem link='/releases/2024-02-04__ordenacao_diagnosticos.gif' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Confirmar agendamento</Titulo>
          <Paragrafo>
            Não será possível confirmar um agendamento quando o horário do profissional está bloqueado.
          </Paragrafo>
          <Paragrafo>
            Nesse caso, uma mensagem será exibida orientando sobre as opções de cancelamento ou reagendamento.
          </Paragrafo>
          <Imagem link='/releases/2024-02-04__agendamento_bloqueado.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alterações no Encaminhamento Multiprofissional Individual</Titulo>
          <Paragrafo>
            Agora é possível priorizar o encaminhamento.
          </Paragrafo>
          <Imagem link='/releases/2024-02-04__campos_novos_priorizar.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug na pesquisa de pacientes no agendamento e lista de espera.</Bug>
          <Bug>Correção do bug ao tentar alterar algum questinário no prontuário do paciente.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2024-01-12'>
        <Funcionalidade>
          <Titulo>Próximos agendamentos médicos</Titulo>
          <Paragrafo>
            Agora todos os agendamentos médicos futuros do paciente são exibidos no prontuário e no cadastro do paciente.
          </Paragrafo>
          <Imagem link='/releases/2024-01-12__proximos_agendamentos_medicos.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novas informações no telefone</Titulo>
          <Paragrafo>
            Agora é possivel marcar um telefone como número WhatsApp e número de confirmação de consulta.
          </Paragrafo>
          <Paragrafo>
            O número de telefone, com confirmação de consulta, é recuperado com prioridade na seleção do paciente no agendamento.
          </Paragrafo>
          <Imagem link='/releases/2024-01-12__tela_telefone.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-01-06'>
        <Funcionalidade>
          <Titulo>Setor na lista de responsáveis do Tarefas</Titulo>
          <Paragrafo>
            Agora é possível definir um setor como responsável por uma tarefa!
            <Topico>Em caso de setores faltantes, entre em contato com a equipe do LifeCode.</Topico>
          </Paragrafo>
          <Imagem link='/releases/2024-01-06__setor_tarefas.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Fluxo Navegação ADC</Titulo>
          <Paragrafo>
            Novo fluxo automático do Tarefas para monitoramento dos pacientes de Alta Densidade de Cuidado!
          </Paragrafo>
          <Imagem link='/releases/2024-01-06__fluxo_navegacao_adc.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[1]}>
          <Titulo>Fluxo Renovação IVCF</Titulo>
          <Paragrafo>
            Novo fluxo automático do Tarefas para monitoramento de quais pacientes devem renovar o IVCF para ficar em dia com a Unimed BH!
          </Paragrafo>
          <Imagem link='/releases/2024-01-06__fluxo_renovacao_ivcf.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2024-01-05'>
        <Funcionalidade>
          <Titulo>Posição dos medicamentos do paciente</Titulo>
          <Paragrafo>
            Agora é possível alterar o posicionamento dos medicamentos do paciente no prontuário.
          </Paragrafo>
          <Imagem link='/releases/2024-01-05__ordenacao_medicamentos.gif' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo indicador de paciente</Titulo>
          <Paragrafo>
            "Atraso no plano de cuidados da Unimed Nacional": desenvolvido para monitorar o tempo de atraso no plano de cuidados e se não houve ainda um primeiro atendimento.
          </Paragrafo>
          <Imagem link='/releases/2024-01-05__atraso_unimed_nacional.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug na pesquisa de pacientes com óbito no agendamento.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-12-23'>
        <Funcionalidade>
          <Titulo>IMC no prontuário</Titulo>
          <Paragrafo>
            Após informar a altura e peso do paciente no prontuário, o IMC é indicado em tempo real.
          </Paragrafo>
          <Imagem link='/releases/2023-12-23__imc_prontuario.png' />
        </Funcionalidade>
        <Funcionalidade empresas={[1, 9]}>
          <Titulo>Pressão Arterial no prontuário</Titulo>
          <Paragrafo>
            É obrigatório agora somente ao finalizar o atendimento.
          </Paragrafo>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug que impede a visualização do agendamento em grupo no primeiro horário do dia, juntamente com um bloqueio.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-12-14'>
        <Funcionalidade empresas={[1]}>
          <Titulo>Dashboard da Tarefa de Retenção Nível 3</Titulo>
          <Paragrafo>
            Disponibilizado o acesso ao dashboard da tarefa de Retenção Nível 3 da Unimed BH com informações sobre o número de pacientes em retenção, número de pacientes retidos e desligados, além do acompanhamento longitudinal dos pacientes em retenção, retidos e desligados.
          </Paragrafo>
          <Imagem link='/releases/2023-12-14__dashboard_tarefa_de_retencao_nivel_3.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração no resumo dos questionários do paciente</Titulo>
          <Paragrafo>
            Agora o nome do paciente é exibido em todos os questionários.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração no registro de óbito do paciente</Titulo>
          <Paragrafo>
            Agora os encaminhamentos com status em aberto, agendado, avaliando e contatando paciente serão automaticamente cancelados ao registrar o óbito do paciente.
          </Paragrafo>
          <Paragrafo>
            IMPORTANTE! Não é possível restaurar os encaminhamentos cancelados devido ao registro de óbito.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Aprimoramento no Cadastro de Medicamentos do Paciente</Titulo>
          <Paragrafo>
            A partir de agora, os profissionais de saúde poderão incluir o mesmo medicamento em diferentes concentrações no cadastro de cada paciente. Esta melhoria visa oferecer maior flexibilidade e precisão no manejo das prescrições, garantindo que as necessidades individuais de cada paciente sejam atendidas de maneira eficaz e personalizada.
          </Paragrafo>
          <Imagem link='/releases/2023-12-14__medicamentos_do_pacientes.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo Perfil USUÁRIO</Titulo>
          <Paragrafo>
            Novo perfil criado com as permissões básicas do sistema.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alterações no cadastro de salas</Titulo>
          <Paragrafo>
            Agora a tela do cadastro de salas conta com os campos de "Tipo" de sala e "Capacidade" de pessoas da sala.
          </Paragrafo>
          <Imagem link='/releases/2023-12-14__nova_tela_de_salas.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alteração no cadastro de Feriados</Titulo>
          <Paragrafo>
            Os feriados agora são listados por ano.
          </Paragrafo>
          <Paragrafo>
            Verificar se para aquele ano desejado já existe o feriado e criá-lo de acordo com a necessidade.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Ajustes na saída do paciente do programa</Titulo>
          <Paragrafo>
            O campo "Observações" passa a ser obrigatório quando o paciente sai do programa.
          </Paragrafo>
          <Paragrafo>
            O campo "Observações" agora é listado no relatório "Pacientes dos Programas".
          </Paragrafo>
          <Paragrafo>
            Foi adicionado "Inadimplência" como um novo motivo de saída do programa.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Cadastro de Setores</Titulo>
          <Paragrafo>
            Foi introduzido o cadastro de Setores.
          </Paragrafo>
          <Imagem link='/releases/2023-12-14__cadastro_setor.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-11-04'>
        <Funcionalidade empresas={[1, 9]}>
          <Titulo>Dashboard Indicadores operacionais</Titulo>
          <Paragrafo>
            Foi adicionado o novo dashboard Indicadores Operacionais no menu de dashboards Indicadores Estratégicos, contendo informações sobre a saída dos pacientes dos programas, informações sobre as consultas médicas e atendimentos.
          </Paragrafo>
          <Imagem link='/releases/2023-11-04__dashboard_indicadores_operacionais.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Salas da Unidade</Titulo>
          <Paragrafo>
            Agora é possivel adicionar salas dentro de uma unidade.
          </Paragrafo>
          <Imagem link='/releases/2023-11-04__salas_da_unidade.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-10-18'>
        <Funcionalidade empresas={[1, 7, 8, 9]}>
          <Titulo>Teleconsulta de Terapia Ocupacional</Titulo>
          <Paragrafo>
            Novo tipo de atendimento criado. Segue o mesmo padrão da Teleconsulta de Enfermagem.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alerta de Desligamento de Programa do Paciente</Titulo>
          <Paragrafo>
            Ao desligar ou remover um programa do paciente e existir agendamentos futuros, é exibido um alerta para contactar o paciente e cancelar os agendamentos.
          </Paragrafo>
          <Imagem link='/releases/2023-10-18__paciente_saiu_programa.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Sumário de Alta da Internação</Titulo>
          <Paragrafo>
            Alterado identificação de sumário de alta na pesquisa das internações para um texto mais destacado.
          </Paragrafo>
          <Imagem link='/releases/2023-10-18__sumario_de_alta.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-10-07'>
        <Funcionalidade>
          <Titulo>Novo formulário PHQ9</Titulo>
          <Paragrafo>
            A partir de agora temos o questionário PHQ9 no LifeCode. Confira!
          </Paragrafo>
          <Paragrafo>
            Esse novo formulário pode ser acessado no prontuário, sob a seção de Questionários / PHQ9.
          </Paragrafo>
          <Imagem link='/releases/2023-10-07__phq9.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo cadastro de Instruções de Agendamento</Titulo>
          <Paragrafo>
            O cadastro de instruções da agendamento agora está mais eficiente e inteligente, trazendo mais facilidade e dinamismo no seu uso.
          </Paragrafo>
          <Paragrafo>
            É possível agora:
          </Paragrafo>
          <Topico>Cadastrar várias séries de instruções</Topico>
          <Topico>Alterar e Excluir todas as instruções de uma série em uma só vez</Topico>
          <Topico>Cadastrar instruções pulando semanas</Topico>
          <Imagem link='/releases/2023-10-07__serie_instrucoes.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-10-03'>
        <ListaDeBugs>
          <Bug>Correção do bug do congelamento de tela ao retornar da prescrição Memed.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-09-30'>
        <Funcionalidade empresas={[1]}>
          <Titulo>Dashboard de Tarefa de Captação UNIMED BH</Titulo>
          <Paragrafo>
            Disponibilizado o acesso ao dashboard da tarefa de Captação da Unimed BH com informações sobre o número de tarefas, número de pacientes captados e não captados, além do acompanhamento do cumprimento dos prazos de visita e contatos assistenciais.
          </Paragrafo>
          <Imagem link='/releases/2023-09-30__dashboard_tarefa_de_captacao_unimed_bh.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novos dashboards no menu de Indicadores Estratégicos</Titulo>
          <Paragrafo>
            Foram adicionados o dashboard Indicadores de Manutenção do Cuidado que incluem métricas relacionadas à gestão de condições como Diabetes, DCV, Sarcopenia, IVCF além de conter informações sobre os medicamentos inapropriados; e o dashboard Indicadores de Desfecho Clínico, que acompanha dados sobre Fratura de fêmur, Internações clínicas, Idas ao PS e Reinternações.
          </Paragrafo>
          <Imagem link='/releases/2023-09-30__dashboards_desfecho_clinico_manutencao_do_cuidado.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Ajuste na escala dos gráficos do prontuário</Titulo>
          <Paragrafo>
            Os gráficos do prontuário agora apresentam uma exibição dinâmica de acordo com a data de realização dos exames.
          </Paragrafo>
          <Imagem link='/releases/2023-09-30__graficos.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo cadastro de Bloqueios da agenda</Titulo>
          <Paragrafo>
            O cadastro de bloqueios da agenda agora está mais eficiente e inteligente, trazendo mais facilidade e dinamismo no seu uso.
          </Paragrafo>
          <Paragrafo>
            É possível agora:
          </Paragrafo>
          <Topico>Cadastrar várias séries de bloqueios</Topico>
          <Topico>Alterar e Excluir todos os bloqueios de uma série em uma só vez</Topico>
          <Topico>Cadastrar bloqueios pulando semanas</Topico>
          <Paragrafo>
            IMPORTANTE! Bloqueios anteriores a data atual nunca serão alterados ou excluídos de uma agenda.
          </Paragrafo>
          <Video link='https://www.youtube.com/watch?v=Z4DolFMHGCc' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Adição de CIDs no cadastro de Diagnósticos</Titulo>
          <Paragrafo>
            O diagnóstico agora passar a ter um CID.
          </Paragrafo>
          <Imagem link='/releases/2023-09-30__nova_tela_de_diagnosticos.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-09-16'>
        <Funcionalidade>
          <Titulo>Menu para o dashboard de Indicadores Estratégicos</Titulo>
          <Paragrafo>
            Para uma melhor visualização dos dados do dashboard de Indicadores Estratégicos Assistenciais, criou-se um menu que agrupará diferentes dashboards com objetivos específicos. O primeiro lançamento é do dashboard de Indicadores Demográficos que apresenta informações como a evolução da quantidade de pacientes e o perfil etário da população assistida, além do dashboard de Indicadores do Perfil de Fragilidade que apresenta informações como a complexidade dos pacientes, os valores médios do IVCF e da quantidade de diagnósticos e a prevalência de diagnósticos da população assistida.
          </Paragrafo>
          <Imagem link='/releases/2023-09-16__indicadores-estrategicos.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Dashboard de Resolutividade do Pronto Cuidar disponível para a empresa UN - Unimed Nacional</Titulo>
          <Paragrafo>
            Disponibilizado o dashboard de resolutividade do pronto cuidar que apresenta os dados da quantidade e quais pacientes vão ao pronto atendimento e internam em estabelecimentos externos após se consultarem no pronto atendimento interno da unidade São Paulo.
          </Paragrafo>
          <Imagem link='/releases/2023-09-16__resolutividade_pronto_cuidar.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alterações na seleção do Tipo de Atendimento</Titulo>
          <Paragrafo>Agora quando selecionado um tipo de atendimento, é exibido um texto explicativo do tipo selecionado.</Paragrafo>
          <Imagem link='/releases/2023-09-16__selecao_tipo_de_atendimento.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novos status de encaminhamento</Titulo>
          <Paragrafo>
            Foram adicionados dois novos status de encaminhamento, "Avaliando" e "Contatando paciente". Além disso, foi adicionada a informação do status atual na tela de alterar o status.
          </Paragrafo>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug que incluia os feriados ao listar os horários livres no wizard de agendamentos.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-08-30'>
        <Funcionalidade>
          <Titulo>Taxa de Filtração Glomerular</Titulo>
          <Paragrafo>
            A Taxa de Filtração Glomerular (TFG) foi incluída juntamente ao campo do histórico de creatinina e aos seus gráficos.
          </Paragrafo>
          <Imagem link='/releases/2023-08-30__taxa_de_filtracao_glomerular.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Alterações no Exame de Imagem</Titulo>
          <Paragrafo>
            A partir de agora, para salvar os exames no prontuário, não será mais necessário incluir uma imagem. A síntese ou o upload da imagem do exame serão aceitos.
          </Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2023-08-24'>
        <Funcionalidade>
          <Titulo>Novo formulário Mini Mental</Titulo>
          <Paragrafo>
            A partir de agora temos o questionário mini mental no life code. Confira!
          </Paragrafo>
          <Paragrafo>
            Esse novo formulário pode ser acessado no prontuário, sob a seção de Questionários / Mini Mental.
          </Paragrafo>
          <Imagem link='/releases/2023-08-24__minimental.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Dashboard de Volumetria disponível para a empresa UN - Unimed Nacional</Titulo>
          <Paragrafo>
            Disponibilizado o dashboard de volumetria que apresenta os dados acumulados sobre a quantidade de atendimentos, quantidade de paciente e o controle de pacientes hipertensos e diabéticos.
          </Paragrafo>
          <Imagem link='/releases/2023-08-24__dashboard_de_volumetria.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug ao mostrar informações da tarefa.</Bug>
          <Bug>Correção do bug ao excluir 'outro medicamento e concentração'.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-08-22'>
        <Funcionalidade>
          <Titulo>Indicador do paciente</Titulo>
          <Paragrafo>
            Novo indicador do paciente:
          </Paragrafo>
          <Topico>
            Nível de atraso 3 no plano de cuidados da Unimed BH
          </Topico>
          <Imagem link='/releases/2023-08-22__nivel3.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-08-18'>
        <Funcionalidade>
          <Titulo>Histórico do medicamento do paciente</Titulo>
          <Paragrafo>
            O histórico de medicamentos ativos dos pacientes agora está disponível.
          </Paragrafo>
          <Paragrafo>
            Essa funcionalidade pode ser acessada no prontuário, sob a seção de Diagnósticos/Medicamentos.
          </Paragrafo>
          <Paragrafo>
            Ao clicar no botão [i] associado a cada medicamento, você terá acesso ao histórico.
          </Paragrafo>
          <Imagem link='/releases/2023-08-18__historico_do_medicamento.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-08-12'>
        <Funcionalidade>
          <Titulo>Melhoria no campo Conduta</Titulo>
          <Paragrafo>
            Implementamos a funcionalidade de templates dentro do campo Conduta.
          </Paragrafo>
          <Imagem link='/releases/2023-08-12__campo_conduta.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Agrupamento dos dashboards de plano de cuidados de São Paulo</Titulo>
          <Paragrafo>
            Inserção do botão que agrupa os dashboards responsáveis para o acompanhamento dos planos de cuidados específicos para a empresa UN - São Paulo.
          </Paragrafo>
          <Imagem link='/releases/2023-08-12__dashboards_un.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Criação de dashboards específicos para acompanhamento dos planos de cuidados em São Paulo</Titulo>
          <Paragrafo>
            Separação do dashboard de Plano de Cuidados entre o plano Médico e o da Enfermagem.
          </Paragrafo>
          <Imagem link='/releases/2023-08-12__dashboards_de_planos_de_cuidado_un.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Edição de medicamentos no prontuário</Titulo>
          <Paragrafo>
            Agora você pode editar os medicamentos do paciente, sem precisar excluí-los do prontuário.
          </Paragrafo>
          <Paragrafo />
          <Paragrafo>
            Ao editar um medicamento, é possível:
          </Paragrafo>
          <Topico>
            Alterar a dosagem (concentração)
          </Topico>
          <Topico>
            Alterar a posologia (frequência)
          </Topico>
          <Topico>
            Incluir observações
          </Topico>
          <Paragrafo />
          <Paragrafo>
            IMPORTANTE! Não é possível incluir medicamentos duplicados para o mesmo paciente com dosagens iguais ou diferentes.
          </Paragrafo>
          <Imagem link='/releases/2023-08-12__edicao_de_medicamentos.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do identificador externo duplicado na prescrição da memed.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-07-28'>
        <Funcionalidade>
          <Titulo>Registro de Óbito do Paciente</Titulo>
          <Paragrafo>
            Aprimoramos nosso sistema com a capacidade de adicionar e gerir informações de óbito no perfil de um paciente.
          </Paragrafo>
          <Paragrafo>
            Quando a opção "Paciente Faleceu" é selecionada, é necessário preencher os campos: Período, Data e "Como o óbito foi informado?".
          </Paragrafo>
          <Paragrafo>
            Exemplo: Período: Mês/Ano. Data: 07/2023. "Como o óbito foi informado?": Recebemos a informação por telefone através do seu filho, José.
          </Paragrafo>
          <Paragrafo>
            ATENÇÃO! A confirmação do falecimento de um paciente resulta no cancelamento imediato de todos os seus agendamentos futuros.
          </Paragrafo>
          <Paragrafo>
            IMPORTANTE! A reversão do status de óbito é possível ao selecionar "Paciente Faleceu" = Não. No entanto, os agendamentos cancelados devido ao registro de óbito não serão automaticamente restaurados.
          </Paragrafo>
          <Video link='https://www.youtube.com/watch?v=2GoZpm5QLLw' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Indicador de Óbito</Titulo>
          <Paragrafo>
            Implementamos um novo indicador de óbito do paciente. Este indicador é exibido no registro do paciente, no prontuário médico e nos agendamentos. Adicionalmente, ao passar o cursor do mouse sobre o indicador, a data de óbito do paciente será exibida.
          </Paragrafo>
          <Imagem link='/releases/2023-07-28__identificador_de_obito.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Validação do Status do Paciente</Titulo>
          <Paragrafo>
            O sistema agora valida automaticamente o status do paciente antes de permitir qualquer novo agendamento ou início de atendimento. Se o paciente estiver registrado como falecido, essas ações serão automaticamente impedidas.
          </Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Filtro de Status de Pacientes: Ativos/Inativos</Titulo>
          <Paragrafo>
            Implementamos um novo filtro de status - Ativo/Inativo - para melhorar a gestão de cadastros de pacientes.
          </Paragrafo>
          <Topico>
            "Ativo" (padrão): Refere-se aos pacientes sem registro de óbito.
          </Topico>
          <Topico>
            "Inativo": Aplica-se aos pacientes que têm registro de óbito.
          </Topico>
          <Imagem link='/releases/2023-07-28__filtro_obito_do_paciente.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-07-10'>
        <ListaDeBugs>
          <Bug>Correção na validação do registro do conselho no cadastro de usuário.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-07-05'>
        <Funcionalidade>
          <Titulo>Indicação do próximo agendamento médico do paciente</Titulo>
          <Paragrafo>
            Funcionalidade que mostra o próximo agendamento médico no prontuário e nos dados gerais do paciente.
          </Paragrafo>
          <Imagem link='/releases/2023-07-05__proximo_agendamento_medico.jpeg' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Iniciar atendimento sem agendamento</Titulo>
          <Paragrafo>
            Foi inserido uma notificação para vincular atendimentos aos agendamentos, caso necessário.
          </Paragrafo>
          <Imagem link='/releases/2023-07-05__atendimento_sem_agendamento.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-06-16'>
        <Funcionalidade>
          <Titulo>Cadastro de Fabricantes</Titulo>
          <Paragrafo>
            Foi introduzido o cadastro de Fabricantes.
          </Paragrafo>
          <Imagem link='/releases/2023-06-16__cadastro_fabricante.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novos campos da vacina</Titulo>
          <Paragrafo>Adição da pergunta se a vacina foi aplicada na unidade, que em caso positivo, deve-se preencher os seguintes novos campos: fabricante, lote, validade e local da aplicação.
          </Paragrafo>
          <Imagem link='/releases/2023-06-16__novos_campos_vacina.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção de bug que impedia a gravação de um atendimento contendo uma prescrição em branco.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-06-14'>
        <Funcionalidade>
          <Titulo>Nova pergunta no questionáro IVCF</Titulo>
          <Paragrafo>Adição de uma pergunta complementar ao questionáro de Índice de Vulnerabilidade Clínico-Funcional (IVCF), na nova seção "Moradia", perguntando se o paciente mora sozinho.
          </Paragrafo>
          <Imagem link='/releases/2023-06-14__nova_secao_ivcf.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Atualização no prontuário</Titulo>
          <Paragrafo>O intervalo válido do exame Hemoglobina passa a ser um valor entre 1 e 25 g/dL.</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2023-06-12'>
        <Funcionalidade>
          <Titulo>Prescrições da Memed</Titulo>
          <Paragrafo>Agora, o LifeCode passa a armazenar os medicamentos prescritos na Memed de forma estruturada, em nossa base de dados própria.</Paragrafo>
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Atualização no prontuário</Titulo>
          <Paragrafo>O campo "Conduta" agora passa a ser opcional ao gravar um atendimento e obrigatório no momento de finalizá-lo.</Paragrafo>
          <Imagem link='/releases/2023-06-12__obrigatorio_ao_finalizar.png' />
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug de pesquisar Células.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-06-05'>
        <Funcionalidade>
          <Titulo>Novos indicadores de pacientes</Titulo>
          <Paragrafo>
            Introduzimos dois novos indicadores importantes:
          </Paragrafo>
          <Paragrafo>
            O primeiro é o "Atraso no plano de cuidados da Unimed BH", desenvolvido para monitorar o tempo de atraso no plano de cuidados. A classificação acontece da seguinte forma: até 150 dias, o plano é considerado 'em dia'; entre 150 e 270 dias, classifica-se como 'nível de atraso 1'; atrasos de 270 a 400 dias correspondem ao 'nível de atraso 2'; e atrasos superiores a 400 dias são considerados 'em processo de desligamento'. Esta ferramenta é fundamental para identificar casos que precisam de atenção prioritária e para planejar estratégias de intervenção mais eficazes.
          </Paragrafo>
          <Paragrafo>
            O segundo indicador introduzido é a 'Alta densidade de cuidado'. Este critério destaca os pacientes que necessitam de maior atenção e cuidados especiais. São considerados de 'alta densidade de cuidado' aqueles pacientes que tenham 90 anos ou mais, que tenham sido internados nos últimos doze meses, que tenham procurado o pronto atendimento duas vezes nos últimos seis meses ou que pertençam à "Super Célula". Este indicador é crucial para otimizar os recursos e garantir que os pacientes que mais precisam de cuidados recebam a atenção necessária.
          </Paragrafo>
          <Imagem link='/releases/2023-06-05__novos_indicadores.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Atualização no prontuário</Titulo>
          <Paragrafo>Reformulamos a apresentação dos campos de exames no prontuário para proporcionar uma visão mais abrangente e eficiente. Agora, você pode visualizar mais campos simultaneamente, otimizando a interação e a velocidade de análise.</Paragrafo>
          <Imagem link='/releases/2023-06-05__componentes_do_prontuario.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-05-25'>
        <Funcionalidade>
          <Titulo>Alterações na Agenda</Titulo>
          <Paragrafo>Agora a Agenda do Profissional de saude é apresentada em "tela cheia", exibindo um maior número de informações de uma só vez.</Paragrafo>
          <Paragrafo>Caso queira exibir as agendas de outros profissionais, basta clicar em "Exibir demais profissionais".</Paragrafo>
          <Imagem link='/releases/2023-05-25__agenda_do_profissonal.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-05-19'>
        <Funcionalidade>
          <Titulo>Alterações no prontuário</Titulo>
          <Topico>Adicionado o campo de exame "Sódio".</Topico>
          <Topico>Adicionado o campo "Conduta".</Topico>
          <Paragrafo>O campo "Conduta" é obrigatório nos seguintes tipos de atendimento: EVOLUÇÃO PRESENCIAL, ORIENTAÇÃO MÉDICA, TELECONSULTA MÉDICA, VÍDEO CONSULTA MÉDICA, VISITA MÉDICA DOMICILIAR.</Paragrafo>
        </Funcionalidade>
      </Release>

      <Release numero='2023-05-13'>
        <Funcionalidade>
          <Titulo>Aba "Minha Carteira" no Tarefas</Titulo>
          <Paragrafo>Agora é possível filtrar as tarefas dos pacientes da sua carteira.</Paragrafo>
          <Paragrafo>A nova aba só está disponível para médicos(as) e enfermeiros(as).</Paragrafo>
          <Imagem link='/releases/2023-05-13__aba_minha_carteira.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Formulário de Avaliação Fisioterápica</Titulo>
          <Topico>Alteração no nome para Avaliação Física e Funcional.</Topico>
        </Funcionalidade>
      </Release>

      <Release numero='2023-05-09'>
        <Funcionalidade>
          <Titulo>Assinaturas Pendentes</Titulo>
          <Paragrafo>Aumento da quantidade de assinaturas por lote de 10 para 30.</Paragrafo>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção da ordenação no histórico de prescrições.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-05-05'>
        <Funcionalidade>
          <Titulo>Prescrições no prontuário</Titulo>
          <Paragrafo>A prescrição da Memed agora está em uma sessão separada da prescrição do LifeCode.</Paragrafo>
          <Paragrafo>Os históricos de prescrição continuam funcionando de forma integrada para ambas as plataformas.</Paragrafo>
          <Imagem link='/releases/2023-05-05__prescricao_da_memed.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Dashboard de Plano de Cuidados de Consultas Médicas - UN</Titulo>
          <Paragrafo>Dashboard para acompanhamento do plano de cuidados de consultas médicas com as regras definidas especificamente para UN em São Paulo.</Paragrafo>
          <Paragrafo>O acesso ao dashboard é limitado por permissões.</Paragrafo>
          <Imagem link='/releases/2023-05-05__dashboard_plano_de_cuidados_de_consultas_medicas_un.png' />
        </Funcionalidade>
      </Release>

      <Release numero='2023-04-15'>
        <Funcionalidade>
          <Titulo>Alterações no formulário de Avaliação Fisioterápica</Titulo>
          <Topico>Adicionado o campo (Sim/Não) se o paciente realiza atividade física.</Topico>
          <Topico>Na seção Atividade Física de Lazer, alterado a opção do "Tempo por semana" de horas para minutos.</Topico>
          <Topico>Na seção Dor, incluiu-se uma pergunta sobre o tipo da dor (Opções: Dor Nociceptiva, Dor Somática Superficial, Dor Somática Profunda, Dor Irradiada Dor Referida, Dor Visceral Dor Neuropática, Dor Centralizada).</Topico>
          <Topico>Permitido valores fracionados e nulos no "TUG", "Senta e Levanta" e "Velocidade da Marcha".</Topico>
          <Topico>Adicionado opção (Com auxílio, Sem auxílio, Não consegue realizar) para "TUG", "Senta e Levanta", "Velocidade da Marcha", "Romberg" e "Semi-Tamdem".</Topico>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção do bug nas abas do cadastro de pacientes.</Bug>
          <Bug>Correção do bug de sessão aberta na assinatura digital.</Bug>
        </ListaDeBugs>
      </Release>

      <Release numero='2023-04-10'>
        <Funcionalidade>
          <Titulo>Automação da exibição de popup de Releases</Titulo>
          <Paragrafo>As novas funcionalidades liberadas no sistema, agora serão listadas automaticamente no primeiro login de cada usuário.</Paragrafo>
          <Paragrafo>O usuário poderá exibir a popup de release em qualquer momento clicando no rodapé do sistema.</Paragrafo>
          <Topico>Parágrafo: explicação da nova funcionalidade.</Topico>
          <Topico>Tópico: destaques da nova funcionalidade.</Topico>
          <Topico>Imagem: print da nova tela ou nova funcionalidade.</Topico>
          <Topico>Vídeo: vídeo explicativo no Youtube da nova funcionalidade.</Topico>
          <Topico>Bugs: lista das correções realizadas nesta release.</Topico>
          <Imagem link='/releases/2023-04-10__release.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Cadastro de célula</Titulo>
          <Paragrafo>Adição de uma página de cadastro de células.</Paragrafo>
          <Topico>Funcionalidades: adição, remoção e alteração.</Topico>
          <Topico>Caminho: Menu / Cadastros / Células.</Topico>
          <Paragrafo>Alteração no cadastro de pacientes, onde o campo célula agora é uma lista pré estabelecida pela clínica.</Paragrafo>
          <Paragrafo>Atualização das células cadastradas anteriormente no sistema.</Paragrafo>
          <Imagem link='/releases/2023-04-10__cadastro_de_celula.png' />
        </Funcionalidade>
        <Funcionalidade>
          <Titulo>Novo perfil MENOR APRENDIZ</Titulo>
          <Paragrafo>Adição do novo perfil no cadastro de usuários.</Paragrafo>
        </Funcionalidade>
        <ListaDeBugs>
          <Bug>Correção da adição de sumário de alta na internação.</Bug>
        </ListaDeBugs>
      </Release>

    </ListaDeReleases>
  )
}
