import api from '../api-major'

const listarUsuariosBloqueados = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros.termo ? `&termo=${filtros.termo}` : ''
  const parametroMotivo = filtros.motivosDeBloqueio ? `&motivosDoBloqueio=${filtros.motivosDeBloqueio}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome},+nome,+identificador` : '&ordem=+dataEHora,+nome,+identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10'

  return api.get(`/usuarios/bloqueados${paginacao}${parametroTermo}${parametroMotivo}${ordem}${tamanhoDaPaginaFormatado}`)
}

export default {
  listarUsuariosBloqueados,
}