import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_SEXOS, LISTOU_SEXOS, ERRO_AO_LISTAR_SEXOS } from './tipos'

const listouSexos = sexos => ({
  type: LISTOU_SEXOS,
  sexos
})

const erroAoListarSexos = erro => ({
  type: ERRO_AO_LISTAR_SEXOS,
  erro
})

export const listarSexos = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_SEXOS })

    try {
      let resultado = await api.listarSexos()

      dispatch(listouSexos(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarSexos(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os sexos.')))
    }
  }

  acao.meta = {
    cache: LISTAR_SEXOS
  }

  return acao
}