import React, { useEffect, useMemo } from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Lista from './lista'
import ListaDeCamposExternos from './campos-externos/lista'
import ListaDeConvenio from './convenios/lista'
import ListaDePlanosDeCuidado from './planos-de-cuidado/lista'
import ListaDePrograma from './programas/lista'
import ListaDeTag from './tags/lista'
import Formulario from './formulario'
import FormularioDeCampoExterno from './campos-externos/formulario'
import FormularioDeConvenio from './convenios/formulario'
import FormularioDePlanosDeCuidado from './planos-de-cuidado/formulario'
import FormularioDePrograma from './programas/formulario'
import FormularioDeTag from './tags/formulario'
import Cabecalho from './cabecalho'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'
import * as permissoes from '../seguranca/permissoes'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'
import moment from 'moment'

export default function Pacientes(props) {
  const {
    adicionarCampoExternoDoPaciente,
    adicionarConvenioDoPaciente,
    adicionarFotoDoPaciente,
    adicionarPaciente,
    adicionarPlanoDeCuidadoDoPaciente,
    adicionarProgramaDoPaciente,
    adicionarTagNoPaciente,
    alterarCampoExternoDoPaciente,
    alterarConvenioDoPaciente,
    alterarProgramaDoPaciente,
    alterarPlanoDeCuidadoDoPaciente,
    empresa,
    estadosCivis,
    fotos,
    grausDeRelacoes,
    history,
    listarAssociacoes,
    listarCamposExternosDoPaciente,
    listarCelulas,
    listarConsultoresDoPaciente,
    listarConveniosDoPaciente,
    listarEnfermeirosDoPaciente,
    listarEstadosCivis,
    listarGrausDeRelacoes,
    listarMedicosDoPaciente,
    listarMotivosDeCancelamentoDoConvenio,
    listarMotivosDeInativacaoDoPaciente,
    listarMotivosDeSaidaDosPlanosDeCuidado,
    listarMotivosDeSaidaDosProgramas,
    listarOperadorasDoPaciente,
    listarPacientes,
    listarPlanosDeCuidado,
    listarPlanosDeCuidadoDoPaciente,
    listarProgramas,
    listarProgramasDoPaciente,
    listarProprietariosDeTelefones,
    listarPsicologosDoPaciente,
    listarSexos,
    listarTagsDePacientes,
    listarUnidadesFederativas,
    listarUnidadesDaEmpresa,
    location,
    match,
    paciente,
    pacientes,
    planosDeCuidado,
    programas,
    proprietariosDeTelefones,
    recuperarCampoExternoDoPaciente,
    recuperarConvenioDoPaciente,
    recuperarEnderecoDoPacientePorCep,
    recuperarFotoDoPacientePorIdentificador,
    recuperarFotoOriginalDoPacientePorIdentificador,
    recuperarPacientePorIdentificador,
    recuperarPlanoDeCuidadoDoPaciente,
    recuperarProgramaDoPaciente,
    removerCampoExternoDoPaciente,
    removerConvenioDoPaciente,
    removerFotoDoPaciente,
    removerImagemDaMemoriaPorIdentificador,
    removerPlanoDeCuidadoDoPaciente,
    removerProgramaDoPaciente,
    removerTagDoPaciente,
    salvarPaciente,
    sexos,
    unidadesFederativas
  } = props

  const permissaoDeIniciarAtendimento = usePossuiAsPermissoes([permissoes.INICIAR_ATENDIMENTO])
  const permissaoDeListarAtendimentos = usePossuiAsPermissoes([permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE])
  const permissaoDeListarOperadoras = usePossuiAsPermissoes([permissoes.LISTAR_OPERADORAS])
  const exibirBotaoProntuario = permissaoDeIniciarAtendimento || permissaoDeListarAtendimentos

  const dadosDoPaciente = useMemo(() => {
    return extrairDadosDoFormulario(pacientes)
  }, [pacientes])

  const unidades = useMemo(() => {
    return empresa && empresa.unidades && empresa.unidades.itens ? empresa.unidades.itens : []
  }, [empresa])

  useEffect(() => { listarUnidadesDaEmpresa() }, [listarUnidadesDaEmpresa])

  useEffect(() => {
    if (permissaoDeListarOperadoras) {
      listarOperadorasDoPaciente()
    }
  }, [listarOperadorasDoPaciente, permissaoDeListarOperadoras])

  useEffect(() => {
    dadosDoPaciente.paginaDeDados.dados && dadosDoPaciente.paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.identificador, item.foto)
    })

    return () => dadosDoPaciente.paginaDeDados.dados && dadosDoPaciente.paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })
  }, [dadosDoPaciente.paginaDeDados.dados, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    if (paciente.foto) {
      recuperarFotoDoPacientePorIdentificador(paciente.identificador, paciente.foto)
    }

    return () => paciente.foto && removerImagemDaMemoriaPorIdentificador(paciente.foto)
  }, [paciente.foto, paciente.identificador, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])


  const salvar = async formulario => {
    let dataDoObito = null

    if (formulario.motivoDaInativacao === 'obito') {
       dataDoObito = formulario.dataDoObito

      switch (formulario.precisaoDaDataDoObito) {
        case 'Mês/Ano':
          dataDoObito = moment(dataDoObito).startOf('month').format('YYYY-MM-DD')
          break
        default:
          dataDoObito = formulario.dataDoObito
      }
    }

    const dados = {
      ...formulario,
      dataDeNascimento: formulario.dataDeNascimento,
      origemDosDados: 'prontuario',
      unidadeFederativa: formulario.uf,
      estadoCivil: formulario.estadoCivil ? formulario.estadoCivil : null,
      dataDoObito: dataDoObito
    }

    const novoRegistro = !dados.identificador
    const acao = novoRegistro ? adicionarPaciente : salvarPaciente
    const identificadorPaciente = await acao(dados)

    if (identificadorPaciente) {
      history.push(`/cadastros/pacientes/${identificadorPaciente}`)
    }
  }

  const salvarConvenio = async formulario => {
    const novoRegistro = !formulario.identificador
    const identificadorDoPaciente = formulario.identificadorDoPaciente

    const convenio = {
      ...formulario,
      origemDosDados: 'prontuario',
      status: formulario.status ? 'Ativo' : 'Cancelado',
      validade: formatarDataParaFormatoUniversal(formulario.validade),
    }

    delete convenio.identificadorDoPaciente

    const acao = novoRegistro ? adicionarConvenioDoPaciente : alterarConvenioDoPaciente
    const salvou = await acao(identificadorDoPaciente, convenio)

    if (salvou) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/convenios`)
      listarConveniosDoPaciente(identificadorDoPaciente)
    }

    return salvou
  }

  const removerConvenioDoPacienteSelecionado = async (identificadorDoPaciente, identificador) => {
    const removeu = await removerConvenioDoPaciente(identificadorDoPaciente, identificador)

    if (removeu) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/convenios`)
    }

    return removeu
  }

  const salvarPrograma = async formulario => {
    const novoRegistro = !formulario.identificador
    const identificadorDoPaciente = formulario.identificadorDoPaciente
    const programa = {
      ...formulario,
      inicio: formatarDataParaFormatoUniversal(formulario.inicio),
    }

    if (formulario.fim) {
      programa.fim = formatarDataParaFormatoUniversal(formulario.fim)
    } else {
      programa.motivoDeSaida = null
    }

    if (!novoRegistro) {
      delete programa.identificadorDoPaciente
      delete programa.programa
    }

    const acao = novoRegistro ? adicionarProgramaDoPaciente : alterarProgramaDoPaciente
    const salvou = await acao(identificadorDoPaciente, programa)

    if (salvou) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/programas`)
      listarProgramasDoPaciente(identificadorDoPaciente)
    }

    return salvou
  }

  const removerProgramaDoPacienteSelecionado = async (identificadorDoPaciente, identificador) => {
    const removeu = await removerProgramaDoPaciente(identificadorDoPaciente, identificador)

    if (removeu) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/programas`)
    }

    return removeu
  }

  const salvarPlanoDeCuidado = async formulario => {
    const novoRegistro = !formulario.identificador
    const identificadorDoPaciente = formulario.identificadorDoPaciente
    const planoDeCuidado = {
      ...formulario,
      inicio: formatarDataParaFormatoUniversal(formulario.inicio),
    }

    if (formulario.fim) {
      planoDeCuidado.fim = formatarDataParaFormatoUniversal(formulario.fim)
    } else {
      planoDeCuidado.motivoDeSaida = null
    }

    if (!novoRegistro) {
      delete planoDeCuidado.identificadorDoPaciente
      delete planoDeCuidado.planoDeCuidado
    }

    const acao = novoRegistro ? adicionarPlanoDeCuidadoDoPaciente : alterarPlanoDeCuidadoDoPaciente
    const salvou = await acao(identificadorDoPaciente, planoDeCuidado)

    if (salvou) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/planos-de-cuidado`)
      listarPlanosDeCuidadoDoPaciente(identificadorDoPaciente)
    }

    return salvou
  }

  const removerPlanoDeCuidadoDoPacienteSelecionado = async (identificadorDoPaciente, identificador) => {
    const removeu = await removerPlanoDeCuidadoDoPaciente(identificadorDoPaciente, identificador)

    if (removeu) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/planos-de-cuidado`)
    }

    return removeu
  }

  const salvarCampoExterno = async formulario => {
    const novoRegistro = !formulario.identificador
    const identificadorDoPaciente = formulario.identificadorDoPaciente

    if (!novoRegistro) {
      delete formulario.identificadorDoPaciente
    }

    const acao = novoRegistro ? adicionarCampoExternoDoPaciente : alterarCampoExternoDoPaciente
    const salvou = await acao(identificadorDoPaciente, formulario)

    if (salvou) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/campos-externos`)
      listarCamposExternosDoPaciente(identificadorDoPaciente)
    }

    return salvou
  }

  const removerCampoExternoSelecionado = async (identificadorDoPaciente, identificador) => {
    const removeu = await removerCampoExternoDoPaciente(identificadorDoPaciente, identificador)

    if (removeu) {
      history.push(`/cadastros/pacientes/${identificadorDoPaciente}/campos-externos`)
    }

    return removeu
  }

  const abaAtiva = (props) => {
    const abas = {
      '/convenios': 1,
      '/programas': 2,
      '/planos-de-cuidado': 3,
      '/tags': 4,
      '/campos-externos': 5
    }
    const url = location.pathname.replace(match.url + '/' + props.match.params.identificador, '')
    return abas[url] || 0
  }

  const aoClicar = (id, rota) => rota ? history.push(`${match.url}/${id}/${rota}`) : history.push(`${match.url}/${id}`)

  const podeListarPacientes = usePossuiAsPermissoes([permissoes.LISTAR_PACIENTES])
  const podeListarConvenios = usePossuiAsPermissoes([permissoes.LISTAR_CONVENIOS_DO_PACIENTE])
  const podeListarProgramas = usePossuiAsPermissoes([permissoes.LISTAR_PROGRAMAS_DO_PACIENTE])
  const podeListarPlanosDeCuidado = usePossuiAsPermissoes([permissoes.LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE])
  const podeListarTags = usePossuiAsPermissoes([permissoes.LISTAR_TAGS_ATIVAS_DE_PACIENTES])
  const podeListarCamposExternos = usePossuiAsPermissoes([permissoes.LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_PACIENTES]}>
              <Lista
                {...props}
                filtros={dadosDoPaciente.filtros}
                fotos={fotos}
                ordenacao={dadosDoPaciente.ordenacao}
                paginaDeDados={dadosDoPaciente.paginaDeDados}
                pesquisar={listarPacientes}
                recuperarFotoOriginalDoPacientePorIdentificador={recuperarFotoOriginalDoPacientePorIdentificador}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/novo`,
          `${match.path}/:identificador`,
          `${match.path}/:identificador/convenios`,
          `${match.path}/:identificador/convenios/novo`,
          `${match.path}/:identificador/convenios/:identificadorDoConvenio`,
          `${match.path}/:identificador/programas`,
          `${match.path}/:identificador/programas/novo`,
          `${match.path}/:identificador/programas/:identificadorDoPrograma`,
          `${match.path}/:identificador/planos-de-cuidado`,
          `${match.path}/:identificador/planos-de-cuidado/novo`,
          `${match.path}/:identificador/planos-de-cuidado/:identificadorDoPlanoDeCuidado`,
          `${match.path}/:identificador/tags`,
          `${match.path}/:identificador/campos-externos`,
          `${match.path}/:identificador/campos-externos/novo`,
          `${match.path}/:identificador/campos-externos/:identificadorDoCampoExterno`,
        ]}
        render={
          props => {
            const identificador = props.match.params.identificador
            return (
              <div className='page-paciente-dados'>
                <div className='container'>
                  <div className='main-content-form'>
                    <div className='header-page-form'>
                      <Breadcrumb />
                    </div>
                    <Abas abaInicial={abaAtiva(props)}>
                      <div className='header-page-bar mt-0'>
                        <div className='header-page-nav'>
                          <ListaDeAbas className='tab'>
                            {podeListarPacientes ?
                              <Aba className='tab-item' aoClicar={() => identificador ? aoClicar(identificador) : ''}>Dados Gerais</Aba>
                            : null}
                            {podeListarConvenios ?
                              <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => aoClicar(identificador, 'convenios')}>Convênios</Aba>
                            : null}
                            {podeListarProgramas ?
                              <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => aoClicar(identificador, 'programas')}>Programas</Aba>
                            : null}
                            {podeListarPlanosDeCuidado ?
                              <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => aoClicar(identificador, 'planos-de-cuidado')}>Planos de Cuidado</Aba>
                            : null}
                            {podeListarTags ?
                              <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => aoClicar(identificador, 'tags')}>Tags</Aba>
                            : null}
                            {podeListarCamposExternos ?
                              <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => aoClicar(identificador, 'campos-externos')}>Campos Externos</Aba>
                            : null}
                          </ListaDeAbas>
                        </div>
                        {identificador && exibirBotaoProntuario && 
                        <>
                          <div className='header-page-tools d-lg-flex d-none'>
                            <Link to={`/cadastros/pacientes/${identificador}/prontuario`} className='button --primary'>
                              <i className='icon icon-doctor'></i>
                              Prontuário
                            </Link>
                          </div>
                        </>                          
                        }
                      </div>
                      <Paineis>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/novo`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ADICIONAR_PACIENTE]}>
                                    <Formulario
                                      {...props}
                                      adicionarFotoDoPaciente={adicionarFotoDoPaciente}
                                      associacoes={dadosDoPaciente.associacoes}
                                      celulas={dadosDoPaciente.celulas}
                                      consultores={dadosDoPaciente.consultores}
                                      enfermeiros={dadosDoPaciente.enfermeiros}
                                      estadosCivis={estadosCivis}
                                      grausDeRelacoes={grausDeRelacoes}
                                      listarAssociacoes={listarAssociacoes}
                                      listarCelulas={listarCelulas}
                                      listarConsultoresDoPaciente={listarConsultoresDoPaciente}
                                      listarEnfermeirosDoPaciente={listarEnfermeirosDoPaciente}
                                      listarEstadosCivis={listarEstadosCivis}
                                      listarGrausDeRelacoes={listarGrausDeRelacoes}
                                      listarMedicosDoPaciente={listarMedicosDoPaciente}
                                      listarMotivosDeInativacaoDoPaciente={listarMotivosDeInativacaoDoPaciente}
                                      listarProprietariosDeTelefones={listarProprietariosDeTelefones}
                                      listarPsicologosDoPaciente={listarPsicologosDoPaciente}
                                      listarSexos={listarSexos}
                                      listarUnidadesFederativas={listarUnidadesFederativas}
                                      medicos={dadosDoPaciente.medicos}
                                      proprietariosDeTelefones={proprietariosDeTelefones}
                                      psicologos={dadosDoPaciente.psicologos}
                                      recuperarEnderecoDoPacientePorCep={recuperarEnderecoDoPacientePorCep}
                                      reinicializar={false}
                                      salvar={salvar}
                                      sexos={sexos}
                                      unidades={unidades}
                                      unidadesFederativas={unidadesFederativas}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador`}
                              render={
                                props => (
                                  <Formulario
                                    {...props}
                                    associacoes={dadosDoPaciente.associacoes}
                                    adicionarFotoDoPaciente={adicionarFotoDoPaciente}
                                    celulas={dadosDoPaciente.celulas}
                                    consultores={dadosDoPaciente.consultores}
                                    enfermeiros={dadosDoPaciente.enfermeiros}
                                    estadosCivis={estadosCivis}
                                    grausDeRelacoes={grausDeRelacoes}
                                    listarAssociacoes={listarAssociacoes}
                                    listarCelulas={listarCelulas}
                                    listarConsultoresDoPaciente={listarConsultoresDoPaciente}
                                    listarEnfermeirosDoPaciente={listarEnfermeirosDoPaciente}
                                    listarEstadosCivis={listarEstadosCivis}
                                    listarGrausDeRelacoes={listarGrausDeRelacoes}                                    
                                    listarMedicosDoPaciente={listarMedicosDoPaciente}
                                    listarMotivosDeInativacaoDoPaciente={listarMotivosDeInativacaoDoPaciente}
                                    listarProprietariosDeTelefones={listarProprietariosDeTelefones}
                                    listarPsicologosDoPaciente={listarPsicologosDoPaciente}
                                    listarSexos={listarSexos}
                                    listarUnidadesFederativas={listarUnidadesFederativas}
                                    medicos={dadosDoPaciente.medicos}
                                    motivosDeInativacao={dadosDoPaciente.motivosDeInativacao}
                                    paciente={paciente}
                                    proprietariosDeTelefones={proprietariosDeTelefones}
                                    psicologos={dadosDoPaciente.psicologos}
                                    recuperarEnderecoDoPacientePorCep={recuperarEnderecoDoPacientePorCep}
                                    recuperarFotoOriginalDoPacientePorIdentificador={recuperarFotoOriginalDoPacientePorIdentificador}
                                    removerFotoDoPaciente={removerFotoDoPaciente}
                                    reinicializar={true}
                                    salvar={salvar}
                                    sexos={sexos}
                                    unidades={unidades}
                                    unidadesFederativas={unidadesFederativas}
                                    recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                  />
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/convenios`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.LISTAR_CONVENIOS_DO_PACIENTE]}>
                                    <ListaDeConvenio
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      pesquisar={listarConveniosDoPaciente}
                                      convenios={paciente.convenios.itens}
                                      recuperar={recuperarConvenioDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/convenios/novo`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ADICIONAR_CONVENIO_DO_PACIENTE]}>
                                    <FormularioDeConvenio
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarMotivosDeCancelamentoDoConvenio={listarMotivosDeCancelamentoDoConvenio}
                                      motivos={dadosDoPaciente.motivosDeCancelamentoDoConvenio}
                                      operadoras={dadosDoPaciente.operadoras}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarConvenio}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/convenios/:identificadorDoConvenio`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ALTERAR_CONVENIO_DO_PACIENTE]}>
                                    <FormularioDeConvenio
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarMotivosDeCancelamentoDoConvenio={listarMotivosDeCancelamentoDoConvenio}
                                      motivos={dadosDoPaciente.motivosDeCancelamentoDoConvenio}
                                      operadoras={dadosDoPaciente.operadoras}
                                      recuperar={recuperarConvenioDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarConvenio}
                                      remover={removerConvenioDoPacienteSelecionado}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/programas`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.LISTAR_PROGRAMAS_DO_PACIENTE]}>
                                    <ListaDePrograma
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      pesquisar={listarProgramasDoPaciente}
                                      programas={paciente.programas.itens}
                                      recuperar={recuperarProgramaDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/programas/novo`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ADICIONAR_PROGRAMA_DO_PACIENTE]}>
                                    <FormularioDePrograma
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarProgramas={listarProgramas}
                                      listarMotivosDeSaidaDosProgramas={listarMotivosDeSaidaDosProgramas}
                                      motivos={dadosDoPaciente.motivosDeSaidaDosProgramas}
                                      programas={programas}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarPrograma}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/programas/:identificadorDoPrograma`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ALTERAR_PROGRAMA_DO_PACIENTE]}>
                                    <FormularioDePrograma
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarProgramas={listarProgramas}
                                      listarMotivosDeSaidaDosProgramas={listarMotivosDeSaidaDosProgramas}
                                      motivos={dadosDoPaciente.motivosDeSaidaDosProgramas}
                                      programas={programas}
                                      recuperar={recuperarProgramaDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarPrograma}
                                      remover={removerProgramaDoPacienteSelecionado}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/planos-de-cuidado`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE]}>
                                    <ListaDePlanosDeCuidado
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      pesquisar={listarPlanosDeCuidadoDoPaciente}
                                      planosDeCuidado={paciente.planosDeCuidado.itens}
                                      recuperar={recuperarPlanoDeCuidadoDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/planos-de-cuidado/novo`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE]}>
                                    <FormularioDePlanosDeCuidado
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarPlanosDeCuidado={listarPlanosDeCuidado}
                                      listarMotivosDeSaidaDosPlanosDeCuidado={listarMotivosDeSaidaDosPlanosDeCuidado}
                                      motivos={dadosDoPaciente.motivosDeSaidaDosPlanosDeCuidado}
                                      planosDeCuidado={planosDeCuidado}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarPlanoDeCuidado}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/planos-de-cuidado/:identificadorDoPlanoDeCuidado`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE]}>
                                    <FormularioDePlanosDeCuidado
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarPlanosDeCuidado={listarPlanosDeCuidado}
                                      listarMotivosDeSaidaDosPlanosDeCuidado={listarMotivosDeSaidaDosPlanosDeCuidado}
                                      motivos={dadosDoPaciente.motivosDeSaidaDosPlanosDeCuidado}
                                      planosDeCuidado={planosDeCuidado}
                                      recuperar={recuperarPlanoDeCuidadoDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarPlanoDeCuidado}
                                      remover={removerPlanoDeCuidadoDoPacienteSelecionado}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                        <Painel>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/tags`}
                            render={
                              props => (
                                <>
                                  <Cabecalho paciente={paciente} fotos={fotos} identificador={identificador} recuperarPorIdentificador={recuperarPacientePorIdentificador} />                                 
                                  <Permissao permissoes={[permissoes.ALTERAR_PACIENTE, permissoes.LISTAR_TAGS_ATIVAS_DE_PACIENTES]}>
                                    <FormularioDeTag
                                      {...props}
                                      adicionarTag={adicionarTagNoPaciente}
                                      listarTagsDePacientes={listarTagsDePacientes}
                                      paciente={paciente}
                                      tags={dadosDoPaciente.tags}
                                      tagsDoPaciente={paciente.tags}
                                    />
                                  </Permissao>
                                  <Permissao permissoes={[permissoes.LISTAR_TAGS_ATIVAS_DE_PACIENTES]}>
                                    <ListaDeTag
                                      {...props}
                                      remover={removerTagDoPaciente}
                                      tagsDoPaciente={paciente.tags}
                                    />
                                  </Permissao>
                                </>
                              )
                            }
                          />
                        </Painel>
                        <Painel>
                          <Switch>
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/campos-externos`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE]}>
                                    <ListaDeCamposExternos
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      camposExternos={paciente.camposExternos.itens}
                                      pesquisar={listarCamposExternosDoPaciente}
                                      recuperar={recuperarCampoExternoDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/campos-externos/novo`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE]}>
                                    <FormularioDeCampoExterno
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      listarCamposExternos={listarPlanosDeCuidado}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarCampoExterno}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                            <RotaComAutenticacao
                              exact
                              path={`${match.path}/:identificador/campos-externos/:identificadorDoCampoExterno`}
                              render={
                                props => (
                                  <Permissao permissoes={[permissoes.ALTERAR_CAMPO_EXTERNO_DO_PACIENTE]}>
                                    <FormularioDeCampoExterno
                                      {...props}
                                      fotos={fotos}
                                      paciente={paciente}
                                      recuperar={recuperarCampoExternoDoPaciente}
                                      recuperarPorIdentificador={recuperarPacientePorIdentificador}
                                      salvar={salvarCampoExterno}
                                      remover={removerCampoExternoSelecionado}
                                    />
                                  </Permissao>
                                )
                              }
                            />
                          </Switch>
                        </Painel>
                      </Paineis>
                    </Abas>
                  </div>
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}

const extrairDadosDoFormulario = paciente => ({
  associacoes: paciente.associacoes,
  celulas: paciente.celulas,
  consultores: paciente.consultores,
  enfermeiros: paciente.enfermeiros,
  filtros: paciente.filtros,
  medicos: paciente.medicos,
  motivosDeCancelamentoDoConvenio: paciente.motivosDeCancelamentoDoConvenio,
  motivosDeInativacao: paciente.motivosDeInativacao,
  motivosDeSaidaDosPlanosDeCuidado: paciente.motivosDeSaidaDosPlanosDeCuidado,
  motivosDeSaidaDosProgramas: paciente.motivosDeSaidaDosProgramas,
  operadoras: paciente.operadoras || [],
  ordenacao: paciente.ordenacao,
  paginaDeDados: paciente.paginaDeDados,
  psicologos: paciente.psicologos,
  tags: paciente.tagsDePacientes,
})