import React from 'react'
import { Coluna, Tabela } from '../../../tabela'
import {WhatsApp} from "../../../../design/icones";
import {v4 as uuidv4} from "uuid";

export default function DadosDeContato({ paciente, fechar }) {
    const dadosDeContato = () => {
        const telefones = paciente.telefones.filter(x => x.whatsApp).map(((x, index) => ({
            tipo: `Telefone ${index + 1}`,
            dado: x.numero,
            whatsApp: x.whatsApp,
            identificador: x.identificador,
        })))

        const email = { tipo: 'Email', dado: paciente.email, identificador: uuidv4() }

        return [email, ...telefones]
    }

    return (
        <fieldset className="form mt-2">
            <h2 className='form-title'>Dados de Contato</h2>
            <Tabela
                chave='identificador'
                className='--secondary'
                dados={dadosDeContato()}
                mensagemTabelaVazia='Não existem dados de contato.'
                nome='dados-de-contato'
            >
                <Coluna
                    className='col-xs-8 col-md-10 col-lg-2'
                    principal={true}
                    nome='Tipo'
                    renderizar={item => item.tipo}
                />
                <Coluna
                    className='col-lg-6'
                    nome='Contato'
                    renderizar={item => item.whatsApp ?
                        <div className='telefone-whatsapp'>
                            <p>{item.dado}</p>
                            <p><WhatsApp size={16}/></p>
                        </div> :
                        item.dado}
                />
                <Coluna
                    className='col-lg-2'
                    nome='Copiar'
                    renderizar={item =>
                        <button
                        title='Copiar'
                        type='button'
                        onClick={() => navigator.clipboard.writeText(item.dado)}
                    >
                        <i className='icon icon-copy'/>
                    </button>}
                />
            </Tabela>
            <div className='list-btn'>
                <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            </div>
        </fieldset>
    )
}

