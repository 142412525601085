import React, { useState, useRef } from 'react'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'

export default function DicaDePesquisa({ children, className, dica, exemplo }) {
  const [exibirDica, setExibirDica] = useState(false)
  const dicaRef = useRef(null)

  const textoDaDica = exemplo ? dica.concat(' Exemplo: ') : dica

  useCliqueForaDoElemento(dicaRef, () => setExibirDica(false))  

  return (        
      <div 
        className={className}
        onFocus={() => setExibirDica(!exibirDica)}
        onKeyPress={() => setExibirDica(false)}
        ref={dicaRef}>
        
        {children}
        {exibirDica && 
          <span className='dica-de-pesquisa'>
            <i className='icon icon-lamp' />{textoDaDica}<i>{exemplo}</i>
          </span>
        }

      </div>    
  )
}