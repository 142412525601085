export const componentesQuePrecisamDoHistorico = [
  'acido_urico',
  'albumina',
  'altura',
  'anti_hbc',
  'anti_hbs',
  'anti_hcv',
  'anti_hiv',
  'bicarbonato',
  'calcio_total',
  'circunferencia_da_panturrilha',
  'colesterol_total',
  'creatinina',
  'exames',
  'ferritina',
  'forca_de_preensao_palmar',
  'fosforo',
  'frutosamina',
  'glicemia_em_jejum',
  'glico_hemoglobina',
  'hbsag',
  'hdl',
  'hemoglobina',
  'indice_de_saturacao_de_transferrina',
  'ldl',
  'paratormonio',
  'peso',
  'potassio',
  'prescricoes',
  'prescricoes_da_memed',
  'pressao_arterial',
  'proteinuria_24_horas',
  'relacao_albumina_creatinina',
  'relacao_proteina_creatinina',
  'rni',
  'sodio',
  'triglicerides',
  'tsh',
  'ureia',
  'vitamina_d',
  'velocidade_da_marcha'
]