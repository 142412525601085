import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_TRATAMENTOS, LISTOU_TRATAMENTOS, ERRO_AO_LISTAR_TRATAMENTOS } from './tipos'

const listouTratamentos = tratamentos => ({
  type: LISTOU_TRATAMENTOS,
  tratamentos
})

const erroAoListarTratamentos = erro => ({
  type: ERRO_AO_LISTAR_TRATAMENTOS,
  erro
})

export const listarTratamentos = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TRATAMENTOS })

    try {
      const resultado = await api.listarTratamentos()

      dispatch(listouTratamentos(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTratamentos(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tratamentos.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TRATAMENTOS
  }

  return acao
}