import {
  LISTOU_FABRICANTES,
  ADICIONOU_FABRICANTE,
  RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR,
  ALTEROU_FABRICANTE,
  REMOVEU_FABRICANTE,
  LISTOU_FABRICANTES_DO_CADASTRO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: ''
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_FABRICANTE: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.fabricante
      }
    }

    case ALTEROU_FABRICANTE: {
      return {
        ...state,
        itemAtual: null,
      }
    }

    case LISTOU_FABRICANTES_DO_CADASTRO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
          ...state,
          filtros,
          itemAtual: null,
          ordenacao,
          paginaDeDados
      }
    }

    case LISTOU_FABRICANTES: {
      return {
        ...state,
        itens: action.fabricante,
      }
    }

    case REMOVEU_FABRICANTE: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}