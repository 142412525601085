import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  AUTENTICAR_MEDICO_NA_MEMED,
  AUTENTICOU_MEDICO_NA_MEMED,
  ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED,
  INTEGRAR_NA_MEMED,
  INTEGROU_NA_MEMED,
  ERRO_AO_INTEGRAR_NA_MEMED,
  RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  RECUPEROU_PRESCRICAO_PDF_NA_MEMED,
  ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  REMOVER_PRESCRICAO_NA_MEMED,
  REMOVEU_PRESCRICAO_NA_MEMED,
  ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED,
  RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED,
  ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO,
  EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO,
  LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO,
  LIMPAR_PRESCRICAO_MEMED,
  EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO,
} from '../tipos'

export const autenticarMedicoNaMemed = () => async dispatch => {
  dispatch({ type: AUTENTICAR_MEDICO_NA_MEMED })
  try {
    const { data } = await api.autenticarMedicoNaMemed()
    dispatch(autenticouMedicoNaMemed(data.dados))

    return true
  } catch (erro) {
    dispatch(erroAoAutenticarMedicoNaMemed(erro, {}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const autenticouMedicoNaMemed = dados => ({
  type: AUTENTICOU_MEDICO_NA_MEMED,
  dados,
})

const erroAoAutenticarMedicoNaMemed = (erro, parametros) => ({
  type: ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED,
  erro,
  parametros
})

export const integrarNaMemed = () => ({ type: INTEGRAR_NA_MEMED })
export const integrouNaMemed = () => ({ type: INTEGROU_NA_MEMED })
export const erroAoIntegrarNaMemed = () => ({ type: ERRO_AO_INTEGRAR_NA_MEMED })

export const recuperarPrescricaoPdfNaMemed = (token, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_PRESCRICAO_PDF_NA_MEMED })
  try {
    const { data } = await api.recuperarPrescricaoPdf(token, identificador)

    dispatch(recuperouPrescricaoPdf())

    return data.dados.link
  } catch (erro) {
    dispatch(erroAoRecuperarPrescricaoPdf(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o link do PDF na Memed.')))
    return null
  }
}

const recuperouPrescricaoPdf = () => ({
  type: RECUPEROU_PRESCRICAO_PDF_NA_MEMED,
})

const erroAoRecuperarPrescricaoPdf = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  erro,
  parametros
})

export const removerPrescricaoNaMemed = identificador => async dispatch => {
  dispatch({ type: REMOVER_PRESCRICAO_NA_MEMED })

  try {
    await api.removerPrescricaoNaMemed(identificador)
    dispatch(removeuPrescricaoNaMemed())

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPrescricaoNaMemed(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const removeuPrescricaoNaMemed = () => ({ type: REMOVEU_PRESCRICAO_NA_MEMED })

const erroAoRemoverPrescricaoNaMemed = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED,
  erro,
  parametros
})

export const recuperarHistoricoPrescricaoNaMemed = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED })
  try {
    const { data } = await api.recuperarHistoricoPrescricaoNaMemed(identificador)
    dispatch(recuperouHistoricoPrescricaoNaMemed(data.dados))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarHistoricoPrescricaonaMemed(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o histórico das prescrições da Memed.')))
    return false
  }
}

const recuperouHistoricoPrescricaoNaMemed = dados => ({
  type: RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED,
  dados
})

const erroAoRecuperarHistoricoPrescricaonaMemed = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  erro,
  parametros
})

export const limparPrescricaoMemedNoFormulario = () => ({ type: LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO })
export const limparPrescricaoMemed = () => ({ type: LIMPAR_PRESCRICAO_MEMED })
export const incluirPrescricaoMemedNoFormulario = dados => ({ type: INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO, dados })
export const excluirPrescricaoMemedNoFormulario = identificador => ({ type: EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO, identificador })
export const exibirPrescricaoMemedDoHistorico = identificador => ({ type: EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO, identificador })