import { connect } from 'react-redux'
import acoes from '../../acoes'
import Programas from './index'

const mapStateToProps = state => {
  const programaAtual = state.programas.itemAtual

  let programa = {
    nome: '',
    observacoes: ''
  }

  if (programaAtual) {
    programa.identificador = programaAtual.identificador
    programa.nome = programaAtual.nome
    programa.observacoes = programaAtual.observacoes
  }

  return {
    filtros: state.programas.filtros,
    ordenacao: state.programas.ordenacao,
    paginaDeDados: state.programas.paginaDeDados,
    programa,
  }
}

export default connect(mapStateToProps, acoes)(Programas)