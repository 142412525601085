import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Modal from 'react-modal'
import moment from 'moment'
import Formulario from './formulario'
import Resumo from './resumo'

import { LISTAR_IVCFS_DO_PACIENTE, REMOVER_IVCF_DO_PACIENTE } from '../../../../../acoes/tipos'

export default function Ivcf({ identificadorDoPaciente, idadeDoPaciente, ivcfsDoPaciente }) {
  const {
    listarNiveisDeSaudeRelativa,
    niveisDeSaudeRelativa,
    listarIvcfsDoPaciente,
    adicionarIvcf,
    alterarIvcf,
    removerIvcf
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeListarNiveisDeSaudeRelativa = usePossuiAsPermissoes([permissoes.LISTAR_NIVEIS_DE_SAUDE_RELATIVA])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_IVCF_DO_PACIENTE])
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_IVCF_DO_PACIENTE])

  useEffect(() => {
    if (ivcfsDoPaciente === undefined) {
      listarIvcfsDoPaciente(identificadorDoPaciente)
    }
  }, [listarIvcfsDoPaciente, identificadorDoPaciente, ivcfsDoPaciente])

  useEffect(() => { listarNiveisDeSaudeRelativa() }, [listarNiveisDeSaudeRelativa])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarIvcf(identificadorDoPaciente, item) : await adicionarIvcf(identificadorDoPaciente, item)
  }

  if (ivcfsDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }
  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de IVCF'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicialIvcf(selecionado)}
          idadeDoPaciente={idadeDoPaciente}
          niveisDeSaudeRelativa={niveisDeSaudeRelativa}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo de IVCF'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicialIvcf(selecionado)}
          idadeDoPaciente={idadeDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção do IVCF'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerIvcf(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_IVCFS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_IVCF_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  (item.versao === '2' || item.versao === '3') &&
                  (
                    <button
                      className='table-items-expanded__bt --edit'
                      title='Ver resumo'
                      onClick={() => abrirResumo(item)}
                      type='button'
                      key={`questionario_${item.identificador}`}
                    >
                      <i className='icon icon-doc-text'></i>
                    </button>
                  )
                ,
                item =>
                  (item.versao === '2' || item.versao === '3') && podeAlterar && podeListarNiveisDeSaudeRelativa &&
                  (
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  )
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
                ,
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={ivcfsDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarNiveisDeSaudeRelativa}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros do IVCF.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Total'
                renderizar={item => item.resultado > 0 ? item.resultado : '0'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-12'>
                <AgrupamentoDeColunas>
                  <Coluna
                    nome='DATA'
                    renderizar={item => formatarDataParaFormatoLocal(item.data)}
                  />
                  <Coluna
                    nome='IDADE'
                    renderizar={item => item.idadeDoPaciente.resultado > 0 ? item.idadeDoPaciente.resultado : '0'}
                  />
                  <Coluna
                    nome='AUTO-PERCEPÇÃO DA SAÚDE'
                    renderizar={item => item.autoPercepcaoDaSaude.resultado > 0 ? item.autoPercepcaoDaSaude.resultado : '0'}
                  />
                  <Coluna
                    nome='ATIVIDADES DE VIDA DIÁRIA'
                    renderizar={item => item.atividadesDeVidaDiaria.resultado > 0 ? item.atividadesDeVidaDiaria.resultado : '0'}
                  />
                  <Coluna
                    nome='COGNIÇÃO'
                    renderizar={item => item.cognicao.resultado > 0 ? item.cognicao.resultado : '0'}
                  />
                  <Coluna
                    nome='HUMOR'
                    renderizar={item => item.humor.resultado > 0 ? item.humor.resultado : '0'}
                  />
                  <Coluna
                    nome='MOBILIDADE'
                    renderizar={item => item.mobilidade.resultado > 0 ? item.mobilidade.resultado : '0'}
                  />
                  <Coluna
                    nome='COMUNICAÇÃO'
                    renderizar={item => item.comunicacao.resultado > 0 ? item.comunicacao.resultado : '0'}
                  />
                  <Coluna
                    nome='COMORBIDADES MÚLTIPLAS'
                    renderizar={item => item.comorbidadesMultiplas.resultado > 0 ? item.comorbidadesMultiplas.resultado : '0'}
                  />
                  <Coluna
                    nome='TOTAL'
                    renderizar={item => item.resultado > 0 ? item.resultado : '0'}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirIvcf = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_IVCF_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover o IVCF?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirIvcf}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicialIvcf = selecionado => {
  if (selecionado) {
    return {
      ...selecionado,
      mobilidade: {
        ...selecionado.mobilidade,
       capacidadeAerobicaEOuMuscular: {
          ...selecionado.mobilidade.capacidadeAerobicaEOuMuscular,
          tempoGastoParaPercorrerQuatroMetros: (moment(selecionado.mobilidade.capacidadeAerobicaEOuMuscular.tempoGastoParaPercorrerQuatroMetros, moment.HTML5_FMT.TIME_MS).diff(moment().startOf('day'), 'ms') / 1000).toString().replace('.', ','),
        }
      }
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    autoPercepcaoDaSaude: {
      nivelDeSaudeRelativa: undefined
    },
    atividadesDeVidaDiaria: {
      avdInstrumental: {
        deixouDeFazerCompras: undefined,
        deixouDeControlarSuasFinancas: undefined,
        deixouDeRealizarPequenosTrabalhosDomesticos: undefined,
      },
      avdBasica: {
        deixouDeTomarBanhoSozinho: undefined,
      },
    },
    cognicao: {
      estahFicandoEsquecido: undefined,
      esquecimentoEstahPiorando: undefined,
      esquecimentoImpedeRealizacaoDeAtividadesDoCotidiano: undefined,
    },
    humor: {
      ficouComDesanimoTristezaOuDesesperanca: undefined,
      perdeuInteresseEmAtividadesPrazerosas: undefined,
    },
    mobilidade: {
      alcancePreensaoEPinca: {
        ehIncapazDeElevarOsBracosAcimaDoOmbro: undefined,
        ehIncapazDeManusearPequenosObjetos: undefined,
      },
      capacidadeAerobicaEOuMuscular: {
        tevePerdaDePesoNaoIntencional: undefined,
        altura: undefined,
        peso: undefined,
        circunferenciaDaPanturrilha: undefined,
        tempoGastoParaPercorrerQuatroMetros: undefined,
      },
      marcha: {
        temDificuldadeParaCaminhar: undefined,
        teveQuedasRecentes: undefined,
      },
      continenciaEsfincteriana: {
        perdeUrinaOuFezesSemQuerer: undefined,
      },
    },
    comunicacao: {
      visao: {
        temProblemasDeVisao: undefined,
      },
      audicao: {
        temProblemasDeAudicao: undefined,
      },
    },
    comorbidadesMultiplas: {
      polipatologia: {
        temCincoOuMaisDoencasCronicas: false,
      },
      polifarmacia: {
        usaCincoOuMaisMedicamentos: false,
      },
      internacaoRecente: {
        teveInternacaoRecente: false,
      },
    },
    moradia: {
      moraSozinho: undefined,
    },
  }
}