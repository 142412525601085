import {
  LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO
} from '../../../acoes/tipos'

const estadoInicial = {
  ordem: { nome: 'nome', ordem: '+' },
  pacientes: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        pacientes: action.pacientes,
        ordem: action.ordem
      }
    }

    default: {
      return state
    }
  }
}