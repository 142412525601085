import React from 'react'
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'
import { LISTAR_TAGS_DE_PACIENTES, REMOVER_TAG_DO_PACIENTE } from '../../../acoes/tipos'

export default function ListaDeTag(props) {
  const {
    match,
    history,
    remover,
    tagsDoPaciente,
  } = props

  const { identificador } = match.params

  const removerSelecionado = async item => {
    await remover(identificador, item)
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover a tag ${item.tag}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item.tag)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='action-bar mt-3'>
        <h2 className='title-adicionar'>Tags Adicionadas</h2>
      </div>
      <div className='main-content-form'>
        <Spinner operacoes={[LISTAR_TAGS_DE_PACIENTES, REMOVER_TAG_DO_PACIENTE]}>
          <Tabela
            acoes={[
              item => (
                <div key='informacao'>
                  <button
                    data-tip
                    data-for={`info_${item.tag}`}
                    key={`info_${item.tag}`}
                    className='table-items__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info'></i>
                  </button>
                  <ReactTooltip
                    id={`info_${item.tag}`}
                    place='top'
                    effect='solid'
                    type='info'
                    key={item.tag}
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>Última alteração</strong></div>
                    <span>Usuário: {item.ultimaAlteracao.usuario.nome}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.ultimaAlteracao.dataEHora)}</span>
                  </ReactTooltip>
                </div>
              ),
              item => (
                <Permissao key='remover' permissoes={[permissoes.REMOVER_TAG_DO_PACIENTE]}>
                  <button
                    onClick={() => confirmarERemover(item)}
                    className='table-items__bt --remove'
                    title='Remover'
                    type='button'
                    key={item.tag}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                </Permissao>
              ),
            ]}
            chave='tag'
            className='mt-2 mb-4'
            dados={tagsDoPaciente}
          >
            <Coluna
              campoDeDados='tag'
              className='col-xs-8 col-md-10 col-lg-11'
              principal={true}
              nome='Nome'
            />
          </Tabela>
        </Spinner>
        <div className='list-btn'>
          <button
            className='button --light'
            type='button'
            onClick={() => history.push(`/cadastros/pacientes/lista`)}
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  )
}