import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Cabecalho from '../cabecalho'
import { LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE, RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE } from '../../../acoes/tipos'

export default function ListaDePlanosDeCuidado(props) {
  const {
    fotos,
    history,
    match,
    paciente,
    pesquisar,
    planosDeCuidado,
    recuperarPorIdentificador,
  } = props

  const { identificador } = match.params

  useEffect(() => {
    (async () => await pesquisar(identificador))()
  }, [pesquisar, identificador])

  return (
    <div className='page-pacientes-lista'>
      <div className='header-page-tools col-lg-12'>
        <Cabecalho paciente={paciente} fotos={fotos} identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador}>
          <Permissao permissoes={[permissoes.ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE]}>
            <Link to={`/cadastros/pacientes/${identificador}/planos-de-cuidado/novo`}
              className='button --primary --plus'>
              Adicionar
            </Link>
          </Permissao>
        </Cabecalho>
      </div>
      <div className='main-content-form'>
        <Spinner operacoes={[LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE, RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE]}>
          <Tabela
            acoes={[
              item => (
                <Permissao key='editar' permissoes={[permissoes.ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE]}>
                  <Link
                    to={`/cadastros/pacientes/${identificador}/planos-de-cuidado/${item.identificador}`}
                    className='table-items__bt --edit'
                    title='Editar'
                    key={item.identificador}
                  >
                    <i className='icon icon-pencil'></i>
                  </Link>
                </Permissao>
              ),
              item => (
                <div key='informacao'>
                  <button
                    data-tip
                    data-for={`info_${item.identificador}`}
                    key={`info_${item.identificador}`}
                    className='table-items__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info'></i>
                  </button>
                  <ReactTooltip
                    id={`info_${item.identificador}`}
                    place='top'
                    effect='solid'
                    type='info'
                    key={item.identificador}
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>Última alteração</strong></div>

                    {item.observacoes && <><span>Observações: {item.observacoes} </span> <br /></>}
                    <span>Usuário: {item.ultimaAlteracao.usuario.nome}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.ultimaAlteracao.dataEHora)}</span>
                  </ReactTooltip>
                </div>
              ),
            ]}
            chave='identificador'
            className='table-items mt-2 mb-4'
            dados={planosDeCuidado}
          >
            <Coluna
              campoDeDados='planoDeCuidado'
              className='col-xs-8 col-md-10 col-lg-7'
              principal={true}
              nome='Plano de Cuidado'
            />
            <Coluna
              className='col-lg-2'
              nome='Início de Vigência'
              renderizar={item => moment(item.inicio).format("DD/MM/YYYY")}
            />
            <Coluna
              className='col-lg-2'
              nome='Fim de Vigência'
              renderizar={item => item.fim && moment(item.fim).format("DD/MM/YYYY")}
            />
          </Tabela>
        </Spinner>
        <div className='list-btn'>
          <button
            className='button --light'
            type='button'
            onClick={() => history.push(`/cadastros/pacientes/lista`)}
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  )
}