import api from './api-major'

const listarMedicamentos = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros.termo ? `&termo=${filtros.termo}` : ''
  const parametroAtivo = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativo=${filtros.ativo[0] === 'Sim'}` : ''
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}`: '&tamanhoDaPagina=10'

  return api.get(`/medicamentos${paginacao}${tamanhoDaPaginaFormatado}${parametroTermo}${parametroAtivo}${parametroOrdem}`)
}
const adicionarMedicamento = medicamento => api.post('/medicamentos', medicamento)
const alterarMedicamento = medicamento => api.put(`/medicamentos/${medicamento.identificador}`, medicamento)
const recuperarMedicamentoPorIdentificador = identificador => api.get(`/medicamentos/${identificador}`)
const removerMedicamento = identificador => api.delete(`/medicamentos/${identificador}`)
const listarConcentracoesDoMedicamento = identificadorDoMedicamento => api.get(`/medicamentos/${identificadorDoMedicamento}/concentracoes?tamanhoDaPagina=100000&ordem=valor`)
const adicionarConcentracaoDoMedicamento = (identificadorDoMedicamento, concentracao) => api.post(`/medicamentos/${identificadorDoMedicamento}/concentracoes`, concentracao)
const alterarConcentracaoDoMedicamento = (identificadorDoMedicamento, concentracao) => api.put(`/medicamentos/${identificadorDoMedicamento}/concentracoes/${concentracao.identificador}`, concentracao)
const removerConcentracaoDoMedicamento = (identificadorDoMedicamento, identificadorDaConcentracao) => api.delete(`/medicamentos/${identificadorDoMedicamento}/concentracoes/${identificadorDaConcentracao}`)
const listarPosologias = () => api.get('/frequencias-dos-medicamentos?tamanhoDaPagina=1000&ordem=+nome')

export default {
  adicionarConcentracaoDoMedicamento,
  adicionarMedicamento,
  alterarConcentracaoDoMedicamento,
  alterarMedicamento,
  listarConcentracoesDoMedicamento,
  listarMedicamentos,
  recuperarMedicamentoPorIdentificador,
  removerConcentracaoDoMedicamento,
  removerMedicamento,
  listarPosologias
}