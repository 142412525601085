import { connect } from 'react-redux'
import acoes from '../../../acoes'
import Pacientes from './index'

const mapStateToProps = state => {
  let operadoras = state.relatorios.pacientes.operadoras.map(x => ({ identificador: x.identificador, nome: x.nome, planos: x.planos }))
  operadoras.push({ identificador: 'todas', nome: 'TODAS' })

  let programas = state.relatorios.pacientes.programas.map(x => ({ identificador: x.identificador, nome: x.nome}))
  programas.push({ identificador: 'todos', nome: 'TODOS' })

    return {
    celulas: state.relatorios.pacientes.celulas,
    enfermeiros: state.relatorios.pacientes.enfermeiros,
    medicos: state.relatorios.pacientes.medicos,
    filtros: state.relatorios.pacientes.filtros,
    programas,
    operadoras,
  }
}

export default connect(mapStateToProps, acoes)(Pacientes)