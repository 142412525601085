import React, { memo, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FieldArray } from 'formik'
import { Input } from '../../../../formik/formulario'
import { useProntuario } from '../../../contexto'
import { useUsuarioLogado } from '../../../../seguranca/provedor-de-autenticacao'

function PrescricoesDaMemed(props) {
  const {
    abrirHistorico,
    abrirMemed
  } = props

  return (
    <FieldArray
      name='prescricoesDaMemed'
      render={(props) => {

        const { push, remove, replace, form: { values: { prescricoesDaMemed = [] } } } = props
        return (
          <Prescricao
            abrirHistorico={abrirHistorico}
            abrirMemed={abrirMemed}
            adicionar={push}
            alterar={replace}
            remover={remove}
            adicionados={prescricoesDaMemed}
          />
        )
      }}
    />
  )
}

const Prescricao = memo(function Prescricao(props) {
  const {
    abrirHistorico,
    abrirMemed,
    adicionados,
    ...rest
  } = props

  const { limparPrescricaoMemedNoFormulario } = useProntuario()

  const { memed } = useSelector(state => state.prontuario)
  const { usuario } = useUsuarioLogado()
  const ehMedico = usuario.profissao?.conselho?.nome === 'CRM'

  const dadosDaMemed = useMemo(() => {
    return extrairDadosDaMemed(memed)
  }, [memed])

  const imprimir = linkExterno => {
    window.open(linkExterno, '_blank')
  }

  const remover = useCallback((registro) => {
    const index = adicionados.indexOf(registro)

    if (index >= 0) {
      rest.remover(index)
    }
  }, [rest, adicionados])

  const removerMemedDoFormulario = useCallback((identificadorMemed) => {
    adicionados.filter(p => p.identificadorExterno === identificadorMemed.toString()).map(item => remover(item))
  }, [adicionados, remover])

  useEffect(() => {
    (async () => {
      let limpar = false
      if (dadosDaMemed.incluida) {
        removerMemedDoFormulario(dadosDaMemed.incluida.identificadorExterno)
        rest.adicionar({ ...dadosDaMemed.incluida, sujo: true })
        limpar = true
      }

      if (dadosDaMemed.excluida) {
        removerMemedDoFormulario(dadosDaMemed.excluida)
        limpar = true
      }

      if (limpar) {
        limparPrescricaoMemedNoFormulario()
      }
    })()
  }, [dadosDaMemed.incluida, dadosDaMemed.excluida, removerMemedDoFormulario, limparPrescricaoMemedNoFormulario, rest])

  const renderizarPrescricoes = (item, index) => {
    return (
      <div className='container-da-prescricao' key={index}>
        <r-cell span={4} span-md={3} span-lg={12} class='d-flex justify-content-lg-end' key={`botoes_${index}`}>
          {item.linkExterno && <button type='button' className='button --light w-sm-100' onClick={() => imprimir(item.linkExterno)}>Imprimir</button>}
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={12}>
          <label style={{ fontSize: '14px', fontWeight: '500', lineHeight: '1.83', color: '#393042', opacity: '0.5' }}>
            Prescrição MEMED {index + 1}
          </label>
          <Input
            as='textarea'
            nome={`prescricoesDaMemed.${index}.texto`}
            disabled={true}
            classname={item.assinada ? 'textarea-memed-assinada' : 'textarea-memed'}
          />
        </r-cell>
      </div>
    )
  }

  return (
    <>
      <FieldArray
        name='adicionados'
      >
        {() => (
          <>
            {adicionados.map((x, index) => renderizarPrescricoes(x, index))}
            <r-cell span={4} span-md={3} span-lg={12} class={`d-flex justify-content-lg-start ${adicionados.length > 0 ? 'proximo-horario' : ''}`}>              
              {ehMedico &&
                <button
                  className='button --light w-sm-100 mr-1'
                  type='button'
                  onClick={abrirMemed}
                  id='abrirMemed'
                  title='Atalho: shift + alt + m'
                >
                  Adicionar Prescrição MEMED
                </button>
              }
              <button
                className='button --light w-sm-100'
                type='button'
                onClick={abrirHistorico}
              >
                Histórico
              </button>
            </r-cell>
          </>
        )}
      </FieldArray>
    </>
  )
})

const extrairDadosDaMemed = memed => ({
  excluida: memed.prescricaoMemedExcluidaNoFormulario,
  incluida: memed.prescricaoMemedIncluidaNoFormulario,
  historico: memed.prescricaoMemedHistorico,
  integrada: memed.integrada,
  userToken: memed.userToken,
})

export default memo(PrescricoesDaMemed)