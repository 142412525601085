import React from 'react'
import { formatarDataParaFormatoUniversal } from '../../../bibliotecas/data'
import verificarSecaoComExame from './verificar-secao-com-exame'

export default function recuperarValoresIniciaisDaSecao(componentes, atendimento, secao, contadorDeExames = undefined) {
  if (!componentes || !atendimento || !secao) return {}

  let CONTADOR_DE_EXAMES = -1
  const DATA_DO_EXAME_DA_SECAO = 'data_exame_' + secao.nome + '_'
  const MESMA_DATA_DO_EXAME_DA_SECAO = 'mesma_data_exame_' + secao.nome + '_'

  let valores = {
    secao: secao.nome,
    template_anamnese: '',
  }

  const formatarCalcioIonico = calcioIonico => {
    if (calcioIonico) {
      return {
        ...calcioIonico,
        data: formatarDataParaFormatoUniversal(atendimento.calcioIonico.data),
        valor: atendimento.calcioIonico.unidade === 'mmoL/L' ?
          (atendimento.calcioIonico.valorEmMilimolPorLitro ? atendimento.calcioIonico.valorEmMilimolPorLitro : atendimento.calcioIonico.valor) :
          (atendimento.calcioIonico.valorEmMiligramaPorDecilitro ? atendimento.calcioIonico.valorEmMiligramaPorDecilitro : atendimento.calcioIonico.valor)
      }
    }

    return { valor: null, unidade: '', data: null }
  }

  const recuperarValorInicialOrientacoes = orientacoes => {
    if (orientacoes) return orientacoes.map(item => ({ ...item, orientacao: item.nome }))
    return []
  }

  function setDataExameDaSecao(valor) {
    const nomeData = DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES
    const nomeMesmaData = MESMA_DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES

    if (valor) {
      if (valores[nomeData] === undefined) {
        valores[nomeData] = valor
        valores[nomeMesmaData] = true

      } else if (valores[nomeData] !== valor) {
        valores[nomeData] = ''
        valores[nomeMesmaData] = false

      }
    }
  }

  componentes.forEach((item, index) => {    
    const { tipo, metadados } = item

    const ehExame = metadados?.exame === 'true'
    const ehUmaSecaoComExame = verificarSecaoComExame(index, item, componentes)

    if (ehUmaSecaoComExame) {
      CONTADOR_DE_EXAMES++

      const nomeData = DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES
      const nomeMesmaData = MESMA_DATA_DO_EXAME_DA_SECAO + CONTADOR_DE_EXAMES

      valores[nomeData] = undefined
      valores[nomeMesmaData] = true
    }

    if (contadorDeExames !== undefined && (contadorDeExames !== CONTADOR_DE_EXAMES || !ehExame)) return

    switch (tipo) {
      case 'ACIDO_URICO':
        valores.acidoUrico = atendimento.acidoUrico ? { ...atendimento.acidoUrico, data: formatarDataParaFormatoUniversal(atendimento.acidoUrico.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.acidoUrico.data)
        break
      case 'ALBUMINA':
        valores.albumina = atendimento.albumina ? { ...atendimento.albumina, data: formatarDataParaFormatoUniversal(atendimento.albumina.data) } : { valor: null, data: null }
        break
      case 'ALTURA':
        valores.altura = atendimento.altura || null
        break
      case 'ANAMNESE':
        valores.anamnese = atendimento.anamnese || ''
        break
      case 'ANEXOS':
        valores.anexos = atendimento.anexos || []
        break
      case 'ANTI_HBC':
        valores.antiHbc = atendimento.antiHbc ? { ...atendimento.antiHbc, data: formatarDataParaFormatoUniversal(atendimento.antiHbc.data) } : { valor: '', data: null }
        ehExame && setDataExameDaSecao(valores.antiHbc.data)
        break
      case 'ANTI_HBS':
        valores.antiHbs = atendimento.antiHbs ? { ...atendimento.antiHbs, data: formatarDataParaFormatoUniversal(atendimento.antiHbs.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.antiHbs.data)
        break
      case 'ANTI_HCV':
        valores.antiHcv = atendimento.antiHcv ? { ...atendimento.antiHcv, data: formatarDataParaFormatoUniversal(atendimento.antiHcv.data) } : { valor: '', data: null }
        ehExame && setDataExameDaSecao(valores.antiHcv.data)
        break
      case 'ANTI_HIV':
        valores.antiHiv = atendimento.antiHiv ? { ...atendimento.antiHiv, data: formatarDataParaFormatoUniversal(atendimento.antiHiv.data) } : { valor: '', data: null }
        ehExame && setDataExameDaSecao(valores.antiHiv.data)
        break
      case 'AVALIACAO':
        valores.avaliacao = atendimento.avaliacao || ''
        break
      case 'BICARBONATO':
        valores.bicarbonato = atendimento.bicarbonato ? { ...atendimento.bicarbonato, data: formatarDataParaFormatoUniversal(atendimento.bicarbonato.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.bicarbonato.data)
        break
      case 'CALCIO_IONICO':
        valores.calcioIonico = formatarCalcioIonico(atendimento.calcioIonico)
        ehExame && setDataExameDaSecao(valores.calcioIonico.data)
        break
      case 'CALCIO_TOTAL':
        valores.calcioTotal = atendimento.calcioTotal ? { ...atendimento.calcioTotal, data: formatarDataParaFormatoUniversal(atendimento.calcioTotal.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.calcioTotal.data)
        break
      case 'CIRCUNFERENCIA_DA_PANTURRILHA':
        valores.circunferenciaDaPanturrilha = atendimento.circunferenciaDaPanturrilha || null
        break
      case 'COLESTEROL_TOTAL':
        valores.colesterolTotal = atendimento.colesterolTotal ? { ...atendimento.colesterolTotal, data: formatarDataParaFormatoUniversal(atendimento.colesterolTotal.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.colesterolTotal.data)
        break
      case 'CONDUTA':
        valores.conduta = atendimento.conduta || ''
        break
      case 'CREATININA':
        valores.creatinina = atendimento.creatinina ? { ...atendimento.creatinina, data: formatarDataParaFormatoUniversal(atendimento.creatinina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.creatinina.data)
        break
      case 'ENCAMINHAMENTO':
        valores.encaminhamentos = atendimento.encaminhamentos || []
        break
      case 'EXAME_FISICO':
          valores.exameFisico = atendimento.exameFisico || ''
        break
      case 'EXAMES':
        valores.exames = atendimento.exames || []
        break
      case 'FERRITINA':
        valores.ferritina = atendimento.ferritina ? { ...atendimento.ferritina, data: formatarDataParaFormatoUniversal(atendimento.ferritina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.ferritina.data)
        break
      case 'FORCA_DE_PREENSAO_PALMAR':
        valores.forcaDePreensaoPalmar = atendimento.forcaDePreensaoPalmar || null
        break
      case 'FOSFORO':
        valores.fosforo = atendimento.fosforo ? { ...atendimento.fosforo, data: formatarDataParaFormatoUniversal(atendimento.fosforo.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.fosforo.data)
        break
      case 'FREQUENCIA_CARDIACA':
        valores.frequenciaCardiaca = atendimento.frequenciaCardiaca || null
        break
      case 'FRUTOSAMINA':
        valores.frutosamina = atendimento.frutosamina ? { ...atendimento.frutosamina, data: formatarDataParaFormatoUniversal(atendimento.frutosamina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.frutosamina.data)
        break
      case 'GLICEMIA_EM_JEJUM':
        valores.glicemiaEmJejum = atendimento.glicemiaEmJejum ? { ...atendimento.glicemiaEmJejum, data: formatarDataParaFormatoUniversal(atendimento.glicemiaEmJejum.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.glicemiaEmJejum.data)
        break
      case 'GLICO_HEMOGLOBINA':
        valores.glicoHemoglobina = atendimento.glicoHemoglobina ? { ...atendimento.glicoHemoglobina, data: formatarDataParaFormatoUniversal(atendimento.glicoHemoglobina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.glicoHemoglobina.data)
        break
      case 'HBSAG':
        valores.hbsag = atendimento.hbsag ? { ...atendimento.hbsag, data: formatarDataParaFormatoUniversal(atendimento.hbsag.data) } : { valor: '', data: null }
        ehExame && setDataExameDaSecao(valores.hbsag.data)
        break
      case 'HDL':
        valores.hdl = atendimento.hdl ? { ...atendimento.hdl, data: formatarDataParaFormatoUniversal(atendimento.hdl.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.hdl.data)
        break
      case 'HEMOGLOBINA':
        valores.hemoglobina = atendimento.hemoglobina ? { ...atendimento.hemoglobina, data: formatarDataParaFormatoUniversal(atendimento.hemoglobina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.hemoglobina.data)
        break
      case 'INDICE_DE_SATURACAO_DE_TRANSFERRINA':
        valores.indiceDeSaturacaoDeTransferrina = atendimento.indiceDeSaturacaoDeTransferrina ? { ...atendimento.indiceDeSaturacaoDeTransferrina, data: formatarDataParaFormatoUniversal(atendimento.indiceDeSaturacaoDeTransferrina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.indiceDeSaturacaoDeTransferrina.data)
        break
      case 'LDL':
        valores.ldl = atendimento.ldl ? { ...atendimento.ldl, data: formatarDataParaFormatoUniversal(atendimento.ldl.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.ldl.data)
        break
      case 'ORIENTACOES':
        valores.orientacoes = recuperarValorInicialOrientacoes(atendimento.orientacoes)
        break
      case 'PARATORMONIO':
        valores.paratormonio = atendimento.paratormonio ? { ...atendimento.paratormonio, data: formatarDataParaFormatoUniversal(atendimento.paratormonio.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.paratormonio.data)
        break
      case 'PASSOMETRO':
        valores.passometro = atendimento.passometro || ''
        break
      case 'PESO':
        valores.peso = atendimento.peso || null
        break
      case 'POTASSIO':
        valores.potassio = atendimento.potassio ? { ...atendimento.potassio, data: formatarDataParaFormatoUniversal(atendimento.potassio.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.potassio.data)
        break
      case 'PRESCRICOES':
        valores.prescricoes = atendimento.prescricoes || []
        break
      case 'PRESCRICOES_DA_MEMED':
        valores.prescricoesDaMemed = atendimento.prescricoesDaMemed || []
        break
      case 'PRESSAO_ARTERIAL':
        valores.pressaoArterial = { pressaoArterialSistolica: atendimento.pressaoArterialSistolica, pressaoArterialDiastolica: atendimento.pressaoArterialDiastolica } || {}
        break
      case 'PREVISAO_DE_RETORNO':
        valores.previsaoDeRetorno = atendimento.previsaoDeRetorno || ''
        break
      case 'PROTEINURIA_24_HORAS':
        valores.proteinuria24Horas = atendimento.proteinuria24Horas ? { ...atendimento.proteinuria24Horas, data: formatarDataParaFormatoUniversal(atendimento.proteinuria24Horas.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.proteinuria24Horas.data)
        break
      case 'RELACAO_ALBUMINA_CREATININA':
        valores.relacaoAlbuminaCreatinina = atendimento.relacaoAlbuminaCreatinina ? { ...atendimento.relacaoAlbuminaCreatinina, data: formatarDataParaFormatoUniversal(atendimento.relacaoAlbuminaCreatinina.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.relacaoAlbuminaCreatinina.data)
        break
      case 'RELACAO_PROTEINA_CREATININA':
        valores.relacaoProteinaCreatinina = atendimento.relacaoProteinaCreatinina ? { ...atendimento.relacaoProteinaCreatinina, data: formatarDataParaFormatoUniversal(atendimento.relacaoProteinaCreatinina.data) } : { proteinas: null, creatinina: null, data: null }
        ehExame && setDataExameDaSecao(valores.relacaoProteinaCreatinina.data)
        break
      case 'RESULTADO_DE_EXAME':
        valores.resultadoDeExame = atendimento.resultadoDeExame || ''
        break
      case 'RNI':
        valores.rni = atendimento.rni ? { ...atendimento.rni, data: formatarDataParaFormatoUniversal(atendimento.rni.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.rni.data)
        break
      case 'SODIO':
        valores.sodio = atendimento.sodio ? { ...atendimento.sodio, data: formatarDataParaFormatoUniversal(atendimento.sodio.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.sodio.data)
        break
      case 'TRIGLICERIDES':
        valores.triglicerides = atendimento.triglicerides ? { ...atendimento.triglicerides, data: formatarDataParaFormatoUniversal(atendimento.triglicerides.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.triglicerides.data)
        break
      case 'TSH':
        valores.tsh = atendimento.tsh ? { ...atendimento.tsh, data: formatarDataParaFormatoUniversal(atendimento.tsh.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.tsh.data)
        break
      case 'UREIA':
        valores.ureia = atendimento.ureia ? { ...atendimento.ureia, data: formatarDataParaFormatoUniversal(atendimento.ureia.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.ureia.data)
        break
      case 'VELOCIDADE_DA_MARCHA':
        valores.velocidadeDaMarcha = atendimento.velocidadeDaMarcha || null
        break
      case 'VIDEO_CONSULTA_COM_ZOOM':
        valores.videoConferencias = atendimento.videoConferencias || {}
        break
      case 'VITAMINA_D':
        valores.vitaminaD = atendimento.vitaminaD ? { ...atendimento.vitaminaD, data: formatarDataParaFormatoUniversal(atendimento.vitaminaD.data) } : { valor: null, data: null }
        ehExame && setDataExameDaSecao(valores.vitaminaD.data)
        break
      default:
        return <div key={tipo}>{tipo}</div>
    }
  })

  return valores
}