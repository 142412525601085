import api from '../api-major'
import moment from 'moment'

const listarAgendamentosDoDia = (filtros, pagina, ordenacao) => {
  const {
    profissionais,
    termo,
    unidades
  } = filtros

  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome},+paciente.nome,+identificador` : '&ordem=+inicio,+paciente.nome,+identificador'
  const parametroTamanhoDaPagina = '&tamanhoDaPagina=10'
  const parametroPaciente = termo ? `&paciente=${termo}` : ''
  const parametroProfissional = profissionais ? `&profissionais=${profissionais}` : ''
  const parametroStatus = `&status=agendado,reagendado,confirmado,aguardando_atendimento,compareceu_mas_nao_foi_atendido,atendido,em_atendimento`
  const parametroInicio = `&inicio=${moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}`
  const parametroFim = `&fim=${moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`
  const parametroUnidades = unidades ? `&unidades=${unidades}` : ''

  return api.get(`/agenda/agendamentos${paginacao}${parametroTamanhoDaPagina}&turnos=MANHA,TARDE,NOITE,MADRUGADA&diasDaSemana=DOM,SEG,TER,QUA,QUI,SEX,SAB${parametroOrdem}${parametroPaciente}${parametroProfissional}${parametroStatus}${parametroInicio}${parametroFim}${parametroUnidades}`)
}
const confirmarChegadaDoPaciente = dados => api.patch(`/profissionais-de-saude/${dados.identificadorDoProfissional}/agendamentos/${dados.identificador}/status`, dados)
const informarSaidaDoPaciente = dados => api.patch(`/profissionais-de-saude/${dados.identificadorDoProfissional}/agendamentos/${dados.identificador}/status`, dados)

export default {
  confirmarChegadaDoPaciente,
  informarSaidaDoPaciente,
  listarAgendamentosDoDia
}   