import React, { useEffect, useState } from 'react'
import Breadcrumb from '../breadcrumb'
import { Link } from 'react-router-dom'
import CampoDePesquisa from '../campo-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import { LISTAR_TIPOS_DE_AGENDAMENTO, LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO } from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar,
    listarModalidades,
    modalidades
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)
  const [listouModalidades, setListouModalidades] = useState(modalidades.length > 0)

  useEffect(() => {
    (async () => {
      if (modalidades.length > 0) return
      const modalidadesListadas = await listarModalidades()

      if (modalidadesListadas) {
        setFiltros({
          ...props.filtros,
          modalidades: props.filtros.modalidades.length > 0 ? props.filtros.modalidades : modalidadesListadas.map(x => x.nome)
        })
        setListouModalidades(true)
      }
    })()
  }, [modalidades, listarModalidades, props.filtros])

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao, 10))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarTermo = nome => alterarFiltros({ ...filtros, nome })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
            <div className='header-page-tools'>
              <Permissao permissoes={[permissoes.ADICIONAR_TIPO_DE_AGENDAMENTO]}>
                <Link
                  to={`/agendamentos/tipos-de-agendamento/novo`}
                  className='button --primary --plus'
                >
                  Adicionar
                </Link>
              </Permissao>
              <CampoDePesquisa
                pesquisar={alterarTermo}
                valor={filtros ? filtros.nome : ''}
              />
            </div>
          </div>
          <Spinner operacoes={[LISTAR_TIPOS_DE_AGENDAMENTO, LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO]}>
            {listouModalidades &&
              <Tabela
                acoes={[
                  item => (
                    <Permissao key='editar' permissoes={[permissoes.ALTERAR_TIPO_DE_AGENDAMENTO]}>
                      <Link
                        to={`/agendamentos/tipos-de-agendamento/${item.identificador}`}
                        className='table-items__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </Link>
                    </Permissao>
                  )
                ]}
                alterarFiltros={alterarFiltrosDaTabela}
                chave='identificador'
                className='mt-1 mb-4'
                filtros={filtros}
                listar={listar}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
              >
                <Coluna
                  campoDeDados='nome'
                  className='col-xs-8 col-md-10 col-lg-3'
                  exibirOrdenacao={true}
                  principal={true}
                  nome='Nome'
                />
                <Coluna
                  className='col-lg-1'
                  exibirOrdenacao={true}
                  nome='Tempo'
                  campoDeDados='tempo'
                />
                <Coluna
                  className='col-lg-1'
                  nome='Cor'
                  renderizar={item => <span className='table-items__bcolor' style={{ background: item.cor }}>{item.cor}</span>}
                />
                <Coluna
                  campoDeDados='procedimento'
                  className='col-lg-2'
                  nome='Procedimento'
                  filtros={['Sim', 'Não']}
                  renderizar={item => <p className='table-items__mobile-value'>{item.procedimento ? 'Sim' : 'Não'}</p>}
                />
                <Coluna
                  campoDeDados='ativo'
                  className='col-lg-2'
                  filtros={['Sim', 'Não']}
                  nome='Ativo'
                  renderizar={item => <p className='table-items__mobile-value'>{item.ativo ? 'Sim' : 'Não'}</p>}
                />
                <Coluna
                  campoDeDados='modalidades'
                  className='col-lg-2'
                  filtros={modalidades.map(x => x.nome)}
                  nome='Modalidade'
                  renderizar={item => item.modalidade}
                />
              </Tabela>
            }
          </Spinner>
        </div>
      </div>
    </div>
  )
}