import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  LISTOU_NIVEIS_DE_SAUDE_RELATIVA,
  ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
} from './tipos'

const listouNiveisDeSaudeRelativa = niveisDeSaudeRelativa => ({
  type: LISTOU_NIVEIS_DE_SAUDE_RELATIVA,
  niveisDeSaudeRelativa,
})

const erroAoListarNiveisDeSaudeRelativa = erro => ({
  type: ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  erro,
})

export const listarNiveisDeSaudeRelativa = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_NIVEIS_DE_SAUDE_RELATIVA })

    try {
      const resultado = await api.listarNiveisDeSaudeRelativa()

      dispatch(listouNiveisDeSaudeRelativa(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarNiveisDeSaudeRelativa(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os níveis de saúde relativa.')))
    }
  }

  acao.meta = {
    cache: LISTAR_NIVEIS_DE_SAUDE_RELATIVA
  }

  return acao
}