import {  
  LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE,  
  RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
} from '../../acoes/tipos'

const estadoInicial = {
  itens: [],  
  selecionado: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE: {
      return {
        ...state,
        selecionado: action.planoDeCuidado,
      }
    }

    case LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE: {
      return {
        selecionado: {},
        itens: action.planosDeCuidado,
      }
    }    

    default: {
      return state
    }
  }
}