import {LISTOU_TIPOS_DE_TAREFA} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_TAREFA: {
      let tipos = {}

      action.tipos.forEach(tipo => [
        tipos[tipo.identificador] = tipo
      ])

      return tipos
    }

    default: {
      return state
    }
  }
}