import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import Breadcrumb from '../breadcrumb'
import Spinner from '../spinner'
import {Formulario as FormularioDoFormik, DatePicker, Input, Opcoes, Select} from '../formik/formulario'
import { Coluna, Tabela } from '../tabela'
import { dataIgualOuAnterior, formatarDataParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import DicaDePesquisa from '../dica-de-pesquisa'
import * as permissoes from '../seguranca/permissoes'
import * as Yup from 'yup'
import AlterarStatus from './alterar-status'

import {
  LISTAR_ENCAMINHAMENTOS,
  LISTAR_STATUS_DE_ENCAMINHAMENTO,
  LISTAR_TIPOS_DE_ENCAMINHAMENTO
} from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar,
    statusDeEncaminhamento,
    tiposDeEncaminhamento,
    alterarStatusDoEncaminhamento,
    prioridades
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)
  const [exibirAlterarEncaminhamento, setExibirAlterarEncaminhamento] = useState(false)
  const [encaminhamentoSelecionado, setEncaminhamentoSelecionado] = useState('')
  const podePesquisar = usePossuiAsPermissoes([permissoes.LISTAR_ENCAMINHAMENTOS])

  const tipos = tiposDeEncaminhamento && [{ codigo: '', nome: 'Todos' }, ...tiposDeEncaminhamento]
  const status = statusDeEncaminhamento && [{ codigo: '', nome: 'Todos' }, ...statusDeEncaminhamento]

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pagina, ordenacao, pesquisar])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const aplicarFiltros = formulario => {
    if (formulario.inicio && formulario.fim)
      alterarFiltros({
        ...filtros,
        inicio: formulario.inicio,
        fim: formulario.fim,
        paciente: formulario.paciente,
        profissionalEncaminhado: formulario.profissionalEncaminhado,
        profissionalQueEncaminhou: formulario.profissionalQueEncaminhou,
        tipo: formulario.tipo.toString() === 'Todos' ? '' : formulario.tipo,
        status: formulario.status.toString() === 'Todos' ? '' : formulario.status,
        prioridade: formulario.prioridade.toString() === 'Todas' ? '' : (formulario.prioridade.toString() === 'Sim')
      })
  }

  function abrirAlterar(encaminhamento) {
    setEncaminhamentoSelecionado(encaminhamento)
    setExibirAlterarEncaminhamento(true)
  }

  function fecharAlterar() {
    setExibirAlterarEncaminhamento(false)
  }

  const renderizarEncaminhadoPara = item => {
    if (item.grupo) {
      return `${item.grupo.nome}`
    }
    if (item.profissional && item.especialidade) {
      return `${item.profissao} / ${item.especialidade} - ${item.profissional.tratamento ? item.profissional.tratamento.concat(' ', item.profissional.nome) : item.profissional.nome}`
    }
    if (item.profissional && !item.especialidade) {
      return `${item.profissao} - ${item.profissional.tratamento ? item.profissional.tratamento.concat(' ', item.profissional.nome) : item.profissional.nome}`
    }
    if (!item.profissional && item.especialidade) {
      return `${item.profissao} / ${item.especialidade} `
    }
    if(item.estabelecimentoDeSaude){
       return `${item.estabelecimentoDeSaude.nome}`
    }

    return `${item.profissao}`
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <Modal
        isOpen={exibirAlterarEncaminhamento}
        className='modal'
        contentLabel='Modal para alterar o status do encaminhamento'
      >
        <AlterarStatus
          encaminhamento={encaminhamentoSelecionado}
          alterarStatusDoEncaminhamento={alterarStatusDoEncaminhamento}
          fechar={fecharAlterar}
          pagina={pagina}
          pesquisar={pesquisar}
          filtros={filtros}
          ordenacao={ordenacao}
          statusDeEncaminhamento={statusDeEncaminhamento}
          renderizarEncaminhadoPara={renderizarEncaminhadoPara}
          tipos={tipos}
        />
      </Modal>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <FormularioDoFormik
            acao={aplicarFiltros}
            valoresIniciais={{
              inicio: filtros.inicio,
              fim: filtros.fim,
              paciente: filtros.paciente,
              profissionalQueEncaminhou: filtros.profissionalQueEncaminhou,
              profissionalEncaminhado: filtros.profissionalEncaminhado,
              status: filtros.status ? filtros.status : 'Todos',
              tipo: filtros.tipo ? filtros.tipo : 'Todos',
              prioridade: filtros.prioridade !== '' ? (filtros.prioridade ? 'Sim' : 'Não') : 'Todas'
            }}
            esquemaDeValidacoes={Yup.object().shape({
              inicio: Yup.string()
                .nullable()
                .required('Obrigatório')
                .test(
                  "fim_teste",
                  "Deve ser menor que o fim.",
                  function (value) {
                    const { fim } = this.parent;
                    return !value || dataIgualOuAnterior(value, fim);
                  }
                ),
              fim: Yup.string()
                .nullable()
                .required('Obrigatório')
                .test(
                  "fim_teste",
                  "Deve ser maior que o início",
                  function (value) {
                    const { inicio } = this.parent;
                    return !value || dataIgualOuAnterior(inicio, value);
                  }
                ),
            })}
          >
            {({ values }) => (
              <div className='mt-1'>
                <r-grid columns-md={12} columns-lg={12}>
                  <r-cell span={4} span-md={3} span-lg={3}>
                    <DatePicker
                      nome='inicio'
                      tabIndex={1}
                      titulo='Início'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={3} span-lg={3}>
                    <DatePicker
                      nome='fim'
                      minDate={new Date(values.inicio)}
                      maxDate={new Date()}
                      tabIndex={2}
                      titulo='Fim'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <DicaDePesquisa
                      dica='A pesquisa é realizada por nome'
                      exemplo='João Augusto'
                    >
                      <Input
                        nome='paciente'
                        titulo='Paciente'
                        tabIndex={3}
                      />
                    </DicaDePesquisa>
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <DicaDePesquisa
                      dica='A pesquisa é realizada por nome'
                      exemplo='Dr. Flavio'
                    >
                      <Input
                        nome='profissionalQueEncaminhou'
                        titulo='Encaminhado por'
                        tabIndex={3}
                      />
                    </DicaDePesquisa>
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <DicaDePesquisa
                      dica='A pesquisa é realizada por nome, profissão, especialidade ou estabelecimento de saúde'
                      exemplo='João Médico Geriatria'
                    >
                      <Input
                        nome='profissionalEncaminhado'
                        titulo='Encaminhado para'
                        tabIndex={4}
                      />
                    </DicaDePesquisa>
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={2}>
                    <Opcoes
                      nome='prioridade'
                      titulo='Prioridade'
                      opcoes={prioridades && prioridades.map(x => x.nome)}
                      selecaoUnica={true}
                      classname='encaminhamentos'
                      tabIndex={5}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={4}>
                    <Select
                      nome='tipo'
                      titulo='Tipo'
                      itens={tipos}
                      campoCodigo='nome'
                      campoDescricao='nome'
                      classname='encaminhamentos'
                      tabIndex={6}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={4}>
                    <Select
                      nome='status'
                      titulo='Status'
                      itens={status && status.map(x => x.nome)}
                      classname='encaminhamentos'
                      selecaoUnica={true}
                      tabIndex={7}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={2}>
                    {podePesquisar && <button className='button --primary w-100 minw-auto alinha-botao' type='submit'>Pesquisar</button>}
                  </r-cell>
                </r-grid>
              </div>
            )}
          </FormularioDoFormik>
          <Spinner operacoes={[LISTAR_ENCAMINHAMENTOS, LISTAR_STATUS_DE_ENCAMINHAMENTO, LISTAR_TIPOS_DE_ENCAMINHAMENTO]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='alterar' permissoes={[permissoes.ALTERAR_STATUS_DO_ENCAMINHAMENTO]}>
                    <button
                      className='table-items__bt --edit'
                      title='Editar'
                      type='button'
                      onClick={() => abrirAlterar(item)}
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ),
                item => (
                  <div key='texto'>
                    <button
                      className='table-items__bt --edit'
                      title='Texto do encaminhamento'
                      type='button'
                      data-tip
                      data-for={`texto_${item.encaminhamento}`}
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`texto_${item.encaminhamento}`}
                      place='left'
                      effect='solid'
                      type='info'
                      key={item.encaminhamento}
                      className='tooltip-encaminhamentos'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Texto do encaminhamento</strong></div>
                      {item.texto && <span><p>{item.texto}</p></span>}
                    </ReactTooltip>
                  </div>
                ),
                item => (
                  <div key='historico'>
                    <button
                      className='table-items__bt --edit'
                      title='Histórico'
                      type='button'
                      data-tip
                      data-for={`info_${item.encaminhamento}`}
                    >
                      <i className='icon icon-history'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.encaminhamento}`}
                      place='left'
                      effect='solid'
                      type='info'
                      key={item.encaminhamento}
                      className='tooltip-encaminhamentos'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Histórico</strong></div>
                      <span><p><strong>{item.status.codigo.charAt(0).toUpperCase() + item.status.codigo.replace('_', ' ').slice(1)}</strong></p></span>
                      {item.status.observacoes && <span><p>{item.status.observacoes}</p></span>}
                      <span><em>{item.status.ultimaAlteracao.usuario.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(item.status.ultimaAlteracao.dataEHora)}</em></span>
                    </ReactTooltip>
                  </div>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='encaminhamento'
              className='mt-2 mb-4'
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
              filtros={filtros}
            >
              <Coluna
                className='col-xs-8 col-md-10 col-lg-3'
                principal={true}
                exibirOrdenacao={true}
                nome='Paciente'
                campoDeDados='paciente.nome'
                renderizar={item => item.paciente.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Encaminhado por'
                renderizar={item => item.encaminhadoPor.tratamento ? item.encaminhadoPor.tratamento.concat(' ', item.encaminhadoPor.nome) : item.encaminhadoPor.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Encaminhado para'
                renderizar={item => renderizarEncaminhadoPara(item)}
              />
              <Coluna
                nome='Data'
                campoDeDados='dataEHora'
                exibirOrdenacao={true}
                className='col-lg-1'
                renderizar={item => formatarDataParaFormatoLocal(item.dataEHora)}
              />
              <Coluna
                className='col-lg-1'
                nome='Status'
                renderizar={item => item.status.codigo.charAt(0).toUpperCase() + item.status.codigo.replace('_', ' ').slice(1)}
              />
              <Coluna
                nome='Tipo'
                className='col-lg-1'
                renderizar={item => tipos && tipos.find(x => x.codigo === item.tipo).nome}
              />
              <Coluna
                campoDeDados='prioridade'
                className='col-lg-1'
                nome='Prioridade'
                renderizar={item => item.priorizar ? 'Sim' : 'Não'}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}