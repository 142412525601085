import { connect } from 'react-redux'
import acoes from '../../acoes'
import EspecialidadesMedicasInternas from './index'

const mapStateToProps = state => {
  return {
    especialidadesMedicasInternas: state.especialidadesMedicasInternas.especialidadesMedicasInternas,
    especialidades: state.profissoes.especialidadesMedicas
  }

}
export default connect(mapStateToProps, acoes)(EspecialidadesMedicasInternas)