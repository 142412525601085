import api from '../api-major'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

const gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado = ({ programa, dataDeInicio, dataDeFim }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  return api.get(`/graficos/internacoes-por-fratura-de-femur-meta-versus-realizado?operadora=''&programa=${programa}&inicio=${inicio}&fim=${fim}`)
}

const fazerDownloadDeInternacoesPorFraturaDeFemur = ({ programa, dataDeInicio, dataDeFim }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  return api.get(`/relatorios/internacoes-por-fratura-de-femur?operadora=''&programa=${programa}&inicio=${inicio}&fim=${fim}`, { responseType: 'arraybuffer' })
}

const gerarGraficoDeReinternacoesMetaVersusRealizado = ({ programa, dataDeInicio, dataDeFim }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  return api.get(`/graficos/reinternacoes-meta-versus-realizado?operadora=''&programa=${programa}&inicio=${inicio}&fim=${fim}`)
}

export default {  
  fazerDownloadDeInternacoesPorFraturaDeFemur,
  gerarGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado,
  gerarGraficoDeReinternacoesMetaVersusRealizado,
}