import {
  LISTOU_AGENDAMENTOS,
  LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA 
} from '../../acoes/tipos'

import moment from 'moment'

const estadoInicial = {
  filtros: {
    unidades: [],
    profissionais: [],
    status: [],
    tiposDeAgendamento: [],
    termo: '',
    inicio: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    fim: moment().endOf('day').add(2, 'months').format('YYYY-MM-DD HH:mm:ss')
  },
  ordenacao: {nome: 'inicio', ordem: '+'},
  paginaDeDados: {},
  tiposDeAgendamento: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_AGENDAMENTOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        ordenacao,
        paginaDeDados: {
          ...paginaDeDados,
          dados: action.paginaDeDados.dados.map(x => ({
            ...x,
            profissional: {
              ...x.profissional,
              nome: x.profissional.tratamento ? x.profissional.tratamento + ' ' + x.profissional.nome : x.profissional.nome
            }
          }))
        },
      }
    }

    case LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA: {
      return {
        ...state,
        tiposDeAgendamento: action.tiposDeAgendamento
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}