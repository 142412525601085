import { LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS: {
      return action.estadosDaMemoriaComparadoHaUmAnoAtras
    }

    default: {
      return state
    }
  }
}