import React, { useState } from 'react'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import Modal from 'react-modal'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, InputSugestao, Opcoes, Toglle } from '../../../formik/formulario'
import { SelectTemplate } from '../../../formik/input-mension/index'
import { LISTAR_PROFISSIONAIS_DE_SAUDE, LISTAR_PROFISSIONAIS_EXTERNOS, LISTAR_PROFISSOES } from '../../../../acoes/tipos'
import DicaDePesquisa from '../../../dica-de-pesquisa'
import AdicionarTemplate from '../../atendimento/adicionar-template'

export default function FormularioMultiprofissional(props) {
  const {
    adicionarTemplateDoProntuario,
    fechar,
    gruposDeCuidado,
    profissoes,
    selecionada,
    tokensDeSugestao,
    setExibirFormulario,
    estabelecimentosDeSaude,
    motivosDePrioridade,
    ...rest
  } = props

  const salvar = (valores, { resetForm }) => {
    resetForm({})

    let dados

    function recuperarTipo(tipo) {
      if (tipo.includes('Individual')) return 'equipe_multiprofissional_individual'

      return 'equipe_multiprofissional_coletiva'
    }

    dados = {
      ...valores,
      tipo: recuperarTipo(valores.tipo),
      versao: valores.tipo.includes('Individual') ? '3' : '2',
      codigoDeRastreio: uuidv4(),
      grupoDeCuidado: valores.tipo.includes('Individual') ? null : valores.grupoDeCuidado,
      profissao: valores.tipo.includes('Individual') ? valores.profissao : null,
    }

    delete dados.template_encaminhamento
    rest.salvar(dados)
    fechar()
  }

  const [pularConfirmacao, setPularConfirmacao] = useState(false)
  const [template, setTemplate] = useState('')
  const [exibirAdicionarTemplate, setExibirAdicionarTemplate] = useState(false)

  const atualizarPreview = (template = '') => {
    return decodificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const { dadosDaSugestao, usuariosDaSugestao, templates, decodificador } = tokensDeSugestao
  const templatesEncaminhamento = templates.map(x => ({
    ...x,
    options: x.options.length > 0 ? x.options.filter(y => y.componente === 'ENCAMINHAMENTO').map(z => ({ ...z, label: z.nome, value: z.identificador })) : []
  }))

  const selecionarTemplate = (valor, setFieldValue) => {
    if (!valor) {
      setPularConfirmacao(false)
      return
    }

    setPularConfirmacao(true)

    let todosOsTemplates = []
    templatesEncaminhamento.forEach(x => x.options.length > 0 && x.options.map(t => todosOsTemplates.push(t)))
    const template = todosOsTemplates.filter(x => x.identificador === valor)[0].template
    setFieldValue('texto', atualizarPreview(template))
  }

  const confirmarESubstituir = (valor, setFieldValue) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Os dados informados do Encaminhamento serão substituídos. Tem certeza disso?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => selecionarTemplate(valor, setFieldValue)
      }, {
        label: 'Não',
        onClick: () => setFieldValue('template_encaminhamento', '')
      }]
    })
  }

  const salvarComoTemplate = template => {
    abrirAdicionarTemplate(template)
  }

  function abrirAdicionarTemplate(template) {
    setTemplate(template)
    setExibirAdicionarTemplate(true)
  }

  function fecharAdicionarTemplate() {
    setExibirAdicionarTemplate(false)
  }

  const recuperarObservacaoDoGrupo = grupo => {
    const grupoSelecionado = gruposDeCuidado.find(x => x.nome === grupo)

    return grupoSelecionado.observacoes
  }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarTemplate}
        className='modal-adicionar-template'
        style={{ content: { width: '700px' } }}
      >
        <AdicionarTemplate
          fechar={fecharAdicionarTemplate}
          salvar={adicionarTemplateDoProntuario}
          template={template}
          componente='ENCAMINHAMENTO'
        />
      </Modal>
      <Spinner operacoes={[LISTAR_PROFISSIONAIS_DE_SAUDE, LISTAR_PROFISSIONAIS_EXTERNOS, LISTAR_PROFISSOES]}>
        <FormularioDoFormik
          reinicializar={false}
          valoresIniciais={{
            identificador: selecionada ? selecionada.identificador : null,
            tipo: selecionada ? selecionada.tipo.toLowerCase().includes('individual') ? ['Individual'] : ['Coletivo'] : [],
            profissao: selecionada ? selecionada.profissao : '',
            grupoDeCuidado: selecionada ? selecionada.grupoDeCuidado : '',
            texto: selecionada ? selecionada.texto : '',
            priorizar: selecionada ? selecionada.priorizar : false,
            motivoDePrioridade: selecionada ? selecionada.motivoDePrioridade : '',
          }}
          esquemaDeValidacoes={Yup.object().shape({
            tipo: Yup.string().required('Obrigatório.'),
            texto: Yup.string().required('Obrigatório.'),
            motivoDePrioridade: Yup.string().nullable()
              .when('priorizar', {
                is: val => val,
                then: Yup.string().required('Obrigatório'),
              }),
            profissao: Yup.string().nullable()
              .when('tipo', {
                is: val => val.includes('Individual'),
                then: Yup.string().required('Obrigatório'),
              }),
            grupoDeCuidado: Yup.string().nullable()
              .when('tipo', {
                is: val => val.includes('Coletivo'),
                then: Yup.string().required('Obrigatório'),
              }),
          })}
          acao={salvar}
        >
          {({ values, setFieldValue }) => (
            <fieldset>
              <h2 className='form-title'>Adicionar Encaminhamento</h2>
              <r-grid columns-md={6} columns-lg={12} className='mb-2'>
                <r-cell span={4} span-md={6} span-lg={12}>
                  <Opcoes
                    nome='tipo'
                    titulo='Tipo *'
                    opcoes={['Individual', 'Coletivo']}
                    classname='encaminhamentos'
                    selecaoUnica={true}
                    tabIndex={1}
                  />
                </r-cell>
                {values.tipo.includes('Individual') && <>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Select
                      nome='profissao'
                      titulo='Profissão multiprofissional *'
                      tabIndex={2}
                      itens={profissoes}
                      campoCodigo='nome'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={1} span-md={1} span-lg={1}>
                    <div className='form-toggle-list'>
                      <Toglle
                        className='form-toggle-2'
                        nome='priorizar'
                        titulo='Priorizar'
                        onChange={() => setFieldValue('motivoDePrioridade', null)}
                      />
                    </div>
                  </r-cell>
                  {values.priorizar &&
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <Select
                        nome='motivoDePrioridade'
                        titulo='Motivo de Prioridade *'
                        tabIndex={4}
                        itens={motivosDePrioridade}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                  }
                </>
                }
                {values.tipo.includes('Coletivo') &&
                  <>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <Select
                        nome='grupoDeCuidado'
                        titulo='Grupo de Cuidado *'
                        tabIndex={5}
                        itens={gruposDeCuidado}
                        campoCodigo='nome'
                        campoDescricao='nome'
                      />
                    </r-cell>
                  </>
                }
                {values.tipo.includes('Coletivo') && values.grupoDeCuidado &&
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <div className='form-item mt-1'>
                      <pre className='form-item__choice'>{' '}{recuperarObservacaoDoGrupo(values.grupoDeCuidado)}</pre>
                    </div>
                  </r-cell>
                }
                <r-cell span={values.tipo === 'Externo' ? 12 : 4} span-md={values.tipo === 'Externo' ? 12 : 6} span-lg={12}>
                  <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@@assinatura'>
                    <InputSugestao
                      as='textarea'
                      nome='texto'
                      titulo='Texto *'
                      dados={dadosDaSugestao}
                      dadosUsuarios={usuariosDaSugestao}
                      minHeight={200}
                      modal={true}
                    >
                      <SelectTemplate
                        nome='template_encaminhamento'
                        itens={templatesEncaminhamento}
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        onChange={v => (values.texto === '' || pularConfirmacao) ? selecionarTemplate(v, setFieldValue) : confirmarESubstituir(v, setFieldValue)}
                        onClickSalvarComoTemplate={() => salvarComoTemplate(values.texto)}
                      />
                    </InputSugestao>
                  </DicaDePesquisa>
                </r-cell>
              </r-grid>
              <div className='list-btn mt-4'>
                <button type='button' className='button --light' onClick={fechar}>Cancelar</button>
                <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
              </div>
            </fieldset>
          )}
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}