import api from '../api-major'

const listarPedidosDeContato = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/pedidos-de-contato${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarPedidoDeContato = (identificadorDaEmpresa, identificadorDaUnidade, pedidoDeContato) => api.post(`/empresas/${identificadorDaEmpresa}/unidades/${identificadorDaUnidade}/pedidos-de-contato`, pedidoDeContato)
const recuperarPedidoDeContatoPeloIdentificador = identificador => api.get(`/pedidos-de-contato/${identificador}`)
const alterarPedidoDeContato = pedidoDeContato => api.put(`/pedidos-de-contato/${pedidoDeContato.identificador}`, pedidoDeContato)
const removerPedidoDeContato = identificador => api.delete(`/pedidos-de-contato/${identificador}`)

export default {
  listarPedidosDeContato,
  adicionarPedidoDeContato,
  recuperarPedidoDeContatoPeloIdentificador,
  alterarPedidoDeContato,
  removerPedidoDeContato
}