import api from '../../api-major'

const adicionarRegistroDocumentalDoPsicologoDoPaciente = (identificador, registroDocumental) => api.post(`/pacientes/${identificador}/registros-documentais-do-psicologo`, registroDocumental)
const alterarRegistroDocumentalDoPsicologoDoPaciente = (identificador, registroDocumental) => api.put(`/pacientes/${identificador}/registros-documentais-do-psicologo/${registroDocumental.identificador}`, registroDocumental)
const removerRegistroDocumentalDoPsicologoDoPaciente = (identificador, identificadorDoRegistroDocumentalDoPsicologo) => api.delete(`/pacientes/${identificador}/registros-documentais-do-psicologo/${identificadorDoRegistroDocumentalDoPsicologo}`)
const listarRegistrosDocumentaisDoPsicologoDoPaciente = identificador => api.get(`/pacientes/${identificador}/registros-documentais-do-psicologo`)

export default { 
  adicionarRegistroDocumentalDoPsicologoDoPaciente,
  alterarRegistroDocumentalDoPsicologoDoPaciente,
  removerRegistroDocumentalDoPsicologoDoPaciente,
  listarRegistrosDocumentaisDoPsicologoDoPaciente
}