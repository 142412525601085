import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_AGENDAMENTOS_DO_DIA,
  LISTOU_AGENDAMENTOS_DO_DIA,
  ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA,
  CONFIRMAR_CHEGADA_DO_PACIENTE,
  CONFIRMOU_CHEGADA_DO_PACIENTE,
  ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE,
  INFORMAR_SAIDA_DO_PACIENTE,
  INFORMOU_SAIDA_DO_PAICENTE,
  ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE,
} from '../tipos'

const listouAgendamentosDoDia = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_AGENDAMENTOS_DO_DIA,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarAgendamentosDoDia = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA,
  erro,
  parametros
})

export const listarAgendamentosDoDia = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_AGENDAMENTOS_DO_DIA })

  try {
    const resultado = await api.listarAgendamentosDoDia(filtros, pagina, ordenacao)
    
    dispatch(listouAgendamentosDoDia(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAgendamentosDoDia(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os agendamentos do dia.')))
  }
}

const confirmouChegadaDoPaciente = (identificadorDoAgendamento, novoStatus) => ({
  type: CONFIRMOU_CHEGADA_DO_PACIENTE,
  identificadorDoAgendamento,
  novoStatus
})

const erroAoConfirmacaoDeChegadaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_CONFIRMAR_CHEGADA_DO_PACIENTE,
  erro,
  parametros
})

export const confirmarChegadaDoPaciente = dadosDaConfirmacao => async dispatch => {
  dispatch({ type: CONFIRMAR_CHEGADA_DO_PACIENTE })

  try {
    dadosDaConfirmacao.status = 'aguardando_atendimento'
    const  resultado = await api.confirmarChegadaDoPaciente(dadosDaConfirmacao)

    dispatch(confirmouChegadaDoPaciente(dadosDaConfirmacao.identificador, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoConfirmacaoDeChegadaDoPaciente(erro, { dadosDaConfirmacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível confirmar a chegada.')))

    return false
  }
}

const informouSaidaDoPaciente = (identificadorDoAgendamento, novoStatus) => ({
  type: INFORMOU_SAIDA_DO_PAICENTE,
  identificadorDoAgendamento,
  novoStatus
})

const erroAoInformarSaidaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_INFORMAR_SAIDA_DO_PACIENTE,
  erro,
  parametros
})

export const informarSaidaDoPaciente = dadosDaSaida => async dispatch => {
  dispatch({ type: INFORMAR_SAIDA_DO_PACIENTE })

  try {
    dadosDaSaida.status = 'compareceu_mas_nao_foi_atendido'
    const resultado = await api.informarSaidaDoPaciente(dadosDaSaida)

    dispatch(informouSaidaDoPaciente(dadosDaSaida.identificador, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoInformarSaidaDoPaciente(erro, { dadosDaSaida }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível informar a saída do paciente.')))

    return false
  }
}