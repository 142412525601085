import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {
  ERRO_AO_LISTAR_INDICADORES_DO_PACIENTE,
  LISTAR_INDICADORES_DO_PACIENTE,
  LISTOU_INDICADORES_DO_PACIENTE,
} from '../tipos'

const listouIndicadoresDoPaciente = (indicadores, identificadorDoPaciente) => async dispatch => {
  dispatch({ type: LISTOU_INDICADORES_DO_PACIENTE,  indicadores, identificadorDoPaciente })
}

const erroAoListarIndicadoresDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_INDICADORES_DO_PACIENTE,
  erro,
  parametros
})

export const listarIndicadoresDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_INDICADORES_DO_PACIENTE })

  try {
    const resultado = await api.listarIndicadoresDoPaciente(identificadorDoPaciente)

    dispatch(listouIndicadoresDoPaciente(resultado.data, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarIndicadoresDoPaciente(erro, { identificadorDoPaciente}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar os indicadores do paciente.')))
  }
}