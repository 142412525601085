import api from './api-major'

const listarGruposDeCuidado = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroAtivo = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativo=${filtros.ativo[0] === 'Sim'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/grupos-de-cuidado${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${parametroAtivo}${ordem}`)
}
const adicionarGrupoDeCuidado = grupo => api.post(`/grupos-de-cuidado/`, grupo)
const recuperarGrupoDeCuidadoPeloIdentificador = identificador => api.get(`/grupos-de-cuidado/${identificador}`)
const alterarGrupoDeCuidado = grupo => api.put(`/grupos-de-cuidado/${grupo.identificador}`, grupo)
const removerGrupoDeCuidado = identificador => api.delete(`/grupos-de-cuidado/${identificador}`)

export default {
  listarGruposDeCuidado,
  adicionarGrupoDeCuidado,
  recuperarGrupoDeCuidadoPeloIdentificador,
  alterarGrupoDeCuidado,
  removerGrupoDeCuidado
}