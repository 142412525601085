import React, { useEffect, useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import {Formulario as FormularioDoFormik, Input, Select, Toglle} from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ALTERAR_PROFISSIONAL_EXTERNO,
  ADICIONAR_PROFISSIONAL_EXTERNO,
  REMOVER_PROFISSIONAL_EXTERNO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    profissional,
    salvar,
    recuperarPeloIdentificador,
    remover,
    profissoes
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_PROFISSIONAL_EXTERNO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_PROFISSIONAL_EXTERNO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_PROFISSIONAL_EXTERNO])

  const [profissaoSelecionada, setProfissaoSelecionada] = useState(null)

  const selecionarProfissao = useCallback((nome, setFieldValue) => {
    const profissao = profissoes.find(x => x.nome === nome)
    setProfissaoSelecionada(profissao)
    if (setFieldValue) {
      setFieldValue('especialidades', [])
    }
  }, [profissoes])

  useEffect(() => {
    if (profissional.profissao) {
      selecionarProfissao(profissional.profissao)
    } else {
      setProfissaoSelecionada(null)
    }
  }, [profissional.profissao, selecionarProfissao])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/profissionais-externos`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o profissional ${profissional.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(profissional)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[
          RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
          ALTERAR_PROFISSIONAL_EXTERNO,
          ADICIONAR_PROFISSIONAL_EXTERNO,
          REMOVER_PROFISSIONAL_EXTERNO
        ]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: profissional.identificador || '',
              nome: profissional.nome || '',
              profissao: profissional.profissao || '',
              ativo: profissional.ativo || true,
              especialidades: profissional.especialidades || [],
              observacoes: profissional.observacoes || ''
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              profissao: Yup.string().required('Obrigatório')
            })}
          >
            {({ setFieldValue }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{profissional.identificador ? 'Alterar Profissional' : 'Adicionar Profissional'}</h2>
                  <r-grid columns-md='12'>
                    <r-cell span={12} span-md={8} span-lg={10}>
                      <Input
                        type='text'
                        nome='nome'
                        tabIndex={1}
                        titulo='Nome *'
                      />
                    </r-cell>
                    <r-cell span={12} span-md={12} span-lg={2}>
                      <Toglle
                          className='form-toggle-2'
                          nome='ativo'
                          tabIndex={2}
                          titulo='Ativo'
                      />
                    </r-cell>
                    <r-cell span={12} span-md={12} span-lg={6}>
                      <Select
                        nome='profissao'
                        tabIndex={3}
                        titulo='Profissão *'
                        itens={profissoes}
                        campoCodigo='nome'
                        campoDescricao='nome'
                        onChange={nome => selecionarProfissao(nome, setFieldValue)}
                      />
                    </r-cell>
                    <r-cell span={12} span-md={12} span-lg={6}>
                      <Select
                        nome='especialidades'
                        tabIndex={4}
                        titulo='Especialidade'
                        isMulti
                        itens={profissaoSelecionada ? profissaoSelecionada.especialidades.map(x => x.nome) : []}
                        isDisabled={!(profissaoSelecionada && profissaoSelecionada.especialidades.length > 0)}
                      />
                    </r-cell>
                    <r-cell span={12} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={5}
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                </fieldset>
                {remover ?
                  <div className='list-btn-unidades'>
                    {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                    {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  </div>
                  :
                  <div className='list-btn'>
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                  </div>
                }
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}