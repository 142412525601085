import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Breadcrumb from '../breadcrumb'
import Lista from './lista'
import Formulario from './formulario'
import moment from 'moment'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Abas, Aba, ListaDeAbas, Painel } from '../abas'

export default function TiposDeAgendamento(props) {
  const {
    adicionarTipoDeAgendamento,
    filtros,
    history,
    listarTiposDeAgendamento,
    listarTiposDeHorarios,
    listarModalidadesDosTiposDeAgendamento,
    match,
    modalidades,
    ordenacao,
    paginaDeDados,
    recuperarTipoDeAgendamentoPorIdentificador,
    removerTipoDeAgendamento,
    salvarTipoDeAgendamento,
    tipoDeAgendamento,
    tiposDeHorarios
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
      tempo: moment.utc(moment.duration(parseInt(formulario.tempo, 10), 'm').asMilliseconds()).format('HH:mm:ss')
    }

    const acao = dados.identificador ? salvarTipoDeAgendamento : adicionarTipoDeAgendamento
    const executou = await acao(dados)

    if (executou) {
      history.push(`/agendamentos/tipos-de-agendamento/lista`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_TIPOS_DE_AGENDAMENTO]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarTiposDeAgendamento}
                paginaDeDados={paginaDeDados}
                listarModalidades={listarModalidadesDosTiposDeAgendamento}
                modalidades={modalidades}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/novo`,
          `${match.path}/:identificador`,
        ]}
        render={
          props => {
            const identificador = props.match.params.identificador
            return (

              <div className='container'>
                <div className='main-content-form'>
                  <div className='header-page-form'>
                    <Breadcrumb />
                  </div>
                  <Abas>
                    <div className='header-page-bar mt-0'>
                      <div className='header-page-nav'>
                        <ListaDeAbas className='tab'>
                          <Aba className='tab-item' aoClicar={() => identificador ? history.push(`${match.url}/${identificador}`) : ''}>
                            Dados Gerais
                          </Aba>
                        </ListaDeAbas>
                      </div>
                    </div>
                    <Painel>
                      <Switch>
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/novo`}
                          render={
                            props => (
                              <Permissao permissoes={[permissoes.ADICIONAR_TIPO_DE_AGENDAMENTO]}>
                                <Formulario
                                  {...props}
                                  salvar={salvar}
                                  listarModalidades={listarModalidadesDosTiposDeAgendamento}
                                  modalidades={modalidades}
                                  listarTiposDeHorarios={listarTiposDeHorarios}
                                  tiposDeHorarios={tiposDeHorarios}
                                  tiposDeHorariosHabilitados={tipoDeAgendamento.tiposDeHorario.map(x => x.tipo)}
                                />
                              </Permissao>
                            )
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/:identificador`}
                          render={
                            props => (
                              <Permissao permissoes={[permissoes.ALTERAR_TIPO_DE_AGENDAMENTO]}>
                                <Formulario
                                  {...props}
                                  recuperarPorIdentificador={recuperarTipoDeAgendamentoPorIdentificador}
                                  salvar={salvar}
                                  tipoDeAgendamento={tipoDeAgendamento}
                                  remover={removerTipoDeAgendamento}
                                  listarModalidades={listarModalidadesDosTiposDeAgendamento}
                                  modalidades={modalidades}
                                  listarTiposDeHorarios={listarTiposDeHorarios}
                                  tiposDeHorarios={tiposDeHorarios}
                                  tiposDeHorariosHabilitados={tipoDeAgendamento.tiposDeHorario.map(x => x.tipo)}
                                />
                              </Permissao>
                            )
                          }
                        />
                      </Switch>
                    </Painel>
                  </Abas>
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}