import api from '../api-major'

const fazerDownloadDoArquivoDeVideoConsulta = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDoArquivo) => {
  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/videos/${identificadorDoArquivo}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  fazerDownloadDoArquivoDeVideoConsulta,
}