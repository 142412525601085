import { configurarMoment } from './moment'
import { configurarStore } from './store'
import { configurarFirestore } from './firestore/index'
import { configurarYup } from './yap'
import { configurarModal } from './modal'
import { configurarDatePicker } from './datepicker'
import  { recuperarClienteAxios, configurarToken, removerConfiguracaoDeToken } from './axios'

export default {
  configurarMoment,
  configurarFirestore,
  configurarStore,
  configurarYup,
  configurarModal,
  configurarDatePicker,
  recuperarClienteAxios,
  configurarToken,
  removerConfiguracaoDeToken,
}