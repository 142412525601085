import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import constantes from '../../configuracoes/constantes'
import { converterParaUrl, gravarFotoNoCache, recuperarFotoDoCache, converterParaImagem } from '../../bibliotecas/imagem'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_FOTO_DO_USUARIO,
  ADICIONOU_FOTO_DO_USUARIO,
  REMOVER_FOTO_DO_USUARIO,
  REMOVEU_FOTO_DO_USUARIO,
  ERRO_AO_ADICIONAR_FOTO_DO_USUARIO,
  RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
} from '../tipos'

export const adicionarFotoDoUsuario = foto => async dispatch => {
  dispatch({ type: ADICIONAR_FOTO_DO_USUARIO })

  const tamanhoDaFotoEmMb = foto.size / 1024 ** 2;
  if(tamanhoDaFotoEmMb >= 1) {
    dispatch(erroAoAdicionarFotoDoUsuario({}, { foto }))
    dispatch(adicionarNotificacoesDaApi('', notificacao.deErro('A foto deve ter menos que 1MB.')))

    return null
  }
  
  try {
    const resultado = await api.adicionarArquivo(foto, 'foto_do_usuario')
    dispatch(adicionouFotoDoUsuario({ identificador: resultado.data.dados.identificador, url: converterParaUrl(resultado.data.dados) }))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarFotoDoUsuario(erro, { foto }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar foto do usuário.')))
    return null
  }
}

const adicionouFotoDoUsuario = foto => ({
  type: ADICIONOU_FOTO_DO_USUARIO,
  foto,
})

const erroAoAdicionarFotoDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FOTO_DO_USUARIO,
  erro,
  parametros
})

const removeuFotoDoUsuario = identificador => ({
  type: REMOVEU_FOTO_DO_USUARIO,
  identificador
})

export const removerFotoDoUsuario = identificador => async dispatch => {
  dispatch({ type: REMOVER_FOTO_DO_USUARIO })
  dispatch(removeuFotoDoUsuario(identificador))
}

const recuperouFotoDoUsuarioPorIdentificador = (foto, identificador) => ({
  type: RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  url: foto,
  identificador,
})

const erroAoRecuperarFotoDoUsuarioPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarFotoDoUsuarioPorIdentificador = (identificador, identificadorDaFoto, tamanho) => async dispatch => {
  dispatch({ type: RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDaFoto)

    if (itemDoCache) {
      dispatch(recuperouFotoDoUsuarioPorIdentificador(itemDoCache, identificadorDaFoto))
      return
    }

    const resultado = await api.recuperarFotoDoUsuarioPorIdentificador(identificador, tamanho)

    const foto = gravarFotoNoCache(resultado.data, identificadorDaFoto)

    dispatch(recuperouFotoDoUsuarioPorIdentificador(foto, identificadorDaFoto))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarFotoDoUsuarioPorIdentificador(erro, { identificador, identificadorDaFoto, tamanho }))
  }
}

export const recuperarFotoOriginalDoUsuarioPorIdentificador = (identificador) => async dispatch => {
  try {
    const resultado = await api.recuperarFotoDoUsuarioPorIdentificador(identificador, constantes.tamanhoDaFoto.original)
    const urlDaFoto = converterParaUrl(resultado.data)

    return urlDaFoto
  } catch (erro) {
    return false
  }
}

const recuperouFotoDoUsuarioLogadoPorIdentificador = foto => ({
  type: RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  foto: window.URL.createObjectURL(new Blob([foto])),
})

const erroAoRecuperarFotoDoUsuarioLogadoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarFotoDoUsuarioLogadoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarFotoDoUsuarioPorIdentificador(identificador)

    dispatch(recuperouFotoDoUsuarioLogadoPorIdentificador(resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return converterParaImagem(resultado.data)
  } catch (erro) {
    dispatch(erroAoRecuperarFotoDoUsuarioLogadoPorIdentificador(erro, { identificador }))
    return null
  }
}