import api from '../api-major'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

const fazerDownloadDeIdasAoProntoAtendimento = ({ programa, dataDeInicio, dataDeFim }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  return api.get(`/relatorios/idas-ao-pronto-atendimento?programa=${programa}&inicio=${inicio}&fim=${fim}`, { responseType: 'arraybuffer' })
}

export default {
  fazerDownloadDeIdasAoProntoAtendimento,
}