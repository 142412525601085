import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTOU_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO
} from './tipos'

const listouStatusDeIdaAoProntoAtendimento = statusDeIdaAoProntoAtendimento => ({
  type: LISTOU_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  statusDeIdaAoProntoAtendimento,
})

const erroAoListarStatusDeIdaAoProntoAtendimento = erro => ({
  type: ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  erro,
})

export const listarStatusDeIdaAoProntoAtendimento = () => async dispatch => {
  dispatch({ type: LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.listarStatusDeIdaAoProntoAtendimento()

    dispatch(listouStatusDeIdaAoProntoAtendimento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarStatusDeIdaAoProntoAtendimento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os status de ida ao pronto atendimento.')))
  }
}