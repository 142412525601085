import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function ProfissionaisExternos(props) {
  const {
    adicionarProfissionalExterno,
    profissional,
    filtros,
    history,
    listarProfissoes,
    listarProfissionaisExternos,
    match,
    ordenacao,
    paginaDeDados,
    profissoes,
    recuperarProfissionalExternoPeloIdentificador,
    removerProfissionalExterno,
    alterarProfissionalExterno
  } = props

  useEffect(() => {
    listarProfissoes()
  }, [listarProfissoes])

  const salvar = async formulario => {
    const dados = {
      ...formulario,
    }

    const acao = dados.identificador ? alterarProfissionalExterno : adicionarProfissionalExterno
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/profissionais-externos`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_PROFISSIONAIS_EXTERNOS]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarProfissionaisExternos}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_PROFISSIONAL_EXTERNO]}>
              <Formulario
                {...props}
                salvar={salvar}
                profissional={profissional}
                profissoes={profissoes}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_PROFISSIONAL_EXTERNO]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarProfissionalExternoPeloIdentificador}
                salvar={salvar}
                profissional={profissional}
                remover={removerProfissionalExterno}
                profissoes={profissoes}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}