import React, { useMemo, useState, useLayoutEffect, useRef, useEffect } from 'react'
import { CartesianGrid, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatarDataParaFormatoLocal, formatarDataParaTimestamp } from '../../../../bibliotecas/data'
import { timestampCrescente } from '../../../../bibliotecas/ordenacao'

export default function GraficoBicarbonato({ historico }) {
  const TooltipBicarbonato = (props) => {
    const { active, payload } = props

    if (active) {
      const payloadBicarbonato = payload.find(x => x.dataKey === 'valor')

      return (
        <div className='tooltip-personalizado'>
          <p className='data'>Data: {formatarDataParaFormatoLocal(payloadBicarbonato.payload.data)}</p>
          <p style={{ color: payloadBicarbonato.color }}>Bicarbonato: {payloadBicarbonato.payload.valor} mEq/L</p>
        </div>
      )
    }

    return null
  }

  const [largura, setLargura] = useState(0)
  const graficos = useRef(null)

  const dados = useMemo(() => historico ? historico.map(x => ({ data: formatarDataParaTimestamp(x.data), valor: x.valor })).sort(timestampCrescente) : [], [historico])
  const [dadosParaGrafico, setDadosParaGrafico] = useState(dados)

  const maximo = dadosParaGrafico.length > 0 ? Math.max(...dadosParaGrafico.map(x => (x.valor))) : 0
  const minimo = dadosParaGrafico.length > 0 ? Math.min(...dadosParaGrafico.map(x => (x.valor))) : 0

  function CustomizacaoDeRotulo({ x, y, stroke, value }) {
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={12} textAnchor='middle'>{dados.length > 9 ? '' : `${value}`}</text>
  }
  
  const proximoMultiploDeCinco = valor => Math.round((valor + 2.4) / 5) * 5
  const multiploDeCincoAnterior = valor => Math.round((valor - 2.5) / 5) * 5

  useLayoutEffect(() => {
    function updateSize() {
      setLargura(graficos.current ? graficos.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateSize);
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    if (largura !== 0 && largura < 500 && dados.length > 9) {
      const ultimosRegistros = dados.slice(-10)
      setDadosParaGrafico(ultimosRegistros)
    } else {
      setDadosParaGrafico(dados)
    }
  }, [largura, dados])

  return (
    <div className='grafico' ref={graficos}>
      {dadosParaGrafico.length > 0 &&
        <ResponsiveContainer width={largura}>
          <LineChart
            data={dadosParaGrafico}
            margin={{
              top: 16,
              right: 25,
              left: -30,
              bottom: 8
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='data'
              height={20}
              padding={{ left: 20 }}
              type='number'
              scale='time'
              domain={['dataMin', 'dataMax']}
              fontSize={12}
              tickMargin={5}
              tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString('pt-br', {year: 'numeric', month: '2-digit'})}
            />
            <YAxis fontSize={12} domain={[multiploDeCincoAnterior(minimo), proximoMultiploDeCinco(maximo)]} />
            <Tooltip content={<TooltipBicarbonato dados={historico} />} />
            <Line
              activeDot={{ r: 5 }}
              dataKey='valor'
              label={<CustomizacaoDeRotulo />}
              name='bicarbonato'
              stroke='#8884d8'
              strokeWidth={1.3}
              type='monotone'
            />
          </LineChart>
        </ResponsiveContainer>
      }
      {largura !== 0 && largura < 500 && dadosParaGrafico.length > 9 &&
        <div className='description mt-2'>Visualização dos últimos 10 registros.</div>
      }
    </div>
  )
}