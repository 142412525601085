import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import constantes from '../../configuracoes/constantes'
import { gravarFotoNoCache, recuperarFotoDoCache, converterParaUrl } from '../../bibliotecas/imagem'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_FOTO_DO_PACIENTE,
  ADICIONOU_FOTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  REMOVER_FOTO_DO_PACIENTE,
  REMOVEU_FOTO_DO_PACIENTE
} from '../tipos'

export const adicionarFotoDoPaciente = foto => async dispatch => {
  dispatch({ type: ADICIONAR_FOTO_DO_PACIENTE })

  const tamanhoDaFotoEmMb = foto.size / 1024 ** 2;
  if (tamanhoDaFotoEmMb >= 2) {
    dispatch(erroAoAdicionarFotoDoPaciente({}, { foto }))
    dispatch(adicionarNotificacoesDaApi('', notificacao.deErro('A foto deve ter menos que 2MB.')))

    return null
  }

  try {
    const resultado = await api.adicionarArquivo(foto, 'foto-do-paciente')
    dispatch(adicionouFotoDoPaciente({ identificador: resultado.data.dados.identificador, url: foto.url }))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarFotoDoPaciente(erro, { foto }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar foto ao paciente.')))

    return null
  }
}

const adicionouFotoDoPaciente = foto => ({
  type: ADICIONOU_FOTO_DO_PACIENTE,
  foto,
})

const erroAoAdicionarFotoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE,
  erro,
  parametros
})

const removeuFotoDoPaciente = identificador => ({
  type: REMOVEU_FOTO_DO_PACIENTE,
  identificador
})

export const removerFotoDoPaciente = identificador => async dispatch => {
  dispatch({ type: REMOVER_FOTO_DO_PACIENTE })
  dispatch(removeuFotoDoPaciente(identificador))
}

const recuperouFotoDoPacientePorIdentificador = (foto, identificador) => ({
  type: RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  url: foto,
  identificador,
})

const erroAoRecuperarFotoDoPacientePorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarFotoDoPacientePorIdentificador = (identificador, identificadorDaFoto) => async dispatch => {
  dispatch({ type: RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDaFoto)

    if (itemDoCache) {
      dispatch(recuperouFotoDoPacientePorIdentificador(itemDoCache, identificadorDaFoto))
      return
    }

    const resultado = await api.recuperarFotoDoPacientePorIdentificador(identificador)
    const foto = gravarFotoNoCache(resultado.data, identificadorDaFoto)

    dispatch(recuperouFotoDoPacientePorIdentificador(foto, identificadorDaFoto))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarFotoDoPacientePorIdentificador(erro, { identificador, identificadorDaFoto }))
  }
}

export const recuperarFotoOriginalDoPacientePorIdentificador = (identificador) => async dispatch => {
  try {
    const resultado = await api.recuperarFotoDoPacientePorIdentificador(identificador, constantes.tamanhoDaFoto.original)
    const urlDaFoto = converterParaUrl(resultado.data)

    return urlDaFoto
  } catch (erro) {
    return false
  }
}