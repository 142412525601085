import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Breadcrumb from '../breadcrumb'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'
import FormularioDeEspecialidade from './especialidades/formulario'
import ListaDeEspecialidade from './especialidades/lista'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'

export default function Profissoes(props) {
  const {
    adicionarEspecialidade,
    adicionarProfissao,
    alterarEspecialidade,
    alterarProfissao,
    history,
    listarEspecialidades,
    listarProfissoesDoCadastro,
    location,
    match,
    profissao,
    paginaDeDados,
    recuperarEspecialidadePorIdentificador,
    recuperarProfissaoPorIdentificador,
    removerProfissao,
    removerEspecialidade,
    ordenacao,
    filtros
  } = props

  const salvar = async dados => {
    const novoRegistro = !dados.identificador
    const acao = novoRegistro ? adicionarProfissao : alterarProfissao
    const identificador = await acao(dados)

    if (identificador) {
      history.push(`/cadastros/profissoes/${identificador}`)
    }
  }

  const remover = async identificador => {
    const removeu = await removerProfissao(identificador)

    if (removeu) {
      history.push(`/cadastros/profissoes`)
    }
  }
  
  const salvarEspecialidade = async dados => {
    const novoRegistro = !dados.identificador

    if (novoRegistro) {
      const adicionou = await adicionarEspecialidade(profissao.identificador, dados)

      if (adicionou) {
        history.push(`/cadastros/profissoes/${profissao.identificador}/especialidades`)
        listarEspecialidades(profissao.identificador)
      }

      return adicionou

    } else {

      const alterou = await alterarEspecialidade(profissao.identificador, dados)

      if (alterou) {
        history.push(`/cadastros/profissoes/${profissao.identificador}/especialidades`)
        listarEspecialidades(profissao.identificador)
      }

      return alterou
    }
  }

  const removerEspecialidadeDaProfissao = async identificador => {
    const removeu = await removerEspecialidade(profissao.identificador, identificador)

    if (removeu) {
      history.push(`/cadastros/profissoes/${profissao.identificador}/especialidades`)
      listarEspecialidades(profissao.identificador)
    }
  }

  const abaAtiva = (props) => {
    const abas = {
      '/especialidades': 1      
    }    
    const url = location.pathname.replace(match.url+'/'+props.match.params.identificador, '')
    return abas[url] || 0
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_PROFISSOES]}>
              <Lista
                {...props}
                pesquisar={listarProfissoesDoCadastro}
                paginaDeDados={paginaDeDados}
                ordenacao={ordenacao}
                filtros={filtros}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/novo`,
          `${match.path}/:identificador`,
          `${match.path}/:identificador/especialidades`,
          `${match.path}/:identificador/especialidades/novo`,
          `${match.path}/:identificador/especialidades/:identificadorDaEspecialidade`,
        ]}
        render={
          props => {
            const identificador = props.match.params.identificador
            return (

              <div className='container'>
                <div className='main-content-form'>
                  <div className='header-page-form'>
                    <Breadcrumb />
                  </div>    

                  <Abas abaInicial={abaAtiva(props)}>
                    <div className='header-page-bar mt-0'>
                      <div className='header-page-nav'>
                        <ListaDeAbas className='tab'>
                          <Aba className='tab-item' aoClicar={() => identificador ? history.push(`${match.url}/${identificador}`) : ''}>
                            Profissão
                          </Aba>
                          <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => history.push(`${match.url}/${identificador}/especialidades`)}>
                            Especialidades
                          </Aba>                        
                        </ListaDeAbas>                        
                      </div>                   
                    </div>
                    <Paineis>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/novo`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ADICIONAR_PROFISSAO]}>
                                  <Formulario
                                    {...props}
                                    salvar={salvar}
                                  />
                                </Permissao>
                              )
                            }
                          />
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ALTERAR_PROFISSAO]}>
                                  <Formulario
                                    {...props}
                                    profissao={profissao}
                                    salvar={salvar}
                                    recuperarPorIdentificador={recuperarProfissaoPorIdentificador}
                                    remover={remover}
                                  />
                                </Permissao>
                              )
                            }
                          />
                        </Switch>
                      </Painel>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/especialidades`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.LISTAR_ESPECIALIDADES]}>
                                  <ListaDeEspecialidade
                                    {...props}
                                    especialidades={profissao.especialidades}
                                    listarEspecialidades={listarEspecialidades}
                                    recuperarProfissaoPorIdentificador={recuperarProfissaoPorIdentificador}
                                    profissao={profissao}
                                  />
                                </Permissao>
                              )
                            }
                          />
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/especialidades/novo`}
                            render={
                              props => (        
                                <Permissao permissoes={[permissoes.ADICIONAR_ESPECIALIDADE]}>
                                  <FormularioDeEspecialidade
                                    {...props}
                                    recuperarEspecialidade={recuperarEspecialidadePorIdentificador}
                                    recuperarProfissaoPorIdentificador={recuperarProfissaoPorIdentificador}
                                    salvar={salvarEspecialidade}
                                  />
                                </Permissao>       
                              )
                            }
                          />
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/especialidades/:identificadorDaEspecialidade`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ALTERAR_ESPECIALIDADE]}>
                                  <FormularioDeEspecialidade
                                    {...props}
                                    recuperarEspecialidade={recuperarEspecialidadePorIdentificador}
                                    recuperarProfissaoPorIdentificador={recuperarProfissaoPorIdentificador}
                                    profissao={profissao}
                                    salvar={salvarEspecialidade}
                                    remover={removerEspecialidadeDaProfissao}
                                  />
                                </Permissao>
                              )
                            }
                          />
                        </Switch>
                      </Painel>                    
                    </Paineis>
                  </Abas> 
                                
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}