import api from '../api-major'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

const fazerDownloadDeAtendimentos = ({ programa, dataDeInicio, dataDeFim }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)
  const programaParametro = programa ? `${programa}` : ''

  return api.get(`/relatorios/atendimentos?operadora=''&programa=${programaParametro}&inicio=${inicio}&fim=${fim}`, { responseType: 'arraybuffer' })
}

export default {
  fazerDownloadDeAtendimentos
}