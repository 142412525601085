import React from 'react'
import moment from 'moment'
import Spinner from '../../../spinner'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'

import {
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
} from '../../../../acoes/tipos'

export default function ConfirmacaoDeExclusao({ bloqueio, fechar, excluir, excluirSerie, motivos }) {

  const recuperarMotivo = codigo => {
    if (!codigo) {
      return ''
    }
    const motivo = motivos.find(x => x.codigo === codigo)

    return motivo ? motivo.nome : ''
  }

  return (
    <Spinner operacoes={[
      EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
      EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
    ]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Confirmação de Exclusão de Bloqueio`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-alerta__item'>
                    <ul>
                      <strong>Bloqueio</strong>: {`${recuperarMotivo(bloqueio.motivo)} do dia ${moment(bloqueio.data).format('DD/MM/YYYY')}`}.
                    </ul>
                  </div>

                </div>
                {bloqueio.serie && <ResumoDaRecorrencia {...bloqueio.serie} semana={construirArrayDeSemana(bloqueio.serie)} />}
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Cancelar</button>
                  <button className='button --danger' type='button' onClick={() => excluir(bloqueio.identificador)}>Excluir o Bloqueio</button>
                  {bloqueio.serie && <button className='button --danger-light' type='button' onClick={() => excluirSerie(bloqueio.identificador)}>Excluir a Série de Bloqueios</button>}
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}