import React from 'react'
import Formulario from './formulario'

export default function PedidoDeContato(props) {
  const {
    adicionarPedidoDeContato,
    cidades,
    listarCidadesParaOPedidoDeContato,
    listarPlanosDeSaudeParaOPedidoDeContato,
    listarSexos,
    planosDeSaude,
    sexos
  } = props

  const enviarPedido = async formulario => {
    let identificadorDaEmpresa = ''
    let identificadorDaUnidade = ''

    if (formulario.cidade === 'belo_horizonte') {
      identificadorDaEmpresa = 'f63d0344-2dbf-4959-8cc8-a505d66806da' //Id público da empresa +60
      identificadorDaUnidade = '1a394b92-9098-4be8-8dc9-2195babe8267' //Id público da Unidade Barro Preto
    } else if (formulario.cidade === 'sao_paulo') {
      identificadorDaEmpresa = 'd2cb1eb6-76f9-4c58-ad64-82280a3040c9' //Id público da empresa UN - Unimed Nacional
      identificadorDaUnidade = '99c38bc6-f154-47b5-9dc6-457453a8c115' //Id público da Unidade Principal
    }

    const enviou = await adicionarPedidoDeContato(identificadorDaEmpresa, identificadorDaUnidade, formulario)

    if (enviou) {
      window.location.replace(`https://mais60saude.com.br/agradecimento/`)
    }
  }

  return (
    <Formulario
      cidades={cidades}
      enviarPedido={enviarPedido}
      listarPlanosDeSaude={listarPlanosDeSaudeParaOPedidoDeContato}
      listarCidades={listarCidadesParaOPedidoDeContato}
      listarSexos={listarSexos}
      planosDeSaude={planosDeSaude}
      sexos={sexos}
    />
  )
}