import cidades from './cidades'
import planosDeSaude from './planos-de-saude'

import {
  ADICIONOU_PEDIDO_DE_CONTATO,
  ALTEROU_PEDIDO_DE_CONTATO,
  LISTOU_PEDIDOS_DE_CONTATO,
  RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  REMOVEU_PEDIDO_DE_CONTATO
} from '../../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: ''
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '-' },
  paginaDeDados: {},
}

const pedidoDeContato = (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_PEDIDO_DE_CONTATO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.pedidoDeContato
      }
    }

    case ALTEROU_PEDIDO_DE_CONTATO: {
      return {
        ...state,
        itemAtual: null,
      }
    }

    case LISTOU_PEDIDOS_DE_CONTATO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_PEDIDO_DE_CONTATO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  return {
    pedidoDeContato: pedidoDeContato(state.pedidoDeContato, action),
    cidades: cidades(state.cidades, action),
    planosDeSaude: planosDeSaude(state.planosDeSaude, action)
  }
}