import api from './api-major'

const fazerDownloadDoArquivoDaInternacao = (identificadorDoPaciente, identificadorDaInternacao, identificador) => {
  return api.get(`/pacientes/${identificadorDoPaciente}/internacoes/${identificadorDaInternacao}/arquivos/${identificador}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  fazerDownloadDoArquivoDaInternacao
}