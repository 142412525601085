import { connect } from 'react-redux'
import { ordemDoServidor } from '../../../bibliotecas/ordenacao'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import acoes from '../../../acoes'
import ListaDeEspera from './index'

const mapStateToProps = state => {
  let valoresIniciais = {
    paciente: {
      identificador: '',
      nome: '',
    },
    telefone1: '',
    telefone2: '',
    observacoes: '',
  }

  const extrairPacienteSelecionado = state => {
    if (state.agendamentos.listaDeEspera.selecionado) return state.agendamentos.listaDeEspera.selecionado
    if (!state.agendamentos.agendamento.paciente || !state.agendamentos.agendamento.paciente.identificador) return null
    return state.agendamentos.agendamento.pacientes[state.agendamentos.agendamento.paciente.identificador]
  }

  const extrairTelefones = (telefones, identificador) => {
    const telefonesDoPaciente = telefones.filter(x => x.proprietario === 'Paciente')

    if (telefonesDoPaciente.length === 0) {
      return
    }

    if (identificador === 1) {
      return telefonesDoPaciente[0].numero
    } else if (identificador === 2 && telefonesDoPaciente.length > 1) {
      return telefonesDoPaciente[1].numero
    }

  }

  const pacienteSelecionado = extrairPacienteSelecionado(state)
  const pacientes = converterObjetoParaArray(state.agendamentos.agendamento.pacientes).sort(ordemDoServidor)

  if (pacienteSelecionado) {
    valoresIniciais.paciente = {
      identificador: pacienteSelecionado.identificador,
      nome: pacienteSelecionado.nome
    }

    if (pacienteSelecionado.posicaoNaListaDeEspera) {
      valoresIniciais.identificador = pacienteSelecionado.identificadorDaListaDeEspera
      valoresIniciais.telefone1 = pacienteSelecionado.telefone1
      valoresIniciais.telefone2 = pacienteSelecionado.telefone2
      valoresIniciais.observacoes = pacienteSelecionado.observacoes
      valoresIniciais.posicaoNaListaDeEspera = pacienteSelecionado.posicaoNaListaDeEspera

    } else {
      valoresIniciais.telefone1 = extrairTelefones(pacienteSelecionado.telefones, 1)
      valoresIniciais.telefone2 = extrairTelefones(pacienteSelecionado.telefones, 2)

    }
  }

  return {
    identificadorDoProfissional: state.agenda.selecionado,
    listaDeEspera: state.agendamentos.listaDeEspera.lista,
    pacientes,
    valoresIniciais
  }
}

export default connect(mapStateToProps, acoes)(ListaDeEspera)