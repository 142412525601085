import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
} from '../tipos'

export const alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, bloqueio) => async dispatch => {
  dispatch({ type: ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.alterarBloqueioNaAgendaDoProfissionalDeSaude(identificadorDoProfissional, bloqueio)

    dispatch(alterouBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, bloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do bloqueio na agenda do profissional de saúde.')))

    return false
  }
}

const alterouBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = bloqueio => ({
  type: ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  bloqueio
})

const erroAoAlterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, bloqueio) => async dispatch => {
  dispatch({ type: ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, bloqueio)

    dispatch(alterouSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, bloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar os dados da série de bloqueio na agenda do profissional de saúde.')))

    return false
  }
}

const alterouSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = bloqueios => ({
  type: ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  bloqueios
})

const erroAoAlterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, identificadorDoBloqueio) => async dispatch => {
  dispatch({ type: EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    let resultado = await api.excluirBloqueioDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificadorDoBloqueio)
    dispatch(excluiuBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(identificadorDoBloqueio, identificadorDoProfissional))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Bloqueio removido com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, identificadorDoBloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um bloqueio da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoBloqueio, identificadorDoProfissional) => ({
  type: EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  identificadorDoBloqueio,
  identificadorDoProfissional
})

const erroAoExcluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro
})

export const excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoProfissional, identificadorDoBloqueio) => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO })

  try {
    const resultado = await api.excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude(identificadorDoProfissional, identificadorDoBloqueio)

    const bloqueios = resultado.data.dados
    bloqueios.forEach(x => {
      dispatch(excluiuSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(x.identificador, identificadorDoProfissional))
    })
    
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Série de bloqueios removida com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(erro, { identificadorDoProfissional, identificadorDoBloqueio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir um bloqueio da agenda do profissional de saúde.')))

    return false
  }
}

const excluiuSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (identificadorDoBloqueio, identificadorDoProfissional) => ({
  type: EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  identificadorDoBloqueio,
  identificadorDoProfissional
})

const erroAoExcluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros
})