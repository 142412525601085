import React from 'react'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'

export default function Resumo({ valor }) {
  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      {valor.length > 0 &&
        <Tabela
          chave='identificador'
          className='--secondary mb-2'
          dados={valor}
          expandida={true}
          exibirBotaoMais={false}
          titulo={false}
        >
          <LinhaExpandida className='col-xs-10 col-lg-11'>
            <AgrupamentoDeColunas>
              <Coluna
                campoDeDados='nome'
                icone='icon-name'
                nome='ORIENTAÇÃO'
              />   
              <Coluna
                campoDeDados='observacoes'
                icone='icon-doc-text'
                nome='OBSERVAÇÕES'
                className='table-items-expanded-100__box-item'
              />
            </AgrupamentoDeColunas>
          </LinhaExpandida>
        </Tabela>
      }
    </r-cell>
  )
}