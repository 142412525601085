import {
  LISTOU_RESULTADOS_HBSAG,
  LISTOU_RESULTADOS_ANTI_HCV,
  LISTOU_RESULTADOS_ANTI_HIV,
  LISTOU_RESULTADOS_ANTI_HBC
} from '../acoes/tipos'

const estadoInicial = {
  hbsag: [],
  antiHcv: [],
  antiHiv: [],
  antiHbc: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_RESULTADOS_HBSAG: {
      return {
        ...state,
        hbsag: action.resultadosHbsag
      }
    }

    case LISTOU_RESULTADOS_ANTI_HCV: {
      return {
        ...state,
        antiHcv: action.resultadosAntiHcv
      }
    }

    case LISTOU_RESULTADOS_ANTI_HIV: {
      return {
        ...state,
        antiHiv: action.resultadosAntiHiv
      }
    }

    case LISTOU_RESULTADOS_ANTI_HBC: {
      return {
        ...state,
        antiHbc: action.resultadosAntiHbc
      }
    }

    default: {
      return state
    }
  }
}
