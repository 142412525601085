import React, { useEffect, useMemo, Children } from 'react'
import { useSelector } from 'react-redux'
import { ListaDeBugs } from './bugs'
import { Funcionalidade } from './funcionalidade'

export function ListaDeReleases({ children, fechar, numero }) {
  const { empresa } = useSelector(state => state.login.logado)

  const release = Children.toArray(children).find(r => r.props.numero === numero)  

  const existeAoMenosUmaFuncionalidadeOuBugParaEmpresaLogada = useMemo(() => {
    if (!release) return false

    const existeListaDeBugs = Children.toArray(release.props.children).some((child) => child.type === ListaDeBugs)

    if (existeListaDeBugs) return true

    const funcionalidades = Children.toArray(release.props.children).filter((child) => child.type === Funcionalidade)

    return funcionalidades.some((child) => {
      const empresasAutorizadas = child.props.empresas || [empresa]
      return empresasAutorizadas.includes(empresa)
    })
  }, [release, empresa])

  useEffect(() => {
    if (!existeAoMenosUmaFuncionalidadeOuBugParaEmpresaLogada) {
      fechar()
    }
  }, [existeAoMenosUmaFuncionalidadeOuBugParaEmpresaLogada, fechar])

  return (
    <div>
      <i style={{ cursor: 'pointer' }} className='icon icon-close icon-close-modal-novidades' onClick={fechar}></i>
      {release}
    </div>
  )
}

export function Release({ children, numero }) {
  const somenteBugsNoRelease = !Array.isArray(children) && children.type === ListaDeBugs

  return (
    <div className='page-novidades-rodape'>
      <div className='box-campos'>
        <div className='logo' />
        <h1>O que há de novo</h1>
        <span className='button-light-novidades' style={{ cursor: 'default' }}>Versão {numero}</span>
        {!somenteBugsNoRelease && <hr className='separator mt-1 mb-1'></hr>}
        {children}
      </div>
    </div>
  )
}
