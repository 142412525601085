import React, { useEffect, useState } from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../../formik/formulario'
import Modal from 'react-modal'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  ALTERAR_STATUS_DE_UMA_RESPOSTA,
  RECUPERAR_AVALIACAO_RESPONDIDA
} from '../../../acoes/tipos'

export default function FormularioAvaliacao(props) {
  const {
    alterar,
    avaliacao = {},
    recuperar,
    history,
    match
  } = props

  const { identificador } = match.params
  const [perguntaSelecionada, setPerguntaSelecionada] = useState(null)
  const [exibirPergunta, setExibirPergunta] = useState(false)

  useEffect(() => {
    if (identificador)
      recuperar(identificador)
  }, [recuperar, identificador])

  function abrirAlterar(pergunta) {
    setPerguntaSelecionada(pergunta)
    setExibirPergunta(true)
  }

  function fecharAlterar() {
    setExibirPergunta(false)
    recuperar(identificador)
  }

  return (
    <>
      <Modal
        isOpen={exibirPergunta}
        className='modal'
        contentLabel='Modal para visualização do agendamento'
      >
        <VisualizarPergunta
          alterar={alterar}
          fechar={fecharAlterar}
          identificador={identificador}
          pergunta={perguntaSelecionada}
        />
      </Modal>
      <Spinner operacoes={[RECUPERAR_AVALIACAO_RESPONDIDA]}>
        <>
          <div className='form'>
            <div className='mt-2'>
              <fieldset>
                <div>
                  <h2 className='form-title'>Avaliação Respondida</h2>
                </div>
                {avaliacao?.atendimento &&
                  <div className='form-item'>
                    <div className='form-item__choice'><strong>Paciente: </strong> {avaliacao.atendimento.paciente}</div>
                    <div className='form-item__choice'><strong>Profissional: </strong>{avaliacao.atendimento.profissional.nome}</div>
                    <div className='form-item__choice'><strong>Data do atendimento: </strong> {moment(avaliacao.atendimento.inicio).format('DD/MM/YYYY')}</div>
                    <div className='form-item__choice'><strong>Respondido em: </strong> {moment(avaliacao.respondidaEm).format('DD/MM/YYYY')}</div>
                  </div>
                }
              </fieldset>
            </div>
          </div>
          <Tabela
            acoes={[
              item => (
                <Permissao key='editar' permissoes={[permissoes.ALTERAR_STATUS_DE_UMA_RESPOSTA_DO_NPS]}>
                  <button
                    className='table-items__bt --edit'
                    title='Editar'
                    type='button'
                    onClick={() => abrirAlterar(item)}
                  >
                    <i className='icon icon-pencil'></i>
                  </button>
                </Permissao>
              )
            ]}
            chave='identificador'
            className='table-items mt-2 mb-4'
            dados={avaliacao.perguntas}
          >
            <Coluna
              campoDeDados='texto'
              className='col-xs-8 col-md-10 col-lg-5'
              principal={true}
              nome='Pergunta'
            />
            <Coluna
              campoDeDados='resposta'
              className='col-xs-8 col-md-10 col-lg-4'
              nome='Resposta'
            />
            <Coluna
              campoDeDados='status'
              className='col-xs-8 col-md-10 col-lg-2'
              nome='Ativa'
              renderizar={item => item.ativa ? 'Sim' : 'Não'}
            />
          </Tabela>
        </>
      </Spinner>
      <div className='list-btn'>
        <button type='button' onClick={() => history.push(`/configuracoes/nps/respostas`)} className='button --light'>Voltar</button>
      </div>
    </>
  )
}

function VisualizarPergunta({
  alterar,
  fechar,
  identificador,
  pergunta
}) {

  const alterarStatus = async pergunta => {
    const dados = {
      status: pergunta.ativa ? 'Ativa' : 'Cancelada',
      justificativa: pergunta.ativa ? '' : pergunta.justificativa
    }
    const alterou = await alterar(identificador, pergunta.identificador, dados)
    if (alterou) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ALTERAR_STATUS_DE_UMA_RESPOSTA, RECUPERAR_AVALIACAO_RESPONDIDA]}>
      <FormularioDoFormik
        acao={alterarStatus}
        reinicializar={true}
        valoresIniciais={{
          identificador: pergunta.identificador,
          ativa: pergunta.ativa,
          justificativa: pergunta.justificativa || '',
        }}
        esquemaDeValidacoes={Yup.object().shape({
          justificativa: Yup.string()
            .when("ativa", {
              is: val => !val,
              then: Yup.string().required('Obrigatório')
            })
        })}
      >
        {({ values }) => (
          <fieldset>
            <h2 className='form-title'>{pergunta.texto}</h2>
            {pergunta.resposta &&
              <div className='form-item'>
                <div className='form-item__choice'>{pergunta.tipo === 'nota_de_1_a_10' ? 'Nota: ' : 'Resposta: '}{pergunta.resposta}</div>
              </div>
            }
            <r-grid columns-md={6} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Toglle
                  nome='ativa'
                  tabIndex={1}
                  titulo='Ativa'
                />
              </r-cell>
              {!values.ativa &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Input
                    as='textarea'
                    nome='justificativa'
                    tabIndex={1}
                    titulo='Justificativa *'
                  />
                </r-cell>
              }
            </r-grid>
            <div className='list-btn'>
              <button type='button' onClick={fechar} className='button --light'>Voltar</button>
              <button type='submit' className='button --primary'>Salvar</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}