import api from '../../../api-major'

const adicionarPhq9 = (identificador, phq9) => api.post(`/pacientes/${identificador}/phqs9`, phq9)
const alterarPhq9 = (identificador, phq9) => api.put(`/pacientes/${identificador}/phqs9/${phq9.identificador}`, phq9)
const removerPhq9 = (identificador, identificadorDoPhq9) => api.delete(`/pacientes/${identificador}/phqs9/${identificadorDoPhq9}`)
const listarPhqs9 = identificador => api.get(`/pacientes/${identificador}/phqs9`)

export default { 
  adicionarPhq9,
  alterarPhq9,
  removerPhq9,
  listarPhqs9
}