import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_PRAZO_DA_TAREFA,
  DEFINIU_PRAZO_DA_TAREFA,
  ERRO_AO_DEFINIR_PRAZO_DA_TAREFA
} from '../tipos'

const definiuPrazoDaTarefa = (identificadorDaTarefa, prazo) => ({
  identificadorDaTarefa,
  prazo,
  type: DEFINIU_PRAZO_DA_TAREFA,
})

const erroAoDefinirPrazoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_PRAZO_DA_TAREFA,
  erro,
  parametros,
})

export const definirPrazoDaTarefa = (identificadorDaTarefa, prazo) => async dispatch => {
  dispatch({ type: DEFINIR_PRAZO_DA_TAREFA })

  try {
    const resultado = await api.definirPrazoDaTarefa(identificadorDaTarefa, prazo)

    dispatch(definiuPrazoDaTarefa(identificadorDaTarefa, prazo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirPrazoDaTarefa(erro, { identificadorDaTarefa, prazo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o prazo da tarefa.')))

    return false
  }
}