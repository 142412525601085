import { ordemDoServidor } from '../../../bibliotecas/ordenacao'

import {
  SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO
} from '../../../acoes/tipos'

const estadoInicial = {
  paciente: {
    convenio: '',
    contatos: {
      telefone1: '',
      proprietarioDoTelefone1: '',
      nomeDoTelefone1: '',
      grauDeRelacaoDoTelefone1: '',
      telefone1EhWhatsapp: false,
      telefone2: '',
      proprietarioDoTelefone2: '',
      nomeDoTelefone2: '',
      grauDeRelacaoDoTelefone2: '',
      telefone2EhWhatsapp: false,
      email: ''
    },
  },
  convenios: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO: {
      let telefonesDoPaciente = action.pacienteSelecionado.telefones.map(x => ({
        ...x,
        _ordem: x.confirmacaoDeConsulta ? 1 : x.proprietario === 'Paciente' ? 2 : x.proprietario === 'Responsável' ? 3 : x.proprietario === 'Cuidador' ? 4 : 5
      }))
      telefonesDoPaciente.sort(ordemDoServidor)

      return {
        ...state,
        paciente: {
          identificador: action.pacienteSelecionado.identificador,
          nome: action.pacienteSelecionado.nome,
          dataDeNascimento: action.pacienteSelecionado.dataDeNascimento,
          medicoDeReferencia: action.pacienteSelecionado.medicoDeReferencia,
          unidadePreferencial: action.pacienteSelecionado.unidadePreferencial,
          foto: action.pacienteSelecionado.foto,
          contatos: {
            telefone1: telefonesDoPaciente.length > 0 ? telefonesDoPaciente[0].numero : '',
            proprietarioDoTelefone1: telefonesDoPaciente.length > 0 ? telefonesDoPaciente[0].proprietario : '',
            nomeDoTelefone1: telefonesDoPaciente.length > 0 ? telefonesDoPaciente[0].nome : '',
            grauDeRelacaoDoTelefone1: telefonesDoPaciente.length > 0 ? telefonesDoPaciente[0].grauDeRelacao : '',
            telefone1EhWhatsapp: telefonesDoPaciente.length > 0 ? telefonesDoPaciente[0].whatsApp : false,
            telefone2: telefonesDoPaciente.length > 1 ? telefonesDoPaciente[1].numero : '',
            proprietarioDoTelefone2: telefonesDoPaciente.length > 1 ? telefonesDoPaciente[1].proprietario : '',
            nomeDoTelefone2: telefonesDoPaciente.length > 1 ? telefonesDoPaciente[1].nome : '',
            grauDeRelacaoDoTelefone2: telefonesDoPaciente.length > 1 ? telefonesDoPaciente[1].grauDeRelacao : '',
            telefone2EhWhatsapp: telefonesDoPaciente.length > 1 ? telefonesDoPaciente[1].whatsApp : false,
            email: action.pacienteSelecionado.email,
          },
          associacao: action.pacienteSelecionado.associacao,
          programas: action.pacienteSelecionado.programas.map(x => x.programa)
        }
      }
    }

    case SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        paciente: {
          ...state.paciente,
          convenio: action.identificador
        }
      }
    }

    case SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO: {
      const { dadosDeContato } = action
      return {
        ...state,
        paciente: {
          ...state.paciente,
          contatos: {
            telefone1: dadosDeContato.telefone1,
            proprietarioDoTelefone1: dadosDeContato.proprietarioDoTelefone1,
            nomeDoTelefone1: dadosDeContato.nomeDoTelefone1,
            grauDeRelacaoDoTelefone1: dadosDeContato.grauDeRelacaoDoTelefone1,
            telefone1EhWhatsapp: dadosDeContato.telefone1EhWhatsapp,
            telefone2: dadosDeContato.telefone2,
            proprietarioDoTelefone2: dadosDeContato.proprietarioDoTelefone2,
            nomeDoTelefone2: dadosDeContato.nomeDoTelefone2,
            grauDeRelacaoDoTelefone2: dadosDeContato.grauDeRelacaoDoTelefone2,
            telefone2EhWhatsapp: dadosDeContato.telefone2EhWhatsapp,
            email: dadosDeContato.email,
          }
        }
      }
    }

    case RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        convenios: action.convenios
      }
    }

    case LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        paciente: {
          convenio: '',
          contatos: {
            telefone1: '',
            proprietarioDoTelefone1: '',
            nomeDoTelefone1: '',
            grauDeRelacaoDoTelefone1: '',
            telefone1EhWhatsapp: false,
            telefone2: '',
            proprietarioDoTelefone2: '',
            nomeDoTelefone2: '',
            grauDeRelacaoDoTelefone2: '',
            telefone2EhWhatsapp: false,
            email: ''
          }
        },
        convenios: []
      }
    }

    default: {
      return state
    }
  }
}