import { connect } from 'react-redux'
import acoes from '../../../acoes'
import Agenda from './index'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const recuperarFotoDoPaciente = (dados = [], imagens = {}, selecionado = 0) => {
  let foto = {}  

  const profissional = dados.filter(x => x.identificador === selecionado)
  if (profissional && profissional[0] && profissional[0].dias) {
    converterObjetoParaArray(profissional[0].dias).forEach(item => {      
      item.agendamentos && item.agendamentos.filter(x => x.paciente.foto).forEach(a => {
        if (imagens[a.paciente.foto]) foto[a.paciente.foto] = imagens[a.paciente.foto]
      })
    })
  }

  return foto
}

const mapStateToProps = state => {
  let profissionaisDeSaude = converterObjetoParaArray(state.agenda.profissionais)

  return {
    calendario: state.agenda.calendario,
    fotos: recuperarFotos(profissionaisDeSaude, state.imagens),
    fotoDoPaciente: recuperarFotoDoPaciente(profissionaisDeSaude, state.imagens, state.agenda.selecionado),
    filtros: state.agenda.filtros,
    motivosDeCancelamento: state.agendamentos.motivosDeCancelamento,
    motivosDeBloqueio: converterObjetoParaArray(state.motivosDeBloqueioDaAgenda.itens),
    profissionais: state.agenda.profissionais,
    profissional: state.agenda.profissionais[state.agenda.selecionado],
    unidades: state.login.logado.unidades,
  }
}

export default connect(mapStateToProps, acoes)(Agenda)