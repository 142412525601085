import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_PERFIS,
  LISTOU_PERFIS,
  ERRO_AO_LISTAR_PERFIS,
  LISTAR_PERFIS_DO_USUARIO,
  LISTOU_PERFIS_DO_USUARIO,
  ERRO_AO_LISTAR_PERFIS_DO_USUARIO,
  REMOVER_PERFIL_DO_USUARIO,
  REMOVEU_PERFIL_DO_USUARIO,
  ERRO_AO_REMOVER_PERFIL_DO_USUARIO,
  ADICIONAR_PERFIL_DO_USUARIO,
  ADICIONOU_PERFIL_DO_USUARIO,
  ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO,
} from '../tipos'

const listouPerfisDoUsuario = perfisDoUsuario => ({
  type: LISTOU_PERFIS_DO_USUARIO,
  perfisDoUsuario,
})

const erroAoListarPerfisDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PERFIS_DO_USUARIO,
  erro,
  parametros,
})

export const listarPerfisDoUsuario = identificador => async dispatch => {
  dispatch({ type: LISTAR_PERFIS_DO_USUARIO })

  try {
    const resultado = await api.listarPerfisDoUsuario(identificador)

    dispatch(listouPerfisDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPerfisDoUsuario(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os perfis do usuário.')))
  }
}

const removeuPerfilDoUsuario = identificadorDoPerfil => ({
  type: REMOVEU_PERFIL_DO_USUARIO,
  identificadorDoPerfil,
})

const erroAoRemoverPerfilDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PERFIL_DO_USUARIO,
  erro,
  parametros,
})

export const removerPerfilDoUsuario = (identificadorDoUsuario, identificadorDoPerfil) => async dispatch => {
  dispatch({ type: REMOVER_PERFIL_DO_USUARIO })

  try {
    const resultado = await api.removerPerfilDoUsuario(identificadorDoUsuario, identificadorDoPerfil)

    dispatch(removeuPerfilDoUsuario(identificadorDoPerfil))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPerfilDoUsuario(erro, { identificadorDoUsuario, identificadorDoPerfil }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o perfil do usuário.')))

    return false
  }
}

const adicionouPerfilDoUsuario = identificadorDoPerfil => ({
  type: ADICIONOU_PERFIL_DO_USUARIO,
  identificadorDoPerfil,
})

const erroAoAdicionarPerfilDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO,
  erro,
  parametros,
})

export const adicionarPerfilDoUsuario = (identificadorDoUsuario, identificadorDoPerfil) => async dispatch => {
  dispatch({ type: ADICIONAR_PERFIL_DO_USUARIO })
  try {
    const resultado = await api.adicionarPerfilDoUsuario(identificadorDoUsuario, identificadorDoPerfil)

    dispatch(adicionouPerfilDoUsuario(identificadorDoPerfil))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPerfilDoUsuario(erro, { identificadorDoUsuario, identificadorDoPerfil }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o perfil do usuário.')))

    return false
  }
}

const listouPerfis = perfis => ({
  type: LISTOU_PERFIS,
  perfis,
})

const erroAoListarPerfis = erro => ({
  type: ERRO_AO_LISTAR_PERFIS,
  erro,
})

export const listarPerfis = () => async dispatch => {
  dispatch({ type: LISTAR_PERFIS })

  try {
    let resultado = await api.listarPerfis()

    dispatch(listouPerfis(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPerfis(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os perfis.')))
  }
}