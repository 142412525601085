import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from '../reducers/'
import createDebounce from 'redux-debounced'
import { verificarSeAcaoPossuiCache } from '../middlewares/cache'

export const configurarStore = (estadoInicial = undefined) => {
  const logger = createLogger()
  const middlewares = applyMiddleware(
    createDebounce(),
    verificarSeAcaoPossuiCache,
    thunk,
    logger,
  )

  const store = createStore(
    reducers,
    estadoInicial,
    composeWithDevTools(
      middlewares,
    )
  )

  return store
}