import React, { useState } from 'react'
import Modal from 'react-modal'
import './style.css'

export default function HistoricoPopup({ titulo, children }) {
  const [exibirGrafico, setExibirGrafico] = useState(false)

  function abrirGrafico() {
    setExibirGrafico(true)
  }

  function fecharGrafico() {
    setExibirGrafico(false)
  }

  return (
    <>
      <div className='botao-historico-prontuario' onClick={() => abrirGrafico()}>
        Histórico
      </div>
      <Modal
        isOpen={exibirGrafico}
        className='modal modal-historico'
        contentLabel='Modal para histórico do prontuario'
      >
        <React.Fragment>
          <label className='titulo-grafico-prontuario'>{titulo}</label>
          <button className='fechar-grafico-prontuario' onClick={fecharGrafico}>x</button>
          {children}
        </React.Fragment>
      </Modal>
    </>
  )
}