import api from './api-major'

const adicionarArquivo = (arquivo, tipo) => {
  const dados = new FormData()
  dados.append('arquivo', arquivo)

  return api.post(`/arquivos?tipo=${tipo}`, dados);
}

export default {
  adicionarArquivo
}