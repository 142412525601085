import {
  LISTOU_MICRORGANISMOS
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_MICRORGANISMOS: {
      return action.microrganismos
    }

    default: {
      return state
    }
  }
}