import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import * as Yup from 'yup'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle, DatePicker, Opcoes } from '../formik/formulario'

import {
  RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  SALVAR_FERIADO,
  ADICIONAR_FERIADO,
  SALVAR_SERIE_DE_FERIADOS,
} from '../../acoes/tipos'
import ConfirmacaoDeExclusao from './confirmacao-de-exclusao'

const TIPO_ALTERACAO = ['Alterar somente este feriado', 'Alterar toda a série']

export default function Formulario(props) {
  const {
    match,
    history,
    feriado,
    salvar,
    recuperarPorIdentificador,
    remover,
    removerSerie
  } = props

  const { identificador } = match.params
  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_FERIADO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_FERIADO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_FERIADO])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  const excluirFeriado = async identificador => {
    const excluiu = await remover(identificador)

    if (excluiu) {
      fecharAlertaDeExclusao()
      history.push(`/configuracoes/feriados`)

    }
  }

  const excluirSerieDeFeriados = async identificador => {
    const excluiu = await removerSerie(identificador)

    if (excluiu) {
      fecharAlertaDeExclusao()
      history.push(`/configuracoes/feriados`)
    }
  }

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={excluirFeriado}
          excluirSerie={excluirSerieDeFeriados}
          fechar={fecharAlertaDeExclusao}
          feriado={feriado}
        />
      </Modal>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[RECUPERAR_FERIADO_POR_IDENTIFICADOR, SALVAR_FERIADO, SALVAR_SERIE_DE_FERIADOS, ADICIONAR_FERIADO]}>
            <FormularioDoFormik
              reinicializar={true}
              valoresIniciais={{
                identificador: feriado.identificador || '',
                nome: feriado.nome || '',
                observacoes: feriado.observacoes || '',
                data: feriado.data ? feriado.data : '',
                tipoDeRepeticao: feriado.serie.tipoDeRepeticao,
                tipoDeAlteracao: [TIPO_ALTERACAO[0]],
                serie: feriado?.serie,
              }}
              acao={salvar}
              esquemaDeValidacoes={Yup.object().shape({
                nome: Yup.string().required('Obrigatório'),
                data: Yup.string().required('Obrigatório')
              })}
            >
              {({ setFieldValue, values }) => (
                <>
                  <fieldset>
                    <h2 className='form-title'>{feriado.identificador ? 'Editar Feriado' : 'Adicionar Novo Feriado'}</h2>
                    <r-grid columns-md={12} columns-lg={12}>
                      {feriado.identificador &&
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Opcoes
                            nome='tipoDeAlteracao'
                            opcoes={TIPO_ALTERACAO}
                            selecaoUnica={true}
                            classname='encaminhamentos'
                          />
                        </r-cell>
                      }
                      <r-cell span={4} span-md={9} span-lg={10}>
                        <Input
                          nome='nome'
                          tabIndex={1}
                          titulo='Nome *'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={2}>
                        <DatePicker
                          nome='data'
                          tabIndex={2}
                          titulo='Data *'
                        />
                      </r-cell>
                      {(!values.identificador || values.tipoDeAlteracao[0] === 'Alterar toda a série') &&
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <div className='form-toggle-list'>
                            <Toglle
                              nome='tipoDeRepeticao'
                              tabIndex={3}
                              titulo='Recorrente'
                            />
                          </div>
                        </r-cell>
                      }
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Input
                          as='textarea'
                          nome='observacoes'
                          tabIndex={4}
                          titulo='Observações'
                        />
                      </r-cell>
                    </r-grid>
                  </fieldset>
                  {remover ?
                    <div className='list-btn-unidades'>
                      {exibirExcluir && <button onClick={() => abrirAlertaDeExclusao()} type='button' className='button --danger'>Excluir</button>}
                      {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                      <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    </div>
                    :
                    <div className='list-btn'>
                      <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                      {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                    </div>
                  }
                </>
              )}
            </FormularioDoFormik>
          </Spinner>
        </div>
      </div>
    </>
  )
}