import React from 'react'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, YearPicker, MonthPicker, Input, Radio } from '../../../formik/formulario'
import { dataIgualOuAnterior } from '../../../../bibliotecas/data'
import { ADICIONAR_VACINA_DO_PACIENTE, ALTERAR_VACINA_DO_PACIENTE, LISTAR_VACINAS, LISTAR_FABRICANTES, LISTAR_LOCAIS_DA_APLICACAO } from '../../../../acoes/tipos'

export default function Formulario({ fechar, vacinas, fabricantes, locaisDaAplicacao, selecionada, ...rest }) {
  const periodos = [
    'Dia',
    'Mês/Ano',
    'Ano',
    'Não lembra'
  ]

  const salvar = async (valores, { resetForm }) => {
    const dados = {
      ...valores,
      precisaoDaData: valores.precisaoDaData === 'Não lembra' ? null : valores.precisaoDaData,
      data: valores.data && valores.precisaoDaData !== 'Não lembra' ? valores.data : null
    }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const retornarVacinas = vacina => {
    if (vacina && !vacinas.find(x => x.nome === vacina)) return [...vacinas, { nome: vacina }]

    return vacinas
  }

  const onChangeAplicadaNaUnidade = (valor, setFieldValue) => {
    if (valor) {
      setFieldValue('aplicadaNaUnidade', true)
      setFieldValue('precisaoDaData', 'Dia')
    } else {
      setFieldValue('aplicadaNaUnidade', false)
      setFieldValue('fabricante', '')
      setFieldValue('lote', '')
      setFieldValue('validade', '')
      setFieldValue('localDaAplicacao', '')
    }
  }

  return (
    <Spinner operacoes={[LISTAR_VACINAS, LISTAR_FABRICANTES, LISTAR_LOCAIS_DA_APLICACAO, ADICIONAR_VACINA_DO_PACIENTE, ALTERAR_VACINA_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : '',
          vacina: selecionada ? selecionada.vacina : '',
          precisaoDaData: selecionada ? (selecionada.precisaoDaData ? selecionada.precisaoDaData : 'Não lembra') : '',
          data: selecionada && selecionada.data ? selecionada.data : '',
          aplicadaNaUnidade: selecionada ? selecionada.aplicadaNaUnidade : '',
          fabricante: selecionada && selecionada.fabricante ? selecionada.fabricante.nome : '',
          lote: selecionada && selecionada.lote ? selecionada.lote : '',
          validade: selecionada && selecionada.validade ? selecionada.validade : '',
          localDaAplicacao: selecionada && selecionada.localDaAplicacao ? selecionada.localDaAplicacao : '',
          observacoes: selecionada ? selecionada.observacoes : ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          aplicadaNaUnidade: Yup.boolean().required('Deve ser informado.'),
          precisaoDaData: Yup.string().required('Obrigatório.'),
          data: Yup.string().nullable()
            .when(['aplicadaNaUnidade', 'precisaoDaData'], {
              is: (aplicadaNaUnidade, precisaoDaData) => aplicadaNaUnidade === true || (precisaoDaData === 'Dia' || precisaoDaData === 'Mês/Ano' || precisaoDaData === 'Ano'),
              then: Yup.string().nullable().required('Obrigatório.')
                .test(
                  "validade_teste",
                  "Deve ser menor que a validade.",
                  function (value) {
                    const { validade } = this.parent;
                    const data = value?.split('-')
                    const primeiro = data && (data[0] + '-' + data[1] + '-01')
                    return !value || !validade || dataIgualOuAnterior(primeiro, validade);
                  }
                )
            }),
          vacina: Yup.string().required('Obrigatório.'),
          fabricante: Yup.string().nullable()
            .when('aplicadaNaUnidade', {
              is: val => val === true,
              then: Yup.string().required('Obrigatório.')
            }),
          lote: Yup.string().nullable()
            .when('aplicadaNaUnidade', {
              is: val => val === true,
              then: Yup.string().required('Obrigatório.')
            }),
          validade: Yup.string().nullable()
            .when('aplicadaNaUnidade', {
              is: val => val === true,
              then: Yup.string().required('Obrigatório.').nullable()
                .test(
                  "data_teste",
                  "Deve ser maior que a data da aplicação.",
                  function (value) {
                    const { data } = this.parent;
                    const dia = data?.split('-')
                    const primeiro = dia && (dia[0] + '-' + dia[1] + '-01')
                    return !value || !data || dataIgualOuAnterior(primeiro, value);
                  }
                ),
            }),
          localDaAplicacao: Yup.string().nullable()
            .when('aplicadaNaUnidade', {
              is: val => val === true,
              then: Yup.string().required('Obrigatório.')
            }),
        })}
        acao={salvar}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>{selecionada ? 'Alterar Vacina' : 'Adicionar Vacina'}</h2>
            <r-grid columns-md={6} columns-lg={12} className='mb-2'>
              <r-cell span={4} span-md={6} span-lg={4}>
                <label className='form-label'>A vacina foi aplicada na unidade? *</label>
                <div className='d-flex'>
                  <Radio
                    nome='aplicadaNaUnidade'
                    titulo='Sim'
                    valor={true}
                    classname='mr-3'
                    onChange={() => onChangeAplicadaNaUnidade(true, setFieldValue)}
                    tabIndex={1}
                  />
                  <Radio
                    nome='aplicadaNaUnidade'
                    titulo='Não'
                    valor={false}
                    onChange={() => onChangeAplicadaNaUnidade(false, setFieldValue)}
                    tabIndex={2}
                  />
                </div>
                {(errors.aplicadaNaUnidade && touched.aplicadaNaUnidade) ? <label className='form-error'>Deve ser informado</label> : ''}
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={4}>
                <Select
                  nome='precisaoDaData'
                  titulo='Período *'
                  tabIndex={3}
                  itens={periodos}
                  campoCodigo=''
                  campoDescricao=''
                  isDisabled={values.aplicadaNaUnidade}
                />
              </r-cell>
              {values.precisaoDaData === 'Dia' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <DatePicker
                    nome='data'
                    titulo='Dia'
                    tabIndex={4}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Mês/Ano' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <MonthPicker
                    nome='data'
                    titulo='Mês/Ano'
                    tabIndex={4}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              {values.precisaoDaData === 'Ano' &&
                <r-cell span={4} span-md={3} span-lg={4}>
                  <YearPicker
                    nome='data'
                    titulo='Ano'
                    tabIndex={4}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={6} span-lg={12}>
                <Select
                  nome='vacina'
                  titulo='Vacina *'
                  tabIndex={5}
                  itens={retornarVacinas(values.vacina)}
                  campoCodigo='nome'
                  campoDescricao='nome'
                />
              </r-cell>
              {values.aplicadaNaUnidade === true &&
                <>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Select
                      nome='fabricante'
                      titulo='Fabricante *'
                      tabIndex={6}
                      itens={fabricantes}
                      campoCodigo='nome'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Input
                      nome='lote'
                      titulo='Lote *'
                      tabIndex={7}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={2} span-lg={4}>
                    <MonthPicker
                      nome='validade'
                      titulo='Validade *'
                      tabIndex={8}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={4} span-lg={8}>
                    <Select
                      nome='localDaAplicacao'
                      titulo='Local da Aplicação *'
                      tabIndex={9}
                      itens={locaisDaAplicacao}
                      campoCodigo='nome'
                      campoDescricao='nome'
                    />
                  </r-cell>
                </>
              }
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  titulo='Observações'
                  tabIndex={10}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn mt-4'>
              <button type='button' className='button --light' onClick={fechar}>Cancelar</button>
              <button type='submit' className='button --primary'>{selecionada ? 'Salvar alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}