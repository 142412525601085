import React, { useEffect } from 'react'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, Input } from '../../formik/formulario'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
} from '../../../acoes/tipos'

export default function FormularioDeConcentracao(props) {
  const {
    salvar,
    match,
    history,
    recuperarMedicamentoPorIdentificador,
  } = props

  const { identificador } = match.params

  useEffect(() => {
    recuperarMedicamentoPorIdentificador(identificador)
  }, [recuperarMedicamentoPorIdentificador, identificador])

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO])

  return (    
    <Spinner operacoes={[ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          valor: '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          valor: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset className='mt-1'>
          <h2 className='form-title'>Adicionar Concentração</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={8}>
              <Input
                type='text'
                nome='valor'
                tabIndex={1}
                titulo='Valor *'
              />
            </r-cell>
          </r-grid>
        </fieldset>
        <div className='list-btn'>
          <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
          {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
        </div>
      </FormularioDoFormik>
    </Spinner>        
  )
}