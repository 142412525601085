import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
    ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
    LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
    LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS,
    ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA,
    ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
    ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
    REMOVER_ESPECIALIDADE_MEDICA_INTERNA,
    REMOVEU_ESPECIALIDADE_MEDICA_INTERNA,
    ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA
} from "./tipos";

const listouEspecialidadesMedicasInternas = especialidades => ({
    type: LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS,
    especialidades
})

const erroAoListarEspecialidadesMedicasInternas = erro => ({
    type: ERRO_AO_LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
    erro
})

export const listarEspecialidadesMedicasInternas = () => async dispatch => {
    dispatch({ type: LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS })

    try {
        const resultado = await api.listarEspecialidadesMedicasInternas()

        dispatch(listouEspecialidadesMedicasInternas(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

    } catch (erro) {
        dispatch(erroAoListarEspecialidadesMedicasInternas(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as especialidades médicas internas.')))
    }
}

const adicionouEspecialidadeMedicaInterna = especialidade => ({
    type: ADICIONOU_ESPECIALIDADE_MEDICA_INTERNA,
    especialidade
})

const erroAoAdicionarEspecialidadeMedicaInterna = (erro, parametros) => ({
    type: ERRO_AO_ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
    erro,
    parametros
})

export const adicionarEspecialidadeMedicaInterna = especialidade => async dispatch => {
    dispatch({ type: ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA })

    try {
        const resultado = await api.adicionarEspecialidadeMedicaInterna(especialidade)

        dispatch(adicionouEspecialidadeMedicaInterna(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoAdicionarEspecialidadeMedicaInterna(erro, { especialidade }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar a especialidade médica interna.')))

        return false
    }
}

const removeuEspecialidadeMedicaInterna = especialidade => ({
    type: REMOVEU_ESPECIALIDADE_MEDICA_INTERNA,
    especialidade
})

const erroAoRemoverEspecialidadeMedicaInterna = (erro, parametros) => ({
    type: ERRO_AO_REMOVER_ESPECIALIDADE_MEDICA_INTERNA,
    erro,
    parametros
})

export const removerEspecialidadeMedicaInterna = identificador => async dispatch => {
    dispatch({ type: REMOVER_ESPECIALIDADE_MEDICA_INTERNA })

    try {
        const resultado = await api.removerEspecialidadeMedicaInterna(identificador)

        dispatch(removeuEspecialidadeMedicaInterna(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoRemoverEspecialidadeMedicaInterna(erro, { identificador }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a especialidade médica interna.')))

        return false
    }
}