import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
} from '../tipos'

const definiuProfissionalResponsavelDaTarefa = (identificadorDaTarefa, profissional) => ({
  identificadorDaTarefa,
  profissional,
  type: DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
})

const erroAoDefinirProfissionalResponsavelDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  erro,
  parametros,
})

export const definirProfissionalResponsavelDaTarefa = (identificadorDaTarefa, profissional) => async dispatch => {
  dispatch({ type: DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA })

  try {
    const resultado = await api.definirProfissionalResponsavelDaTarefa(identificadorDaTarefa, profissional)

    dispatch(definiuProfissionalResponsavelDaTarefa(identificadorDaTarefa, profissional))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirProfissionalResponsavelDaTarefa(erro, { identificadorDaTarefa, profissional }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o responsável da tarefa.')))

    return false
  }
}

const listouProfissionaisResponsaveisDoTarefas = profissionais => ({
  type: LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  profissionais
})

const erroAoListarProfissionaisResponsaveisDoTarefas = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  erro,
  parametros
})

export const listarProfissionaisResponsaveisDoTarefas = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS })

    try {
      const retorno = await api.listarUsuarios(filtros, pagina, ordem, tamanhoDaPagina)

      dispatch(listouProfissionaisResponsaveisDoTarefas(retorno.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarProfissionaisResponsaveisDoTarefas(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais responsáveis do tarefas.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
    }
  }

  return resultado
}