import React from 'react'
import {formatarDataParaFormatoDiaDaSemana} from '../../../bibliotecas/data'
import moment from 'moment'
import {FormChecked} from '../../../design/icones'

export default function Ivcf({ valor, rotulo }) {
  const icone = <FormChecked />

  function diaDaSemana(dia) {
    const dataFormatada = moment(dia).format('LL')
    return `${formatarDataParaFormatoDiaDaSemana(dia)}, ${dataFormatada}`
  }

  if (valor.length === 0) {
    return (
        <div className='card-componente form-group'>
          <div className='icon'>{icone}</div>
          <div className='componente-select-acao'>
            <label className='titulo'>{rotulo}</label>
          </div>
          <div className='unity-infos agendamentos'>
            <div className='unity-infos__item agendamentos'>
              <p><i className='icon icon-attention-circled' style={{ position: 'inherit' }}></i>O paciente ainda não possui IVCF.</p>
            </div>
          </div>
      </div>
    )
  }

  return (
    <div className='card-componente form-group'>
      <div className='icon'>{icone}</div>
      <div className='componente-select-acao'>
        <label className='titulo'>{rotulo}</label>
      </div>
      {valor && valor.map(x => (
          <div key={x.identificador} className='unity-infos agendamentos'>
            <div className='unity-infos__item agendamentos'>
              <p><i className='icon icon-calendar-empty' style={{ position: 'inherit' }}></i><strong>{diaDaSemana(x.data)}</strong></p>
              <p><strong>Resultado: </strong>{x.resultado}</p>
            </div>
          </div>
        ))}
      </div>
  )
}