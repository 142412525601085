import api from './api-major'

const listarSetores = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10000'

  return api.get(`/setores${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarSetor = fabricante => api.post(`/setores/`, fabricante)
const recuperarSetorPeloIdentificador = identificador => api.get(`/setores/${identificador}`)
const alterarSetor = fabricante => api.put(`/setores/${fabricante.identificador}`, fabricante)
const removerSetor = identificador => api.delete(`/setores/${identificador}`)

export default {
  listarSetores,
  adicionarSetor,
  recuperarSetorPeloIdentificador,
  alterarSetor,
  removerSetor
}