import {
  LISTOU_INTENSIDADES_DAS_DORES_DA_TRIAGEM
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_INTENSIDADES_DAS_DORES_DA_TRIAGEM: {
      return action.intensidadesDasDores
    }

    default: {
      return state
    }
  }
}