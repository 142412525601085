import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  IMPORTAR_PACIENTES,
  IMPORTOU_PACIENTES,
  ERRO_AO_IMPORTAR_PACIENTES,
  LIMPAR_IMPORTACAO_DE_PACIENTES
} from '../tipos'

const importouPacientes = dados => ({
  type: IMPORTOU_PACIENTES,
  dados
})

const erroAoImportarPacientes = erro => ({
  type: ERRO_AO_IMPORTAR_PACIENTES,
  erro
})

export const importarPacientes = (arquivo, etapa) => async dispatch => {
  dispatch({ type: IMPORTAR_PACIENTES })
  try {
    const resultado = await api.importarPacientes({ arquivo, etapa })

    dispatch(importouPacientes(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoImportarPacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível importar os pacientes.')))
  }
}

export const limparImportacaoDePacientes = () => ({ type: LIMPAR_IMPORTACAO_DE_PACIENTES })