import React, {useRef, useState} from 'react'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'

export default function CampoDePesquisa(props) {
  const {
    autoPesquisar = false,
    placeholder = 'Pesquisar',
    valor,
    pesquisar,
    className = '',
    filtros = [],
    filtrosAplicados = [],
    filtrar = false
  } = props

  const [termo, setTermo] = useState(valor || '')
  const [filtro, setFiltro] = useState(filtrosAplicados)
  const [exibirFiltro, setExibirFiltro] = useState(false)

  const referenciaDoFiltro = useRef(null)
  useCliqueForaDoElemento(referenciaDoFiltro, () => setExibirFiltro(false))

  const aoPressionarEnter = evento => {
    if (evento.key !== 'Enter') return
    filtrar ? pesquisar(termo, filtro) : pesquisar(termo)
  }

  const adicionarFiltro = filtro => {
    const novosFiltros = [...filtrosAplicados, filtro]
    setFiltro(novosFiltros)
    pesquisar(termo, novosFiltros)
  }

  const removerFiltro = filtro => {
    const novosFiltros = filtrosAplicados.filter(x => x !== filtro)
    setFiltro(novosFiltros)
    pesquisar(termo, novosFiltros)
  }

  return (
    <div className={`form-search ${className}`}>
      <input
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        className={`form-search__input ${filtro ? 'filtro' : ''}`}
        type='text'
        placeholder={placeholder}
        onChange={evento => {
          setTermo(evento.target.value)
          if (autoPesquisar) {
            filtrar ? pesquisar(evento.target.value, filtro) : pesquisar(evento.target.value)
          }
        }}
        onKeyDown={evento => aoPressionarEnter(evento)}
        value={termo}
        name='pesquisar'
        id='pesquisar'
      />
      {filtrar &&
       <div ref={referenciaDoFiltro}>
         <div
           className={`form-search__bt filtrar filter-list ${exibirFiltro && 'is-active'}`}
           onClick={() => setExibirFiltro(!exibirFiltro)}
         >
           <div className='d-inline-flex icon-filter'></div>
           <div className='filter-list__drop filtro-campo-pesquisa'>
             <div className='filter-list__drop__arrow'></div>
             {filtros.map((filtro, index) => (
               <div className='checkbox' key={`check_${index}`}>
                 <input
                   className='checkbox-input'
                   defaultChecked={filtrosAplicados.includes(filtro)}
                   disabled={filtrosAplicados.length === 1 && filtrosAplicados.includes(filtro)}
                   onChange={evento => evento.target.checked ? adicionarFiltro(filtro) : removerFiltro(filtro)}
                   id={`check_${index}`}
                   name={`check_${index}`}
                   type='checkbox'
                 />
                 <label className='checkbox-label' htmlFor={`check_${index}`}><span>{filtro}</span></label>
               </div>
             ))}
           </div>
         </div>
       </div>
      }
      <button
        className='form-search__bt'
        type='submit'
        onClick={() => filtrar ? pesquisar(termo, filtro) : pesquisar(termo)}
      >
        <i className='icon icon-search' />
      </button>
    </div>
  )
}