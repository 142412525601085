import React from 'react'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import { Formulario as FormularioDoFormik, DatePicker, Select } from '../../formik/formulario'
import { dataIgualOuAnterior } from '../../../bibliotecas/data'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import * as Yup from 'yup'
import moment from 'moment'

import {
  FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA
} from '../../../acoes/tipos'

export default function Formulario(props) {
  const {
    celulas,
    download,
    enfermeiros,
    filtrosIniciais,
    medicos,
    programas,
    limparFiltrosDoRelatorioDePacientesDoPrograma,
  } = props

  const limparFiltros = async resetForm => {
    await limparFiltrosDoRelatorioDePacientesDoPrograma()
    resetForm({})
  }

  return (
    <div className='formulario-de-filtros'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
            LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
            LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
            LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
            LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA]}>
            {({ processando }) => (
              <div className={`box-agenda ${processando && 'is-loading'} relatorios`}>
                <FormularioDoFormik
                  acao={download}
                  valoresIniciais={{
                    celula: filtrosIniciais.celula || '',
                    dataDeInicio: filtrosIniciais.dataDeInicio || '',
                    dataDeFim: filtrosIniciais.dataDeFim || '',
                    enfermeiroDeReferencia: filtrosIniciais.enfermeiroDeReferencia || '',
                    medicoDeReferencia: filtrosIniciais.medicoDeReferencia || '',
                    programa: filtrosIniciais.programa || ''
                  }}
                  esquemaDeValidacoes={Yup.object().shape({
                    dataDeInicio: Yup.string()
                      .nullable()
                      .required('Obrigatório')
                      .test(
                        "inicio_teste",
                        "Deve ser menor que o fim.",
                        function (value) {
                          const { dataDeFim } = this.parent;
                          return !value || dataIgualOuAnterior(value, dataDeFim);
                        }
                      ),
                    dataDeFim: Yup.string()
                      .nullable()
                      .required('Obrigatório')
                      .test(
                        "fim_teste",
                        "Deve ser maior que o início.",
                        function (value) {
                          const { dataDeInicio } = this.parent;
                          return !value || dataIgualOuAnterior(dataDeInicio, value);
                        }
                      ),
                    programa: Yup.string().required('Obrigatório')
                  })}
                >
                  {({ values, resetForm }) => (
                    <>
                      <fieldset>
                        <h2 className='form-title'>Pacientes do Programa</h2>
                        <r-grid columns-md={12} columns-lg={12}>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            <Select
                              nome='programa'
                              itens={programas}
                              titulo='Programa *'
                              tabIndex={2}
                              campoCodigo='identificador'
                              campoDescricao='nome'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            <DatePicker
                              nome='dataDeInicio'
                              tabIndex={3}
                              titulo='Início *'
                              type='text'
                              maxDate={new Date()}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            <DatePicker
                              nome='dataDeFim'
                              tabIndex={4}
                              titulo='Fim *'
                              type='text'
                              minDate={moment(values.dataDeInicio).toDate()}
                              maxDate={new Date()}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={2} span-lg={2}>
                            <Select
                              nome='celula'
                              tabIndex={5}
                              titulo='Célula'
                              itens={celulas}
                              campoCodigo='nome'
                              campoDescricao='nome'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={5} span-lg={5}>
                            <Select
                              nome='medicoDeReferencia'
                              itens={medicos}
                              tabIndex={6}
                              titulo='Médico(a) de Referência'
                              campoCodigo='nome'
                              campoDescricao='label'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={5} span-lg={5}>
                            <Select
                              nome='enfermeiroDeReferencia'
                              itens={enfermeiros}
                              tabIndex={7}
                              titulo='Enfermeiro(a) de Referência'
                              campoCodigo='nome'
                              campoDescricao='label'
                            />
                          </r-cell>
                        </r-grid>
                      </fieldset>
                      <div className='list-btn'>
                        <button type='button' onClick={() => limparFiltros(resetForm)} className='button --light'>Limpar</button>
                        <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA]}>
                          <button type='submit' className='button --primary'>Gerar</button>
                        </Permissao>
                      </div>
                    </>
                  )}
                </FormularioDoFormik>
              </div>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}