import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ORIENTACOES_DE_PAGINAS,
  LISTOU_ORIENTACOES_DE_PAGINAS,
  ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS,
} from './tipos'

const listouOrientacoesDePaginas = orientacoesDePaginas => ({
  type: LISTOU_ORIENTACOES_DE_PAGINAS,
  orientacoesDePaginas
})

const erroAoListarOrientacoesDePaginas = erro => ({
  type: ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS,
  erro
})

export const listarOrientacoesDePaginas = () => async dispatch => {
  dispatch({ type: LISTAR_ORIENTACOES_DE_PAGINAS })

  try {
    const resultado = await api.listarOrientacoesDePaginas()

    dispatch(listouOrientacoesDePaginas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOrientacoesDePaginas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os orientações de páginas.')))
  }
}