import {
  LISTOU_RESPOSTAS_DO_CASP16
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_RESPOSTAS_DO_CASP16: {

      return action.respostasDoCasp16
    }

    default: {
      return state
    }
  }
}