import React from 'react'

export default function Alerta({ metadados }) {
  const texto = metadados.texto ? metadados.texto : ''

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <div className='form-choice-alerta'>
        <div className='form-choice-alerta__item'>
          <i className='icon icon-attention-circled'></i>
        </div>
        <div className='form-choice-alerta__item'>{texto}</div>
      </div>
    </r-cell>
  )
}
