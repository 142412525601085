import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_INTENSIDADES_DAS_DORES,
  LISTOU_INTENSIDADES_DAS_DORES,
  ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES,
} from './tipos'

const listouIntensidadesDasDores = intensidadesDasDores => ({
  type: LISTOU_INTENSIDADES_DAS_DORES,
  intensidadesDasDores,
})

const erroAoListarIntensidadesDasDores = erro => ({
  type: ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES,
  erro,
})

export const listarIntensidadesDasDores = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_INTENSIDADES_DAS_DORES })

    try {
      const resultado = await api.listarIntensidadesDasDores()

      dispatch(listouIntensidadesDasDores(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarIntensidadesDasDores(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as intensidades das dores.')))
    }
  }

  acao.meta = {
    cache: LISTAR_INTENSIDADES_DAS_DORES
  }

  return acao
}