import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Formulario as Form, Select, Checkbox } from '../../formik/formulario'
import Spinner from '../../spinner'

import {
  fazerDownloadDoArquivoDeAtendimento,
} from '../../../acoes/prontuario/linha-do-tempo'

import {
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
} from '../../../acoes/tipos'

export default function Imprimir({ fechar, atendimento, orientacoesDePagina, tamanhosDePaginas, identificadorDoPaciente }) {
  const dispatch = useDispatch()

  const atendimentoAssinado = atendimento && atendimento.assinaturasDigitais.length > 0
  const [imprimindo, setImprimindo] = useState(false)

  const imprimirAtendimento = useCallback((formulario) => {
    (async () => {
      const retorno = await dispatch(fazerDownloadDoArquivoDeAtendimento(formulario))

      if (retorno) {
        const arquivo = new Blob([retorno], { type: 'application/pdf' });
        const url = URL.createObjectURL(arquivo);

        window.open(url);

        fechar()
      }
    })()
  }, [fechar, dispatch])

  useEffect(() => {
    if (atendimentoAssinado && !imprimindo) {
      setImprimindo(true)
      imprimirAtendimento({
        identificadorDoPaciente: atendimento.paciente.identificador,
        identificadorDoAtendimento: atendimento.identificador,
        tamanhoDaPagina: 'A4',
        orientacaoDaPagina: 'retrato',
        incluirAnexos: false,
        incluirImagens: false,
        umAtendimentoPorPagina: false
      })
    }
  }, [atendimento, atendimentoAssinado, imprimirAtendimento, imprimindo, setImprimindo])

  return (
    <Spinner operacoes={[FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO]}>
      <Form
        valoresIniciais={{
          identificadorDoPaciente: atendimento ? atendimento.paciente.identificador : identificadorDoPaciente,
          identificadorDoAtendimento: atendimento ? atendimento.identificador : '',
          tamanhoDaPagina: 'A4',
          orientacaoDaPagina: 'retrato',
          incluirAnexos: false,
          incluirImagens: false,
          umAtendimentoPorPagina: false
        }}
        reinicializar={true}
        acao={imprimirAtendimento}
        esquemaDeValidacoes={Yup.object().shape({
          tamanhoDaPagina: Yup.string().required('Obrigatório'),
          orientacaoDaPagina: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset>
          <h2 className='form-title'>{atendimento ? 'Impressão do Atendimento' : 'Impressão do Prontuário'}{atendimentoAssinado ? ' Assinado' : ''}</h2>
          {!atendimentoAssinado &&
            <>
              <r-grid columns-md={6} columns-lg={12}>
                <r-cell span={4} span-md={6} span-lg={6}>
                  <Select
                    nome='tamanhoDaPagina'
                    tabIndex={1}
                    titulo='Tamanho da Página *'
                    itens={tamanhosDePaginas}
                    campoCodigo='codigo'
                    campoDescricao='nome'
                  />
                </r-cell>
                <r-cell span={4} span-md={6} span-lg={6}>
                  <Select
                    nome='orientacaoDaPagina'
                    tabIndex={2}
                    titulo='Orientação da Página *'
                    itens={orientacoesDePagina}
                    campoCodigo='codigo'
                    campoDescricao='nome'
                  />
                </r-cell>
                <r-cell span={4} span-md='row' span-lg='row'>
                  <Checkbox
                    nome='incluirImagens'
                    tabIndex={3}
                    titulo='Incluir imagens'
                  />
                </r-cell>
                <r-cell span={4} span-md='row' span-lg='row'>
                  <Checkbox
                    nome='incluirAnexos'
                    tabIndex={4}
                    titulo='Incluir anexos'
                  />
                </r-cell>
                {!atendimento &&
                  <r-cell span={4} span-md='row' span-lg='row'>
                    <Checkbox
                      nome='umAtendimentoPorPagina'
                      tabIndex={5}
                      titulo='Um atendimento por página'
                    />
                  </r-cell>
                }
              </r-grid>
              <div className='list-btn mt-4'>
                <button type='button' onClick={() => fechar()} className='button --light'>Sair</button>
                <button type='submit' className='button --primary'>Imprimir</button>
              </div>
            </>
          }
        </fieldset>
      </Form>
    </Spinner>
  )
}