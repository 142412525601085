import cpf from 'node-cpf'

export const zerosAEsquerda = (numero, tamanho) => {
  var valor = numero + ""

  while (valor.length < tamanho) {
    valor = '0' + valor
  }

  return valor;
}

export const formatarCpf = (valor) => {
  return cpf.mask(zerosAEsquerda(valor, 11))
}

export const formatarCep = numero => {
  var valor = numero + ""
  if (valor.indexOf('-') >= 0) {
    return numero
  }
  var cepFormatado = zerosAEsquerda(numero, 8)
  cepFormatado = cepFormatado.substring(0, 5) + '-' + cepFormatado.substr(5, 3)
  return cepFormatado
}
