import React, { useState, useMemo } from 'react'
import Modal from 'react-modal'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { Select } from '../../formik/formulario'
import Spinner from '../../spinner'
import * as Yup from 'yup'
import { INICIAR_ATENDIMENTO, LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO } from '../../../acoes/tipos'
import { useMemed } from '../../../configuracoes/memed'

export default function SelecionadorDeTipoDeAtendimento(props) {
  const { iniciarAtendimento, fecharFinalizarAtendimento, identificadorDoAgendamento, paciente: { ativo } } = props
  const [exibirModal, setExibirModal] = useState(false)
  const [tipo, setTipo] = useState('')
  const [tipoDeAtendimento, setTipoDeAtendimento] = useState('')
  const { logOut } = useMemed()
  const tiposDeAtendimento = useMemo(() => converterObjetoParaArray(props.tiposDeAtendimento), [props.tiposDeAtendimento])

  const salvar = async ({ tipo }) => {
    fecharFinalizarAtendimento()
    const tipoDeAtendimento = tiposDeAtendimento.find(x => x.nome === tipo)
    await iniciarAtendimento(identificadorDoAgendamento === undefined ?
      { tipo: tipoDeAtendimento.nome, versao: tipoDeAtendimento.ultimaVersao } :
      { tipo: tipoDeAtendimento.nome, versao: tipoDeAtendimento.ultimaVersao, agendamento: identificadorDoAgendamento })
  }

  const iniciarAtendimentoDoFormulario = () => {
    logOut()
    if (tiposDeAtendimento.length === 1) {
      setTipo(tiposDeAtendimento[0].nome)
      salvar({ tipo: tiposDeAtendimento[0].nome })
    } else {
      setExibirModal(true)
    }
  }

  const tipoDeAtendimentoSelecionado = tiposDeAtendimento.find(x => x.nome === tipoDeAtendimento)

  return (
    <Spinner operacoes={[INICIAR_ATENDIMENTO]}>
      {({ processando }) => (
        <>
          <Modal
            isOpen={exibirModal}
            className='modal-selecionar-tipo-de-atendimento'
          >
            <Spinner operacoes={[LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO, INICIAR_ATENDIMENTO]}>
              <FormularioDoFormik
                reinicializar={true}
                valoresIniciais={{
                  tipo: tipo
                }}
                acao={salvar}
                esquemaDeValidacoes={Yup.object().shape({
                  tipo: Yup.string().required('Obrigatório')
                })}
              >
                <fieldset>
                  <h2 className='form-title'>Selecionar Tipo de Atendimento </h2>
                  <Select
                    nome='tipo'
                    campoCodigo='nome'
                    campoDescricao='nome'
                    itens={tiposDeAtendimento}
                    titulo='Tipo de Atendimento *'
                    onChange={setTipoDeAtendimento}
                  />
                  {tipoDeAtendimentoSelecionado && tipoDeAtendimentoSelecionado.metaDados && tipoDeAtendimentoSelecionado.metaDados.descricao &&
                    <div className='py-1'>
                      <r-cell span-md='row' span-lg='row'>
                        <div className='form-choice-info'>
                          <div className='form-choice-info__item'>
                            <i className='icon icon-attention-circled'></i>
                          </div>
                          <div className='form-choice-info__item'>
                            {tipoDeAtendimentoSelecionado.metaDados.descricao}
                          </div>
                        </div>
                      </r-cell>
                    </div>
                  }
                  <div className='list-btn'>
                    <button className='button --light' onClick={() => setExibirModal(false)}>Cancelar</button>
                    <button className='button --primary' type='submit'>Iniciar</button>
                  </div>
                </fieldset>
              </FormularioDoFormik>
            </Spinner>
          </Modal>
          <button disabled={!ativo} className={`button --primary  ${tiposDeAtendimento.length === 1 && processando && 'is-loading'}`} onClick={iniciarAtendimentoDoFormulario}>Iniciar Atendimento</button>
        </>
      )}
    </Spinner>
  )
}