import api from '../../api'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE
} from '../tipos'

const listouProximosAgendamentosMedicosDoPaciente = () => ({ type: LISTOU_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE })

const erroAoListarProximosAgendamentosMedicosDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  erro,
})

export const listarProximosAgendamentosMedicosDoPaciente = paciente => async dispatch => {
  dispatch({ type: LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE })

  try {
    const resultado = await api.listarProximosAgendamentosMedicosDoPaciente(paciente)

    dispatch(listouProximosAgendamentosMedicosDoPaciente())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados.dados
  } catch (erro) {
    dispatch(erroAoListarProximosAgendamentosMedicosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi('Não foi possível listar os próximos agendamentos médicos do paciente.'))

    return false
  }
}