import api from '../api-narcissus'

const listarTiposDeAtendimentoParaONps = () => api.get('/tipos-de-atendimento?tamanhoDaPagina=10000&ordem=nome')
const habilitarTiposDeAtendimentoParaONps = tipos => api.put('/tipos-de-atendimento/habilitados', tipos)
const desabilitarTipoDeAtendimentoParaONps = tipo => api.put('/tipos-de-atendimento/desabilitado', { tipo: tipo })

export default {
  listarTiposDeAtendimentoParaONps,
  habilitarTiposDeAtendimentoParaONps,
  desabilitarTipoDeAtendimentoParaONps
}