import React from 'react'
import {MultiplaEscolha} from '../../../../formik/formulario'
import {formatarDataParaFormatoLocal} from '../../../../../bibliotecas/data'

const renderizarTitulo = valor => valor.descricao

export default function Resumo({ valores, fechar }) {
  const {
    data,
    areasDeLocomocao,
    iluminacao,
    quartoDeDormir,
    banheiro,
    cozinha,
    escada
  } = valores

  if (!data) return null

  const opcoes = [{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]

  return (
    <r-grid columns-md={6} columns-lg={12} class='form form-group'>
      <r-cell span={4} span-md={6} span-lg={12}>
        <fieldset>
          <h2 className='form-title'>Resumo da Escala Ambiental de Risco de Quedas</h2>
          <Paciente paciente={valores.paciente} />
          <Data data={data} />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-escala-ambiental'>
            <Cabecalho />
            <AreasDeLocomocao valor={areasDeLocomocao} opcoes={opcoes} />
            <Iluminacao valor={iluminacao} opcoes={opcoes} />
            <QuartoDeDormir valor={quartoDeDormir} opcoes={opcoes} />
            <Banheiro valor={banheiro} opcoes={opcoes} />
            <Cozinha valor={cozinha} opcoes={opcoes} />
            <Escada valor={escada} opcoes={opcoes} />
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
          </div>
        </fieldset>
      </r-cell>
    </r-grid>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-escala-ambiental data-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>ESCALA AMBIENTAL DE RISCO DE QUEDAS</strong>
      </div>
    </r-cell>
  )
}

function AreasDeLocomocao({ valor = {}, opcoes }) {
  const {
    asAreasDeLocomocaoSaoDesimpedidas,
    existemBarrasDeApoio,
    osRevestimentosSaoUniformesEOsTapetesSaoBemFixos,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ÁREAS DE LOCOMOÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.areasDeLocomocao.asAreasDeLocomocaoSaoDesimpedidas'
          opcoes={opcoes}
          pergunta='1. As áreas de locomoção são desimpedidas?'
          valor={asAreasDeLocomocaoSaoDesimpedidas}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.areasDeLocomocao.existemBarrasDeApoio'
          opcoes={opcoes}
          pergunta='2. Existem barras de apoio?'
          valor={existemBarrasDeApoio}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.areasDeLocomocao.osRevestimentosSaoUniformesEOsTapetesSaoBemFixos'
          opcoes={opcoes}
          pergunta='3. Os revestimentos são uniformes e os tapetes são bem fixos?'
          valor={osRevestimentosSaoUniformesEOsTapetesSaoBemFixos}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}

function Iluminacao({ valor = {}, opcoes }) {
  const {
    existeIluminacaoSuficienteParaClarearTodoOInteriorIncluindoDegraus,
    existemInterruptoresAcessiveisNaEntradaDosComodos,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ILUMINAÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.iluminacao.existeIluminacaoSuficienteParaClarearTodoOInteriorIncluindoDegraus'
          opcoes={opcoes}
          pergunta='4. Existe iluminação suficiente para clarear todo interior de cada cômodo, incluindo degraus?'
          valor={existeIluminacaoSuficienteParaClarearTodoOInteriorIncluindoDegraus}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.iluminacao.existemInterruptoresAcessiveisNaEntradaDosComodos'
          opcoes={opcoes}
          pergunta='5. Existem interruptores acessíveis na entrada dos cômodos?'
          valor={existemInterruptoresAcessiveisNaEntradaDosComodos}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}

function QuartoDeDormir({ valor = {}, opcoes }) {
  const {
    oGuardaRoupaPossuiCabidesFacilmenteAcessiveis,
    existeCadeiraQuePermiteOPacienteSeAssentarParaSeVestir,
    aCamaPossuiBoaAltura,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>QUARTO DE DORMIR</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.quartoDeDormir.oGuardaRoupaPossuiCabidesFacilmenteAcessiveis'
          opcoes={opcoes}
          pergunta='6. O guarda-roupa possui cabides facilmente acessíveis?'
          valor={oGuardaRoupaPossuiCabidesFacilmenteAcessiveis}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.quartoDeDormir.existeCadeiraQuePermiteOPacienteSeAssentarParaSeVestir'
          opcoes={opcoes}
          pergunta='7. Existe cadeira que permite o paciente se assentar para se vestir?'
          valor={existeCadeiraQuePermiteOPacienteSeAssentarParaSeVestir}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.quartoDeDormir.aCamaPossuiBoaAltura'
          opcoes={opcoes}
          pergunta='8. A cama possui boa altura (45cm)?'
          valor={aCamaPossuiBoaAltura}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}

function Banheiro({ valor = {}, opcoes }) {
  const {
    aAreaDoChuveiroPossuiAntiderrapante,
    oBoxPossuiAberturaFacilOuSePossuiCortinaElaEstahBemFirme,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>BANHEIRO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.banheiro.aAreaDoChuveiroPossuiAntiderrapante'
          opcoes={opcoes}
          pergunta='9. A área do chuveiro possui antiderrapante?'
          valor={aAreaDoChuveiroPossuiAntiderrapante}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.banheiro.oBoxPossuiAberturaFacilOuSePossuiCortinaElaEstahBemFirme'
          opcoes={opcoes}
          pergunta='10. O box possui abertura fácil ou, se possui cortina, ela está bem firme?'
          valor={oBoxPossuiAberturaFacilOuSePossuiCortinaElaEstahBemFirme}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}

function Cozinha({ valor = {}, opcoes }) {
  const {
    osArmariosSaoBaixosSemNecessidadeDoUsoDeEscada,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>COZINHA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='escalaAmbientalDeRiscoDeQuedas.cozinha.osArmariosSaoBaixosSemNecessidadeDoUsoDeEscada'
          opcoes={opcoes}
          pergunta='11. Os armário são baixos, sem necessidade do uso de escada?'
          valor={osArmariosSaoBaixosSemNecessidadeDoUsoDeEscada}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}

function Escada({ valor = {}, opcoes }) {
  const {
    existeRevestimentoAntiderrapanteComMarcacaoAmarelaNoPrimeiroEUltimoDegrau,
    existeCorrimaoBilateralSolidoEProlongadoAlemDoPrimeiroEUltimoDegrau,
    osDegrausSaoUniformes
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ESCADA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.escada.existeRevestimentoAntiderrapanteComMarcacaoAmarelaNoPrimeiroEUltimoDegrau'
          opcoes={opcoes}
          pergunta='12. Existe revestimento antiderrapante, com marcação do primeiro e do último degrau com faixa amarela?'
          valor={existeRevestimentoAntiderrapanteComMarcacaoAmarelaNoPrimeiroEUltimoDegrau}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.escada.existeCorrimaoBilateralSolidoEProlongadoAlemDoPrimeiroEUltimoDegrau'
          opcoes={opcoes}
          pergunta='13. Existe corrimão bilateral, sólido e que se prolonga além do primeiro e do último degrau?'
          valor={existeCorrimaoBilateralSolidoEProlongadoAlemDoPrimeiroEUltimoDegrau}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escalaAmbientalDeRiscoDeQuedas.escada.osDegrausSaoUniformes'
          opcoes={opcoes}
          pergunta='14. Os degraus são uniformes (com altura dos espelhos e profundidade constantes)?'
          valor={osDegrausSaoUniformes}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
    </r-cell>
  )
}