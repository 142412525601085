import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Coluna, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Modal from 'react-modal'
import Formulario from './formulario'
import Resumo from './resumo'
import { v4 as uuidv4 } from 'uuid';

import {
  LISTAR_ESCALAS_DE_FRAGILIDADE,
  LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE
} from '../../../../../acoes/tipos'

export default function QuestionarioDoIdoso({ identificadorDoPaciente, questionariosDoIdosoDoPaciente }) {
  const {
    cognicoesDosPacientes,
    comorbidadesCardiovasculares,
    comorbidadesEndocrinas,
    comorbidadesGastrointestinais,
    comorbidadesNeurologicas,
    comorbidadesOncologicas,
    comorbidadesOsteomusculares,
    comorbidadesPsiquiatricas,
    comorbidadesPulmonares,
    comorbidadesRenais,
    comorbidadesReumatologicas,
    comQuemOsPacientesMoram,
    condicoesDosPacientes,
    dispositivosEmUsoDosPacientes,
    escalasDeFragilidade,
    estadosDeDeambulacao,
    quedasNosUltimosDozeMeses,
    usosDeMedicamentosPelosPacientes,
    vacinasEmDia,
    adicionarQuestionarioDoIdosoDoPaciente,
    alterarQuestionarioDoIdosoDoPaciente,
    removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente,
    listarCognicoesDosPacientes,
    listarComQuemOsPacientesMoram,
    listarComorbidadesCardiovasculares,
    listarComorbidadesEndocrinas,
    listarComorbidadesGastrointestinais,
    listarComorbidadesNeurologicas,
    listarComorbidadesOncologicas,
    listarComorbidadesOsteomusculares,
    listarComorbidadesPsiquiatricas,
    listarComorbidadesPulmonares,
    listarComorbidadesRenais,
    listarComorbidadesReumatologicas,
    listarCondicoesDosPacientes,
    listarDispositivosEmUsoDosPacientes,
    listarEstadosDeDeambulacao,
    listarEscalasDeFragilidade,
    listarQuedasNosUltimosDozeMeses,
    listarQuestionariosDoIdosoDoPaciente,
    listarUsosDeMedicamentosPelosPacientes,
    listarVacinasEmDia,
    registrarEnvioDoQuestionarioDoIdosoDoPaciente,
    removerQuestionarioDoIdosoDoPaciente
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [exibirRegistrarEnvio, setExibirRegistrarEnvio] = useState(false)
  const [exibirRemoverRegistroDeEnvio, setExibirRemoverRegistroDeEnvio] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_QUESTIONARIO_DO_IDOSO])
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_QUESTIONARIO_DO_IDOSO])
  const podeRegistrarEnvio = usePossuiAsPermissoes([permissoes.REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO])
  const podeRemoverRegistroDeEnvio = usePossuiAsPermissoes([permissoes.REMOVER_REGISTRO_DO_ENVIO_DO_QUESTIONARIO_DO_IDOSO])

  useEffect(() => {
    if (questionariosDoIdosoDoPaciente === undefined) {
      listarQuestionariosDoIdosoDoPaciente(identificadorDoPaciente)
    }
  }, [listarQuestionariosDoIdosoDoPaciente, identificadorDoPaciente, questionariosDoIdosoDoPaciente])

  useEffect(() => {
    listarCognicoesDosPacientes()
    listarComQuemOsPacientesMoram()
    listarComorbidadesCardiovasculares()
    listarComorbidadesEndocrinas()
    listarComorbidadesGastrointestinais()
    listarComorbidadesNeurologicas()
    listarComorbidadesOncologicas()
    listarComorbidadesOsteomusculares()
    listarComorbidadesPsiquiatricas()
    listarComorbidadesPulmonares()
    listarComorbidadesRenais()
    listarComorbidadesReumatologicas()
    listarCondicoesDosPacientes()
    listarDispositivosEmUsoDosPacientes()
    listarEstadosDeDeambulacao()
    listarEscalasDeFragilidade()
    listarQuedasNosUltimosDozeMeses()
    listarUsosDeMedicamentosPelosPacientes()
    listarVacinasEmDia()
  }, [
    listarCognicoesDosPacientes,
    listarComQuemOsPacientesMoram,
    listarComorbidadesCardiovasculares,
    listarComorbidadesEndocrinas,
    listarComorbidadesGastrointestinais,
    listarComorbidadesNeurologicas,
    listarComorbidadesOncologicas,
    listarComorbidadesOsteomusculares,
    listarComorbidadesPsiquiatricas,
    listarComorbidadesPulmonares,
    listarComorbidadesRenais,
    listarComorbidadesReumatologicas,
    listarCondicoesDosPacientes,
    listarDispositivosEmUsoDosPacientes,
    listarEstadosDeDeambulacao,
    listarEscalasDeFragilidade,
    listarQuedasNosUltimosDozeMeses,
    listarUsosDeMedicamentosPelosPacientes,
    listarVacinasEmDia
  ])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirRegistrarEnvio(item) {
    setSelecionado(item)
    setExibirRegistrarEnvio(true)
  }

  function fecharRegistrarEnvio() {
    setExibirRegistrarEnvio(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirRemoverRegistroDeEnvio(item) {
    setSelecionado(item)
    setExibirRemoverRegistroDeEnvio(true)
  }

  function fecharRemoverRegistroDeEnvio() {
    setExibirRemoverRegistroDeEnvio(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarQuestionarioDoIdosoDoPaciente(identificadorDoPaciente, item) : await adicionarQuestionarioDoIdosoDoPaciente(identificadorDoPaciente, item)
  }

  if (questionariosDoIdosoDoPaciente === undefined) {
    return (
      <div className="table-items-expanded is-loading abas-qgcc"></div>
    )
  }
  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className="modal"
        contentLabel="Modal do formulário do Questionário do Idoso"
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicial(selecionado)}
          cognicoesDosPacientes={cognicoesDosPacientes}
          comorbidadesCardiovasculares={comorbidadesCardiovasculares}
          comorbidadesEndocrinas={comorbidadesEndocrinas}
          comorbidadesGastrointestinais={comorbidadesGastrointestinais}
          comorbidadesNeurologicas={comorbidadesNeurologicas}
          comorbidadesOncologicas={comorbidadesOncologicas}
          comorbidadesOsteomusculares={comorbidadesOsteomusculares}
          comorbidadesPsiquiatricas={comorbidadesPsiquiatricas}
          comorbidadesPulmonares={comorbidadesPulmonares}
          comorbidadesRenais={comorbidadesRenais}
          comorbidadesReumatologicas={comorbidadesReumatologicas}
          comQuemOsPacientesMoram={comQuemOsPacientesMoram}
          condicoesDosPacientes={condicoesDosPacientes}
          dispositivosEmUsoDosPacientes={dispositivosEmUsoDosPacientes}
          escalasDeFragilidade={escalasDeFragilidade}
          estadosDeDeambulacao={estadosDeDeambulacao}
          quedasNosUltimosDozeMeses={quedasNosUltimosDozeMeses}
          usosDeMedicamentosPelosPacientes={usosDeMedicamentosPelosPacientes}
          vacinasEmDia={vacinasEmDia}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className="modal"
        contentLabel="Modal do resumo do Questionário do Idoso"
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicial(selecionado)}
          cognicoesDosPacientes={cognicoesDosPacientes}
          comorbidadesCardiovasculares={comorbidadesCardiovasculares}
          comorbidadesEndocrinas={comorbidadesEndocrinas}
          comorbidadesGastrointestinais={comorbidadesGastrointestinais}
          comorbidadesNeurologicas={comorbidadesNeurologicas}
          comorbidadesOncologicas={comorbidadesOncologicas}
          comorbidadesOsteomusculares={comorbidadesOsteomusculares}
          comorbidadesPsiquiatricas={comorbidadesPsiquiatricas}
          comorbidadesPulmonares={comorbidadesPulmonares}
          comorbidadesRenais={comorbidadesRenais}
          comorbidadesReumatologicas={comorbidadesReumatologicas}
          comQuemOsPacientesMoram={comQuemOsPacientesMoram}
          condicoesDosPacientes={condicoesDosPacientes}
          dispositivosEmUsoDosPacientes={dispositivosEmUsoDosPacientes}
          escalasDeFragilidade={escalasDeFragilidade}
          estadosDeDeambulacao={estadosDeDeambulacao}
          quedasNosUltimosDozeMeses={quedasNosUltimosDozeMeses}
          usosDeMedicamentosPelosPacientes={usosDeMedicamentosPelosPacientes}
          vacinasEmDia={vacinasEmDia}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel="Modal para remoção do Questionário do Idoso"
        className="modal-vacinas"
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerQuestionarioDoIdosoDoPaciente(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirRegistrarEnvio}
        contentLabel="Modal para registrar envio do Questionário do Idoso"
        className="modal-vacinas"
      >
        <RegistrarEnvio
          selecionada={selecionado}
          registrar={valor => registrarEnvioDoQuestionarioDoIdosoDoPaciente(identificadorDoPaciente, valor)}
          fechar={fecharRegistrarEnvio}
        />
      </Modal>
      <Modal
        isOpen={exibirRemoverRegistroDeEnvio}
        contentLabel="Modal para remover registro do envio do Questionário do Idoso"
        className="modal-vacinas"
      >
        <RemoverRegistroDeEnvio
          selecionada={selecionado}
          remover={valor => removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente(identificadorDoPaciente, valor)}
          fechar={fecharRemoverRegistroDeEnvio}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE, LISTAR_ESCALAS_DE_FRAGILIDADE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key="remover" permissoes={[permissoes.REMOVER_QUESTIONARIO_DO_IDOSO]}>
                    <button
                      className="table-items__bt --remove"
                      title="Remover"
                      onClick={() => abrirExcluir(item)}
                      type="button"
                      key={`remove_${item.identificador}`}
                    >
                      <i className="icon icon-remove"></i>
                    </button>
                  </Permissao>
                ,
                item => (
                  <button
                    className="table-items__bt --edit"
                    title="Ver resumo"
                    onClick={() => abrirResumo(item)}
                    type="button"
                    key={`questionario_${item.identificador}`}
                  >
                    <i className="icon icon-doc-text"></i>
                  </button>
                ),
                item => {
                  return (!item.enviadoPor && podeRegistrarEnvio && (
                    <button
                      className="table-items__bt --edit"
                      title="Registrar Envio"
                      type="button"
                      key="registrar-envio"
                      onClick={() => abrirRegistrarEnvio(item)}
                    >
                      <i className="icon icon-checked"></i>
                    </button>
                  ))
                },
                item => {
                  return (item.enviadoPor && podeRemoverRegistroDeEnvio && (
                    <button
                      className="table-items__bt --remove"
                      title="Remover registro de envio"
                      type="button"
                      key="remover-registro-de-envio"
                      onClick={() => abrirRemoverRegistroDeEnvio(item)}
                    >
                      <i className="icon icon-block estilo-block"></i>
                    </button>
                  ))
                },
                item =>
                  podeAlterar && (
                    <div key="editar">
                      <button
                        type="button"
                        onClick={() => abrirFormulario(item)}
                        className="table-items__bt --edit"
                        title="Editar"
                      >
                        <i className="icon icon-pencil"></i>
                      </button>
                    </div>
                  ),
                item =>
                  <div key="informacao">
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className="table-items__bt --edit"
                      type="button"
                    >
                      <i className="icon icon-info"></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place="top"
                      effect="solid"
                      type="info"
                      key={item.identificador}
                      className="tamanho-tooltip-diagnostico lh-spam"
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}>
                        <strong>Última alteração</strong></div>
                      <span>Profissional: {item.profissional.nome}</span> <br/>
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.horario)}</span> <br/>
                      {item.enviadoPor &&
                        <>
                          <div
                            style={{
                              marginBottom: 8,
                              borderBottomStyle: 'solid',
                              borderBottomWidth: '1px',
                              borderBottomColor: 'rgb(232, 232, 232)'
                            }}>
                            <strong>Dados do registro de envio</strong></div>
                          <span>Profissional: {item.enviadoPor.nome}</span> <br/>
                          <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.enviadoEm)}</span> <br/>
                        </>
                      }
                    </ReactTooltip>
                  </div>
                ,
              ]}
              chave="identificador"
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={questionariosDoIdosoDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia="Não existem registros do Questionário do Idoso."
              classNameAdicionar="col-xs-6 col-lg-6"
            >
              <Coluna
                className="col-xs-6 col-lg-3"
                nome="Data"
                campoDeDados="data"
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.horario)}
              />
              <Coluna
                className="col-lg-3"
                nome="Escala de Fragilidade"
                renderizar={item => escalasDeFragilidade?.find(x => x.codigo === item.escalaDeFragilidade)?.nome}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluir = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className="form-group">
                <h2>{`Tem certeza que deseja remover o Questionário do Idoso?`}</h2>
                <div className="form-choice-alerta my-1">
                  <div className="form-choice-alerta__item">
                    <i className="icon icon-attention-circled"></i>
                  </div>
                  <p className="form-choice-alerta__item">
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className="list-btn">
                  <button className="button --light" type="button" onClick={fechar}>Sair</button>
                  <button className="button --danger" type="button" onClick={excluir}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function RegistrarEnvio({ selecionada, registrar, fechar }) {
  const registrarEnvio = async () => {
    const registrou = await registrar(selecionada.identificador)
    if (registrou) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className="form-group">
                <h2>{`Tem certeza que deseja registrar o envio do Questionário do Idoso?`}</h2>
                <div className="list-btn">
                  <button className="button --light" type="button" onClick={fechar}>Sair</button>
                  <button className="button --primary" type="button" onClick={registrarEnvio}>Registrar</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function RemoverRegistroDeEnvio({ selecionada, remover, fechar }) {
  const removerRegistroDeEnvio = async () => {
    const removeu = await remover(selecionada.identificador)
    if (removeu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className="form-group">
                <h2>{`Tem certeza que deseja remover o registro do envio do Questionário do Idoso realizado por ${selecionada?.enviadoPor?.nome} em ${formatarDataParaFormatoLocal(selecionada.enviadoEm)}?`}</h2>
                <div className="list-btn">
                  <button className="button --light" type="button" onClick={fechar}>Sair</button>
                  <button className="button --danger" type="button" onClick={removerRegistroDeEnvio}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicial = selecionado => {
  if (selecionado) return selecionado

  return {
    identificador: uuidv4(),
    escalaDeFragilidade: undefined,
    moraComQuem: undefined,
    vacinasEmDia: [],
    cognicao: undefined,
    condicoes: [],
    deambulacao: undefined,
    quedasNosUltimosDozeMeses: undefined,
    dispositivosEmUso: [],
    estadoTerminal: undefined,
    necessidadeDeControleDeSintomas: undefined,
    comorbidadesCardiovasculares: [],
    comorbidadesPulmonares: [],
    comorbidadesNeurologicas: [],
    comorbidadesPsiquiatricas: [],
    comorbidadesRenais: [],
    comorbidadesEndocrinas: [],
    comorbidadesReumatologicas: [],
    comorbidadesGastrointestinais: [],
    comorbidadesOncologicas: [],
    comorbidadesOsteomusculares: [],
  }
}