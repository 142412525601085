import React, { useEffect } from 'react'
import logoIcone from '../../../design/img/logomarca_icone.png'
import profile from '../../../design/img/profile.png'
import { b64toBlob } from '../../../bibliotecas/imagem'

export const AutenticacaoPassoDois = ({ children, email }) => {
  let fotoUsuarioStorage = localStorage.getItem(`foto_usuario_${email}`)

  if (fotoUsuarioStorage)
    fotoUsuarioStorage = window.URL.createObjectURL(b64toBlob(fotoUsuarioStorage))


  return (
    <div className='escolher-unidade'>
      <div className='escolher-unidade-user'>
        <img className='escolher-unidade-user-avatar' src={fotoUsuarioStorage || profile} alt='Usuário' />
      </div>
      <div className='escolher-unidade-user'>
        <p className='escolher-unidade-title'>Escolha a empresa/unidade para continuar</p>
      </div>
      {children}
    </div>
  )
}

export const ListaDeUnidades = (props) => {
  const {
    selecionar,
    selecionada,
    unidades = [],
    voltar,
    recuperarFotoDaEmpresaPorIdentificadorPublico,
    removerImagemDaMemoriaPorIdentificador,
    logotipos
  } = props

  useEffect(() => {
    unidades.filter(x => x.empresa.logotipo).forEach(x => {
      recuperarFotoDaEmpresaPorIdentificadorPublico(x.empresa.identificadorPublico)
    })

    return () => unidades.filter(x => x.empresa.logotipo).map(x => removerImagemDaMemoriaPorIdentificador(x.empresa.identificadorPublico))
  }, [unidades, recuperarFotoDaEmpresaPorIdentificadorPublico, removerImagemDaMemoriaPorIdentificador])

  const buscaLogotipo = unidade => {
    if (unidade.empresa.logotipo) {

      const logo = logotipos[unidade.empresa.identificadorPublico]
      if (logo) return logo
    }

    return logoIcone
  }

  return (
    <>
      <div className='list-unidades'>
        {unidades.map(unidade => (
          <div
            key={unidade.identificador}
            className={`list-unidades-line ${(selecionada && unidade.identificador === selecionada.identificador) && 'loading'}`}
            onClick={() => selecionar(unidade)}
          >
            <div className='list-unidades-photo'>
              <img src={buscaLogotipo(unidade)} alt='Logotipo da empresa' />
            </div>
            <div className='list-unidades-text'>
              <p className='list-unidades-title'>{unidade.empresa.nome}</p>
              <p className='list-unidades-subtitle'>{unidade.nome}</p>
            </div>
            <button className={`button ${(selecionada && unidade.identificador === selecionada.identificador) && 'is-loading'}`}></button>
          </div>
        ))}
      </div>
      <div className='list-btn'>
        <button type='button' onClick={voltar} className='button --light'>Voltar</button>
      </div>
    </>
  )
}