import React from 'react'
import * as Yup from 'yup'
import { Formulario as FormularioDoFormik, Input } from '../../formik/formulario'
import { CANCELAR_ATENDIMENTO } from '../../../acoes/tipos'
import Spinner from '../../spinner'

export default function Cancelamento(props) {
  const cancelarAtendimento = async dados => {
    await props.cancelar(dados)
  }

  return (
    <>
      <Spinner operacoes={[CANCELAR_ATENDIMENTO]}>
        <FormularioDoFormik
          valoresIniciais={{
            status: 'cancelado_antes_de_finalizar',
            observacoes: ''
          }}
          esquemaDeValidacoes={Yup.object().shape({
            observacoes: Yup.string().required('Obrigatório.'),
          })}
          acao={cancelarAtendimento}
        >
          <fieldset>
            <h2 className='form-title'>Cancelar atendimento </h2>
            <Input
              as='textarea'
              tabIndex={2}
              nome='observacoes'
              titulo='Motivo do cancelamento *'
            />
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => props.fechar()}>Fechar</button>
              <button type='submit' className='button --danger'>Cancelar</button>
            </div>
          </fieldset>
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}