import React from 'react'
import Spinner from '../../../spinner'
import SpinnerDaTarefa from '../spinner-da-tarefa'
import { Formulario as FormularioDoFormik, InputSugestao } from '../../../formik/formulario'
import { ALTERAR_COMENTARIO_DA_TAREFA, REMOVER_COMENTARIO_DA_TAREFA } from '../../../../acoes/tipos'

export default function Edicao({ item, alterar, fechar }) {
  const alterarComentario = async dados => {
    const alterou = await alterar(dados)
    if (alterou) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ALTERAR_COMENTARIO_DA_TAREFA, REMOVER_COMENTARIO_DA_TAREFA]}>
      {({ processando }) =>
        <FormularioDoFormik
          valoresIniciais={{
            identificador: item.identificador,
            texto: item.texto
          }}
          acao={alterarComentario}
        >
          <div className='table-items-expanded__box-value'>
            <InputSugestao
              nome='texto'
              minHeight={100}
              fontFamily='inherit'
            />
            {!processando &&
              <div className='list-btn-tasks'>
                <button onClick={() => fechar()} className='button button-task-comments-light'>Cancelar</button>
                <button type='submit' className='button button-task-comments'>Salvar</button>
              </div>
            }
            <SpinnerDaTarefa processando={processando} />
          </div>
        </FormularioDoFormik>
      }
    </Spinner>
  )
}