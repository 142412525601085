import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import FileSaver from 'file-saver'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import arquivoPdf from '../../../../design/img/pdf-file.svg'
import arquivo from '../../../../design/img/file.svg'
import Formulario from './formulario'
import { LISTAR_INTERNACOES_DO_PACIENTE, REMOVER_INTERNACAO_DO_PACIENTE } from '../../../../acoes/tipos'

const renderizarStatus = status => {
  switch (status) {
    case 'Alta': {
      return '#1ABC9C'
    }
    case 'Internado': {
      return '#F1C40F'
    }
    case 'Encaminhado para atendimento domiciliar': {
      return '#f277c8'
    }
    case 'Informado pela operadora': {
      return '#555555'
    }
    default: {
      return '#cc0a11'
    }
  }
}

export default function Internacoes({ identificadorDoPaciente, internacoesDoPaciente }) {
  const {
    arquivosDaInternacao,
    motivosDeInternacao,
    estabelecimentosDeSaude,
    statusDeInternacao,
    tiposDeInternacao,
    fazerDownloadDoSumarioDeAltaDaInternacao,
    adicionarSumarioDeAltaDaInternacao,
    listarStatusDeInternacao,
    listarMotivosDeInternacao,
    listarTiposDeInternacao,
    listarEstabelecimentosDeSaude,
    listarInternacoesDoPaciente,
    adicionarInternacao,
    alterarInternacao,
    removerInternacao
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const [exibirSumarioDeAlta, setExibirSumarioDeAlta] = useState(false)
  const [sumarioDeAltaEscolhido, setSumarioDeAltaEscolhido] = useState(false)
  const [estabelecimentos, setEstabelecimentos] = useState([])
  const [motivos, setMotivos] = useState([])

  const referenciaDoInfo = useRef(null)

  const podeListarStatus = usePossuiAsPermissoes([permissoes.LISTAR_STATUS_DE_INTERNACOES])
  const podeListarEstabelecimentosDeSaude = usePossuiAsPermissoes([permissoes.LISTAR_ESTABELECIMENTOS_DE_SAUDE])
  const podeListarMotivos = usePossuiAsPermissoes([permissoes.LISTAR_MOTIVOS_DE_INTERNACAO])
  const podeListarTiposDeInternacao = usePossuiAsPermissoes([permissoes.LISTAR_TIPOS_DE_INTERNACAO])

  const podeListarParametros = podeListarStatus && podeListarEstabelecimentosDeSaude && podeListarMotivos && podeListarTiposDeInternacao

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_INTERNACAO_DO_PACIENTE])
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_INTERNACAO_DO_PACIENTE])

  useCliqueForaDoElemento(referenciaDoInfo, () => setExibirTooltipInfo(false))

  useEffect(() => {
    if (internacoesDoPaciente === undefined) {
      listarInternacoesDoPaciente(identificadorDoPaciente)
    }
  }, [listarInternacoesDoPaciente, identificadorDoPaciente, internacoesDoPaciente])

  useEffect(() => listarStatusDeInternacao(), [listarStatusDeInternacao])
  useEffect(() => listarMotivosDeInternacao(), [listarMotivosDeInternacao])
  useEffect(() => listarEstabelecimentosDeSaude(), [listarEstabelecimentosDeSaude])
  useEffect(() => listarTiposDeInternacao(), [listarTiposDeInternacao])

  const selecionarInternacao = item => {
    if (!estabelecimentosDeSaude.find(x => x.nome === item.estabelecimentoDeSaude)) {
      const estabelecimentos = [...estabelecimentosDeSaude, { nome: item.estabelecimentoDeSaude }]
      setEstabelecimentos(estabelecimentos)
    } else {
      setEstabelecimentos(estabelecimentosDeSaude)
    }

    if (item.motivos.length > 0 && !motivosDeInternacao.find(x => x.nome === item.motivoPrincipal.nome)) {
      const motivos = [...motivosDeInternacao, item.motivoPrincipal]
      setMotivos(motivos)
    } else {
      setMotivos(motivosDeInternacao)
    }

    setExibirFormulario(true)
    setSelecionada(item)
  }

  function abrirFormulario() {
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
      setEstabelecimentos(estabelecimentosDeSaude)
      setMotivos(motivosDeInternacao)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirImagem(sumarioDeAlta) {
    setSumarioDeAltaEscolhido(sumarioDeAlta)
    setExibirSumarioDeAlta(true)
  }

  function fecharImagem() {
    setExibirSumarioDeAlta(false)
  }

  function ExibirImagem() {
    return (
      <div className='modal-foto'>
        <i className='icon icon-close'
          onClick={fecharImagem}>
        </i>
        <img alt='foto' src={sumarioDeAltaEscolhido} />
      </div>
    )
  }

  const salvar = async item => {
    return selecionada ? await alterarInternacao(identificadorDoPaciente, item) : await adicionarInternacao(identificadorDoPaciente, item)
  }

  if (internacoesDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal modal-internacao'
        contentLabel='Modal do formulário de internações'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          motivos={selecionada ? motivos : motivosDeInternacao}
          estabelecimentos={selecionada ? estabelecimentos : estabelecimentosDeSaude}
          status={statusDeInternacao}
          tiposDeInternacao={tiposDeInternacao}
          adicionarSumarioDeAltaDaInternacao={adicionarSumarioDeAltaDaInternacao}
          fazerDownloadDoSumarioDeAltaDaInternacao={fazerDownloadDoSumarioDeAltaDaInternacao}
          arquivosDaInternacao={arquivosDaInternacao}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de internação'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerInternacao(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirSumarioDeAlta}
        onRequestClose={fecharImagem}
        className='modal'
      >
        <ExibirImagem />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_INTERNACOES_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_INTERNACAO_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  podeAlterar && podeListarParametros &&
                  (
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => selecionarInternacao({
                          ...item,
                          motivoPrincipal: item.motivos.length > 0 ? item.motivos.find(x => x.principal).motivo : '',
                          motivosSecundarios: item.motivos.length > 0 ? item.motivos.filter(x => !x.principal).map(y => ({ nome: y.motivo.nome })) : []
                        })}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  )
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                      <div className='tooltip__drop custom-scrollbar right20'>
                        <div className='tooltip__drop__body'>
                          <div className='tooltip__drop__title border-bottom-none'>
                            Histórico
                            <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i></button>
                          </div>
                          <div className='tooltip__drop__description'>
                            {item.status === 'Informado pela operadora' &&
                              <div>
                                <span><strong>Última alteração: </strong>{item.usuario.nome}</span> <br />
                                {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                                <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                              </div>
                            }
                            {item.descricoes.map(descricao =>
                              <div className='border-top-active' key={descricao.identificador} >
                                {descricao.descricao &&
                                  <>
                                    <strong>Descrição: </strong><br />
                                    <pre>{descricao.descricao}</pre>
                                  </>
                                }
                                <span><em>{descricao.usuario.nome}</em>{descricao.usuario.profissao ? <> | <em>{descricao.usuario.profissao.nome}</em></> : ''}</span> <br />
                                <span><em>Data e Hora: {formatarDataEHoraParaFormatoLocal(descricao.dataEHora)}</em></span> <br />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={internacoesDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarParametros}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros de internações.'
              classNameAdicionar='col-xs-6 col-lg-2'
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                nome='Início'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataParaFormatoLocal(item.inicio)}
              />
              <Coluna
                className='col-lg-2'
                nome='Fim'
                renderizar={item => formatarDataParaFormatoLocal(item.fim)}
              />
              <Coluna
                className='col-lg-2'
                nome='Estabelecimento'
                campoDeDados='estabelecimentoDeSaude'
              />
              <Coluna
                className='col-lg-2'
                nome='Motivo'
                renderizar={item => item.motivos.length > 0 && item.motivos.find(x => x.principal).motivo.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Status'
                renderizar={item =>
                  <p className='table-items__status'>
                    <span className='table-items__status__bg' style={{ backgroundColor: renderizarStatus(item.status) }}></span>
                    <span className='table-items__status__text'>{item.status}</span>
                  </p>
                }
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-doc-text'
                    nome='Status'
                    campoDeDados='status'
                  />
                  <Coluna
                    icone='icon-date'
                    nome='Início'
                    renderizar={item => formatarDataParaFormatoLocal(item.inicio)}
                  />
                  <Coluna
                    icone='icon-date'
                    nome='Fim'
                    className='table-items-expanded-45'
                    renderizar={item => item.fim ? formatarDataParaFormatoLocal(item.fim) : <>&nbsp;</>}
                  />
                  <Coluna
                    icone='icon-name'
                    nome='Motivo'
                    renderizar={item => item.motivos.length > 0 && item.motivos.find(x => x.principal).motivo.nome}
                  />
                  <Coluna
                    icone='icon-name'
                    nome='Motivo Secundário'
                    renderizar={
                      item => {
                        const motivosSecundarios = item.motivos.length > 0 && item.motivos.filter(x => !x.principal)
                        return (
                          motivosSecundarios.length > 1 ?
                            <>
                              {motivosSecundarios[0].motivo.nome}
                              <Motivos key={`motivo_${item.codigoDeRastreio}`} registro={motivosSecundarios} />
                            </>
                            : motivosSecundarios.length > 0 && motivosSecundarios[0].motivo.nome
                        )
                      }}
                  />
                  <Coluna
                    icone='icon-folder-empty'
                    nome='Sumário'
                    renderizar={item => item.arquivos.length > 0 &&
                      <SumarioDeAlta
                        key={`sumario_${item.arquivos[0].arquivo}`}
                        registro={item}
                        arquivosDaInternacao={arquivosDaInternacao}
                        fazerDownloadDoSumarioDeAltaDaInternacao={fazerDownloadDoSumarioDeAltaDaInternacao}
                        identificadorDoPaciente={identificadorDoPaciente}
                        identificadorDaInternacao={item.identificador}
                        abrirImagem={abrirImagem}
                      />
                    }
                  />
                  <Coluna
                    icone='icon-estabelecimento-de-saude'
                    nome='Estabelecimento'
                    className='table-items-expanded-100__box-item'
                    campoDeDados='estabelecimentoDeSaude'
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='Descrição mais recente'
                    className='table-items-expanded-100__box-item'
                    renderizar={item => item.descricoes.length > 0 && <pre>{item.descricoes[0].descricao}</pre>}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirInternacao = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_INTERNACAO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a internação do dia ${formatarDataParaFormatoLocal(selecionada && selecionada.inicio)}?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirInternacao}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function Motivos({ registro }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDosMotivos = useRef(null)
  const posicaoAEsquerda = referenciaDosMotivos.current && referenciaDosMotivos.current.offsetLeft

  useCliqueForaDoElemento(referenciaDosMotivos, () => setVisivel(false))

  if (!registro) return

  return (
    <>
      <button
        className='indicator-tooltip'
        type='button'
      >
        <i className={`icon-arrow${visivel ? '-up' : '-down'}`}
          onClick={() => setVisivel(!visivel)}
          ref={referenciaDosMotivos}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop tooltip-motivos custom-scrollbar' style={{ left: posicaoAEsquerda }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              {registro.map((x, index) =>
                <span key={index}>- {x.motivo.nome} <br /></span>
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}

function SumarioDeAlta({ registro, arquivosDaInternacao, fazerDownloadDoSumarioDeAltaDaInternacao, identificadorDoPaciente, identificadorDaInternacao, abrirImagem }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDoSumario = useRef(null)
  const width = registro.arquivos.length > 3 ? 381 : registro.arquivos.length * 127
  const posicaoAEsquerda = referenciaDoSumario.current && referenciaDoSumario.current.offsetLeft

  useCliqueForaDoElemento(referenciaDoSumario, () => setVisivel(false))

  if (!registro) return

  const abrirTooltipSumario = item => {
    if (visivel === item.arquivos[0].arquivo.identificador) {
      setVisivel(false)
    } else {
      setVisivel(item.arquivos[0].arquivo.identificador)
      item.arquivos.forEach(x => {
        if (!x.arquivo.url) {
          if (!x.arquivo.url && x.arquivo.extensao !== '.PDF') {
            return fazerDownloadDoSumarioDeAltaDaInternacao(identificadorDoPaciente, identificadorDaInternacao, x.arquivo.identificador)
          }
        }
      })
    }
  }

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoSumarioDeAltaDaInternacao(identificadorDoPaciente, identificadorDaInternacao, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  const formatosImagens = ['.PNG', '.GIF', '.JPEG', '.JPG', '.JFIF', '.WEBP']

  return (
    <div ref={referenciaDoSumario}>
      <div className='p-0'>
        <button
          type='button'
          className='button button-light-select-acao'
          title='Clique para Download'
          onClick={() => abrirTooltipSumario(registro)}>
          Clique para download ({registro.arquivos.length})
        </button>
      </div>
      {visivel &&
        <div className='tooltip__antibioticos_drop tooltip-sumario custom-scrollbar z-index-10' style={{ width: width, left: posicaoAEsquerda }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              <div className='form-summary'>
                <div className='form-summary-images'>
                  {registro.arquivos.map((x, index) => {
                    const url = arquivosDaInternacao && arquivosDaInternacao[x.arquivo.identificador] ? arquivosDaInternacao[x.arquivo.identificador].url : x.arquivo.url

                    if (formatosImagens.includes(x.arquivo.extensao)) {
                      return (
                        <div className='form-summary-image' key={index}>
                          {url ?
                            <>
                              <img src={url} alt='sumario' />
                              <div className='form-summary-image-tools'>
                                <button
                                  className='form-summary-image-zoom'
                                  aria-label='Ampliar'
                                  type='button'
                                  onClick={() => abrirImagem(url)}>
                                  <i className='icon-search'></i>
                                </button>
                              </div>
                            </>
                            : 'Carregando...'}
                        </div>
                      )
                    } else if (x.arquivo.extensao === '.PDF') {
                      return (
                        <div className='form-summary-image' key={index}>
                          <img src={arquivoPdf} alt='sumario' />
                          {x.arquivo.nome &&
                            <>
                              <label className='form-label'>{x.arquivo.nome.slice(0, x.arquivo.nome.lastIndexOf('.'))}</label>
                              <div className='form-summary-image-tools'>
                                <button
                                  className='form-summary-image-zoom'
                                  aria-label='Ampliar'
                                  type='button'
                                  onClick={() => fazerDownload(x.arquivo)}>
                                  <i className='icon-download-cloud'></i>
                                </button>
                              </div>
                            </>
                          }
                        </div>
                      )
                    } else {
                      return (
                        <div className='form-summary-image' key={index}>
                          <img src={arquivo} alt='sumario' />
                          {x.arquivo.nome &&
                            <>
                              <label className='form-label'>{x.arquivo.nome.slice(0, x.arquivo.nome.lastIndexOf('.'))}</label>
                              <div className='form-summary-image-tools'>
                                <button
                                  className='form-summary-image-zoom'
                                  aria-label='Ampliar'
                                  type='button'
                                  onClick={() => fazerDownload(x.arquivo)}>
                                  <i className='icon-download-cloud'></i>
                                </button>
                              </div>
                            </>
                          }
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}