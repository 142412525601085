import {
  LISTOU_MEDICAMENTOS_DO_PRONTUARIO,
  LISTOU_POSOLOGIAS
} from '../acoes/tipos'

const estadoInicial = {
  medicamentos: [],
  posologias: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_MEDICAMENTOS_DO_PRONTUARIO: {
      const medicamentos = []

      action.medicamentos.forEach(medicamento => {
        medicamento.concentracoes.forEach(concentracao => concentracao.ativa && {
          return : (
              medicamentos.push({
                concentracao: concentracao,
                exibicao: `${medicamento.nome} - ${concentracao.valor}`,
                nome: medicamento.nome,
              })
          )
        })
      })

      return {
        ...state,
        medicamentos
      }
    }

    case LISTOU_POSOLOGIAS: {
      return {
        ...state,
        posologias: action.posologias,
      }
    }


    default: {
      return state
    }
  }
}