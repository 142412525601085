import { useState } from 'react'

export default function usePrescricao() {
  const [textoAdicionado, setTextoAdicionado] = useState(null)

  const temPrescricao = (componentes) => {
    return componentes.some(x => x.tipo === 'PRESCRICOES')
  }

  const temPrescricaoDaMemed = (componentes) => {
    return componentes.some(x => x.tipo === 'PRESCRICOES_DA_MEMED')
  }

  const adicionarPrescricaoDoHistorico = (texto) => {
    setTextoAdicionado(texto)
  }

  const limparPrescricaoDoHistorico = () => {
    setTextoAdicionado(null)
  }

  return {
    adicionarPrescricaoDoHistorico,
    limparPrescricaoDoHistorico,
    textoAdicionado,
    temPrescricao,
    temPrescricaoDaMemed
  }
}