import React from 'react'
import { Formulario as FormularioDoFormik, InputImagem, MultiplaEscolha, Input } from '../../../../formik/formulario'
import Permissao from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import { contemValor } from '../../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Pentagonos from '../../../../../design/img/pentagonos.png'
import Spinner from '../../../../spinner'

import {
  RECUPERAR_DESENHO_DO_MINI_MENTAL,
  ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL
} from '../../../../../acoes/tipos'

const obrigatorio = valor => {
  if (contemValor(valor)) {
    if (Array.isArray(valor) && valor.length === 0) {
      return 'Obrigatório'
    }

    return undefined
  }

  return 'Obrigatório'
}

const renderizarTitulo = valor => valor.descricao

export default function InterpretacaoMedica(props) {
  const {
    estadosDaMemoriaAtual,
    estadosDaMemoriaComparadoHaUmAnoAtras,
    valores,
    fechar,
    recuperarDesenhoDoMiniMental,
    ...rest
  } = props

  const salvar = async (valores, { resetForm }) => {
    const dados = {
      ...valores,
    }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const remover = async () => {
    const removeu = await rest.remover()

    if (removeu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[RECUPERAR_DESENHO_DO_MINI_MENTAL, ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL, REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]}>
      <FormularioDoFormik
        reinicializar={false}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>Interpretação médica</h2>
          <Data data={valores.data} />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-mini-mental'>
            <Cabecalho />
            <SecaoMemoria estadosDaMemoriaAtual={estadosDaMemoriaAtual} estadosDaMemoriaComparadoHaUmAnoAtras={estadosDaMemoriaComparadoHaUmAnoAtras} memoria={valores.secaoMemoria} />
            <SecaoOrientacaoTemporal orientacaoTemporal={valores.secaoOrientacaoTemporal} />
            <SecaoOrientacaoEspacial orientacaoEspacial={valores.secaoOrientacaoEspacial} />
            <SecaoRegistroDaMemoriaImediata registroDaMemoriaImediata={valores.secaoRegistroDaMemoriaImediata} />
            <SecaoAtencaoECalculo atencaoECalculo={valores.secaoAtencaoECalculo} />
            <SecaoMemoriaRecente memoriaRecente={valores.secaoMemoriaRecente} />
            <SecaoLinguagem linguagem={valores.secaoLinguagem} recuperarDesenho={recuperarDesenhoDoMiniMental} />
            <r-cell span={4} span-md={6} span-lg={12}>
              <div className='resultado-questionario'>
                <div><strong>RESULTADO</strong></div>
                <div><strong>{valores.pontuacaoTotal}</strong></div>
              </div>
            </r-cell>
          </r-grid>
          <>
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-escala-ambiental'>
              <CabecalhoComplementar />
              <Interpretacao />
            </r-grid>
          </>
          <div className='list-btn'>
            <Permissao key='remover' permissoes={[permissoes.REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL]}>
              <button className='button --danger' type='button' onClick={remover} disabled={valores.secaoInterpretacaoMedica.interpretacaoMedica === ''}>Remover</button>
            </Permissao>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>Salvar</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-zarit paciente-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>MINI MENTAL</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function SecaoMemoria({ estadosDaMemoriaAtual, estadosDaMemoriaComparadoHaUmAnoAtras, memoria }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>MEMÓRIA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='secaoMemoria.estadoDaMemoriaAtual'
          opcoes={estadosDaMemoriaAtual.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
          pergunta='1) Como o Sr(a) avalia sua memória atualmente?'
          renderizarTitulo={renderizarTitulo}
          leitura={true}
          valor={memoria.estadoDaMemoriaAtual}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='secaoMemoria.estadoDaMemoriaComparadoHaUmAnoAtras'
          opcoes={estadosDaMemoriaComparadoHaUmAnoAtras.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
          pergunta='2) Comparando com um ano atrás, o Sr(a) diria que sua memória está: '
          renderizarTitulo={renderizarTitulo}
          leitura={true}
          valor={memoria.estadoDaMemoriaComparadoHaUmAnoAtras}
        />
      </div>
    </r-cell>
  )
}

function SecaoOrientacaoTemporal({ orientacaoTemporal }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ORIENTAÇÃO TEMPORAL</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>3) Por favor, diga-me:</strong>
          <div className='respostas-leitura'>
            <span>({orientacaoTemporal.diaDaSemana ? 'x' : '  '}) Dia da semana</span>
            <br />
            <span>({orientacaoTemporal.diaDoMes ? 'x' : '  '}) Dia do mês</span>
            <br />
            <span>({orientacaoTemporal.mes ? 'x' : '  '}) Mês</span>
            <br />
            <span>({orientacaoTemporal.ano ? 'x' : '  '}) Ano</span>
            <br />
            <span>({orientacaoTemporal.horaAproximada ? 'x' : '  '}) Hora aprox.</span>
          </div>

        </div>
      </div>
      <div className='pontuacao'>{orientacaoTemporal.pontuacaoOrientacaoTemporal}</div>
    </r-cell>
  )
}

function SecaoOrientacaoEspacial({ orientacaoEspacial }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ORIENTAÇÃO ESPACIAL</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>4) Responda:</strong>
          <div className='respostas-leitura'>
            <span>({orientacaoEspacial.onde ? 'x' : '  '}) Onde estamos: consultório, hospital, residência</span>
            <br />
            <span>({orientacaoEspacial.lugar ? 'x' : '  '}) Em que lugar estamos: andar, sala, cozinha</span>
            <br />
            <span>({orientacaoEspacial.bairro ? 'x' : '  '}) Em que bairro estamos</span>
            <br />
            <span>({orientacaoEspacial.cidade ? 'x' : '  '}) Em que cidade estamos</span>
            <br />
            <span>({orientacaoEspacial.estado ? 'x' : '  '}) Em que estado estamos</span>
          </div>
        </div>
      </div>
      <div className='pontuacao'>{orientacaoEspacial.pontuacaoOrientacaoEspacial}</div>
    </r-cell>
  )
}

function SecaoRegistroDaMemoriaImediata({ registroDaMemoriaImediata }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>REGISTRO DA MEMÓRIA IMEDIATA</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>5) Vou lhe dizer o nome de três objetos e quando terminar, pedirei para repeti-los, em qualquer ordem. Guarde-os que mais tarde voltarei a perguntar: Árvore, Mesa, Cachorro. </strong>
          <div className='respostas-leitura'>
            <span>({registroDaMemoriaImediata.arvore ? 'x' : '  '}) Árvore</span>
            <br />
            <span>({registroDaMemoriaImediata.mesa ? 'x' : '  '}) Mesa</span>
            <br />
            <span>({registroDaMemoriaImediata.cachorro ? 'x' : '  '}) Cachorro</span>
          </div>
          <br />
          <p><strong>Obs: </strong>Leia os nomes dos objetos devagar e de forma clara, somente um a vez e anote. Se o
            total for diferente de três: - repita todos os objetos até no máximo três repetições; - anote o
            número de repetições que fez: <strong>{registroDaMemoriaImediata.repeticoes}</strong></p>
        </div>
      </div>
      <div className='pontuacao'>{registroDaMemoriaImediata.pontuacaoRegistroDaMemoriaImediata}</div>
    </r-cell>
  )
}

function SecaoAtencaoECalculo({ atencaoECalculo }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ATENÇÃO E CÁLCULO</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>6) Vou lhe dizer alguns números e gostaria que realizasse os seguintes cálculos:</strong>
          <div className='respostas-leitura'>
            <span>({atencaoECalculo.cemMenosSete ? 'x' : '  '}) 100 - 7</span>
            <br />
            <span>({atencaoECalculo.noventaETresMenosSete ? 'x' : '  '}) 93 - 7</span>
            <br />
            <span>({atencaoECalculo.oitentaESeisMenosSete ? 'x' : '  '}) 86 - 7</span>
            <br />
            <span>({atencaoECalculo.setentaENoveMenosSete ? 'x' : '  '}) 79 - 7</span>
            <br />
            <span>({atencaoECalculo.setentaEDoisMenosSete ? 'x' : ' '}) 72 - 7</span>
          </div>
        </div>
      </div>
      <div className='pontuacao'>{atencaoECalculo.pontuacaoAtencaoECalculo}</div>
    </r-cell>
  )
}

function SecaoMemoriaRecente({ memoriaRecente }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>MEMÓRIA RECENTE</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>7) Há alguns minutos, o Sr(a) repetiu uma série de três palavras. Por favor, diga-me agora quais ainda se lembra:</strong>
          <div className='respostas-leitura'>
            <span>({memoriaRecente.arvore ? 'x' : '  '}) Árvore</span>
            <br />
            <span>({memoriaRecente.mesa ? 'x' : '  '}) Mesa</span>
            <br />
            <span>({memoriaRecente.cachorro ? 'x' : '  '}) Cachorro</span>
          </div>
        </div>
      </div>
      <div className='pontuacao'>{memoriaRecente.pontuacaoMemoriaRecente}</div>
    </r-cell>
  )
}

function SecaoLinguagem({ linguagem, recuperarDesenho }) {
  const pontuacao = linguagem.pontuacaoNomearCanetaERelogio + linguagem.pontuacaoRepetirFrase + linguagem.pontuacaoLerPapel + linguagem.pontuacaoComandosPapel + linguagem.pontuacaoFraseCompleta + linguagem.pontuacaoDesenhoPentagonos

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>LINGUAGEM</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>8) Aponte a caneta e o relógio e peça pra nomeá-los:</strong>
          <div className='respostas-leitura'>
            <span>({linguagem.caneta ? 'x' : '  '}) Caneta</span>
            <br />
            <span>({linguagem.relogio ? 'x' : '  '}) Relógio</span>
          </div>
        </div>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.repetirFrase'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='9) Repita a frase que eu vou lhe dizer (pronunciar em voz alta, bem articulada e lentamente): “NEM AQUI, NEM ALI, NEM LÁ”'
          renderizarTitulo={renderizarTitulo}
          leitura={true}
          valor={linguagem.repetirFrase}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.lerPapel'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='10) Dê ao entrevistado uma folha de papel, na qual esteja escrito em letras grandes: “FECHE OS OLHOS”. Diga-lhe: leia este papel e faça o que está escrito (permita dez segundos). '
          renderizarTitulo={renderizarTitulo}
          leitura={true}
          valor={linguagem.lerPapel}
        />
        <div className='pergunta-2n leitura'>
          <strong>11) Vou lhe dar um papel e quando eu o entregar, pegue com sua mão direita, dobre-o na metade com as duas mãos e coloque no chão. </strong>
          <div className='respostas-leitura'>
            <span>({linguagem.pegarPapel ? 'x' : '  '}) Pegar</span>
            <br />
            <span>({linguagem.dobrarPapel ? 'x' : '  '}) Dobrar</span>
            <br />
            <span>({linguagem.dobrarPcolocarPapelNoChaoapel ? 'x' : '  '}) Colocar</span>
          </div>
        </div>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.fraseCompleta'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='12) Pedir ao entrevistado que escreva uma frase em um papel em branco. (contar um ponto se a frase
            tem sujeito, verbo, predicado, sem levar em conta erros de ortografia ou de sintaxe). Se o
            entrevistado não fizer corretamente, perguntar-lhe: “Isto é uma frase/ E permitir-lhe
            corrigir se tiver consciência de seu erro.'
          renderizarTitulo={renderizarTitulo}
          leitura={true}
          valor={linguagem.fraseCompleta}
        />
        <div className='pergunta-2n'>
          <MultiplaEscolha
            nome='secaoLinguagem.desenhoPentagonos'
            opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
            pergunta='13) Por favor, copie este desenho (entregue ao entrevistado o desenho e peça-o para
            copiar). A ação está correta se o desenho tiver dois pentágonos com intersecção de um
            ângulo.'
            renderizarTitulo={renderizarTitulo}
            leitura={true}
            valor={linguagem.desenhoPentagonos}
          />
          <img
            className='pentagono'
            alt='Pentágonos'
            src={Pentagonos}
          />
          <div className='list-btn'>
            <InputImagem
              valor={linguagem.desenhoDoMiniMental}
              recuperar={recuperarDesenho}
              leitura={true}
            />
          </div>
        </div>
      </div>
      <div className='pontuacao'>{linguagem ? `${pontuacao}` : ''}</div>
    </r-cell >
  )
}

function CabecalhoComplementar() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>INTERPRETAÇÃO MÉDICA</strong>
      </div>
    </r-cell>
  )
}

function Interpretacao() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <Input
        as='textarea'
        classname='pergunta'
        nome='secaoInterpretacaoMedica.interpretacaoMedica'
        titulo='Interpretação *'
        validar={obrigatorio}
      />
    </r-cell>
  )
}