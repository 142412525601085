import {
  LISTOU_TIPOS_DE_INTERNACAO,
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_INTERNACAO: {
      return action.tiposDeInternacao
    }

    default: {
      return state
    }
  }
}