import React from 'react'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'

export default function ResumoDaRecorrencia({ inicioDaSerie, fimDaSerie, semana, repeteACada }) {
  if (!inicioDaSerie || !fimDaSerie || !semana || semana.length === 0 || !repeteACada) return null

  const inicioFormatado = formatarDataParaFormatoLocal(inicioDaSerie)
  const fimFormatado = formatarDataParaFormatoLocal(fimDaSerie)
  const semanasPorExtenso = formatarSemanasPorExtenso(semana)
  const repeticao = repeteACada > 1 ? `a cada ${repeteACada} semanas.` : 'todas as semanas.';
  let retorno = `Ocorre ${semanasPorExtenso} de ${inicioFormatado} até ${fimFormatado} ${repeticao}`;

  if (inicioFormatado === fimFormatado) {
    retorno = `Ocorre somente no dia ${inicioFormatado}.`
  }

  return (
    <r-cell span='4' span-md='12' span-lg='12'>
      <div className='form-choice-info'>
        <div className='form-choice-info__item'>
          <i className='icon icon-attention-circled'></i>
        </div>
        <div className='form-choice-info__item'>
          <strong>Série</strong>: {retorno}
        </div>
      </div>
    </r-cell>
  )
}

function formatarSemanasPorExtenso(semana) {
  let semanasPorExtenso = ''

  if (semana.length === 1) {
    semanasPorExtenso = formatarDiasDaSemanaPorExtenso(semana)

  } else if (semana.length === 7) {
    return 'todos os dias'

  } else {
    const ultimoDia = formatarDiasDaSemanaPorExtenso([semana[semana.length - 1]])
    const outrosDias = semana.slice(0, -1)

    semanasPorExtenso = `${formatarDiasDaSemanaPorExtenso(outrosDias)} e ${ultimoDia}`
  }

  if (semana[0] === 'Sáb' || semana[0] === 'Dom') {
    return `todos os ${semanasPorExtenso}`
  }

  return `todas as ${semanasPorExtenso}`
}

function formatarDiasDaSemanaPorExtenso(semana) {
  const diasPorExtenso = {
    Seg: 'Segundas',
    Ter: 'Terças',
    Qua: 'Quartas',
    Qui: 'Quintas',
    Sex: 'Sextas',
    Sáb: 'Sábados',
    Dom: 'Domingos',
  };

  const diasExtenso = semana.map(dia => diasPorExtenso[dia]);
  return diasExtenso.join(', ')
}