import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA
} from './tipos'

const listouTiposDeRepeticaoDeSerieDaAgenda = tipos => ({
  type: LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  tipos
})

const erroAoListarTiposDeRepeticaoDeSerieDaAgenda = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  erro
})

export const listarTiposDeRepeticaoDeSerieDaAgenda = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA })

    try {
      const resultado = await api.listarTiposDeRepeticaoDeSerieDaAgenda()

      dispatch(listouTiposDeRepeticaoDeSerieDaAgenda(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTiposDeRepeticaoDeSerieDaAgenda(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de repetição de série da agenda.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA
  }

  return acao
}