import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Formulario as FormularioDoFormik, Select } from '../../formik/formulario'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import avatar from '../../../design/img/no-thumb.png'
import * as Yup from 'yup'
import moment from 'moment'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import ContatosDoAgendamento from '../agendamento/contatos-do-agendamento'

import {
  LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
} from '../../../acoes/tipos'

export default function Passo2(props) {
  const {
    convenios,
    convenioSelecionado,
    fotosDosPacientes,
    history,
    paciente,
    profissionaisDeSaude,
    profissoes,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarEspecialidadeDoProfissional,
    selecionarProfissaoDoAgendamento,
    selecionarProfissionalDoAgendamento,
    selecionarTipoDoAgendamento,
    contatos,
    tiposDeAgendamento,
    filtros: {
      especialidadeSelecionada,
      profissaoSelecionada,
      profissionaisSelecionados,
      tipoDeAgendamentoSelecionado,
    }
  } = props

  const profissionais = profissaoSelecionada && profissaoSelecionada.identificador ? profissionaisDeSaude.filter(x => x.profissao.nome === profissaoSelecionada.nome) : profissionaisDeSaude
  const semConvenio = convenioSelecionado === 'naoCadastrado' || !convenioSelecionado
  const convenio = !semConvenio && convenios.find(x => x.identificador === convenioSelecionado).descricao
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const referenciaDoRelato = useRef(null)

  useCliqueForaDoElemento(referenciaDoRelato, () => setExibirTooltipInfo(false))

  const selecionarProfissao = useCallback(nome => {
    const profissao = profissoes.find(x => x.nome === nome)

    selecionarProfissaoDoAgendamento(profissao)
    selecionarEspecialidadeDoProfissional('')
    selecionarProfissionalDoAgendamento([])
  }, [profissoes, selecionarProfissaoDoAgendamento, selecionarEspecialidadeDoProfissional, selecionarProfissionalDoAgendamento])

  const selecionarEspecialidade = especialidade => {
    selecionarEspecialidadeDoProfissional(especialidade)
    selecionarProfissionalDoAgendamento([])
  }

  const limparPasso2 = () => {
    selecionarProfissaoDoAgendamento('')
    selecionarProfissionalDoAgendamento([])
    selecionarTipoDoAgendamento('')
    selecionarEspecialidadeDoProfissional('')
  }

  const filtrarProfissionaisDeSaude = () => {
    if (profissaoSelecionada && especialidadeSelecionada) {
      return profissionais.filter(x => x.profissao.especialidades.includes(especialidadeSelecionada))
    } else {
      return profissionais
    }
  }

  const profissionaisFiltrados = filtrarProfissionaisDeSaude()

  const enviarDadosProfissionais = formulario => {
    selecionarProfissionalDoAgendamento(formulario.profissional)
    history.push(`/agendamentos/novo-agendamento/passo-3`)

  }

  useEffect(() => {
    paciente && [paciente].filter(x => x.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.identificador, item.foto)
    })

    return () => paciente && [paciente].filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })

  }, [paciente, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  return (
    <div className='page-paciente-dados'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[
            LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
            LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
            LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
          ]}>
            <FormularioDoFormik
              reinicializar={true}
              valoresIniciais={{
                profissao: profissaoSelecionada ? profissaoSelecionada.nome : '',
                profissional: profissionaisSelecionados ? profissionaisSelecionados : [],
                especialidade: especialidadeSelecionada ? especialidadeSelecionada : '',
                tipoDeAgendamento: tipoDeAgendamentoSelecionado ? tipoDeAgendamentoSelecionado : ''
              }}
              esquemaDeValidacoes={Yup.object().shape({
                profissao: Yup.string().when('profissional', {
                  is: val => val.length === 0,
                  then: Yup.string().required('Obrigatório.')
                }),
                profissional: Yup.string().when('profissao', {
                  is: val => !val,
                  then: Yup.string().required('Obrigatório.')
                }),
                tipoDeAgendamento: Yup.string().required('Obrigatório.'),
              }, [['profissao', 'profissional'], ['profissional', 'profissao']])}
              acao={enviarDadosProfissionais}
            >
              <div className='nav-route-agendamento margem-zero'>
                <div className='nav-route-back-agendamento'>
                  <button
                    onClick={history.goBack}
                    type='button'
                    className='nav-route-agendamento-name'
                    title='Voltar'
                  ></button>
                </div>
                <div className='nav-convenio'>
                  <ol className='nav-step'>
                    <li className='nav-step-item active'>2</li>
                    <li className='nav-step-item'> / 3</li>
                  </ol>
                  <p>Dados do Profissional</p>
                </div>
              </div>
              <div className='mb-4'>
                <fieldset>
                  <div className='form-item-passo-3'>
                    <r-grid columns-md={12} columns-lg={12}>
                      <r-cell span={4} span-md={1} span-lg={1} class='image-paciente'>
                        <div className='with-thumb-sidebar'>
                          <img
                            alt={paciente.nome}
                            src={(paciente.foto && fotosDosPacientes[paciente.foto]) || avatar}
                          />
                        </div>
                      </r-cell>
                      <r-cell span={4} span-md={5} span-lg={5}>
                        <div className='form-item__choice-passo-3'>
                          <strong>Nome do Paciente:</strong>{' '}{paciente.nome}
                          {' | '}
                          <i className='icon icon-smartphone'
                            onClick={() => setExibirTooltipInfo(true)}
                            ref={referenciaDoRelato}
                            data-for={'info_telefones_paciente'}
                            style={{ cursor: 'pointer', fontSize: '90%' }}>
                            <strong>Contatos</strong>
                          </i>
                          {exibirTooltipInfo &&
                            <div className='tooltip__drop custom-scrollbar' style={{ left: '150px', top: '50px', width: '400px', height: 'auto' }}>
                              <div className='tooltip__drop__body'>
                                <div className='tooltip__drop__title'>
                                  Contatos do paciente
                                  <button
                                    className='tooltip__drop__bt-close'
                                    type='button'
                                    onClick={() => setExibirTooltipInfo(false)}>
                                    <i className='icon-task icon-close'></i></button>
                                </div>
                                <div className='tooltip__drop__description'>
                                  <ContatosDoAgendamento agendamento={contatos} />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <div className='form-item__choice-passo-3'><strong>Data de Nascimento:</strong>{' '}{moment(paciente.dataDeNascimento).format('DD/MM/YYYY')}</div>
                        {paciente.medicoDeReferencia && <div className='form-item__choice-passo-3'><strong>Médico de Referência:</strong>{' '}{paciente.medicoDeReferencia.tratamento}{' '}{paciente.medicoDeReferencia.nome}</div>}
                      </r-cell>
                      <r-cell span={4} span-md={5} span-lg={5}>
                        {convenio && <div className='form-item__choice-passo-3'><strong>Convênio:</strong>{' '}{convenio}</div>}
                        {paciente.associacao && <div className='form-item__choice-passo-3'><strong>Associação:</strong>{' '}{paciente.associacao.nome}</div>}
                        {paciente.programas && paciente.programas.length > 0 && <div className='form-item__choice-passo-3'><strong>Programa(s):</strong>{' '}{paciente.programas.join(', ')}</div>}
                      </r-cell>
                    </r-grid>
                  </div>
                  <div className='page-agendamento-passo-2'>
                    <div className='page-paciente'>
                      <r-grid columns-md={6} columns-lg={12} class='align-end'>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Select
                            nome='profissao'
                            tabIndex={1}
                            titulo='Profissão'
                            itens={profissoes}
                            campoCodigo='nome'
                            campoDescricao='nome'
                            onChange={selecionarProfissao}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Select
                            nome='especialidade'
                            tabIndex={1}
                            titulo='Especialidade'
                            campoCodigo='nome'
                            campoDescricao='nome'
                            itens={profissaoSelecionada ? profissaoSelecionada.especialidades : []}
                            isDisabled={profissaoSelecionada ? (profissaoSelecionada.especialidades ? profissaoSelecionada.especialidades.length === 0 : true) : true}
                            onChange={especialidade => selecionarEspecialidade(especialidade)}
                          />
                        </r-cell>
                      </r-grid>
                    </div>
                    <div className='renderizar-convenio'>
                      <r-grid columns-md={6} columns-lg={12} class='align-end'>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Select
                            nome='profissional'
                            tabIndex={5}
                            titulo='Profissional'
                            itens={profissionaisFiltrados}
                            isMulti
                            campoCodigo='identificador'
                            campoDescricao='nome'
                            onChange={identificador => selecionarProfissionalDoAgendamento(identificador)}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Select
                            nome='tipoDeAgendamento'
                            tabIndex={6}
                            titulo='Tipo de Agendamento *'
                            itens={tiposDeAgendamento}
                            campoCodigo='identificador'
                            campoDescricao='nome'
                            onChange={identificador => selecionarTipoDoAgendamento(identificador)}
                          />
                        </r-cell>
                      </r-grid>
                    </div>
                  </div>
                  <div className='list-btn'>
                    <button tabIndex={7} type='reset' className='button --light' onClick={() => limparPasso2()}>Limpar</button>
                    <button tabIndex={8} type='submit' className='button --primary'>Continuar</button>
                  </div>
                </fieldset>
              </div>
            </FormularioDoFormik>
          </Spinner>
        </div>
      </div>
    </div>
  )
}