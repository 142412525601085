import {
  ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO
} from '../../acoes/tipos'

const estadoInicial = {
  filtros: {
    termo: '',
    status: ['Aguardando Atendimento', 'Atendido', 'Em Atendimento'],
  },
  ordenacao: null,
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ATUALIZOU_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO:
    case LISTOU_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        ordenacao,
        paginaDeDados
      }
    }

    default: {
      return state
    }
  }
}