import api from '../api-major'

const listarAgendamentos = (filtros, pagina, ordenacao) => {
  const {
    fim,
    profissionais,
    inicio,
    termo,
    status,
    unidades,
    tiposDeAgendamento
  } = filtros

  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome},+paciente.nome,+identificador` : '&ordem=+inicio,+paciente.nome,+identificador'
  const parametroTamanhoDaPagina = '&tamanhoDaPagina=10'
  const parametroPaciente = termo ? `&paciente=${termo}` : ''
  const parametroProfissional = profissionais ? `&profissionais=${profissionais}` : ''
  const parametroStatus = status ? `&status=${status}` : ''
  const parametroInicio = inicio ? `&inicio=${inicio}` : ''
  const parametroFim = inicio ? `&fim=${fim}` : ''
  const parametroUnidades = unidades ? `&unidades=${unidades}` : ''
  const parametroTipos = tiposDeAgendamento ? `&tipos=${tiposDeAgendamento}` : ''


  return api.get(`/agenda/agendamentos${paginacao}${parametroTamanhoDaPagina}&turnos=MANHA,TARDE,NOITE,MADRUGADA&diasDaSemana=DOM,SEG,TER,QUA,QUI,SEX,SAB${parametroOrdem}${parametroPaciente}${parametroProfissional}${parametroTipos}${parametroStatus}${parametroInicio}${parametroFim}${parametroUnidades}`)
}

export default {
  listarAgendamentos
}   