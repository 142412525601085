import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Breadcrumb from '../breadcrumb'
import CampoDePesquisa from '../campo-de-pesquisa'
import DicaDePesquisa from '../dica-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import { LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN, LISTAR_USUARIOS_BLOQUEADOS } from '../../acoes/tipos'

export default function Lista(props) {
  const {
    fotos,
    paginaDeDados,
    pesquisar,
    motivosDeBloqueioDoLogin,
    listarMotivosDeBloqueioDoLogin,
    desbloquearLoginDoUsuario
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)
  const [listouMotivos, setListouMotivos] = useState(motivosDeBloqueioDoLogin.length > 0)

  const podeDesbloquear = usePossuiAsPermissoes([permissoes.DESBLOQUEAR_LOGIN_DO_USUARIO])

  useEffect(() => {
    (async () => {
      if (motivosDeBloqueioDoLogin.length > 0) return
      const motivos = await listarMotivosDeBloqueioDoLogin()

      if (motivos) {
        setFiltros({
          ...props.filtros,
          motivosDeBloqueio: props.filtros.motivosDeBloqueio.length > 0 ? props.filtros.motivosDeBloqueio : motivos.map(x => x.nome)
        })
        setListouMotivos(true)
      }
    })()
  }, [motivosDeBloqueioDoLogin, listarMotivosDeBloqueioDoLogin, props.filtros])

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pagina, ordenacao, pesquisar])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarTermo = termo => alterarFiltros({ ...filtros, termo })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const desbloquear = async identificador => {
    const usuarioDesbloqueado = await desbloquearLoginDoUsuario(identificador)
    
    if(usuarioDesbloqueado) {
      pesquisar(filtros, pagina, ordenacao)
    }
  }

  const confirmarEDesbloquear = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja desbloquear o login do usuário ${item.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => desbloquear(item.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
              <r-cell span={4} span-md={2} span-lg={8}>
                <Breadcrumb />
              </r-cell>
              <r-cell span={4} span-md={2} span-lg={4}>
                <DicaDePesquisa
                  className='form-search'
                  dica='A pesquisa é realizada por nome.'
                  exemplo='Maria'
                >
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={filtros ? filtros.termo : ''}
                  />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_USUARIOS_BLOQUEADOS, LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN]}>
            {listouMotivos &&
              <Tabela
                acoes={[
                  item => {
                    return (podeDesbloquear && (
                      <button
                        className='table-items__bt --edit'
                        title='Desbloquear'
                        type='button'
                        key='desbloquear'
                        onClick={() => confirmarEDesbloquear(item)}
                      >
                        <i className='icon icon-lock-open-alt'></i>
                      </button>
                    ))
                  }
                ]}
                alterarFiltros={alterarFiltrosDaTabela}
                chave='identificador'
                className='mt-1 mb-4'
                listar={listar}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                filtros={filtros}
              >
                <Coluna
                  className='col-xs-2 col-md-1 col-lg-1'
                  foto={true}
                  fotos={fotos}
                  identificadorDaFoto={item => item.foto}
                />
                <Coluna
                  className='col-xs-6 col-md-9 col-lg-2'
                  principal={true}
                  nome='Nome'
                  campoDeDados='nome'
                />
                <Coluna
                  className='col-xs-6 col-md-9 col-lg-3'
                  nome='Email'
                  campoDeDados='email'
                />
                <Coluna
                  campoDeDados='dataEHora'
                  className='col-lg-2'
                  nome='Data/Hora'
                  exibirOrdenacao={true}
                  renderizar={item => item.dataEHoraDoBloqueio && moment(item.dataEHoraDoBloqueio, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}
                />
                <Coluna
                  campoDeDados='motivosDeBloqueio'
                  className='col-lg-3'
                  filtros={motivosDeBloqueioDoLogin.map(x => x.nome)}
                  nome='Motivo do bloqueio'
                  renderizar={item => item.motivoDoBloqueio}
                />
              </Tabela>
            }
          </Spinner>
        </div>
      </div>
    </div>
  )
}