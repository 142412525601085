import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Link, withRouter } from 'react-router-dom'
import logoBlue from '../../design/img/logo-blue.svg'
import profile from '../../design/img/profile.png'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import Permissao, { usePossuiAsPermissoes, usePossuiAlgumaPermissao } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'
import { Grupo, ListaDeGrupos } from './grupos/index'

function BarraDeMenu({ history, match, statusDoSistema }) {
  const { usuario, logout } = useUsuarioLogado()

  const [exibirMenuUsuario, setExibirMenuUsuario] = useState(false)
  const [exibirMenuMobile, setExibirMenuMobile] = useState(false)
  const [exibirMenuAtendimentosEmAndamento, setExibirMenuAtendimentosEmAndamento] = useState(false)

  const [exibirMenuAgendamentos, setExibirMenuAgendamentos] = useState(false)
  const [exibirMenuAtendimentos, setExibirMenuAtendimentos] = useState(false)
  const [exibirMenuCadastros, setExibirMenuCadastros] = useState(false)
  const [exibirMenuRelatorios, setExibirMenuRelatorios] = useState(false)
  const [exibirMenuConfiguracoes, setExibirMenuConfiguracoes] = useState(false)

  const menuUsuario = useRef(null)
  const menuAtendimentosEmAndamento = useRef(null)

  const ocultarMenuConfiguracoesDoUsuario = () => setExibirMenuUsuario(false)
  const ocultarMenuAtendimentosEmAndamento = () => setExibirMenuAtendimentosEmAndamento(false)

  const acessarRelatorios = usePossuiAlgumaPermissao([
    permissoes.FAZER_DOWNLOAD_DE_ATENDIMENTOS,
    permissoes.FAZER_DOWNLOAD_DE_PACIENTES,
    permissoes.FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
    permissoes.FAZER_DOWNLOAD_DE_REINTERNACOES,
    permissoes.FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
    permissoes.FAZER_DOWNLOAD_DE_INTERNACOES,
    permissoes.GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
    permissoes.GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
    permissoes.LISTAR_RELATORIOS_DO_POWER_BI
  ])

  const acessarCadastros = usePossuiAlgumaPermissao([
    permissoes.LISTAR_PACIENTES,
    permissoes.LISTAR_MOTIVOS_DE_INTERNACAO,
    permissoes.LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
    permissoes.LISTAR_ESTABELECIMENTOS_DE_SAUDE,
    permissoes.LISTAR_FABRICANTES,
    permissoes.LISTAR_OPERADORAS,
    permissoes.LISTAR_PROGRAMAS,
    permissoes.LISTAR_MEDICAMENTOS,
    permissoes.LISTAR_ASSOCIACOES,
    permissoes.LISTAR_PROFISSOES,
    permissoes.LISTAR_PROFISSIONAIS_EXTERNOS,
    permissoes.LISTAR_DIAGNOSTICOS,
    permissoes.LISTAR_GRUPOS_DE_CUIDADO,
    permissoes.LISTAR_PLANOS_DE_CUIDADO,
    permissoes.LISTAR_ORIENTACOES,
  ])

  const permissoesAcessarAgendamentos = [
    permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE,
    permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE,
    permissoes.LISTAR_TIPOS_DE_AGENDAMENTO,
    permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
    permissoes.CONFIRMAR_CHEGADA_DO_PACIENTE
  ]

  const acessarAtendimentosIniciados = usePossuiAsPermissoes([
    permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE,
    permissoes.LISTAR_ATENDIMENTOS_INICIADOS_PELO_USUARIO
  ])

  const acessarConfiguracoes = usePossuiAlgumaPermissao([
    permissoes.LISTAR_USUARIOS,
    permissoes.LISTAR_EMPRESAS,
    permissoes.LISTAR_FERIADOS,
    permissoes.LISTAR_TEMPLATES,
    permissoes.LISTAR_SETORES
  ])

  const acessarMenuAgendamentos = usePossuiAlgumaPermissao([
    permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
    permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE,
    permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE,
    permissoes.LISTAR_TIPOS_DE_AGENDAMENTO,
    permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
    permissoes.CONFIRMAR_CHEGADA_DO_PACIENTE
  ])

  const acessarAgenda = usePossuiAsPermissoes([permissoes.LISTAR_CONFIGURACOES_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]) && usuario.profissao
  const acessarAtendimentos = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]) && usuario.profissao
  const acessarEncaminhamentos = usePossuiAsPermissoes([permissoes.LISTAR_ENCAMINHAMENTOS])
  const acessarTarefas = usePossuiAsPermissoes([permissoes.LISTAR_TAREFAS])
  const podeAlterarDados = usePossuiAsPermissoes([permissoes.ALTERAR_USUARIO])
  const podeAlterarSenha = usePossuiAsPermissoes([permissoes.ALTERAR_SENHA])

  const url = window.location.pathname.split('/')
  const AGENDAMENTOS = url.length >= 2 && url[1].includes('agendamentos') ? 'is-active' : ''
  const ATENDIMENTOS = url.length >= 2 && url[1].includes('atendimentos') ? 'is-active' : ''
  const ENCAMINHAMENTOS = url.length >= 2 && url[1].includes('encaminhamentos') ? 'is-active' : ''
  const CADASTROS = url.length >= 2 && url[1].includes('cadastros') ? 'is-active' : ''
  const RELATORIOS = url.length >= 2 && url[1].includes('relatorios') ? 'is-active' : ''
  const CONFIGURACOES = url.length >= 2 && url[1].includes('configuracoes') ? 'is-active' : ''
  const TAREFAS = url.length >= 2 && url[1].includes('tarefas') ? 'is-active' : ''

  useCliqueForaDoElemento(menuUsuario, ocultarMenuConfiguracoesDoUsuario)
  useCliqueForaDoElemento(menuAtendimentosEmAndamento, ocultarMenuAtendimentosEmAndamento)

  useEffect(() => {
    if (exibirMenuMobile) {
      document.body.className = document.body.className ? `${document.body.className} overflow-hidden` : 'overflow-hidden'
    } else {
      document.body.className = document.body.className.includes('overflow-hidden') ? `${document.body.className.replaceAll('overflow-hidden', '')}` : document.body.className
    }
  }, [exibirMenuMobile])

  useEffect(() => {
    return history.listen(() => {
      if (exibirMenuMobile) setExibirMenuMobile(false)
    })
  }, [history, exibirMenuMobile])

  const sair = async () => {
    const saiu = await logout()

    if (saiu) {
      history.push('/login')
    }
  }

  const mudarUnidade = () => {
    history.push(`/login/unidades`)
  }

  const recuperarUnidades = (empresas = []) => empresas.reduce((acumulado, atual) => acumulado.concat(atual.unidades.map(unidade => ({
    ...unidade,
    empresa: {
      identificador: atual.identificador,
      nome: atual.nome,
    },
  }))), [])

  const unidades = useMemo(() => recuperarUnidades(usuario.empresas), [usuario.empresas])

  return (
    <header className='main-header'>
      <div className='container'>
        <Link to={match.url} className='logo-header'>
          <img src={logoBlue} alt='Lifecode' />
        </Link>
        <div className={`main-header__menu ${exibirMenuMobile && 'is-active'}`}>
          <ul className='menu'>
            {acessarMenuAgendamentos &&
              <li className='menu__item'>
                <span className={`circle-arrow ${exibirMenuAgendamentos && 'is-active'} d-lg-none`} onClick={() => setExibirMenuAgendamentos(!exibirMenuAgendamentos)}></span>
                <span className={`menu__link ${AGENDAMENTOS}`}>Agendamentos</span>
                <div className={`menu__drop ${exibirMenuAgendamentos && 'is-active'}`}>
                  <ListaDeGrupos>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]}>
                        <Link to={`/agendamentos/agenda`} className='menu__drop-link'> Agenda do Profissional</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={permissoesAcessarAgendamentos}>
                        <Link to={`/agendamentos/novo-agendamento`} className='menu__drop-link'>Novo Agendamento (Wizard)</Link>
                        <Link to={`/agendamentos/confirmar-agendamento`} className='menu__drop-link'>Confirmar Agendamento</Link>
                        <Link to={`/agendamentos/informar-chegada-do-paciente`} className='menu__drop-link'>Informar Chegada do Paciente</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE]}>
                        <Link to={`/agendamentos/pesquisa-de-agendamentos`} className='menu__drop-link'>Pesquisa de Agendamentos</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_TIPOS_DE_AGENDAMENTO]}>
                        <Link to={`/agendamentos/tipos-de-agendamento`} className='menu__drop-link'>Tipos de Agendamento</Link>
                      </Permissao>
                    </Grupo>
                  </ListaDeGrupos>
                </div>
              </li>
            }
            {acessarAtendimentos &&
              <li className='menu__item'>
                <span className={`circle-arrow ${exibirMenuAtendimentos && 'is-active'} d-lg-none`} onClick={() => setExibirMenuAtendimentos(!exibirMenuAtendimentos)}></span>
                <span className={`menu__link ${ATENDIMENTOS}`}>Atendimentos</span>
                <div className={`menu__drop ${exibirMenuAtendimentos && 'is-active'}`}>
                  <ListaDeGrupos>
                    <Grupo>
                      <Link to={`/atendimentos/sala-de-espera`} className='menu__drop-link'>Sala de Espera</Link>
                    </Grupo>
                    <Grupo>
                      <Link to={`/atendimentos/nao-assinados`} className='menu__drop-link'>Assinaturas Pendentes</Link>
                    </Grupo>
                  </ListaDeGrupos>
                </div>
              </li>
            }
            {acessarEncaminhamentos &&
              <li className='menu__item'>
                <Link to={`/encaminhamentos`} className={`menu__link ${ENCAMINHAMENTOS}`}>Encaminhamentos</Link>
              </li>
            }
            {acessarTarefas &&
              <li className='menu__item'>
                <Link to={`/tarefas`} className={`menu__link ${TAREFAS}`}>Tarefas</Link>
              </li>
            }
            {acessarCadastros &&
              <li className='menu__item'>
                <span className={`circle-arrow ${exibirMenuCadastros && 'is-active'} d-lg-none`} onClick={() => setExibirMenuCadastros(!exibirMenuCadastros)}></span>
                <span className={`menu__link ${CADASTROS}`}>Cadastros</span>
                <div className={`menu__drop ${exibirMenuCadastros && 'is-active'}`}>
                  <ListaDeGrupos>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_PACIENTES]}>
                        <Link to={`/cadastros/pacientes`} className='menu__drop-link'>Pacientes</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      {/*<Permissao>*/}
                      <Link to={`/cadastros/celulas`} className='menu__drop-link'>Células</Link>
                      {/*</Permissao>*/}
                      <Permissao permissoes={[permissoes.LISTAR_OPERADORAS]}>
                        <Link to={`/cadastros/operadoras`} className='menu__drop-link'>Operadoras</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_PROGRAMAS]}>
                        <Link to={`/cadastros/programas`} className='menu__drop-link'>Programas</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_ASSOCIACOES]}>
                        <Link to={`/cadastros/associacoes`} className='menu__drop-link'>Associações</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_PLANOS_DE_CUIDADO]}>
                        <Link to={`/cadastros/planos-de-cuidado`} className='menu__drop-link'>Planos de Cuidado</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_GRUPOS_DE_CUIDADO]}>
                        <Link to={`/cadastros/grupos-de-cuidado`} className='menu__drop-link'>Grupos de Cuidado</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_ORIENTACOES]}>
                        <Link to={`/cadastros/orientacoes`} className='menu__drop-link'>Orientações</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_ESTABELECIMENTOS_DE_SAUDE]}>
                        <Link to={`/cadastros/estabelecimentos-de-saude`} className='menu__drop-link'>Estabelecimentos de Saúde</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO]}>
                        <Link to={`/cadastros/motivos-de-ida-ao-pronto-atendimento`} className='menu__drop-link'>Motivos de Ida ao PA</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_MOTIVOS_DE_INTERNACAO]}>
                        <Link to={`/cadastros/motivos-de-internacao`} className='menu__drop-link'>Motivos de Internação</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_FABRICANTES]}>
                        <Link to={`/cadastros/fabricantes`} className='menu__drop-link'>Fabricantes</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_MEDICAMENTOS]}>
                        <Link to={`/cadastros/medicamentos`} className='menu__drop-link'>Medicamentos</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_DIAGNOSTICOS]}>
                        <Link to={`/cadastros/diagnosticos`} className='menu__drop-link'>Diagnósticos</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_PROFISSOES]}>
                        <Link to={`/cadastros/profissoes`} className='menu__drop-link'>Profissões</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_PROFISSIONAIS_EXTERNOS]}>
                        <Link to={`/cadastros/profissionais-externos`} className='menu__drop-link'>Profissionais Externos</Link>
                      </Permissao>
                    </Grupo>
                  </ListaDeGrupos>
                </div>
              </li>
            }
            {acessarRelatorios &&
              <li className='menu__item'>
                <span className={`circle-arrow ${exibirMenuRelatorios && 'is-active'} d-lg-none`} onClick={() => setExibirMenuRelatorios(!exibirMenuRelatorios)}></span>
                <span className={`menu__link ${RELATORIOS}`}>Relatórios</span>
                <div className={`menu__drop ${exibirMenuRelatorios && 'is-active'}`}>
                  <ListaDeGrupos>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_RELATORIOS_DO_POWER_BI, permissoes.VISUALIZAR_RELATORIO_DO_POWER_BI]}>
                        <Link to={`/relatorios/dashboards`} className='menu__drop-link'><i className='icon icon-chart-pie' /> Dashboards</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[
                        permissoes.GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
                        permissoes.GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO
                      ]}>
                        <Link to={`/relatorios/resumo-de-internacoes`} className='menu__drop-link'><i className='icon icon-chart-line' /> Internações</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO]}>
                        <Link to={`/relatorios/idas-ao-pronto-atendimento`} className='menu__drop-link'> Idas ao Pronto Atendimento</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_INTERNACOES]}>
                        <Link to={`/relatorios/internacoes`} className='menu__drop-link'>Internações</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_REINTERNACOES]}>
                        <Link to={`/relatorios/reinternacoes`} className='menu__drop-link'>Reinternações</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_PACIENTES]}>
                        <Link to={`/relatorios/pacientes`} className='menu__drop-link'>Pacientes</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA]}>
                        <Link to={`/relatorios/pacientes-do-programa`} className='menu__drop-link'>Pacientes dos Programas</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_ATENDIMENTOS]}>
                        <Link to={`/relatorios/atendimentos`} className='menu__drop-link'>Atendimentos</Link>
                      </Permissao>
                    </Grupo>
                    <Grupo>
                      <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DOS_HORARIOS_DOS_PROFISSIONAIS_DE_SAUDE]}>
                        <Link to={`/relatorios/horas-dos-profissionais`} className='menu__drop-link'>Horas dos Profissionais</Link>
                      </Permissao>
                    </Grupo>
                  </ListaDeGrupos>
                </div>
              </li>
            }
            {acessarConfiguracoes &&
              <li className='menu__item'>
                <span className={`circle-arrow ${exibirMenuConfiguracoes && 'is-active'} d-lg-none`} onClick={() => setExibirMenuConfiguracoes(!exibirMenuConfiguracoes)}></span>
                <span className={`menu__link ${CONFIGURACOES}`}>Configurações</span>
                <div className={`menu__drop ${exibirMenuConfiguracoes && 'is-active'}`}>
                  <ListaDeGrupos>
                    <Grupo>
                      <Permissao permissoes={[permissoes.LISTAR_USUARIOS]}>
                        <Link to={`/configuracoes/usuarios`} className='menu__drop-link'>Usuários</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_EMPRESAS]}>
                        <Link to={`/configuracoes/empresa`} className='menu__drop-link'>Empresa</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_FERIADOS]}>
                        <Link to={`/configuracoes/feriados`} className='menu__drop-link'>Feriados</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_SETORES]}>
                        <Link to={`/configuracoes/setores`} className='menu__drop-link'>Setores</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_TEMPLATES]}>
                        <Link to={`/configuracoes/templates`} className='menu__drop-link'>Templates</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.CONFIGURAR_NPS]}>
                        <Link to={`/configuracoes/nps`} className='menu__drop-link'>NPS</Link>
                      </Permissao>
                      <Permissao permissoes={[permissoes.LISTAR_ESPECIALIDADES]}>
                        <Link to={`/configuracoes/especialidades-medicas-internas`} className='menu__drop-link'>Especialidades Médicas Internas</Link>
                      </Permissao>
                    </Grupo>
                    <Permissao permissoes={[permissoes.LISTAR_USUARIOS_BLOQUEADOS]}>
                      <Grupo>
                        <Link to={`/configuracoes/usuarios-bloqueados`} className='menu__drop-link'>Usuários Bloqueados</Link>
                      </Grupo>
                    </Permissao>
                    <Permissao permissoes={[permissoes.IMPORTAR_PACIENTES]}>
                      <Grupo>
                        <Link to={`/configuracoes/importacao-de-pacientes`} className='menu__drop-link'>Importação de Pacientes</Link>
                      </Grupo>
                    </Permissao>
                    <Permissao permissoes={[permissoes.LISTAR_PEDIDOS_DE_CONTATO]}>
                      <Grupo>
                        <Link to={`/configuracoes/pedidos-de-contato`} className='menu__drop-link'>Pedidos de Contato</Link>
                      </Grupo>
                    </Permissao>
                  </ListaDeGrupos>
                </div>
              </li>
            }
          </ul>
          <div className='menu-account' ref={menuUsuario}>
            <div className='menu-account__pill' style={statusDoSistema ? { borderColor: '' } : { borderColor: 'red' }}>
              <span className={`circle-arrow d-lg-none ${exibirMenuUsuario && 'is-active'}`} onClick={() => setExibirMenuUsuario(!exibirMenuUsuario)}></span>
              <div className='menu-account__pill__info'>
                <p className='menu-account__pill__title' onClick={() => setExibirMenuUsuario(!exibirMenuUsuario)}>{usuario.empresa.nome}</p>
                <p className='menu-account__pill__unity' onClick={() => setExibirMenuUsuario(!exibirMenuUsuario)}>{usuario.unidade.nome}</p>
                {acessarAtendimentosIniciados && usuario.atendimentos.length > 0 &&
                  <p className='menu-account__pill__attendance'
                    onClick={() => setExibirMenuAtendimentosEmAndamento(!exibirMenuAtendimentosEmAndamento)}>
                    {usuario.atendimentos.length} Atendimento{usuario.atendimentos.length > 1 && 's'} em Andamento
                  </p>
                }
              </div>
              <img className='menu-account__pill__avatar' src={usuario.foto || profile} alt='Empresa' />
            </div>
            <div className={`menu-account__attendance-drop ${exibirMenuAtendimentosEmAndamento && 'is-active'}`} ref={menuAtendimentosEmAndamento}>
              <div className='menu-account__attendance-drop__arrow'></div>
              <div className='menu-account__attendance-drop__wrapper'>
                {usuario.atendimentos.map((item, index) =>
                  <Link to={`/cadastros/pacientes/${item.paciente.identificador}/prontuario/${item.identificador}`} className='menu-account__attendance-drop__item' onClick={ocultarMenuAtendimentosEmAndamento} key={index}>
                    {item.paciente.nome}
                  </Link>
                )}
              </div>
            </div>
            <div className={`menu-account__drop ${exibirMenuUsuario && 'is-active'}`}>
              <div className='menu-account__drop__header'>
                <div className='menu-account__drop__info'>
                  <div className='menu-account__drop__text'>
                    <p className='menu-account__drop__title'>{usuario.nome}</p>
                    <p className='menu-account__drop__email'>{usuario.email}</p>
                  </div>
                </div>
              </div>
              <div className='menu-account__drop__body'>
                {podeAlterarDados && <Link to={`/configuracoes/usuarios/${usuario.identificador}`} onClick={ocultarMenuConfiguracoesDoUsuario} className='menu-account__drop__text-link'><i className='icon icon-pacientes'></i> Alterar Meus Dados</Link>}
                {acessarAgenda &&
                  <>
                    <Link to={`/configuracoes/usuarios/${usuario.identificador}/agenda`} onClick={ocultarMenuConfiguracoesDoUsuario} className='menu-account__drop__text-link'><i className='icon icon-agenda-profissional'></i> Agenda</Link>
                    <Link to={`/configuracoes/usuarios/${usuario.identificador}/agenda/tipos-de-agendamento`} onClick={ocultarMenuConfiguracoesDoUsuario} className='menu-account__drop__text-link'><i className='icon icon-agenda'></i> Tipos de Agendamento</Link>
                  </>
                }
                {podeAlterarSenha && <Link to={`/configuracoes/alterar-senha`} onClick={ocultarMenuConfiguracoesDoUsuario} className='menu-account__drop__text-link'><i className='icon icon-edit'></i>Alterar Senha</Link>}
              </div>
              <div className='menu-account__drop__footer'>
                {unidades.length > 1 && <button onClick={mudarUnidade} className='menu-account__drop__bt-change'>Mudar Unidade</button>}
                <button onClick={sair} className='menu-account__drop__bt-logout'>Sair</button>
              </div>
            </div>
          </div>
        </div>
        <button className='bt-menu d-lg-none' onClick={() => setExibirMenuMobile(!exibirMenuMobile)} aria-label='Menu'></button>
      </div>
    </header>
  )
}

export default withRouter(BarraDeMenu)