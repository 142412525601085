import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_OPERADORAS_DO_PACIENTE,
  LISTOU_OPERADORAS_DO_PACIENTE,
  ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE,
} from '../tipos'

const listouOperadorasDoPaciente = operadoras => ({
  type: LISTOU_OPERADORAS_DO_PACIENTE,
  operadoras,
})

const erroAoListarOperadorasDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE,
  erro,
})

export const listarOperadorasDoPaciente = () => async dispatch => {
  dispatch({ type: LISTAR_OPERADORAS_DO_PACIENTE })

  try {
    let resultado = await api.listarOperadoras()

    dispatch(listouOperadorasDoPaciente(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOperadorasDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as operadoras do paciente.')))
  }
}