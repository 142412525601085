import React, { useState } from 'react'

const BoxAgendaOver = function ({ children }) {
  const [mouseOverStyle, setMouseOverStyle] = useState('')

  const alteraStyle = () => {
    setMouseOverStyle('box-agenda__event__mouse-over')
  }

  if (!children) return children

  return (
    <div onMouseOver={alteraStyle} onMouseOut={() => setMouseOverStyle('')}>
      {children({ ...children.props, mouseOverStyle })}
    </div>
  )
}

export default BoxAgendaOver