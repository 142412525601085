import api from '../../api-major'

const adicionarIdaAoProntoAtendimentoDoPaciente = (identificador, idaAoPa) => api.post(`/pacientes/${identificador}/idas-ao-pronto-atendimento`, idaAoPa)
const alterarIdaAoProntoAtendimentoDoPaciente = (identificador, idaAoPa) => api.put(`/pacientes/${identificador}/idas-ao-pronto-atendimento/${idaAoPa.identificador}`, idaAoPa)
const removerIdaAoProntoAtendimentoDoPaciente = (identificador, identificadorDaIdaAoPa) => api.delete(`/pacientes/${identificador}/idas-ao-pronto-atendimento/${identificadorDaIdaAoPa}`)
const listarIdasAoProntoAtendimentoDoPaciente = identificador => api.get(`/pacientes/${identificador}/idas-ao-pronto-atendimento`)

export default { 
  adicionarIdaAoProntoAtendimentoDoPaciente,
  alterarIdaAoProntoAtendimentoDoPaciente,
  removerIdaAoProntoAtendimentoDoPaciente,
  listarIdasAoProntoAtendimentoDoPaciente
}