import { RECUPEROU_LOGIN } from '../../acoes/tipos'

export default (state = {}, action) => {
  switch (action.type) {
    case RECUPEROU_LOGIN: {
      return {
        ...state,
        [action.login.email]: action.login
      }
    }

    default: {
      return state
    }
  }
}