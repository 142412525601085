import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {  
  LISTAR_CIDS10,
  LISTOU_CIDS10,
  ERRO_AO_LISTAR_CIDS10,
} from './tipos'

const listouCids10 = cids10 => ({
  type: LISTOU_CIDS10,
  cids10,
})

const erroAoListarCids10 = erro => ({
  type: ERRO_AO_LISTAR_CIDS10,
  erro,
})

export const listarCids10 = () => async dispatch => {
  dispatch({ type: LISTAR_CIDS10 })

  try {
    const resultado = await api.listarCids10()
    
    dispatch(listouCids10(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCids10(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os CIDs-10.')))
  }
}