import React, { Children } from 'react'
import { Link } from 'react-router-dom'
import Permissao from '../../seguranca/permissao'

export function ListaDeGrupos({ children }) {
  function getGrupoContext(child) {
    return child.type === Grupo ? child : getGrupoContext(child.props.children)
  }
  
  const grupos = Children.toArray(children).map((child, index) => {
    if (child.type === Grupo) {
      return React.cloneElement(child, {
        ultimoGrupo: !children.length || index + 1 === children.length,
      })
    }

    return React.cloneElement(child, {
      children: {
        ...getGrupoContext(child),
        props: {
          ...getGrupoContext(child).props,
          ultimoGrupo: !children.length || index + 1 === children.length,
        }
      }
    })

  })

  return grupos
}

export function Grupo({ children, ultimoGrupo }) {
  function getPermissaoContext(child) {
    if (child.type === Link) return null
    return child.type === Permissao ? child : getPermissaoContext(child.props.children)
  }

  let temAoMenosUmaPermissao = false

  Children.toArray(children).forEach((child, index) => {
    const permissao = getPermissaoContext(child)
    const possui = permissao ? Permissao({ permissoes: permissao.props.permissoes, children: true }) : true
    temAoMenosUmaPermissao = possui ? true : temAoMenosUmaPermissao
  })

  return (
    <>
      {children}
      {!ultimoGrupo && temAoMenosUmaPermissao && <hr />}
    </>
  )
}