import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from './notificacoes'

import {
    ADICIONAR_CELULA,
    ADICIONOU_CELULA,
    ALTERAR_CELULA,
    ALTEROU_CELULA,
    ERRO_AO_ADICIONAR_CELULA,
    ERRO_AO_ALTERAR_CELULA,
    ERRO_AO_LISTAR_CELULAS,
    ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR,
    ERRO_AO_REMOVER_CELULA,
    ERRO_AO_LISTAR_CELULAS_DO_CADASTRO,
    LISTAR_CELULAS,
    LISTAR_CELULAS_DO_CADASTRO,
    LISTOU_CELULAS,
    LISTOU_CELULAS_DO_CADASTRO,
    RECUPERAR_CELULA_PELO_IDENTIFICADOR,
    RECUPEROU_CELULA_PELO_IDENTIFICADOR,
    REMOVER_CELULA,
    REMOVEU_CELULA,
} from './tipos'

const listouCelulas = (celulas) => ({
    type: LISTOU_CELULAS,
    celulas
})

const erroAoListarCelulas = erro => ({
    type: ERRO_AO_LISTAR_CELULAS,
    erro
})

export const listarCelulas = () => async dispatch => {
    dispatch({ type: LISTAR_CELULAS })

    try {
        const resultado = await api.listarCelulas()

        dispatch(listouCelulas(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

    } catch (erro) {
        dispatch(erroAoListarCelulas(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as células.')))
    }
}

const listouCelulasDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
    type: LISTOU_CELULAS_DO_CADASTRO,
    filtros,
    paginaDeDados,
    ordenacao
})

const erroAoListarCelulasDoCadastro = erro => ({
    type: ERRO_AO_LISTAR_CELULAS_DO_CADASTRO,
    erro
})

export const listarCelulasDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
    dispatch({ type: LISTAR_CELULAS_DO_CADASTRO })

    try {
        const resultado = await api.listarCelulas(filtros, pagina, ordenacao, tamanhoDaPagina)

        dispatch(listouCelulasDoCadastro(filtros, resultado.data.dados, ordenacao))
        dispatch(adicionarNotificacoesDaApi(resultado))

    } catch (erro) {
        dispatch(erroAoListarCelulasDoCadastro(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as células.')))
    }
}

const adicionouCelula = (celula) => ({
    type: ADICIONOU_CELULA,
    celula
})

const erroAoAdicionarCelula = (erro, parametros) => ({
    type: ERRO_AO_ADICIONAR_CELULA,
    parametros,
    erro
})

export const adicionarCelula = celula => async dispatch => {
    dispatch({type: ADICIONAR_CELULA})

    try {
        const resultado = await api.adicionarCelula(celula)

        dispatch(adicionouCelula(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoAdicionarCelula(erro, { celula }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar a célula.')))

        return false
    }
}

const recuperouCelulaPeloIdentificador = celula => ({
    type: RECUPEROU_CELULA_PELO_IDENTIFICADOR,
    celula
})

const erroAoRecuperarCelulaPeloIdentificador = (erro, parametros) => ({
    type: ERRO_AO_RECUPERAR_CELULA_PELO_IDENTIFICADOR,
    erro,
    parametros
})

export const recuperarCelulaPeloIdentificador = identificador => async dispatch => {
    dispatch({ type: RECUPERAR_CELULA_PELO_IDENTIFICADOR })

    try {
        const resultado = await api.recuperarCelulaPeloIdentificador(identificador)

        dispatch(recuperouCelulaPeloIdentificador(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoRecuperarCelulaPeloIdentificador(erro, { identificador }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a célula por identificador.')))
    }
}

const alterouCelula = (celula) => ({
    type: ALTEROU_CELULA,
    celula
})

const erroAoAlterarCelula = (erro, parametros) => ({
    type: ERRO_AO_ALTERAR_CELULA,
    parametros,
    erro
})

export const alterarCelula = celula => async dispatch => {
    dispatch({type: ALTERAR_CELULA})

    try {
        const resultado = await api.alterarCelula(celula)

        dispatch(alterouCelula(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoAlterarCelula(erro, { celula }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a célula.')))

        return false
    }
}

const removeuCelula = (celula) => ({
    type: REMOVEU_CELULA,
    celula
})

const erroAoRemoverCelula = (erro, parametros) => ({
    type: ERRO_AO_REMOVER_CELULA,
    erro,
    parametros
})

export const removerCelula = identificador => async dispatch => {
    dispatch({type: REMOVER_CELULA})

    try {
        const resultado = await api.removerCelula(identificador)

        dispatch(removeuCelula(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoRemoverCelula(erro, { identificador }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a célula.')))

        return false
    }
}