import api from './api-major'

const listarMotivosDeIdaAoProntoAtendimento = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroStatus = filtros.status !== undefined && filtros.status.length === 1 ? `&status=${filtros.status[0]}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`motivos-de-ida-ao-pronto-atendimento${paginacao}${tamanhoDaPaginaFormatado}${parametroStatus}${parametroNome}${ordem}`)
}

const adicionarMotivoDeIdaAoProntoAtendimento = motivo => api.post(`/motivos-de-ida-ao-pronto-atendimento/`, motivo)
const recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador = identificador => api.get(`/motivos-de-ida-ao-pronto-atendimento/${identificador}`)
const alterarMotivoDeIdaAoProntoAtendimento = motivo => api.put(`/motivos-de-ida-ao-pronto-atendimento/${motivo.identificador}`, motivo)
const removerMotivoDeIdaAoProntoAtendimento = identificador => api.delete(`/motivos-de-ida-ao-pronto-atendimento/${identificador}`)

export default {
  listarMotivosDeIdaAoProntoAtendimento,
  adicionarMotivoDeIdaAoProntoAtendimento,
  recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador,
  alterarMotivoDeIdaAoProntoAtendimento,
  removerMotivoDeIdaAoProntoAtendimento
}