import React from 'react'
import Breadcrumb from '../../breadcrumb'
import ListaDeProfissionais from './lista-de-profissionais'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import lupa from '../../../design/img/lupa.png'

export default function AgendaInicial(props) {
  const {
    alterarTermoDeProfissionaisDeSaudeDaAgenda,
    calendario,
    filtros,
    fotos,
    match,
    profissional,
    profissionaisDeSaudeFiltrados,
  } = props

  const podeVerAgendaDeOutrosProfissionais = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE])

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form mb-2'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
              <r-cell span={4} span-md={2} span-lg={3}>
                <Breadcrumb />
              </r-cell>
            </r-grid>
          </div>
          <div className='box-panel --narrow'>
            {podeVerAgendaDeOutrosProfissionais &&
              <ListaDeProfissionais
                alterarTermoDeProfissionaisDeSaudeDaAgenda={alterarTermoDeProfissionaisDeSaudeDaAgenda}
                data={calendario.data}
                filtros={filtros}
                fotos={fotos}
                history={props.history}
                match={match}
                profissional={profissional}
                profissionaisDeSaudeFiltrados={profissionaisDeSaudeFiltrados}
              />
            }
            <div className={`box-panel__content p-0 ${podeVerAgendaDeOutrosProfissionais ? '' : 'vw-100'}`}>
              <div className='table-items__result'>
                <button className='icon-tabela-vazia'>
                  <img src={lupa} alt='tabelaVazia' />
                </button>
                <p>Selecione um profissional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}