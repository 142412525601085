import moment from 'moment'

import {
  LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
} from '../../../acoes/tipos'

const estadoInicial = {
  filtros: {
    data: moment().format('YYYY-MM-DD'),
  },
  paginaDeDados: {},
  unidades: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE: {
      return {
        ...state,
        filtros: action.filtros,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case LISTOU_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA: {
      return {
        ...state,
        unidades: action.unidades,
      }
    }

    default: {
      return state
    }
  }
}