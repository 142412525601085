import React, {useEffect, useMemo} from 'react'
import {SelectReact} from '../../formik/select'
import {useTarefas} from '../contexto'
import Spinner from '../../spinner'
import {LISTAR_CATEGORIAS_DO_TAREFAS} from '../../../acoes/tipos'

export default function Categoria({ alterar, valor }) {
  const { listarCategoriasDoTarefas, categorias } = useTarefas()

  useEffect(() => {
    if (!categorias) {
      listarCategoriasDoTarefas()
    }
  }, [listarCategoriasDoTarefas, categorias])

  const optionsCategorias = useMemo(() => categorias?.map(x => ({
    value: x.codigo,
    label: x.nome,
  })), [categorias])

  return (
    <div className='form-group'>
      <label className='form-label'>Categoria</label>
      <Spinner operacoes={[LISTAR_CATEGORIAS_DO_TAREFAS]}>
        {({processando}) =>
          <SelectReact
            nome='categoria'
            id='categoria'
            onChange={alterar}
            options={optionsCategorias}
            valor={valor}
            isLoading={processando}
          />
        }
      </Spinner>
    </div>
  )
}
