import api from '../api-major'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

const fazerDownloadDoRelatorioDeHorasDosProfissionais = ({ profissao, dataDeInicio, dataDeFim, status, situacao }) => {
  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  return api.get(`/agenda/horarios/csv?inicio=${inicio}&fim=${fim}&profissao=${profissao}&ativos=${situacao}&status=${status}`, { responseType: 'arraybuffer' })
}

export default {
  fazerDownloadDoRelatorioDeHorasDosProfissionais,
}