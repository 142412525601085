import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  LIMPAR_AGENDAMENTO_ATUAL,
  LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO
} from '../../tipos'

const listouPacientesDoWizardDeAgendamento = (filtros, ordem, pacientes) => ({
  type: LISTOU_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  filtros,
  ordem,
  pacientes,
})

const erroAoListarPacientesDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const listarPacientesDoWizardDeAgendamento = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO })

    try {
      const retorno = await api.listarPacientes(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouPacientesDoWizardDeAgendamento(filtros, ordem, retorno.data.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarPacientesDoWizardDeAgendamento(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes do agendamento.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
    }
  }

  return resultado
}

export const limparDadosDoPacienteDoWizardDeAgendamento = () => ({
  type: LIMPAR_DADOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
})

export const limparAgendamentoAtual = () => ({ type: LIMPAR_AGENDAMENTO_ATUAL })

const selecionouPacienteDoWizardDeAgendamento = pacienteSelecionado => ({
  type: SELECIONOU_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  pacienteSelecionado,
})

const erroAoSelecionarPacienteDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const selecionarPacienteDoWizardDeAgendamento = identificador => async dispatch => {
  dispatch({ type: SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.recuperarPacientePorIdentificador(identificador)
    const programas = await api.listarProgramasDoPaciente(identificador)
    const paciente = {
      ...resultado.data.dados,
      programas: programas.data.dados.dados.filter(x => !x.motivoDeSaida)
    }

    dispatch(selecionouPacienteDoWizardDeAgendamento(identificador ? paciente : {}))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSelecionarPacienteDoWizardDeAgendamento(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao selecionar paciente.')))

    return false
  }
}

export const selecionarContatosDoPacienteDoWizardDeAgendamento = (dadosDeContato) => ({
  type: SELECIONAR_CONTATOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  dadosDeContato
})

export const selecionarConvenioDoPacienteDoWizardDeAgendamento = identificador => ({
  type: SELECIONAR_CONVENIO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  identificador
})

const recuperouConveniosDoPacienteDoWizardDeAgendamento = convenios => ({
  type: RECUPEROU_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  convenios,
})

const erroAoRecuperarConveniosDoPacienteDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const recuperarConveniosDoPacienteDoWizardDeAgendamento = identificadorDoPaciente => async dispatch => {
  dispatch({ type: RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO })

  try {
    let resultado = await api.recuperarConveniosDoPacienteSelecionadoDoAgendamento(identificadorDoPaciente)
    let convenios = resultado.data.dados.dados.filter(x => x.status === 'Ativo')

    dispatch(recuperouConveniosDoPacienteDoWizardDeAgendamento(convenios))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarConveniosDoPacienteDoWizardDeAgendamento(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao recuperar os convênios do paciente selecionado.')))

    return false
  }
}