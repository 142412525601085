import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../spinner'
import { RECUPERAR_TELECONSULTA_DO_AGENDAMENTO } from '../../../acoes/tipos'
import { recuperarTeleconsultaDoAgendamento } from '../../../acoes/teleconsulta'

export default function LinksDaTeleconsulta({ agendamento }) {
  const dispatch = useDispatch()

  const { teleconsulta: { teleconsulta, links } } = useSelector(state => state.agendamentos.agendamento)

  useEffect(() => {
    dispatch(recuperarTeleconsultaDoAgendamento(agendamento))
  }, [dispatch, agendamento])

  if (!teleconsulta) {
    return null
  }

  return (
    <Spinner operacoes={[RECUPERAR_TELECONSULTA_DO_AGENDAMENTO]}>
      {({ processando }) => {
        if (processando) return <></>
        return (
          <div className='unity-infos__item'>
            <span>
              <strong>Links para a vídeo consulta:</strong>
              {' '}
              {'Paciente'}
              {<button title='Copiar' className='botao-historico' type='button' onClick={() => { navigator.clipboard.writeText(links.paciente) }}><i className='icon icon-copy'></i></button>}
              {' '}{' '}{' '}
              {'Convidado'}
              {<button title='Copiar' className='botao-historico' type='button' onClick={() => { navigator.clipboard.writeText(links.convidado) }}><i className='icon icon-copy'></i></button>}
            </span>
          </div>
        )
      }}
    </Spinner>
  )
}