import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_GRUPOS_DE_CUIDADO,
  LISTOU_GRUPOS_DE_CUIDADO,
  ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO,
  ADICIONAR_GRUPO_DE_CUIDADO,
  ADICIONOU_GRUPO_DE_CUIDADO,
  ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO,
  RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTERAR_GRUPO_DE_CUIDADO,
  ALTEROU_GRUPO_DE_CUIDADO,
  ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO,
  REMOVER_GRUPO_DE_CUIDADO,
  REMOVEU_GRUPO_DE_CUIDADO,
  ERRO_AO_REMOVER_GRUPO_DE_CUIDADO,
  LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO,
  ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO,
  ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO
} from './tipos'

const listouGruposDeCuidado = gruposDeCuidado => ({
  type: LISTOU_GRUPOS_DE_CUIDADO,
  gruposDeCuidado,
})

const erroAoListarGruposDeCuidado = erro => ({
  type: ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO,
  erro,
})

export const listarGruposDeCuidado = ativo => async dispatch => {
  dispatch({ type: LISTAR_GRUPOS_DE_CUIDADO })

  try {
    const resultado = await api.listarGruposDeCuidado(ativo)

    dispatch(listouGruposDeCuidado(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarGruposDeCuidado(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os grupos de cuidado.')))
  }
}

const erroAoListarGruposDeCuidadoDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  erro,
  parametros
})

const listouGruposDeCuidadoDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarGruposDeCuidadoDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_GRUPOS_DE_CUIDADO_DO_CADASTRO })

  try {
    const resultado = await api.listarGruposDeCuidado(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouGruposDeCuidadoDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarGruposDeCuidadoDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os grupos de cuidado.')))
  }
}

const adicionouGrupoDeCuidado = grupoDeCuidado => ({
  type: ADICIONOU_GRUPO_DE_CUIDADO,
  grupoDeCuidado
})

const erroAoAdicionarGrupoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_GRUPO_DE_CUIDADO,
  erro,
  parametros
})

export const adicionarGrupoDeCuidado = grupoDeCuidado => async dispatch => {
  dispatch({ type: ADICIONAR_GRUPO_DE_CUIDADO })

  try {
    const resultado = await api.adicionarGrupoDeCuidado(grupoDeCuidado)

    dispatch(adicionouGrupoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarGrupoDeCuidado(erro, { grupoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o grupo de cuidado.')))

    return false
  }
}

const recuperouGrupoDeCuidadoPeloIdentificador = grupoDeCuidado => ({
  type: RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  grupoDeCuidado
})

const erroAoRecuperarGrupoDeCuidadoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarGrupoDeCuidadoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarGrupoDeCuidadoPeloIdentificador(identificador)

    dispatch(recuperouGrupoDeCuidadoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarGrupoDeCuidadoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o grupo de cuidado pelo identificador.')))
  }
}

const alterouGrupoDeCuidado = grupoDeCuidado => ({
  type: ALTEROU_GRUPO_DE_CUIDADO,
  grupoDeCuidado
})

const erroAoAlterarGrupoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_GRUPO_DE_CUIDADO,
  erro,
  parametros
})

export const alterarGrupoDeCuidado = grupoDeCuidado => async dispatch => {
  dispatch({ type: ALTERAR_GRUPO_DE_CUIDADO })

  try {
    const resultado = await api.alterarGrupoDeCuidado(grupoDeCuidado)

    dispatch(alterouGrupoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarGrupoDeCuidado(erro, { grupoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o grupo de cuidado.')))

    return false
  }
}

const removeuGrupoDeCuidado = grupoDeCuidado => ({
  type: REMOVEU_GRUPO_DE_CUIDADO,
  grupoDeCuidado
})

const erroAoRemoverGrupoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_GRUPO_DE_CUIDADO,
  erro,
  parametros
})

export const removerGrupoDeCuidado = identificador => async dispatch => {
  dispatch({ type: REMOVER_GRUPO_DE_CUIDADO })

  try {
    const resultado = await api.removerGrupoDeCuidado(identificador)

    dispatch(removeuGrupoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverGrupoDeCuidado(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o grupo de cuidado.')))

    return false
  }
}

const alterouStatusDoGrupoDeCuidado = grupoDeCuidado => ({
  type: ALTEROU_STATUS_DO_GRUPO_DE_CUIDADO,
  grupoDeCuidado,
})

const erroAoAlterarStatusDoGrupoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO,
  erro,
  parametros
})

export const alterarStatusDoGrupoDeCuidado = grupoDeCuidado => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO })

  try {
    const resultado = await api.alterarGrupoDeCuidado(grupoDeCuidado)

    dispatch(alterouStatusDoGrupoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoGrupoDeCuidado(erro, { grupoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o status do grupo de cuidado.')))

    return false
  }
}