import {
  LISTOU_RESPOSTAS_VALIDAS_SOBRE_VOMITO
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_RESPOSTAS_VALIDAS_SOBRE_VOMITO: {
      return action.respostasVomito
    }

    default: {
      return state
    }
  }
}
