export const ATENDIMENTOS_MENSAIS = 'ATENDIMENTOS_MENSAIS'
export const CONFIRMACOES_DE_AGENDAMENTOS = 'CONFIRMACOES_DE_AGENDAMENTOS'
export const GESTAO_DE_ENCAMINHAMENTOS = 'GESTAO_DE_ENCAMINHAMENTOS'
export const GESTAO_DA_AGENDA = 'GESTAO_DA_AGENDA'
export const INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO = 'INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR = 'INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR'
export const INDICADOR_DE_REINTERNACAO = 'INDICADOR_DE_REINTERNACAO'
export const INDICADORES_CONTRATUAIS_UN = 'INDICADORES_CONTRATUAIS_UN'
export const INDICADORES_DE_DESFECHO_CLINICO = 'INDICADORES_DE_DESFECHO_CLINICO'
export const INDICADORES_DE_IMPACTO = 'INDICADORES_DE_IMPACTO'
export const INDICADORES_DE_MANUTENCAO_DO_CUIDADO = 'INDICADORES_DE_MANUTENCAO_DO_CUIDADO'
export const INDICADORES_DEMOGRAFICOS = 'INDICADORES_DEMOGRAFICOS'
export const INDICADORES_DO_PERFIL_DE_FRAGILIDADE = 'INDICADORES_DO_PERFIL_DE_FRAGILIDADE'
export const INDICADORES_OPERACIONAIS = 'INDICADORES_OPERACIONAIS'
export const VOLUMETRIA = 'VOLUMETRIA'
export const NAVEGACAO_MEDICA = 'NAVEGACAO_MEDICA'
export const NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO = 'NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO'
export const NPS = 'NPS'
export const PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR = 'PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR'
export const PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA = 'PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA'
export const PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN = 'PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN'
export const PLANO_DE_CUIDADOS_CONTRATUAL_UN = 'PLANO_DE_CUIDADOS_CONTRATUAL_UN'
export const PLANO_DE_CUIDADOS_ENFERMAGEM = 'PLANO_DE_CUIDADOS_ENFERMAGEM'
export const PLANO_DE_CUIDADOS_MEDICO = 'PLANO_DE_CUIDADOS_MEDICO'
export const PLANO_DE_CUIDADOS_UN = 'PLANO_DE_CUIDADOS_UN'
export const RESOLUTIVIDADE_DO_PRONTO_CUIDAR = 'RESOLUTIVIDADE_DO_PRONTO_CUIDAR'
export const VACINACAO = 'VACINACAO'