import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  FEZ_DOWNLOAD_DE_ATENDIMENTOS,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS
} from '../tipos'

const listouProgramasDoRelatorioDeAtendimentos = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  programas
})

const erroAoListarProgramasDoRelatorioDeAtendimentos = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  erro
})

export const listarProgramasDoRelatorioDeAtendimentos = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS })
  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDeAtendimentos(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDeAtendimentos(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os programas do relatório de atendimentos.')))
  }
}

const fezDownloadDeAtendimentos = filtros => ({
  type: FEZ_DOWNLOAD_DE_ATENDIMENTOS,
  filtros
})

const erroAoFazerDownloadDeAtendimentos = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  erro,
  parametros
})

export const fazerDownloadDeAtendimentos = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_ATENDIMENTOS })

  try {
    const resultado = await api.fazerDownloadDeAtendimentos(filtros)

    dispatch(fezDownloadDeAtendimentos(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDeAtendimentos(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do relatório de atendimentos.')))

    return null
  }
}