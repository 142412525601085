import {
  LISTOU_ANTIBIOTICOS
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_ANTIBIOTICOS: {
      return action.antibioticos
    }

    default: {
      return state
    }
  }
}