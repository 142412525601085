import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_TEXTO_LONGO_DA_TAREFA,
  DEFINIU_TEXTO_LONGO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA
} from '../tipos'

const definiuTextoLongoDaTarefa = (identificadorDaTarefa, campo, conteudo) => ({
  identificadorDaTarefa,
  campo,
  conteudo,
  type: DEFINIU_TEXTO_LONGO_DA_TAREFA,
})

const erroAoDefinirTextoLongoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA,
  erro,
  parametros,
})

export const definirTextoLongoDaTarefa = (identificadorDaTarefa, campo, conteudo) => async dispatch => {
  dispatch({ type: DEFINIR_TEXTO_LONGO_DA_TAREFA })

  try {
    const resultado = await api.definirTextoLongoDaTarefa(identificadorDaTarefa, campo, conteudo)

    dispatch(definiuTextoLongoDaTarefa(identificadorDaTarefa, campo, conteudo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirTextoLongoDaTarefa(erro, { identificadorDaTarefa, campo, conteudo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o texto longo da tarefa.')))

    return false
  }
}