import { LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA: {
      return action.tipos
    }

    default: {
      return state
    }
  }
}