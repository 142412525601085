import unidades from './unidades'

import {
  ALTEROU_UNIDADE_DO_LOGIN,
  RECUPEROU_EMPRESA,
  REMOVEU_FOTO_DA_EMPRESA,
  SALVOU_EMPRESA
} from '../../acoes/tipos'

const estadoInicial = {
  unidades: undefined,
}

const empresa = (state, action) => {
  switch (action.type) {
    case SALVOU_EMPRESA:
    case RECUPEROU_EMPRESA: {
      return {
        ...action.empresa,
        unidades: state.unidades
      }
    }

    case REMOVEU_FOTO_DA_EMPRESA: {
      return {
        ...state,
        logotipo: null,
        unidades: state.unidades
      }
    }

    case ALTEROU_UNIDADE_DO_LOGIN: {
      return estadoInicial
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = empresa(state, action)

  return {
    ...dados,
    unidades: unidades(state.unidades, action),
  }
}