import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { gravarFotoNoCache, recuperarFotoDoCache } from '../bibliotecas/imagem'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
  RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR
} from './tipos';

const recuperouMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador = (identificadorDaFoto, miniaturaDaFoto) => ({
  type: RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
  identificadorDaFoto,
  miniaturaDaFoto,
})

const erroAoRecuperarDaMiniaturaFotoDoProfissionalDeSaudePorIdentificador = (identificadorDaFoto, erro) => ({
  type: ERRO_AO_RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
  identificadorDaFoto,
  erro,
})

export const recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador = (identificadorDoProfissional, identificadorDaFoto) => async dispatch => {
  dispatch({
    type: RECUPERAR_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
    identificadorDaFoto,
  })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDaFoto)

    if (itemDoCache) {      
      dispatch(recuperouMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(identificadorDaFoto, itemDoCache))
      return
    }    

    const resultado = await api.recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(identificadorDoProfissional)
    const miniaturaDaFoto = gravarFotoNoCache(resultado.data, identificadorDaFoto)    

    dispatch(recuperouMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(identificadorDaFoto, miniaturaDaFoto))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarDaMiniaturaFotoDoProfissionalDeSaudePorIdentificador(identificadorDaFoto, erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a miniatura da foto do profissional.')))
  }
}