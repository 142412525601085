import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_LOCAIS_DAS_DORES,
  LISTOU_LOCAIS_DAS_DORES,
  ERRO_AO_LISTAR_LOCAIS_DAS_DORES,
} from './tipos'

const listouLocaisDasDores = locaisDasDores => ({
  type: LISTOU_LOCAIS_DAS_DORES,
  locaisDasDores,
})

const erroAoListarLocaisDasDores = erro => ({
  type: ERRO_AO_LISTAR_LOCAIS_DAS_DORES,
  erro,
})

export const listarLocaisDasDores = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_LOCAIS_DAS_DORES })

    try {
      const resultado = await api.listarLocaisDasDores()

      dispatch(listouLocaisDasDores(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarLocaisDasDores(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os locais das dores.')))
    }
  }

  acao.meta = {
    cache: LISTAR_LOCAIS_DAS_DORES
  }

  return acao
}