import {connect} from 'react-redux'
import acoes from '../../acoes'
import Tarefas from './index'

const mapStateToProps = state => {
  let tarefa = state.tarefas.itemAtual

  const recuperarTarefa = (tarefa = null) => {
    if (!tarefa || !tarefa.tipo) return null

    const tipo = state.tarefas.tipos[tarefa.tipo.identificador]
    const versao = tipo ? tipo.versoes.find(x => x.numero === tarefa.tipo.versao) : null

    return {
      ...tarefa,
      tipo: {
        ...tarefa.tipo,
        status: versao ? versao.status : null,
      }
    }
  }

  const recuperarAbaAtual = (filtros, tipoDaTarefaAtual) => {
    if (filtros) {
      const filtrosDaTarefa = filtros[tipoDaTarefaAtual]
      const abaAtual = filtrosDaTarefa && filtrosDaTarefa.abaAtual
      return abaAtual
    }

    return filtros
  }

  const recuperarFiltros = (filtros, tipoDaTarefaAtual) => {
    if (filtros) {
      const filtrosDaTarefa = filtros[tipoDaTarefaAtual]
      const filtrosDaAba = filtrosDaTarefa && filtrosDaTarefa[filtrosDaTarefa.abaAtual]

      return filtrosDaAba
    }


    return filtros
  }

  return {
    abaAtual: recuperarAbaAtual(state.tarefas.filtrosAtuais, state.tarefas.tipoDaTarefaAtual),
    filtrosAtuais: recuperarFiltros(state.tarefas.filtrosAtuais, state.tarefas.tipoDaTarefaAtual),
    itens: state.tarefas.itens,
    tarefa: recuperarTarefa(tarefa),
    tiposDeTarefa: state.tarefas.tipos,
    profissionaisResponsaveis: state.tarefas.profissionaisResponsaveis,
    setoresResponsaveis: state.tarefas.setoresResponsaveis,
    powerBI: state.integracoes.powerBI,
    tipoDaTarefaAtual: state.tarefas.tipoDaTarefaAtual,
    semTipoSelecionado: state.tarefas.tipoDaTarefaAtual && state.tarefas.tipoDaTarefaAtual === 'todas',
    todosOsFiltros: state.tarefas.filtrosAtuais,
  }
}

export default connect(mapStateToProps, acoes)(Tarefas)