import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Element } from 'react-scroll'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Breadcrumb from '../../breadcrumb'
import constantes from '../../../configuracoes/constantes'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, Opcoes } from '../../formik/formulario'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { dataIgualOuAnterior } from '../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import moment from 'moment'
import * as Yup from 'yup'
import avatar from '../../../design/img/no-thumb.png'
import ConfirmarAgendamento from './modais/confirmar-agendamento'
import ConcluirAgendamentos from './modais/concluir-agendamento'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import ContatosDoAgendamento from '../agendamento/contatos-do-agendamento'

import {
  LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO
} from '../../../acoes/tipos'

export default function Passo3(props) {
  const {
    adicionarAgendamento,
    agendamentosRealizados,
    convenios,
    dataBaseHorariosLivres,
    excluirAgendamento,
    fotosDosPacientes,
    fotosDosProfissionais,
    history,
    horarios,
    limparDadosDoAgendamento,
    limparDadosDoPaciente,
    limparHorariosLivresDoAgendamento,
    listarHorariosLivresDoAgendamento,
    listarPacientesDoWizardDeAgendamento,
    listarProfissionaisDeSaudeDoAgendamento,
    listarRecorrenciasDoAgendamento,
    listarUnidadesDaEmpresaDoAgendamento,
    ordem,
    pacienteSemPrograma,
    profissoes,
    profissionaisDeSaude,
    proximosAgendamentos,
    recorrenciasDoAgendamento,
    recuperarFotoDoPacientePorIdentificador,
    recuperarFotoDoUsuarioPorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    tiposDeAgendamento,
    sexos,
    unidades,
    paciente,
    convenioSelecionado,
    parametrosRota,
    contatos,
    filtros: {
      especialidadeSelecionada,
      profissaoSelecionada,
      profissionaisSelecionados,
      tipoDeAgendamentoSelecionado
    }
  } = props

  const [exibirConfirmacaoDeAgendamento, setExibirConfirmacaoDeAgendamento] = useState(false)
  const [exibirConclusaoDeAgendamento, setExibirConclusaoDeAgendamento] = useState(false)
  const [horarioSelecionado, setHorarioSelecionado] = useState({})
  const [unidadeSelecionada, setUnidadeSelecionada] = useState([])
  const [inicio, setInicio] = useState('')
  const [fim, setFim] = useState('')
  const [sexoDoProfissional, setSexoDoProfissional] = useState('')
  const [turno, setTurno] = useState([])
  const [semana, setSemana] = useState([])
  const [listar, setListar] = useState(true)
  const [profissao, setProfissao] = useState(profissaoSelecionada)
  const [especialidade, setEspecialidade] = useState(especialidadeSelecionada)
  const [profissionaisDeSaudeSelecionados, setProfissionaisDeSaudeSelecionados] = useState(profissionaisSelecionados)
  const [tipoDeAgendamento, setTipoDeAgendamento] = useState(tipoDeAgendamentoSelecionado)
  const [tipoDeAgendamentoPreSelecionado, setTipoDeAgendamentoPreSelecionado] = useState(tipoDeAgendamentoSelecionado)
  const semConvenio = convenioSelecionado === 'naoCadastrado' || !convenioSelecionado
  const convenio = !semConvenio && convenios.find(x => x.identificador === convenioSelecionado).descricao
  const profissionais = profissao && profissao.nome ? profissionaisDeSaude.filter(x => x.profissao.nome === profissao.nome) : profissionaisDeSaude
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE])
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const referenciaDoRelato = useRef(null)

  useCliqueForaDoElemento(referenciaDoRelato, () => setExibirTooltipInfo(false))

  const [filtrosAplicados, setFiltrosAplicados] = useState({
    profissional: profissionaisDeSaudeSelecionados,
    inicio: dataBaseHorariosLivres,
    fim: moment(dataBaseHorariosLivres).add(1, 'month'),
    profissao: profissao,
    especialidade: especialidade,
    tipoDeAgendamento: tipoDeAgendamento,
    sexoDoProfissional: sexoDoProfissional,
    turno: turno,
    semana: semana,
    unidades: unidadeSelecionada
  })

  const selecionarProfissao = useCallback(nome => {
    const profissao = profissoes.find(x => x.nome === nome)
    setProfissao(profissao)
    setProfissionaisDeSaudeSelecionados([])
    setEspecialidade('')
  }, [profissoes, setProfissao, setProfissionaisDeSaudeSelecionados, setEspecialidade])

  const selecionarEspecialidade = especialidade => {
    setEspecialidade(especialidade)
    setProfissionaisDeSaudeSelecionados([])
  }

  useEffect(() => {
    paciente && [paciente].filter(x => x.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.identificador, item.foto)
    })

    return () => paciente && [paciente].filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })

  }, [paciente, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  const filtrarProfissionaisDeSaude = (profissaoSelecionada, especialidade) => {
    if (profissaoSelecionada && especialidade) {
      return profissionais.filter(x => x.profissao.especialidades.includes(especialidade))
    } else {
      return profissionais
    }
  }

  useEffect(() => {
    const filtrarProfissionaisDeSaude = () => {
      if (profissionaisDeSaudeSelecionados.length > 0) {
        return profissionaisDeSaudeSelecionados.map(x => profissionaisDeSaude.find(y => y.identificador === x))
      }

      if (profissao && especialidade) {
        return profissionaisDeSaude.filter(x => x.profissao.especialidades.includes(especialidade))
      } else {
        return profissao && profissao.nome ? profissionaisDeSaude.filter(x => x.profissao.nome === profissao.nome) : profissionaisDeSaude
      }

    }

    const profissionaisFiltrados = filtrarProfissionaisDeSaude()

    profissionaisFiltrados && profissionaisFiltrados.filter(x => x.foto).forEach(item => {
      recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
    })

  }, [recuperarFotoDoUsuarioPorIdentificador, especialidade, profissao, profissionaisDeSaude, profissionaisDeSaudeSelecionados])

  useEffect(() => {
    listarProfissionaisDeSaudeDoAgendamento()
    listarUnidadesDaEmpresaDoAgendamento()
  }, [listarProfissionaisDeSaudeDoAgendamento, listarUnidadesDaEmpresaDoAgendamento])

  function filtrarProfissionaisPeloSexo() {
    if (sexoDoProfissional) {
      return filtrarProfissionaisDeSaude(profissao, especialidade).filter(x => x.sexo === sexoDoProfissional)
    } else {
      return filtrarProfissionaisDeSaude(profissao, especialidade)
    }
  }

  const profissionaisFiltrados = filtrarProfissionaisPeloSexo()

  const listarHorarios = async () => {
    setListar(false)

    const diasDaSemana = filtrosAplicados.semana.includes('Sáb') ? [
      filtrosAplicados.semana.find(x => x === 'Dom') ? 'DOM' : '',
      filtrosAplicados.semana.find(x => x === 'Seg') ? 'SEG' : '',
      filtrosAplicados.semana.find(x => x === 'Ter') ? 'TER' : '',
      filtrosAplicados.semana.find(x => x === 'Qua') ? 'QUA' : '',
      filtrosAplicados.semana.find(x => x === 'Qui') ? 'QUI' : '',
      filtrosAplicados.semana.find(x => x === 'Sex') ? 'SEX' : '',
      filtrosAplicados.semana.find(x => x === 'Sáb') ? 'SAB' : '',
    ] : filtrosAplicados.semana

    const filtros = {
      identificadorDoProfissional: filtrosAplicados.profissional,
      dataInicio: moment(filtrosAplicados.inicio).format('YYYY-MM-DD'),
      dataFim: moment(filtrosAplicados.fim).format('YYYY-MM-DD'),
      profissao: filtrosAplicados.profissao && filtrosAplicados.profissao.identificador,
      especialidadeSelecionada: filtrosAplicados.especialidade && filtrosAplicados.especialidade,
      tipoDeAgendamento: filtrosAplicados.tipoDeAgendamento,
      sexoDoProfissional: filtrosAplicados.sexoDoProfissional,
      turnos: filtrosAplicados.turno,
      diasDaSemana: diasDaSemana,
      unidades: filtrosAplicados.unidades && filtrosAplicados.unidades,
    }

    await listarHorariosLivresDoAgendamento(filtros)
  }

  useEffect(() => {
    if (listar) {
      limparHorariosLivresDoAgendamento()
      listarHorarios()
    }
  })

  function formatarDiaDaSemana(dia) {
    return dia.charAt(0).toUpperCase() + dia.slice(1)
  }

  const renderizarDia = dia => {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia.data).format('dddd'))
    const dataFormatada = moment(dia.data).format('LL')
    const profissionais = converterObjetoParaArray(dia.profissionais)

    return (
      <div key={dataFormatada} className='box-hours-free__item passo-3'>
        <h4 className='form-item-passo-3'>{`${diaDaSemanaFormatado}, ${dataFormatada}`}</h4>
        {profissionais.map(x => renderizarProfissional(x))}
      </div>
    )
  }

  const renderizarProfissional = profissional => {
    const dadosDoProfissional = profissional && profissionaisDeSaude.find(x => x.identificador === profissional.identificador)
    const unidades = converterObjetoParaArray(profissional.unidades)

    return (
      <div key={profissional.identificador}>
        <div className='renderizar-horarios'>
          <r-grid columns-md={12} columns-lg={12}>
            <r-cell span={1} span-md={1} span-lg={1} class='ajusta-largura'>
              <div className='with-thumb-sidebar'>
                <img
                  alt={profissional.nome}
                  src={(profissional.foto && fotosDosProfissionais[profissional.foto]) || avatar}
                />
              </div>
            </r-cell>
            <r-cell span={3} span-md={11} span-lg={11}>
              <div className='form-item__choice-passo-3 profissional'>{profissional.tratamento ? profissional.tratamento.concat(' ', profissional.nome) : profissional.nome}</div>
              {dadosDoProfissional &&
                <>
                  <div className='form-item__choice-passo-3'>{dadosDoProfissional.profissao.nome}
                    {dadosDoProfissional.profissao.especialidades.length > 0 ?
                      ` | ${dadosDoProfissional.profissao.especialidades.join(', ')}`
                      : ''}
                  </div>
                  <div className='form-item__choice-passo-3'>
                    {dadosDoProfissional.profissao.conselho.nome}{' - '}
                    {dadosDoProfissional.profissao.conselho.unidadeFederativa}{' '}
                    {dadosDoProfissional.profissao.conselho.numeroDoRegistro}
                  </div>
                </>
              }
            </r-cell>
          </r-grid>
          {unidades.map(x => renderizarUnidade(x))}
        </div>
      </div>
    )
  }

  const renderizarUnidade = unidade => {
    const horarios = converterObjetoParaArray(unidade.horarios)

    return (
      <div key={unidade.identificador}>
        <div
          className='box-hours-free__item list-buttom-hours unidade'
          style={{ backgroundColor: unidade.cor, color: '#fff', borderColor: unidade.cor }}
        >
          {unidade.nome}
        </div>
        <div className='box-hours-free__item list-buttom-hours padding'>
          {horarios.map(x => renderizarHorario(x))}
        </div>
      </div>
    )
  }

  const renderizarHorario = horario => {
    const horaFormatada = moment(horario.inicio).format('HH:mm')

    return (
      <div key={horario.inicio.concat(horario.profissional.identificador)} className='horario'>
        {horario.instrucoes && horario.instrucoes.length > 0 ?
          <div>
            <button
              className='orange'
              type='button'
              data-tip
              data-for={horario.inicio.concat(horario.profissional.identificador)}
              onClick={() => podeAdicionar && abrirConfirmar(horario)}
            >
              {horaFormatada}
            </button>
            <ReactTooltip
              id={horario.inicio.concat(horario.profissional.identificador)}
              place='top'
              effect='solid'
              type='info'
              border={true}
              textColor='black'
              backgroundColor='#fff'
              borderColor='#fa8c16'
            >
              {horario.instrucoes.map((instrucao, key) =>
                <div key={key}>
                  <span>{instrucao}</span>
                </div>)}
            </ReactTooltip>
          </div>
          :
          <button
            className='green-right'
            type='button'
            data-tip
            data-for={horario.inicio.concat(horario.profissional.identificador)}
            onClick={() => podeAdicionar && abrirConfirmar(horario)}
          >
            {horaFormatada}
          </button>
        }
      </div>
    )
  }

  const pesquisar = formulario => {
    setTipoDeAgendamento(formulario.tipoDeAgendamento)
    limparHorariosLivresDoAgendamento()
    setFiltrosAplicados({ ...formulario, profissao: profissao })
    setListar(true)
  }

  function abrirConfirmar(horario) {
    setListar(false)
    setHorarioSelecionado(horario)
    setExibirConfirmacaoDeAgendamento(true)
  }

  function fecharConfirmar() { setExibirConfirmacaoDeAgendamento(false) }

  function abrirConcluir() { setExibirConclusaoDeAgendamento(true) }

  function fecharConcluir() {
    setExibirConclusaoDeAgendamento(false)
    setListar(true)
  }

  const limpar = () => {
    limparDadosDoAgendamento()
    limparDadosDoPaciente()
    history.push(`/agendamentos/novo-agendamento/passo-1`)
    listarPacientesDoWizardDeAgendamento({ termo: '' }, 1, ordem, 50)
  }

  return (
    <div className='page-paciente-dados'>
      <Modal
        isOpen={exibirConfirmacaoDeAgendamento}
        className='modal'
      >
        <ConfirmarAgendamento
          adicionarAgendamento={adicionarAgendamento}
          convenioSelecionado={convenioSelecionado}
          formatarDiaDaSemana={formatarDiaDaSemana}
          horario={horarioSelecionado}
          listarRecorrenciasDoAgendamento={listarRecorrenciasDoAgendamento}
          fechar={fecharConfirmar}
          paciente={paciente}
          pacienteSemPrograma={pacienteSemPrograma}
          proximosAgendamentos={proximosAgendamentos}
          recorrenciasDoAgendamento={recorrenciasDoAgendamento}
          contatos={contatos}
          tiposDeAgendamento={tiposDeAgendamento}
          tipoDeAgendamentoSelecionado={tipoDeAgendamento}
          setExibirConclusaoDeAgendamento={setExibirConclusaoDeAgendamento}
          unidadeDaEmpresa={horarioSelecionado.unidade}
        />
      </Modal>
      <Modal
        isOpen={exibirConclusaoDeAgendamento}
        className='modal'
      >
        <ConcluirAgendamentos
          agendamentosRealizados={agendamentosRealizados}
          fechar={fecharConcluir}
          convenio={convenio}
          excluirAgendamento={excluirAgendamento}
          formatarDiaDaSemana={formatarDiaDaSemana}
          fotosDosPacientes={fotosDosPacientes}
          limpar={limpar}
          paciente={paciente}
        />
      </Modal>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO]}>
            {({ processando }) => (
              <Spinner operacoes={[LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO]}>
                <FormularioDoFormik
                  reinicializar={true}
                  valoresIniciais={{
                    inicio: inicio ? moment(inicio).format('YYYY-MM-DD') : dataBaseHorariosLivres,
                    fim: fim ? moment(fim).format('YYYY-MM-DD') : moment(dataBaseHorariosLivres).add(1, 'month').format('YYYY-MM-DD'),
                    turno: turno ? turno : [],
                    semana: semana ? semana : [],
                    unidades: unidadeSelecionada,
                    profissao: profissao ? profissao.nome : '',
                    profissional: profissionaisDeSaudeSelecionados.length > 0 ? profissionaisDeSaudeSelecionados : [],
                    tipoDeAgendamento: tipoDeAgendamentoPreSelecionado ? tipoDeAgendamentoPreSelecionado : '',
                    especialidade: especialidade ? especialidade : '',
                    sexoDoProfissional: sexoDoProfissional ? sexoDoProfissional : ''
                  }}
                  esquemaDeValidacoes={Yup.object().shape({
                    inicio: Yup.string()
                      .nullable()
                      .test(
                        "fim_teste",
                        "Deve ser menor que o fim.",
                        function (value) {
                          const { fim } = this.parent;
                          return !value || dataIgualOuAnterior(value, fim);
                        }
                      ),
                    fim: Yup.string()
                      .nullable()
                      .test(
                        "fim_teste",
                        "Deve ser maior que o início",
                        function (value) {
                          const { inicio } = this.parent;
                          return !value || dataIgualOuAnterior(inicio, value);
                        }
                      ),
                    turno: Yup.string(),
                    semana: Yup.string(),
                    tipoDeAgendamento: Yup.string().required('Obrigatório.')
                  })}
                  acao={pesquisar}
                >
                  {({ values }) => (
                    <>
                      {!parametrosRota &&
                        <div className='nav-route-agendamento margem-zero'>
                          <div className='nav-route-back-agendamento'>
                            <button
                              onClick={history.goBack}
                              type='button'
                              className='nav-route-agendamento-name'
                              title='Voltar'
                            ></button>
                          </div>
                          <div className='nav-convenio'>
                            <ol className='nav-step'>
                              <li className='nav-step-item active'>3</li>
                              <li className='nav-step-item'> / 3</li>
                            </ol>
                            <p>Dados do Agendamento</p>
                          </div>
                        </div>
                      }
                      <div>
                        <fieldset>
                          <div className='form-item-passo-3'>
                            <r-grid columns-md={12} columns-lg={12}>
                              <r-cell span={4} span-md={1} span-lg={1} class='image-paciente'>
                                <div className='with-thumb-sidebar'>
                                  <img
                                    alt={paciente.nome}
                                    src={(paciente.foto && fotosDosPacientes[paciente.foto]) || avatar}
                                  />
                                </div>
                              </r-cell>
                              <r-cell span={4} span-md={5} span-lg={5}>
                                <div className='form-item__choice-passo-3'>
                                  <strong>Nome do Paciente:</strong>{' '}{paciente.nome}
                                  {' | '}
                                  <i className='icon icon-smartphone'
                                    onClick={() => setExibirTooltipInfo(true)}
                                    ref={referenciaDoRelato}
                                    data-for={'info_telefones_paciente'}
                                    style={{ cursor: 'pointer', fontSize: '90%' }}>
                                    <strong>Contatos</strong>
                                  </i>
                                  {exibirTooltipInfo &&
                                    <div className='tooltip__drop custom-scrollbar' style={{ left: '150px', top: '50px', width: '400px', height: 'auto' }}>
                                      <div className='tooltip__drop__body'>
                                        <div className='tooltip__drop__title'>
                                          Contatos do paciente
                                          <button
                                            className='tooltip__drop__bt-close'
                                            type='button'
                                            onClick={() => setExibirTooltipInfo(false)}>
                                            <i className='icon-task icon-close'></i></button>
                                        </div>
                                        <div className='tooltip__drop__description'>
                                          <ContatosDoAgendamento agendamento={contatos} />
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                                <div className='form-item__choice-passo-3'><strong>Data de Nascimento:</strong>{' '}{moment(paciente.dataDeNascimento).format('DD/MM/YYYY')}</div>
                                {paciente.medicoDeReferencia && <div className='form-item__choice-passo-3'><strong>Médico de Referência:</strong>{' '}{paciente.medicoDeReferencia.tratamento}{' '}{paciente.medicoDeReferencia.nome}</div>}
                              </r-cell>
                              <r-cell span={4} span-md={5} span-lg={5}>
                                {convenio && <div className='form-item__choice-passo-3'><strong>Convênio:</strong>{' '}{convenio}</div>}
                                {paciente.associacao && <div className='form-item__choice-passo-3'><strong>Associação:</strong>{' '}{paciente.associacao.nome}</div>}
                                {paciente.programas && paciente.programas.length > 0 && <div className='form-item__choice-passo-3'><strong>Programa(s):</strong>{' '}{paciente.programas.join(', ')}</div>}
                              </r-cell>
                            </r-grid>
                          </div>
                          <div className='page-agendamento-passo-3'>
                            <r-grid columns-md={6} columns-lg={6}>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <DatePicker
                                  nome='inicio'
                                  tabIndex={1}
                                  titulo='Início*'
                                  minDate={(new Date())}
                                  onChange={setInicio}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <DatePicker
                                  nome='fim'
                                  tabIndex={2}
                                  titulo='Fim *'
                                  minDate={(new Date(values.inicio))}
                                  onChange={setFim}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Select
                                  nome='profissao'
                                  tabIndex={3}
                                  titulo='Profissão'
                                  itens={profissoes}
                                  campoCodigo='nome'
                                  campoDescricao='nome'
                                  onChange={selecionarProfissao}
                                />
                              </r-cell>
                              {profissao && profissao.especialidades && profissao.especialidades.length > 0 &&
                                <r-cell span={4} span-md={6} span-lg={10}>
                                  <Select
                                    nome='especialidade'
                                    tabIndex={4}
                                    titulo='Especialidade'
                                    campoCodigo='nome'
                                    campoDescricao='nome'
                                    itens={profissao && profissao.especialidades}
                                    onChange={especialidade => selecionarEspecialidade(especialidade)}
                                  />
                                </r-cell>
                              }
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Select
                                  nome='sexoDoProfissional'
                                  tabIndex={5}
                                  titulo='Sexo do Profissional'
                                  itens={sexos}
                                  campoCodigo='codigo'
                                  campoDescricao='nome'
                                  onChange={codigo => setSexoDoProfissional(codigo)}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Select
                                  nome='profissional'
                                  tabIndex={6}
                                  titulo='Profissional'
                                  itens={profissionaisFiltrados}
                                  isMulti
                                  campoCodigo='identificador'
                                  campoDescricao='nome'
                                  onChange={identificador => setProfissionaisDeSaudeSelecionados(identificador)}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Select
                                  nome='tipoDeAgendamento'
                                  tabIndex={7}
                                  titulo='Tipo de Agendamento *'
                                  itens={tiposDeAgendamento}
                                  campoCodigo='identificador'
                                  campoDescricao='nome'
                                  onChange={setTipoDeAgendamentoPreSelecionado}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Opcoes
                                  titulo='Turno'
                                  nome='turno'
                                  opcoes={['Manhã', 'Tarde', 'Noite']}
                                  tabIndex={8}
                                  onChange={setTurno}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Opcoes
                                  titulo='Dias da Semana'
                                  nome='semana'
                                  opcoes={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                                  tabIndex={9}
                                  onChange={setSemana}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Select
                                  nome='unidades'
                                  tabIndex={10}
                                  titulo='Unidade'
                                  itens={unidades}
                                  isMulti
                                  campoCodigo='identificador'
                                  campoDescricao='nome'
                                  onChange={setUnidadeSelecionada}
                                />
                              </r-cell>
                              <r-cell span={4} span-md={6} span-lg={10}>
                                <Permissao permissoes={[permissoes.LISTAR_HORARIOS_LIVRES_DA_AGENDA]}>
                                  <button tabIndex={11} type='submit' className='button --primary w-100'>Pesquisar</button>
                                </Permissao>
                              </r-cell>
                            </r-grid>
                            <r-grid columns-md={6} columns-lg={6} class='page-horarios'>
                              <r-cell span={4} span-md={12} span-lg={12}>
                                {horarios && horarios.length > 0 &&
                                  <Element style={{
                                    height: '1100px',
                                    overflowY: 'scroll',
                                  }}>
                                    <div className='box-hours-free'>
                                      {horarios.map(x => renderizarDia(x))}
                                    </div>
                                  </Element>
                                }{!processando && horarios.length === 0 &&
                                  <div className='box-hours-free__item nao-ha-horarios'><h4>Não há horários disponíveis ou não há profissional(is) com o tipo de agendamento selecionado.</h4></div>
                                }
                              </r-cell>
                            </r-grid>
                          </div>
                          <div className='list-btn'>
                            <button type='button' onClick={() => limpar()} className='button --light'>Fechar</button>
                            <button type='button' className={agendamentosRealizados.length > 0 ? 'button --primary' : 'button --primary is-disabled'} onClick={() => abrirConcluir()}>
                              Visualizar Agendamento(s)
                            </button>
                          </div>
                        </fieldset>
                      </div>
                    </>
                  )}
                </FormularioDoFormik>
              </Spinner>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}