import api from "../api-hercules";

const definirNumeroInteiroDaTarefa = (identificadorDaTarefa, campo, conteudo) => {
  const parametroConteudo = conteudo ? conteudo : null

  return api.put(`tarefas/${identificadorDaTarefa}/numero-inteiro`, { campo: campo, conteudo: parametroConteudo })
}

export default {
  definirNumeroInteiroDaTarefa
}