import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS,
  LISTOU_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS,
  ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS
} from '../tipos'

const listouSituacoesAssociadasASintomasUrinarios = situacoesSintomasUrinarios => ({
  type: LISTOU_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS,
  situacoesSintomasUrinarios
})

const erroAoListarSituacoesAssociadasASintomasUrinarios = erro => ({
  type: ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS,
  erro
})

export const listarSituacoesAssociadasASintomasUrinarios = () => async dispatch => {
  dispatch({ type: LISTAR_SITUACOES_ASSOCIADAS_A_SINTOMAS_URINARIOS })

  try {
    const resultado = await api.listarSituacoesAssociadasASintomasUrinarios()

    dispatch(listouSituacoesAssociadasASintomasUrinarios(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSituacoesAssociadasASintomasUrinarios(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as situações associadas a sintomas urinários.')))
  }
}
