import {
  LISTOU_USUARIOS_BLOQUEADOS,
} from '../../acoes/tipos'

const estadoInicial = {
  filtros: {
    termo: '',
    motivosDeBloqueio: [],
  },
  ordenacao: { nome: 'dataEHora', ordem: '+' },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_USUARIOS_BLOQUEADOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: {},
        ordenacao,
        paginaDeDados,
      }
    }

    default: {
      return state
    }
  }
}