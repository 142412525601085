import {
  LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO
} from '../../acoes/tipos'

const estadoInicial = []

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO: {
      return action.motivosParaNaoTerSidoAtendido
    }

    default: {
      return state
    }
  }
}