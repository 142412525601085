import api from '../../../api-major'

const adicionarIvcfDoPaciente = (identificador, ivcf) => api.post(`/pacientes/${identificador}/ivcfs`, ivcf)
const alterarIvcfDoPaciente = (identificador, ivcf) => api.put(`/pacientes/${identificador}/ivcfs/${ivcf.identificador}`, ivcf)
const removerIvcfDoPaciente = (identificador, identificadorDoIvcf) => api.delete(`/pacientes/${identificador}/ivcfs/${identificadorDoIvcf}`)
const listarIvcfsDoPaciente = identificador => api.get(`/pacientes/${identificador}/ivcfs`)

export default { 
  adicionarIvcfDoPaciente,
  alterarIvcfDoPaciente,
  removerIvcfDoPaciente,
  listarIvcfsDoPaciente
}