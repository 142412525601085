import React, { useState } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import { formatarDataParaFormatoLocal, diferencaEmDiasEntreDatas } from '../../../bibliotecas/data'
import Imprimir from '../linha-do-tempo/impressao'

export default function Assinado({ fecharModal, lacuna, labelSaida = 'Fechar' }) {
  const [exibirImprimirAtendimento, setExibirImprimirAtendimento] = useState(false)

  const prazoValidade = diferencaEmDiasEntreDatas(lacuna.finalizado.certificado.validade, moment())

  function abrirImprimirAtendimento() {
    setExibirImprimirAtendimento(true)
  }

  function fecharImprimirAtendimento() {
    setExibirImprimirAtendimento(false)
  }

  return (
    <>
      <Modal
        isOpen={exibirImprimirAtendimento}
        className='modal'
        contentLabel='Modal para impressão do atendimento'
        style={{ content: { width: '400px' } }}
      >
        <Imprimir
          fechar={fecharImprimirAtendimento}
          atendimento={{
            identificador: lacuna.identificadores.atendimento,
            paciente: { identificador: lacuna.identificadores.paciente },
            assinaturasDigitais: [{}]
          }}
        />
      </Modal>
      <fieldset>
        <div className='unity-infos'>
          <div className='unity-infos__item'>
            <div className='assinatura-digital-atendimento__info'>
              <div className='col'>
                <p><strong>Paciente:</strong></p>
                <p><strong>Data do Atendimento:</strong></p>
                <p><strong>Tipo de Atendimento:</strong></p>
              </div>
              <div className='col'>
                <p>{lacuna.finalizado.atendimento.paciente.nome}</p>
                <p>{formatarDataParaFormatoLocal(lacuna.finalizado.atendimento.inicio)}</p>
                <p>{lacuna.finalizado.atendimento.tipo.nome}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='unity-infos'>
          <div className='unity-infos__item'>
            <div className='assinatura-digital-atendimento__info'>
              <div className='col'>
                <p><strong>Nome:</strong></p>
                <p><strong>CPF:</strong></p>
                <p><strong>Tipo de Certificado:</strong></p>
                <p><strong>Emissor:</strong></p>
                <p><strong>Data de Emissão:</strong></p>
                <p><strong>Data de Validade:</strong></p>
              </div>
              <div className='col'>
                <p>{lacuna.finalizado.certificado.nome}</p>
                <p>{lacuna.finalizado.certificado.cpf}</p>
                <p>{lacuna.finalizado.certificado.tipoDoCertificado}</p>
                <p>{lacuna.finalizado.certificado.emissor}</p>
                <p>{formatarDataParaFormatoLocal(lacuna.finalizado.certificado.dataDeEmissao)}</p>
                {prazoValidade <= 30 && <p>{formatarDataParaFormatoLocal(lacuna.finalizado.certificado.validade)}<i className='icon icon-attention text-danger'><small>Atenção! Seu certificado vence em {prazoValidade} dias.</small></i></p>}
                {prazoValidade > 30 && <p>{formatarDataParaFormatoLocal(lacuna.finalizado.certificado.validade)}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className='list-btn'>
          <button className='button --light' onClick={fecharModal}>{labelSaida}</button>
          <button className='button --light' onClick={abrirImprimirAtendimento}>Imprimir</button>
        </div>
      </fieldset>
    </>
  )
}