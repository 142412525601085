
import { connect } from 'react-redux'
import acoes from '../../acoes'
import Notificacao from './notificacao'

const mapStateToProps = state => {
  const notificacoes = Object.keys(state.notificacoes).map(key => ({
    ...state.notificacoes[key],
    chave: key
  }))

  return { notificacoes }
}

export default connect(mapStateToProps, acoes)(Notificacao)