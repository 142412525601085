import api from '../api-major'

const adicionarPlanoDeCuidadoDoPaciente = (identificador, planoDeCuidado) => api.post(`/pacientes/${identificador}/planos-de-cuidado`, planoDeCuidado)
const alterarPlanoDeCuidadoDoPaciente = (identificador, planoDeCuidado) => api.put(`/pacientes/${identificador}/planos-de-cuidado/${planoDeCuidado.identificador}`, planoDeCuidado)
const listarPlanosDeCuidadoDoPaciente = identificador => api.get(`/pacientes/${identificador}/planos-de-cuidado?tamanhoDaPagina=1000&ordem=-identificador`)
const removerPlanoDeCuidadoDoPaciente = (identificadorDoPaciente, identificador) => api.delete(`/pacientes/${identificadorDoPaciente}/planos-de-cuidado/${identificador}`)
const recuperarPlanoDeCuidadoDoPaciente = (identificadorDoPaciente, identificador) => api.get(`/pacientes/${identificadorDoPaciente}/planos-de-cuidado/${identificador}`)
const listarMotivosDeSaidaDosPlanosDeCuidado = () => api.get('/motivos-de-saida-do-plano-de-cuidado?internos=false&tamanhoDaPagina=1000')

export default {
  adicionarPlanoDeCuidadoDoPaciente,
  alterarPlanoDeCuidadoDoPaciente,
  listarPlanosDeCuidadoDoPaciente,
  recuperarPlanoDeCuidadoDoPaciente,
  removerPlanoDeCuidadoDoPaciente,
  listarMotivosDeSaidaDosPlanosDeCuidado
}