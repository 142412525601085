import React from 'react'

export default function LegendaDasUnidades({ itens = [] }) {
    return (
        <div className='legenda-das-unidades'>
            {itens.map(x => (
                <div key={x.identificador} className='item-da-legenda'>
                    <div
                        className='cor'
                        style={{ backgroundColor: x.cor }}
                    />
                    <div className='descricao'>
                        {x.nome}
                    </div>
                </div>
            ))}
        </div>
    )
} 