import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import Modal from 'react-modal'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../../../spinner'
import {Formulario as FormularioDoFormik, Select, InputSugestao} from '../../../formik/formulario'
import { SelectTemplate } from '../../../formik/input-mension/index'
import { LISTAR_PROFISSIONAIS_DE_SAUDE, LISTAR_PROFISSIONAIS_EXTERNOS, LISTAR_PROFISSOES } from '../../../../acoes/tipos'
import DicaDePesquisa from '../../../dica-de-pesquisa'
import AdicionarTemplate from '../../atendimento/adicionar-template'

export default function FormularioMedico(props) {
  const {
    adicionarTemplateDoProntuario,
    fechar,
    especialidades,
    especialidadesMedicasInternas,
    listarEspecialidadesMedicasInternas,
    profissionaisExternos,
    profissionaisInternos,
    selecionada,
    tokensDeSugestao,
    setExibirFormulario,
    estabelecimentosDeSaude,
    ...rest
  } = props

  useEffect(() => { listarEspecialidadesMedicasInternas() }, [listarEspecialidadesMedicasInternas])

  const salvar = (valores, { resetForm }) => {
    resetForm({})

    let dados

    function recuperarTipo(especialidade) {
      if(!especialidadesMedicasInternas.map(x => x.nome).includes(especialidade)) return 'especialidade_medica_externa'

      return 'especialidade_medica_interna'
    }

    dados = {
      ...valores,
      tipo: recuperarTipo(valores.especialidade),
      profissao: 'MÉDICO(A)',
      versao: '2',
      codigoDeRastreio: uuidv4(),
    }

    delete dados.template_encaminhamento
    rest.salvar(dados)
    fechar()
  }

  const [pularConfirmacao, setPularConfirmacao] = useState(false)
  const [template, setTemplate] = useState('')
  const [exibirAdicionarTemplate, setExibirAdicionarTemplate] = useState(false)

  const atualizarPreview = (template = '') => {
    return decodificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const { dadosDaSugestao, usuariosDaSugestao, templates, decodificador } = tokensDeSugestao
  const templatesEncaminhamento = templates.map(x => ({
    ...x,
    options: x.options.length > 0 ? x.options.filter(y => y.componente === 'ENCAMINHAMENTO').map(z => ({ ...z, label: z.nome, value: z.identificador })) : []
  }))

  const selecionarTemplate = (valor, setFieldValue) => {
    if (!valor) {
      setPularConfirmacao(false)
      return
    }

    setPularConfirmacao(true)

    let todosOsTemplates = []
    templatesEncaminhamento.forEach(x => x.options.length > 0 && x.options.map(t => todosOsTemplates.push(t)))
    const template = todosOsTemplates.filter(x => x.identificador === valor)[0].template
    setFieldValue('texto', atualizarPreview(template))
  }

  const confirmarESubstituir = (valor, setFieldValue) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Os dados informados do Encaminhamento serão substituídos. Tem certeza disso?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => selecionarTemplate(valor, setFieldValue)
      }, {
        label: 'Não',
        onClick: () => setFieldValue('template_encaminhamento', '')
      }]
    })
  }

  const salvarComoTemplate = template => {
    abrirAdicionarTemplate(template)
  }

  function abrirAdicionarTemplate(template) {
    setTemplate(template)
    setExibirAdicionarTemplate(true)
  }

  function fecharAdicionarTemplate() {
    setExibirAdicionarTemplate(false)
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    color: 'black'
  }

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const formatarGrupos = dados => {
    return (
        <div style={groupStyles}>
          <span>{dados.grupo}</span>
          <span style={groupBadgeStyles}>{dados.options.length}</span>
        </div>
    )
  }

  const retornarProfissionais = especialidade => profissionaisExternos.filter(x => x.especialidades.includes(especialidade))

  const recuperarObservacao = profissional => {
    const profissionalSelecionado = profissionaisExternos.find(x => x.nome === profissional)

    return profissionalSelecionado.observacoes
  }

  const onChangeEspecialidade = (setFieldValue) => { setFieldValue('profissionalExterno', '') }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarTemplate}
        className='modal-adicionar-template'
        style={{ content: { width: '700px' } }}
      >
        <AdicionarTemplate
          fechar={fecharAdicionarTemplate}
          salvar={adicionarTemplateDoProntuario}
          template={template}
          componente='ENCAMINHAMENTO'
        />
      </Modal>
      <Spinner operacoes={[LISTAR_PROFISSIONAIS_DE_SAUDE, LISTAR_PROFISSIONAIS_EXTERNOS, LISTAR_PROFISSOES]}>
        <FormularioDoFormik
          reinicializar={false}
          valoresIniciais={{
            identificador: selecionada ? selecionada.identificador : null,
            especialidade: selecionada ? selecionada.especialidade : '',
            profissionalExterno: selecionada ? selecionada.profissionalExterno : '',
            texto: selecionada ? selecionada.texto : '',
          }}
          esquemaDeValidacoes={Yup.object().shape({
            texto: Yup.string().required('Obrigatório.'),
            especialidade: Yup.string().required('Obrigatório.'),
          })}
          acao={salvar}
        >
          {({ values, setFieldValue }) => (
            <fieldset>
              <h2 className='form-title'>Adicionar Encaminhamento Médico</h2>
              <r-grid columns-md={6} columns-lg={12} className='mb-2'>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <SelectTemplate
                          nome='especialidade'
                          titulo='Especialidade *'
                          tabIndex={1}
                          formatGroupLabel={formatarGrupos}
                          itens={especialidades}
                          campoCodigo='nome'
                          campoDescricao='nome'
                          onChange={() => onChangeEspecialidade(setFieldValue)}
                      />
                    </r-cell>
                {values.especialidade && !especialidadesMedicasInternas.map(x => x.nome).includes(values.especialidade) && retornarProfissionais(values.especialidade).length > 0 &&
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <Select
                          nome='profissionalExterno'
                          titulo='Profissional externo'
                          tabIndex={2}
                          itens={retornarProfissionais(values.especialidade)}
                          campoCodigo='nome'
                          campoDescricao='nome'
                      />
                    </r-cell>
                }
                {values.especialidade && values.profissionalExterno &&
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <div className='form-choice-alerta'>
                        <div className='form-choice-alerta__item'>
                          <i className='icon icon-attention-circled'></i>
                        </div>
                        <div className='form-choice-alerta__item'>
                          Este é um encaminhamento externo, por favor, lembre-se de imprimir e entregar ao paciente.
                        </div>
                      </div>
                      {recuperarObservacao(values.profissionalExterno) &&
                      <div className='form-item'>
                        <pre className='form-item__choice'>{' '}{recuperarObservacao(values.profissionalExterno)}</pre>
                      </div>
                      }
                    </r-cell>
                }
                <r-cell span={values.tipo === 'Externo' ? 12 : 4} span-md={values.tipo === 'Externo' ? 12 : 6} span-lg={12}>
                  <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@@assinatura'>
                    <InputSugestao
                      as='textarea'
                      nome='texto'
                      titulo='Texto *'
                      dados={dadosDaSugestao}
                      dadosUsuarios={usuariosDaSugestao}
                      minHeight={200}
                      modal={true}
                    >
                      <SelectTemplate
                        nome='template_encaminhamento'
                        itens={templatesEncaminhamento}
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        onChange={v => (values.texto === '' || pularConfirmacao) ? selecionarTemplate(v, setFieldValue) : confirmarESubstituir(v, setFieldValue)}
                        onClickSalvarComoTemplate={() => salvarComoTemplate(values.texto)}
                      />
                    </InputSugestao>
                  </DicaDePesquisa>
                </r-cell>
              </r-grid>
              <div className='list-btn mt-4'>
                <button type='button' className='button --light' onClick={fechar}>Cancelar</button>
                <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
              </div>
            </fieldset>
          )}
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}