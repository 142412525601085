import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
  FEZ_DOWNLOAD_DO_ENCAMINHAMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
} from './tipos'

const fezDownloadDoEncaminhamento = (identificadorDoEncaminhamento, bytesDoEncaminhamento) => ({
  type: FEZ_DOWNLOAD_DO_ENCAMINHAMENTO,
  identificadorDoEncaminhamento,
  bytesDoEncaminhamento,
})

const erroAoFazerDownloadDoEncaminhamento = (erro, identificadorDoEncaminhamento) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
  identificadorDoEncaminhamento,
  erro,
})

export const fazerDownloadDoEncaminhamento = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDoEncaminhamento, tamanhoDaPagina, adicionarData) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_ENCAMINHAMENTO,
    identificadorDoEncaminhamento
  })

  try {
    const resultado = await api.fazerDownloadDoEncaminhamento(identificadorDoPaciente, identificadorDoAtendimento, identificadorDoEncaminhamento, tamanhoDaPagina, adicionarData)

    dispatch(fezDownloadDoEncaminhamento(identificadorDoEncaminhamento, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoEncaminhamento(erro, identificadorDoEncaminhamento))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download da imagem do encaminhamento.')))

    return null
  }
}