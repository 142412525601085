import { connect } from 'react-redux'
import acoes from '../../acoes'
import ProfissionaisExternos from './index'

const mapStateToProps = state => {
  const profissionalAtual = state.profissionaisExternos.itemAtual

  let profissional = {
    nome: '',
    observacoes: '',
    profissao: '',
    especialidades: [],
    parceiro: '',
    ativo: ''
  }

  if (profissionalAtual) {
    profissional.identificador = profissionalAtual.identificador
    profissional.observacoes = profissionalAtual.observacoes
    profissional.nome = profissionalAtual.nome
    profissional.profissao = profissionalAtual.profissao
    profissional.especialidades = profissionalAtual.especialidades
    profissional.parceiro = profissionalAtual.parceiro
    profissional.ativo = profissionalAtual.ativo
  }

  return {
    filtros: state.profissionaisExternos.filtros,
    ordenacao: state.profissionaisExternos.ordenacao,
    paginaDeDados: state.profissionaisExternos.paginaDeDados,
    profissoes: state.profissoes.itens,
    profissional,
  }
}

export default connect(mapStateToProps, acoes)(ProfissionaisExternos)