import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MOTIVOS_DE_PRIORIDADE,
  LISTOU_MOTIVOS_DE_PRIORIDADE,
  ERRO_AO_LISTAR_MOTIVOS_DE_PRIORIDADE,
} from './tipos'

const listouMotivosDePrioridade = motivosDePrioridade => ({
  type: LISTOU_MOTIVOS_DE_PRIORIDADE,
  motivosDePrioridade,
})

const erroAoListarMotivosDePrioridade = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_PRIORIDADE,
  erro,
})

export const listarMotivosDePrioridade = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_MOTIVOS_DE_PRIORIDADE })

    try {
      const resultado = await api.listarMotivosDePrioridade()

      dispatch(listouMotivosDePrioridade(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarMotivosDePrioridade(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de prioridade.')))
    }
  }

  acao.meta = {
    cache: LISTAR_MOTIVOS_DE_PRIORIDADE
  }

  return acao
}