import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import { LISTAR_TIPOS_DE_HORARIOS, LISTOU_TIPOS_DE_HORARIOS, ERRO_AO_LISTAR_TIPOS_DE_HORARIOS } from '../../tipos'

const listouTiposDeHorarios = tiposDeHorarios => ({
  type: LISTOU_TIPOS_DE_HORARIOS,
  tiposDeHorarios
})

const erroAoListarTiposDeHorarios = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_HORARIOS,
  erro
})

export const listarTiposDeHorarios = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_HORARIOS })

    try {
      const resultado = await api.listarTiposDeHorarios()

      dispatch(listouTiposDeHorarios(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTiposDeHorarios(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de horários.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TIPOS_DE_HORARIOS
  }

  return acao
}