import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ATENDIMENTOS_INICIADOS,
  LISTOU_ATENDIMENTOS_INICIADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS,
} from './tipos'

const listouAtendimentosIniciados = (atendimentos) => ({
  type: LISTOU_ATENDIMENTOS_INICIADOS,
  atendimentos,
})

const erroAoListarAtendimentosIniciados = erro => ({
  type: ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS,
  erro,
})

export const listarAtendimentosIniciados = () => async dispatch => {
  dispatch({ type: LISTAR_ATENDIMENTOS_INICIADOS })

  try {
    const resultado = await api.listarAtendimentosIniciados()

    dispatch(listouAtendimentosIniciados(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAtendimentosIniciados(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os atendimentos iniciados.')))
  }
}