import React, { useMemo } from 'react'
import Grafico from './grafico'
import { useTarefas } from '../contexto'

export default function Fluxo({ match }) {
  const {
    tiposDeTarefa
  } = useTarefas()

  const identificadorDoTipoDeTarefa = match.params.identificadorDoTipoDeTarefa
  const tipoDeTarefa = tiposDeTarefa[identificadorDoTipoDeTarefa]

  const elementosIniciais = useMemo(() => {
    const position = { x: 0, y: 0 }
    const status = tipoDeTarefa && tipoDeTarefa.versoes[tipoDeTarefa.versoes.length - 1].status
    let dados = []

    // elementos
    status && status.forEach(tarefa => {
      dados.push({
        id: tarefa.nome,
        data: { label: tarefa.nome.toUpperCase(), color: '#000' },
        position,
        style: {
          borderColor: tarefa.tipo === 'inicial' ? '#0041d0' : (tarefa.tipo === 'final' || tarefa.tipo === 'cancelamento') ? '#000' : '',
          backgroundColor: tarefa.tipo === 'inicial' ? '#b4c7e3' : (tarefa.tipo === 'final' || tarefa.tipo === 'cancelamento') ? '#eee' : '',
        },
        type: tarefa.tipo === 'inicial' ? 'input' : (tarefa.tipo === 'final' || tarefa.tipo === 'cancelamento')  ? 'output' : 'default'
      })

    })

    // transicoes
    status && status.forEach(tarefa => {
      tarefa.transicoes.forEach(transicao => {
        if (transicao.status === '__status_anterior__') return

        const identificador = transicao.status + '-' + tarefa.nome
        const duploSentido = dados.find(x => x.id === identificador)

        if (duploSentido) {
          // atualiza NODO para tipo special
          const node = dados.find(x => x.id === transicao.status)

          dados = dados.filter(x => x.id !== transicao.status)
          dados.push({
            ...node,
            type: node.type === 'input' ? 'input' : 'special'
          })

          // atualiza ligacao para NODO special
          dados = dados.filter(item => item.id !== identificador)
          dados.push({
            ...duploSentido,
            sourceHandle: 'l2',
          })

        }

        dados.push({
          id: tarefa.nome + '-' + transicao.status,
          source: tarefa.nome,
          target: transicao.status,
          arrowHeadType: 'arrowclosed',
          label: transicao.configuracoes.rotulo.toUpperCase(),
          targetHandle: duploSentido ? 'l3' : '', // ligacao para NODO special (se existir)
          labelStyle: { fontSize: '8px' },
        })

      })
    })

    return dados
  }, [tipoDeTarefa])

  return (
    <div className='form p-12'>
      <h2 className='form-title'>
        <span className='dica-de-pesquisa'>
          <i className='icon icon-lamp' />Utilize o mouse para redimensionar/mover os componentes
        </span>
      </h2>
      <div className='form-tarefas' style={{ height: '700px', backgroundColor: '#fff' }}>
        {elementosIniciais && elementosIniciais.length > 0 &&
          <Grafico elementosIniciais={elementosIniciais} />
        }
      </div>
    </div>
  )
}