import { connect } from 'react-redux'
import acoes from '../../../acoes/index'
import UnidadesDoLogin from './index'

const recuperarLogotipos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.logotipo).forEach(item => {
    fotos[item.identificadorPublico] = imagens[item.identificadorPublico]
  })

  return fotos
}

const mapStateToProps = state => {
  return {
    unidadeLogada: state.login.logado.unidade,
    empresas: state.login.logado.empresas,
    logotipos: recuperarLogotipos(state.login.logado.empresas, state.imagens)
  }
}

export default connect(mapStateToProps, acoes)(UnidadesDoLogin)