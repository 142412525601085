import { connect } from 'react-redux'
import acoes from '../../acoes'
import Diagnosticos from './index'

const mapStateToProps = state => {
  const diagnosticoAtual = state.diagnosticos.itemAtual

  let diagnostico = {
    nome: '',
    status: '',
    aprovado: true,
    cid10: '',
    semCid10: false,
  }

  if (diagnosticoAtual) {
    diagnostico.identificador = diagnosticoAtual.identificador
    diagnostico.status = diagnosticoAtual.status
    diagnostico.nome = diagnosticoAtual.nome
    diagnostico.aprovado = diagnosticoAtual.status === 'aprovado' ? true : false
    diagnostico.cid10 = diagnosticoAtual.cid10
    diagnostico.semCid10 = diagnosticoAtual.semCid10
  }

  return {
    filtros: state.diagnosticos.filtros,
    ordenacao: state.diagnosticos.ordenacao,
    paginaDeDados: state.diagnosticos.paginaDeDados,
    cids10: state.cids10,
    diagnostico,
  }
}

export default connect(mapStateToProps, acoes)(Diagnosticos)