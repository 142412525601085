import React, { useEffect, useState } from 'react'
import Cabecalho from './cabecalho'
import ReactTooltip from 'react-tooltip'
import Spinner from '../spinner'
import { Formulario as Form, Select } from '../formik/formulario'
import { confirmAlert } from 'react-confirm-alert'
import { ordemDoServidor } from '../../bibliotecas/ordenacao'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import * as permissoes from '../seguranca/permissoes'

import {
  ADICIONAR_PERFIL_DO_USUARIO,
  REMOVER_PERFIL_DO_USUARIO,
  LISTAR_PERFIS,
  LISTAR_PERFIS_DO_USUARIO
} from '../../acoes/tipos'

export default function Perfil(props) {
  const {
    fotos,
    history,
    match,
    recuperarPorIdentificador,
    usuario = {},
    listarPerfis,
    listarPerfisDoUsuario,
    adicionarPerfilDoUsuario,
    removerPerfilDoUsuario,
    perfil: {
      perfis,
      paginaDeDados,
      habilitados
    }
  } = props

  const { identificador } = match.params
  const { usuario: { ehAdministrador } } = useUsuarioLogado()
  const [selecionado, setSelecionado] = useState('')
  const podeAdicionarPerfil = usePossuiAsPermissoes([permissoes.ADICIONAR_PERFIL_DO_USUARIO])
  const podeRemoverPerfil = usePossuiAsPermissoes([permissoes.REMOVER_PERFIL_DO_USUARIO])

  useEffect(() => {
    listarPerfis()
    listarPerfisDoUsuario(identificador)
  }, [listarPerfis, listarPerfisDoUsuario, identificador])

  const recuperarPerfisDoUsuario = dados => ({
    dados: Object.keys(dados)
      .map(key => dados[key])
      .filter(x => habilitados.findIndex(i => i === x.identificador) === -1)
      .filter(x => ehAdministrador || x.nome !== 'ADMINISTRADOR')
      .sort(ordemDoServidor)
      .map(x => ({ identificador: x.identificador, nome: x.nome }))
  })

  const perfisDisponiveisParaOUsuario = perfis && recuperarPerfisDoUsuario(perfis)

  const adicionar = async () => {
    const adicionou = await adicionarPerfilDoUsuario(identificador, selecionado)

    if (adicionou) {
      setSelecionado('')
      listarPerfisDoUsuario(identificador)
    }
  }

  const removerSelecionado = async item => {
    const removeu = await removerPerfilDoUsuario(identificador, item.identificador)

    if (removeu) {
      setSelecionado('')
      listarPerfisDoUsuario(identificador)
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o perfil: ${item.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[LISTAR_PERFIS, LISTAR_PERFIS_DO_USUARIO, ADICIONAR_PERFIL_DO_USUARIO, REMOVER_PERFIL_DO_USUARIO]}>
      <Form
        valoresIniciais={{
          perfis: selecionado ? selecionado : ''
        }}
        acao={adicionar}
        reinicializar={true}
      >
        <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
        <div className='mt-2 mb-4'>
          <div>
            <fieldset>
              <h2 className='form-title'>{podeAdicionarPerfil ? 'Adicionar Perfil' : 'Perfil'}</h2>
              <r-grid columns-md={6} columns-lg={12} class='align-end'>
                {podeAdicionarPerfil &&
                  <>
                    <r-cell span={4} span-md={4} span-lg={5}>
                      <Select
                        nome='perfis'
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        itens={perfisDisponiveisParaOUsuario.dados}
                        tabIndex={1}
                        titulo='Perfil *'
                        onChange={setSelecionado}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg={2}>
                      <button
                        type='submit'
                        className={!selecionado ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                        disabled={!selecionado}
                      >
                        Adicionar
                      </button>
                    </r-cell>
                  </>
                }
                <r-cell span={4} span-md={6} span-lg={'1-7'}>
                  {paginaDeDados.dados &&
                    paginaDeDados.dados.map((perfil, index) => (
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item'>{perfil.nome}</div>
                        {perfil.observacoes &&
                          <div className='form-choice__item'>
                            <span className='form-choice__toggle'>Observações
                              <i
                                className='icon icon-arrow-down'
                                data-tip
                                data-for={`info_${perfil.identificador}`}
                                style={{ cursor: 'pointer' }}
                                key={`info_${perfil.identificador}`}>
                              </i>
                            </span>
                            <ReactTooltip
                              id={`info_${perfil.identificador}`}
                              place='bottom'
                              effect='solid'
                              type='info'
                              key={perfil.identificador}
                            >
                              <span>{perfil.observacoes}</span>
                            </ReactTooltip>
                          </div>
                        }
                        {podeRemoverPerfil && <button className='form-choice__bt-close' type='button' aria-label='Fechar' onClick={() => confirmarERemover(perfil)}><i className='icon icon-close'></i></button>}
                      </div>
                    ))}
                </r-cell>
              </r-grid>
            </fieldset>
            <div className='list-btn'>
              <button
                className='button --light'
                type='button'
                onClick={() => history.push(`/configuracoes/usuarios`)}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Spinner>
  )
}