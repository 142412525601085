import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela } from '../../../tabela'
import { formatarDataComPrecisaoParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import { LISTAR_VACINAS_DO_PACIENTE, REMOVER_VACINA_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Vacinas({ identificadorDoPaciente, vacinasDoPaciente }) {
  const {
    listarVacinasDoPaciente,
    vacinas,
    listarVacinas,
    listarLocaisDaAplicacao,
    locaisDaAplicacao,
    listarFabricantes,
    fabricantes,
    adicionarVacina,
    alterarVacina,
    removerVacina
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)

  const podeListarVacinas = usePossuiAsPermissoes([permissoes.LISTAR_VACINAS])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_VACINA_DO_PACIENTE])

  useEffect(() => {
    if (vacinasDoPaciente === undefined) {
      listarVacinasDoPaciente(identificadorDoPaciente)
    }
  }, [listarVacinasDoPaciente, identificadorDoPaciente, vacinasDoPaciente])

  useEffect(() => listarVacinas(), [listarVacinas])
  useEffect(() => listarLocaisDaAplicacao(), [listarLocaisDaAplicacao])
  useEffect(() => listarFabricantes(), [listarFabricantes])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarVacina(identificadorDoPaciente, item) : await adicionarVacina(identificadorDoPaciente, item)
  }


  if (vacinasDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de vacinas'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          vacinas={vacinas}
          fabricantes={fabricantes}
          locaisDaAplicacao={locaisDaAplicacao}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de vacinas'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerVacina(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_VACINAS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_VACINA_DO_PACIENTE]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_VACINA_DO_PACIENTE]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>{item.vacina}</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                      {item.observacoes &&
                        <>
                          <strong>Observações: </strong>
                          {item.observacoes}
                        </>
                      }
                    </ReactTooltip>
                  </div>
                ,
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={vacinasDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarVacinas}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de vacinas.'
              classNameAdicionar='col-xs-6 col-lg-3'
            >
              <Coluna
                className='col-xs-6 col-lg-4'
                campoDeDados='vacina'
                nome='Nome'
                exibirTitulo={true}
                principal={true}
              />
              <Coluna
                className='col-lg-2'
                nome='Data'
                renderizar={item => item.data ? formatarDataComPrecisaoParaFormatoLocal(item.data, item.precisaoDaData) : 'Não lembra'}
              />
              <Coluna
                className='col-lg-3'
                nome='Aplicada na unidade'
                renderizar={item => item.aplicadaNaUnidade ? 'Sim' : 'Não'}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirVacina = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_VACINA_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a vacina ${selecionada && selecionada.vacina}?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirVacina}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}