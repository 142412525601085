import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import { importarPacientes, limparImportacaoDePacientes } from '../../../acoes/importacoes/pacientes'
import { adicionarArquivo } from '../../../acoes/arquivos'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { IMPORTAR_PACIENTES, ADICIONAR_ARQUIVO } from '../../../acoes/tipos'
import '../style.css'
import { Hash } from '../../../design/icones'

export default function ImportacaoDePacientes({ match }) {
  const dispatch = useDispatch()
  const { pacientes } = useSelector(state => state.importacoes)
  const { adicionados, alterados, erros, naoImportados } = pacientes

  const [arquivo, setArquivo] = useState(null)

  useEffect(() => { return () => dispatch(limparImportacaoDePacientes()) }, [dispatch])

  const adicionarAnexo = async evento => {
    if (!evento.target.files[0]) return

    const arquivo = await dispatch(adicionarArquivo(evento.target.files[0], 'importacao_de_pacientes'))
    setArquivo(arquivo)
  }

  const simular = () => {
    if (arquivo) {
      dispatch(importarPacientes(arquivo.identificador, 'simulacao'))
    }
  }

  const importar = async () => {
    if (arquivo) {
      await dispatch(importarPacientes(arquivo.identificador, 'confirmacao'))
      limpar()
    }
  }

  const limpar = () => {
    setArquivo(null)
    dispatch(limparImportacaoDePacientes())
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.IMPORTAR_PACIENTES]}>
              <Importacao
                {...props}
                adicionados={adicionados}
                alterados={alterados}
                arquivo={arquivo}
                adicionarAnexo={adicionarAnexo}
                erros={erros}
                importar={importar}
                limpar={limpar}
                naoImportados={naoImportados}
                simular={simular}
              />
            </Permissao>
          )
        }
      />
    </Switch>
  )
}

function Importacao({ adicionados = 0, alterados = 0, arquivo, adicionarAnexo, erros = [], importar, limpar, naoImportados = [], simular }) {
  const jahIniciouImportacao = arquivo && (erros.length > 0 || naoImportados.length > 0 || adicionados > 0 || alterados > 0)
  const temErroENaoTemInconsistencia = erros.length > 0 && naoImportados.length === 0
  const temInconsistenciaENaoTemErro = erros.length === 0 && naoImportados.length > 0
  const temErroEInconsistencia = erros.length > 0 && naoImportados.length > 0

  const descricoes =
    temErroEInconsistencia ? [`Existe(m) ${erros.length} erro(s) na planilha.`, `Existe(m) ${naoImportados.length} inconsistência(s) na planilha.`] :
      temErroENaoTemInconsistencia ? [`Existe(m) ${erros.length} erro(s) na planilha.`] :
        temInconsistenciaENaoTemErro > 0 ? [`Existe(m) ${naoImportados.length} inconsistência(s) na planilha.`] : []

  const sufixoBotaoConfirmar =
    temErroEInconsistencia ? 'e Ignorar os Erros e Inconsistências' :
      temErroENaoTemInconsistencia ? 'e Ignorar os Erros' :
        temInconsistenciaENaoTemErro > 0 ? 'e Ignorar as Inconsistências' : ''

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[ADICIONAR_ARQUIVO, IMPORTAR_PACIENTES]}>
          {({ processando }) => (
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset>
                <h2 className='form-title'>Importação de Pacientes</h2>
                <r-cell span={4} span-md={6} span-lg={12}>
                  {!arquivo &&
                    <div className='mt-1'>
                      <label
                        htmlFor='anexos'
                        className='button --light w-sm-100'
                      >
                        Selecionar Planilha para Importação
                        <i className='icon icon-doc ml-1'></i>
                      </label>
                      <input
                        type='file'
                        name='anexos'
                        id='anexos'
                        onChange={(evento) => adicionarAnexo(evento)}
                        onClick={evento => { evento.target.value = null }}
                      />
                    </div>
                  }
                  {arquivo &&
                    <>
                      <div className='form-summary'>
                        <div className='form-summary-files'>
                          <div className='file-upload-item'>
                            <div className='file-upload-item__name'>{arquivo.nome}</div>
                            <button
                              className='file-upload-item__bt --remove'
                              title='Cancelar'
                              type='button'
                              onClick={() => (limpar())}
                            >
                              <i className='icon icon-remove'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {!jahIniciouImportacao &&
                        <div className='list-btn-importacao'>
                          <button type='button' onClick={() => limpar()} className='button --light'>Cancelar Importação</button>
                          <button type='button' onClick={() => simular()} className='button --primary'>Iniciar Importação</button>
                        </div>
                      }
                      {descricoes.length > 0 && renderizarResumoErros(descricoes)}
                      {(adicionados + alterados) > 0 && renderizarSucesso(adicionados, alterados)}
                    </>
                  }
                </r-cell>
                {erros.length > 0 &&
                  <div className='list-erros'>
                    <h2 className='form-title'>Erros</h2>
                    {erros.map((item, index) => renderizarLinha(item.linha, item.detalhes, index))}
                  </div>
                }
                {naoImportados.length > 0 &&
                  <div className='list-erros'>
                    <h2 className='form-title'>Inconsistências</h2>
                    {naoImportados.map((item, index) => renderizarLinha(item.linha, item.motivo, index))}
                  </div>
                }
                {jahIniciouImportacao &&
                  <div className='list-btn-importacao'>
                    <button type='button' onClick={() => limpar()} className='button --light'>Cancelar Importação</button>
                    {(adicionados + alterados) > 0 && <button type='button' onClick={() => importar()} className='button --primary'>Confirmar Importação {sufixoBotaoConfirmar}</button>}
                  </div>
                }
              </fieldset>
            </div>
          )}
        </Spinner>
      </div>
    </div>
  )
}

const renderizarLinha = (linha, descricao, index) => {
  return (
    <div
      key={`linha_${index}`}
      className={`panel__sidebar__importacao__item`}
    >
      <p><Hash size={18} />{linha} - {descricao}</p>
    </div>
  )
}

const renderizarResumoErros = descricoes => {
  return (
    <div className='list-erros'>
      <div className='form-choice-alerta'>
        <div className='form-choice-alerta__item'>
          <i className='icon icon-attention-circled'></i>
        </div>
        <div className='form-choice-alerta__item'>
          <ul>
            {descricoes.map(item => <li key={item}>{item}</li>)}
          </ul>
        </div>
      </div>
    </div>
  )
}

const renderizarSucesso = (adicionados, alterados) => {
  return (
      <div className='list-erros'>
        {adicionados > 0 &&
            <>
              <div className='form-choice-info'>
                <div className='form-choice-info__item'>
                  <i className='icon icon-attention-circled'></i>
                </div>
                {adicionados === 1 && <div className='form-choice-info__item'>{adicionados} paciente será importado.</div>}
                {adicionados > 1 && <div className='form-choice-info__item'>{adicionados} pacientes serão importados.</div>}
              </div>
            </>}
        {alterados > 0 &&
            <>
              <div className='form-choice-info'>
                <div className='form-choice-info__item'>
                  <i className='icon icon-attention-circled'></i>
                </div>
                {alterados === 1 && <div className='form-choice-info__item'>{alterados} paciente será alterado na importação.</div>}
                {alterados > 1 && <div className='form-choice-info__item'>{alterados} pacientes serão alterados na importação.</div>}
              </div>
            </>}
      </div>
  )
}