import {
  ABRIR_TELECONSULTA_NO_PRONTUARIO,
  CANCELOU_ATENDIMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  FECHAR_TELECONSULTA_NO_PRONTUARIO,
  FINALIZAR_ATENDIMENTO,
  INICIAR_ATENDIMENTO,
  RECUPEROU_TELECONSULTA_DO_ATENDIMENTO,
} from '../../acoes/tipos'

const estadoInicial = {
  videoChamada: false,
  dados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ABRIR_TELECONSULTA_NO_PRONTUARIO: {
      return {
        ...state,
        videoChamada: action.identificadorDoAtendimento
      }
    }

    case RECUPEROU_TELECONSULTA_DO_ATENDIMENTO: {
      return {
        ...state,
        dados: action.teleconsulta
      }
    }

    case ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO: {
      return {
        ...state,
        dados: {}
      }
    }

    case CANCELOU_ATENDIMENTO:
    case FECHAR_TELECONSULTA_NO_PRONTUARIO:
    case INICIAR_ATENDIMENTO:
    case FINALIZAR_ATENDIMENTO: {
      return estadoInicial
    }

    default: {
      return state
    }
  }
}