import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../spinner'
import { Input, Toglle, Select, Formulario as FormularioDoFormik, Cor } from '../formik/formulario'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {
  ADICIONAR_TIPO_DE_AGENDAMENTO,
  LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  REMOVER_TIPO_DE_AGENDAMENTO,
  SALVAR_TIPO_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_HORARIOS,
  LISTAR_TIPOS_DE_AGENDAMENTO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    history,
    listarModalidades,
    listarTiposDeHorarios,
    match,
    modalidades,
    recuperarPorIdentificador,
    remover,
    salvar,
    tipoDeAgendamento = {},
    tiposDeHorarios,
    tiposDeHorariosHabilitados
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_TIPO_DE_AGENDAMENTO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_TIPO_DE_AGENDAMENTO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_TIPO_DE_AGENDAMENTO])

  useEffect(() => listarModalidades(), [listarModalidades])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  useEffect(() => {
    listarTiposDeHorarios()
  }, [listarTiposDeHorarios])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/agendamentos/tipos-de-agendamento`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o tipo de atendimento ${tipoDeAgendamento.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(tipoDeAgendamento)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[
      RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
      SALVAR_TIPO_DE_AGENDAMENTO,
      ADICIONAR_TIPO_DE_AGENDAMENTO,
      REMOVER_TIPO_DE_AGENDAMENTO,
      LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
      LISTAR_TIPOS_DE_HORARIOS,
      LISTAR_TIPOS_DE_AGENDAMENTO
    ]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificador: tipoDeAgendamento.identificador || '',
          ativo: tipoDeAgendamento.ativo !== undefined ? tipoDeAgendamento.ativo : true,
          procedimento: tipoDeAgendamento.procedimento !== undefined ? tipoDeAgendamento.procedimento : false,
          nome: tipoDeAgendamento.nome || '',
          tempo: tipoDeAgendamento.tempo || '',
          cor: tipoDeAgendamento.cor || '',
          modalidade: tipoDeAgendamento.modalidade || '',
          codigoDaAns: tipoDeAgendamento.codigoDaAns || '',
          observacoes: tipoDeAgendamento.observacoes || '',
          tiposDeHorario: tiposDeHorariosHabilitados || []
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          tempo: Yup.number()
            .typeError('Dever ser numérico')
            .required('Obrigatório')
            .min(5, 'Mínimo 5 minutos')
            .max(1440, 'Máximo 1440 minutos')
            .positive('Deve ser positivo')
            .integer('Deve ser inteiro'),
          cor: Yup.string().required('Obrigatório'),
          modalidade: Yup.string().required('Obrigatório'),
          tiposDeHorario: Yup.array()
            .min(1, 'Pelo menos um tipo de horário deve ser informado.')
            .required('Tipos de horário são obrigatórios.'),
        })}
      >
        <fieldset className='mt-1'>
          <h2 className='form-title'>{tipoDeAgendamento.identificador ? 'Alterar Tipo de Agendamento' : 'Adicionar Tipo de Agendamento'}</h2>
          <r-grid columns-md={12} columns-lg={12}>
            <r-cell span={4} span-md={3} span-lg={4}>
              <Input
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            <r-cell span={2} span-md={2} span-lg={2}>
              <Input
                nome='tempo'
                tabIndex={2}
                titulo='Tempo (Minuto) *'
              />
            </r-cell>
            <r-cell span={2} span-md={2} span-lg={2}>
              <Cor
                nome='cor'
                tabIndex={3}
                titulo='Cor *'
              />
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={2}>
              <Select
                nome='modalidade'
                titulo='Modalidade *'
                itens={modalidades}
                campoCodigo='nome'
                campoDescricao='nome'
                tabIndex={4}
              />
            </r-cell>
            <r-cell span={4} span-md={2} span-lg={2}>
              <Input
                nome='codigoDaAns'
                tabIndex={5}
                titulo='Código da ANS'
              />
            </r-cell>
            <r-cell span={4} span-md={8} span-lg={8}>
              <Select
                nome='tiposDeHorario'
                campoCodigo='codigo'
                campoDescricao='nome'
                itens={tiposDeHorarios}
                isMulti={true}
                tabIndex={6}
                titulo='Tipos de Horário *'
              />
            </r-cell>
            <r-cell span={4} span-md={4} span-lg={4}>
              <div className='form-toggle-list'>
                <Toglle
                  className='form-toggle-2'
                  nome='ativo'
                  tabIndex={7}
                  titulo='Ativo'
                />
                <Toglle
                  className='form-toggle-2'
                  nome='procedimento'
                  tabIndex={8}
                  titulo='Procedimento'
                />
              </div>
            </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={9}
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
        </fieldset>
        {tipoDeAgendamento.identificador ?
          <div className='list-btn-unidades'>
            {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
            {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
            <button onClick={() => history.push(`/agendamentos/tipos-de-agendamento/lista`)} type='button' className='button --light'>Voltar</button>
          </div>
          :
          <div className='list-btn'>
            <button onClick={() => history.push(`/agendamentos/tipos-de-agendamento/lista`)} type='button' className='button --light'>Voltar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        }
      </FormularioDoFormik>
    </Spinner>
  )
}