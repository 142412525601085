import {
  ALTEROU_STATUS_DE_CONEXAO_DE_REDE
} from '../acoes/tipos'

let estadoInicial = {
  status: navigator.onLine
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ALTEROU_STATUS_DE_CONEXAO_DE_REDE: {
      return {
        ...state,
        status: action.status,
      }
    }

    default: {
      return state
    }
  }
}