import React from 'react'
import avatar from '../../design/img/no-thumb.png'
import IndicadoresDoPaciente from '../indicadores-do-paciente'

export default function FotoDoPaciente(props) {
  const {
    fotos,
    paciente: {
      aniversario,
      dataDoObito,
      foto,
      identificadorPublico,
      nome,
    }
  } = props

  return (
    <div className='foto-do-paciente'>
      <img
        alt={nome}
        src={(foto && fotos[foto]) || avatar}
      />
      <IndicadoresDoPaciente
        aniversario={aniversario}
        identificadorPublicoDoPaciente={identificadorPublico}
        modal={true}
        obito={dataDoObito}
      />
    </div>
  )
}