import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  FAZER_DOWNLOAD_DE_INTERNACOES,
  FEZ_DOWNLOAD_DE_INTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
} from '../tipos'

const listouProgramasDoRelatorioDeInternacoes = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  programas
})

const erroAoListarProgramasDoRelatorioDeInternacoes = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  erro
})

export const listarProgramasDoRelatorioDeInternacoes = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDeInternacoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDeInternacoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as programas do relatório de internações.')))
  }
}

const fezDownloadDeInternacoes = filtros => ({
  type: FEZ_DOWNLOAD_DE_INTERNACOES,
  filtros
})

const erroAoFazerDownloadDeInternacoes = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_INTERNACOES,
  erro,
  parametros
})

export const fazerDownloadDeInternacoes = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_INTERNACOES })

  try {
    const resultado = await api.fazerDownloadDeInternacoes(filtros)

    dispatch(adicionarNotificacoesDaApi(resultado))
    dispatch(fezDownloadDeInternacoes(filtros))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDeInternacoes(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download de internacoes.')))

    return null
  }
}