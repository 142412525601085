import React from 'react'
import SpinnerDaTarefa from './spinner-da-tarefa'
import CampoDaTarefa from './campo-da-tarefa'
import {ComponenteLeitura} from '../card'
import {FileText} from '../../../design/icones'

export default function Descricao({ definir, obrigatorio, processando, valor, leitura, rotulo }) {
  const icone = <FileText/>
  const titulo = rotulo ?? 'Descrição'

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        renderizar={() => <pre className='valor'>{valor}</pre>}
      />
    )
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valor}
      icone={icone}
      titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <>
          <textarea
            value={valor || ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
          />
          <SpinnerDaTarefa processando={processando}/>
        </>
      }
    </CampoDaTarefa>
  )
}