import api from './api-major'

const listarAssociacoes = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/associacoes${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarAssociacao = associacao => api.post(`/associacoes/`, associacao)
const recuperarAssociacaoPeloIdentificador = identificador => api.get(`/associacoes/${identificador}`)
const alterarAssociacao = associacao => api.put(`/associacoes/${associacao.identificador}`, associacao)
const removerAssociacao = identificador => api.delete(`/associacoes/${identificador}`)

export default {
  listarAssociacoes,
  adicionarAssociacao,
  recuperarAssociacaoPeloIdentificador,
  alterarAssociacao,
  removerAssociacao
}