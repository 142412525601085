import {LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS: {
      return action.tiposDeAtendimento
    }

    default: {
      return state
    }
  }
}