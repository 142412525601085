import pacientes from './pacientes'
import categorias from './categorias'
import urgencias from './urgencias'
import motivosDeCancelamento from './motivos-de-cancelamento'
import profissionaisResponsaveis from './profissionais-responsaveis'
import setoresResponsaveis from './setores-responsaveis'
import tipos from './tipos'
import filtros from './filtros'

import {
  ALTEROU_STATUS_DA_TAREFA,
  ATUALIZAR_ATIVIDADES_DA_TAREFA,
  ATUALIZAR_TAREFA_ATUAL,
  ATUALIZAR_TAREFAS,
  ATUALIZAR_TAREFAS_DO_PACIENTE,
  ATUALIZAR_TAREFAS_DO_USUARIO,
  DEFINIR_DASHBOARD_DA_TAREFA_ATUAL,
  DEFINIR_TIPO_DA_TAREFA_ATUAL,
  DEFINIU_CATEGORIA_DA_TAREFA,
  DEFINIU_DATA_DA_TAREFA,
  DEFINIU_DESCRICAO_DA_TAREFA,
  DEFINIU_MOMENTO_DA_TAREFA,
  DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  DEFINIU_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIU_PACIENTE_DA_TAREFA,
  DEFINIU_PRAZO_DA_TAREFA,
  DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA,
  DEFINIU_TEXTO_LONGO_DA_TAREFA,
  DEFINIU_TEXTO_DA_TAREFA,
  DEFINIU_URGENCIA_DA_TAREFA,
  DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  LIMPAR_TAREFA_ATUAL,
  MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA
} from '../../acoes/tipos'

const estadoInicial = {
  filtrosAtuais: [],
  tipoDaTarefaAtual: null,
  dashboardDaTarefaAtual: '',
  itens: {},
  itensDoPaciente: {},
  itensDoUsuario: {},
  itemAtual: null,
  motivosDeCancelamento: undefined,
  pacientes: [],
  categorias: undefined,
  tipos: {},
  urgencias: undefined,
  profissionaisResponsaveis: undefined,
  setoresResponsaveis: undefined,
  atividades: {},
}

const tarefas = (state = estadoInicial, action) => {
  switch (action.type) {
    case ATUALIZAR_TAREFAS: {
      const tarefas = action.tarefas || []
      let itens = {}

      tarefas.forEach((item, index) => {
        itens[item.identificador] = {
          ...item,
          comentarios: item.comentarios.reverse(),
          _ordenacao: index
        }
      })

      return { ...state, itens }
    }

    case ATUALIZAR_TAREFAS_DO_PACIENTE: {
      const tarefas = action.tarefas || []
      let itensDoPaciente = {}

      tarefas.forEach((item, index) => {
        itensDoPaciente[item.identificador] = {
          ...item,
          comentarios: item.comentarios.reverse(),
          _ordenacao: index
        }
      })

      return { ...state, itensDoPaciente }
    }

    case ATUALIZAR_TAREFAS_DO_USUARIO: {
      const tarefas = action.tarefas || []
      let itensDoUsuario = {}

      tarefas.forEach((item, index) => {
        itensDoUsuario[item.identificador] = {
          ...item,
          comentarios: item.comentarios.reverse(),
          _ordenacao: index
        }
      })

      return { ...state, itensDoUsuario }
    }

    case ATUALIZAR_TAREFA_ATUAL: {
      return {
        ...state,
        itemAtual: {
          ...action.tarefa,
          comentarios: action.tarefa.comentarios.reverse()
        },
      }
    }

    case ALTEROU_STATUS_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          status: action.status
        }
      }
    }

    case DEFINIU_CATEGORIA_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          categoria: action.categoria
        }
      }
    }

    case DEFINIU_DATA_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          datas: {
            ...state.itemAtual.datas,
            [action.campo]: action.conteudo
          }
        }
      }
    }

    case DEFINIU_DESCRICAO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          descricao: action.descricao
        }
      }
    }

    case DEFINIU_PRAZO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          prazo: action.prazo
        }
      }
    }

    case MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          listasDeOpcoesMultiplas: {
            ...state.itemAtual.listasDeOpcoesMultiplas,
            [action.lista]: state.itemAtual.listasDeOpcoesMultiplas[action.lista].concat(action.item)
          }
        }
      }
    }

    case DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          listasDeOpcoesMultiplas: {
            ...state.itemAtual.listasDeOpcoesMultiplas,
            [action.lista]: state.itemAtual.listasDeOpcoesMultiplas[action.lista].filter(y => y !== action.item)
          }
        }
      }
    }

    case DEFINIU_MOMENTO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          momentos: {
            ...state.itemAtual.momentos,
            [action.campo]: action.conteudo
          }
        }
      }
    }

    case DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          motivoDeCancelamento: action.motivoDeCancelamento
        }
      }
    }

    case DEFINIU_NUMERO_INTEIRO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          numerosInteiros: {
            ...state.itemAtual.numerosInteiros,
            [action.campo]: action.conteudo
          }
        }
      }
    }


    case DEFINIU_PACIENTE_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          paciente: action.paciente
        }
      }
    }

    case DEFINIU_PROFISSIONAL_RESPONSAVEL_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          responsavel:  { tipo: "profissional", identificador: action.profissional }
        }
      }
    }

    case DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          responsavel: { tipo: "setor", identificador: action.setor }
        }
      }
    }

    case DEFINIU_TELEFONE_DE_CONTATO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          telefoneDeContato: action.telefoneDeContato
        }
      }
    }

    case DEFINIU_TEXTO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          textos: {
            ...state.itemAtual.textos,
            [action.campo]: action.conteudo
          }
        }
      }
    }

    case DEFINIU_TEXTO_LONGO_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          textosLongos: {
            ...state.itemAtual.textosLongos,
            [action.campo]: action.conteudo
          }
        }
      }
    }

    case DEFINIU_URGENCIA_DA_TAREFA: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          urgencia: action.urgencia
        }
      }
    }

    case LIMPAR_TAREFA_ATUAL: {
      return {
        ...state,
        itemAtual: null,
      }
    }

    case ATUALIZAR_ATIVIDADES_DA_TAREFA: {
      const { identificadorDaTarefa, atividades } = action

      return {
        ...state,
        atividades: {
          ...state.atividades,
          [identificadorDaTarefa]: {
            atividades
          }
        },
      }
    }

    case DEFINIR_TIPO_DA_TAREFA_ATUAL: {
      return {
        ...state,
        tipoDaTarefaAtual: action.identificador,
      }
    }

    case DEFINIR_DASHBOARD_DA_TAREFA_ATUAL: {
      return {
        ...state,
        dashboardDaTarefaAtual: action.dashboard,
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = tarefas(state, action)

  return {
    ...dados,
    filtrosAtuais: filtros(state.filtrosAtuais, action),
    categorias: categorias(state.categorias, action),
    motivosDeCancelamento: motivosDeCancelamento(state.motivosDeCancelamento, action),
    pacientes: pacientes(state.pacientes, action),
    profissionaisResponsaveis: profissionaisResponsaveis(state.profissionaisResponsaveis, action),
    setoresResponsaveis: setoresResponsaveis(state.setoresResponsaveis, action),
    tipos: tipos(state.tipos, action),
    urgencias: urgencias(state.urgencias, action)
  }
}