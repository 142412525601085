import {  
  LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,  
} from '../acoes/tipos'

let estadoInicial = {
  itens: {},  
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA: {
      let itens = {}

      action.motivosDeBloqueioDaAgenda.forEach(motivo => {
        itens[motivo.codigo] = motivo
      })

      return {
        ...state,
        itens: {
          ...itens,
        },        
      }
    }    

    default: {
      return state
    }
  }
}