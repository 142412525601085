import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi, adicionarNotificacao } from '../notificacoes'

import {
  ADICIONAR_UNIDADE_DA_EMPRESA,
  ADICIONOU_UNIDADE_DA_EMPRESA,
  ATIVAR_UNIDADE_DA_EMPRESA,
  ATIVOU_UNIDADE_DA_EMPRESA,
  DESATIVAR_UNIDADE_DA_EMPRESA,
  DESATIVOU_UNIDADE_DA_EMPRESA,
  ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA,
  ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA,
  ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA,
  LISTAR_UNIDADES_DA_EMPRESA,
  LISTOU_UNIDADES_DA_EMPRESA,
  RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP,
  RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP,
  REMOVER_UNIDADE_DA_EMPRESA,
  REMOVEU_UNIDADE_DA_EMPRESA,
  SALVAR_UNIDADE_DA_EMPRESA,
  SALVOU_UNIDADE_DA_EMPRESA,
  SELECIONAR_UNIDADE,
} from '../tipos'

const adicionouUnidadeDaEmpresa = unidade => ({
  type: ADICIONOU_UNIDADE_DA_EMPRESA,
  unidade,
})

const erroAoAdicionarUnidadeDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA,
  erro,
  parametros,
})

export const adicionarUnidadeDaEmpresa = unidade => async dispatch => {
  dispatch({ type: ADICIONAR_UNIDADE_DA_EMPRESA })

  try {
    const resultado = await api.adicionarUnidadeDaEmpresa(unidade)

    dispatch(adicionouUnidadeDaEmpresa(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarUnidadeDaEmpresa(erro, { unidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar unidade da empresa.')))

    return false
  }
}

const salvouUnidadeDaEmpresa = unidade => ({
  type: SALVOU_UNIDADE_DA_EMPRESA,
  unidade,
})

const erroAoSalvarUnidadeDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA,
  erro,
  parametros,
})

export const salvarUnidadeDaEmpresa = unidade => async dispatch => {
  dispatch({ type: SALVAR_UNIDADE_DA_EMPRESA })

  try {
    const resultado = await api.salvarUnidadeDaEmpresa(unidade)

    dispatch(salvouUnidadeDaEmpresa(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarUnidadeDaEmpresa(erro, { unidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar a unidade da empresa.')))

    return false
  }
}

const listouUnidadesDaEmpresa = unidades => ({
  type: LISTOU_UNIDADES_DA_EMPRESA,
  unidades,
})

const erroAoListarUnidadesDaEmpresa = erro => ({
  type: ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA,
  erro
})

export const listarUnidadesDaEmpresa = () => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DA_EMPRESA })

  try {
    const resultado = await api.listarUnidadesDaEmpresa()

    dispatch(listouUnidadesDaEmpresa(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUnidadesDaEmpresa(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as unidades da empresa.')))
  }
}

const removeuUnidadeDaEmpresa = identificador => ({
  type: REMOVEU_UNIDADE_DA_EMPRESA,
  identificador,
})

const erroAoRemoverUnidadeDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA,
  erro,
  parametros,
})

export const removerUnidadeDaEmpresa = identificador => async dispatch => {
  dispatch({ type: REMOVER_UNIDADE_DA_EMPRESA })

  try {
    const resultado = await api.removerUnidadeDaEmpresa(identificador)

    dispatch(removeuUnidadeDaEmpresa(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverUnidadeDaEmpresa(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível excluir a unidade da empresa.')))

    return false
  }
}

const ativouUnidadeDaEmpresa = (identificador, ativa) => ({
  type: ATIVOU_UNIDADE_DA_EMPRESA,
  identificador,
  ativa,
})

const erroAoAtivarUnidadeDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA,
  erro,
  parametros,
})

export const ativarUnidadeDaEmpresa = identificador => async dispatch => {
  dispatch({ type: ATIVAR_UNIDADE_DA_EMPRESA })

  try {
    const resultado = await api.alterarStatusUnidadeDaEmpresa(identificador, 'ativa')
    const status = resultado.data.dados === 'Ativa'

    dispatch(ativouUnidadeDaEmpresa(identificador, status))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAtivarUnidadeDaEmpresa(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível ativar a unidade da empresa.')))

    return false
  }
}

const desativouUnidadeDaEmpresa = (identificador, ativa) => ({
  type: DESATIVOU_UNIDADE_DA_EMPRESA,
  identificador,
  ativa,
})

const erroAoDesativarUnidadeDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA,
  erro,
  parametros,
})

export const desativarUnidadeDaEmpresa = identificador => async dispatch => {
  dispatch({ type: DESATIVAR_UNIDADE_DA_EMPRESA })

  try {
    const resultado = await api.alterarStatusUnidadeDaEmpresa(identificador, 'inativa')
    const status = resultado.data.dados === 'Inativa'

    dispatch(desativouUnidadeDaEmpresa(identificador, !status))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDesativarUnidadeDaEmpresa(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível desativar a unidade da empresa.')))

    return false
  }
}

const recuperouEnderecoDaUnidadePorCep = (endereco, cep) => async dispatch => {
  dispatch({ type: RECUPEROU_ENDERECO_DA_UNIDADE_POR_CEP, endereco, cep })
}

const erroAoRecuperarEnderecoDaUnidadePorCep = (erro, parametros) => async dispatch => {
  dispatch({ type: ERRO_AO_RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP, erro, parametros })
}

export const recuperarEnderecoDaUnidadePorCep = cep => async dispatch => {
  dispatch({ type: RECUPERAR_ENDERECO_DA_UNIDADE_POR_CEP })

  try {
    const resultado = await api.recuperarEnderecoPorCep(cep)

    if (resultado.data.erro) {
      dispatch(adicionarNotificacao(notificacao.deErro('Não foi possível recuperar o endereço pelo CEP.')))
    }

    dispatch(recuperouEnderecoDaUnidadePorCep(resultado.data, cep))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoRecuperarEnderecoDaUnidadePorCep(erro, { cep }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o endereço pelo CEP.')))

    return null
  }
}

export const selecionarUnidade = identificador => ({
  type: SELECIONAR_UNIDADE,
  identificador,
})