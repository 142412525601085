import React, {useEffect, useMemo} from 'react'
import Breadcrumb from '../../breadcrumb'
import {Link, Switch} from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Dashboard from './dashboard'
import IndicadoresDeImpacto from "./indicadores-de-impacto"
import IndicadoresEstrategicos from "./indicadores-estrategicos"
import PlanosDeCuidadosUN from "./planos-de-cuidados-un"
import {converterObjetoParaArray} from '../../../bibliotecas/conversao'
import { usePermissaoQueUsuarioPossui } from "../../seguranca/permissao"
import {RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI} from '../../../acoes/tipos'
import Spinner from '../../spinner'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import {
  Agenda,
  Atendimento,
  Charts,
  Clinica,
  Confirmacao,
  ConsultasMedicas,
  Cuidado,
  FraturaDeFemur,
  GraficoDeLinhas,
  Idosos,
  Indicator,
  Nps,
  Nurse,
  Objetivo,
  Operacao,
  PlanoDeCuidados,
  Populacao,
  ProntoCuidar,
  Reinternacao,
} from '../../../design/icones'

export default function Dashboards(props) {
  const {
    match,
    recuperarTokenDosRelatoriosDoPowerBI,
    relatorios = {},
  } = props

  const relatoriosDoPowerBI = useMemo(() => converterObjetoParaArray(relatorios).filter(x =>  x.codigo !== 'PACIENTES_DOS_PROGRAMAS').map(x => x.codigo), [relatorios])

  useEffect(() => {
    if (relatoriosDoPowerBI.length > 0) {
      recuperarTokenDosRelatoriosDoPowerBI(relatoriosDoPowerBI)
    }
  }, [recuperarTokenDosRelatoriosDoPowerBI, relatoriosDoPowerBI])

  const possuiPermissaoPlanoDeCuidados = usePermissaoQueUsuarioPossui([
    permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN,
    permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA,
    permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_ENFERMAGEM,
    permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_MEDICO,
    permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_CONTRATUAL_UN
  ])

  const possuiPermissaoIndicadoresDeImpacto = usePermissaoQueUsuarioPossui([
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_IMPACTO,
    permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO,
    permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR,
    permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_REINTERNACAO,
  ])

  const possuiPermissaoIndicadoresEstrategicos = usePermissaoQueUsuarioPossui([
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_DESFECHO_CLINICO,
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_MANUTENCAO_DO_CUIDADO,
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_DEMOGRAFICOS,
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_DO_PERFIL_DE_FRAGILIDADE,
    permissoes.VISUALIZAR_RELATORIO___INDICADORES_OPERACIONAIS,
  ])

  const dashboards = [

    {
      url: 'atendimentos-mensais',
      permissao: permissoes.VISUALIZAR_RELATORIO___ATENDIMENTOS_MENSAIS,
      icone: <Atendimento />,
      nome: 'Atendimentos Mensais',
    },
    {
      url: 'confirmacoes-de-agendamentos',
      permissao: permissoes.VISUALIZAR_RELATORIO___CONFIRMACOES_DE_AGENDAMENTOS,
      icone: <Confirmacao />,
      nome: 'Confirmações de Agendamentos',
    },
    {
      url: 'gestao-de-encaminhamentos',
      permissao: permissoes.VISUALIZAR_RELATORIO___GESTAO_DE_ENCAMINHAMENTOS,
      icone: 'icon-forward',
      nome: 'Gestão de Encaminhamentos',
    },
    {
      url: 'gestao-da-agenda',
      permissao: permissoes.VISUALIZAR_RELATORIO___GESTAO_DA_AGENDA,
      icone: <Agenda />,
      nome: 'Gestão da Agenda',
    },
    {
      url: 'indicadores-contratuais-un',
      permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_CONTRATUAIS_UN,
      icone: <GraficoDeLinhas />,
      nome: 'Indicadores Contratuais UN',
    },
    {
      menu: 'indicadores',
      nome: 'Indicadores de Impacto',
      icone: <Indicator />,
      permissao: possuiPermissaoIndicadoresDeImpacto,
      dashboards: [
        {
          url: 'indicador-de-internacao-clinica',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_IMPACTO,
          icone: 'icon-estabelecimento-de-saude',
          nome: 'Internações Clínicas',
        },
        {
          url: 'indicador-de-ida-ao-pronto-atendimento',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO,
          icone: 'icon-sala',
          nome: 'Idas ao Pronto Atendimento'
        },
        {
          url: 'indicador-de-internacao-por-fratura-de-femur',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR,
          icone: <FraturaDeFemur />,
          nome: 'Internações por Fratura de Fêmur'
        },
        {
          url: 'indicador-de-reinternacao',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADOR_DE_REINTERNACAO,
          icone: <Reinternacao />,
          nome: 'Reinternações Clínicas'
        }
      ]
    },
    {
      menu: 'indicadores-estrategicos',
      nome: 'Indicadores Estratégicos',
      icone: <Objetivo />,
      permissao: possuiPermissaoIndicadoresEstrategicos,
      dashboards: [
        {
          url: 'indicadores-de-desfecho-clinico',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_DESFECHO_CLINICO,
          icone: <Clinica />,
          nome: 'Indicadores de Desfecho Clínico'
        },
        {
          url: 'indicadores-de-manutencao-do-cuidado',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_DE_MANUTENCAO_DO_CUIDADO,
          icone: <Cuidado />,
          nome: 'Indicadores de Manutenção do Cuidado'
        },
        {
          url: 'indicadores-demograficos',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_DEMOGRAFICOS,
          icone: <Populacao />,
          nome: 'Indicadores Demográficos'
        },
        {
          url: 'indicadores-do-perfil-de-fragilidade',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_DO_PERFIL_DE_FRAGILIDADE,
          icone: <Idosos />,
          nome: 'Indicadores do Perfil de Fragilidade'
        },
        {
          url: 'indicadores-operacionais',
          permissao: permissoes.VISUALIZAR_RELATORIO___INDICADORES_OPERACIONAIS,
          icone: <Operacao />,
          nome: 'Indicadores Operacionais',
        },
      ]
    },
    {
      url: 'navegacao-medica',
      permissao: permissoes.VISUALIZAR_RELATORIO___NAVEGACAO_MEDICA,
      icone: 'icon-shuffle',
      nome: 'Navegação Médica',
    },
    {
      url: 'net-promoter-score',
      permissao: permissoes.VISUALIZAR_RELATORIO___NPS,
      icone: <Nps />,
      nome: 'Net Promoter Score',
    },
    {
      url: 'notificacoes-de-internacoes-e-idas-ao-pronto-atendimento',
      permissao: permissoes.VISUALIZAR_RELATORIO___NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO,
      icone: 'icon-doc-text',
      nome: 'Notificações de Internações e Idas ao Pronto Atendimento',
    },
    {
      menu: 'planos-de-cuidados',
      nome: 'Planos de Cuidados',
      icone: <PlanoDeCuidados />,
      permissao: possuiPermissaoPlanoDeCuidados,
      dashboards: [
        {
          url: 'plano-de-cuidados-de-consultas-medicas-un',
          permissao: permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN,
          icone: <ConsultasMedicas />,
          nome: 'Consultas Médicas',
        },
        {
          url: 'plano-de-cuidados-de-consultas-medicas',
          permissao: permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA,
          icone: 'icon-doctor',
          nome: 'Plano de Cuidados de Consultas Médicas',
        },
        {
          url: 'plano-de-cuidados-enfermagem',
          permissao: permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_ENFERMAGEM,
          icone: <Nurse size={70} />,
          nome: 'Enfermagem',
        },
        {
          url: 'plano-de-cuidados-medico',
          permissao: permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_MEDICO,
          icone: 'icon-doctor',
          nome: 'Médico',
        },
        {
          url: 'plano-de-cuidados-contratual-un',
          permissao: permissoes.VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_CONTRATUAL_UN,
          icone: 'icon-doctor',
          nome: 'Plano de Cuidados Contratual UN',
        }
      ]
    },
    {
      url: 'resolutividade-do-pronto-cuidar',
      permissao: permissoes.VISUALIZAR_RELATORIO___RESOLUTIVIDADE_DO_PRONTO_CUIDAR,
      icone: <ProntoCuidar />,
      nome: 'Resolutividade do Pronto Cuidar',
    },
    {
      url: 'vacinacao',
      permissao: permissoes.VISUALIZAR_RELATORIO___VACINACAO,
      icone: 'icon-eyedropper',
      nome: 'Vacinação',
    },
    {
      url: 'volumetria',
      permissao: permissoes.VISUALIZAR_RELATORIO___VOLUMETRIA,
      icone: <Charts />,
      nome: 'Volumetria',
    },
  ]

  return (
      <div className='container'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI]}>
          {({ processando }) => {
            if (processando) {
              return <h2 className='verificando-permissoes'>Verificando permissões...</h2>
            }
            return (
                <Switch>
                  <RotaComAutenticacao
                      exact
                      path={match.path}
                      render={() =>
                          <div className='dashboard relatorios'>
                            <div className='container'>
                              <div className='centraliza-dashboard'>
                                {dashboards.map((dash, index) => dash.menu ?
                                    <Menu key={index} match={match} {...dash}/>
                                    : <Atalho key={index} match={match} {...dash} />)}
                              </div>
                            </div>
                          </div>
                      }
                  />
                  <RotaComAutenticacao
                      exact
                      path={`${match.path}/:dashboard`}
                      render={() => <Dashboard relatorios={relatorios} />}
                  />
                  <RotaComAutenticacao
                      exact
                      path={`${match.path}/menu/indicadores`}
                      render={() => <IndicadoresDeImpacto match={match} dashboards={dashboards.find(x => x.menu === "indicadores").dashboards} />}
                  />
                  <RotaComAutenticacao
                      exact
                      path={`${match.path}/menu/indicadores-estrategicos`}
                      render={() => <IndicadoresEstrategicos match={match} dashboards={dashboards.find(x => x.menu === "indicadores-estrategicos").dashboards} />}
                  />
                  <RotaComAutenticacao
                      exact
                      path={`${match.path}/menu/planos-de-cuidados`}
                      render={() => <PlanosDeCuidadosUN match={match} dashboards={dashboards.find(x => x.menu === "planos-de-cuidados").dashboards} />}
                  />
                </Switch>
            )
          }}
        </Spinner>
      </div>
  )
}

function Menu({ match, menu, nome, icone, permissao }){
  return (
      <Permissao permissoes={[permissoes.VISUALIZAR_RELATORIO_DO_POWER_BI, permissao]}>
        <Link
            to={`${match.url}/menu/${menu}`}
            className='dashboard-item'>
          {typeof icone === 'string' && <i className={`dashboard-item-icon --br icon ${icone}`}></i>}
          {typeof icone === 'object' && <div className='dashboard-item-icon --br icon'>{icone}</div>}
          <p>{nome}</p>
        </Link>
      </Permissao>
  )
}

function Atalho({ match, url, icone, nome, permissao }) {
  return (
      <Permissao permissoes={[permissoes.VISUALIZAR_RELATORIO_DO_POWER_BI, permissao]}>
        <Link
            to={`${match.url}/${url}`}
            className='dashboard-item'>
          {typeof icone === 'string' && <i className={`dashboard-item-icon --br icon ${icone}`}></i>}
          {typeof icone === 'object' && <div className='dashboard-item-icon --br icon'>{icone}</div>}
          <p>{nome}</p>
        </Link>
      </Permissao>
  )
}