import { useEffect } from 'react'

export default function useCliqueForaDoElemento(ref, acao) {
  useEffect(() => {
    function click(event) {
      if (ref.current && !ref.current.contains(event.target) && acao) {
        acao()
      }
    }

    document.addEventListener('mousedown', click)
    return () => {
      document.removeEventListener('mousedown', click)
    }
  }, [ref, acao])
}