import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ALTERAR_STATUS_DA_TAREFA,
  ALTEROU_STATUS_DA_TAREFA,
  ERRO_AO_ALTERAR_STATUS_DA_TAREFA
} from '../tipos'

const alterouStatusDaTarefa = (identificadorDaTarefa, status) => ({
  identificadorDaTarefa,
  status,
  type: ALTEROU_STATUS_DA_TAREFA
})

const erroAoAlterarStatusDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_STATUS_DA_TAREFA,
  erro,
  parametros,
})

export const alterarStatusDaTarefa = (identificadorDaTarefa, status) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DA_TAREFA })

  try {
    const resultado = await api.alterarStatusDaTarefa(identificadorDaTarefa, status)

    dispatch(alterouStatusDaTarefa(identificadorDaTarefa, status))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDaTarefa(erro, { identificadorDaTarefa, status }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o status da tarefa.')))

    return false
  }
}