import api from '../api-hercules'

const listarPacientesDoTarefas = (filtros, pagina, ordenacao, tamanhoDaPagina = 50) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros && filtros.termo ? `&termo=${filtros.termo}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = `&tamanhoDaPagina=${tamanhoDaPagina}`

  return api.get(`/pacientes${paginacao}${tamanhoDaPaginaFormatado}${parametroTermo}${ordem}`)
}

const definirPacienteDaTarefa = (identificadorDaTarefa, paciente) => {
  const parametroPaciente = paciente ? paciente : null
  return api.put(`tarefas/${identificadorDaTarefa}/paciente`, { paciente: parametroPaciente })
}

export default {
  listarPacientesDoTarefas,
  definirPacienteDaTarefa
}