import { connect } from 'react-redux'
import acoes from '../../acoes'
import Encaminhamentos from './index'

const mapStateToProps = state => {
  const prioridades = [
    { codigo: '', nome: 'Todas' },
    { codigo: true, nome: 'Sim' },
    { codigo: false, nome: 'Não' }
  ]

  return {
    filtros: state.encaminhamentos.filtros,
    ordenacao: state.encaminhamentos.ordenacao,
    paginaDeDados: state.encaminhamentos.paginaDeDados,
    tiposDeEncaminhamento: state.encaminhamentos.tipos.dados?.filter(t => t.habilitado),
    statusDeEncaminhamento: state.encaminhamentos.status.dados,
    prioridades
  }
}

export default connect(mapStateToProps, acoes)(Encaminhamentos)