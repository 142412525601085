import React from 'react'
import { ehUmNumeroDeTelefoneValido } from '../../../bibliotecas/validacoes'
import SpinnerDaTarefa from './spinner-da-tarefa'
import CampoDaTarefa from './campo-da-tarefa'
import { ComponenteLeitura } from '../card'
import { Phone } from '../../../design/icones'
import InputMask from 'react-input-mask'

export default function TelefoneDeContato({ definir, obrigatorio, processando, valor, leitura, rotulo }) {
  const filtrarNumeros = valor => valor && valor.replace(/\D/g, '')
  const icone = <Phone />
  const titulo = rotulo ?? 'Telefone de contato'

  const validar = valor => {
    if (!ehUmNumeroDeTelefoneValido(filtrarNumeros(valor))) {
      return 'Telefone inválido. Ex.: 99 99999-9999'
    }

    return null
  }

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        valor={valor}
      />
    )
  }

  const enviarMascara = valor => {
    if (!valor || valor.length === 0) return ''
    const numeros = filtrarNumeros(valor)
    return numeros.length < 11 ? '(99) 9999-9999?' : '(99) 99999-9999'
  }

  return (
    <CampoDaTarefa
      validar={validar}
      gravar={definir}
      valor={valor}
      icone={icone}
      titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor, foco }) =>
        <>
          <InputMask
            mask={enviarMascara(valor)}
            placeholder='99 99999-9999'
            value={valor || ''}
            onChange={evento => onChange(filtrarNumeros(evento.target.value))}
            formatChars={{ 9: '[0-9]', '?': '[0-9]' }}
            maskChar={!foco ? '' : '_'}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
          />
          <SpinnerDaTarefa processando={processando} />
        </>
      }
    </CampoDaTarefa>
  )
}