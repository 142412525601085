import { converterArrayParaObjeto } from '../../bibliotecas/conversao'
import {
  ADICIONOU_UNIDADE_DO_USUARIO,
  AUTENTICOU_USUARIO,
  CANCELOU_ATENDIMENTO,
  ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  FINALIZOU_ATENDIMENTO,
  INICIOU_ATENDIMENTO,
  INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  LISTOU_ATENDIMENTOS_INICIADOS,
  LISTOU_EMPRESAS_HABILITADAS,
  LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  LISTOU_UNIDADES_DO_USUARIO_LOGADO,
  RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPEROU_USUARIO_POR_IDENTIFICADOR,
  REMOVEU_FOTO_DO_USUARIO,
  REMOVEU_UNIDADE_DO_USUARIO,
  SALVOU_USUARIO,
} from '../../acoes/tipos'

const estadoInicial = {
  permissoes: [],
  unidades: {},
  tiposDeAtendimento: {},
  empresas: [],
  profissao: null,
  atendimentos: [],
  certificadosEmNuvem: {
    lista: [],
    erro: false
  },
  configuracoes: {
    agenda: []
  },
  configuracoesVigentes: {
    agenda: []
  },
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case AUTENTICOU_USUARIO: {
      return {
        ...state,
        ...action.usuario
      }
    }

    case LISTOU_UNIDADES_DO_USUARIO_LOGADO: {
      return {
        ...state,
        unidades: converterArrayParaObjeto(action.unidades),
      }
    }

    case REMOVEU_UNIDADE_DO_USUARIO: {
      if (state.identificador === action.identificadorDoUsuario) {
        let unidades = { ...state.unidades }
        delete unidades[action.identificadorDaUnidade]

        return {
          ...state,
          unidades,
        }
      }

      return state
    }

    case ADICIONOU_UNIDADE_DO_USUARIO: {
      if (state.identificador === action.identificadorDoUsuario) {
        let unidades = { ...state.unidades }
        unidades[action.unidade.identificador] = action.unidade

        return {
          ...state,
          unidades,
        }
      }

      return state
    }

    case RECUPEROU_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR: {
      return {
        ...state,
        foto: action.foto,
      }
    }

    case LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO: {
      let tiposDeAtendimento = {}

      action.tiposDeAtendimento.forEach(tipoDeAtendimento => {
        tiposDeAtendimento[tipoDeAtendimento.nome] = tipoDeAtendimento.nome
      })

      return {
        ...state,
        tiposDeAtendimento,
      }
    }

    case SALVOU_USUARIO: {
      const salvouUsuario = state.identificador === action.usuario.identificador

      if (salvouUsuario) {
        return {
          ...state,
          foto: action.usuario.foto,
          nome: action.usuario.nome,
          email: action.usuario.email,
          profissao: action.usuario.profissao
        }
      }

      return state
    }

    case REMOVEU_FOTO_DO_USUARIO: {
      const usuario = state.identificador === action.identificador

      if (usuario) {
        return {
          ...state,
          foto: null
        }
      }

      return state
    }

    case LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN: {
      return {
        ...state,
        empresas: action.empresas,
      }
    }

    case LISTOU_EMPRESAS_HABILITADAS: {
      return {
        ...state,
        empresas: action.empresas,
      }
    }

    case RECUPEROU_USUARIO_POR_IDENTIFICADOR: {
      let usuario = action.usuario

      delete usuario['unidades']

      return {
        ...state,
        profissao: usuario.identificador === state.identificador ? usuario.profissao : state.profissao,
      }
    }

    case LISTOU_ATENDIMENTOS_INICIADOS: {
      return {
        ...state,
        atendimentos: action.atendimentos
      }
    }

    case CANCELOU_ATENDIMENTO:
    case FINALIZOU_ATENDIMENTO: {
      return {
        ...state,
        atendimentos: state.atendimentos.filter(x => x.identificador !== action.atendimento.identificador)
      }
    }

    case INICIOU_ATENDIMENTO: {
      return {
        ...state,
        atendimento: state.atendimentos.push(
          {
            identificador: action.atendimento.identificador,
            paciente: action.atendimento.paciente,
          }
        )
      }
    }

    case RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO: {
      return {
        ...state,
        certificadosEmNuvem: {
          ...state.certificadosEmNuvem,
          lista: action.dados,
          erro: false,
        }
      }
    }

    case RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM:
    case INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM: {
      return {
        ...state,
        certificadosEmNuvem: {
          ...state.certificadosEmNuvem,
          erro: false,
        }
      }
    }


    case RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO: {
      return {
        ...state,
        configuracoes: {
          ...action.configuracoes
        }
      }
    }

    case RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO: {
      return {
        ...state,
        configuracoesVigentes: {
          ...action.configuracoesVigentes
        }
      }
    }

    case ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO: {
      return {
        ...state,
        certificadosEmNuvem: {
          ...state.certificadosEmNuvem,
          sessaoIniciada: false,
          erro: true
        }
      }
    }

    case ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM: {
      return {
        ...state,
        certificadosEmNuvem: {
          ...state.certificadosEmNuvem,
          sessaoIniciada: false,
        }
      }
    }

    case ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR: {
      return {
        ...state,
        foto: null
      }
    }

    default: {
      return state
    }
  }
}