import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS
} from '../tipos'

const definiuMotivoDoCancelamentoDaTarefa = (identificadorDaTarefa, motivoDeCancelamento) => ({
  identificadorDaTarefa,
  motivoDeCancelamento,
  type: DEFINIU_MOTIVO_DO_CANCELAMENTO_DA_TAREFA
})

const erroAoDefinirMotivoDoCancelamentoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  erro,
  parametros,
})

export const definirMotivoDoCancelamentoDaTarefa = (identificadorDaTarefa, motivoDoCancelamento) => async dispatch => {
  dispatch({ type: DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA })

  try {
    const resultado = await api.definirMotivoDoCancelamentoDaTarefa(identificadorDaTarefa, motivoDoCancelamento)

    dispatch(definiuMotivoDoCancelamentoDaTarefa(identificadorDaTarefa, motivoDoCancelamento))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirMotivoDoCancelamentoDaTarefa(erro, { identificadorDaTarefa, motivoDoCancelamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o motivo do cancelamento da tarefa.')))

    return false
  }
}

const listouMotivosDeCancelamentoDoTarefas = motivosDeCancelamento => ({
  type: LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  motivosDeCancelamento
})

const erroAoListarMotivosDeCancelamentoDoTarefas = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  erro
})

export const listarMotivosDeCancelamentoDoTarefas = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS })

  try {
    const resultado = await api.listarMotivosDeCancelamentoDoTarefas()
    dispatch(listouMotivosDeCancelamentoDoTarefas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarMotivosDeCancelamentoDoTarefas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de cancelamento do tarefas.')))

    return false
  }
}