import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO
} from '../../tipos'

const adicionouTagNoPacienteDoProntuario = (identificadorDoPaciente, tag) => ({
  type: ADICIONOU_TAG_NO_PACIENTE_DO_PRONTUARIO,
  identificadorDoPaciente,
  tag,
})

const erroAoAdicionarTagNoPacienteDoProntuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  erro,
  parametros,
})

export const adicionarTagNoPacienteDoProntuario = (identificadorDoPaciente, tag) => async dispatch => {
  dispatch({ type: ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO, identificadorDoPaciente, tag })

  try {
    const resultado = await api.adicionarTagNoPaciente(identificadorDoPaciente, tag)

    dispatch(adicionouTagNoPacienteDoProntuario(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoAdicionarTagNoPacienteDoProntuario(erro, { identificadorDoPaciente, tag }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar tag no paciente do prontuário.')))
  }
}

const removeuTagDoPacienteDoProntuario = (identificadorDoPaciente, tag) => ({
  type: REMOVEU_TAG_DO_PACIENTE_DO_PRONTUARIO,
  identificadorDoPaciente,
  tag,
})

const erroAoRemoverTagDoPacienteDoProntuario = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  erro,
  parametros,
})

export const removerTagDoPacienteDoProntuario = (identificadorDoPaciente, tag) => async dispatch => {
  dispatch({ type: REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO, identificadorDoPaciente, tag })

  try {
    const resultado = await api.removerTagDoPaciente(identificadorDoPaciente, tag)

    dispatch(removeuTagDoPacienteDoProntuario(identificadorDoPaciente, tag))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRemoverTagDoPacienteDoProntuario(erro, { identificadorDoPaciente, tag }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover tag do paciente do prontuário.')))
  }
}