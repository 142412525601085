import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
    LISTAR_TIPOS_DE_ATENDIMENTO,
    LISTOU_TIPOS_DE_ATENDIMENTO,
    ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO,
    LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
    LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
    ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
    HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
} from '../tipos'

const listouTiposDeAtendimento = tiposDeAtendimento => ({
    type: LISTOU_TIPOS_DE_ATENDIMENTO,
    tiposDeAtendimento
})

const erroAoListarTiposDeAtendimento = erro => ({
    type: ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO,
    erro
})

export const listarTiposDeAtendimento = () => async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_ATENDIMENTO })

    try {
        const resultado = await api.listarTiposDeAtendimento()

        dispatch(listouTiposDeAtendimento(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoListarTiposDeAtendimento(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as tipos de atendimento.')))

        return false
    }
}

const listouTiposDeAtendimentoHabilitadosParaOUsuario = tiposDeAtendimento => ({
    type: LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
    tiposDeAtendimento
})

const erroAoListarTiposDeAtendimentoHabilitadosParaOUsuario = (erro, parametros) => ({
    type: ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
    erro,
    parametros
})

export const listarTiposDeAtendimentoHabilitadosParaOUsuario = identificadorDoUsuario => async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO })
    try {
        const resultado = await api.listarTiposDeAtendimentoHabilitadosParaOUsuario(identificadorDoUsuario)

        dispatch(listouTiposDeAtendimentoHabilitadosParaOUsuario(resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoListarTiposDeAtendimentoHabilitadosParaOUsuario(erro, { identificadorDoUsuario }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as tipos de atendimento do usuário.')))

        return false
    }
}

const habilitouTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, tipoDeAtendimento) => ({
    type: HABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    identificadorDoUsuario,
    tipoDeAtendimento,
})

const erroAoHabilitarTipoDeAtendimentoDoUsuario = (erro, parametros) => ({
    type: ERRO_AO_HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    erro,
    parametros
})

export const habilitarTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, identificadorDoTipoDeAtendimento, privado) => async dispatch => {
    dispatch({ type: HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO })

    try {
        const resultado = await api.habilitarTipoDeAtendimentoDoUsuario(identificadorDoUsuario, identificadorDoTipoDeAtendimento, privado)

        dispatch(habilitouTipoDeAtendimentoDoUsuario(identificadorDoUsuario, resultado.data.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoHabilitarTipoDeAtendimentoDoUsuario(erro, { identificadorDoUsuario, identificadorDoTipoDeAtendimento }))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar tipo de atendimento do usuário.')))

        return false
    }
}

const desabilitouTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, identificadorDoTipoDeAtendimento) => ({
    type: DESABILITOU_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    identificadorDoUsuario, 
    identificadorDoTipoDeAtendimento,
})

const erroAoDesabilitarTipoDeAtendimentoDoUsuario = (erro, parametros) => ({
    type: ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
    erro,
    parametros
})

export const desabilitarTipoDeAtendimentoDoUsuario = (identificadorDoUsuario, identificadorDoTipoDeAtendimento) => async dispatch => {
    dispatch({ type: DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO })

    try {
        const resultado = await api.desabilitarTipoDeAtendimentoDoUsuario(identificadorDoUsuario, identificadorDoTipoDeAtendimento)

        dispatch(desabilitouTipoDeAtendimentoDoUsuario(identificadorDoUsuario, identificadorDoTipoDeAtendimento))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return true
    } catch (erro) {
        dispatch(erroAoDesabilitarTipoDeAtendimentoDoUsuario(erro, { identificadorDoUsuario, identificadorDoTipoDeAtendimento}))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover tipo de atendimento do usuário.')))

        return false
    }
}