import api from '../../api-major'

const listarBloqueiosNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, filtros = {}, pagina) => {
  const paginacao = pagina ? `pagina=${pagina}` : '?pagina=1'
  const parametroTamanhoDaPagina = filtros && filtros.tamanhoDaPagina ? `&tamanhoDaPagina=${filtros.tamanhoDaPagina}` : `&tamanhoDaPagina=50`
  const parametroOrdem = filtros && filtros.ordem ? `&ordem=${filtros.ordem}` : '&ordem=data'
  const parametroMotivo = filtros && filtros.motivo ? `&motivo=${filtros.motivo}` : ''
  const diasDaSemana = `&diasDaSemana=SEG,TER,QUA,QUI,SEX,SAB,DOM`
  const parametroInicio = filtros && filtros.inicio ? `&inicio=${filtros.inicio}` : ''
  const parametroFim = filtros && filtros.fim ? `&fim=${filtros.fim}` : ''

  return api.get(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios?${paginacao}${parametroOrdem}${parametroTamanhoDaPagina}${parametroMotivo}${diasDaSemana}${parametroInicio}${parametroFim}`)
}

const adicionarBloqueioNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, bloqueio) => {
  return api.post(`profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios`, bloqueio)
}

const alterarBloqueioNaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, bloqueio) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios/${bloqueio.identificador}`, bloqueio)
}

const excluirBloqueioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDoBloqueio) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios/${identificadorDoBloqueio}`)
}

const excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDoBloqueio) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios/${identificadorDoBloqueio}/serie`)
}

const alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, serie) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/bloqueios/${serie.identificador}/serie`, serie)
}

export default {
  adicionarBloqueioNaAgendaDoProfissionalDeSaude,
  alterarBloqueioNaAgendaDoProfissionalDeSaude,
  alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaude,
  listarBloqueiosNaAgendaDoProfissionalDeSaude,
  excluirBloqueioDaAgendaDoProfissionalDeSaude,  
  excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaude
}   