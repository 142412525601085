import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_VACINA_DO_PACIENTE,
  ADICIONOU_VACINA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE,
  ALTERAR_VACINA_DO_PACIENTE,
  ALTEROU_VACINA_DO_PACIENTE,
  ERRO_AO_ALTERAR_VACINA_DO_PACIENTE,
  LISTAR_VACINAS_DO_PACIENTE,
  LISTOU_VACINAS_DO_PACIENTE,
  ERRO_AO_LISTAR_VACINAS_DO_PACIENTE,
  REMOVER_VACINA_DO_PACIENTE,
  REMOVEU_VACINA_DO_PACIENTE,
  ERRO_AO_REMOVER_VACINA_DO_PACIENTE,
} from '../../tipos'

const listouVacinasDoPaciente = (identificadorDoPaciente, vacinas) => ({
  type: LISTOU_VACINAS_DO_PACIENTE,
  identificadorDoPaciente,
  vacinas
})

const erroAoListarVacinasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_VACINAS_DO_PACIENTE,
  erro,
  parametros
})

export const listarVacinasDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_VACINAS_DO_PACIENTE })

  try {
    const resultado = await api.listarVacinasDoPaciente(identificadorDoPaciente)

    dispatch(listouVacinasDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarVacinasDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as vacinas do paciente.')))
  }
}

const adicionouVacinaDoPaciente = vacina => ({
  type: ADICIONOU_VACINA_DO_PACIENTE,
  vacina
})

const erroAoAdicionarVacinaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarVacinaDoPaciente = (identificadorDoPaciente, vacina) => async dispatch => {
  dispatch({ type: ADICIONAR_VACINA_DO_PACIENTE })

  try {
    const resultado = await api.adicionarVacinaDoPaciente(identificadorDoPaciente, vacina)

    dispatch(adicionouVacinaDoPaciente(resultado.data.dados))
    dispatch(listarVacinasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarVacinaDoPaciente(erro, { vacina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar vacina do paciente.')))

    return false
  }
}

const alterouVacinaDoPaciente = vacina => ({
  type: ALTEROU_VACINA_DO_PACIENTE,
  vacina
})

const erroAoAlterarVacinaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_VACINA_DO_PACIENTE,
  erro,
  parametros
})

export const alterarVacinaDoPaciente = (identificadorDoPaciente, vacina) => async dispatch => {
  dispatch({ type: ALTERAR_VACINA_DO_PACIENTE })

  try {
    const resultado = await api.alterarVacinaDoPaciente(identificadorDoPaciente, vacina)

    dispatch(alterouVacinaDoPaciente(resultado.data.dados))
    dispatch(listarVacinasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarVacinaDoPaciente(erro, { vacina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar vacina do paciente.')))

    return false
  }
}

const removeuVacinaDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_VACINA_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverVacinaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_VACINA_DO_PACIENTE,
  erro,
  parametros
})

export const removerVacinaDoPaciente = (identificadorDoPaciente, identificadorDaVacina) => async dispatch => {
  dispatch({ type: REMOVER_VACINA_DO_PACIENTE })

  try {
    const resultado = await api.removerVacinaDoPaciente(identificadorDoPaciente, identificadorDaVacina)

    dispatch(removeuVacinaDoPaciente(identificadorDoPaciente))
    dispatch(listarVacinasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverVacinaDoPaciente(erro, { identificadorDoPaciente, identificadorDaVacina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover vacina do paciente.')))

    return false
  }
}