import React, {useEffect} from 'react'
import Spinner from '../../spinner'
import {Formulario as Form, Select} from '../../formik/formulario'
import {confirmAlert} from 'react-confirm-alert'

import {
  DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS
} from '../../../acoes/tipos'

export default function TiposDeAtendimento(props) {
  const {
    listarTiposDeAtendimento,
    habilitar,
    desabilitar,
    tiposDeAtendimento
  } = props

  const podeHabilitar = true
  const podeDesabilitar = true

  useEffect(() => { listarTiposDeAtendimento() }, [listarTiposDeAtendimento])

  const desabilitados = tiposDeAtendimento.filter(x => !x.habilitado)
  const habilitados = tiposDeAtendimento.filter(x => x.habilitado)

  const adicionar = async (values, { resetForm }) => {
    const adicionou = await habilitar(values)

    if (adicionou) {
      resetForm({})
      listarTiposDeAtendimento()
    }
  }

  const removerSelecionado = async item => {
    const removeu = await desabilitar(item.identificador)

    if (removeu) {
      listarTiposDeAtendimento()
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja desabilitar o tipo de atendimento: ${item.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[
      LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
      HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
      DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS
    ]}>
      <Form
        valoresIniciais={{
          tipos: []
        }}
        acao={adicionar}
        reinicializar={true}
      >
        {({ values }) => (
          <div className='mt-2 mb-4'>
            <fieldset>
              <h2 className='form-title'>{podeHabilitar ? 'Habilitar Tipos de Atendimento' : 'Tipos de Atendimento'}</h2>
              <r-grid columns-md={6} columns-lg={12} class='align-end'>
                {podeHabilitar &&
                 <>
                   <r-cell span={4} span-md={5} span-lg={6}>
                     <Select
                       nome='tipos'
                       campoCodigo='identificador'
                       campoDescricao='nome'
                       itens={desabilitados}
                       tabIndex={1}
                       titulo='Tipos de Atendimento *'
                       isMulti
                     />
                   </r-cell>
                   <r-cell span={4} span-md={1} span-lg={1}>
                     <button
                       type='submit'
                       className={values.tipos.length === 0 ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                       disabled={values.tipos.length === 0}
                       tabIndex={2}
                     >
                       Adicionar
                     </button>
                   </r-cell>
                 </>
                }
                <r-cell span={4} span-md={6} span-lg={'1-7'}>
                  {habilitados.map((tipo, index) => (
                     <div className='form-choice' key={index}>
                       <div className='form-choice__item --full'>{tipo.nome}</div>
                       {podeDesabilitar && <button className='form-choice__bt-close' type='button' aria-label='Fechar' onClick={() => confirmarERemover(tipo)}><i className='icon icon-close'></i></button>}
                     </div>
                   ))}
                </r-cell>
              </r-grid>
            </fieldset>
          </div>
        )}
      </Form>
    </Spinner>
  )
}