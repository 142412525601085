import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  LISTAR_SETORES,
  LISTOU_SETORES,
  ERRO_AO_LISTAR_SETORES,
  RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  RECUPEROU_SETOR_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  ADICIONAR_SETOR,
  ADICIONOU_SETOR,
  ERRO_AO_ADICIONAR_SETOR,
  ALTERAR_SETOR,
  ALTEROU_SETOR,
  ERRO_AO_ALTERAR_SETOR,
  REMOVER_SETOR,
  REMOVEU_SETOR,
  ERRO_AO_REMOVER_SETOR
} from './tipos'

const listouSetores = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_SETORES,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarSetores = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_SETORES,
  erro,
  parametros
})

export const listarSetores = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_SETORES })

  try {
    const resultado = await api.listarSetores(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouSetores(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSetores(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os setores.')))
  }
}

const adicionouSetor = (setor) => ({
  type: ADICIONOU_SETOR,
  setor
})

const erroAoAdicionarSetor = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_SETOR,
  erro,
  parametros
})

export const adicionarSetor = setor => async dispatch => {
  dispatch({ type: ADICIONAR_SETOR })

  try {
    const resultado = await api.adicionarSetor(setor)

    dispatch(adicionouSetor(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarSetor(erro, { setor }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o setor.')))

    return false
  }
}

const recuperouSetorPeloIdentificador = setor => ({
  type: RECUPEROU_SETOR_PELO_IDENTIFICADOR,
  setor
})

const erroAoRecuperarSetorPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarSetorPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_SETOR_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarSetorPeloIdentificador(identificador)

    dispatch(recuperouSetorPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarSetorPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o setor pelo identificador.')))
  }
}

const alterouSetor = setor => ({
  type: ALTEROU_SETOR,
  setor
})

const erroAoAlterarSetor = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_SETOR,
  erro,
  parametros
})

export const alterarSetor = setor => async dispatch => {
  dispatch({ type: ALTERAR_SETOR })

  try {
    const resultado = await api.alterarSetor(setor)

    dispatch(alterouSetor(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarSetor(erro, { setor }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o setor.')))

    return false
  }
}

const removeuSetor = setor => ({
  type: REMOVEU_SETOR,
  setor
})

const erroAoRemoverSetor = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_SETOR,
  erro,
  parametros
})

export const removerSetor = identificador => async dispatch => {
  dispatch({ type: REMOVER_SETOR })

  try {
    const resultado = await api.removerSetor(identificador)

    dispatch(removeuSetor(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverSetor(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o setor.')))

    return false
  }
}