import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { useProntuario } from '../../contexto'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../tabela'
import { formatarDataComPrecisaoParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import { LISTAR_QUEDAS_DO_PACIENTE, REMOVER_QUEDA_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Quedas({ identificadorDoPaciente, quedasDoPaciente }) {
  const {
    listarQuedasDoPaciente,
    locaisDeQuedas,
    listarLocaisDeQuedas,
    adicionarQueda,
    alterarQueda,
    removerQueda
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)

  const referenciaDoInfo = useRef(null)
  const podeListarLocaisDeQuedas = usePossuiAsPermissoes([permissoes.LISTAR_LOCAIS_DE_QUEDAS])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_QUEDA_DO_PACIENTE])

  useCliqueForaDoElemento(referenciaDoInfo, () => setExibirTooltipInfo(false))

  useEffect(() => {
    if (quedasDoPaciente === undefined) {
      listarQuedasDoPaciente(identificadorDoPaciente)
    }
  }, [listarQuedasDoPaciente, identificadorDoPaciente, quedasDoPaciente])

  useEffect(() => listarLocaisDeQuedas(), [listarLocaisDeQuedas])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarQueda(identificadorDoPaciente, item) : await adicionarQueda(identificadorDoPaciente, item)
  }

  if (quedasDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de quedas'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          locais={locaisDeQuedas}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de queda'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerQueda(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_QUEDAS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_QUEDA_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_QUEDA_DO_PACIENTE]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                      <div className='tooltip__drop custom-scrollbar' style={{ height: !item.oQueEstavaFazendoNoMomentoDaQueda ? 'auto' : '200px' }}>
                        <div className='tooltip__drop__body'>
                          <div className='tooltip__drop__title'>
                            Histórico
                            <button className='tooltip__drop__bt-close' type='button'
                              onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i>
                            </button>
                          </div>
                          <div className='tooltip__drop__description'>
                            <span>Usuário: {item.usuario.nome}</span> <br />
                            {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                            <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                            {item.oQueEstavaFazendoNoMomentoDaQueda &&
                              <>
                                <strong>O que estava fazendo no momento da queda:</strong><br />
                                <pre>{item.oQueEstavaFazendoNoMomentoDaQueda}</pre>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={quedasDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarLocaisDeQuedas}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de quedas.'
              classNameAdicionar='col-xs-6 col-lg-1'
              expandida={false}
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                nome='Data da Queda'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataComPrecisaoParaFormatoLocal(item.data, item.precisaoDaData)}
              />
              <Coluna
                className='col-lg-2'
                nome='Local da Queda'
                campoDeDados='local'
              />
              <Coluna
                className='col-lg-2'
                nome='Ligou para a Clínica'
                renderizar={item => item.ligouParaAClinica ? 'Sim' : 'Não'}
              />
              <Coluna
                className='col-lg-3'
                nome='Precisou ir ao pa / hospital'
                renderizar={item => item.precisouIrAoProntoAtendimentoOuHospital ? 'Sim' : 'Não'}
              />
              <Coluna
                className='col-lg-2'
                nome='Procurou Algum Médico'
                renderizar={item => item.procurouAlgumMedico ? 'Sim' : 'Não'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-date'
                    nome='Data da Queda'
                    renderizar={item => formatarDataComPrecisaoParaFormatoLocal(item.data, item.precisaoDaData)}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='Local da Queda'
                    campoDeDados='local'
                  />
                  <Coluna
                    icone='icon-phone'
                    nome='Ligou para a Clínica'
                    renderizar={item => item.ligouParaAClinica ? 'Sim' : 'Não'}
                  />
                  <Coluna
                    icone='icon-estabelecimento-de-saude'
                    nome='Precisou ir ao pa / hospital'
                    renderizar={item => item.precisouIrAoProntoAtendimentoOuHospital ? 'Sim' : 'Não'}
                  />
                  <Coluna
                    icone='icon-doctor'
                    nome='Procurou Algum Médico'
                    renderizar={item => item.procurouAlgumMedico ? 'Sim' : 'Não'}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirQueda = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_QUEDA_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a queda?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirQueda}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}