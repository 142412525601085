import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import acoes from './acoes'
import configuracoes from './configuracoes/'
import App from './componentes/'
import 'typeface-roboto'

const iniciar = async () => {
  if (window.location.hostname.indexOf('www') === 0) {
    window.location = window.location.href.replace('www.', '');
  }

  configuracoes.configurarMoment()
  configuracoes.configurarYup()
  configuracoes.configurarModal()
  configuracoes.configurarDatePicker()

  const recuperarSenha = window.location.pathname.includes('recuperar-senha')
  const nps = window.location.pathname.includes('nps') && !window.location.pathname.includes('configuracoes')
  const pedidoDeContato = window.location.pathname.includes('pedido-de-contato')
  const validarAssinaturaDigital = window.location.pathname.includes('validar-assinatura-digital')

  const firestore = configuracoes.configurarFirestore()
  const store = configuracoes.configurarStore()

  !recuperarSenha && !nps && !pedidoDeContato && !validarAssinaturaDigital && await store.dispatch(acoes.reautenticarUsuario())

  ReactDOM.render(
    <App store={store} firestore={firestore} />,
    document.getElementById('root')
  )
}

iniciar()
serviceWorker.unregister()