import React, { } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Lista from "./lista";
import Formulario from "./formulario";

export default function Celulas(props) {
    const {
        adicionarCelula,
        celula,
        filtros,
        history,
        listarCelulasDoCadastro,
        match,
        ordenacao,
        paginaDeDados,
        recuperarCelulaPeloIdentificador,
        removerCelula,
        alterarCelula
    } = props

    const salvar = async formulario => {
        const dados = {
            ...formulario,
        }

        const acao = dados.identificador ? alterarCelula : adicionarCelula
        const executou = await acao(dados)

        if (executou) {
            history.push(`/cadastros/celulas`)
        }
    }

    return (
        <Switch>
            <RotaComAutenticacao
                exact
                path={`${match.path}/lista`}
                render={
                    props => (
                        <Permissao permissoes={[permissoes.LISTAR_CELULAS]}>
                            <Lista
                                {...props}
                                filtros={filtros}
                                ordenacao={ordenacao}
                                pesquisar={listarCelulasDoCadastro}
                                paginaDeDados={paginaDeDados}
                            />
                        </Permissao>
                    )
                }
            />
            <RotaComAutenticacao
                exact
                path={`${match.path}/novo`}
                render={
                    props => (
                        <Permissao permissoes={[permissoes.ADICIONAR_CELULA]}>
                            <Formulario
                                {...props}
                                salvar={salvar}
                                celula={celula}
                            />
                        </Permissao>
                    )
                }
            />
            <RotaComAutenticacao
                exact
                path={`${match.path}/:identificador`}
                render={
                    props => (
                        <Permissao permissoes={[permissoes.ALTERAR_CELULA]}>
                            <Formulario
                                {...props}
                                recuperarPeloIdentificador={recuperarCelulaPeloIdentificador}
                                salvar={salvar}
                                celula={celula}
                                remover={removerCelula}
                            />
                        </Permissao>
                    )
                }
            />
            <Redirect exact from={match.path} to={`${match.path}/lista`} />
        </Switch>
    )
}
