import React, { useMemo } from 'react'
import { Coluna, Tabela } from '../../../tabela'
import Arquivos from './arquivos'

export default function ResumoDeVideoConferencias({ videoConferencias, lotesDeArquivosDeVideoConsulta, atendimento }) {
  const arquivos = useMemo(() => lotesDeArquivosDeVideoConsulta.reduce((acumulado, atual) => {
    return acumulado.concat(atual.arquivos)
  }, []), [lotesDeArquivosDeVideoConsulta])

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      {videoConferencias.length > 0 &&
        <>
          <h2 className='form-title'>Vídeo Consulta</h2>
          <Tabela
            chave='identificador'
            className='--secondary my-2'
            dados={videoConferencias}
          >
            <Coluna
              className='col-lg-6'
              nome='Url do Paciente - Computador'
              principal={true}
              renderizar={item => item.urlDosParticipantes.replace('zoom.us/j/', 'zoom.us/wc/join/')}
            />
            <Coluna
              className='col-lg-5'
              nome='Url do Paciente - Celular'
              renderizar={item => item.urlDosParticipantes}
            />
          </Tabela>
        </>
      }
      <Arquivos arquivos={arquivos} atendimento={atendimento} />
    </r-cell>
  )
}