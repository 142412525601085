import React from 'react'
import Spinner from '../../spinner'
import ReactTooltip from 'react-tooltip'
import { Formulario as FormularioDoFormik, Input, Select } from '../../formik/formulario'
import { formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'
import * as Yup from 'yup'
import moment from 'moment'
import FotoDoPaciente from '../foto-do-paciente'
import { INFORMAR_SAIDA_DO_PACIENTE, RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR } from '../../../acoes/tipos'
import ContatosDoAgendamento from '../agendamento/contatos-do-agendamento'

export default function InformarSaida({
  fechar,
  agendamento,
  informarSaidaDoPaciente,
  motivosParaNaoTerSidoAtendido,
  fotos,
  pesquisar,
  filtros,
  pagina,
  ordenacao
}) {

  function formatarDiaDaSemana(dia) {
    return dia.charAt(0).toUpperCase() + dia.slice(1)
  }

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia).format('dddd'))
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  const informarSaida = async formulario => {
    const dados = {
      identificadorDoProfissional: agendamento.profissional.identificador,
      identificador: agendamento.identificador,
      motivo: formulario.motivoParaNaoTerSidoAtendindo,
      observacoes: formulario.observacoes
    }

    const informouSaida = await informarSaidaDoPaciente(dados)

    if (informouSaida) {
      fechar()
      pesquisar(filtros, pagina, ordenacao)
    }
  }

  return (
    <Spinner operacoes={[INFORMAR_SAIDA_DO_PACIENTE, RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          observacoes: '',
          motivoParaNaoTerSidoAtendindo: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          motivoParaNaoTerSidoAtendindo: Yup.string().required('Obrigatório.'),
          observacoes: Yup.string()
            .when('motivoParaNaoTerSidoAtendindo', {
              is: val => val === 'outro' || val === 'desconhecido',
              then: Yup.string().required('Obrigatório.')
            }),
        })}
        acao={informarSaida}
      >

        {({ values }) => (

          <fieldset>
            <h2 className='form-title'>Informar Saída do Paciente</h2>
            <div className='user-paciente-agenda-modal'>
              <FotoDoPaciente
                paciente={agendamento.paciente}
                fotos={fotos}
                identificadorDoAgendamento={agendamento.identificador}
              />
              <div>
                <p className='user-paciente-agenda-modal__name'>{agendamento.paciente.nome}</p>
                <div className='user-paciente-agenda-modal__info'>
                  <p><strong>Idade:</strong>{' '}{agendamento.paciente.idade}</p>
                  <p><strong>Sexo:</strong>{' '}{agendamento.paciente.sexo}</p>
                  {agendamento.convenio && <p><strong>Convênio:</strong>{' '}{agendamento.convenio.operadora}{' - '}{agendamento.convenio.numeroDaCarteira}</p>}
                  {agendamento.paciente.associacao && <p><strong>Associação:</strong>{' '}{agendamento.paciente.associacao}</p>}
                  {agendamento.paciente.programas.length > 0 && <p><strong>Programa(s):</strong>{' '}{agendamento.paciente.programas.join(', ')}</p>}
                </div>
              </div>
            </div>
            <hr className='separator mt-1 mb-1'></hr>
            <div className='unity-infos'>
              <div className='unity-infos__item'>
                <ContatosDoAgendamento agendamento={agendamento} />
              </div>
              <div className='unity-infos-item'>
                <p>
                  <strong><i className='icon icon-calendar-empty'></i>{diaDaSemana(agendamento.inicio)}</strong>
                </p>
                <p>
                  <strong><i className='icon icon-clock'></i>{formatarHoraParaFormatoLocal(agendamento.inicio)} às {formatarHoraParaFormatoLocal(agendamento.fim)}</strong>
                </p>
              </div>
              <div className='unity-infos__item'>
                <p><strong>Unidade:</strong>{' '}{agendamento.unidade.nome}</p>
                <p><strong>Tipo:</strong>{' '}{agendamento.tipo.nome}</p>
                <p><strong>Profissional:</strong>{' '}{agendamento.profissional.nome}</p>
                <span>
                  <strong>Status:</strong>{' '}{agendamento.status[0].nome}
                  <button
                    data-tip
                    data-for={`info_${agendamento.identificador}`}
                    key={`info_${agendamento.identificador}`}
                    type='button'
                    className='botao-historico'
                    onMouseOver={() => ReactTooltip.rebuild()}
                  >
                    <i className='icon icon-history'></i>
                  </button>
                </span>
              </div>
            </div>
            <r-grid columns-md={6} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Select
                  itens={motivosParaNaoTerSidoAtendido}
                  nome='motivoParaNaoTerSidoAtendindo'
                  titulo='Motivo para não ter sido atendido *'
                  campoCodigo='codigo'
                  campoDescricao='nome'
                  tabIndex={1}
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={2}
                  titulo={(values.motivoParaNaoTerSidoAtendindo === 'outro' || values.motivoParaNaoTerSidoAtendindo === 'desconhecido') ? 'Observações *' : 'Observações'}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' onClick={() => fechar()} className='button --danger-light'>Fechar</button>
              <button type='submit' className='button --danger'>Informar Saída</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}