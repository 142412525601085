import {
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  FEZ_DOWNLOAD_DE_PACIENTES,
  LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES,
} from '../../acoes/tipos'

const estadoInicial = {
  celulas: [],
  enfermeiros: [],
  filtros: {},
  medicos: [],
  operadoras: [],
  programas: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        celulas: action.celulas,
      }
    }

    case LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        operadoras: action.operadoras,
      }
    }

    case LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        programas: action.programas,
      }
    }

    case LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        medicos: action.medicos.map(x => ({ ...x, label: x.tratamento ? x.tratamento + ' ' + x.nome : x.nome }))
      }
    }

    case LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        enfermeiros: action.enfermeiros.map(x => ({ ...x, label: x.tratamento ? x.tratamento + ' ' + x.nome : x.nome }))
      }
    }

    case FEZ_DOWNLOAD_DE_PACIENTES: {
      return {
        ...state,
        filtros: action.filtros
      }
    }

    case LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES: {
      return {
        ...state,
        filtros: {}
      }
    }

    default: {
      return state
    }
  }
}