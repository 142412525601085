import {
  LISTOU_RESULTADOS_DAS_TRIAGENS
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_RESULTADOS_DAS_TRIAGENS: {
      return action.resultadosTriagens
    }

    default: {
      return state
    }
  }
}
