import React, {useEffect} from 'react'
import Spinner from '../spinner'
import {DatePicker, Formulario as FormularioDoFormik, Input, Select, Telefone} from '../formik/formulario'
import {Telephone} from "../../design/icones";
import * as Yup from 'yup'
import {
  ADICIONAR_PEDIDO_DE_CONTATO,
  LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  LISTAR_SEXOS
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    cidades,
    enviarPedido,
    listarCidades,
    listarPlanosDeSaude,
    listarSexos,
    planosDeSaude,
    sexos
  } = props

  useEffect(() => { listarPlanosDeSaude() }, [listarPlanosDeSaude])
  useEffect(() => { listarCidades() }, [listarCidades])
  useEffect(() => { listarSexos() }, [listarSexos])

  return (
    <Spinner operacoes={[ADICIONAR_PEDIDO_DE_CONTATO, LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO, LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO, LISTAR_SEXOS]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          nomeDoPaciente: '',
          telefone: '',
          sexo: '',
          dataDeNascimento: '',
          planoDeSaude: '',
          cidade: '',
          nomeDoSolicitante: ''
        }}
        acao={enviarPedido}
        esquemaDeValidacoes={Yup.object().shape({
          nomeDoPaciente: Yup.string().required('Obrigatório').min(3, 'O nome do paciente deve possuir no mínimo 3 caracteres.'),
          telefone: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999').required('Obrigatório.'),
          sexo: Yup.string().oneOf(['Feminino', 'Masculino']).required('Obrigatório'),
          dataDeNascimento: Yup.string().nullable().required('Obrigatório'),
          planoDeSaude: Yup.string().required('Obrigatório'),
          cidade: Yup.string().required('Obrigatório'),
          nomeDoSolicitante: Yup.string().required('Obrigatório').min(3, 'O nome do solicitante deve possuir no mínimo 3 caracteres.')
        })}
      >
        <div className='page-pedido-de-contato'>          
          <div className='containers'>
            <div className='informacao'>
              <div><h1>A mais60 é referência nacional em cuidar das pessoas idosas.</h1></div>
              <div><h2>Atuamos na prevenção de doenças, reabilitação e tratamento, proporcionando mais cuidado, melhora na qualidade de vida e independência da pessoa idosa.</h2></div>
              <div><h3>Para marcação de consulta entre em contato com a nossa equipe.</h3></div>
              <div className='telefone'>
                <Telephone />
                <strong><a href='https://mais60saude.com.br/nossas-unidades/' target='_blank' rel='noopener noreferrer'>Ligue para nós</a></strong>
              </div>
              <hr/>
              <div className='telefone'>
                <Telephone />
                <div className='urgencia'>
                  <strong>Atendimento de urgência 24h</strong>
                  <strong>(31) 2513-0552</strong>
                </div>
              </div>
            </div>
            <div className='formulario'>
              <fieldset>
                <div className='text-justify mb-1'><h2>Faça o seu cadastro e descubra como nosso pacote de cuidados, com médico e enfermeiro de referência, irá ajudar na manutenção da sua saúde.</h2></div>
                <r-grid columns-md={6} columns-lg={12}>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Input
                      nome='nomeDoPaciente'
                      tabIndex={1}
                      titulo='Nome do paciente *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Telefone
                      nome='telefone'
                      tabIndex={2}
                      titulo='Telefone de contato *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Select
                      nome='sexo'
                      tabIndex={3}
                      titulo='Sexo *'
                      itens={sexos}
                      campoCodigo='nome'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <DatePicker
                      nome='dataDeNascimento'
                      tabIndex={4}
                      titulo='Data de Nascimento *'
                      maxDate={new Date()}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Select
                      nome='planoDeSaude'
                      tabIndex={5}
                      titulo='Plano de Saúde *'
                      itens={planosDeSaude}
                      campoCodigo='codigo'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Select
                      nome='cidade'
                      tabIndex={6}
                      titulo='Cidade que deseja o atendimento *'
                      itens={cidades}
                      campoCodigo='codigo'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={12}>
                    <Input
                      type='text'
                      nome='nomeDoSolicitante'
                      tabIndex={7}
                      titulo='Nome do solicitante *'
                    />
                  </r-cell>
                </r-grid>
                <div className='list-btn'>                  
                  <button type='submit' className='button --primary'>Enviar</button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </FormularioDoFormik>
    </Spinner>
  )
}