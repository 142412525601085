import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  REMOVER_ESTABELECIMENTO_DE_SAUDE
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    estabelecimento,
    salvar,
    recuperarPeloIdentificador,
    remover
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_ESTABELECIMENTO_DE_SAUDE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_ESTABELECIMENTO_DE_SAUDE])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_ESTABELECIMENTO_DE_SAUDE])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/estabelecimentos-de-saude`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o estabelecimento de saúde: ${estabelecimento.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(estabelecimento)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[
          RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
          ALTERAR_ESTABELECIMENTO_DE_SAUDE,
          ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
          REMOVER_ESTABELECIMENTO_DE_SAUDE
        ]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: estabelecimento.identificador || '',
              status: estabelecimento.status === 'Não aprovado' ? false : true,
              nome: estabelecimento.nome || '',
              cnes: estabelecimento.cnes || '',
              redePropria: estabelecimento.redePropria
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              cnes: Yup.number().typeError('Deve ser numérico').positive('Deve ser positivo').integer('Deve ser inteiro')
            })}
          >
            <fieldset>
              <h2 className='form-title'>{estabelecimento.identificador ? 'Alterar Estabelecimento De Saúde' : 'Adicionar Estabelecimento De Saúde'}</h2>
              <r-grid columns={6} columns-md={6} columns-lg={12}>
                <r-cell span={6} span-md={8} span-lg={8}>
                  <Input
                    nome='nome'
                    tabIndex={1}
                    titulo='Nome *'
                  />
                </r-cell>
                <r-cell span={6} span-md={4} span-lg={4}>
                  <Input
                    nome='cnes'
                    tabIndex={2}
                    titulo='CNES'
                  />
                </r-cell>
                <r-cell span={3} span-md={12} span-lg={12}>
                  <div className='form-toggle-list'>
                    <Toglle
                      nome='redePropria'
                      tabIndex={3}
                      titulo='Rede Própria'
                    />
                    <Toglle
                      nome='status'
                      tabIndex={4}
                      titulo='Aprovado'
                    />
                  </div>
                </r-cell>
              </r-grid>
            </fieldset>
            {remover ?
              <div className='list-btn-unidades'>
                {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
              </div>
              :
              <div className='list-btn'>
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
              </div>
            }
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}