import React, { useEffect, useMemo } from 'react'
import { Redirect, Switch, Link } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'
import Formulario from './formulario'
import FormularioDeUnidade from './unidades/formulario'
import Lista from './unidades/lista'
import Salas from './unidades/salas/lista'
import FormularioDeSala from './unidades/salas/formulario'

export default function Empresa(props) {
  const {
    adicionarFotoDaEmpresa,
    adicionarSalaNaUnidade,
    adicionarUnidadeDaEmpresa,
    alterarSalaDaUnidade,
    ativarUnidadeDaEmpresa,
    bytesDaFoto,
    desativarUnidadeDaEmpresa,
    empresa,
    fusosHorarios,
    history,
    listarFusosHorarios,
    listarSalasDaUnidade,
    listarTiposDeSala,
    listarTiposDeUnidade,
    listarUnidadesDaEmpresa,
    listarUnidadesFederativas,
    location,
    match,
    recuperarEmpresa,
    recuperarEnderecoDaUnidadePorCep,
    recuperarFotoDaEmpresaLogada,
    recuperarSalaDaUnidadePeloIdentificador,
    removerFotoDaEmpresa,
    removerImagemDaMemoriaPorIdentificador,
    removerSalaDaUnidade,
    removerUnidadeDaEmpresa,
    salvarEmpresa,
    salvarUnidadeDaEmpresa,
    selecionarUnidade,
    tiposDeSala,
    tiposDeUnidade,
    unidadesFederativas,
  } = props

  useEffect(() => {
    if (empresa.logotipo) {
      recuperarFotoDaEmpresaLogada(empresa.logotipo)
    }

    return () => empresa.logotipo && removerImagemDaMemoriaPorIdentificador(empresa.logotipo)
  }, [empresa.logotipo, recuperarFotoDaEmpresaLogada, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => { recuperarEmpresa() }, [recuperarEmpresa])

  const dadosDaEmpresa = useMemo(() => {
    return extrarDadosDoFormulario(empresa)
  }, [empresa])

  if (!empresa) {
    return null
  }

  const unidadeSelecionada = empresa && empresa.unidades && empresa.unidades.itemAtual
  const salas = unidadeSelecionada && unidadeSelecionada.salas && unidadeSelecionada.salas.itens

  const salvarUnidade = async unidade => {
    const acao = unidade.identificador ? salvarUnidadeDaEmpresa : adicionarUnidadeDaEmpresa
    const salvou = await acao(unidade)

    if (salvou) {
      history.push(`/configuracoes/empresa/unidades/lista`)
    }
  }

  const salvarSala = async sala => {
    const acao = sala.identificador ? alterarSalaDaUnidade : adicionarSalaNaUnidade
    const salvou = await acao(unidadeSelecionada.identificador, sala)
    if (salvou) {
      history.push(`/configuracoes/empresa/unidades/${unidadeSelecionada.identificador}/salas`)
    }
  }

  const removerUnidade = async identificador => {
    const removeu = await removerUnidadeDaEmpresa(identificador)

    if (removeu) {
      history.push(`/configuracoes/empresa/unidades/lista`)
    }
  }

  const desativarUnidade = async identificador => {
    const inativou = await desativarUnidadeDaEmpresa(identificador)

    if (inativou) {
      history.push(`/configuracoes/empresa/unidades/lista`)
    }
  }

  const ativarUnidade = async identificador => {
    const ativou = await ativarUnidadeDaEmpresa(identificador)

    if (ativou) {
      history.push(`/configuracoes/empresa/unidades/lista`)
    }
  }

  const abaAtiva = () => {
    if (location.pathname.includes('unidades')) {
      return 1
    }

    return 0
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}`,
          `${match.path}/unidades`,
          `${match.path}/unidades/lista`,
          `${match.path}/unidades/nova`,
          `${match.path}/unidades/:identificador`,
          `${match.path}/unidades/:identificador/salas`,
          `${match.path}/unidades/:identificador/salas/nova`,
          `${match.path}/unidades/:identificador/salas/:identificadorDaSala`
        ]}
        render={() => (
          <div className='page-configuracoes-empresa-dados'>
            <div className='container'>
              <div className='main-content-form'>
                <div className='header-page-form'>
                  <Breadcrumb />
                </div>
                <Abas abaInicial={abaAtiva()} ajusteRedirecionamento={true}>
                  <div className='header-page-bar mt-0'>
                    <div className='header-page-nav'>
                      <ListaDeAbas className='tab'>
                        <Aba className='tab-item' aoClicar={() => history.push(`${match.url}`)}>
                          Dados Gerais
                        </Aba>
                        <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/unidades/lista`)}>
                          Unidades
                        </Aba>
                      </ListaDeAbas>
                    </div>
                    <Permissao permissoes={[permissoes.ADICIONAR_UNIDADE_DA_EMPRESA]}>
                      <div className='header-page-tools'>
                        <Link to={`${match.url}/unidades/nova`} className='button --primary --plus w-sm-100'>Adicionar Unidade</Link>
                      </div>
                    </Permissao>
                  </div>
                  <Paineis>
                    <Painel>
                      <RotaComAutenticacao
                        path={match.url}
                        exact
                        render={
                          props =>
                            <Formulario
                              {...props}
                              dadosDaEmpresa={dadosDaEmpresa}
                              salvar={salvarEmpresa}
                              removerFotoDaEmpresa={removerFotoDaEmpresa}
                              adicionarFotoDaEmpresa={adicionarFotoDaEmpresa}
                              bytesDaFoto={bytesDaFoto}
                            />
                        }
                      />
                    </Painel>
                    <Painel>
                      <Switch>
                        <RotaComAutenticacao
                          exact
                          path={[
                            `${match.path}/unidades`,
                            `${match.path}/unidades/lista`
                          ]}
                          render={
                            props => (
                              <Permissao permissoes={[permissoes.LISTAR_UNIDADES_DA_EMPRESA]} >
                                <Lista
                                  {...props}
                                  listarUnidadesDaEmpresa={listarUnidadesDaEmpresa}
                                  unidades={empresa.unidades ? empresa.unidades.itens : []}
                                  permissoes={permissoes}
                                />
                              </Permissao>
                            )
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/unidades/nova`}
                          render={
                            props => {
                              return (
                                <Permissao permissoes={[permissoes.ADICIONAR_UNIDADE_DA_EMPRESA]}>
                                  <FormularioDeUnidade
                                    {...props}
                                    salvar={salvarUnidade}
                                    recuperarEndereco={recuperarEnderecoDaUnidadePorCep}
                                    listarUnidadesFederativas={listarUnidadesFederativas}
                                    unidadesFederativas={unidadesFederativas}
                                    listarFusosHorarios={listarFusosHorarios}
                                    fusosHorarios={fusosHorarios}
                                    listarTiposDeUnidade={listarTiposDeUnidade}
                                    tiposDeUnidade={tiposDeUnidade}
                                  />
                                </Permissao>
                              )
                            }
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/unidades/:identificador`}
                          render={
                            props => {
                              return (
                                <Permissao permissoes={[permissoes.ALTERAR_UNIDADE_DA_EMPRESA]}>
                                  <FormularioDeUnidade
                                    {...props}
                                    ativar={ativarUnidade}
                                    desativar={desativarUnidade}
                                    fusosHorarios={fusosHorarios}
                                    listarFusosHorarios={listarFusosHorarios}
                                    listarUnidadesFederativas={listarUnidadesFederativas}
                                    listarTiposDeUnidade={listarTiposDeUnidade}
                                    recuperarEndereco={recuperarEnderecoDaUnidadePorCep}
                                    remover={removerUnidade}
                                    salvar={salvarUnidade}
                                    selecionarUnidade={selecionarUnidade}
                                    tiposDeUnidade={tiposDeUnidade}
                                    unidade={unidadeSelecionada}
                                    unidadesFederativas={unidadesFederativas}
                                  />
                                </Permissao>
                              )
                            }
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/unidades/:identificador/salas`}
                          render={
                            props => {
                              return (
                                <Permissao permissoes={[permissoes.LISTAR_SALAS_DA_UNIDADE]}>
                                  <Salas
                                    {...props}
                                    unidade={unidadeSelecionada}
                                    pesquisar={listarSalasDaUnidade}
                                    salas={salas}
                                  />
                                </Permissao>
                              )
                            }
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/unidades/:identificador/salas/nova`}
                          render={
                            props => (
                              <Permissao permissoes={[permissoes.ADICIONAR_SALA_NA_UNIDADE]}>
                                <FormularioDeSala
                                  {...props}
                                  listarTiposDeSala={listarTiposDeSala}
                                  salvar={salvarSala}
                                  tiposDeSala={tiposDeSala}
                                  unidade={unidadeSelecionada}
                                />
                              </Permissao>
                            )
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/unidades/:identificador/salas/:identificadorDaSala`}
                          render={
                            props => (
                              <Permissao permissoes={[permissoes.ALTERAR_SALA_DA_UNIDADE]}>
                                <FormularioDeSala
                                  {...props}
                                  listarTiposDeSala={listarTiposDeSala}
                                  recuperarPeloIdentificador={recuperarSalaDaUnidadePeloIdentificador}
                                  remover={removerSalaDaUnidade}
                                  salvar={salvarSala}
                                  tiposDeSala={tiposDeSala}
                                  unidade={unidadeSelecionada}
                                />
                              </Permissao>
                            )
                          }
                        />
                      </Switch>
                    </Painel>
                  </Paineis>
                </Abas>
              </div>
            </div>
          </div>
        )}
      />
      <Redirect exact from={match.path} to={`${match.path}`} />
    </Switch>
  )
}

const extrarDadosDoFormulario = empresa => ({
  identificador: empresa.identificador,
  nome: empresa.nome,
  nomeReduzido: empresa.nomeReduzido,
  nomeDoResponsavel: empresa.nomeDoResponsavel,
  emailDoResponsavel: empresa.emailDoResponsavel,
  telefone: empresa.telefone,
  observacoes: empresa.observacoes,
  logotipo: empresa.logotipo
})