import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_UNIDADES_DO_USUARIO,
  LISTOU_UNIDADES_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO,
  ADICIONAR_UNIDADE_DO_USUARIO,
  ADICIONOU_UNIDADE_DO_USUARIO,
  ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO,
  REMOVER_UNIDADE_DO_USUARIO,
  REMOVEU_UNIDADE_DO_USUARIO,
  ERRO_AO_REMOVER_UNIDADE_DO_USUARIO,
  LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
} from '../tipos'

const listouUnidadesDoUsuario = unidades => ({
  type: LISTOU_UNIDADES_DO_USUARIO,
  unidades,
})

const erroAoListarUnidadesDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_UNIDADES_DO_USUARIO,
  erro,
  parametros,
})

export const listarUnidadesDoUsuario = identificador => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DO_USUARIO })

  try {
    const resultado = await api.listarUnidadesDoUsuario(identificador)

    dispatch(listouUnidadesDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return true
  } catch (erro) {
    dispatch(erroAoListarUnidadesDoUsuario(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as unidades do usuário.')))
    return false
  }
}

const adicionouUnidadeDoUsuario = (identificadorDoUsuario, unidade) => ({
  type: ADICIONOU_UNIDADE_DO_USUARIO,
  identificadorDoUsuario,
  unidade,
})

const erroAoAdicionarUnidadeDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO,
  erro,
})

export const adicionarUnidadeDoUsuario = (identificadorDoUsuario, identificadorDaUnidade) => async dispatch => {
  dispatch({ type: ADICIONAR_UNIDADE_DO_USUARIO })

  try {
    const resultado = await api.adicionarUnidadeDoUsuario(identificadorDoUsuario, identificadorDaUnidade)

    dispatch(adicionouUnidadeDoUsuario(identificadorDoUsuario, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarUnidadeDoUsuario(erro, { identificadorDoUsuario, identificadorDaUnidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar unidade do usuário.')))

    return false
  }
}

const removeuUnidadeDoUsuario = (identificadorDoUsuario, identificadorDaUnidade) => ({
  type: REMOVEU_UNIDADE_DO_USUARIO,
  identificadorDoUsuario,
  identificadorDaUnidade,
})

const erroAoRemoverUnidadeDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_UNIDADE_DO_USUARIO,
  erro,
})

export const removerUnidadeDoUsuario = (identificadorDoUsuario, identificadorDaUnidade) => async dispatch => {
  dispatch({ type: REMOVER_UNIDADE_DO_USUARIO })

  try {
    const resultado = await api.removerUnidadeDoUsuario(identificadorDoUsuario, identificadorDaUnidade)

    dispatch(removeuUnidadeDoUsuario(identificadorDoUsuario, identificadorDaUnidade))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverUnidadeDoUsuario(erro, { identificadorDoUsuario, identificadorDaUnidade }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover unidade do usuário.')))

    return false
  }
}

const listouUnidadesDaEmpresaDoUsuario = unidades => ({
  type: LISTOU_UNIDADES_DA_EMPRESA_DO_USUARIO,
  unidades,
})

const erroAoListarUnidadesDaEmpresaDoUsuario = erro => ({
  type: ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  erro,
})

export const listarUnidadesDaEmpresaDoUsuario = () => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO })

  try {
    const resultado = await api.listarUnidadesDaEmpresaDoUsuario()

    dispatch(listouUnidadesDaEmpresaDoUsuario(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarUnidadesDaEmpresaDoUsuario(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as unidades da empresa do usuário.')))

    return false
  }
}