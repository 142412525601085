import React, { useEffect, useMemo } from 'react'
import { SelectReact } from '../../formik/select'
import { extrairNomeResumido } from '../../../bibliotecas/texto'
import Spinner from "../../spinner";
import { useTarefas } from '../contexto'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'
import { LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS, LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS } from '../../../acoes/tipos'

const groupStyles = {
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  color: 'black',
  backgroundColor: '#EBECF0',
  display: 'inline-block',
  minWidth: 1,
  lineHeight: '1',
  padding: '0.5em 0.5em',
  borderRadius: '1em',
}

export default function Responsavel({ alterar, valor, filtroPaciente }) {
  const { usuario } = useUsuarioLogado()

  const {
    listarProfissionaisResponsaveisDoTarefas,
    profissionaisResponsaveis,
    listarSetoresResponsaveisDoTarefas,
    setoresResponsaveis,
    pacientesDosFiltros,
    abaAtual
  } = useTarefas()

  useEffect(() => {
    if (!profissionaisResponsaveis) {
      listarProfissionaisResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarProfissionaisResponsaveisDoTarefas, profissionaisResponsaveis])

  useEffect(() => {
    if (!setoresResponsaveis) {
      listarSetoresResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarSetoresResponsaveisDoTarefas, setoresResponsaveis])

  const renderizarItem = ({ value, label, profissao }) => {
    return (
      <div key={value}>
        <div>
          <strong>{extrairNomeResumido(label)}</strong>
        </div>
        <div className={`segunda-linha-auto-complete-pacientes ${profissao ? 'possui-medico' : ''}`}>
          {profissao &&
           <div className='medico-do-paciente'>
             <span>{profissao.nome}</span>
           </div>
          }
        </div>
      </div>
    )
  }

  const opcoesDeProfissionais = useMemo(() => {
    return profissionaisResponsaveis ?
      profissionaisResponsaveis.filter(responsavel => responsavel.ativo).map(x => ({
        value: x.identificadorPublico,
        label: x.nome,
        profissao: x.profissao
      })) : []
  }, [profissionaisResponsaveis])

  const opcoesDeSetores = useMemo(() => {
    return setoresResponsaveis ?
      setoresResponsaveis.map(x => ({
        value: x.identificador,
        label: x.nome
      })) : []
  }, [setoresResponsaveis])

  const opcoesDeResponsaveis = () => {
    return [
      { grupo: 'Setores', options: opcoesDeSetores },
      { grupo: 'Profissionais', options: opcoesDeProfissionais }
    ]
  }

  const formatarGrupos = opcoesDeResponsaveis => (
    <div style={groupStyles}>
      <span>{opcoesDeResponsaveis.grupo}</span>
    </div>
  )

  const acoesDosBotoes = []

  if (opcoesDeProfissionais.some(o => o.value === usuario.token.identificador)) {
    const definirEu = () => alterar(opcoesDeProfissionais.filter(o => o.value === usuario.token.identificador)[0].value)
    acoesDosBotoes.splice(1, 0, { titulo: 'Eu', tituloSecundario: 'Usuário logado', acao: definirEu })
  }

  if (filtroPaciente && pacientesDosFiltros.length > 0) {
    const pacienteSelecionado = pacientesDosFiltros.find(x => x.identificador === filtroPaciente)
    const medicoDeReferencia = pacienteSelecionado?.medicoDeReferencia?.identificador
    const enfermeiroDeReferencia = pacienteSelecionado?.enfermeiroDeReferencia?.identificador

    if (medicoDeReferencia && opcoesDeProfissionais.some(o => o.value === medicoDeReferencia)) {
      const definirMedicoDeReferencia = () => alterar(opcoesDeProfissionais.filter(o => o.value === medicoDeReferencia)[0].value)
      acoesDosBotoes.splice(0, 0, { titulo: 'MR', tituloSecundario: 'Médico(a) de referência', acao: definirMedicoDeReferencia })
    }

    if (enfermeiroDeReferencia && opcoesDeProfissionais.some(o => o.value === enfermeiroDeReferencia)) {
      const definirEnfermeiroDeReferencia = () => alterar(opcoesDeProfissionais.filter(o => o.value === enfermeiroDeReferencia)[0].value)
      acoesDosBotoes.unshift({ titulo: 'ER', tituloSecundario: 'Enfermeiro(a) de referência', acao: definirEnfermeiroDeReferencia })
    }
  }

  return (
    <div className='form-group'>
      <div className='componente-select-acao'>
        <label className='form-label'>Responsável</label>
        {acoesDosBotoes.length > 0 &&
          acoesDosBotoes.map((x, index) => <button key={index} type='button' className={abaAtual === 'minhas' ? 'button button-light-select-acao is-disabled' : 'button button-light-select-acao'} title={x.tituloSecundario} onClick={x.acao}>{x.titulo}</button>)
        }
      </div>
      <Spinner operacoes={[LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS, LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS]}>
        {({ processando }) =>
          <SelectReact
            nome='responsavel'
            isLoading={processando}
            onChange={alterar}
            options={opcoesDeResponsaveis()}
            formatOptionLabel={renderizarItem}
            formatGroupLabel={formatarGrupos}
            valor={valor}
            isDisabled={abaAtual === 'minhas'}
          />
        }
      </Spinner>
    </div>
  )
}