import {LISTOU_PROFISSOES_PARA_O_NPS} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PROFISSOES_PARA_O_NPS: {
      return action.profissoes
    }

    default: {
      return state
    }
  }
}