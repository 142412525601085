import React, { memo, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { InputSugestao } from '../../../formik/formulario'
import { SelectTemplate } from '../../../formik/input-mension/index'
import DicaDePesquisa from '../../../dica-de-pesquisa'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import { ehVerdadeiro } from '../../../../bibliotecas/texto'
import useAtalhos, { scrollEvolucao } from '../../atendimento/useAtalhos'

function Anamnese({ metadados, leitura, valor, setFieldValue, tokensDeSugestao = {}, abrirAdicionarTemplate, statusDaSecao }) {
  const obrigatorio = ehVerdadeiro(metadados?.obrigatorio)
  const rotulo = metadados?.rotulo ? metadados.rotulo : 'Anamnese'

  const [pularConfirmacao, setPularConfirmacao] = useState(false)
   
  useAtalhos(document.getElementById('anamnese'), statusDaSecao)

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>{rotulo}</strong>
            <br />
            <div className='font-react-menstion'>{valor}</div>
          </pre>
        </div>
      </r-cell>
    )
  }

  const { dadosDaSugestao, usuariosDaSugestao, templates, decodificador } = tokensDeSugestao
  const templatesAnamnese = templates.map(x => ({
    ...x,
    options: x.options.length > 0 ? x.options.filter(y => y.componente === 'ANAMNESE').map(z => ({ ...z, label: z.nome, value: z.identificador })) : []
  }))

  const validar = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  const atualizarPreview = (template = '') => {
    return decodificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const selecionarTemplate = (valor) => {
    if (!valor) {
      setPularConfirmacao(false)
      return
    }

    setPularConfirmacao(true)

    let todosOsTemplates = []
    templatesAnamnese.forEach(x => x.options.length > 0 && x.options.map(t => todosOsTemplates.push(t)))

    const template = todosOsTemplates.filter(x => x.identificador === valor)[0].template
    setFieldValue('anamnese', atualizarPreview(template))
  }

  const confirmarESubstituir = (valor) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Os dados informados da Anamnese serão substituídos. Tem certeza disso?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => selecionarTemplate(valor)
      }, {
        label: 'Não',
        onClick: () => setFieldValue('template_anamnese', '')
      }]
    })
  }

  const limparTemplate = (valor, setFieldValue) => {
    if (valor === '') {
      setFieldValue('template_anamnese', '')
      setPularConfirmacao(true)
    }
  }

  return (
    <r-cell class='scroll' ref={scrollEvolucao} span={4} span-md='row' span-lg='row'>         
      <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@@paciente'>
        <InputSugestao
          as='textarea'
          nome='anamnese'
          titulo={`${rotulo} (shift + alt + e) ${obrigatorio ? '*' : ''}`}
          validar={validar}
          dados={dadosDaSugestao}
          dadosUsuarios={usuariosDaSugestao}
          minHeight={300}
          onChange={evento => limparTemplate(evento.target.value, setFieldValue)}   
          id='anamnese'                        
        >
          <SelectTemplate
            nome='template_anamnese'
            itens={templatesAnamnese}
            campoCodigo='identificador'
            campoDescricao='nome'
            onChange={v => (document.getElementById('anamnese').value === '' || pularConfirmacao) ? selecionarTemplate(v) : confirmarESubstituir(v)}
            onClickSalvarComoTemplate={() => abrirAdicionarTemplate(document.getElementById('anamnese').value, 'ANAMNESE')}
          />
        </InputSugestao>
      </DicaDePesquisa>
    </r-cell>
  )
}

export default memo(Anamnese)