import React, {useState} from 'react'
import Modal from 'react-modal'
import {v4 as uuidv4} from 'uuid'
import {Formulario as FormularioDoFormik, Select} from '../formik/formulario'
import Spinner from '../spinner'
import {CRIAR_TAREFA, LISTAR_TIPOS_DE_TAREFA} from '../../acoes/tipos'
import {useParams} from 'react-router-dom'
import {converterObjetoParaArray} from '../../bibliotecas/conversao'

export default function SeletorDeTipoDeTarefa({ criarTarefa, tiposDeTarefa, history, semTipoSelecionado }) {
  const { identificadorDoTipoDeTarefa } = useParams()
  const [exibirModal, setExibirModal] = useState(false)

  const tipo = tiposDeTarefa[identificadorDoTipoDeTarefa] || {}
  const tipoCompleto = tipo && tiposDeTarefa[tipo.identificador]
  const ultimaVersao = tipoCompleto && tipoCompleto.versoes[tipoCompleto.versoes.length - 1]

  const ehCriacaoAutomatica = ultimaVersao && ultimaVersao.configuracoes.automatica ? ultimaVersao.configuracoes.automatica === 'true' : false

  const criar = async formulario => {
    const criou = await criarTarefa({
      identificador: uuidv4(),
      tipo: {
        identificador: formulario.identificador,
        nome: formulario.nome,
        numero: formulario.numero
      }
    })

    if (criou) {
      setExibirModal(false)
      history.push(`/tarefas/${identificadorDoTipoDeTarefa}/tarefa/${criou}`)
    }
  }

  const selecionarTipoDaTarefa = setFieldValue => {
    const tipos = converterObjetoParaArray(tiposDeTarefa)
    const ultimaVersaoPorTipo = tipos.length > 0 && tipos.map(x => ({ identificador: x.identificador, nome: x.nome, ultimaVersao: x.versoes[x.versoes.length - 1] }))
    const tiposNaoAutomaticos = ultimaVersaoPorTipo.length > 0 && ultimaVersaoPorTipo.filter(x => x.ultimaVersao.configuracoes?.automatica !== 'true')

    const onChange = valor => {
      const tipoSelecionado = tiposNaoAutomaticos.find(x => x.identificador === valor)

      setFieldValue(`identificador`, tipoSelecionado.identificador)
      setFieldValue(`nome`, tipoSelecionado.nome)
      setFieldValue(`numero`, tipoSelecionado.ultimaVersao.numero)
    }

    return (
      <div>
        <Select
          nome='tipo'
          titulo='Tipo da tarefa *'
          itens={tiposNaoAutomaticos}
          campoCodigo='identificador'
          campoDescricao='nome'
          onChange={onChange}
        />
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirModal}
        className='modal-selecionar-tipo-de-tarefa'
      >
        <Spinner operacoes={[CRIAR_TAREFA, LISTAR_TIPOS_DE_TAREFA]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: tipoCompleto ? tipoCompleto.identificador : '',
              nome: tipoCompleto ? tipoCompleto.nome : '',
              numero: ultimaVersao ? ultimaVersao.numero : ''
            }}
            acao={criar}
          >
            {({ setFieldValue }) => (
              <fieldset>
                {semTipoSelecionado ?
                  <h2 className='form-title'>Selecionar tipo de tarefa</h2> :
                  <h2 className='form-title'>Tem certeza que deseja criar uma tarefa do tipo {tipo.nome}?</h2>
                }
                {semTipoSelecionado && selecionarTipoDaTarefa(setFieldValue)}
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={() => setExibirModal(false)}>Cancelar</button>
                  <button className='button --primary' type='submit'>Criar</button>
                </div>
              </fieldset>
            )}
          </FormularioDoFormik>
        </Spinner>
      </Modal>
      {!ehCriacaoAutomatica &&
        <div className='header-page-tools'>
           <button className='button --primary --plus w-sm-100' onClick={() => setExibirModal(true)}>
             Adicionar Tarefa
           </button>
        </div>
      }
    </>
  )
}