import React, { useMemo, useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { formatarDataParaFormatoLocal, formatarHoraParaFormatoLocal, diferencaEmMinutosEntreDatas } from '../../../bibliotecas/data'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import LegendaDaUnidade from './legenda-da-unidade-no-evento'

import EventoVazio from './eventos/vazio'
import EventoAgendamento from './eventos/agendamento'
import EventoInstrucao from './eventos/instrucao'
import EventoBloqueio from './eventos/bloqueio'
import EventoDelimitadorDeHorario from './eventos/delimitador-de-horario'

const EVENTO_AGENDAMENTO = 'agendamento'
const EVENTO_BLOQUEIO = 'bloqueio'
const EVENTO_HORARIO = 'horario'
const EVENTO_INSTRUCAO = 'instrucao'
const EVENTO_TRANSICAO = 'transicao'
const EVENTO_FERIADO = 'feriado'
const EVENTO_DELIMITAR_HORA = 'delimitar_hora'


export default function Calendario(props) {
  const {
    abrirNovoAgendamentoNoHorario,
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    cancelarAgendamento,
    confirmarAgendamento,
    calendario,
    excluirAgendamento,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    filtros,
    fotoDoPaciente,
    identificadorDoUsuarioLogado,
    match,
    motivosDeBloqueio,
    motivosDeCancelamento,
    processando,
    profissional,
    recuperarAgendamentoPeloIdentificador,
    recuperarAgendaSemanalDoProfissional,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    unidades,
  } = props

  const extrairPermissoes = {
    alterarAgendamento: usePossuiAsPermissoes([permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]),
    alterarBloqueioNaAgenda: usePossuiAsPermissoes([permissoes.ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]),
    alterarInstrucaoDeAgendamento: usePossuiAsPermissoes([permissoes.ALTERAR_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]),
    removerAgendamento: usePossuiAsPermissoes([permissoes.REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]),
    removerAgendamentosRecorrentes: usePossuiAsPermissoes([permissoes.REMOVER_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE]),
    cancelarAgendamento: usePossuiAsPermissoes([permissoes.CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]),
    cancelarAgendamentosRecorrentes: usePossuiAsPermissoes([permissoes.CANCELAR_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE]),
    confirmarAgendamento: usePossuiAsPermissoes([permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]),
    excluirInstrucaoDeAgendamento: usePossuiAsPermissoes([permissoes.REMOVER_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]),
    excluirBloqueiosNaAgenda: usePossuiAsPermissoes([permissoes.REMOVER_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]),
  }

  const componentRef = useRef()
  const unidadesFiltradasDoUsuario = unidades.filter(x => filtros.unidadesFiltradas.some(identificador => x.identificador === identificador))
  const chave = moment(calendario.data).format('YYYY-MM-DD')
  const dias = profissional.dias
  const tamanhoDoSlotEmMinutos = profissional.configuracoes && profissional.configuracoes.tamanhoDoSlot ? moment.duration(profissional.configuracoes.tamanhoDoSlot, "HH:mm:ss").asMinutes() : 30

  const [segundoAtual, setSegundoAtual] = useState(moment())
  const [horarioAtual, setHorarioAtual] = useState(moment())

  useEffect(() => {
    const identificador = setInterval(() => setSegundoAtual(moment()), 1000)

    if (segundoAtual.format('ss') === '00') {
      setHorarioAtual(segundoAtual)
    }

    return () => clearInterval(identificador)
  }, [segundoAtual])

  const menorMaiorHorarios = useMemo(() => {
    let menorHorario = moment('23:59:59', 'HH:mm:ss')
    let maiorHorario = moment('00:00:00', 'HH:mm:ss')
    let possuiHorario = false
    const diasConvertido = converterObjetoParaArray(profissional.dias)

    diasConvertido.forEach(x => {
      const agendamentosFiltrados = x.agendamentos
      const bloqueiosFiltrados = x.bloqueios
      const horariosFiltrados = x.horarios

      horariosFiltrados.filter(x => unidadesFiltradasDoUsuario.some(z => z.identificador === x.unidade.identificador)).forEach(horario => {
        const inicio = moment(horario.inicio, 'HH:mm:ss')
        const fim = moment(horario.fim, 'HH:mm:ss')

        if (inicio.isBefore(menorHorario)) {
          menorHorario = inicio
        }

        if (fim.isAfter(maiorHorario)) {
          maiorHorario = fim
        }

        possuiHorario = true
      })

      agendamentosFiltrados.filter(x => unidadesFiltradasDoUsuario.some(z => z.identificador === x.unidade.identificador)).forEach(horario => {
        const inicio = moment(formatarHoraParaFormatoLocal(horario.inicio), 'HH:mm:ss')
        const fim = moment(formatarHoraParaFormatoLocal(horario.fim), 'HH:mm:ss')

        if (inicio.isBefore(menorHorario)) {
          menorHorario = inicio
        }

        if (fim.isAfter(maiorHorario)) {
          maiorHorario = fim
        }

        possuiHorario = true
      })

      bloqueiosFiltrados.forEach(horario => {
        const inicio = moment(horario.inicio, 'HH:mm:ss')
        const fim = moment(horario.fim, 'HH:mm:ss')

        if (inicio.isBefore(menorHorario)) {
          menorHorario = inicio
        }

        if (fim.isAfter(maiorHorario)) {
          maiorHorario = fim
        }

        possuiHorario = true
      })

    })

    if (!possuiHorario) {
      return null
    }

    return [menorHorario, maiorHorario]
  }, [profissional.dias, unidadesFiltradasDoUsuario])

  const configuracoesDaAgenda = useMemo(() => {
    let todosHorarios = []
    let slots = []
    let delimitadorDeHorario = []
    const unidadesFiltradas = filtros.unidadesFiltradas
    const diasConvertido = converterObjetoParaArray(profissional.dias)
    const pixelPorMinuto = 60 / tamanhoDoSlotEmMinutos

    if (!menorMaiorHorarios) {
      return null
    }

    let menorHorarioCalc = menorMaiorHorarios[0]
    let maiorHorarioCalc = menorMaiorHorarios[1]

    while (menorHorarioCalc.isBefore(maiorHorarioCalc)) {
      slots.push(moment(menorHorarioCalc, 'HH:mm:ss').format('HH:mm'))
      menorHorarioCalc = moment(menorHorarioCalc, 'HH:mm:ss').add(tamanhoDoSlotEmMinutos, 'minutes')
    }

    function getSlot(inicio) {
      if (moment(slots[0], 'HH:mm:ss').isAfter(inicio)) return null
      return slots.filter(horario => moment(horario, 'HH:mm:ss').add(tamanhoDoSlotEmMinutos, 'minutes').isAfter(inicio))[0]
    }

    function calcularTopDoEvento(inicio) {
      const slotInicio = getSlot(inicio)
      if (!slotInicio) return 0

      let tokens = slotInicio.split(':')
      const horaMinutoSlot = Number(tokens[0]) * 60 + Number(tokens[1])

      tokens = moment(inicio).format('HH:mm').split(':')
      const horaMinutoInicio = Number(tokens[0]) * 60 + Number(tokens[1])

      return Math.abs(horaMinutoSlot - horaMinutoInicio) * pixelPorMinuto
    }

    function calcularTopDoDelimitador(horario) {
      const slot = getSlot(moment(formatarHoraParaFormatoLocal(horario), 'HH:mm:ss'))
      const inicio = moment(slot, 'HH:mm:ss')
      const fim = moment(horario, 'HH:mm:ss')
      const top = diferencaEmMinutosEntreDatas(inicio, fim) * pixelPorMinuto

      return top
    }

    delimitadorDeHorario.push({
      diaDaSemana: horarioAtual.format('ddd').toUpperCase(),
      evento: EVENTO_DELIMITAR_HORA,
      slot: getSlot(moment(formatarHoraParaFormatoLocal(horarioAtual), 'HH:mm:ss')),
      posicionamento: {
        top: calcularTopDoDelimitador(horarioAtual)
      }
    })

    diasConvertido.forEach(dias => {

      dias.instrucoes.forEach(instrucao => {
        let inicio = moment(instrucao.inicio, 'HH:mm:ss')
        const fim = moment(instrucao.fim, 'HH:mm:ss')
        const semSlot = moment(slots[0], 'HH:mm:ss').isAfter(inicio) ? true : false
        const top = calcularTopDoEvento(inicio)

        todosHorarios.push({
          ...instrucao,
          inicio: instrucao.inicio.substring(0, 5),
          fim: instrucao.fim.substring(0, 5),
          diaDaSemana: dias.diaDaSemana,
          label: instrucao.texto,
          evento: EVENTO_INSTRUCAO,
          slot: getSlot(inicio),
          visivel: true,
          posicionamento: {
            height: diferencaEmMinutosEntreDatas(inicio, fim) * pixelPorMinuto,
            top: top
          },
          cor: { codigo: '#84878B' }
        })

        if (!semSlot) {
          inicio = inicio.add(tamanhoDoSlotEmMinutos, 'minutes')
          while (inicio.isBefore(fim)) {
            todosHorarios.push({
              diaDaSemana: dias.diaDaSemana,
              evento: EVENTO_TRANSICAO,
              slot: getSlot(inicio),
              visivel: true,
            })

            inicio = inicio.add(tamanhoDoSlotEmMinutos, 'minutes')
          }
        }
      })

      dias.agendamentos.forEach(agenda => {
        const inicio = moment(formatarHoraParaFormatoLocal(agenda.inicio), 'HH:mm:ss')
        const top = calcularTopDoEvento(inicio)

        todosHorarios.push({
          ...agenda,
          dataInicio: agenda.inicio,
          dataFim: agenda.fim,
          inicio: formatarHoraParaFormatoLocal(agenda.inicio),
          fim: formatarHoraParaFormatoLocal(agenda.fim),
          diaDaSemana: dias.diaDaSemana,
          feriado: dias.feriado,
          feriadoTrabalhado: dias.feriadoTrabalhado,
          tipoDeHorario: agenda.horario?.tipo,
          label: agenda.paciente.nome,
          evento: EVENTO_AGENDAMENTO,
          slot: getSlot(inicio),
          visivel: unidadesFiltradas.some(identificador => agenda.unidade.identificador === identificador),
          posicionamento: {
            height: diferencaEmMinutosEntreDatas(agenda.inicio, agenda.fim) * pixelPorMinuto,
            top: top
          },
        })
      })

      dias.bloqueios.filter(x => !dias.ehFerias || x.motivo === 'ferias').forEach(bloqueio => {
        const inicio = bloqueio.inicio ? moment(bloqueio.inicio, 'HH:mm:ss') : moment(menorMaiorHorarios[0], 'HH:mm:ss')
        const fim = bloqueio.fim ? moment(bloqueio.fim, 'HH:mm:ss') : moment(menorMaiorHorarios[1], 'HH:mm:ss')
        const top = calcularTopDoEvento(inicio)

        todosHorarios.push({
          ...bloqueio,
          inicio: moment(inicio).format('HH:mm'),
          fim: moment(fim).format('HH:mm'),
          diaDaSemana: dias.diaDaSemana,
          label: bloqueio.motivo === 'outro' ? bloqueio.observacoes : bloqueio.motivo,
          evento: EVENTO_BLOQUEIO,
          slot: getSlot(inicio),
          visivel: true,
          posicionamento: {
            height: (diferencaEmMinutosEntreDatas(inicio, fim)) * pixelPorMinuto + diferencaEmMinutosEntreDatas(inicio, fim) / tamanhoDoSlotEmMinutos,
            top: top,
          },
        })
      })

      dias.horarios.forEach(horario => {
        const fim = moment(horario.fim, 'HH:mm:ss')
        let inicio = moment(horario.inicio, 'HH:mm:ss')

        while (inicio.isBefore(fim)) {
          todosHorarios.push({
            data: dias.data,
            inicio: inicio.format('HH:mm'),
            diaDaSemana: dias.diaDaSemana,
            duracaoDoAgendamento: tamanhoDoSlotEmMinutos,
            evento: EVENTO_HORARIO,
            slot: getSlot(inicio),
            visivel: unidadesFiltradas.some(identificador => horario.unidade.identificador === identificador),
            unidade: horario.unidade,
            tipoDeHorario: horario.tipo,
            posicionamento: {
              height: (diferencaEmMinutosEntreDatas(inicio, fim)) * pixelPorMinuto,
            },
          })

          inicio = inicio.add(tamanhoDoSlotEmMinutos, 'minutes')
        }
      })

      if (dias.feriado && !dias.feriadoTrabalhado) {
        todosHorarios.push({
          data: dias.data,
          diaDaSemana: dias.diaDaSemana,
          evento: EVENTO_FERIADO,
        })
      }

      todosHorarios.forEach(todos => delete todos.profissional)
    })

    return {
      slots,
      todosHorarios,
      delimitadorDeHorario
    }
  }, [filtros.unidadesFiltradas, profissional, tamanhoDoSlotEmMinutos, menorMaiorHorarios, horarioAtual])

  function diasDaSemana(dias, chave) {
    let diasDaSemana = chave ? [dias[chave]] : converterObjetoParaArray(dias)

    diasDaSemana = diasDaSemana.filter(x => x && (x.horarios.length > 0 || x.agendamentos.length > 0))
    diasDaSemana = diasDaSemana.filter(x => x &&
      (x.horarios.some(y => unidadesFiltradasDoUsuario.some(z => z.identificador === y.unidade.identificador)) ||
        x.agendamentos.some(y => unidadesFiltradasDoUsuario.some(z => z.identificador === y.unidade.identificador))))

    return diasDaSemana
  }

  const header = diasDaSemana(dias, calendario.visualizacao === 'semana' ? null : chave)

  const recuperarTextoNaoExisteHorario = (calendario, unidadesFiltradasDoUsuario) => {
    const periodo = calendario.visualizacao === 'semana' ?
      `o período de ${moment(calendario.data).startOf('week').format('DD/MM/YYYY')} até ${moment(calendario.data).endOf('week').format('DD/MM/YYYY')}` :
      `o dia ${moment(calendario.data).format('DD/MM/YYYY')}`

    let unidade = ''

    if (unidadesFiltradasDoUsuario.length > 0) {
      unidade = unidadesFiltradasDoUsuario.length > 1 ?
        `nas unidades (${unidadesFiltradasDoUsuario.map(x => x.nome).join(', ')})` :
        `na unidade ${unidadesFiltradasDoUsuario[0] ? unidadesFiltradasDoUsuario[0].nome : ''}`
    }

    return `Não existem horários ne agenda para ${periodo}, ${unidade}.`
  }

  const renderizarHeader = item => {
    return (
      <div
        key={item.data}
        className={`box-agenda__header__item ${item.ehHoje ? 'is-active' : ''}`}
      >
        <strong>{item.diaDaSemana}{item.feriado && ' (Feriado)'}</strong>
        {formatarDataParaFormatoLocal(item.data)}
      </div>
    )
  }

  const renderizarBoxHorario = (slot, index) => {
    return (
      <div className='box-agenda__row' key={slot + index}>
        <div className='box-agenda__timezone'>{slot}</div>
        <div className='box-agenda__cells'>
          {header.map((item, index) =>
            <RenderizarEventos
              abrirNovoAgendamentoNoHorario={abrirNovoAgendamentoNoHorario}
              alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              cancelarAgendamento={cancelarAgendamento}
              configuracoesDaAgenda={configuracoesDaAgenda}
              confirmarAgendamento={confirmarAgendamento}
              excluirAgendamento={excluirAgendamento}
              excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}              
              excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              exibirCorDaUnidadeNoEvento={unidades.length > 1}
              fotoDoPaciente={fotoDoPaciente}
              header={item}
              identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
              key={index}
              match={match}
              motivosDeBloqueio={motivosDeBloqueio}
              motivosDeCancelamento={motivosDeCancelamento}
              permissoes={extrairPermissoes}
              profissional={profissional}
              recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
              recuperarAgendaSemanalDoProfissional={recuperarAgendaSemanalDoProfissional}
              recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
              removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
              selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
              selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
              slot={slot}
            />
          )}
        </div>
      </div>
    )
  }

  const exibirCalendario = calendario.visualizacao === 'semana' ?
    header && header.length > 0 :
    header && header.length > 0 && configuracoesDaAgenda.todosHorarios && configuracoesDaAgenda.todosHorarios.filter(x => x.visivel && x.diaDaSemana === header[0].diaDaSemana).length > 0

  const isLoading = processando || (exibirCalendario && !configuracoesDaAgenda) ? 'is-loading' : ''

  return (
    <div className={`box-agenda ${isLoading}`} ref={componentRef}>
      <div className='box-agenda__header custom-scrollbar'>
        {!exibirCalendario && !isLoading &&
          <div className='mensagens-do-calendario'>
            {recuperarTextoNaoExisteHorario(calendario, unidadesFiltradasDoUsuario)}
          </div>
        }
        {exibirCalendario && header.map(item => renderizarHeader(item))}
      </div>
      <div className='box-agenda__body custom-scrollbar'>
        {exibirCalendario && configuracoesDaAgenda &&
          configuracoesDaAgenda.slots.map((slot, index) => renderizarBoxHorario(slot, index))}
      </div>
    </div>
  )
}

function RenderizarEventos(props) {
  const {
    abrirNovoAgendamentoNoHorario,
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    cancelarAgendamento,
    configuracoesDaAgenda,
    confirmarAgendamento,
    excluirAgendamento,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    exibirCorDaUnidadeNoEvento,
    fotoDoPaciente,
    header,
    identificadorDoUsuarioLogado,
    match,
    motivosDeBloqueio,
    motivosDeCancelamento,
    permissoes,
    profissional,
    recuperarAgendamentoPeloIdentificador,
    recuperarAgendaSemanalDoProfissional,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    slot
  } = props

  const eventoFeriado = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.evento === EVENTO_FERIADO).length > 0
  const eventoHorario = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_HORARIO && x.visivel)[0]
  const eventosAgendamento = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_AGENDAMENTO)
  const eventosInstrucao = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_INSTRUCAO)
  const eventosBloqueio = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_BLOQUEIO)
  const eventosTransicao = configuracoesDaAgenda.todosHorarios.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_TRANSICAO)
  const delimitadorDeHorario = configuracoesDaAgenda.delimitadorDeHorario.filter(x => x.diaDaSemana === header.diaDaSemana && x.slot === slot && x.evento === EVENTO_DELIMITAR_HORA && header.ehHoje)

  const qtdeEventosMesmoHorario = eventosAgendamento.length + eventosInstrucao.length + eventosBloqueio.length
  const temHorario = !eventoFeriado && eventoHorario && eventoHorario !== undefined

  if (!eventoFeriado && qtdeEventosMesmoHorario === 0) {
    return (
      <EventoVazio
        abrirNovoAgendamentoNoHorario={abrirNovoAgendamentoNoHorario}
        className={'box-agenda__disable-event'}
        exibirCorDaUnidadeNoEvento={exibirCorDaUnidadeNoEvento}
        item={eventoHorario}
        delimitadorDeHorario={delimitadorDeHorario}
      />
    )
  }

  return (
    <div
      className='box-agenda__cells__col'
      style={{ cursor: 'pointer' }}
    >
      {temHorario &&
        <EventoInstrucao
          alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          itens={eventosInstrucao}
          permissoes={permissoes}
          profissional={profissional}
          temAgenda={eventosAgendamento.length > 0}
        />
      }
      <EventoAgendamento
        cancelarAgendamento={cancelarAgendamento}
        configuracoesDaAgenda={configuracoesDaAgenda}
        confirmarAgendamento={confirmarAgendamento}
        excluirAgendamento={excluirAgendamento}
        fotoDoPaciente={fotoDoPaciente}
        identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
        itens={eventosAgendamento}
        match={match}
        motivosDeCancelamento={motivosDeCancelamento}
        profissional={profissional}
        recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
        recuperarAgendaSemanalDoProfissional={recuperarAgendaSemanalDoProfissional}
        recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
        removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
        selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
        selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
        temInstrucao={eventosTransicao.length > 0 || eventosInstrucao.length > 0}
      />
      <EventoBloqueio
        alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
        alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
        excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
        excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
        itens={eventosBloqueio}
        motivosDeBloqueio={motivosDeBloqueio}
        permissoes={permissoes}
        profissional={profissional}
      />
      <EventoDelimitadorDeHorario
        itens={delimitadorDeHorario}
      />
      {eventosBloqueio.length === 0 && temHorario &&
        <div className='box-agenda__novo_atendimento' onClick={() => abrirNovoAgendamentoNoHorario(eventoHorario)}
          style={{
            cursor: 'pointer',
            width: `${qtdeEventosMesmoHorario <= 3 ? '' : '8px'}`,
          }} />
      }
      {eventosAgendamento.length === 0 && eventosBloqueio.length === 0 && !temHorario &&
        <div className='box-agenda__disable-event' />
      }
      {eventoFeriado &&
        <div className='box-agenda__disable-event-feriado' />
      }
      <LegendaDaUnidade
        exibir={exibirCorDaUnidadeNoEvento}
        item={eventoHorario}
      />
    </div>
  )
}