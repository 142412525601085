export const converterArrayParaObjeto = (array = [], nomeDaPropriedade = 'identificador') => {
  let objeto = {}

  array.forEach((item, index) => {
      objeto[item[nomeDaPropriedade]] = {
          ...item,
          _ordem: index,
      }
  })

  return objeto
}

export const converterObjetoParaArray = (objeto = {}) => Object.keys(objeto).map(key => objeto[key])