import React, { useState, useEffect } from 'react'
import { DatePicker, Formulario as FormularioDoFormik, MultiplaEscolha } from '../../../../formik/formulario'
import { contemValor } from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_PHQ9,
  ALTERAR_PHQ9,
  LISTAR_RESPOSTAS_DO_PHQ9
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const renderizarTitulo = valor => { return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Formulario(props) {
  const {
    respostasDoPhq9,
    selecionado,
    valores,
    fechar,
    ...rest
  } = props

  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const [pontuacao, setPontuacao] = useState({})

  const atualizarPontuacao = (nome, codigo) => {
    setPontuacao(pontuacao => ({ ...pontuacao, [nome]: respostasDoPhq9.find(x => x.codigo === codigo).pontuacao }))
  }

  const recuperarPontuacao = (campo) => {
    if (pontuacao[campo] === undefined && valores[campo] !== undefined) {
      atualizarPontuacao(campo, valores[campo])
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_PHQ9, ALTERAR_PHQ9, LISTAR_RESPOSTAS_DO_PHQ9]}>
      <FormularioDoFormik
        reinicializar={true}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar PHQ9' : 'Adicionar PHQ9'}</h2>
          <Data />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
            <Cabecalho />
            <PossuiPoucoInteresse respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <SeSenteDeprimido respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <DificuldadeParaDormir respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <SeSenteCansado respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <FaltaDeApetite respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <SeSenteMalConsigoMesmo respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <DificuldadeParaConcentrar respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <LentidaoParaMovimentarOuFalar respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <PensaEmSeFerir respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} atualizarPontuacao={atualizarPontuacao} recuperarPontuacao={recuperarPontuacao} />
            <Avaliacao pontuacao={pontuacao} />
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>PHQ9</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function PossuiPoucoInteresse({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('possuiPoucoInteresse')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='possuiPoucoInteresse'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Possui pouco interesse ou pouco prazer em fazer as coisas.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.possuiPoucoInteresse}</div>
    </r-cell>
  )
}

function SeSenteDeprimido({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('seSenteDeprimido')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteDeprimido'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Se sente para baixo, deprimido/a ou sem perspectiva.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.seSenteDeprimido}</div>
    </r-cell>
  )
}

function DificuldadeParaDormir({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('dificuldadeParaDormir')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='dificuldadeParaDormir'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Possui dificuldade para pegar no sono ou permanecer dormindo ou dorme mais do que de costume.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.dificuldadeParaDormir}</div>
    </r-cell>
  )
}

function SeSenteCansado({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('seSenteCansado')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteCansado'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='4. Se sente cansado/a ou com pouca energia.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.seSenteCansado}</div>
    </r-cell>
  )
}

function FaltaDeApetite({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('faltaDeApetite')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='faltaDeApetite'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='5. Possui falta de apetite ou está comendo demais.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.faltaDeApetite}</div>
    </r-cell>
  )
}

function SeSenteMalConsigoMesmo({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('seSenteMalConsigoMesmo')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteMalConsigoMesmo'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='6. Se sente mal consigo mesmo/a ou acha que você é um fracasso ou que decepcionou sua família ou você mesmo/a.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.seSenteMalConsigoMesmo}</div>
    </r-cell>
  )
}

function DificuldadeParaConcentrar({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('dificuldadeParaConcentrar')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='dificuldadeParaConcentrar'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='7. Possui dificuldade para se concentrar nas coisas, como ler o jornal ou ver televisão.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.dificuldadeParaConcentrar}</div>
    </r-cell>
  )
}

function LentidaoParaMovimentarOuFalar({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('lentidaoParaMovimentarOuFalar')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='lentidaoParaMovimentarOuFalar'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='8. Possui lentidão para se movimentar ou falar, a ponto das outras pessoas perceberem. Ou o oposto, estar tão agitado/a ou inquieto/a que fica andando de um lado o outro mais do que o de costume.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.lentidaoParaMovimentarOuFalar}</div>
    </r-cell>
  )
}

function PensaEmSeFerir({ respostasDoPhq9, pontuacao, atualizarPontuacao, recuperarPontuacao }) {
  useEffect(() => {
    recuperarPontuacao('pensaEmSeFerir')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='pensaEmSeFerir'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='9. Pensa em se ferir de alguma maneira ou que seria melhor estar morto/a.'
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        onChange={atualizarPontuacao}
      />
      <div className='pontuacao'>{pontuacao.pensaEmSeFerir}</div>
    </r-cell>
  )
}

function Avaliacao({ pontuacao }) {
  const possuiPoucoInteresse = pontuacao.possuiPoucoInteresse ? pontuacao.possuiPoucoInteresse : 0
  const seSenteDeprimido = pontuacao.seSenteDeprimido ? pontuacao.seSenteDeprimido : 0
  const dificuldadeParaDormir = pontuacao.dificuldadeParaDormir ? pontuacao.dificuldadeParaDormir : 0
  const seSenteCansado = pontuacao.seSenteCansado ? pontuacao.seSenteCansado : 0
  const faltaDeApetite = pontuacao.faltaDeApetite ? pontuacao.faltaDeApetite : 0
  const seSenteMalConsigoMesmo = pontuacao.seSenteMalConsigoMesmo ? pontuacao.seSenteMalConsigoMesmo : 0
  const dificuldadeParaConcentrar = pontuacao.dificuldadeParaConcentrar ? pontuacao.dificuldadeParaConcentrar : 0
  const lentidaoParaMovimentarOuFalar = pontuacao.lentidaoParaMovimentarOuFalar ? pontuacao.lentidaoParaMovimentarOuFalar : 0
  const pensaEmSeFerir = pontuacao.pensaEmSeFerir ? pontuacao.pensaEmSeFerir : 0

  const total = possuiPoucoInteresse + seSenteDeprimido + dificuldadeParaDormir + seSenteCansado + faltaDeApetite + seSenteMalConsigoMesmo + dificuldadeParaConcentrar + lentidaoParaMovimentarOuFalar + pensaEmSeFerir

  const avaliacaoPhq9 = () => {
    if (total === 0) return ''

    if (total >= 1 && total <= 4) {
      return 'Depressão mínima'
    } else if (total >= 5 && total <= 9) {
      return 'Depressão leve'
    } else if (total >= 10 && total <= 14) {
      return 'Depressão moderada'
    } else if (total >= 15 && total <= 19) {
      return 'Depressão moderadamente grave'
    } else {
      return 'Depressão grave'
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(total || total === 0) &&
        <div className='resultado-questionario'>
          <div>
            RESULTADO PHQ9:{' '}<strong>{avaliacaoPhq9()}</strong>
            <div className='legenda'>
              Depressão mínima (1-4 pontos); Leve (5-9 pontos); Moderada (10-14 pontos); Moderadamente grave (15-19 pontos); Grave (20-27 pontos)
            </div>
          </div>
          <div><strong>{total}</strong></div>
        </div>
      }
    </r-cell>
  )
}