import api from '../../../api-major'

const adicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificador, avaliacao) => api.post(`/pacientes/${identificador}/avaliacoes-da-sobrecarga-do-cuidador`, avaliacao)
const alterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificador, avaliacao) => api.put(`/pacientes/${identificador}/avaliacoes-da-sobrecarga-do-cuidador/${avaliacao.identificador}`, avaliacao)
const removerAvaliacaoDaSobrecargaDoCuidadorDoPaciente = (identificador, identificadorDaAvaliacao) => api.delete(`/pacientes/${identificador}/avaliacoes-da-sobrecarga-do-cuidador/${identificadorDaAvaliacao}`)
const listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente = identificador => api.get(`/pacientes/${identificador}/avaliacoes-da-sobrecarga-do-cuidador`)

export default { 
  adicionarAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  alterarAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  removerAvaliacaoDaSobrecargaDoCuidadorDoPaciente,
  listarAvaliacoesDaSobrecargaDoCuidadorDoPaciente
}