import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_PHQS9,
  LISTOU_PHQS9,
  ERRO_AO_LISTAR_PHQS9,
  ADICIONAR_PHQ9,
  ADICIONOU_PHQ9,
  ERRO_AO_ADICIONAR_PHQ9,
  ALTERAR_PHQ9,
  ALTEROU_PHQ9,
  ERRO_AO_ALTERAR_PHQ9,
  REMOVER_PHQ9,
  REMOVEU_PHQ9,
  ERRO_AO_REMOVER_PHQ9
} from '../../../tipos'

const listouPhqs9 = (identificadorDoPaciente, phqs9) => ({
  type: LISTOU_PHQS9,
  identificadorDoPaciente,
  phqs9
})

const erroAoListarPhqs9 = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PHQS9,
  erro,
  parametros
})

export const listarPhqs9 = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_PHQS9 })

  try {
    const resultado = await api.listarPhqs9(identificadorDoPaciente)

    dispatch(listouPhqs9(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPhqs9(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os questionários PHQ9 do paciente.')))
  }
}

const adicionouPhq9 = phq9 => ({
  type: ADICIONOU_PHQ9,
  phq9
})

const erroAoAdicionarPhq9 = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PHQ9,
  erro,
  parametros
})

export const adicionarPhq9 = (identificadorDoPaciente, phq9) => async dispatch => {
  dispatch({ type: ADICIONAR_PHQ9 })

  try {
    const resultado = await api.adicionarPhq9(identificadorDoPaciente, phq9)

    dispatch(adicionouPhq9(resultado.data.dados))
    dispatch(listarPhqs9(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPhq9(erro, { phq9 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar o questionário PHQ9 do paciente.')))

    return false
  }
}

const alterouPhq9 = phq9 => ({
  type: ALTEROU_PHQ9,
  phq9
})

const erroAoAlterarPhq9 = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PHQ9,
  erro,
  parametros
})

export const alterarPhq9 = (identificadorDoPaciente, phq9) => async dispatch => {
  dispatch({ type: ALTERAR_PHQ9 })

  try {
    const resultado = await api.alterarPhq9(identificadorDoPaciente, phq9)

    dispatch(alterouPhq9(resultado.data.dados))
    dispatch(listarPhqs9(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPhq9(erro, { phq9 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o questionário PHQ9 do paciente.')))

    return false
  }
}

const removeuPhq9 = identificadorDoPaciente => ({
  type: REMOVEU_PHQ9,
  identificadorDoPaciente
})

const erroAoRemoverPhq9 = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PHQ9,
  erro,
  parametros
})

export const removerPhq9 = (identificadorDoPaciente, identificadorDoPhq9) => async dispatch => {
  dispatch({ type: REMOVER_PHQ9 })

  try {
    const resultado = await api.removerPhq9(identificadorDoPaciente, identificadorDoPhq9)

    dispatch(removeuPhq9(identificadorDoPaciente))
    dispatch(listarPhqs9(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPhq9(erro, { identificadorDoPaciente, identificadorDoPhq9 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover o questionário PHQ9 do paciente.')))

    return false
  }
}