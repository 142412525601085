import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
} from '../tipos'

const fezDownloadDoArquivoAdicionalDoAtendimento = (identificadorDoArquivoGenerico, bytesDoArquivoGenerico) => ({
  type: FEZ_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  identificadorDoArquivoGenerico,
  bytesDoArquivoGenerico
})

const erroAoFazerDownloadDoArquivoAdicionalDoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
  erro,
  parametros
})

export const fazerDownloadDoArquivoAdicionalDoAtendimento = (atendimento, identificadorDoArquivoGenerico) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_ARQUIVO_ADICIONAL_DO_ATENDIMENTO,
    identificadorDoArquivoGenerico
  })

  try {
    const resultado = await api.fazerDownloadDoArquivoAdicionalDoAtendimento(atendimento, identificadorDoArquivoGenerico)

    dispatch(fezDownloadDoArquivoAdicionalDoAtendimento(identificadorDoArquivoGenerico, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoArquivoAdicionalDoAtendimento(erro, { atendimento, identificadorDoArquivoGenerico }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do arquivo adicional do atendimento.')))
    return null
  }
}
