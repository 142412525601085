import {
  LISTOU_ATENDIMENTOS_NAO_ASSINADOS
} from '../../acoes/tipos'

const estadoInicial = {
  paginaDeDados: {},
  ordenacao: { nome: 'Inicio', ordem: '-' }
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_ATENDIMENTOS_NAO_ASSINADOS: {
      const { ordenacao, paginaDeDados } = action

      return {
        ...state,
        paginaDeDados,
        ordenacao
      }

    }

    default: {
      return state
    }
  }
}