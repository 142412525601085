import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_PSICOLOGOS_DO_PACIENTE,
  LISTOU_PSICOLOGOS_DO_PACIENTE,
  ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE,
} from '../tipos'

const listouPsicologosDoPaciente = psicologos => ({
  type: LISTOU_PSICOLOGOS_DO_PACIENTE,
  psicologos,
})

const erroAoListarPsicologosDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE,
  erro,
})

export const listarPsicologosDoPaciente = () => async dispatch => {
  dispatch({ type: LISTAR_PSICOLOGOS_DO_PACIENTE })

  try {
    let resultado = await api.listarPsicologos()

    dispatch(listouPsicologosDoPaciente(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPsicologosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os psicólogos do paciente.')))
  }
}