import api from './api-major'

const listarFabricantes = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10000'

  return api.get(`/fabricantes${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarFabricante = fabricante => api.post(`/fabricantes/`, fabricante)
const recuperarFabricantePeloIdentificador = identificador => api.get(`/fabricantes/${identificador}`)
const alterarFabricante = fabricante => api.put(`/fabricantes/${fabricante.identificador}`, fabricante)
const removerFabricante = identificador => api.delete(`/fabricantes/${identificador}`)

export default {
  listarFabricantes,
  adicionarFabricante,
  recuperarFabricantePeloIdentificador,
  alterarFabricante,
  removerFabricante
}