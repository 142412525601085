import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
} from '../tipos'

const listouPlanosDeSaudeParaOPedidoDeContato = planosDeSaude => ({
  type: LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  planosDeSaude
})

const erroAoListarPlanosDeSaudeParaOPedidoDeContato = erro => ({
  type: ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  erro
})

export const listarPlanosDeSaudeParaOPedidoDeContato = () => async dispatch => {
  dispatch({ type: LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO })

  try {
    const resultado = await api.listarPlanosDeSaudeParaOPedidoDeContato()

    dispatch(listouPlanosDeSaudeParaOPedidoDeContato(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarPlanosDeSaudeParaOPedidoDeContato(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar os planos de saúde para o pedido de contato.')))

    return false
  }
}