import { LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN: {
      return action.motivosDeBloqueioDoLogin
    }

    default: {
      return state
    }
  }
}