import acoes from '../../acoes'
import { connect } from 'react-redux'
import Medicamentos from './index'

const mapStatesToProps = state => {
  const medicamentoAtual = state.medicamentos.itemAtual

  let medicamento = {
    nome: '',
    observacoes: '',
    ativo: true,
    concentracoes: []
  }

  if(medicamentoAtual) {
    medicamento.identificador = medicamentoAtual.identificador
    medicamento.nome = medicamentoAtual.nome
    medicamento.observacoes = medicamentoAtual.observacoes
    medicamento.ativo = medicamentoAtual.ativo
    medicamento.concentracoes = medicamentoAtual.concentracoes
  }

  return {
    filtros: state.medicamentos.filtros,
    ordenacao: state.medicamentos.ordenacao,
    paginaDeDados: state.medicamentos.paginaDeDados,
    medicamento
  }
}
export default connect(mapStatesToProps, acoes)(Medicamentos)