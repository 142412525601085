import React, {useEffect, useState} from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import {Coluna, Tabela} from '../../../../tabela'
import {formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal} from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import {useProntuario} from '../../../contexto'
import Permissao, {usePossuiAsPermissoes} from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Formulario from './formulario'
import Resumo from './resumo'
import moment from 'moment'

import {
  LISTAR_PHQS9,
  REMOVER_PHQ9
} from '../../../../../acoes/tipos'

export default function Phq9({ identificadorDoPaciente, phqs9 }) {
  const {
    listarPhqs9,
    adicionarPhq9,
    alterarPhq9,
    removerPhq9,
    listarRespostasDoPhq9,
    respostasDoPhq9
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_PHQ9])

  useEffect(() => {
    if (phqs9 === undefined) {
      listarPhqs9(identificadorDoPaciente)
    }
  }, [listarPhqs9, identificadorDoPaciente, phqs9])

  useEffect(() => { listarRespostasDoPhq9() }, [listarRespostasDoPhq9])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarPhq9(identificadorDoPaciente, item) : await adicionarPhq9(identificadorDoPaciente, item)
  }

  if (phqs9 === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do PHQ9'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          respostasDoPhq9={respostasDoPhq9}
          valores={recuperaValorInicialPhq9(selecionado)}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo do PHQ9'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          respostasDoPhq9={respostasDoPhq9}
          valores={recuperaValorInicialPhq9(selecionado)}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção do PHQ9'
        className='modal-vacinas'
      >
        <Remover
          selecionado={selecionado}
          remover={valor => removerPhq9(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_PHQS9]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_PHQ9]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <button
                    className='table-items__bt --edit'
                    title='Ver resumo'
                    onClick={() => abrirResumo(item)}
                    type='button'
                    key={`questionario_${item.identificador}`}
                  >
                    <i className='icon icon-doc-text'></i>
                  </button>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_PHQ9]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={phqs9}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros do PHQ9.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Pontuação'
                campoDeDados='pontuacaoTotal'
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionado, remover, fechar }) {
  const excluirPhq9 = async () => {
    const excluiu = await remover(selecionado.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_PHQ9]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover o PHQ9?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirPhq9}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicialPhq9 = selecionado => {
  if (selecionado) {
    return {
      ...selecionado
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    possuiPoucoInteresse: undefined,
    seSenteDeprimido: undefined,
    dificuldadeParaDormir: undefined,
    seSenteCansado: undefined,
    faltaDeApetite: undefined,
    seSenteMalConsigoMesmo: undefined,
    dificuldadeParaConcentrar: undefined,
    lentidaoParaMovimentarOuFalar: undefined,
    pensaEmSeFerir: undefined
  }
}