import {LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO: {
      return action.frequenciasNaEscalaDaSolidao
    }

    default: {
      return state
    }
  }
}