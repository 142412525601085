import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE,
  ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
} from '../../tipos'

const listouConsultasExternasDoPaciente = (identificadorDoPaciente, consultasExternas) => ({
  type: LISTOU_CONSULTAS_EXTERNAS_DO_PACIENTE,
  identificadorDoPaciente,
  consultasExternas
})

const erroAoListarConsultasExternasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  erro,
  parametros
})

export const listarConsultasExternasDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE })

  try {
    const resultado = await api.listarConsultasExternasDoPaciente(identificadorDoPaciente)

    dispatch(listouConsultasExternasDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarConsultasExternasDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as consultas externas do paciente.')))
  }
}

const adicionouConsultaExternaDoPaciente = consultaExterna => ({
  type: ADICIONOU_CONSULTA_EXTERNA_DO_PACIENTE,
  consultaExterna
})

const erroAoAdicionarConsultaExternaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarConsultaExternaDoPaciente = (identificadorDoPaciente, consultaExterna) => async dispatch => {
  dispatch({ type: ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE })

  try {
    const resultado = await api.adicionarConsultaExternaDoPaciente(identificadorDoPaciente, consultaExterna)

    dispatch(adicionouConsultaExternaDoPaciente(resultado.data.dados))
    dispatch(listarConsultasExternasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarConsultaExternaDoPaciente(erro, { consultaExterna }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar consulta externa do paciente.')))

    return false
  }
}

const alterouConsultaExternaDoPaciente = consultaExterna => ({
  type: ALTEROU_CONSULTA_EXTERNA_DO_PACIENTE,
  consultaExterna
})

const erroAoAlterarConsultaExternaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  erro,
  parametros
})

export const alterarConsultaExternaDoPaciente = (identificadorDoPaciente, consultaExterna) => async dispatch => {
  dispatch({ type: ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE })

  try {
    const resultado = await api.alterarConsultaExternaDoPaciente(identificadorDoPaciente, consultaExterna)

    dispatch(alterouConsultaExternaDoPaciente(resultado.data.dados))
    dispatch(listarConsultasExternasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarConsultaExternaDoPaciente(erro, { consultaExterna }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar consulta externa do paciente.')))

    return false
  }
}

const removeuConsultaExternaDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_CONSULTA_EXTERNA_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverConsultaExternaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  erro,
  parametros
})

export const removerConsultaExternaDoPaciente = (identificadorDoPaciente, identificadorDaConsultaExterna) => async dispatch => {
  dispatch({ type: REMOVER_CONSULTA_EXTERNA_DO_PACIENTE })

  try {
    const resultado = await api.removerConsultaExternaDoPaciente(identificadorDoPaciente, identificadorDaConsultaExterna)

    dispatch(removeuConsultaExternaDoPaciente(identificadorDoPaciente))
    dispatch(listarConsultasExternasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverConsultaExternaDoPaciente(erro, { identificadorDoPaciente, identificadorDaConsultaExterna }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover consulta externa do paciente.')))

    return false
  }
}