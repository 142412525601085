import { LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO } from '../../acoes/tipos'

const estadoInicial = {
  habilitados: [],
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO: {
      return {
        ...state,
        habilitados: action.tiposDeAtendimento.dados.map(x => x.tipo.identificador),
        paginaDeDados: action.tiposDeAtendimento
      }
    }

    default: {
      return state
    }
  }
}