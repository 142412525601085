import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_CATEGORIA_DA_TAREFA,
  DEFINIU_CATEGORIA_DA_TAREFA,
  ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA,
  ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS,
  LISTAR_CATEGORIAS_DO_TAREFAS,
  LISTOU_CATEGORIAS_DO_TAREFAS
} from '../tipos'

const definiuCategoriaDaTarefa = (identificadorDaTarefa, categoria) => ({
  identificadorDaTarefa,
  categoria,
  type: DEFINIU_CATEGORIA_DA_TAREFA
})

const erroAoDefinirCategoriaDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_CATEGORIA_DA_TAREFA,
  erro,
  parametros,
})

export const definirCategoriaDaTarefa = (identificadorDaTarefa, categoria) => async dispatch => {
  dispatch({ type: DEFINIR_CATEGORIA_DA_TAREFA })

  try {
    const resultado = await api.definirCategoriaDaTarefa(identificadorDaTarefa, categoria)

    dispatch(definiuCategoriaDaTarefa(identificadorDaTarefa, categoria))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirCategoriaDaTarefa(erro, { identificadorDaTarefa, categoria }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir a categoria da tarefa.')))

    return false
  }
}

const listouCategoriasDoTarefas = categorias => ({
  type: LISTOU_CATEGORIAS_DO_TAREFAS,
  categorias
})

const erroAoListarCategoriasDoTarefas = erro => ({
  type: ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS,
  erro
})

export const listarCategoriasDoTarefas = () => async dispatch => {
  dispatch({ type: LISTAR_CATEGORIAS_DO_TAREFAS })

  try {
    const resultado = await api.listarCategoriasDoTarefas()
    dispatch(listouCategoriasDoTarefas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarCategoriasDoTarefas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as categorias do tarefas.')))

    return false
  }
}