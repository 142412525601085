import { connect } from 'react-redux'
import acoes from '../../acoes'
import Fabricantes from './index'

const mapStateToProps = state => {
  const fabricanteAtual = state.fabricantes.itemAtual

  let fabricante = {
    nome: '',
    observacoes: ''
  }

  if (fabricanteAtual) {
    fabricante.identificador = fabricanteAtual.identificador
    fabricante.nome = fabricanteAtual.nome
    fabricante.observacoes = fabricanteAtual.observacoes
  }

  return {
    filtros: state.fabricantes.filtros,
    ordenacao: state.fabricantes.ordenacao,
    paginaDeDados: state.fabricantes.paginaDeDados,
    fabricante,
  }
}

export default connect(mapStateToProps, acoes)(Fabricantes)