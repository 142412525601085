import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_PROGRAMA_DO_PACIENTE,
  ADICIONOU_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE,
  ALTERAR_PROGRAMA_DO_PACIENTE,
  ALTEROU_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE,
  LISTAR_PROGRAMAS_DO_PACIENTE,
  LISTOU_PROGRAMAS_DO_PACIENTE,
  ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE,
  REMOVER_PROGRAMA_DO_PACIENTE,
  REMOVEU_PROGRAMA_DO_PACIENTE,
  ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE,
  RECUPERAR_PROGRAMA_DO_PACIENTE,
  RECUPEROU_PROGRAMA_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE,
} from '../tipos'

const adicionouProgramaDoPaciente = programa => ({
  type: ADICIONOU_PROGRAMA_DO_PACIENTE,
  programa,
})

const erroAoAdicionarProgramaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE,
  erro,
  parametros,
})

export const adicionarProgramaDoPaciente = (identificador, programa) => async dispatch => {
  dispatch({ type: ADICIONAR_PROGRAMA_DO_PACIENTE })

  try {    
    const resultado = await api.adicionarProgramaDoPaciente(identificador, programa)
    
    dispatch(adicionouProgramaDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarProgramaDoPaciente(erro, { identificador, programa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar programa do paciente.')))

    return false
  }
}

const alterouProgramaDoPaciente = programaDoPaciente => ({
  type: ALTEROU_PROGRAMA_DO_PACIENTE,
  programaDoPaciente
})

const erroAoAlterarProgramaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE,
  erro,
  parametros,
})

export const alterarProgramaDoPaciente = (identificadorDoPaciente, programaDoPaciente) => async dispatch => {
  dispatch({ type: ALTERAR_PROGRAMA_DO_PACIENTE })

  try {    
    const resultado = await api.alterarProgramaDoPaciente(identificadorDoPaciente, programaDoPaciente)
    
    dispatch(alterouProgramaDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarProgramaDoPaciente(erro, { identificadorDoPaciente, programaDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o programa do paciente.')))

    return false
  }
}

const listouProgramasDoPaciente = (programas, identificadorDoPaciente) => ({
  identificadorDoPaciente,
  type: LISTOU_PROGRAMAS_DO_PACIENTE,
  programas,
})

const erroAoListarProgramasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE,
  erro,
  parametros,
})

export const listarProgramasDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_PACIENTE })

  try {
    const resultado = await api.listarProgramasDoPaciente(identificadorDoPaciente)

    dispatch(listouProgramasDoPaciente(resultado.data.dados.dados, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar os programas do paciente.')))
  }
}

const removeuProgramaDoPaciente = identificador => ({
  type: REMOVEU_PROGRAMA_DO_PACIENTE,
  identificador,
})

const erroAoRemoverProgramaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE,
  erro,
  parametros,
})

export const removerProgramaDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: REMOVER_PROGRAMA_DO_PACIENTE })

  try {    
    const resultado = await api.removerProgramaDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(removeuProgramaDoPaciente(identificadorDoPaciente, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverProgramaDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o programa do paciente.')))

    return false
  }
}

const recuperouProgramaDoPaciente = programa => ({
  type: RECUPEROU_PROGRAMA_DO_PACIENTE, 
  programa,
})

const erroAoRecuperarProgramaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE,
  erro,
  parametros,
})

export const recuperarProgramaDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_PROGRAMA_DO_PACIENTE })
  try {    
    const resultado = await api.recuperarProgramaDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(recuperouProgramaDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarProgramaDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar programa do paciente.')))
  }
}