import {
    ADICIONOU_CELULA,
    RECUPEROU_CELULA_PELO_IDENTIFICADOR,
    ALTEROU_CELULA,
    REMOVEU_CELULA,
    LISTOU_CELULAS_DO_CADASTRO,
} from '../acoes/tipos'

const estadoInicial = {
    filtros: {
        nome: ''
    },
    itemAtual: null,
    ordenacao: { nome: 'nome', ordem: '+' },
    paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
    switch (action.type) {
        case ADICIONOU_CELULA: {
            return {
                ...state,
                itemAtual: null
            }
        }

        case RECUPEROU_CELULA_PELO_IDENTIFICADOR: {
            return {
                ...state,
                itemAtual: action.celula
            }
        }

        case ALTEROU_CELULA: {
            return {
                ...state,
                itemAtual: null
            }
        }

        case LISTOU_CELULAS_DO_CADASTRO: {
            const { filtros, ordenacao, paginaDeDados } = action

            return {
                ...state,
                filtros,
                itemAtual: null,
                ordenacao,
                paginaDeDados
            }
        }

        case REMOVEU_CELULA: {
            return {
                ...state,
                itemAtual: null
            }
        }

        default: {
            return state
        }
    }
}