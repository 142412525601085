import { LISTOU_CIDS10 } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_CIDS10: {
      return action.cids10
    }

    default: {
      return state
    }
  }
}