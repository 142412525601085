import api from '../../api-major'

const listarEventosDaTimelineDoPaciente = (identificadorDoPaciente, filtros) => {
  const parametroInicio = filtros?.inicio || ''
  const parametroFim = filtros?.fim || ''
  const parametroProfissoes = filtros?.profissoes && filtros.profissoes.length > 0 ? filtros.profissoes.join(',') : ''
  const parametroTiposDeAtendimento = filtros?.tiposDeAtendimento && filtros.tiposDeAtendimento.length > 0 ? filtros.tiposDeAtendimento.join(',') : ''
  const parametroVacinas = filtros?.vacinas && filtros.vacinas.length > 0 ? filtros.vacinas.join(',') : ''
  const parametroMotivosDeIdaAoProntoAtendimento = filtros?.motivosDeIdaAoProntoAtendimento && filtros.motivosDeIdaAoProntoAtendimento.length > 0 ? filtros.motivosDeIdaAoProntoAtendimento.join(',') : ''
  const parametroMotivosDeInternacao = filtros?.motivosDeInternacao && filtros.motivosDeInternacao.length > 0 ? filtros.motivosDeInternacao.join(',') : ''

  return api.get(`/pacientes/${identificadorDoPaciente}/eventos-da-timeline?inicio=${parametroInicio}&fim=${parametroFim}&profissoes=${parametroProfissoes}&tiposDeAtendimento=${parametroTiposDeAtendimento}&vacinas=${parametroVacinas}&motivosDeIdaAoProntoAtendimento=${parametroMotivosDeIdaAoProntoAtendimento}&motivosDeInternacao=${parametroMotivosDeInternacao}`)
}
export default {
  listarEventosDaTimelineDoPaciente
}