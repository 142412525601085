import { LISTOU_GRAUS_DE_RELACOES } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_GRAUS_DE_RELACOES: {
      return action.grausDeRelacoes      
    }    

    default: {
      return state
    }
  }
}
