import api from '../../api-major'

const adicionarInternacaoDoPaciente = (identificador, internacao) => api.post(`/pacientes/${identificador}/internacoes`, internacao)
const alterarInternacaoDoPaciente = (identificador, internacao) => api.put(`/pacientes/${identificador}/internacoes/${internacao.identificador}`, internacao)
const removerInternacaoDoPaciente = (identificador, identificadorDaInternacao) => api.delete(`/pacientes/${identificador}/internacoes/${identificadorDaInternacao}`)
const listarInternacoesDoPaciente = identificador => api.get(`/pacientes/${identificador}/internacoes`)

export default { 
  adicionarInternacaoDoPaciente,
  alterarInternacaoDoPaciente,
  removerInternacaoDoPaciente,
  listarInternacoesDoPaciente
}