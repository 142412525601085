import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS,
} from '../tipos'

const definiuSetorResponsavelDaTarefa = (identificadorDaTarefa, setor) => ({
  identificadorDaTarefa,
  setor,
  type: DEFINIU_SETOR_RESPONSAVEL_DA_TAREFA,
})

const erroAoDefinirSetorResponsavelDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  erro,
  parametros,
})

export const definirSetorResponsavelDaTarefa = (identificadorDaTarefa, setor) => async dispatch => {
  dispatch({ type: DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA })

  try {
    const resultado = await api.definirSetorResponsavelDaTarefa(identificadorDaTarefa, setor)

    dispatch(definiuSetorResponsavelDaTarefa(identificadorDaTarefa, setor))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirSetorResponsavelDaTarefa(erro, { identificadorDaTarefa, setor }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o responsável da tarefa.')))

    return false
  }
}

const listouSetoresResponsaveisDoTarefas = setores => ({
  type: LISTOU_SETORES_RESPONSAVEIS_DO_TAREFAS,
  setores
})

const erroAoListarSetoresResponsaveisDoTarefas = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  erro,
  parametros
})

export const listarSetoresResponsaveisDoTarefas = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS })

    try {
      const retorno = await api.listarSetoresDoTarefas(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouSetoresResponsaveisDoTarefas(retorno.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarSetoresResponsaveisDoTarefas(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os setores responsáveis do tarefas.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
    }
  }

  return resultado
}