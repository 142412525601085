import React from 'react'
import moment from 'moment'
import '../style.css'

export default function NotificacaoPacienteSaiuDoProgramaComAgendamento(props) {
  const { fechar, notificacao, history } = props
  const { metaDados } = notificacao
  const { nomePaciente, inicio, fim, quantidade } = metaDados

  const sim = () => {
    const inicioDaPesquisa = moment(inicio).format('YYYY-MM-DD')
    const fimDaPesquisa = moment(fim).format('YYYY-MM-DD')

    history.push(`/agendamentos/pesquisa-de-agendamentos?nomePaciente=${nomePaciente}&inicio=${inicioDaPesquisa}&fim=${fimDaPesquisa}`)
    fechar()
  }

  const mensagem = quantidade === 1 ?
    `O paciente ${nomePaciente} saiu do programa mas possui 1 agendamento futuro.` :
    `O paciente ${nomePaciente} saiu do programa mas possui ${metaDados.quantidade} agendamentos futuros.`


  return (
    <div>
      <p>{mensagem}</p>
      <p><strong>Favor contactar o paciente e cancelar os agendamentos.</strong></p>
      <div>
        <br />
        <button type='button' className='button button-light-select-horario-agendamento' onClick={sim}>Visualizar os agendamentos</button>
      </div>
    </div>
  )
}