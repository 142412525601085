import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  LISTOU_RECORRENCIAS_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO
} from '../tipos'

const listouRecorrenciasDoAgendamento = recorrenciasDoAgendamento => ({
  type: LISTOU_RECORRENCIAS_DO_AGENDAMENTO,
  recorrenciasDoAgendamento
})

const erroAoListarRecorrenciasDoAgendamento = erro => ({
  type: ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  erro
})

export const listarRecorrenciasDoAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_RECORRENCIAS_DO_AGENDAMENTO })

  try {
    const resultado = await api.listarRecorrenciasDoAgendamento()

    dispatch(listouRecorrenciasDoAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRecorrenciasDoAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as recorrências do agendamento.')))
  }
}