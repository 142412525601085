import {
  LISTOU_GRUPOS_DE_CUIDADO,
  ADICIONOU_GRUPO_DE_CUIDADO,
  RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTEROU_GRUPO_DE_CUIDADO,
  REMOVEU_GRUPO_DE_CUIDADO,
  LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: '',
    ativo: ['Sim']
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_GRUPO_DE_CUIDADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.grupoDeCuidado
      }
    }

    case ALTEROU_GRUPO_DE_CUIDADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_GRUPOS_DE_CUIDADO: {
      return {
        ...state,
        itens: action.gruposDeCuidado
      }
    }

    case LISTOU_GRUPOS_DE_CUIDADO_DO_CADASTRO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_GRUPO_DE_CUIDADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}