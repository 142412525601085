import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { removerTrema } from '../../bibliotecas/texto'
import { adicionarNotificacoesDaApi, adicionarNotificacao } from '../notificacoes'

import {
  ADICIONAR_PACIENTE,
  ADICIONOU_PACIENTE,
  ERRO_AO_ADICIONAR_PACIENTE,
  ERRO_AO_LISTAR_PACIENTES,
  ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  ERRO_AO_SALVAR_PACIENTE,
  LISTAR_PACIENTES,
  LISTOU_PACIENTES,
  RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP,
  RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  RECUPEROU_PACIENTE_POR_IDENTIFICADOR,
  SALVAR_PACIENTE,
  SALVOU_PACIENTE,
  RECUPEROU_PACIENTE_POR_IDENTIFICADOR_PUBLICO,
  ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO,
  RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO,
} from '../tipos'

const recuperouEnderecoDoPacientePorCep = (endereco, cep) => async dispatch => {
  dispatch({ type: RECUPEROU_ENDERECO_DO_PACIENTE_POR_CEP, endereco, cep })
}

const erroAoRecuperarEnderecoDoPacientePorCep = (erro, parametros) => async dispatch => {
  dispatch({ type: ERRO_AO_RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP, erro, parametros })
}

export const recuperarEnderecoDoPacientePorCep = cep => async dispatch => {
  dispatch({ type: RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP })

  try {
    const resultado = await api.recuperarEnderecoPorCep(cep)

    if (resultado.data.erro) {
      dispatch(adicionarNotificacao(notificacao.deErro('Não foi possível recuperar o endereço pelo CEP.')))
    }

    const localidadeSemTrema = removerTrema(resultado.data.localidade)

    dispatch(recuperouEnderecoDoPacientePorCep({ ...resultado.data, localidade: localidadeSemTrema }, cep))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return { ...resultado.data, localidade: localidadeSemTrema }
  } catch (erro) {
    dispatch(erroAoRecuperarEnderecoDoPacientePorCep(erro, { cep }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o endereço pelo CEP.')))

    return null
  }
}

export const adicionarPaciente = paciente => async dispatch => {
  dispatch({ type: ADICIONAR_PACIENTE })

  try {
    const resultado = await api.adicionarPaciente(paciente)
    dispatch(adicionouPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarPaciente(erro, { paciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do paciente.')))

    return null
  }
}

const adicionouPaciente = paciente => ({
  type: ADICIONOU_PACIENTE,
  paciente
})

const erroAoAdicionarPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PACIENTE,
  erro,
  parametros,
})

const recuperouPacientePorIdentificador = paciente => async dispatch => {
  dispatch({ type: RECUPEROU_PACIENTE_POR_IDENTIFICADOR, paciente })
}

const erroAoRecuperarPacientePorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  erro,
  parametros,
})

export const recuperarPacientePorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PACIENTE_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarPacientePorIdentificador(identificador)

    dispatch(recuperouPacientePorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoRecuperarPacientePorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar paciente por identificador.')))
  }
}

const salvouPaciente = paciente => ({
  type: SALVOU_PACIENTE,
  paciente,
})

const erroAoSalvarPaciente = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_PACIENTE,
  erro,
  parametros,
})

export const salvarPaciente = paciente => async dispatch => {
  dispatch({ type: SALVAR_PACIENTE })

  try {
    const resultado = await api.salvarPaciente(paciente)

    dispatch(salvouPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoSalvarPaciente(erro, { paciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o paciente.')))

    return null
  }
}

const listouPacientes = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_PACIENTES,
  filtros,
  ordenacao,
  paginaDeDados,
})

const erroAoListarPacientes = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES,
  erro,
  parametros,
})

export const listarPacientes = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_PACIENTES })

  try {
    const resultado = await api.listarPacientes(filtros, pagina, ordenacao)

    dispatch(listouPacientes(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPacientes(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes.')))
  }
}

const recuperouPacientePorIdentificadorPublico = paciente => async dispatch => {
  dispatch({ type: RECUPEROU_PACIENTE_POR_IDENTIFICADOR_PUBLICO, paciente })
}

const erroAoRecuperarPacientePorIdentificadorPublico = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO,
  erro,
  parametros,
})

export const recuperarPacientePorIdentificadorPublico = identificadorPublico => async dispatch => {
  dispatch({ type: RECUPERAR_PACIENTE_POR_IDENTIFICADOR_PUBLICO })

  try {
    const resultado = await api.recuperarPacientePorIdentificadorPublico(identificadorPublico)

    dispatch(recuperouPacientePorIdentificadorPublico(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados
  } catch (erro) {
    dispatch(erroAoRecuperarPacientePorIdentificadorPublico(erro, { identificadorPublico }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar paciente por identificador.')))
  }
}