import {
  FEZ_DOWNLOAD_DE_REINTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES
} from '../../acoes/tipos'

const estadoInicial = {
  programas: [],
  filtros: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES: {
      return {
        ...state,
        programas: action.programas
      }
    }

    case FEZ_DOWNLOAD_DE_REINTERNACOES: {
      return {
        ...state,
        filtros: action.filtros
      }
    }

    default: {
      return state
    }
  }
}