import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { ALTERAR_MEDICAMENTO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function AlterarMedicamento({
  alterarMedicamento,
  fechar,
  medicamentos,
  posologias,
  identificadorDoPaciente,
  medicamentoSelecionado,
  removerMedicamento,
}) {

  const {
    identificador,
    medicamento,
    concentracao,
    frequencia,
    observacoes
  } = medicamentoSelecionado

  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_DOSE_DO_MEDICAMENTO_DO_PACIENTE])
  const podeExcluir = usePossuiAsPermissoes([permissoes.REMOVER_MEDICAMENTO_CONFIRMADO_DO_PACIENTE, permissoes.REMOVER_MEDICAMENTO_DO_PACIENTE])

  const alterarMedicamentoDoPaciente = async formulario => {
    const dados = {
      frequencia: formulario.posologia,
      observacoes: formulario.observacoes,
    }

    const medicamento = medicamentos.find(x => x.exibicao === formulario.medicamento)

    dados.medicamento = medicamento.nome
    dados.concentracao = medicamento.concentracao.valor

    const alterou = await alterarMedicamento(identificadorDoPaciente, identificador, dados)

    if (alterou) {
      fechar()
    }
  }

  const medicamentosFiltrados = medicamentos.filter(m => m.nome === medicamento.nome)
  const medicamentoAtual = medicamentosFiltrados.find(p => p.concentracao.valor === concentracao.valor)?.exibicao
  const posologia = posologias.find(p => p.nome === frequencia).codigo

  return (
    <Spinner operacoes={[ALTERAR_MEDICAMENTO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          medicamento: medicamentoAtual,
          posologia: posologia,
          observacoes: observacoes
        }}
        esquemaDeValidacoes={Yup.object().shape({
          medicamento: Yup.string().required('Obrigatório.'),
          posologia: Yup.string().required('Obrigatório.')
        })}
        acao={alterarMedicamentoDoPaciente}
      >
        <fieldset>
          <h2 className='form-title'>Alterar Dose do Medicamento</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span-md='row' span-lg='row'>
              <div className='form-choice-info'>
                <div className='form-choice-info__item'>
                  <i className='icon icon-attention-circled'></i>
                </div>
                <div className='form-choice-info__item'>
                  <strong>Medicamento/Dosagem atual:</strong>{' '}{medicamento.nome} - {concentracao.valor}
                </div>
              </div>
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={12}>
              <Select
                nome='medicamento'
                titulo='Medicamento *'
                tabIndex={1}
                itens={medicamentosFiltrados}
                campoCodigo='exibicao'
                campoDescricao='exibicao'
              />
            </r-cell>
            <r-cell span={4} span-md={3} span-lg={12}>
              <Select
                nome='posologia'
                titulo='Posologia *'
                tabIndex={2}
                itens={posologias}
                campoCodigo='codigo'
                campoDescricao='nome'
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as='textarea'
                tabIndex={3}
                nome='observacoes'
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            {podeExcluir && <button onClick={() => removerMedicamento(medicamentoSelecionado)} type='button' className='button --danger minw-auto'>Excluir</button>}
            <button type='button' className='button --light minw-auto' onClick={() => fechar()}>Cancelar</button>
            {podeAlterar && <button type='submit' className='button --primary minw-auto'>Alterar</button>}
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}