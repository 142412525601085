import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function GruposDeCuidado(props) {
  const {
    adicionarGrupoDeCuidado,
    alterarGrupoDeCuidado,
    alterarStatusDoGrupoDeCuidado,
    filtros,
    grupoDeCuidado,
    history,
    listarGruposDeCuidadoDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarGrupoDeCuidadoPeloIdentificador,
    removerGrupoDeCuidado,
  } = props

  const salvar = async dados => {
    const novoRegistro = !dados.identificador
    const acao = novoRegistro ? adicionarGrupoDeCuidado : alterarGrupoDeCuidado 
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/grupos-de-cuidado`)
    }
  }

  const alterarStatus = async status => {
    const dados = {
      ...grupoDeCuidado,
      ativo: status
    }

    const alterou = await alterarStatusDoGrupoDeCuidado(dados)

    if (alterou) {
      history.push(`/cadastros/grupos-de-cuidado`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_GRUPOS_DE_CUIDADO]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarGruposDeCuidadoDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_GRUPO_DE_CUIDADO]}>
              <Formulario
                {...props}
                salvar={salvar}
                grupoDeCuidado={grupoDeCuidado}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_GRUPO_DE_CUIDADO]}>
              <Formulario
                {...props}
                alterarStatusDoGrupoDeCuidado={alterarStatus}
                grupoDeCuidado={grupoDeCuidado}
                salvar={salvar}
                recuperarPeloIdentificador={recuperarGrupoDeCuidadoPeloIdentificador}
                remover={removerGrupoDeCuidado}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}