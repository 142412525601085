import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  LISTAR_FABRICANTES,
  LISTOU_FABRICANTES,
  ERRO_AO_LISTAR_FABRICANTES,
  LISTAR_FABRICANTES_DO_CADASTRO,
  LISTOU_FABRICANTES_DO_CADASTRO,
  ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO,
  RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  ADICIONAR_FABRICANTE,
  ADICIONOU_FABRICANTE,
  ERRO_AO_ADICIONAR_FABRICANTE,
  ALTERAR_FABRICANTE,
  ALTEROU_FABRICANTE,
  ERRO_AO_ALTERAR_FABRICANTE,
  REMOVER_FABRICANTE,
  REMOVEU_FABRICANTE,
  ERRO_AO_REMOVER_FABRICANTE
} from './tipos'

const listouFabricantes = fabricante => ({
  type: LISTOU_FABRICANTES,
  fabricante
})

const erroAoListarFabricantes = erro => ({
  type: ERRO_AO_LISTAR_FABRICANTES,
  erro
})

export const listarFabricantes = () => async dispatch => {
  dispatch({ type: LISTAR_FABRICANTES })

  try {
    const resultado = await api.listarFabricantes()

    dispatch(listouFabricantes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarFabricantes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os fabricantes.')))
  }
}

const listouFabricantesDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_FABRICANTES_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarFabricantesDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO,
  erro,
  parametros
})

export const listarFabricantesDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_FABRICANTES_DO_CADASTRO })

  try {
      const resultado = await api.listarFabricantes(filtros, pagina, ordenacao, tamanhoDaPagina)

      dispatch(listouFabricantesDoCadastro(filtros, resultado.data.dados, ordenacao))
      dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
      dispatch(erroAoListarFabricantesDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os fabricantes.')))
  }
}

const adicionouFabricante = (fabricante) => ({
  type: ADICIONOU_FABRICANTE,
  fabricante
})

const erroAoAdicionarFabricante = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FABRICANTE,
  erro,
  parametros
})

export const adicionarFabricante = fabricante => async dispatch => {
  dispatch({ type: ADICIONAR_FABRICANTE })

  try {
    const resultado = await api.adicionarFabricante(fabricante)

    dispatch(adicionouFabricante(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarFabricante(erro, { fabricante }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o fabricante.')))

    return false
  }
}

const recuperouFabricantePeloIdentificador = fabricante => ({
  type: RECUPEROU_FABRICANTE_PELO_IDENTIFICADOR,
  fabricante
})

const erroAoRecuperarFabricantePeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarFabricantePeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarFabricantePeloIdentificador(identificador)

    dispatch(recuperouFabricantePeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarFabricantePeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o fabricante pelo identificador.')))
  }
}

const alterouFabricante = fabricante => ({
  type: ALTEROU_FABRICANTE,
  fabricante
})

const erroAoAlterarFabricante = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_FABRICANTE,
  erro,
  parametros
})

export const alterarFabricante = fabricante => async dispatch => {
  dispatch({ type: ALTERAR_FABRICANTE })

  try {
    const resultado = await api.alterarFabricante(fabricante)

    dispatch(alterouFabricante(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarFabricante(erro, { fabricante }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o fabricante.')))

    return false
  }
}

const removeuFabricante = fabricante => ({
  type: REMOVEU_FABRICANTE,
  fabricante
})

const erroAoRemoverFabricante = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_FABRICANTE,
  erro,
  parametros
})

export const removerFabricante = identificador => async dispatch => {
  dispatch({ type: REMOVER_FABRICANTE })

  try {
    const resultado = await api.removerFabricante(identificador)

    dispatch(removeuFabricante(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverFabricante(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o fabricante.')))

    return false
  }
}