import { LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO: {
      return action.modalidadesDosTiposDeAgendamento
    }

    default: {
      return state
    }
  }
}