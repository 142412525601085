import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO,
  LISTOU_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO,
  ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO
} from '../tipos'

const listouSituacoesAssociadasADorNoPeito = situacoesDorPeito => ({
  type: LISTOU_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO,
  situacoesDorPeito
})

const erroAoListarSituacoesAssociadasADorNoPeito = erro => ({
  type: ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO,
  erro
})

export const listarSituacoesAssociadasADorNoPeito = () => async dispatch => {
  dispatch({ type: LISTAR_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO })

  try {
    const resultado = await api.listarSituacoesAssociadasADorNoPeito()

    dispatch(listouSituacoesAssociadasADorNoPeito(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSituacoesAssociadasADorNoPeito(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as situações associadas à dor no peito.')))
  }
}