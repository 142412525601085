import api from '../api-major'
import { formatarDataParaFormatoUniversal } from '../../bibliotecas/data'

const fazerDownloadDePacientes = filtros => {
  const {
    celula,
    dataDeInicio,
    dataDeFim,
    enfermeiroDeReferencia,
    medicoDeReferencia,
    programa,
    operadora,
  } = filtros

  const inicio = formatarDataParaFormatoUniversal(dataDeInicio)
  const fim = formatarDataParaFormatoUniversal(dataDeFim)

  const parametroOperadora = operadora ? `operadora=${operadora}` : ''
  const parametroPrograma = programa ? `&programa=${programa}` : ''
  const parametroInicio = inicio ? `&inicio=${inicio}` : ''
  const parametroFim = fim ? `&fim=${fim}` : ''
  const parametroCelula = celula ? `&celula=${celula}` : ''
  const parametroMedico = medicoDeReferencia ? `&medicoDeReferencia=${medicoDeReferencia}` : ''
  const parametroEnfermeiro = enfermeiroDeReferencia ? `&enfermeiroDeReferencia=${enfermeiroDeReferencia}` : ''

  return api.get(`/relatorios/pacientes?${parametroOperadora}${parametroPrograma}${parametroInicio}${parametroFim}${parametroCelula}${parametroMedico}${parametroEnfermeiro}`, { responseType: 'arraybuffer' })
}

export default {
  fazerDownloadDePacientes,
}