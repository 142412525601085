import { connect } from 'react-redux'
import acoes from '../../acoes'
import Celulas from './index'

const mapStateToProps = state => {
    const celulaAtual = state.celulas.itemAtual

    let celula = {
        nome: '',
        observacoes: ''
    }

    if (celulaAtual) {
        celula.identificador = celulaAtual.identificador
        celula.nome = celulaAtual.nome
        celula.observacoes = celulaAtual.observacoes
    }

    return {
        filtros: state.celulas.filtros,
        ordenacao: state.celulas.ordenacao,
        paginaDeDados: state.celulas.paginaDeDados,
        celula: celula,
    }
}

export default connect(mapStateToProps, acoes)(Celulas)