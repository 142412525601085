import React, { useState, useEffect } from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, Input, Opcoes } from '../../../formik/formulario'
import { formatarHoraParaFormatoLocal, formatarDataEHoraParaFormatoUniversal } from '../../../../bibliotecas/data'
import NotificacaoQuandoOPacienteNaoPossuiProgramaAtivo from '../../agendamento/paciente-sem-programa'
import NotificacaoQuandoOPacienteJaTemAgendamentosNosProximosMeses from '../../agendamento/agendamentos-nos-proximos-meses'

import {
  ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO
} from '../../../../acoes/tipos'

export const adicionarProximosAgendamentosDoAgendamento = proximos => ({
  type: ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  proximos,
})

export const adicionarPacienteSemPrograma = semPrograma => ({
  type: IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO,
  semPrograma,
})

export default function ConfirmarAgendamento({
  adicionarAgendamento,
  convenioSelecionado,
  formatarDiaDaSemana,
  horario,
  listarRecorrenciasDoAgendamento,
  fechar,
  paciente,
  pacienteSemPrograma,
  proximosAgendamentos,
  recorrenciasDoAgendamento,
  contatos: {
    grauDeRelacaoDoTelefone1,
    grauDeRelacaoDoTelefone2,
    nomeDoTelefone1,
    nomeDoTelefone2,
    proprietarioDoTelefone1,
    proprietarioDoTelefone2,
    telefone1,
    telefone2,
    telefone1EhWhatsapp,
    telefone2EhWhatsapp,
    email
  },
  tiposDeAgendamento,
  tipoDeAgendamentoSelecionado,
  setExibirConclusaoDeAgendamento,
  unidadeDaEmpresa
}) {

  const NAO_RECORRENTE = 'nao_recorrente'
  const [recorrencia, setRecorrencia] = useState(NAO_RECORRENTE)
  const diaDaSemanaFormatado = formatarDiaDaSemana(moment(horario.inicio).format('dddd'))
  const dataFormatada = moment(horario.inicio).format('LL')
  const semConvenio = convenioSelecionado === 'particular' || convenioSelecionado === 'naoCadastrado'
  const tipoDeAgendamento = tiposDeAgendamento && tiposDeAgendamento.find(x => x.identificador === tipoDeAgendamentoSelecionado).nome

  useEffect(() => { listarRecorrenciasDoAgendamento() }, [listarRecorrenciasDoAgendamento])

  const adicionarAgendamentoDoPaciente = async formulario => {

    const dados = {
      inicio: formatarDataEHoraParaFormatoUniversal(new Date(horario.inicio)),
      fim: formatarDataEHoraParaFormatoUniversal(new Date(horario.fim)),
      identificadorDoPaciente: paciente.identificador,
      grauDeRelacaoDoTelefone1,
      grauDeRelacaoDoTelefone2,
      nomeDoTelefone1,
      nomeDoTelefone2,
      proprietarioDoTelefone1,
      proprietarioDoTelefone2,
      telefone1,
      telefone2,
      telefone1EhWhatsapp,
      telefone2EhWhatsapp,
      email,
      identificadorDoConvenio: semConvenio ? '' : convenioSelecionado,
      particular: convenioSelecionado === 'particular' ? true : false,
      identificadorDoTipo: tipoDeAgendamentoSelecionado,
      unidadeDaEmpresa: unidadeDaEmpresa.identificador,
      recorrencia: formulario.recorrencia,
      segundaFeira: formulario.diasDeAtendimento.includes('Seg') && true,
      tercaFeira: formulario.diasDeAtendimento.includes('Ter') && true,
      quartaFeira: formulario.diasDeAtendimento.includes('Qua') && true,
      quintaFeira: formulario.diasDeAtendimento.includes('Qui') && true,
      sextaFeira: formulario.diasDeAtendimento.includes('Sex') && true,
      sabado: formulario.diasDeAtendimento.includes('Sáb') && true,
      domingo: formulario.diasDeAtendimento.includes('Dom') && true,
      dataFinal: formulario.ateAData,
      numeroDeRepeticoes: formulario.repeticoes && formulario.repeticoes - 1,
      observacoes: formulario.observacoes,
      confirmarOAgendamentoQuandoOPacienteNaoPossuiProgramaAtivo: !!pacienteSemPrograma,
      confirmarOAgendamentoQuandoOPacienteJaTemAgendamentosNosProximosMeses: !!proximosAgendamentos
    }    

    const adicionou = await adicionarAgendamento(horario.profissional.identificador, dados)

    if (adicionou) {
      fechar()
      setExibirConclusaoDeAgendamento(true)
    }
  }

  const element = document.getElementById('lista-botoes')

  useEffect(() => {
    if (proximosAgendamentos && element) {
      element.scrollIntoView(false)
    }

  }, [proximosAgendamentos, element])

  useEffect(() => {
    if (pacienteSemPrograma && element) {
      element.scrollIntoView(false)
    }

  }, [pacienteSemPrograma, element])


  return (
    <Spinner operacoes={[ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO, LISTAR_RECORRENCIAS_DO_AGENDAMENTO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          diasDeAtendimento: [],
          recorrencia: recorrencia,
          repeticoes: '',
          ateAData: '',
          observacoes: '',
        }}
        esquemaDeValidacoes={Yup.object().shape({
          recorrencia: Yup.string().required('Obrigatório.'),
          repeticoes: Yup.number().when(["recorrencia", "ateAData"], {
            is: (recorrencia, ateAData) => recorrencia !== NAO_RECORRENTE && !ateAData,
            then: Yup.number()
              .typeError('Dever ser numérico')
              .positive('Deve ser positivo')
              .integer('Deve ser inteiro').required('Obrigatório.')
          }),
          ateAData: Yup.string().when(["recorrencia", "repeticoes"], {
            is: (recorrencia, repeticoes) => recorrencia !== NAO_RECORRENTE && !repeticoes,
            then: Yup.string().required('Obrigatório.')
          }),
          diasDeAtendimento: Yup.string()
            .when("recorrencia", {
              is: val => val === 'semanal',
              then: Yup.string().required('Obrigatório.')
            }),
        }, [['recorrencia', 'ateAData'], ['recorrencia', 'repeticoes'], ['ateAData', 'repeticoes']])}
        acao={adicionarAgendamentoDoPaciente}
      >

        {({ values }) => (
          <fieldset>
            <h2 className='form-title'>Confirmar Agendamento</h2>
            <r-grid columns-md={6} columns-lg={12}>
              <r-cell span={4} span-md={6} span-lg={12} class='form-confirmar-agendamento'>
                <div className='form-item__choice-passo-3 pd-ajuste top'><i className='icon icon-calendar-empty'></i><strong>{diaDaSemanaFormatado}{', '}{dataFormatada}</strong></div>
                <div className='form-item__choice-passo-3 pd-ajuste bottom'><i className='icon icon-clock'></i><strong>{formatarHoraParaFormatoLocal(horario.inicio)} às {formatarHoraParaFormatoLocal(horario.fim)}</strong></div>
              </r-cell>
              <r-cell span={4} span-md={6} span-lg={12} class='form-confirmar-agendamento'>
                <div className='form-item__choice-passo-3 pd-ajuste top'><strong>Unidade:</strong>{' '}{horario.unidade.nome}</div>
                <div className='form-item__choice-passo-3 pd-ajuste'><strong>Tipo de Agendamento:</strong>{' '}{tipoDeAgendamento}</div>
                <div className='form-item__choice-passo-3 pd-ajuste bottom'><strong>Profissional:</strong>{' '}{horario.profissional.tratamento ? horario.profissional.tratamento.concat(' ', horario.profissional.nome) : horario.profissional.nome}</div>
              </r-cell>
              <r-cell span={4} span-md={6} span-lg={4}>
                <Select
                  nome='recorrencia'
                  titulo='Recorrência *'
                  itens={recorrenciasDoAgendamento}
                  campoCodigo='codigo'
                  campoDescricao='nome'
                  tabIndex={2}
                  onChange={setRecorrencia}
                />
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={4}>
                <DatePicker
                  nome='ateAData'
                  tabIndex={3}
                  titulo='Até a Data *'
                  minDate={(new Date(horario.inicio))}
                  isDisabled={values.recorrencia === 'nao_recorrente' || values.repeticoes}
                />
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={4}>
                <Input
                  nome='repeticoes'
                  tabIndex={4}
                  titulo='Repetições *'
                  disabled={values.recorrencia === 'nao_recorrente' || values.ateAData}
                />
              </r-cell>
              {values.recorrencia === 'semanal' &&
                <r-cell span={4} span-md={6} span-lg={12}>
                  <Opcoes
                    titulo='Selecione os Dias de Atendimento'
                    nome='diasDeAtendimento'
                    opcoes={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                    tabIndex={5}
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={6}
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <NotificacaoQuandoOPacienteNaoPossuiProgramaAtivo pacienteSemPrograma={pacienteSemPrograma} />
            <NotificacaoQuandoOPacienteJaTemAgendamentosNosProximosMeses proximosAgendamentos={proximosAgendamentos} />
            <div className='list-btn' id='lista-botoes'>
              <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
              <button type='submit' className='button --primary'>{(proximosAgendamentos || pacienteSemPrograma) ? 'Confirmar Novo Agendamento' : 'Confirmar Agendamento'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}