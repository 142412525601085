import {
  LISTOU_RESPOSTAS_VALIDAS_SOBRE_DIARREIA
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_RESPOSTAS_VALIDAS_SOBRE_DIARREIA: {
      return action.respostasDiarreia
    }

    default: {
      return state
    }
  }
}
