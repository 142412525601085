import api from '../api-major'

const fazerDownloadDoAnexoDoAtendimento = (identificadorDoPaciente, identificadorDoAtendimento, identificador) => {
  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/anexos/${identificador}`, {
    responseType: 'arraybuffer'
  })
}

export default {
  fazerDownloadDoAnexoDoAtendimento
}