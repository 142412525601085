import React from 'react'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, InputFoto, Telefone } from '../formik/formulario'
import Spinner from '../spinner'

import {
  RECUPERAR_EMPRESA,
  SALVAR_EMPRESA,
  RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  ADICIONAR_FOTO_DA_EMPRESA
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    adicionarFotoDaEmpresa,
    bytesDaFoto,
    dadosDaEmpresa,
    removerFotoDaEmpresa,
    salvar
  } = props

  const permissaoParaAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_EMPRESA_DO_USUARIO])

  return (
    <Spinner operacoes={[RECUPERAR_FOTO_DA_EMPRESA_LOGADA, ADICIONAR_FOTO_DA_EMPRESA]}>
      {({ processando }) => (
        <Spinner operacoes={[RECUPERAR_EMPRESA, SALVAR_EMPRESA]}>
          <FormularioDoFormik
            acao={salvar}
            reinicializar={true}
            valoresIniciais={{
              identificador: dadosDaEmpresa.identificador,
              nome: dadosDaEmpresa.nome || '',
              nomeReduzido: dadosDaEmpresa.nomeReduzido || '',
              nomeDoResponsavel: dadosDaEmpresa.nomeDoResponsavel || '',
              emailDoResponsavel: dadosDaEmpresa.emailDoResponsavel || '',
              telefone: dadosDaEmpresa.telefone || '',
              observacoes: dadosDaEmpresa.observacoes || '',
              logotipo: dadosDaEmpresa.logotipo || ''
            }}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              nomeReduzido: Yup.string().required('Obrigatório'),
              nomeDoResponsavel: Yup.string().required('Obrigatório'),
              emailDoResponsavel: Yup.string()
                .required('Obrigatório')
                .ehUmEmailValido('E-mail inválido'),
              telefone: Yup.string()
                .required('Obrigatório')
                .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-99999')
            })}
          >
            <fieldset className='mt-1'>
              <h2 className='form-title'>Dados da Empresa</h2>
              <div className='with-thumb'>
                <div className='with-thumb-sidebar'>
                  <InputFoto
                    habilitarFuncoes={true}
                    bytes={bytesDaFoto}
                    nome='logotipo'
                    selecionar={logotipo => logotipo && adicionarFotoDaEmpresa(logotipo)}
                    remover={() => dadosDaEmpresa.identificador && removerFotoDaEmpresa(dadosDaEmpresa.identificador)}
                    carregandoFoto={processando}
                  />
                </div>
                <r-grid columns-md={12} columns-lg={12}>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Input
                      type='text'
                      nome='nome'
                      tabIndex={1}
                      titulo='Nome *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Input
                      nome='nomeReduzido'
                      tabIndex={2}
                      titulo='Nome reduzido *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Input
                      nome='nomeDoResponsavel'
                      tabIndex={3}
                      titulo='Nome do responsável *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={6} span-lg={6}>
                    <Input
                      nome='emailDoResponsavel'
                      tabIndex={4}
                      titulo='E-mail do Responsável *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={3} span-lg={3}>
                    <Telefone
                      nome='telefone'
                      tabIndex={5}
                      titulo='Telefone *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={12} span-lg={12}>
                    <Input
                      as='textarea'
                      nome='observacoes'
                      tabIndex={6}
                      titulo='Observações'
                    />
                  </r-cell>
                </r-grid>
              </div>
            </fieldset>
            <div className='list-btn'>
              {permissaoParaAlterar && <button type='submit' className='button --primary'>Salvar alterações</button>}
            </div>
          </FormularioDoFormik>
        </Spinner>
      )}
    </Spinner>
  )
}