import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  CRIAR_TAREFA,
  CRIAR_TAREFA_DO_PACIENTE,
  CRIOU_TAREFA,
  CRIOU_TAREFA_DO_PACIENTE,
  ERRO_AO_CRIAR_TAREFA,
  ERRO_AO_CRIAR_TAREFA_DO_PACIENTE,
  LIMPAR_TAREFA_ATUAL,
} from '../tipos'

const erroAoCriarTarefa = (erro, parametros) => ({
  type: ERRO_AO_CRIAR_TAREFA,
  erro,
  parametros,
})

export const criarTarefa = tarefa => async dispatch => {
  dispatch({ type: CRIAR_TAREFA })

  try {
    const resultado = await api.criarTarefa({ identificador: tarefa.identificador, tipo: tarefa.tipo.identificador })

    dispatch({ type: CRIOU_TAREFA })
    dispatch(adicionarNotificacoesDaApi(resultado))

    return tarefa.identificador
  } catch (erro) {
    dispatch(erroAoCriarTarefa(erro, { tarefa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao criar a tarefa.')))

    return false
  }
}

const erroAoCriarTarefaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_CRIAR_TAREFA_DO_PACIENTE,
  erro,
  parametros,
})

export const criarTarefaDoPaciente = (identificadorDoPaciente, tarefa) => async dispatch => {
  dispatch({ type: CRIAR_TAREFA_DO_PACIENTE })

  try {
    const resultado = await api.criarTarefaDoPaciente(identificadorDoPaciente, { identificador: tarefa.identificador, tipo: tarefa.tipo.identificador })

    dispatch({ type: CRIOU_TAREFA_DO_PACIENTE })
    dispatch(adicionarNotificacoesDaApi(resultado))

    return tarefa.identificador
  } catch (erro) {
    dispatch(erroAoCriarTarefaDoPaciente(erro, { tarefa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao criar a tarefa do paciente.')))

    return false
  }
}

export const limparTarefaAtual = () => ({
  type: LIMPAR_TAREFA_ATUAL,
})