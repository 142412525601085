import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import LacunaWebPKI from 'web-pki'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import { Select } from '../../formik/formulario'
import Spinner from '../../spinner'
import constantes from '../../../configuracoes/constantes'
import Assinado from './assinado'

import {
  assinarAtendimentoComCertificadoFisico,
  erroAoLerCertificadosDigitaisFisicos,
  finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico,
  iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico,
  lerCertificadosDigitaisFisicos,
  leuCertificadosDigitaisFisicos,
  selecionarCertificadoDigital,
} from '../../../acoes/lacuna'

import { FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO, LER_CERTIFICADOS_DIGITAIS_FISICOS } from '../../../acoes/tipos'

export default function AssinaturaDigital({ fecharModal }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { lacuna } = useSelector(state => state.prontuario)
  const [etapas, setEtapas] = useState(null)
  const [exibirInstalarPlugin, setExibirInstalarPlugin] = useState(false)

  const certificadosParaSelecao = useMemo(() => {
    if (lacuna && lacuna.certificados) {
      return lacuna.certificados.map(x => ({ codigo: x.subjectName, nome: x.subjectName + ' - ' + x.issuerName }))
    }
  }, [lacuna])

  useEffect(() => { dispatch(lerCertificadosDigitaisFisicos()) }, [dispatch])

  var pki = new LacunaWebPKI(constantes.licensaDaLacuna)

  const webPkiPronta = useCallback(() => {
    pki.listCertificates().success(function (certificates) {
      dispatch(leuCertificadosDigitaisFisicos(certificates))
    })
  }, [dispatch, pki])

  const instalarPlugin = () => {
    history.push({
      pathname: `/atendimentos/nao-assinados`,
      state: { instalarPlugin: true }
    })
  }

  const webPkiNaoInstalada = useCallback((status, message) => {
    dispatch(leuCertificadosDigitaisFisicos([]))
    setExibirInstalarPlugin(true)
  }, [dispatch])

  const webPkiErro = useCallback((ex) => {
    setEtapas('')
    dispatch(erroAoLerCertificadosDigitaisFisicos(ex.message))
  }, [dispatch])

  const start = useCallback(() => {
    pki.init({
      ready: webPkiPronta,
      notInstalled: webPkiNaoInstalada,
      defaultFail: webPkiErro
    })
  }, [webPkiPronta, webPkiErro, webPkiNaoInstalada, pki])

  useEffect(() => {
    if (lacuna.lerCertificados) {
      dispatch(lerCertificadosDigitaisFisicos())
      start()
    }
  }, [dispatch, lacuna, start])

  const assinar = async ({ certificado }) => {
    setEtapas('Iniciando assinatura ...')

    const certificadoSelecionado = lacuna.certificados.find(c => c.subjectName === certificado)
    dispatch(selecionarCertificadoDigital(certificadoSelecionado))

    pki.readCertificate(certificadoSelecionado).success(iniciarAssinaturaDigital)
  }

  const iniciarAssinaturaDigital = async (certEncoding) => {
    const iniciou = await dispatch(iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico(lacuna.identificadores.paciente, lacuna.identificadores.atendimento, certEncoding))

    if (iniciou) {
      setEtapas('Assinando, por favor aguarde ...')
      dispatch(assinarAtendimentoComCertificadoFisico(certEncoding))
    } else {
      setEtapas()
    }
  }

  const assinaturaDoAtendimentoFinalizada = useCallback((signature) => {
    (async () => {
      await dispatch(finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico(lacuna.identificadores.atendimento, lacuna.identificadores.arquivo, lacuna.itemAtual.certEncoding, signature))
      setEtapas(null)
    })()
  }, [dispatch, lacuna])

  useEffect(() => {
    if (lacuna.assinar) {
      dispatch(assinarAtendimentoComCertificadoFisico())
      setEtapas('Favor informar a senha.')

      pki.signData({
        thumbprint: lacuna.itemAtual.thumbprint,
        data: lacuna.dadosParaAssinar,
        digestAlgorithm: lacuna.algoritmo
      }).success(assinaturaDoAtendimentoFinalizada).fail(webPkiErro)
    }
  }, [lacuna, pki, dispatch, assinaturaDoAtendimentoFinalizada, webPkiErro])

  return (
    <div className={`box-agenda resumo`}>
      <div className='form mt-2 resumo-do-atendimento'>
        <div className='assinatura-digital-atendimento'>
          <h2 className='form-title'>{lacuna.finalizado ? 'Atendimento Assinado com Certificado Físico' : 'Assinar com Certificado Físico'}</h2>
          <hr className='separator mt-1'></hr>
          <Spinner operacoes={[LER_CERTIFICADOS_DIGITAIS_FISICOS]}>
            {({ processando }) => {
              if (processando) {
                return (
                  <h2 className='verificando-permissoes text-black-50'>Inicializando componente...</h2>
                )

              } else {
                return (
                  <Spinner operacoes={[FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO]}>
                    {({ processando }) => {
                      if (processando) {
                        return (
                          <h2 className='verificando-permissoes text-black-50'>Assinando...</h2>
                        )
                      } else if (lacuna.finalizado) {
                        return <Assinado lacuna={lacuna} fecharModal={fecharModal} />

                      } else {
                        return (
                          <FormularioDoFormik
                            reinicializar={true}
                            valoresIniciais={{
                              certificado: lacuna?.itemAtual?.subjectName
                            }}
                            acao={assinar}
                            esquemaDeValidacoes={Yup.object().shape({
                              certificado: Yup.string().required('Obrigatório')
                            })}
                          >
                            {etapas &&
                              <h2 className='verificando-permissoes text-black-50'>{etapas}</h2>
                            }
                            {!etapas && !exibirInstalarPlugin &&
                              <fieldset>
                                {certificadosParaSelecao && certificadosParaSelecao.length === 0 &&
                                  <>
                                    <div className='form-choice-alerta'>
                                      <div className='form-choice-alerta__item'>
                                        <i className='icon icon-attention-circled'></i>
                                      </div>
                                      <div className='form-choice-alerta__item'>
                                        Nenhum certificado físico instalado atualmente nesta máquina.
                                      </div>
                                    </div>
                                    <div className='list-btn'>
                                      <button className='button --light' onClick={fecharModal}>Fechar</button>
                                    </div>
                                  </>
                                }
                                {certificadosParaSelecao && certificadosParaSelecao.length > 0 &&
                                  <>
                                    <Select
                                      nome='certificado'
                                      campoCodigo='codigo'
                                      campoDescricao='nome'
                                      itens={certificadosParaSelecao}
                                      titulo='Certificado *'
                                    />
                                    <div className='list-btn'>
                                      <button className='button --light' onClick={fecharModal}>Fechar</button>
                                      <button className='button --primary' type='submit'>Assinar</button>
                                    </div>
                                  </>
                                }
                              </fieldset>
                            }
                            {exibirInstalarPlugin &&
                              <fieldset>
                                <div className='form-choice-alerta'>
                                  <div className='form-choice-alerta__item'>
                                    <i className='icon icon-attention-circled'></i>
                                  </div>
                                  <div className='form-choice-alerta__item'>
                                    <ul>
                                      <li>É necessário instalar o Plugin da assinatura digital neste navegador.</li>
                                      <li>Após o término da instalação, você será redirecionado de volta.</li>
                                      <li>O seu atendimento está finalizado.</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='list-btn'>
                                  <button className='button --light' onClick={fecharModal}>Fechar</button>
                                  <button className='button --primary' onClick={instalarPlugin}>Instalar Plugin</button>
                                </div>
                              </fieldset>
                            }
                          </FormularioDoFormik>
                        )
                      }
                    }}
                  </Spinner>
                )
              }
            }}
          </Spinner>
        </div>
      </div>
    </div>
  )
}