import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import moment from 'moment'
import { setMinutes, setHours } from 'date-fns'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import { Formulario as Form, DatePicker, Select, Input, HourPicker, Opcoes, CampoNumerico } from '../../../formik/formulario'
import { formatarHoraParaFormatoLocal, formatarDataParaFormatoUniversal, dataIgualOuAnterior } from '../../../../bibliotecas/data'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'
import ConfirmacaoDeExclusao from './confirmacao-de-exclusao'

import {
  ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
} from '../../../../acoes/tipos'

import {
  listarTiposDeRepeticaoDeSerieDaAgenda
} from '../../../../acoes/tipos-de-repeticao-de-serie-da-agenda'

const OUTRO_MOTIVO = 'outro'
const TIPO_ALTERACAO = ['Alterar somente este bloqueio', 'Alterar toda a série']
const DIAS_DE_SEMANA = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
const RECORRENCIAS = [
  { codigo: 'nao_repetir', nome: 'Não repetir' },
  { codigo: 'seg_sex', nome: 'Dia de semana (Segunda-Sexta)' },
  { codigo: 'diario', nome: 'Diário' },
  { codigo: 'semanal', nome: 'Semanal' },
]

export default function FormularioBloqueio(props) {
  const {
    adicionar,
    alterar,
    alterarSerie,
    bloqueio = null,
    fechar,
    aposFechar,
    identificadorDoProfissional,
    motivosDeBloqueio,
    remover,
    removerSerie
  } = props

  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)

  const dispatch = useDispatch()
  const tiposDeRepeticaoDeSerieDaAgenda = useSelector(state => state.tiposDeRepeticaoDeSerieDaAgenda)

  const [alteraSomenteAOcorrencia, setAlteraSomenteAOcorrencia] = useState(bloqueio ? true : false)

  useEffect(() => { dispatch(listarTiposDeRepeticaoDeSerieDaAgenda()) }, [dispatch])

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  const adicionarBloqueio = async formulario => {
    const dadosDaSerieDeBloqueio = {
      identificador: formulario.identificador,
      inicioDaSerie: formatarDataParaFormatoUniversal(formulario.inicioDaSerie),
      fimDaSerie: formulario.tipoDeRecorrencia === 'nao_repetir' ? formatarDataParaFormatoUniversal(formulario.inicioDaSerie) : formatarDataParaFormatoUniversal(formulario.fimDaSerie),
      horaInicial: formatarHoraParaFormatoLocal(formulario.horaInicial),
      horaFinal: formatarHoraParaFormatoLocal(formulario.horaFinal),
      domingo: formulario.semana.includes('Dom'),
      segundaFeira: formulario.semana.includes('Seg'),
      tercaFeira: formulario.semana.includes('Ter'),
      quartaFeira: formulario.semana.includes('Qua'),
      quintaFeira: formulario.semana.includes('Qui'),
      sextaFeira: formulario.semana.includes('Sex'),
      sabado: formulario.semana.includes('Sáb'),
      motivo: formulario.motivoDoBloqueio,
      repeteACada: formulario.repeteACada,
      tipoDeRepeticao: formulario.tipoDeRepeticao,
      observacoes: formulario.observacoes,
    }

    const acao = formulario.identificador ? alterarSerie : adicionar
    const salvou = await acao(identificadorDoProfissional, dadosDaSerieDeBloqueio)

    if (salvou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  const alterarRecorrencia = (tipoDeRecorrencia, setFieldValue) => {
    if (tipoDeRecorrencia === 'nao_repetir') {
      setFieldValue('semana', DIAS_DE_SEMANA)
      return
    }

    const semana = tipoDeRecorrencia === 'seg_sex' ? DIAS_DE_SEMANA.filter(x => x !== 'Dom' && x !== 'Sáb') :
      tipoDeRecorrencia === 'diario' ? DIAS_DE_SEMANA : [DIAS_DE_SEMANA[new Date().getDay()]]

    setFieldValue('semana', semana)
  }

  function retornaTipoDeRecorrenciaDeAcordoComSemana(semana) {
    let tipo = 'semanal'

    if (semana.length === 7) {
      tipo = 'diario'
    } else if (semana.length === 5 && !semana.some(x => x === 'Dom' || x === 'Sáb')) {
      tipo = 'seg_sex'
    }

    return tipo
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={remover}
          excluirSerie={removerSerie}
          fechar={fecharAlertaDeExclusao}
          bloqueio={bloqueio}
          motivos={motivosDeBloqueio}
        />
      </Modal>
      {alteraSomenteAOcorrencia ?
        <FormularioAlterarBloqueio
          abrirAlertaDeExclusao={abrirAlertaDeExclusao}
          setAlteraSomenteAOcorrencia={setAlteraSomenteAOcorrencia}
          bloqueio={bloqueio}
          fechar={fechar}
          aposFechar={aposFechar}
          alterar={alterar}
          identificador={identificadorDoProfissional}
          motivos={motivosDeBloqueio}
        /> :
        <Spinner operacoes={[
          ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
          ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
          ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
        ]}>
          <Form
            reinicializar={true}
            valoresIniciais={{
              identificador: bloqueio?.identificador || '',
              inicioDaSerie: bloqueio?.serie?.inicioDaSerie || '',
              fimDaSerie: bloqueio?.serie?.fimDaSerie || '',
              horaInicial: bloqueio && bloqueio.serie ? moment(bloqueio.serie.inicio, 'HH:mm:ss').toDate() : '',
              horaFinal: bloqueio && bloqueio.serie ? moment(bloqueio.serie.fim, 'HH:mm:ss').toDate() : '',
              tipoDeRecorrencia: bloqueio && bloqueio.serie && bloqueio.serie.inicioDaSerie !== bloqueio.serie.fimDaSerie ? retornaTipoDeRecorrenciaDeAcordoComSemana(construirArrayDeSemana(bloqueio.serie)) : 'nao_repetir',
              repeteACada: bloqueio?.serie?.repeteACada || 1,
              tipoDeRepeticao: tiposDeRepeticaoDeSerieDaAgenda && bloqueio && bloqueio.serie ? tiposDeRepeticaoDeSerieDaAgenda.find(x => x.nome === bloqueio.serie.tipoDeRepeticao).codigo : 'semana',
              motivoDoBloqueio: bloqueio?.serie?.motivo || '',
              observacoes: bloqueio?.serie?.observacoes || '',
              semana: bloqueio && bloqueio.serie ? construirArrayDeSemana(bloqueio.serie) : DIAS_DE_SEMANA,
              tipoDeAlteracao: TIPO_ALTERACAO[1]
            }}
            acao={adicionarBloqueio}
            esquemaDeValidacoes={Yup.object().shape({
              inicioDaSerie: Yup.string().required('Obrigatório').nullable(),
              fimDaSerie: Yup.string()
                .when("tipoDeRecorrencia", {
                  is: val => val !== 'nao_repetir',
                  then: Yup.string().required('Obrigatório')
                    .nullable()
                    .test(
                      "fim_teste",
                      "Deve ser maior que o início.",
                      function (value) {
                        const { inicioDaSerie } = this.parent;
                        return !value || dataIgualOuAnterior(inicioDaSerie, value);
                      }
                    )
                }),
              horaInicial: Yup.string().required('Obrigatório.').nullable(),
              horaFinal: Yup.string().required('Obrigatório.').test(
                "horaFim_teste",
                "Horário Final deve ser maior que Horário Inicial",
                function (value) {
                  const { horaInicial } = this.parent;
                  return !value || horaIgualOuAnterior(horaInicial, value);
                }
              ).nullable(),
              semana: Yup.string().required('Obrigatório.'),
              tipoDeRecorrencia: Yup.string().required('Obrigatório').nullable(),
              repeteACada: Yup.string().required('Obrigatório').nullable(),
              tipoDeRepeticao: Yup.string().required('Obrigatório').nullable(),
              motivoDoBloqueio: Yup.string().required('Obrigatório.'),
              observacoes: Yup.string()
                .when("motivoDoBloqueio", {
                  is: val => val === OUTRO_MOTIVO,
                  then: Yup.string().required('Quando o motivo "Outro" for informado, o campo "Observações" deve ser informado.')
                })
            })}
          >
            {({ values, setFieldValue }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{bloqueio ? 'Alterar' : 'Adicionar'} Bloqueio da Agenda</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    {bloqueio &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Opcoes
                          nome='tipoDeAlteracao'
                          opcoes={TIPO_ALTERACAO}
                          onChange={() => setAlteraSomenteAOcorrencia(true)}
                          selecaoUnica={true}
                          classname='encaminhamentos'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={6} span-lg={values.tipoDeRecorrencia === 'nao_repetir' ? 6 : 3}>
                      <DatePicker
                        nome='inicioDaSerie'
                        tabIndex={1}
                        minDate={(new Date())}
                        titulo='Início do Período *'
                      />
                    </r-cell>
                    {values.tipoDeRecorrencia !== 'nao_repetir' &&
                      <r-cell span={4} span-md={6} span-lg={3}>
                        <DatePicker
                          nome='fimDaSerie'
                          tabIndex={2}
                          isDisabled={!values.inicioDaSerie}
                          minDate={moment(values.inicioDaSerie).toDate()}
                          titulo='Fim do Período *'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <HourPicker
                        titulo='Hora Inicial *'
                        nome='horaInicial'
                        tabIndex={3}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <HourPicker
                        nome='horaFinal'
                        titulo='Hora Final *'
                        tabIndex={4}
                        isDisabled={!values.horaInicial}
                        minTime={(new Date(values.horaInicial).getTime() + 5 * 60000)}
                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Select
                        nome='tipoDeRecorrencia'
                        tabIndex={5}
                        titulo='Recorrência *'
                        isDisabled={!values.inicioDaSerie}
                        itens={RECORRENCIAS}
                        onChange={e => alterarRecorrencia(e, setFieldValue)}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    {values.tipoDeRecorrencia !== 'nao_repetir' &&
                      <>
                        <r-cell span={4} span-md={3} span-lg={3}>
                          <CampoNumerico
                            nome='repeteACada'
                            tabIndex={6}
                            titulo='Repete a cada *'
                          />
                        </r-cell>
                        <r-cell span={4} span-md={9} span-lg={9}>
                          <Select
                            titulo='.'
                            nome='tipoDeRepeticao'
                            tabIndex={7}
                            itens={tiposDeRepeticaoDeSerieDaAgenda}
                            campoCodigo='codigo'
                            campoDescricao='nome'
                          />
                        </r-cell>
                        <DiasDaSemana onChange={(e) => setFieldValue('tipoDeRecorrencia', retornaTipoDeRecorrenciaDeAcordoComSemana(e))} />
                        <ResumoDaRecorrencia {...values} />
                      </>
                    }
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Select
                        nome='motivoDoBloqueio'
                        tabIndex={8}
                        titulo='Motivo do Bloqueio *'
                        itens={motivosDeBloqueio}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={9}
                        titulo={values.motivoDoBloqueio === OUTRO_MOTIVO ? 'Observações *' : 'Observações'}
                      />
                    </r-cell>
                  </r-grid>
                  <div className='list-btn'>
                    <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
                    <button type='submit' className='button --primary'>{bloqueio ? 'Alterar' : 'Adicionar'} Série</button>
                  </div>
                </fieldset>
              </>
            )}
          </Form>
        </Spinner>
      }
    </>
  )
}

function FormularioAlterarBloqueio({
  abrirAlertaDeExclusao,
  alterar,
  aposFechar,
  bloqueio,
  fechar,
  identificador,
  motivos,
  setAlteraSomenteAOcorrencia
}) {

  const alterarBloqueio = async formulario => {
    const dadosParaAlterarBloqueio = {
      identificador: formulario.identificador,
      data: formulario.data,
      inicio: formatarHoraParaFormatoLocal(formulario.horaInicial),
      fim: formatarHoraParaFormatoLocal(formulario.horaFinal),
      motivo: formulario.motivoDoBloqueio,
      observacoes: formulario.observacoes,
    }

    const alterou = await alterar(identificador, dadosParaAlterarBloqueio)

    if (alterou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  return (
    <Spinner operacoes={[ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE, ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          identificador: bloqueio.identificador || '',
          data: bloqueio.data ? bloqueio.data : '',
          horaInicial: bloqueio.inicio ? moment(bloqueio.inicio, 'HH:mm:ss').toDate() : '',
          horaFinal: bloqueio.fim ? moment(bloqueio.fim, 'HH:mm:ss').toDate() : '',
          motivoDoBloqueio: bloqueio.motivo || '',
          observacoes: bloqueio.observacoes || '',
          tipoDeAlteracao: TIPO_ALTERACAO[0]
        }}
        acao={alterarBloqueio}
        esquemaDeValidacoes={Yup.object().shape({
          data: Yup.string().required('Obrigatório.').nullable(),
          horaInicial: Yup.string().required('Obrigatório.').nullable(),
          horaFinal: Yup.string().required('Obrigatório.')
            .nullable()
            .test(
              "horaFim_teste",
              "Deve ser maior que a hora inicial",
              function (value) {
                const { horaInicial } = this.parent;
                return !value || horaIgualOuAnterior(horaInicial, value);
              }
            ),
          motivoDoBloqueio: Yup.string().required('Obrigatório.'),
          observacoes: Yup.string()
            .when("motivoDoBloqueio", {
              is: val => val === OUTRO_MOTIVO,
              then: Yup.string().required('Quando o motivo "Outro" for informado, o campo "Observações" deve ser informado.')
            }),
        })}
      >
        {({ values }) => (
          <fieldset>
            <h2 className="form-title">Alterar Bloqueio da Agenda</h2>
            <r-grid columns-md={12} columns-lg={12}>
              {bloqueio.serie &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Opcoes
                    nome='tipoDeAlteracao'
                    opcoes={TIPO_ALTERACAO}
                    onChange={() => setAlteraSomenteAOcorrencia(false)}
                    selecaoUnica={true}
                    classname='encaminhamentos'
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={4} span-lg={3}>
                <DatePicker
                  nome='data'
                  tabIndex={1}
                  minDate={(new Date())}
                  titulo='Data *'
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaInicial'
                  titulo='Hora Inicial *'
                  tabIndex={2}
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaFinal'
                  titulo='Hora Final *'
                  tabIndex={3}
                  isDisabled={!values.horaInicial}
                  minTime={(new Date(values.horaInicial).getTime() + 5 * 60000)}
                  maxTime={(setHours(setMinutes(new Date(), 59), 23))}
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Select
                  nome='motivoDoBloqueio'
                  tabIndex={4}
                  titulo='Motivo do Bloqueio *'
                  itens={motivos}
                  campoCodigo='codigo'
                  campoDescricao='nome'
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={5}
                  titulo={values.motivoDoBloqueio === OUTRO_MOTIVO ? 'Observações *' : 'Observações'}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              {bloqueio?.identificador && <button onClick={abrirAlertaDeExclusao} type='button' className='button --danger'>Excluir</button>}
              <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
              <button type='submit' className='button --primary'>Alterar Bloqueio</button>
            </div>
          </fieldset>
        )}
      </Form>
    </Spinner>
  )
}

function DiasDaSemana({ onChange }) {
  return (
    <r-cell span={4} span-md={12} span-lg={12}>
      <Opcoes
        titulo='Selecione os dias da Semana *'
        nome='semana'
        opcoes={DIAS_DE_SEMANA}
        opcoesDesabilitadas={[]}
        onChange={onChange}
      />
    </r-cell>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}