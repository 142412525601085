import api from './api-major'

const fazerDownloadDaExameDoAtendimento = (identificadorDoPaciente, identificadorDoAtendimento, identificador) => {
  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/${identificadorDoAtendimento}/exames/${identificador}`, {
    responseType: 'arraybuffer'
  })
}

const listarTiposDeExame = () => api.get('/tipos-de-exame?tamanhoDaPagina=100000&ordem=+nome')

export default {
  fazerDownloadDaExameDoAtendimento,
  listarTiposDeExame
}