import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ANTIBIOTICOS,
  LISTOU_ANTIBIOTICOS,
  ERRO_AO_LISTAR_ANTIBIOTICOS,
} from './tipos'

const listouAntibioticos = antibioticos => ({
  type: LISTOU_ANTIBIOTICOS,
  antibioticos
})

const erroAoListarAntibioticos = erro => ({
  type: ERRO_AO_LISTAR_ANTIBIOTICOS,
  erro
})

export const listarAntibioticos = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_ANTIBIOTICOS })

    try {
      const resultado = await api.listarAntibioticos()

      dispatch(listouAntibioticos(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarAntibioticos(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os antibióticos.')))
    }
  }

  acao.meta = {
    cache: LISTAR_ANTIBIOTICOS,
  }

  return acao
}