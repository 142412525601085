import api from '../api-narcissus'

const listarAvaliacoesRespondidas = (filtros = {}, pagina) => {
  const paginacao = pagina ? `pagina=${pagina}` : 'pagina=1'
  const parametroTamanhoDaPagina = `&tamanhoDaPagina=20`
  const parametroInicio = filtros && filtros.inicio ? `&inicio=${filtros.inicio}` : ''
  const parametroFim = filtros && filtros.fim ? `&fim=${filtros.fim}` : ''
  const parametroPaciente = filtros && filtros.paciente ? `&paciente=${filtros.paciente}` : ''
  const parametroProfissional = filtros && filtros.profissional ? `&profissional=${filtros.profissional}` : ''

  return api.get(`/avaliacoes/respondidas?${paginacao}${parametroTamanhoDaPagina}${parametroInicio}${parametroFim}${parametroPaciente}${parametroProfissional}`)
}

const alterarStatusDeUmaResposta = (identificador, identificadorDaPergunta, dados) => api.post(`/avaliacoes/respondidas/${identificador}/respostas/${identificadorDaPergunta}/status`, dados)
const recuperarAvaliacaoRespondida = (identificador) => api.get(`/avaliacoes/respondidas/${identificador}`)

export default {
  alterarStatusDeUmaResposta,
  listarAvaliacoesRespondidas,
  recuperarAvaliacaoRespondida
}