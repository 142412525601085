import React from 'react'
import avatar from '../../design/img/no-thumb.png'

export const Foto = ({ foto, children }) => { 
  return (    
    <div className='foto'>
      <div className='foto-images'>
        <div className='foto-image'>
          <img src={foto || avatar} alt='foto-do-paciente' />
          {/* {foto && children} */}
        </div>
      </div>
    </div>
  )
}

// export const BotaoAplicar = () => {
//   return (
//     <div className='foto-image-tools'>
//       <button
//         className='foto-image-zoom'
//         aria-label='Ampliar'
//         type='button'
//         onClick={() => console.log('abrir foto')}
//       >
//         <i className='icon icon-search'></i>
//       </button>
//     </div>
//   )
// }
