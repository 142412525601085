import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { setMinutes, setHours } from 'date-fns'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import { Formulario as Form, DatePicker, Select, Input, HourPicker, Opcoes, CampoNumerico } from '../../../formik/formulario'
import { formatarHoraParaFormatoLocal, formatarDataParaFormatoUniversal, dataIgualOuAnterior } from '../../../../bibliotecas/data'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'
import { listarTiposDeRepeticaoDeSerieDaAgenda } from '../../../../acoes/tipos-de-repeticao-de-serie-da-agenda'
import ConfirmacaoDeExclusao from './confirmacao-de-exclusao'

import {
  ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
} from '../../../../acoes/tipos'

const TIPO_ALTERACAO = ['Alterar somente esta instrução', 'Alterar toda a série']
const DIAS_DE_SEMANA = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
const RECORRENCIAS = [
  { codigo: 'nao_repetir', nome: 'Não repetir' },
  { codigo: 'seg_sex', nome: 'Dia de semana (Segunda-Sexta)' },
  { codigo: 'diario', nome: 'Diário' },
  { codigo: 'semanal', nome: 'Semanal' },
]

export default function FormularioInstrucao(props) {
  const {
    adicionar,
    alterar,
    alterarSerie,
    instrucao = null,
    fechar,
    aposFechar,
    identificadorDoProfissional,
    remover,
    removerSerie
  } = props

  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)

  const dispatch = useDispatch()
  const tiposDeRepeticaoDeSerieDaAgenda = useSelector(state => state.tiposDeRepeticaoDeSerieDaAgenda)

  const [alteraSomenteAOcorrencia, setAlteraSomenteAOcorrencia] = useState(instrucao ? true : false)

  useEffect(() => { dispatch(listarTiposDeRepeticaoDeSerieDaAgenda()) }, [dispatch])

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  const adicionarInstrucao = async formulario => {
    const dadosDaSerieDeInstrucao = {
      identificador: formulario.identificador,
      inicioDaSerie: formatarDataParaFormatoUniversal(formulario.inicioDaSerie),
      fimDaSerie: formulario.tipoDeRecorrencia === 'nao_repetir' ? formatarDataParaFormatoUniversal(formulario.inicioDaSerie) : formatarDataParaFormatoUniversal(formulario.fimDaSerie),
      horaInicial: formatarHoraParaFormatoLocal(formulario.horaInicial),
      horaFinal: formatarHoraParaFormatoLocal(formulario.horaFinal),
      domingo: formulario.semana.includes('Dom'),
      segundaFeira: formulario.semana.includes('Seg'),
      tercaFeira: formulario.semana.includes('Ter'),
      quartaFeira: formulario.semana.includes('Qua'),
      quintaFeira: formulario.semana.includes('Qui'),
      sextaFeira: formulario.semana.includes('Sex'),
      sabado: formulario.semana.includes('Sáb'),
      texto: formulario.texto,
      repeteACada: formulario.repeteACada,
      tipoDeRepeticao: formulario.tipoDeRepeticao,
      observacoes: formulario.observacoes,
    }

    const acao = formulario.identificador ? alterarSerie : adicionar
    const salvou = await acao(identificadorDoProfissional, dadosDaSerieDeInstrucao)

    if (salvou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  const alterarRecorrencia = (tipoDeRecorrencia, setFieldValue) => {
    if (tipoDeRecorrencia === 'nao_repetir') {
      setFieldValue('semana', DIAS_DE_SEMANA)
      return
    }

    const semana = tipoDeRecorrencia === 'seg_sex' ? DIAS_DE_SEMANA.filter(x => x !== 'Dom' && x !== 'Sáb') :
      tipoDeRecorrencia === 'diario' ? DIAS_DE_SEMANA : [DIAS_DE_SEMANA[new Date().getDay()]]

    setFieldValue('semana', semana)
  }

  function retornaTipoDeRecorrenciaDeAcordoComSemana(semana) {
    let tipo = 'semanal'

    if (semana.length === 7) {
      tipo = 'diario'
    } else if (semana.length === 5 && !semana.some(x => x === 'Dom' || x === 'Sáb')) {
      tipo = 'seg_sex'
    }

    return tipo
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={remover}
          excluirSerie={removerSerie}
          fechar={fecharAlertaDeExclusao}
          instrucao={instrucao}
        />
      </Modal>
      {alteraSomenteAOcorrencia ?
        <FormularioAlterarInstrucao
          abrirAlertaDeExclusao={abrirAlertaDeExclusao}
          setAlteraSomenteAOcorrencia={setAlteraSomenteAOcorrencia}
          instrucao={instrucao}
          fechar={fechar}
          aposFechar={aposFechar}
          alterar={alterar}
          identificador={identificadorDoProfissional}
        />
        :
        <Spinner operacoes={[
          ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
          ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
          ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO
        ]}>
          <Form
            reinicializar={true}
            valoresIniciais={{
              identificador: instrucao?.identificador || '',
              inicioDaSerie: instrucao?.serie?.inicioDaSerie || '',
              fimDaSerie: instrucao?.serie?.fimDaSerie || '',
              horaInicial: instrucao && instrucao.serie ? moment(instrucao.serie.inicio, 'HH:mm:ss').toDate() : '',
              horaFinal: instrucao && instrucao.serie ? moment(instrucao.serie.fim, 'HH:mm:ss').toDate() : '',
              tipoDeRecorrencia: instrucao && instrucao.serie && instrucao.serie.inicioDaSerie !== instrucao.serie.fimDaSerie ? retornaTipoDeRecorrenciaDeAcordoComSemana(construirArrayDeSemana(instrucao.serie)) : 'nao_repetir',
              repeteACada: instrucao?.serie?.repeteACada || 1,
              tipoDeRepeticao: tiposDeRepeticaoDeSerieDaAgenda && instrucao && instrucao.serie ? tiposDeRepeticaoDeSerieDaAgenda.find(x => x.nome === instrucao.serie.tipoDeRepeticao).codigo : 'semana',
              texto: instrucao?.serie?.texto || '',
              observacoes: instrucao?.serie?.observacoes || '',
              semana: instrucao && instrucao.serie ? construirArrayDeSemana(instrucao.serie) : DIAS_DE_SEMANA,
              tipoDeAlteracao: TIPO_ALTERACAO[1]
            }}
            acao={adicionarInstrucao}
            esquemaDeValidacoes={Yup.object().shape({
              inicioDaSerie: Yup.string().required('Obrigatório').nullable(),
              fimDaSerie: Yup.string()
                .when("tipoDeRecorrencia", {
                  is: val => val !== 'nao_repetir',
                  then: Yup.string().required('Obrigatório')
                    .nullable()
                    .test(
                      "fim_teste",
                      "Deve ser maior que o início.",
                      function (value) {
                        const { inicioDaSerie } = this.parent;
                        return !value || dataIgualOuAnterior(inicioDaSerie, value);
                      }
                    )
                }),
              horaInicial: Yup.string().required('Obrigatório.').nullable(),
              horaFinal: Yup.string().required('Obrigatório.').test(
                "horaFim_teste",
                "Horário Final deve ser maior que Horário Inicial",
                function (value) {
                  const { horaInicial } = this.parent;
                  return !value || horaIgualOuAnterior(horaInicial, value);
                }
              ).nullable(),
              semana: Yup.string().required('Obrigatório.'),
              tipoDeRecorrencia: Yup.string().required('Obrigatório').nullable(),
              repeteACada: Yup.string().required('Obrigatório').nullable(),
              tipoDeRepeticao: Yup.string().required('Obrigatório').nullable(),
              texto: Yup.string().required('Obrigatório.')
            })}
          >
            {({ values, setFieldValue }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{instrucao ? 'Alterar' : 'Adicionar'} Instrução de Agendamento</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    {instrucao &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Opcoes
                          nome='tipoDeAlteracao'
                          opcoes={TIPO_ALTERACAO}
                          onChange={() => setAlteraSomenteAOcorrencia(true)}
                          selecaoUnica={true}
                          classname='encaminhamentos'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={6} span-lg={values.tipoDeRecorrencia === 'nao_repetir' ? 6 : 3}>
                      <DatePicker
                        nome='inicioDaSerie'
                        tabIndex={1}
                        minDate={(new Date())}
                        titulo='Início do Período *'
                      />
                    </r-cell>
                    {values.tipoDeRecorrencia !== 'nao_repetir' &&
                      <r-cell span={4} span-md={6} span-lg={3}>
                        <DatePicker
                          nome='fimDaSerie'
                          tabIndex={2}
                          isDisabled={!values.inicioDaSerie}
                          minDate={moment(values.inicioDaSerie).toDate()}
                          titulo='Fim do Período *'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <HourPicker
                        titulo='Hora Inicial *'
                        nome='horaInicial'
                        tabIndex={3}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <HourPicker
                        nome='horaFinal'
                        titulo='Hora Final *'
                        tabIndex={4}
                        isDisabled={!values.horaInicial}
                        minTime={(new Date(values.horaInicial).getTime() + 5 * 60000)}
                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Select
                        nome='tipoDeRecorrencia'
                        tabIndex={5}
                        titulo='Recorrência *'
                        isDisabled={!values.inicioDaSerie}
                        itens={RECORRENCIAS}
                        onChange={e => alterarRecorrencia(e, setFieldValue)}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    {values.tipoDeRecorrencia !== 'nao_repetir' &&
                      <>
                        <r-cell span={4} span-md={3} span-lg={3}>
                          <CampoNumerico
                            nome='repeteACada'
                            tabIndex={6}
                            titulo='Repete a cada *'
                          />
                        </r-cell>
                        <r-cell span={4} span-md={9} span-lg={9}>
                          <Select
                            titulo='.'
                            nome='tipoDeRepeticao'
                            tabIndex={7}
                            itens={tiposDeRepeticaoDeSerieDaAgenda}
                            campoCodigo='codigo'
                            campoDescricao='nome'
                          />
                        </r-cell>
                        <DiasDaSemana onChange={(e) => setFieldValue('tipoDeRecorrencia', retornaTipoDeRecorrenciaDeAcordoComSemana(e))} />
                        <ResumoDaRecorrencia {...values} />
                      </>
                    }
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        nome='texto'
                        tabIndex={8}
                        titulo='Texto *'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={9}
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                  <div className='list-btn'>
                    {instrucao?.identificador && <button onClick={abrirAlertaDeExclusao} type='button' className='button --danger'>Excluir</button>}
                    <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
                    <button type='submit' className='button --primary'>{instrucao ? 'Alterar' : 'Adicionar'} Série</button>
                  </div>
                </fieldset>
              </>
            )}
          </Form>
        </Spinner>
      }
    </>
  )
}

function FormularioAlterarInstrucao({
  abrirAlertaDeExclusao,
  alterar,
  aposFechar,
  instrucao,
  fechar,
  identificador,
  setAlteraSomenteAOcorrencia
}) {

  const alterarInstrucao = async formulario => {
    const dadosParaAlterarInstrucao = {
      identificador: formulario.identificador,
      data: formulario.data,
      inicio: formatarHoraParaFormatoLocal(formulario.horaInicial),
      fim: formatarHoraParaFormatoLocal(formulario.horaFinal),
      texto: formulario.texto,
      observacoes: formulario.observacoes,
    }

    const alterou = await alterar(identificador, dadosParaAlterarInstrucao)

    if (alterou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  return (
    <Spinner operacoes={[ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE, ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          identificador: instrucao.identificador || '',
          data: instrucao.data ? instrucao.data : '',
          horaInicial: instrucao.inicio ? moment(instrucao.inicio, 'HH:mm:ss').toDate() : '',
          horaFinal: instrucao.fim ? moment(instrucao.fim, 'HH:mm:ss').toDate() : '',
          texto: instrucao.texto || '',
          observacoes: instrucao.observacoes || '',
          tipoDeAlteracao: TIPO_ALTERACAO[0]
        }}
        acao={alterarInstrucao}
        esquemaDeValidacoes={Yup.object().shape({
          data: Yup.string().required('Obrigatório.').nullable(),
          horaInicial: Yup.string().required('Obrigatório.').nullable(),
          horaFinal: Yup.string().required('Obrigatório.')
            .nullable()
            .test(
              "horaFim_teste",
              "Deve ser maior que a hora inicial",
              function (value) {
                const { horaInicial } = this.parent;
                return !value || horaIgualOuAnterior(horaInicial, value);
              }
            ),
          texto: Yup.string().required('Obrigatório.')
        })}
      >
        {({ values }) => (
          <fieldset>
            <h2 className="form-title">Alterar Instrução de Agendamento</h2>
            <r-grid columns-md={12} columns-lg={12}>
              {instrucao.serie &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Opcoes
                    nome='tipoDeAlteracao'
                    opcoes={TIPO_ALTERACAO}
                    onChange={() => setAlteraSomenteAOcorrencia(false)}
                    selecaoUnica={true}
                    classname='encaminhamentos'
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={4} span-lg={3}>
                <DatePicker
                  nome='data'
                  tabIndex={1}
                  minDate={(new Date())}
                  titulo='Data *'
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaInicial'
                  titulo='Hora Inicial *'
                  tabIndex={2}
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaFinal'
                  titulo='Hora Final *'
                  tabIndex={3}
                  isDisabled={!values.horaInicial}
                  minTime={(new Date(values.horaInicial).getTime() + 5 * 60000)}
                  maxTime={(setHours(setMinutes(new Date(), 59), 23))}
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  nome='texto'
                  tabIndex={4}
                  titulo='Texto *'
                />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={5}
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              {instrucao?.identificador && <button onClick={abrirAlertaDeExclusao} type='button' className='button --danger'>Excluir</button>}
              <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
              <button type='submit' className='button --primary'>Alterar Instrução</button>
            </div>
          </fieldset>
        )}
      </Form>
    </Spinner>
  )
}

function DiasDaSemana({ onChange }) {
  return (
    <r-cell span={4} span-md={12} span-lg={12}>
      <Opcoes
        titulo='Selecione os dias da Semana *'
        nome='semana'
        opcoes={DIAS_DE_SEMANA}
        opcoesDesabilitadas={[]}
        onChange={onChange}
      />
    </r-cell>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}