import React, { memo, useState, useCallback, useEffect } from 'react'
import Modal from 'react-modal'
import { FieldArray } from 'formik'
import { confirmAlert } from 'react-confirm-alert'
import { useProntuario } from '../../../contexto'
import { CampoPrescricao } from '../../../../formik/formulario'
import { SelectTemplate } from '../../../../formik/input-mension/index'
import { contemValor } from '../../../../../bibliotecas/validacoes'
import DicaDePesquisa from '../../../../dica-de-pesquisa'
import Imprimir from './impressao'

const deveSerInformado = valor => contemValor(valor) ? undefined : 'Obrigatório.'

function Prescricoes(props) {
  const {
    identificadorDoAtendimento,
    identificadorDoPaciente,
    metadados,
    tokensDeSugestao,
    setFieldValue,
    abrirAdicionarTemplate,
    abrirHistorico,
    limparHistorico,
    textoAdicionado,
  } = props

  return (
    <FieldArray
      name='prescricoes'
      render={(props) => {
        const { push, remove, replace, form: { values: { prescricoes = [] } } } = props
        return (
          <Prescricao
            adicionar={push}
            alterar={replace}
            remover={remove}
            metadados={metadados}
            adicionados={prescricoes}
            identificadorDoAtendimento={identificadorDoAtendimento}
            identificadorDoPaciente={identificadorDoPaciente}
            tokensDeSugestao={tokensDeSugestao}
            setFieldValue={setFieldValue}
            abrirAdicionarTemplate={abrirAdicionarTemplate}
            abrirHistorico={abrirHistorico}
            limparHistorico={limparHistorico}
            textoAdicionado={textoAdicionado}
          />
        )
      }}
    />
  )
}

const Prescricao = memo(function Prescricao(props) {
  const {
    adicionados,
    identificadorDoAtendimento,
    identificadorDoPaciente,
    metadados,
    setFieldValue,
    tokensDeSugestao,
    abrirAdicionarTemplate,
    abrirHistorico,
    limparHistorico,
    textoAdicionado,
    ...rest
  } = props

  const {
    fazerDownloadDaPrescricao,
    tamanhosDePaginas,
  } = useProntuario()

  const [pularConfirmacao, setPularConfirmacao] = useState([])
  const [selecionada, setSelecionada] = useState(null)
  const [exibirImprimirPrescricao, setExibirImprimirPrescricao] = useState(false)

  const obrigatorio = metadados?.obrigatorio === 'true'
  const { dadosDaSugestao, usuariosDaSugestao, templates, decodificador } = tokensDeSugestao
  const templatesPrescricao = templates.map(x => ({
    ...x,
    options: x.options.length > 0 ? x.options.filter(y => y.componente === 'PRESCRICOES').map(z => ({ ...z, label: z.nome, value: z.identificador })) : []
  }))

  const atualizarPreview = (template = '') => {
    return decodificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const selecionarTemplate = (valor, index) => {
    if (!valor) {
      setPularConfirmacao([pularConfirmacao.filter(x => x !== index)])
      return
    }

    setPularConfirmacao(pularConfirmacao.includes(index) ? [...pularConfirmacao] : [...pularConfirmacao, index])

    let todosOsTemplates = []
    templatesPrescricao.forEach(x => x.options.length > 0 && x.options.map(t => todosOsTemplates.push(t)))

    const template = todosOsTemplates.filter(x => x.identificador === valor)[0].template
    setFieldValue(`prescricoes.${index}.texto`, atualizarPreview(template))
  }

  const confirmarESubstituir = (valor, index) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Os dados informados da Prescrição serão substituídos. Tem certeza disso?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => selecionarTemplate(valor, index)
      }, {
        label: 'Não',
        onClick: () => setFieldValue(`prescricoes.${index}.template`, '')
      }]
    })
  }

  const validar = valor => {
    if (!valor && obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  const abrirImpressao = (item) => {
    setSelecionada(item)
    setExibirImprimirPrescricao(true)
  }

  const fecharImpressao = () => setExibirImprimirPrescricao(false)

  const confirmarERemover = (item) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover a prescrição?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(item)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEImprimir = () => {
    confirmAlert({
      title: 'Alerta',
      message: `Para imprimir a prescrição é necessário salvar o atendimento.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  const imprimir = async (tamanhoDaPagina, adicionarData) => {
    const retorno = await fazerDownloadDaPrescricao(identificadorDoPaciente, identificadorDoAtendimento.identificador, selecionada.identificador, tamanhoDaPagina, adicionarData)

    if (retorno) {
      const arquivo = new Blob([retorno], { type: 'application/pdf' });
      const url = URL.createObjectURL(arquivo);

      window.open(url);

      fecharImpressao()
    }
  }

  const remover = useCallback((registro) => {
    const index = adicionados.indexOf(registro)

    if (index >= 0) {
      rest.remover(index)
    }
  }, [rest, adicionados])

  const alterar = item => {
    const index = adicionados.indexOf(item)
    if (index >= 0) {
      rest.alterar(index, { ...item, sujo: true })
    }
  }

  useEffect(() => {
    if (textoAdicionado) {
      const registro = { texto: textoAdicionado, template: '' }
      const index = adicionados.indexOf(registro)

      if (index < 0) {
        rest.adicionar(registro)
      }

      limparHistorico()
    }
  }, [textoAdicionado, limparHistorico, adicionados, rest])

  const renderizarPrescricoes = (item, index) => {
    return (
      <div className='container-da-prescricao' key={index}>
        <r-cell span={4} span-md={3} span-lg={12} class='d-flex justify-content-lg-end' key={`botoes_${index}`}>
          <button type='button' className='button --danger w-sm-100 mr-3' onClick={() => confirmarERemover(item)}>Remover</button>
          <button type='button' className='button --light w-sm-100' onClick={() => item.identificador && !item.sujo ? abrirImpressao(item) : confirmarEImprimir()}>Imprimir</button>
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={12}>
          <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@@medicamentos'>
            <CampoPrescricao
              as='textarea'
              nome={`prescricoes.${index}.texto`}
              titulo={`Prescrição ${index + 1}`}
              validar={validar}
              dados={dadosDaSugestao}
              dadosUsuarios={usuariosDaSugestao}
              minHeight={300}
              onKeyPress={() => alterar(item)}
            >
              <SelectTemplate
                nome={`prescricoes.${index}.template`}
                itens={templatesPrescricao}
                campoCodigo='identificador'
                campoDescricao='nome'
                onChange={v => (document.getElementById(`prescricoes.${index}.texto`).value === '' || pularConfirmacao.includes(index)) ? selecionarTemplate(v, index) : confirmarESubstituir(v, index)}
                onClickSalvarComoTemplate={() => abrirAdicionarTemplate(document.getElementById(`prescricoes.${index}.texto`).value, 'PRESCRICOES')}
              />
            </CampoPrescricao>
          </DicaDePesquisa>
        </r-cell>
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirImprimirPrescricao}
        className='modal'
        contentLabel='Modal para adição de medicamento'
        style={{ content: { width: '400px' } }}
      >
        <Imprimir
          fechar={fecharImpressao}
          tamanhosDePaginas={tamanhosDePaginas}
          imprimir={imprimir}
        />
      </Modal>
      <FieldArray
        name='adicionados'
      >
        {() => (
          <>
            {adicionados.map((x, index) => renderizarPrescricoes(x, index))}
            <r-cell span={4} span-md={3} span-lg={12} class={`d-flex justify-content-lg-start ${adicionados.length > 0 ? 'proximo-horario' : ''}`}>
              <button
                className='button --light w-sm-100 mr-1'
                type='button'
                onClick={() => rest.adicionar({ texto: '', template: '' })}
              >
                Adicionar Prescrição
              </button>
              <button
                className='button --light w-sm-100'
                type='button'
                onClick={abrirHistorico}
              >
                Histórico
              </button>
            </r-cell>
          </>
        )}
      </FieldArray>
    </>
  )
})

export default memo(Prescricoes)