import {
  LISTOU_MOTIVOS_DE_INTERNACAO,
  LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ADICIONOU_MOTIVO_DE_INTERNACAO,
  RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ALTEROU_MOTIVO_DE_INTERNACAO,
  REMOVEU_MOTIVO_DE_INTERNACAO,
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: '',
    status: ['Aprovado']
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
  itens: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_MOTIVO_DE_INTERNACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.motivoDeInternacao
      }
    }

    case ALTEROU_MOTIVO_DE_INTERNACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_MOTIVOS_DE_INTERNACAO: {
      return {
        ...state,
        itens: action.motivosDeInternacao
      }
    }

    case LISTOU_MOTIVOS_DE_INTERNACAO_DO_CADASTRO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_MOTIVO_DE_INTERNACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}