export const removerAcentos = texto => {
  if (!texto) return ''
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const contem = (texto, termo) => {
  if (!termo) return false
  if (!texto) return false

  texto = removerAcentos(texto)
  termo = removerAcentos(termo)

  return texto.toLowerCase().indexOf(termo.toLowerCase()) >= 0
}

export const contemNaLista = (lista, termo) => {
  if (!termo) return false
  if (!lista || lista.length === 0) return false

  const filtrados = lista.filter(x => contem(x, termo))

  return filtrados && filtrados.length > 0
}

export const filtroPadrao = (input, item) => {
  const tokens = input.split(' ').filter(x => x)
  const baseDaPesquisa = item + ' '

  return filtrar(tokens, baseDaPesquisa)
}

export const filtroPadraoMultiplo = (input, option) => {
  const tokens = input.split(' ').filter(x => x)
  const item = option.props.children ? option.props.children : ''
  const baseDaPesquisa = item + ' '

  return filtrar(tokens, baseDaPesquisa)
}

export const removerEspacos = valor => {
  if (valor != null) {
    return valor.replace(/\s/gi, '')
  }
}

export const filtrar = (tokens, baseDaPesquisa) => {
  for (const token of tokens) {
    if (!contem(baseDaPesquisa, token)) {
      return false
    }
  }

  return true
}

export const estahVazioOuNulo = valor => {
  const valorSemEspacos = removerEspacos(valor)
  return !valorSemEspacos || valorSemEspacos === undefined || valorSemEspacos === '' || valorSemEspacos.length === 0
}

export const colocarPrimeiraLetraMaiuscula = valor => {
  return valor.charAt(0).toUpperCase() + valor.slice(1)
}

export const extrairNomeResumido = (nome, caracteres = 30) => {
  if (!nome) return ''
  if (nome.length <= caracteres) return nome

  const nomes = nome.split(' ')

  if (nomes.length === 1) {
    return nomes[0]
  }

  const primeiroNome = nomes[0]
  const segundoNome = nomes.length > 2 ? nomes[1] : ''
  const ultimoNome = nomes[nomes.length - 1]

  return `${primeiroNome} ${segundoNome} ${ultimoNome}`
}

export const alterarCedilhaPorC = valor => {
  const value = valor.toUpperCase()
  return value.replaceAll('Ç', 'C')
}

export const alterarUnderlinePorEspaco = valor => {
  return valor.replaceAll('_', ' ');
}

export const alterarEspacoPorUnderline = valor => {
  return valor.replaceAll(' ', '_')
}

export const removerTrema = texto => {
  if (!texto) return ''
  return texto.replace(/ü/g, "u").replace(/ä/g, "a").replace(/ë/g, "e").replace(/ï/g, "i").replace(/ö/g, "o").replace(/ÿ/g, "y").trim()
}

export const ehVerdadeiro = valor => {
  if (!valor) return false

  const valorLowerCase = valor.toLowerCase()

  return valorLowerCase === 'sim' ||
    valorLowerCase === 'yes' ||
    valorLowerCase === 'true' ||
    valorLowerCase === '1'
}

export const ehFalso = valor => {
  return !ehVerdadeiro(valor)
}