import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_AVALIACOES_RESPONDIDAS,
  LISTOU_AVALIACOES_RESPONDIDAS,
  ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS,
  ALTERAR_STATUS_DE_UMA_RESPOSTA,
  ALTEROU_STATUS_DE_UMA_RESPOSTA,
  ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA,
  RECUPERAR_AVALIACAO_RESPONDIDA,
  RECUPEROU_AVALIACAO_RESPONDIDA,
  ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA,
  LISTAR_PACIENTES_DO_NPS,
  LISTOU_PACIENTES_DO_NPS,
  ERRO_AO_LISTAR_PACIENTES_DO_NPS,
  LISTAR_PROFISSIONAIS_DO_NPS,
  LISTOU_PROFISSIONAIS_DO_NPS,
  ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS
} from '../tipos'

const listouAvaliacoesRespondidas = (paginaDeDados, filtros) => ({
  type: LISTOU_AVALIACOES_RESPONDIDAS,
  paginaDeDados,
  filtros
})

const erroAoListarAvaliacoesRespondidas = erro => ({
  type: ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS,
  erro
})

export const listarAvaliacoesRespondidas = (filtros, pagina) => async dispatch => {
  dispatch({ type: LISTAR_AVALIACOES_RESPONDIDAS })

  try {
    const resultado = await api.listarAvaliacoesRespondidas(filtros, pagina)
    dispatch(listouAvaliacoesRespondidas(resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarAvaliacoesRespondidas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as avaliações respondidas do NPS.')))

    return false
  }
}

const alterouStatusDeUmaResposta = pergunta => ({
  type: ALTEROU_STATUS_DE_UMA_RESPOSTA,
  pergunta,
})

const erroAoAlterarStatusDeUmaResposta = erro => ({
  type: ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA,
  erro
})

export const alterarStatusDeUmaResposta = (identificador, identificadorDaPergunta, dados) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DE_UMA_RESPOSTA })

  try {
    const resultado = await api.alterarStatusDeUmaResposta(identificador, identificadorDaPergunta, dados)
    dispatch(alterouStatusDeUmaResposta(identificadorDaPergunta))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDeUmaResposta(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o status da resposta do NPS.')))

    return false
  }
}

const recuperouAvaliacaoRespondida = avaliacao => ({
  type: RECUPEROU_AVALIACAO_RESPONDIDA,
  avaliacao,
})

const erroAoRecuperarAvaliacaoRespondida = erro => ({
  type: ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA,
  erro
})

export const recuperarAvaliacaoRespondida = (identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_AVALIACAO_RESPONDIDA })

  try {
    const resultado = await api.recuperarAvaliacaoRespondida(identificador)
    dispatch(recuperouAvaliacaoRespondida(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarAvaliacaoRespondida(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a avaliação respondida do NPS.')))

    return false
  }
}

const listouPacientesDoNps = pacientes => ({
  type: LISTOU_PACIENTES_DO_NPS,
  pacientes
})

const erroAoListarPacientesDoNps = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PACIENTES_DO_NPS,
  erro,
  parametros
})

export const listarPacientesDoNps = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PACIENTES_DO_NPS })

    try {
      const retorno = await api.listarPacientes(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouPacientesDoNps(retorno.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarPacientesDoNps(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pacientes do NPS.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PACIENTES_DO_NPS,
    }
  }

  return resultado
}

const listouProfissionaisDoNps = profissionais => ({
  type: LISTOU_PROFISSIONAIS_DO_NPS,
  profissionais
})

const erroAoListarProfissionaisDoNps = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS,
  erro,
  parametros
})

export const listarProfissionaisDoNps = (filtros, pagina, ordem, tamanhoDaPagina) => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_PROFISSIONAIS_DO_NPS })

    try {
      const retorno = await api.listarUsuarios(filtros, pagina, ordem, tamanhoDaPagina)
      dispatch(listouProfissionaisDoNps(retorno.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(retorno))

      return true
    } catch (erro) {
      dispatch(erroAoListarProfissionaisDoNps(erro, { filtros, pagina, ordem, tamanhoDaPagina }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais do NPS.')))

      return false
    }
  }

  resultado.meta = {
    debounce: {
      time: 600,
      key: LISTAR_PROFISSIONAIS_DO_NPS,
    }
  }

  return resultado
}