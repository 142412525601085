import React, { useState } from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { filtroPadrao } from '../../../../bibliotecas/texto'
import { LISTAR_MEDICAMENTOS_DO_PRONTUARIO, LISTAR_POSOLOGIAS, ADICIONAR_MEDICAMENTO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function AdicionarMedicamento({
  adicionarMedicamento,
  fechar,
  medicamentos,
  posologias,
  identificadorDoPaciente
}) {

  const [medicamento, setMedicamento] = useState('')
  const [posologia, setPosologia] = useState('')
  const [outroMedicamentoEConcentracao, setOutroMedicamentoEConcentracao] = useState('')
  
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_MEDICAMENTO_DO_PACIENTE])

  const adicionarMedicamentoDoPaciente = async formulario => {
    const dados = {
      frequencia: formulario.posologia,
      observacoes: formulario.observacoes,
    }

    const medicamento = !outroMedicamentoEConcentracao && medicamentos.find(x => x.exibicao === formulario.medicamento)

    if (medicamento) {
      dados.medicamento = medicamento.nome
      dados.concentracao = medicamento.concentracao.valor
    } else {
      dados.outroMedicamentoEConcentracao = formulario.outroMedicamentoEConcentracao
    }

    const adicionou = await adicionarMedicamento(identificadorDoPaciente, dados)

    if (adicionou) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[LISTAR_MEDICAMENTOS_DO_PRONTUARIO, LISTAR_POSOLOGIAS, ADICIONAR_MEDICAMENTO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          medicamento: medicamento,
          outroMedicamentoEConcentracao: outroMedicamentoEConcentracao,
          posologia: posologia,
          observacoes: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          medicamento: Yup.string().required('Obrigatório.'),
          outroMedicamentoEConcentracao: medicamento === 'Outro' && Yup.string().required('Obrigatório.'),
          posologia: Yup.string().required('Obrigatório.')
        })}
        acao={adicionarMedicamentoDoPaciente}
      >
        <fieldset>
          <h2 className='form-title'>Adicionar Medicamento</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={3} span-lg={12}>
              <Select
                nome='medicamento'
                titulo='Medicamento *'
                tabIndex={1}
                itens={medicamentos}
                campoCodigo='exibicao'
                campoDescricao='exibicao'
                onChange={setMedicamento}
                noOptionsMessage='Digite 3 caracteres para pesquisar'
                filterOption={(option, inputValue) => {
                  if (inputValue.length >= 3) {
                    if (option.label === 'Outro') {
                      const result = inputValue && medicamentos.filter(x => x.exibicao.includes(inputValue));
                      return !result.length;
                    }
                    return filtroPadrao(inputValue, option.label)
                  }
                }}
              />
            </r-cell>
            {medicamento === 'Outro' &&
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  tabIndex={3}
                  nome='outroMedicamentoEConcentracao'
                  titulo='Outro Medicamento e Concentração *'
                  onChange={setOutroMedicamentoEConcentracao}
                />
              </r-cell>
            }
            <r-cell span={4} span-md={3} span-lg={12}>
              <Select
                nome='posologia'
                titulo='Posologia *'
                tabIndex={2}
                itens={posologias}
                campoCodigo='codigo'
                campoDescricao='nome'
                onChange={setPosologia}
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as='textarea'
                tabIndex={3}
                nome='observacoes'
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}