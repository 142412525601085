import api from './api-major'
import constantes from '../configuracoes/constantes'


const listarProfissionaisDeSaude = (termo, atende) => {
  const parametroTermo = termo ? `&termo=${termo}` : ''
  const parametroAtende = atende ? `&atende=${atende}` : ''

  return api.get(`/profissionais-de-saude/ativos?tamanhoDaPagina=1000&pagina=1${parametroTermo}${parametroAtende}&ordem=+nome`)
}

const recuperarListaDeEsperaDoProfissionalDeSaude = (identificadorDoProfissional) => {
  return api.get(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/lista-de-espera?tamanhoDaPagina=1000`)
}

const adicionarPacienteNaListaDeEspera = (identificadorDoProfissional, paciente) => {
  return api.post(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/lista-de-espera`, paciente)
}

const alterarPacienteNaListaDeEspera = (identificadorDoProfissional, identificadorEmEspera, paciente) => {
  return api.put(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/lista-de-espera/${identificadorEmEspera}`, paciente)
}

const removerPacienteNaListaDeEspera = (identificadorDoProfissional, identificadorEmEspera) => {
  return api.delete(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/lista-de-espera/${identificadorEmEspera}`)
}

const recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador = identificador => api.get(`/usuarios/${identificador}/foto?tamanho=${constantes.tamanhoDaFoto.miniatura}`, {
  responseType: 'arraybuffer'
})

export default {
  listarProfissionaisDeSaude,
  recuperarListaDeEsperaDoProfissionalDeSaude,
  adicionarPacienteNaListaDeEspera,
  alterarPacienteNaListaDeEspera,
  recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador,
  removerPacienteNaListaDeEspera
}