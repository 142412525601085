import React, {useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import alerta from '../../design/img/alerta.svg'

export default function Senha(props) {
  const match = useRouteMatch()
  const [oculto, setOculto] = useState(true)
  const [capsLockAtivo, setCapsLockAtivo] = useState(null)
  const [focoCampo, setFocoCampo] = useState(true)

  const mostraAlerta = focoCampo && capsLockAtivo

  let copiaDeProps = { ...props }
  delete copiaDeProps['erro']

  const alterarVisualizacao = evento => {
    evento.preventDefault()
    setOculto(!oculto)
  }

  const erro = props.form.errors[props.field.name]
  const tocado = props.form.touched[props.field.name]

  function verificaCapsLock(keyEvent) {
    const charCode = keyEvent.charCode
    const shiftKey = keyEvent.shiftKey

    if (charCode >= 97 && charCode <= 122) {
      setCapsLockAtivo(shiftKey)
    } else if (charCode >= 65 && charCode <= 90 && !(shiftKey)) {
      setCapsLockAtivo(!shiftKey)
    }

    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      props.form.handleSubmit()
    }
  }

  return (
    <div id='password' className={`item-password-field  ${erro && tocado && 'has-error'}`}>
      <input
        {...props.field}
        {...copiaDeProps}
        onKeyPress={verificaCapsLock}
        onKeyDown={verificaCapsLock}
        onFocus={() => setFocoCampo(true)}
        onBlurCapture={() => setFocoCampo(false)}
        type={oculto ? 'password' : 'input'}
      />
      {mostraAlerta &&
       <button
         type='button'
         className='icon-alerta'
         data-tip
         data-for='alerta-caps-lock'
       >
         <img src={alerta} alt='caps-lock' />
         <ReactTooltip
           id='alerta-caps-lock'
           place='left'
           effect='solid'
           type='warning'
           key='alerta-caps-lock'
         >
           <span>Caps-lock ativo</span>
         </ReactTooltip>
       </button>
      }
      <a href={match.url} onClick={alterarVisualizacao} className={`view-password ${oculto && 'is-active'}`}> </a>
    </div>
  )
}