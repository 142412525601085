import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  LISTOU_ESTABELECIMENTOS_DE_SAUDE,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  ADICIONOU_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  ALTEROU_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  REMOVER_ESTABELECIMENTO_DE_SAUDE,
  REMOVEU_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE
} from './tipos'

const listouEstabelecimentosDeSaude = estabelecimentoDeSaude => ({
  type: LISTOU_ESTABELECIMENTOS_DE_SAUDE,
  estabelecimentoDeSaude
})

const erroAoListarEstabelecimentosDeSaude = erro => ({
  type: ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  erro
})

export const listarEstabelecimentosDeSaude = () => async dispatch => {
  dispatch({ type: LISTAR_ESTABELECIMENTOS_DE_SAUDE })

  try {
    const resultado = await api.listarEstabelecimentosDeSaude()

    dispatch(listouEstabelecimentosDeSaude(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEstabelecimentosDeSaude(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estabelecimentos de saúde.')))
  }
}

const listouEstabelecimentosDeSaudeDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarEstabelecimentosDeSaudeDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  erro,
  parametros
})

export const listarEstabelecimentosDeSaudeDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO })

  try {
    const resultado = await api.listarEstabelecimentosDeSaude(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouEstabelecimentosDeSaudeDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEstabelecimentosDeSaudeDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estabelecimentos de saúde.')))
  }
}

const adicionouEstabelecimentoDeSaude = estabelecimentoDeSaude => ({
  type: ADICIONOU_ESTABELECIMENTO_DE_SAUDE,
  estabelecimentoDeSaude
})

const erroAoAdicionarEstabelecimentoDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  erro,
  parametros
})

export const adicionarEstabelecimentoDeSaude = estabelecimento => async dispatch => {
  dispatch({ type: ADICIONAR_ESTABELECIMENTO_DE_SAUDE })

  try {
    const resultado = await api.adicionarEstabelecimentoDeSaude(estabelecimento)

    dispatch(adicionouEstabelecimentoDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarEstabelecimentoDeSaude(erro, { estabelecimento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o estabelecimento de saúde.')))

    return false
  }
}

const recuperouEstabelecimentoDeSaudePeloIdentificador = estabelecimentoDeSaude => ({
  type: RECUPEROU_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  estabelecimentoDeSaude
})

const erroAoRecuperarEstabelecimentoDeSaudePeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarEstabelecimentoDeSaudePeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarEstabelecimentoDeSaudePeloIdentificador(identificador)

    dispatch(recuperouEstabelecimentoDeSaudePeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarEstabelecimentoDeSaudePeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o estabelecimento de saúde por identificador.')))
  }
}

const alterouEstabelecimentoDeSaude = estabelecimentoDeSaude => ({
  type: ALTEROU_ESTABELECIMENTO_DE_SAUDE,
  estabelecimentoDeSaude
})

const erroAoAlterarEstabelecimentoDeSaude = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  erro,
  parametros
})

export const alterarEstabelecimentoDeSaude = estabelecimento => async dispatch => {
  dispatch({ type: ALTERAR_ESTABELECIMENTO_DE_SAUDE })

  try {
    const resultado = await api.alterarEstabelecimentoDeSaude(estabelecimento)

    dispatch(alterouEstabelecimentoDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarEstabelecimentoDeSaude(erro, { estabelecimento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o estabelecimento de saúde.')))

    return false
  }
}

const removeuEstabelecimentoDeSaude = estabelecimentoDeSaude => ({
  type: REMOVEU_ESTABELECIMENTO_DE_SAUDE,
  estabelecimentoDeSaude
})

const erroAoRemoverEstabelecimentoDeSaude = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE,
  erro,
  parametros
})

export const removerEstabelecimentoDeSaude = identificador => async dispatch => {
  dispatch({ type: REMOVER_ESTABELECIMENTO_DE_SAUDE })

  try {
    const resultado = await api.removerEstabelecimentoDeSaude(identificador)

    dispatch(removeuEstabelecimentoDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverEstabelecimentoDeSaude(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o estabelecimento de saúde.')))

    return false
  }
}