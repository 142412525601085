import {
  ADICIONOU_FOTO_DA_EMPRESA,
  ADICIONOU_FOTO_DO_PACIENTE,
  ADICIONOU_FOTO_DO_USUARIO,  
  RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR,  
  RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DA_EMPRESA_LOGADA,
  REMOVER_FOTO_DA_EMPRESA,
  REMOVER_FOTO_DO_PACIENTE,
  REMOVER_FOTO_DO_USUARIO,
  REMOVER_IMAGEM_DA_MEMORIA_POR_IDENTIFICADOR,
  RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
} from '../acoes/tipos'

const estadoInicial = {}

export default (state = {}, action) => {
  switch (action.type) {
    case RECUPEROU_FOTO_DA_EMPRESA_LOGADA: {
      return {
        ...state,
        [action.identificador]: [action.url]
      }
    }

    case RECUPEROU_FOTO_DO_USUARIO_POR_IDENTIFICADOR: {
      return {
        ...state,
        [action.identificador]: [action.url]
      }
    }

    case RECUPEROU_FOTO_DO_PACIENTE_POR_IDENTIFICADOR: {
      return {
        ...state,
        [action.identificador]: [action.url]
      }
    }

    case RECUPEROU_MINIATURA_DA_FOTO_DO_PROFISSIONAL_DE_SAUDE_POR_IDENTIFICADOR: {
      return {
        ...state,
        [action.identificadorDaFoto]: [action.miniaturaDaFoto]
      }
    }

    case RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO: {
      return {
        ...state,
        [action.identificadorDaEmpresa]: [action.url]
      }
    }

    case REMOVER_IMAGEM_DA_MEMORIA_POR_IDENTIFICADOR: {
      let imagens = { ...state }

      if (imagens[action.identificador]) {
        delete imagens[action.identificador]
      }
      return imagens
    }

    case ADICIONOU_FOTO_DA_EMPRESA: {
      return {
        ...state,
        ...action.foto
      }
    }   

    case ADICIONOU_FOTO_DO_USUARIO: {
      return {
        ...state,
        ...action.foto
      }
    }

    case ADICIONOU_FOTO_DO_PACIENTE: {
      return {
        ...state,
        ...action.foto
      }
    }
    
    case REMOVER_FOTO_DA_EMPRESA:
    case REMOVER_FOTO_DO_USUARIO:
    case REMOVER_FOTO_DO_PACIENTE: {
      return {
        ...estadoInicial
      }
    }

    default: {
      return state
    }
  }
}