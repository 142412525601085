import {
  GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES
} from '../../acoes/tipos'

const estadoInicial = {
  programas: [],
  filtros: {},
  dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado: [],
  dadosGraficoDeReinternacoesMetaVersusRealizado: []
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case GEROU_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO: {
      return {
        ...state,
        filtros: action.filtros,
        dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado: action.dados,
      }
    }

    case GEROU_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO: {
      return {
        ...state,
        filtros: action.filtros,
        dadosGraficoDeReinternacoesMetaVersusRealizado: action.dados,
      }
    }

    case LISTOU_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES: {
      return {
        ...state,
        programas: action.programas
      }
    }

    default: {
      return state
    }
  }
}