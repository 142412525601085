import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  ADICIONAR_DIAGNOSTICO,
  ADICIONOU_DIAGNOSTICO,
  ALTERAR_DIAGNOSTICO,
  ALTEROU_DIAGNOSTICO,
  LISTAR_DIAGNOSTICOS,
  LISTOU_DIAGNOSTICOS,
  ERRO_AO_LISTAR_DIAGNOSTICOS,
  ERRO_AO_ADICIONAR_DIAGNOSTICO,
  ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  ERRO_AO_ALTERAR_DIAGNOSTICO,
  ERRO_AO_REMOVER_DIAGNOSTICO,  
  RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR,
  RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  REMOVEU_DIAGNOSTICO,
  REMOVER_DIAGNOSTICO,
} from './tipos'

const listouDiagnosticos = (filtros, paginaDeDados) => ({
  type: LISTOU_DIAGNOSTICOS,
  filtros,
  paginaDeDados,
})

const erroAoListarDiagnosticos = erro => ({
  type: ERRO_AO_LISTAR_DIAGNOSTICOS,
  erro,
})

export const listarDiagnosticos = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_DIAGNOSTICOS })

  try {
    const resultado = await api.listarDiagnosticos(filtros, pagina, ordenacao, tamanhoDaPagina)
    
    dispatch(listouDiagnosticos(filtros, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarDiagnosticos(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os diagnósticos.')))
  }
}

const adicionouDiagnostico = diagnostico => ({
  type: ADICIONOU_DIAGNOSTICO,
  diagnostico
})

const erroAoAdicionarDiagnostico = erro => ({
  type: ERRO_AO_ADICIONAR_DIAGNOSTICO,
  erro
})

export const adicionarDiagnostico = diagnostico => async dispatch => {
  dispatch({ type: ADICIONAR_DIAGNOSTICO })

  try {
    const resultado = await api.adicionarDiagnostico(diagnostico)

    dispatch(adicionouDiagnostico(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarDiagnostico(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o diagnóstico.')))

    return false
  }
}

const recuperouDiagnosticoPeloIdentificador = diagnostico => ({
  type: RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR,
  diagnostico
})

const erroAoRecuperarDiagnosticoPeloIdentificador = erro => ({
  type: ERRO_AO_RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  erro
})

export const recuperarDiagnosticoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarDiagnosticoPeloIdentificador(identificador)

    dispatch(recuperouDiagnosticoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarDiagnosticoPeloIdentificador(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o diagnóstico por identificador.')))
  }
}

const alterouDiagnostico = diagnostico => ({
  type: ALTEROU_DIAGNOSTICO,
  diagnostico
})

const erroAoAlterarDiagnostico = erro => ({
  type: ERRO_AO_ALTERAR_DIAGNOSTICO,
  erro
})

export const alterarDiagnostico = diagnostico => async dispatch => {
  dispatch({ type: ALTERAR_DIAGNOSTICO })

  try {
    const resultado = await api.alterarDiagnostico(diagnostico)

    dispatch(alterouDiagnostico(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarDiagnostico(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o diagnóstico.')))

    return false
  }
}

const removeuDiagnostico = diagnostico => ({
  type: REMOVEU_DIAGNOSTICO,
  diagnostico
})

const erroAoRemoverDiagnostico = erro => ({
  type: ERRO_AO_REMOVER_DIAGNOSTICO,
  erro,
})

export const removerDiagnostico = identificador => async dispatch => {
  dispatch({ type: REMOVER_DIAGNOSTICO })

  try {
    const resultado = await api.removerDiagnostico(identificador)
    
    dispatch(removeuDiagnostico(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverDiagnostico(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o diagnóstico.')))

    return false
  }
}