import { connect } from 'react-redux'
import acoes from '../../../acoes'
import PacientesDoPrograma from './index'

const mapStateToProps = state => ({
  celulas: state.relatorios.pacientesDoPrograma.celulas,
  enfermeiros: state.relatorios.pacientesDoPrograma.enfermeiros,
  medicos: state.relatorios.pacientesDoPrograma.medicos,
  filtros: state.relatorios.pacientesDoPrograma.filtros,
  programas: state.relatorios.pacientesDoPrograma.programas
})

export default connect(mapStateToProps, acoes)(PacientesDoPrograma)