import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
} from '../../tipos'

const listouInfeccoesDoTratoUrinarioDoPaciente = (identificadorDoPaciente, infeccoes) => ({
  type: LISTOU_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  identificadorDoPaciente,
  infeccoes
})

const erroAoListarInfeccoesDoTratoUrinarioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  erro,
  parametros
})

export const listarInfeccoesDoTratoUrinarioDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE })

  try {
    const resultado = await api.listarInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente)

    dispatch(listouInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarInfeccoesDoTratoUrinarioDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as infecções do trato urinário do paciente.')))
  }
}

const adicionouInfeccaoDoTratoUrinarioDoPaciente = infeccao => ({
  type: ADICIONOU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  infeccao
})

const erroAoAdicionarInfeccaoDoTratoUrinarioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarInfeccaoDoTratoUrinarioDoPaciente = (identificadorDoPaciente, infeccao) => async dispatch => {
  dispatch({ type: ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarInfeccaoDoTratoUrinarioDoPaciente(identificadorDoPaciente, infeccao)

    dispatch(adicionouInfeccaoDoTratoUrinarioDoPaciente(resultado.data.dados))
    dispatch(listarInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarInfeccaoDoTratoUrinarioDoPaciente(erro, { infeccao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar infecção do trato urinário do paciente.')))

    return false
  }
}

const alterouInfeccaoDoTratoUrinarioDoPaciente = infeccao => ({
  type: ALTEROU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  infeccao
})

const erroAoAlterarInfeccaoDoTratoUrinarioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarInfeccaoDoTratoUrinarioDoPaciente = (identificadorDoPaciente, infeccao) => async dispatch => {
  dispatch({ type: ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE })

  try {
    const resultado = await api.alterarInfeccaoDoTratoUrinarioDoPaciente(identificadorDoPaciente, infeccao)

    dispatch(alterouInfeccaoDoTratoUrinarioDoPaciente(resultado.data.dados))
    dispatch(listarInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarInfeccaoDoTratoUrinarioDoPaciente(erro, { infeccao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar infecção do trato urinário do paciente.')))

    return false
  }
}

const removeuInfeccaoDoTratoUrinarioDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverInfeccaoDoTratoUrinarioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  erro,
  parametros
})

export const removerInfeccaoDoTratoUrinarioDoPaciente = (identificadorDoPaciente, identificadorDaInfeccaoDoTratoUrinario) => async dispatch => {
  dispatch({ type: REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE })

  try {
    const resultado = await api.removerInfeccaoDoTratoUrinarioDoPaciente(identificadorDoPaciente, identificadorDaInfeccaoDoTratoUrinario)

    dispatch(removeuInfeccaoDoTratoUrinarioDoPaciente(identificadorDoPaciente))
    dispatch(listarInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverInfeccaoDoTratoUrinarioDoPaciente(erro, { identificadorDoPaciente, identificadorDaInfeccaoDoTratoUrinario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover infecção do trato urinário do paciente.')))

    return false
  }
}