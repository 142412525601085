import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS
} from '../tipos'


const listouProfissoesDoRelatorioDeHorasDosProfissionais = profissoes => ({
  type: LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  profissoes
})

const erroAoListarProfissoesDoRelatorioDeHorasDosProfissionais = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  erro
})

export const listarProfissoesDoRelatorioDeHorasDosProfissionais = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS })

  try {
    const resultado = await api.listarProfissoes()

    dispatch(listouProfissoesDoRelatorioDeHorasDosProfissionais(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissoesDoRelatorioDeHorasDosProfissionais(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissões do relatório de horas dos profissionais.')))
  }
}

const fezDownloadDoRelatorioDeHorasDosProfissionais = filtros => ({
  type: FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  filtros
})

const erroAoFazerDownloadDoRelatorioDeHorasDosProfissionais = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  erro,
  parametros
})

export const fazerDownloadDoRelatorioDeHorasDosProfissionais = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS })

  try {
    const resultado = await api.fazerDownloadDoRelatorioDeHorasDosProfissionais(filtros)

    dispatch(fezDownloadDoRelatorioDeHorasDosProfissionais(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
 
    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoRelatorioDeHorasDosProfissionais(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do relatório de horas dos profissionais.')))

    return null
  }
}