import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_RESPOSTAS_DO_PHQ9,
  LISTOU_RESPOSTAS_DO_PHQ9,
  ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9,
} from './tipos'

const listouRespostasDoPhq9 = respostasDoPhq9 => ({
  type: LISTOU_RESPOSTAS_DO_PHQ9,
  respostasDoPhq9,
})

const erroAoListarRespostasDoPhq9 = erro => ({
  type: ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9,
  erro,
})

export const listarRespostasDoPhq9 = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_RESPOSTAS_DO_PHQ9 })

    try {
      const resultado = await api.listarRespostasDoPhq9()

      dispatch(listouRespostasDoPhq9(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarRespostasDoPhq9(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as respostas do PHQ9.')))
    }
  }

  acao.meta = {
    cache: LISTAR_RESPOSTAS_DO_PHQ9
  }

  return acao
}