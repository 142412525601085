import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from '../../spinner'
import { CheckLg, XLg } from '../../../design/icones'
import loading from '../../../design/img/loading-lote.gif'
import { ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM } from '../../../acoes/tipos'
import { assinarAtendimentoComCertificadoEmNuvem } from '../../../acoes/lacuna'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'

export default function AssinaturaDigitalEmLote({ fecharModal, dados }) {
  const dispatch = useDispatch()

  const [atendimentosAssinados, setAtendimentosAssinados] = useState([])

  const adicionarAtendimentoAssinado = useCallback((identificador, assinou) => {
    setAtendimentosAssinados([...atendimentosAssinados, { identificador, assinou }])
  }, [atendimentosAssinados])

  useEffect(() => {
    (async () => {
      const proximoAtendimento = dados.find(d => !atendimentosAssinados.some(a => a.identificador === d.identificador))

      if (proximoAtendimento) {
        const assinou = await dispatch(assinarAtendimentoComCertificadoEmNuvem(proximoAtendimento.identificador, true))
        adicionarAtendimentoAssinado(proximoAtendimento.identificador, assinou)
      }

    })()
  }, [dispatch, dados, adicionarAtendimentoAssinado, atendimentosAssinados])

  return (
    <div className='main-content-form'>
      <div className='form mt-2 resumo-do-atendimento'>
        <div className='assinatura-digital-atendimento'>
          <h2 className='form-title'>Assinar em Lote com Certificado em Nuvem</h2>
          <hr className='separator mt-1'></hr>
          <Spinner operacoes={[ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM]}>
            {({ processando }) => (
              <>
                <div className='box-panel__em-lote'>
                  {dados.map(item => (
                    <div
                      className={'box-panel__sidebar__item'}
                      key={item.identificador}
                    >
                      {!atendimentosAssinados.some(a => a.identificador === item.identificador) && <img className='user-doctor-lote' src={loading} alt='Assinando...' />}
                      {atendimentosAssinados.some(a => a.identificador === item.identificador && a.assinou) && <CheckLg className='user-doctor-lote' />}
                      {atendimentosAssinados.some(a => a.identificador === item.identificador && !a.assinou) && <XLg className='user-doctor-lote --erro' />}
                      <div className='evolution-info'>
                        <p className='evolution-info__data'>{formatarDataEHoraParaFormatoLocal(item.inicio)}{' - '}{item.unidade.nome}</p>
                        <p className='evolution-info__data'><strong>{item.tipo.nome}</strong></p>
                        <p className='evolution-info__name'>{item.paciente.nome}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='list-btn'>
                  <button className={`button --primary ${processando && 'is-loading'}`} onClick={fecharModal}>Fechar</button>
                </div>
              </>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}