import chroma from 'chroma-js'
import React from 'react'

const corDaUrgencia = codigo => {
  if (!codigo) return ''

  switch (codigo) {
    case 'vermelho':
      return '#BD3035'
    case 'amarelo':
      return '#F9C72D'
    case 'verde':
      return '#70C968'
    default:
      return ''
  }
}

export default function Resultado({codigo}) {
  const resultado = codigo

  if (!resultado) {
    return null
  }

  const cor = corDaUrgencia(resultado)
  const color = cor && chroma(cor)
  const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

  return (
    <span
      className={'resultado-triagem-titulo  card-tipo'}
      style={{background: cor, color: corDaFonte}}
    >
      {resultado}
    </span>
  )
}