import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import moment from 'moment'
import Breadcrumb from '../breadcrumb'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import { Link } from 'react-router-dom'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, YearPicker, Input } from '../formik/formulario'

import { LISTAR_FERIADOS } from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
            <div className='header-page-tools'>
              <Permissao permissoes={[permissoes.ADICIONAR_FERIADO]}>
                <Link
                  to={`/configuracoes/feriados/novo`}
                  className='button --primary --plus'
                >
                  Adicionar
                </Link>
              </Permissao>
            </div>
          </div>
          <FormularioDoFormik
            acao={alterarFiltros}
            valoresIniciais={{
              ano: filtros.ano,
              nome: filtros.nome
            }}
            esquemaDeValidacoes={Yup.object().shape({
              ano: Yup.string().nullable().required('Obrigatório')
            })}
          >
            <div className='mt-1'>
              <r-grid columns-md={12} columns-lg={12}>
                <r-cell span={4} span-md={2} span-lg={2}>
                  <YearPicker
                    nome='ano'
                    titulo='Ano *'
                    tabIndex={1}
                  />
                </r-cell>
                <r-cell span={4} span-md={4} span-lg={4}>
                  <Input
                    nome='nome'
                    titulo='Nome'
                    tabIndex={2}
                  />
                </r-cell>
                <r-cell span={4} span-md={2} span-lg={2}>
                  <button className='button --primary w-100 minw-auto alinha-botao' type='submit'>Pesquisar</button>
                </r-cell>
              </r-grid>
            </div>
          </FormularioDoFormik>
          <Spinner operacoes={[LISTAR_FERIADOS]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_FERIADO]}>
                    <Link
                      to={`/configuracoes/feriados/${item.identificador}`}
                      className='table-items__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </Link>
                  </Permissao>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                campoDeDados='nome'
                className='col-xs-8 col-md-10 col-lg-4'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-2'
                exibirOrdenacao={true}
                nome='Data'
                campoDeDados='data'
                renderizar={item => moment(item.data).format('DD/MM/YYYY')}
              />
              <Coluna
                campoDeDados='serie'
                className='col-lg-2'
                nome='Recorrente'
                renderizar={item => <p className='table-items__mobile-value'>{item.serie?.tipoDeRepeticao ? 'Sim' : 'Não'}</p>}
              />
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Observações'
                campoDeDados='observacoes'
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}