import React from 'react'
import { Stethoscope } from '../../../../design/icones'

export default function Responsavel({ valor }) {
  if (!valor) return null
  
  return (
    <div className='card-cabecalho'>
      <div className='icon'><Stethoscope size='18' /></div>
      <div className='valor'>{valor}</div>
    </div>
  )
}