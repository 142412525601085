import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_PROGRAMAS,
  LISTOU_PROGRAMAS,
  ERRO_AO_LISTAR_PROGRAMAS,
  ADICIONOU_PROGRAMA,
  ERRO_AO_ADICIONAR_PROGRAMA,
  ADICIONAR_PROGRAMA,
  RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  ALTEROU_PROGRAMA,
  ERRO_AO_ALTERAR_PROGRAMA,
  ALTERAR_PROGRAMA,
  REMOVEU_PROGRAMA,
  ERRO_AO_REMOVER_PROGRAMA,
  REMOVER_PROGRAMA
} from './tipos'

const listouProgramas = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_PROGRAMAS,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarProgramas = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS,
  erro,
})

export const listarProgramas = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS })

  try {
    const resultado = await api.listarProgramas(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouProgramas(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
    
  } catch (erro) {
    dispatch(erroAoListarProgramas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os programas.')))
  }
}

const adicionouPrograma = programa => ({
  type: ADICIONOU_PROGRAMA,
  programa
})

const erroAoAdicionarPrograma = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PROGRAMA,
  erro,
  parametros
})

export const adicionarPrograma = programa => async dispatch => {
  dispatch({ type: ADICIONAR_PROGRAMA })

  try {
    const resultado = await api.adicionarPrograma(programa)

    dispatch(adicionouPrograma(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPrograma(erro, { programa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o Programa.')))

    return false
  }
}

const recuperouProgramaPeloIdentificador = programa => ({
  type: RECUPEROU_PROGRAMA_PELO_IDENTIFICADOR,
  programa
})

const erroAoRecuperarProgramaPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarProgramaPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarProgramaPeloIdentificador(identificador)

    dispatch(recuperouProgramaPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarProgramaPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o programa por identificador.')))
  }
}

const alterouPrograma = programa => ({
  type: ALTEROU_PROGRAMA,
  programa
})

const erroAoAlterarPrograma = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PROGRAMA,
  erro,
  parametros
})

export const alterarPrograma = programa => async dispatch => {
  dispatch({ type: ALTERAR_PROGRAMA })

  try {
    const resultado = await api.alterarPrograma(programa)

    dispatch(alterouPrograma(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPrograma(erro, { programa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o programa.')))

    return false
  }
}

const removeuPrograma = programa => ({
  type: REMOVEU_PROGRAMA,
  programa
})

const erroAoRemoverPrograma = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PROGRAMA,
  erro,
  parametros
})

export const removerPrograma = identificador => async dispatch => {
  dispatch({ type: REMOVER_PROGRAMA })

  try {
    const resultado = await api.removerPrograma(identificador)

    dispatch(removeuPrograma(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPrograma(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o programa.')))

    return false
  }
}