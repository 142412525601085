import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
} from './tipos'

const listouTiposDeAtividadeFisicaDeLazer = tiposDeAtividadeFisicaDeLazer => ({
  type: LISTOU_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  tiposDeAtividadeFisicaDeLazer,
})

const erroAoListarTiposDeAtividadeFisicaDeLazer = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  erro,
})

export const listarTiposDeAtividadeFisicaDeLazer = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER })

    try {
      const resultado = await api.listarTiposDeAtividadeFisicaDeLazer()

      dispatch(listouTiposDeAtividadeFisicaDeLazer(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTiposDeAtividadeFisicaDeLazer(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de atividade física de lazer.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER
  }

  return acao
}