import { connect } from 'react-redux'
import acoes from '../../acoes'
import Feriados from './index'

const mapStateToProps = state => {
  const feriadoAtual = state.feriados.itemAtual

  let feriado = {    
    nome: '',
    data: '',
    observacoes: '',
    serie: {
      tipoDeRepeticao: false
    }
  }

  if (feriadoAtual) {
    feriado.identificador = feriadoAtual.identificador    
    feriado.nome = feriadoAtual.nome
    feriado.data = feriadoAtual.data
    feriado.observacoes = feriadoAtual.observacoes
    feriado.serie = {
      ...feriadoAtual.serie,
      TipoDeRepeticao: feriadoAtual?.serie?.tipoDeRepeticao ? true : false
    }
  }

  return {
    filtros: state.feriados.filtros,
    ordenacao: state.feriados.ordenacao,
    paginaDeDados: state.feriados.paginaDeDados,
    feriado,
  }
}

export default connect(mapStateToProps, acoes)(Feriados)