import api from '../../api-major'

const listarHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, filtros = {}) => {
  const paginacao = 'pagina=1'
  const parametroTamanhoDaPagina = `&tamanhoDaPagina=1000`
  const parametroOrdem = filtros && filtros.ordem ? `&ordem=${filtros.ordem}` : '&ordem=+data,+inicio'
  const parametroData = filtros && filtros.data ? `&data=${filtros.data}` : ''

  return api.get(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios?${paginacao}${parametroOrdem}${parametroTamanhoDaPagina}${parametroData}`)
}

const adicionarHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, horario) => {
  return api.post(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios`, horario)
}

const alterarHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, horario) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios/${horario.identificador}`, horario)
}

const excluirHorarioDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificador) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios/${identificador}`)
}

const excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDaInstrucao) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios/${identificadorDaInstrucao}/serie`)
}

const alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude = (identificadorDoProfissional, serie) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios/${serie.identificador}/serie`, serie)
}


export default {
  adicionarHorarioDaAgendaDoProfissionalDeSaude,
  alterarHorarioDaAgendaDoProfissionalDeSaude,
  alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude,
  excluirHorarioDaAgendaDoProfissionalDeSaude,
  excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude,
  listarHorariosDaAgendaDoProfissionalDeSaude
}
