import {LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO: {
      return action.planosDeSaude
    }

    default: {
      return state
    }
  }
}