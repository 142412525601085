import React, { createContext, useState, useContext, useEffect } from 'react'

const tabContext = createContext()

export default function ContextoDasAbas({ children, abaInicial = 0, ajusteRedirecionamento }) {
  const [indexAtivo, setIndexAtivo] = useState(abaInicial)

  useEffect(() => {
    if (ajusteRedirecionamento && indexAtivo !== abaInicial) {
      setIndexAtivo(abaInicial)
    }
  }, [indexAtivo, abaInicial, ajusteRedirecionamento])
  
  return (
    <tabContext.Provider
      value={{
        indexAtivo,
        selecionarIndexAtivo: setIndexAtivo
      }}
    >
      {children}
    </tabContext.Provider>
  )
}

export function useAbas() {
  const context = useContext(tabContext)
  if (!context) throw new Error('useAbas deve ser utilizado entre ContextoDasAbas')
  const { indexAtivo, selecionarIndexAtivo } = context
  return { indexAtivo, selecionarIndexAtivo }
}