import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import { 
  LISTAR_MEDICOS_DO_PACIENTE, 
  LISTOU_MEDICOS_DO_PACIENTE, 
  ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE, 
} from '../tipos'

const listouMedicosDoPaciente = medicos => ({
    type: LISTOU_MEDICOS_DO_PACIENTE,
    medicos,
})

const erroAoListarMedicosDoPaciente = erro => ({
    type: ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE,
    erro,
})

export const listarMedicosDoPaciente = () => async dispatch => {
    dispatch({ type: LISTAR_MEDICOS_DO_PACIENTE })

    try {
        let resultado = await api.listarMedicos()

        dispatch(listouMedicosDoPaciente(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoListarMedicosDoPaciente(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os medicos do paciente.')))
    }
}