import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
} from '../tipos'

const listouMotivosDeCancelamentoDoConvenio = motivos => ({
  type: LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  motivos
})

const erroAoListarMotivosDeCancelamentoDoConvenio = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  erro
})

export const listarMotivosDeCancelamentoDoConvenio = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO })
  
  try {
    const resultado = await api.listarMotivosDeCancelamentoDoConvenio()
    
    dispatch(listouMotivosDeCancelamentoDoConvenio(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeCancelamentoDoConvenio(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de cancelamento do convênio.')))
  }
}