import { LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS } from '../../acoes/tipos'

export default (state, action) => {
  switch (action.type) {
    case LISTOU_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS: {
      return action.motivosDeCancelamento
    }

    default: {
      return state
    }
  }
}