import React, { useState } from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker, Input } from '../../../formik/formulario'
import { FieldArray } from 'formik'
import {dataIgualOuAnterior, formatarDataEHoraParaFormatoLocal} from '../../../../bibliotecas/data'
import * as Yup from 'yup'
import moment from 'moment'
import {
  ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO
} from '../../../../acoes/tipos'

export default function Formulario({
  fechar,
  status,
  motivos,
  estabelecimentosDeSaude,
  selecionada,
  ...rest
}) {

  const [exibirDescricoes, setExibirDescricoes] = useState(false)

  const salvar = async (valores, { resetForm }) => {
    const dados = { ...selecionada, ...valores }

    const salvou = await rest.salvar(dados)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const removerMotivosAdicionados = (motivosAdicionados = [], motivos = []) => {
    if (motivosAdicionados.length === 0) return motivos

    const filtro = motivo => {
      return motivosAdicionados.findIndex(x => x === motivo.nome) === -1
    }

    return motivos.filter(filtro)
  }

  const selecionarStatus = (status, setFieldValue) => {
    if (status === 'Em observação' || status === 'Informado pela operadora') {
      setFieldValue('fim', '')
    }
  }

  const adicionarMotivos = (motivoSelecionado, setFieldValue, arrayHelpers) => {
    arrayHelpers.push(motivoSelecionado)
    setFieldValue('motivo', '')
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
      ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
      LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
      LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
      LISTAR_ESTABELECIMENTOS_DE_SAUDE
    ]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionada ? selecionada.identificador : '',
          status: selecionada ? selecionada.status : '',
          inicio: selecionada ? selecionada.inicio : '',
          fim: selecionada ? selecionada.fim : '',
          estabelecimentoDeSaude: selecionada ? selecionada.estabelecimentoDeSaude : '',
          motivos: selecionada ? selecionada.motivos : [],
          descricao: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          status: Yup.string().required('Obrigatório.'),
          estabelecimentoDeSaude: Yup.string().required('Obrigatório.'),
          descricao: Yup.string()
            .when('status', {
              is: val => val !== 'Informado pela operadora',
              then: Yup.string().required('Obrigatório')
            }),
          inicio: Yup.string().required('Obrigatório.').nullable()
            .when('status', {
              is: val => val !== 'Em observação',
              then: Yup.string().required('Obrigatório.').nullable()
                .test(
                  "fim_teste",
                  "Deve ser menor que a data de saída.",
                  function (value) {
                    const { fim } = this.parent;
                    return !value || dataIgualOuAnterior(value, fim);
                  }
                )
            }),
          fim: Yup.string().nullable()
            .when('status', {
              is: val => val !== 'Em observação',
              then: Yup.string().required('Obrigatório.').nullable()
                .test(
                  "fim_teste",
                  "Deve ser maior que a data de admissão",
                  function (value) {
                    const { inicio } = this.parent;
                    return !value || dataIgualOuAnterior(inicio, value);
                  }
                ),
            })
        })}
        acao={salvar}
      >
        {({ values, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>{selecionada ? 'Alterar Ida Ao Pronto Atendimento' : 'Adicionar Ida Ao Pronto Atendimento'} </h2>
            <r-grid columns-md={12} columns-lg={12}>
              <r-cell span={4} span-md={4} span-lg={4}>
                <Select
                  nome='status'
                  titulo='Status *'
                  tabIndex={1}
                  itens={status}
                  campoCodigo='nome'
                  campoDescricao='nome'
                  onChange={item => selecionarStatus(item, setFieldValue)}
                />
              </r-cell>
              <r-cell span={2} span-md={4} span-lg={4}>
                <DatePicker
                  nome='inicio'
                  titulo='Data Admissão *'
                  tabIndex={2}
                  maxDate={new Date()}
                />
              </r-cell>
              {!(values.status === 'Em observação') &&
                <r-cell span={2} span-md={4} span-lg={4}>
                  <DatePicker
                    nome='fim'
                    titulo='Data da Saída *'
                    tabIndex={3}
                    minDate={moment(values.inicio).toDate()}
                    maxDate={new Date()}
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={12} span-lg={12}>
                <Select
                  nome='estabelecimentoDeSaude'
                  titulo='Estabelecimento de Saúde *'
                  tabIndex={4}
                  itens={estabelecimentosDeSaude}
                  campoCodigo='nome'
                  campoDescricao='nome'
                />
              </r-cell>
              <r-cell span={4} span-md={10} span-lg={10}>
                <Select
                  nome='motivo'
                  titulo={values.status !== 'Informado pela operadora' ? 'Motivo *' : 'Motivo'}
                  tabIndex={5}
                  itens={removerMotivosAdicionados(values.motivos, motivos)}
                  campoCodigo='nome'
                  campoDescricao='nome'
                />
              </r-cell>
              <FieldArray
                name='motivos'
                render={arrayHelpers => (
                  <>
                    <r-cell span={4} span-md={2} span-lg={2}>
                      <button
                        type='button'
                        className={values.motivo ? 'button --primary w-100 minw-auto mt-24' : 'button --primary is-disabled w-100 minw-auto mt-24'}
                        tabIndex={6}
                        onClick={() => adicionarMotivos(values.motivo, setFieldValue, arrayHelpers)}
                      >
                        Adicionar
                      </button>
                    </r-cell>
                    {values.motivos && values.motivos.length > 0 &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        {values.motivos.map((motivo, index) =>
                          <div className='form-choice' key={index}>
                            <div className='form-choice__item --full'>{motivo}</div>
                            <button className='form-choice__bt-close' type='button' onClick={() => arrayHelpers.remove(index)}><i className='icon icon-close'></i></button>
                          </div>
                        )}
                      </r-cell>
                    }
                  </>
                )}
              />
              {selecionada && selecionada.descricoes.length > 0 &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  <div className='form-choice'>
                    <div className='form-choice__item --full'><strong>Ver descrições anteriores</strong></div>
                    <button
                      className='indicator-tooltip form-choice__bt-close'
                      type='button'
                    >
                      <i className={`icon-arrow${exibirDescricoes ? '-up' : '-down'} ml-0`}
                        onClick={() => setExibirDescricoes(!exibirDescricoes)}
                      ></i>
                    </button>
                  </div>
                  {exibirDescricoes && selecionada.descricoes.map((descricao, index) =>
                      <div className='form-choice pr-0' key={index}>
                        <div className='form-choice__item --full'>
                          <span><em>{descricao.usuario.nome}</em>{descricao.usuario.profissao ? <> | <em>{descricao.usuario.profissao.nome}</em></> : ''}</span> <br />
                          <span><em>Data e Hora: {formatarDataEHoraParaFormatoLocal(descricao.dataEHora)}</em></span> <br />
                          <hr className='separator my-1' style={{ backgroundColor: '#528ce3' }} />
                          <pre>{descricao.descricao}</pre>
                        </div>
                      </div>
                  )}
                </r-cell>
              }
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  titulo={values.status !== 'Informado pela operadora' ? 'Descrição *' : 'Descrição'}
                  nome='descricao'
                  tabIndex={7}
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
              <button type='submit' className='button --primary' disabled={values.status !== 'Informado pela operadora' && values.motivos.length === 0}>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}