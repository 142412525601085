import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Setores(props) {
  const {
    adicionarSetor,
    setor,
    filtros,
    history,
    listarSetores,
    match,
    ordenacao,
    paginaDeDados,
    recuperarSetorPeloIdentificador,
    removerSetor,
    alterarSetor
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
    }

    const acao = dados.identificador ? alterarSetor : adicionarSetor
    const executou = await acao(dados)

    if (executou) {
      history.push(`/configuracoes/setores`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_SETORES]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarSetores}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_SETOR]}>
              <Formulario
                {...props}
                salvar={salvar}
                setor={setor}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_SETOR]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarSetorPeloIdentificador}
                salvar={salvar}
                setor={setor}
                remover={removerSetor}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}