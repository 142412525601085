import React, { useState, useRef } from 'react'
import { useProntuario } from '../../contexto'
import FileSaver from 'file-saver'
import { formatarDataParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import arquivoPdf from '../../../../design/img/pdf-file.svg'
import outrosArquivos from '../../../../design/img/file.svg'

import { LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function ResumoDeExames({ valor, identificadorDoPaciente, identificadorDoAtendimento, ativaSpinner }) {
  const { fazerDownloadDoExameDoAtendimento, arquivosDoExame } = useProntuario()
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)

  if (!valor) return null

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <Spinner operacoes={[LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE]}>
        {({ processando }) => (
          <Tabela
            acoes={[
              item => item.atendimento && (
                <div key='informacao'>
                  <button
                    key={`info_${item.identificador}`}
                    className='table-items-expanded__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                  </button>
                  {(item.identificador === exibirTooltipInfo) &&
                    <div className='tooltip__drop custom-scrollbar' style={{ height: !item.sintese && 'auto' }}>
                      <div className='tooltip__drop__body'>
                        <div className='tooltip__drop__title border-bottom-none'>
                          Histórico
                        <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i></button>
                        </div>
                        <div className='tooltip__drop__description'>
                          <div className='border-top-active' key={item.identificador} >
                            {item.tipo ? <><strong>Tipo:</strong> {item.tipo} <br /></> : <><strong>Tipo:</strong> Exame Genérico <br /></>}
                            <span><em>Atendimento: {item.atendimento.tipo.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(item.atendimento.inicio)}</em></span><br />
                            <span>{item.atendimento.usuario.profissao ? <><em>{item.atendimento.usuario.profissao.nome}</em> | <em>{item.atendimento.usuario.nome}</em></> : <em>{item.atendimento.usuario.nome}</em>}</span> <br />
                            {item.sintese &&
                              <>
                                <strong>Síntese:</strong><br />
                                {item.sintese}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ),
            ]}
            chave='identificador'
            className={processando && ativaSpinner ? 'is-loading abas-qgcc' : '--secondary'}
            dados={valor}
            expandida={true}
            titulo={ativaSpinner ? (!processando ? valor.length > 0 : !processando) : true}
            exibirMensagem={!processando}
            mensagemTabelaVazia='Não existem registros de exames.'
          >
            <Coluna
              className='col-lg-4'
              nome='Tipo'
              principal={true}
              exibirTitulo={true}
              renderizar={item => item.tipo ? item.tipo : 'Exames Genéricos'}
            />
            <Coluna
              className='col-lg-2'
              nome='Data'
              renderizar={item => item.data ? formatarDataParaFormatoLocal(item.data) : ''}
            />
            <LinhaExpandida className='col-xs-10 col-lg-11'>
              <AgrupamentoDeColunas>
                <Coluna
                  icone='icon-doc-text'
                  className='col-lg-4'
                  nome='Tipo'
                  renderizar={item => item.tipo ? item.tipo : 'Exames Genéricos'}
                />
                <Coluna
                  icone='icon-date'
                  nome='Data'
                  className='col-lg-2'
                  renderizar={item => item.data ? formatarDataParaFormatoLocal(item.data) : ''}
                />
                <Coluna
                  icone='icon-folder-empty'
                  nome='Arquivos'
                  renderizar={item => item.arquivos.length > 0 &&
                    <Arquivos
                      key={`arquivo_${item.arquivos[0].arquivo}`}
                      registro={item}
                      arquivosDoExame={arquivosDoExame}
                      fazerDownloadDoExameDoAtendimento={fazerDownloadDoExameDoAtendimento}
                      identificadorDoPaciente={identificadorDoPaciente}
                      identificadorDoAtendimento={identificadorDoAtendimento}
                    />
                  }
                />
                <Coluna
                  icone='icon-doc-text'
                  nome='Síntese'
                  className='table-items-expanded-100__box-item'
                  renderizar={item => item.sintese && <pre>{item.sintese}</pre>}
                />
              </AgrupamentoDeColunas>
            </LinhaExpandida>
          </Tabela>
        )}
      </Spinner>
    </r-cell>
  )
}

function Arquivos({ registro, arquivosDoExame, fazerDownloadDoExameDoAtendimento, identificadorDoPaciente, identificadorDoAtendimento }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDoArquivo = useRef(null)
  const width = registro.arquivos.length > 3 ? 381 : registro.arquivos.length * 127
  const posicaoAEsquerda = referenciaDoArquivo.current && referenciaDoArquivo.current.offsetLeft
  const imagens = ['.JPG', '.JPEG', '.PNG', '.JFIF']

  useCliqueForaDoElemento(referenciaDoArquivo, () => setVisivel(false))

  if (!registro) return

  const abrirTooltipArquivo = item => {
    if (visivel === item.arquivos[0].identificador) {
      setVisivel(false)
    } else {
      setVisivel(item.arquivos[0].identificador)
      if (item && item.atendimento && item.arquivos.length > 0) {
        item.arquivos.forEach(x => {
          if (!x.url) {
            return fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, item.atendimento.identificador, x.identificador)
          }
        })
      } else if (item && !item.atendimento && item.arquivos.length > 0) {
        item.arquivos.forEach(x => {
          if (!x.url) {
            return fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, x.identificador)
          }
        })
      }
    }
  }

  const recuperarThumbnail = (extensao, url) => {
    if (imagens.find(x => x === extensao)) {
      return <img src={url} alt='exame' />
    } else if (extensao === '.PDF') {
      return <img src={arquivoPdf} alt='exame' />
    } else {
      return <img src={outrosArquivos} alt='exame' />
    }
  }

  const fazerDownload = async exame => {
    const identificadorDoAtendimentoDoExame = identificadorDoAtendimento ?  identificadorDoAtendimento : registro.atendimento.identificador
    const dados = await fazerDownloadDoExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimentoDoExame, exame.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), exame.nome)
    }
  }

  return (
    <div ref={referenciaDoArquivo}>
      <button type='button' className='p-0'>
        <i className={`icon-folder-empty text${visivel ? '-primary' : '-secondary'}`}
          onClick={() => abrirTooltipArquivo(registro)}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop tooltip-sumario custom-scrollbar' style={{ width: width, left: posicaoAEsquerda }}>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          <div className='tooltip__antibioticos_drop__body'>
            <div className='tooltip__antibioticos_drop__description'>
              <div className='form-summary'>
                <div className='form-summary-images'>
                  {registro.arquivos.map((x, index) => {
                    const url = arquivosDoExame && arquivosDoExame[x.identificador] ? arquivosDoExame[x.identificador].url : x.url
                    const extensao = x.extensao.toUpperCase()
                    return (
                      <div key={index}>
                        <>
                          {url ?
                            <>
                              <div className='form-summary-image exames'>
                                {recuperarThumbnail(extensao, url)}
                                <div className='form-summary-image-tools'>
                                  <button className='form-summary-image-zoom' aria-label='Download' type='button' onClick={() => fazerDownload(x)}><i className='icon-download-cloud'></i></button>
                                </div>
                              </div>
                              <p className='exames nome' title={x.nome}>{x.nome}</p>
                            </>
                            : 'Carregando...'
                          }
                        </>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}