import api from '../api-hercules'

const adicionarComentarioDaTarefa = (identificadorDaTarefa, comentario) => api.post(`/tarefas/${identificadorDaTarefa}/comentarios`, comentario)
const alterarComentarioDaTarefa = (identificadorDaTarefa, identificadorDoComentario, comentario) => api.put(`/tarefas/${identificadorDaTarefa}/comentarios/${identificadorDoComentario}`, comentario)
const removerComentarioDaTarefa = (identificadorDaTarefa, identificadorDoComentario) => api.delete(`/tarefas/${identificadorDaTarefa}/comentarios/${identificadorDoComentario}`)

export default {
  adicionarComentarioDaTarefa,
  alterarComentarioDaTarefa,
  removerComentarioDaTarefa,
}