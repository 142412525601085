import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-modal'
import { salvarPaciente } from '../../../acoes/pacientes/index'
import { ERRO_AO_SALVAR_PACIENTE } from '../../../acoes/tipos'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import '../style.css'

export default function NotificacaoAtendimentoAposOObito({ notificacao }) {
  const dispatch = useDispatch()
  const [exibirModal, setExibirModal] = useState(false)

  const { metaDados } = notificacao
  const erros = useSelector(state => state.erros)
  const paciente = erros[ERRO_AO_SALVAR_PACIENTE].parametros.paciente

  useEffect(() => {
    if (notificacao) {
      setExibirModal(true)
    }
  }, [notificacao])

  const sim = (paciente) => {
    dispatch(salvarPaciente({
      ...paciente,
      confirmarOObito: true
    }))
    setExibirModal(false)
  }

  const nao = () => {
    setExibirModal(false)
  }

  const todosAtendimentosDoPaciente = useMemo(() => {
    const atendimentos = Object.keys(metaDados).filter(key => key.startsWith('atendimento_')).map(key => metaDados[key])
    const horarios = Object.keys(metaDados).filter(key => key.startsWith('horario_')).map(key => metaDados[key])
    const tipos = Object.keys(metaDados).filter(key => key.startsWith('tipo_')).map(key => metaDados[key])
    const usuarios = Object.keys(metaDados).filter(key => key.startsWith('usuario_')).map(key => metaDados[key])

    const resultado = atendimentos.map((atendimento, index) => {
      return { atendimento, horario: horarios[index], tipo: tipos[index], usuario: usuarios[index] }
    })

    return resultado;
  }, [metaDados])

  return (
    <Modal
      isOpen={exibirModal}
      className='notificacao_com_intermedio_do_usuario__modal'
    >
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={12}>
          <div className='form-choice-alerta my-1'>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <h2>
                O paciente {metaDados.nome_do_paciente} possui{' '}
                {todosAtendimentosDoPaciente.length === 1 ? 'o' : 'os'} seguinte
                {todosAtendimentosDoPaciente.length === 1 ? '' : 's'} atendimento
                {todosAtendimentosDoPaciente.length === 1 ? '' : 's'} após a data do óbito:
              </h2>
              {todosAtendimentosDoPaciente.map((at) => (
                <ul key={at.atendimento}>
                  <li>{at.tipo}: {at.usuario} em {at.horario}</li>
                </ul>
              ))}
            </div>
          </div>
          <h2>{`Confirma a data do óbito em ${formatarDataParaFormatoLocal(paciente.dataDoObito)}?`}</h2>
          <div className='list-btn'>
            <button className='button --light' type='button' onClick={nao}>Não</button>
            <button className='button --danger' type='button' onClick={() => sim(paciente)}>Sim</button>
          </div>
        </r-cell>
      </r-grid>
    </Modal>
  )
}