import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import StickyBox from 'react-sticky-box'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import { Aba, Abas, ListaDeAbas, Paineis, Painel } from './abas'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Formulario from './formulario'
import Resumo from './resumo'
import AlertaDeSaida from './alerta-de-saida'
import TempoDoAtendimento from '../tempo-do-atendimento'
import { useProntuario } from '../contexto'
import { useMemed } from '../../../configuracoes/memed'
import Spinner from '../../spinner'
import { SALVAR_SECAO_DO_ATENDIMENTO } from '../../../acoes/tipos'

export default function Atendimento(props) {
  const {
    abrirCancelarAtendimento,
    abrirFinalizarAtendimento,
    abrirAssinaturaComCertificadoFisico,
    abrirAssinaturaComCertificadoEmNuvem,
    atendimento,
    historicoDeCampos,
    recuperarVersaoDoTipoDeAtendimento,
    salvarSecaoDoAtendimento,
    tiposDeAtendimento,
    setStatusDaSecao,
    statusDaSecao,
    finalizarAtendimento,
    exibirFinalizarAtendimento,
    fecharFinalizarAtendimento,
    history,
    tokensDeSugestao,
    abrirAdicionarTemplate,
    reiniciarMemed,
    setReiniciarMemed,
    recuperarCertificadosEmNuvemDoUsuario,
    recuperarPrescricaoPdfNaMemed,
    recuperarTeleconsultaDoAtendimento,
    paciente,
    teleconsultaDoAtendimento,
  } = props

  const { limparPrescricaoMemed } = useProntuario()
  const [idForm, setIdForm] = useState(null)
  const [tipo, setTipo] = useState(null)
  const [secao, setSecao] = useState('')
  const [dirty, setDirty] = useState(false)
  const { logOut } = useMemed()
  const tipoDoAtendimento = atendimento && atendimento.tipo

  const controlarDados = (secao, isDirty) => {
    setSecao(secao)
    setDirty(isDirty)
  }

  useEffect(() => {
    if (reiniciarMemed) {
      setReiniciarMemed(false)
      limparPrescricaoMemed()
      logOut()
    }
  }, [reiniciarMemed, logOut, limparPrescricaoMemed, setReiniciarMemed])

  useEffect(() => {
    if (secao) {
      setStatusDaSecao(statusDaSecao =>
        dirty ?
          statusDaSecao.includes(secao) ? statusDaSecao : [...statusDaSecao, secao]
          : statusDaSecao.filter(x => x !== secao))
    }
  }, [secao, dirty, setStatusDaSecao])

  useEffect(() => {
    if (tipoDoAtendimento) {
      const encontrado = tiposDeAtendimento[tipoDoAtendimento.nome]
      const versao = encontrado && encontrado.versoes[tipoDoAtendimento.versao]

      if (versao) {
        setTipo(versao)
        if (idForm === null) setIdForm(versao.secoes[0].nome)
      } else if (encontrado) {
        recuperarVersaoDoTipoDeAtendimento(tipoDoAtendimento.identificador, tipoDoAtendimento.versao)
      }
    }
  }, [tipoDoAtendimento, tiposDeAtendimento, recuperarVersaoDoTipoDeAtendimento, idForm])

  if (!tipo) {
    return null
  }

  const CabecalhoProntuarioAtendimento = () => (
    <div className='cabecalho-do-atendimento'>
      <div className='informacoes-do-paciente-no-cabecalho-do-atendimento'>
        <strong>{atendimento.paciente.nome}</strong> | {atendimento.tipo.nome}
      </div>
      <div className='header-page-bar mt-0'>
        <div className='header-page-nav'>
          <ListaDeAbas statusDaSecao={statusDaSecao} className='tab'>
            {tipo.secoes.map((secao, index) => <Aba key={`tab_${secao.nome}`} aoClicar={() => setIdForm(secao.nome)} index={index}>{secao.nome}</Aba>)}
          </ListaDeAbas>
        </div>
        <div className='header-page-tools d-lg-flex d-none'>
          <Permissao permissoes={[permissoes.CANCELAR_ATENDIMENTO_INICIADO]}>
            <button className='button --danger minw-auto' onClick={abrirCancelarAtendimento}>Cancelar</button>
          </Permissao>
          <Spinner operacoes={[SALVAR_SECAO_DO_ATENDIMENTO]}>
            {({ processando }) => (
              <span className='tooltip-botoes' title='Atalho: ctrl + s'>
                <button
                  id={`salvarformulario_${idForm}`}
                  form={`formulario_${idForm}`}
                  type='submit'
                  className={`button --primary ${!statusDaSecao.includes(idForm) || processando ? 'is-disabled' : ''}`}>
                  Salvar
                </button>
              </span>
            )}
          </Spinner>
          <span className='tooltip-botoes' title='Atalho: shift + alt + f'>
            <div
              className={`assistence-end botao-finalizar-atendimento ${statusDaSecao.length > 0 ? 'is-disabled' : ''}`}
              onClick={abrirFinalizarAtendimento}
            >
              <span className='assistence-end__label'>Finalizar</span>
              <span className='assistence-end__time'>
                <i className='icon icon-clock'></i>
                {atendimento && <TempoDoAtendimento inicio={atendimento.inicio} />}
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <AlertaDeSaida history={history} exibirPrompt={statusDaSecao.length > 0} />
      <Modal
        isOpen={exibirFinalizarAtendimento}
        className='modal modal-finalizar-atendimento'
        contentLabel='Modal para finalização do atendimento'
      >
        <Resumo
          atendimento={atendimento}
          secoes={tipo.secoes}
          finalizarAtendimento={true}
          fecharModal={fecharFinalizarAtendimento}
          confirmarAtendimento={finalizarAtendimento}
          abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
          abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
          recuperarCertificadosEmNuvemDoUsuario={recuperarCertificadosEmNuvemDoUsuario}
        />
      </Modal>
      <RotaComAutenticacao
        exact
        path={`/cadastros/pacientes/:identificador/prontuario/:identificadorDoAtendimento`}
        render={() => (
          <div className='page-prontuario-atendimento'>
            <Abas>
              <div className='form mt-2'>
                <StickyBox className='cabecalho-fixo-prontuario-atendimentos' offsetTop={0}><CabecalhoProntuarioAtendimento /></StickyBox>
                <fieldset>
                  <Paineis>
                    {tipo.secoes.map(secao =>
                      <Painel key={`painel_${secao.nome}`}>
                        <div className='mt-2'>
                          <Formulario
                            key={`formulario_${secao.nome}`}
                            id={`formulario_${secao.nome}`}
                            secao={secao}
                            atendimento={atendimento}
                            historicoDeCampos={historicoDeCampos}
                            salvar={salvarSecaoDoAtendimento}
                            recuperarPrescricaoPdfNaMemed={recuperarPrescricaoPdfNaMemed}
                            recuperarTeleconsultaDoAtendimento={recuperarTeleconsultaDoAtendimento}
                            componentes={secao.componentes}
                            controlarDadosSalvos={isDirty => controlarDados(secao.nome, isDirty)}
                            setStatusDaSecao={setStatusDaSecao}
                            statusDaSecao={statusDaSecao}
                            tokensDeSugestao={tokensDeSugestao}
                            abrirAdicionarTemplate={abrirAdicionarTemplate}
                            abrirCancelarAtendimento={abrirCancelarAtendimento}
                            abrirFinalizarAtendimento={abrirFinalizarAtendimento}
                            paciente={paciente}
                            ehRemotoPorVideoENaoTemTeleconsulta={(tipo?.metaDados?.modalidade === 'remoto_por_video' && !teleconsultaDoAtendimento?.dados?.links) ? true : undefined}
                            dadosDaTeleconsulta={teleconsultaDoAtendimento?.dados}
                          />
                        </div>
                      </Painel>
                    )}
                  </Paineis>
                </fieldset>
              </div>
            </Abas>
          </div>
        )}
      />
    </>
  )
}