import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Orientacoes(props) {
  const {
    adicionarOrientacao,
    orientacao,
    filtros,
    history,
    listarOrientacoes,
    match,
    ordenacao,
    paginaDeDados,
    recuperarOrientacaoPeloIdentificador,
    removerOrientacao,
    alterarOrientacao,
    adicionarArquivo,
    fazerDownloadDoArquivoDaOrientacao
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
      arquivos: formulario.arquivos.length > 0 ? formulario.arquivos.map(x => x.identificador) : []
    }

    const acao = dados.identificador ? alterarOrientacao : adicionarOrientacao
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/orientacoes`)
    }
  }

  const remover = async item => {
    const removeu = await removerOrientacao(item.identificador)

    if (removeu) {
      history.push(`/cadastros/orientacoes`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_ORIENTACOES]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarOrientacoes}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_ORIENTACAO]}>
              <Formulario
                {...props}
                salvar={salvar}
                orientacao={orientacao}
                adicionarArquivo={adicionarArquivo}
                fazerDownloadDoArquivoDaOrientacao={fazerDownloadDoArquivoDaOrientacao}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_ORIENTACAO]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarOrientacaoPeloIdentificador}
                salvar={salvar}
                orientacao={orientacao}
                remover={remover}
                adicionarArquivo={adicionarArquivo}
                fazerDownloadDoArquivoDaOrientacao={fazerDownloadDoArquivoDaOrientacao}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}