import React, { useMemo, useState } from 'react'
import StickyBox from 'react-sticky-box'
import Spinner from '../../../spinner'
import CampoDePesquisa from '../../../campo-de-pesquisa'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import { contem } from '../../../../bibliotecas/texto'
import { LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Historico({ historico = [], adicionar, adicionarMemed, temMemed, temLifecode, fechar }) {
  const [termo, setTermo] = useState('')  

  const prescricoesFiltradas = useMemo(() => {
    if (!termo) return historico

    const tokens = termo.split(' ').filter(x => x)

    return historico.filter(x => {
      const informacoesDoUsuario = x.atendimento.usuario
      const data = formatarDataParaFormatoLocal(x.atendimento.inicio)

      let baseDaPesquisa = ''
      baseDaPesquisa = data + ' '
      baseDaPesquisa += x.texto + ' '
      baseDaPesquisa += x.origem + ' '
      baseDaPesquisa += (informacoesDoUsuario.nome || '') + ' '

      if (informacoesDoUsuario.profissao) {
        baseDaPesquisa += (informacoesDoUsuario.profissao.nome || '') + ' '
      }

      for (const token of tokens) {
        if (!contem(baseDaPesquisa, token)) {
          return false
        }
      }

      return true
    })
  }, [historico, termo])

  const imprimirMemed = linkExterno => {
    window.open(linkExterno, '_blank')
  }

  return (
    <div className='form'>
      <StickyBox className='pesquisa-historico-de-prescricoes' offsetTop={0}>
        <h2 className='form-title'>Histórico de Prescrições ML</h2>
        <CampoDePesquisa
          autoPesquisar={true}
          pesquisar={setTermo}
          placeholder='Origem, Data, Profissão, Profissional ou Texto'
          valor={termo}
          className='mw-100'
        />
      </StickyBox>
      <fieldset>
        <Spinner operacoes={[LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <div key='copiar'>
                    <CopyToClipboard text={item.texto}>
                      <button
                        title='Copiar prescrição'
                        type='button'
                        className='table-items-expanded__bt is-active'
                      >
                        <i className='icon icon-copy'></i>
                      </button>
                    </CopyToClipboard>
                  </div>,
                item => ((temLifecode && temMemed) || (temLifecode && item.origem === 'LifeCode') || (temMemed && item.origem === 'Memed')) &&
                  <div key='adicionar'>
                    <button
                      title='Adicionar prescrição'
                      type='button'
                      className='table-items-expanded__bt is-active'
                      onClick={item.identificadorExterno ? () => adicionarMemed(item.identificadorExterno) : () => adicionar(item.texto)}
                    >
                      <i className='icon icon-plus'></i>
                    </button>
                  </div>,
                item => item.linkExterno &&
                  <div key='imprimir'>
                    <button
                      title='Imprimir PDF da precrição Memed'
                      type='button'
                      className='table-items-expanded__bt is-active'
                      onClick={() => imprimirMemed(item.linkExterno)}
                    >
                      <i className='icon icon-print'></i>
                    </button>
                  </div>
              ]}
              chave='identificador'
              className={processando ? 'is-loading abas-qgcc' : '--secondary'}
              dados={prescricoesFiltradas}
              expandida={true}
              titulo={false}
              exibirMensagem={!processando}
              mensagemTabelaVazia='Não existem registros de prescrições.'
              exibirBotaoMais={false}
            >
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-date'
                    nome='DATA'
                    className='col-lg-1'
                    renderizar={item => formatarDataParaFormatoLocal(item.atendimento.inicio)}
                  />
                  <Coluna
                    icone='icon-attention-circled'
                    nome='ORIGEM'
                    className='col-lg-1'
                    campoDeDados='origem'
                  />
                  <Coluna
                    icone='icon-graduation-cap'
                    nome='PROFISSÃO'
                    className='table-items-expanded-20'
                    renderizar={item => item.atendimento.usuario.profissao && `${item.atendimento.usuario.profissao.nome}`}
                  />
                  <Coluna
                    icone='icon-doctor'
                    nome='PROFISSIONAL'
                    className='table-items-expanded-45'
                    renderizar={item => item.atendimento.usuario.nome}
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='TEXTO'
                    className='table-items-expanded-100__box-item'
                    renderizar={item => <div><pre className='font-react-menstion'>{item.texto}</pre></div>}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </fieldset>
      <StickyBox className='fechar-historico-de-prescricoes list-btn m-0' offsetBottom={0} bottom>
        <button className='button --light' type='button' onClick={fechar}>Fechar</button>
      </StickyBox>
    </div>
  )
}