import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_PROFISSOES_PARA_O_NPS,
  LISTOU_PROFISSOES_PARA_O_NPS,
  ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS,
  HABILITAR_PROFISSOES_PARA_O_NPS,
  HABILITOU_PROFISSOES_PARA_O_NPS,
  ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS,
  DESABILITAR_PROFISSAO_PARA_O_NPS,
  DESABILITOU_PROFISSAO_PARA_O_NPS,
  ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS
} from '../tipos'

const listouProfissoesParaONps = profissoes => ({
  type: LISTOU_PROFISSOES_PARA_O_NPS,
  profissoes,
})

const erroAoListarProfissoesParaONps = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS,
  erro
})

export const listarProfissoesParaONps = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES_PARA_O_NPS })

  try {
    const resultado = await api.listarProfissoesParaONps()

    dispatch(listouProfissoesParaONps(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarProfissoesParaONps(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar as profissões para o NPS.')))

    return false
  }
}

const habilitouProfissoesParaONps = () => async dispatch => {
  dispatch({ type: HABILITOU_PROFISSOES_PARA_O_NPS })
}

const erroAoHabilitarProfissoesParaONps = (erro, parametros) => ({
  type: ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS,
  erro,
  parametros
})

export const habilitarProfissoesParaONps = profissoes => async dispatch => {
  dispatch({ type: HABILITAR_PROFISSOES_PARA_O_NPS })

  try {
    const resultado = await api.habilitarProfissoesParaONps(profissoes)

    dispatch(habilitouProfissoesParaONps())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoHabilitarProfissoesParaONps(erro, { profissoes }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao habilitar profissões para o NPS.')))

    return false
  }
}

const desabilitouProfissaoParaONps = () => async dispatch => {
  dispatch({ type: DESABILITOU_PROFISSAO_PARA_O_NPS })
}

const erroAoDesabilitarProfissaoParaONps = (erro, parametros) => ({
  type: ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS,
  erro,
  parametros
})

export const desabilitarProfissaoParaONps = profissao => async dispatch => {
  dispatch({ type: DESABILITAR_PROFISSAO_PARA_O_NPS })

  try {
    const resultado = await api.desabilitarProfissaoParaONps(profissao)

    dispatch(desabilitouProfissaoParaONps())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDesabilitarProfissaoParaONps(erro, { profissao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao desabilitar profissão para o NPS.')))

    return false
  }
}