import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { 
  LISTAR_UNIDADES_DE_CALCIO_IONICO, 
  LISTOU_UNIDADES_DE_CALCIO_IONICO, 
  ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO 
} from './tipos'

const listouUnidadesDeCalcioIonico = unidadesDeCalcioIonico => ({
  type: LISTOU_UNIDADES_DE_CALCIO_IONICO,
  unidadesDeCalcioIonico
})

const erroAoListarUnidadesDeCalcioIonico = erro => ({
  type: ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO,
  erro
})

export const listarUnidadesDeCalcioIonico = () => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DE_CALCIO_IONICO })

  try {
    let resultado = await api.listarUnidadesDeCalcioIonico()

    dispatch(listouUnidadesDeCalcioIonico(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUnidadesDeCalcioIonico(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as Unidades de Calcio Iônico.')))
  }
}