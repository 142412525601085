import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, Input } from '../../formik/formulario'

import {
  RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR,
  ADICIONAR_ESPECIALIDADE,
  ALTERAR_ESPECIALIDADE,
  REMOVER_ESPECIALIDADE
} from '../../../acoes/tipos'

export default function FormularioDeEspecialidade(props) {
  const {
    match,
    history,
    salvar,
    profissao = {},
    remover,
    recuperarEspecialidade,
    recuperarProfissaoPorIdentificador
  } = props

  const { identificadorDaEspecialidade, identificador } = match.params
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_ESPECIALIDADE])
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_ESPECIALIDADE])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_ESPECIALIDADE])

  useEffect(() => {
    (async () => {
      if (identificador) {
        await recuperarProfissaoPorIdentificador(identificador)
        if (identificadorDaEspecialidade) {
          recuperarEspecialidade(identificador, identificadorDaEspecialidade)
        }
      }
    })()
  }, [recuperarEspecialidade, identificador, recuperarProfissaoPorIdentificador, identificadorDaEspecialidade])

  const especialidade = (profissao && profissao.especialidadeSelecionada) || {}

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a especialidade ${especialidade.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => remover(especialidade.especialidade)
      }, {
        label: 'Não'
      }]
    })
  }

  return (    
    <Spinner operacoes={[RECUPERAR_ESPECIALIDADE_POR_IDENTIFICADOR, ADICIONAR_ESPECIALIDADE, ALTERAR_ESPECIALIDADE, REMOVER_ESPECIALIDADE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificador: especialidade.especialidade || '',
          nome: especialidade.nome || '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset className='mt-1'>
          <h2 className='form-title'>{`${especialidade.especialidade ? 'Alterar Especialidade' : 'Adicionar Especialidade'}`}</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={4} span-lg={8}>
              <Input
                type='text'
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
          </r-grid>
        </fieldset>
        {remover ?
          <div className='list-btn-unidades'>
            {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
            {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
            <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
          </div>
          :
          <div className='list-btn'>
            <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        }
      </FormularioDoFormik>
    </Spinner>        
  )
}