import React, { Children, useState } from 'react'

const LIMITE_BUGS = 2

export function ListaDeBugs({ children }) {
  const [exibirTodos, setExibirTodos] = useState(false)

  function abrir() {
    setExibirTodos(true)
  }

  const todosOsBugsEmFormatoTexto = Children.toArray(children)
    .filter((child, index) => child.type === Bug && index > LIMITE_BUGS)

  const bugs = Children.toArray(children)
    .filter((child, index) => child.type === Bug && index <= LIMITE_BUGS)
    .map((child, index) => {
      return React.cloneElement(child, {
        abrir: index === LIMITE_BUGS && todosOsBugsEmFormatoTexto.length > 0 ? abrir : null,
        exibirTodos
      })
    })

  if (bugs.length === 0) {
    return null
  }

  return (
    <>
      <hr className='separator mt-3'></hr>
      <h2 style={{ fontSize: '2.0rem' }}>Correções de bugs:</h2>
      {bugs}
      {exibirTodos && todosOsBugsEmFormatoTexto}
    </>
  )
}

export function Bug({ children, abrir, exibirTodos }) {
  return (
    <>
      <p>- {children}</p>
      {abrir && !exibirTodos &&
        <div style={{ cursor: 'pointer' }}>
          <p
            data-tip
            data-for={`info`}
            key={`info_lista`}
            onClick={abrir}
          >
            [ + ]
          </p>
        </div>
      }
    </>
  )
}