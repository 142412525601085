import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  FAZER_DOWNLOAD_DE_PACIENTES,
  FEZ_DOWNLOAD_DE_PACIENTES,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES,
} from '../tipos'

const listouCelulasDoRelatorioDePacientes = celulas => ({
  type: LISTOU_CELULAS_DO_RELATORIO_DE_PACIENTES,
  celulas
})

const erroAoListarCelulasDoRelatorioDePacientes = erro => ({
  type: ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  erro
})

export const listarCelulasDoRelatorioDePacientes = () => async dispatch => {
  dispatch({ type: LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES })

  try {
      const resultado = await api.listarCelulas()

      dispatch(listouCelulasDoRelatorioDePacientes(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
      dispatch(erroAoListarCelulasDoRelatorioDePacientes(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as células do relatório de pacientes.')))
  }
}

const listouOperadorasDoRelatorioDePacientes = operadoras => ({
  type: LISTOU_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  operadoras
})

const erroAoListarOperadorasDoRelatorioDePacientes = erro => ({
  type: ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  erro
})

export const listarOperadorasDoRelatorioDePacientes = () => async dispatch => {
  dispatch({ type: LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES })

  try {
    const resultado = await api.listarOperadoras()

    dispatch(listouOperadorasDoRelatorioDePacientes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOperadorasDoRelatorioDePacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as operadoras do relatório de pacientes.')))
  }
}

const listouProgramasDoRelatorioDePacientes = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  programas
})

const erroAoListarProgramasDoRelatorioDePacientes = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  erro
})

export const listarProgramasDoRelatorioDePacientes = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDePacientes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDePacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os programas do relatório de pacientes.')))
  }
}

const listouMedicosDoRelatorioDePacientes = medicos => ({
  type: LISTOU_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  medicos
})

const erroAoListarMedicosDoRelatorioDePacientes = erro => ({
  type: ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  erro
})

export const listarMedicosDoRelatorioDePacientes = () => async dispatch => {
  dispatch({ type: LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES })

  try {
    const resultado = await api.listarMedicos()

    dispatch(listouMedicosDoRelatorioDePacientes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMedicosDoRelatorioDePacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os médicos do relatório de pacientes.')))
  }
}

const listouEnfermeirosDoRelatorioDePacientes = enfermeiros => ({
  type: LISTOU_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  enfermeiros
})

const erroAoListarEnfermeirosDoRelatorioDePacientes = erro => ({
  type: ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  erro
})

export const listarEnfermeirosDoRelatorioDePacientes = () => async dispatch => {
  dispatch({ type: LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES })

  try {
    const resultado = await api.listarEnfermeiros()
    dispatch(listouEnfermeirosDoRelatorioDePacientes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEnfermeirosDoRelatorioDePacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar enfermeiro do relatório de pacientes.')))
  }
}

const fezDownloadDePacientes = filtros => ({
  type: FEZ_DOWNLOAD_DE_PACIENTES,
  filtros
})

const erroAoFazerDownloadDePacientes = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES,
  erro,
  parametros
})

export const fazerDownloadDePacientes = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_PACIENTES })

  try {
    const resultado = await api.fazerDownloadDePacientes(filtros)

    dispatch(fezDownloadDePacientes(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDePacientes(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do relatório de pacientes.')))

    return null
  }
}

export const limparFiltrosDoRelatorioDePacientes = () => ({ type: LIMPAR_FILTROS_DO_RELATORIO_DE_PACIENTES })