import api from '../api-major'

const recuperarConfiguracoesVigentesDoUsuarioLogado = () => api.get(`usuario/configuracoes/vigentes`)
const recuperarConfiguracoesDoUsuarioLogado = () => api.get(`usuario/configuracoes`)
const recuperarConfiguracoesVigentesDoUsuario = (identificadorDoUsuario, unidadeDoUsuarioLogado) => api.get(`usuarios/${identificadorDoUsuario}/configuracoes/vigentes?unidade=${unidadeDoUsuarioLogado}`)
const recuperarConfiguracoesDoUsuario = (identificadorDoUsuario) => api.get(`usuarios/${identificadorDoUsuario}/configuracoes`)
const alterarConfiguracoesDoUsuario = (configuracao) => api.put(`usuario/configuracoes`, configuracao)

export default {
    recuperarConfiguracoesVigentesDoUsuarioLogado,
    recuperarConfiguracoesDoUsuarioLogado,
    recuperarConfiguracoesVigentesDoUsuario,
    recuperarConfiguracoesDoUsuario,
    alterarConfiguracoesDoUsuario,
}