import React from 'react'
import { Link } from 'react-router-dom'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import Spinner from '../spinner'
import * as Yup from 'yup'
import Senha from '../senha'

import { REDEFINIR_SENHA } from '../../acoes/tipos'

export default function RedefinirSenha({ redefinirSenha, identificador, history }) {
  const redefinir = async formulario => {
    const redefiniu = await redefinirSenha(formulario.email, formulario.senha, identificador)
    if (redefiniu) {
      history.push('/login')
    }
  }

  return (
    <Spinner operacoes={[REDEFINIR_SENHA]}>
      <FormularioDoFormik
        valoresIniciais={{
          email: '',
          senha: '',
          confirmacao: '',
        }}
        acao={redefinir}
        esquemaDeValidacoes={Yup.object().shape({
          email: Yup.string()
            .required('Obrigatório')
            .email('E-mail inválido'),
          senha: Yup.string().required('Obrigatório.'),
          confirmacao: Yup.string().oneOf([Yup.ref('senha'), null], 'Confirmação deve ser o mesmo valor da senha.').required('Obrigatório. ')
        })}
      >
        <div className='page-recuperar-senha'>
          <div className='box-campos'>
            <a href={window.location.href} className='logo'> </a>
            <h1>Redefinir Senha</h1>
            <div className='form-login'>
              <div className='item-email'>
                <r-grid columns-md={12} columns-lg={12}>
                  <r-cell span={12} span-md={12} span-lg={12} class='item-email'>
                    <Input
                      titulo='Seu e-mail *'
                      nome='email'
                      tabIndex={1}
                    />
                  </r-cell>
                  <r-cell span={12} span-md={12} span-lg={6} class='page-alterar-senha'>
                    <Input
                      component={Senha}
                      nome='senha'
                      tabIndex={2}
                      titulo='Nova senha *'
                    />
                  </r-cell>
                  <r-cell span={12} span-md={12} span-lg={6} class='page-alterar-senha'>
                    <Input
                      component={Senha}
                      nome='confirmacao'
                      tabIndex={3}
                      titulo='Confirmar a nova senha *'
                    />
                  </r-cell>
                </r-grid>
                <div className='form-nav'>
                  <Link to='/login' className='login-back'>Voltar</Link>
                  <button type='submit' className='login-btn'>Alterar Senha</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormularioDoFormik>
    </Spinner>
  )
}