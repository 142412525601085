import { LISTOU_TAMANHOS_DE_PAGINAS, } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TAMANHOS_DE_PAGINAS: {
      return action.tamanhosDePaginas
    }

    default: {
      return state
    }
  }
}