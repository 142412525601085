import React from 'react'

import Responsavel from './campos/responsavel'
import Status from './campos/status'
import Tipo from './campos/tipo'
import Prazo from './campos/prazo'
import Intercorrencia from './intercorrencia'
import Navegacao from './navegacao'

export default function Card({ abrirEditarTarefa, item, index, nome }) {
  function renderizarComponente(item) {
    if (!item) return null

    switch (item.tipo.nome) {
      case 'Intercorrência':
        return (
          <Intercorrencia item={item} nome={nome} />
        )
      case 'Navegação':
        return (
          <Navegacao item={item} nome={nome} />
        )
      default:
        return (
          <div className='card card-tarefas'>
            <div className='card-title'>
              <Tipo valor={item.tipo.nome} cor={item.corDoTipo} />
              <Prazo valor={item.prazo} atrasado={item.atrasado} />
            </div>
            <Status valor={item.status} />
            <Responsavel valor={nome} />
          </div>
        )

    }
  }

  return (
    <li className='pro-menu-item pro-sub-menu' key={`tarefa_paciente_${index}`} onClick={() => abrirEditarTarefa(item.identificador)}>
      {renderizarComponente(item)}
    </li>
  )
}