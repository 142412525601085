import React from 'react'
import { CampoNumerico } from '../../../formik/formulario'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'

export default function ForcaDePreensaoPalmar({ metadados, historico = [], valor, leitura }) {
  const obrigatorio = metadados?.obrigatorio === 'true'

  const validar = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 1, 65) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 1, 65)
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Força de Preensão Palmar: </strong>
            {valor.toString().replace('.', ',')} kg
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={2} span-lg={2}>
      <CampoNumerico
        nome='forcaDePreensaoPalmar'
        titulo={`Força de Preensão Palmar ${obrigatorio ? '*' : ''}`}
        escala='kg'
        numeroDeCasasDecimais={1}
        validar={validar}
      >
        {historico.length > 0 &&
          <GraficoPopup titulo='Força de Preensão Palmar'>
            <Grafico historico={historico} />
          </GraficoPopup>
        }
      </CampoNumerico>
    </r-cell>
  )
}