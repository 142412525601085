import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA,
  LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA,
  ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA
} from '../tipos'

const listouSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia = situacoesConsciencia => ({
  type: LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA,
  situacoesConsciencia
})

const erroAoListarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia = erro => ({
  type: ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA,
  erro
})

export const listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia = () => async dispatch => {
  dispatch({ type: LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACAO_OU_PERDA_DA_CONSCIENCIA })

  try {
    const resultado = await api.listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia()

    dispatch(listouSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as situações associadas à alteração ou perda da consciência.')))
  }
}
