import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_PLANOS_DE_CUIDADO,
  LISTOU_PLANOS_DE_CUIDADO,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO,
  ADICIONOU_PLANO_DE_CUIDADO,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO,
  ADICIONAR_PLANO_DE_CUIDADO,
  RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTEROU_PLANO_DE_CUIDADO,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO,
  ALTERAR_PLANO_DE_CUIDADO,
  REMOVEU_PLANO_DE_CUIDADO,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO,
  REMOVER_PLANO_DE_CUIDADO
} from './tipos'

const listouPlanosDeCuidado = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_PLANOS_DE_CUIDADO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarPlanosDeCuidado = erro => ({
  type: ERRO_AO_LISTAR_PLANOS_DE_CUIDADO,
  erro,
})

export const listarPlanosDeCuidado = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_PLANOS_DE_CUIDADO })

  try {
    const resultado = await api.listarPlanosDeCuidado(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouPlanosDeCuidado(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
    
  } catch (erro) {
    dispatch(erroAoListarPlanosDeCuidado(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os planos de cuidado.')))
  }
}

const adicionouPlanoDeCuidado = planoDeCuidado => ({
  type: ADICIONOU_PLANO_DE_CUIDADO,
  planoDeCuidado
})

const erroAoAdicionarPlanoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO,
  erro,
  parametros
})

export const adicionarPlanoDeCuidado = planoDeCuidado => async dispatch => {
  dispatch({ type: ADICIONAR_PLANO_DE_CUIDADO })

  try {
    const resultado = await api.adicionarPlanoDeCuidado(planoDeCuidado)

    dispatch(adicionouPlanoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPlanoDeCuidado(erro, { planoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o plano de cuidado.')))

    return false
  }
}

const recuperouPlanoDeCuidadoPeloIdentificador = planoDeCuidado => ({
  type: RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  planoDeCuidado
})

const erroAoRecuperarPlanoDeCuidadoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarPlanoDeCuidadoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarPlanoDeCuidadoPeloIdentificador(identificador)

    dispatch(recuperouPlanoDeCuidadoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarPlanoDeCuidadoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o plano de cuidado pelo identificador.')))
  }
}

const alterouPlanoDeCuidado = PlanoDeCuidado => ({
  type: ALTEROU_PLANO_DE_CUIDADO,
  PlanoDeCuidado
})

const erroAoAlterarPlanoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PLANO_DE_CUIDADO,
  erro,
  parametros
})

export const alterarPlanoDeCuidado = planoDeCuidado => async dispatch => {
  dispatch({ type: ALTERAR_PLANO_DE_CUIDADO })

  try {
    const resultado = await api.alterarPlanoDeCuidado(planoDeCuidado)

    dispatch(alterouPlanoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPlanoDeCuidado(erro, { planoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o plano de cuidado.')))

    return false
  }
}

const removeuPlanoDeCuidado = planoDeCuidado => ({
  type: REMOVEU_PLANO_DE_CUIDADO,
  planoDeCuidado
})

const erroAoRemoverPlanoDeCuidado = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PLANO_DE_CUIDADO,
  erro,
  parametros
})

export const removerPlanoDeCuidado = identificador => async dispatch => {
  dispatch({ type: REMOVER_PLANO_DE_CUIDADO })

  try {
    const resultado = await api.removerPlanoDeCuidado(identificador)

    dispatch(removeuPlanoDeCuidado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPlanoDeCuidado(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o plano de cuidado.')))

    return false
  }
}