import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MICRORGANISMOS,
  LISTOU_MICRORGANISMOS,
  ERRO_AO_LISTAR_MICRORGANISMOS
} from './tipos'

const listouMicrorganismos = microrganismos => ({
  type: LISTOU_MICRORGANISMOS,
  microrganismos
})

const erroAoListarMicrorganismos = erro => ({
  type: ERRO_AO_LISTAR_MICRORGANISMOS,
  erro
})

export const listarMicrorganismos = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_MICRORGANISMOS })

    try {
      const resultado = await api.listarMicrorganismos()

      dispatch(listouMicrorganismos(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarMicrorganismos(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os microrganismos.')))
    }
  }

  acao.meta = {
    cache: LISTAR_MICRORGANISMOS
  }

  return acao
}