import {
  FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
  LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS,
} from '../../acoes/tipos'

const estadoInicial = {
  profissoes: [],
  filtros: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PROFISSOES_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS: {
      return {
        ...state,
        profissoes: action.profissoes
      }
    }

    case FEZ_DOWNLOAD_DO_RELATORIO_DE_HORAS_DOS_PROFISSIONAIS: {
      return {
        ...state,
        filtros: action.filtros
      }
    }

    default: {
      return state
    }
  }
}