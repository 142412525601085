import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Fabricantes(props) {
  const {
    adicionarFabricante,
    fabricante,
    filtros,
    history,
    listarFabricantesDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarFabricantePeloIdentificador,
    removerFabricante,
    alterarFabricante
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
    }

    const acao = dados.identificador ? alterarFabricante : adicionarFabricante
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/fabricantes`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_FABRICANTES]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarFabricantesDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_FABRICANTE]}>
              <Formulario
                {...props}
                salvar={salvar}
                fabricante={fabricante}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_FABRICANTE]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarFabricantePeloIdentificador}
                salvar={salvar}
                fabricante={fabricante}
                remover={removerFabricante}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}