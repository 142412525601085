import React from 'react'
import Spinner from '../../../../spinner'
import {MultiplaEscolha} from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'

import {LISTAR_RESPOSTAS_DO_CASP16} from '../../../../../acoes/tipos'

const renderizarTitulo = valor => { return <>{valor.nome}<sup>{valor.valor}</sup> </> }

export default function Resumo({ valores, respostasDoCasp16, fechar }) {
  const {
    data,
    controle,
    autonomia,
    prazer,
    autoRealizacao,
    resultado
  } = valores

  if (!resultado) return null

  let valorCrescente = 0
  let valorDecrescente = 3
  const opcoesComValoresCrescentes = respostasDoCasp16.map(x => ({ codigo: x.codigo, nome: x.nome, valor: valorCrescente++ }))
  const opcoesComValoresDecrescentes = respostasDoCasp16.map(x => ({ codigo: x.codigo, nome: x.nome, valor: valorDecrescente-- }))

  return (
    <Spinner operacoes={[LISTAR_RESPOSTAS_DO_CASP16]}>
      <r-grid columns-md={6} columns-lg={12} class='form form-group'>
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className='form-title'>Resumo do CASP-16</h2>
            <Paciente paciente={valores.paciente} />
            <Data data={data} />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-casp-16'>
              <Cabecalho />
              <Controle valor={controle} opcoesComValoresCrescentes={opcoesComValoresCrescentes} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
              <Autonomia valor={autonomia} opcoesComValoresCrescentes={opcoesComValoresCrescentes} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
              <AutoRealizacao valor={autoRealizacao} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
              <Prazer valor={prazer} opcoesComValoresDecrescentes={opcoesComValoresDecrescentes} />
              <r-cell span={4} span-md={6} span-lg={12}>
                {(resultado || resultado === 0) &&
                  <div className='resultado-questionario'>
                    <div><strong>RESULTADO</strong></div>
                    <div><strong>{resultado}</strong></div>
                  </div>
                }
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            </div>
          </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-zarit paciente-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>CASP-16</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function Controle({ valor = {}, opcoesComValoresCrescentes, opcoesComValoresDecrescentes }) {
  const {
    minhaIdadeMeImpedeDeFazerAsCoisasQueEuGostariaDeFazer,
    euSintoQueOQueAconteceComigoEstaForaDoMeuControle,
    euMeSintoLivreParaPlanejarOFuturo,
    euMeSintoExcluidoDeTudo,
    resultado
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>CONTROLE</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.controle.minhaIdadeMeImpedeDeFazerAsCoisasQueEuGostariaDeFazer'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C1. Minha idade me impede de fazer as coisas que eu gostaria de fazer.'
          valor={minhaIdadeMeImpedeDeFazerAsCoisasQueEuGostariaDeFazer}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.controle.euSintoQueOQueAconteceComigoEstaForaDoMeuControle'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C2. Eu sinto que o que acontece comigo, está fora do meu controle.'
          valor={euSintoQueOQueAconteceComigoEstaForaDoMeuControle}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.controle.euMeSintoLivreParaPlanejarOFuturo'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='C3. Eu me sinto livre para planejar o futuro.'
          valor={euMeSintoLivreParaPlanejarOFuturo}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.controle.euMeSintoExcluidoDeTudo'
          opcoes={opcoesComValoresCrescentes}
          pergunta='C4. Eu me sinto excluído de tudo.'
          valor={euMeSintoExcluidoDeTudo}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
      <div className='pontuacao'>{resultado}</div>
    </r-cell>
  )
}

function Autonomia({ valor = {}, opcoesComValoresCrescentes, opcoesComValoresDecrescentes }) {
  const {
    euPossoFazerAsCoisasQueQuero,
    asResponsabilidadesFamiliaresMeImpedemDeFazerOQueEuQuero,
    euMeSintoLivreParaFazerAsCoisas,
    minhaSaudeMeImpedeDeFazerAsCoisasQueQuero,
    aFaltaDeDinheiroMeImpedeDeFazerAsCoisasQueQuero,
    resultado
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>AUTONOMIA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.autonomia.euPossoFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='A1. Eu posso fazer as coisas que quero.'
          valor={euPossoFazerAsCoisasQueQuero}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.autonomia.asResponsabilidadesFamiliaresMeImpedemDeFazerOQueEuQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A2. As responsabilidades familiares me impedem de fazer o que quero.'
          valor={asResponsabilidadesFamiliaresMeImpedemDeFazerOQueEuQuero}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.autonomia.euMeSintoLivreParaFazerAsCoisas'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='A3. Eu me sinto livre para fazer as coisas.'
          valor={euMeSintoLivreParaFazerAsCoisas}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.autonomia.minhaSaudeMeImpedeDeFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A4. Minha saúde me impede de fazer as coisas que quero.'
          valor={minhaSaudeMeImpedeDeFazerAsCoisasQueQuero}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='casp16.autonomia.aFaltaDeDinheiroMeImpedeDeFazerAsCoisasQueQuero'
          opcoes={opcoesComValoresCrescentes}
          pergunta='A5. A falta de dinheiro me impede de fazer as coisas que quero.'
          valor={aFaltaDeDinheiroMeImpedeDeFazerAsCoisasQueQuero}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
      <div className='pontuacao'>{resultado}</div>
    </r-cell>
  )
}

function AutoRealizacao({ valor = {}, opcoesComValoresDecrescentes }) {
  const {
    euMeSintoCheioDeEnergiaHojeEmDia,
    euEscolhoFazerCoisasQueNuncaFizAntes,
    euSintoQueAVidaEstahCheiaDeOportunidades,
    euSintoQueOMeuFuturoPareceBom,
    resultado
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>AUTO REALIZAÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.autoRealizacao.euMeSintoCheioDeEnergiaHojeEmDia'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR1. Eu me sinto cheio de energia hoje em dia.'
          valor={euMeSintoCheioDeEnergiaHojeEmDia}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.autoRealizacao.euEscolhoFazerCoisasQueNuncaFizAntes'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR2. Eu escolho fazer coisas que nunca fiz antes.'
          valor={euEscolhoFazerCoisasQueNuncaFizAntes}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.autoRealizacao.euSintoQueAVidaEstahCheiaDeOportunidades'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR3. Eu sinto que a vida está cheia de oportunidades.'
          valor={euSintoQueAVidaEstahCheiaDeOportunidades}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.autoRealizacao.euSintoQueOMeuFuturoPareceBom'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='SR4. Eu sinto que o meu futuro parece bom.'
          valor={euSintoQueOMeuFuturoPareceBom}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
      <div className='pontuacao'>{resultado}</div>
    </r-cell>
  )
}

function Prazer({ valor = {}, opcoesComValoresDecrescentes }) {
  const {
    euFicoAnimadoACadaDia,
    euSintoQueMinhaVidaTemSentido,
    euGostoDasCoisasQueFaco,
    resultado
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>PRAZER</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.prazer.euFicoAnimadoACadaDia'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P1. Eu fico animado a cada dia.'
          valor={euFicoAnimadoACadaDia}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.prazer.euSintoQueMinhaVidaTemSentido'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P2. Eu sinto que minha vida tem sentido.'
          valor={euSintoQueMinhaVidaTemSentido}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='casp16.prazer.euGostoDasCoisasQueFaco'
          opcoes={opcoesComValoresDecrescentes}
          pergunta='P3. Eu gosto das coisas que faço.'
          valor={euGostoDasCoisasQueFaco}
          renderizarTitulo={renderizarTitulo}
          leitura={true}
        />
      </div>
      <div className='pontuacao'>{resultado}</div>
    </r-cell>
  )
}