import React, { useMemo } from 'react'
import { connect } from 'react-redux'

const Spinner = function ({ children, operacoes = [], operacoesEmAndamento = {} }) {
  const processando = useMemo(() => operacoes.findIndex(x => operacoesEmAndamento[x]) > -1, [operacoes, operacoesEmAndamento])

  if (!children) return children

  return typeof (children) === 'function' ?
    children({ ...children.props, processando }) :
    React.cloneElement(children, { processando })
}

export default connect(state => ({ operacoesEmAndamento: state.operacoesEmAndamento }))(Spinner)