import React, { useRef } from 'react'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import MensalCustomInput from './mensal-custom-input'
import './style.css'

export default function SeletorDeData(props) {
  const {
    data,
    pesquisar
  } = props

  const input = useRef(null)

  const alterarData = novaData => {
    pesquisar(novaData)
  }

  const mesAnterior = () => {
    const novaData = moment(data).add(-1, 'months')
    alterarData(novaData)
  }

  const proximoMes = () => {
    const novaData = moment(data).add(1, 'months')
    alterarData(novaData)
  }

  return (
    <div className="date-period_horario_agenda">
      <span className="date-period_horario_agenda__before" onClick={mesAnterior}><i className="icon icon-arrow-left"></i></span>
      <div className="date-period_horario_agenda__content">
        <ReactDatePicker
          className="date-period_horario_agenda__content_agenda"
          selected={moment(data).toDate()}
          onChange={date => alterarData(date)}
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          dateFormat='MM/yyyy'
          showMonthYearPicker
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          todayButton="Hoje"
          customInput={<MensalCustomInput ref={input} data={data} />}
        />

      </div>
      <span className="date-period_horario_agenda__after" onClick={proximoMes}><i className="icon icon-arrow-right"></i></span>
    </div>
  )
}