import React from 'react'
import { useEffect } from 'react'
import { Formulario as FormularioDoFormik, CampoNumerico, DatePicker, MultiplaEscolha, Input, MultiplaSelecao, Select } from '../../../../formik/formulario'
import { contemValor, ehUmNumeroDecimalValido, numeroDeCasasDecimais } from '../../../../../bibliotecas/validacoes'
import CorpoHumano from '../../../../../design/img/dor-avaliacao-fisica-e-funcional.png'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  LISTAR_DURACOES_DAS_DORES,
  LISTAR_INTENSIDADES_DAS_DORES,
  LISTAR_LOCAIS_DAS_DORES,
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA
} from '../../../../../acoes/tipos'

const obrigatorio = valor => {
  if (contemValor(valor)) {
    if (Array.isArray(valor) && valor.length === 0) {
      return 'Obrigatório'
    }

    return undefined
  }

  return 'Obrigatório'
}

const deveSerNumerico = valor => contemValor(valor) && !ehUmNumeroDecimalValido(valor) ? 'Deve ser um número' : undefined
const deveSerUmValorEntre = (valor, valorMinimo, valorMaximo) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  valor = valor ? parseFloat(valor.toString().replace(',', '.')) : valor

  return valor >= valorMinimo && valor <= valorMaximo ? undefined : `Deve ser um valor entre ${valorMinimo} e ${valorMaximo}.`
}

const maximoDeCasasDecimais = (valor, casasDecimais) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  return numeroDeCasasDecimais(valor) <= casasDecimais ? undefined : `Máximo de ${casasDecimais} casa(s) decimal(is).`
}

const renderizarTitulo = valor => valor.descricao

export default function Formulario({ duracoesDasDores, intensidadesDasDores, locaisDasDores, tiposDeAtividadeFisicaDeLazer, execucoesDoTesteDeFisioterapia, selecionado, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE, 
      ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE, 
      LISTAR_DURACOES_DAS_DORES, 
      LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
      LISTAR_INTENSIDADES_DAS_DORES, 
      LISTAR_LOCAIS_DAS_DORES, 
      LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER, 
      ]}>
      <FormularioDoFormik
        reinicializar={false}
        acao={salvar}
        valoresIniciais={valores}
      >
        {({ values, setFieldValue }) => (
          <fieldset>
            <h2 className='form-title'>{selecionado ? 'Alterar Avaliação Física e Funcional' : 'Adicionar Avaliação Física e Funcional'}</h2>
            <Data />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-fisioterapia'>
              <Cabecalho />
              <QueixaPrincipal />
              <DeambulaComAuxilioDeDispositivoEMarcapassoEProteseMetalica proteseMetalica={values.proteseMetalica} setFieldValue={setFieldValue} />
              <AtividadeFisicaDeLazer realizaAtividadeFisicaDeLazer={values.atividadeFisicaDeLazer.realizaAtividadeFisicaDeLazer} tempoPorSemana={values.atividadeFisicaDeLazer.tempoPorSemana} tiposDeAtividadeFisicaDeLazer={tiposDeAtividadeFisicaDeLazer} setFieldValue={setFieldValue} tipos={values.atividadeFisicaDeLazer.tipos} />
              <Dor dor={values.dor.dor} duracoesDasDores={duracoesDasDores} intensidadesDasDores={intensidadesDasDores} locaisDasDores={locaisDasDores} setFieldValue={setFieldValue} locais={values.dor.locais} />
              <AtividadeEParticipacao />
              <Quedas />
              <Testes execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia} execucaoTug={values.tug.execucao} execucaoSentaELevanta5Vezes={values.sentaELevanta5Vezes.execucao} execucaoVelocidadeDaMarchaDe4Metros={values.velocidadeDaMarchaDe4Metros.execucao} setFieldValue={setFieldValue} />
              <Equilibrio execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia} />
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
              <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div>
        <strong>AVALIAÇÃO FÍSICA E FUNCIONAL</strong>
      </div>
    </r-cell>
  )
}

function QueixaPrincipal() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <Input
        as='textarea'
        classname='pergunta'
        nome='queixaPrincipal'
        titulo='Queixa principal *'
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function DeambulaComAuxilioDeDispositivoEMarcapassoEProteseMetalica({ proteseMetalica, setFieldValue }) {
  const limparValores = () => {
    if (proteseMetalica === false) {
      setFieldValue('localDaProteseMetalica', '')
    }
  }

  useEffect(() => {
    if (proteseMetalica === false) {
      setFieldValue('localDaProteseMetalica', '')
    }
  }, [setFieldValue, proteseMetalica])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='deambulaComAuxilioDeDispositivo'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='1. Deambula com auxílio de dispositivo?'
        renderizarTitulo={renderizarTitulo}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='marcapasso'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='2. Marcapasso?'
        renderizarTitulo={renderizarTitulo}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='proteseMetalica'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='3. Prótese metálica?'
        onChange={limparValores}
        renderizarTitulo={renderizarTitulo}
      />
      <Input
        classname='pergunta'
        nome='localDaProteseMetalica'
        titulo='Local da protese metálica'
        disabled={!proteseMetalica}
        validar={proteseMetalica && obrigatorio}
      />
    </r-cell>
  )
}

function AtividadeFisicaDeLazer({ realizaAtividadeFisicaDeLazer, tempoPorSemana, tiposDeAtividadeFisicaDeLazer, setFieldValue, tipos }) {
  const validacaoTempoPorSemana = valor => {
    if (valor !== undefined) {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 0, 1439) ||
        maximoDeCasasDecimais(valor, 0))
    }
  }

  useEffect(() => {
    if (!realizaAtividadeFisicaDeLazer) {
      setFieldValue('atividadeFisicaDeLazer.tempoPorSemana', null)
      setFieldValue('atividadeFisicaDeLazer.tipos', [])
    }
  }, [setFieldValue, realizaAtividadeFisicaDeLazer])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>ATIVIDADE FÍSICA DE LAZER</p>
      <MultiplaEscolha
        className='pergunta'
        nome='atividadeFisicaDeLazer.realizaAtividadeFisicaDeLazer'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='4. Realiza atividade física de lazer?'
        renderizarTitulo={renderizarTitulo}
      />
      <CampoNumerico
        className='pergunta campo-numerico is-disabled'
        nome='atividadeFisicaDeLazer.tempoPorSemana'
        escala='m'
        titulo='5. Tempo por semana:'
        margem='5px'
        validar={realizaAtividadeFisicaDeLazer && validacaoTempoPorSemana && obrigatorio}
        disabled={!realizaAtividadeFisicaDeLazer}
      />
      <MultiplaSelecao
        className='pergunta'
        nome='atividadeFisicaDeLazer.tipos'
        opcoes={tiposDeAtividadeFisicaDeLazer}
        pergunta='6. Tipo:'
        renderizarTitulo={renderizarTitulo}
        validar={realizaAtividadeFisicaDeLazer && tipos.length === 0 && obrigatorio}
        disabled={!realizaAtividadeFisicaDeLazer}
        valores={tipos}
      />
    </r-cell>
  )
}

function Dor({ dor, duracoesDasDores, intensidadesDasDores, locaisDasDores, setFieldValue, locais }) {
  useEffect(() => {
    if (dor === false) {
      setFieldValue('dor.locais', [])
      setFieldValue('dor.duracao', '')
      setFieldValue('dor.intensidade', '')      
    }
  }, [setFieldValue, dor])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <div className='dor'>
        <div>
          <MultiplaEscolha
            className='pergunta'
            nome='dor.dor'
            opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
            pergunta='7. Dor:'
            renderizarTitulo={renderizarTitulo}
          />
          <img
            alt='Corpo Humano'
            src={CorpoHumano}
          />
        </div>
        <MultiplaSelecao
          className='pergunta'
          nome='dor.locais'
          opcoes={locaisDasDores}
          pergunta='8. Local:'
          renderizarTitulo={renderizarTitulo}
          validar={dor && locais.length === 0 && obrigatorio}
          disabled={!dor}
          valores={locais}
        />
      </div>
      <div className='dor'>
        <MultiplaEscolha
          className='pergunta'
          nome='dor.duracao'
          opcoes={duracoesDasDores.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
          pergunta='9. Duração:'
          renderizarTitulo={renderizarTitulo}
          validar={dor && obrigatorio}
          disabled={!dor}
        />        
        <Select
          className='pergunta'
          nome='dor.intensidade'
          itens={intensidadesDasDores}
          campoCodigo='codigo'
          campoDescricao='nome'
          titulo='10. Intensidade:'
          subTitulo='Como você descreveria a intensidade dessa (s) dore (s) que o (a) senhor (a) apresentou sendo 1 a menor intensidade e 10 a intensidade máxima?'
          validar={dor && obrigatorio}
          isDisabled={!dor}
        />
      </div>
    </r-cell>
  )
}

function AtividadeEParticipacao() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>ATIVIDADE E PARTICIPAÇÃO</p>
      <MultiplaEscolha
        className='pergunta'
        nome='problemaImpedeRealizacaoDeAtividadesNormais'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='11. Esse problema que o trouxe a fisioterapia (dor, desconforto, rigidez) o impede de realizar atividades normais (por exemplo trabalho, atividades domésticas e de lazer)?'
        renderizarTitulo={renderizarTitulo}
      />
    </r-cell>
  )
}

function Quedas() {
  const validacaoNumeroDeQuedasNosUltimos12Meses = valor => {
    if (valor !== undefined) {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 0, 100) ||
        maximoDeCasasDecimais(valor, 0))
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <p>QUEDAS</p>
      <CampoNumerico
        className='pergunta campo-numerico'
        nome='quedas.numeroDeQuedasNosUltimos12Meses'
        titulo='12. Número de quedas nos últimos 12 meses:'
        margem='5px'
        validar={validacaoNumeroDeQuedasNosUltimos12Meses}
      />
      <MultiplaEscolha
        className='pergunta'
        nome='quedas.medoDeCair'
        opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
        pergunta='13. Medo de cair:'
        renderizarTitulo={renderizarTitulo}
      />
    </r-cell>
  )
}

function Testes({ execucoesDoTesteDeFisioterapia, execucaoTug, execucaoSentaELevanta5Vezes, execucaoVelocidadeDaMarchaDe4Metros, setFieldValue }) {
  useEffect(() => {
    if (execucaoTug === 'nao_consegue_realizar' || execucaoTug === '') {
      setFieldValue('tug.tempo', '')
    }

    if (execucaoSentaELevanta5Vezes === 'nao_consegue_realizar' || execucaoSentaELevanta5Vezes === '') {
      setFieldValue('sentaELevanta5Vezes.tempo', '')
    }

    if (execucaoVelocidadeDaMarchaDe4Metros === 'nao_consegue_realizar' || execucaoVelocidadeDaMarchaDe4Metros === '') {
      setFieldValue('velocidadeDaMarchaDe4Metros.tempo', '')
    }
  }, [setFieldValue, execucaoTug, execucaoSentaELevanta5Vezes, execucaoVelocidadeDaMarchaDe4Metros])

  const validacaoTug = valor => {
    if (execucaoTug === 'sem_auxilio' || execucaoTug === 'com_auxilio') {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 1, 59) ||
        maximoDeCasasDecimais(valor, 2)) || 
        obrigatorio(valor)
    }
  }

  const validacaoSentaELevanta5Vezes = valor => {
    if (execucaoSentaELevanta5Vezes === 'sem_auxilio' || execucaoSentaELevanta5Vezes === 'com_auxilio') {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 1, 59) ||
        maximoDeCasasDecimais(valor, 2)) ||
        obrigatorio(valor)
    }
  }

  const validacaoVelocidadeDaMarchaDe4Metros = valor => {
    if (execucaoVelocidadeDaMarchaDe4Metros === 'sem_auxilio' || execucaoVelocidadeDaMarchaDe4Metros === 'com_auxilio') {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 1, 59) ||
        maximoDeCasasDecimais(valor, 2)) ||
        obrigatorio(valor)
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={4}>
          <Select
            className='pergunta'
            nome='tug.execucao'
            itens={execucoesDoTesteDeFisioterapia}
            campoCodigo='codigo'
            campoDescricao='nome'
            titulo='14. TUG (Time Up and Go):'
          />
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={2}>
          <CampoNumerico
            className='pergunta campo-numerico'
            nome='tug.tempo'
            escala='s'
            titulo='Tempo:'
            margem='5px'
            validar={validacaoTug}
            numeroDeCasasDecimais={2}
            disabled={execucaoTug === 'nao_consegue_realizar' || execucaoTug === ''}
          />
        </r-cell>
      </r-grid>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={4}>
          <Select
            className='pergunta'
            nome='sentaELevanta5Vezes.execucao'
            itens={execucoesDoTesteDeFisioterapia}
            campoCodigo='codigo'
            campoDescricao='nome'
            titulo='15. Senta-Levanta 5 vezes:'
          />
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={2}>
          <CampoNumerico
            className='pergunta campo-numerico'
            nome='sentaELevanta5Vezes.tempo'
            escala='s'
            titulo='Tempo:'
            margem='5px'
            validar={validacaoSentaELevanta5Vezes}
            numeroDeCasasDecimais={2}
            disabled={execucaoSentaELevanta5Vezes === 'nao_consegue_realizar' || execucaoSentaELevanta5Vezes === ''}
          />
        </r-cell>
      </r-grid>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={4}>
          <Select
            className='pergunta'
            nome='velocidadeDaMarchaDe4Metros.execucao'
            itens={execucoesDoTesteDeFisioterapia}
            campoCodigo='codigo'
            campoDescricao='nome'
            titulo='16. Velocidade de Marcha:'
          />
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={2}>
          <CampoNumerico
            className='pergunta campo-numerico'
            nome='velocidadeDaMarchaDe4Metros.tempo'
            escala='s'
            titulo='Tempo:'
            margem='5px'
            validar={validacaoVelocidadeDaMarchaDe4Metros}
            numeroDeCasasDecimais={2}
            disabled={execucaoVelocidadeDaMarchaDe4Metros === 'nao_consegue_realizar' || execucaoVelocidadeDaMarchaDe4Metros === ''}
          />
        </r-cell>
      </r-grid>
    </r-cell>
  )
}

function Equilibrio({ execucoesDoTesteDeFisioterapia }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={4}>
          <p>EQUILÍBRIO</p>
          <Select
            className='pergunta'
            nome='equilibrio.romberg'
            itens={execucoesDoTesteDeFisioterapia}
            campoCodigo='codigo'
            campoDescricao='nome'
            titulo='17. Romberg:'
          />
          <Select
            className='pergunta'
            nome='equilibrio.semiTamdem'
            itens={execucoesDoTesteDeFisioterapia}
            campoCodigo='codigo'
            campoDescricao='nome'
            titulo='18. Semi Tamdem:'
          />
        </r-cell>
      </r-grid>
    </r-cell>
  )
}