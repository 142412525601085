import React, {memo, useState} from 'react'
import { InputSugestao } from '../../../formik/formulario'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import DicaDePesquisa from '../../../dica-de-pesquisa'
import {SelectTemplate} from '../../../formik/input-mension'
import {confirmAlert} from 'react-confirm-alert'

function Passometro({ metadados, leitura, valor, setFieldValue, tokensDeSugestao = {}, abrirAdicionarTemplate }) {
  const obrigatorio = metadados?.obrigatorio === 'true'
  const [pularConfirmacao, setPularConfirmacao] = useState(false)

  const validar = valor => {
    if (!valor && obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Passômetro</strong>
            <br />
            <div className='font-react-menstion'>{valor}</div>
          </pre>
        </div>
      </r-cell>
    )
  }

  const { dadosDaSugestao, usuariosDaSugestao, templates, decodificador } = tokensDeSugestao
  const templatesPassometro = templates.map(x => ({
    ...x,
    options: x.options.length > 0 ? x.options.filter(y => y.componente === 'PASSOMETRO').map(z => ({ ...z, label: z.nome, value: z.identificador })) : []
  }))

  const atualizarPreview = (template = '') => {
    return decodificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const selecionarTemplate = (valor) => {
    if (!valor) {
      setPularConfirmacao(false)
      return
    }

    setPularConfirmacao(true)

    let todosOsTemplates = []
    templatesPassometro.forEach(x => x.options.length > 0 && x.options.map(t => todosOsTemplates.push(t)))

    const template = todosOsTemplates.filter(x => x.identificador === valor)[0].template
    setFieldValue('passometro', atualizarPreview(template))
  }

  const confirmarESubstituir = (valor) => {
    confirmAlert({
      title: 'Confirmação',
      message: `Os dados informados do Passômetro serão substituídos. Tem certeza disso?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => selecionarTemplate(valor)
      }, {
        label: 'Não',
        onClick: () => setFieldValue('template_passometro', '')
      }]
    })
  }

  const limparTemplate = (valor, setFieldValue) => {
    if (valor === '') {
      setFieldValue('template_passometro', '')
      setPularConfirmacao(true)
    }
  }

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@maria'>
        <InputSugestao
          as='textarea'
          nome='passometro'
          titulo={`Passômetro ${obrigatorio ? '*' : ''}`}
          validar={validar}
          dados={dadosDaSugestao}
          dadosUsuarios={usuariosDaSugestao}
          minHeight={150}
          onChange={evento => limparTemplate(evento.target.value, setFieldValue)}
        >
          <SelectTemplate
            nome='template_passometro'
            itens={templatesPassometro}
            campoCodigo='identificador'
            campoDescricao='nome'
            onChange={v => (document.getElementById('passometro').value === '' || pularConfirmacao) ? selecionarTemplate(v) : confirmarESubstituir(v)}
            onClickSalvarComoTemplate={() => abrirAdicionarTemplate(document.getElementById('passometro').value, 'PASSOMETRO')}
          />
        </InputSugestao>
      </DicaDePesquisa>      
    </r-cell>
  )
}

export default memo(Passometro)