import React from 'react'
import {formatarDataEHoraParaFormatoLocal} from '../../../../bibliotecas/data'
import Resultado from './resultado'
import {useProntuario} from '../../contexto'

export default function Resumo({triagem}) {
  const {
    situacoesAssociadasADorNoPeito,
    situacoesAssociadasAAlteracoesGastrointestinais,
    situacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    situacoesAssociadasAQueda,
    situacoesAssociadasASintomasUrinarios,
    sintomasAssociadasAoLocalDaDorMusculoesqueletica,
    intensidadesDasDoresDaTriagem,
  } = useProntuario()

  if (!triagem) {
    return null
  }

  const recuperarDescricaoDeItens = (itens, codigos) => {
    let descricoes = []

    codigos.forEach((codigo) => {
      const item = itens.find(x => x.codigo === codigo)

      if (item) {
        descricoes.push(item.nome)
      }
    })

    return descricoes
  }

  const RespostaObservacoes = ({observacoes}) => {
    if (!observacoes) {
      return null
    }

    return (
      <pre className='description'><strong>{'Observações: '}</strong>
        {observacoes}
      </pre>
    )
  }

  const Secao = ({titulo}) => {
     return <h2 className='form-title nome-secao'>{titulo}</h2>
  }

  const RespostaAfirmativa = ({afirmacao, valor}) => {
    if (!valor) {
      return null
    }

    return <pre className='description'>{afirmacao}</pre>
  }

  const RespostaAfirmativaEraAssim = ({afirmacao, resposta, visivel}) => {
    if (!visivel) {
      return null
    }

    return (
      <pre className='description'>
        {afirmacao}
        <strong>{resposta === 'sim_e_nao_era_assim' ? ' e não era assim' : ', mas já era assim'}.</strong>
      </pre>
    )
  }

  const RespostaAfirmativaComSangue = ({afirmacao, resposta, visivel}) => {
    if (!visivel) {
      return null
    }

    return (
      <pre className='description'>
        {afirmacao}
        <strong>{resposta === 'sim_com_sangue' ? ' com sangue' : ' sem sangue'}.</strong>
      </pre>
    )
  }

  const RespostaDorComIntensidade = ({afirmacao, intensidade, visivel}) => {
    if (!visivel) {
      return null
    }

    const descricaoDaIntensidade = intensidadesDasDoresDaTriagem.find(x => x.codigo === intensidade)

    if (!descricaoDaIntensidade) {
      return null
    }

    return (
      <pre className='description'>
        {afirmacao}
        <strong className='intensidade'>{` ${descricaoDaIntensidade.nome}.`}</strong>
      </pre>
    )
  }

  const RespostaDaHemorragiaAPressao = ({ resposta}) => {
    if (!resposta) {
      return null
    }

    return (
      <pre className='description'>
        Ao pressionar com as mãos
        <strong>{resposta === 'continua' ? ' continua' : ' reduz ou para'}.</strong>
      </pre>
    )
  }

  const Respiracao =({respiracao}) => {
    const algumaRespostaDeRespiracao = respiracao.dificuldadeParaRespirar ||
      respiracao.falaEntrecortadaOuRespiracaoAcelerada ||
      respiracao.coloracaoAzuladaNosLabiosOuExtremidades ||
      respiracao.podeEstarEngasgado ||
      respiracao.observacoes

    if (!algumaRespostaDeRespiracao) {
      return null
    }

    return (
      <>
        <Secao titulo='Respiração'/>
        <RespostaAfirmativa
          valor={respiracao.dificuldadeParaRespirar}
          afirmacao='Dificuldade para respirar.'
        />
        <RespostaAfirmativa
          valor={respiracao.falaEntrecortadaOuRespiracaoAcelerada}
          afirmacao='Fala entrecortada ou respiração acelerada.'
        />
        <RespostaAfirmativa
          valor={respiracao.coloracaoAzuladaNosLabiosOuExtremidades}
          afirmacao='Coloração azulada nos lábios ou extremidades.'
        />
        <RespostaAfirmativa
          valor={respiracao.podeEstarEngasgado}
          afirmacao='Engasgado (sensação de sufocação, mãos no pescoço, salivação).'
        />
        <RespostaObservacoes observacoes={respiracao.observacoes}/>
      </>
    )
  }

  const DorNoPeito = ({dorNoPeito}) => {
    const algumaRespostaDeDorNoPeito = dorNoPeito.intensidade ||
      dorNoPeito.situacoesAssociadas.length > 0 ||
      dorNoPeito.observacoes

    if (!algumaRespostaDeDorNoPeito) {
      return null
    }

    return (
      <>
        <Secao titulo='Dor no peito'/>
        <RespostaDorComIntensidade
          visivel={dorNoPeito.intensidade}
          afirmacao='Dor no peito com a intensidade'
          intensidade={dorNoPeito.intensidade}
        />
        <RespostaAfirmativa
          valor={dorNoPeito.situacoesAssociadas.length > 0}
          afirmacao={`Situações associadas: ${recuperarDescricaoDeItens(situacoesAssociadasADorNoPeito,
            dorNoPeito.situacoesAssociadas).join(', ')}.`}
        />
        <RespostaObservacoes observacoes={dorNoPeito.observacoes}/>
      </>
    )
  }

  const AlteracaoOuPerdaDaConsciencia = ({alteracaoOuPerdaDaConsciencia}) => {
    const algumaRespostaDeAlteracaoOuPerdaDaConsciencia = alteracaoOuPerdaDaConsciencia.inconsciente ||
      alteracaoOuPerdaDaConsciencia.dificuldadeParaRespirar ||
      alteracaoOuPerdaDaConsciencia.situacoesAssociadas.length > 0 ||
      alteracaoOuPerdaDaConsciencia.confusoOuDesorientado !== 'nao' ||
      alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas !== 'nao' ||
      alteracaoOuPerdaDaConsciencia.observacoes

    if (!algumaRespostaDeAlteracaoOuPerdaDaConsciencia) {
      return null
    }

    return (
      <>
        <Secao titulo='Alteração ou Perda da Consciência'/>
        <RespostaAfirmativa
          valor={alteracaoOuPerdaDaConsciencia.inconsciente}
          afirmacao='Inconsciente.'
        />
        <RespostaAfirmativa
          valor={alteracaoOuPerdaDaConsciencia.dificuldadeParaRespirar}
          afirmacao='Dificuldade para respirar..'
        />
        <RespostaAfirmativa
          valor={alteracaoOuPerdaDaConsciencia.situacoesAssociadas.length > 0}
          afirmacao={`Situações associadas: ${recuperarDescricaoDeItens(situacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
            alteracaoOuPerdaDaConsciencia.situacoesAssociadas).join(', ')}.`}
        />
        <RespostaAfirmativaEraAssim
          visivel={alteracaoOuPerdaDaConsciencia.confusoOuDesorientado !== 'nao'}
          afirmacao={'Confuso ou desorientado'}
          resposta={alteracaoOuPerdaDaConsciencia.confusoOuDesorientado }
        />
        <RespostaAfirmativaEraAssim
          visivel={alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas !== 'nao'}
          afirmacao={'Dificuldade em responder perguntas'}
          resposta={alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas}
        />
        <RespostaObservacoes observacoes={alteracaoOuPerdaDaConsciencia.observacoes}/>
      </>
    )
  }

  const Queda = ({queda}) => {
    const algumaRespostaDeQueda = queda.aconteceuHaMenosDeVinteEQuatroHoras ||
      queda.inconsciente ||
      queda.dificuldadeParaRespirar ||
      queda.situacoesAssociadas.length > 0 ||
      queda.confusoOuDesorientado !== 'nao' ||
      queda.dificuldadeDeResponderPerguntas !== 'nao' ||
      queda.incapacidadeDeSeMovimentar ||
      queda.sangramentoLesaoAbertaHematomaOuEdema ||
      queda.dor ||
      queda.observacoes

    if (!algumaRespostaDeQueda) {
      return null
    }

    return (
      <>
        <Secao titulo='Queda' />
        <RespostaAfirmativa
          valor={queda.aconteceuHaMenosDeVinteEQuatroHoras}
          afirmacao='Aconteceu há menos de 24h.'
        />
        <RespostaAfirmativa
          valor={queda.inconsciente}
          afirmacao='Está inconsciente.'
        />
        <RespostaAfirmativa
          valor={queda.dificuldadeParaRespirar}
          afirmacao='Dificuldade para respirar.'
        />
        <RespostaAfirmativa
          valor={queda.situacoesAssociadas.length > 0}
          afirmacao={`Situações associadas: ${recuperarDescricaoDeItens(situacoesAssociadasAQueda,
            queda.situacoesAssociadas).join(', ')}.`}
        />
        <RespostaAfirmativaEraAssim
          visivel={queda.confusoOuDesorientado !== 'nao'}
          afirmacao={'Confuso ou desorientado'}
          resposta={queda.confusoOuDesorientado}
        />
        <RespostaAfirmativaEraAssim
          visivel={queda.dificuldadeDeResponderPerguntas  !== 'nao'}
          afirmacao={'Dificuldade em responder perguntas'}
          resposta={queda.dificuldadeDeResponderPerguntas }
        />
        <RespostaAfirmativa
          valor={queda.incapacidadeDeSeMovimentar}
          afirmacao='Incapacidade para se movimentar normalmente.'
        />
        <RespostaAfirmativa
          valor={queda.sangramentoLesaoAbertaHematomaOuEdema}
          afirmacao='Apresenta sangramento, lesão aberta, hematoma ou edema.'
        />
        <RespostaDorComIntensidade
          visivel={queda.dor}
          afirmacao='Dor com intensidade'
          intensidade={queda.dor}
        />
        <RespostaObservacoes observacoes={queda.observacoes}/>
      </>
    )
  }

  const AlteracoesGastroIntestinais = ({alteracoesGastroIntestinais}) => {
    const algumaRespostaDeAlteracoesGastroIntestinais = alteracoesGastroIntestinais.diarreia !== 'nao' ||
      alteracoesGastroIntestinais.vomito !== 'nao' ||
      alteracoesGastroIntestinais.situacoesAssociadas.length > 0 ||
      alteracoesGastroIntestinais.dorAbdominal ||
      alteracoesGastroIntestinais.observacoes

    if (!algumaRespostaDeAlteracoesGastroIntestinais) {
      return null
    }

    return (
      <>
        <Secao titulo='Alterações Gastrointestinais' />
        <RespostaAfirmativaComSangue
          visivel={alteracoesGastroIntestinais.diarreia !== 'nao'}
          afirmacao='Diarréia'
          resposta={alteracoesGastroIntestinais.diarreia}
        />
        <RespostaAfirmativaComSangue
          visivel={alteracoesGastroIntestinais.vomito !== 'nao'}
          afirmacao='Vômito'
          resposta={alteracoesGastroIntestinais.vomito}
        />
        <RespostaAfirmativa
          valor={alteracoesGastroIntestinais.situacoesAssociadas.length > 0}
          afirmacao={`Situações associadas: ${recuperarDescricaoDeItens(situacoesAssociadasAAlteracoesGastrointestinais,
            alteracoesGastroIntestinais.situacoesAssociadas).join(', ')}.`}
        />
        <RespostaDorComIntensidade
          visivel={alteracoesGastroIntestinais.dorAbdominal}
          afirmacao='Dor abdominal com intensidade'
          intensidade={alteracoesGastroIntestinais.dorAbdominal}
        />
        <RespostaObservacoes observacoes={alteracoesGastroIntestinais.observacoes}/>
      </>
    )
  }

  const SintomasUrinarios = ({sintomasUrinarios}) => {
    const algumaRespostaDeSintomasUrinarios = sintomasUrinarios.semUrinarHaMaisDeOitoHoras ||
      sintomasUrinarios.dorAbdominal ||
      sintomasUrinarios.situacoesAssociadas.length > 0 ||
      sintomasUrinarios.observacoes

    if (!algumaRespostaDeSintomasUrinarios) {
      return null
    }

    return (
      <>
        <Secao titulo='Sintomas Urinários'/>
        <RespostaAfirmativa
          valor={sintomasUrinarios.semUrinarHaMaisDeOitoHoras}
          afirmacao={'Sem urinar há mais de 8h.'}
        />
        <RespostaDorComIntensidade
          visivel={sintomasUrinarios.dorAbdominal}
          afirmacao='Dor abdominal, no flanco ou abaixo do umbigo com intensidade'
          intensidade={sintomasUrinarios.dorAbdominal}
        />
        <RespostaAfirmativa
          valor={sintomasUrinarios.situacoesAssociadas.length > 0}
          afirmacao={`Situações associadas: ${recuperarDescricaoDeItens(situacoesAssociadasASintomasUrinarios,
            sintomasUrinarios.situacoesAssociadas).join(', ')}.`}
        />
        <RespostaObservacoes observacoes={sintomasUrinarios.observacoes}/>
      </>
    )
  }

  const DorMusculoEsqueletica = ({dorMusculoesqueletica}) => {
    const algumaRespostaDeDorMusculoesqueletica = dorMusculoesqueletica.incapacitante ||
      dorMusculoesqueletica.intensidade ||
      dorMusculoesqueletica.iniciouHaMenosDeVinteEQuatroHoras ||
      dorMusculoesqueletica.sintomasAssociados.length > 0 ||
      dorMusculoesqueletica.observacoes

    if (!algumaRespostaDeDorMusculoesqueletica) {
      return null
    }

    return (
      <>
        <Secao titulo='Dor Musculoesquelética' visivel={algumaRespostaDeDorMusculoesqueletica}/>
        <RespostaDorComIntensidade
          visivel={dorMusculoesqueletica.intensidade}
          afirmacao='Dor com intensidade'
          intensidade={dorMusculoesqueletica.intensidade}
        />
        <RespostaAfirmativa
          valor={dorMusculoesqueletica.incapacitante}
          afirmacao='Dor incapacitante (impede a pessoa de fazer até pequenos movimentos)'
        />
        <RespostaAfirmativa
          valor={dorMusculoesqueletica.iniciouHaMenosDeVinteEQuatroHoras}
          afirmacao='Iniciou há menos de 24h.'
        />
        <RespostaAfirmativa
          valor={dorMusculoesqueletica.sintomasAssociados.length > 0}
          afirmacao={`Sintomas associados: ${recuperarDescricaoDeItens(sintomasAssociadasAoLocalDaDorMusculoesqueletica,
            dorMusculoesqueletica.sintomasAssociados).join(', ')}.`}
        />
        <RespostaObservacoes observacoes={dorMusculoesqueletica.observacoes}/>
      </>
    )
  }

  const Hemorragia = ({hemorragia}) => {
    const algumaRespostaDeHemorragia = hemorragia.sinaisDeHemorragia ||
      hemorragia.respostaAPressao ||
      hemorragia.usaAnticoagulantes ||
      hemorragia.observacoes

    if (!algumaRespostaDeHemorragia) {
      return null
    }

    return (
      <>
        <Secao titulo='Hemorragia'/>
        <RespostaAfirmativa
          valor={hemorragia.sinaisDeHemorragia}
          afirmacao='Sinais de hemorragia.'
        />
        <RespostaDaHemorragiaAPressao resposta={hemorragia.respostaAPressao} />
        <RespostaAfirmativa
          valor={hemorragia.usaAnticoagulantes}
          afirmacao='Paciente usando anticoagulante.'
        />
        <RespostaObservacoes observacoes={hemorragia.observacoes}/>
      </>
    )
  }

  return (
    <>
      <div className='evolution-info'>
        <p className='evolution-info__data'>
          {formatarDataEHoraParaFormatoLocal(triagem.horario)}
          <strong title='TRIAGEM'>TRIAGEM</strong>
        </p>
        <p className='evolution-info__name'>
          {triagem.profissional.nome}
        </p>
        <Resultado codigo={triagem.resultado}/>
      </div>
      <div className='docs-info resumo-triagem'>
        <div className='mt-2'>
          <Respiracao respiracao={triagem.respiracao} />
          <DorNoPeito dorNoPeito={triagem.dorNoPeito} />
          <AlteracaoOuPerdaDaConsciencia alteracaoOuPerdaDaConsciencia={triagem.alteracaoOuPerdaDaConsciencia} />
          <Queda queda={triagem.queda} />
          <AlteracoesGastroIntestinais alteracoesGastroIntestinais={triagem.alteracoesGastroIntestinais} />
          <SintomasUrinarios sintomasUrinarios={triagem.sintomasUrinarios} />
          <DorMusculoEsqueletica dorMusculoesqueletica={triagem.dorMusculoesqueletica} />
          <Hemorragia hemorragia={triagem.hemorragia} />
        </div>
      </div>
    </>
  )
}