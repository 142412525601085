import React from 'react'
import ReactDatePicker from 'react-datepicker'
import CampoDaTarefa from './campo-da-tarefa'
import { ComponenteLeitura } from '../card'
import { Calendario } from '../../../design/icones'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import moment from 'moment'

export default function Data({ rotulo, obrigatorio, valor, leitura, definir }) {
  const icone = <Calendario/>

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        valor={formatarDataParaFormatoLocal(valor)}
      />
    )
  }

  const valorFormatado = valor && moment(valor).isValid() ? moment(valor).toDate() : valor

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valorFormatado || ''}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <ReactDatePicker
          value={valor}
          selected={valor}
          onChange={onChange}
          onFocus={onFocus}
          onSelect={onBlur}
          onCalendarClose={onBlur}
          showYearDropdown
          dateFormatCalendar='MMMM'
          dateFormat='dd/MM/yyyy'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          wrapperClassName='w-100'
        />
      }
    </CampoDaTarefa>
  )
}