import moment from 'moment'
import { formatarDataParaFormatoUniversal } from '../../../bibliotecas/data'

import {
  LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
} from '../../../acoes/tipos'

const estadoInicial = {
  filtros: {
    fim: formatarDataParaFormatoUniversal(moment().add(1, 'years')),
    inicio: formatarDataParaFormatoUniversal(moment()),
  },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE: {
      return {
        ...state,
        filtros: action.filtros,
        paginaDeDados: action.paginaDeDados,
      }
    }

    default: {
      return state
    }
  }
}