import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import * as Yup from 'yup'
import {
    ADICIONAR_CELULA,
    ALTERAR_CELULA,
    RECUPERAR_CELULA_PELO_IDENTIFICADOR,
    REMOVER_CELULA
} from "../../acoes/tipos";

export default function Formulario(props) {
    const {
        match,
        history,
        celula,
        salvar,
        recuperarPeloIdentificador,
        remover,
    } = props

    const { identificador } = match.params
    const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_CELULA])
    const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CELULA])
    const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_CELULA])

    useEffect(() => {
        if (identificador) {
            recuperarPeloIdentificador(identificador)
        }
    }, [identificador, recuperarPeloIdentificador])

    const removerSelecionado = async item => {
        const removeu = await remover(item.identificador)

        if (removeu) {
            history.push(`/cadastros/celulas`)
        }
    }

    const confirmarERemover = () => {
        confirmAlert({
            title: 'Confirmação',
            message: `Tem certeza que deseja excluir a célula ${celula.nome}?`,
            buttons: [{
                className:'is-danger',
                label: 'Sim',
                onClick: () => removerSelecionado(celula)
            }, {
                label: 'Não'
            }]
        })
    }

    return (
        <div className='container'>
            <div className='main-content-form'>
                <div className='header-page-form'>
                    <Breadcrumb />
                </div>
                <Spinner operacoes={[RECUPERAR_CELULA_PELO_IDENTIFICADOR, ALTERAR_CELULA, ADICIONAR_CELULA, REMOVER_CELULA]}>
                    <FormularioDoFormik
                        reinicializar={true}
                        valoresIniciais={{
                            identificador: celula.identificador || '',
                            nome: celula.nome || '',
                            observacoes: celula.observacoes || ''
                        }}
                        acao={salvar}
                        esquemaDeValidacoes={Yup.object().shape({
                            nome: Yup.string().required('Obrigatório'),
                        })}
                    >
                        <fieldset>
                            <h2 className='form-title'>{celula.identificador ? 'Alterar Célula' : 'Adicionar Célula'}</h2>
                            <r-grid columns-md='12'>
                                <r-cell span={6} span-md={12} span-lg={12}>
                                    <Input
                                        type='text'
                                        nome='nome'
                                        tabIndex={1}
                                        titulo='Nome *'
                                    />
                                </r-cell>
                                <r-cell span={6} span-md={12} span-lg={12}>
                                    <Input
                                        as='textarea'
                                        nome='observacoes'
                                        tabIndex={3}
                                        titulo='Observações'
                                    />
                                </r-cell>
                            </r-grid>
                        </fieldset>
                        {remover ?
                            <div className='list-btn-unidades'>
                                {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                                {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                            </div>
                            :
                            <div className='list-btn'>
                                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                                {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                            </div>
                        }
                    </FormularioDoFormik>
                </Spinner>
            </div>
        </div>
    )
}