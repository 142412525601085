import {
  LISTOU_COGNICOES_DOS_PACIENTES,
  LISTOU_COM_QUEM_OS_PACIENTES_MORAM,
  LISTOU_COMORBIDADES_CARDIOVASCULARES,
  LISTOU_COMORBIDADES_ENDOCRINAS,
  LISTOU_COMORBIDADES_GASTROINTESTINAIS,
  LISTOU_COMORBIDADES_NEUROLOGICAS,
  LISTOU_COMORBIDADES_ONCOLOGICAS,
  LISTOU_COMORBIDADES_OSTEOMUSCULARES,
  LISTOU_COMORBIDADES_PSIQUIATRICAS,
  LISTOU_COMORBIDADES_PULMONARES,
  LISTOU_COMORBIDADES_RENAIS,
  LISTOU_COMORBIDADES_REUMATOLOGICAS,
  LISTOU_CONDICOES_DOS_PACIENTES,
  LISTOU_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  LISTOU_ESCALAS_DE_FRAGILIDADE,
  LISTOU_ESTADOS_DE_DEAMBULACAO,
  LISTOU_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  LISTOU_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  LISTOU_VACINAS_EM_DIA
} from '../../acoes/tipos'

const estadoInicial = {
  escalasDeFragilidade: [],
  comQuemOsPacientesMoram: [],
  cognicoesDosPacientes: [],
  comorbidadesCardiovasculares: [],
  comorbidadesEndocrinas: [],
  comorbidadesGastrointestinais: [],
  comorbidadesNeurologicas: [],
  comorbidadesOncologicas: [],
  comorbidadesOsteomusculares: [],
  comorbidadesPsiquiatricas: [],
  comorbidadesPulmonares: [],
  comorbidadesRenais: [],
  comorbidadesReumatologicas: [],
  condicoesDosPacientes: [],
  dispositivosEmUsoDosPacientes: [],
  estadosDeDeambulacao: [],
  quedasNosUltimosDozeMeses: [],
  usosDeMedicamentosPelosPacientes: [],
  vacinasEmDia: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_ESCALAS_DE_FRAGILIDADE: {
      return {
        ...state,
        escalasDeFragilidade: action.escalas
      }
    }

    case LISTOU_COM_QUEM_OS_PACIENTES_MORAM: {
      return {
        ...state,
        comQuemOsPacientesMoram: action.dados
      }
    }

    case LISTOU_COGNICOES_DOS_PACIENTES: {
      return {
        ...state,
        cognicoesDosPacientes: action.dados
      }
    }

    case LISTOU_COMORBIDADES_CARDIOVASCULARES: {
      return {
        ...state,
        comorbidadesCardiovasculares: action.dados
      }
    }

    case LISTOU_COMORBIDADES_ENDOCRINAS: {
      return {
        ...state,
        comorbidadesEndocrinas: action.dados
      }
    }

    case LISTOU_COMORBIDADES_GASTROINTESTINAIS: {
      return {
        ...state,
        comorbidadesGastrointestinais: action.dados
      }
    }

    case LISTOU_COMORBIDADES_NEUROLOGICAS: {
      return {
        ...state,
        comorbidadesNeurologicas: action.dados
      }
    }

    case LISTOU_COMORBIDADES_ONCOLOGICAS: {
      return {
        ...state,
        comorbidadesOncologicas: action.dados
      }
    }

    case LISTOU_COMORBIDADES_OSTEOMUSCULARES: {
      return {
        ...state,
        comorbidadesOsteomusculares: action.dados
      }
    }

    case LISTOU_COMORBIDADES_PSIQUIATRICAS: {
      return {
        ...state,
        comorbidadesPsiquiatricas: action.dados
      }
    }

    case LISTOU_COMORBIDADES_PULMONARES: {
      return {
        ...state,
        comorbidadesPulmonares: action.dados
      }
    }

    case LISTOU_COMORBIDADES_RENAIS: {
      return {
        ...state,
        comorbidadesRenais: action.dados
      }
    }

    case LISTOU_COMORBIDADES_REUMATOLOGICAS: {
      return {
        ...state,
        comorbidadesReumatologicas: action.dados
      }
    }

    case LISTOU_CONDICOES_DOS_PACIENTES: {
      return {
        ...state,
        condicoesDosPacientes: action.dados
      }
    }

    case LISTOU_DISPOSITIVOS_EM_USO_DOS_PACIENTES: {
      return {
        ...state,
        dispositivosEmUsoDosPacientes: action.dados
      }
    }

    case LISTOU_ESTADOS_DE_DEAMBULACAO: {
      return {
        ...state,
        estadosDeDeambulacao: action.dados
      }
    }

    case LISTOU_QUEDAS_NOS_ULTIMOS_DOZE_MESES: {
      return {
        ...state,
        quedasNosUltimosDozeMeses: action.dados
      }
    }

    case LISTOU_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES: {
      return {
        ...state,
        usosDeMedicamentosPelosPacientes: action.dados
      }
    }

    case LISTOU_VACINAS_EM_DIA: {
      return {
        ...state,
        vacinasEmDia: action.dados
      }
    }

    default: {
      return state
    }
  }
}