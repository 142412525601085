import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, CampoNumerico, Select } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import { useProntuario } from '../../contexto'

export default function CalcioIonico({ metadados, valor, leitura, exibirDataExame, dataExame, setFieldValue }) {
  const { unidadesDeCalcioIonico, listarUnidadesDeCalcioIonico } = useProntuario()

  useEffect(() => { listarUnidadesDeCalcioIonico() }, [listarUnidadesDeCalcioIonico])

  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame

  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)

  useEffect(() => {
    if (exibirDataExame && valor.valor) {
      setFieldValue('calcioIonico.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  const [unidadeSelecionada, setUnidadeSelecionada] = useState(valor ? valor.unidade : '')

  const validarValor = valor => {
    if (obrigatorio) {
      if (unidadeSelecionada === 'mmoL/L')
        return (deveSerUmValorEntre(valor, 0.1, 6) || deveSerInformado(valor))

      else if (unidadeSelecionada === 'mg/dL')
        return (deveSerUmValorEntre(valor, 0.4, 24) || deveSerInformado(valor))

      else
        return deveSerInformado(valor)

    }

    if (unidadeSelecionada === 'mmoL/L') return deveSerUmValorEntre(valor, 0.1, 6)
    else if (unidadeSelecionada === 'mg/dL') return deveSerUmValorEntre(valor, 0.4, 24)
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  const validarUnidade = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Cálcio Iônico: </strong>
            {valor.unidade === 'mmoL/L' ? valor.valorEmMilimolPorLitro : valor.valorEmMiligramaPorDecilitro} {valor.unidade}  no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 4
    return 6
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <Select
            nome='calcioIonico.unidade'
            titulo={`Unidade do Cálcio Iônico ${obrigatorio ? '*' : ''}`}
            itens={unidadesDeCalcioIonico}
            campoCodigo='nome'
            campoDescricao='nome'
            validar={validarUnidade}
            onChange={evento => setUnidadeSelecionada(evento)}
          />
        </r-cell>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='calcioIonico.valor'
            titulo={`Cálcio Iônico ${obrigatorio ? '*' : ''}`}
            escala={!unidadeSelecionada ? '' : unidadeSelecionada}
            validar={validarValor}
            numeroDeCasasDecimais={2}
          />
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='calcioIonico.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}