import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  ALTEROU_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE,
  ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  REMOVEU_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
} from '../tipos'

const adicionouCampoExternoDoPaciente = campoExterno => ({
  type: ADICIONOU_CAMPO_EXTERNO_DO_PACIENTE,
  campoExterno,
})

const erroAoAdicionarCampoExternoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarCampoExternoDoPaciente = (identificador, campoExterno) => async dispatch => {
  dispatch({ type: ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE })

  try {    
    const resultado = await api.adicionarCampoExternoDoPaciente(identificador, campoExterno)
    
    dispatch(adicionouCampoExternoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarCampoExternoDoPaciente(erro, { identificador, campoExterno }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar campo externo do paciente.')))

    return false
  }
}

const alterouCampoExternoDoPaciente = campoExterno => ({
  type: ALTEROU_CAMPO_EXTERNO_DO_PACIENTE,
  campoExterno
})

const erroAoAlterarCampoExternoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  erro,
  parametros,
})

export const alterarCampoExternoDoPaciente = (identificadorDoPaciente, campoExterno) => async dispatch => {
  dispatch({ type: ALTERAR_CAMPO_EXTERNO_DO_PACIENTE })

  try {    
    const resultado = await api.alterarCampoExternoDoPaciente(identificadorDoPaciente, campoExterno)
    
    dispatch(alterouCampoExternoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarCampoExternoDoPaciente(erro, { identificadorDoPaciente, campoExterno }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o campo externo do paciente.')))

    return false
  }
}

const listouCamposExternosDoPaciente = (identificadorDoPaciente, camposExternos) => ({
  type: LISTOU_CAMPOS_EXTERNOS_DO_PACIENTE,
  identificadorDoPaciente,
  camposExternos
})

const erroAoListarCamposExternosDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE,
  erro,
  parametros
})

export const listarCamposExternosDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE })

  try {
    const resultado = await api.listarCamposExternosDoPaciente(identificadorDoPaciente)

    dispatch(listouCamposExternosDoPaciente(identificadorDoPaciente, resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCamposExternosDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar os campos externos do paciente.')))
  }
}

const removeuCampoExternoDoPaciente = identificador => ({
  type: REMOVEU_CAMPO_EXTERNO_DO_PACIENTE,
  identificador,
})

const erroAoRemoverCampoExternoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_CAMPO_EXTERNO_DO_PACIENTE,
  erro,
  parametros
})

export const removerCampoExternoDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: REMOVER_CAMPO_EXTERNO_DO_PACIENTE })

  try {    
    const resultado = await api.removerCampoExternoDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(removeuCampoExternoDoPaciente(identificadorDoPaciente, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverCampoExternoDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o campo externo do paciente.')))

    return false
  }
}

const recuperouCampoExternoDoPaciente = campoExterno => ({
  type: RECUPEROU_CAMPO_EXTERNO_DO_PACIENTE, 
  campoExterno
})

const erroAoRecuperarCampoExternoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  erro,
  parametros
})

export const recuperarCampoExternoDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE })
  try {    
    const resultado = await api.recuperarCampoExternoDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(recuperouCampoExternoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarCampoExternoDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o campo externo do paciente.')))
  }
}