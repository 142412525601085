import {connect} from 'react-redux'
import acoes from '../../acoes/index'
import PedidoDeContato from './index'

const mapStatesToProps = state => {
  return {
    cidades: state.pedidosDeContato.cidades,
    planosDeSaude: state.pedidosDeContato.planosDeSaude,
    sexos: state.sexos
  }
}

export default connect(mapStatesToProps, acoes)(PedidoDeContato)