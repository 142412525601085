import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN, ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN, LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN } from './tipos'

const listouMotivosDeBloqueioDoLogin = motivosDeBloqueioDoLogin => ({
  type: LISTOU_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  motivosDeBloqueioDoLogin
})

const erroAoListarMotivosDeBloqueioDoLogin = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  erro
})

export const listarMotivosDeBloqueioDoLogin = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN })

  try {
    const resultado = await api.listarMotivosDeBloqueioDoLogin()

    dispatch(listouMotivosDeBloqueioDoLogin(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados.dados
  } catch (erro) {
    dispatch(erroAoListarMotivosDeBloqueioDoLogin(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de bloqueio do login.')))

    return false
  }
}