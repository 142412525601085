import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes, usePossuiAlgumaPermissao } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle, Select, InputSugestao } from '../formik/formulario'
import DicaDePesquisa from '../dica-de-pesquisa'

import {
  RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  SALVAR_TEMPLATE,
  ADICIONAR_TEMPLATE,
  EXCLUIR_TEMPLATE,
} from '../../acoes/tipos'
import {formatarDataEHoraParaFormatoLocal} from '../../bibliotecas/data'

export default function Formulario(props) {
  const {
    componentes = [],
    codificador = [],
    decodificador = [],
    dadosDaSugestao = [],
    salvar,
    selecionado = {},
    recuperarPorIdentificador,
    remover,
  } = props

  const history = useHistory()
  const { identificador } = useParams()

  const podeEditarCompartilhado = usePossuiAsPermissoes([permissoes.ALTERAR_TEMPLATE_COMPARTILHADO])
  const podeEditarIndividual = usePossuiAsPermissoes([permissoes.ALTERAR_TEMPLATE_DO_USUARIO])
  const podeAdicionarCompartilhado = usePossuiAsPermissoes([permissoes.ADICIONAR_TEMPLATE_COMPARTILHADO])
  const podeAdicionarIndividual = usePossuiAsPermissoes([permissoes.ADICIONAR_TEMPLATE_DO_USUARIO])
  const podeExcluir = usePossuiAlgumaPermissao([permissoes.REMOVER_TEMPLATE_COMPARTILHADO, permissoes.REMOVER_TEMPLATE_DO_USUARIO])
  const podeExcluirCompartilhado = usePossuiAsPermissoes([permissoes.REMOVER_TEMPLATE_COMPARTILHADO])
  const podeExcluirIndividual = usePossuiAsPermissoes([permissoes.REMOVER_TEMPLATE_DO_USUARIO])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o template ${selecionado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(selecionado)
      }, {
        label: 'Não'
      }]
    })
  }

  const valorInicialCompartilhado = () => {
    if (identificador) return podeEditarCompartilhado ? true : false

    return podeAdicionarCompartilhado ? true : false
  }

  const exibirExcluir = (compartilhado) => {
    if (!podeExcluir) return false
    if (compartilhado) return podeExcluirCompartilhado ? true : false
    return podeExcluirIndividual
  }

  const exibirAlterar = (compartilhado) => {
    if (compartilhado) return podeEditarCompartilhado
    return podeEditarIndividual
  }

  const exibirSalvar = (compartilhado) => {
    if (compartilhado) return podeAdicionarCompartilhado
    return podeAdicionarIndividual
  }

  const atualizarPreview = (template, acao) => {
    return acao.reduce((acumulado, atual) => acumulado.replaceAll(atual.id, atual.display), template)
  }

  const alterarPreviewDoFormulario = (template, setFieldValue) => {
    setFieldValue('preview', atualizarPreview(template, decodificador))
  }

  const selecionadoDecodificado = {
    ...selecionado,
    compartilhado: selecionado.compartilhado !== undefined ? selecionado.compartilhado : valorInicialCompartilhado(),
    template: selecionado.template ? atualizarPreview(selecionado.template, codificador) : '',
    preview: selecionado.template ? atualizarPreview(atualizarPreview(selecionado.template, codificador), decodificador) : '',
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[RECUPERAR_TEMPLATE_POR_IDENTIFICADOR, SALVAR_TEMPLATE, ADICIONAR_TEMPLATE, EXCLUIR_TEMPLATE]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: selecionadoDecodificado.identificador || '',
              compartilhado: selecionadoDecodificado.compartilhado,
              componente: selecionadoDecodificado.componente || null,
              nome: selecionadoDecodificado.nome || '',
              template: selecionadoDecodificado.template,
              preview: selecionadoDecodificado.preview,
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              template: Yup.string().required('Obrigatório'),
              componente: Yup.string().nullable().required('Obrigatório')
            })}
          >
            {({ values, setFieldValue }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{identificador ? 'Editar Template' : 'Adicionar Novo Template'}</h2>
                  <r-grid columns-md={12} columns-lg={12}>
                    <r-cell span={4} span-md={12} span-lg={6}>
                      <Input
                        nome='nome'
                        tabIndex={1}
                        titulo='Nome *'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={4}>
                      <Select
                        nome='componente'
                        tabIndex={2}
                        titulo='Componente *'
                        itens={componentes}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={2}>
                      <div className='form-toggle-list'>
                        <Toglle
                          className='form-toggle-2'
                          nome='compartilhado'
                          tabIndex={3}
                          titulo='Compartilhado'
                          disabled={identificador ? true : !podeAdicionarCompartilhado}
                        />
                      </div>
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <DicaDePesquisa dica='Utilize @@ para menções.' exemplo='@@medicamentos'>
                        <InputSugestao
                          as='textarea'
                          nome='template'
                          titulo='Template *'
                          dados={dadosDaSugestao}
                          tags={true}
                          onChange={evento => alterarPreviewDoFormulario(evento.target.value, setFieldValue)}
                          minHeight={200}
                        />
                      </DicaDePesquisa>
                    </r-cell>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <InputSugestao
                        as='textarea'
                        nome='preview'
                        titulo='Pré-visualização'
                        disabled={true}
                        minHeight={200}
                      />
                    </r-cell>
                  </r-grid>
                  {selecionadoDecodificado.identificador &&
                   <div className='informacoes-de-auditoria'>
                     <span className='titulo-do-usuario'>Criado por: </span>
                     <span className='dados-do-usuario'>{`${selecionadoDecodificado.quemCriou.nome} | ${formatarDataEHoraParaFormatoLocal(selecionadoDecodificado.quandoCriou)} `}</span>
                     <span className='titulo-do-usuario'>Última alteração: </span>
                     <span className='dados-do-usuario'>{`${selecionadoDecodificado.quemAlterou.nome} | ${formatarDataEHoraParaFormatoLocal(selecionadoDecodificado.quandoAlterou)}`}</span>
                   </div>
                  }
                </fieldset>
                {remover ?
                  <div className='list-btn-unidades'>
                    {exibirExcluir(selecionado.compartilhado) && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                    {exibirAlterar(selecionado.compartilhado) && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  </div>
                  :
                  <div className='list-btn'>
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    {exibirSalvar(selecionado.compartilhado) && <button type='submit' className='button --primary'>Adicionar</button>}
                  </div>
                }
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}