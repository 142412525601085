import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import Cabecalho from './cabecalho'
import {BarraLateral} from './barra-lateral'
import {
  PerguntaDeOpcaoUnica,
  PerguntaEscala,
  PerguntaObservacoes,
  PerguntaSelecionarItens,
  PerguntaSimNao
} from './perguntas'
import {Secao, Secoes} from './secoes'
import {
  ADICIONAR_TRIAGEM,
  ALTERAR_TRIAGEM,
} from '../../acoes/tipos'
import Spinner from '../spinner'

export default function Triagem(props) {
  const {
    paciente,
    listarIntensidadesDasDoresDaTriagem,
    listarCondicoesRelacionadasAosEstadosHabituais,
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarRespostasAPressaoNoLocalDaHemorragia,
    listarRespostasValidasSobreDiarreia,
    listarRespostasValidasSobreVomito,
    listarResultadosDasTriagens,
    resultadosDasTriagens,
    situacoesAssociadasADorNoPeito = [],
    situacoesAssociadasAAlteracaoOuPerdaDaConsciencia = [],
    condicoesRelacionadasAosEstadosHabituais = [],
    situacoesAssociadasAQueda = [],
    respostasValidasSobreDiarreia = [],
    respostasValidasSobreVomito = [],
    respostasAPressaoNoLocalDaHemorragia = [],
    situacoesAssociadasAAlteracoesGastrointestinais = [],
    situacoesAssociadasASintomasUrinarios = [],
    sintomasAssociadasAoLocalDaDorMusculoesqueletica = [],
    intensidadesDasDores = [],
    adicionarTriagem,
    alterarTriagem,
    cancelar,
    triagem,
  } = props

  const triagemPadrao = {
    respiracao: {
      dificuldadeParaRespirar: false,
      falaEntrecortadaOuRespiracaoAcelerada: false,
      coloracaoAzuladaNosLabiosOuExtremidades: false,
      podeEstarEngasgado: false,
      observacoes: ''
    },
    dorNoPeito: {
      intensidade: null,
      situacoesAssociadas: [],
      observacoes: ''
    },
    alteracaoOuPerdaDaConsciencia: {
      inconsciente: false,
      dificuldadeParaRespirar: false,
      situacoesAssociadas: [],
      confusoOuDesorientado: 'nao',
      dificuldadeDeResponderPerguntas: 'nao',
      observacoes: null
    },
    queda: {
      aconteceuHaMenosDeVinteEQuatroHoras: false,
      inconsciente: false,
      dificuldadeParaRespirar: false,
      situacoesAssociadas: [],
      confusoOuDesorientado: 'nao',
      dificuldadeDeResponderPerguntas: 'nao',
      incapacidadeDeSeMovimentar: false,
      sangramentoLesaoAbertaHematomaOuEdema: false,
      dor: null,
      observacoes: null
    },
    alteracoesGastroIntestinais: {
      diarreia: '',
      vomito: '',
      situacoesAssociadas: [],
      dorAbdominal: null,
      observacoes: null,
    },
    dorMusculoesqueletica: {
      incapacitante: false,
      intensidade: null,
      iniciouHaMenosDeVinteEQuatroHoras: false,
      sintomasAssociados: [],
      observacoes: null,
    },
    sintomasUrinarios: {
      semUrinarHaMaisDeOitoHoras: false,
      dorAbdominal: null,
      situacoesAssociadas: [],
      observacoes: null,
    },
    hemorragia: {
      sinaisDeHemorragia: false,
      respostaAPressao: null,
      usaAnticoagulantes: false,
      observacoes: null,
    },
    resultado: null,
    justificativa: '',
  }

  const [secaoSelecionada, setSecaoSelecionada] = useState('Respiração')
  const [questionario, setQuestionario] = useState(triagem ? triagem : triagemPadrao)

  useEffect(() => {
    listarResultadosDasTriagens()
    listarIntensidadesDasDoresDaTriagem()
    listarCondicoesRelacionadasAosEstadosHabituais()
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia()
    listarSituacoesAssociadasAAlteracoesGastrointestinais()
    listarSituacoesAssociadasADorNoPeito()
    listarSituacoesAssociadasAQueda()
    listarSituacoesAssociadasASintomasUrinarios()
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica()
    listarRespostasAPressaoNoLocalDaHemorragia()
    listarRespostasValidasSobreDiarreia()
    listarRespostasValidasSobreVomito()
  }, [
    listarIntensidadesDasDoresDaTriagem,
    listarCondicoesRelacionadasAosEstadosHabituais,
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarRespostasAPressaoNoLocalDaHemorragia,
    listarRespostasValidasSobreDiarreia,
    listarRespostasValidasSobreVomito,
    listarResultadosDasTriagens])

  const responderPergunta = (secao = '', pergunta, resposta) => {
    if (!questionario.dorMusculoesqueletica.intensidade || (secao === 'dorMusculoesqueletica' && pergunta === 'intensidade'
      && resposta === '')) {
      questionario.dorMusculoesqueletica.incapacitante = false
    }

    if (!questionario.dorNoPeito.intensidade || (secao === 'dorNoPeito' && pergunta === 'intensidade'
      && resposta === '')) {
      questionario.dorNoPeito.situacoesAssociadas = []
    }

    setQuestionario({
      ...questionario,
      [secao]: {
        ...questionario[secao],
        [pergunta]: resposta
      }
    })
  }

  const maiorOuIgualASeis = intensidade => {
    return intensidade === 'seis' ||
      intensidade === 'sete' ||
      intensidade === 'oito' ||
      intensidade === 'nove' ||
      intensidade === 'dez'
  }

  const menorQueSeis = intensidade => {
    return intensidade === 'um' ||
      intensidade === 'dois' ||
      intensidade === 'tres' ||
      intensidade === 'quatro' ||
      intensidade === 'cinco'
  }

  const calcularResultado = questionario => {
    if (questionario.justificativa) {
      return questionario.resultado
    }

    let resultado = 'verde'

    //respiração
    if (questionario.respiracao.dificuldadeParaRespirar ||
      questionario.respiracao.falaEntrecortadaOuRespiracaoAcelerada ||
      questionario.respiracao.coloracaoAzuladaNosLabiosOuExtremidades ||
      questionario.respiracao.podeEstarEngasgado) {
      return 'vermelho'
    }

    //dor no peito
    if (maiorOuIgualASeis(questionario.dorNoPeito.intensidade)) {
      return 'vermelho'
    }

    if (menorQueSeis(questionario.dorNoPeito.intensidade)) {
      resultado = 'amarelo'
    }

    if (questionario.dorNoPeito.situacoesAssociadas.length > 0) {
      return 'vermelho'
    }

    //consciência
    if (questionario.alteracaoOuPerdaDaConsciencia.inconsciente
      || questionario.alteracaoOuPerdaDaConsciencia.dificuldadeParaRespirar) {
      return 'vermelho'
    }

    if (questionario.alteracaoOuPerdaDaConsciencia.situacoesAssociadas.length > 0) {
      resultado = 'amarelo'
    }

    if (questionario.alteracaoOuPerdaDaConsciencia.confusoOuDesorientado === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (questionario.alteracaoOuPerdaDaConsciencia.confusoOuDesorientado === 'sim_mas_jah_era_assim') {
      resultado = 'amarelo'
    }

    if (questionario.alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (questionario.alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas === 'sim_mas_jah_era_assim') {
      resultado = 'amarelo'
    }

    //queda
    if (questionario.queda.inconsciente) {
      return 'vermelho'
    }

    if (questionario.queda.dificuldadeParaRespirar) {
      return 'vermelho'
    }

    if (questionario.queda.situacoesAssociadas.length > 0) {
      resultado = 'amarelo'
    }

    if (questionario.queda.confusoOuDesorientado === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (questionario.queda.confusoOuDesorientado === 'sim_mas_jah_era_assim') {
      resultado = 'amarelo'
    }

    if (questionario.queda.dificuldadeDeResponderPerguntas === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (questionario.queda.dificuldadeDeResponderPerguntas === 'sim_mas_jah_era_assim') {
      resultado = 'amarelo'
    }

    if (questionario.queda.incapacidadeDeSeMovimentar) {
      resultado = 'amarelo'
    }

    if (questionario.queda.sangramentoLesaoAbertaHematomaOuEdema) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(questionario.queda.dor)) {
      return 'vermelho'
    }

    if (menorQueSeis(questionario.queda.dor)) {
      resultado = 'amarelo'
    }

    //gastrointestinais
    if (questionario.alteracoesGastroIntestinais.diarreia === 'sim_com_sangue') {
      resultado = 'amarelo'
    }

    if (questionario.alteracoesGastroIntestinais.vomito === 'sim_com_sangue') {
      resultado = 'amarelo'
    }

    if (questionario.alteracoesGastroIntestinais.situacoesAssociadas.length > 0) {
      resultado = 'amarelo'
    }

    if (maiorOuIgualASeis(questionario.alteracoesGastroIntestinais.dorAbdominal)) {
      return 'vermelho'
    }

    if (menorQueSeis(questionario.alteracoesGastroIntestinais.dorAbdominal)) {
      resultado = 'amarelo'
    }

    //urinário
    if (questionario.sintomasUrinarios.semUrinarHaMaisDeOitoHoras) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(questionario.sintomasUrinarios.dorAbdominal)) {
      return 'vermelho'
    }

    if (menorQueSeis(questionario.sintomasUrinarios.dorAbdominal)) {
      resultado = 'amarelo'
    }

    const situacoesQuePontuam = questionario.sintomasUrinarios.situacoesAssociadas
      .filter(x => x !== 'dor_para_urinar' && x !== 'vontade_de_urinar_varias_vezes')

    if (situacoesQuePontuam.length > 0) {
      resultado = 'amarelo'
    }

    //muscoesquelética
    if (questionario.dorMusculoesqueletica.incapacitante) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(questionario.dorMusculoesqueletica.intensidade)) {
      return 'vermelho'
    }

    if (menorQueSeis(questionario.dorMusculoesqueletica.intensidade)) {
      resultado = 'amarelo'
    }

    if (questionario.dorMusculoesqueletica.sintomasAssociados.length > 0) {
      resultado = 'amarelo'
    }

    //hemorragia
    if (questionario.hemorragia.sinaisDeHemorragia) {
      resultado = 'amarelo'
    }

    if (questionario.hemorragia.respostaAPressao === 'continua') {
      return 'vermelho'
    }

    if (questionario.hemorragia.usaAnticoagulantes) {
      resultado = 'amarelo'
    }

    return resultado
  }

  const resultado = calcularResultado(questionario)

  const gravar = () => {
    questionario.paciente = paciente.identificador
    questionario.resultado = resultado

    if (!questionario.identificador) {
      questionario.identificador = uuidv4()
      adicionarTriagem(questionario)
    } else {
      alterarTriagem(questionario)
    }
  }

  const sobrescreverResultado = (justificativa, resultado) => {
    setQuestionario({
      ...questionario,
      resultado: resultado,
      justificativa: justificativa
    })
  }
  const removerResultadoFixo = () => {
    sobrescreverResultado('', calcularResultado(questionario))
  }

  const secoes = ['Respiração', 'Dor no Peito', 'Alteração ou Perda da Consciência', 'Queda', 'Alterações Gastrointestinais',
    'Sintomas Urinários', 'Dor Musculoesquelética', 'Hemorragia']

  const indexDaSecaoSelecionada = secoes.indexOf(secaoSelecionada)
  return (
    <>
      <Spinner operacoes={[ADICIONAR_TRIAGEM, ALTERAR_TRIAGEM]}>
        {({processando}) => (
          <div className={`triagem form ${processando && 'is-loading'}`}>
            <Cabecalho
              paciente={paciente}
              resultado={resultado}
              resultadosDasTriagens={resultadosDasTriagens}
              sobrescreverResultado={sobrescreverResultado}
              justificativa={questionario.justificativa}
            />
            {questionario.justificativa &&
              <div>
                <div className='form-choice-alerta'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-alerta__item'>
                    <h2 className='alerta-de-justificativa-preenchida'>
                      O resultado da triagem foi alterado e uma justificativa foi informada. Portanto, mudanças no
                      questionário não afetarão o resultado.
                    </h2>
                    <button
                      className='button-remover-justificativa-triagem'
                      onClick={removerResultadoFixo}
                    >
                      Remover resultado fixo
                    </button>
                  </div>
                </div>
              </div>
            }
            <div className='conteudo-triagem tarefas'>
              <BarraLateral
                itens={secoes}
                selecionado={secaoSelecionada}
                aoSelecionar={item => setSecaoSelecionada(item)}
              />
              <Secoes
                className='perguntas'
                abaSelecionada={indexDaSecaoSelecionada}
                aoSelecionarResposta={responderPergunta}
                questionario={questionario}
              >
                <Secao chave='respiracao'>
                  <PerguntaSimNao
                    chave='dificuldadeParaRespirar'
                    texto='Está com dificuldade para respirar?'
                  />
                  <PerguntaSimNao
                    chave='falaEntrecortadaOuRespiracaoAcelerada'
                    texto='A fala está entrecortada ou a respiração acelerada?'
                  />
                  <PerguntaSimNao
                    chave='coloracaoAzuladaNosLabiosOuExtremidades'
                    texto='Há coloração azulada nos lábios ou extremidades?'
                  />
                  <PerguntaSimNao
                    chave='podeEstarEngasgado'
                    texto='Pode estar engasgado (sensação de sufocação, mãos no pescoço, salivação)?'
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='dorNoPeito' aoSelecionarResposta={responderPergunta}>
                  <PerguntaEscala
                    chave='intensidade'
                    texto='Está sentindo dor no peito?'
                    intensidades={intensidadesDasDores}
                  />
                  <PerguntaSelecionarItens
                    key={`situacoesAssociadas_${questionario.dorNoPeito.intensidade}`}
                    chave='situacoesAssociadas'
                    texto='Teve alguma das seguintes situações associadas?'
                    desabilitado={!questionario.dorNoPeito.intensidade}
                    itens={situacoesAssociadasADorNoPeito}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='alteracaoOuPerdaDaConsciencia' aoSelecionarResposta={responderPergunta}>
                  <PerguntaSimNao chave='inconsciente' texto='O paciente está inconsciente?'/>
                  <PerguntaSimNao chave='dificuldadeParaRespirar' texto='Está com dificuldade ou sem respirar?'/>
                  <PerguntaSelecionarItens
                    chave='situacoesAssociadas'
                    texto='Teve alguma das seguintes situações recente?'
                    itens={situacoesAssociadasAAlteracaoOuPerdaDaConsciencia}
                  />
                  <PerguntaDeOpcaoUnica
                    chave='confusoOuDesorientado'
                    texto='Em relação ao estado habitual, nota que está confuso ou desorientado?'
                    opcoes={condicoesRelacionadasAosEstadosHabituais}
                  />
                  <PerguntaDeOpcaoUnica
                    chave='dificuldadeDeResponderPerguntas'
                    texto='Tem dificuldade em responder às perguntas?'
                    opcoes={condicoesRelacionadasAosEstadosHabituais}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='queda'>
                  <PerguntaSimNao chave='aconteceuHaMenosDeVinteEQuatroHoras' texto='Aconteceu há menos de 24h?'/>
                  <PerguntaSimNao chave='inconsciente' texto='Está inconsciente?'/>
                  <PerguntaSimNao chave='dificuldadeParaRespirar' texto='Está com dificuldade ou sem respirar?'/>
                  <PerguntaSelecionarItens
                    chave='situacoesAssociadas'
                    texto='Teve alguma das seguintes situações associadas?'
                    itens={situacoesAssociadasAQueda}
                  />
                  <PerguntaDeOpcaoUnica
                    chave='confusoOuDesorientado'
                    texto='Em relação ao estado habitual, nota que está confuso ou desorientado?'
                    opcoes={condicoesRelacionadasAosEstadosHabituais}
                  />
                  <PerguntaDeOpcaoUnica
                    chave='dificuldadeDeResponderPerguntas'
                    texto='Tem dificuldade em responder às perguntas?'
                    opcoes={condicoesRelacionadasAosEstadosHabituais}
                  />
                  <PerguntaSimNao
                    chave='incapacidadeDeSeMovimentar'
                    texto='Incapacidade para se movimentar normalmente após a queda?'
                  />
                  <PerguntaSimNao
                    chave='sangramentoLesaoAbertaHematomaOuEdema'
                    texto='Apresenta sangramento, lesão aberta, hematoma ou edema após a queda?'
                  />
                  <PerguntaEscala
                    chave='dor'
                    texto='Presença de dor?'
                    intensidades={intensidadesDasDores}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='alteracoesGastroIntestinais'>
                  <PerguntaDeOpcaoUnica
                    chave='diarreia'
                    texto='O paciente está com diarréia?'
                    opcoes={respostasValidasSobreDiarreia}
                  />
                  <PerguntaDeOpcaoUnica
                    chave='vomito'
                    texto='O paciente está com vômitos?'
                    opcoes={respostasValidasSobreVomito}
                  />
                  <PerguntaSelecionarItens
                    chave='situacoesAssociadas'
                    texto='Teve alguma das seguintes situações associadas?'
                    itens={situacoesAssociadasAAlteracoesGastrointestinais}
                  />
                  <PerguntaEscala
                    chave='dorAbdominal'
                    texto='O paciente apresenta dor abdominal?'
                    intensidades={intensidadesDasDores}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='sintomasUrinarios'>
                  <PerguntaSimNao
                    chave='semUrinarHaMaisDeOitoHoras'
                    texto='Está sem urinar há mais de 8h?'
                  />
                  <PerguntaEscala
                    chave='dorAbdominal'
                    texto='Há dor abdominal, no flanco ou abaixo do umbigo?'
                    intensidades={intensidadesDasDores}
                  />
                  <PerguntaSelecionarItens
                    chave='situacoesAssociadas'
                    texto='Teve alguma das seguintes situações associadas?'
                    itens={situacoesAssociadasASintomasUrinarios}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='dorMusculoesqueletica'>
                  <PerguntaEscala
                    texto='Informar a intensidade da dor?'
                    chave='intensidade'
                    intensidades={intensidadesDasDores}
                  />
                  <PerguntaSimNao
                    chave='incapacitante'
                    valor={questionario.dorMusculoesqueletica.incapacitante}
                    texto='A dor é incapacitante (impede a pessoa de fazer até pequenos movimentos)?'
                    desabilitado={!questionario.dorMusculoesqueletica.intensidade}
                  />
                  <PerguntaSimNao
                    chave='iniciouHaMenosDeVinteEQuatroHoras'
                    texto='Iniciou há menos de 24h?'
                  />
                  <PerguntaSelecionarItens
                    chave='sintomasAssociados'
                    texto='Sintomas associados ao local da dor?'
                    itens={sintomasAssociadasAoLocalDaDorMusculoesqueletica}
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
                <Secao chave='hemorragia'>
                  <PerguntaSimNao
                    chave='sinaisDeHemorragia'
                    texto='Há sinais de hemorragia? (Se sim, descreva com mais detalhes no campo observações)'
                  />
                  <PerguntaDeOpcaoUnica
                    chave='respostaAPressao'
                    texto='O que acontece com a hemorragia ao pressionar com as mãos?'
                    opcoes={respostasAPressaoNoLocalDaHemorragia}
                  />
                  <PerguntaSimNao
                    chave='usaAnticoagulantes'
                    texto='O paciente está usando anticoagulantes?'
                  />
                  <PerguntaObservacoes chave='observacoes' texto='Observações'/>
                </Secao>
              </Secoes>
            </div>
            <div className='acoes-triagem'>
              <button
                type='button'
                className='button --light'
                onClick={cancelar}
              >
                Sair
              </button>
              <button
                type='button'
                className='button --primary'
                onClick={gravar}
              >
                Gravar
              </button>
            </div>
          </div>
        )}
      </Spinner>
    </>
  )
}
