import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_DESCRICAO_DA_TAREFA,
  DEFINIU_DESCRICAO_DA_TAREFA,
  ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA
} from '../tipos'

const definiuDescricaoDaTarefa = (identificadorDaTarefa, descricao) => ({
  identificadorDaTarefa,
  descricao,
  type: DEFINIU_DESCRICAO_DA_TAREFA,
})

const erroAoDefinirDescricaoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_DESCRICAO_DA_TAREFA,
  erro,
  parametros,
})

export const definirDescricaoDaTarefa = (identificadorDaTarefa, descricao) => async dispatch => {
  dispatch({ type: DEFINIR_DESCRICAO_DA_TAREFA })

  try {
    const resultado = await api.definirDescricaoDaTarefa(identificadorDaTarefa, descricao)

    dispatch(definiuDescricaoDaTarefa(identificadorDaTarefa, descricao))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirDescricaoDaTarefa(erro, { identificadorDaTarefa, descricao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir descrição da tarefa.')))

    return false
  }
}