import condicoesRelacionadasAosEstadosHabituais from './condicoes-relacionadas-aos-estados-habituais'
import intensidadesDasDores from './intensidades-das-dores'
import situacoesAssociadasAAlteracaoOuPerdaDaConsciencia
  from './situacoes-associadas-a-alteracao-ou-perda-da-consciencia'
import situacoesAssociadasAAlteracoesGastrointestinais from './situacoes-associadas-a-alteracoes-gastrointestinais'
import situacoesAssociadasADorNoPeito from './situacoes-associadas-a-dor-no-peito'
import situacoesAssociadasAQueda from './situacoes-associadas-a-queda'
import situacoesAssociadasASintomasUrinarios from './situacoes-associadas-a-sintomas-urinarios'
import sintomasAssociadasAoLocalDaDorMusculoesqueletica from './sintomas-associados-ao-local-da-dor-musculoesqueletica'
import respostasAPressaoNoLocalDaHemorragia from './respostas-a-pressao-no-local-da-hemorragia'
import respostasValidasSobreDiarreia from './respostas-validas-sobre-diarreia'
import respostasValidasSobreVomito from './respostas-validas-sobre-vomito'
import resultadosDasTriagens from './resultados-das-triagens'
import {
  ADICIONOU_TRIAGEM,
  ALTEROU_TRIAGEM,
  RECUPEROU_TRIAGEM_PELO_IDENTIFICADOR
} from "../../acoes/tipos";


const estadoInicial = {
  itemAtual: {},
  condicoesRelacionadasAosEstadosHabituais: [],
  intensidadesDasDores: [],
  situacoesAssociadasAAlteracaoOuPerdaDaConsciencia: [],
  situacoesAssociadasAAlteracoesGastrointestinais: [],
  situacoesAssociadasADorNoPeito: [],
  situacoesAssociadasAQueda: [],
  situacoesAssociadasASintomasUrinarios: [],
  sintomasAssociadasAoLocalDaDorMusculoesqueletica: [],
  respostasAPressaoNoLocalDaHemorragia: [],
  respostasValidasSobreDiarreia: [],
  respostasValidasSobreVomito: [],
  resultadosDasTriagens: [],
}

const triagem = (state, action) => {
  switch (action.type) {
    case RECUPEROU_TRIAGEM_PELO_IDENTIFICADOR:
    case ALTEROU_TRIAGEM:
    case ADICIONOU_TRIAGEM: {
      return {
        ...state,
        itemAtual: action.triagem,
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = triagem(state, action)

  return {
    itemAtual: {
      ...dados.itemAtual
    },
    condicoesRelacionadasAosEstadosHabituais: condicoesRelacionadasAosEstadosHabituais(state.condicoesRelacionadasAosEstadosHabituais, action),
    intensidadesDasDores: intensidadesDasDores(state.intensidadesDasDores, action),
    situacoesAssociadasAAlteracaoOuPerdaDaConsciencia: situacoesAssociadasAAlteracaoOuPerdaDaConsciencia(state.situacoesAssociadasAAlteracaoOuPerdaDaConsciencia, action),
    situacoesAssociadasAAlteracoesGastrointestinais: situacoesAssociadasAAlteracoesGastrointestinais(state.situacoesAssociadasAAlteracoesGastrointestinais, action),
    situacoesAssociadasADorNoPeito: situacoesAssociadasADorNoPeito(state.situacoesAssociadasADorNoPeito, action),
    situacoesAssociadasAQueda: situacoesAssociadasAQueda(state.situacoesAssociadasAQueda, action),
    situacoesAssociadasASintomasUrinarios: situacoesAssociadasASintomasUrinarios(state.situacoesAssociadasASintomasUrinarios, action),
    sintomasAssociadasAoLocalDaDorMusculoesqueletica: sintomasAssociadasAoLocalDaDorMusculoesqueletica(state.sintomasAssociadasAoLocalDaDorMusculoesqueletica, action),
    respostasAPressaoNoLocalDaHemorragia: respostasAPressaoNoLocalDaHemorragia(state.respostasAPressaoNoLocalDaHemorragia, action),
    respostasValidasSobreDiarreia: respostasValidasSobreDiarreia(state.respostasValidasSobreDiarreia, action),
    respostasValidasSobreVomito: respostasValidasSobreVomito(state.respostasValidasSobreVomito, action),
    resultadosDasTriagens: resultadosDasTriagens(state.resultadosDasTriagens, action),
  }
}