import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTarefas } from './contexto'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { alterarEspacoPorUnderline, removerAcentos } from '../../bibliotecas/texto'
import { Hash } from '../../design/icones'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import { ordenarAlfabeticamente } from '../../bibliotecas/ordenacao'

export default function ListaDeTiposDeTarefas({ history, filtros }) {
  const {
    tiposDeTarefa,
    definirTipoDaTarefaAtual,
    definirDashboardDaTarefaAtual,
    tipoDaTarefaAtual,
    semTipoSelecionado,
    dashboardDaTarefaAtual
  } = useTarefas()

  const { usuario } = useUsuarioLogado()
  const { identificadorDoTipoDeTarefa } = useParams()

  const tipos = converterObjetoParaArray(tiposDeTarefa)

  useEffect(() => {
    if (!tipoDaTarefaAtual && identificadorDoTipoDeTarefa) {
      definirTipoDaTarefaAtual(identificadorDoTipoDeTarefa)
    }
  }, [definirTipoDaTarefaAtual, identificadorDoTipoDeTarefa, tipoDaTarefaAtual])

  useEffect(() => {
    if (tipoDaTarefaAtual && !dashboardDaTarefaAtual) {
      if (tipos.length > 0 && tipos.find(x => x.identificador === tipoDaTarefaAtual)) {
        const nome = tipos.find(x => x.identificador === tipoDaTarefaAtual)?.nome
        const nomeDoDashboard = 'TAREFA_DE_'.concat((alterarEspacoPorUnderline(removerAcentos(nome))).toUpperCase())

        definirDashboardDaTarefaAtual(nomeDoDashboard)
      }
    }
  }, [tipoDaTarefaAtual, dashboardDaTarefaAtual, tipos, definirDashboardDaTarefaAtual])

  const selecionarTipo = tarefa => {
    const nomeDoDashboard = 'TAREFA_DE_'.concat((alterarEspacoPorUnderline(removerAcentos(tarefa.nome))).toUpperCase())

    definirTipoDaTarefaAtual(tarefa.identificador)
    definirDashboardDaTarefaAtual(nomeDoDashboard)

    const abasParaOReducer = {
      'minhas': 'minhas',
      'minha': 'minha-carteira',
      'criadasPorMim': 'criadas-por-mim',
      'todas': 'todas',
      'dashboard': 'dashboard',
      'fluxo': 'fluxo',
    }

    const abaSelecionada = filtros[tarefa.identificador] && filtros[tarefa.identificador].abaAtual ? abasParaOReducer[filtros[tarefa.identificador].abaAtual] : 'minhas'
    history.push(`/tarefas/${tarefa.identificador}/${abaSelecionada}`)
  }

  const renderizarTipo = item => {
    return (
      <div
        key={item.identificador}
        className={`box-panel__sidebar__item --task ${identificadorDoTipoDeTarefa && identificadorDoTipoDeTarefa === item.identificador ? 'is-selected' : ''}`}
        onClick={() => selecionarTipo(item)}
      >
        <p className='evolution-info__name'><Hash size={18} />{item.nome}</p>
      </div>
    )
  }

  useEffect(() => {
    if (tipos.length === 1 && !tipoDaTarefaAtual) {
      history.push(`/tarefas/${tipos[0].identificador}/minhas`)
    } else if (tipos.length > 1 && !tipoDaTarefaAtual) {
      history.push(`/tarefas/todas/minhas`)
    }
  }, [tipos, tipoDaTarefaAtual, history])
  
  const tiposComPermissao = usuario.temPermissaoVisualizarTarefasQualidade ? tipos : tipos.filter(f => f.nome !== 'Gestão de Qualidade do NPS')
  tiposComPermissao.sort(ordenarAlfabeticamente)

  return (
    <div className='box-panel__sidebar tarefas'>
      <div className='box-panel__sidebar__items'>
        {tiposComPermissao.length === 0 &&
          <div className='container-nenhum-profissional-encontrado'>
            <span>Nenhum tipo encontrado</span>
          </div>
        }
        {tiposComPermissao.length > 1 &&
          <div
            key='todos'
            className={`box-panel__sidebar__item --task ${semTipoSelecionado ? 'is-selected' : ''}`}
            onClick={() => selecionarTipo({ identificador: 'todas' })}
          >
            <p className='evolution-info__name'><Hash size={18} />Todas</p>
          </div>
        }
        {tiposComPermissao.length > 0 && tiposComPermissao.map(item => renderizarTipo(item))}
      </div>
    </div>
  )
}