import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {
  ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO
} from '../tipos'

const listouAgendamentosParaConfirmacaoDeAgendamento = (paginaDeDados, filtros, ordenacao) => ({
  type: LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  paginaDeDados,
  filtros,
  ordenacao
})

const erroAoListarAgendamentosParaConfirmacaoDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  erro,
  parametros
})

export const listarAgendamentosParaConfirmacaoDeAgendamento = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarAgendamentosParaConfirmacaoDeAgendamento(filtros, pagina, ordenacao)

    dispatch(listouAgendamentosParaConfirmacaoDeAgendamento(resultado.data.dados, filtros, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarAgendamentosParaConfirmacaoDeAgendamento(erro, { filtros, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os agendamentos para confirmação de agendamento.')))

    return false
  }
}