import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  ADICIONAR_ARQUIVO,
  ADICIONOU_ARQUIVO,
  ERRO_AO_ADICIONAR_ARQUIVO,
} from './tipos'

const adicionouArquivo = (arquivo, tipo) => ({
  type: ADICIONOU_ARQUIVO,
  arquivo,
  tipo,
})

const erroAoAdicionarArquivo = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ARQUIVO,
  erro,
  parametros,
})

export const adicionarArquivo = (arquivo, tipo) => async dispatch => {
  dispatch({ type: ADICIONAR_ARQUIVO })

  try {
    const resultado = await api.adicionarArquivo(arquivo, tipo)

    dispatch(adicionouArquivo(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados
  } catch (erro) {
    dispatch(erroAoAdicionarArquivo(erro, { arquivo, tipo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o arquivo.')))
  }
}