import React from 'react'
import Spinner from '../spinner'
import {Formulario as Form, Select} from '../formik/formulario'
import {confirmAlert} from 'react-confirm-alert'
import {usePossuiAsPermissoes} from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {
  LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
  LISTAR_ESPECIALIDADES,
  ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
  REMOVER_ESPECIALIDADE_MEDICA_INTERNA
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    listar,
    adicionar,
    remover,
    especialidadesMedicasInternas,
    especialidades,
  } = props

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA])
  const podeRemover = usePossuiAsPermissoes([permissoes.REMOVER_ESPECIALIDADE_MEDICA_INTERNA])

  const identificadoresDasEspecialidadesDisponíveis = especialidades.map(x => x.identificador).filter(z => especialidadesMedicasInternas.map(y => y.identificador).indexOf(z) === -1)
  const especialidadesDisponiveis = especialidades.filter(x => identificadoresDasEspecialidadesDisponíveis.includes(x.identificador))

  const adicionarEspecialidade = async (values, { resetForm }) => {
    const adicionou = await adicionar(values)

    if (adicionou) {
      resetForm({})
      listar()
    }
  }

  const removerSelecionada = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      listar()
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover a especialidade interna: ${item.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionada(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
      <Spinner operacoes={[
        LISTAR_ESPECIALIDADES,
        LISTAR_ESPECIALIDADES_MEDICAS_INTERNAS,
        ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA,
        REMOVER_ESPECIALIDADE_MEDICA_INTERNA
      ]}>
        <Form
            valoresIniciais={{
              especialidade: ''
            }}
            acao={adicionarEspecialidade}
            reinicializar={true}
        >
          {({ values }) => (
              <div className='mt-2 mb-4'>
                <fieldset>
                  <h2 className='form-title'>{podeAdicionar ? 'Adicionar Especialidade Médica Interna' : 'Especialidades Médicas Internas'}</h2>
                  <r-grid columns-md={6} columns-lg={12} class='align-end'>
                    {podeAdicionar &&
                        <>
                          <r-cell span={4} span-md={5} span-lg={6}>
                            <Select
                                nome='especialidade'
                                campoCodigo='identificador'
                                campoDescricao='nome'
                                itens={especialidadesDisponiveis}
                                tabIndex={1}
                                titulo='Especialidade *'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={1} span-lg={1}>
                            <button
                                type='submit'
                                className={!values.especialidade ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                                disabled={!values.especialidade}
                                tabIndex={2}
                            >
                              Adicionar
                            </button>
                          </r-cell>
                        </>
                    }
                    <r-cell span={4} span-md={6} span-lg={'1-7'}>
                      {especialidadesMedicasInternas.map((especialidade, index) => (
                          <div className='form-choice' key={index}>
                            <div className='form-choice__item --full'>{especialidade.nome}</div>
                            {podeRemover && <button className='form-choice__bt-close' type='button' aria-label='Fechar' onClick={() => confirmarERemover(especialidade)}><i className='icon icon-close'></i></button>}
                          </div>
                      ))}
                    </r-cell>
                  </r-grid>
                </fieldset>
              </div>
          )}
        </Form>
      </Spinner>
  )
}