import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ALTERAR_MOTIVO_DE_INTERNACAO,
  ADICIONAR_MOTIVO_DE_INTERNACAO,
  REMOVER_MOTIVO_DE_INTERNACAO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    motivo,
    salvar,
    recuperarPeloIdentificador,
    remover
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_MOTIVO_DE_INTERNACAO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_MOTIVO_DE_INTERNACAO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_MOTIVO_DE_INTERNACAO])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/motivos-de-internacao`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o motivo de internação: ${motivo.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(motivo)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[
          RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
          ALTERAR_MOTIVO_DE_INTERNACAO,
          ADICIONAR_MOTIVO_DE_INTERNACAO,
          REMOVER_MOTIVO_DE_INTERNACAO
        ]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: motivo.identificador || '',
              status: motivo.status === 'Não aprovado' ? false : true,
              nome: motivo.nome || '',
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
            })}
          >
            <fieldset>
              <h2 className='form-title'>{motivo.identificador ? 'Alterar Motivo de Internação' : 'Adicionar Motivo de Internação'}</h2>
              <r-grid columns={6} columns-md={6} columns-lg={12}>
                <r-cell span={6} span-md={12} span-lg={8}>
                  <Input
                    nome='nome'
                    tabIndex={1}
                    titulo='Nome *'
                  />
                </r-cell>
                <r-cell span={3} span-md={12} span-lg={12}>
                  <div className='form-toggle-list'>
                    <Toglle
                      nome='status'
                      tabIndex={2}
                      titulo='Aprovado'
                    />
                  </div>
                </r-cell>
              </r-grid>
            </fieldset>
            {remover ?
              <div className='list-btn-unidades'>
                {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
              </div>
              :
              <div className='list-btn'>
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
              </div>
            }
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}