import React, { useRef, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import versao from '../../configuracoes/versao'
import * as Yup from 'yup'
import logo from '../../design/img/logo-blue.svg'
import { Link } from 'react-router-dom'
import Spinner from '../spinner'
import Senha from '../senha'

import {
  LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  AUTENTICAR_USUARIO,
  LISTAR_UNIDADES_DO_USUARIO_LOGADO
} from '../../acoes/tipos'

export default function AutenticacaoPassoUm({ autenticar }) {
  const campoUsuario = useRef()

  useEffect(() => {
    if (campoUsuario.current) {
      campoUsuario.current.focus()
    }
  }, [campoUsuario])

  return (
    <div className='page-login'>
      <div className='description d-none d-lg-flex'>
        <header className='header'>
          <a href={window.location.href} className='logo'> </a>
        </header>
        <div className='box-description'>
          <h2>LifeCode: inovação a serviço do cuidado.</h2>
          <p>Versão: {versao.numero}</p>
        </div>
      </div>
      <div className='login-header d-lg-none'>
        <a href={window.location.href}>
          <img src={logo} alt='Lifecode' />
        </a>
      </div>
      <div className='login'>
        <div className='box-login'>
          <h1>Faça o login no LifeCode</h1>
          <p>Digite suas informações abaixo</p>
          <Spinner operacoes={[LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN, AUTENTICAR_USUARIO, LISTAR_UNIDADES_DO_USUARIO_LOGADO]}>
            {({ processando }) => (
              <Formik
                initialValues={{ email: '', senha: '' }}
                onSubmit={autenticar}
                validationSchema={Yup.object().shape({
                  email: Yup.string().required('Obrigatório').email('E-mail inválido'),
                  senha: Yup.string().required('Obrigatório')
                })}
              >
                {({ errors, touched }) => (
                  <Form className='form-login'>
                    <div className={`item-username ${(errors.email && touched.email) && 'has-error'}`}>
                      <label htmlFor='email'>Usuário</label>
                      <Field type='text' name='email' tabIndex={1} innerRef={campoUsuario} />
                      <ErrorMessage name='email' className='form-tip' component='span' />
                    </div>
                    <div className={`item-password ${(errors.senha && touched.senha) && 'has-error'}`}>
                      <label htmlFor='senha'>Senha</label>
                      <Link to='/recuperar-senha' className='senha-pergunta'>Esqueceu a senha?</Link>
                      <Field name='senha' tabIndex={2} component={Senha} />
                      <ErrorMessage name='senha' className='form-tip' component='span' />
                    </div>
                    <button type='submit' tabIndex={3} className={`button --primary ${processando && 'is-loading'}`}>
                      Login
                    </button>
                  </Form>
                )}
              </Formik>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}