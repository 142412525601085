import { LISTOU_UNIDADES_DE_CALCIO_IONICO } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_UNIDADES_DE_CALCIO_IONICO: {
      return action.unidadesDeCalcioIonico        
    }

    default: {
      return state
    }
  }
}