import {
  LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO
} from '../../../acoes/tipos'

const estadoInicial = {
  profissionaisDeSaude: [],
  profissoes: [],
  tiposDeAgendamento: [],
  filtros: {
    profissionaisSelecionados: [],
    profissaoSelecionada: {},
    especialidadeSelecionada: '',
    tipoDeAgendamentoSelecionado: '',
  }
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          profissionaisSelecionados: action.profissionaisSelecionados,
        }
      }
    }

    case SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          tipoDeAgendamentoSelecionado: action.identificador
        }
      }
    }

    case SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          especialidadeSelecionada: action.especialidade
        }
      }
    }

    case SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          profissaoSelecionada: action.profissaoSelecionada
        }
      }
    }

    case LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        profissionaisDeSaude: action.profissionaisDeSaude.map(x => ({...x, nome: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
      }
    }

    case LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        profissoes: action.profissoes
      }
    }

    case LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        tiposDeAgendamento: action.tiposDeAgendamento
      }
    }

    case LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO: {
      return {
        ...state,
        filtros: {
          profissionaisSelecionados: [],
          profissaoSelecionada: {},
          especialidadeSelecionada: '',
          tipoDeAgendamentoSelecionado: '',
        }
      }
    }

    default: {
      return state
    }
  }
}