import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  ADICIONAR_AGENDAMENTO,
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  ADICIONOU_AGENDAMENTO,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO,
  CANCELAR_AGENDAMENTO,
  CANCELOU_AGENDAMENTO,
  CONFIRMAR_AGENDAMENTO,
  CONFIRMOU_AGENDAMENTO,
  ERRO_AO_ADICIONAR_AGENDAMENTO,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  ERRO_AO_CANCELAR_AGENDAMENTO,
  ERRO_AO_CONFIRMAR_AGENDAMENTO,
  ERRO_AO_EXCLUIR_AGENDAMENTO,
  ERRO_AO_LISTAR_HORARIOS_LIVRES,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_SALVAR_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO,
  EXCLUIU_AGENDAMENTO,
  LIMPAR_AGENDAMENTO_ATUAL,
  LIMPAR_ALERTAS_DO_AGENDAMENTO,
  LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO,
  LIMPAR_HORARIOS_LIVRES,
  LISTAR_HORARIOS_LIVRES,
  LISTOU_HORARIOS_LIVRES,
  RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  SALVAR_AGENDAMENTO,
  SALVOU_AGENDAMENTO,
  SELECIONAR_AGENDAMENTO_PARA_ALTERACAO,
  SELECIONAR_PACIENTE_DO_AGENDAMENTO,
  RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO
} from "../tipos";

export const selecionarPacienteDoAgendamento = paciente => ({
  type: SELECIONAR_PACIENTE_DO_AGENDAMENTO,
  paciente,
})

export const adicionarPacienteSemPrograma = semPrograma => ({
  type: IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO, 
  semPrograma,
})

export const adicionarProximosAgendamentosDoAgendamento = proximos => ({
  type: ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  proximos,
})

export const limparAlertasDoAgendamento = () => ({
  type: LIMPAR_ALERTAS_DO_AGENDAMENTO  
})

export const limparDadosDoPacienteDoAgendamento = () => ({
  type: LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO  
})

const recuperouConveniosDoPacienteSelecionadoDoAgendamento = conveniosDoPacienteSelecionadoDoAgendamento => ({
  type: RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  conveniosDoPacienteSelecionadoDoAgendamento,
})

const erroAoRecuperarConveniosDoPacienteSelecionadoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const recuperarConveniosDoPacienteSelecionadoDoAgendamento = identificadorDoPaciente => async dispatch => {
  dispatch({ type: RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO })

  try {
    let resultado = await api.recuperarConveniosDoPacienteSelecionadoDoAgendamento(identificadorDoPaciente)

    let convenios = resultado.data.dados.dados.filter(x => x.status === 'Ativo')

    dispatch(recuperouConveniosDoPacienteSelecionadoDoAgendamento(convenios))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarConveniosDoPacienteSelecionadoDoAgendamento(erro, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao recuperar os convênios do paciente selecionado.')))

    return false
  }
}

const adicionouAgendamento = (identificadorDoProfissional, agendamento) => ({
  type: ADICIONOU_AGENDAMENTO,
  identificadorDoProfissional,
  agendamento,
})

const erroAoAdicionarAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_AGENDAMENTO,
  erro,
  parametros,
})

export const adicionarAgendamento = (identificadorDoProfissional, agendamento) => async dispatch => {
  dispatch({ type: ADICIONAR_AGENDAMENTO })

  try {
    let resultado = await api.adicionarAgendamento(identificadorDoProfissional, agendamento)

    dispatch(adicionouAgendamento(identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAgendamento(erro, { identificadorDoProfissional, agendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar o agendamento.')))

    return false
  }
}

const listouHorariosLivres = (horariosLivres, dataBaseHorariosLivres) => ({
  type: LISTOU_HORARIOS_LIVRES,
  horariosLivres,
  dataBaseHorariosLivres,
})

const erroAoListarHorariosLivres = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_HORARIOS_LIVRES,
  erro,
  parametros,
})

export const listarHorariosLivres = (identificadorDoProfissional, dataInicio, dataFim, tipoDeAgendamento, unidades) => async dispatch => {
  dispatch({ type: LISTAR_HORARIOS_LIVRES })
  try {
    const todosOsTurnos = 'MANHA,TARDE,NOITE,MADRUGADA'
    const todosOsDiasDaSemana = 'DOM,SEG,TER,QUA,QUI,SEX,SAB'

    let resultado = await api.listarHorariosLivres(identificadorDoProfissional, dataInicio, dataFim, tipoDeAgendamento, unidades, todosOsTurnos, todosOsDiasDaSemana)

    dispatch(listouHorariosLivres(resultado.data.dados, dataInicio))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarHorariosLivres(erro, { identificadorDoProfissional, dataInicio, dataFim, tipoDeAgendamento, unidades }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os horários livres.')))
  }
}

export const limparHorariosLivres = () => ({
  type: LIMPAR_HORARIOS_LIVRES
})

export const adicionarPreCadastroDoPaciente = preCadastro => async dispatch => {
  dispatch({ type: ADICIONAR_PRE_CADASTRO_DO_PACIENTE })
  try {
    let resultado = await api.adicionarPreCadastroDoPaciente(preCadastro)
    let retornoPreCadastro = resultado.data.dados

    dispatch(adicionouPreCadastroDoPaciente(retornoPreCadastro))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPreCadastroDoPaciente(erro, preCadastro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do pré cadastro do paciente.')))

    return false
  }
}

const adicionouPreCadastroDoPaciente = preCadastro => ({
  type: ADICIONOU_PRE_CADASTRO_DO_PACIENTE,
  preCadastro
})

const erroAoAdicionarPreCadastroDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE,
  erro,
  parametros,
})

const cancelouAgendamento = (identificadorDoAgendamento, identificadorDoProfissional, novoStatus) => ({
  type: CANCELOU_AGENDAMENTO,
  identificadorDoAgendamento,
  identificadorDoProfissional,
  novoStatus
})

const erroAoCancelarAgendamento = (erro, parametros) => ({
  type: ERRO_AO_CANCELAR_AGENDAMENTO,
  erro,
  parametros,
})

export const cancelarAgendamento = dadosDoCancelamento => async dispatch => {
  dispatch({ type: CANCELAR_AGENDAMENTO })
  try {
    let resultado = await api.cancelarAgendamento(dadosDoCancelamento)

    dispatch(cancelouAgendamento(dadosDoCancelamento.identificador, dadosDoCancelamento.identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoCancelarAgendamento(erro, dadosDoCancelamento))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao cancelar o agendamento.')))

    return false
  }
}

const confirmouAgendamento = (identificadorDoAgendamento, identificadorDoProfissional, novoStatus) => ({
  type: CONFIRMOU_AGENDAMENTO,
  identificadorDoAgendamento,
  identificadorDoProfissional,
  novoStatus,
})

const erroAoConfirmarAgendamento = (erro, parametros) => ({
  type: ERRO_AO_CONFIRMAR_AGENDAMENTO,
  erro,
  parametros,
})

export const confirmarAgendamento = dadosDaConfirmacao => async dispatch => {
  dispatch({ type: CONFIRMAR_AGENDAMENTO })
  try {
    const resultado = await api.confirmarAgendamento(dadosDaConfirmacao)

    dispatch(confirmouAgendamento(dadosDaConfirmacao.identificador, dadosDaConfirmacao.identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoConfirmarAgendamento(erro, dadosDaConfirmacao))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao confirmar o agendamento.')))

    return false
  }
}

const excluiuAgendamento = (identificadorDoAgendamento, identificadorDoProfissional) => ({
  type: EXCLUIU_AGENDAMENTO,
  identificadorDoAgendamento,
  identificadorDoProfissional,
})

const erroAoExcluirAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_AGENDAMENTO,
  erro,
  parametros,
})

export const excluirAgendamento = dadosParaExcluirAgendamento => async dispatch => {
  dispatch({ type: EXCLUIR_AGENDAMENTO })
  try {
    let resultado = await api.excluirAgendamento(dadosParaExcluirAgendamento)

    dispatch(adicionarNotificacoesDaApi(resultado))
    dispatch(excluiuAgendamento(dadosParaExcluirAgendamento.identificador, dadosParaExcluirAgendamento.identificadorDoProfissional))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirAgendamento(erro, dadosParaExcluirAgendamento))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir o agendamento.')))

    return false
  }
}

export const selecionarAgendamentoParaAlteracao = agendamento => async dispatch => {
  dispatch({ type: SELECIONAR_AGENDAMENTO_PARA_ALTERACAO, agendamento })

  await dispatch(recuperarConveniosDoPacienteSelecionadoDoAgendamento(agendamento.paciente.identificador))
}

const salvouAgendamento = (identificadorDoProfissional, agendamento) => ({
  type: SALVOU_AGENDAMENTO,
  identificadorDoProfissional,
  agendamento,
})

const erroAoSalvarAgendamento = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_AGENDAMENTO,
  erro,
  parametros,
})

export const salvarAgendamento = (identificadorDoProfissional, agendamento) => async dispatch => {
  dispatch({ type: SALVAR_AGENDAMENTO })
  try {
    let resultado = await api.salvarAgendamento(identificadorDoProfissional, agendamento)

    dispatch(salvouAgendamento(identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarAgendamento(erro, { identificadorDoProfissional, agendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o agendamento.')))

    return false
  }
}

const recuperouAgendamentoPeloIdentificador = (identificadorDoProfissional, agendamento) => ({
  type: RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR,
  identificadorDoProfissional,
  agendamento,
})

const erroAoRecuperarAgendamentoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR,
  erro,
  parametros,
})

export const recuperarAgendamentoPeloIdentificador = (identificadorDoProfissional, identificadorDoAgendamento) => async dispatch => {
  dispatch({ type: RECUPERAR_AGENDAMENTO_PELO_IDENTIFICADOR })
  try {
    const resultado = await api.recuperarAgendamentoPeloIdentificador(identificadorDoProfissional, identificadorDoAgendamento)

    dispatch(recuperouAgendamentoPeloIdentificador(identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarAgendamentoPeloIdentificador(erro, { identificadorDoProfissional, identificadorDoAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o agendamento.')))

    return false
  }
}
export const limparAgendamentoAtual = () => ({ type: LIMPAR_AGENDAMENTO_ATUAL })

export const alterarDadosSelecionadosDoAgendamento = dadosSelecionadosDoAgendamento => ({ type: ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO, dadosSelecionadosDoAgendamento })

const alterouDadosSelecionadosDoAgendamentoNaListaDeEspera = pacienteSelecionado => ({
  type: ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  pacienteSelecionado,
})

const erroAoAlterarDadosSelecionadosDoAgendamentoNaListaDeEspera = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  erro,
  parametros
})

export const alterarDadosSelecionadosDoAgendamentoNaListaDeEspera = dadosSelecionadosDoAgendamento => async dispatch => {
  dispatch({ type: ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA })

  try {
    const resultado = await api.recuperarPacientePorIdentificador(dadosSelecionadosDoAgendamento.paciente.identificador)

    const paciente = {
      ...resultado.data.dados,
      telefones: dadosSelecionadosDoAgendamento.paciente.telefones
    }

    dispatch(alterouDadosSelecionadosDoAgendamentoNaListaDeEspera(paciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoAlterarDadosSelecionadosDoAgendamentoNaListaDeEspera(erro, { dadosSelecionadosDoAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao selecionar paciente.')))

  }
}