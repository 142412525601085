import moment from 'moment'

export const ordemDoServidor = (a, b) => {
  if (a._ordem < b._ordem) {
    return -1
  }

  if (a._ordem > b._ordem) {
    return 1
  }

  return 0
}

export const dataDecrescente = (a, b) => {
  const data1 = moment(a)
  const data2 = moment(b)

  if (data1.isBefore(data2)) {
    return 1
  }

  if (data1.isAfter(data2)) {
    return -1
  }

  return 0
}

export const dataCrescente = (a, b) => {
  const data1 = moment(a.data, 'DD/MM/YYYY').toDate()
  const data2 = moment(b.data, 'DD/MM/YYYY').toDate()

  if (data1 > data2) {
    return 1
  }
  if (data1 < data2) {
    return -1
  }
  return 0
}

export const timestampCrescente = (a, b) => a.data - b.data

export const ordenarAlfabeticamente = (a, b) => {
  return a.nome.localeCompare(b.nome)
}
