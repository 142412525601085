import React from 'react'
import LegendaDaUnidade from '../legenda-da-unidade-no-evento'
import EventoDelimitadorDeHorario from './delimitador-de-horario'

export default function Vazio(props) {
  const {
    abrirNovoAgendamentoNoHorario,
    className,
    exibirCorDaUnidadeNoEvento,
    item,
    delimitadorDeHorario
  } = props

  return (
    <div
      className="box-agenda__cells__col"
      onClick={() => abrirNovoAgendamentoNoHorario(item)}
      style={{ cursor: `${item ? 'pointer' : ''}` }}
    >
      {!item && <div className={className} style={{ height: `calc(62px)` }} />}
      <EventoDelimitadorDeHorario
        itens={delimitadorDeHorario}
      />
      <LegendaDaUnidade
        exibir={exibirCorDaUnidadeNoEvento}
        item={item}
      />
    </div>
  )
}