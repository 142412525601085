import React from 'react'

export default function SessaoAssinaturaEmNuvemNaoEncontrada(props) {
  const { notificacao } = props

  return (
    <div>
      <span>{notificacao.texto} É necessário reautenticar a assinatura em nuvem.</span>      
    </div>
  )
}