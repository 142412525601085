import { connect } from 'react-redux'
import acoes from '../../../acoes/index'
import Habilitacoes from './index'

const mapStatesToProps = state => {
  return {
    profissoes: state.nps.profissoes,
    respostas: state.nps.respostas,
    pacientes: state.nps.pacientes,
    profissionais: state.nps.profissionais,
    tiposDeAtendimento: state.nps.tiposDeAtendimento
  }
}

export default connect(mapStatesToProps, acoes)(Habilitacoes)