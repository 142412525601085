import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Spinner from '../../../spinner'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela } from '../../../tabela-dnd'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import AdicionarDiagnostico from './adicionar'
import ConfirmarDiagnostico from './confirmar'
import RemoverDiagnostico from './remover'
import { LISTAR_DIAGNOSTICOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Diagnosticos({ diagnosticosDoPaciente, diagnosticos, identificadorDoPaciente }) {
  const {
    adicionarDiagnosticoDoPaciente,
    alterarStatusDoDiagnosticoDoPaciente,
    alterarPosicaoDosDiagnosticosDoPaciente,
    listarDiagnosticosDoPaciente
  } = useProntuario()

  const [exibirAdicionarDiagnostico, setExibirAdicionarDiagnostico] = useState(false)
  const [exibirConfirmarDiagnostico, setExibirConfirmarDiagnostico] = useState(false)
  const [exibirRemoverDiagnostico, setExibirRemoverDiagnostico] = useState(false)
  const [diagnosticoSelecionado, setDiagnosticoSelecionado] = useState(false)

  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_DIAGNOSTICO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_DIAGNOSTICO_DO_PACIENTE])

  useEffect(() => {
    if (diagnosticosDoPaciente === undefined) {
      listarDiagnosticosDoPaciente(identificadorDoPaciente)
    }
  }, [listarDiagnosticosDoPaciente, identificadorDoPaciente, diagnosticosDoPaciente])

  function abrirAdicionarDiagnostico() {
    setExibirAdicionarDiagnostico(true)
  }

  function fecharAdicionarDiagnostico() {
    setExibirAdicionarDiagnostico(false)
  }

  function abrirConfirmarDiagnostico(item) {
    setDiagnosticoSelecionado(item)
    setExibirConfirmarDiagnostico(true)
  }

  function fecharConfirmarDiagnostico() {
    setExibirConfirmarDiagnostico(false)
  }

  function abrirRemoverDiagnostico(item) {
    setDiagnosticoSelecionado(item)
    setExibirRemoverDiagnostico(true)
  }

  function fecharRemoverDiagnostico() {
    setExibirRemoverDiagnostico(false)
  }

  const alterarPosicaoDoDiagnostico = async (result) => {
    if (!result.destination) return

    const [diagnostico] = diagnosticosDoPaciente.splice(result.source.index, 1)
    const posicao = result.destination.index + 1
    diagnosticosDoPaciente.splice(result.destination.index, 0, diagnostico)

    if (diagnostico.posicao !== posicao) {
      var alterou = await alterarPosicaoDosDiagnosticosDoPaciente(identificadorDoPaciente, diagnostico.identificador, { posicao }, diagnosticosDoPaciente)

      if (!alterou) listarDiagnosticosDoPaciente(identificadorDoPaciente)
    }
  }

  const removerDiagnosticosAdicionados = (diagnosticosDoPaciente = [], diagnosticos = []) => {
    if (diagnosticosDoPaciente.length === 0) return diagnosticos

    const filtro = diagnostico => {
      return diagnosticosDoPaciente.findIndex(x => x.diagnostico === diagnostico.nome) === -1
    }

    return diagnosticos.filter(filtro)
  }

  const diagnosticosFiltrados = removerDiagnosticosAdicionados(diagnosticosDoPaciente, diagnosticos)

  return (
    <>
      <Modal
        isOpen={exibirAdicionarDiagnostico}
        className='modal'
        contentLabel='Modal para adição de diagnóstico'
      >
        <AdicionarDiagnostico
          adicionarDiagnosticoDoPaciente={adicionarDiagnosticoDoPaciente}
          fechar={fecharAdicionarDiagnostico}
          diagnosticos={diagnosticosFiltrados}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirConfirmarDiagnostico}
        className='modal'
        contentLabel='Modal para confirmação do diagnóstico'
      >
        <ConfirmarDiagnostico
          alterarStatusDoDiagnosticoDoPaciente={alterarStatusDoDiagnosticoDoPaciente}
          fechar={fecharConfirmarDiagnostico}
          diagnostico={diagnosticoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirRemoverDiagnostico}
        className='modal'
        contentLabel='Modal para remoção do diagnóstico'
      >
        <RemoverDiagnostico
          alterarStatusDoDiagnosticoDoPaciente={alterarStatusDoDiagnosticoDoPaciente}
          fechar={fecharRemoverDiagnostico}
          diagnostico={diagnosticoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={5}>
        <Spinner operacoes={[LISTAR_DIAGNOSTICOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE]}>
          <Tabela
            acoes={[
              item => (
                <Permissao key='remover' permissoes={[permissoes.REMOVER_DIAGNOSTICO_CONFIRMADO_DO_PACIENTE, permissoes.REMOVER_DIAGNOSTICO_DO_PACIENTE]}>
                  <button
                    className='table-items__bt --remove'
                    title='Remover'
                    onClick={() => abrirRemoverDiagnostico(item)}
                    type='button'
                    key={`remove_${item.identificador}`}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                </Permissao>
              ),
              item => {
                const permissaoParaConfirmar = podeConfirmar && item.status !== 'Confirmado'
                return (
                  permissaoParaConfirmar &&
                  <button
                    className='table-items__bt --edit'
                    title='Confirmar'
                    onClick={() => abrirConfirmarDiagnostico(item)}
                    type='button'
                    key={`confirma_${item.identificador}`}
                  >
                    <i className='icon icon-checked'></i>
                  </button>
                )
              },
              item => (
                <div key='informacao'>
                  <button
                    data-tip
                    data-for={`info_${item.diagnostico}`}
                    key={`info_${item.identificador}`}
                    className='table-items__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info'></i>
                  </button>
                  <ReactTooltip
                    id={`info_${item.diagnostico}`}
                    place='top'
                    effect='solid'
                    type='info'
                    key={item.identificador}
                    className='tamanho-tooltip-diagnostico'
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>{item.diagnostico}</strong></div>

                    <span>Status: {item.status}</span> <br />
                    <span>Usuário: {item.usuario}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    {item.observacoes && <span>Observações: {item.observacoes}</span>}
                  </ReactTooltip>
                </div>
              ),
            ]}
            chave='identificador'
            className='--secondary'
            dados={diagnosticosDoPaciente}
            exibirBotaoAdicionar={podeAdicionar}
            acaoDoBotaoAdicionar={abrirAdicionarDiagnostico}
            mensagemTabelaVazia='Não existem registros de diagnósticos.'
            classNameAdicionar='col-xs-6 col-lg-4'
            nome='diagnosticos'
            handleOnDragEndDrop={alterarPosicaoDoDiagnostico}
          >
            <Coluna
              className='col-xs-6 col-lg-8'
              principal={true}
              exibirTitulo={true}
              nome='Diagnósticos'
              renderizar={item => item.status !== 'Confirmado' ?
                <span style={{ color: '#e74c3c' }}>{item.diagnostico}</span> :
                item.diagnostico
              }
            />
          </Tabela>
        </Spinner>
      </r-cell>
    </>
  )
}