import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
} from './tipos'

const listouModalidadesDosTiposDeAgendamento = modalidadesDosTiposDeAgendamento => ({
  type: LISTOU_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  modalidadesDosTiposDeAgendamento
})

const erroAoListarModalidadesDosTiposDeAgendamento = erro => ({
  type: ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  erro
})

export const listarModalidadesDosTiposDeAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarModalidadesDosTiposDeAgendamento()

    dispatch(listouModalidadesDosTiposDeAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados.dados
  } catch (erro) {
    dispatch(erroAoListarModalidadesDosTiposDeAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as modalidades dos tipos de agendamento.')))
  
    return false
  }
}