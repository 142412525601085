import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_PEDIDOS_DE_CONTATO,
  LISTOU_PEDIDOS_DE_CONTATO,
  ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO,
  RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  ADICIONAR_PEDIDO_DE_CONTATO,
  ADICIONOU_PEDIDO_DE_CONTATO,
  ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO,
  ALTERAR_PEDIDO_DE_CONTATO,
  ALTEROU_PEDIDO_DE_CONTATO,
  ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO,
  REMOVER_PEDIDO_DE_CONTATO,
  REMOVEU_PEDIDO_DE_CONTATO,
  ERRO_AO_REMOVER_PEDIDO_DE_CONTATO
} from '../tipos'

const listouPedidosDeContato = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_PEDIDOS_DE_CONTATO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarPedidosDeContato = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO,
  erro,
  parametros
})

export const listarPedidosDeContato = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_PEDIDOS_DE_CONTATO })

  try {
    const resultado = await api.listarPedidosDeContato(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouPedidosDeContato(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPedidosDeContato(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os pedidos de contato.')))
  }
}

const adicionouPedidoDeContato = pedidoDeContato => ({
  type: ADICIONOU_PEDIDO_DE_CONTATO,
  pedidoDeContato
})

const erroAoAdicionarPedidoDeContato = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO,
  erro,
  parametros
})

export const adicionarPedidoDeContato = (identificadorDaEmpresa, identificadorDaUnidade, pedidoDeContato) => async dispatch => {
  dispatch({ type: ADICIONAR_PEDIDO_DE_CONTATO })

  try {
    const resultado = await api.adicionarPedidoDeContato(identificadorDaEmpresa, identificadorDaUnidade, pedidoDeContato)

    dispatch(adicionouPedidoDeContato(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPedidoDeContato(erro, { identificadorDaEmpresa, identificadorDaUnidade, pedidoDeContato }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o pedido de contato.')))

    return false
  }
}

const recuperouPedidoDeContatoPeloIdentificador = pedidoDeContato => ({
  type: RECUPEROU_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  pedidoDeContato
})

const erroAoRecuperarPedidoDeContatoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarPedidoDeContatoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_PEDIDO_DE_CONTATO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarPedidoDeContatoPeloIdentificador(identificador)

    dispatch(recuperouPedidoDeContatoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarPedidoDeContatoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o pedido de contato pelo identificador.')))
  }
}

const alterouPedidoDeContato = pedidoDeContato => ({
  type: ALTEROU_PEDIDO_DE_CONTATO,
  pedidoDeContato
})

const erroAoAlterarPedidoDeContato = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO,
  erro,
  parametros
})

export const alterarPedidoDeContato = pedidoDeContato => async dispatch => {
  dispatch({ type: ALTERAR_PEDIDO_DE_CONTATO })

  try {
    const resultado = await api.alterarPedidoDeContato(pedidoDeContato)

    dispatch(alterouPedidoDeContato(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPedidoDeContato(erro, { pedidoDeContato }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o pedido de contato.')))

    return false
  }
}

const removeuPedidoDeContato = pedidoDeContato => ({
  type: REMOVEU_PEDIDO_DE_CONTATO,
  pedidoDeContato
})

const erroAoRemoverPedidoDeContato = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PEDIDO_DE_CONTATO,
  erro,
  parametros
})

export const removerPedidoDeContato = identificador => async dispatch => {
  dispatch({ type: REMOVER_PEDIDO_DE_CONTATO })

  try {
    const resultado = await api.removerPedidoDeContato(identificador)

    dispatch(removeuPedidoDeContato(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPedidoDeContato(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o pedido de contato.')))

    return false
  }
}