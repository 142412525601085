import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTAR_INTERNACOES_DO_PACIENTE,
  LISTOU_INTERNACOES_DO_PACIENTE,
  ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE,
  ADICIONAR_INTERNACAO_DO_PACIENTE,
  ADICIONOU_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE,
  ALTERAR_INTERNACAO_DO_PACIENTE,
  ALTEROU_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE,
  REMOVER_INTERNACAO_DO_PACIENTE,
  REMOVEU_INTERNACAO_DO_PACIENTE,
  ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE
} from '../../tipos'

const listouInternacoesDoPaciente = (identificadorDoPaciente, internacoes) => ({
  type: LISTOU_INTERNACOES_DO_PACIENTE,
  identificadorDoPaciente,
  internacoes
})

const erroAoListarInternacoesDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE,
  erro,
  parametros
})

export const listarInternacoesDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_INTERNACOES_DO_PACIENTE })

  try {
    const resultado = await api.listarInternacoesDoPaciente(identificadorDoPaciente)

    dispatch(listouInternacoesDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarInternacoesDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as internações do paciente.')))
  }
}

const adicionouInternacaoDoPaciente = internacao => ({
  type: ADICIONOU_INTERNACAO_DO_PACIENTE,
  internacao
})

const erroAoAdicionarInternacaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarInternacaoDoPaciente = (identificadorDoPaciente, internacao) => async dispatch => {
  dispatch({ type: ADICIONAR_INTERNACAO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarInternacaoDoPaciente(identificadorDoPaciente, internacao)

    dispatch(adicionouInternacaoDoPaciente(resultado.data.dados))
    dispatch(listarInternacoesDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarInternacaoDoPaciente(erro, { internacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar internação do paciente.')))

    return false
  }
}

const alterouInternacaoDoPaciente = internacao => ({
  type: ALTEROU_INTERNACAO_DO_PACIENTE,
  internacao
})

const erroAoAlterarInternacaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarInternacaoDoPaciente = (identificadorDoPaciente, internacao) => async dispatch => {
  dispatch({ type: ALTERAR_INTERNACAO_DO_PACIENTE })

  try {
    const resultado = await api.alterarInternacaoDoPaciente(identificadorDoPaciente, internacao)

    dispatch(alterouInternacaoDoPaciente(resultado.data.dados))
    dispatch(listarInternacoesDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarInternacaoDoPaciente(erro, { internacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar internação do paciente.')))

    return false
  }
}

const removeuInternacaoDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_INTERNACAO_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverInternacaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE,
  erro,
  parametros
})

export const removerInternacaoDoPaciente = (identificadorDoPaciente, identificadorDaIdaAoPa) => async dispatch => {
  dispatch({ type: REMOVER_INTERNACAO_DO_PACIENTE })

  try {
    const resultado = await api.removerInternacaoDoPaciente(identificadorDoPaciente, identificadorDaIdaAoPa)

    dispatch(removeuInternacaoDoPaciente(identificadorDoPaciente))
    dispatch(listarInternacoesDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverInternacaoDoPaciente(erro, { identificadorDoPaciente, identificadorDaIdaAoPa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover internação do paciente.')))

    return false
  }
}