import { connect } from 'react-redux'
import acoes from '../../acoes'
import Triagem from './index'

const mapStateToProps = state => {  
  return {
    ...state.triagem
  }
}

export default connect(mapStateToProps, acoes)(Triagem)