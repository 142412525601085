import React from 'react'

export default function Prazo({ valor, atrasado }) {
  return (
    <div>
      <span style={{ right: '0px' }} className={`badge ${atrasado ? 'red' : 'green'}`}>
        {valor}
      </span>
    </div>
  )
}