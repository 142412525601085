import { RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI, LISTOU_RELATORIOS_DO_POWERBI } from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI: {
      const itens = action.dados.relatorios || []
      let relatorios = state.relatorios || {}

      itens.forEach(relatorio => {
        let item = relatorios[relatorio.codigo] || {}

        relatorios[relatorio.codigo] = {
          ...item,
          ...relatorio,
          validoAteh: new Date(action.dados.validoAteh),
          token: action.dados.token,
        }
      })

      return {
        relatorios,
      }
    }

    case LISTOU_RELATORIOS_DO_POWERBI: {
      const itens = action.relatorios || []
      let relatorios = state.relatorios || {}

      itens.forEach(relatorio => {
        let item = relatorios[relatorio.codigo] || {}

        relatorios[relatorio.codigo] = {
          ...item,
          ...relatorio,
        }
      })

      return {
        ...state,
        relatorios,
      }
    }

    default: {
      return state
    }
  }
}