import React, {useState} from 'react'
import {DatePicker, Formulario as FormularioDoFormik, Input, MultiplaEscolha} from '../../../../formik/formulario'
import {contemValor} from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const buscarPontuacao = (frequenciasNaEscalaZarit, valor) => frequenciasNaEscalaZarit.length > 0 && frequenciasNaEscalaZarit.find(x => x.nome === valor).pontuacao
const renderizarTitulo = valor => { return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Formulario({ frequenciasNaEscalaZarit, selecionada, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const [valoresAtualizados, setValoresAtualizados] = useState(valores)

  return (
    <Spinner operacoes={[
      ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
      ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
      LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT
    ]}>
      <FormularioDoFormik
        reinicializar={true}
        acao={salvar}
        valoresIniciais={valores}
      >
        {({ setFieldValue, values }) => {
          const setValue = (nome, valor) => {
            setValoresAtualizados({ ...valoresAtualizados, [nome]: valor })
            setFieldValue(nome, valor)
          }
          return (
            <fieldset>
              <h2 className='form-title'>{selecionada ? 'Alterar Avaliação da Sobrecarga do Cuidador' : 'Adicionar Avaliação da Sobrecarga do Cuidador'}</h2>
              <Informacoes />
              <br />
              <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
                <Cabecalho />
                <SemTempoSuficienteParaVoceMesmo frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.semTempoSuficienteParaVoceMesmo} onChange={setValue} />
                <EstressadoOuAngustiado frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.estressadoOuAngustiado} onChange={setValue} />
                <RelacaoComAmigosOuFamiliaresAfetada frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.relacaoComAmigosOuFamiliaresAfetada} onChange={setValue} />
                <Exausto frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.exausto} onChange={setValue} />
                <SaudeAfetada frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.saudeAfetada} onChange={setValue} />
                <TemPerdidoOControleDaVida frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.temPerdidoOControleDaVida} onChange={setValue} />
                <MuitoSobrecarregado frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={values.muitoSobrecarregado} onChange={setValue} />
                <Avaliacao valores={valoresAtualizados} frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} />
              </r-grid>
              <div className='list-btn'>
                <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
                <button type='submit' className='button --primary'>{selecionada ? 'Salvar Alterações' : 'Adicionar'}</button>
              </div>
            </fieldset>
        )
        }}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>AVALIAÇÃO DA SOBRECARGA DO CUIDADOR - ESCALA ZARIT</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function Informacoes() {
  const validar = valor => {
    if (!valor) {
      return obrigatorio(valor)
    }
  }

  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={8}>
        <Input
          nome='nomeDoCuidador'
          titulo='Nome do Cuidador *'
          tabIndex={1}
          validar={validar}
        />
      </r-cell>
      <r-cell span={4} span-md={2} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={2}
          maxDate={new Date()}
          validar={validar}
        />
      </r-cell>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Input
          tabIndex={3}
          as='textarea'
          nome='observacoes'
          titulo='Observações'
        />
      </r-cell>
    </r-grid>
  )
}

function SemTempoSuficienteParaVoceMesmo({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='semTempoSuficienteParaVoceMesmo'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Sente que, por causa do tempo que utiliza com o seu familiar/doente já não tem tempo suficiente para você mesmo?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function EstressadoOuAngustiado({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='estressadoOuAngustiado'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Sente-se estressado/angustiado por ter que cuidar do seu familiar/doente e ao mesmo tempo ser responsável por outras tarefas? (ex.: cuidar de outros familiares, ter que trabalhar).'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function RelacaoComAmigosOuFamiliaresAfetada({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='relacaoComAmigosOuFamiliaresAfetada'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Acha que a situação atual afeta a sua relação com amigos ou outros elementos da família de uma forma negativa?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Exausto({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='exausto'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='4. Sente-se exausto quando tem de estar junto do seu familiar/doente?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function SaudeAfetada({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='saudeAfetada'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='5. Sente que sua saúde tem sido afetada por ter que cuidar do seu familiar/doente?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function TemPerdidoOControleDaVida({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='temPerdidoOControleDaVida'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='6. Sente que tem perdido o controle da sua vida desde que a doença o seu familiar/doente se manifestou?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function MuitoSobrecarregado({ frequenciasNaEscalaZarit, valor, onChange }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='muitoSobrecarregado'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='7. No geral, sente-se muito sobrecarregado por ter que cuidar do seu familiar/doente?'
        onChange={onChange}
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Avaliacao({ valores, frequenciasNaEscalaZarit }) {
  const semTempoSuficienteParaVoceMesmo = valores.semTempoSuficienteParaVoceMesmo ? buscarPontuacao(frequenciasNaEscalaZarit, valores.semTempoSuficienteParaVoceMesmo) : 0
  const estressadoOuAngustiado = valores.estressadoOuAngustiado ? buscarPontuacao(frequenciasNaEscalaZarit, valores.estressadoOuAngustiado) : 0
  const relacaoComAmigosOuFamiliaresAfetada = valores.relacaoComAmigosOuFamiliaresAfetada ? buscarPontuacao(frequenciasNaEscalaZarit, valores.relacaoComAmigosOuFamiliaresAfetada) : 0
  const exausto = valores.exausto ? buscarPontuacao(frequenciasNaEscalaZarit, valores.exausto) : 0
  const saudeAfetada = valores.saudeAfetada ? buscarPontuacao(frequenciasNaEscalaZarit, valores.saudeAfetada) : 0
  const temPerdidoOControleDaVida = valores.temPerdidoOControleDaVida ? buscarPontuacao(frequenciasNaEscalaZarit, valores.temPerdidoOControleDaVida) : 0
  const muitoSobrecarregado = valores.muitoSobrecarregado ? buscarPontuacao(frequenciasNaEscalaZarit, valores.muitoSobrecarregado) : 0

  const total = semTempoSuficienteParaVoceMesmo + estressadoOuAngustiado + relacaoComAmigosOuFamiliaresAfetada + exausto + saudeAfetada + temPerdidoOControleDaVida + muitoSobrecarregado

  const avaliacaoZarit = () => {
    if (total === 0) return ''

    if (total <= 14) {
      return 'LEVE'
    } else if (total >= 15 && total <= 21) {
      return 'MODERADA'
    } else {
      return 'GRAVE'
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(total || total === 0) &&
       <div className='resultado-questionario'>
         <div>
           AVALIAÇÃO DA SOBRECARGA:{' '}<strong>{avaliacaoZarit()}</strong>
           <div className='legenda'>
             Leve (até 14 pontos); Moderada (15 a 21 pontos); Grave (acima de 22 pontos)
           </div>
         </div>
         <div><strong>{total}</strong></div>
       </div>
      }
    </r-cell>
  )
}