import React from 'react'
import { Input } from '../../../formik/formulario'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import useAtalhos from '../../atendimento/useAtalhos'

export default function ResultadoDeExame({ metadados, leitura, valor, statusDaSecao }) {
  const obrigatorio = metadados?.obrigatorio === 'true'
  const rotulo = metadados?.rotulo ? metadados.rotulo : 'Resultado de Exames'

  useAtalhos(document.getElementById('outrosExames'), statusDaSecao)

  if (leitura) {
    if (!valor) return null
    
    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>{rotulo}</strong>
            <br />
            {valor}
          </pre>
        </div>
      </r-cell>
    )
  }

  const validar = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <Input
        as='textarea'
        nome='resultadoDeExame'
        titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
        validar={validar}        
        id='outrosExames'
      />
    </r-cell>
  )
}