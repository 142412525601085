import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  LISTOU_ESTADOS_DA_MEMORIA_ATUAL,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
} from './tipos'

const listouEstadosDaMemoriaAtual = estadosDaMemoriaAtual => ({
  type: LISTOU_ESTADOS_DA_MEMORIA_ATUAL,
  estadosDaMemoriaAtual,
})

const erroAoListarEstadosDaMemoriaAtual = erro => ({
  type: ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  erro,
})

export const listarEstadosDaMemoriaAtual = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_ESTADOS_DA_MEMORIA_ATUAL })

    try {
      const resultado = await api.listarEstadosDaMemoriaAtual()

      dispatch(listouEstadosDaMemoriaAtual(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarEstadosDaMemoriaAtual(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estados da memória atual.')))
    }
  }

  acao.meta = {
    cache: LISTAR_ESTADOS_DA_MEMORIA_ATUAL
  }

  return acao
}