import { LISTOU_TIPOS_DE_ATENDIMENTO } from '../acoes/tipos'

export default (state = [], action) => {
    switch (action.type) {
        case LISTOU_TIPOS_DE_ATENDIMENTO: {
            return action.tiposDeAtendimento
        }

        default: {
            return state
        }
    }
}