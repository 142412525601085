import {
  ABRIR_TELECONSULTA_NO_PRONTUARIO,
  FECHAR_TELECONSULTA_NO_PRONTUARIO
} from '../tipos'

export const abrirTeleconsultaNoProntuario = identificadorDoAtendimento => ({
  type: ABRIR_TELECONSULTA_NO_PRONTUARIO,
  identificadorDoAtendimento
})

export const fecharTeleconsultaNoProntuario = () => ({
  type: FECHAR_TELECONSULTA_NO_PRONTUARIO
})