import React from 'react'
import { Link } from 'react-router-dom'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function AbasDaUnidade(props) {
  const { identificador, url } = props

  const DADOS_GERAIS = url && url.indexOf('salas') < 0 ? 'is-active' : ''
  const SALAS = url && url.indexOf('salas') > 0 ? 'is-active' : ''

  return (
    <div className='pills-nav'>
      <Permissao permissoes={[permissoes.ALTERAR_UNIDADE_DA_EMPRESA]}>
        <Link
          to={`/configuracoes/empresa/unidades/${identificador}`}
          className={`pills-nav__item ${DADOS_GERAIS}`}
          title='Dados Gerais'
        >
          Dados Gerais
        </Link>
      </Permissao>
      <Permissao permissoes={[permissoes.ALTERAR_UNIDADE_DA_EMPRESA]}>
        <Link
          to={`/configuracoes/empresa/unidades/${identificador}/salas`}
          className={`pills-nav__item ${SALAS}`}
          title='Salas'
        >
          Salas
        </Link>
      </Permissao>
    </div>
  )
}