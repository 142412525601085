import api from '../../api-major'

const recuperarCabecalhoDoProntuario = identificador => api.get(`/pacientes/${identificador}/prontuario/cabecalho`)
const listarHistoricoDeCamposDoProntuarioDoPaciente = (identificadorDoPaciente, componentes) => {
  const parametroComponentes = componentes && componentes.length > 0 ? componentes.join(',') : ''

  return api.get(`/pacientes/${identificadorDoPaciente}/prontuario/historico?componentes=${parametroComponentes}`)
}

export default {
  recuperarCabecalhoDoProntuario,
  listarHistoricoDeCamposDoProntuarioDoPaciente,
}
