import { LISTOU_FUSOS_HORARIOS } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_FUSOS_HORARIOS: {
      return action.fusosHorarios
    }

    default: {
      return state
    }
  }
}