import {
  LISTOU_MEDICAMENTOS,
  ADICIONOU_MEDICAMENTO,
  RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR,
  ALTEROU_MEDICAMENTO,
  REMOVEU_MEDICAMENTO,
  LISTOU_CONCENTRACOES_DO_MEDICAMENTO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    termo: '',
    ativo: ['Sim']
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_MEDICAMENTO_POR_IDENTIFICADOR:
    case ALTEROU_MEDICAMENTO:
    case ADICIONOU_MEDICAMENTO: {
      return {
        ...state,
        itemAtual: action.medicamento
      }
    }

    case REMOVEU_MEDICAMENTO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_MEDICAMENTOS: {
      const { filtros, ordenacao, paginaDeDados } = action
      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case LISTOU_CONCENTRACOES_DO_MEDICAMENTO: {
      const itemAtual = state.itemAtual ? { ...state.itemAtual } : null

      if (itemAtual) {
        itemAtual.concentracoes = action.concentracoes
      }

      return { ...state, itemAtual }
    }

    default: {
      return state
    }
  }
}