import moment from 'moment'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import {
  ADICIONOU_AGENDAMENTO,
  ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_DATA_DO_CALENDARIO,
  ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ALTERAR_VISUALIZACAO_DO_CALENDARIO,
  ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  CONFIRMOU_AGENDAMENTO,
  EXCLUIU_AGENDAMENTO,
  EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  LISTOU_UNIDADES_DO_USUARIO_LOGADO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL,
  RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR,
  SALVOU_AGENDAMENTO,
  SALVOU_USUARIO,
  SELECIONAR_PROFISSIONAL_DA_AGENDA,
  SELECIONAR_UNIDADES_DA_AGENDA,
} from '../../acoes/tipos'

const estadoInicial = {
  calendario: {
    data: null,
    visualizacao: 'semana',
  },
  filtros: {
    termo: null,
    ativo: ['Ativo'],
    unidadesFiltradas: [],
  },
  selecionado: null,
  profissionais: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case SELECIONAR_PROFISSIONAL_DA_AGENDA: {
      if (action.identificadorDoProfissional === state.selecionado) {
        return state
      }

      return {
        ...state,
        selecionado: action.identificadorDoProfissional,
      }
    }

    case LISTOU_PROFISSIONAIS_DE_SAUDE_DA_AGENDA: {
      let todos = {}

      action.profissionaisDeSaude.forEach(profissional => {
        todos[profissional.identificador] = {
          ...profissional,
          nome: profissional.tratamento ? profissional.tratamento + ' ' + profissional.nome : profissional.nome,
          _ordem: profissional.nome,
        }
      })

      return {
        ...state,
        profissionais: todos,
      }
    }

    case ALTERAR_TERMO_DE_PROFISSIONAIS_DE_SAUDE_DA_AGENDA: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          termo: action.termo,
          ativo: action.filtros
        }
      }
    }

    case ALTERAR_VISUALIZACAO_DO_CALENDARIO: {
      return {
        ...state,
        calendario: {
          ...state.calendario,
          visualizacao: action.visualizacao,
        }
      }
    }

    case ALTERAR_DATA_DO_CALENDARIO: {
      return {
        ...state,
        calendario: {
          ...state.calendario,
          data: action.data,
        }
      }
    }

    case SELECIONAR_UNIDADES_DA_AGENDA: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          unidadesFiltradas: action.unidades,
        }
      }
    }

    case RECUPEROU_AGENDA_SEMANAL_DO_PROFISSIONAL: {
      let itens = {}
      let { agenda } = action

      if (agenda) {
        agenda.forEach(item => {
          const chave = moment(item.data).format('YYYY-MM-DD')

          itens[chave] = {
            ...item,
            ehHoje: moment().isSame(moment(item.data), 'days'),
            ehFerias: item.bloqueios && item.bloqueios.length > 0 && item.bloqueios.some(b => b.motivo === 'ferias')
          }

          delete itens[chave].profissional
        })
      }

      let profissionais = state.profissionais
      profissionais[state.selecionado] = {
        ...profissionais[state.selecionado],
        dias: itens,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case ADICIONOU_AGENDAMENTO: {
      let { dias } = state.profissionais[action.identificadorDoProfissional]

      const agendamentos = action.agendamento.recorrente ?
        [action.agendamento, ...action.agendamento.agendamentos] :
        [action.agendamento]

      agendamentos.forEach(item => {
        const chaveDoDia = moment(item.inicio).format('YYYY-MM-DD')
        const dia = dias[chaveDoDia]

        if (dia) {
          dia.agendamentos.push(item)
        }
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case EXCLUIU_AGENDAMENTO: {
      let { dias } = state.profissionais[action.identificadorDoProfissional]
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.agendamentos = item.agendamentos.filter(x => x.identificador !== action.identificadorDoAgendamento)
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case CONFIRMOU_AGENDAMENTO: {
      let { dias } = state.profissionais[action.identificadorDoProfissional]
      let itens = converterObjetoParaArray(dias)
      let agendamento = {}

      itens.forEach(item => {
        agendamento = item.agendamentos.find(x => x.identificador === action.identificadorDoAgendamento)

        if (agendamento) {
          agendamento.status = [
            action.novoStatus,
            ...agendamento.status,
          ]
        }
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR:
    case SALVOU_AGENDAMENTO: {
      let { dias } = state.profissionais[action.identificadorDoProfissional]

      if (dias === undefined) {
        return state
      }

      let itens = converterObjetoParaArray(dias)
      const agendamentos = [action.agendamento]

      itens.forEach(item => {
        item.agendamentos = item.agendamentos.filter(x => x.identificador !== action.agendamento.identificador)
      })

      agendamentos.forEach(item => {
        const chaveDoDia = moment(item.inicio).format('YYYY-MM-DD')
        const dia = dias[chaveDoDia]
        if (dia) {
          dia.agendamentos.push(item)
        }
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case ALTEROU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      let dias = state.profissionais[action.bloqueio.profissional.identificador].dias
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.bloqueios = item.bloqueios.filter(x => x.identificador !== action.bloqueio.identificador)
      })

      const chaveDoDia = moment(action.bloqueio.data).format('YYYY-MM-DD')
      const dia = dias[chaveDoDia]
      if (dia) {
        dia.bloqueios.push(action.bloqueio)
      }

      let profissionais = state.profissionais
      profissionais[action.bloqueio.profissional.identificador] = {
        ...profissionais[action.bloqueio.profissional.identificador],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case ALTEROU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      if (!action.bloqueios || action.bloqueios.length === 0) {
        return state
      }

      const bloqueio = action.bloqueios[0]

      let dias = state.profissionais[bloqueio.profissional.identificador].dias
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.bloqueios = item.bloqueios.filter(x => !x.serie || x.serie.identificador !== bloqueio.serie.identificador)
      })

      let profissionais = state.profissionais

      action.bloqueios.forEach(x => {
        const chaveDoDia = moment(x.data).format('YYYY-MM-DD')
        const dia = dias[chaveDoDia]
        if (dia) {
          dia.bloqueios.push(x)
        }

        profissionais[x.profissional.identificador] = {
          ...profissionais[x.profissional.identificador],
          dias,
        }
      })

      return {
        ...state,
        profissionais,
      }
    }

    case ADICIONOU_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE: {
      if (!state.profissionais[action.identificadorDoProfissional]) {
        return state
      }

      let { dias } = state.profissionais[action.identificadorDoProfissional]

      action.bloqueios.forEach(item => {
        const chaveDoDia = moment(item.data).format('YYYY-MM-DD')
        const dia = dias[chaveDoDia]
        if (dia) {
          dia.bloqueios.push(item)
        }
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case ALTEROU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      let { dias } = state.profissionais[action.instrucao.profissional.identificador]
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.instrucoes = item.instrucoes.filter(x => x.identificador !== action.instrucao.identificador)
      })


      const chaveDoDia = moment(action.instrucao.data).format('YYYY-MM-DD')
      const dia = dias[chaveDoDia]
      if (dia) {
        dia.instrucoes.push(action.instrucao)
      }

      let profissionais = state.profissionais
      profissionais[action.instrucao.profissional.identificador] = {
        ...profissionais[action.instrucao.profissional.identificador],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case ALTEROU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      if (!action.instrucoes || action.instrucoes.length === 0) {
        return state
      }

      const instrucao = action.instrucoes[0]

      let dias = state.profissionais[instrucao.profissional.identificador].dias
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.instrucoes = item.instrucoes.filter(x => !x.serie || x.serie.identificador !== instrucao.serie.identificador)
      })

      let profissionais = state.profissionais

      action.instrucoes.forEach(x => {
        const chaveDoDia = moment(x.data).format('YYYY-MM-DD')
        const dia = dias[chaveDoDia]
        if (dia) {
          dia.instrucoes.push(x)
        }

        profissionais[x.profissional.identificador] = {
          ...profissionais[x.profissional.identificador],
          dias,
        }
      })

      return {
        ...state,
        profissionais,
      }
    }

    case EXCLUIU_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case EXCLUIU_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      let dias = state.profissionais[action.identificadorDoProfissional].dias
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.bloqueios = item.bloqueios.filter(x => x.identificador !== action.identificadorDoBloqueio)
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case EXCLUIU_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case EXCLUIU_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO: {
      let dias = state.profissionais[action.identificadorDoProfissional].dias
      let itens = converterObjetoParaArray(dias)

      itens.forEach(item => {
        item.instrucoes = item.instrucoes.filter(x => x.identificador !== action.identificador)
      })

      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias,
      }

      return {
        ...state,
        profissionais,
      }
    }

    case LISTOU_UNIDADES_DO_USUARIO_LOGADO: {
      return {
        ...state,
        filtros: {
          ...state.filtros,
          unidadesFiltradas: action.unidades.map(x => x.identificador),
        }
      }
    }

    case LIMPAR_DIAS_DO_PROFISSIONAL_DA_AGENDA: {
      let profissionais = state.profissionais
      profissionais[action.identificadorDoProfissional] = {
        ...profissionais[action.identificadorDoProfissional],
        dias: {},
      }

      return {
        ...state,
        profissionais,
      }
    }

    case RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO: {
      let profissionais = state.profissionais

      profissionais[state.selecionado] = {
        ...profissionais[state.selecionado],
        configuracoes: {
          ...action.configuracoesVigentes.agenda
        }
      }

      return {
        ...state,
        profissionais,
      }
    }

    case SALVOU_USUARIO: {
      return {
        ...state,
        selecionado: null,
        profissionais: {}
      }
    }

    default: {
      return state
    }
  }
}