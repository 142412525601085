import React from 'react'
import moment from 'moment'
import {ClipboardCheck} from '../../../design/icones'
import {
  formatarDataParaFormatoDiaDaSemana,
  formatarHoraParaFormatoLocal,
  formatarSegundosENanosegundosParaMoment
} from '../../../bibliotecas/data'

export default function Atendimento({ valor, rotulo }) {
  const icone = <ClipboardCheck />

  function diaDaSemana(dia) {
    const dataDoFirestoreFormatada = formatarSegundosENanosegundosParaMoment(dia)
    const diaDaSemanaFormatado = formatarDataParaFormatoDiaDaSemana(dataDoFirestoreFormatada)
    const dataFormatada = moment(dataDoFirestoreFormatada).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  if (valor.length === 0) {
    return (
        <div className='card-componente form-group'>
          <div className='icon'>{icone}</div>
          <div className='componente-select-acao'>
            <label className='titulo'>{rotulo}</label>
          </div>
          <div className='unity-infos agendamentos'>
            <div className='unity-infos__item agendamentos'>
              <p><i className='icon icon-attention-circled' style={{ position: 'inherit' }}></i>O paciente ainda não possui atendimentos.</p>
            </div>
          </div>
      </div>
    )
  }

  return (
    <div className='card-componente form-group'>
      <div className='icon'>{icone}</div>
      <div className='componente-select-acao'>
        <label className='titulo'>{rotulo}</label>
      </div>
      {valor && valor.map(x => (
          <div key={x.identificador} className='unity-infos agendamentos'>
            <div className='unity-infos__item agendamentos'>
              <p><i className='icon icon-calendar-empty' style={{ position: 'inherit' }}></i><strong>{diaDaSemana(x.inicio)} às {formatarHoraParaFormatoLocal(formatarSegundosENanosegundosParaMoment(x.inicio))}</strong></p>
              <p><strong>Unidade:</strong>{' '}{x.unidade}</p>
              <p><strong>Tipo do Atendimento:</strong>{' '}{x.tipo}</p>
              <p><strong>Profissional:</strong>{' '}{x.profissional}{', '}{x.profissao}</p>
            </div>
          </div>
        ))}
      </div>
  )
}