import { connect } from 'react-redux'
import acoes from '../../acoes'
import Templates from './index'

const mapStateToProps = state => {
  return {
    componentes: state.sugestoes.componentes,
    filtros: state.sugestoes.filtros,
    ordenacao: state.sugestoes.ordenacao,
    paginaDeDados: state.sugestoes.paginaDeDados,
    selecionado: state.sugestoes.selecionado,
    sugestoes: state.sugestoes.sugestoes,
    tokens: state.sugestoes.tokens,
  }
}

export default connect(mapStateToProps, acoes)(Templates)