import React from 'react'
import { Link } from 'react-router-dom'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import Spinner from '../spinner'
import * as Yup from 'yup'

import { RECUPERAR_SENHA } from '../../acoes/tipos'

export default function RecuperarSenha({ recuperarSenha, history }) {

  const recuperar = async formulario => {
    const recuperou = await recuperarSenha(formulario.email)
    if (recuperou) {
      history.push('/login')
    }
  }

  return (
    <Spinner operacoes={[RECUPERAR_SENHA]}>
      <FormularioDoFormik
        valoresIniciais={{ email: '' }}
        acao={recuperar}
        esquemaDeValidacoes={Yup.object().shape({
          email: Yup.string()
            .required('Obrigatório')
            .email('E-mail inválido')
        })}
      >
        <div className='page-recuperar-senha'>
          <div className='box-campos'>
            <a href={window.location.href} className='logo'> </a>
            <h1>Você esqueceu a sua senha?</h1>
            <p>Digite sua informação abaixo</p>
            <div className='form-login'>
              <div className='item-email'>
                <Input
                  titulo='Seu e-mail'
                  nome='email'
                />
                <div className='form-nav'>
                  <Link to='/login' className='login-back'>Voltar</Link>
                  <button type='submit' className='login-btn'>Recuperar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormularioDoFormik>
    </Spinner>
  )
}