import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Alergia, RiscoDeQueda, HeartPulse, ClockOne, ClockTwo, Luto, ClockThree, StethoscopeOff } from '../../design/icones'
import { listarIndicadoresDoPaciente } from '../../acoes/pacientes/indicadores'
import { formatarDataParaFormatoLocal } from '../../bibliotecas/data'
import './style.css'

export default function IndicadoresDoPaciente({ identificadorPublicoDoPaciente, aniversario = false, obito = false, modal = false }) {
  const dispatch = useDispatch()
  const indicadores = useSelector(state => state.indicadores)
  const indicadoresDoPaciente = indicadores[identificadorPublicoDoPaciente]

  useEffect(() => {
    if (identificadorPublicoDoPaciente && !indicadores[identificadorPublicoDoPaciente]) {
      dispatch(listarIndicadoresDoPaciente(identificadorPublicoDoPaciente))
    }
  }, [dispatch, identificadorPublicoDoPaciente, indicadores])

  const recuperaIndicador = (nome) => {
    if (nome === 'obito' && obito) return obito
    if (nome === 'aniversario' && aniversario) return nome
    if (!indicadoresDoPaciente) return false

    return indicadoresDoPaciente[nome] || false
  }

  const indicadoresTratados = [
    'aniversario',
    'alergia',
    'alta_densidade_de_cuidado',
    'atraso_no_plano_de_cuidados_da_unimed_bh',
    'atraso_no_plano_de_cuidados_da_unimed_nacional',
    'obito',
    'risco_de_queda',
  ]

  const algumIndicadorExibido = indicadoresTratados.some(indicador => recuperaIndicador(indicador))

  if (!algumIndicadorExibido) {
    return <div />
  }

  return (
    <div className='indicadores'>
      {indicadoresTratados.map((i) => {
        const indicador = recuperaIndicador(i)

        return (
          indicador && (
            <React.Fragment key={`indicador_do_paciente_${i}`}>
              <span data-tip data-for={i}>
                {icone[i](indicador)}
              </span>
              <ReactTooltip
                id={i}
                place={modal ? 'right' : 'bottom'}
                effect='solid'
                type='info'
                key={i}
                className='tooltip-indicador'
              >
                <pre>{tooltip[i](indicador)}</pre>
              </ReactTooltip>
            </React.Fragment>
          )
        )
      })}
    </div>
  )
}

const icone = {
  aniversario: () => <i className='icon icon-birthday indicador-do-paciente' />,
  alergia: () => <Alergia size='18' className='indicador-do-paciente' />,
  alta_densidade_de_cuidado: () => <HeartPulse size='18' className='indicador-do-paciente' />,
  atraso_no_plano_de_cuidados_da_unimed_bh: (nivel) => {
    const mensagens = {
      nivel_um: <ClockOne size='18' className='indicador-do-paciente' />,
      nivel_dois: <ClockTwo size='18' className='indicador-do-paciente' />,
      nivel_tres: <ClockThree size='18' className='indicador-do-paciente' />,
    }

    return mensagens[nivel] || '';
  },
  atraso_no_plano_de_cuidados_da_unimed_nacional: (nivel) => {
    const mensagens = {
      nivel_um: <ClockOne size='18' className='indicador-do-paciente' />,
      nivel_dois: <ClockTwo size='18' className='indicador-do-paciente' />,
      nivel_tres: <ClockThree size='18' className='indicador-do-paciente' />,
      primeiro_atendimento_pendente: <StethoscopeOff size='18' className='indicador-do-paciente' />,
    }
    return mensagens[nivel] || '';
  },
  obito: () => <Luto size='20' className='indicador-do-paciente' />,
  risco_de_queda: () => <RiscoDeQueda size='18' className='indicador-do-paciente' />,
}

const tooltip = {
  aniversario: () => 'Aniversário do paciente',
  alergia: (texto) => texto && texto.trim().replace('\n', ''),
  alta_densidade_de_cuidado: () => 'Paciente com alta densidade de cuidado',
  atraso_no_plano_de_cuidados_da_unimed_bh: (nivel) => {
    const mensagens = {
      nivel_um: 'Nível de atraso 1 no plano de cuidados da Unimed BH',
      nivel_dois: 'Nível de atraso 2 no plano de cuidados da Unimed BH',
      nivel_tres: 'Nível de atraso 3 no plano de cuidados da Unimed BH',      
    }

    return mensagens[nivel] || 'Desconhecido';
  },
  atraso_no_plano_de_cuidados_da_unimed_nacional: (nivel) => {
    const mensagens = {
      nivel_um: 'Nível de atraso 1 no plano de cuidados da Unimed Nacional',
      nivel_dois: 'Nível de atraso 2 no plano de cuidados da Unimed Nacional',
      nivel_tres: 'Nível de atraso 3 no plano de cuidados da Unimed Nacional',
      primeiro_atendimento_pendente: 'Primeiro atendimento pendente',
    }

    return mensagens[nivel] || 'Desconhecido';
  },
  obito: (data) => `Óbito do paciente em ${formatarDataParaFormatoLocal(data)}`,
  risco_de_queda: () => 'Paciente possui risco de queda',
}