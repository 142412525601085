import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import { useFirestore } from '../../configuracoes/firestore/provedor'
import ProvedorDeContextoDoTarefas from '../tarefas/contexto/com-redux'
import Formulario from '../tarefas/formulario'
import { registrarFirestoreDaTarefaAtual } from '../../acoes/tarefas/firestore'
import { limparTarefaAtual } from '../../acoes/tarefas'
import '../tarefas/tarefas.css'

export default function EditarTarefa({ identificadorDaTarefa, fechar, ...rest }) {
  const dispatch = useDispatch()
  const { itemAtual, tipos } = useSelector(state => state.tarefas)

  const { firestore } = useFirestore()
  const { usuario } = useUsuarioLogado()
  const empresa = usuario.token.empresa

  const recuperarTarefa = tarefa => {
    if (!tarefa || !tarefa.tipo) return null

    const tipo = tipos && tipos[tarefa.tipo.identificador]
    const versao = tipo ? tipo.versoes.find(x => x.numero === tarefa.tipo.versao) : null

    return {
      ...tarefa,
      tipo: {
        ...tarefa.tipo,
        status: versao ? versao.status : null,
      }
    }
  }

  const tarefa = recuperarTarefa(itemAtual)

  useEffect(() => {
    const desregistrar = dispatch(registrarFirestoreDaTarefaAtual(firestore, empresa, identificadorDaTarefa))

    return () => {
      desregistrar()
      dispatch(limparTarefaAtual())
    }
  }, [dispatch, empresa, firestore, identificadorDaTarefa])


  return (
    <ProvedorDeContextoDoTarefas>
      <div className={`box-agenda container`}>
        <div className='form mt-2'>
          {tarefa &&
            <Formulario
              {...rest}
              tarefa={tarefa}
              origemProntuario={true}
              fechar={fechar}
            />
          }
        </div>
      </div>
    </ProvedorDeContextoDoTarefas>
  )
}