import {
  ADICIONOU_TAG_NO_PACIENTE,
  RECUPEROU_PACIENTE_POR_IDENTIFICADOR,
  REMOVEU_TAG_DO_PACIENTE
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case RECUPEROU_PACIENTE_POR_IDENTIFICADOR: {
      if (!action.paciente) return { state }

      return action.paciente.tags
    }

    case ADICIONOU_TAG_NO_PACIENTE: {
      return [
        ...state,
        action.tag,
      ]
    }

    case REMOVEU_TAG_DO_PACIENTE: {
      return [
        ...state.filter(x => x.tag !== action.tag),
      ]
    }

    default: {
      return state
    }
  }
}