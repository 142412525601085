import React from 'react'
import { useSelector } from 'react-redux'

function Funcionalidade({ children, empresas = null }) {
  const { empresa } = useSelector(state => state.login.logado)
  const empresaAutorizada = !empresas || empresas.includes(empresa)

  if (!empresaAutorizada) {
    return null
  }

  const titulo = React.Children.toArray(children).find((child) => child.type === Titulo)
  const subtituloParagrafoTopico = React.Children.toArray(children).filter((child) => child.type === Subtitulo || child.type === Paragrafo || child.type === Topico)
  const imagemVideo = React.Children.toArray(children).find((child) => child.type === Imagem || child.type === Video)

  return (
    <>
      {titulo}
      <div className={imagemVideo ? 'video-imagem' : ''}>
        <div className='divider'>
          <div className='page-novidades-rodape'>
            {subtituloParagrafoTopico}
          </div>
        </div>
        <div className='page-novidades-rodape'>
          {imagemVideo}
        </div>
      </div>
    </>
  )
}

function Titulo(props) {
  return <h2>{props.children}</h2>
}

function Subtitulo(props) {
  return <h3>{props.children}</h3>
}

function Paragrafo(props) {
  if (!props.children) return <br />
  return <p>{props.children}</p>
}

function Topico(props) {
  return <ul><li>{props.children}</li></ul>
}

function Imagem(props) {
  return <a href={props.link} target='_blank' rel='noopener noreferrer'><img style={{ width: 'auto', height: 'auto' }} alt='Imagem' src={props.link} /></a>
}

function Video(props) {
  const videoId = props.link.split('v=')[1]
  return (
    <iframe
      className='novidades-youtube'
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  )

}

export { Funcionalidade, Titulo, Subtitulo, Paragrafo, Topico, Imagem, Video }