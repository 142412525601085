import { connect } from 'react-redux'
import moment from 'moment'
import { ordemDoServidor } from '../../../bibliotecas/ordenacao'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import acoes from '../../../acoes'
import { diferencaEmMinutosEntreDatas } from '../../../bibliotecas/data'
import Agendamento from './index'

const mapStateToProps = state => {
  let valoresIniciais = {
    email: '',
    identificadorDoAgendamento: '',
    tipoDeAgendamento: '',
    paciente: {
      identificador: '',
      nome: '',
    },
    telefone1: '',
    proprietarioDoTelefone1: '',
    nomeDoTelefone1: '',
    grauDeRelacaoDoTelefone1: '',
    telefone1EhWhatsapp: false,
    telefone2: '',
    proprietarioDoTelefone2: '',
    nomeDoTelefone2: '',
    grauDeRelacaoDoTelefone2: '',
    telefone2EhWhatsapp: false,
    convenio: '',
    data: '',
    duracaoDoAgendamento: '',
    horarioDoAgendamento: '',
    observacoes: '',
    unidadeDaEmpresa: '',
    recorrencia: '',
    tipoDeHorario: ''
  }

  const extrairConvenios = state => {
    let convenios = converterObjetoParaArray(state.agendamentos.agendamento.paciente.convenios)
      .map(x => ({ identificador: x.identificador, descricao: `${x.numeroDaCarteira} - ${x.operadora}`, operadora: x.operadora }))

    convenios.push({ identificador: 'particular', descricao: 'Particular' })

    return convenios
  }

  const extrairPacienteSelecionado = state => {
    if (!state.agendamentos.agendamento.paciente || !state.agendamentos.agendamento.paciente.identificador) return null
    return state.agendamentos.agendamento.pacientes[state.agendamentos.agendamento.paciente.identificador]
  }

  const extrairTelefones = (telefones, identificador) => {
    let telefonesDoPaciente = telefones.map(x => ({
      ...x,
      _ordem: x.confirmacaoDeConsulta ? 1 : x.proprietario === 'Paciente' ? 2 : x.proprietario === 'Responsável' ? 3 : x.proprietario === 'Cuidador' ? 4 : 5
    }))
    telefonesDoPaciente.sort(ordemDoServidor)

    if (telefonesDoPaciente.length === 0) {
      return
    }

    if (identificador === 1) {
      return telefonesDoPaciente[0]
    } else if (identificador === 2 && telefonesDoPaciente.length > 1) {
      return telefonesDoPaciente[1]
    }

  }

  const agendamento = state.agendamentos.agendamento.selecionado
  const pacienteSelecionado = extrairPacienteSelecionado(state)
  const diaSelecionado = state.agendamentos.agendamento.data
  const pacientes = converterObjetoParaArray(state.agendamentos.agendamento.pacientes).sort(ordemDoServidor)

  if (diaSelecionado) {
    valoresIniciais.data = state.agendamentos.agendamento.data
    valoresIniciais.duracaoDoAgendamento = state.agendamentos.agendamento.duracaoDoAgendamento
    valoresIniciais.horarioDoAgendamento = moment(state.agendamentos.agendamento.data, 'YYYY-MM-DD HH:mm').format('HH:mm')
    valoresIniciais.unidadeDaEmpresa = state.agendamentos.agendamento.unidade
    valoresIniciais.tipoDeHorario = state.agendamentos.agendamento.tipoDeHorario
  }

  if (agendamento) {
    const { dataInicio, dataFim } = agendamento
    const minutos = diferencaEmMinutosEntreDatas(dataInicio, dataFim)

    valoresIniciais.email = agendamento.email
    valoresIniciais.identificadorDoAgendamento = agendamento.identificador
    valoresIniciais.paciente = {
      identificador: agendamento.paciente.identificador,
      nome: agendamento.paciente.nome,
    }
    valoresIniciais.tipoDeAgendamento = agendamento.tipo.identificador
    valoresIniciais.telefone1 = agendamento.telefone1
    valoresIniciais.proprietarioDoTelefone1 = agendamento.proprietarioDoTelefone1
    valoresIniciais.nomeDoTelefone1 = agendamento.nomeDoTelefone1
    valoresIniciais.grauDeRelacaoDoTelefone1 = agendamento.grauDeRelacaoDoTelefone1
    valoresIniciais.telefone1EhWhatsapp = agendamento.telefone1EhWhatsapp
    valoresIniciais.telefone2 = agendamento.telefone2
    valoresIniciais.proprietarioDoTelefone2 = agendamento.proprietarioDoTelefone2
    valoresIniciais.nomeDoTelefone2 = agendamento.nomeDoTelefone2
    valoresIniciais.grauDeRelacaoDoTelefone2 = agendamento.grauDeRelacaoDoTelefone2
    valoresIniciais.telefone2EhWhatsapp = agendamento.telefone2EhWhatsapp
    valoresIniciais.data = dataInicio
    valoresIniciais.horarioDoAgendamento = moment(dataInicio, 'YYYY-MM-DD HH:mm').format('HH:mm')
    valoresIniciais.duracaoDoAgendamento = minutos
    valoresIniciais.recorrencia = agendamento.recorrencia
    valoresIniciais.tipoDeHorario = agendamento.tipoDeHorario

    if (agendamento.particular) {
      valoresIniciais.convenio = 'particular'
    } else {
      valoresIniciais.convenio = agendamento.convenio ? agendamento.convenio.identificador : ''
    }

    valoresIniciais.observacoes = agendamento.observacoes
    valoresIniciais.unidadeDaEmpresa = agendamento.unidade.identificador

    if (!pacienteSelecionado) {
      pacientes.push(agendamento.paciente)
    }

  }

  if (pacienteSelecionado) {
    valoresIniciais.paciente = {
      identificador: pacienteSelecionado.identificador,
      nome: pacienteSelecionado.nome,
    }

    var infoTelefone1 = extrairTelefones(pacienteSelecionado.telefones, 1)
    var infoTelefone2 = extrairTelefones(pacienteSelecionado.telefones, 2)

    if (infoTelefone1) {
      valoresIniciais.telefone1 = infoTelefone1.numero
      valoresIniciais.proprietarioDoTelefone1 = infoTelefone1.proprietario
      valoresIniciais.nomeDoTelefone1 = infoTelefone1.nome
      valoresIniciais.grauDeRelacaoDoTelefone1 = infoTelefone1.grauDeRelacao
      valoresIniciais.telefone1EhWhatsapp = infoTelefone1.whatsApp
    }

    if (infoTelefone2) {
      valoresIniciais.telefone2 = infoTelefone2.numero
      valoresIniciais.proprietarioDoTelefone2 = infoTelefone2.proprietario
      valoresIniciais.nomeDoTelefone2 = infoTelefone2.nome
      valoresIniciais.grauDeRelacaoDoTelefone2 = infoTelefone2.grauDeRelacao
      valoresIniciais.telefone2EhWhatsapp = infoTelefone2.whatsApp
    }

    valoresIniciais.unidadeDaEmpresa = valoresIniciais.unidadeDaEmpresa ? valoresIniciais.unidadeDaEmpresa : (pacienteSelecionado.unidadePreferencial ? pacienteSelecionado.unidadePreferencial.identificador : '')
    valoresIniciais.email = pacienteSelecionado.email
  }

  return {
    convenios: extrairConvenios(state),
    dataBaseHorariosLivres: state.agendamentos.agendamento.dataBaseHorariosLivres,
    grausDeRelacoes: state.grausDeRelacoes,
    horariosLivres: state.agendamentos.agendamento.horariosLivres,
    identificadorDoProfissional: state.agenda.selecionado,
    pacientes,
    recorrenciasDoAgendamento: converterObjetoParaArray(state.agendamentos.recorrencias),
    sexos: state.sexos,
    tiposDeAgendamento: state.agendamentos.agendamento.tiposDeAgendamento,
    proprietariosDeTelefones: state.proprietariosDeTelefones,
    proximosAgendamentos: state.agendamentos.agendamento.proximosAgendamentos,
    unidades: state.login.logado.unidades,
    valoresIniciais,
    pacienteEmEspera: state.agendamentos.agendamento.paciente.emEspera,
    pacienteSemPrograma: state.agendamentos.agendamento.semPrograma,
    tiposDeHorarios: state.tiposDeHorarios
  }
}

export default connect(mapStateToProps, acoes)(Agendamento)