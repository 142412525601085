import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import constantes from '../../configuracoes/constantes'
import Lista from './lista'

export default function UsuariosBloqueados(props) {
  const {
    filtros,
    fotos,
    match,
    ordenacao,
    paginaDeDados,
    motivosDeBloqueioDoLogin,
    recuperarFotoDoUsuarioPorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    listarMotivosDeBloqueioDoLogin,
    listarUsuariosBloqueados,
    desbloquearLoginDoUsuario
  } = props

  useEffect(() => {
    paginaDeDados.dados && paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
    })

    return () => paginaDeDados.dados && paginaDeDados.dados.filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })
  }, [paginaDeDados.dados, recuperarFotoDoUsuarioPorIdentificador, removerImagemDaMemoriaPorIdentificador])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_USUARIOS_BLOQUEADOS, permissoes.LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN]}>
              <Lista
                {...props}
                filtros={filtros}
                fotos={fotos}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                pesquisar={listarUsuariosBloqueados}
                motivosDeBloqueioDoLogin={motivosDeBloqueioDoLogin}
                listarMotivosDeBloqueioDoLogin={listarMotivosDeBloqueioDoLogin}
                desbloquearLoginDoUsuario={desbloquearLoginDoUsuario}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}