import {
  LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS: {
      return action.situacoesGastrointestinais
    }

    default: {
      return state
    }
  }
}
