import { connect } from 'react-redux'
import acoes from '../../acoes'
import EstabelecimentosDeSaude from './index'

const mapStateToProps = state => {
  const estabelecimentoAtual = state.estabelecimentosDeSaude.itemAtual

  let estabelecimento = {
    nome: '',
    cnes: '',
    status: true,
    redePropria: false
  }

  if (estabelecimentoAtual) {
    estabelecimento.identificador = estabelecimentoAtual.identificador
    estabelecimento.status = estabelecimentoAtual.status
    estabelecimento.nome = estabelecimentoAtual.nome
    estabelecimento.cnes = estabelecimentoAtual.cnes
    estabelecimento.redePropria = estabelecimentoAtual.redePropria
  }

  return {
    filtros: state.estabelecimentosDeSaude.filtros,
    ordenacao: state.estabelecimentosDeSaude.ordenacao,
    paginaDeDados: state.estabelecimentosDeSaude.paginaDeDados,
    estabelecimento,
  }
}

export default connect(mapStateToProps, acoes)(EstabelecimentosDeSaude)