import api from './api-major'

const listarProgramas = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/programas${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarPrograma = programa => api.post(`/programas/`, programa)
const recuperarProgramaPeloIdentificador = identificador => api.get(`/programas/${identificador}`)
const alterarPrograma = programa => api.put(`/programas/${programa.identificador}`, programa)
const removerPrograma = identificador => api.delete(`/programas/${identificador}`)

export default {
  listarProgramas,
  adicionarPrograma,
  recuperarProgramaPeloIdentificador,
  alterarPrograma,
  removerPrograma
}