import { connect } from 'react-redux'
import acoes from '../../acoes'
import UsuariosBloqueados from './index'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const mapStateToProps = state => {
  return {
    filtros: state.usuariosBloqueados.filtros,
    fotos: recuperarFotos(state.usuariosBloqueados.paginaDeDados.dados, state.imagens),
    ordenacao: state.usuariosBloqueados.ordenacao,
    paginaDeDados: state.usuariosBloqueados.paginaDeDados,
    motivosDeBloqueioDoLogin: state.motivosDeBloqueioDoLogin
  }
}

export default connect(mapStateToProps, acoes)(UsuariosBloqueados)