import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'

import {
  ADICIONAR_TRIAGEM,
  ADICIONOU_TRIAGEM,
  ERRO_AO_ADICIONAR_TRIAGEM,
  ALTERAR_TRIAGEM,
  ALTEROU_TRIAGEM,
  ERRO_AO_ALTERAR_TRIAGEM,
  RECUPERAR_TRIAGEM_PELO_IDENTIFICADOR,
  RECUPEROU_TRIAGEM_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TRIAGEM_PELO_IDENTIFICADOR,
  LISTOU_TRIAGENS_DO_PACIENTE,
  ERRO_AO_LISTAR_TRIAGENS_DO_PACIENTE,
  LISTAR_TRIAGENS_DO_PACIENTE, REMOVEU_TRIAGEM, ERRO_AO_REMOVER_TRIAGEM, REMOVER_TRIAGEM,
} from '../tipos'

export const adicionarTriagem = triagem => async dispatch => {
  dispatch({type: ADICIONAR_TRIAGEM})

  try {
    const resultado = await api.adicionarTriagem(triagem)
    dispatch(adicionouTriagem(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarTriagem(erro, {triagem}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados da triagem.')))

    return null
  }
}

const adicionouTriagem = triagem => ({
  type: ADICIONOU_TRIAGEM,
  triagem
})

const erroAoAdicionarTriagem = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TRIAGEM,
  erro,
  parametros,
})


export const alterarTriagem = triagem => async dispatch => {
  dispatch({type: ALTERAR_TRIAGEM})

  try {
    const resultado = await api.alterarTriagem(triagem)
    dispatch(alterouTriagem(resultado.data.dados))
    dispatch(listarTriagensDoPaciente(triagem.paciente))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAlterarTriagem(erro, {triagem}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados da triagem.')))

    return null
  }
}

const alterouTriagem = triagem => ({
  type: ALTEROU_TRIAGEM,
  triagem
})

const erroAoAlterarTriagem = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_TRIAGEM,
  erro,
  parametros,
})

const recuperouTriagemPeloIdentificador = diagnostico => ({
  type: RECUPEROU_TRIAGEM_PELO_IDENTIFICADOR,
  diagnostico
})

const erroAoRecuperarTriagemPeloIdentificador = erro => ({
  type: ERRO_AO_RECUPERAR_TRIAGEM_PELO_IDENTIFICADOR,
  erro
})

export const recuperarTriagemPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_TRIAGEM_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarTriagemPeloIdentificador(identificador)

    dispatch(recuperouTriagemPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarTriagemPeloIdentificador(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o triagem pelo identificador.')))
  }
}

const listouTriagensDoPaciente = (identificadorDoPaciente, triagens) => ({
  type: LISTOU_TRIAGENS_DO_PACIENTE,
  identificadorDoPaciente,
  triagens
})

const erroAoListarTriagensDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TRIAGENS_DO_PACIENTE,
  erro,
  parametros
})

export const listarTriagensDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_TRIAGENS_DO_PACIENTE })

  try {
    const resultado = await api.listarTriagensDoPaciente(identificadorDoPaciente)

    dispatch(listouTriagensDoPaciente(identificadorDoPaciente, resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTriagensDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as triagens do paciente.')))
  }
}

const removeuTriagem = (identificadorDoPaciente, identificadorDaTriagem) => ({
  type: REMOVEU_TRIAGEM,
  identificadorDoPaciente,
  identificadorDaTriagem,
})

const erroAoRemoverTriagem = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_TRIAGEM,
  erro,
  parametros
})

export const removerTriagem = (identificadorDoPaciente, identificadorDaTriagem) => async dispatch => {
  dispatch({ type: REMOVER_TRIAGEM })

  try {
    const resultado = await api.removerTriagem(identificadorDaTriagem)

    dispatch(removeuTriagem(identificadorDoPaciente, identificadorDaTriagem))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverTriagem(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover triagem.')))

    return false
  }
}