import { LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA: {
      return action.execucoesDoTesteDeFisioterapia
    }

    default: {
      return state
    }
  }
}