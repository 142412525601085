import {
  ADICIONAR_NOTIFICACAO,
  ADICIONAR_NOTIFICACOES,
  REMOVER_NOTIFICACAO,
  REMOVER_NOTIFICACOES
} from '../acoes/tipos'

const gerarChave = notificacao => `${notificacao.tipo}_${notificacao.texto}`

export default (state = {}, action) => {
  switch (action.type) {
    case ADICIONAR_NOTIFICACAO: {
      return {
        ...state,
        [gerarChave(action.notificacao)]: action.notificacao,
      }
    }

    case ADICIONAR_NOTIFICACOES: {
      let novoEstado = { ...state }

      action.notificacoes.forEach(notificacao => {
        novoEstado = {
          ...novoEstado,
          [gerarChave(notificacao)]: notificacao,
        }
      })

      return novoEstado
    }

    case REMOVER_NOTIFICACAO: {
      let novoEstado = { ...state }
      delete novoEstado[gerarChave(action.notificacao)]
      return novoEstado
    }

    case REMOVER_NOTIFICACOES: {
      if (!action.notificacoes || action.notificacoes.length === 0) {
        return state
      }

      let novoEstado = { ...state }

      action.notificacoes.forEach(notificacao => {
        delete novoEstado[notificacao.chave]
      })

      return novoEstado
    }

    default: {
      return state
    }
  }
}