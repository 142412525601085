import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../../spinner'
import { useUsuarioLogado } from '../../../seguranca/provedor-de-autenticacao'
import { useMemed } from '../../../../configuracoes/memed'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import {
  AUTENTICAR_MEDICO_NA_MEMED,
  INTEGRAR_NA_MEMED,
} from '../../../../acoes/tipos'
import './style.css'

export default function Memed(props) {
  const {
    autenticar,
    fechar,
    identificadorDoPaciente,
    identificadorDoAtendimento,
  } = props

  const { usuario } = useUsuarioLogado()
  const { initMemed } = useMemed()
  const { pacientes } = useSelector(state => state.prontuario)
  const { memed } = useSelector(state => state.prontuario)

  const [passoInicializarMemed, setPassoInicializarMemed] = useState(false)

  const paciente = pacientes[identificadorDoPaciente].cabecalho.identificacao

  const dadosDaMemed = useMemo(() => {
    return extrairDadosDaMemed(memed)
  }, [memed])

  function recuperaTelefoneCelularDoPaciente(telefones) {
    const telefonesParaMemed = telefones.map(t => t.numero.replace(/\D/g, ''))
    const celular = telefonesParaMemed.find(t => t.length > 10)
    if (celular) return celular
    return null
  }

  const pacienteMemed = {
    idExterno: paciente.identificadorPublico,
    cpf: paciente.cpf,
    nome: paciente.nome,
    endereco: paciente.endereco && (paciente.endereco.logradouro + ', ' + paciente.endereco.numero),
    cidade: paciente.endereco?.cidade,
    telefone: paciente.telefones && recuperaTelefoneCelularDoPaciente(paciente.telefones),
    nome_mae: paciente.nomeDaMae,
    data_nascimento: paciente.dataDeNascimento && formatarDataParaFormatoLocal(paciente.dataDeNascimento)
  }

  const tokens = {
    userToken: dadosDaMemed.userToken,
    identificadorDoUsuario: usuario.token.identificador,
    identificadorDaEmpresa: usuario.token.empresa,
    identificadorDaUnidade: usuario.token.unidade,
    identificadorDoAtendimento: identificadorDoAtendimento.identificadorPublico,
    identificadorDoPaciente: paciente.identificadorPublico,
  }

  useEffect(() => {
    (async () => {
      if (!dadosDaMemed.userToken && usuario.token.identificador) {
        await autenticar()
      }
    })()
  }, [autenticar, usuario.token.identificador, dadosDaMemed.userToken])

  useEffect(() => {
    if (!passoInicializarMemed && tokens.userToken && !dadosDaMemed.integrada) {
      setPassoInicializarMemed(true)
      initMemed(tokens, pacienteMemed, usuario, fechar)
    }

  }, [initMemed, tokens, passoInicializarMemed, pacienteMemed, usuario, dadosDaMemed.integrada, fechar])


  return (
    <div className='form'>
      <fieldset>
        <Spinner operacoes={[AUTENTICAR_MEDICO_NA_MEMED]}>
          {({ processando }) => {
            if (processando) {
              return <h2 className='verificando-permissoes'>Autenticando dados do Médico para integração com a Memed...</h2>
            }
            return <></>
          }}
        </Spinner>
        <Spinner operacoes={[INTEGRAR_NA_MEMED]}>
          {({ processando }) => {
            if (processando) {
              return <h2 className='verificando-permissoes'>Iniciando a integração com a Memed...</h2>
            }
            return <></>
          }}
        </Spinner>
        {dadosDaMemed.tentarNovamente &&
          <div className='list-btn m-0'>
            <button className='button --primary' type='button' onClick={() => autenticar()}>Nova tentativa de Integração com a Memed</button>
          </div>
        }
        {dadosDaMemed.integrada && <h2 className='verificando-permissoes'>Aguardando resposta da Memed...</h2>}
      </fieldset>
      <div className='list-btn m-0'>
        <div className='memed-interrupcao' onClick={fechar}>Interromper a integração e voltar para o Atendimento</div>
      </div>
    </div>
  )
}

const extrairDadosDaMemed = memed => ({
  tentarNovamente: memed.tentarNovamente,
  userToken: memed.userToken,
  integrada: memed.integrada,
  exibirModulo: memed.exibirModulo,
})