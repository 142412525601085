import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_ENFERMEIROS_DO_PACIENTE,
  LISTOU_ENFERMEIROS_DO_PACIENTE,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE,
} from '../tipos'

const listouEnfermeirosDoPaciente = enfermeiros => ({
  type: LISTOU_ENFERMEIROS_DO_PACIENTE,
  enfermeiros,
})

const erroAoListarEnfermeirosDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE,
  erro,
})

export const listarEnfermeirosDoPaciente = () => async dispatch => {
  dispatch({ type: LISTAR_ENFERMEIROS_DO_PACIENTE })

  try {
    let resultado = await api.listarEnfermeiros()

    dispatch(listouEnfermeirosDoPaciente(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEnfermeirosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os enfermeiros do paciente.')))
  }
}