import {
  ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA,
  LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO,
  RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA,
  SELECIONAR_PACIENTE_DO_AGENDAMENTO,
  SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
} from '../../acoes/tipos'

const estadoInicial = {
  lista: [],
  selecionado: null,
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE: {
      return {
        ...state,
        lista: action.listaDeEspera ? action.listaDeEspera.dados : []
      }
    }

    case ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA: {
      return {
        ...state,
        lista: [...state.lista, action.paciente]
      }
    }

    case REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA: {
      return {
        ...state,
        lista: state.lista.filter(p => p.identificador !== action.identificador)
      }
    }

    case SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE: {
      return {
        ...state,
        selecionado: action.paciente
      }
    }

    case SELECIONAR_PACIENTE_DO_AGENDAMENTO: {
      return {
        ...state,
        selecionado: null
      }
    }

    case LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO: {
      return {
        ...state,
        selecionado: null
      }
    }

    case ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA: {
      return {
        ...state,
        selecionado: null,
        lista: [...state.lista.filter(p => p.identificador !== action.paciente.identificador), action.paciente]
      }

    }

    default: {
      return state
    }
  }
}