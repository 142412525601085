import React from 'react'

export default function Resumo({ valor }) {
  if (!valor) return null

  const renderizarPrescricaoLeitura = (prescricao, index) => {
    return (
      <r-cell span={4} span-md={6} span-lg={12} key={prescricao.identificador}>
        <div className='form-group'>
          <pre className='description'>
            <strong>Prescrição MEMED {index + 1}</strong>
            <br />
            <div className='font-react-menstion'>{prescricao.texto}</div>
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <>
      {valor.map((prescricao, index) => renderizarPrescricaoLeitura(prescricao, index))}
    </>
  )
}