import React, {useCallback, useEffect} from 'react'
import StickyBox from 'react-sticky-box'
import {Switch} from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Breadcrumb from '../breadcrumb'
import Lista from './lista'
import {useUsuarioLogado} from '../seguranca/provedor-de-autenticacao'
import {useFirestore} from '../../configuracoes/firestore/provedor'
import './tarefas.css'
import ProvedorDeContextoDoTarefas from './contexto/com-redux'
import Formulario from './formulario'
import Fluxo from './fluxo/index.js'
import * as permissoes from '../seguranca/permissoes'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import {Aba, Abas, ListaDeAbas, Paineis, Painel} from '../abas'
import SeletorDeTipoDeTarefa from './seletor-de-tipo-de-tarefa'
import ListaDeTiposDeTarefa from './lista-de-tipos-de-tarefa'
import Dashboards from './dashboards'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'

export default function Tarefas(props) {
  const {
    abaAtual,
    alterarFiltrosDoTarefas,
    definirAbaAtual,
    criarTarefa,
    filtrosAtuais,
    history,
    itens,
    limparTarefaAtual,
    listarTiposDeTarefa,
    location,
    match,
    registrarFirestoreDeTarefas,
    registrarFirestoreDaTarefaAtual,
    tiposDeTarefa,
    tarefa,
    recuperarTokenDosRelatoriosDoPowerBI,
    removerImagemDaMemoriaPorIdentificador,
    profissionaisResponsaveis,
    powerBI,
    tipoDaTarefaAtual,
    todosOsFiltros,
    semTipoSelecionado,
  } = props

  const { firestore } = useFirestore()
  const { usuario } = useUsuarioLogado()
  const { identificadorDoTipoDeTarefa } = match.params
  const empresa = usuario.token.empresa
  const identificadorPublicoDoUsuario = usuario.token.identificador
  const profissaoDoUsuario = usuario.profissao?.nome
  const usuarioEhMedico = profissaoDoUsuario === 'MÉDICO(A)'
  const usuarioEhEnfermeiro = profissaoDoUsuario === 'ENFERMEIRO(A)'

  const exibirAbaMinhaCarteira = usuarioEhMedico || usuarioEhEnfermeiro
  const exibirAbaDashboard = usePossuiAsPermissoes([permissoes.EXIBIR_DASHBOARD_DAS_TAREFAS])
  const exibirAbaFluxo = usePossuiAsPermissoes([permissoes.EXIBIR_FLUXO_DAS_TAREFAS])
  const exibirTarefasNps = usuario.temPermissaoVisualizarTarefasQualidade

  const registrarTarefa = useCallback(identificador => registrarFirestoreDaTarefaAtual(firestore, empresa, identificador),
    [firestore, empresa, registrarFirestoreDaTarefaAtual])

  useEffect(() => {
    return registrarFirestoreDeTarefas(firestore, empresa, filtrosAtuais, tipoDaTarefaAtual)
  }, [registrarFirestoreDeTarefas, firestore, empresa, filtrosAtuais, tipoDaTarefaAtual])

  useEffect(() => { listarTiposDeTarefa() }, [listarTiposDeTarefa])

  const tipos = converterObjetoParaArray(tiposDeTarefa)
  const tiposParaExibir = !exibirTarefasNps && tipos.length > 0 ? tipos.filter(f => f.nome !== 'Gestão de Qualidade do NPS').map(t => t.identificador) : []

  const removerImagensDoTarefas = useCallback(() => profissionaisResponsaveis?.filter(x => x.foto).forEach(item => removerImagemDaMemoriaPorIdentificador(item.foto)),
    [profissionaisResponsaveis, removerImagemDaMemoriaPorIdentificador])

  const abaAtiva = () => {
    const abas = {
      '/minha-carteira': 1,
      '/criadas-por-mim': 2,
      '/todas': 3,
      '/dashboard': 4,
      '/fluxo': 5,
    }

    const url = location.pathname.substring(location.pathname.lastIndexOf('/'))

    return abas[url] || 0
  }

  useEffect(() => {
    const abasParaOReducer = {
      '/minhas': 'minhas',
      '/minha-carteira': 'minhaCarteira',
      '/criadas-por-mim': 'criadasPorMim',
      '/todas': 'todas',
      '/dashboard': 'dashboard',
      '/fluxo': 'fluxo',
    }

    const url = location.pathname.substring(location.pathname.lastIndexOf('/'))

    if ((!abaAtual || abaAtual !== abasParaOReducer[url]) && abasParaOReducer[url] && tipoDaTarefaAtual) {
      definirAbaAtual(abasParaOReducer[url], tipoDaTarefaAtual)
    }
  }, [definirAbaAtual, abaAtual, location, tipoDaTarefaAtual])

  useEffect(() => {
    const filtroCriador = `criador|${identificadorPublicoDoUsuario}`
    const filtroMedicoDeReferencia = `medicoDeReferencia|${identificadorPublicoDoUsuario}`
    const filtroEnfermeiroDeReferencia = `enfermeiroDeReferencia|${identificadorPublicoDoUsuario}`

    if (abaAtual === 'minhaCarteira' && filtrosAtuais) {
      if (usuarioEhMedico && !filtrosAtuais.filtros?.includes(filtroMedicoDeReferencia)) {
        alterarFiltrosDoTarefas({ ...filtrosAtuais, filtros: [filtroMedicoDeReferencia] }, tipoDaTarefaAtual)
      } else if (usuarioEhEnfermeiro && !filtrosAtuais.filtros?.includes(filtroEnfermeiroDeReferencia)) {
        alterarFiltrosDoTarefas({ ...filtrosAtuais, filtros: [filtroEnfermeiroDeReferencia] }, tipoDaTarefaAtual)
      }
    }

    if (abaAtual === 'minhas' && filtrosAtuais && filtrosAtuais.responsavel !== identificadorPublicoDoUsuario) {
      alterarFiltrosDoTarefas({ ...filtrosAtuais, responsavel: identificadorPublicoDoUsuario, filtros: [] }, tipoDaTarefaAtual)
    }

    if (abaAtual === 'criadasPorMim' && filtrosAtuais && !filtrosAtuais.filtros?.includes(filtroCriador)) {
      alterarFiltrosDoTarefas({ ...filtrosAtuais, filtros: [filtroCriador] }, tipoDaTarefaAtual)
    }

    if (tipoDaTarefaAtual === 'todas' && filtrosAtuais && filtrosAtuais?.tiposParaExibir && tiposParaExibir.length > 0 && JSON.stringify(filtrosAtuais.tiposParaExibir) !== JSON.stringify(tiposParaExibir)) {
      alterarFiltrosDoTarefas({ ...filtrosAtuais, tiposParaExibir }, tipoDaTarefaAtual)
    }

  }, [abaAtual, filtrosAtuais, identificadorPublicoDoUsuario, alterarFiltrosDoTarefas, tipoDaTarefaAtual, usuarioEhEnfermeiro, usuarioEhMedico, tiposParaExibir])

  const divAbas = document.getElementsByClassName('abas')
  let alturaAbas = 0

  if (divAbas.length > 0) {
    alturaAbas = divAbas.item(0).getBoundingClientRect().height
  }

  return (
    <ProvedorDeContextoDoTarefas>
      <div className='tarefas page-configuracoes-empresa-dados'>
        <div className='container'>
          <div className='main-content-form'>
            <div className='header-page-form'>
              <Breadcrumb />
            </div>
            <Switch>
              <RotaComAutenticacao
                exact
                path={[
                  `/tarefas/:identificadorDoTipoDeTarefa/minhas`,
                  `/tarefas/:identificadorDoTipoDeTarefa/minha-carteira`,
                  `/tarefas/:identificadorDoTipoDeTarefa/criadas-por-mim`,
                  `/tarefas/:identificadorDoTipoDeTarefa/todas`,
                  `/tarefas/:identificadorDoTipoDeTarefa/dashboard`,
                  `/tarefas/:identificadorDoTipoDeTarefa/fluxo`,
                ]}
                render={props => {
                  return (
                    <div className='box-panel --task'>
                      <ListaDeTiposDeTarefa
                        history={props.history}
                        filtros={todosOsFiltros}
                      />
                      <div className={`box-panel__content form`}>
                        <Abas abaInicial={abaAtiva()} ajusteRedirecionamento={true}>
                          <StickyBox className='abas' offsetTop={0}>
                            <div className='header-page-bar'>
                              <div className='header-page-nav'>
                                <ListaDeAbas className='tab'>
                                  <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/minhas`)}>
                                    Minhas
                                  </Aba>
                                  {exibirAbaMinhaCarteira &&
                                     <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/minha-carteira`)}>
                                      Minha Carteira
                                    </Aba>
                                  }
                                  <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/criadas-por-mim`)}>
                                    Criadas por Mim
                                  </Aba>
                                  <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/todas`)}>
                                    Todas
                                  </Aba>
                                  {!semTipoSelecionado && exibirAbaDashboard &&
                                     <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/dashboard`)}>
                                       Dashboard
                                     </Aba>
                                  }
                                  {!semTipoSelecionado && exibirAbaFluxo &&
                                     <Aba className='tab-item' aoClicar={() => history.push(`/tarefas/${identificadorDoTipoDeTarefa}/fluxo`)}>
                                       Fluxo
                                     </Aba>
                                  }
                                </ListaDeAbas>
                              </div>
                              <RotaComAutenticacao
                                exact
                                path={[
                                  `/tarefas/:identificadorDoTipoDeTarefa/minhas`,
                                  `/tarefas/:identificadorDoTipoDeTarefa/minha-carteira`,
                                  `/tarefas/:identificadorDoTipoDeTarefa/criadas-por-mim`,
                                  `/tarefas/:identificadorDoTipoDeTarefa/todas`,
                                ]}
                                render={() =>
                                  <SeletorDeTipoDeTarefa
                                    {...props}
                                    tiposDeTarefa={tiposDeTarefa}
                                    criarTarefa={criarTarefa}
                                    history={history}
                                    semTipoSelecionado={semTipoSelecionado}
                                  />
                                }
                              />
                            </div>
                          </StickyBox>
                          <Paineis>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/minhas`}
                                render={
                                  props => (
                                    <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
                                      <Lista
                                        {...props}
                                        alturaAbas={alturaAbas}
                                        filtros={filtrosAtuais}
                                        itens={itens}
                                        removerImagensDoTarefas={removerImagensDoTarefas}
                                      />
                                    </Permissao>
                                  )
                                }
                              />
                            </Painel>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/minha-carteira`}
                                render={
                                  props => (
                                    <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
                                      <Lista
                                        {...props}
                                        alturaAbas={alturaAbas}
                                        filtros={filtrosAtuais}
                                        itens={itens}
                                        removerImagensDoTarefas={removerImagensDoTarefas}
                                      />
                                    </Permissao>
                                  )
                                }
                              />
                            </Painel>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/criadas-por-mim`}
                                render={
                                  props => (
                                    <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
                                      <Lista
                                        {...props}
                                        alturaAbas={alturaAbas}
                                        filtros={filtrosAtuais}
                                        itens={itens}
                                        removerImagensDoTarefas={removerImagensDoTarefas}
                                      />
                                    </Permissao>
                                  )
                                }
                              />
                            </Painel>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/todas`}
                                render={
                                  props => (
                                    <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
                                      <Lista
                                        {...props}
                                        alturaAbas={alturaAbas}
                                        filtros={filtrosAtuais}
                                        itens={itens}
                                        removerImagensDoTarefas={removerImagensDoTarefas}
                                      />
                                    </Permissao>
                                  )
                                }
                              />
                            </Painel>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/dashboard`}
                                render={props => (
                                  <Dashboards
                                    {...props}
                                    relatorios={powerBI.relatorios}
                                    recuperarTokenDosRelatoriosDoPowerBI={recuperarTokenDosRelatoriosDoPowerBI}
                                  />
                                )}
                              />
                            </Painel>
                            <Painel>
                              <RotaComAutenticacao
                                exact
                                path={`/tarefas/:identificadorDoTipoDeTarefa/fluxo`}
                                render={props => (
                                  <Fluxo {...props} />
                                )}
                              />
                            </Painel>
                          </Paineis>
                        </Abas>
                      </div>
                    </div>
                  )
                }}
              />
              <RotaComAutenticacao
                exact
                path={`/tarefas/:identificadorDoTipoDeTarefa/tarefa/:identificador`}
                render={props => (
                  <Permissao permissoes={[permissoes.LISTAR_TAREFAS]}>
                    <Formulario
                      {...props}
                      abaAtual={abaAtual}
                      limparTarefaAtual={limparTarefaAtual}
                      tarefa={tarefa}
                      registrarTarefa={registrarTarefa}
                      removerImagensDoTarefas={removerImagensDoTarefas}
                    />
                  </Permissao>
                )}
              />
              <RotaComAutenticacao
                exact
                path={`${match.path}`}
                render={
                  props => (
                    <div className='box-panel --task'>
                      <ListaDeTiposDeTarefa
                        history={props.history}
                        filtros={todosOsFiltros}
                      />
                      <TelaInicial
                        {...props}
                        history={history}
                        tipoDaTarefaAtual={tipoDaTarefaAtual}
                        abaAtual={abaAtual}
                      />
                    </div>
                  )
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    </ProvedorDeContextoDoTarefas>
  )
}

function TelaInicial({ history, tipoDaTarefaAtual, abaAtual }) {
  useEffect(() => {
    if (tipoDaTarefaAtual) {
      const abasParaOReducer = {
        'minhas': 'minhas',
        'minhaCarteira': 'minha-carteira',
        'criadasPorMim': 'criadas-por-mim',
        'todas': 'todas',
        'dashboard': 'dashboard',
        'fluxo': 'fluxo',
      }

      const aba = abaAtual ? abasParaOReducer[abaAtual] : 'minhas'

      history.push(`/tarefas/${tipoDaTarefaAtual}/${aba}`)
    }
  }, [history, tipoDaTarefaAtual, abaAtual])

  return (<div className='box-task__header'><div className='mensagens-do-calendario'>Selecione um tipo de tarefa.</div></div>)
}