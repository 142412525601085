import React from 'react'

export default function NotificacaoQuandoOPacienteNaoPossuiProgramaAtivo({ pacienteSemPrograma = null }) {

  if (!pacienteSemPrograma) {
    return null
  }

  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={6} span-lg={12}>
        <div className='form-choice-alerta my-1'>
          <div className='form-choice-alerta__item'>
            <i className='icon icon-attention-circled'></i>
          </div>
          <div className='form-choice-alerta__item'>
            <h2>
              O paciente não possui nenhum programa ativo.
            </h2>
          </div>
        </div>
      </r-cell>
    </r-grid>
  )
}