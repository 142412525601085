import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../../../spinner'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { CampoNumerico, Formulario as FormularioDoFormik, Input, Select } from '../../../formik/formulario'
import AbasDaUnidade from '../abas'
import Cabecalho from '../cabecalho'
import * as Yup from 'yup'
import {
  ADICIONAR_SALA_NA_UNIDADE,
  ALTERAR_SALA_DA_UNIDADE,
  RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  REMOVER_SALA_DA_UNIDADE
} from "../../../../acoes/tipos";

export default function Formulario(props) {
  const {
    match,
    history,
    salvar,
    recuperarPeloIdentificador,
    remover,
    unidade,
    tiposDeSala,
    listarTiposDeSala,
  } = props

  const { identificador, identificadorDaSala } = match.params

  const sala = unidade.salas.selecionada || {}
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_SALA_DA_UNIDADE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_SALA_NA_UNIDADE])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_SALA_DA_UNIDADE])

  useEffect(() => { listarTiposDeSala() }, [listarTiposDeSala])

  useEffect(() => {
    if (identificador && identificadorDaSala) {
      recuperarPeloIdentificador(identificador, identificadorDaSala)
    }
  }, [identificador, identificadorDaSala, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(unidade.identificador, item.identificador)

    if (removeu) {
      history.push(`/configuracoes/empresa/unidades/${unidade.identificador}/salas`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a sala ${sala.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(sala)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='main-content-form'>
      <div className='form'>
        <Spinner operacoes={[RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR, ALTERAR_SALA_DA_UNIDADE, ADICIONAR_SALA_NA_UNIDADE, REMOVER_SALA_DA_UNIDADE]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: sala.identificador || '',
              nome: sala.nome || '',
              tipo: tiposDeSala && sala && sala.tipo ? tiposDeSala.find(x => x.nome === sala.tipo)?.codigo : '',
              capacidade: sala.capacidade || '',
              observacoes: sala.observacoes || ''
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              tipo: Yup.string().required('Obrigatório'),
              capacidade: Yup.number().nullable()
                .positive('A capacidade não pode ser 0.')
                .integer('A capacidade deve ser um número inteiro.')
                .max(999, 'A capacidade não pode ser maior que 999.')
                .required('Obrigatório'),
            })}
          >
            <Cabecalho unidade={unidade} />
            <div className='mt-2 mb-4'>
              <div>
                <fieldset>
                  <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <AbasDaUnidade identificador={unidade.identificador} url={match.url} />
                    </r-cell>
                  </r-grid>
                  <fieldset>
                    <h2 className='form-title'>{sala.identificador ? 'Alterar Sala' : 'Adicionar Sala'}</h2>
                    <r-grid columns-md={6} columns-lg={12}>
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <Input
                          type='text'
                          nome='nome'
                          tabIndex={1}
                          titulo='Nome *'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={6}>
                        <Select
                          nome='tipo'
                          tabIndex={2}
                          titulo='Tipo *'
                          itens={tiposDeSala}
                          campoCodigo='codigo'
                          campoDescricao='nome'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={3} span-lg={6}>
                        <CampoNumerico
                          type='text'
                          nome='capacidade'
                          tabIndex={3}
                          titulo='Capacidade *'
                        />
                      </r-cell>
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <Input
                          as='textarea'
                          nome='observacoes'
                          tabIndex={4}
                          titulo='Observações'
                        />
                      </r-cell>
                    </r-grid>
                  </fieldset>
                </fieldset>
                {remover ?
                  <div className='list-btn-unidades'>
                    {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                    {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  </div>
                  :
                  <div className='list-btn'>
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                  </div>
                }
              </div>
            </div>
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div >
  )
}