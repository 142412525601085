import React from 'react'
import ReactTooltip from 'react-tooltip'
import {Link} from "react-router-dom";
import {formatarDataParaFormatoDiaDaSemana, formatarHoraParaFormatoLocal} from '../../../bibliotecas/data'
import {Agenda, CalendarCheck} from '../../../design/icones'
import moment from 'moment'

export default function Agendamento({ valor, rotulo, paciente, linkProAgendamento }) {
  const icone = <CalendarCheck />

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDataParaFormatoDiaDaSemana(dia)
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  if (valor.length === 0) {
    return (
        <div className='card-componente form-group'>
          <div className='icon'>{icone}</div>
          <div className='componente-select-acao'>
            <label className='titulo'>{rotulo}</label>
          </div>
          <div className='unity-infos agendamentos'>
            <div className='unity-infos__item agendamentos'>
              <p><i className='icon icon-attention-circled' style={{ position: 'inherit' }}></i>O paciente ainda não possui agendamentos.</p>
            </div>
          </div>
      </div>
    )
  }

  const renderizarLink = agendamento => {
    const nomeDoPaciente = paciente.nome
    const identificadorDoProfissional = agendamento.profissional.identificador
    const inicio = agendamento.inicio
    const fim = agendamento.fim

    if (!nomeDoPaciente || !identificadorDoProfissional) {
      return
    }

    return (
      <Link
        to={`/agendamentos/confirmar-agendamento?paciente=${nomeDoPaciente}&profissional=${identificadorDoProfissional}&inicio=${inicio}&fim=${fim}`}
        title='Acessar agendamento'
        target='_blank'
      >
        <div><Agenda size={22}/></div>
      </Link>
    )
  }

  return (
    <div className='card-componente form-group'>
      <div className='icon'>{icone}</div>
      <div className='componente-select-acao'>
        <label className='titulo'>{rotulo}</label>
      </div>
      {valor && valor.map(x => (
        <div key={x.identificador} className='unity-infos agendamentos'>
          <div className='unity-infos__item agendamentos'>
            <div className='link-agendamento'>
              <span><i className='icon icon-calendar-empty' style={{ position: 'inherit' }}></i><strong>{diaDaSemana(x.inicio)}</strong></span>
              {linkProAgendamento && <button className='link button button-light-select-acao'>{renderizarLink(x)}</button>}
            </div>
            <p><i className='icon icon-clock' style={{ position: 'inherit' }}></i><strong>{formatarHoraParaFormatoLocal(x.inicio)} às {formatarHoraParaFormatoLocal(x.fim)}</strong></p>
            <p><strong>Unidade:</strong>{' '}{x.unidade}</p>
            <p><strong>Tipo do Agendamento:</strong>{' '}{x.tipo}</p>
            <p><strong>Profissional:</strong>{' '}{typeof x.profissional === 'string' ? x.profissional : x.profissional.nome}{', '}{x.profissao}</p>
            {x.observacoes &&
             <>
               <p><strong>Observações:</strong></p>
               <div
                 data-tip
                 data-for={`info_${x.identificador}`}
                 className='observacoes-agendamento'
               >
                 <pre>{x.observacoes}</pre>
               </div>
               <ReactTooltip
                 id={`info_${x.identificador}`}
                 place='bottom'
                 effect='solid'
                 type='info'
                 className='tooltip-atividades'
                 key={`info_${x.identificador}`}
               >
                 <pre>{x.observacoes}</pre>
               </ReactTooltip>
             </>
            }
          </div>
        </div>
      ))}
    </div>
  )
}