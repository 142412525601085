import React from 'react'
import Spinner from '../spinner'
import { Formulario as FormularioDoFormik, Input, Select } from '../formik/formulario'
import * as Yup from 'yup'
import { ALTERAR_STATUS_DO_ENCAMINHAMENTO } from '../../acoes/tipos'

export default function AlterarStatusDoEncaminhamento({
  fechar,
  encaminhamento,
  alterarStatusDoEncaminhamento,
  statusDeEncaminhamento,
  pesquisar,
  filtros,
  pagina,
  ordenacao,
  renderizarEncaminhadoPara,
  tipos
}) {

  const alterarStatus = async formulario => {
    const dados = {
      identificadorDoPaciente: encaminhamento.paciente.identificador,
      identificadorDoAtendimento: encaminhamento.atendimento,
      identificadorDoEncaminhamento: encaminhamento.encaminhamento,
      status: formulario.status,
      observacoes: formulario.observacoes
    }

    const alterou = await alterarStatusDoEncaminhamento(dados)

    if (alterou) {
      fechar()
      pesquisar(filtros, pagina, ordenacao)
    }
  }

  return (
    <Spinner operacoes={[ALTERAR_STATUS_DO_ENCAMINHAMENTO]}>
      <FormularioDoFormik
        valoresIniciais={{ status: '' }}
        esquemaDeValidacoes={Yup.object().shape({ status: Yup.string().required('Obrigatório') })}
        acao={alterarStatus}
      >
        <fieldset>
          <h2 className='form-title'>Alterar Status do Encaminhamento</h2>
          <div>
            <p className='informacoes-modal__name'>{encaminhamento.paciente.nome}</p>
            <div className='informacoes-modal__info'>
              <p><strong>Telefone(s):</strong> {encaminhamento.paciente.telefones.join(' / ')}</p>
              <p><strong>Encaminhado por:</strong>{' '}{encaminhamento.encaminhadoPor.nome}</p>
              <p><strong>Encaminhado para:</strong>{' '}{renderizarEncaminhadoPara(encaminhamento)}</p>
              <p><strong>Tipo:</strong>{' '}{tipos && tipos.find(x => x.codigo === encaminhamento.tipo).nome}</p>
              <p><strong>Prioridade:</strong>{' '}{encaminhamento.priorizar ? 'Sim' : 'Não'}</p>
              <p><strong>Status atual:</strong>{' '}{statusDeEncaminhamento.find(x => x.codigo === encaminhamento.status.codigo).nome}</p>
            </div>
          </div>
          <hr className='separator' />
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Select
                nome='status'
                titulo='Novo status'
                itens={statusDeEncaminhamento.filter(x => x.codigo !== encaminhamento.status.codigo)}
                campoCodigo='codigo'
                campoDescricao='nome'
                tabIndex={1}
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome='observacoes'
                titulo='Observações'
                as='textarea'
                tabIndex={2}
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' onClick={() => fechar()} className='button --light'>Fechar</button>
            <button type='submit' className='button --primary'>Alterar Status</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}