import {
  AUTENTICOU_MEDICO_NA_MEMED,
  CANCELOU_ATENDIMENTO,
  ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED,
  ERRO_AO_INTEGRAR_NA_MEMED,
  EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO,
  EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO,
  FINALIZOU_ATENDIMENTO,
  INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO,
  INICIOU_ATENDIMENTO,
  INTEGRAR_NA_MEMED,
  INTEGROU_NA_MEMED,
  LIMPAR_PRESCRICAO_MEMED,
  LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO,
  RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED,
} from '../../acoes/tipos'

const estadoInicial = {
  user: undefined,
  userToken: undefined,
  tentarNovamente: false,
  historico: [],
  integrada: false,
  prescricaoMemedIncluidaNoFormulario: null,
  prescricaoMemedExcluidaNoFormulario: null,
  prescricaoMemedHistorico: null,
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case AUTENTICOU_MEDICO_NA_MEMED: {
      return {
        ...state,
        user: action.dados,
        userToken: action.dados.token,
      }
    }

    case INTEGRAR_NA_MEMED: {
      return {
        ...state,
        tentarNovamente: false,
        integrada: false,
      }
    }

    case INTEGROU_NA_MEMED: {
      return {
        ...state,
        integrada: true,
      }
    }

    case RECUPEROU_HISTORICO_PRESCRICAO_NA_MEMED: {
      return {
        ...state,
        historico: action.dados
      }
    }


    case INCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO: {
      return {
        ...state,
        prescricaoMemedIncluidaNoFormulario: action.dados
      }
    }

    case EXIBIR_PRESCRICAO_MEMED_DO_HISTORICO: {
      return {
        ...state,
        prescricaoMemedHistorico: action.identificador
      }
    }

    case EXCLUIR_PRESCRICAO_MEMED_NO_FORMULARIO: {
      const excluirPrescricaoIncluida = state.prescricaoMemedIncluidaNoFormulario && state.prescricaoMemedIncluidaNoFormulario.identificadorExterno === action.identificadorExterno
      return {
        ...state,
        prescricaoMemedIncluidaNoFormulario: excluirPrescricaoIncluida ? null : state.prescricaoMemedIncluidaNoFormulario,
        prescricaoMemedExcluidaNoFormulario: action.identificador.toString(),
      }
    }

    case LIMPAR_PRESCRICAO_MEMED_NO_FORMULARIO: {
      return {
        ...state,
        prescricaoMemedIncluidaNoFormulario: null,
        prescricaoMemedExcluidaNoFormulario: null,
        prescricaoMemedHistorico: null,
      }
    }

    case ERRO_AO_INTEGRAR_NA_MEMED:
    case ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED: {
      return {
        ...estadoInicial,
        tentarNovamente: true,
      }
    }

    case INICIOU_ATENDIMENTO:
    case CANCELOU_ATENDIMENTO:
    case FINALIZOU_ATENDIMENTO:
    case LIMPAR_PRESCRICAO_MEMED: {
      return estadoInicial
    }

    default: {
      return state
    }
  }
}