import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUsuarioLogado } from './provedor-de-autenticacao'


export default function RotaComAutenticacao(props) {
  const { usuario } = useUsuarioLogado()

  if (!usuario) {
    return <Redirect to={'/login'} />
  }

  return <Route {...props} />
}