import agendamento from './agendamento'
import confirmarAgendamento from './confirmar-agendamento'
import informarChegadaDoPaciente from './informar-chegada-do-paciente'
import pesquisaDeAgendamentos from './pesquisa-de-agendamentos'
import recorrencias from './recorrencias'
import salaDeEspera from './sala-de-espera'
import statusDosAgendamentos from './status-dos-agendamentos'
import wizard from './wizard/'
import motivosDeCancelamento from './motivos-de-cancelamento'
import motivosParaNaoTerSidoAtendido from './motivos-para-nao-ter-sido-atendido'
import listaDeEspera from './lista-de-espera';

const estadoInicial = {
  agendamento: undefined,
  confirmarAgendamento: undefined,
  informarChegadaDoPaciente: undefined,
  pesquisaDeAgendamentos: undefined,
  motivosDeCancelamento: undefined,
  motivosParaNaoTerSidoAtendido: undefined,
  recorrencias: undefined,
  listaDeEspera: undefined,
  salaDeEspera: undefined,
  statusDosAgendamentos: undefined,
  wizard: {}
}

export default (state = estadoInicial, action) => {
  return {
    agendamento: agendamento(state.agendamento, action),
    confirmarAgendamento: confirmarAgendamento(state.confirmarAgendamento, action),
    informarChegadaDoPaciente: informarChegadaDoPaciente(state.informarChegadaDoPaciente, action),
    pesquisaDeAgendamentos: pesquisaDeAgendamentos(state.pesquisaDeAgendamentos, action),
    motivosDeCancelamento: motivosDeCancelamento(state.motivosDeCancelamento, action),
    motivosParaNaoTerSidoAtendido: motivosParaNaoTerSidoAtendido(state.motivosParaNaoTerSidoAtendido, action),
    recorrencias: recorrencias(state.recorrencias, action),
    listaDeEspera: listaDeEspera(state.listaDeEspera, action),
    salaDeEspera: salaDeEspera(state.salaDeEspera, action),
    statusDosAgendamentos: statusDosAgendamentos(state.statusDosAgendamentos, action),
    wizard: wizard(state.wizard, action)
  }
}