import React from 'react'
import { useProntuario } from '../../contexto'
import { confirmAlert } from 'react-confirm-alert'
import { FieldArray } from 'formik'
import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'

export default function Anexos({ identificadorDoAtendimento, identificadorDoPaciente, valor = [] }) {
  return (
    <FieldArray
      name='anexos'
      render={({ push, remove }) =>
        <Anexo
          adicionar={push}
          remover={remove}
          adicionados={valor}
          identificadorDoAtendimento={identificadorDoAtendimento}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      }
    />
  )
}

function Anexo({ adicionados, identificadorDoAtendimento, identificadorDoPaciente, ...rest }) {
  const {
    fazerDownloadDoAnexoDoAtendimento,
    adicionarArquivo
  } = useProntuario()

  const arquivosAdicionados = [...adicionados]

  const adicionarAnexo = async evento => {
    if (!evento.target.files[0]) return

    const arquivoAdicionado = await adicionarArquivo(evento.target.files[0], 'atendimento')
    const arquivo = {
      ...arquivoAdicionado,
      codigoDeRastreio: uuidv4()
    }

    rest.adicionar(arquivo)
  }

  const remover = identificador => {
    const index = adicionados.findIndex(x => x.identificador === identificador)
    rest.remover(index)
  }

  const confirmarERemover = arquivo => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o arquivo ${arquivo.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(arquivo.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoAnexoDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  const alertaDownload = () => {
    confirmAlert({
      title: 'Alerta',
      message: `Para fazer o download do anexo é necessário salvar a seção.`,
      buttons: [{
        label: 'OK'
      }]
    })
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <div className='form-summary'>
        <div className='form-summary-files'>
          {arquivosAdicionados.length > 0 && arquivosAdicionados.map((x, index) =>
            <div className='file-upload-item' key={index}>
              <div className='file-upload-item__name'>{x.nome}</div>
              <button
                className='file-upload-item__bt'
                title='Fazer Download'
                type='button'
                onClick={() => x.identificador && !x.codigoDeRastreio ? fazerDownload(x) : alertaDownload()}
              >
                <i className='icon icon-download-cloud'></i>
              </button>
              <button
                className='file-upload-item__bt --remove'
                title='Remover'
                type='button'
                onClick={() => confirmarERemover(x)}
              >
                <i className='icon icon-remove'></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='mt-1'>
        <label
          htmlFor='anexos'
          className='button --light w-sm-100'
        >
          Adicionar Anexo
          <i className='icon icon-doc ml-1'></i>
        </label>
        <input
          type='file'
          name='anexos'
          id='anexos'
          tabIndex={10}
          onChange={(evento) => adicionarAnexo(evento)}
          onClick={evento => { evento.target.value = null }}
        />
      </div>
    </r-cell>
  )
}