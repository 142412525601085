import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import SemanalCustomInput from './semanal-custom-input'

export default function SeletorDeDataDaAgenda(props) {
  const {
    calendario,
    history,
    match,
  } = props

  const [data, setData] = useState(calendario.data)
  const [dataInicial, setDataInicial] = useState(null)
  const [dataFinal, setDataFinal] = useState(null)  
  const input = useRef(null)

  useEffect(() => {
    if (data) {
      setDataInicial(moment(data).day(0).toDate())
      setDataFinal(moment(data).day(6).toDate())
    }
  }, [data])

  const alterarData = novaData => {
    let dataAlterada = novaData[0] === undefined ? novaData : novaData[0]
    setData(moment(dataAlterada).toDate())
    history.push(`/agendamentos/agenda/${match.params.identificadorDoProfissional}/data/${moment(dataAlterada).format('YYYY-MM-DD')}`)
  }

  const diaAnterior = () => {
    let diasParaSubtrair = calendario.visualizacao === 'semana' ? -7 : -1
    let novaData = moment(data).add(diasParaSubtrair, 'days')
    alterarData(novaData)
  }

  const proximoDia = () => {
    let diasParaSomar = calendario.visualizacao === 'semana' ? 7 : 1
    let novaData = moment(data).add(diasParaSomar, 'days')
    alterarData(novaData)
  }

  return (
    <div className="date-period">
      <span className="date-period__before" onClick={diaAnterior}><i className="icon icon-arrow-left"></i></span>
      <div className="date-period__content">
        {calendario.visualizacao === 'dia' &&
          <ReactDatePicker
            className="date-period__content_agenda"
            selected={data}
            onChange={date => alterarData(date)}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={5}
            scrollableYearDropdown
            dateFormat='dd/MM/yyyy'
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            todayButton="Hoje"
          />
        }
        {calendario.visualizacao === 'semana' &&
          <ReactDatePicker
            className="date-period__content"
            selected={data}
            onChange={date => alterarData(date)}
            startDate={dataInicial}
            endDate={dataFinal}
            selectsRange
            customInput={<SemanalCustomInput ref={input} dataInicial={dataInicial} dataFinal={dataFinal} />}
            shouldCloseOnSelect={false}
            todayButton="Hoje"
          />
        }
      </div>
      <span className="date-period__after" onClick={proximoDia}><i className="icon icon-arrow-right"></i></span>
    </div>
  )
}