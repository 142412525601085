import React, { useState, useMemo } from 'react'
import { ProSidebar, Menu, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar'
import { CalendarCheck, ChevronRight, ChevronLeft } from '../../design/icones'
import SeletorDeTipoDeTarefa from './seletor-de-tipo-de-tarefa'
import Card from './cards'

import 'react-pro-sidebar/dist/css/styles.css'
import './style.css'

export default function Sidebar(props) {
  const {
    abrirEditarTarefa,
    identificadorDoPaciente,
    inicioComponente,
    recuperarNome,
    tarefas,
    tipos,
    titulo,
    ehTelaDeCadastro
  } = props

  const [collapsed, setCollapsed] = useState(false)

  const tarefasVisiveis = useMemo(() => {
    return tarefas.map((tarefa) => {
      const tipo = tipos && tipos[tarefa.tipo.identificador]
      const versao = tipo ? tipo.versoes.find(x => x.numero === tarefa.tipo.versao) : null

      return {
        ...tarefa,
        exibir: ehTelaDeCadastro ? versao?.configuracoes?.exibir_no_cadastro === 'true' : versao?.configuracoes?.exibir_no_prontuario === 'true',
        corDoTipo: versao?.configuracoes?.cor
      }
    })
  }, [tarefas, tipos, ehTelaDeCadastro])

  return (
    <ProSidebar
      rtl={false}
      toggled={false}
      collapsed={collapsed}
      breakPoint='md'
      style={{ borderTop: `${inicioComponente}px solid #f1f3f4` }}
    >
      <SidebarHeader onClick={() => setCollapsed(!collapsed)}>
        <div className='sidebar-btn-wrapper sidebar-header'>
          <div>{collapsed ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}</div>
          <div><span>{!collapsed && titulo}</span></div>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape='circle' className='adicionar-tarefa' style={{ 'paddingTop': '5px', 'paddingLeft': '5px' }}>
          <SeletorDeTipoDeTarefa abrirEditarTarefa={abrirEditarTarefa} identificadorDoPaciente={identificadorDoPaciente} ehTelaDeCadastro={ehTelaDeCadastro} />
        </Menu>

        <Menu iconShape='circle'>
          {tarefasVisiveis.filter(t => t.exibir).map((item, index) => {
            const nome = recuperarNome(item)

            if (collapsed) {
              return (
                <SubMenu
                  icon={<CalendarCheck size='17' />}
                  key={`tarefa_paciente_${index}`}
                >
                  <Card abrirEditarTarefa={abrirEditarTarefa} item={item} index={index} nome={nome} />
                </SubMenu>
              )
            } else {
              return (
                <React.Fragment key={`tarefa_paciente_${index}`}>
                  <Card abrirEditarTarefa={abrirEditarTarefa} item={item} index={index} nome={nome} />
                </React.Fragment>
              )
            }
          })}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  )
}