import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
} from '../../tipos'

export const listarTiposDeAgendamentoDasConfiguracoesDaAgenda = () => {
  const resultado = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA })

    try {
      const resultado = await api.listarTiposDeAgendamentoDasConfiguracoesDaAgenda()

      dispatch(listouTiposDeAgendamentoDasConfiguracoesDaAgenda(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))

    } catch (erro) {
      dispatch(erroAoListarTiposDeAgendamentoDasConfiguracoesDaAgenda(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de atendimento da configuração da agenda.')))
    }
  }

  resultado.meta = {
    debounce: {
      key: LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
      time: 300,
    }
  }

  return resultado
}

const listouTiposDeAgendamentoDasConfiguracoesDaAgenda = paginaDeDados => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  paginaDeDados
})

const erroAoListarTiposDeAgendamentoDasConfiguracoesDaAgenda = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  erro,
})

export const adicionarTipoDeAgendamentoDoProfissional = (identificadorDoProfissional, identificadorDoTipoDeAgendamento) => async dispatch => {
  dispatch({ type: ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL })

  try {
    const resultado = await api.adicionarTipoDeAgendamentoDoProfissional(identificadorDoProfissional, identificadorDoTipoDeAgendamento)

    dispatch(adicionouTipoDeAgendamentoDoProfissional(identificadorDoTipoDeAgendamento))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoadicionarTipoDeAgendamentoDoProfissional(erro, { identificadorDoProfissional, identificadorDoTipoDeAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o tipo de agendamento do profissional.')))
    return false
  }
}

const adicionouTipoDeAgendamentoDoProfissional = identificadorDoTipoDeAgendamento => ({
  type: ADICIONOU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  identificadorDoTipoDeAgendamento,
})

const erroAoadicionarTipoDeAgendamentoDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const removerTipoDeAgendamentoDoProfissional = (identificadorDoProfissional, identificadorDoTipoDeAgendamento) => async dispatch => {
  dispatch({ type: REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL })

  try {
    const resultado = await api.removerTipoDeAgendamentoDoProfissional(identificadorDoProfissional, identificadorDoTipoDeAgendamento)

    dispatch(removeuTipoDeAgendamentoDoProfissional(identificadorDoTipoDeAgendamento))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverTipoDeAgendamentoDoProfissional(erro, { identificadorDoProfissional, identificadorDoTipoDeAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o tipo de agendamento do profissional.')))

    return false
  }
}

const removeuTipoDeAgendamentoDoProfissional = identificadorDoTipoDeAgendamento => ({
  type: REMOVEU_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  identificadorDoTipoDeAgendamento,
})

const erroAoRemoverTipoDeAgendamentoDoProfissional = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  erro,
  parametros,
})

export const listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda = (identificador, filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA })

  try {
    const resultado = await api.listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(identificador, filtros, pagina, ordenacao)

    dispatch(listouTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(erro, { identificador, filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de atendimento da configuração da agenda.')))
  }
}

const listouTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  filtros,
  ordenacao,
  paginaDeDados
})


const erroAoListarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  erro,
  parametros,
})