import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_VACINAS,
  LISTOU_VACINAS,
  ERRO_AO_LISTAR_VACINAS
} from './tipos'

const listouVacinas = vacinas => ({
  type: LISTOU_VACINAS,
  vacinas
})

const erroAoListarVacinas = erro => ({
  type: ERRO_AO_LISTAR_VACINAS,
  erro
})

export const listarVacinas = () => async dispatch => {
  dispatch({ type: LISTAR_VACINAS })

  try {
    const resultado = await api.listarVacinas()

    dispatch(listouVacinas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarVacinas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as vacinas.')))
  }
}