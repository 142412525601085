import api from '../../../api-major'

const adicionarMiniMental = (paciente, miniMental) => api.post(`/pacientes/${paciente}/mini-mentais`, miniMental)
const alterarMiniMental = (paciente, miniMental) => api.put(`/pacientes/${paciente}/mini-mentais/${miniMental.identificador}`, miniMental)
const removerMiniMental = (paciente, identificador) => api.delete(`/pacientes/${paciente}/mini-mentais/${identificador}`)
const listarMiniMentais = paciente => api.get(`/pacientes/${paciente}/mini-mentais`)
const recuperarDesenhoDoMiniMental = (paciente, identificador) => api.get(`/pacientes/${paciente}/mini-mentais/${identificador}/desenho`, { responseType: 'arraybuffer' })
const adicionarInterpretacaoMedicaDoMiniMental = (paciente, identificador, interpretacao) => api.put(`/pacientes/${paciente}/mini-mentais/${identificador}/interpretacao-medica`, interpretacao)
const removerInterpretacaoMedicaDoMiniMental = (paciente, identificador) => api.delete(`/pacientes/${paciente}/mini-mentais/${identificador}/interpretacao-medica`)

export default {
  adicionarMiniMental,
  alterarMiniMental,
  listarMiniMentais,
  recuperarDesenhoDoMiniMental,
  removerMiniMental,
  adicionarInterpretacaoMedicaDoMiniMental,
  removerInterpretacaoMedicaDoMiniMental
}