import { connect } from 'react-redux'
import acoes from '../../acoes'
import GruposDeCuidado from './index'

const mapStateToProps = state => {
  const grupoDeCuidadoAtual = state.gruposDeCuidado.itemAtual

  let grupoDeCuidado = {
    nome: '',
    observacoes: '',
    ativo: true
  }

  if (grupoDeCuidadoAtual) {
    grupoDeCuidado.identificador = grupoDeCuidadoAtual.identificador
    grupoDeCuidado.nome = grupoDeCuidadoAtual.nome
    grupoDeCuidado.observacoes = grupoDeCuidadoAtual.observacoes
    grupoDeCuidado.ativo = grupoDeCuidadoAtual.ativo
  }

  return {
    filtros: state.gruposDeCuidado.filtros,
    ordenacao: state.gruposDeCuidado.ordenacao,
    paginaDeDados: state.gruposDeCuidado.paginaDeDados,
    grupoDeCuidado
  }
}

export default connect(mapStateToProps, acoes)(GruposDeCuidado)