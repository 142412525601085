import React, {useEffect, useRef, useState} from 'react'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Modal from 'react-modal'
import moment from 'moment'
import Formulario from './formulario'
import Resumo from './resumo'

import {
  LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE
} from '../../../../../acoes/tipos'

export default function AvaliacaoDaSobrecargaDoCuidador({ identificadorDoPaciente, avaliacoesDoPaciente }) {
  const {
    listarFrequenciasNaEscalaZarit,
    frequenciasNaEscalaZarit,
    listarAvaliacoesDaSobrecargaDoCuidador,
    adicionarAvaliacaoDaSobrecargaDoCuidador,
    alterarAvaliacaoDaSobrecargaDoCuidador,
    removerAvaliacaoDaSobrecargaDoCuidador
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)

  const referenciaDoInfo = useRef(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE])

  useEffect(() => {
    if (avaliacoesDoPaciente === undefined) {
      listarAvaliacoesDaSobrecargaDoCuidador(identificadorDoPaciente)
    }
  }, [listarAvaliacoesDaSobrecargaDoCuidador, identificadorDoPaciente, avaliacoesDoPaciente])

  useEffect(() => { listarFrequenciasNaEscalaZarit() }, [listarFrequenciasNaEscalaZarit])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirResumo(item) {
    setSelecionada(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarAvaliacaoDaSobrecargaDoCuidador(identificadorDoPaciente, item) : await adicionarAvaliacaoDaSobrecargaDoCuidador(identificadorDoPaciente, item)
  }

  if (avaliacoesDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }
  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário da Avaliação da Sobrecarga do Cuidador'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          valores={recuperarValorInicialZarit(selecionada)}
          frequenciasNaEscalaZarit={frequenciasNaEscalaZarit}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo da Avaliação da Sobrecarga do Cuidador'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          frequenciasNaEscalaZarit={frequenciasNaEscalaZarit}
          fechar={fecharResumo}
          valores={recuperarValorInicialZarit(selecionada)}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção da Avaliação da Sobrecarga do Cuidador'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerAvaliacaoDaSobrecargaDoCuidador(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  (
                    <button
                      className='table-items-expanded__bt --edit'
                      title='Ver resumo'
                      onClick={() => abrirResumo(item)}
                      type='button'
                      key={`questionario_${item.identificador}`}
                    >
                      <i className='icon icon-doc-text'></i>
                    </button>
                  )
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                     <div className='tooltip__drop custom-scrollbar right-20' style={{ height: !item.observacoes ? 'auto' : '200px' }}>
                       <div className='tooltip__drop__body'>
                         <div className='tooltip__drop__title'>
                           Histórico
                           <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i>
                           </button>
                         </div>
                         <div className='tooltip__drop__description'>
                           <span>Usuário: {item.usuario.nome}</span> <br />
                           {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br/></>}
                           <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                           {item.observacoes &&
                            <>
                              <strong>Observações:</strong><br />
                              <pre>{item.observacoes}</pre>
                            </>
                           }
                         </div>
                       </div>
                     </div>
                    }
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={avaliacoesDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros da Escala Zarit.'
              classNameAdicionar='col-xs-6 col-lg-3'
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Cuidador'
                renderizar={item => item.nomeDoCuidador}
              />
              <Coluna
                className='col-lg-2'
                nome='Avaliação'
                renderizar={item => item.avaliacao}
              />
              <Coluna
                className='col-lg-2'
                nome='Pontuação'
                renderizar={item => item.pontuacao > 0 && item.pontuacao}
              />
              <LinhaExpandida className='col-xs-10 col-lg-12'>
                <AgrupamentoDeColunas>
                  <Coluna
                    nome='DATA'
                    renderizar={item => formatarDataParaFormatoLocal(item.data)}
                  />
                  <Coluna
                    nome='CUIDADOR'
                    renderizar={item => item.nomeDoCuidador && item.nomeDoCuidador}
                  />
                  <Coluna
                    nome='SEM TEMPO SUFICIENTE PARA VOCÊ MESMO'
                    campoDeDados='semTempoSuficienteParaVoceMesmo'
                  />
                  <Coluna
                    nome='ESTRESSADO OU ANGUSTIADO'
                    campoDeDados='estressadoOuAngustiado'
                  />
                  <Coluna
                    nome='RELAÇÃO COM AMIGOS OU FAMILIARES AFETADA'
                    campoDeDados='relacaoComAmigosOuFamiliaresAfetada'
                  />
                  <Coluna
                    nome='EXAUSTO'
                    campoDeDados='exausto'
                  />
                  <Coluna
                    nome='SAÚDE AFETADA'
                    campoDeDados='saudeAfetada'
                  />
                  <Coluna
                    nome='TEM PERDIDO O CONTROLE DA VIDA'
                    campoDeDados='temPerdidoOControleDaVida'
                  />
                  <Coluna
                    nome='MUITO SOBRECARREGADO'
                    campoDeDados='muitoSobrecarregado'
                  />
                  <Coluna
                    nome='AVALIAÇÃO'
                    campoDeDados='avaliacao'
                  />
                  <Coluna
                    nome='PONTUAÇÃO'
                    renderizar={item => item.pontuacao > 0 && item.pontuacao}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirAvaliacao = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a Avaliação de Sobrecarga do Cuidador?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirAvaliacao}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperarValorInicialZarit = selecionada => {
  if (selecionada) {
    return {
      ...selecionada
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    nomeDoCuidador: '',
    observacoes: '',
    semTempoSuficienteParaVoceMesmo: undefined,
    estressadoOuAngustiado: undefined,
    relacaoComAmigosOuFamiliaresAfetada: undefined,
    exausto: undefined,
    saudeAfetada: undefined,
    temPerdidoOControleDaVida: undefined,
    muitoSobrecarregado: undefined
  }
}