import React, { useMemo } from 'react'
import moment from 'moment'
import { converterObjetoParaArray } from '../../../../bibliotecas/conversao'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import { DatePicker, Toglle } from '../../../formik/formulario'
import recuperarValoresIniciaisDaSecao from '../../atendimento/recuperar-valores-iniciais-da-secao'

export default function DataDosExames({ nomeData, nomeMesmaData, contadorDeExames = undefined, valores = {}, componentes, secao }) {
  const valoresDosExames = useMemo(() => recuperarValoresIniciaisDaSecao(componentes, valores, secao, contadorDeExames), [componentes, valores, secao, contadorDeExames])
  const temExameInformado = converterObjetoParaArray(valoresDosExames).some(x => x && (x.valor || x.proteinas || x.creatinina))

  const replicarDataDosExames = valores[nomeMesmaData]
  const validarData = valor => {
    if (replicarDataDosExames && temExameInformado) {
      return deveSerInformado(valor)
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={'1-2'} span-lg={'1-3'}>
          <DatePicker
            nome={nomeData}
            titulo={`Data dos Exames`}
            maxDate={moment().toDate()}
            isDisabled={!replicarDataDosExames}
            validar={validarData}
          />
        </r-cell>
        <r-cell span={4} span-md={'3-5'} span-lg={'4-6'}>
          <div className='form-toggle-list'>
            <Toglle
              className='form-toggle-2'
              nome={nomeMesmaData}
              titulo='Exames feitos na mesma data?'
            />
          </div>
        </r-cell>
      </r-grid>      
    </r-cell>
  )
}