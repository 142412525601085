import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO
} from '../../tipos'

export const selecionarProfissaoDoWizardDeAgendamento = profissaoSelecionada => ({
  type: SELECIONAR_PROFISSAO_DO_WIZARD_DE_AGENDAMENTO,
  profissaoSelecionada
})

export const selecionarProfissionalDoWizardDeAgendamento = profissionaisSelecionados => ({
  type: SELECIONAR_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO,
  profissionaisSelecionados,
})

export const selecionarEspecialidadeDoProfissionalDoWizardDeAgendamento = especialidade => ({
  type: SELECIONAR_ESPECIALIDADE_DO_PROFISSIONAL_DO_WIZARD_DE_AGENDAMENTO,
  especialidade
})

export const selecionarTipoDoAgendamentoDoWizardDeAgendamento = identificador => ({
  type: SELECIONAR_TIPO_DO_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  identificador
})

const listouProfissionaisDeSaudeDoWizardDeAgendamento = profissionaisDeSaude => ({
  type: LISTOU_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  profissionaisDeSaude,
})

const erroAoListarProfissionaisDeSaudeDoWizardDeAgendamento = erro => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  erro
})

export const listarProfissionaisDeSaudeDoWizardDeAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarProfissionaisDeSaude()

    dispatch(listouProfissionaisDeSaudeDoWizardDeAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {

    dispatch(erroAoListarProfissionaisDeSaudeDoWizardDeAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais de saúde.')))
  }
}

const listouProfissoesDoWizardDeAgendamento = profissoes => ({
  type: LISTOU_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  profissoes,
})

const erroAoListarProfissoesDoWizardDeAgendamento = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  erro,
})

export const listarProfissoesDoWizardDeAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarProfissoes()

    dispatch(listouProfissoesDoWizardDeAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissoesDoWizardDeAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissoes.')))
  }
}

const listouTiposDeAgendamentoDoWizardDeAgendamento = tiposDeAgendamento => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  tiposDeAgendamento,
})

const erroAoListarTiposDeAgendamentoDoWizardDeAgendamento = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  erro
})

export const listarTiposDeAgendamentoDoWizardDeAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarTiposDeAgendamento({ ativo: ['Sim'] }, '', '', 100)

    dispatch(listouTiposDeAgendamentoDoWizardDeAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeAgendamentoDoWizardDeAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de agendamento.')))
  }
}