import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_TAG_NO_PACIENTE,
  ADICIONOU_TAG_NO_PACIENTE,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE,
  ERRO_AO_LISTAR_TAGS_DE_PACIENTES,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE,
  REMOVEU_TAG_DO_PACIENTE,
  REMOVER_TAG_DO_PACIENTE,
  LISTAR_TAGS_DE_PACIENTES, 
  LISTOU_TAGS_DE_PACIENTES
} from '../tipos'

const adicionouTagNoPaciente = (identificadorDoPaciente, tag) => ({
  type: ADICIONOU_TAG_NO_PACIENTE,
  identificadorDoPaciente,
  tag,
})

const erroAoAdicionarTagNoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TAG_NO_PACIENTE,
  erro,
  parametros,
})

export const adicionarTagNoPaciente = (identificadorDoPaciente, tag) => async dispatch => {
  dispatch({ type: ADICIONAR_TAG_NO_PACIENTE })

  try {
    const resultado = await api.adicionarTagNoPaciente(identificadorDoPaciente, tag)

    dispatch(adicionouTagNoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarTagNoPaciente(erro, { identificadorDoPaciente, tag }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar tag no paciente.')))

    return false
  }
}

const removeuTagNoPaciente = (identificadorDoPaciente, tag) => ({
  type: REMOVEU_TAG_DO_PACIENTE,
  identificadorDoPaciente,
  tag,
})

const erroAoRemoverTagDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_TAG_DO_PACIENTE,
  erro,
  parametros
})

export const removerTagDoPaciente = (identificadorDoPaciente, tag) => async dispatch => {
  dispatch({ type: REMOVER_TAG_DO_PACIENTE })

  try {
    const resultado = await api.removerTagDoPaciente(identificadorDoPaciente, tag)

    dispatch(removeuTagNoPaciente(identificadorDoPaciente, tag))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRemoverTagDoPaciente(erro, {identificadorDoPaciente, tag}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao remover tag do paciente.')))
  }
}

const listouTagsDePacientes = tagsDePacientes => ({
    type: LISTOU_TAGS_DE_PACIENTES,
    tagsDePacientes
})

const erroAoListarTagsDePacientes = erro => ({
    type: ERRO_AO_LISTAR_TAGS_DE_PACIENTES,
    erro
})

export const listarTagsDePacientes = () => async dispatch => {
    dispatch({ type: LISTAR_TAGS_DE_PACIENTES })

    try {
        const resultado = await api.listarTagsDePacientes()

        dispatch(listouTagsDePacientes(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoListarTagsDePacientes(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as tags de pacientes.')))
    }
}
