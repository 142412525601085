import React from 'react'
import ReactTooltip from 'react-tooltip'
import { FileText, Diagram } from '../../../../design/icones'

export function title(tarefa) {
  return (
    <>
      <div
        data-tip
        data-for={`info_tile_${tarefa.identificador}`}
      >
        <div className='atividades-titulo'><FileText size={18} />{tarefa.descricao}</div>
      </div>
      <ReactTooltip
        id={`info_tile_${tarefa.identificador}`}
        place='bottom'
        effect='solid'
        type='info'
        className='tooltip-atividades-header'
        key={`info_tile_${tarefa.identificador}`}
      >
        <pre>{tarefa.descricao}</pre>
      </ReactTooltip>
    </>
  )
}

export function subTitle(tarefa) {
  return (
    <div className='atividades-titulo'><Diagram size={18} />{tarefa.status}</div>
  )
}
