import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_AGENDAMENTOS,
  LISTOU_AGENDAMENTOS,
  ERRO_AO_LISTAR_AGENDAMENTOS,
  LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA
} from '../tipos'

const listouAgendamentos = (paginaDeDados, filtros, ordenacao) => ({
  type: LISTOU_AGENDAMENTOS,
  paginaDeDados,
  filtros,
  ordenacao
})

const erroAoListarAgendamentos = (erro, filtros) => ({
  type: ERRO_AO_LISTAR_AGENDAMENTOS,
  erro,
  filtros
})

export const listarAgendamentos = (filtros, pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_AGENDAMENTOS })

  try {
    const resultado = await api.listarAgendamentos(filtros, pagina, ordenacao)

    dispatch(listouAgendamentos(resultado.data.dados, filtros, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAgendamentos(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os agendamentos.')))
  }
}


const listouTiposDeAgendamentoDaPesquisa = tiposDeAgendamento => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  tiposDeAgendamento
})

const erroAoListarTiposDeAgendamentoDaPesquisa = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  erro,
  parametros
})

export const listarTiposDeAgendamentoDaPesquisa = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA })

  try {
    const resultado = await api.listarTiposDeAgendamento({ ativo: ['Sim'] }, '', '', 10000)

    dispatch(listouTiposDeAgendamentoDaPesquisa(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarTiposDeAgendamentoDaPesquisa(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de agendamento.')))
  }
}