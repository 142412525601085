import React, { useState, Children, useRef, useEffect, useCallback } from 'react'
import Modal from 'react-modal'
import avatar from '../../design/img/no-thumb.png'
import tabelaVazia from '../../design/img/empty-data.svg'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'

export function Coluna(props) {
  const {
    campoDeDados,
    className,
    expandida,
    filtros = [],
    valorDosFiltros = [],
    limparOrdenacao,
    nome,
    exibirOrdenacao = false,
    exibirTitulo = false,
    ordenacao = null,
    ...rest
  } = props

  const ordem = ordenacao ? ordenacao.ordem : null

  const ordenar = () => {
    const parametro = ordem === '+' ? '-' : '+'
    rest.ordenar({ nome: campoDeDados, ordem: parametro })
  }

  const classeOrdem = ordem ? (ordem === '+' ? 'is-asc is-on' : 'is-desc is-on') : 'is-asc'
  const [exibirFiltro, setExibirFiltro] = useState(false)
  const [filtrosAdicionados, setFiltrosAdicionados] = useState(valorDosFiltros)
  const referenciaDoFiltro = useRef(null)

  useCliqueForaDoElemento(referenciaDoFiltro, () => setExibirFiltro(false))

  const adicionarFiltro = filtro => {
    const novosFiltros = [...filtrosAdicionados, filtro]
    setFiltrosAdicionados(novosFiltros)
    rest.alterarFiltros({ nome: campoDeDados, valor: novosFiltros })
  }

  const removerFiltro = filtro => {
    const novosFiltros = filtrosAdicionados.filter(x => x !== filtro)
    setFiltrosAdicionados(novosFiltros)
    rest.alterarFiltros({ nome: campoDeDados, valor: novosFiltros })
  }

  const filtroAplicado = filtrosAdicionados.length !== filtros.length

  return (
    <div
      className={`table-items${expandida ? '-expanded' : ''}__head ${!exibirTitulo && 'd-none'} d-lg-inline-flex  ${exibirOrdenacao && classeOrdem} ${ordem && 'ordem'} ${className}`}
      key={`${nome}`}
      ref={referenciaDoFiltro}
      onClick={exibirOrdenacao ? ordenar : undefined}
    >
      {filtros.length > 0 ?
        (() => (
          <div
            className={`filter-list ${exibirFiltro && 'is-active'} ${filtroAplicado && 'is-filtered'}`}
            onClick={() => setExibirFiltro(true)}
          >
            <div className='filter-list__toggle'>{nome}</div>
            <div className='filter-list__drop'>
              <div className='filter-list__drop__arrow'></div>
              {filtros.map((filtro, index) => (
                <div className='checkbox' key={`check_${nome}_${index}`}>
                  <input
                    className='checkbox-input'
                    defaultChecked={filtrosAdicionados.includes(filtro)}
                    onChange={evento => evento.target.checked ? adicionarFiltro(filtro) : removerFiltro(filtro)}
                    id={`check_${nome}_${index}`}
                    name={`check_${nome}_${index}`}
                    type='checkbox'
                  />
                  <label className='checkbox-label' htmlFor={`check_${nome}_${index}`}><span>{filtro}</span></label>
                </div>
              ))}
            </div>
          </div>
        ))() :
        (() => nome)()
      }
    </div>
  )
}

export function ColunaFoto({ nome }) {
  return <div className='table-items-expanded__head justify-content-center col-xs-1'>{nome}</div>
}

export function ColunaExcluir({ desabilitado = false, excluir, labelExcluir = 'Excluir' }) {
  return (
    <div className={`table-items__head justify-content-center col-xs-2 col-lg-1`}>
      <button
        type='button'
        className={`table-items__bt-remove ${desabilitado && 'disabled'}`}
        onClick={excluir}
      >
        {labelExcluir}
      </button>
    </div>
  )
}

export function Foto({ nome, fotos, identificador, recuperarFotoOriginal }) {
  const [exibir, setExibir] = useState(false)
  const [foto, setFoto] = useState('')

  const src = fotos[identificador]

  function abrirImagem(foto) {
    setFoto(foto)
    setExibir(true)
  }

  function fecharImagem() {
    setExibir(false)
  }

  function ExibirImagem({ fechar, recuperarFotoOriginal }) {
    const [fotoOriginal, setFotoOriginal] = useState(false);

    useEffect(() => {
      const recuperar = async () => {
        const response = await recuperarFotoOriginal()
        setFotoOriginal(response)
      }

      exibir && recuperarFotoOriginal && recuperar()

    }, [recuperarFotoOriginal])

    return (
      <div className='modal-foto'>
        <i className='icon icon-close modal-foto' onClick={fechar}></i>
        {!fotoOriginal && <img alt='foto' src={foto} />}
        {fotoOriginal && <img alt='foto' src={fotoOriginal} />}
      </div>
    )
  }

  const estiloDoModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2px',
      width: '30%'
    }
  }

  return (
    <>
      <Modal
        isOpen={exibir}
        onRequestClose={fecharImagem}
        style={estiloDoModal}
      >
        <ExibirImagem
          fechar={fecharImagem}
          recuperarFotoOriginal={recuperarFotoOriginal}
        />
      </Modal>
      <div className='form-table'>
        <div className='form-table-images'>
          <div className='form-table-image'>
            <img src={src || avatar} alt={nome} />
            {src &&
              <div className='form-table-image-tools'>
                <button className='form-table-image-zoom' aria-label='Ampliar' type='button' onClick={() => abrirImagem(src)}><i className='icon icon-search'></i></button>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export function Tabela(props) {
  const {
    acaoDoBotaoAdicionar,
    acoes = [],
    children,
    className,
    classNameAdicionar,
    chave,
    dados,
    dadosAbertos = [],
    esconderItemExcluir = undefined,
    excluir,
    exibirBotaoAdicionar = false,
    exibirBotaoMais = true,
    exibirMensagem = true,
    expandida = false,
    labelExcluir,
    listar,
    marcacaoDeErro = undefined,
    marcacaoDeAlerta = undefined,
    marcacaoDeInativo = undefined,
    mensagemTabelaVazia = 'Não há resultados.',
    paginaDeDados,
    processando,
    ordenacao,
    titulo = true,
    ...rest
  } = props
  const [selecionados, setSelecionados] = useState([])
  const [abertos, setAbertos] = useState(dadosAbertos)
  const [filtros, setFiltro] = useState(rest.filtros)
  const chaves = chave && chave.split('.')

  const escolheChave = useCallback(item => {
    let valor = item

    chaves.forEach(item => {
      valor = valor[item]
    })

    return valor
  }, [chaves])

  useEffect(() => {
    if (paginaDeDados && paginaDeDados.dados && selecionados.length > 0 && !paginaDeDados.dados.find(x => selecionados.find(s => escolheChave(x) === escolheChave(s)))) {
      setSelecionados([])
    }
  }, [paginaDeDados, selecionados, escolheChave])

  const alterarFiltros = filtro => {
    setFiltro({
      ...filtros,
      [filtro.nome]: filtro.valor
    })

    rest.alterarFiltros(filtro)
  }

  useEffect(() => {
    const itensDaTabela = paginaDeDados ? paginaDeDados.dados : dados
    const identificadores = itensDaTabela && itensDaTabela.map((x, index) => chave ? escolheChave(x) : index)
    const ehIgual = JSON.stringify(abertos) === JSON.stringify(identificadores)

    if (!exibirBotaoMais && expandida && itensDaTabela && !ehIgual) {
      const abertosReduce = itensDaTabela.reduce((acc, item, idx) => [...acc, chave ? escolheChave(item) : idx], [])
      setAbertos(abertosReduce)
    }
  }, [exibirBotaoMais, expandida, abertos, dados, paginaDeDados, escolheChave, chave])

  useEffect(() => {
    if (dadosAbertos.length > 0) {
      setAbertos(dadosAbertos)
    }
  }, [dadosAbertos])

  const colunas = Children.toArray(children).filter(x => x.type === Coluna)
  const colunasDeFoto = Children.toArray(children).filter(x => x.type === ColunaFoto)
  const linhaEspandida = Children.toArray(children).find(x => x.type === LinhaExpandida)
  const filtrosValidos = !colunas.some(x => x.props.campoDeDados && x.props.filtros && filtros[x.props.campoDeDados].length === 0)

  const mais = identificador => {
    if (abertos.includes(identificador)) {
      setAbertos(abertos.filter(x => x !== identificador))
    } else {
      setAbertos([...abertos, identificador])
    }
  }

  const clickSelecionado = item => {
    const achei = selecionados.find(s => escolheChave(item) === escolheChave(s))

    if (achei) {
      setSelecionados(selecionados.filter(x => escolheChave(x) !== escolheChave(item)))
    } else {
      setSelecionados([...selecionados, item])
    }
  }

  const ordenar = ordem => listar(paginaDeDados.pagina, ordem)
  const renderizarLinha = (item, index) => {
    return (
      <>
        {excluir &&
          <div className={`table-items${expandida ? '-expanded' : ''}__data justify-content-center col-xs-2 col-lg-1`}>
            <div className='checkbox'>
              {(esconderItemExcluir === undefined || !esconderItemExcluir(item)) &&
                <input
                  className='checkbox-input'
                  onChange={() => clickSelecionado(item)}
                  type='checkbox'
                  checked={selecionados.length > 0 && selecionados.find(s => escolheChave(item) === escolheChave(s)) ? true : false}
                  id={`check${escolheChave(item)}`}
                  name={`check${escolheChave(item)}`}
                />
              }
              <label className='checkbox-label' htmlFor={`check${escolheChave(item)}`} />
            </div>
          </div>
        }
        {colunasDeFoto.map(x => x.props.renderizar(item))}
        {colunas.map(coluna => {
          const key = chave ? escolheChave(item) : index
          if (coluna.props.principal) {
            return (<div className={`table-items__data --name ${coluna.props.className}`} key={`${coluna.props.nome}_${key}`}>
              <p className={`table-items__mobile-label-name ${abertos.includes(key) && 'is-active'}`}>{coluna.props.nome}</p>
              <strong>{coluna.props.renderizar ? coluna.props.renderizar(item) : item[coluna.props.campoDeDados]}</strong>
              <div className={`table-items__mobile ${abertos.includes(key) && 'is-active'}`}>
                {colunas.filter(x => !x.props.principal).map((coluna) =>
                  <div key={`mobile_${coluna.props.nome}_${key}`}>
                    {((coluna.props.renderizar && coluna.props.renderizar(item)) || item[coluna.props.campoDeDados]) &&
                      <div className='table-items__mobile-item'>
                        <p className='table-items__mobile-label'>{coluna.props.nome}</p>
                        {coluna.props.renderizar ? coluna.props.renderizar(item) : <p className='table-items__mobile-value'>{item[coluna.props.campoDeDados]}</p>}
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>)
          }

          return (
            <div
              className={`table-items${expandida ? '-expanded' : ''}__data ${coluna.props.className} ${coluna.props.foto ? 'pr-0' : 'd-none d-lg-inline-flex'}`}
              key={`${coluna.props.nome}_${key}`}
            >
              {coluna.props.foto ? <Foto item={item} fotos={coluna.props.fotos} identificador={coluna.props.identificadorDaFoto(item)} recuperarFotoOriginal={() => coluna.props.recuperarFotoOriginal && coluna.props.recuperarFotoOriginal(item)} /> :
                coluna.props.renderizar ?
                  coluna.props.renderizar(item)
                  : item[coluna.props.campoDeDados]}
            </div>
          )
        })}
      </>
    )
  }

  if ((!paginaDeDados || !paginaDeDados.dados) && !dados) {
    return null
  }

  const itensDaTabela = dados ? dados : paginaDeDados.dados

  const naoTemAcoes = !acoes || (acoes.length === 0 && !exibirBotaoMais)

  const renderizarAcoes = (item, index) => {
    if (naoTemAcoes) {
      return null
    }

    const key = chave ? escolheChave(item) : index

    return (
      <div className={`table-items${expandida ? '-expanded' : ''}__tools`}>
        {acoes.map(acao => acao(item))}
        {exibirBotaoMais &&
          <button
            onClick={() => mais(key)}
            className={`table-items${expandida ? '-expanded' : ''}__bt ${abertos.includes(key) && 'is-active'} ${expandida ? '' : 'd-lg-none'}`}
            title='Mais'
            type='button'
          >
            <i className='icon icon-more'></i>
          </button>
        }
      </div>
    )
  }

  const renderizarItem = (item, index) => {
    const key = chave ? escolheChave(item) : index
    const exibirMarcacaoDeAlerta = marcacaoDeAlerta && marcacaoDeAlerta(item)
    const exibirMarcacaoDeErro = marcacaoDeErro && marcacaoDeErro(item)
    const exibirMarcacaoDeInativo = marcacaoDeInativo && marcacaoDeInativo(item)

    const classNameAlerta = exibirMarcacaoDeAlerta ? 'linha-com-warning' : ''
    const classNameAlertaErro = exibirMarcacaoDeErro ? 'linha-com-error' : ''
    const classNameInativo = exibirMarcacaoDeInativo ? 'is-disabled' : ''

    if (!expandida) {
      return (
        <div className={`table-items__row ${classNameAlerta} ${classNameAlertaErro} ${classNameInativo} ${(abertos.includes(key)) && 'is-active'}`} key={key}>
          {renderizarLinha(item, index)}
          {!naoTemAcoes &&
            <div className='table-items__data justify-content-end justify-content-lg-end col-xs-4 col-md-2 col-lg-1'>
              {renderizarAcoes(item, index)}
            </div>
          }
        </div>
      )
    }

    return (
      <div className={`table-items-expanded__collapsable ${abertos.includes(key) && 'is-active'} `} key={key}>
        <div className='table-items-expanded__collapsable-cols'>
          {renderizarLinha(item, index)}
        </div>
        <div className='table-items-expanded__collapsable-content'>
          {React.cloneElement(linhaEspandida, { item })}
        </div>
        {renderizarAcoes(item, index)}
      </div>
    )
  }

  return (
    <div className={`table-items${expandida ? '-expanded' : ''} ${className} ${processando && 'is-loading'}`}>
      {titulo &&
        <div className={`table-items${expandida ? '-expanded' : ''}__row is-head `}>
          {excluir && itensDaTabela.length > 0 && <ColunaExcluir desabilitado={selecionados.length === 0} labelExcluir={labelExcluir} excluir={() => selecionados.length > 0 && excluir(selecionados)} />}
          {colunas.map(coluna => React.cloneElement(coluna, {
            alterarFiltros,
            expandida,
            ordenar,
            ordenacao: ordenacao && ordenacao.nome === coluna.props.campoDeDados ? ordenacao : null,
            valorDosFiltros: filtros && coluna.props.campoDeDados ? filtros[coluna.props.campoDeDados] : [],
          }))}
          {exibirBotaoAdicionar &&
            <div className={`table-items__head d-lg-inline-flex justify-content-end ${classNameAdicionar}`}>
              <button className='button --plus-short' type='button' title='Adicionar' onClick={() => acaoDoBotaoAdicionar()}>
                <i className='icon icon-plus'></i>
              </button>
            </div>
          }
          {((acoes.length > 0 || exibirBotaoMais) && expandida && !exibirBotaoAdicionar) && <div className='table-items-expanded__head d-none d-lg-inline-flex col-lg-1'></div>}
        </div>
      }
      {filtrosValidos && itensDaTabela.map((item, index) => renderizarItem(item, index))}
      {exibirMensagem && itensDaTabela.length === 0 &&
        <div className='table-items__result'>
          <button className='icon-tabela-vazia'>
            <img src={tabelaVazia} alt='tabelaVazia' />
          </button>
          <p>{mensagemTabelaVazia}</p>
        </div>
      }
      {itensDaTabela.length > 0 && filtrosValidos && paginaDeDados && <Paginacao alterarPagina={pagina => listar(pagina, ordenacao)} {...paginaDeDados} />}
    </div>
  )
}

export function LinhaExpandida({ item, children, className }) {
  return (
    <>
      {Children.toArray(children).filter(x => x.type === ColunaFoto).map(coluna =>
        <div className='table-items-expanded__data pr-0 align-items-start col-xs-2 col-md-1' key={item.identificador}>
          <Foto item={item} fotos={coluna.props.fotos} identificador={coluna.props.identificadorDaFoto(item)} recuperarFotoOriginal={() => coluna.props.recuperarFotoOriginal && coluna.props.recuperarFotoOriginal(item)} />
        </div>
      )}
      <div className={`table-items-expanded__box ${className}`}>
        {Children.toArray(children).filter(x => x.type !== Coluna && x.type !== ColunaFoto).map(x => React.cloneElement(x, { item }))}
      </div>
    </>
  )
}

export function AgrupamentoDeColunas({ children, item, className }) {
  const colunas = Children.toArray(children)

  return colunas.map((coluna, index) => {
    if (!item[coluna.props.campoDeDados] && !coluna.props.renderizar) {
      return null
    }

    const valor = item[coluna.props.campoDeDados] ? item[coluna.props.campoDeDados] : coluna.props.renderizar(item)

    if (!valor) {
      return null
    }

    return (
      <div className={`table-items-expanded__box-item ${className} ${coluna.props.className && coluna.props.className}`} key={index}>
        {coluna.props.icone && typeof coluna.props.icone === 'string' && <i className={`icon ${coluna.props.icone}`}></i>}
        {coluna.props.icone && typeof coluna.props.icone === 'object' && <div className='icon'>{coluna.props.icone}</div>}
        <div className='table-items-expanded__box-label'>{coluna.props.nome}</div>
        <div className='table-items-expanded__box-value'>{item[coluna.props.campoDeDados] || coluna.props.renderizar(item)}</div>
      </div>
    )
  })
}

export function AgrupamentoDeColunasDinamico({ colunas, chave, item, className }) {
  const chaves = chave && chave.split('.')

  const escolheChave = useCallback(item => {
    let valor = item

    chaves.forEach(item => {
      valor = valor[item]
    })

    return valor
  }, [chaves])

  const colunasDoItemAtual = colunas[escolheChave(item)]

  if (colunasDoItemAtual.length === 0) {
    return null
  }

  return <AgrupamentoDeColunas item={item} className={className}>{colunasDoItemAtual}</AgrupamentoDeColunas>
}

function Paginacao({ alterarPagina, numeroTotalDePaginas, pagina, primeiraPagina, ultimaPagina }) {
  const opcoes = []

  for (let i = 1; i <= numeroTotalDePaginas; i++) {
    opcoes.push(<option key={i} value={`${i}`}>{i}</option>)
  }

  const proxima = () => {
    if (ultimaPagina) return
    alterarPagina(pagina + 1)
  }

  const anterior = () => {
    if (primeiraPagina) return
    alterarPagina(pagina - 1)
  }
  return (
    <ul className='pagination mt-3'>
      <li className='pagination__item'>
        <button
          className={`pagination__bt-prev ${primeiraPagina && 'is-inactive'}`}
          disabled={primeiraPagina}
          onClick={anterior}
          type='button'
        >
          <i className='icon icon-arrow-left' />
        </button>
      </li>
      <li className='pagination__item'>Página</li>
      <li className='pagination__item'>
        <div className='pagination__select'>
          <select
            className='selectPage'
            onChange={evento => alterarPagina(evento.target.value)}
            value={pagina}
          >
            {opcoes}
          </select>
        </div>
      </li>
      <li className='pagination__item'>de {numeroTotalDePaginas}</li>
      <li className='pagination__item'>
        <button
          className={`pagination__bt-next ${ultimaPagina && 'is-inactive'}`}
          disabled={ultimaPagina}
          onClick={proxima}
          type='button'
        >
          <i className='icon icon-arrow-right' />
        </button>
      </li>
    </ul>
  )
}