import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_QUEDA_DO_PACIENTE,
  ADICIONOU_QUEDA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE,
  ALTERAR_QUEDA_DO_PACIENTE,
  ALTEROU_QUEDA_DO_PACIENTE,
  ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE,
  LISTAR_QUEDAS_DO_PACIENTE,
  LISTOU_QUEDAS_DO_PACIENTE,
  ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE,
  REMOVER_QUEDA_DO_PACIENTE,
  REMOVEU_QUEDA_DO_PACIENTE,
  ERRO_AO_REMOVER_QUEDA_DO_PACIENTE,
} from '../../tipos'

const listouQuedasDoPaciente = (identificadorDoPaciente, quedas) => ({
  type: LISTOU_QUEDAS_DO_PACIENTE,
  identificadorDoPaciente,
  quedas
})

const erroAoListarQuedasDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE,
  erro,
  parametros
})

export const listarQuedasDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_QUEDAS_DO_PACIENTE })

  try {
    const resultado = await api.listarQuedasDoPaciente(identificadorDoPaciente)

    dispatch(listouQuedasDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarQuedasDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as quedas do paciente.')))
  }
}

const adicionouQuedaDoPaciente = queda => ({
  type: ADICIONOU_QUEDA_DO_PACIENTE,
  queda
})

const erroAoAdicionarQuedaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarQuedaDoPaciente = (identificadorDoPaciente, queda) => async dispatch => {
  dispatch({ type: ADICIONAR_QUEDA_DO_PACIENTE })

  try {
    const resultado = await api.adicionarQuedaDoPaciente(identificadorDoPaciente, queda)

    dispatch(adicionouQuedaDoPaciente(resultado.data.dados))
    dispatch(listarQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarQuedaDoPaciente(erro, { queda }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar queda do paciente.')))

    return false
  }
}

const alterouQuedaDoPaciente = queda => ({
  type: ALTEROU_QUEDA_DO_PACIENTE,
  queda
})

const erroAoAlterarQuedaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE,
  erro,
  parametros
})

export const alterarQuedaDoPaciente = (identificadorDoPaciente, queda) => async dispatch => {
  dispatch({ type: ALTERAR_QUEDA_DO_PACIENTE })

  try {
    const resultado = await api.alterarQuedaDoPaciente(identificadorDoPaciente, queda)

    dispatch(alterouQuedaDoPaciente(resultado.data.dados))
    dispatch(listarQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarQuedaDoPaciente(erro, { queda }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar queda do paciente.')))

    return false
  }
}

const removeuQuedaDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_QUEDA_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverQuedaDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_QUEDA_DO_PACIENTE,
  erro,
  parametros
})

export const removerQuedaDoPaciente = (identificadorDoPaciente, identificadorDaQueda) => async dispatch => {
  dispatch({ type: REMOVER_QUEDA_DO_PACIENTE })

  try {
    const resultado = await api.removerQuedaDoPaciente(identificadorDoPaciente, identificadorDaQueda)

    dispatch(removeuQuedaDoPaciente(identificadorDoPaciente))
    dispatch(listarQuedasDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverQuedaDoPaciente(erro, { identificadorDoPaciente, identificadorDaQueda }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover queda do paciente.')))

    return false
  }
}