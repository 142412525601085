import React, {useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import {setHours, setMinutes} from 'date-fns'
import moment from 'moment/moment'
import CampoDaTarefa from './campo-da-tarefa'
import {ComponenteLeitura} from '../card'
import {CalendarioComRelogio} from '../../../design/icones'
import {
  formatarSegundosENanosegundosParaFormatoLocal,
  formatarSegundosENanosegundosParaMoment
} from '../../../bibliotecas/data'

export default function Prazo({ obrigatorio, rotulo, valor, leitura, definir, criadaEm }) {
  const icone = <CalendarioComRelogio/>
  const titulo = rotulo ?? 'Prazo'

  const [horarioMinimo, setHorarioMinimo] = useState(setHours(setMinutes(new Date(), 0), 0))

  const dataDeCriacao = criadaEm && formatarSegundosENanosegundosParaMoment(criadaEm).isValid() ? formatarSegundosENanosegundosParaMoment(criadaEm).toDate() : criadaEm
  const horaDefault = new Date().setHours(23, 59, 59, 999)

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        valor={formatarSegundosENanosegundosParaFormatoLocal(valor)}
      />
    )
  }

  let valorFormatado = valor && formatarSegundosENanosegundosParaMoment(valor).isValid() ? formatarSegundosENanosegundosParaMoment(valor).toDate() : valor
  const valorFormatadoEmData = moment(valorFormatado).isValid() ? moment(valorFormatado).format('YYYY-MM-DD') : null
  valorFormatado = valorFormatadoEmData && moment(valorFormatadoEmData).isSame('2099-12-31') ? '' : valorFormatado

  const filtrarHorarios = dataSelecionada => {
    const dataEhIgual = moment(dataSelecionada).format('YYYY-MM-DD') === moment(dataDeCriacao).format('YYYY-MM-DD')

    if (dataEhIgual) {
      return setHorarioMinimo(dataDeCriacao.getTime())
    }

    return setHorarioMinimo(setHours(setMinutes(new Date(), 0), 0))
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valorFormatado || ''}
      icone={icone}
      titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <ReactDatePicker
          value={valor}
          selected={valor ? valor : horaDefault}
          onChange={onChange}
          onFocus={onFocus}
          onSelect={(data) => filtrarHorarios(data)}
          onCalendarClose={onBlur}
          showYearDropdown
          dateFormatCalendar='MMMM'
          dateFormat='dd/MM/yyyy, HH:mm'
          minDate={dataDeCriacao}
          showTimeSelect
          timeCaption='Hora'
          timeFormat='HH:mm'
          timeIntervals={30}
          minTime={horarioMinimo}
          maxTime={setHours(setMinutes(new Date(), 59), 23)}
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          wrapperClassName='w-100'
        />
      }
    </CampoDaTarefa>
  )
}