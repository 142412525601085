import {adicionarNotificacoesDaApi} from '../notificacoes'
import notificacao from '../../bibliotecas/notificacao'
import constantes from '../../configuracoes/constantes'
import {
  ATUALIZAR_ATIVIDADES_DA_TAREFA,
  ATUALIZAR_TAREFA_ATUAL,
  ATUALIZAR_TAREFAS,
  ATUALIZAR_TAREFAS_DO_PACIENTE,
  ATUALIZAR_TAREFAS_DO_USUARIO,
  ERRO_AO_REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL,
  ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA,
  ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS,
  ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE,
  ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO,
  REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL,
  REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA,
  REGISTRAR_FIRESTORE_DE_TAREFAS,
  REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE,
  REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO,
  REGISTROU_FIRESTORE_DA_TAREFA_ATUAL,
  REGISTROU_FIRESTORE_DE_ATIVIDADES_DA_TAREFA,
  REGISTROU_FIRESTORE_DE_TAREFAS,
  REGISTROU_FIRESTORE_DE_TAREFAS_DO_PACIENTE,
  REGISTROU_FIRESTORE_DE_TAREFAS_DO_USUARIO,
} from '../tipos'

const registrouFirestoreDeTarefas = () => ({ type: REGISTROU_FIRESTORE_DE_TAREFAS })

const erroAoRegistrarFirestoreDeTarefas = erro => ({
  type: ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS,
  erro
})

const atualizarTarefas = tarefas => ({
  type: ATUALIZAR_TAREFAS,
  tarefas
})

export const registrarFirestoreDeTarefas = (firestore, empresa, filtrosAtuais, identificadorDoTipoDeTarefa) => dispatch => {
  dispatch({ type: REGISTRAR_FIRESTORE_DE_TAREFAS })
  try {
    const limite = filtrosAtuais ? filtrosAtuais.limite : constantes.numeroTarefasPorPagina
    let tarefas = firestore.collection(`empresas/${empresa.toLowerCase()}/tarefas`).orderBy('prazo').limit(limite)

    if (identificadorDoTipoDeTarefa !== 'todas') {
      tarefas = tarefas.where('tipo.identificador', '==', identificadorDoTipoDeTarefa)
    }

    if (identificadorDoTipoDeTarefa === 'todas' && filtrosAtuais && filtrosAtuais.tiposParaExibir.length > 0) {
      tarefas = tarefas.where('tipo.identificador', 'in', filtrosAtuais.tiposParaExibir)
    }

    if (filtrosAtuais && filtrosAtuais.categoria) {
      tarefas = tarefas.where('categoria.codigo', '==', filtrosAtuais.categoria)
    }

    if (filtrosAtuais && filtrosAtuais.finalizada) {
      const filtro = filtrosAtuais.finalizada === 'true'
      tarefas = tarefas.where('finalizada', '==', filtro)
    }

    if (filtrosAtuais && filtrosAtuais.status && filtrosAtuais.status.length > 0) {
      tarefas = tarefas.where('status', 'in', filtrosAtuais.status)
    }

    if (filtrosAtuais && filtrosAtuais.responsavel) {
      tarefas = tarefas.where('responsavel.identificador', '==', filtrosAtuais.responsavel)
    }

    if (filtrosAtuais && filtrosAtuais.paciente) {
      tarefas = tarefas.where('paciente.identificador', '==', filtrosAtuais.paciente)
    }

    if (filtrosAtuais && filtrosAtuais.filtros && filtrosAtuais.filtros.length > 0) {
      const filtro = filtrosAtuais.filtros[0]
      tarefas = tarefas.where('filtros', 'array-contains', filtro)
    }

    const retorno = tarefas.onSnapshot(snapshot => {
      const docs = []

      snapshot.docs.forEach(doc => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        })
      })

      dispatch(atualizarTarefas(docs))
    })

    dispatch(registrouFirestoreDeTarefas())
    return retorno
  } catch (erro) {
    dispatch(erroAoRegistrarFirestoreDeTarefas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o Firestore de tarefas.')))
  }
}

const resgistrouFirestoreDeAtividadesDaTarefa = () => ({ type: REGISTROU_FIRESTORE_DE_ATIVIDADES_DA_TAREFA })

const erroAoRegistrarFirestoreDeaAtividadesDaTarefa = erro => ({
  type: ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA,
  erro
})

export const registrarFirestoreDeAtividadesDaTarefa = (firestore, empresa, identificadorDaTarefa) => dispatch => {
  dispatch({ type: REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA })

  try {
    const atividades = firestore.collection(`empresas/${empresa.toLowerCase()}/tarefas/${identificadorDaTarefa}/atividades`).orderBy('quando', 'desc')

    const retorno = atividades.onSnapshot(snapshot => {
      const docs = []
      snapshot.docs.forEach(doc => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        })
      })

      dispatch(atualizarAtividadesDaTarefa(identificadorDaTarefa, docs))
    })

    dispatch(resgistrouFirestoreDeAtividadesDaTarefa())
    return retorno
  } catch (erro) {
    dispatch(erroAoRegistrarFirestoreDeaAtividadesDaTarefa(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o Firestore de atividades da tarefa.')))
  }
}

export const atualizarTarefaAtual = tarefa => ({ type: ATUALIZAR_TAREFA_ATUAL, tarefa })

const resgistrouFirestoreDaTarefaAtual = () => ({ type: REGISTROU_FIRESTORE_DA_TAREFA_ATUAL })

const erroAoRegistrarFirestoreDaTarefaAtual = erro => ({
  type: ERRO_AO_REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL,
  erro
})

export const registrarFirestoreDaTarefaAtual = (firestore, empresa, identificadorDaTarefa) => dispatch => {
  dispatch({ type: REGISTRAR_FIRESTORE_DA_TAREFA_ATUAL })

  try {
    const tarefa = firestore.collection(`empresas/${empresa.toLowerCase()}/tarefas`).doc(identificadorDaTarefa)
    let tarefaAtual = null

    const retorno = tarefa.onSnapshot(doc => {
      const dados = doc.data()

      if (dados) {
        tarefaAtual = {
          id: doc.id,
          ...doc.data(),
        }

        dispatch(atualizarTarefaAtual(tarefaAtual))
      }
    })

    dispatch(resgistrouFirestoreDaTarefaAtual())
    return retorno
  } catch (erro) {
    dispatch(erroAoRegistrarFirestoreDaTarefaAtual(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o Firestore da tarefa atual.')))
  }
}

const atualizarAtividadesDaTarefa = (identificadorDaTarefa, atividades) => ({
  type: ATUALIZAR_ATIVIDADES_DA_TAREFA,
  identificadorDaTarefa,
  atividades
})

const erroAoRegistrarFirestoreDeTarefasDoPaciente = erro => ({
  type: ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE,
  erro
})

const registrouFirestoreDeTarefasDoPaciente = () => ({ type: REGISTROU_FIRESTORE_DE_TAREFAS_DO_PACIENTE })

const atualizarTarefasDoPaciente = tarefas => ({
  type: ATUALIZAR_TAREFAS_DO_PACIENTE,
  tarefas
})

export const registrarFirestoreDeTarefasDoPaciente = (firestore, empresa, identificadorDoPaciente) => dispatch => {
  dispatch({ type: REGISTRAR_FIRESTORE_DE_TAREFAS_DO_PACIENTE })
  try {
    const limite = constantes.numeroTarefasPorPagina

    let tarefas = firestore.collection(`empresas/${empresa.toLowerCase()}/tarefas`).orderBy('prazo').limit(limite)
    tarefas = tarefas.where('paciente.identificador', '==', identificadorDoPaciente)
    tarefas = tarefas.where('finalizada', '==', false)

    const retorno = tarefas.onSnapshot(snapshot => {
      const docs = []

      snapshot.docs.forEach(doc => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        })
      })

      dispatch(atualizarTarefasDoPaciente(docs))
    })

    dispatch(registrouFirestoreDeTarefasDoPaciente())
    return retorno
  } catch (erro) {
    dispatch(erroAoRegistrarFirestoreDeTarefasDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o Firestore de tarefas do paciente.')))
  }
}

const erroAoRegistrarFirestoreDeTarefasDoUsuario = erro => ({
  type: ERRO_AO_REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO,
  erro
})

const registrouFirestoreDeTarefasDoUsuario = () => ({ type: REGISTROU_FIRESTORE_DE_TAREFAS_DO_USUARIO })

const atualizarTarefasDoUsuario = tarefas => ({
  type: ATUALIZAR_TAREFAS_DO_USUARIO,
  tarefas
})

export const registrarFirestoreDeTarefasDoUsuario = (firestore, empresa, identificadorDoUsuario) => dispatch => {
  dispatch({ type: REGISTRAR_FIRESTORE_DE_TAREFAS_DO_USUARIO })
  try {
    const limite = constantes.numeroTarefasPorPagina
    let tarefas = firestore.collection(`empresas/${empresa.toLowerCase()}/tarefas`).orderBy('prazo').limit(limite*2)
    tarefas = tarefas.where('responsavel.identificador', '==', identificadorDoUsuario)

    const retorno = tarefas.onSnapshot(snapshot => {
      const docs = []

      snapshot.docs.forEach(doc => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        })
      })

      dispatch(atualizarTarefasDoUsuario(docs))
    })

    dispatch(registrouFirestoreDeTarefasDoUsuario())
    return retorno
  } catch (erro) {
    dispatch(erroAoRegistrarFirestoreDeTarefasDoUsuario(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o Firestore de tarefas do usuário.')))
  }
}