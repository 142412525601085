import {
  ADICIONOU_PROFISSAO,
  ALTEROU_ESPECIALIDADE,
  ALTEROU_PROFISSAO,
  LISTOU_ESPECIALIDADES,
  LISTOU_PROFISSOES,
  RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR,
  RECUPEROU_PROFISSAO_POR_IDENTIFICADOR,
  REMOVEU_PROFISSAO,
  LISTOU_PROFISSOES_DO_CADASTRO,
  LISTOU_PROFISSOES_MULTIDISCIPLINARES, RECUPEROU_PROFISSAO_MEDICA, LISTOU_PROFISSOES_PARA_TIMELINE_DO_PACIENTE
} from '../acoes/tipos'

let estadoInicial = {
  filtros: {
    termo: '',
    academica: ["Sim", "Não"]
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
  itens: [],
  profissoesMultidisciplinares: [],
  especialidadesMedicas: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_PROFISSAO_POR_IDENTIFICADOR:
    case ALTEROU_PROFISSAO:
    case ADICIONOU_PROFISSAO: {
      return {
        ...state,
        itemAtual: action.profissao,
      }
    }

    case REMOVEU_PROFISSAO: {
      return {
        ...state,
        itemAtual: null,
      }
    }

    case LISTOU_PROFISSOES: {
      return {
        ...state,
        itens: action.profissoes
      }
    }

    case LISTOU_PROFISSOES_PARA_TIMELINE_DO_PACIENTE: {
      return {
        ...state,
        itens: action.profissoes
      }
    }

    case LISTOU_PROFISSOES_MULTIDISCIPLINARES: {
      return {
        ...state,
        profissoesMultidisciplinares: action.profissoes
      }
    }

    case LISTOU_PROFISSOES_DO_CADASTRO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case RECUPEROU_PROFISSAO_MEDICA: {
      return {
        ...state,
        especialidadesMedicas: action.profissao.especialidades
      }
    }

    case LISTOU_ESPECIALIDADES: {
      const itemAtual = state.itemAtual ? { ...state.itemAtual } : null

      if (itemAtual) {
        itemAtual.especialidades = action.especialidades
      }

      return { ...state, itemAtual }
    }

    case RECUPEROU_ESPECIALIDADE_POR_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          especialidadeSelecionada: action.especialidade
        },
      }
    }

    case ALTEROU_ESPECIALIDADE: {
      delete state.itemAtual.especialidadeSelecionada

      return {
        ...state
      }
    }

    default: {
      return state
    }
  }
}