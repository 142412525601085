import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Redirect, useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import LacunaWebPKI from 'web-pki'
import ProvedorDeContextoDoProntuario from '../contexto/com-redux'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Lista from './lista'

export default function AtendimentosNaoAssinados(props) {
  const location = useLocation()
  const match = useRouteMatch()
  const history = useHistory()

  const parametros = useMemo(() => new URLSearchParams(location?.search), [location])
  const iniciarEmLote = parametros.get('iniciarEmLote')

  const instalarPlugin = location?.state?.instalarPlugin

  var pki = new LacunaWebPKI()

  useEffect(() => {
    if (instalarPlugin) {
      pki.redirectToInstallPage()
    }
  }, [instalarPlugin, pki])

  const stateAtendimentosNaoAssinados = useSelector(state => state.atendimentosNaoAssinados)
  const dadosDosAtendimentosNaoAssinados = useMemo(() => extrairDadosDoState(stateAtendimentosNaoAssinados), [stateAtendimentosNaoAssinados])

  if (instalarPlugin) return null

  return (
    <ProvedorDeContextoDoProntuario>
      <Switch>
        <RotaComAutenticacao
          exact
          path={`${match.path}`}
          render={
            props => (
              <Permissao permissoes={[permissoes.ASSINAR_ATENDIMENTO]}>
                <Lista
                  {...props}
                  paginaDeDados={dadosDosAtendimentosNaoAssinados.paginaDeDados}
                  ordenacao={dadosDosAtendimentosNaoAssinados.ordenacao}
                  iniciarEmLote={iniciarEmLote}
                  history={history}
                />
              </Permissao>
            )
          }
        />
        <Redirect exact from={match.path} to={`${match.path}`} />
      </Switch>
    </ProvedorDeContextoDoProntuario>
  )
}

const extrairDadosDoState = state => ({
  paginaDeDados: state.paginaDeDados,
  ordenacao: state.ordenacao,
})