import React, { useState, useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import avatar from '../../../design/img/no-thumb.png'
import Tags from '../cabecalho/tags'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import moment from 'moment'
import IndicadoresDoPaciente from '../../indicadores-do-paciente'
import ProximosAgendamentos from '../../agendamentos/proximos-agendamentos'
import { CalendarCheck, WhatsApp } from '../../../design/icones'

export default function Cabecalho(props) {
  const {
    identificador,
    cabecalho,
    recuperarFotoDoPacientePorIdentificador,
    recuperarFotoOriginalDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    adicionarTagNoPacienteDoProntuario,
    removerTagDoPacienteDoProntuario
  } = props

  const [exibirFoto, setExibirFoto] = useState(false)
  const paciente = cabecalho.identificacao

  useEffect(() => {
    if (paciente.identificadorDaFoto) {
      recuperarFotoDoPacientePorIdentificador(identificador, paciente.identificadorDaFoto)
    }

    return () => paciente.identificadorDaFoto && removerImagemDaMemoriaPorIdentificador(paciente.identificadorDaFoto)
  }, [paciente.identificadorDaFoto, identificador, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  function abrirFoto() {
    setExibirFoto(true)
  }

  function fecharFoto() {
    setExibirFoto(false)
  }

  function FotoDoPaciente({ foto, fechar, recuperarFotoOriginal }) {
    const [fotoOriginal, setFotoOriginal] = useState(false)

    useEffect(() => {
      const recuperar = async () => {
        const response = await recuperarFotoOriginal()
        setFotoOriginal(response)
      }

      exibirFoto && recuperarFotoOriginal && recuperar()

    }, [recuperarFotoOriginal])

    return (
      <div className='modal-foto'>
        <i className='icon icon-close modal-foto' onClick={fechar}></i>
        {!fotoOriginal && <img alt='foto' src={foto} />}
        {fotoOriginal && <img alt='foto' src={fotoOriginal} />}
      </div>
    )
  }

  const elemento = document.getElementsByClassName('cabecalho-planos-de-cuidado')
  const [exibirTooltip, setExibirTooltip] = useState(false)

  useEffect(() => {
    if (elemento.length > 0) {
      setExibirTooltip(elemento[0].offsetWidth < elemento[0].scrollWidth)
    }
  }, [elemento])

  const renderizarAlerta = mensagens => {
    return (
      <>
        {mensagens.map((x, index) => x.tipo === 'Alerta' ?
          <div className='form-choice-alerta' key={index}>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
          :
          <div className='form-choice-erro' key={index}>
            <div className='form-choice-erro__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-erro__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
        )}
        <hr className='separator mt-1 mb-1'></hr>
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFoto}
        onRequestClose={fecharFoto}
        className='estilo-do-modal-foto'
      >
        <FotoDoPaciente
          foto={paciente.foto}
          fechar={fecharFoto}
          recuperarFotoOriginal={() => recuperarFotoOriginalDoPacientePorIdentificador(identificador)}
        />
      </Modal>
      <div className='cabecalho-prontuario'>
      {paciente.precisaDeAcompanhamento &&  renderizarAlerta([{ tipo: 'Alerta', texto: 'O paciente precisa de acompanhamento o tempo todo.' }])}
        <div className='user-paciente-cabecalho'>        
          <div className='foto-prontuario'>
            <div className='foto-prontuario-images'>
              <div className='foto-prontuario-image'>
                <img src={paciente.foto || avatar} alt='foto-do-paciente' />
                {paciente.foto &&
                  <div className='foto-prontuario-image-tools'>
                    <button
                      className='foto-prontuario-image-zoom'
                      aria-label='Ampliar'
                      type='button'
                      onClick={abrirFoto}
                    >
                      <i className='icon icon-search'></i>
                    </button>
                  </div>
                }
              </div>
              <IndicadoresDoPaciente
                aniversario={paciente.aniversario}
                identificadorPublicoDoPaciente={paciente.identificadorPublico}
                obito={paciente.dataDoObito}
              />
            </div>
          </div>
          <div className='w-100'>
            <div className='tab'>
              <Link to={`/cadastros/pacientes/${identificador}`} target='_blank'>
                <p className='nome_paciente_prontuario'><strong>{paciente.nome}</strong></p>
              </Link>
              <div title='Copiar' style={{ cursor: 'pointer', fontSize: '150%' }} onClick={() => { navigator.clipboard.writeText(paciente.nome) }}><i className='icon icon-copy'></i></div>
            </div>
            <div className='user-paciente-cabecalho__info'>
              <div className='user-paciente-cabecalho__dados_pessoais'>
                <div>
                  {paciente.idade}{' '}anos{' | '}{paciente.sexo === 'M' ? 'Masculino' : 'Feminino'}<RenderizarTelefones telefones={paciente.telefones} />
                </div>
                {paciente.dataDeNascimento && <p><strong>Data de nascimento: </strong>{' '}{moment(paciente.dataDeNascimento).format("DD/MM/YYYY")}</p>}
                {paciente.nomeDaMae && <p><strong>Nome da mãe: </strong>{' '}{paciente.nomeDaMae}</p>}
              </div>
              <div className='user-paciente-cabecalho__dados_medicos'>
                {paciente.convenio && <p><strong>Convênio: </strong>{' '}{paciente.convenio}{' - '}{paciente.numeroDaCarteira}</p>}
                {paciente.programas && paciente.programas.length > 0 && <p><strong>Programa{paciente.programas.length > 1 ? 's' : ''}: </strong>{' '}{paciente.programas.join(', ')}</p>}
                {paciente.associacao && <p><strong>Associação: </strong>{' '}{paciente.associacao}</p>}
                {paciente.planosDeCuidado && paciente.planosDeCuidado.length > 0 &&
                  <div data-tip data-for='planos-de-cuidado'>
                    <p className='cabecalho-planos-de-cuidado'>
                      <strong>Plano{paciente.planosDeCuidado.length > 1 ? 's de Cuidado' : ' de Cuidado'}: </strong>
                      {' '}{paciente.planosDeCuidado.join(', ')}
                    </p>
                    {exibirTooltip &&
                      <ReactTooltip
                        id='planos-de-cuidado'
                        place='bottom'
                        effect='solid'
                        type='info'
                        className='tamanho-tooltip'
                        key={paciente.nome}
                      >
                        <span>{paciente.planosDeCuidado.join(', ')}</span>
                      </ReactTooltip>
                    }
                  </div>
                }
                {paciente.celula && <p><strong>Célula:</strong>{' '}{paciente.celula}</p>}
                {paciente.medicoDeReferencia && <p><strong>Médico(a):</strong>{' '}{paciente.medicoDeReferencia}</p>}
                {paciente.enfermeiroDeReferencia && <p><strong>Enfermeiro(a):</strong>{' '}{paciente.enfermeiroDeReferencia}</p>}
                {paciente.unidadePreferencial && <div><strong>Unidade Preferencial: </strong>{' '}{paciente.unidadePreferencial}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className='tag__info'>
          <Tags
            adicionarTag={adicionarTagNoPacienteDoProntuario}
            paciente={paciente}
            identificador={identificador}
            removerTag={removerTagDoPacienteDoProntuario}
          />
        </div>
        <ProximosAgendamentos paciente={identificador} />
      </div>
    </>
  )
}

function RenderizarTelefones({ telefones }) {
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const referenciaDoRelato = useRef(null)

  function ordenarTelefones(a, b) {
    return a.proprietario.localeCompare(b.proprietario)
  }

  useCliqueForaDoElemento(referenciaDoRelato, () => setExibirTooltipInfo(false))

  if (!telefones || telefones.length === 0) return null

  return (
    <>
      {' | '}
      <i className='icon icon-smartphone'
        onClick={() => setExibirTooltipInfo(true)}
        ref={referenciaDoRelato}
        data-for={'info_telefones_paciente'}
        style={{ cursor: 'pointer', fontSize: '90%' }}>
        <strong>Telefones</strong>
      </i>
      {exibirTooltipInfo &&
        <div className='tooltip__drop custom-scrollbar' style={{ left: '302px', top: '150px', width: 'auto', height: 'auto' }}>
          <div className='tooltip__drop__body'>
            <div className='tooltip__drop__title'>
              Telefones do paciente
              <button
                className='tooltip__drop__bt-close'
                type='button'
                onClick={() => setExibirTooltipInfo(false)}>
                <i className='icon-task icon-close'></i></button>
            </div>
            <div className='tooltip__drop__description'>
              {telefones.sort(ordenarTelefones).map((item, index) =>
                <React.Fragment key={index}>
                  <span>
                    <strong>
                      {item.proprietario}{' '}
                      {item.proprietario !== 'Paciente' && item.nome}
                    </strong>
                    {': '}
                    {item.whatsApp && <WhatsApp size={16}/>}
                    {' '}
                    {item.confirmacaoDeConsulta && <CalendarCheck size={16}/>}
                    {' '}
                    {item.numero}
                  </span>
                  <br />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}