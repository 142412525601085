import {
  ADICIONOU_TIPO_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO,
  RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  REMOVEU_TIPO_DE_AGENDAMENTO,  
  SALVOU_TIPO_DE_AGENDAMENTO,
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: '',
    ativo: ['Sim'],
    procedimento: ['Sim', 'Não'],
    modalidades: []
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_TIPO_DE_AGENDAMENTO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.tipoDeAgendamento
      }
    }

    case SALVOU_TIPO_DE_AGENDAMENTO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_TIPOS_DE_AGENDAMENTO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_TIPO_DE_AGENDAMENTO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}