import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  LISTAR_TIPOS_DE_EXAME,
  LISTOU_TIPOS_DE_EXAME,
  ERRO_AO_LISTAR_TIPOS_DE_EXAME
} from './tipos'

const fezDownloadDoExameDoAtendimento = (identificadorDoExame, bytesDoExame) => ({
  type: FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  identificadorDoExame,
  bytesDoExame,
})

const erroAoFazerDownloadDoExameDoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  erro,
  parametros
})

export const fazerDownloadDoExameDoAtendimento = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDoExame) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
    identificadorDoExame
  })

  try {
    const resultado = await api.fazerDownloadDaExameDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, identificadorDoExame)
    
    dispatch(fezDownloadDoExameDoAtendimento(identificadorDoExame, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoExameDoAtendimento(erro, { identificadorDoPaciente, identificadorDoAtendimento, identificadorDoExame }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do exame do atendimento.')))
    return null
  }
}

const listouTiposDeExame = tiposDeExame => ({
  type: LISTOU_TIPOS_DE_EXAME,
  tiposDeExame
})

const erroAoListarTiposDeExame = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_EXAME,
  erro
})

export const listarTiposDeExame = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_EXAME })

  try {
    const resultado = await api.listarTiposDeExame()

    dispatch(listouTiposDeExame(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeExame(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de exame.')))
  }
}