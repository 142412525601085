import React from 'react'
import {Aba, Abas, ListaDeAbas, Paineis, Painel} from '../../atendimento/abas'
import GraficoPeso from '../../componentes/peso/grafico'
import GraficoCreatinina from '../../componentes/creatinina/grafico-creatinina'
import GraficoTaxaDeFiltracaoGlomerular from '../../componentes/creatinina/grafico-taxa-de-filtracao-glomerular'
import GraficoGlicoHemoglobina from '../../componentes/glico-hemoglobina/grafico'
import ResumoDeExames from '../../componentes/exames/resumo'

export default function Questionarios(props) {
  const {
    identificadorDoPaciente,
    historicoDeCampos: {
      peso,
      creatinina,
      glico_hemoglobina,
      exames
    },
  } = props

  return (
    <div className='form gestao-de-condicoes-clinicas'>
      <fieldset>
        <Abas>
          <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
            <r-cell span={4} span-md={6} span-lg={12}>
              <ListaDeAbas className='tab'>
                <Aba>Peso</Aba>
                <Aba>Creatinina / TFG</Aba>
                <Aba title='Hemoglobina Glicada'>HbA1C</Aba>
                <Aba>Exames Anexados</Aba>
              </ListaDeAbas>
            </r-cell>
          </r-grid>
          <Paineis>
            <Painel key='peso'>
              <r-grid columns-md={6} columns-lg={12}>
                <r-cell span={4} span-md={6} span-lg={12}>
                  <GraficoPeso historico={peso ? peso : []} exibirMensagem={true} />
                </r-cell>
              </r-grid>
            </Painel>
            <Painel key='creatinina'>
              <r-grid columns-md={6} columns-lg={12}>
                <r-cell span={4} span-md={6} span-lg={12}>
                  <GraficoCreatinina historico={creatinina ? creatinina : []} exibirMensagem={true} />
                  <GraficoTaxaDeFiltracaoGlomerular historico={creatinina ? creatinina : []} exibirMensagem={false} />
                </r-cell>
              </r-grid>
            </Painel>
            <Painel key='glico_hemoglobina'>
              <r-grid columns-md={6} columns-lg={12}>
                <r-cell span={4} span-md={6} span-lg={12}>
                  <GraficoGlicoHemoglobina historico={glico_hemoglobina ? glico_hemoglobina : []} exibirMensagem={true} />
                </r-cell>
              </r-grid>
            </Painel>
            <Painel key='exames'><ResumoDeExames valor={exames ? exames : []} identificadorDoPaciente={identificadorDoPaciente} ativaSpinner={true} /></Painel>
          </Paineis>
        </Abas>
      </fieldset>
    </div>
  )
}