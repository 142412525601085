import React, {useEffect} from 'react'
import Agradecimento from './agradecimento'
import Avaliacao from './avaliacao'
import logoIcone from '../../design/img/logomarca_icone.png'

export default function Nps(props) {
  const {
    avaliacao,
    responderAvaliacao,
    recuperarAvaliacao,
    location,
    match,
    history,
    recuperarFotoDaEmpresaPorIdentificadorPublico,
    removerImagemDaMemoriaPorIdentificador,
    imagens
  } = props

  const { identificadorDaEmpresa, identificadorDaAvaliacao } = match.params
  const ehAgradecimento = window.location.href.split('/').includes('agradecimento')

  useEffect(() => {
    recuperarFotoDaEmpresaPorIdentificadorPublico(identificadorDaEmpresa)
    return () => removerImagemDaMemoriaPorIdentificador(identificadorDaEmpresa)
  }, [identificadorDaEmpresa, recuperarFotoDaEmpresaPorIdentificadorPublico, removerImagemDaMemoriaPorIdentificador])

  const buscaLogotipo = () => {
    const logotipo = imagens[identificadorDaEmpresa]
    if (logotipo) return logotipo
    return logoIcone
  }

  return (
    <>
      {ehAgradecimento ?
        (() => (
          <Agradecimento
            recuperarAvaliacao={recuperarAvaliacao}
            avaliacao={avaliacao}
            match={match}
            logotipoDaEmpresa={buscaLogotipo()}
          />
        ))() :
        (() => (
          <Avaliacao
            recuperarAvaliacao={recuperarAvaliacao}
            avaliacao={avaliacao}
            responderAvaliacao={responderAvaliacao}
            identificadorDaEmpresa={identificadorDaEmpresa}
            identificadorDaAvaliacao={identificadorDaAvaliacao}
            location={location}
            history={history}
            logotipoDaEmpresa={buscaLogotipo()}
          />
        ))()
      }
    </>
  )
}