import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
} from '../tipos'

const fezDownloadDoArquivoDeVideoConsulta = (identificadorDoAtendimento, arquivo) => ({
  type: FEZ_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  identificadorDoAtendimento,
  arquivo,
})

const erroAoFazerDownloadDoArquivoDeVideoConsulta = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA,
  erro,
  parametros
})

export const fazerDownloadDoArquivoDeVideoConsulta = (atendimento, identificadorDoArquivo) => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DO_ARQUIVO_DE_VIDEO_CONSULTA, identificadorDoAtendimento: atendimento.identificador })

  try {
    const resultado = await api.fazerDownloadDoArquivoDeVideoConsulta(atendimento.paciente.identificador, atendimento.identificador, identificadorDoArquivo)
    dispatch(fezDownloadDoArquivoDeVideoConsulta(atendimento.paciente.identificador, atendimento.identificador, identificadorDoArquivo))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoArquivoDeVideoConsulta(erro, { atendimento, identificadorDoArquivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do arquivo de video consulta.')))
    return false
  }
}