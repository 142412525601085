import { converterParaUrl } from '../../bibliotecas/imagem'
import { FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO } from '../../acoes/tipos'

export default (state = null, action) => {
  switch (action.type) {
    case FEZ_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO: {
      return {
        ...state,
        [action.identificadorDoExame]: {
          url: converterParaUrl(action.bytesDoExame),
        }
      }
    }

    default: {
      return state
    }
  }
}