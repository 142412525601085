import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
 ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
 LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
 LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
 ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE
} from '../../tipos'

const listouRegistrosDocumentaisDoPsicologoDoPaciente = (identificadorDoPaciente, registrosDocumentais) => ({
  type: LISTOU_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  identificadorDoPaciente,
  registrosDocumentais
})

const erroAoListarRegistrosDocumentaisDoPsicologoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  erro,
  parametros
})

export const listarRegistrosDocumentaisDoPsicologoDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE })

  try {
    const resultado = await api.listarRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente)

    dispatch(listouRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRegistrosDocumentaisDoPsicologoDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os registros documentais do psicólogo do paciente.')))
  }
}

const adicionouRegistroDocumentalDoPsicologoDoPaciente = registroDocumental => ({
  type: ADICIONOU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  registroDocumental
})

const erroAoAdicionarRegistroDocumentalDoPsicologoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarRegistroDocumentalDoPsicologoDoPaciente = (identificadorDoPaciente, registroDocumental) => async dispatch => {
  dispatch({ type: ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarRegistroDocumentalDoPsicologoDoPaciente(identificadorDoPaciente, registroDocumental)

    dispatch(adicionouRegistroDocumentalDoPsicologoDoPaciente(resultado.data.dados))
    dispatch(listarRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarRegistroDocumentalDoPsicologoDoPaciente(erro, { registroDocumental  }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar o registro documental do psicólogo do paciente.')))

    return false
  }
}

const alterouRegistroDocumentalDoPsicologoDoPaciente = registroDocumental => ({
  type: ALTEROU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  registroDocumental
})

const erroAoAlterarRegistroDocumentalDoPsicologoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarRegistroDocumentalDoPsicologoDoPaciente = (identificadorDoPaciente, registroDocumental) => async dispatch => {
  dispatch({ type: ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE })

  try {
    const resultado = await api.alterarRegistroDocumentalDoPsicologoDoPaciente(identificadorDoPaciente, registroDocumental)

    dispatch(alterouRegistroDocumentalDoPsicologoDoPaciente(resultado.data.dados))
    dispatch(listarRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarRegistroDocumentalDoPsicologoDoPaciente(erro, { registroDocumental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o registro documental do psicólogo do paciente.')))

    return false
  }
}

const removeuRegistroDocumentalDoPsicologoDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverRegistroDocumentalDoPsicologoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  erro,
  parametros
})

export const removerRegistroDocumentalDoPsicologoDoPaciente = (identificadorDoPaciente, identificadorDoRegistroDocumentalDoPsicologo) => async dispatch => {
  dispatch({ type: REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE })

  try {
    const resultado = await api.removerRegistroDocumentalDoPsicologoDoPaciente(identificadorDoPaciente, identificadorDoRegistroDocumentalDoPsicologo)

    dispatch(removeuRegistroDocumentalDoPsicologoDoPaciente(identificadorDoPaciente))
    dispatch(listarRegistrosDocumentaisDoPsicologoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverRegistroDocumentalDoPsicologoDoPaciente(erro, { identificadorDoPaciente, identificadorDoRegistroDocumentalDoPsicologo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover registro documental do psicólogo do paciente.')))

    return false
  }
}