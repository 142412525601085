import React, { useEffect, useState } from 'react'
import { DatePicker, CampoNumerico } from '../../../formik/formulario'
import GraficoPopup from '../grafico-popup'
import GraficoCreatinina from './grafico-creatinina'
import GraficoTaxaDeFiltracaoGlomerular from './grafico-taxa-de-filtracao-glomerular'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import moment from 'moment'

export default function Creatinina({ metadados, historico = [], valor, leitura, exibirDataExame, dataExame, setFieldValue, paciente }) {
  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame
  const [taxaDeFiltracaoGlomerular, setTaxaDeFiltracaoGlomerular] = useState(null)

  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)
  const sexo = paciente?.sexo
  const dataDeNascimento = paciente?.dataDeNascimento
  const idade = calcularIdade(dataDeNascimento, dataExame)

  useEffect(() => {
    if (exibirDataExame && valor.valor) {
      setFieldValue('creatinina.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  useEffect(() => {
    if (sexo && idade && valor.valor) {
      setTaxaDeFiltracaoGlomerular(calcularTaxaDeFiltracaoGlomerular(valor.valor, idade, sexo))
    } else {
      setTaxaDeFiltracaoGlomerular(null)
    }
  }, [sexo, idade, valor])


  const validarCreatinina = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 0.1, 30) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 0.1, 30)
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Creatinina: </strong>
            {valor.valor.toString().replace('.', ',')} mg/dL no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 2
    return 4
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='creatinina.valor'
            titulo={`Creatinina ${obrigatorio ? '*' : ''}`}
            escala='mg/dL'
            numeroDeCasasDecimais={2}
            validar={validarCreatinina}
            informacaoAdicional={taxaDeFiltracaoGlomerular ? { label: 'TFG', valor: taxaDeFiltracaoGlomerular + ' mL/min/1.73m²' } : null}
          >
            {historico.length > 0 &&
              <GraficoPopup>
                <GraficoCreatinina historico={historico} />
                <GraficoTaxaDeFiltracaoGlomerular historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='creatinina.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}

function calcularIdade(dataDeNascimento, dataAtual) {
  const dataNascimento = new Date(dataDeNascimento)
  const hoje = dataAtual ? new Date(dataAtual) : new Date()

  const idade = hoje.getFullYear() - dataNascimento.getFullYear()
  const mesAtual = hoje.getMonth() + 1
  const mesNascimento = dataNascimento.getMonth() + 1

  if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < dataNascimento.getDate())) {
    return idade - 1
  }

  return idade
}

function calcularTaxaDeFiltracaoGlomerular(creatinina, idade, sexo) {
  if (!creatinina) return null  

  creatinina = parseFloat(creatinina)
  idade = parseInt(idade)

  let a, b, fatorDeMultiplicacao

  if (creatinina < 0.1 || creatinina > 30) return null

  if (sexo === 'F') {
    a = 0.7
    fatorDeMultiplicacao = 1.012

    if (creatinina <= 0.7) {
      b = -0.241
    } else {
      b = -1.2
    }
  } else {
    a = 0.9
    fatorDeMultiplicacao = 1.0

    if (creatinina <= 0.9) {
      b = -0.302
    } else {
      b = -1.2
    }
  }

  const resultado = 142 * Math.pow(creatinina / a, b) * Math.pow(0.9938, idade) * fatorDeMultiplicacao
  return resultado.toFixed(2)
}