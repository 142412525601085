import React, { useState } from 'react'
import Modal from 'react-modal'
import { GraphUp } from '../../../design/icones'
import './style.css'

export default function GraficoPopup({ titulo, children }) {
  const [exibirGrafico, setExibirGrafico] = useState(false)

  function abrirGrafico() {
    setExibirGrafico(true)
  }

  function fecharGrafico() {
    setExibirGrafico(false)
  }

  return (
    <>
      <div className='botao-grafico-prontuario' onClick={() => abrirGrafico()}>
        <GraphUp size='16' />
      </div>
      <Modal
        isOpen={exibirGrafico}
        className='modal-grafico-prontuario'
        contentLabel='Modal para gráfico do prontuario'
      >
        <React.Fragment>
          <label className='titulo-grafico-prontuario'>{titulo}</label>
          <button className='fechar-grafico-prontuario' onClick={fecharGrafico}>x</button>
          {children}
        </React.Fragment>
      </Modal>
    </>
  )
}