import {
  LISTAR_ANTIBIOTICOS,
  LISTAR_DURACOES_DAS_DORES,
  LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
  LISTAR_FABRICANTES,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_INTENSIDADES_DAS_DORES,
  LISTAR_LOCAIS_DA_APLICACAO,
  LISTAR_LOCAIS_DAS_DORES,
  LISTAR_LOCAIS_DE_QUEDAS,
  LISTAR_MICRORGANISMOS,
  LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
  LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  LISTAR_POSOLOGIAS,
  LISTAR_SEXOS,
  LISTAR_TAMANHOS_DE_PAGINAS,
  LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  LISTAR_TIPOS_DE_INTERNACAO,
  LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  LISTAR_TRATAMENTOS,
  LISTAR_UNIDADES_FEDERATIVAS,
  LISTAR_VACINAS,
  LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS
} from '../acoes/tipos'

export const verificarSeAcaoPossuiCache = store => next => action => {
  const { meta: { cache = null } = {} } = action

  if (cache && estahEmCache[cache] && estahEmCache[cache](store.getState())) {
    return Promise.resolve()
  }

  return next(action)
}

const estahEmCache = {
  [LISTAR_TIPOS_DE_INTERNACAO]: state => state.tiposDeInternacao.length > 0,
  [LISTAR_ANTIBIOTICOS]: state => state.antibioticos.length > 0,
  [LISTAR_LOCAIS_DE_QUEDAS]: state => Object.keys(state.locaisDeQuedas.itens).length > 0,
  [LISTAR_MICRORGANISMOS]: state => state.microrganismos.length > 0,
  [LISTAR_NIVEIS_DE_SAUDE_RELATIVA]: state => state.niveisDeSaudeRelativa.length > 0,
  [LISTAR_POSOLOGIAS]: state => state.medicamentosDoProntuario.posologias.length > 0,
  [LISTAR_VACINAS]: state => Object.keys(state.vacinas.itens).length > 0,
  [LISTAR_FABRICANTES]: state => Object.keys(state.fabricantes.itens).length > 0,
  [LISTAR_LOCAIS_DA_APLICACAO]: state => Object.keys(state.locaisDaAplicacao.itens).length > 0,
  [LISTAR_VIAS_DE_ADMINISTRACAO_DE_MEDICAMENTOS]: state => Object.keys(state.viasDeAdministracaoDeMedicamentos.itens).length > 0,
  [LISTAR_TAMANHOS_DE_PAGINAS]: state => state.tamanhosDePaginas.length > 0,
  [LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS]: state => state.motivosDeCancelamentoDosAgendamentos.length > 0,
  [LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA]: state => Object.keys(state.motivosDeBloqueioDaAgenda.itens).length > 0,
  [LISTAR_SEXOS]: state => state.sexos.length > 0,
  [LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA]: state => state.tiposDeRepeticaoDeSerieDaAgenda.length > 0,
  [LISTAR_TRATAMENTOS]: state => state.tratamentos.length > 0,
  [LISTAR_UNIDADES_FEDERATIVAS]: state => state.unidadesFederativas.length > 0,
  [LISTAR_GRAUS_DE_RELACOES]: state => state.grausDeRelacoes.length > 0,
  [LISTAR_DURACOES_DAS_DORES]: state => state.duracoesDasDores.length > 0,
  [LISTAR_INTENSIDADES_DAS_DORES]: state => state.intensidadesDasDores.length > 0,
  [LISTAR_LOCAIS_DAS_DORES]: state => state.locaisDasDores.length > 0,
  [LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER]: state => state.tiposDeAtividadeFisicaDeLazer.length > 0,
  [LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA]: state => state.execucoesDoTesteDeFisioterapia.length > 0,
}