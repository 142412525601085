import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import constantes from '../../configuracoes/constantes'
import { ToastContainer, toast } from 'react-toastify'
import { adicionarPacienteSemPrograma, adicionarProximosAgendamentosDoAgendamento } from '../../acoes/agendamentos/agendamento'
import NotificacaoAgendamentoParaOutraUnidade from './agendamento-para-outra-unidade/com-redux'
import NotificacaoAtendimentoParaOutraUnidade from './atendimento-para-outra-unidade/com-redux'
import NotificacaoAtendimentoSemAgendamento from './atendimento-sem-agendamento'
import NotificacaoAtendimentoPrivado from './atendimento-privado'
import NotificacaoAtendimentoAposOObito from './atendimeto-apos-obito'
import NotificacaoPacienteSaiuDoProgramaComAgendamento from './paciente-saiu-do-programa-com-agendamento'
import NotificacaoSessaoAssinaturaEmNuvemNaoEncontrada from './sessao-assinatura-em-nuvem-nao-encontrada'
import NotificacaoProfissionalPossuiAgendamentosFuturos from './profissional-inativado-possui-agendamentos-futuros/index'

const { ALERTA, ALERTA_MINIMO, ERRO, INFORMACAO, LOG, SUCESSO } = constantes.notificacoes
const AGENDADO_PARA_OUTRA_UNIDADE = 'AGENDADO_PARA_OUTRA_UNIDADE'
const ATENDIMENTO_INICIADO_EM_OUTRA_UNIDADE = 'ATENDIMENTO_INICIADO_EM_OUTRA_UNIDADE'
const ATENDIMENTO_INICIADO_SEM_AGENDAMENTO = 'ATENDIMENTO_INICIADO_SEM_AGENDAMENTO'
const ATENDIMENTO_PRIVADO = 'ATENDIMENTO_PRIVADO'
const ATENDIMENTO_APOS_O_OBITO = 'ATENDIMENTO_APOS_O_OBITO'
const AGENDAMENTOS_NOS_PROXIMOS_MESES = 'AGENDAMENTOS_NOS_PROXIMOS_MESES'
const PACIENTE_SAIU_DO_PROGRAMA_MAS_POSSUI_AGENDAMENTOS_FUTUROS = 'PACIENTE_SAIU_DO_PROGRAMA_MAS_POSSUI_AGENDAMENTOS_FUTUROS'
const AGENDAMENTO_PARA_PACIENTE_SEM_PROGRAMA = 'AGENDAMENTO_PARA_PACIENTE_SEM_PROGRAMA'
const SESSAO_DO_CERTIFICADO_EM_NUVEM_NAO_ENCONTRADA = 'SESSAO_DO_CERTIFICADO_EM_NUVEM_NAO_ENCONTRADA'
const PROFISSIONAL_INATIVADO_COM_AGENDAMENTOS_FUTUROS = 'PROFISSIONAL_INATIVADO_COM_AGENDAMENTOS_FUTUROS'

export default function Notificacao(props) {
  const {
    history,
    notificacoes,
    removerNotificacoes,
  } = props

  const dispatch = useDispatch()
  const [popupDeNotificacao, setPopupDeNotificacao] = useState(undefined)

  const exibirNotificacaoes = useCallback(notificacoes => {
    notificacoes.forEach(notificacao => {
      const tipo = recuperarTipo(notificacao)

      if (tipo === 'log') return

      if (notificacao.metaDados) {
        switch (notificacao.metaDados.tipo) {

          case AGENDADO_PARA_OUTRA_UNIDADE:
            toast(({ closeToast }) =>
              <NotificacaoAgendamentoParaOutraUnidade
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />
            )

            break

          case AGENDAMENTOS_NOS_PROXIMOS_MESES:
            dispatch(adicionarProximosAgendamentosDoAgendamento(notificacao.metaDados))

            break

          case ATENDIMENTO_INICIADO_EM_OUTRA_UNIDADE:
            toast(({ closeToast }) =>
              <NotificacaoAtendimentoParaOutraUnidade
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />
            )

            break

          case ATENDIMENTO_INICIADO_SEM_AGENDAMENTO:
            toast(({ closeToast }) =>
              <NotificacaoAtendimentoSemAgendamento
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />,
              {
                autoClose: 15000,
              }
            )

            break

          case ATENDIMENTO_PRIVADO:
            toast(({ closeToast }) =>
              <NotificacaoAtendimentoPrivado
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />
            )

            break

          case ATENDIMENTO_APOS_O_OBITO:
            setPopupDeNotificacao(<NotificacaoAtendimentoAposOObito notificacao={notificacao} />)

            break

            case PROFISSIONAL_INATIVADO_COM_AGENDAMENTOS_FUTUROS:
                setPopupDeNotificacao(<NotificacaoProfissionalPossuiAgendamentosFuturos notificacao={notificacao} history={history} />)

            break

          case PACIENTE_SAIU_DO_PROGRAMA_MAS_POSSUI_AGENDAMENTOS_FUTUROS:
            toast(({ closeToast }) =>
              <NotificacaoPacienteSaiuDoProgramaComAgendamento
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />,
              {
                autoClose: 15000,
              }
            )

            break

          case AGENDAMENTO_PARA_PACIENTE_SEM_PROGRAMA:
            dispatch(adicionarPacienteSemPrograma(notificacao.metaDados))

            break

          case SESSAO_DO_CERTIFICADO_EM_NUVEM_NAO_ENCONTRADA:
            toast(({ closeToast }) =>
              <NotificacaoSessaoAssinaturaEmNuvemNaoEncontrada
                fechar={closeToast}
                notificacao={notificacao}
                history={history}
              />
            )

            break

          default:
            break
        }
      }
      else {
        const configuracoes = {
          toastId: notificacao.texto,
          type: tipo,
          autoClose: notificacao.timeout * 1000,
          delay: false,
        }

        if (!toast.isActive(notificacao.texto)) {
          toast(notificacao.texto, configuracoes)
        } else {
          toast.update(notificacao.texto, configuracoes)
        }
      }
    })
  }, [dispatch, history])

  useEffect(() => {
    exibirNotificacaoes(notificacoes)

    if (notificacoes.length > 0) {
      removerNotificacoes(notificacoes)
    }
  }, [notificacoes, exibirNotificacaoes, removerNotificacoes])

  return (
    <>
      <ToastContainer />
      {popupDeNotificacao}
    </>
  )
}

const recuperarTipo = notificacao => {
  switch (notificacao.tipo) {
    case ALERTA: {
      return toast.TYPE.WARNING
    }

    case ALERTA_MINIMO: {
      return toast.TYPE.DEFAULT
    }

    case ERRO: {
      return toast.TYPE.ERROR
    }

    case LOG: {
      return 'log'
    }

    case INFORMACAO: {
      return toast.TYPE.INFO
    }

    case SUCESSO: {
      return toast.TYPE.SUCCESS
    }

    default: {
      return toast.TYPE.INFO
    }
  }
}