import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import CampoDePesquisa from '../campo-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import { LISTAR_CELULAS_DO_CADASTRO } from '../../acoes/tipos'

export default function Lista(props) {
    const {
        paginaDeDados,
        pesquisar
    } = props

    const [ordenacao, setOrdenacao] = useState(props.ordenacao)
    const [pagina, setPagina] = useState(paginaDeDados.pagina)
    const [filtros, setFiltros] = useState(props.filtros)

    useEffect(() => {
        (async () => await pesquisar(filtros, pagina, ordenacao, 10))()
    }, [filtros, pesquisar, pagina, ordenacao])

    const listar = (numeroDaPagina, ordenacao) => {
        setPagina(numeroDaPagina)
        setOrdenacao(ordenacao)
    }

    const alterarFiltros = filtros => {
        if (filtros) {
            setFiltros(filtros)
            setPagina(1)
        }
    }

    const alterarTermo = nome => alterarFiltros({ ...filtros, nome })

    const alterarFiltrosDaTabela = filtro => {
        alterarFiltros({
            ...filtros,
            [filtro.nome]: filtro.valor
        })
    }

    return (
        <div className='page-configuracoes-agendamento'>
            <div className='container'>
                <div className='main-content-form'>
                    <div className='header-page-form'>
                        <Breadcrumb />
                        <div className='header-page-tools'>
                            <Permissao permissoes={[permissoes.ADICIONAR_CELULA]}>
                                <Link
                                    to={`/cadastros/celulas/novo`}
                                    className='button --primary --plus'
                                >
                                    Adicionar
                                </Link>
                            </Permissao>
                            <CampoDePesquisa
                                pesquisar={alterarTermo}
                                valor={filtros ? filtros.nome : ''}
                            />
                        </div>
                    </div>
                    <Spinner operacoes={[LISTAR_CELULAS_DO_CADASTRO]}>
                        <Tabela
                            acoes={[
                                item => (
                                    <Permissao key='editar' permissoes={[permissoes.ALTERAR_CELULA]}>
                                        <Link
                                            to={`/cadastros/celulas/${item.identificador}`}
                                            className='table-items__bt --edit'
                                            title='Editar'
                                        >
                                            <i className='icon icon-pencil'></i>
                                        </Link>
                                    </Permissao>
                                )
                            ]}
                            alterarFiltros={alterarFiltrosDaTabela}
                            chave='identificador'
                            className='mt-1 mb-4'
                            filtros={filtros}
                            listar={listar}
                            ordenacao={ordenacao}
                            paginaDeDados={paginaDeDados}
                        >
                            <Coluna
                                campoDeDados='nome'
                                className='col-xs-8 col-md-10 col-lg-6'
                                exibirOrdenacao={true}
                                principal={true}
                                nome='Nome'
                            />
                            <Coluna
                                campoDeDados='observacoes'
                                className='col-xs-6 col-lg-5'
                                nome='Observações'
                            />
                        </Tabela>
                    </Spinner>
                </div>
            </div>
        </div>
    )
}