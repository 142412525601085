import React, { useEffect } from 'react'
import * as Yup from 'yup'
import Spinner from '../spinner'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../formik/formulario'
import { confirmAlert } from 'react-confirm-alert'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {
  ADICIONAR_MEDICAMENTO,
  ALTERAR_MEDICAMENTO,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  REMOVER_MEDICAMENTO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    alterarStatusDoMedicamento,
    match,
    history,
    medicamento = {},
    recuperarPorIdentificador,
    remover,
    salvar
  } = props

  const { identificador } = match.params
  const exibirAtivar = usePossuiAsPermissoes([permissoes.ALTERAR_MEDICAMENTO]) && !medicamento.ativo && identificador
  const exibirDesativar = usePossuiAsPermissoes([permissoes.ALTERAR_MEDICAMENTO]) && medicamento.ativo && identificador
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_MEDICAMENTO])
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_MEDICAMENTO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_MEDICAMENTO])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/medicamentos`)
    }
  }

  const confirmarEAtivar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja ativar o medicamento ${medicamento.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => alterarStatusDoMedicamento(true)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEDesativar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja desativar o medicamento ${medicamento.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => alterarStatusDoMedicamento(false)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o medicamento ${medicamento.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(medicamento)
      }, {
        label: 'Não'
      }]
    })
  }

  return (            
    <Spinner operacoes={[RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR, ADICIONAR_MEDICAMENTO, ALTERAR_MEDICAMENTO, REMOVER_MEDICAMENTO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificador: medicamento.identificador || '',
          ativo: medicamento.ativo || '',
          nome: medicamento.nome || '',
          observacoes: medicamento.observacoes || '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset className='mt-1'>
          <h2 className="form-title">Dados Gerais</h2>
          <r-grid columns-md={12} columns-lg={12}>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                type='text'
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            {identificador &&
              <r-cell span={4} span-md={12} span-lg={12}>
                <Toglle
                  nome='ativo'
                  tabIndex={2}
                  titulo='Ativo'
                  disabled={true}
                />
              </r-cell>
            }
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={3}
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
        </fieldset>
        {remover ?
          <div className='list-btn-unidades'>
            {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
            {exibirDesativar && <button onClick={confirmarEDesativar} type='button' className='button --danger-light'>Desativar</button>}
            {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
            {exibirAtivar && <button onClick={confirmarEAtivar} type='button' className='button --light'>Ativar</button>}
            <button onClick={() => history.push(`/cadastros/medicamentos`)} type='button' className='button --light'>Voltar</button>
          </div>
          :
          <div className='list-btn'>
            <button onClick={() => history.push(`/cadastros/medicamentos`)} type='button' className='button --light'>Voltar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        }
      </FormularioDoFormik>
    </Spinner>      
  )
}