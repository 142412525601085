import React, { useEffect, useRef } from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import chroma from 'chroma-js'
import { filtroPadrao } from '../../bibliotecas/texto'

export default function StylesSelect(props) {
  const selectRef = useRef()

  useEffect(() => {
    const valorAtual = props.value
    const valorNoSelect = selectRef.current && selectRef.current.state.value

    if (!valorAtual && valorNoSelect) {
      selectRef.current.select.setValue(valorAtual)
    }
  }, [props.value])

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  })

  const colourStylesDefault = {
    option: (styles, { data, isFocused, isSelected }) => {
      const color = props.selectColor && chroma(data.color)
      return {
        ...styles,
        fontFamily: 'Roboto',
        fontSize: '14px',
        zIndex: 5,
        backgroundColor: props.selectColor ? (isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null) : styles.backgroundColor,
        color: props.selectColor ? (isSelected ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black' : data.color) : styles.color
      }
    },
    control: styles => {
      return {
        ...styles,
        backgroundColor: props.isValid ? '#fdecec' : props.isDisabled ? '#cfcfcf' : props.isWarning ? '#fafdec' : '#fff',
        color:  props.isDisabled ? '#999' : '#000',
        border: props.isValid ? 'solid 1px #e74c3c' : props.isWarning ? '1px solid #ffc107' : 'solid 1px #d3d8dc',
        borderRadius: '5px',
        minHeight: '48px',
        fontSize: '14px'
      }
    },
    input: styles => props.selectColor && props.value && ({ ...styles, ...dot() }),
    placeholder: styles => props.selectColor && props.value && ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => props.selectColor && props.value && ({ ...styles, ...dot(data.color) }),
    clearIndicator: styles => {
      return {
        ...styles,
        color: '#528ce3'
      }
    },
    indicatorSeparator: () => {
      return {
        border: 'none'
      }
    },
    dropdownIndicator: styles => {
      return {
        ...styles,
        color: '#528ce3',
        ':hover': {
          color: 'none'
        }
      }
    },
    multiValue: styles => {
      const color = chroma('rgb(97,97,97)')
      return {
        ...styles,
        backgroundColor: color.alpha(0.2).css(),
        borderRadius: '5px',
        padding: '6px'
      }
    },
    multiValueLabel: styles => ({
      ...styles,
      color: '#393042',
      padding: '0px',
      paddingLeft: '0px',
      fontFamily: 'Roboto'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#616161',
      backgroundColor: '#fff',
      width: '16px',
      height: '16px',
      borderRadius: '50px',
      fontSize: '20px',
      marginLeft: '15px',
      ':hover': {
        backgroundColor: '#616161',
        color: '#fff'
      }
    }),
    menuPortal: styles => ({
      ...styles,
      zIndex: 999
    })
  }

  return props.criador ? (
    <CreatableSelect
      {...props}
      formatCreateLabel={userInput => `Criar opção para '${userInput}'`}
      loadingMessage={() => 'Pesquisando...'}
      noOptionsMessage={() => 'Nenhum registro encontrado'}
      styles={props.colourStyles ? props.colourStyles : colourStylesDefault}
    />) : (
    <ReactSelect
      {...props}
      ref={selectRef}
      styles={props.colourStyles ? props.colourStyles : colourStylesDefault}
      loadingMessage={() => 'Pesquisando...'}
      noOptionsMessage={() => props.noOptionsMessage ? props.noOptionsMessage : 'Nenhum registro encontrado'}
    />
  )
}

export function SelectReact({ nome, valor, options = null, isMulti = false, closeMenuOnSelect = true, ...rest }) {
  return (
    <SelectComponent
      {...rest}
      options={options}
      name={nome}
      value={valor}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  )
}

function SelectComponent(props) {
  const { name, value, options = [], placeholder = '', selectColor = false, isMulti, ...rest } = props

  const filter = rest.filterOption ? rest.filterOption : (option, rawInput) => filtroPadrao(rawInput, option.label)

  const recuperarValorDoSelect = () => {
    if (isMulti) {
      return options.filter(x => value && value.some(item => item === x.value))
    } else if (rest.value) {
      return rest.value
    } else if (value) {
      if (rest.formatGroupLabel) {
        return options.map(x => x.options.find(y => y.value === value))
      }

      return options.find(x => x.value === value)
    }

    return ''
  }

  const valorPadrao = isMulti ? [] : ''
  const valor = recuperarValorDoSelect()

  const onBlur = evento => {
    rest.onBlur && rest.onBlur(evento)
  }

  const onChange = (option, evento) => {
    const valorAlterado = isMulti ? (option ? option.map(x => x.value) : valorPadrao) : (option ? option.value : valorPadrao)
    rest.onChange && rest.onChange(valorAlterado)
  }

  return (
    <StylesSelect
      {...rest}
      {...props.field}
      criador={false}
      filterOption={filter}
      key={`${name}`}
      options={options}
      value={valor}
      onChange={onChange}
      isClearable={true}
      isMulti={isMulti}
      placeholder={placeholder}
      onBlur={onBlur}
      formatCreateLabel={userInput => `Criar opção para '${userInput}'`}
      noOptionsMessage={rest.noOptionsMessage}
      selectColor={selectColor}
    />
  )
}