import React from 'react'
import SpinnerDaTarefa from './spinner-da-tarefa'
import CampoDaTarefa from './campo-da-tarefa'
import {ComponenteLeitura} from '../card'
import {FileText} from '../../../design/icones'

export default function Texto({ rotulo, obrigatorio, copiar, valor, processando, leitura, definir }) {
  const icone = <FileText/>

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        valor={valor}
        renderizar={() =>
          <div className='valor tab'>
            <p>{valor}</p>
            {copiar && <button title='Copiar' className='botao-copiar' style={{ top: '-4px', marginLeft: '4px' }} type='button' onClick={() => { navigator.clipboard.writeText(valor) }}><i className='icon icon-copy'></i></button>}
          </div>
        }
      />
    )
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valor}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <>
          <input
            value={valor || ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
          />
          <SpinnerDaTarefa processando={processando}/>
        </>
      }
    </CampoDaTarefa>
  )
}