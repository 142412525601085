import {
  LISTOU_ORIENTACOES,
  ADICIONOU_ORIENTACAO,
  RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR,
  ALTEROU_ORIENTACAO,
  REMOVEU_ORIENTACAO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: ''
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_ORIENTACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.orientacao
      }
    }

    case ALTEROU_ORIENTACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_ORIENTACOES: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        itemAtual: null,
        filtros,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_ORIENTACAO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}