import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_FUSOS_HORARIOS, LISTOU_FUSOS_HORARIOS, ERRO_AO_LISTAR_FUSOS_HORARIOS } from './tipos'

const listouFusosHorarios = fusosHorarios => ({
  type: LISTOU_FUSOS_HORARIOS,
  fusosHorarios
})

const erroAoListarFusosHorarios = erro => ({
  type: ERRO_AO_LISTAR_FUSOS_HORARIOS,
  erro
})

export const listarFusosHorarios = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_FUSOS_HORARIOS })

    try {
      const resultado = await api.listarFusosHorarios()

      dispatch(listouFusosHorarios(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarFusosHorarios(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os fusos horários.')))
    }
  }

  acao.meta = {
    cache: LISTAR_FUSOS_HORARIOS
  }

  return acao
}