import api from "../api-hercules";

const listarUsuariosDoTarefas = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `&pagina=${pagina}` : '&pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10'

  return api.get(`/usuarios?ativos=true${paginacao}${parametroNome}${ordem}${tamanhoDaPaginaFormatado}`)
}

const definirProfissionalResponsavelDaTarefa = (identificadorDaTarefa, profissional) => {
  const parametroProfissional = profissional ? profissional : null
  return api.put(`tarefas/${identificadorDaTarefa}/profissional-responsavel`, { profissional: parametroProfissional })
}

export default {
  definirProfissionalResponsavelDaTarefa,
  listarUsuariosDoTarefas
}