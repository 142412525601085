import moment from 'moment'
import cpf from 'node-cpf'

export const contemValor = valor => {
  if (valor === undefined) return false
  if (valor === false || valor === true || valor === 0) return true
  if (!valor) return false
  if (ehUmNumeroInteiroValido(valor) || ehUmNumeroDecimalValido(valor)) return true
  if (typeof (valor) !== 'string' && valor) return true

  return valor.trim() !== ''
}

export const ehUmCepValido = cep => !contemValor(cep) || /^[0-9]{8}$/.test(cep) || /^[0-9]{5}-[0-9]{3}$/.test(cep)

export const ehUmCpfValido = numero => {
  if (!contemValor(numero)) {
    return true
  }

  if (!cpf.validate(numero)) {
    return false
  }

  const numero_formatado = cpf.mask(numero)

  if (numero_formatado === '000.000.000-00') {
    return false
  }

  if (numero_formatado === '111.111.111-11') {
    return false
  }

  if (numero_formatado === '222.222.222-22') {
    return false
  }

  if (numero_formatado === '333.333.333-33') {
    return false
  }

  if (numero_formatado === '444.444.444-44') {
    return false
  }

  if (numero_formatado === '555.555.555-55') {
    return false
  }

  if (numero_formatado === '666.666.666-66') {
    return false
  }

  if (numero_formatado === '777.777.777-77') {
    return false
  }

  if (numero_formatado === '888.888.888-88') {
    return false
  }

  if (numero_formatado === '999.999.999-99') {
    return false
  }

  return true
}

export const ehUmaDataValida = data => !contemValor(data) || moment(data, 'DD/MM/YYYY', true).isValid()
export const ehUmaHoraValida = hora => contemValor(hora) && moment(hora, 'HH:mm', true).isValid()
export const ehUmEmailValido = email => !contemValor(email) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
export const ehUmNumeroDeTelefoneValido = numero => !contemValor(numero) || /^[1-9]{2} [2-9][0-9]{3,4}-[0-9]{4}$/.test(numero) || /^[1-9]{2}[2-9][0-9]{3,4}[0-9]{4}$/.test(numero)
export const ehUmAlfanumericoValido = valor => /^[a-z0-9]+$/i.test(valor)
export const ehUmIPValido = ip => contemValor(ip)? /^((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))$/.test(ip) : true


export const ehUmNumeroInteiroValido = valor => {
  if (isNaN(valor)) {
    return false
  }

  let x = parseFloat(valor)
  return (x | 0) === x
}

export const ehUmNumeroDecimalValido = valor => {
  valor = substuirVirgulaPorPonto(valor)

  return !isNaN(parseFloat(valor)) && isFinite(valor)
}

export const ehUmPercentualValido = valor => {
  valor = substuirVirgulaPorPonto(valor)

  if (isNaN(valor)) {
    return false
  }

  let x = parseFloat(valor)

  if (x < 0 || x > 100) {
    return false
  }

  return true
}

export const numeroDeCasasDecimais = valor => {
  valor = substuirVirgulaPorPonto(valor)

  var representacao_textual = "" + (+valor)
  var partes = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/.exec(representacao_textual)

  if (!partes) {
    return 0
  }

  return Math.max(0, (partes[1] === '0' ? 0 : (partes[1] || '').length) - (partes[2] || 0))
}

const substuirVirgulaPorPonto = valor => {
  return valor ? valor.toString().replace(',', '.') : valor
}

export const deveSerUmValorEntre = (valor, valorMinimo, valorMaximo) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  return valor >= valorMinimo && valor <= valorMaximo ? undefined : `Deve ser um valor entre ${valorMinimo} e ${valorMaximo}.`
}

export const deveSerInformado = valor => contemValor(valor) ? undefined : 'Obrigatório'