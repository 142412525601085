import React from 'react'
import Spinner from '../../../../spinner'
import {MultiplaEscolha} from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'

import {LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT} from '../../../../../acoes/tipos'

const buscarPontuacao = (frequenciasNaEscalaZarit, valor) => frequenciasNaEscalaZarit.length > 0 && frequenciasNaEscalaZarit.find(x => x.nome === valor).pontuacao
const renderizarTitulo = valor => { return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Resumo({ frequenciasNaEscalaZarit, valores, fechar }) {
  const {
    nomeDoCuidador,
    data,
    observacoes,
    semTempoSuficienteParaVoceMesmo,
    estressadoOuAngustiado,
    relacaoComAmigosOuFamiliaresAfetada,
    exausto,
    saudeAfetada,
    temPerdidoOControleDaVida,
    muitoSobrecarregado,
    pontuacao = 0,
  } = valores

  if (!valores.nomeDoCuidador) return null

  return (
    <Spinner operacoes={[LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT]}>
      <r-grid columns-md={6} columns-lg={12} class='form form-group'>
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className='form-title'>Resumo da Avaliação da Sobrecarga do Cuidador</h2>
            <Paciente paciente={valores.paciente} />
            <Informacoes nomeDoCuidador={nomeDoCuidador} data={data} observacoes={observacoes} />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
              <Cabecalho />
              <SemTempoSuficienteParaVoceMesmo frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={semTempoSuficienteParaVoceMesmo} />
              <EstressadoOuAngustiado frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={estressadoOuAngustiado} />
              <RelacaoComAmigosOuFamiliaresAfetada frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={relacaoComAmigosOuFamiliaresAfetada} />
              <Exausto frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={exausto} />
              <SaudeAfetada frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={saudeAfetada} />
              <TemPerdidoOControleDaVida frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={temPerdidoOControleDaVida} />
              <MuitoSobrecarregado frequenciasNaEscalaZarit={frequenciasNaEscalaZarit} valor={muitoSobrecarregado} />
              <Avaliacao pontuacao={pontuacao} />
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            </div>
          </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Informacoes({ nomeDoCuidador, data, observacoes }) {
  if (!nomeDoCuidador) return null

  return (
    <>
      <div className='componente-zarit paciente-leitura mb-1'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
      <div className='componente-zarit paciente-leitura mb-1'><strong>Cuidador:</strong> {nomeDoCuidador}</div>
      <div className='componente-zarit paciente-leitura'><strong>Observações:</strong> <pre>{observacoes}</pre> </div>
    </>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>AVALIAÇÃO DA SOBRECARGA DO CUIDADOR - ESCALA ZARIT</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function SemTempoSuficienteParaVoceMesmo({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='semTempoSuficienteParaVoceMesmo'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Sente que, por causa do tempo que utiliza com o seu familiar/doente já não tem tempo suficiente para você mesmo?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function EstressadoOuAngustiado({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='estressadoOuAngustiado'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Sente-se estressado/angustiado por ter que cuidar do seu familiar/doente e ao mesmo tempo ser responsável por outras tarefas? (ex.: cuidar de outros familiares, ter que trabalhar).'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function RelacaoComAmigosOuFamiliaresAfetada({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='relacaoComAmigosOuFamiliaresAfetada'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Acha que a situação atual afeta a sua relação com amigos ou outros elementos da família de uma forma negativa?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Exausto({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='exausto'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='4. Sente-se exausto quando tem de estar junto do seu familiar/doente?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function SaudeAfetada({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='saudeAfetada'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='5. Sente que sua saúde tem sido afetada por ter que cuidar do seu familiar/doente?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function TemPerdidoOControleDaVida({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='temPerdidoOControleDaVida'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='6. Sente que tem perdido o controle da sua vida desde que a doença o seu familiar/doente se manifestou?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function MuitoSobrecarregado({ frequenciasNaEscalaZarit, valor }) {
  const pontuacao = valor && frequenciasNaEscalaZarit.length > 0 && buscarPontuacao(frequenciasNaEscalaZarit, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='muitoSobrecarregado'
        opcoes={frequenciasNaEscalaZarit.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='7. No geral, sente-se muito sobrecarregado por ter que cuidar do seu familiar/doente?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaZarit.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Avaliacao({ pontuacao }) {
  const avaliacaoZarit = () => {
    if (pontuacao === 0) return ''

    if (pontuacao <= 14) {
      return 'LEVE'
    } else if (pontuacao >= 15 && pontuacao <= 21) {
      return 'MODERADA'
    } else {
      return 'GRAVE'
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(pontuacao || pontuacao === 0) &&
       <div className='resultado-questionario'>
         <div>
           AVALIAÇÃO DA SOBRECARGA:{' '}<strong>{avaliacaoZarit()}</strong>
           <div className='legenda'>
             Leve (até 14 pontos); Moderada (15 a 21 pontos); Grave (acima de 22 pontos)
           </div>
         </div>
         <div><strong>{pontuacao}</strong></div>
       </div>
      }
    </r-cell>
  )
}

