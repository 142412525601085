import React, {useMemo} from 'react'
import CampoDaTarefa from './campo-da-tarefa'
import SpinnerDaTarefa from './spinner-da-tarefa'
import {ComponenteLeitura} from '../card'
import {Checkbox} from '../../../design/icones'
import {SelectReact} from '../../formik/select'

export default function ListaDeOpcoesMultiplas({ configuracoes, valor, marcar, desmarcar, processando, leitura }) {
  const icone = <Checkbox />
  const { nome, rotulo, itens, minimo, maximo } = configuracoes

  if (itens && itens.length === 0) return

  let listaDeOpcoes = []

  const itensDaLista = itens.replaceAll('][', '$').replaceAll('] [', '$').replaceAll('[', '').replaceAll(']', '').split('$')
  itensDaLista.map(tupla => tupla.split("|")).map(item => listaDeOpcoes.push({ codigo: item[0], nome: item[1] }))

  const obrigatorio = minimo && minimo !== '0'

  return (listaDeOpcoes.length !== 1 && maximo === '1') ? (
    <ListaSelect
      itens={listaDeOpcoes}
      valor={valor}
      marcar={marcar}
      desmarcar={desmarcar}
      icone={icone}
      nome={nome}
      rotulo={rotulo}
      obrigatorio={obrigatorio}
      leitura={leitura}
      processando={processando}
    />
  ) : (
    <ListaCheckbox
      itens={listaDeOpcoes}
      valor={valor}
      marcar={marcar}
      desmarcar={desmarcar}
      icone={icone}
      nome={nome}
      rotulo={rotulo}
      obrigatorio={obrigatorio}
      leitura={leitura}
      processando={processando}
    />
  )
}

function ListaCheckbox({ itens, valor, marcar, desmarcar, icone, nome, rotulo, obrigatorio, leitura, processando}) {
  if (leitura) {
    if (valor.length === 0) return null

    const valoresMarcados = valor &&  itens.filter(x => valor.some(y => y === x.codigo))

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        renderizar={() =>
          valoresMarcados.map(x => (
            <div className='checkbox is-disabled' key={x.codigo}>
              <input
                className='checkbox-input'
                type='checkbox'
                disabled={true}
                defaultChecked={x.codigo}
                id={x.codigo}
                value={x.codigo}
                name={x.codigo}
              />
              <label className='checkbox-label' htmlFor={x.codigo}><span>{x.nome}</span></label>
            </div>
          ))
        }
      />
    )
  }

  const definir = async item => {
    if (valor.includes(item)) return await desmarcar(nome, item)

    return await marcar(nome, item)
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valor}
      gravarAoSelecionar={true}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus }) =>
        <>
          {itens.map(x => (
            <div className='checkbox' key={x.codigo}>
              <input
                className='checkbox-input'
                type='checkbox'
                value={x.codigo}
                id={x.codigo}
                name={x.codigo}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                checked={valor.includes(x.codigo)}
              />
              <label className='checkbox-label' htmlFor={x.codigo}><span>{x.nome}</span></label>
            </div>
          ))}
          <SpinnerDaTarefa processando={processando}/>
        </>
      }
    </CampoDaTarefa>
  )
}

function ListaSelect({ itens, valor, marcar, desmarcar, icone, nome, rotulo, obrigatorio, leitura, processando}) {
  const options = useMemo(() => itens.map(x => ({
    value: x.codigo,
    label: x.nome
  })), [itens])

  const valorMarcado = valor && itens.find(x => valor.some(y => y === x.codigo))

  if (leitura) {
    if (valor.length === 0) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        valor={valorMarcado.nome}
      />
    )
  }

  const definir = async item => {
    let result = null

    for (const x of valor) {
      result = await desmarcar(nome, x)
    }

    if (item && result !== false) result = await marcar(nome, item)

    return result ?? true
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={(valorMarcado && valorMarcado.codigo) ? valorMarcado.codigo : valorMarcado}
      gravarAoSelecionar={true}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor, isValid, isWarning }) =>
        <SelectReact
          nome='opcao'
          id='opcao'
          onChange={onChange}
          options={options}
          valor={valor}
          onBlur={onBlur}
          onFocus={onFocus}
          isLoading={processando}
          isValid={isValid}
          isWarning={isWarning}
        />
      }
    </CampoDaTarefa>
  )
}