import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  LISTAR_PROPRIETARIOS_DE_TELEFONES,
  LISTOU_PROPRIETARIOS_DE_TELEFONES,
  ERRO_AO_LISTAR_PROPRIETARIOS_DE_TELEFONES
} from './tipos'

const listouProprietariosDeTelefones = proprietariosDeTelefones => ({
  type: LISTOU_PROPRIETARIOS_DE_TELEFONES,
  proprietariosDeTelefones,
})

const erroAoListarProprietariosDeTelefones = erro => ({
  type: ERRO_AO_LISTAR_PROPRIETARIOS_DE_TELEFONES,
  erro,
})

export const listarProprietariosDeTelefones = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_PROPRIETARIOS_DE_TELEFONES })

    try {
      const resultado = await api.listarProprietariosDeTelefones()

      dispatch(listouProprietariosDeTelefones(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarProprietariosDeTelefones(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os proprietários de telefones.')))
    }
  }

  acao.meta = {
    cache: LISTAR_PROPRIETARIOS_DE_TELEFONES
  }

  return acao
}