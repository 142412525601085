import { converterMensagemParaNotificacao } from '../bibliotecas/notificacao'
import {
  ADICIONAR_NOTIFICACAO,
  ADICIONAR_NOTIFICACOES,
  REMOVER_NOTIFICACAO,
  REMOVER_NOTIFICACOES,
} from './tipos'

export const adicionarNotificacao = notificacao => ({
  type: ADICIONAR_NOTIFICACAO,
  notificacao
})

export const adicionarNotificacoes = notificacoes => ({
  type: ADICIONAR_NOTIFICACOES,
  notificacoes
})

export const adicionarNotificacoesDaApi = (resultado, mensagemPadrao, tipos = []) => {
  let notificacoes = mensagemPadrao ? [mensagemPadrao] : []

  if (resultado) {
    if (resultado.data) {
      if (resultado.data.mensagens) {
        notificacoes = resultado.data.mensagens.map(mensagem => converterMensagemParaNotificacao(mensagem))
      }
    } else {
      if (resultado.mensagens) {
        notificacoes = resultado.mensagens.map(mensagem => converterMensagemParaNotificacao(mensagem))
      }
    }
  }

  if (tipos.length > 0) {
    notificacoes = notificacoes.filter(x => tipos.includes(x.tipo))
  }

  return {
    type: ADICIONAR_NOTIFICACOES,
    notificacoes
  }
}

export const removerNotificacao = notificacao => ({
  type: REMOVER_NOTIFICACAO,
  notificacao
})

export const removerNotificacoes = notificacoes => ({
  type: REMOVER_NOTIFICACOES,
  notificacoes
})
