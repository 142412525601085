import api from '../../api-major'

const adicionarFeriadoTrabalhadoDoProfissional = (profissional, feriado) => api.post(`/profissionais-de-saude/${profissional}/feriados-trabalhados`, feriado)
const removerFeriadoTrabalhadoDoProfissional = (profissional, feriado) => api.delete(`/profissionais-de-saude/${profissional}/feriados-trabalhados/${feriado}`)

const listarFeriadosTrabalhadosDoProfissional = profissional => {
  const paginacao = '?pagina=1'
  const ordem = '&ordem=-feriado.data'
  const tamanhoDaPaginaFormatado = '&tamanhoDaPagina=1000'

  return api.get(`/profissionais-de-saude/${profissional}/feriados-trabalhados${paginacao}${tamanhoDaPaginaFormatado}${ordem}`)
}

export default { 
  adicionarFeriadoTrabalhadoDoProfissional,
  removerFeriadoTrabalhadoDoProfissional,
  listarFeriadosTrabalhadosDoProfissional,
}