import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RESPOSTAS_VALIDAS_SOBRE_DIARREIA,
  LISTOU_RESPOSTAS_VALIDAS_SOBRE_DIARREIA,
  ERRO_AO_LISTAR_RESPOSTAS_VALIDAS_SOBRE_DIARREIA
} from '../tipos'

const listouRespostasValidasSobreDiarreia = respostasDiarreia => ({
  type: LISTOU_RESPOSTAS_VALIDAS_SOBRE_DIARREIA,
  respostasDiarreia
})

const erroAoListarRespostasValidasSobreDiarreia = erro => ({
  type: ERRO_AO_LISTAR_RESPOSTAS_VALIDAS_SOBRE_DIARREIA,
  erro
})

export const listarRespostasValidasSobreDiarreia = () => async dispatch => {
  dispatch({ type: LISTAR_RESPOSTAS_VALIDAS_SOBRE_DIARREIA })

  try {
    const resultado = await api.listarRespostasValidasSobreDiarreia()

    dispatch(listouRespostasValidasSobreDiarreia(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRespostasValidasSobreDiarreia(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as respostas válidas sobre diarreia.')))
  }
}
