import { connect } from 'react-redux'
import acoes from '../../../acoes'
import SalaDeEspera from './index'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.paciente.foto).forEach(item => {
    fotos[item.paciente.foto] = imagens[item.paciente.foto]
  })

  return fotos
}

const mapStateToProps = state => {
  return {
    filtros: state.agendamentos.salaDeEspera.filtros,
    fotos: recuperarFotos(state.agendamentos.salaDeEspera.paginaDeDados.dados, state.imagens),
    identificadorDoProfissional: state.login.logado ? state.login.logado.identificador : null,
    ordenacao: state.agendamentos.salaDeEspera.ordenacao,
    paginaDeDados: state.agendamentos.salaDeEspera.paginaDeDados,
    unidadeAtual: state.login.logado.identificador ? state.login.logado.unidades[state.login.logado.unidade] : undefined
  }
}

export default connect(mapStateToProps, acoes)(SalaDeEspera)