import { formatarDataParaFormatoUniversal } from '../bibliotecas/data'
import {
  ADICIONOU_FERIADO,
  RECUPEROU_FERIADO_POR_IDENTIFICADOR,
  SALVOU_FERIADO,
  LISTOU_FERIADOS,
  EXCLUIU_FERIADO
} from '../acoes/tipos'


const estadoInicial = {
  filtros: {
    nome: '',
    ano: formatarDataParaFormatoUniversal(new Date())
  },
  itemAtual: null,
  ordenacao: { nome: 'data', ordem: '-' },
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_FERIADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_FERIADO_POR_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.feriado
      }
    }

    case SALVOU_FERIADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_FERIADOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case EXCLUIU_FERIADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}