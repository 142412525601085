import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import { gravarFotoNoCache, recuperarFotoDoCache } from '../../../../bibliotecas/imagem'
import {
  LISTAR_MINI_MENTAIS,
  LISTOU_MINI_MENTAIS,
  ERRO_AO_LISTAR_MINI_MENTAIS,
  ADICIONAR_MINI_MENTAL,
  ADICIONOU_MINI_MENTAL,
  ERRO_AO_ADICIONAR_MINI_MENTAL,
  ALTERAR_MINI_MENTAL,
  ALTEROU_MINI_MENTAL,
  ERRO_AO_ALTERAR_MINI_MENTAL,
  REMOVER_MINI_MENTAL,
  REMOVEU_MINI_MENTAL,
  ERRO_AO_REMOVER_MINI_MENTAL,
  RECUPEROU_DESENHO_DO_MINI_MENTAL,
  ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL,
  RECUPERAR_DESENHO_DO_MINI_MENTAL,
  ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL
} from '../../../tipos'

const listouMiniMentais = (identificadorDoPaciente, miniMentais) => ({
  type: LISTOU_MINI_MENTAIS,
  identificadorDoPaciente,
  miniMentais
})

const erroAoListarMiniMentais = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_MINI_MENTAIS,
  erro,
  parametros
})

export const listarMiniMentais = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_MINI_MENTAIS })

  try {
    const resultado = await api.listarMiniMentais(identificadorDoPaciente)

    dispatch(listouMiniMentais(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMiniMentais(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os mini mentais.')))
  }
}

const adicionouMiniMental = miniMental => ({
  type: ADICIONOU_MINI_MENTAL,
  miniMental
})

const erroAoAdicionarMiniMental = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_MINI_MENTAL,
  erro,
  parametros
})

export const adicionarMiniMental = (identificadorDoPaciente, miniMental) => async dispatch => {
  dispatch({ type: ADICIONAR_MINI_MENTAL })

  try {
    const resultado = await api.adicionarMiniMental(identificadorDoPaciente, miniMental)

    dispatch(adicionouMiniMental(resultado.data.dados))
    dispatch(listarMiniMentais(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarMiniMental(erro, { miniMental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar o mini mental.')))

    return false
  }
}

const alterouMiniMental = miniMental => ({
  type: ALTEROU_MINI_MENTAL,
  miniMental
})

const erroAoAlterarMiniMental = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_MINI_MENTAL,
  erro,
  parametros
})

export const alterarMiniMental = (identificadorDoPaciente, miniMental) => async dispatch => {
  dispatch({ type: ALTERAR_MINI_MENTAL })

  try {
    const resultado = await api.alterarMiniMental(identificadorDoPaciente, miniMental)

    dispatch(alterouMiniMental(resultado.data.dados))
    dispatch(listarMiniMentais(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarMiniMental(erro, { miniMental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o mini mental')))

    return false
  }
}

const removeuMiniMental = identificadorDoPaciente => ({
  type: REMOVEU_MINI_MENTAL,
  identificadorDoPaciente
})

const erroAoRemoverMiniMental = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_MINI_MENTAL,
  erro,
  parametros
})

export const removerMiniMental = (identificadorDoPaciente, identificadorDoMiniMental) => async dispatch => {
  dispatch({ type: REMOVER_MINI_MENTAL })

  try {
    const resultado = await api.removerMiniMental(identificadorDoPaciente, identificadorDoMiniMental)

    dispatch(removeuMiniMental(identificadorDoPaciente))
    dispatch(listarMiniMentais(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverMiniMental(erro, { identificadorDoPaciente, identificadorDoMiniMental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover o mini mental.')))

    return false
  }
}

const recuperouDesenhoDoMiniMental = () => ({ type: RECUPEROU_DESENHO_DO_MINI_MENTAL })

const erroAoRecuperarDesenhoDoMiniMental = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL,
  erro,
  parametros
})

export const recuperarDesenhoDoMiniMental = (identificadorDoPaciente, identificadorDoMiniMental, identificadorDoDesenhoDoMiniMental) => async dispatch => {
  dispatch({ type: RECUPERAR_DESENHO_DO_MINI_MENTAL })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDoDesenhoDoMiniMental)

    if (itemDoCache) {
      dispatch(recuperouDesenhoDoMiniMental())
      return itemDoCache
    }

    const resultado = await api.recuperarDesenhoDoMiniMental(identificadorDoPaciente, identificadorDoMiniMental)
    const desenho = gravarFotoNoCache(resultado.data, identificadorDoDesenhoDoMiniMental)

    dispatch(recuperouDesenhoDoMiniMental())

    return desenho
  } catch (erro) {
    dispatch(erroAoRecuperarDesenhoDoMiniMental(erro, { identificadorDoPaciente, identificadorDoMiniMental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao visualizar o desenho do mini mental.')))
  }
}

const adicionouInterpretacaoMedicaDoMiniMental = identificadorDoPaciente => ({
  type: ADICIONOU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  identificadorDoPaciente
})

const erroAoAdicionarInterpretacaoMedicaDoMiniMental = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  erro,
  parametros
})

export const adicionarInterpretacaoMedicaDoMiniMental = (identificadorDoPaciente, identificadorDoMiniMental, interpretacaoMedica) => async dispatch => {
  dispatch({ type: ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL })

  try {
    const resultado = await api.adicionarInterpretacaoMedicaDoMiniMental(identificadorDoPaciente, identificadorDoMiniMental, interpretacaoMedica)

    dispatch(adicionouInterpretacaoMedicaDoMiniMental(identificadorDoPaciente))
    dispatch(listarMiniMentais(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarInterpretacaoMedicaDoMiniMental(erro, { identificadorDoPaciente, identificadorDoMiniMental, interpretacaoMedica }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao adicionar interpretação médica do mini mental.')))

    return false
  }
}

const removeuInterpretacaoMedicaDoMiniMental = identificadorDoPaciente => ({
  type: REMOVEU_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  identificadorDoPaciente
})

const erroAoRemoverInterpretacaoMedicaDoMiniMental = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  erro,
  parametros
})

export const removerInterpretacaoMedicaDoMiniMental = (identificadorDoPaciente, identificadorDoMiniMental) => async dispatch => {
  dispatch({ type: REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL })

  try {
    const resultado = await api.removerInterpretacaoMedicaDoMiniMental(identificadorDoPaciente, identificadorDoMiniMental)

    dispatch(removeuInterpretacaoMedicaDoMiniMental(identificadorDoPaciente))
    dispatch(listarMiniMentais(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverInterpretacaoMedicaDoMiniMental(erro, { identificadorDoPaciente, identificadorDoMiniMental }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover interpretação médica do mini mental.')))

    return false
  }
}