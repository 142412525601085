import moment from 'moment'
import { formatarDataParaFormatoUniversal } from '../../../bibliotecas/data'

import {
  LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
} from '../../../acoes/tipos'

const estadoInicial = {
  filtros: {
    fim: formatarDataParaFormatoUniversal(moment().add(1, 'years')),
    inicio: formatarDataParaFormatoUniversal(moment()),
  },
  motivos: [],
  paginaDeDados: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE: {
      return {
        ...state,
        filtros: action.filtros,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA: {
      return {
        ...state,
        motivos: action.motivosDeBloqueioDaAgenda,
      }
    }

    default: {
      return state
    }
  }
}

