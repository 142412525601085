import {
  LISTOU_STATUS_DOS_AGENDAMENTOS
} from '../../acoes/tipos'

const estadoInicial = []

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_STATUS_DOS_AGENDAMENTOS: {

      return action.statusDosAgendamentos
    }

    default: {
      return state
    }
  }
}