import React, { memo } from 'react'
import { CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'

function FrequenciaCardiaca({ metadados, leitura, valor }) {
  const obrigatorio = metadados?.obrigatorio === 'true'

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Frequência Cardíaca: </strong>
            {valor} bpm
          </pre>
        </div>
      </r-cell>
    )
  }

  const validar = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 1, 500) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 1, 500)
  }

  return (
    <r-cell span={4} span-md={2} span-lg={2}>
      <CampoNumerico
        nome='frequenciaCardiaca'
        titulo={`Frequência Cardíaca ${obrigatorio ? '*' : ''}`}
        escala='bpm'
        validar={validar}
      />
    </r-cell>
  )
}

export default memo(FrequenciaCardiaca)