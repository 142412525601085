import { connect } from 'react-redux'
import acoes from '../../../acoes'
import ResumoDeInternacoes from './index'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'

const tratarDadosParaGrafico = dados => {
  if (!dados) return []

  return dados.map(x => ({
    ...x,
    meta: parseFloat(x.meta).toFixed(2),
    metaAcumulada: parseFloat(x.metaAcumulada).toFixed(2),
    data: formatarDataParaFormatoLocal(x.data)
  }))
}

const mapStateToProps = state => ({
  filtros: state.relatorios.resumoDeInternacoes.filtros,
  programas: state.relatorios.resumoDeInternacoes.programas,
  dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado: tratarDadosParaGrafico(state.relatorios.resumoDeInternacoes.dadosGraficoDeInternacoesPorFraturaDeFemurMetaVersusRealizado),
  dadosGraficoDeReinternacoesMetaVersusRealizado: tratarDadosParaGrafico(state.relatorios.resumoDeInternacoes.dadosGraficoDeReinternacoesMetaVersusRealizado),
})

export default connect(mapStateToProps, acoes)(ResumoDeInternacoes)