import React from 'react'
import chroma from 'chroma-js'

export default function Tipo({ valor, cor, origemTarefas = false }) {
  if (!valor) return null
  if (!cor) return valor

  const color = cor && chroma(cor)
  const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

  return (
    <div>
      <h3>
        <span
          className={`table-items__bcolor-card-sidebar card-tipo ${!origemTarefas && 'card-tipo-menor'}`}
          style={{ background: cor, color: corDaFonte }}
        >
          {valor}
        </span>
      </h3>
    </div>
  )
}