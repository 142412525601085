import api from '../api-major'

const adicionarConvenioDoPaciente = (identificador, convenio) => api.post(`/pacientes/${identificador}/convenios`, convenio)
const alterarConvenioDoPaciente = (identificador, convenio) => api.put(`/pacientes/${identificador}/convenios/${convenio.identificador}`, convenio)
const listarConveniosDoPaciente = identificador => api.get(`/pacientes/${identificador}/convenios?tamanhoDaPagina=1000&ordem=-identificador`)
const removerConvenioDoPaciente = (identificadorDoPaciente, identificador) => api.delete(`/pacientes/${identificadorDoPaciente}/convenios/${identificador}`)
const recuperarConvenioDoPaciente = (identificadorDoPaciente, identificador) => api.get(`/pacientes/${identificadorDoPaciente}/convenios/${identificador}`)

export default {
  adicionarConvenioDoPaciente,
  alterarConvenioDoPaciente,
  listarConveniosDoPaciente,
  recuperarConvenioDoPaciente,
  removerConvenioDoPaciente,
}