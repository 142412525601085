import React, { useMemo, useState } from 'react'
import { AutenticacaoPassoDois, ListaDeUnidades } from './lista'

export default function UnidadesDoLogin(props) {
  const {
    logotipos,
    empresas,
    alterarUnidadeDoLogin,
    history,
    unidadeLogada,
    recuperarFotoDaEmpresaPorIdentificadorPublico,
    removerImagemDaMemoriaPorIdentificador
  } = props

  const unidades = useMemo(() => recuperarUnidades(empresas, unidadeLogada), [empresas, unidadeLogada])
  const [unidadeSelecionada, setUnidadeSelecionada] = useState(null)

  const selecionarUnidade = async unidade => {
    setUnidadeSelecionada(unidade)

    const alterou = await alterarUnidadeDoLogin(unidade.empresa.identificador, unidade.identificador)

    if (alterou) {
      history.push(`/`)
    }
  }

  return (
    <>
      <AutenticacaoPassoDois>
        <ListaDeUnidades
          selecionar={selecionarUnidade}
          selecionada={unidadeSelecionada}
          unidades={unidades}
          voltar={() => history.goBack()}
          recuperarFotoDaEmpresaPorIdentificadorPublico={recuperarFotoDaEmpresaPorIdentificadorPublico}
          removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
          logotipos={logotipos}
        />
      </AutenticacaoPassoDois>
    </>
  )
}

const recuperarUnidades = (empresas = [], unidadeLogada) =>
  empresas.reduce((acumulado, atual) =>
    acumulado.concat(atual.unidades.map(unidade => ({
      ...unidade,
      empresa: {
        identificador: atual.identificador,
        identificadorPublico: atual.identificadorPublico,
        nome: atual.nome,
        logotipo: atual.logotipo
      },
    }))).filter(x => x.identificador !== unidadeLogada), [])