import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_CONVENIO_DO_PACIENTE,
  ADICIONOU_CONVENIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE,
  ALTERAR_CONVENIO_DO_PACIENTE,
  ALTEROU_CONVENIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE,
  LISTAR_CONVENIOS_DO_PACIENTE,
  LISTOU_CONVENIOS_DO_PACIENTE,
  ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE,
  REMOVER_CONVENIO_DO_PACIENTE,
  REMOVEU_CONVENIO_DO_PACIENTE,
  ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE,
  RECUPERAR_CONVENIO_DO_PACIENTE,
  RECUPEROU_CONVENIO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE,
} from '../tipos'

const adicionouConvenioDoPaciente = convenio => ({
  type: ADICIONOU_CONVENIO_DO_PACIENTE,
  convenio,
})

const erroAoAdicionarConvenioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE,
  erro,
  parametros,
})

export const adicionarConvenioDoPaciente = (identificador, convenio) => async dispatch => {
  dispatch({ type: ADICIONAR_CONVENIO_DO_PACIENTE })

  try {    
    const resultado = await api.adicionarConvenioDoPaciente(identificador, convenio)
    
    dispatch(adicionouConvenioDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarConvenioDoPaciente(erro, { identificador, convenio }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar convênio do paciente.')))

    return false
  }
}

const alterouConvenioDoPaciente = convenioDoPaciente => ({
  type: ALTEROU_CONVENIO_DO_PACIENTE,
  convenioDoPaciente
})

const erroAoAlterarConvenioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE,
  erro,
  parametros,
})

export const alterarConvenioDoPaciente = (identificadorDoPaciente, convenioDoPaciente) => async dispatch => {
  dispatch({ type: ALTERAR_CONVENIO_DO_PACIENTE })

  try {    
    const resultado = await api.alterarConvenioDoPaciente(identificadorDoPaciente, convenioDoPaciente)
    
    dispatch(alterouConvenioDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarConvenioDoPaciente(erro, { identificadorDoPaciente, convenioDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o convênio do paciente.')))

    return false
  }
}

const listouConveniosDoPaciente = (convenios, identificadorDoPaciente) => ({
  identificadorDoPaciente,
  type: LISTOU_CONVENIOS_DO_PACIENTE,
  convenios,
})

const erroAoListarConveniosDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE,
  erro,
  parametros,
})

export const listarConveniosDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_CONVENIOS_DO_PACIENTE })

  try {
    const resultado = await api.listarConveniosDoPaciente(identificadorDoPaciente)

    dispatch(listouConveniosDoPaciente(resultado.data.dados.dados, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarConveniosDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar os convênios do paciente.')))
  }
}

const removeuConvenioDoPaciente = identificador => ({
  type: REMOVEU_CONVENIO_DO_PACIENTE,
  identificador,
})

const erroAoRemoverConvenioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE,
  erro,
  parametros,
})

export const removerConvenioDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: REMOVER_CONVENIO_DO_PACIENTE })

  try {    
    const resultado = await api.removerConvenioDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(removeuConvenioDoPaciente(identificadorDoPaciente, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverConvenioDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o convênio do paciente.')))

    return false
  }
}

const recuperouConvenioDoPaciente = convenio => ({
  type: RECUPEROU_CONVENIO_DO_PACIENTE, 
  convenio,
})

const erroAoRecuperarConvenioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE,
  erro,
  parametros,
})

export const recuperarConvenioDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_CONVENIO_DO_PACIENTE })
  try {    
    const resultado = await api.recuperarConvenioDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(recuperouConvenioDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarConvenioDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar convênio do paciente.')))
  }
}