import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  FAZER_DOWNLOAD_DE_REINTERNACOES,
  FEZ_DOWNLOAD_DE_REINTERNACOES,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
} from '../tipos'

const listouProgramasDoRelatorioDeReinternacoes = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  programas
})

const erroAoListarProgramasDoRelatorioDeReinternacoes = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  erro
})

export const listarProgramasDoRelatorioDeReinternacoes = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDeReinternacoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDeReinternacoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as programas do relatório de reinternações.')))
  }
}

const fezDownloadDeReinternacoes = filtros => ({
  type: FEZ_DOWNLOAD_DE_REINTERNACOES,
  filtros
})

const erroAoFazerDownloadDeReinternacoes = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_REINTERNACOES,
  erro,
  parametros
})

export const fazerDownloadDeReinternacoes = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_REINTERNACOES })

  try {
    const resultado = await api.fazerDownloadDeReinternacoes(filtros)

    dispatch(fezDownloadDeReinternacoes(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDeReinternacoes(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do relatório de reinternações.')))

    return null
  }
}