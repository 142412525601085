import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../../spinner'
import { Coluna, Tabela } from '../../../tabela'
import Permissao from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import AbasDaUnidade from '../abas'
import Cabecalho from '../cabecalho'

import { LISTAR_SALAS_DA_UNIDADE } from '../../../../acoes/tipos'

export default function Lista(props) {
  const {
    unidade,
    salas,
    pesquisar,
    match,
  } = props

  useEffect(() => {
    (async () => await pesquisar(unidade.identificador))()
  }, [unidade.identificador, pesquisar])

  return (
    <div className='main-content-form'>
      <div className='form'>
        <Cabecalho unidade={unidade} />
        <div className='mt-2 mb-4'>
          <fieldset>
            <r-grid columns-md={12} columns-lg={12} class='align-items-center'>
              <r-cell span={4} span-md={9} span-lg={9}>
                <AbasDaUnidade identificador={unidade.identificador} url={match.url} />
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={3} class='d-lg-flex justify-content-end'>
                <Permissao permissoes={[permissoes.ADICIONAR_SALA_NA_UNIDADE]}>
                  <Link
                    to={`/configuracoes/empresa/unidades/${unidade.identificador}/salas/nova`}
                    className='button --primary --plus w-sm-100'
                  >
                    Adicionar Sala
                  </Link>
                </Permissao>
              </r-cell>
            </r-grid>
            <Spinner operacoes={[LISTAR_SALAS_DA_UNIDADE]}>
              <Tabela
                acoes={[
                  item => (
                    <Permissao key='editar' permissoes={[permissoes.ALTERAR_SALA_DA_UNIDADE]}>
                      <Link
                        to={`/configuracoes/empresa/unidades/${unidade.identificador}/salas/${item.identificador}`}
                        className='table-items__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </Link>
                    </Permissao>
                  )
                ]}
                chave='identificador'
                dados={salas}
              >
                <Coluna
                  campoDeDados='nome'
                  className='col-xs-8 col-md-10 col-lg-4'
                  principal={true}
                  nome='Nome'
                />
                <Coluna
                  campoDeDados='tipo'
                  className='col-xs-4 col-lg-4'
                  nome='Tipo'
                />
                <Coluna
                  campoDeDados='capacidade'
                  className='col-xs-4 col-lg-3'
                  nome='Capacidade'
                />
              </Tabela>
            </Spinner>
          </fieldset>
        </div>
      </div>
    </div>
  )
}