import React, { memo, useEffect, useCallback, useState } from 'react'
import { CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'

function Peso({ metadados, historico = [], leitura, valor, altura }) {
  const obrigatorio = metadados?.obrigatorio === 'true'
  const [imcCalculado, setImcCalculado] = useState(null)

  const validar = useCallback(valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 0.1, 200) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 0.1, 200)
  }, [obrigatorio])

  useEffect(() => {
    if (altura && valor && !validar(valor)) { 
      const pesoFormatado = valor.toString().replace(',', '.')
      const alturaFormatada = altura.toString().replace(',', '.')
      const alturaEmMetros = alturaFormatada / 100

      setImcCalculado(Math.round((pesoFormatado / (alturaEmMetros * alturaEmMetros)) * 100) / 100)
    } else {
      setImcCalculado(null)
    }
  }, [valor, altura, validar])

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Peso: </strong>
            {valor.toString().replace('.', ',')} kg
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <r-cell span={4} span-md={2} span-lg={2}>
      <CampoNumerico
        nome='peso'
        titulo={`Peso ${obrigatorio ? '*' : ''}`}
        escala='kg'
        numeroDeCasasDecimais={1}
        validar={validar}
        informacaoAdicional={imcCalculado ? { label: 'IMC', valor: imcCalculado + ' kg/m²' } : null}
      >
        {historico.length > 0 &&
          <GraficoPopup titulo='Peso'>
            <Grafico historico={historico} />
          </GraficoPopup>
        }
      </CampoNumerico>
    </r-cell>
  )
}

export default memo(Peso)