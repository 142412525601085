import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Lista from './lista'

export default function ConfirmarAgendamento(props) {
  const {
    agendamento,
    confirmarChegadaDoPaciente,
    cancelarAgendamento,
    excluirAgendamento,
    filtros,
    fotos,
    identificadorDoUsuarioLogado,
    informarSaidaDoPaciente,
    match,
    ordenacao,
    paginaDeDados,
    listarAgendamentosDoDia,
    listarProfissionaisDeSaudeDaAgenda,
    listarMotivosDeCancelamentoDosAgendamentos,
    listarMotivosParaNaoTerSidoAtendido,
    profissional,
    profissionais,
    motivosDeCancelamento,
    motivosParaNaoTerSidoAtendido,
    recuperarFotoDoPacientePorIdentificador,
    recuperarAgendamentoPeloIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    unidadeDoUsuario
  } = props

  useEffect(() => {
    paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.paciente.identificador, item.paciente.foto)
    })

    return () => paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.paciente.foto)
    })
  }, [paginaDeDados.dados, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    listarProfissionaisDeSaudeDaAgenda()
    listarMotivosDeCancelamentoDosAgendamentos()
    listarMotivosParaNaoTerSidoAtendido()
  }, [listarProfissionaisDeSaudeDaAgenda, listarMotivosParaNaoTerSidoAtendido, listarMotivosDeCancelamentoDosAgendamentos])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE]}>
              <Lista
                {...props}
                agendamento={agendamento}
                confirmarChegadaDoPaciente={confirmarChegadaDoPaciente}
                cancelarAgendamento={cancelarAgendamento}
                excluirAgendamento={excluirAgendamento}
                filtros={filtros}
                fotos={fotos}
                identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
                informarSaidaDoPaciente={informarSaidaDoPaciente}
                motivosDeCancelamento={motivosDeCancelamento}
                motivosParaNaoTerSidoAtendido={motivosParaNaoTerSidoAtendido}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                pesquisar={listarAgendamentosDoDia}
                profissional={profissional}
                profissionais={profissionais}
                recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
                selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
                selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
                unidadeDoUsuario={unidadeDoUsuario}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}`} />
    </Switch>
  )
}