import {
  LISTOU_INDICADORES_DO_PACIENTE
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_INDICADORES_DO_PACIENTE: {
      return {
        ...state,
        [action.identificadorDoPaciente]: action.indicadores
      }
    }

    default: {
      return state
    }
  }
}