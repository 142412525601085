import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Lista from './lista'

export default function PedidosDeContato(props) {
  const {
    filtros,
    listarPedidosDeContato,
    listarPlanosDeSaudeParaOPedidoDeContato,
    planosDeSaude,
    match,
    ordenacao,
    paginaDeDados,
    removerPedidoDeContato,
  } = props

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Lista
              {...props}
              filtros={filtros}
              ordenacao={ordenacao}
              pesquisar={listarPedidosDeContato}
              paginaDeDados={paginaDeDados}
              listarPlanosDeSaude={listarPlanosDeSaudeParaOPedidoDeContato}
              planosDeSaude={planosDeSaude}
              removerPedidoDeContato={removerPedidoDeContato}
            />
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}