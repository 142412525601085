import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  LISTOU_ATENDIMENTOS_NAO_ASSINADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  ASSINAR_ATENDIMENTO_FINALIZADO,
} from './tipos'

const listouAtendimentosNaoAssinados = (pagina, paginaDeDados, ordenacao) => ({
  type: LISTOU_ATENDIMENTOS_NAO_ASSINADOS,
  pagina,
  paginaDeDados,
  ordenacao
})

const erroAoListarAtendimentosNaoAssinados = erro => ({
  type: ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  erro,
})

export const listarAtendimentosNaoAssinados = (pagina, ordenacao) => async dispatch => {
  dispatch({ type: LISTAR_ATENDIMENTOS_NAO_ASSINADOS })

  try {
    const resultado = await api.listarAtendimentosNaoAssinados(pagina, ordenacao)

    dispatch(listouAtendimentosNaoAssinados(pagina, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAtendimentosNaoAssinados(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os atendimentos não assinados.')))
  }
}

export const assinarAtendimentoFinalizado = (identificadorDoPaciente, atendimento) => ({ type: ASSINAR_ATENDIMENTO_FINALIZADO, identificadorDoPaciente, atendimento })