import React from 'react'
import NumberFormat from 'react-number-format'
import SpinnerDaTarefa from './spinner-da-tarefa'
import CampoDaTarefa from './campo-da-tarefa'
import {ComponenteLeitura} from '../card'
import {Number} from '../../../design/icones'

export default function NumeroInteiro({ rotulo, obrigatorio, valor, processando, leitura, definir }) {
  const icone = <Number/>

  if (leitura) {
    if (!valor && valor !== 0) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={rotulo}
        valor={valor}
      />
    )
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={valor}
      icone={icone}
      titulo={`${rotulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor }) =>
        <>
          <NumberFormat
            value={valor || ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
            decimalScale={0}
          />
          <SpinnerDaTarefa processando={processando}/>
        </>
      }
    </CampoDaTarefa>
  )
}