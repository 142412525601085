import React, { useEffect } from 'react'
import Formulario from './formulario'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'

export default function ListaDeEspera(props) {
  const {
    abrirNovoAgendamento,
    adicionarPacienteNaListaDeEspera,
    alterarPacienteNaListaDeEspera,
    alterarDadosSelecionadosDoAgendamentoNaListaDeEspera,
    identificadorDoProfissional,
    limparDadosDoPacienteDoAgendamento,
    listarPacientesDoAgendamento,
    recuperarListaDeEsperaDoProfissionalDeSaude,
    removerPacienteNaListaDeEspera,
    match,
    fecharListaDeEspera,
    pacientes,
    selecionarPacienteDoAgendamento,
    selecionarPacienteDaListaDeEsperaDoProfissionalDeSaude,
    valoresIniciais,
    listaDeEspera
  } = props

  useEffect(() => {
    listarPacientesDoAgendamento({ ativo: ['Ativo'] }, 1, { nome: 'nome', ordem: '+' }, 50)
  }, [listarPacientesDoAgendamento])

  useEffect(() => {
    recuperarListaDeEsperaDoProfissionalDeSaude(identificadorDoProfissional)
  }, [recuperarListaDeEsperaDoProfissionalDeSaude, identificadorDoProfissional])

  const pesquisarPacientes = async termo => {
    await listarPacientesDoAgendamento({ termo: termo, ativo: ['Ativo'] }, 1, { nome: 'nome', ordem: '+' }, 50)
  }

  const selecionarPaciente = async paciente => {
    selecionarPacienteDoAgendamento(paciente)
  }

  const fechar = () => {
    selecionarPacienteDaListaDeEsperaDoProfissionalDeSaude(null)
    fecharListaDeEspera()
  }

  const adicionar = async (formulario, { resetForm }) => {
    const sucesso = await adicionarPacienteNaListaDeEspera(identificadorDoProfissional, formulario)

    if (sucesso) {
      selecionarPaciente({})
      resetForm()
    }
  }

  const alterar = async (formulario, { resetForm }) => {
    const sucesso = await alterarPacienteNaListaDeEspera(identificadorDoProfissional, formulario.identificador, formulario)

    if (sucesso) {
      selecionarPaciente({})
      resetForm()
    }
  }

  const editar = (item, index) => {
    if (item) {
      selecionarPacienteDaListaDeEsperaDoProfissionalDeSaude({
        identificadorDaListaDeEspera: item.identificador,
        posicaoNaListaDeEspera: index + 1,
        ...item,
        ...item.paciente,
      })
    } else {
      selecionarPacienteDaListaDeEsperaDoProfissionalDeSaude(null)
    }
  }

  const remover = async item => {
    await removerPacienteNaListaDeEspera(identificadorDoProfissional, item.identificador)
  }

  const agendar = async item => {
    const telefone1 = item.telefone1 ? { proprietario: 'Paciente', numero: item.telefone1 } : { proprietario: 'Paciente', numero: item.telefone2 }
    const telefone2 = item.telefone2 ? { proprietario: 'Paciente', numero: item.telefone2 } : null
    const telefones = telefone1 && telefone2 ? [telefone1, telefone2] : [telefone1]

    const dadosSelecionadosDoAgendamento = {
      paciente: {
        ...item.paciente,
        telefones
      }
    }

    await alterarDadosSelecionadosDoAgendamentoNaListaDeEspera(dadosSelecionadosDoAgendamento)

    fechar()
    abrirNovoAgendamento()
  }

  const ordenarListaDeEspera = (a, b) => {
    if (a.identificador < b.identificador) {
      return -1
    }

    if (a.identificador > b.identificador) {
      return 1
    }

    return 0
  }

  return (
    <>
      {listaDeEspera.map(x =>
        <ReactTooltip
          id={`info_icone_${x.identificador}`}
          place='right'
          effect='solid'
          type='info'
          className='unity-infos__item'
          key={x.identificador}
        >
          <span><p><strong>Adicionado por:</strong></p></span>
          <span><p>Usuário: {x.cadastro.usuario.nome}</p></span>
          <span><p>Data e Hora: {formatarDataEHoraParaFormatoLocal(x.cadastro.dataEHora)}</p></span>
          {!moment(x.cadastro.dataEHora).isSame(moment(x.ultimaAlteracao.dataEHora)) &&
            <>
              <div className='border-top-active my-6' />
              <span><p><strong>Última alteração:</strong></p></span>
              <span><p>Usuário: {x.ultimaAlteracao.usuario.nome}</p></span>
              <span><p>Data e Hora: {formatarDataEHoraParaFormatoLocal(x.ultimaAlteracao.dataEHora)}</p></span>
            </>
          }
        </ReactTooltip>
      )}
      <Formulario
        abrirNovoAgendamento={abrirNovoAgendamento}
        match={match}
        fechar={fechar}
        limparDadosDoPaciente={limparDadosDoPacienteDoAgendamento}
        pacientes={pacientes}
        pesquisarPacientes={pesquisarPacientes}
        selecionarPaciente={selecionarPaciente}
        valoresIniciais={valoresIniciais}
        emEspera={listaDeEspera.sort(ordenarListaDeEspera)}
        adicionar={adicionar}
        alterar={alterar}
        editar={editar}
        remover={remover}
        agendar={agendar}
      />
    </>
  )
}