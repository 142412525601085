import api from '../api-major'

const recuperarAgendaSemanalDoProfissional = (identificadorDoProfissional, data) => api.get(`profissionais-de-saude/${identificadorDoProfissional}/agenda/semana/${data}`)

const listarHorariosLivres = (identificadorDoProfissional, dataInicio, dataFim, tipoDeAgendamento, unidades, turnos, diasDaSemana) => {
  return api.get(`profissionais-de-saude/${identificadorDoProfissional}/agenda/horarios/livres?inicio=${dataInicio}&fim=${dataFim}&turnos=${turnos}&diasDaSemana=${diasDaSemana}&tipoDeAgendamento=${tipoDeAgendamento}&profissionais=${identificadorDoProfissional}&unidades=${unidades}`)
}

const listarProfissionaisDeSaudeDaAgenda = () => {
  return api.get(`profissionais-de-saude?tamanhoDaPagina=1000&pagina=1&ordem=nome`)
}

const filtrarProfissionaisDeSaudeDaAgenda = termo => {
  const parametroTermo = termo ? `&termo=${termo}` : ''
  return api.get(`profissionais-de-saude/ativos?tamanhoDaPagina=1000&pagina=1${parametroTermo}&ordem=nome`)
}

export default {
  filtrarProfissionaisDeSaudeDaAgenda,
  listarHorariosLivres,
  listarProfissionaisDeSaudeDaAgenda,
  recuperarAgendaSemanalDoProfissional,
}