import React, { useEffect, useMemo } from 'react'
import { Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao, { usePossuiAlgumaPermissao } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Templates(props) {
  const {
    adicionarTemplate,
    componentes,
    excluirTemplate,
    filtros,
    listarComponentesComTemplate,
    listarTemplates,
    listarTokensDeExemplo,
    listarTokensDeSugestao,
    ordenacao,
    paginaDeDados,
    recuperarTemplatePorIdentificador,
    salvarTemplate,
    selecionado,
    sugestoes = [],
    tokens = [],
  } = props

  const history = useHistory()
  const { path } = useRouteMatch()

  useEffect(() => {
    listarComponentesComTemplate()
    listarTokensDeSugestao()
    listarTokensDeExemplo()
  }, [listarComponentesComTemplate, listarTokensDeSugestao, listarTokensDeExemplo])

  const salvar = async formulario => {
    let dados = {
      ...formulario,
      template: codificador.reduce((acumulado, atual) => acumulado.replaceAll(atual.display, atual.id), formulario.template)
    }
    delete dados.preview

    const acao = dados.identificador ? salvarTemplate : adicionarTemplate
    const executou = await acao(dados)

    if (executou) {
      history.push(`/configuracoes/templates/`)
    }
  }

  const remover = async item => {
    const removeu = await excluirTemplate(item.identificador)

    if (removeu) {
      history.push(`/configuracoes/templates`)
    }
  }

  const permissaoInclusao = usePossuiAlgumaPermissao([
    permissoes.ADICIONAR_TEMPLATE_COMPARTILHADO,
    permissoes.ADICIONAR_TEMPLATE_DO_USUARIO
  ])

  const permissaoAlteracao = usePossuiAlgumaPermissao([
    permissoes.ALTERAR_TEMPLATE_COMPARTILHADO,
    permissoes.ALTERAR_TEMPLATE_DO_USUARIO
  ])

  const decodificador = useMemo(() => tokens.reduce((acumulado, atual) => {
    return acumulado.concat({ id: `@@{{${atual.nome.substring(2)}}}`, display: sugestoes[atual.nome] })
  }, []), [sugestoes, tokens])

  const codificador = useMemo(() => tokens.reduce((acumulado, atual) => {
    return acumulado.concat({ id: atual.nome, display: `@@{{${atual.nome.substring(2)}}}` })
  }, []), [tokens])

  const dadosDaSugestao = useMemo(() => tokens.reduce((acumulado, atual, idx) => {
    return acumulado.concat({ id: `${idx} ${atual.nome}`, display: atual.nome.substring(2) })
  }, []), [tokens])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_TEMPLATES]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarTemplates}
                permissaoInclusao={permissaoInclusao}
                permissaoAlteracao={permissaoAlteracao}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      {permissaoInclusao &&
        <RotaComAutenticacao
          exact
          path={`${path}/novo`}
          render={
            props => (
              <Formulario
                {...props}
                componentes={componentes}
                codificador={codificador}
                decodificador={decodificador}
                dadosDaSugestao={dadosDaSugestao}
                salvar={salvar}
              />
            )
          }
        />
      }
      <RotaComAutenticacao
        exact
        path={`${path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_TEMPLATES]}>
              <Formulario
                {...props}
                componentes={componentes}
                codificador={codificador}
                decodificador={decodificador}
                dadosDaSugestao={dadosDaSugestao}
                recuperarPorIdentificador={recuperarTemplatePorIdentificador}
                remover={remover}
                salvar={salvar}
                selecionado={selecionado}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={path} to={`${path}/lista`} />
    </Switch>
  )
}