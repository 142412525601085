import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_TEXTO_DA_TAREFA,
  DEFINIU_TEXTO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_DA_TAREFA
} from '../tipos'

const definiuTextoDaTarefa = (identificadorDaTarefa, campo, conteudo) => ({
  identificadorDaTarefa,
  campo,
  conteudo,
  type: DEFINIU_TEXTO_DA_TAREFA,
})

const erroAoDefinirTextoDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_TEXTO_DA_TAREFA,
  erro,
  parametros,
})

export const definirTextoDaTarefa = (identificadorDaTarefa, campo, conteudo) => async dispatch => {
  dispatch({ type: DEFINIR_TEXTO_DA_TAREFA })

  try {
    const resultado = await api.definirTextoDaTarefa(identificadorDaTarefa, campo, conteudo)

    dispatch(definiuTextoDaTarefa(identificadorDaTarefa, campo, conteudo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirTextoDaTarefa(erro, { identificadorDaTarefa, campo, conteudo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o texto da tarefa.')))

    return false
  }
}