import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ORIENTACOES,
  LISTOU_ORIENTACOES,
  ERRO_AO_LISTAR_ORIENTACOES,
  ADICIONOU_ORIENTACAO,
  ERRO_AO_ADICIONAR_ORIENTACAO,
  ADICIONAR_ORIENTACAO,
  RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  ALTEROU_ORIENTACAO,
  ERRO_AO_ALTERAR_ORIENTACAO,
  ALTERAR_ORIENTACAO,
  REMOVEU_ORIENTACAO,
  ERRO_AO_REMOVER_ORIENTACAO,
  REMOVER_ORIENTACAO,
  FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO
} from './tipos'

const erroAoListarOrientacoes = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ORIENTACOES,
  erro,
  parametros
})

const listouOrientacoes = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_ORIENTACOES,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarOrientacoes = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_ORIENTACOES })

  try {
    const resultado = await api.listarOrientacoes(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouOrientacoes(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOrientacoes(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as orientações.')))
  }
}

const adicionouOrientacao = orientacao => ({
  type: ADICIONOU_ORIENTACAO,
  orientacao
})

const erroAoAdicionarOrientacao = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_ORIENTACAO,
  erro,
  parametros
})

export const adicionarOrientacao = orientacao => async dispatch => {
  dispatch({ type: ADICIONAR_ORIENTACAO })

  try {
    const resultado = await api.adicionarOrientacao(orientacao)

    dispatch(adicionouOrientacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarOrientacao(erro, { orientacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar a orientação.')))

    return false
  }
}

const recuperouOrientacaoPeloIdentificador = orientacao => ({
  type: RECUPEROU_ORIENTACAO_PELO_IDENTIFICADOR,
  orientacao
})

const erroAoRecuperarOrientacaoPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarOrientacaoPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarOrientacaoPeloIdentificador(identificador)

    dispatch(recuperouOrientacaoPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarOrientacaoPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a orientação pelo identificador.')))
  }
}

const alterouOrientacao = orientacao => ({
  type: ALTEROU_ORIENTACAO,
  orientacao
})

const erroAoAlterarOrientacao = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_ORIENTACAO,
  erro,
  parametros
})

export const alterarOrientacao = orientacao => async dispatch => {
  dispatch({ type: ALTERAR_ORIENTACAO })

  try {
    const resultado = await api.alterarOrientacao(orientacao)

    dispatch(alterouOrientacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarOrientacao(erro, { orientacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a orientação.')))

    return false
  }
}

const removeuOrientacao = orientacao => ({
  type: REMOVEU_ORIENTACAO,
  orientacao
})

const erroAoRemoverOrientacao = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_ORIENTACAO,
  erro,
  parametros
})

export const removerOrientacao = identificador => async dispatch => {
  dispatch({ type: REMOVER_ORIENTACAO })

  try {
    const resultado = await api.removerOrientacao(identificador)

    dispatch(removeuOrientacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverOrientacao(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a orientação.')))

    return false
  }
}

const fezDownloadDoArquivoDaOrientacao = (identificadorDoArquivo, bytesDoArquivoDeOrientacao) => ({
  type: FEZ_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  identificadorDoArquivo,
  bytesDoArquivoDeOrientacao
})

const erroAoFazerDownloadDoArquivoDaOrientacao = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
  erro,
  parametros
})

export const fazerDownloadDoArquivoDaOrientacao = (identificadorDaOrientacao, identificadorDoArquivo) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_ARQUIVO_DA_ORIENTACAO,
    identificadorDoArquivo
  })

  try {
    const resultado = await api.fazerDownloadDoArquivoDaOrientacao(identificadorDaOrientacao, identificadorDoArquivo)

    dispatch(fezDownloadDoArquivoDaOrientacao(identificadorDoArquivo, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoArquivoDaOrientacao(erro, { identificadorDaOrientacao, identificadorDoArquivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do arquivo da orientação.')))
    return null
  }
}