import {
  ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM,
  INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  LER_CERTIFICADOS_DIGITAIS_FISICOS,
  LEU_CERTIFICADOS_DIGITAIS_FISICOS,
  SELECIONAR_CERTIFICADO_DIGITAL,
  FINALIZOU_ATENDIMENTO,
  ASSINAR_ATENDIMENTO_FINALIZADO
} from '../../acoes/tipos'

const estadoInicial = {
  certificados: [],
  itemAtual: null,
  lerCertificados: false,
  assinar: false,
  algoritmo: null,
  dadosParaAssinar: null,
  finalizado: null,
  identificadores: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ASSINAR_ATENDIMENTO_FINALIZADO:
    case FINALIZOU_ATENDIMENTO: {
      const { identificadorDoPaciente, atendimento } = action

      return {
        ...estadoInicial,
        itemAtual: state.itemAtual,
        identificadores: {
          paciente: identificadorDoPaciente,
          atendimento: atendimento.identificador,
        }
      }
    }

    case LER_CERTIFICADOS_DIGITAIS_FISICOS: {
      return {
        ...state,
        lerCertificados: !state.lerCertificados,
      }
    }

    case LEU_CERTIFICADOS_DIGITAIS_FISICOS: {
      return {
        ...state,
        lerCertificados: false,
        certificados: action.certificados,
      }
    }

    case SELECIONAR_CERTIFICADO_DIGITAL: {
      return {
        ...state,
        itemAtual: action.certificado,
      }
    }

    case INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO: {
      return {
        ...state,
        algoritmo: action.dados.algoritmo,
        dadosParaAssinar: action.dados.dadosParaAssinar,
        identificadores: {
          ...state.identificadores,
          arquivo: action.dados.identificadorDoArquivo,
        }
      }
    }

    case ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO: {
      return {
        ...state,
        assinar: !state.assinar,
        itemAtual: {
          ...state.itemAtual,
          certEncoding: action.certEncoding ? action.certEncoding : state.itemAtual.certEncoding
        }
      }
    }

    case FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO: {
      return {
        ...state,
        finalizado: action.dados,
      }
    }

    case SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM: {
      return {
        ...state,
        finalizado: action.dados,
        identificadores: {
          paciente: action.dados.atendimento.paciente.identificador,
          atendimento: action.dados.atendimento.identificador,
        }
      }
    }

    default: {
      return state
    }
  }
}