import React, { useState, useEffect, useMemo, Children } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import Historico from './historico'
import { useProntuario } from '../../contexto'
import { useMemed } from '../../../../configuracoes/memed'
import Memed from './memed'

export default function Prescricao({
  adicionarPrescricaoDoHistorico,
  children,
  historico,
  identificadorDoAtendimento,
  identificadorDoPaciente,
  limparPrescricaoDoHistorico,
  temMemed,
  temLifecode,
  textoAdicionado
}) {

  const [exibirHistorico, setExibirHistorico] = useState(false)
  const [exibirMemed, setExibirMemed] = useState(false)

  const { memed } = useSelector(state => state.prontuario)
  const { visualizarPrescricaoGerada, hide, open } = useMemed()

  const dadosDaMemed = useMemo(() => {
    return extrairDadosDaMemed(memed)
  }, [memed])

  const {
    autenticarMedicoNaMemed,
    exibirPrescricaoMemedDoHistorico,
    limparPrescricaoMemedNoFormulario
  } = useProntuario()

  const abrirHistorico = () => setExibirHistorico(true)
  const fecharHistorico = () => setExibirHistorico(false)

  const abrirMemed = async () => {
    if (dadosDaMemed.integrada) {
      open()
    }
    setExibirMemed(true)
  }

  const fecharMemed = () => {
    limparPrescricaoMemedNoFormulario()
    setExibirMemed(false)
  }

  useEffect(() => {
    (async () => {
      if (dadosDaMemed.integrada && dadosDaMemed.historico) {
        limparPrescricaoMemedNoFormulario()
        await new Promise(r => setTimeout(r, 4000))
        hide()
        visualizarPrescricaoGerada(dadosDaMemed.historico)
      }
    })()
  }, [dadosDaMemed.historico, dadosDaMemed.integrada, hide, visualizarPrescricaoGerada, limparPrescricaoMemedNoFormulario])

  const adicionarPrescricaoMemedDoHistorico = async identificadorExterno => {
    if (dadosDaMemed.integrada) {
      visualizarPrescricaoGerada(identificadorExterno)
    } else {
      exibirPrescricaoMemedDoHistorico(identificadorExterno)
      abrirMemed()
    }
    fecharHistorico()
  }

  const childrenModificado = Children.map(children, (child, index) =>
    child &&
    React.cloneElement(child, {
      abrirHistorico: () => abrirHistorico(),
      limparHistorico: limparPrescricaoDoHistorico,
      abrirMemed: () => abrirMemed(),
      textoAdicionado,
      identificadorDoAtendimento,
      identificadorDoPaciente
    })
  )

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <Modal
        isOpen={exibirHistorico}
        className='modal modal-historico-de-prescricoes'
        contentLabel='Modal de histórico das prescrições'
      >
        <Historico
          fechar={fecharHistorico}
          historico={historico}
          adicionar={adicionarPrescricaoDoHistorico}
          adicionarMemed={adicionarPrescricaoMemedDoHistorico}
          temLifecode={temLifecode}
          temMemed={temMemed}
        />
      </Modal>
      <Modal
        isOpen={exibirMemed}
        className='modal modal-prescricoes-memed'
        contentLabel='Modal de prescrições da Memed'
      >
        <Memed
          autenticar={autenticarMedicoNaMemed}
          fechar={fecharMemed}
          identificadorDoAtendimento={identificadorDoAtendimento}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      {childrenModificado}
    </r-cell>
  )
}

const extrairDadosDaMemed = memed => ({
  excluida: memed.prescricaoMemedExcluidaNoFormulario,
  incluida: memed.prescricaoMemedIncluidaNoFormulario,
  historico: memed.prescricaoMemedHistorico,
  integrada: memed.integrada,
  userToken: memed.userToken,
})