import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import constantes from '../constantes'

export const configurarFirestore = () => {
  firebase.initializeApp(constantes.configuracoesDoFirestoreDoTarefas)
  return firebase.firestore()
}

export const useFirebase = () => {
  return firebase
}