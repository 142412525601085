import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO,
  LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO,
} from '../../tipos'

const adicionouAgendamentoDoWizardDeAgendamento = (identificadorDoProfissional, agendamento) => ({
  type: ADICIONOU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  identificadorDoProfissional,
  agendamento,
})

const erroAoAdicionarAgendamentoDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const adicionarAgendamentoDoWizardDeAgendamento = (identificadorDoProfissional, agendamento) => async dispatch => {
  dispatch({ type: ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO })

  try {
    let resultado = await api.adicionarAgendamento(identificadorDoProfissional, agendamento)
    dispatch(adicionouAgendamentoDoWizardDeAgendamento(identificadorDoProfissional, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAgendamentoDoWizardDeAgendamento(erro, { identificadorDoProfissional, agendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar o agendamento.')))

    return false
  }
}

const excluiuAgendamentoDoWizardDeAgendamento = dadosParaExcluirAgendamento => ({
  type: EXCLUIU_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  dadosParaExcluirAgendamento,
})

const erroAoExcluirAgendamentoDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const excluirAgendamentoDoWizardDeAgendamento = dadosParaExcluirAgendamento => async dispatch => {
  dispatch({ type: EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.excluirAgendamento(dadosParaExcluirAgendamento)

    dispatch(excluiuAgendamentoDoWizardDeAgendamento(dadosParaExcluirAgendamento))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirAgendamentoDoWizardDeAgendamento(erro, { dadosParaExcluirAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao excluir o agendamento.')))

    return false
  }
}

const listouUnidadesDaEmpresaDoWizardDeAgendamento = unidades => ({
  type: LISTOU_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO,
  unidades,
})

const erroAoListarUnidadesDaEmpresaDoWizardDeAgendamento = erro => ({
  type: ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO,
  erro
})

export const listarUnidadesDaEmpresaDoWizardDeAgendamento = () => async dispatch => {
  dispatch({ type: LISTAR_UNIDADES_DA_EMPRESA_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarUnidadesDaEmpresa()

    dispatch(listouUnidadesDaEmpresaDoWizardDeAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUnidadesDaEmpresaDoWizardDeAgendamento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as unidades da empresa.')))
  }
}

const listouHorariosLivresDoWizardDeAgendamento = horariosLivres => ({
  type: LISTOU_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  horariosLivres,
})

const erroAoListarHorariosLivresDoWizardDeAgendamento = (erro, parametros)=> ({
  type: ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const listarHorariosLivresDoWizardDeAgendamento = filtros => async dispatch => {
  dispatch({ type: LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarHorariosLivresDoAgendamento(filtros)

    dispatch(listouHorariosLivresDoWizardDeAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarHorariosLivresDoWizardDeAgendamento(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os horários livres.')))

    return false
  }
}

export const limparHorariosLivresDoWizardDeAgendamento = () => ({
  type: LIMPAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO
})

export const limparDadosDoWizardDeAgendamento = () => ({
  type: LIMPAR_DADOS_DO_WIZARD_DE_AGENDAMENTO
})