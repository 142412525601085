import { LISTOU_ESTADOS_DA_MEMORIA_ATUAL } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_ESTADOS_DA_MEMORIA_ATUAL: {
      return action.estadosDaMemoriaAtual
    }

    default: {
      return state
    }
  }
}