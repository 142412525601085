import React, {useEffect, useRef, useState} from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Breadcrumb from '../../breadcrumb'
import Spinner from '../../spinner'
import {DatePicker, Formulario as FormularioDoFormik, Input, Select} from '../../formik/formulario'
import {Coluna, Tabela} from '../../tabela'
import {dataIgualOuAnterior, formatarDataEHoraParaFormatoLocal} from '../../../bibliotecas/data'
import Permissao, {usePossuiAsPermissoes} from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import * as Yup from 'yup'
import useCliqueForaDoElemento from '../../../bibliotecas/clique-fora-do-elemento'
import ConfirmarAgendamento from './confirmar-agendamento'
import AlterarAgendamento from './visualizar-agendamento'
import moment from 'moment'

import {LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO} from '../../../acoes/tipos'

export default function Lista(props) {
  const {
    agendamento,
    confirmarAgendamento,
    cancelarAgendamento,
    dadosDaQueryString,
    excluirAgendamento,
    fotos,
    identificadorDoUsuarioLogado,
    match,
    motivosDeCancelamento,
    paginaDeDados,
    pesquisar,
    profissional,
    profissionais,
    recuperarAgendamentoPeloIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    unidades
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [exibirFiltro, setExibirFiltro] = useState(false)
  const [exibirAgendamento, setExibirAgendamento] = useState(false)
  const [exibirConfirmacaoDeAgendamento, setExibirConfirmacaoDeAgendamento] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState('')
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [exibirCancelar, setExibirCancelar] = useState(false)
  const [fezAPrimeiraRequisicao, setFezAPrimeiraRequisicao] = useState(false)
  const [filtrouPorQueryString, setFiltrouPorQueryString] = useState(false)
  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE])
  const podePesquisar = usePossuiAsPermissoes([permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE])

  const estiloDoModal = {
    content: {
      height: exibirCancelar || exibirExcluir ? '95%' : '',
    }
  }

  const [filtros, setFiltros] = useState({
    ...props.filtros,
    unidades: props.filtros.unidades.length > 0 ? props.filtros.unidades : unidades.map(x => x.identificador)
  })

  const referenciaDoFiltro = useRef(null)
  const naoExibirFiltroUnidade = unidades.length === 1

  useCliqueForaDoElemento(referenciaDoFiltro, () => setExibirFiltro(false))

  useEffect(() => {
    (async () => {
      const requisicaoConcluida = await pesquisar(filtros, pagina, ordenacao)

      if (requisicaoConcluida) {
        setFezAPrimeiraRequisicao(true)
      }
    })()
  }, [filtros, pagina, ordenacao, pesquisar])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    setFiltros(filtros)
    setPagina(1)
  }

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const alterarUnidade = unidades => alterarFiltros({ ...filtros, unidades })

  useEffect(() => {
    if (dadosDaQueryString && fezAPrimeiraRequisicao && !filtrouPorQueryString) {
      const profissional = profissionais?.filter(x => x.identificadorPublico === dadosDaQueryString.profissional)

      if (profissional.length > 0) {
        const filtrosDaQueryString = {
          ...filtros,
          termo: dadosDaQueryString.paciente,
          profissionais: [profissional[0]?.identificador],
          inicio: moment(dadosDaQueryString.inicio).format('YYYY-MM-DD HH:mm:ss'),
          fim: moment(dadosDaQueryString.fim).format('YYYY-MM-DD HH:mm:ss'),
        }

        alterarFiltros(filtrosDaQueryString)
        setFiltrouPorQueryString(true)
      }
    }
  }, [dadosDaQueryString, fezAPrimeiraRequisicao, filtrouPorQueryString, profissionais, filtros])

  const adicionarFiltro = filtro => {
    const novosFiltros = [...filtros.unidades, filtro]
    alterarUnidade(novosFiltros)
  }

  const removerFiltro = filtro => {
    const novosFiltros = filtros.unidades.filter(x => x !== filtro)
    alterarUnidade(novosFiltros)
  }

  const aplicarFiltros = formulario => {
    const ehHoje = moment(formulario.inicio).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')

    if (formulario.inicio && formulario.fim)
      alterarFiltros({
        ...filtros,
        inicio: ehHoje ? moment().format('YYYY-MM-DD HH:mm:ss') : moment(formulario.inicio).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        fim: moment(formulario.fim).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        profissionais: formulario.profissionais,
        termo: formulario.paciente
      })
  }

  const renderizarStatusDoAgendamento = status => {
    const cor = status === 'Agendado' ? '#f277c8' : (status === 'Reagendado' ? '#F1C40F' : '#1ABC9C')

    return (
      <p className='table-items__status'>
        <span className='table-items__status__bg' style={{ backgroundColor: cor }}></span>
        <span className='table-items__status__text'>{status}</span>
      </p>
    )
  }

  function abrirAlterar(agendamentoSelecionado) {
    const recuperar = async () => {
      const recuperou = await recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)

      if (recuperou) {
        setAgendamentoSelecionado(agendamentoSelecionado)
        setExibirAgendamento(true)
      }
    }
    recuperar()
  }

  function fecharAlterar() {
    setExibirAgendamento(false)
    setExibirCancelar(false)
    setExibirExcluir(false)
    pesquisar(filtros, pagina, ordenacao)
  }

  function abrirConfirmar(agendamentoSelecionado) {
    const recuperar = async () => {
      const recuperou = await recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)

      if (recuperou)
        setExibirConfirmacaoDeAgendamento(true)
    }
    recuperar()
  }

  function fecharConfirmar() {
    setExibirConfirmacaoDeAgendamento(false)
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <Modal
        isOpen={exibirAgendamento}
        style={estiloDoModal}
        className='modal'
        contentLabel='Modal para visualização do agendamento'
      >
        <AlterarAgendamento
          agendamento={agendamento}
          agendamentoSelecionado={agendamentoSelecionado}
          cancelarAgendamento={cancelarAgendamento}
          excluirAgendamento={excluirAgendamento}
          fotos={fotos}
          identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
          match={match}
          motivosDeCancelamento={motivosDeCancelamento}
          fechar={fecharAlterar}
          profissional={profissional}
          recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
          selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
          selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
          setExibirCancelar={setExibirCancelar}
          exibirCancelar={exibirCancelar}
          setExibirExcluir={setExibirExcluir}
          exibirExcluir={exibirExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirConfirmacaoDeAgendamento}
        className='modal'
        contentLabel='Modal para confirmação'
      >
        <ConfirmarAgendamento
          fechar={fecharConfirmar}
          confirmarAgendamento={confirmarAgendamento}
          agendamento={agendamento}
          fotos={fotos}
          pesquisar={pesquisar}
          filtros={filtros}
          pagina={pagina}
          ordenacao={ordenacao}
        />
      </Modal>
      <ReactTooltip
        id={`info_${agendamento.identificador}`}
        place='right'
        effect='solid'
        type='info'
        key={agendamento.identificador}
        className='unity-infos__item'
      >
        <div
          style={{
            marginBottom: 8,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'rgb(232, 232, 232)'
          }}><strong>Histórico do Status</strong></div>
        {agendamento && agendamento.status.map(x =>
          <span key={x.dataEHora}>
            <span><p><strong>{x.nome}</strong></p></span>
            <span><p>{x.observacoes}</p></span>
            <span><em>{x.usuario.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(x.dataEHora)}</em></span>
          </span>
        )}
      </ReactTooltip>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-center'>
              <r-cell span={4} span-md={3} span-lg={6}>
                <Breadcrumb />
              </r-cell>
              {!naoExibirFiltroUnidade &&
                <r-cell span={4} span-md={3} span-lg='10-12'>
                  <div className='custom-select d-md-block' ref={referenciaDoFiltro}>
                    <div
                      className={`filter-list ${exibirFiltro && 'is-active'}`}
                      onClick={() => setExibirFiltro(!exibirFiltro)}
                    >
                      <div className='filter-list__toggle'>Escolher Unidades</div>
                      <div className='filter-list__drop'>
                        <div className='filter-list__drop__arrow'></div>
                        {unidades.map(x => (
                          <div className='checkbox' key={`check_unidades_${x.identificador}`}>
                            <input
                              className='checkbox-input'
                              onChange={evento => evento.target.checked ? adicionarFiltro(x.identificador) : removerFiltro(x.identificador)}
                              id={`check_unidades_${x.identificador}`}
                              name={`check_unidades_${x.identificador}`}
                              type='checkbox'
                              defaultChecked={filtros.unidades.includes(x.identificador)}
                              disabled={filtros.unidades.length === 1 && filtros.unidades.includes(x.identificador)}
                            />
                            <label className='checkbox-label' htmlFor={`check_unidades_${x.identificador}`}><span>{x.nome}</span></label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </r-cell>
              }
            </r-grid>
          </div>
          <FormularioDoFormik
            reinicializar={true}
            acao={aplicarFiltros}
            valoresIniciais={{
              inicio: filtros.inicio,
              fim: filtros.fim,
              profissionais: filtros.profissionais,
              paciente: filtros.termo,
            }}
            esquemaDeValidacoes={Yup.object().shape({
              inicio: Yup.string()
                .nullable()
                .test(
                  "fim_teste",
                  "Deve ser menor que o fim.",
                  function (value) {
                    const { fim } = this.parent;
                    return !value || dataIgualOuAnterior(value, fim);
                  }
                ),
              fim: Yup.string()
                .nullable()
                .test(
                  "fim_teste",
                  "Deve ser maior que o início",
                  function (value) {
                    const { inicio } = this.parent;
                    return !value || dataIgualOuAnterior(inicio, value);
                  }
                ),
            })}
          >
            {({ values }) => (
              <div className='mt-1'>
                <r-grid columns-md={12} columns-lg={12}>
                  <r-cell span={4} span-md={2} span-lg={2}>
                    <DatePicker
                      nome='inicio'
                      minDate={moment().toDate()}
                      tabIndex={1}
                      titulo='Início'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={2} span-lg={2}>
                    <DatePicker
                      nome='fim'
                      minDate={moment(values.inicio).toDate()}
                      tabIndex={2}
                      titulo='Fim'
                    />
                  </r-cell>
                  <r-cell span={4} span-md={3} span-lg={3}>
                    <Input
                      nome='paciente'
                      titulo='Paciente'
                      placeholder='Informe o nome do paciente'
                      tabIndex={3}
                    />
                  </r-cell>
                  <r-cell span={4} span-md={3} span-lg={3}>
                    <Select
                      nome='profissionais'
                      titulo='Profissional'
                      itens={profissionais}
                      campoCodigo='identificador'
                      campoDescricao='nome'
                      tabIndex={4}
                      isMulti
                    />
                  </r-cell>
                  <r-cell span={4} span-md={2} span-lg={2}>
                    {podePesquisar && <button className='button --primary w-100 minw-auto alinha-botao' type='submit'>Pesquisar</button>}
                  </r-cell>
                </r-grid>
              </div>
            )}
          </FormularioDoFormik>
          <Spinner operacoes={[LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar'
                    permissoes={[permissoes.ALTERAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
                    permissoes.CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]}
                  >
                    <button
                      className='table-items__bt --edit'
                      title='Editar'
                      type='button'
                      onClick={() => abrirAlterar(item)}
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ),
                item => {
                  const permitidoConfirmar = item.status[0].nome && item.status[0].nome !== 'Confirmado'
                  return (permitidoConfirmar && podeConfirmar && (
                    <button
                      className='table-items__bt --edit'
                      title='Confirmar'
                      type='button'
                      key='confirmar'
                      onClick={() => abrirConfirmar(item)}
                    >
                      <i className='icon icon-checked'></i>
                    </button>
                  ))
                }
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
              filtros={filtros}
              marcacaoDeErro={item => (item.bloqueado || (item.horario && item.horario.unidade.identificador !== item.unidade.identificador)) && item.status[0].nome !== 'Cancelado'}
            >
              <Coluna
                className='col-xs-2 col-md-1 col-lg-1'
                foto={true}
                fotos={fotos}
                identificadorDaFoto={item => item.paciente.foto}
              />
              <Coluna
                className='col-xs-6 col-md-9 col-lg-2'
                principal={true}
                nome='Paciente'
                renderizar={item => item.paciente.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Profissional'
                renderizar={item => item.profissional.nome}
              />
              <Coluna
                className='col-lg-2'
                nome='Tipo'
                campoDeDados='tipo'
              />
              <Coluna
                className='col-lg-1'
                nome='Unidade'
                renderizar={item => item.unidade.sigla}
              />
              <Coluna
                className='col-lg-1'
                nome='Data/Hora'
                renderizar={item => item.inicio && moment(item.inicio, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}
              />
              <Coluna
                campoDeDados='status'
                className='col-lg-2'
                filtros={['Agendado', 'Reagendado', 'Confirmado']}
                nome='Status'
                renderizar={item => renderizarStatusDoAgendamento(item.status[0].nome)}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}