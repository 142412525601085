import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_TIPOS_DE_SALA,
  LISTOU_TIPOS_DE_SALA,
  ERRO_AO_LISTAR_TIPOS_DE_SALA
} from '../tipos'

const listouTiposDeSala = tiposDeSala => ({
  type: LISTOU_TIPOS_DE_SALA,
  tiposDeSala
})

const erroAoListarTiposDeSala = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_SALA,
  erro
})

export const listarTiposDeSala = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_SALA })

  try {
    const resultado = await api.listarTiposDeSala()

    dispatch(listouTiposDeSala(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeSala(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de sala.')))
  }
}