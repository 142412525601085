import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ENFERMEIROS,
  LISTOU_ENFERMEIROS,
  ERRO_AO_LISTAR_ENFERMEIROS
} from './tipos'

const listouEnfermeiros = enfermeiros => ({
  type: LISTOU_ENFERMEIROS,
  enfermeiros,
})

const erroAoListarEnfermeiros = erro => ({
  type: ERRO_AO_LISTAR_ENFERMEIROS,
  erro,
})

export const listarEnfermeiros = () => async dispatch => {
  dispatch({ type: LISTAR_ENFERMEIROS })

  try {
    const resultado = await api.listarEnfermeiros()

    dispatch(listouEnfermeiros(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEnfermeiros(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os enfermeiros.')))
  }
}