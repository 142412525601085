import React, { useState, useCallback } from 'react'
import ReactFlow, {
  Handle,
  MiniMap,
  Controls,
  Background,
  isNode
} from 'react-flow-renderer'
import dagre from 'dagre'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 172
const nodeHeight = 80

const getLayoutedElements = (elements) => {
  dagreGraph.setGraph({ rankdir: 'TB' })

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
    } else {
      dagreGraph.setEdge(el.source, el.target)
    }
  })

  dagre.layout(dagreGraph)

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id)
      el.targetPosition = 'top'
      el.sourcePosition = 'bottom'

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      }
    }

    return el
  })
}

const atualizarEdges = (elements, element) => {
  const dados = elements.map(el => {
    if (isNode(el)) return el

    return {
      ...el,
      animated: element.id && (el.target === element.id || el.source === element.id),
      style: element.id && el.target === element.id ? { stroke: '#87d932' } : (element.id && el.source === element.id ? { stroke: '#f6ab6c' } : {})
    }
  })

  return dados
}

const CustomNodeComponent = ({ data }) => {
  return (
    <div style={customNodeStyles}>
      <Handle
        type='target'
        position='top'
        style={{ borderRadius: 50 }}
        isConnectable={false} />
      <div>{data.label}</div>
      <Handle
        type='source'
        position='bottom'
        id='l1'
        style={{ left: '30%', borderRadius: 50 }}
        isConnectable={false}
      />
      <Handle
        type='source'
        position='right'
        id='l2'
        isConnectable={false}
      />
      <Handle
        type='target'
        position='bottom'
        id='l3'
        style={{ left: '70%', borderRadius: 50 }}
        isConnectable={false}
      />
    </div>
  )
}

const nodeTypes = {
  special: CustomNodeComponent,
}

const customNodeStyles = {
  background: '#fff',
  borderColor: '#1a192b',
  padding: '10px',
  borderRadius: '3px',
  width: '150px',
  fontSize: '12px',
  color: '#222',
  textAlign: 'center',
  borderWidth: '1px',
  borderStyle: 'solid',
}

export default function Grafico({ elementosIniciais }) {
  const [layoutedElements, setLayoutedElements] = useState(getLayoutedElements(elementosIniciais))

  const [rfInstance, setRfInstance] = useState(null)

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    setRfInstance(reactFlowInstance)
  }

  const onNodeMouseEnter = (event, element) => {
    const dados = atualizarEdges(layoutedElements, element)
    setLayoutedElements(dados)
  }

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject()

      const dados = atualizarEdges(flow.elements, {})
      setLayoutedElements(dados)
    }
  }, [rfInstance])

  return (
    <ReactFlow
      elements={layoutedElements}
      nodesConnectable={false}
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseLeave={onSave}
      onLoad={onLoad}
      nodeTypes={nodeTypes}
    >
      <MiniMap />
      <Controls showInteractive={false} />
      <Background color='#aaa' gap={16} />
    </ReactFlow>

  )
}