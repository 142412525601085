import React, { useEffect, useMemo, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useUsuarioLogado } from '../../../seguranca/provedor-de-autenticacao'
import { useTarefas } from '../../contexto'
import { Tabela, Coluna } from './tabela-de-comentarios'
import constantes from '../../../../configuracoes/constantes'
import { formatarSegundosENanosegundosParaFormatoLocal } from '../../../../bibliotecas/data'
import { Chat, Clock } from '../../../../design/icones'
import Novo from './novo'
import tabelaVazia from '../../../../design/img/empty-data.svg'
import Filtro from '../comentarios/filtro'

export default function Comentarios(props) {
  const { identificadorDaTarefa, tipoDaTarefa, statusDaTarefa, valor, ehFormulario = false } = props

  const {
    adicionarComentarioDaTarefa,
    alterarComentarioDaTarefa,
    fotos = {},
    recuperarFotoDoUsuarioPorIdentificador,
    removerComentarioDaTarefa,
    profissionaisResponsaveis,
    tiposDeTarefa
  } = useTarefas()

  const { usuario } = useUsuarioLogado()
  const [exibirComentariosDoSistema, setExibirComentariosDoSistema] = useState(false)
  const possuiComentariosDoSistema = valor.filter(t => t.quem.nome === 'LifeCode').length > 0

  useEffect(() => {
    const usuariosDoComentario = valor && profissionaisResponsaveis?.filter(x => x.foto && valor.some(i => i.quem.identificador === x.identificadorPublico))

    usuariosDoComentario && usuariosDoComentario.forEach(item => {
      recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
    })

  }, [valor, profissionaisResponsaveis, recuperarFotoDoUsuarioPorIdentificador])

  const confirmarERemover = registro => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o comentário?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerComentarioDaTarefa(identificadorDaTarefa, registro.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  const alterarComentario = async formulario => {
    const comentario = {
      texto: formulario.texto
    }

    return await alterarComentarioDaTarefa(identificadorDaTarefa, formulario.identificador, comentario)
  }

  function temComentarioPorStatusDaTarefa() {
    if (!tipoDaTarefa) return false

    let existeComentario = false
    const tipo = tiposDeTarefa[tipoDaTarefa.identificador]

    tipo && tipo.versoes.forEach((item) => {
      const status = item.status.filter(x => x.nome === statusDaTarefa)
      if (status.length > 0) {
        existeComentario = status[0].componentes.some(x => x.nome === 'comentarios')
      }
    })

    return existeComentario
  }

  function getProfissao(identificadorPublico) {
    const responsavel = profissionaisResponsaveis?.find(x => x.identificadorPublico === identificadorPublico)

    let texto = ''

    if (responsavel) {
      let profissao = responsavel.profissao

      if (profissao) {
        texto = ' | ' + profissao.nome
      }

      if (profissao && profissao.especialidades.length > 0) {
        const especialidades = profissao.especialidades.join(', ')
        texto += ' - ' + especialidades
      }
    }

    return texto
  }

  const comentarios = useMemo(() => {
    const todos = valor

    if (exibirComentariosDoSistema) return todos
    return todos.filter(t => t.quem.nome !== 'LifeCode')
  }, [valor, exibirComentariosDoSistema])

  const podeEditar = temComentarioPorStatusDaTarefa()

  if (!podeEditar && valor.length === 0) {
    if (ehFormulario) return null

    return (
      <div className='table-items__result'>
        <button className='icon-tabela-vazia'>
          <img src={tabelaVazia} alt='tabelaVazia' />
        </button>
        <p>Não existem comentários nessa tarefa.</p>
      </div>
    )
  }

  return (
    <>
      {ehFormulario &&
        <div className='card-componente'>
          <div className='icon comentarios'><Chat /></div>
          <label className='titulo'>Comentários</label>
        </div>
      }
      {podeEditar && <Novo adicionar={adicionarComentarioDaTarefa} identificadorDaTarefa={identificadorDaTarefa} />}
      {possuiComentariosDoSistema && <Filtro alterarFiltro={() => setExibirComentariosDoSistema(!exibirComentariosDoSistema)} exibido={exibirComentariosDoSistema} />}
      <Tabela
        alterar={alterarComentario}
        acoes={[
          item =>
            <div key='remover'>
              <button
                onClick={() => confirmarERemover(item)}
                type='button'
                className='table-items-expanded__bt --remove'
                title='Remover'
              >
                <i className='icon icon-minus'></i>
              </button>
            </div>
        ]}
        dados={comentarios}
        usuario={usuario}
        edicao={podeEditar}
      >
        <Coluna
          className='table-items-expanded-90__box-item'
          foto={true}
          fotos={fotos}
          renderizar={item =>
            <>
              <strong>{item.quem.nome}</strong>{getProfissao(item.quem.identificador)}
              <div className='form-table-icon'><Clock size='18' />{formatarSegundosENanosegundosParaFormatoLocal(item.quando)}</div>
            </>
          }
        />
        <Coluna
          className='table-items-expanded-100__box-item comentario'
          comentario={true}
          renderizar={item => <pre>{item.texto}</pre>}
        />
      </Tabela>
    </>
  )
}