import api from '../../../api-major'

const adicionarAvaliacaoDaSolidaoDoPaciente = (identificador, avaliacao) => api.post(`/pacientes/${identificador}/avaliacoes-da-solidao`, avaliacao)
const alterarAvaliacaoDaSolidaoDoPaciente = (identificador, avaliacao) => api.put(`/pacientes/${identificador}/avaliacoes-da-solidao/${avaliacao.identificador}`, avaliacao)
const removerAvaliacaoDaSolidaoDoPaciente = (identificador, identificadorDaAvaliacao) => api.delete(`/pacientes/${identificador}/avaliacoes-da-solidao/${identificadorDaAvaliacao}`)
const listarAvaliacoesDaSolidaoDoPaciente = identificador => api.get(`/pacientes/${identificador}/avaliacoes-da-solidao`)
const listarFrequenciasNaEscalaDaSolidao = () => api.get('/frequencias-na-escala-da-solidao?pagina=1&tamanhoDaPagina=100000&ordem=pontuacao')

export default { 
  adicionarAvaliacaoDaSolidaoDoPaciente,
  alterarAvaliacaoDaSolidaoDoPaciente,
  removerAvaliacaoDaSolidaoDoPaciente,
  listarAvaliacoesDaSolidaoDoPaciente,
  listarFrequenciasNaEscalaDaSolidao
}