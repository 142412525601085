import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_GRAUS_DE_RELACOES, LISTOU_GRAUS_DE_RELACOES, ERRO_AO_LISTAR_GRAUS_DE_RELACOES } from './tipos'

const listouGrausDeRelacoes = grausDeRelacoes => ({
    type: LISTOU_GRAUS_DE_RELACOES,
    grausDeRelacoes,
})

const erroAoListarGrausDeRelacoes = erro => ({
    type: ERRO_AO_LISTAR_GRAUS_DE_RELACOES,
    erro,
})

export const listarGrausDeRelacoes = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_GRAUS_DE_RELACOES })

    try {
        let resultado = await api.listarGrausDeRelacoes()

        dispatch(listouGrausDeRelacoes(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoListarGrausDeRelacoes(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os graus de relações.')))
    }
  }

  acao.meta = {
    cache: LISTAR_GRAUS_DE_RELACOES
  }

  return acao
}