import React, { useEffect, useMemo } from 'react'
import { Report } from 'powerbi-report-component'
import { PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR } from '../../acoes/integracoes/relatorios-do-power-bi'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI } from '../../acoes/tipos'
import Spinner from '../spinner'

export default function PacienteElegivel({ powerBI, recuperarTokenDosRelatoriosDoPowerBI, voltar }) {
  const { relatorios } = powerBI
  const { token, url, identificador } = relatorios[PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR]

  const relatoriosDoPowerBI = useMemo(() => converterObjetoParaArray(relatorios).map(x => x.codigo), [relatorios])

  useEffect(() => {
    if (relatoriosDoPowerBI.length > 0) {
      recuperarTokenDosRelatoriosDoPowerBI(relatoriosDoPowerBI)
    }
  }, [recuperarTokenDosRelatoriosDoPowerBI, relatoriosDoPowerBI])

  return (
    <div className='mt-2'>
      <Spinner operacoes={[RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI]}>
        {({ processando }) => {
          if (processando) {
            return <h2 className='verificando-permissoes'>Verificando permissões...</h2>
          }
          if (!token || !url || !identificador) {
            return null
          }
          return (
            <>
              <Report
                tokenType='Embed'
                accessToken={token}
                embedUrl={url}
                embedId={identificador}
                reportMode='view'
                permissions='View'
                style={{ height: '800px' }}
                extraSettings={{
                  filterPaneEnabled: false,
                  navContentPaneEnabled: true,
                }}
              />
              <div className='list-btn'>
                <button onClick={voltar} type='button' className='button --light'>Voltar</button>
              </div>
            </>
          )
        }}
      </Spinner>
    </div>
  )
}