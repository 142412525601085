import React, { useEffect, useState } from 'react'
import AbasDaAgenda from './abas'
import Cabecalho from '../cabecalho'
import Spinner from '../../spinner'
import * as Yup from 'yup'
import { Formulario as FormularioDoFormik, Select, YearPicker } from '../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import { Coluna, Tabela } from '../../tabela'
import { confirmAlert } from 'react-confirm-alert'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import moment from 'moment'

import {
  LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTAR_FERIADOS,
  ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
} from '../../../acoes/tipos'



export default function FeriadosTrabalhados(props) {
  const {
    adicionarFeriadoTrabalhadoDoProfissional,
    listarFeriados,
    listarFeriadosTrabalhadosDoProfissional,
    removerFeriadoTrabalhadoDoProfissional,
    fotos,
    history,
    match,
    recuperarPorIdentificador,
    usuario = {},
    feriadosTrabalhados,
    feriados = []
  } = props

  const { identificador } = match.params

  const podeAdicionarFeriadoTrabalhado = usePossuiAsPermissoes([permissoes.ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL])

  const [ano, setAno] = useState(moment())

  useEffect(() => {
    listarFeriadosTrabalhadosDoProfissional(identificador)
  }, [listarFeriadosTrabalhadosDoProfissional, identificador])

  useEffect(() => {
    listarFeriados({ ano }, 1, { ordem: "+", nome: "data" }, 1000)
  }, [listarFeriados, ano])

  const adicionar = async (formulario, { resetForm }) => {
    const adicionou = await adicionarFeriadoTrabalhadoDoProfissional(identificador, formulario)

    if (adicionou) {
      resetForm()
      listarFeriadosTrabalhadosDoProfissional(identificador)
    }
  }

  const removerSelecionado = async item => {
    const removeu = await removerFeriadoTrabalhadoDoProfissional(identificador, item.feriado.identificador)

    if (removeu) {
      listarFeriadosTrabalhadosDoProfissional(identificador)
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o feriado trabalhado: ${item.feriado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <>
      <Spinner operacoes={[LISTAR_FERIADOS, LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL, ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL, REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL]}>
        <FormularioDoFormik
          valoresIniciais={{
            feriado: '',
            ano: ano
          }}
          acao={adicionar}
          reinicializar={true}
          esquemaDeValidacoes={Yup.object().shape({
            feriado: Yup.string().required('Obrigatório'),
          })}
        >
          {({ values }) => (
            <>
              <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
              <div className='mt-2 mb-4'>
                <div>
                  <fieldset>
                    <h2 className='form-title'>Agenda</h2>
                    <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                      <r-cell span={4} span-md={6} span-lg={9}>
                        <AbasDaAgenda identificador={identificador} url={match.url} usuario={usuario} />
                      </r-cell>
                    </r-grid>
                    <r-grid columns-md={6} columns-lg={12} class='mt-3'>
                      {podeAdicionarFeriadoTrabalhado && <>
                        <r-cell span={4} span-md={2} span-lg={2}>
                          <YearPicker
                            nome='ano'
                            titulo='Ano *'
                            tabIndex={1}
                            minDate={moment({ year: 2024 }).toDate()}
                            onChange={setAno}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={5}>
                          <Select
                            nome='feriado'
                            campoCodigo='identificador'
                            campoDescricao='label'
                            itens={feriados.map(x => ({ ...x, label: `${formatarDataParaFormatoLocal(x.data)} - ${x.nome}` }))}
                            tabIndex={1}
                            titulo='Feriados Trabalhados'
                          />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={3}>
                          <button
                            type='submit'
                            className={'button --primary w-100 mt-24'}
                            disabled={!values}
                          >
                            Adicionar
                          </button>
                        </r-cell>
                      </>
                      }
                    </r-grid>
                    <Tabela
                      acoes={[
                        item => (
                          <Permissao key='remover' permissoes={[permissoes.REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL]}>
                            <button
                              className='table-items__bt --remove'
                              title='Remover'
                              type='button'
                              onClick={() => confirmarERemover(item)}
                            >
                              <i className='icon icon-remove'></i>
                            </button>
                          </Permissao>

                        ),
                      ]}
                      chave='identificador'
                      className='--secondary my-4'
                      dados={feriadosTrabalhados}
                    >
                      <Coluna
                        campoDeDados='feriado'
                        className='col-lg-6'
                        principal={true}
                        nome='Feriado'
                        renderizar={item => `${formatarDataParaFormatoLocal(item.feriado.data)} - ${item.feriado.nome}`}
                      />
                    </Tabela>
                  </fieldset>
                  <div className='list-btn'>
                    <button
                      className='button --light'
                      type='button'
                      onClick={() => history.push(`/configuracoes/usuarios`)}
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}