import logando from './logando'
import logins from './logins'
import logado from './logado'

import {
  LOGOUT,
  ALTERAR_FILTROS_DE_USUARIOS,
  LISTOU_USUARIOS,
  ADICIONOU_USUARIO,
  ALTERAR_STATUS_DO_USUARIO,
} from '../../acoes/tipos'

const estadoInicial = {
  itemAtual: {
    ativo: null,
  },
  logado: undefined,
  tiposDeAtendimento: undefined,
  filtros: {
    termo: '',
    ativos: true,
  },
  pagina: null
}

const usuario = (state, action) => {
  switch (action.type) {
    case LOGOUT: {
      return estadoInicial
    }

    case ALTERAR_FILTROS_DE_USUARIOS: {
      return {
        ...state,
        filtros: action.filtros,
      }
    }

    case ALTERAR_STATUS_DO_USUARIO: {
      return {
        ...state,
        ativo: action.ativo,
      }
    }

    case LISTOU_USUARIOS: {

      return {
        ...state,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case ADICIONOU_USUARIO: {
      return {
        ...state,
        itemAtual: action.usuario,
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  let stateUsuario = usuario(state, action)

  return {
    ...stateUsuario,
    logado: logado(state.logado, action),
    logando: logando(state.logando, action),
    logins: logins(state.logins, action),
  }
}