import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Select, DatePicker, Formulario as Form, Input } from '../../formik/formulario'
import { dataIgualOuAnterior } from '../../../bibliotecas/data'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import moment from 'moment'
import Cabecalho from '../cabecalho'

import {
  ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE
} from '../../../acoes/tipos'

export default function FormularioDePlanoDeCuidado(props) {
  const {
    fotos,
    history,
    match,
    motivos,
    paciente,
    listarMotivosDeSaidaDosPlanosDeCuidado,
    listarPlanosDeCuidado,
    planosDeCuidado,
    recuperar,
    recuperarPorIdentificador,
    remover,
    salvar,
  } = props

  const OUTRO_MOTIVO = 'Outro motivo'
  const { identificador, identificadorDoPlanoDeCuidado } = match.params
  const planoDeCuidado = paciente.planosDeCuidado.selecionado || {}
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE])
  const permissaoParaRemover = usePossuiAsPermissoes([permissoes.REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE])

  const [finalizarPlanoDeCuidado, setFinalizarPlanoDeCuidado] = useState(false)

  useEffect(() => { listarMotivosDeSaidaDosPlanosDeCuidado() }, [listarMotivosDeSaidaDosPlanosDeCuidado])
  useEffect(() => { listarPlanosDeCuidado() }, [listarPlanosDeCuidado])

  useEffect(() => {
    if (planoDeCuidado.identificador) {
      setFinalizarPlanoDeCuidado(false)
      if (planoDeCuidado.fim) {
        setFinalizarPlanoDeCuidado(true)
      }
    }
  }, [planoDeCuidado])

  useEffect(() => {
    if (identificador && identificadorDoPlanoDeCuidado) {
      recuperar(identificador, identificadorDoPlanoDeCuidado)
    }
  }, [identificador, identificadorDoPlanoDeCuidado, recuperar])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o plano de cuidado ${planoDeCuidado.planoDeCuidado}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(identificador, planoDeCuidado.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE, ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE, RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE, REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          identificadorDoPaciente: identificador,
          identificador: planoDeCuidado.identificador || '',
          inicio: planoDeCuidado.inicio ? planoDeCuidado.inicio : '',
          fim: planoDeCuidado.fim ? planoDeCuidado.fim : '',
          planoDeCuidado: planoDeCuidado.planoDeCuidado || '',
          observacoes: planoDeCuidado.observacoes || '',
          motivoDeSaida: planoDeCuidado.motivoDeSaida || '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          inicio: Yup.string()
            .nullable()
            .required('Obrigatório'),
          fim: Yup.string()
            .nullable()
            .test(
              "fim_teste",
              "Fim de Vigência deve ser maior que o Início de Vigência",
              function (value) {
                const { inicio } = this.parent;
                return !value || dataIgualOuAnterior(inicio, value);
              }
            ),
          planoDeCuidado: Yup.string().required('Obrigatório'),
          motivoDeSaida: finalizarPlanoDeCuidado ? Yup.string().required('Obrigatório.') : undefined,
          observacoes: finalizarPlanoDeCuidado ? Yup.string()
            .when("motivoDeSaida", {
              is: val => val === OUTRO_MOTIVO,
              then: Yup.string().required('Obrigatório')
            }) : undefined,
        })}
      >
        {({ setFieldValue, values }) => (
          <>
            <Cabecalho paciente={paciente} fotos={fotos} identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} />
            <div className="mt-2">
              <fieldset>
                <h2 className="form-title">{`${planoDeCuidado.identificador ? 'Alterar Plano de Cuidado' : 'Adicionar Plano de Cuidado'}`}</h2>
                <r-grid columns-md='6' columns-lg='12'>
                  <r-cell span={4} span-md='2' span-lg='6'>
                    <Select
                      nome='planoDeCuidado'
                      campoCodigo='nome'
                      campoDescricao='nome'
                      itens={planosDeCuidado}
                      tabIndex={3}
                      titulo='Plano de Cuidado *'
                      isDisabled={planoDeCuidado.identificador}
                    />
                  </r-cell>
                  <r-cell span={4} span-md='2' span-lg='3'>
                    <DatePicker
                      nome='inicio'
                      tabIndex={4}
                      titulo='Início de Vigência *'
                      type='text'
                    />
                  </r-cell>
                  <r-cell span={4} span-md='2' span-lg='3'>
                    <DatePicker
                      nome='fim'
                      tabIndex={5}
                      titulo='Fim de Vigência'
                      type='text'
                      minDate={moment(values.inicio).toDate()}
                      onChange={valor => {
                        !valor && setFieldValue('motivoDeSaida', '')
                        setFinalizarPlanoDeCuidado(valor)
                      }
                      }
                    />
                  </r-cell>
                  <r-cell span={2} span-md='row'>
                    <Select
                      nome='motivoDeSaida'
                      titulo={finalizarPlanoDeCuidado ? 'Motivo de Saída *' : 'Motivo de Saída'}
                      itens={motivos}
                      campoCodigo='nome'
                      campoDescricao='nome'
                      isDisabled={!finalizarPlanoDeCuidado}
                      tabIndex={6}
                    />
                  </r-cell>
                  <r-cell span={4} span-md='row'>
                    <Input
                      as='textarea'
                      nome='observacoes'
                      tabIndex={7}
                      titulo={values.motivoDeSaida === OUTRO_MOTIVO ? 'Observações *' : 'Observações'}
                    />
                  </r-cell>
                </r-grid>
              </fieldset>
              {remover ?
                <div className='list-btn-unidades'>
                  {permissaoParaRemover && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                  {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                </div>
                :
                <div className='list-btn'>
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                </div>
              }
            </div>
          </>
        )}
      </Form>
    </Spinner>
  )
}