import React, { useState, useEffect } from 'react'
import Spinner from '../../../../spinner'
import { MultiplaEscolha } from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import { LISTAR_RESPOSTAS_DO_PHQ9 } from '../../../../../acoes/tipos'

const renderizarTitulo = valor => { return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Resumo(props) {
  const {
    valores,
    respostasDoPhq9,
    fechar
  } = props

  const [pontuacao, setPontuacao] = useState({})

  const atualizarPontuacao = (nome, codigo) => {
    setPontuacao(pontuacao => ({ ...pontuacao, [nome]: respostasDoPhq9.find(x => x.codigo === codigo).pontuacao }))
  }

  const recuperarPontuacao = (campo) => {
    if (pontuacao[campo] === undefined && valores[campo] !== undefined) {
      atualizarPontuacao(campo, valores[campo])
    }
  }

  return (
    <Spinner operacoes={[LISTAR_RESPOSTAS_DO_PHQ9]}>
      <r-grid columns-md={6} columns-lg={12} class='form form-group'>
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className='form-title'>Resumo do PHQ9</h2>
            <Paciente paciente={valores.paciente} />
            <Data data={valores.data} />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
              <Cabecalho />
              <PossuiPoucoInteresse respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.possuiPoucoInteresse} />
              <SeSenteDeprimido respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.seSenteDeprimido} />
              <DificuldadeParaDormir respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.dificuldadeParaDormir} />
              <SeSenteCansado respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.seSenteCansado} />
              <FaltaDeApetite respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.faltaDeApetite} />
              <SeSenteMalConsigoMesmo respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.seSenteMalConsigoMesmo} />
              <DificuldadeParaConcentrar respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.dificuldadeParaConcentrar} />
              <LentidaoParaMovimentarOuFalar respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.lentidaoParaMovimentarOuFalar} />
              <PensaEmSeFerir respostasDoPhq9={respostasDoPhq9} pontuacao={pontuacao} recuperarPontuacao={recuperarPontuacao} valor={valores.pensaEmSeFerir} />
              <Avaliacao pontuacao={valores.pontuacaoTotal} />
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            </div>
          </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-escala-ambiental data-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>PHQ9</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function PossuiPoucoInteresse({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('possuiPoucoInteresse')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='possuiPoucoInteresse'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Possui pouco interesse ou pouco prazer em fazer as coisas.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.possuiPoucoInteresse}</div>
    </r-cell>
  )
}

function SeSenteDeprimido({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('seSenteDeprimido')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteDeprimido'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Se sente para baixo, deprimido/a ou sem perspectiva.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.seSenteDeprimido}</div>
    </r-cell>
  )
}

function DificuldadeParaDormir({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('dificuldadeParaDormir')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='dificuldadeParaDormir'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Possui dificuldade para pegar no sono ou permanecer dormindo ou dorme mais do que de costume.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.dificuldadeParaDormir}</div>
    </r-cell>
  )
}

function SeSenteCansado({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('seSenteCansado')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteCansado'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='4. Se sente cansado/a ou com pouca energia.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.seSenteCansado}</div>
    </r-cell>
  )
}

function FaltaDeApetite({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('faltaDeApetite')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='faltaDeApetite'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='5. Possui falta de apetite ou está comendo demais.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.faltaDeApetite}</div>
    </r-cell>
  )
}

function SeSenteMalConsigoMesmo({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('seSenteMalConsigoMesmo')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='seSenteMalConsigoMesmo'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='6. Se sente mal consigo mesmo/a ou acha que você é um fracasso ou que decepcionou sua família ou você mesmo/a.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.seSenteMalConsigoMesmo}</div>
    </r-cell>
  )
}

function DificuldadeParaConcentrar({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('dificuldadeParaConcentrar')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='dificuldadeParaConcentrar'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='7. Possui dificuldade para se concentrar nas coisas, como ler o jornal ou ver televisão.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.dificuldadeParaConcentrar}</div>
    </r-cell>
  )
}

function LentidaoParaMovimentarOuFalar({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('lentidaoParaMovimentarOuFalar')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='lentidaoParaMovimentarOuFalar'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='8. Possui lentidão para se movimentar ou falar, a ponto das outras pessoas perceberem. Ou o oposto, estar tão agitado/a ou inquieto/a que fica andando de um lado o outro mais do que o de costume.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.lentidaoParaMovimentarOuFalar}</div>
    </r-cell>
  )
}

function PensaEmSeFerir({ respostasDoPhq9, pontuacao, recuperarPontuacao, valor }) {
  useEffect(() => {
    recuperarPontuacao('pensaEmSeFerir')
  }, [recuperarPontuacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='pensaEmSeFerir'
        opcoes={respostasDoPhq9.map(x => ({ codigo: x.codigo, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='9. Pensa em se ferir de alguma maneira ou que seria melhor estar morto/a.'
        renderizarTitulo={renderizarTitulo}
        valor={valor}
        leitura={true}
      />
      <div className='pontuacao'>{pontuacao.pensaEmSeFerir}</div>
    </r-cell>
  )
}

function Avaliacao({ pontuacao }) {
  const avaliacaoPhq9 = () => {
    if (pontuacao === 0) return ''

    if (pontuacao >= 1 && pontuacao <= 4) {
      return 'Depressão mínima'
    } else if (pontuacao >= 5 && pontuacao <= 9) {
      return 'Depressão leve'
    } else if (pontuacao >= 10 && pontuacao <= 14) {
      return 'Depressão moderada'
    } else if (pontuacao >= 15 && pontuacao <= 19) {
      return 'Depressão moderadamente grave'
    } else {
      return 'Depressão grave'
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(pontuacao || pontuacao === 0) &&
        <div className='resultado-questionario'>
          <div>
            RESULTADO PHQ9:{' '}<strong>{avaliacaoPhq9()}</strong>
            <div className='legenda'>
              Depressão mínima (1-4 pontos); Leve (5-9 pontos); Moderada (10-14 pontos); Moderadamente grave (15-19 pontos); Grave (20-27 pontos)
            </div>
          </div>
          <div><strong>{pontuacao}</strong></div>
        </div>
      }
    </r-cell>
  )
}