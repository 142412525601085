import React, { useEffect, useMemo, useState } from 'react'
import { Switch } from 'react-router-dom'
import moment from 'moment'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import AgendaDoProfissional from './agenda-do-profissional'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { ordemDoServidor } from '../../../bibliotecas/ordenacao'
import { contem } from '../../../bibliotecas/texto'
import AgendaSemProfissional from './agenda-inicial'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'

export default function Agenda(props) {
  const {
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarDadosSelecionadosDoAgendamento,
    alterarDataDoCalendario,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarTermoDeProfissionaisDeSaudeDaAgenda,
    alterarVisualizacaoDoCalendario,
    cancelarAgendamento,
    calendario,
    confirmarAgendamento,
    excluirAgendamento,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    filtros,
    filtrarProfissionaisDeSaudeDaAgenda,
    fotos,
    fotoDoPaciente,
    history,
    limparDiasDoProfissionalDaAgenda,
    listarMotivosDeBloqueioDaAgenda,
    listarMotivosDeCancelamentoDosAgendamentos,
    listarProfissionaisDeSaudeDaAgenda,
    match,
    motivosDeBloqueio,
    motivosDeCancelamento,
    profissional,
    profissionais,
    recuperarAgendamentoPeloIdentificador,
    recuperarAgendaSemanalDoProfissional,
    recuperarFotoDoPacientePorIdentificador,
    recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    selecionarUnidadesDaAgenda,
    unidades
  } = props

  const { usuario } = useUsuarioLogado()

  const temProfissao = usuario.profissao !== null && usuario.profissao !== undefined
  const usuarioLogadoEhSelecionado = !profissional || profissional.identificador === usuario.identificador
  const ehProfissional = temProfissao && usuarioLogadoEhSelecionado

  const [exibirDemaisProfissionais, setExibirDemaisProfissionais] = useState(ehProfissional)

  function handleExibirDemaisProfissionais() {
    setExibirDemaisProfissionais(false)
  }

  useEffect(() => { listarMotivosDeBloqueioDaAgenda() }, [listarMotivosDeBloqueioDaAgenda])
  useEffect(() => { listarMotivosDeCancelamentoDosAgendamentos() }, [listarMotivosDeCancelamentoDosAgendamentos])

  useEffect(() => {
    if (!exibirDemaisProfissionais) {
      listarProfissionaisDeSaudeDaAgenda()
    } else {
      filtrarProfissionaisDeSaudeDaAgenda(usuario.email ? usuario.email : usuario.nome)
    }
  }, [exibirDemaisProfissionais, filtrarProfissionaisDeSaudeDaAgenda, listarProfissionaisDeSaudeDaAgenda, usuario.nome, usuario.email])

  const existeInterseccaoDeUnidades = (unidades, identificadores) => {
    if (unidades) {
      for (const unidade of unidades) {
        if (identificadores.findIndex(identificador => unidade.identificador === identificador) !== -1) {
          return true
        }
      }
    }

    return false
  }

  const unidadesHabilitadasDoUsuario = useMemo(() => {
    return unidades ? converterObjetoParaArray(unidades).sort(ordemDoServidor) : []
  }, [unidades])

  const profissionaisDeSaudeFiltrados = useMemo(() => {
    let profissionaisFiltrados = converterObjetoParaArray(profissionais)

    profissionaisFiltrados = profissionaisFiltrados
      .filter(profissional => (filtros.unidadesFiltradas.length === 0 || existeInterseccaoDeUnidades(profissional.unidades, filtros.unidadesFiltradas))
        && existeInterseccaoDeUnidades(profissional.unidades, unidadesHabilitadasDoUsuario.map(x => x.identificador))).sort(ordemDoServidor)

    const termo = filtros.termo
    const ativo = filtros.ativo

    if (ativo && ativo.length === 1) {
      profissionaisFiltrados = profissionaisFiltrados.filter(x => x.ativo === (ativo[0] === 'Ativo'))
    }

    if (profissionaisFiltrados.length === 0 || !termo) return [...profissionaisFiltrados]

    const tokens = termo.split(' ').filter(x => x)

    return profissionaisFiltrados.filter(x => {
      let baseDaPesquisa = ''
      baseDaPesquisa = (x.nome || '') + ' '

      if (x.profissao) {
        baseDaPesquisa += (x.profissao.nome || '') + ' '

        if (x.profissao.especialidades) {
          baseDaPesquisa += x.profissao.especialidades.join(' ')
        }
      }

      for (const token of tokens) {
        if (!contem(baseDaPesquisa, token)) {
          return false
        }
      }

      return true
    })
  }, [filtros, unidadesHabilitadasDoUsuario, profissionais])

  useEffect(() => {
    const profissionaisDeSaude = converterObjetoParaArray(profissionais)

    profissionaisDeSaude && profissionaisDeSaude.filter(x => x.foto && x.ativo).forEach(item => {
      recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(item.identificador, item.foto)
    })

    return () => profissionaisDeSaude && profissionaisDeSaude.filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })
  }, [profissionais, recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    if (filtros.ativo.length === 2) {
      const profissionaisDeSaude = converterObjetoParaArray(profissionais)

      profissionaisDeSaude && profissionaisDeSaude.filter(x => x.foto && !x.ativo).forEach(item => {
        recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(item.identificador, item.foto)
      })
    }
  }, [filtros.ativo, profissionais, recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador])

  if (converterObjetoParaArray(profissionais).length === 0) return (<div />)

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificadorDoProfissional/data/:dia`}
        render={
          props => (
            <AgendaDoProfissional
              {...props}
              alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarDadosSelecionadosDoAgendamento={alterarDadosSelecionadosDoAgendamento}
              alterarDataDoCalendario={alterarDataDoCalendario}
              alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarVisualizacaoDoCalendario={alterarVisualizacaoDoCalendario}
              alterarTermoDeProfissionaisDeSaudeDaAgenda={alterarTermoDeProfissionaisDeSaudeDaAgenda}
              calendario={calendario}
              cancelarAgendamento={cancelarAgendamento}
              confirmarAgendamento={confirmarAgendamento}
              excluirAgendamento={excluirAgendamento}
              excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              exibirDemaisProfissionais={exibirDemaisProfissionais ? handleExibirDemaisProfissionais : null}
              filtros={filtros}
              fotos={fotos}
              fotoDoPaciente={fotoDoPaciente}
              identificadorDoUsuarioLogado={usuario.identificador}
              limparDiasDoProfissionalDaAgenda={limparDiasDoProfissionalDaAgenda}
              motivosDeBloqueio={motivosDeBloqueio}
              motivosDeCancelamento={motivosDeCancelamento}
              profissional={profissional}
              profissionaisDeSaudeFiltrados={profissionaisDeSaudeFiltrados}
              recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
              recuperarAgendaSemanalDoProfissional={recuperarAgendaSemanalDoProfissional}
              recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
              removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
              selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
              selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
              selecionarUnidadesDaAgenda={selecionarUnidadesDaAgenda}
              unidadeDoUsuarioLogado={usuario.unidade.identificador}
              unidades={unidadesHabilitadasDoUsuario}
            />
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <AgendaInicial
              {...props}
              alterarTermoDeProfissionaisDeSaudeDaAgenda={alterarTermoDeProfissionaisDeSaudeDaAgenda}
              calendario={calendario}
              filtros={filtros}
              fotos={fotos}
              history={history}
              identificadorDoUsuarioLogado={usuario.identificador}
              identificadorDoProfissional={profissional && profissional.identificador}
              profissionaisDeSaudeFiltrados={profissionaisDeSaudeFiltrados}
              profissional={profissional}
            />
          )
        }
      />
    </Switch>
  )
}

function AgendaInicial(props) {
  const {
    alterarTermoDeProfissionaisDeSaudeDaAgenda,
    calendario,
    identificadorDoProfissional,
    identificadorDoUsuarioLogado,
    filtros,
    fotos,
    history,
    profissional,
    profissionaisDeSaudeFiltrados
  } = props

  useEffect(() => {
    const profissionalInicial = identificadorDoProfissional ? identificadorDoProfissional
      : (profissionaisDeSaudeFiltrados.some(x => x.identificador === identificadorDoUsuarioLogado) ?
        profissionaisDeSaudeFiltrados.filter(x => x.identificador === identificadorDoUsuarioLogado)[0].identificador : null)

    if (profissionalInicial) {
      const data = calendario.data ? calendario.data : moment()
      history.push(`/agendamentos/agenda/${profissionalInicial}/data/${moment(data).format('YYYY-MM-DD')}`)
    }
  }, [history, profissionaisDeSaudeFiltrados, identificadorDoUsuarioLogado, calendario.data, identificadorDoProfissional])

  return (
    <AgendaSemProfissional
      {...props}
      alterarTermoDeProfissionaisDeSaudeDaAgenda={alterarTermoDeProfissionaisDeSaudeDaAgenda}
      calendario={{ data: moment().format('YYYY-MM-DD'), visualizacao: 'semana' }}
      filtros={filtros}
      fotos={fotos}
      profissional={profissional}
      profissionaisDeSaudeFiltrados={profissionaisDeSaudeFiltrados}
    />
  )
}