import { connect } from 'react-redux'
import acoes from '../../acoes'
import Operadoras from './index'

const mapStateToProps = state => {
  const operadoraAtual = state.operadoras.itemAtual

  let operadora = {
    nome: '',
    observacoes: '',
    tamanhoDoNumeroDaCarteira: '',
  }

  if (operadoraAtual) {
    operadora.identificador = operadoraAtual.identificador
    operadora.observacoes = operadoraAtual.observacoes
    operadora.nome = operadoraAtual.nome
    operadora.tamanhoDoNumeroDaCarteira = operadoraAtual.tamanhoDoNumeroDaCarteira
  }

  return {
    filtros: state.operadoras.filtros,
    ordenacao: state.operadoras.ordenacao,
    paginaDeDados: state.operadoras.paginaDeDados,
    operadora,
  }
}

export default connect(mapStateToProps, acoes)(Operadoras)