import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Formulario from './formulario'
import Resumo from './resumo'

import {
  LISTAR_CASPS_16_DO_PACIENTE,
  REMOVER_CASP_16_DO_PACIENTE
} from '../../../../../acoes/tipos'
import moment from 'moment'

export default function Casp16({ identificadorDoPaciente, casps16DoPaciente }) {
  const {
    listarRespostasDoCasp16,
    respostasDoCasp16,
    listarCasps16DoPaciente,
    adicionarCasp16,
    alterarCasp16,
    removerCasp16
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CASP_16_DO_PACIENTE])

  useEffect(() => {
    if (casps16DoPaciente === undefined) {
      listarCasps16DoPaciente(identificadorDoPaciente)
    }
  }, [listarCasps16DoPaciente, identificadorDoPaciente, casps16DoPaciente])

  useEffect(() => { listarRespostasDoCasp16() }, [listarRespostasDoCasp16])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarCasp16(identificadorDoPaciente, item) : await adicionarCasp16(identificadorDoPaciente, item)
  }

  if (casps16DoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário do CASP-16'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicialCasp16(selecionado)}
          respostasDoCasp16={respostasDoCasp16}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo do CASP-16'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicialCasp16(selecionado)}
          respostasDoCasp16={respostasDoCasp16}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção do CASP-16'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerCasp16(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_CASPS_16_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_CASP_16_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <button
                    className='table-items-expanded__bt --edit'
                    title='Ver resumo'
                    onClick={() => abrirResumo(item)}
                    type='button'
                    key={`questionario_${item.identificador}`}
                  >
                    <i className='icon icon-doc-text'></i>
                  </button>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_CASP_16_DO_PACIENTE]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={casps16DoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              expandida={true}
              mensagemTabelaVazia='Não existem registros do CASP-16.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-3'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
              <Coluna
                className='col-lg-3'
                nome='Resultado'
                renderizar={item => item.resultado > 0 ? item.resultado : '0'}
              />
              <LinhaExpandida className='col-xs-10 col-lg-12'>
                <AgrupamentoDeColunas>
                  <Coluna
                    nome='DATA'
                    renderizar={item => formatarDataParaFormatoLocal(item.data)}
                  />
                  <Coluna
                    nome='CONTROLE'
                    renderizar={item => item.controle.resultado > 0 ? item.controle.resultado : '0'}
                  />
                  <Coluna
                    nome='AUTONOMIA'
                    renderizar={item => item.autonomia.resultado > 0 ? item.autonomia.resultado : '0'}
                  />
                  <Coluna
                    nome='AUTO-REALIZAÇÃO'
                    renderizar={item => item.autoRealizacao.resultado > 0 ? item.autoRealizacao.resultado : '0'}
                  />
                  <Coluna
                    nome='PRAZER'
                    renderizar={item => item.prazer.resultado > 0 ? item.prazer.resultado : '0'}
                  />
                  <Coluna
                    nome='RESULTADO'
                    renderizar={item => item.resultado > 0 ? item.resultado : '0'}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirCasp16 = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_CASP_16_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover o CASP-16?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirCasp16}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicialCasp16 = selecionado => {
  if (selecionado) {
    return {
      ...selecionado
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    controle: {
      minhaIdadeMeImpedeDeFazerAsCoisasQueEuGostariaDeFazer: undefined,
      euSintoQueOQueAconteceComigoEstaForaDoMeuControle: undefined,
      euMeSintoLivreParaPlanejarOFuturo: undefined,
      euMeSintoExcluidoDeTudo: undefined
    },
    autonomia: {
      euPossoFazerAsCoisasQueQuero: undefined,
      asResponsabilidadesFamiliaresMeImpedemDeFazerOQueEuQuero: undefined,
      euMeSintoLivreParaFazerAsCoisas: undefined,
      minhaSaudeMeImpedeDeFazerAsCoisasQueQuero: undefined,
      aFaltaDeDinheiroMeImpedeDeFazerAsCoisasQueQuero: undefined
    },
    autoRealizacao: {
      euMeSintoCheioDeEnergiaHojeEmDia: undefined,
      euEscolhoFazerCoisasQueNuncaFizAntes: undefined,
      euSintoQueAVidaEstahCheiaDeOportunidades: undefined,
      euSintoQueOMeuFuturoPareceBom: undefined
    },
    prazer: {
      euFicoAnimadoACadaDia: undefined,
      euSintoQueMinhaVidaTemSentido: undefined,
      euGostoDasCoisasQueFaco: undefined
    },
  }
}