import api from '../../../api-major'

const adicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificador, escala) => api.post(`/pacientes/${identificador}/escalas-ambientais-de-risco-de-quedas`, escala)
const alterarEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificador, escala) => api.put(`/pacientes/${identificador}/escalas-ambientais-de-risco-de-quedas/${escala.identificador}`, escala)
const removerEscalaAmbientalDeRiscoDeQuedasDoPaciente = (identificador, identificadorDaEscala) => api.delete(`/pacientes/${identificador}/escalas-ambientais-de-risco-de-quedas/${identificadorDaEscala}`)
const listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente = identificador => api.get(`/pacientes/${identificador}/escalas-ambientais-de-risco-de-quedas`)

export default { 
  adicionarEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  alterarEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  removerEscalaAmbientalDeRiscoDeQuedasDoPaciente,
  listarEscalasAmbientaisDeRiscoDeQuedasDoPaciente
}