import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { converterParaUrl, gravarFotoNoCache, recuperarFotoDoCache } from '../../bibliotecas/imagem'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_FOTO_DA_EMPRESA,
  ADICIONOU_FOTO_DA_EMPRESA,
  ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA,
  ERRO_AO_RECUPERAR_EMPRESA,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  ERRO_AO_SALVAR_EMPRESA,
  RECUPERAR_EMPRESA,
  RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  RECUPEROU_EMPRESA,
  RECUPEROU_FOTO_DA_EMPRESA_LOGADA,
  RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  REMOVER_FOTO_DA_EMPRESA,
  REMOVEU_FOTO_DA_EMPRESA,
  SALVAR_EMPRESA,
  SALVOU_EMPRESA
} from '../tipos'

const recuperouEmpresaPorIdentificador = empresa => async dispatch => {
  dispatch({ type: RECUPEROU_EMPRESA, empresa })
}

const erroAoRecuperarEmpresa = erro => ({
  type: ERRO_AO_RECUPERAR_EMPRESA,
  erro,
})

export const recuperarEmpresa = () => async dispatch => {
  dispatch({ type: RECUPERAR_EMPRESA })

  try {
    const resultado = await api.recuperarEmpresa()

    dispatch(recuperouEmpresaPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoRecuperarEmpresa(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar empresa por identificador.')))
  }
}

const salvouEmpresa = empresa => ({
  type: SALVOU_EMPRESA,
  empresa,
})

const erroAoSalvarEmpresa = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_EMPRESA,
  erro,
  parametros,
})

export const salvarEmpresa = empresa => async dispatch => {
  dispatch({ type: SALVAR_EMPRESA })

  try {
    const resultado = await api.salvarEmpresa(empresa)

    dispatch(salvouEmpresa(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoSalvarEmpresa(erro, { empresa }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o empresa.')))
  }
}

const adicionouFotoDaEmpresa = foto => ({
  type: ADICIONOU_FOTO_DA_EMPRESA,
  foto,
})

const erroAoAdicionarFotoDaEmpresa = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA,
  erro,
  parametros
})

export const adicionarFotoDaEmpresa = foto => async dispatch => {
  dispatch({ type: ADICIONAR_FOTO_DA_EMPRESA })

  try {
    const resultado = await api.adicionarArquivo(foto, 'logotipo_da_empresa')
    dispatch(adicionouFotoDaEmpresa({ identificador: resultado.data.dados.identificador, url: converterParaUrl(resultado.data.dados) }))

    return resultado.data.dados.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarFotoDaEmpresa(erro, { foto }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar foto da empresa.')))
    return null
  }
}

const removeuFotoDaEmpresa = identificador => ({
  type: REMOVEU_FOTO_DA_EMPRESA,
  identificador
})

export const removerFotoDaEmpresa = identificador => async dispatch => {
  dispatch({ type: REMOVER_FOTO_DA_EMPRESA })
  dispatch(removeuFotoDaEmpresa(identificador))
}

const recuperouFotoDaEmpresaLogada = (foto, identificador) => ({
  type: RECUPEROU_FOTO_DA_EMPRESA_LOGADA,
  url: foto,
  identificador,
})

const erroAoRecuperarFotoDaEmpresaLogada = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  erro,
  parametros
})

export const recuperarFotoDaEmpresaLogada = identificadorDaFoto => async dispatch => {
  dispatch({ type: RECUPERAR_FOTO_DA_EMPRESA_LOGADA })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDaFoto)

    if (itemDoCache) {
      dispatch(recuperouFotoDaEmpresaLogada(itemDoCache, identificadorDaFoto))
      return
    }

    const resultado = await api.recuperarFotoDaEmpresaLogada()
    const foto = gravarFotoNoCache(resultado.data, identificadorDaFoto)

    dispatch(recuperouFotoDaEmpresaLogada(foto, identificadorDaFoto))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarFotoDaEmpresaLogada(erro, { identificadorDaFoto }))
  }
}

const recuperouFotoDaEmpresaPorIdentificadorPublico = (url, identificadorDaEmpresa) => ({
  type: RECUPEROU_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  url,
  identificadorDaEmpresa
})

const erroAoRecuperarFotoDaEmpresaPorIdentificadorPublico = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  erro,
  parametros
})

export const recuperarFotoDaEmpresaPorIdentificadorPublico = identificadorDaEmpresa => async dispatch => {
  dispatch({ type: RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO })

  try {
    const itemDoCache = await recuperarFotoDoCache(identificadorDaEmpresa)

    if (itemDoCache) {
      dispatch(recuperouFotoDaEmpresaLogada(itemDoCache, identificadorDaEmpresa))
      return
    }

    const resultado = await api.recuperarFotoDaEmpresaPorIdentificadorPublico(identificadorDaEmpresa)
    const foto = gravarFotoNoCache(resultado.data, identificadorDaEmpresa)

    dispatch(recuperouFotoDaEmpresaPorIdentificadorPublico(foto, identificadorDaEmpresa))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoRecuperarFotoDaEmpresaPorIdentificadorPublico(erro, { identificadorDaEmpresa }))
  }
}