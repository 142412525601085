import {LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO} from '../../acoes/tipos'
import moment from 'moment'

const estadoInicial = {
  filtros: {
    unidades: [],
    profissionais: [],
    status: ['Agendado', 'Reagendado', 'Confirmado'],
    termo: '',
    inicio: moment().format('YYYY-MM-DD HH:mm:ss'),
    fim: moment().endOf('day').endOf('week').format('YYYY-MM-DD HH:mm:ss')
  },
  ordenacao: null,
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        ordenacao,
        paginaDeDados: {
          ...paginaDeDados,
          dados: action.paginaDeDados.dados.map(x => ({
            ...x,
            profissional: {
              ...x.profissional,
              nome: x.profissional.tratamento ? x.profissional.tratamento + ' ' + x.profissional.nome : x.profissional.nome
            }
          }))
        },
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}