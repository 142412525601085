import api from './api-major'

const listarTokensDePaciente = paciente => api.get(`/pacientes/${paciente}/tokens`)
const listarTokensDeTemplates = () => api.get(`/templates/tokens?pagina=1&tamanhoDaPagina=100000`)
const listarTokensDeExemplo = () => api.get(`/templates/tokens/exemplos`)
const listarComponentesComTemplate = () => api.get(`/templates/componentes?pagina=1&tamanhoDaPagina=100000`)

const recuperarTemplatePorIdentificador = identificador => api.get(`/templates/${identificador}`)
const adicionarTemplate = template => api.post(`/templates/`, template)
const salvarTemplate = template => api.put(`/templates/${template.identificador}`, template)
const excluirTemplate = identificador => api.delete(`/templates/${identificador}`)

const listarTemplates = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros.termo ? `&termo=${filtros.termo}` : ''
  const parametroCompartilhado = filtros.compartilhado !== undefined && filtros.compartilhado.length === 1 ? `&compartilhado=${filtros.compartilhado[0] === 'Sim'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/templates${paginacao}${tamanhoDaPaginaFormatado}${parametroTermo}${parametroCompartilhado}${ordem}`)
}

export default {
  adicionarTemplate,
  excluirTemplate,
  listarComponentesComTemplate,
  listarTemplates,
  listarTokensDeExemplo,
  listarTokensDePaciente,
  listarTokensDeTemplates,
  recuperarTemplatePorIdentificador,
  salvarTemplate,
}