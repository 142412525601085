import {
  LISTOU_PROFISSIONAIS_EXTERNOS,
  ADICIONOU_PROFISSIONAL_EXTERNO,
  RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ALTEROU_PROFISSIONAL_EXTERNO,
  REMOVEU_PROFISSIONAL_EXTERNO,
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: '',
    parceiro: ['Sim', 'Não'],
    ativo: ['Sim']
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ADICIONOU_PROFISSIONAL_EXTERNO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case RECUPEROU_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.profissional
      }
    }

    case ALTEROU_PROFISSIONAL_EXTERNO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    case LISTOU_PROFISSIONAIS_EXTERNOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case REMOVEU_PROFISSIONAL_EXTERNO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}