import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Formulario as Form, Input } from '../../formik/formulario'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Cabecalho from '../cabecalho'

import {
  ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE,
  ALTERAR_CAMPO_EXTERNO_DO_PACIENTE,
  RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE,
  REMOVER_CAMPO_EXTERNO_DO_PACIENTE
} from '../../../acoes/tipos'

export default function FormularioDeCampoExterno(props) {
  const {
    fotos,
    history,
    match,
    paciente,
    recuperar,
    recuperarPorIdentificador,
    remover,
    salvar,
  } = props

  const { identificador, identificadorDoCampoExterno } = match.params
  const campoExterno = paciente.camposExternos.selecionado || {}
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_CAMPO_EXTERNO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE])
  const permissaoParaRemover = usePossuiAsPermissoes([permissoes.REMOVER_CAMPO_EXTERNO_DO_PACIENTE])

  useEffect(() => {
    if (identificador && identificadorDoCampoExterno) {
      recuperar(identificador, identificadorDoCampoExterno)
    }
  }, [identificador, identificadorDoCampoExterno, recuperar])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o campo externo ${campoExterno.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(identificador, campoExterno.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE, ALTERAR_CAMPO_EXTERNO_DO_PACIENTE, RECUPERAR_CAMPO_EXTERNO_DO_PACIENTE, REMOVER_CAMPO_EXTERNO_DO_PACIENTE]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          identificadorDoPaciente: identificador,
          identificador: campoExterno.identificador || '',
          nome: campoExterno.nome|| '',
          conteudo: campoExterno.conteudo || '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          conteudo: Yup.string().required('Obrigatório')
        })}
      >
        {({ setFieldValue, values }) => (
          <>
            <Cabecalho paciente={paciente} fotos={fotos} identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} />
            <div className="mt-2">
              <fieldset>
                <h2 className="form-title">{`${campoExterno.identificador ? 'Alterar Campo Externo' : 'Adicionar Campo Externo'}`}</h2>
                <r-grid columns-md='6' columns-lg='12'>
                  <r-cell span={4} span-md='6' span-lg='12'>
                    <Input
                      nome='nome'
                      tabIndex={1}
                      titulo='Nome *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md='row' span-lg='row'>
                    <Input
                      as='textarea'
                      nome='conteudo'
                      tabIndex={2}
                      titulo='Conteúdo *'
                    />
                  </r-cell>
                </r-grid>
              </fieldset>
              {remover ?
                <div className='list-btn-unidades'>
                  {permissaoParaRemover && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                  {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                </div>
                :
                <div className='list-btn'>
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                </div>
              }
            </div>
          </>
        )}
      </Form>
    </Spinner>
  )
}