import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
} from '../tipos'

const listouMotivosDeSaidaDosProgramas = motivosDeSaidaDosProgramas => ({
  type: LISTOU_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  motivosDeSaidaDosProgramas
})

const erroAoListarMotivosDeSaidaDosProgramas = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  erro
})

export const listarMotivosDeSaidaDosProgramas = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS })
  
  try {
    const resultado = await api.listarMotivosDeSaidaDosProgramas()
    
    dispatch(listouMotivosDeSaidaDosProgramas(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeSaidaDosProgramas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de saída dos programas.')))
  }
}