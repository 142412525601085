import moment from 'moment-timezone'
import 'moment/locale/pt-br'

export const  configurarMoment = () => {
  moment.locale('pt-br')
  moment.updateLocale('pt-br', {
    relativeTime: {
      future: '%s',
      past: '%s'
    }
  })
}