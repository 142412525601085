import React, { useEffect, useState, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela } from '../../../tabela'
import { ordemDoServidor } from '../../../../bibliotecas/ordenacao'
import { formatarDataComPrecisaoParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import { LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE, REMOVER_CONSULTA_EXTERNA_DO_PACIENTE } from '../../../../acoes/tipos'

export default function ConsultasExternas({ identificadorDoPaciente, consultasExternasDoPaciente }) {
  const {
    listarConsultasExternas,
    listarTiposDeConsultaExterna,
    listarProfissoes,
    profissoes,
    tiposDeConsultaExterna,
    adicionarConsultaExterna,
    alterarConsultaExterna,
    removerConsultaExterna
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE])

  useEffect(() => {
    if (consultasExternasDoPaciente === undefined) {
      listarConsultasExternas(identificadorDoPaciente)
    }
  }, [listarConsultasExternas, identificadorDoPaciente, consultasExternasDoPaciente])

  useEffect(() => { listarProfissoes() }, [listarProfissoes])
  useEffect(() => { listarTiposDeConsultaExterna() }, [listarTiposDeConsultaExterna])

  function abrirFormulario(item) {
    if (item) {
      const especialidade = item.profissao && item.profissao.toUpperCase() === 'ENFERMEIRO(A)' ? 'GENERALISTA' : item.especialidade
      setSelecionada({ ...item, profissao: especialidade ? `${especialidade}#${item.profissao}` : item.profissao })
    } else {
      setSelecionada(item)
    }

    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarConsultaExterna(identificadorDoPaciente, item) : await adicionarConsultaExterna(identificadorDoPaciente, item)
  }

  const enfermeiro = profissoes.find(x => x.nome.toUpperCase() === 'ENFERMEIRO(A)')
  const medico = profissoes.find(x => x.nome.toUpperCase() === 'MÉDICO(A)')

  let profissoesComGeneralistas = profissoes.filter(x => x.nome.toUpperCase() !== 'ENFERMEIRO(A)')

  profissoesComGeneralistas && profissoesComGeneralistas.push({ ...enfermeiro, especialidades: [{ nome: 'GENERALISTA' }] })
  profissoesComGeneralistas && profissoesComGeneralistas.push({ ...medico, especialidades: [] })

  const profissaoEspecialidades = useMemo(() => {
    let todos = []

    profissoesComGeneralistas.forEach(p => {
      if (p.especialidades && p.especialidades.length > 0) {
        p.especialidades.forEach(e => {
          todos.push({ codigo: `${e.nome}#${p.nome}`, label: `${e.nome} - ${p.nome}`, _ordem: `${e.nome} - ${p.nome}` })
        })
      } else {
        todos.push({ codigo: p.nome, label: p.nome, _ordem: p.nome })
      }
    })

    return todos.sort(ordemDoServidor)
  }, [profissoesComGeneralistas])

  if (consultasExternasDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de consultas externas'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          profissoes={profissaoEspecialidades}
          tiposDeConsultaExterna={tiposDeConsultaExterna}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de consultas externas'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerConsultaExterna(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_CONSULTA_EXTERNA_DO_PACIENTE]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Última alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                      {item.observacoes &&
                        <>
                          <strong>Observações: </strong>
                          {item.observacoes}
                        </>
                      }
                    </ReactTooltip>
                  </div>
                ,
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={consultasExternasDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de consultas externas.'
              classNameAdicionar='col-xs-6 col-lg-2'
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                campoDeDados='profissao'
                nome='Profissão'
                exibirTitulo={true}
                principal={true}
              />
              <Coluna
                className='col-lg-4'
                campoDeDados='especialidade'
                nome='Especialidade'
              />
              <Coluna
                className='col-lg-2'
                campoDeDados='tipo'
                nome='Tipo'
              />
              <Coluna
                className='col-lg-2'
                nome='Data'
                renderizar={item => formatarDataComPrecisaoParaFormatoLocal(item.data, item.precisaoDaData)}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirConsultaExterna = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_CONSULTA_EXTERNA_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a consulta externa?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirConsultaExterna}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}