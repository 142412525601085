import api from '../../api'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  RECUPEROU_TELECONSULTA_DO_AGENDAMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  RECUPEROU_TELECONSULTA_DO_ATENDIMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,  
} from '../tipos'

const recuperouTeleconsultaDoAgendamento = teleconsulta => ({
  type: RECUPEROU_TELECONSULTA_DO_AGENDAMENTO,
  teleconsulta,
})

const erroAoRecuperarTeleconsultaDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  erro,
  parametros
})

export const recuperarTeleconsultaDoAgendamento = identificadorDoAgendamento => async dispatch => {
  dispatch({ type: RECUPERAR_TELECONSULTA_DO_AGENDAMENTO })

  try {
    const resultado = await api.recuperarTeleconsultaDoAgendamento(identificadorDoAgendamento)

    dispatch(recuperouTeleconsultaDoAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarTeleconsultaDoAgendamento(erro, { identificadorDoAgendamento }))

    return false
  }
}

const recuperouTeleconsultaDoAtendimento = teleconsulta => ({
  type: RECUPEROU_TELECONSULTA_DO_ATENDIMENTO,
  teleconsulta,
})

const erroAoRecuperarTeleconsultaDoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
  erro,
  parametros
})

export const recuperarTeleconsultaDoAtendimento = identificadorDoAtendimento => async dispatch => {
  dispatch({ type: RECUPERAR_TELECONSULTA_DO_ATENDIMENTO })

  try {
    const resultado = await api.recuperarTeleconsultaDoAtendimento(identificadorDoAtendimento)

    dispatch(recuperouTeleconsultaDoAtendimento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarTeleconsultaDoAtendimento(erro, { identificadorDoAtendimento }))

    return false
  }
}