import { connect } from 'react-redux'
import acoes from '../../acoes'
import Pacientes from './index'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const mapStateToProps = state => {
  let paciente = state.pacientes.itemAtual
  paciente.bytesDaFoto = paciente.foto ? state.imagens[paciente.foto] : null

  return {
    empresa: state.empresa,
    estadosCivis: state.estadosCivis,
    fotos: recuperarFotos(state.pacientes.paginaDeDados.dados, state.imagens),
    grausDeRelacoes: state.grausDeRelacoes,
    paciente,
    pacientes: state.pacientes,
    planosDeCuidado: state.planosDeCuidado.paginaDeDados.dados,
    programas: state.programas.paginaDeDados.dados,
    proprietariosDeTelefones: state.proprietariosDeTelefones,
    sexos: state.sexos,
    unidadesFederativas: state.unidadesFederativas,
  }
}

export default connect(mapStateToProps, acoes)(Pacientes)