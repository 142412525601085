import React from 'react'
import {Link} from 'react-router-dom'
import {RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI} from '../../../acoes/tipos'
import Spinner from '../../spinner'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function IndicadoresEstrategicos(props) {
    const {
        match,
        dashboards
    } = props

    return (
        <div className='container'>
            <Spinner operacoes={[RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI]}>
                {({ processando }) => {
                    if (processando) {
                        return <h2 className='verificando-permissoes'>Verificando permissões...</h2>
                    }
                    return (
                        <div className='dashboard relatorios'>
                            <div className='container'>
                                <div className='centraliza-dashboard'>
                                    {dashboards.map((dash, index) => <Atalho key={index} match={match} {...dash} />)}
                                </div>
                            </div>
                       </div>
                    )
                }}
            </Spinner>
        </div>
    )
}

function Atalho({ match, url, icone, nome, permissao }) {
    return (
        <Permissao permissoes={[permissoes.VISUALIZAR_RELATORIO_DO_POWER_BI, permissao]}>
            <Link
                to={`${match.url}/${url}`}
                className='dashboard-item'>
                {typeof icone === 'string' && <i className={`dashboard-item-icon --br icon ${icone}`}></i>}
                {typeof icone === 'object' && <div className='dashboard-item-icon --br icon'>{icone}</div>}
                <p>{nome}</p>
            </Link>
        </Permissao>
    )
}