import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Lista from './lista'

export default function Encaminhamentos(props) {
  const {
    filtros,
    ordenacao,
    paginaDeDados,
    listarEncaminhamentos,
    listarStatusDeEncaminhamento,
    listarTiposDeEncaminhamento,
    alterarStatusDoEncaminhamento,
    statusDeEncaminhamento,
    tiposDeEncaminhamento,
    prioridades,
    match
  } = props

  useEffect(() => {
    listarStatusDeEncaminhamento()
    listarTiposDeEncaminhamento()
  }, [listarStatusDeEncaminhamento, listarTiposDeEncaminhamento])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_ENCAMINHAMENTOS]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                pesquisar={listarEncaminhamentos}
                statusDeEncaminhamento={statusDeEncaminhamento}
                tiposDeEncaminhamento={tiposDeEncaminhamento}
                alterarStatusDoEncaminhamento={alterarStatusDoEncaminhamento}
                prioridades={prioridades}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}`} />
    </Switch>
  )
}