import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela, LinhaExpandida, AgrupamentoDeColunas } from '../../../tabela'
import { formatarDataParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import useCliqueForaDoElemento from '../../../../bibliotecas/clique-fora-do-elemento'
import Spinner from '../../../spinner'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import Formulario from './formulario'
import { LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE, REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function InfeccoesDoTratoUrinario({ identificadorDoPaciente, infeccoesDoPaciente }) {
  const {
    listarInfeccoesDoTratoUrinarioDoPaciente,
    microrganismos,
    antibioticos,
    listarMicrorganismos,
    listarAntibioticos,
    adicionarInfeccao,
    alterarInfeccao,
    removerInfeccao
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionada, setSelecionada] = useState(null)
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)

  const referenciaDoInfo = useRef(null)

  const podeListarMicrorganismos = usePossuiAsPermissoes([permissoes.LISTAR_MICRORGANISMOS])
  const podeListarAntibioticos = usePossuiAsPermissoes([permissoes.LISTAR_ANTIBIOTICOS])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE])

  useCliqueForaDoElemento(referenciaDoInfo, () => setExibirTooltipInfo(false))

  useEffect(() => {
    if (infeccoesDoPaciente === undefined) {
      listarInfeccoesDoTratoUrinarioDoPaciente(identificadorDoPaciente)
    }
  }, [listarInfeccoesDoTratoUrinarioDoPaciente, identificadorDoPaciente, infeccoesDoPaciente])

  useEffect(() => listarMicrorganismos(), [listarMicrorganismos])
  useEffect(() => listarAntibioticos(), [listarAntibioticos])

  function abrirFormulario(item) {
    setSelecionada(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  const salvar = async item => {
    return selecionada ? await alterarInfeccao(identificadorDoPaciente, item) : await adicionarInfeccao(identificadorDoPaciente, item)
  }

  if (infeccoesDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal modal-itu'
        contentLabel='Modal do formulário de infecções do trato urinário'
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionada={selecionada}
          microrganismos={microrganismos}
          antibioticos={antibioticos}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção de infecção do trato urinário'
        className='modal-vacinas'
        style={{ content: { maxWidth: '400px' } }}
      >
        <Remover
          selecionada={selecionada}
          remover={valor => removerInfeccao(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => abrirFormulario(item)}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
                ,
                item =>
                  <div key='informacao' ref={referenciaDoInfo}>
                    <button
                      key={`info_${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info' onClick={() => setExibirTooltipInfo(item.identificador)}></i>
                    </button>
                    {(item.identificador === exibirTooltipInfo) &&
                      <div className='tooltip__drop custom-scrollbar right-11' style={{ height: !item.observacoes && 'auto' }}>
                        <div className='tooltip__drop__body'>
                          <div className='tooltip__drop__title'>
                            Histórico
                            <button className='tooltip__drop__bt-close' type='button' onClick={() => setExibirTooltipInfo(false)}><i className='icon icon-close'></i></button>
                          </div>
                          <div className='tooltip__drop__description'>
                            <span>Usuário: {item.usuario.nome}</span> <br />
                            {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                            <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                            {item.observacoes &&
                              <>
                                <strong>Observações:</strong><br />
                                <pre>{item.observacoes}</pre>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={infeccoesDoPaciente}
              exibirBotaoAdicionar={podeAdicionar && podeListarMicrorganismos && podeListarAntibioticos}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de infecções do trato urinário.'
              classNameAdicionar='col-xs-6 col-lg-2'
              expandida={true}
            >
              <Coluna
                className='col-xs-6 col-lg-2'
                nome='Data'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataParaFormatoLocal(item.dataDaInfeccaoOuDoExameDeUrina)}
              />
              <Coluna
                className='col-lg-3'
                nome='Bacteriúria Assintomática'
                renderizar={item => item.bacteriuriaAssintomatica ? 'Sim' : 'Não'}
              />
              <Coluna
                className='col-lg-3'
                nome='Resultado de Urocultura'
                renderizar={item => item.temResultadoDeUrocultura ? 'Sim' : 'Não'}
              />
              <Coluna
                className='col-lg-2 ajusta-indicator'
                nome='Microrganismos'
                renderizar={item => {
                  const microrganismo = item.temResultadoDeUrocultura && item.microrganismos
                  const temAntibioticos = microrganismo && microrganismo.filter(x => x.antibioticos).map(y => y.antibioticos.length > 0)
                  return (
                    microrganismo &&
                    <>
                      {microrganismo[0].microrganismo.nome}
                      {(temAntibioticos.includes(true) || microrganismo.length > 1) &&
                        <Microrganismos key={`microrganismo_${item.identificador}`} registro={microrganismo} />
                      }
                    </>
                  )
                }}
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-date'
                    nome='Data'
                    renderizar={item => formatarDataParaFormatoLocal(item.dataDaInfeccaoOuDoExameDeUrina)}
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='Bacteriúria Assintomática'
                    renderizar={item => item.bacteriuriaAssintomatica ? 'Sim' : 'Não'}
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='Resultado de Urocultura'
                    renderizar={item => item.temResultadoDeUrocultura ? 'Sim' : 'Não'}
                  />
                  <Coluna
                    icone='icon-doc-text'
                    nome='Microrganismos'
                    renderizar={item => item.temResultadoDeUrocultura && item.microrganismos && item.microrganismos[0].microrganismo.nome}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirInfeccao = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a infecção do trato urinário do dia ${selecionada && formatarDataParaFormatoLocal(selecionada.dataDaInfeccaoOuDoExameDeUrina)}?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirInfeccao}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function Microrganismos({ registro }) {
  const [visivel, setVisivel] = useState(false)
  const referenciaDosMicrorganismos = useRef(null)

  useCliqueForaDoElemento(referenciaDosMicrorganismos, () => setVisivel(false))

  if (!registro) return

  return (
    <>
      <button
        key={`microrganismo_${registro.identificador}`}
        className='indicator-tooltip'
        type='button'
      >
        <i className={`icon icon-arrow${visivel ? '-up' : '-down'}`}
          onClick={() => setVisivel(!visivel)}
          ref={referenciaDosMicrorganismos}
        ></i>
      </button>
      {visivel &&
        <div className='tooltip__antibioticos_drop custom-scrollbar'>
          <div className='tooltip__antibioticos_drop__arrow'></div>
          {registro.map((x, index) =>
            <div className='tooltip__antibioticos_drop__body' key={index}>
              <div className='tooltip__antibioticos_drop__title'>
                {x.microrganismo.nome}
              </div>
              <div className='tooltip__antibioticos_drop__description'>
                {x.antibioticos.filter(x => x.sensibilidade === 'Sensível').length > 0 &&
                  <>
                    <strong>Antibióticos sensíveis</strong><br />
                    {x.antibioticos.filter(x => x.sensibilidade === 'Sensível').map((x, index) =>
                      <span key={index}>{x.antibiotico.nome}<br /></span>
                    )}
                  </>
                }
                {x.antibioticos.filter(x => x.sensibilidade === 'Resistente').length > 0 &&
                  <>
                    <strong>Antibióticos resistentes</strong><br />
                    {x.antibioticos.filter(x => x.sensibilidade === 'Resistente').map((x, index) =>
                      <span key={index}>{x.antibiotico.nome}<br /></span>
                    )}
                  </>
                }
                {x.antibioticos.filter(x => x.sensibilidade === 'Resistência Intermediária').length > 0 &&
                  <>
                    <strong>Antibióticos com resistência intermediária</strong><br />
                    {x.antibioticos.filter(x => x.sensibilidade === 'Resistência Intermediária').map((x, index) =>
                      <span key={index}>{x.antibiotico.nome}<br /></span>
                    )}
                  </>
                }
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}