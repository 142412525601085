import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO
} from '../../../tipos'

const listouAvaliacoesDaSolidaoDoPaciente = (identificadorDoPaciente, avaliacoesDaSolidao) => ({
  type: LISTOU_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  identificadorDoPaciente,
  avaliacoesDaSolidao
})

const erroAoListarAvaliacoesDaSolidaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  erro,
  parametros
})

export const listarAvaliacoesDaSolidaoDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE })

  try {
    const resultado = await api.listarAvaliacoesDaSolidaoDoPaciente(identificadorDoPaciente)

    dispatch(listouAvaliacoesDaSolidaoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAvaliacoesDaSolidaoDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as avaliações da solidão do paciente.')))
  }
}

const adicionouAvaliacaoDaSolidaoDoPaciente = avaliacao => ({
  type: ADICIONOU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  avaliacao
})

const erroAoAdicionarAvaliacaoDaSolidaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarAvaliacaoDaSolidaoDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarAvaliacaoDaSolidaoDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(adicionouAvaliacaoDaSolidaoDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesDaSolidaoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAvaliacaoDaSolidaoDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar avaliação da solidão do paciente.')))

    return false
  }
}

const alterouAvaliacaoDaSolidaoDoPaciente = avaliacao => ({
  type: ALTEROU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  avaliacao
})

const erroAoAlterarAvaliacaoDaSolidaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarAvaliacaoDaSolidaoDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE })

  try {
    const resultado = await api.alterarAvaliacaoDaSolidaoDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(alterouAvaliacaoDaSolidaoDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesDaSolidaoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarAvaliacaoDaSolidaoDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar avaliação da solidão do paciente.')))

    return false
  }
}

const removeuAvaliacaoDaSolidaoDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverAvaliacaoDaSolidaoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  erro,
  parametros
})

export const removerAvaliacaoDaSolidaoDoPaciente = (identificadorDoPaciente, identificadorDaAvaliacao) => async dispatch => {
  dispatch({ type: REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE })

  try {
    const resultado = await api.removerAvaliacaoDaSolidaoDoPaciente(identificadorDoPaciente, identificadorDaAvaliacao)

    dispatch(removeuAvaliacaoDaSolidaoDoPaciente(identificadorDoPaciente))
    dispatch(listarAvaliacoesDaSolidaoDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverAvaliacaoDaSolidaoDoPaciente(erro, { identificadorDoPaciente, identificadorDaAvaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover avaliação da solidão do paciente.')))

    return false
  }
}

const listouFrequenciasNaEscalaDaSolidao = frequenciasNaEscalaDaSolidao => ({
  type: LISTOU_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  frequenciasNaEscalaDaSolidao,
})

const erroAoListarFrequenciasNaEscalaDaSolidao = erro => ({
  type: ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  erro,
})

export const listarFrequenciasNaEscalaDaSolidao = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO })

    try {
      const resultado = await api.listarFrequenciasNaEscalaDaSolidao()

      dispatch(listouFrequenciasNaEscalaDaSolidao(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarFrequenciasNaEscalaDaSolidao(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as frequências na escala da solidão.')))
    }
  }

  acao.meta = {
    cache: LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO
  }

  return acao
}