import api from './api-major'

const listarTiposDeAgendamento = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroProcedimento = filtros.procedimento !== undefined && filtros.procedimento.length === 1 ? `&procedimento=${filtros.procedimento[0] === 'Sim'}` : ''
  const parametroAtivo = filtros.ativo !== undefined && filtros.ativo.length === 1 ? `&ativo=${filtros.ativo[0] === 'Sim'}` : ''
  const parametroModalidades = filtros.modalidades ? `&modalidades=${filtros.modalidades}` : []
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatodo = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=1000000'

  return api.get(`/tipos-de-agendamento${paginacao}${tamanhoDaPaginaFormatodo}${parametroNome}${parametroProcedimento}${parametroAtivo}${parametroModalidades}${ordem}`)
}

const adicionarTipoDeAgendamento = tipoDeAgendamento => api.post(`/tipos-de-agendamento`, tipoDeAgendamento)
const recuperarTipoDeAgendamentoPorIdentificador = identificador => api.get(`/tipos-de-agendamento/${identificador}`)
const salvarTipoDeAgendamento = tipoDeAgendamento => api.put(`/tipos-de-agendamento/${tipoDeAgendamento.identificador}`, tipoDeAgendamento)
const removerTipoDeAgendamento = identificador => api.delete(`/tipos-de-agendamento/${identificador}`)

export default {
  adicionarTipoDeAgendamento,
  removerTipoDeAgendamento,
  listarTiposDeAgendamento,
  recuperarTipoDeAgendamentoPorIdentificador,
  salvarTipoDeAgendamento,
}