import React from 'react'
import {DatePicker, Formulario as FormularioDoFormik, MultiplaEscolha} from '../../../../formik/formulario'
import {contemValor} from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE
} from '../../../../../acoes/tipos'

const obrigatorio = valor => contemValor(valor) ? undefined : 'Obrigatório'
const renderizarTitulo = valor => valor.descricao

export default function Formulario({ respostasDoCasp16, selecionado, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  const opcoes = [{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]

  return (
    <Spinner operacoes={[ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE, ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar Escala Ambiental de Risco de Quedas' : 'Adicionar Escala Ambiental de Risco de Quedas'}</h2>
          <Data />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-escala-ambiental'>
            <Cabecalho />
            <AreasDeLocomocao opcoes={opcoes} />
            <Iluminacao opcoes={opcoes} />
            <QuartoDeDormir opcoes={opcoes} />
            <Banheiro opcoes={opcoes} />
            <Cozinha opcoes={opcoes} />
            <Escada opcoes={opcoes} />
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>ESCALA AMBIENTAL DE RISCO DE QUEDAS</strong>
      </div>
    </r-cell>
  )
}

function AreasDeLocomocao({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ÁREAS DE LOCOMOÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='areasDeLocomocao.asAreasDeLocomocaoSaoDesimpedidas'
          opcoes={opcoes}
          pergunta='1. As áreas de locomoção são desimpedidas?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='areasDeLocomocao.existemBarrasDeApoio'
          opcoes={opcoes}
          pergunta='2. Existem barras de apoio?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='areasDeLocomocao.osRevestimentosSaoUniformesEOsTapetesSaoBemFixos'
          opcoes={opcoes}
          pergunta='3. Os revestimentos são uniformes e os tapetes são bem fixos?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function Iluminacao({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ILUMINAÇÃO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='iluminacao.existeIluminacaoSuficienteParaClarearTodoOInteriorIncluindoDegraus'
          opcoes={opcoes}
          pergunta='4. Existe iluminação suficiente para clarear todo interior de cada cômodo, incluindo degraus?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='iluminacao.existemInterruptoresAcessiveisNaEntradaDosComodos'
          opcoes={opcoes}
          pergunta='5. Existem interruptores acessíveis na entrada dos cômodos?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function QuartoDeDormir({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>QUARTO DE DORMIR</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='quartoDeDormir.oGuardaRoupaPossuiCabidesFacilmenteAcessiveis'
          opcoes={opcoes}
          pergunta='6. O guarda-roupa possui cabides facilmente acessíveis?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='quartoDeDormir.existeCadeiraQuePermiteOPacienteSeAssentarParaSeVestir'
          opcoes={opcoes}
          pergunta='7. Existe cadeira que permite o paciente se assentar para se vestir?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='quartoDeDormir.aCamaPossuiBoaAltura'
          opcoes={opcoes}
          pergunta='8. A cama possui boa altura (45cm)?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function Banheiro({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>BANHEIRO</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='banheiro.aAreaDoChuveiroPossuiAntiderrapante'
          opcoes={opcoes}
          pergunta='9. A área do chuveiro possui antiderrapante?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='banheiro.oBoxPossuiAberturaFacilOuSePossuiCortinaElaEstahBemFirme'
          opcoes={opcoes}
          pergunta='10. O box possui abertura fácil ou, se possui cortina, ela está bem firme?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function Cozinha({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>COZINHA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='cozinha.osArmariosSaoBaixosSemNecessidadeDoUsoDeEscada'
          opcoes={opcoes}
          pergunta='11. Os armário são baixos, sem necessidade do uso de escada?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function Escada({ opcoes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ESCADA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escada.existeRevestimentoAntiderrapanteComMarcacaoAmarelaNoPrimeiroEUltimoDegrau'
          opcoes={opcoes}
          pergunta='12. Existe revestimento antiderrapante, com marcação do primeiro e do último degrau com faixa amarela?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escada.existeCorrimaoBilateralSolidoEProlongadoAlemDoPrimeiroEUltimoDegrau'
          opcoes={opcoes}
          pergunta='13. Existe corrimão bilateral, sólido e que se prolonga além do primeiro e do último degrau?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='escada.osDegrausSaoUniformes'
          opcoes={opcoes}
          pergunta='14. Os degraus são uniformes (com altura dos espelhos e profundidade constantes)?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}