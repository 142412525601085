import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS
} from '../tipos'

const listouTiposDeAtendimentoParaONps = tiposDeAtendimento => ({
  type: LISTOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  tiposDeAtendimento,
})

const erroAoListarTiposDeAtendimentoParaONps = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  erro
})

export const listarTiposDeAtendimentoParaONps = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS })

  try {
    const resultado = await api.listarTiposDeAtendimentoParaONps()

    dispatch(listouTiposDeAtendimentoParaONps(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarTiposDeAtendimentoParaONps(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar os tipos de atendimento para o NPS.')))

    return false
  }
}

const habilitouTiposDeAtendimentoParaONps = () => async dispatch => {
  dispatch({ type: HABILITOU_TIPOS_DE_ATENDIMENTO_PARA_O_NPS })
}

const erroAoHabilitarTiposDeAtendimentoParaONps = (erro, parametros) => ({
  type: ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  erro,
  parametros
})

export const habilitarTiposDeAtendimentoParaONps = tipos => async dispatch => {
  dispatch({ type: HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS })

  try {
    const resultado = await api.habilitarTiposDeAtendimentoParaONps(tipos)

    dispatch(habilitouTiposDeAtendimentoParaONps())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoHabilitarTiposDeAtendimentoParaONps(erro, { tipos }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao habilitar tipos de atendimento para o NPS.')))

    return false
  }
}

const desabilitouTipoDeAtendimentoParaONps = () => async dispatch => {
  dispatch({ type: DESABILITOU_TIPO_DE_ATENDIMENTO_PARA_O_NPS })
}

const erroAoDesabilitarTipoDeAtendimentoParaONps = (erro, parametros) => ({
  type: ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  erro,
  parametros
})

export const desabilitarTipoDeAtendimentoParaONps = tipo => async dispatch => {
  dispatch({ type: DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS })

  try {
    const resultado = await api.desabilitarTipoDeAtendimentoParaONps(tipo)

    dispatch(desabilitouTipoDeAtendimentoParaONps())
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDesabilitarTipoDeAtendimentoParaONps(erro, { tipo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao desabilitar tipo de atendimento para o NPS.')))

    return false
  }
}