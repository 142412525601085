import React, { useEffect, useState } from 'react'
import Spinner from '../../spinner'
import Modal from 'react-modal'
import Skeleton from 'react-loading-skeleton'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import { Checkbox, Formulario as FormularioDoFormik, Input, Select } from '../../formik/formulario'
import { formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import * as Yup from 'yup'
import moment from 'moment'
import Agendamento from '../agendamento/com-redux'
import FotoDoPaciente from '../foto-do-paciente'
import ContatosDoAgendamento from '../agendamento/contatos-do-agendamento'
import LinksDaTeleconsulta from '../agendamento/links-da-teleconsulta'
import { CANCELAR_AGENDAMENTO, CONFIRMAR_AGENDAMENTO, EXCLUIR_AGENDAMENTO } from '../../../acoes/tipos'

export default function VisualizarAgendamento({
  agendamento,
  agendamentoSelecionado,
  cancelarAgendamento,
  confirmarAgendamento,
  excluirAgendamento,
  fotos,
  identificadorDoUsuarioLogado,
  match,
  motivosDeCancelamento,
  fechar,
  profissional,
  recuperarAgendamentoPeloIdentificador,
  recuperarAgendaSemanalDoProfissional,
  recuperarFotoDoPacientePorIdentificador,
  removerImagemDaMemoriaPorIdentificador,
  selecionarAgendamentoParaAlteracao,
  selecionarProfissionalDaAgenda,
  setExibirCancelar,
  setExibirConfirmar,
  setExibirExcluir,
  exibirCancelar,
  exibirExcluir,
  exibirConfirmar
}) {

  const identificadorDoPaciente = agendamento && agendamento.paciente.identificador
  const dataMaiorOuIgualAHoje = moment(agendamento.inicio).isSameOrAfter(moment(), 'day')

  const [exibirAlteracaoDeAgendamento, setExibirAlteracaoDeAgendamento] = useState(false)

  const statusCancelado = agendamento && agendamento.status[0].nome === 'Cancelado'
  const statusAtendido = agendamento && agendamento.status[0].nome === 'Atendido'
  const statusNaoAtendido = agendamento && agendamento.status[0].nome === 'Compareceu mas não foi atendido'
  const statusConfirmado = agendamento && agendamento.status[0].nome === 'Confirmado'
  const statusAguardandoAtendimento = agendamento && agendamento.status[0].nome === 'Aguardando atendimento'

  const podeExcluir = usePossuiAsPermissoes([permissoes.REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]) && dataMaiorOuIgualAHoje
  const podeCancelar = usePossuiAsPermissoes([permissoes.CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]) && dataMaiorOuIgualAHoje
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]) && dataMaiorOuIgualAHoje
  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]) && dataMaiorOuIgualAHoje && confirmarAgendamento && !statusAguardandoAtendimento
  const podeExcluirRecorrentes = usePossuiAsPermissoes([permissoes.REMOVER_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE])
  const podeCancelarRecorrentes = usePossuiAsPermissoes([permissoes.CANCELAR_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE])
  const permissaoDeIniciarAtendimento = usePossuiAsPermissoes([permissoes.INICIAR_ATENDIMENTO])
  const permissaoDeListarAtendimentos = usePossuiAsPermissoes([permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE])
  const exibirBotaoProntuario = permissaoDeIniciarAtendimento || permissaoDeListarAtendimentos

  const element = document.getElementById('modal');

  const profissionalEhOMesmoDoAgendamento = agendamento && agendamento.profissional.identificador === identificadorDoUsuarioLogado
  const urlDoProntuario = profissionalEhOMesmoDoAgendamento ? `prontuario/novo/origem/${agendamento.identificador}` : `prontuario`

  useEffect(() => {
    if (exibirCancelar || exibirExcluir || exibirConfirmar)
      element.scrollIntoView(false)

  }, [exibirCancelar, exibirExcluir, exibirConfirmar, element])

  useEffect(() => {
    let isMounted = recuperarFotoDoPacientePorIdentificador !== undefined
    if (isMounted && agendamento.paciente.foto) recuperarFotoDoPacientePorIdentificador(agendamento.paciente.identificador, agendamento.paciente.foto)

    return () => {
      isMounted = false
      agendamento.paciente && agendamento.paciente.foto && removerImagemDaMemoriaPorIdentificador && removerImagemDaMemoriaPorIdentificador(agendamento.paciente.foto)
    }
  }, [agendamento.paciente, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  function formatarDiaDaSemana(dia) {
    return dia.charAt(0).toUpperCase() + dia.slice(1)
  }

  function diaDaSemana(dia) {
    const diaDaSemanaFormatado = formatarDiaDaSemana(moment(dia).format('dddd'))
    const dataFormatada = moment(dia).format('LL')

    return `${diaDaSemanaFormatado}, ${dataFormatada}`
  }

  const recuperarLink = mensagem => {
    if (mensagem?.metaDados?.tipo === 'AGENDAMENTO_COM_PRE_CADASTRO') {
      return <Link to={`/cadastros/pacientes/${identificadorDoPaciente}`} target='_blank'>{' '}[Ir para o Cadastro de Pacientes]</Link>
    } else if (mensagem?.metaDados?.tipo === 'AGENDAMENTO_SEM_CONVENIO') {
      return <Link to={`/cadastros/pacientes/${identificadorDoPaciente}/convenios`} target='_blank'>{' '}[Ir para os Convênios do Paciente]</Link>
    } else {
      return ''
    }
  }

  const renderizarAlerta = mensagens => {
    return (
      <>
        {mensagens.map((x, index) => x.tipo === 'Alerta' ?
          <div className='form-choice-alerta' key={index}>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
              <strong>
                {recuperarLink(x)}
              </strong>
            </div>
          </div>
          :
          <div className='form-choice-erro' key={index}>
            <div className='form-choice-erro__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-erro__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
        )}
        <hr className='separator mt-1 mb-1'></hr>
      </>
    )
  }

  const cancelar = async (formulario, setTouched) => {
    setTouched({ motivoDoCancelamento: true })

    const dados = {
      identificadorDoProfissional: agendamento.profissional.identificador,
      identificador: agendamento.identificador,
      status: 'cancelado',
      todos: formulario.cancelarTodos ? true : false,
      motivo: formulario.motivoDoCancelamento,
      observacoes: formulario.observacoesDoCancelamento
    }

    if (formulario.motivoDoCancelamento) {
      const cancelou = await cancelarAgendamento(dados)

      if (cancelou) {
        recuperarAgendaSemanalDoProfissional && recuperarAgendaSemanalDoProfissional()
        fechar()
      }
    }
  }

  const excluir = async formulario => {
    const dados = {
      identificadorDoProfissional: agendamento.profissional.identificador,
      identificador: agendamento.identificador,
      todos: formulario.excluirTodos ? true : false,
    }

    const excluiu = await excluirAgendamento(dados)

    if (excluiu) {
      recuperarAgendaSemanalDoProfissional && recuperarAgendaSemanalDoProfissional()
      fechar()
    }
  }

  const confirmar = async formulario => {
    const dados = {
      identificadorDoProfissional: agendamento.profissional.identificador,
      identificador: agendamento.identificador,
      status: 'Confirmado',
      observacoes: formulario.observacoesDaConfirmacao,
    }

    const confirmou = await confirmarAgendamento(dados)

    if (confirmou) {
      fechar()
    }
  }

  function abrirAlterar() {
    const dados = {
      ...agendamento,
      dataInicio: agendamento.inicio,
      dataFim: agendamento.fim
    }

    selecionarAgendamentoParaAlteracao(dados)
    selecionarProfissionalDaAgenda(agendamento.profissional.identificador)
    setExibirAlteracaoDeAgendamento(true)
  }

  function fecharAlterar() {
    setExibirAlteracaoDeAgendamento(false)
    selecionarProfissionalDaAgenda(null)
    recuperarAgendamentoPeloIdentificador(agendamentoSelecionado.profissional.identificador, agendamentoSelecionado.identificador)
  }

  function recuperarNomeDoProfissional(profissional) {
    const jahPossuiTratamento = profissional.nome.includes('Dr.') || profissional.nome.includes('Dra.')
    let nomeDoProfissional = profissional.tratamento && !jahPossuiTratamento ? profissional.tratamento.concat(' ', profissional.nome) : profissional.nome

    if (profissional.profissao.especialidades.length === 0) {
      nomeDoProfissional += ` (${profissional.profissao.nome})`
    }

    if (profissional.profissao.especialidades.length > 0) {
      const especialidades = profissional.profissao.especialidades.join(', ')
      nomeDoProfissional += ` (${profissional.profissao.nome} - ${especialidades})`
    }

    return `${nomeDoProfissional}`
  }

  return (
    <div>
      <Modal
        isOpen={exibirAlteracaoDeAgendamento}
        className='modal'
        contentLabel='Modal para alteração'
        style={{ content: { width: '1100px' } }}
      >
        <Agendamento
          match={match}
          fechar={fecharAlterar}
          profissional={profissional}
        />
      </Modal>
      <Spinner operacoes={[CANCELAR_AGENDAMENTO, EXCLUIR_AGENDAMENTO, CONFIRMAR_AGENDAMENTO]}>
        <FormularioDoFormik
          reinicializar={true}
          valoresIniciais={{
            observacoes: agendamento.observacoes || '',
            observacoesDoCancelamento: '',
            observacoesDaConfirmacao: '',
            motivoDoCancelamento: '',
            cancelarTodos: '',
            excluirTodos: ''
          }}
          esquemaDeValidacoes={Yup.object().shape({
            motivoDoCancelamento: exibirCancelar ? Yup.string().required('Obrigatório.') : '',
          })}
        >
          {({ values, setTouched }) => (
            <div id='modal'>
              <div className={exibirExcluir || exibirCancelar || exibirConfirmar ? 'form-lock-error' : ''}>
                <fieldset>
                  <h2 className='form-title'>Agendamento</h2>
                  {agendamento ?
                    <>
                      {agendamento.feriado && !agendamento.feriadoTrabalhado && renderizarAlerta([{ tipo: 'Alerta', texto: 'Este agendamento foi realizado no feriado. Por favor, <strong>reagende ou cancele este agendamento</strong>.' }])}
                      {agendamento.feriadoTrabalhado && renderizarAlerta([{ tipo: 'Alerta', texto: 'Este agendamento foi realizado no feriado.' }])}
                      {agendamento.horario && agendamento.horario.unidade.identificador !== agendamento.unidade.identificador && renderizarAlerta([{ tipo: 'Erro', texto: `Este horário está configurado para outra unidade. Por favor, <strong>reagende para a unidade ${agendamento.horario.unidade.nome} ou cancele este agendamento</strong>.` }])}
                      {agendamento.bloqueado && !statusCancelado && renderizarAlerta([{ tipo: 'Erro', texto: 'Este horário está bloqueado. Por favor, <strong>reagende ou cancele este agendamento</strong>.' }])}
                      {agendamento.mensagens.length > 0 && renderizarAlerta(agendamento.mensagens)}
                      <div className='user-paciente-agenda-modal'>
                        <FotoDoPaciente
                          paciente={agendamento.paciente}
                          fotos={fotos}
                        />
                        <div>
                          <div className={`${exibirBotaoProntuario && 'user-paciente-agenda-modal__header'}`}>
                            <p className='user-paciente-agenda-modal__name'>{agendamento.paciente.nome}</p>
                            {exibirBotaoProntuario &&
                              <Link to={`/cadastros/pacientes/${identificadorDoPaciente}/${urlDoProntuario}`} className='button --primary --icon botao-agendamento'>
                                <i className='icon icon-doctor'></i>
                              </Link>
                            }
                          </div>
                          <div className='user-paciente-agenda-modal__info'>
                            <p><strong>Idade:</strong>{' '}{agendamento.paciente.idade}</p>
                            <p><strong>Sexo:</strong>{' '}{agendamento.paciente.sexo}</p>
                            {agendamento.convenio ? <p><strong>Convênio:</strong>{' '}{agendamento.convenio.operadora}{' - '}{agendamento.convenio.numeroDaCarteira}</p> : agendamento.particular && <p><strong>Convênio:</strong>{' '}{'Particular'}</p>}
                            {agendamento.paciente.associacao && <p><strong>Associação:</strong>{' '}{agendamento.paciente.associacao}</p>}
                            {agendamento.paciente.programas.length > 0 && <p><strong>Programa(s):</strong>{' '}{agendamento.paciente.programas.join(', ')}</p>}
                          </div>
                        </div>
                      </div>
                      <hr className='separator mt-1 mb-1'></hr>
                      <div className='unity-infos'>
                        <div className='unity-infos__item'>
                          <ContatosDoAgendamento agendamento={agendamento} />
                        </div>
                        <div className='unity-infos-item'>
                          <p>
                            <strong><i className='icon icon-calendar-empty'></i>{diaDaSemana(agendamento.inicio)}</strong>
                          </p>
                          <p>
                            <strong><i className='icon icon-clock'></i>{formatarHoraParaFormatoLocal(agendamento.inicio)} às {formatarHoraParaFormatoLocal(agendamento.fim)}</strong>
                          </p>
                        </div>
                        <div className='unity-infos__item'>
                          <p><strong>Unidade:</strong>{' '}{agendamento.unidade.nome}</p>
                          <p><strong>Tipo:</strong>{' '}{agendamento.tipo.nome}</p>
                          <p><strong>Profissional:</strong>{' '}{recuperarNomeDoProfissional(agendamento.profissional)}</p>
                        </div>
                        <div className='unity-infos__item'>
                          <span>
                            <strong>Status:</strong>{' '}{agendamento.status[0].nome}
                            <button
                              data-tip
                              data-for={`info_${agendamento.identificador}`}
                              key={`info_${agendamento.identificador}`}
                              type='button'
                              className='botao-historico'
                              onMouseOver={() => ReactTooltip.rebuild()}
                            >
                              <i className='icon icon-history'></i>
                            </button>
                          </span>
                          <p>{agendamento.status[0].observacoes}</p>
                        </div>
                        <LinksDaTeleconsulta agendamento={agendamento.identificadorPublico} />
                      </div>
                      <r-grid columns-md={6} columns-lg={12} class='mt-3'>
                        <r-cell span={4} span-md={12} span-lg={12}>
                          <Input
                            as='textarea'
                            nome='observacoes'
                            tabIndex={1}
                            titulo='Observações'
                          />
                        </r-cell>
                      </r-grid>
                    </>
                    :
                    <>
                      <div className='user-paciente-agenda-modal'>
                        <Skeleton variant='rect' width={115} height={115} />
                        <div>
                          <p className='user-paciente-agenda-modal__name'>
                            <Skeleton variant='rect' width={362} height={21} className='skeleton-name' />
                          </p>
                          <div className='user-paciente-agenda-modal__info'>
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                            <Skeleton variant='rect' width={240} height={21} className='skeleton-paciente-info' />
                          </div>
                        </div>
                      </div>
                      <hr className='separator mt-1 mb-1'></hr>
                      <div className='unity-infos'>
                        <Skeleton variant='rect' width={654} height={75} className='skeleton-info' />
                        <Skeleton variant='rect' width={654} height={93} className='skeleton-info' />
                      </div>
                      <Skeleton variant='rect' width={654} height={150} className='skeleton-observacao' />
                    </>
                  }
                  <div className='list-btn'>
                    {podeExcluir && <button type='button' onClick={() => setExibirExcluir(true)} disabled={statusAtendido} className='button --danger minw-auto'>Excluir</button>}
                    {podeCancelar && <button type='button' onClick={() => setExibirCancelar(true)} disabled={statusCancelado || statusAtendido || statusNaoAtendido} className='button --danger-light minw-auto'>Cancelar</button>}
                    {podeConfirmar && <button type='button' onClick={() => setExibirConfirmar(true)} disabled={statusConfirmado || statusCancelado || agendamento.bloqueado || (agendamento.horario && agendamento.horario.unidade.identificador !== agendamento.unidade.identificador)} className='button --primary minw-auto'>Confirmar</button>}
                    {podeAlterar && <button type='button' onClick={() => abrirAlterar()} disabled={statusCancelado || statusAtendido || statusNaoAtendido} className='button --primary minw-auto'>Alterar</button>}
                    <button type='button' onClick={() => fechar()} className='button --light minw-auto'>Fechar</button>
                  </div>
                </fieldset>
              </div>
              {exibirExcluir &&
                <fieldset>
                  <r-grid columns-md={6} columns-lg={12}>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <p className='msg-confirm'>Tem certeza que deseja excluir o agendamento?</p>
                    </r-cell>
                    {podeExcluirRecorrentes && agendamento.recorrente && agendamento.recorrencia &&
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <Checkbox
                          nome='excluirTodos'
                          titulo='Excluir Todos Recorrentes'
                        />
                      </r-cell>
                    }
                  </r-grid>
                  <div className='list-btn'>
                    <button type='button' onClick={() => setExibirExcluir(false)} className='button --danger-light'>Fechar</button>
                    <button type='button' onClick={() => excluir(values)} className='button --danger'>Sim, Excluir Agendamento</button>
                  </div>
                </fieldset>
              }
              {exibirCancelar &&
                <fieldset>
                  <h2 className='form-title text-danger'>Cancelar Agendamento</h2>
                  <r-grid columns-md={6} columns-lg={12} className='mt-3'>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Select
                        nome='motivoDoCancelamento'
                        titulo='Motivo do Cancelamento *'
                        itens={motivosDeCancelamento}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    {podeCancelarRecorrentes && agendamento.recorrente && agendamento.recorrencia &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Checkbox
                          nome='cancelarTodos'
                          titulo='Cancelar Todos Recorrentes'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoesDoCancelamento'
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                  <div className='list-btn'>
                    <button type='button' onClick={() => setExibirCancelar(false)} className='button --danger-light'>Fechar</button>
                    <button type='button' onClick={() => cancelar(values, setTouched)} className='button --danger'>Sim, Cancelar Agendamento</button>
                  </div>
                </fieldset>
              }
              {exibirConfirmar &&
                <fieldset>
                  <h2 className='form-title text-primary'>Confirmar Agendamento</h2>
                  <r-grid columns-md={6} columns-lg={12} className='mt-3'>
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoesDaConfirmacao'
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                  <div className='list-btn'>
                    <button type='button' onClick={() => setExibirConfirmar(false)} className='button --light'>Fechar</button>
                    <button type='button' onClick={() => confirmar(values)} className='button --primary'>Sim, Confirmar Presença</button>
                  </div>
                </fieldset>
              }
            </div>
          )}
        </FormularioDoFormik>
      </Spinner>
    </div >
  )
}