import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, DatePicker, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import {ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE, ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE} from '../../../../acoes/tipos'

export default function Formulario({ fechar, selecionado, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const salvou = await rest.salvar(valores)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE, ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={false}
        valoresIniciais={{
          identificador: selecionado ? selecionado.identificador : '',
          texto: selecionado ? selecionado.texto : '',
          data: selecionado ? selecionado.data : ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          data: Yup.string().nullable().required('Obrigatório.'),
          texto: Yup.string().required('Obrigatório.')
        })}
        acao={salvar}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar Registro Documental do Psicólogo' : 'Adicionar  Registro Documental do Psicólogo'}</h2>
          <r-grid columns-md={6} columns-lg={12}>
              <r-cell span={4} span-md={4} span-lg={4}>
                <DatePicker
                  nome='data'
                  titulo='Data *'
                  tabIndex={1}
                  maxDate={new Date()}
                />
              </r-cell>
            <r-cell span={4} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                titulo='Texto *'
                nome='texto'
                tabIndex={2}
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}