import constantes from '../../configuracoes/constantes'
import {
  ALTERAR_FILTROS_DO_TAREFAS,
  ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS,
  LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  DEFINIR_ABA_ATUAL,
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case DEFINIR_ABA_ATUAL: {
      return {
        ...state,
        [action.identificadorDoTipo]: {
          ...state[action.identificadorDoTipo],
          abaAtual: action.aba,
        }
      }
    }

    case ALTERAR_FILTROS_DO_TAREFAS: {
      const abaAtual = state[action.identificadorDoTipo] && state[action.identificadorDoTipo].abaAtual ? state[action.identificadorDoTipo].abaAtual : 'minhas'

      return {
        ...state,
        [action.identificadorDoTipo]: {
          ...state[action.identificadorDoTipo],
          [abaAtual]: {
            ...action.filtrosAtuais,
            limite: action.filtrosAtuais.limite ? action.filtrosAtuais.limite : constantes.numeroTarefasPorPagina,
            termo: action.filtrosAtuais.paciente ? action.filtrosAtuais.termo : '',
            tiposParaExibir: action.filtrosAtuais.tiposParaExibir ? action.filtrosAtuais.tiposParaExibir : []
          }
        }
      }
    }

    case ALTERAR_TERMO_DO_FILTRO_DE_PACIENTES_DO_TAREFAS: {
      const abaAtual = state[action.identificadorDoTipo] && state[action.identificadorDoTipo].abaAtual ? state[action.identificadorDoTipo].abaAtual : 'minhas'
      const filtrosDaTarefa = state[action.identificadorDoTipo]

      return {
        ...state,
        [action.identificadorDoTipo]: {
          ...filtrosDaTarefa,
          [abaAtual]: {
            ...filtrosDaTarefa[abaAtual],
            termo: action.termo
          }
        }
      }
    }

    case LISTOU_PACIENTES_DOS_FILTROS_DO_TAREFAS: {
      const abaAtual = state[action.identificadorDoTipo] && state[action.identificadorDoTipo].abaAtual ? state[action.identificadorDoTipo].abaAtual : 'minhas'
      const filtrosDaTarefa = state[action.identificadorDoTipo]

      return {
        ...state,
        [action.identificadorDoTipo]: {
          ...filtrosDaTarefa,
          [abaAtual]: {
            ...filtrosDaTarefa[abaAtual],
            pacientes: action.pacientes.dados
          }
        }
      }
    }

    default: {
      return state
    }
  }
}