import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_PROFISSIONAIS_DE_SAUDE,
  LISTOU_PROFISSIONAIS_DE_SAUDE,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE,
  RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
} from './tipos'

const listouProfissionaisDeSaude = profissionaisDeSaude => ({
  type: LISTOU_PROFISSIONAIS_DE_SAUDE,
  profissionaisDeSaude,
})

const erroAoListarProfissionaisDeSaude = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE,
  parametros,
  erro
})

export const listarProfissionaisDeSaude = atende => async dispatch => {
  dispatch({ type: LISTAR_PROFISSIONAIS_DE_SAUDE })

  try {
    const resultado = await api.listarProfissionaisDeSaude('', atende)
    dispatch(listouProfissionaisDeSaude(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProfissionaisDeSaude(erro, { atende }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os profissionais de saúde.')))
  }
}

const recuperouListaDeEsperaDoProfissionalDeSaude = listaDeEspera => ({
  type: RECUPEROU_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  listaDeEspera,
})

const erroAoRecuperarListaDeEsperaDoProfissionalDeSaude = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  parametros,
  erro
})

export const recuperarListaDeEsperaDoProfissionalDeSaude = profissional => async dispatch => {
  dispatch({ type: RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE })
  
  try {
    const resultado = await api.recuperarListaDeEsperaDoProfissionalDeSaude(profissional)

    dispatch(recuperouListaDeEsperaDoProfissionalDeSaude(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarListaDeEsperaDoProfissionalDeSaude(erro, { profissional }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar a lista de espera do profissional de saúde.')))
  }
}

const adicionouPacienteNaListaDeEspera = paciente => ({
  type: ADICIONOU_PACIENTE_NA_LISTA_DE_ESPERA,
  paciente,
})

const erroAoAdicionarPacienteNaListaDeEspera = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  parametros,
  erro
})


export const adicionarPacienteNaListaDeEspera = (profissional, paciente) => async dispatch => {
  dispatch({ type: ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA })

  try {
    const resultado = await api.adicionarPacienteNaListaDeEspera(profissional, paciente)

    dispatch(adicionouPacienteNaListaDeEspera(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPacienteNaListaDeEspera(erro, { profissional, paciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o paciente na lista de espera do profissional de saúde.')))

    return false
  }
}

const alterouPacienteNaListaDeEspera = paciente => ({
  type: ALTEROU_PACIENTE_NA_LISTA_DE_ESPERA,
  paciente,
})

const erroAoAlterarPacienteNaListaDeEspera = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  parametros,
  erro
})


export const alterarPacienteNaListaDeEspera = (profissional, identificador, paciente) => async dispatch => {
  dispatch({ type: ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA })

  try {
    const resultado = await api.alterarPacienteNaListaDeEspera(profissional, identificador, paciente)

    dispatch(alterouPacienteNaListaDeEspera(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPacienteNaListaDeEspera(erro, { profissional, paciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o paciente na lista de espera do profissional de saúde.')))

    return false
  }
} 

const removeuPacienteNaListaDeEspera = identificador => ({
  type: REMOVEU_PACIENTE_NA_LISTA_DE_ESPERA,
  identificador,
})

const erroAoRemoverPacienteNaListaDeEspera = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  parametros,
  erro
})


export const removerPacienteNaListaDeEspera = (profissional, identificador) => async dispatch => {
  dispatch({ type: REMOVER_PACIENTE_NA_LISTA_DE_ESPERA })
  
  try {
    const resultado = await api.removerPacienteNaListaDeEspera(profissional, identificador)

    dispatch(removeuPacienteNaListaDeEspera(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRemoverPacienteNaListaDeEspera(erro, { profissional, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o paciente na lista de espera do profissional de saúde.')))
  }
}

export const selecionarPacienteDaListaDeEsperaDoProfissionalDeSaude = paciente => ({
  type: SELECIONAR_PACIENTE_DA_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  paciente,
})