import React from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { Select } from '../../formik/formulario'
import avatar from '../../../design/img/no-thumb.png'
import merge from './utils/merge.js'
import defaultStyle from './style.css'

export default function InputMension(props) {
  const {
    value,
    name,
    handleChange,
    handleSelection,
    handleKeyDown,
    dados,
    dadosUsuarios,
    placeholder,
    minHeight = 300,
    inputRef,
    disabled,
    fontFamily = 'Courier New',
  } = props

  let style = merge({}, defaultStyle, {
    control: {
      minHeight: minHeight,
      backgroundColor: disabled ? 'rgb(207, 207, 207)' : 'white',
      fontFamily: fontFamily,
    }
  })

  return (
    <MentionsInput
      id={name}
      allowSpaceInQuery={true}
      onKeyDown={handleKeyDown}
      onSelect={handleSelection}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      className='mentions'
      style={style}
      autoFocus={false}
      inputRef={inputRef}
    >
      <Mention
        type='helpers'
        trigger='@@'
        data={dados}
        className='mentions__mention'
        displayTransform={(id, display) => id.substring(id.indexOf(' ') + 1)}
        renderSuggestion={renderHelpers}
        markup='@@{{__display__}}'
        appendSpaceOnAdd={true}
      />
      <Mention
        type='users'
        trigger='@'
        data={dadosUsuarios}
        className='mentions__mention'
        renderSuggestion={renderUsuarios}
        markup='@{{__id__||__display__}}'
        appendSpaceOnAdd={true}
      />
    </MentionsInput>
  )
}

function renderUsuarios(suggestion, search, highlightedDisplay, index, focused) {
  return (
    <div>
      <img alt='Usuário' className='mentions__suggestions__avatar' src={suggestion.foto || avatar} />
      {highlightedDisplay}
    </div>

  )
}

function renderHelpers(suggestion, search, highlightedDisplay, index, focused) {
  return (
    <div className='mentions__suggestions__helpers'>
      <i className={`icon ${retornaAvatarDoToken(suggestion.display)}`}></i>
      <span>{highlightedDisplay}</span>
    </div>
  )
}

export function SelectTemplate({ nome, titulo, campoCodigo, campoDescricao, onChange, itens, onClickSalvarComoTemplate }) {
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    color: 'black'
  }

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const styleTemplate = {
    option: styles => {
      return {
        ...styles,
        fontFamily: 'Roboto',
        fontSize: '12px',
        zIndex: 13,
      }
    },
    control: styles => {
      return {
        ...styles,
        borderRadius: '5px',
        minHeight: '14px',
        fontSize: '12px'
      };
    },
    menuPortal: styles => ({
      ...styles,
      zIndex: 13
    })
  }

  const formatarGrupos = dados => (
    <div style={groupStyles}>
      <span>{dados.grupo}</span>
      <span style={groupBadgeStyles}>{dados.options.length}</span>
    </div>
  )

  return onClickSalvarComoTemplate ? (
            <>
              <Select
                  nome={nome}
                  options={itens}
                  formatGroupLabel={formatarGrupos}
                  campoCodigo={campoCodigo}
                  campoDescricao={campoDescricao}
                  onChange={onChange}
                  colourStyles={styleTemplate}
                  placeholder='Selecione um template se necessário...'
              />
              <div className='componente-react-mentions-button' onClick={onClickSalvarComoTemplate}>
                Salvar como Template
              </div>
            </>)
            :
      (<>
              <Select
                  titulo={titulo}
                  nome={nome}
                  options={itens}
                  formatGroupLabel={formatarGrupos}
                  campoCodigo={campoCodigo}
                  campoDescricao={campoDescricao}
                  onChange={onChange}
              />
            </>)
}

function retornaAvatarDoToken(token) {
  switch (token) {
    case 'assinatura': return 'icon-edit'
    case 'data_por_extenso': return 'icon-calendar-empty'
    case 'diagnosticos': return 'icon-doc-text'
    case 'empresa': return 'icon-empresa'
    case 'idas_ao_pronto_atendimento': return 'icon-sala'
    case 'idade_do_paciente': return 'icon-birthday'
    case 'internacoes': return 'icon-hospital'
    case 'medicamentos': return 'icon-plus'
    case 'paciente': return 'icon-pacientes'
    case 'quedas': return 'icon-down'
    case 'ultima_ida_ao_pronto_atendimento': return 'icon-sala'
    case 'ultima_internacao': return 'icon-hospital'
    case 'unidade': return 'icon-estabelecimento-de-saude'
    case 'vacinas': return 'icon-eyedropper'
    default: return ''
  }
}