import React from 'react'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import { DatePicker, Select, Formulario as FormularioDoFormik } from '../../formik/formulario'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { dataIgualOuAnterior } from '../../../bibliotecas/data'
import * as Yup from 'yup'
import moment from 'moment'

import {
  FAZER_DOWNLOAD_DE_INTERNACOES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
} from '../../../acoes/tipos'

export default function Formulario(props) {
  const {
    download,
    filtrosIniciais,
    programas
  } = props

  return (
    <div className='formulario-de-filtros'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES, FAZER_DOWNLOAD_DE_INTERNACOES]}>
            {({ processando }) => (
              <div className={`box-agenda ${processando && 'is-loading'} relatorios`}>
                <FormularioDoFormik
                  acao={download}
                  valoresIniciais={{
                    programa: filtrosIniciais.programa || '',
                    dataDeInicio: filtrosIniciais.dataDeInicio || '',
                    dataDeFim: filtrosIniciais.dataDeFim || '',
                  }}
                  esquemaDeValidacoes={Yup.object().shape({
                    dataDeInicio: Yup.string()
                      .nullable()
                      .required('Obrigatório')
                      .test(
                        "inicio_teste",
                        "Deve ser menor que o fim.",
                        function (value) {
                          const { dataDeFim } = this.parent;
                          return !value || dataIgualOuAnterior(value, dataDeFim);
                        }
                      ),
                    dataDeFim: Yup.string()
                      .nullable()
                      .required('Obrigatório')
                      .test(
                        "fim_teste",
                        "Deve ser maior que o início.",
                        function (value) {
                          const { dataDeInicio } = this.parent;
                          return !value || dataIgualOuAnterior(dataDeInicio, value);
                        }
                      ),
                    programa: Yup.string().required('Obrigatório'),
                  })}
                >
                  {({ values }) => (
                    <>
                      <fieldset>
                        <h2 className='form-title'>Internações</h2>
                        <r-grid columns-md='6' columns-lg='12'>
                          <r-cell span={4} span-md='6' span-lg='6'>
                            <Select
                              nome='programa'
                              itens={programas}
                              tabIndex={2}
                              campoCodigo='identificador'
                              campoDescricao='nome'
                              titulo='Programa *'
                            />
                          </r-cell>
                          <r-cell span={4} span-md='2' span-lg='3'>
                            <DatePicker
                              nome='dataDeInicio'
                              tabIndex={3}
                              titulo='Início *'
                              type='text'
                              maxDate={new Date()}
                            />
                          </r-cell>
                          <r-cell span={4} span-md='2' span-lg='3'>
                            <DatePicker
                              nome='dataDeFim'
                              tabIndex={4}
                              titulo='Fim *'
                              type='text'
                              minDate={moment(values.dataDeInicio).toDate()}
                              maxDate={new Date()}
                            />
                          </r-cell>
                        </r-grid>
                      </fieldset>
                      <div className='list-btn'>
                        <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_INTERNACOES]}>
                          <button type='submit' className='button --primary'>Gerar</button>
                        </Permissao>
                      </div>
                    </>
                  )}
                </FormularioDoFormik>
              </div>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}