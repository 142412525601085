import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'

import {
  LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
} from '../../tipos'

const listouMotivosDeBloqueioDaAgenda = motivosDeBloqueioDaAgenda => ({
  type: LISTOU_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  motivosDeBloqueioDaAgenda
})

const erroAoListarMotivosDeBloqueioDaAgenda = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  erro
})

export const listarMotivosDeBloqueioDaAgenda = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA })

  try {
    const resultado = await api.listarMotivosDeBloqueioDaAgenda()

    dispatch(listouMotivosDeBloqueioDaAgenda(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeBloqueioDaAgenda(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de bloqueio da agenda.')))
  }
}