import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import { ERRO_AO_DESBLOQUEAR_LOGIN_DO_USUARIO, DESBLOQUEOU_LOGIN_DO_USUARIO, DESBLOQUEAR_LOGIN_DO_USUARIO } from '../tipos'

const desbloqueouLoginDoUsuario = identificador => ({
  type: DESBLOQUEOU_LOGIN_DO_USUARIO,
  identificador,
})

const erroAoDesbloquearLoginDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_DESBLOQUEAR_LOGIN_DO_USUARIO,
  erro,
  parametros
})

export const desbloquearLoginDoUsuario = identificador => async dispatch => {
  dispatch({ type: DESBLOQUEAR_LOGIN_DO_USUARIO })

  try {
    const resultado = await api.desbloquearLoginDoUsuario(identificador)

    dispatch(desbloqueouLoginDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
    return resultado.data.dados.identificador

  } catch (erro) {
    dispatch(erroAoDesbloquearLoginDoUsuario(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao desbloquear o login do usuário.')))

    return null
  }
}