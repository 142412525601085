import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_STATUS_DE_INTERNACOES,
  LISTOU_STATUS_DE_INTERNACOES,
  ERRO_AO_LISTAR_STATUS_DE_INTERNACOES,
} from './tipos'

const listouStatusDeInternacoes = statusDeInternacoes => ({
  type: LISTOU_STATUS_DE_INTERNACOES,
  statusDeInternacoes
})

const erroAoListarStatusDeInternacoes = erro => ({
  type: ERRO_AO_LISTAR_STATUS_DE_INTERNACOES,
  erro
})

export const listarStatusDeInternacoes = () => async dispatch => {
  dispatch({ type: LISTAR_STATUS_DE_INTERNACOES })

  try {
    const resultado = await api.listarStatusDeInternacoes()

    dispatch(listouStatusDeInternacoes(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarStatusDeInternacoes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os status de internações.')))
  }
}