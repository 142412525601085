import {
  LISTOU_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_SINTOMAS_ASSOCIADOS_AO_LOCAL_DA_DOR_MUSCULOESQUELETICA: {
      return action.sintomasDorMusculoesqueletica
    }

    default: {
      return state
    }
  }
}
