import api from "../api-hercules";

const listarSetoresDoTarefas = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10'

  return api.get(`/setores${paginacao}${parametroNome}${ordem}${tamanhoDaPaginaFormatado}`)
}

const definirSetorResponsavelDaTarefa = (identificadorDaTarefa, setor) => {
  const parametroSetor = setor ? setor : null
  return api.put(`tarefas/${identificadorDaTarefa}/setor-responsavel`, { setor: parametroSetor })
}

export default {
  definirSetorResponsavelDaTarefa,
  listarSetoresDoTarefas
}