import React from 'react'
import { Link } from 'react-router-dom'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function AbasDaAgenda(props) {
  const { identificador, url } = props

  const BLOQUEIOS = url && url.indexOf('bloqueios') > 0 ? 'is-active' : ''
  const INSTRUCOES = url && url.indexOf('instrucoes') > 0 ? 'is-active' : ''
  const TIPOS_DE_AGENDAMENTO = url && url.indexOf('tipos-de-agendamento') > 0 ? 'is-active' : ''
  const FERIADOS_TRABALHADOS = url && url.indexOf('feriados-trabalhados') > 0 ? 'is-active' : ''
  const HORARIOS = !BLOQUEIOS && !INSTRUCOES && !TIPOS_DE_AGENDAMENTO && !FERIADOS_TRABALHADOS ? 'is-active' : ''

  return (
    <div className='pills-nav'>
      <Permissao permissoes={[permissoes.LISTAR_HORARIOS_DA_AGENDA]}>
        <Link
          to={`/configuracoes/usuarios/${identificador}/agenda`}
          className={`pills-nav__item ${HORARIOS}`}
          title='Horários'
        >
          Horários
        </Link>
      </Permissao>
      <Permissao permissoes={[permissoes.LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE]}>
        <Link
          to={`/configuracoes/usuarios/${identificador}/agenda/bloqueios`}
          className={`pills-nav__item ${BLOQUEIOS}`}
          title='Bloqueios'
        >
          Bloqueios
        </Link>
      </Permissao>
      <Permissao permissoes={[permissoes.LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]}>
        <Link
          to={`/configuracoes/usuarios/${identificador}/agenda/instrucoes`}
          className={`pills-nav__item ${INSTRUCOES}`}
          title='Instruções'
        >
          Instruções
        </Link>
      </Permissao>      
      <Permissao permissoes={[permissoes.LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE]}>
        <Link
          to={`/configuracoes/usuarios/${identificador}/agenda/tipos-de-agendamento`}
          className={`pills-nav__item ${TIPOS_DE_AGENDAMENTO}`}
          title='Tipos de Agendamento'
        >
          Tipos de Agendamento
        </Link>
      </Permissao>
      <Permissao permissoes={[permissoes.LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL]}>
        <Link
          to={`/configuracoes/usuarios/${identificador}/agenda/feriados-trabalhados`}
          className={`pills-nav__item ${FERIADOS_TRABALHADOS}`}
          title='Feriados Trabalhados'
        >
          Feriados Trabalhados
        </Link>
      </Permissao>
    </div>
  )
}