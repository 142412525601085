import React, { useEffect, useState } from 'react'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Spinner from '../../spinner'
import { Select, Formulario as Form } from '../../formik/formulario'
import {
  ADICIONAR_TAG_NO_PACIENTE,
} from '../../../acoes/tipos'

export default function FormularioDePrograma(props) {
  const {
    adicionarTag,
    listarTagsDePacientes,
    match,
    paciente,
    tags,
  } = props

  const { identificador } = match.params

  const [selecionado, setSelecionado] = useState('')

  const adicionarTagInformada = async (formulario) => {
    const adicionou = await adicionarTag(identificador, formulario.nome)

    if (adicionou) {
      setSelecionado('')
    }
  }

  useEffect(() => { listarTagsDePacientes() }, [listarTagsDePacientes])

  const removerTagsAdicionadas = (tagsAdicionadas = [], tags = []) => {
    if (tagsAdicionadas.length === 0) return tags

    const filtro = tag => {
      return tagsAdicionadas.findIndex(x => x.tag === tag) === -1
    }

    return tags.filter(filtro)
  }
  const tagsFiltradas = removerTagsAdicionadas(paciente.tags, tags)

  return (
    <Spinner operacoes={[ADICIONAR_TAG_NO_PACIENTE]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          nome: selecionado ? selecionado : ''
        }}
        acao={adicionarTagInformada}        
      >
        <div className="mt-2">
          <fieldset>
            <h2 className="form-title">Adicionar Tags</h2>
            <r-grid columns-md='6' columns-lg='12' class='align-end'>
              <r-cell span={4} span-md={4} span-lg={5}>
                <Select
                  criador={true}
                  nome='nome'
                  itens={tagsFiltradas}
                  tabIndex={1}
                  titulo='Tag *'
                  onChange={setSelecionado}
                />
              </r-cell>
              <Permissao permissoes={[permissoes.ADICIONAR_TAG_NO_PACIENTE]}>
                <r-cell span={4} span-md={2} span-lg={2}>
                  <button 
                    type='submit' 
                    className={!selecionado ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                    disabled={!selecionado}>
                    Adicionar
                  </button>
                </r-cell>
              </Permissao>
            </r-grid>
          </fieldset>
        </div>
      </Form>
    </Spinner>
  )
}