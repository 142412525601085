import React from 'react'

export default function EventoDelimitadorDeHorario({ itens = [] }) {

  if (!itens || itens.length === 0) return null

  return (
    <div
      className='time-location-line'
      style={{ top: `calc(${itens[0].posicionamento.top}px)` }}
    >
    </div>
  )
}