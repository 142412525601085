import api from '../../api-major'

const adicionarVacinaDoPaciente = (identificador, vacina) => api.post(`/pacientes/${identificador}/vacinas`, vacina)
const alterarVacinaDoPaciente = (identificador, vacina) => api.put(`/pacientes/${identificador}/vacinas/${vacina.identificador}`, vacina)
const removerVacinaDoPaciente = (identificador, identificadorDaVacina) => api.delete(`/pacientes/${identificador}/vacinas/${identificadorDaVacina}`)
const listarVacinasDoPaciente = identificador => api.get(`/pacientes/${identificador}/vacinas`)

export default { 
  adicionarVacinaDoPaciente,
  alterarVacinaDoPaciente,
  removerVacinaDoPaciente,
  listarVacinasDoPaciente
}