import api from '../../../api-major'

const adicionarAvaliacaoFisicaEFuncionalDoPaciente = (paciente, avaliacao) => api.post(`/pacientes/${paciente}/avaliacoes-fisicas-e-funcionais`, avaliacao)
const alterarAvaliacaoFisicaEFuncionalDoPaciente = (paciente, avaliacao) => api.put(`/pacientes/${paciente}/avaliacoes-fisicas-e-funcionais/${avaliacao.identificador}`, avaliacao)
const removerAvaliacaoFisicaEFuncionalDoPaciente = (paciente, identificador) => api.delete(`/pacientes/${paciente}/avaliacoes-fisicas-e-funcionais/${identificador}`)
const listarAvaliacoesFisicasEFuncionaisDoPaciente = paciente => api.get(`/pacientes/${paciente}/avaliacoes-fisicas-e-funcionais`)

export default { 
  adicionarAvaliacaoFisicaEFuncionalDoPaciente,
  alterarAvaliacaoFisicaEFuncionalDoPaciente,
  removerAvaliacaoFisicaEFuncionalDoPaciente,
  listarAvaliacoesFisicasEFuncionaisDoPaciente
}