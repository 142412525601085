import React from 'react'

export default function Finalizada({ alterar, valor = '' }) {
  const opcoes = [
    { codigo: '', nome: 'Todas' },
    { codigo: 'true', nome: 'Finalizadas' },
    { codigo: 'false', nome: 'Não Finalizadas' }
  ]

  const aoClicar = opcao => { alterar(opcao.codigo) }

  return (
    <div className='form-group'>
      <div className='encaminhamentos week-options'>
        {opcoes.map((x, index) => (
          <div
            key={index}
            name='finalizada'
            id='finalizada'
            className={`encaminhamentos week-options__item ${(valor === x.codigo ? 'is-selected' : '')}`}
            onClick={() => opcoes.includes(x) && aoClicar(x)}
          >
            {x.nome}
          </div>
        ))}
      </div>
    </div>
  )
}