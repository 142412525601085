import constantes from '../configuracoes/constantes'

const { timeoutDasNotificacoes } = constantes
const { ALERTA, ALERTA_MINIMO, ERRO, SUCESSO, INFORMACAO, LOG } = constantes.notificacoes

const deAlerta = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: mensagem.texto || mensagem,
  tipo: ALERTA,
  timeout: timeoutDasNotificacoes.alerta
})

const deAlertaMinimo = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: mensagem.texto || mensagem,
  tipo: ALERTA_MINIMO,
  timeout: timeoutDasNotificacoes.alerta_minimo
})

const deErro = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: (navigator.onLine ? mensagem.texto : 'OFFLINE: '.concat(mensagem)) || mensagem,
  tipo: ERRO,
  timeout: timeoutDasNotificacoes.erro
})

const deSucesso = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: mensagem.texto || mensagem,
  tipo: SUCESSO,
  timeout: timeoutDasNotificacoes.sucesso
})

const deInformacao = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: mensagem.texto || mensagem,
  tipo: INFORMACAO,
  timeout: timeoutDasNotificacoes.informacao
})

const deLog = mensagem => ({
  metaDados: mensagem.metaDados,
  texto: mensagem.texto || mensagem,
  tipo: LOG,
  timeout: timeoutDasNotificacoes.log
})

export const converterMensagemParaNotificacao = mensagem => {
  if (!mensagem) return null

  let notificacao = deInformacao

  if (mensagem.tipo === 'Alerta') {
    notificacao = deAlerta
  } else if (mensagem.tipo === 'Alerta Mínimo') {
    notificacao = deAlertaMinimo
  } else if (mensagem.tipo === 'Erro') {
    notificacao = deErro
  } else if (mensagem.tipo === 'Sucesso') {
    notificacao = deSucesso
  } else if (mensagem.tipo === 'Log') {
    notificacao = deLog
  }

  return notificacao(mensagem)
}

export default {
  deAlerta,
  deAlertaMinimo,
  deErro,
  deSucesso,
  deInformacao
}