import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
    LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
    LISTOU_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
    ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
} from '../tipos'

const listouMotivosDeCancelamentoDosAgendamentos = motivosDeCancelamentoDosAgendamentos => ({
    type: LISTOU_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
    motivosDeCancelamentoDosAgendamentos
})

const erroAoListarMotivosDeCancelamentoDosAgendamentos = erro => ({
    type: ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
    erro
})

export const listarMotivosDeCancelamentoDosAgendamentos = () => async dispatch => {
    dispatch({ type: LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS })

    try {
        let resultado = await api.listarMotivosDeCancelamentoDosAgendamentos()

        dispatch(listouMotivosDeCancelamentoDosAgendamentos(resultado.data.dados.dados))
        dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
        dispatch(erroAoListarMotivosDeCancelamentoDosAgendamentos(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos do cancelamento dos agendamentos.')))
    }
}