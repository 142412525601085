import api from '../api-major'

const adicionarAgendamento = (identificadorDoProfissional, agendamento) => api.post(`/profissionais-de-saude/${identificadorDoProfissional}/agendamentos`, agendamento)
const salvarAgendamento = (identificadorDoProfissional, agendamento) => api.put(`/profissionais-de-saude/${identificadorDoProfissional}/agendamentos/${agendamento.identificador}`, agendamento)
const excluirAgendamento = dados => api.delete(`/profissionais-de-saude/${dados.identificadorDoProfissional}/agendamentos/${dados.identificador}?todos=${dados.todos}`)
const cancelarAgendamento = dadosDoCancelamento => api.patch(`/profissionais-de-saude/${dadosDoCancelamento.identificadorDoProfissional}/agendamentos/${dadosDoCancelamento.identificador}/status`,
  dadosDoCancelamento)
const confirmarAgendamento = dadosDoConfirmacao => api.patch(`/profissionais-de-saude/${dadosDoConfirmacao.identificadorDoProfissional}/agendamentos/${dadosDoConfirmacao.identificador}/status`,
  dadosDoConfirmacao)
const recuperarConveniosDoPacienteSelecionadoDoAgendamento = identificador => api.get(`/pacientes/${identificador}/convenios?tamanhoDaPagina=1000&ordem=-identificador`)
const recuperarProgramasDoPacienteSelecionadoDoAgendamento = identificador => api.get(`/pacientes/${identificador}/programas?tamanhoDaPagina=1000&ordem=-identificador`)
const recuperarAgendamentoPeloIdentificador = (identificadorDoProfissional, identificadorDoAgendamento) => api.get(`/profissionais-de-saude/${identificadorDoProfissional}/agendamentos/${identificadorDoAgendamento}`)

export default {
  adicionarAgendamento,
  salvarAgendamento,
  excluirAgendamento,
  cancelarAgendamento,
  confirmarAgendamento,
  recuperarConveniosDoPacienteSelecionadoDoAgendamento,
  recuperarProgramasDoPacienteSelecionadoDoAgendamento,
  recuperarAgendamentoPeloIdentificador  
} 