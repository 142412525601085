import api from '../../api-major'

const adicionarMedicamentoDoPaciente = (paciente, medicamento) => api.post(`/pacientes/${paciente}/medicamentos`, medicamento)
const alterarMedicamentoDoPaciente = (paciente, identificador, medicamento) => api.put(`/pacientes/${paciente}/medicamentos/${identificador}`, medicamento)
const alterarPosicaoDosMedicamentosDoPaciente = (paciente, identificador, posicao) => api.put(`/pacientes/${paciente}/medicamentos/${identificador}/posicao`, posicao)
const alterarStatusDoMedicamentoDoPaciente = (paciente, identificador, dados) => api.put(`/pacientes/${paciente}/medicamentos/${identificador}/status`, dados)
const listarMedicamentosDoPaciente = (paciente) => api.get(`/pacientes/${paciente}/medicamentos`)

export default {
  adicionarMedicamentoDoPaciente,
  alterarMedicamentoDoPaciente,
  alterarPosicaoDosMedicamentosDoPaciente,
  alterarStatusDoMedicamentoDoPaciente,
  listarMedicamentosDoPaciente
}
