import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import { LISTAR_UNIDADES_FEDERATIVAS, LISTOU_UNIDADES_FEDERATIVAS, ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS } from './tipos'

const listouUnidadesFederativas = unidadesFederativas => ({
  type: LISTOU_UNIDADES_FEDERATIVAS,
  unidadesFederativas
})

const erroAoListarUnidadesFederativas = erro => ({
  type: ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS,
  erro
})

export const listarUnidadesFederativas = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_UNIDADES_FEDERATIVAS })

    try {
      const resultado = await api.listarUnidadesFederativas()

      dispatch(listouUnidadesFederativas(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarUnidadesFederativas(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os Unidades Federativas.')))
    }
  }

  acao.meta = {
    cache: LISTAR_UNIDADES_FEDERATIVAS
  }

  return acao
}