import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import { change } from 'redux-form'
import {
  ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
} from '../../tipos'

const adicionouPreCadastroDoPacienteDoWizardDeAgendamento = preCadastro => ({
  type: ADICIONOU_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  preCadastro
})

const erroAoAdicionarPreCadastroDoPacienteDoWizardDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  erro,
  parametros
})

export const adicionarPreCadastroDoPacienteDoWizardDeAgendamento = preCadastro => async dispatch => {
  dispatch({ type: ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO })

  try {
    const resultado = await api.adicionarPreCadastroDoPaciente(preCadastro)
    const retornoPreCadastro = resultado.data.dados

    dispatch(adicionouPreCadastroDoPacienteDoWizardDeAgendamento(retornoPreCadastro))
    dispatch(change('adicionarAgendamento', 'paciente', { identificador: retornoPreCadastro.identificador, nome: retornoPreCadastro.nome }))
    dispatch(change('adicionarAgendamento', 'telefone1', retornoPreCadastro.telefone1))
    dispatch(adicionarNotificacoesDaApi(resultado, notificacao.deSucesso('Dados gravados com sucesso.')))

    return retornoPreCadastro
  } catch (erro) {
    dispatch(erroAoAdicionarPreCadastroDoPacienteDoWizardDeAgendamento(erro, { preCadastro }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao gravar os dados do pré cadastro do paciente.')))

    return false
  }
}