import idasAoProntoAtendimento from './idas-ao-pronto-atendimento'
import internacoes from './internacoes'
import reinternacoes from './reinternacoes'
import atendimentos from './atendimentos'
import pacientes from './pacientes'
import pacientesDoPrograma from './pacientes-do-programa'
import resumoDeInternacoes from './resumo-de-internacoes'
import horasDosProfissionais from './horas-dos-profissionais'

export default (state = {}, action) => {
  return {
    ...state,
    idasAoProntoAtendimento: idasAoProntoAtendimento(state.idasAoProntoAtendimento, action),
    internacoes: internacoes(state.internacoes, action),
    reinternacoes: reinternacoes(state.reinternacoes, action),
    atendimentos: atendimentos(state.atendimentos, action),
    pacientes: pacientes(state.pacientes, action),
    pacientesDoPrograma: pacientesDoPrograma(state.pacientesDoPrograma, action),
    resumoDeInternacoes: resumoDeInternacoes(state.resumoDeInternacoes, action),
    horasDosProfissionais: horasDosProfissionais(state.horasDosProfissionais, action)
  }
}