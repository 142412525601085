import moment from 'moment'
import {
  LISTOU_ENCAMINHAMENTOS, LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS,
  LISTOU_STATUS_DE_ENCAMINHAMENTO,
  LISTOU_TIPOS_DE_ENCAMINHAMENTO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    inicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    fim: moment().format('YYYY-MM-DD'),
    status: '',
    profissionalQueEncaminhou: '',
    profissionalEncaminhado: '',
    paciente: '',
    prioridade: '',
    tipo: '',
    tamanhoDaPagina: 10
  },
  paginaDeDados: {},
  ordenacao: { nome: 'dataEHora', ordem: '+' },
  status: [],
  tipos: [],
  especialidadesMedicasInternas: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_ENCAMINHAMENTOS: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        paginaDeDados,
        ordenacao
      }
    }

    case LISTOU_STATUS_DE_ENCAMINHAMENTO: {
      return {
        ...state,
        status: action.statusDeEncaminhamento
      }
    }

    case LISTOU_TIPOS_DE_ENCAMINHAMENTO: {
      return {
        ...state,
        tipos: action.tiposDeEncaminhamento
      }
    }

    case LISTOU_ESPECIALIDADES_MEDICAS_INTERNAS: {
      return {
        ...state,
        especialidadesMedicasInternas: action.especialidades.dados
      }
    }

    default: {
      return state
    }
  }
}