import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  LER_CERTIFICADOS_DIGITAIS_FISICOS,
  LEU_CERTIFICADOS_DIGITAIS_FISICOS,
  ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS,
  SELECIONAR_CERTIFICADO_DIGITAL,
  ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM
} from './tipos'

export const lerCertificadosDigitaisFisicos = () => ({ type: LER_CERTIFICADOS_DIGITAIS_FISICOS })

export const leuCertificadosDigitaisFisicos = certificados => ({ type: LEU_CERTIFICADOS_DIGITAIS_FISICOS, certificados })

export const erroAoLerCertificadosDigitaisFisicos = erro => async dispatch => {
  dispatch({ type: ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS, erro })
  dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))
}

export const selecionarCertificadoDigital = certificado => ({ type: SELECIONAR_CERTIFICADO_DIGITAL, certificado })

export const assinarAtendimentoComCertificadoFisico = certEncoding => ({ type: ASSINAR_ATENDIMENTO_COM_CERTIFICADO_FISICO, certEncoding })

export const iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (paciente, atendimento, certificado) => async dispatch => {
  dispatch({ type: INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO })

  try {
    const { data } = await api.iniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico(paciente, atendimento, certificado)
    dispatch(iniciouAssinaturaDigitalDoAtendimentoComCertificadoFisico(data.dados))

    return true
  } catch (erro) {
    dispatch(erroAoIniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico(erro, {}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const iniciouAssinaturaDigitalDoAtendimentoComCertificadoFisico = dados => ({
  type: INICIOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  dados,
})

const erroAoIniciarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (erro, parametros) => ({
  type: ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  erro,
  parametros
})

export const finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (atendimento, identificadorDoArquivo, certificado, assinatura) => async dispatch => {
  dispatch({ type: FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO })

  try {
    const { data } = await api.finalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico(atendimento, identificadorDoArquivo, certificado, assinatura)

    dispatch(finalizouAssinaturaDigitalDoAtendimentoComCertificadoFisico(data.dados))
    dispatch(adicionarNotificacoesDaApi(data, notificacao.deSucesso('Atendimento assinado com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoFinalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico(erro, {}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const finalizouAssinaturaDigitalDoAtendimentoComCertificadoFisico = dados => ({
  type: FINALIZOU_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  dados,
})

const erroAoFinalizarAssinaturaDigitalDoAtendimentoComCertificadoFisico = (erro, parametros) => ({
  type: ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  erro,
  parametros
})

export const iniciarSessaoComCertificadoEmNuvem = (code, state) => async dispatch => {
  dispatch({ type: INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM })

  try {
    const { data } = await api.iniciarSessaoComCertificadoEmNuvem(code, state)
    dispatch(iniciouSessaoComCertificadoEmNuvem(true))

    return data.dados
  } catch (erro) {
    dispatch(erroAoIniciarSessaoComCertificadoEmNuvem(erro, {}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const iniciouSessaoComCertificadoEmNuvem = emAberto => {
  localStorage.setItem('sessao_iniciada', emAberto)
  return { type: INICIOU_SESSAO_COM_CERTIFICADO_EM_NUVEM }
}

const erroAoIniciarSessaoComCertificadoEmNuvem = (erro, parametros) => ({
  type: ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  erro,
  parametros
})

export const recuperarSessaoComCertificadoEmNuvem = () => async dispatch => {
  dispatch({ type: RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM })

  try {
    const { data } = await api.recuperarSessaoComCertificadoEmNuvem()
    dispatch(recuperouSessaoComCertificadoEmNuvem(true))

    return data.dados
  } catch (erro) {
    dispatch(recuperouSessaoComCertificadoEmNuvem(false))
    dispatch(erroAoRecuperarSessaoComCertificadoEmNuvem(erro, {}))

    if (erro.data && erro.data.mensagens) {
      const sessaoNaoEncontrada = erro.data.mensagens.find(n => n.metaDados?.tipo === 'SESSAO_DO_CERTIFICADO_EM_NUVEM_NAO_ENCONTRADA')
      if (sessaoNaoEncontrada) return false
    }

    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))
    return false
  }
}

const recuperouSessaoComCertificadoEmNuvem = emAberto => {
  localStorage.setItem('sessao_iniciada', emAberto)
  return { type: RECUPEROU_SESSAO_COM_CERTIFICADO_EM_NUVEM }
}


const erroAoRecuperarSessaoComCertificadoEmNuvem = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  erro,
  parametros
})

export const assinarAtendimentoComCertificadoEmNuvem = (atendimento, emLote) => async dispatch => {
  dispatch({ type: ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM })

  try {
    const { data } = await api.assinarAtendimentoComCertificadoEmNuvem(atendimento)

    dispatch(sinalizarAtendimentoAssinadoComCertificadoEmNuvem(data.dados))
    dispatch(assinouAtendimentoComCertificadoEmNuvem(data.dados))

    if (!emLote) dispatch(adicionarNotificacoesDaApi(data, notificacao.deSucesso('Atendimento assinado com sucesso.')))

    return true
  } catch (erro) {
    dispatch(erroAoAssinarAtendimentoComCertificadoEmNuvem(erro, {}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro(erro)))

    return false
  }
}

const assinouAtendimentoComCertificadoEmNuvem = dados => ({
  type: ASSINOU_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  dados,
})

const sinalizarAtendimentoAssinadoComCertificadoEmNuvem = dados => ({
  type: SINALIZAR_ATENDIMENTO_ASSINADO_COM_CERTIFICADO_EM_NUVEM,
  dados,
})

const erroAoAssinarAtendimentoComCertificadoEmNuvem = (erro, parametros) => ({
  type: ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  erro,
  parametros
})

export const recuperarCertificadosEmNuvemDoUsuario = () => async dispatch => {
  dispatch({ type: RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO })

  try {
    const { data } = await api.recuperarCertificadosEmNuvemDoUsuario()
    dispatch(recuperouCertificadosEmNuvemDoUsuario(data.dados))

    return true
  } catch (erro) {
    dispatch(erroAoRecuperarCertificadosEmNuvemDoUsuario(erro, {}))
    dispatch(adicionarNotificacoesDaApi('', notificacao.deErro('Não foi possível a comunicação com os provedores em nuvem.')))

    return false
  }
}

const recuperouCertificadosEmNuvemDoUsuario = dados => ({
  type: RECUPEROU_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  dados,
})

const erroAoRecuperarCertificadosEmNuvemDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  erro,
  parametros
})

export const recuperarUrlDeAutenticacaoDoCertificadoEmNuvem = (servico, tempo, atendimento, origem) => async dispatch => {
  dispatch({ type: RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM })

  try {
    const { data } = await api.recuperarUrlDeAutenticacaoDoCertificadoEmNuvem(servico, tempo, atendimento, origem)
    dispatch(recuperouUrlDeAutenticacaoDoCertificadoEmNuvem())

    return data.dados
  } catch (erro) {
    dispatch(erroAoRecuperarUrlDeAutenticacaoDoCertificadoEmNuvem(erro, {}))
    dispatch(adicionarNotificacoesDaApi('', notificacao.deErro('Não foi possível a comunicação com os provedores em nuvem.')))

    return false
  }
}

const recuperouUrlDeAutenticacaoDoCertificadoEmNuvem = () => ({ type: RECUPEROU_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM })

const erroAoRecuperarUrlDeAutenticacaoDoCertificadoEmNuvem = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  erro,
  parametros
})