import React from 'react'
import Spinner from '../../../../spinner'
import {MultiplaEscolha} from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'

import {LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO} from '../../../../../acoes/tipos'

const buscarPontuacao = (frequenciasNaEscalaDaSolidao, valor) => frequenciasNaEscalaDaSolidao.length > 0 && frequenciasNaEscalaDaSolidao.find(x => x.nome === valor).pontuacao
const renderizarTitulo = valor => {  return <>{valor.descricao}<sup>{valor.pontuacao}</sup> </> }

export default function Resumo({ frequenciasNaEscalaDaSolidao, valores, fechar }) {
  if (!valores.resultado) return null

  return (
    <Spinner operacoes={[LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO]}>
      <r-grid columns-md={6} columns-lg={12} class='form form-group'>
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className='form-title'>Resumo da Avaliação da Solidão</h2>
            <Paciente paciente={valores.paciente} />
            <Data data={valores.data} />
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-zarit'>
              <Cabecalho />
              <FaltaDeCompanhia frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={valores.faltaDeCompanhia} />
              <DeixadoDeLado frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={valores.deixadoDeLado} />
              <IsoladoDasOutrasPessoas frequenciasNaEscalaDaSolidao={frequenciasNaEscalaDaSolidao} valor={valores.isoladoDasOutrasPessoas} />
              <Resultado resultado={valores.resultado} />
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light' onClick={fechar}>Fechar</button>
            </div>
        </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-zarit paciente-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-zarit'>
        <strong>ESCALA DE SOLIDÃO - UCLA-3</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function FaltaDeCompanhia({ frequenciasNaEscalaDaSolidao, valor }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='faltaDeCompanhia'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='1. Com que frequência você sente que te falta companhia?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function DeixadoDeLado({ frequenciasNaEscalaDaSolidao, valor }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='deixadoDeLado'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='2. Com que frequência você se sente deixado de lado?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function IsoladoDasOutrasPessoas({ frequenciasNaEscalaDaSolidao, valor }) {
  const pontuacao = valor && frequenciasNaEscalaDaSolidao.length > 0 && buscarPontuacao(frequenciasNaEscalaDaSolidao, valor)

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='agrupamento'>
      <MultiplaEscolha
        className='pergunta'
        nome='isoladoDasOutrasPessoas'
        opcoes={frequenciasNaEscalaDaSolidao.map(x => ({ codigo: x.nome, descricao: x.nome, pontuacao: x.pontuacao }))}
        pergunta='3. Com que frequência você se sente isolado das outras pessoas?'
        valor={valor}
        renderizarTitulo={renderizarTitulo}
        leitura={true}
      />
      <div className='pontuacao'>{valor && frequenciasNaEscalaDaSolidao.length > 0 ? `${pontuacao}` : ''}</div>
    </r-cell>
  )
}

function Resultado({ resultado }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      {(resultado || resultado === 0) &&
       <div className='resultado-questionario'>
         <div><strong>RESULTADO</strong></div>
         <div><strong>{resultado}</strong></div>
       </div>
      }
    </r-cell>
  )
}