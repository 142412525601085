const converterHexadecimalParaRgb = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
  } : null
}

export const inverterCor = cor => {
  if (!cor) return

  const corRgb = converterHexadecimalParaRgb(cor)

  if (!corRgb) {
      return cor
  }

  const corInvertida = (corRgb.r * 299 + corRgb.g * 587 + corRgb.b * 114) / 1000 > 114 ? '#000000' : '#FFFFFF'
  
  return corInvertida
}