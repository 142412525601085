import React from 'react'
import { Rnd } from 'react-rnd'
import StickyBox from 'react-sticky-box'
import { useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import constantes from '../../../../configuracoes/constantes'
import { fecharTeleconsultaNoProntuario } from '../../../../acoes/prontuario/teleconsulta'
import './style.css'

export default function BoxVideoConsulta() {
  const dispatch = useDispatch()
  const teleconsulta = useSelector(state => state.prontuario.teleconsulta.videoChamada)
  const [token] = useLocalStorage('token')

  const URL = `${constantes.urlDaApiAsclepius}/profissional?token=${token}&atendimento=${teleconsulta}`

  function handleFechar() {
    dispatch(fecharTeleconsultaNoProntuario())
  }

  const GripHorizontal = () => {
    return (
      <div className='teleconsulta-asclepius-grip-vertical' title='Arraste e solte para trocar de posição'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
          <path d="M4 4v2H2V4zm1 7V9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m5 5V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M9 4v2H7V4zm5 0h-2v2h2zM4 9v2H2V9zm5 0v2H7V9zm5 0v2h-2V9zm-3-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
        </svg>
      </div>
    )
  }

  if (!teleconsulta) {
    return null
  }

  return (
    <StickyBox className='cabecalho-fixo-prontuario-asclepius' offsetTop={0}>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: 450,
          height: 450,
        }}
        minWidth={400}
        minHeight={400}
        bounds="body"
        className='teleconsulta-asclepius-rnd'
        style={{
          //position: 'fixed'        
        }}
      >
        <>
          <GripHorizontal />
          <button className='teleconsulta-asclepius-botao' onClick={handleFechar}>Fechar</button>
          <iframe
            className='teleconsulta-asclepius'
            src={URL}
            title="Teleconsulta"
            allow="camera;microphone"
          />
        </>
      </Rnd>
    </StickyBox>
  )
}