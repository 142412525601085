import React, { useEffect, useState } from 'react'
import AbasDaAgenda from './abas'
import Cabecalho from '../cabecalho'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, Select } from '../../formik/formulario'
import { Coluna, Tabela } from '../../tabela'
import { extendMoment } from 'moment-range'
import { confirmAlert } from 'react-confirm-alert'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import { ordemDoServidor } from '../../../bibliotecas/ordenacao'
import Moment from 'moment'

import {
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
  REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL,
} from '../../../acoes/tipos'

const moment = extendMoment(Moment)

export default function TiposDeAgendamentos(props) {
  const {
    adicionarTipoDeAgendamentoDoProfissional,
    fotos,
    history,
    match,
    recuperarPorIdentificador,
    listarTiposDeAgendamentoDasConfiguracoesDaAgenda,
    listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda,
    removerTipoDeAgendamentoDoProfissional,
    usuario = {},
    tiposDeAgendamento: {
      paginaDeDados,
      habilitados,
      filtros,
      ordenacao,
      tiposDeAgendamento,
    },
  } = props

  const { identificador } = match.params
  const [selecionado, setSelecionado] = useState('')
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtrosAplicados, setFiltrosAplicados] = useState(filtros)
  const [ordenacaoAplicada, setOrdenacaoAplicada] = useState(ordenacao)
  const podeAdicionarTipoDeAgendamento = usePossuiAsPermissoes([permissoes.HABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE])

  const recuperarTiposDeAgendamento = dados => ({
    dados: Object.keys(dados)
      .map(key => dados[key])
      .filter(x => habilitados.findIndex(i => i === x.identificador) === -1)
      .sort(ordemDoServidor)
      .map(x => ({ identificador: x.identificador, nome: x.nome }))
  })

  const tiposDeAgendamentos = tiposDeAgendamento && recuperarTiposDeAgendamento(tiposDeAgendamento)

  useEffect(() => {
    listarTiposDeAgendamentoDasConfiguracoesDaAgenda()
  }, [listarTiposDeAgendamentoDasConfiguracoesDaAgenda])

  useEffect(() => {
    listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(identificador, filtrosAplicados, pagina, ordenacaoAplicada)
  }, [listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda, identificador, filtrosAplicados, pagina, ordenacaoAplicada])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacaoAplicada(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros.ativo.length) {
      setFiltrosAplicados(filtros)
      setPagina(1)
    }
  }

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtrosAplicados,
      [filtro.nome]: filtro.valor
    })
  }

  const adicionar = async () => {
    const adicionou = await adicionarTipoDeAgendamentoDoProfissional(identificador, selecionado)

    if (adicionou) {
      setSelecionado('')
      listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(identificador, filtrosAplicados, pagina, ordenacaoAplicada)
    }
  }

  const removerSelecionado = async item => {
    const removeu = await removerTipoDeAgendamentoDoProfissional(identificador, item.identificador)

    if (removeu) {
      setSelecionado('')
      listarTiposDeAgendamentoDoProfissionalDasConfiguracoesDaAgenda(identificador, filtrosAplicados, pagina, ordenacaoAplicada)

    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o tipo de agendamento: ${item.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <>
      <Spinner operacoes={[LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA, LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA, ADICIONAR_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL, REMOVER_TIPO_DE_AGENDAMENTO_DO_PROFISSIONAL]}>
        <FormularioDoFormik
          valoresIniciais={{
            tiposDeAgendamentos: selecionado ? selecionado : ''
          }}
          acao={adicionar}
          reinicializar={true}
        >
          <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
          <div className='mt-2 mb-4'>
            <div>
              <fieldset>
                <h2 className='form-title'>Agenda</h2>
                <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                  <r-cell span={4} span-md={6} span-lg={9}>
                  <AbasDaAgenda identificador={identificador} url={match.url} usuario={usuario}/>
                  </r-cell>
                </r-grid>
                <r-grid columns-md={6} columns-lg={12} class='align-end mt-3'>
                  {podeAdicionarTipoDeAgendamento && <>
                    <r-cell span={4} span-md={3} span-lg={5}>
                      <Select
                        nome='tiposDeAgendamentos'
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        itens={tiposDeAgendamentos.dados}
                        tabIndex={1}
                        titulo='Tipos de Agendamento'
                        onChange={setSelecionado}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <button
                        type='submit'
                        className={!selecionado ? 'button --primary w-100 is-disabled' : 'button --primary w-100'}
                        disabled={!selecionado}
                      >
                        Adicionar
                      </button>
                    </r-cell>
                  </>
                  }
                </r-grid>
                <Tabela
                  acoes={[
                    item => (
                      <Permissao key='remover' permissoes={[permissoes.DESABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE]}>
                        <button
                          className='table-items__bt --remove'
                          title='Remover'
                          type='button'
                          onClick={() => confirmarERemover(item)}
                        >
                          <i className='icon icon-remove'></i>
                        </button>
                      </Permissao>
                    ),
                  ]}
                  chave='identificador'
                  className='--secondary my-4'
                  alterarFiltros={alterarFiltrosDaTabela}
                  filtros={filtrosAplicados}
                  ordenacao={ordenacaoAplicada}
                  listar={listar}
                  paginaDeDados={paginaDeDados}
                >
                  <Coluna
                    campoDeDados='nome'
                    className='col-lg-3'
                    principal={true}
                    exibirOrdenacao={true}
                    nome='Nome'
                  />
                  <Coluna
                    campoDeDados='tempo'
                    className='col-lg-2'
                    nome='Tempo (Min)'
                    renderizar={item => item.tempo && moment.duration(item.tempo, "HH:mm:ss").asMinutes()}
                  />
                  <Coluna
                    campoDeDados='cor'
                    className='col-lg-2'
                    nome='Cor'
                    renderizar={item => <span className='table-items__bcolor' style={{ background: item.cor }}>{item.cor}</span>}
                  />
                  <Coluna
                    campoDeDados='modalidade'
                    nome='Modalidade'
                    className='col-lg-2'
                  />
                  <Coluna
                    campoDeDados='ativo'
                    className='col-lg-2'
                    nome='Ativo'
                    filtros={['Sim', 'Não']}
                    renderizar={item => <p className='table-items__mobile-value'>{item.ativo ? 'Sim' : 'Não'}</p>}
                  />
                </Tabela>
              </fieldset>
              <div className='list-btn'>
                <button
                  className='button --light'
                  type='button'
                  onClick={() => history.push(`/configuracoes/usuarios`)}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </FormularioDoFormik>
      </Spinner>
    </>
  )
}