import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  ADICIONAR_TIPO_DE_AGENDAMENTO,
  ADICIONOU_TIPO_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO,
  RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  SALVAR_TIPO_DE_AGENDAMENTO,
  SALVOU_TIPO_DE_AGENDAMENTO,
  ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO,
  REMOVER_TIPO_DE_AGENDAMENTO,
  REMOVEU_TIPO_DE_AGENDAMENTO,
  ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO,
} from './tipos'

const adicionouTipoDeAgendamento = tipoDeAgendamento => ({
  type: ADICIONOU_TIPO_DE_AGENDAMENTO,
  tipoDeAgendamento,
})

const erroAoAdicionarTipoDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO,
  erro,
  parametros
})

export const adicionarTipoDeAgendamento = tipoDeAgendamento => async dispatch => {
  dispatch({ type: ADICIONAR_TIPO_DE_AGENDAMENTO })

  try {
    const resultado = await api.adicionarTipoDeAgendamento(tipoDeAgendamento)

    dispatch(adicionouTipoDeAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarTipoDeAgendamento(erro, { tipoDeAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar tipo de agendamento.')))

    return false
  }
}

const recuperouTipoDeAgendamentoPorIdentificador = tipoDeAgendamento => async dispatch => {
  dispatch({ type: RECUPEROU_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR, tipoDeAgendamento })
}

const erroAoRecuperarTipoDeAgendamentoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarTipoDeAgendamentoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarTipoDeAgendamentoPorIdentificador(identificador)

    dispatch(recuperouTipoDeAgendamentoPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarTipoDeAgendamentoPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar tipo de agendamento por identificador.')))
  }
}

const salvouTipoDeAgendamento = tipoDeAgendamento => ({
  type: SALVOU_TIPO_DE_AGENDAMENTO,
  tipoDeAgendamento,
})

const erroAoSalvarTipoDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO,
  erro,
  parametros
})

export const salvarTipoDeAgendamento = tipoDeAgendamento => async dispatch => {
  dispatch({ type: SALVAR_TIPO_DE_AGENDAMENTO })

  try {
    const resultado = await api.salvarTipoDeAgendamento(tipoDeAgendamento)

    dispatch(salvouTipoDeAgendamento(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarTipoDeAgendamento(erro, { tipoDeAgendamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o tipo de agendamento.')))

    return false
  }
}

const listouTiposDeAgendamento = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO,
  filtros,
  ordenacao,
  paginaDeDados,
})

const erroAoListarTiposDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO,
  erro,
  parametros
})

export const listarTiposDeAgendamento = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO })

  try {
    const resultado = await api.listarTiposDeAgendamento(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouTiposDeAgendamento(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarTiposDeAgendamento(erro, { filtros, pagina, ordenacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de agendamento.')))
  }
}

const removeuTipoDeAgendamento = identificador => ({
  type: REMOVEU_TIPO_DE_AGENDAMENTO,
  identificador,
})

const erroAoRemoverTipoDeAgendamento = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO,
  erro,
  parametros
})

export const removerTipoDeAgendamento = identificador => async dispatch => {
  dispatch({ type: REMOVER_TIPO_DE_AGENDAMENTO })

  try {
    const resultado = await api.removerTipoDeAgendamento(identificador)

    dispatch(removeuTipoDeAgendamento(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverTipoDeAgendamento(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível excluir.')))

    return false
  }
}