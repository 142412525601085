import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
  LISTOU_EVENTOS_DA_TIMELINE_DO_PACIENTE,
  ERRO_AO_LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
  LISTOU_PROFISSOES_PARA_TIMELINE_DO_PACIENTE,
  ERRO_AO_LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE
} from '../../tipos'

const listouEventosDaTimelineDoPaciente = (identificadorDoPaciente, eventos, filtros) => ({
  type: LISTOU_EVENTOS_DA_TIMELINE_DO_PACIENTE,
  identificadorDoPaciente,
  eventos,
  filtros
})

const erroAoListarEventosDaTimelineDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
  erro,
  parametros
})

export const listarEventosDaTimelineDoPaciente = ((identificadorDoPaciente, filtros) => async dispatch => {
  dispatch({ type: LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE })

  try {
    const resultado = await api.listarEventosDaTimelineDoPaciente(identificadorDoPaciente, filtros)

    dispatch(listouEventosDaTimelineDoPaciente(identificadorDoPaciente, resultado.data.dados, filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarEventosDaTimelineDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os eventos da timeline do paciente.')))
  }
})

const listouProfissoesParaTimelineDoPaciente = (identificadorDoPaciente, profissoes) => ({
  type: LISTOU_PROFISSOES_PARA_TIMELINE_DO_PACIENTE,
  identificadorDoPaciente,
  profissoes
})

const erroAoListarProfissoesParaTimelineDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE,
  erro
})

export const listarProfissoesParaTimelineDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: ERRO_AO_LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE })

  try {
    const resultado = await api.listarProfissoes()

    dispatch(listouProfissoesParaTimelineDoPaciente(identificadorDoPaciente, resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarProfissoesParaTimelineDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as profissões.')))
  }
}