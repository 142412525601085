import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../spinner'
import Assinado from '../prontuario/assinatura-fisico/assinado'
import { iniciarSessaoComCertificadoEmNuvem, assinarAtendimentoComCertificadoEmNuvem } from '../../acoes/lacuna'
import { ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM, INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM } from '../../acoes/tipos'

export default function UrlDeRetornoAposAutenticacaoEmNuvem({ path }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()

  const [origem, setOrigem] = useState()
  const [erroAoAssinar, setErroAoAssinar] = useState(false)

  const { lacuna } = useSelector(state => state.prontuario)

  const parametros = useMemo(() => new URLSearchParams(search), [search])

  const code = parametros.get('code')
  const state = parametros.get('state')

  useEffect(() => {
    (async () => {
      const dados = await dispatch(iniciarSessaoComCertificadoEmNuvem(code, state))

      if (dados) {
        const tokens = dados.split(';')
        const atendimento = tokens[0]
        const origem = tokens[1]

        if (!atendimento) {
          history.push(`/atendimentos/nao-assinados?iniciarEmLote=${origem}`)
          return
        }

        const assinou = await dispatch(assinarAtendimentoComCertificadoEmNuvem(atendimento))

        setOrigem(origem)
        setErroAoAssinar(!assinou)
      } else {
        setErroAoAssinar(true)
      }
    })()
  }, [code, state, dispatch, history])

  const fecharModal = () => {
    if (origem === 'naoassinados') {
      history.push('/atendimentos/nao-assinados')
    } else if (origem === 'prontuario') {
      history.push(`cadastros/pacientes/${lacuna.identificadores.paciente}/prontuario`)
    }
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='form'>
          <div className='mt-2 mb-4'>
            <fieldset>
              <h2 className='form-title'>{lacuna.finalizado ? 'Atendimento Assinado com Certificado em Nuvem' : 'Assinar com Certificado em Nuvem'}</h2>
              <hr />
              <Spinner operacoes={[INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM]}>
                {({ processando }) => (
                  <>{processando && <h2 className='verificando-permissoes text-black-50'>Iniciando sessão, por favor aguarde ...</h2>}</>
                )}
              </Spinner>
              <Spinner operacoes={[ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM]}>
                {({ processando }) => (
                  <>{processando && <h2 className='verificando-permissoes text-black-50'>Assinando, por favor aguarde ...</h2>}</>
                )}
              </Spinner>
              {!lacuna.finalizado && erroAoAssinar && <h2 className='verificando-permissoes text-black-50'>Não foi possível autorizar a assinatura junto ao serviço. Verifique sua senha e tente novamente.</h2>}
              {lacuna.finalizado && <Assinado lacuna={lacuna} fecharModal={fecharModal} labelSaida='Voltar' />}
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}