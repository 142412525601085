import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  LISTAR_RELATORIOS_DO_POWERBI,
  LISTOU_RELATORIOS_DO_POWERBI,
  ERRO_AO_LISTAR_RELATORIOS_DO_POWERBI,
} from '../tipos'

const recuperouTokenDosRelatoriosDoPowerBI = dados => ({
  type: RECUPEROU_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  dados
})

const erroAoRecuperarTokenDosRelatoriosDoPowerBI = erro => ({
  type: ERRO_AO_RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI,
  erro
})

export const recuperarTokenDosRelatoriosDoPowerBI = relatorios => async dispatch => {
  dispatch({ type: RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI })

  try {
    const resultado = await api.recuperarTokenDosRelatoriosDoPowerBI(relatorios)

    dispatch(recuperouTokenDosRelatoriosDoPowerBI(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarTokenDosRelatoriosDoPowerBI(erro, { relatorios }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o token dos relatórios do Power BI.')))
  }
}

const listouRelatoriosDoPowerBI = relatorios => ({
  type: LISTOU_RELATORIOS_DO_POWERBI,
  relatorios
})

const erroAoListarRelatoriosDoPowerBI = erro => ({
  type: ERRO_AO_LISTAR_RELATORIOS_DO_POWERBI,
  erro
})

export const listarRelatoriosDoPowerBI = () => async dispatch => {
  dispatch({ type: LISTAR_RELATORIOS_DO_POWERBI })

  try {
    const resultado = await api.listarRelatoriosDoPowerBI()

    dispatch(listouRelatoriosDoPowerBI(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRelatoriosDoPowerBI(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os relatórios do Power BI.')))
  }
}