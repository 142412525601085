import {
  LIMPAR_TAREFA_ATUAL,
  LISTOU_PACIENTES_DO_TAREFAS,
} from '../../acoes/tipos'

export default (state = {}, action) => {
  switch (action.type) {
    case LISTOU_PACIENTES_DO_TAREFAS: {
      return action.pacientes.dados
    }

    case LIMPAR_TAREFA_ATUAL: {
      return []
    }

    default: {
      return state
    }
  }
}