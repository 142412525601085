import api from './api-major'

const listarPlanosDeCuidado = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&termo=${filtros.nome}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/planos-de-cuidado${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}
const adicionarPlanoDeCuidado = planoDeCuidado => api.post(`/planos-de-cuidado/`, planoDeCuidado)
const recuperarPlanoDeCuidadoPeloIdentificador = identificador => api.get(`/planos-de-cuidado/${identificador}`)
const alterarPlanoDeCuidado = planosDeCuidado => api.put(`/planos-de-cuidado/${planosDeCuidado.identificador}`, planosDeCuidado)
const removerPlanoDeCuidado = identificador => api.delete(`/planos-de-cuidado/${identificador}`)

export default {
  listarPlanosDeCuidado,
  adicionarPlanoDeCuidado,
  recuperarPlanoDeCuidadoPeloIdentificador,
  alterarPlanoDeCuidado,
  removerPlanoDeCuidado
}