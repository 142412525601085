import React, { useEffect, useMemo, useState } from 'react'
import { RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI } from '../../../acoes/tipos'
import Spinner from '../../spinner'
import { Report } from 'powerbi-report-component'
import { converterObjetoParaArray } from "../../../bibliotecas/conversao";
import { useTarefas } from '../contexto'

export default function Dashboards({ relatorios = {}, recuperarTokenDosRelatoriosDoPowerBI }) {
  const [token, setToken] = useState()
  const [url, setUrl] = useState()
  const [identificador, setIdentificador] = useState()

  const { dashboardDaTarefaAtual } = useTarefas()

  const relatoriosDoPowerBI = useMemo(() => converterObjetoParaArray(relatorios).map(x => x.codigo), [relatorios])
  const dados = relatorios[dashboardDaTarefaAtual]

  useEffect(() => {
    if (dados) {
      setToken(dados.token)
      setUrl(dados.url)
      setIdentificador(dados.identificador)
    }
  }, [dados])

  useEffect(() => {
    if (relatoriosDoPowerBI.length > 0) {
      recuperarTokenDosRelatoriosDoPowerBI(relatoriosDoPowerBI)
    }
  }, [recuperarTokenDosRelatoriosDoPowerBI, relatoriosDoPowerBI])

  return (
    <Spinner operacoes={[RECUPERAR_TOKEN_DOS_RELATORIOS_DO_POWERBI]}>
      {({ processando }) => {
        if (processando) {
          return <h2 className='verificando-permissoes'>Verificando permissões...</h2>
        }

        if (!token || !identificador || !url) {
          return (
            <div className='container'>
              <fieldset className='pb-12'>
                <h2 style={{ fontSize: 15 }}>Dashboard indisponível.</h2>
              </fieldset>
            </div>
          )
        }

        return (
          <div className='dashboard-de-tarefas'>
            <Report
              tokenType='Embed'
              accessToken={token}
              embedUrl={url}
              embedId={identificador}
              reportMode='view'
              permissions='View'
              style={{
                height: '700px'
              }}
              extraSettings={{
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
              }}
            />
          </div>
        )
      }}
    </Spinner>
  )
}