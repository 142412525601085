import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  RECUPERAR_CABECALHO_DO_PRONTUARIO,
  RECUPEROU_CABECALHO_DO_PRONTUARIO,
  ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO,
  LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE
} from '../../tipos'

const recuperouCabecalhoDoProntuario = (identificadorDoPaciente, cabecalho) => ({
  type: RECUPEROU_CABECALHO_DO_PRONTUARIO,
  identificadorDoPaciente,
  cabecalho
})

const erroAoRecuperarCabecalhoDoProntuario = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO,
  erro,
  parametros
})

export const recuperarCabecalhoDoProntuario = identificadorDoPaciente => async dispatch => {
  dispatch({ type: RECUPERAR_CABECALHO_DO_PRONTUARIO })

  try {
    const resultado = await api.recuperarCabecalhoDoProntuario(identificadorDoPaciente)
    const cabecalho = { ...resultado.data.dados }

    await dispatch(recuperouCabecalhoDoProntuario(identificadorDoPaciente, cabecalho))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarCabecalhoDoProntuario(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o cabeçalho do prontuário.')))
  }
}

const listouHistoricoDeCamposDoProntuarioDoPaciente = (identificadorDoPaciente, historicoDeCampos) => ({
  type: LISTOU_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  identificadorDoPaciente,
  historicoDeCampos
})

const erroAoListarHistoricoDeCamposDoProntuarioDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  erro,
  parametros
})

export const listarHistoricoDeCamposDoProntuarioDoPaciente = (identificadorDoPaciente, componentes) => async dispatch => {
  dispatch({ type: LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE, identificadorDoPaciente })

  try {
    const resultado = await api.listarHistoricoDeCamposDoProntuarioDoPaciente(identificadorDoPaciente, componentes)

    dispatch(listouHistoricoDeCamposDoProntuarioDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarHistoricoDeCamposDoProntuarioDoPaciente(erro, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao listar histórico de campos do prontuário do paciente.')))
  }
}