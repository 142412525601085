import api from '../../api-major'

const adicionarQuedaDoPaciente = (identificador, queda) => api.post(`/pacientes/${identificador}/quedas`, queda)
const alterarQuedaDoPaciente = (identificador, queda) => api.put(`/pacientes/${identificador}/quedas/${queda.identificador}`, queda)
const removerQuedaDoPaciente = (identificador, identificadorDaQueda) => api.delete(`/pacientes/${identificador}/quedas/${identificadorDaQueda}`)
const listarQuedasDoPaciente = identificador => api.get(`/pacientes/${identificador}/quedas`)

export default { 
  adicionarQuedaDoPaciente,
  alterarQuedaDoPaciente,
  removerQuedaDoPaciente,
  listarQuedasDoPaciente
}