import React, { useEffect } from 'react'
import moment from 'moment'
import { DatePicker, CampoNumerico } from '../../../formik/formulario'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'
import { deveSerInformado, deveSerUmValorEntre } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'

export default function AntiHbs({ metadados, historico = [], valor, leitura, exibirDataExame, dataExame, setFieldValue }) {
  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame

  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)

  useEffect(() => {
    if (exibirDataExame && valor.valor) {
      setFieldValue('antiHbs.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  const validarAntiHbs = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 0, 1000) ||
        deveSerInformado(valor)
      )
    }
    return deveSerUmValorEntre(valor, 0, 1000)
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Anti-HBS: </strong>
            {valor.valor.toString().replace('.', ',')} UI/L no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 2
    return 4
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='antiHbs.valor'
            titulo={`Anti-HBS ${obrigatorio ? '*' : ''}`}
            escala='UI/L'
            numeroDeCasasDecimais={1}
            validar={validarAntiHbs}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Anti-HBS'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='antiHbs.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}