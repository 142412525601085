import api from './api-major'

const adicionarDiagnostico = diagnostico => api.post(`/diagnosticos`, diagnostico)
const recuperarDiagnosticoPeloIdentificador = identificador => api.get(`/diagnosticos/${identificador}`)
const alterarDiagnostico = diagnostico => api.put(`/diagnosticos/${diagnostico.identificador}`, diagnostico)
const removerDiagnostico = identificador => api.delete(`diagnosticos/${identificador}`)
const listarDiagnosticos = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.termo ? `&nome=${filtros.termo}` : ''  
  const parametroStatus = filtros.aprovado !== undefined && filtros.aprovado.length === 1 ? `&status=${filtros.aprovado[0] === 'Sim' ? 'aprovado' : 'nao_aprovado'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'
    
  return api.get(`/diagnosticos${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${parametroStatus}${ordem}`)
}

export default {
  adicionarDiagnostico,
  alterarDiagnostico,
  listarDiagnosticos,
  recuperarDiagnosticoPeloIdentificador,
  removerDiagnostico,  
}
