import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO
} from '../tipos'

const listouProgramasDoRelatorioDeIdasAoProntoAtendimento = programas => ({
  type: LISTOU_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  programas
})

const erroAoListarProgramasDoRelatorioDeIdasAoProntoAtendimento = erro => ({
  type: ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  erro
})

export const listarProgramasDoRelatorioDeIdasAoProntoAtendimento = () => async dispatch => {
  dispatch({ type: LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.listarProgramas()

    dispatch(listouProgramasDoRelatorioDeIdasAoProntoAtendimento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarProgramasDoRelatorioDeIdasAoProntoAtendimento(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as programas do relatório de Idas ao Pronto Atendimento.')))
  }
}

const fezDownloadDeIdasAoProntoAtendimento = filtros => ({
  type: FEZ_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  filtros
})

const erroAoFazerDownloadDeIdasAoProntoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  erro,
  parametros
})

export const fazerDownloadDeIdasAoProntoAtendimento = filtros => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO })

  try {
    const resultado = await api.fazerDownloadDeIdasAoProntoAtendimento(filtros)

    dispatch(fezDownloadDeIdasAoProntoAtendimento(filtros))
    dispatch(adicionarNotificacoesDaApi(resultado))
 
    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDeIdasAoProntoAtendimento(erro, { filtros }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download de idas ao pronto atendimento.')))

    return null
  }
}