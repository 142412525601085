import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
  FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO
} from './tipos'

const adicionouSumarioDeAltaDaInternacao = (sumarioDeAlta, tipo) => ({
  type: ADICIONOU_SUMARIO_DE_ALTA_DA_INTERNACAO,
  sumarioDeAlta,
  tipo,
})

const erroAoAdicionarSumarioDeAltaDaInternacao = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO,
  erro,
  parametros
})

export const adicionarSumarioDeAltaDaInternacao = (sumarioDeAlta, tipo) => async dispatch => {
  dispatch({ type: ADICIONAR_SUMARIO_DE_ALTA_DA_INTERNACAO })

  try {
    const resultado = await api.adicionarArquivo(sumarioDeAlta, tipo)
    const arquivo = resultado.data.dados

    dispatch(adicionouSumarioDeAltaDaInternacao({ ...arquivo, url: sumarioDeAlta.url }))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return arquivo.identificador
  } catch (erro) {
    dispatch(erroAoAdicionarSumarioDeAltaDaInternacao(erro, { sumarioDeAlta, tipo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o sumário de alta.')))
  }
}

const fezDownloadDoSumarioDeAltaDaInternacao = (identificador, conteudo) => ({
  type: FEZ_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
  identificador,
  conteudo,
})

const erroAoFazerDownloadDoSumarioDeAltaDaInternacao = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
  erro,
  parametros
})

export const fazerDownloadDoSumarioDeAltaDaInternacao = (identificadorDoPaciente, identificadorDaInternacao, identificadorDoArquivo) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
    identificadorDoArquivo
  })

  try {
    const resultado = await api.fazerDownloadDoArquivoDaInternacao(identificadorDoPaciente, identificadorDaInternacao, identificadorDoArquivo)

    dispatch(fezDownloadDoSumarioDeAltaDaInternacao(identificadorDoArquivo, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoSumarioDeAltaDaInternacao(erro, { identificadorDoPaciente, identificadorDaInternacao, identificadorDoArquivo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do sumário de alta da internação.')))

    return null
  }
}