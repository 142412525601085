import { LISTOU_URGENCIAS_DO_TAREFAS } from '../../acoes/tipos'

export default (state, action) => {
  switch (action.type) {
    case LISTOU_URGENCIAS_DO_TAREFAS: {
      return action.urgencias
    }

    default: {
      return state
    }
  }
}