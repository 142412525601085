import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO,
  LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO,
  ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO
} from '../tipos'

const listouMotivosParaNaoTerSidoAtendido = motivosParaNaoTerSidoAtendido => ({
  type: LISTOU_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO,
  motivosParaNaoTerSidoAtendido,
})

const erroAoListarMotivosParaNaoTerSidoAtendido = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO,
  erro,
})

export const listarMotivosParaNaoTerSidoAtendido = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO })

  try {
    const resultado = await api.listarMotivosParaNaoTerSidoAtendido()

    dispatch(listouMotivosParaNaoTerSidoAtendido(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosParaNaoTerSidoAtendido(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos para não ter sido atendido.')))
  }
}