import api from '../api-major'

const listarHorariosLivresDoAgendamento = filtros => {
  const {
    identificadorDoProfissional,
    dataInicio,
    dataFim,
    profissao,
    especialidadeSelecionada,
    tipoDeAgendamento,
    sexoDoProfissional,
    turnos,
    unidades,
    diasDaSemana
  } = filtros

  const parametroProfissional = identificadorDoProfissional ? `&profissionais=${identificadorDoProfissional}` : ''
  const parametroProfissao = profissao ? `&profissao=${profissao}` : ''
  const parametroEspecialidade = especialidadeSelecionada ? `&especialidade=${especialidadeSelecionada}` : ''
  const parametroTipoDeAgendamento = tipoDeAgendamento ? `&tipoDeAgendamento=${tipoDeAgendamento}` : ''
  const parametroSexoDoProfissional = sexoDoProfissional ? `&sexoDoProfissional=${sexoDoProfissional}` : ''
  const parametroTurnos = turnos ? `&turnos=${turnos}` : '&turnos=MANHA,TARDE,NOITE'
  const parametroUnidades = unidades ? `&unidades=${unidades}` : ''
  const parametroDiasDaSemana = diasDaSemana ? `&diasDaSemana=${diasDaSemana}` : '&diasDaSemana=DOM,SEG,TER,QUA,QUI,SEX,SAB'

  return api.get(`agenda/horarios/livres?inicio=${dataInicio}&fim=${dataFim}${parametroSexoDoProfissional}${parametroProfissao}${parametroEspecialidade}${parametroTurnos}${parametroDiasDaSemana}${parametroTipoDeAgendamento}${parametroProfissional}${parametroUnidades}`)
}

export default {
  listarHorariosLivresDoAgendamento
}