import React, { useState, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import { inverterCor } from '../../../../bibliotecas/cor'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import IconeStatus from '../icone-status-no-evento'
import VisualizarAgendamento from '../../confirmar-agendamento/visualizar-agendamento'
import BoxAgendaOver from '../box-agenda-over'

export default function EventoAgendamento(props) {
  const {
    cancelarAgendamento,
    confirmarAgendamento,
    excluirAgendamento,
    fotoDoPaciente,
    identificadorDoUsuarioLogado,
    itens,
    match,
    motivosDeCancelamento,
    profissional,
    recuperarAgendamentoPeloIdentificador,
    recuperarAgendaSemanalDoProfissional,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    temInstrucao,
  } = props

  const [indexPaciente, setIndexPaciente] = useState(1)
  const [exibirPopup, setExibirPopup] = useState(false)

  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [exibirCancelar, setExibirCancelar] = useState(false)
  const [exibirConfirmar, setExibirConfirmar] = useState(false)

  const estiloDoModalPopup = {
    content: {
      height: exibirCancelar || exibirExcluir || exibirConfirmar ? '95%' : '',
    }
  }

  const existeHorariosDiferentes = useMemo(() => {
    if (!itens || itens.length === 0) return false
    var inicio = itens[0].inicio
    var fim = itens[0].fim
    var diferente = false
    itens.forEach(i => {
      if (i.inicio !== inicio || i.fim !== fim) {
        diferente = true
      }
    });
    return diferente
  }, [itens])

  if (!itens || itens.length === 0) return null

  function ordenarHorarioFinal(a, b) {
    return a.fim.localeCompare(b.fim)
  }
  function ordenarStatus(a, b) {
    const statusA = a.status[0].nome
    const statusB = b.status[0].nome

    if (statusA === 'Cancelado' && statusB === 'Cancelado') {
      return 0
    } else if (statusA === 'Cancelado') {
      return 1
    }

    return -1
  }

  itens.sort(ordenarStatus).sort(ordenarHorarioFinal)

  const qtdePacientesMesmoHorario = itens.length

  const multiplosEventos = !existeHorariosDiferentes && qtdePacientesMesmoHorario > 3 && !temInstrucao
  const multiple = multiplosEventos ? '-multiple' : ''

  const item = itens[indexPaciente - 1] === undefined ? itens[0] : itens[indexPaciente - 1]
  const itensRenderizados = multiplosEventos ? [item] : itens


  const aoClicarEvento = (evento, index) => {
    if (!multiplosEventos) setIndexPaciente(index)
    setExibirPopup(!exibirPopup)

    if (evento.stopPropagation) evento.stopPropagation()
  }

  function aoClicarProximoEvento(evento, index) {
    setIndexPaciente(index)
    if (evento.stopPropagation) evento.stopPropagation()
  }

  function extrairNomeResumido(nome) {
    if (!nome) return ''

    const nomes = nome.split(' ')

    if (nomes.length === 1) {
      return nomes[0]
    }

    const primeiroNome = nomes[0]
    const ultimoNome = nomes[nomes.length - 1]

    return `${primeiroNome} ${ultimoNome}`
  }

  function calculaCorDoEvento(cor) {
    if (!cor) return '#000000'

    return inverterCor(cor.codigo)
  }

  function calculaLarguraBox(index) {
    if (multiplosEventos) return 80

    const left = calculaPosicaoLeft(index)
    return (95 - left)
  }

  function calculaPosicaoLeft(index) {
    if (multiplosEventos) return 50

    const somaInstrucao = temInstrucao ? 30 : 0
    const tamanhoSlotDoEvento = (92 - somaInstrucao) / qtdePacientesMesmoHorario
    const valor = index * tamanhoSlotDoEvento + somaInstrucao

    return valor
  }

  function exibirToolTip() {
    return temInstrucao || (qtdePacientesMesmoHorario > 1 && !multiplosEventos)
  }

  function fecharPopup() {
    setExibirCancelar(false)
    setExibirExcluir(false)
    setExibirConfirmar(false)
    setExibirPopup(false)
  }

  return (
    <>
      {itensRenderizados.map((item, index) =>
        <React.Fragment key={index}>
          <BoxAgendaOver>
            {({ mouseOverStyle }) =>
              <div
                className={`box-agenda__event${multiple} ${mouseOverStyle}`}
                key={item.identificador}
                data-tip
                data-for={`informacao_${item.identificador}`}
                style={{
                  backgroundColor: item.cor ? item.cor.codigo : '#dddd',
                  top: `calc(${item.posicionamento.top}px)`,
                  height: `calc(${item.posicionamento.height}px)`,
                  width: `calc(${calculaLarguraBox(index)}%)`,
                  left: `calc(${calculaPosicaoLeft(index)}%)`
                }}
                onClick={(evento) => aoClicarEvento(evento, index + 1)}
              >
                {exibirToolTip() &&
                  <ReactTooltip
                    id={`informacao_${item.identificador}`}
                    place='top'
                    effect='solid'
                    type='info'
                    key={item.identificador}
                  >
                    <span>{item.inicio} - {item.fim} - {extrairNomeResumido(item.label)}</span>
                  </ReactTooltip>
                }
                {multiplosEventos && indexPaciente > 1 &&
                  <button className='box-agenda__event-prev' onClick={(evento) => aoClicarProximoEvento(evento, indexPaciente - 1)}>
                    <i className='icon icon-arrow-left'></i>
                  </button>
                }
                <div className={`box-agenda__event${multiple}__info`}>
                  <div className={`box-agenda__event${multiple}__time`} style={{ color: calculaCorDoEvento(item.cor) }}>
                    <IconeStatus item={item} />
                    {` ${item.inicio} - ${item.fim}`}
                  </div>
                  <p className={`box-agenda__event${multiple}__local`} style={{ color: calculaCorDoEvento(item.cor) }}>{extrairNomeResumido(item.label)}</p>
                </div>
                {multiplosEventos &&
                  <div className='box-agenda__event-multiple__counter'>
                    <span>{indexPaciente}/{itens.length}</span>
                  </div>
                }
                {multiplosEventos && indexPaciente < qtdePacientesMesmoHorario &&
                  <button className='box-agenda__event-next' onClick={(evento) => aoClicarProximoEvento(evento, indexPaciente + 1)}>
                    <i className='icon icon-arrow-right'></i>
                  </button>
                }
              </div>
            }
          </BoxAgendaOver>
        </React.Fragment>
      )}
      <ReactTooltip
        id={`info_${item.identificador}`}
        place='right'
        effect='solid'
        type='info'
        key={item.identificador}
        className='unity-infos__item'
      >
        <div
          style={{
            marginBottom: 8,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'rgb(232, 232, 232)'
          }}><strong>Histórico do Status</strong></div>
        {item && item.status.map(x =>
          <span key={x.dataEHora}>
            <span><p><strong>{x.nome}</strong></p></span>
            <span><p>{x.observacoes}</p></span>
            <span><em>{x.usuario.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(x.dataEHora)}</em></span>
          </span>
        )}
      </ReactTooltip>
      <div className='page-configuracoes-agendamento'>
        <Modal
          isOpen={exibirPopup}
          style={estiloDoModalPopup}
          className='modal'
          contentLabel='Example Modal'
        >
          <VisualizarAgendamento
            agendamento={{
              ...item,
              inicio: item.dataInicio,
              fim: item.dataFim,
              profissional: profissional
            }}
            agendamentoSelecionado={{
              ...item,
              profissional: profissional,
            }}
            cancelarAgendamento={cancelarAgendamento}
            confirmarAgendamento={confirmarAgendamento}
            excluirAgendamento={excluirAgendamento}
            fechar={fecharPopup}
            fotos={fotoDoPaciente}
            identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
            match={match}
            motivosDeCancelamento={motivosDeCancelamento}
            profissional={profissional}
            recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
            recuperarAgendaSemanalDoProfissional={recuperarAgendaSemanalDoProfissional}
            recuperarFotoDoPacientePorIdentificador={recuperarFotoDoPacientePorIdentificador}
            removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
            selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
            selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
            setExibirCancelar={setExibirCancelar}
            setExibirConfirmar={setExibirConfirmar}
            setExibirExcluir={setExibirExcluir}
            exibirCancelar={exibirCancelar}
            exibirExcluir={exibirExcluir}
            exibirConfirmar={exibirConfirmar}
          />
        </Modal>
      </div>
    </>
  )
}