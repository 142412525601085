import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
} from './tipos'

const listouEstadosDaMemoriaComparadoHaUmAnoAtras = estadosDaMemoriaComparadoHaUmAnoAtras => ({
  type: LISTOU_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  estadosDaMemoriaComparadoHaUmAnoAtras,
})

const erroAoListarEstadosDaMemoriaComparadoHaUmAnoAtras = erro => ({
  type: ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  erro,
})

export const listarEstadosDaMemoriaComparadoHaUmAnoAtras = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS })

    try {
      const resultado = await api.listarEstadosDaMemoriaComparadoHaUmAnoAtras()

      dispatch(listouEstadosDaMemoriaComparadoHaUmAnoAtras(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarEstadosDaMemoriaComparadoHaUmAnoAtras(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estados da memória comparado há um ano atrás.')))
    }
  }

  acao.meta = {
    cache: LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS
  }

  return acao
}