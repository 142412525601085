import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {
  ERRO_AO_LISTAR_TIPOS_DE_TAREFA,
  LISTAR_TIPOS_DE_TAREFA,
  LISTOU_TIPOS_DE_TAREFA
} from '../tipos'


const listouTiposDeTarefa = tipos => ({
  type: LISTOU_TIPOS_DE_TAREFA,
  tipos
})

const erroAoListarTiposDeTarefa = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_TAREFA,
  erro,
})

export const listarTiposDeTarefa = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_TAREFA })

  try {
    const resultado = await api.listarTiposDeTarefa()
    dispatch(listouTiposDeTarefa(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoListarTiposDeTarefa(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de tarefa.')))
  }
}