import React, { forwardRef } from 'react'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'
import './style.css'

const MensalCustomInput = (props, ref) => {
  const {
    data,
    onClick
  } = props

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  const dataFormatada = new Date(data)
  dataFormatada.setHours(dataFormatada.getHours() + 12)

  const mesAnoPorExtenso = format(dataFormatada, 'MMMM yyyy', { locale: pt })
  const mesCapitalizado = capitalize(mesAnoPorExtenso.split(' ')[0]);
  const dataPorExtenso = `${mesCapitalizado} de ${mesAnoPorExtenso.split(' ')[1]}`

  return (
    <input ref={ref} type="text" autoComplete="off" className="date-period_horario_agenda__content" value={dataPorExtenso} onClick={onClick} readOnly />
  )
}

export default forwardRef(MensalCustomInput)
