import React from 'react'

import ContextoDasAbas, { useAbas } from './contexto-das-abas'

export function Abas({ children, abaInicial, ajusteRedirecionamento = false }) {
  return (
    <ContextoDasAbas abaInicial={abaInicial} ajusteRedirecionamento={ajusteRedirecionamento}>
      {children}
    </ContextoDasAbas>
  )
}

export function ListaDeAbas(props) {
  const { indexAtivo, selecionarIndexAtivo } = useAbas()

  const children = React.Children.map(props.children, (child, index) =>
    child &&
    React.cloneElement(child, {
      estahAtiva: index === indexAtivo,
      aoSelecionar: () => selecionarIndexAtivo(index)
    })
  )

  return (
    <div className={props.className}>
      {children}
    </div>
  )
}

export function Aba({ aoSelecionar, children, estahAtiva, className, aoClicar }) {
  const clicar = () => {
    aoClicar && aoClicar()
    aoSelecionar && aoSelecionar()
  }

  return (
    <div
      className={`${className} ${estahAtiva && 'active'}`}
      type='button'
      onClick={clicar}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </div>
  )
}

export function Paineis({ children }) {
  const { indexAtivo } = useAbas()
  return children[indexAtivo]
}

export function Painel({ children }) {
  return children
} 