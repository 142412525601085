import api from './api-major'

const listarMotivosDeInternacao = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroStatus = filtros.status !== undefined && filtros.status.length === 1 ? `&status=${filtros.status[0]}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

  return api.get(`/motivos-de-internacao${paginacao}${tamanhoDaPaginaFormatado}${parametroStatus}${parametroNome}${ordem}`)
}
const adicionarMotivoDeInternacao = motivo => api.post(`/motivos-de-internacao/`, motivo)
const recuperarMotivoDeInternacaoPeloIdentificador = identificador => api.get(`/motivos-de-internacao/${identificador}`)
const alterarMotivoDeInternacao = motivo => api.put(`/motivos-de-internacao/${motivo.identificador}`, motivo)
const removerMotivoDeInternacao = identificador => api.delete(`/motivos-de-internacao/${identificador}`)

export default {
  listarMotivosDeInternacao,
  adicionarMotivoDeInternacao,
  recuperarMotivoDeInternacaoPeloIdentificador,
  alterarMotivoDeInternacao,
  removerMotivoDeInternacao
}