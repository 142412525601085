import React from 'react'
import ReactTooltip from 'react-tooltip'
import { useProntuario } from '../../contexto'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import FileSaver from 'file-saver'

export default function ArquivosDeVideoConsulta({ arquivos, atendimento }) {
  const { fazerDownloadDoArquivoDeVideoConsulta } = useProntuario()

  const fazerDownload = async arquivo => {
    const dados = await fazerDownloadDoArquivoDeVideoConsulta(atendimento, arquivo.identificador)

    if (dados) {
      FileSaver.saveAs(new Blob([dados]), arquivo.nome)
    }
  }

  if (!arquivos || arquivos.length === 0) return null

  return (
    <r-cell span={4} span-md='row' span-lg='row'>
      <h2 className='form-title'>Arquivos da Vídeo Consulta</h2>
      <div className='form-summary mt-2'>
        <div className='form-summary-files'>
          {arquivos.length > 0 && arquivos.map((x, index) =>
            <div className='file-upload-item' key={index}>
              <div className='file-upload-item__name'>{x.nome}</div>
              <button
                className='file-upload-item__bt'
                title='Fazer Download'
                type='button'
                onClick={() => fazerDownload(x)}
              >
                <i className='icon icon-download-cloud'></i>
              </button>
              <button
                data-tip
                data-for={`info_${x.identificador}`}
                key={`info_${x.identificador}`}
                className='file-upload-item__bt'
                type='button'
              >
                <i className='icon icon-info'></i>
              </button>
              <ReactTooltip
                id={`info_${x.identificador}`}
                place='top'
                effect='solid'
                type='info'
                key={x.identificador}
              >
                <div
                  style={{
                    marginBottom: 8,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: 'rgb(232, 232, 232)'
                  }}><strong>Última alteração</strong></div>
                <p>Usuário: {x.usuario}</p> <br />
                <p>Data e Hora: {formatarDataEHoraParaFormatoLocal(x.dataEHora)}</p>
              </ReactTooltip>
            </div>
          )}
        </div>
      </div>
    </r-cell>
  )
}