import { connect } from 'react-redux'
import acoes from '../../acoes'
import Associacoes from './index'

const mapStateToProps = state => {
  const associacaoAtual = state.associacoes.itemAtual

  let associacao = {
    nome: '',
    observacoes: '',
  }

  if (associacaoAtual) {
    associacao.identificador = associacaoAtual.identificador
    associacao.observacoes = associacaoAtual.observacoes
    associacao.nome = associacaoAtual.nome
  }

  return {
    filtros: state.associacoes.filtros,
    ordenacao: state.associacoes.ordenacao,
    paginaDeDados: state.associacoes.paginaDeDados,
    associacao,
  }
}

export default connect(mapStateToProps, acoes)(Associacoes)