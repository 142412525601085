import {
  ADICIONOU_PLANO_DE_CUIDADO,
  RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTEROU_PLANO_DE_CUIDADO,
  REMOVEU_PLANO_DE_CUIDADO,
  LISTOU_PLANOS_DE_CUIDADO
} from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    nome: ''
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case RECUPEROU_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        itemAtual: action.planoDeCuidado
      }
    }

    case LISTOU_PLANOS_DE_CUIDADO: {
      const { filtros, ordenacao, paginaDeDados } = action

      return {
        ...state,
        filtros,
        itemAtual: null,
        ordenacao,
        paginaDeDados
      }
    }

    case ADICIONOU_PLANO_DE_CUIDADO:
    case ALTEROU_PLANO_DE_CUIDADO:
    case REMOVEU_PLANO_DE_CUIDADO: {
      return {
        ...state,
        itemAtual: null
      }
    }

    default: {
      return state
    }
  }
}