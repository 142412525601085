import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link } from 'react-router-dom'
import Spinner from '../../spinner'
import { Coluna, Tabela } from '../../tabela'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR
} from '../../../acoes/tipos'

export default function ListaDeConcentracao(props) {
  const {
    concentracoes,
    history,
    recuperarMedicamentoPorIdentificador,
    match,
    medicamento,
    removerConcentracao,
    salvar
  } = props

  const { identificador } = match.params
  const permissaoParaAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_CONCENTRACAO_DO_MEDICAMENTO])

  useEffect(() => {
    recuperarMedicamentoPorIdentificador(identificador)
  }, [recuperarMedicamentoPorIdentificador, identificador])

  const confirmarEAlterar = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja alterar o status da concentração?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => salvar(item)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover a concentração ${item.valor}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerConcentracao(item.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='page-pacientes-lista'>
      <div className='header-page-tools'>
        <div className='user-paciente mt-2'>
          <p>{medicamento.nome}</p>
          <Permissao permissoes={[permissoes.ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO]}>
            <Link to={`/cadastros/medicamentos/${identificador}/concentracoes/novo`}
              className='button --primary --plus'>
              Adicionar
            </Link>
          </Permissao>
        </div>
      </div>
      <div className='main-content-form'>
        <Spinner operacoes={[RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR, LISTAR_CONCENTRACOES_DO_MEDICAMENTO]}>
          <Tabela
            acoes={[
              item => (
                <Permissao key='remover' permissoes={[permissoes.REMOVER_CONCENTRACAO_DO_MEDICAMENTO]}>
                  <button
                    onClick={() => confirmarERemover(item)}
                    className='table-items__bt --remove'
                    title='Remover'
                    type='button'
                    key={item.identificador}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                </Permissao>
              )
            ]}
            chave='valor'
            className='mt-1 mb-4'
            dados={concentracoes}
          >
            <Coluna
              campoDeDados='valor'
              className='col-xs-8 col-md-10 col-lg-8'
              principal={true}
              nome='Valor'
            />
            <Coluna
              className='col-xs-6 col-lg-3'
              nome='Ativo'
              renderizar={item =>
                <div className='form-toggle justify-content-between justify-content-md-start'>
                  <input
                    id={`checkbox_${item.identificador}`}
                    name='ativa'
                    type='checkbox'
                    checked={item.ativa}
                    disabled={!permissaoParaAlterar}
                    onChange={evento => confirmarEAlterar({ ...item, ativa: evento.target.checked })}
                  />
                  <label className='m0' htmlFor={`checkbox_${item.identificador}`}></label>
                </div>
              }
            />
          </Tabela>
        </Spinner>
        <div className='list-btn'>
          <button
            className='button --light'
            type='button'
            onClick={() => history.push(`/cadastros/medicamentos/lista`)}
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  )
}