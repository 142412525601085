import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../../spinner'
import { Formulario as FormularioDoFormik, DatePicker, Input, Select, Toglle } from '../../formik/formulario'
import Cabecalho from '../cabecalho'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  LISTAR_OPERADORAS,
  ADICIONAR_CONVENIO_DO_PACIENTE,
  ALTERAR_CONVENIO_DO_PACIENTE,
  RECUPERAR_CONVENIO_DO_PACIENTE,
  REMOVER_CONVENIO_DO_PACIENTE
} from '../../../acoes/tipos'

export default function FormularioDeConvenio(props) {
  const {
    fotos,
    history,
    match,
    motivos,
    paciente,
    listarMotivosDeCancelamentoDoConvenio,
    recuperar,
    recuperarPorIdentificador,
    remover,
    salvar,
    operadoras,
  } = props

  const { identificador, identificadorDoConvenio } = match.params
  const convenio = paciente.convenios.selecionado || {}
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_CONVENIO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_CONVENIO_DO_PACIENTE])
  const permissaoParaRemover = usePossuiAsPermissoes([permissoes.REMOVER_CONVENIO_DO_PACIENTE])

  useEffect(() => { listarMotivosDeCancelamentoDoConvenio() }, [listarMotivosDeCancelamentoDoConvenio])

  useEffect(() => {
    if (identificador && identificadorDoConvenio) {
      recuperar(identificador, identificadorDoConvenio)
    }
  }, [identificador, identificadorDoConvenio, recuperar])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o convênio ${convenio.operadora}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(identificador, convenio.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[LISTAR_OPERADORAS, ADICIONAR_CONVENIO_DO_PACIENTE, ALTERAR_CONVENIO_DO_PACIENTE, RECUPERAR_CONVENIO_DO_PACIENTE, REMOVER_CONVENIO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificadorDoPaciente: identificador,
          identificador: convenio.identificador || '',
          observacoes: convenio.observacoes || '',
          operadora: convenio.operadora || '',
          motivoDoCancelamento: convenio.motivoDoCancelamento || null,
          numeroDaCarteira: convenio.numeroDaCarteira || '',
          status: convenio.status ? convenio.status === 'Ativo' : true,
          validade: convenio.validade || '',
          via: convenio.via || '',
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          operadora: Yup.string().required('Obrigatório'),
          motivoDoCancelamento: Yup.string().nullable()
            .when("status", {
              is: val => !val,
              then: Yup.string().required('Obrigatório.')
            }),
          numeroDaCarteira: Yup.string()
            .required('Obrigatório')
            .ehUmAlfanumericoValido('Número da carteira deve conter apenas números e letras.'),
          via: Yup.number()
            .typeError('Dever ser numérico')
            .min(0, 'Via deve ser um valor entre 1 e 999.')
            .max(999, 'Via deve ser um valor entre 1 e 999.')
            .positive('Deve ser positivo')
            .integer('Deve ser inteiro'),
        })}
      >
        {({ setFieldValue, values }) => (
          <>
            <Cabecalho paciente={paciente} fotos={fotos} identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} />
            <div className="mt-2">
              <fieldset>
                <h2 className="form-title">{`${convenio.identificador ? 'Alterar Convênio' : 'Adicionar Convênio'}`}</h2>
                <r-grid columns-md='6' columns-lg='12'>
                  <r-cell span={4} span-md='3' span-lg='4'>
                    <Select
                      nome='operadora'
                      tabIndex={1}
                      titulo='Operadora *'
                      itens={operadoras}
                      campoCodigo='nome'
                      campoDescricao='nome'
                    />
                  </r-cell>
                  <r-cell span={4} span-md='3' span-lg='3'>
                    <Input
                      type='text'
                      nome='numeroDaCarteira'
                      tabIndex={3}
                      titulo='Número da Carteira *'
                    />
                  </r-cell>
                  <r-cell span={4} span-md='3' span-lg='3'>
                    <Input
                      type='text'
                      nome='via'
                      tabIndex={4}
                      titulo='Via'
                    />
                  </r-cell>
                  <r-cell span={4} span-md='3' span-lg='2'>
                    <DatePicker
                      type='text'
                      nome='validade'
                      tabIndex={5}
                      titulo='Data de Validade'
                    />
                  </r-cell>
                  {remover &&
                    <>
                      <r-cell span={4} span-md={6} span-lg={12}>
                        <Toglle
                          className='form-toggle-2'
                          nome='status'
                          tabIndex={2}
                          titulo='Ativo'
                          onChange={() => setFieldValue('motivoDoCancelamento', null)}
                        />
                      </r-cell>
                      {!values.status &&
                        <r-cell span={4} span-md='row' span-lg='row'>
                          <Select
                            nome='motivoDoCancelamento'
                            titulo='Motivo do cancelamento *'
                            itens={motivos}
                            campoCodigo='nome'
                            campoDescricao='nome'
                          />
                        </r-cell>
                      }
                    </>
                  }
                  <r-cell span={4} span-md='row' span-lg='row'>
                    <Input
                      as='textarea'
                      nome='observacoes'
                      tabIndex={6}
                      titulo='Observações'
                    />
                  </r-cell>
                </r-grid>
              </fieldset>
              {remover ?
                <div className='list-btn-unidades'>
                  {permissaoParaRemover && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                  {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                </div>
                :
                <div className='list-btn'>
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                </div>
              }
            </div>
          </>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}