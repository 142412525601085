import api from '../api-major'

const adicionarTriagem = triagem => api.post('/triagens', triagem)
const alterarTriagem = triagem => api.put(`/triagens/${triagem.identificador}`, triagem)
const recuperarTriagemPeloIdentificador = identificador => api.get(`/triagens/${identificador}`)
const listarTriagensDoPaciente = paciente => api.get(`/triagens?paciente=${paciente}&pagina=1&tamanhoDaPagina=10000&ordem=-horario`)
const removerTriagem = identificador => api.delete(`/triagens/${identificador}`)

export default {
  adicionarTriagem,
  alterarTriagem,
  recuperarTriagemPeloIdentificador,
  listarTriagensDoPaciente,
  removerTriagem,
}