import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_LOCAIS_DA_APLICACAO,
  LISTOU_LOCAIS_DA_APLICACAO,
  ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO
} from './tipos'

const listouLocaisDaAplicacao = locaisDaAplicacao => ({
  type: LISTOU_LOCAIS_DA_APLICACAO,
  locaisDaAplicacao
})

const erroAoListarLocaisDaAplicacao = erro => ({
  type: ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO,
  erro
})

export const listarLocaisDaAplicacao = () => async dispatch => {
  dispatch({ type: LISTAR_LOCAIS_DA_APLICACAO })

  try {
    const resultado = await api.listarLocaisDaAplicacao()

    dispatch(listouLocaisDaAplicacao(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarLocaisDaAplicacao(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os locais da aplicação.')))
  }
}