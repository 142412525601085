import React from 'react'
import { Formulario as FormularioDoFormik, CampoNumerico, DatePicker, MultiplaEscolha, Checkbox, InputImagem } from '../../../../formik/formulario'
import { contemValor, ehUmNumeroDecimalValido, numeroDeCasasDecimais } from '../../../../../bibliotecas/validacoes'
import Pentagonos from '../../../../../design/img/pentagonos.png'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_ARQUIVO,
  ADICIONAR_MINI_MENTAL,
  ALTERAR_MINI_MENTAL,
  LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  RECUPERAR_DESENHO_DO_MINI_MENTAL
} from '../../../../../acoes/tipos'

const obrigatorio = valor => {
  if (contemValor(valor)) {
    if (Array.isArray(valor) && valor.length === 0) {
      return 'Obrigatório'
    }

    return undefined
  }

  return 'Obrigatório'
}

const deveSerNumerico = valor => contemValor(valor) && !ehUmNumeroDecimalValido(valor) ? 'Deve ser um número' : undefined
const deveSerUmValorEntre = (valor, valorMinimo, valorMaximo) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  valor = valor ? parseFloat(valor.toString().replace(',', '.')) : valor

  return valor >= valorMinimo && valor <= valorMaximo ? undefined : `Deve ser um valor entre ${valorMinimo} e ${valorMaximo}.`
}

const maximoDeCasasDecimais = (valor, casasDecimais) => {
  if (!ehUmNumeroDecimalValido(valor)) return

  return numeroDeCasasDecimais(valor) <= casasDecimais ? undefined : `Máximo de ${casasDecimais} casa(s) decimal(is).`
}

const renderizarTitulo = valor => valor.descricao

export default function Formulario(props) {
  const {
    adicionarArquivo,
    estadosDaMemoriaAtual,
    estadosDaMemoriaComparadoHaUmAnoAtras,
    identificadorDoPaciente,
    selecionado,
    valores,
    fechar,
    recuperarDesenhoDoMiniMental,
    ...rest
  } = props

  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_MINI_MENTAL,
      ALTERAR_MINI_MENTAL,
      LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
      LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
      ADICIONAR_ARQUIVO,
      RECUPERAR_DESENHO_DO_MINI_MENTAL]}>
      <FormularioDoFormik
        reinicializar={false}
        acao={salvar}
        valoresIniciais={valores}
      >
        <fieldset>
          <h2 className='form-title'>{selecionado ? 'Alterar Mini Mental' : 'Adicionar Mini Mental'}</h2>
          <Data />
          <br />
          <r-grid columns-md={6} columns-lg={12} class='componente-mini-mental'>
            <Cabecalho />
            <SecaoMemoria estadosDaMemoriaAtual={estadosDaMemoriaAtual} estadosDaMemoriaComparadoHaUmAnoAtras={estadosDaMemoriaComparadoHaUmAnoAtras} />
            <SecaoOrientacaoTemporal />
            <SecaoOrientacaoEspacial />
            <SecaoRegistroDaMemoriaImediata />
            <SecaoAtencaoECalculo />
            <SecaoMemoriaRecente />
            <SecaoLinguagem adicionarDesenho={adicionarArquivo} recuperarDesenho={recuperarDesenhoDoMiniMental} />
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --primary'>{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}

function Data() {
  return (
    <r-grid columns-md={6} columns-lg={12}>
      <r-cell span={4} span-md={4} span-lg={4}>
        <DatePicker
          nome='data'
          titulo='Data'
          tabIndex={1}
          maxDate={new Date()}
          validar={obrigatorio}
        />
      </r-cell>
    </r-grid>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>MINI MENTAL</strong>
      </div>
      <div className='titulo-pontuacao'><strong>PONTUAÇÃO</strong></div>
    </r-cell>
  )
}

function SecaoMemoria({ estadosDaMemoriaAtual, estadosDaMemoriaComparadoHaUmAnoAtras }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>MEMÓRIA</strong></div>
      <div className='perguntas'>
        <MultiplaEscolha
          className='pergunta'
          nome='secaoMemoria.estadoDaMemoriaAtual'
          opcoes={estadosDaMemoriaAtual.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
          pergunta='1) Como o Sr(a) avalia sua memória atualmente?'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta'
          nome='secaoMemoria.estadoDaMemoriaComparadoHaUmAnoAtras'
          opcoes={estadosDaMemoriaComparadoHaUmAnoAtras.map(x => ({ codigo: x.codigo, descricao: x.nome }))}
          pergunta='2) Comparando com um ano atrás, o Sr(a) diria que sua memória está: '
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
      </div>
    </r-cell>
  )
}

function SecaoOrientacaoTemporal() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ORIENTAÇÃO TEMPORAL</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>3) Por favor, diga-me:</strong>
          <Checkbox
            nome='secaoOrientacaoTemporal.diaDaSemana'
            titulo='Dia da semana'
          />
          <Checkbox
            nome='secaoOrientacaoTemporal.diaDoMes'
            titulo='Dia do mês'
          />
          <Checkbox
            nome='secaoOrientacaoTemporal.mes'
            titulo='Mês'
          />
          <Checkbox
            nome='secaoOrientacaoTemporal.ano'
            titulo='Ano'
          />
          <Checkbox
            nome='secaoOrientacaoTemporal.horaAproximada'
            titulo='Hora aprox.'
          />
        </div>
      </div>
    </r-cell>
  )
}

function SecaoOrientacaoEspacial() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ORIENTAÇÃO ESPACIAL</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>4) Responda:</strong>
          <Checkbox
            nome='secaoOrientacaoEspacial.onde'
            titulo='Onde estamos: consultório, hospital, residência'
          />
          <Checkbox
            nome='secaoOrientacaoEspacial.lugar'
            titulo='Em que lugar estamos: andar, sala, cozinha'
          />
          <Checkbox
            nome='secaoOrientacaoEspacial.bairro'
            titulo='Em que bairro estamos'
          />
          <Checkbox
            nome='secaoOrientacaoEspacial.cidade'
            titulo='Em que cidade estamos'
          />
          <Checkbox
            nome='secaoOrientacaoEspacial.estado'
            titulo='Em que estado estamos'
          />
        </div>
      </div>
    </r-cell>
  )
}

function SecaoRegistroDaMemoriaImediata() {
  const validacaoRepeticoes = valor => {
    if (valor !== undefined && valor !== null) {
      return (
        deveSerNumerico(valor) ||
        deveSerUmValorEntre(valor, 0, 3) ||
        maximoDeCasasDecimais(valor, 0))
    } else {
      return obrigatorio(valor)
    }
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>REGISTRO DA MEMÓRIA IMEDIATA</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>5) Vou lhe dizer o nome de três objetos e quando terminar, pedirei para repeti-los, em qualquer ordem. Guarde-os que mais tarde voltarei a perguntar: Árvore, Mesa, Cachorro. </strong>
          <Checkbox
            nome='secaoRegistroDaMemoriaImediata.arvore'
            titulo='Árvore'
          />
          <Checkbox
            nome='secaoRegistroDaMemoriaImediata.mesa'
            titulo='Mesa'
          />
          <Checkbox
            nome='secaoRegistroDaMemoriaImediata.cachorro'
            titulo='Cachorro'
          />
          <br />
          <p><strong>Obs: </strong>Leia os nomes dos objetos devagar e de forma clara, somente um a vez e anote. Se o
            total for diferente de três: - repita todos os objetos até no máximo três repetições; - anote o
            número de repetições que fez: </p>
          <CampoNumerico
            className='pergunta campo-numerico'
            nome='secaoRegistroDaMemoriaImediata.repeticoes'
            validar={validacaoRepeticoes}
          />
        </div>
      </div>
    </r-cell>
  )
}

function SecaoAtencaoECalculo() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>ATENÇÃO E CÁLCULO</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>6) Vou lhe dizer alguns números e gostaria que realizasse os seguintes cálculos:</strong>
          <Checkbox
            nome='secaoAtencaoECalculo.cemMenosSete'
            titulo='100 - 7'
          />
          <Checkbox
            nome='secaoAtencaoECalculo.noventaETresMenosSete'
            titulo='93 - 7'
          />
          <Checkbox
            nome='secaoAtencaoECalculo.oitentaESeisMenosSete'
            titulo='86 - 7'
          />
          <Checkbox
            nome='secaoAtencaoECalculo.setentaENoveMenosSete'
            titulo='79 - 7'
          />
          <Checkbox
            nome='secaoAtencaoECalculo.setentaEDoisMenosSete'
            titulo='72 - 7'
          />
        </div>
      </div>
    </r-cell>
  )
}

function SecaoMemoriaRecente() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>MEMÓRIA RECENTE</strong></div>
      <div className='perguntas'>
        <div className='pergunta'>
          <strong>7) Há alguns minutos, o Sr(a) repetiu uma série de três palavras. Por favor, diga-me agora quais ainda se lembra:</strong>
          <Checkbox
            nome='secaoMemoriaRecente.arvore'
            titulo='Árvore'
          />
          <Checkbox
            nome='secaoMemoriaRecente.mesa'
            titulo='Mesa'
          />
          <Checkbox
            nome='secaoMemoriaRecente.cachorro'
            titulo='Cachorro'
          />
        </div>
      </div>
    </r-cell>
  )
}

function SecaoLinguagem({ adicionarDesenho, recuperarDesenho }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='componente'>
      <div className='agrupamento'><strong>LINGUAGEM</strong></div>
      <div className='perguntas'>
        <div className='pergunta-2n'>
          <strong>8) Aponte a caneta e o relógio e peça pra nomeá-los:</strong>
          <Checkbox
            nome='secaoLinguagem.caneta'
            titulo='Caneta'
          />
          <Checkbox
            nome='secaoLinguagem.relogio'
            titulo='Relógio'
          />
        </div>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.repetirFrase'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='9) Repita a frase que eu vou lhe dizer (pronunciar em voz alta, bem articulada e lentamente): “NEM AQUI, NEM ALI, NEM LÁ”'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.lerPapel'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='10) Dê ao entrevistado uma folha de papel, na qual esteja escrito em letras grandes: “FECHE OS OLHOS”. Diga-lhe: leia este papel e faça o que está escrito (permita dez segundos). '
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <div className='pergunta-2n'>
          <strong>11) Vou lhe dar um papel e quando eu o entregar, pegue com sua mão direita, dobre-o na metade com as duas mãos e coloque no chão. </strong>
          <Checkbox
            nome='secaoLinguagem.pegarPapel'
            titulo='Pegar'
          />
          <Checkbox
            nome='secaoLinguagem.dobrarPapel'
            titulo='Dobrar'
          />
          <Checkbox
            nome='secaoLinguagem.colocarPapelNoChao'
            titulo='Colocar'
          />
        </div>
        <MultiplaEscolha
          className='pergunta-2n'
          nome='secaoLinguagem.fraseCompleta'
          opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
          pergunta='12) Pedir ao entrevistado que escreva uma frase em um papel em branco. (contar um ponto se a frase
            tem sujeito, verbo, predicado, sem levar em conta erros de ortografia ou de sintaxe). Se o
            entrevistado não fizer corretamente, perguntar-lhe: "Isto é uma frase" / E permitir-lhe
            corrigir se tiver consciência de seu erro.'
          renderizarTitulo={renderizarTitulo}
          validar={obrigatorio}
        />
        <div className='pergunta-2n'>
          <MultiplaEscolha
            nome='secaoLinguagem.desenhoPentagonos'
            opcoes={[{ codigo: true, descricao: 'Correto' }, { codigo: false, descricao: 'Incorreto' }]}
            pergunta='13) Por favor, copie este desenho (entregue ao entrevistado o desenho e peça-o para
            copiar). A ação está correta se o desenho tiver dois pentágonos com intersecção de um
            ângulo.'
            renderizarTitulo={renderizarTitulo}
            validar={obrigatorio}
          />
          <img
            className='pentagono'
            alt='Pentágonos'
            src={Pentagonos}
          />
          <div className='list-btn'>
            <InputImagem
              nome='secaoLinguagem.desenhoDoMiniMental'
              recuperar={recuperarDesenho}
              adicionar={adicionarDesenho}
              tipo='desenho_do_mini_mental'
            />
          </div>
        </div>
      </div>
    </r-cell >
  )
}