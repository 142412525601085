import React, {useEffect, useMemo} from 'react'
import CampoDaTarefa from './campo-da-tarefa'
import {SelectReact} from '../../formik/select'
import {useTarefas} from '../contexto'
import {ComponenteLeitura} from '../card'
import {XOctagon} from '../../../design/icones'

export default function MotivoDoCancelamento({ definir, obrigatorio, rotulo, processando, valor, leitura }) {
  const icone = <XOctagon/>
  const titulo = rotulo ?? 'Motivo do cancelamento'

  const { listarMotivosDeCancelamentoDoTarefas, motivosDeCancelamento } = useTarefas()

  useEffect(() => {
    if(!motivosDeCancelamento) {
      listarMotivosDeCancelamentoDoTarefas()
    }
  }, [listarMotivosDeCancelamentoDoTarefas, motivosDeCancelamento])

  const opcoesDeMotivosDeCancelamento = useMemo(() => {
    if (!motivosDeCancelamento) return []
    return motivosDeCancelamento.map(x => ({
      value: x.codigo,
      label: x.nome,
    }))
  }, [motivosDeCancelamento])

  if (leitura) {
    if (!valor) return null

    return (
      <ComponenteLeitura
        icone={icone}
        titulo={titulo}
        valor={valor.nome}
      />
    )
  }

  return (
    <CampoDaTarefa
      gravar={definir}
      valor={(valor && valor.codigo) ? valor.codigo : valor}
      gravarAoSelecionar={true}
      icone={icone}
      titulo={`${titulo} ${obrigatorio ? '*' : ''}`}
    >
      {({ onChange, onBlur, onFocus, valor, isValid, isWarning }) =>
        <SelectReact
          nome='motivoDoCancelamento'
          id='motivoDoCancelamento'
          onChange={onChange}
          options={opcoesDeMotivosDeCancelamento}
          valor={valor || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          isLoading={processando}
          isValid={isValid}
          isWarning={isWarning}
        />
      }
    </CampoDaTarefa>
  )
}