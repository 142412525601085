import React from 'react'
import Spinner from '../../../spinner'
import SpinnerDaTarefa from '../spinner-da-tarefa'
import { v4 as uuidv4 } from 'uuid'
import { contemValor } from '../../../../bibliotecas/validacoes'
import { Formulario as FormularioDoFormik, InputSugestao } from '../../../formik/formulario'
import { ADICIONAR_COMENTARIO_DA_TAREFA } from '../../../../acoes/tipos'

export default function NovoComentario({ identificadorDaTarefa, adicionar }) {
  const adicionarComentario = async (formulario, { resetForm }) => {
    const comentario = {
      comentario: uuidv4(),
      tarefa: identificadorDaTarefa,
      texto: formulario.texto
    }

    const adicionou = await adicionar(identificadorDaTarefa, comentario)

    if (adicionou) {
      resetForm()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_COMENTARIO_DA_TAREFA]}>
      {({ processando }) =>
        <FormularioDoFormik
          valoresIniciais={{ texto: '' }}
          acao={adicionarComentario}
        >
          {({ values }) => (
            <div className={contemValor(values.texto) ? 'padding-task-visualizacao' : 'pb-1'}>
              <InputSugestao
                nome='texto'
                placeholder='Adicione um novo comentário'
                minHeight={contemValor(values.texto) ? 100 : 30}
                fontFamily='inherit'
              />
              <SpinnerDaTarefa processando={processando} />
              {contemValor(values.texto) && !processando &&
                <div className='list-btn-tasks'>
                  <button type='submit' className='button button-task-comments'>Salvar</button>
                </div>
              }
            </div>
          )}
        </FormularioDoFormik>
      }
    </Spinner>
  )
}