import { LISTOU_TIPOS_DE_CONSULTA_EXTERNA } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_CONSULTA_EXTERNA: {
      return action.tiposDeConsultaExterna
    }

    default: {
      return state
    }
  }
}