import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'
import FormularioDeConcentracao from './concentracoes/formulario'
import ListaDeConcentracao from './concentracoes/lista'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'

export default function Medicamentos(props) {
  const {
    adicionarMedicamento,
    adicionarConcentracaoDoMedicamento,
    alterarConcentracaoDoMedicamento,
    alterarMedicamento,
    alterarStatusDoMedicamento,
    filtros,
    history,
    listarMedicamentos,
    location,
    match,
    medicamento,
    ordenacao,
    paginaDeDados,
    recuperarMedicamentoPorIdentificador,
    removerMedicamento
  } = props

  const salvar = async dados => {
    const novoRegistro = !dados.identificador
    const acao = novoRegistro ? adicionarMedicamento : alterarMedicamento
    const identificador = await acao(dados)

    if (identificador) {
      history.push(`/cadastros/medicamentos/${identificador}`)
    }
  }

  const salvarConcentracao = async dados => {
    const novoRegistro = !dados.identificador

    if (novoRegistro) {
      const adicionou = await adicionarConcentracaoDoMedicamento(medicamento.identificador, { valor: dados.valor })

      if (adicionou) {
        history.push(`/cadastros/medicamentos/${medicamento.identificador}/concentracoes`)
      }

      return adicionou

    } else {

      const alterou = await alterarConcentracaoDoMedicamento(medicamento.identificador, dados)

      if (alterou) {
        props.listarConcentracoesDoMedicamento(medicamento.identificador)
      }

      return alterou
    }
  }

  const removerConcentracao = async identificador => {
    const removeu = await props.removerConcentracaoDoMedicamento(medicamento.identificador, identificador)

    if (removeu) {
      props.listarConcentracoesDoMedicamento(medicamento.identificador)
    }
  }

  const alterarStatus = async status => {
    const dados = {
      ...medicamento,
      ativo: status
    }

    const alterou = await alterarStatusDoMedicamento(dados)

    if (alterou) {
      history.push(`/cadastros/medicamentos`)
    }
  }

  const abaAtiva = (props) => {
    const abas = {
      '/concentracoes': 1      
    }    
    const url = location.pathname.replace(match.url+'/'+props.match.params.identificador, '')
    return abas[url] || 0
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_MEDICAMENTOS]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarMedicamentos}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/novo`,
          `${match.path}/:identificador`,
          `${match.path}/:identificador/concentracoes`,
          `${match.path}/:identificador/concentracoes/novo`,
        ]}
        render={
          props => {
            const identificador = props.match.params.identificador
            return (

              <div className='container'>
                <div className='main-content-form'>
                  <div className='header-page-form'>
                    <Breadcrumb />
                  </div>    

                  <Abas abaInicial={abaAtiva(props)}>
                    <div className='header-page-bar mt-0'>
                      <div className='header-page-nav'>
                        <ListaDeAbas className='tab'>
                          <Aba className='tab-item' aoClicar={() => identificador ? history.push(`${match.url}/${identificador}`) : ''}>
                            Dados Gerais                            
                          </Aba>
                          <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => history.push(`${match.url}/${identificador}/concentracoes`)}>
                            Concentrações                          
                          </Aba>                        
                        </ListaDeAbas>                        
                      </div>                   
                    </div>
                    <Paineis>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/novo`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ADICIONAR_MEDICAMENTO]}>
                                  <Formulario
                                    {...props}
                                    salvar={salvar}
                                  />
                                </Permissao>
                              )
                            }
                          />
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador`}
                            render={
                              props => (
                                <Formulario
                                  {...props}
                                  alterarStatusDoMedicamento={alterarStatus}
                                  medicamento={medicamento}
                                  salvar={salvar}
                                  recuperarPorIdentificador={recuperarMedicamentoPorIdentificador}
                                  remover={removerMedicamento}
                                />
                              )
                            }
                          />
                        </Switch>
                      </Painel>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/concentracoes`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.LISTAR_CONCENTRACOES_DO_MEDICAMENTO]}>
                                  <ListaDeConcentracao
                                    {...props}
                                    medicamento={medicamento}
                                    concentracoes={medicamento.concentracoes}
                                    recuperarMedicamentoPorIdentificador={recuperarMedicamentoPorIdentificador}
                                    salvar={salvarConcentracao}
                                    removerConcentracao={removerConcentracao}
                                  />
                                </Permissao>
                              )
                            }
                          />
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/:identificador/concentracoes/novo`}
                            render={
                              props => (        
                                <Permissao permissoes={[permissoes.ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO]}>
                                  <FormularioDeConcentracao
                                    {...props}
                                    salvar={salvarConcentracao}
                                    recuperarMedicamentoPorIdentificador={recuperarMedicamentoPorIdentificador}
                                  />
                                </Permissao>        
                              )
                            }
                          />
                        </Switch>
                      </Painel>                    
                    </Paineis>
                  </Abas> 
                                
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>    
  )
}