import api from './api-major'

const listarCelulas = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
    const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
    const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
    const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
    const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'

    return api.get(`/celulas${paginacao}${tamanhoDaPaginaFormatado}${parametroNome}${ordem}`)
}

const adicionarCelula = celula => api.post("/celulas", celula)
const recuperarCelulaPeloIdentificador = identificador => api.get(`/celulas/${identificador}`)
const alterarCelula = celula => api.put(`/celulas/${celula.identificador}`, celula)
const removerCelula = identificador => api.delete(`/celulas/${identificador}`)

export default {
    listarCelulas,
    adicionarCelula,
    recuperarCelulaPeloIdentificador,
    alterarCelula,
    removerCelula
}