import { connect } from 'react-redux'
import acoes from '../../acoes'
import PedidosDeContato from './index'

const mapStateToProps = state => { 
  return {
    filtros: state.pedidosDeContato.pedidoDeContato.filtros,
    ordenacao: state.pedidosDeContato.pedidoDeContato.ordenacao,
    paginaDeDados: state.pedidosDeContato.pedidoDeContato.paginaDeDados,
    planosDeSaude: state.pedidosDeContato.planosDeSaude,
  }
}

export default connect(mapStateToProps, acoes)(PedidosDeContato)