import React, { useState, useEffect, useCallback } from 'react'
import { Prompt } from 'react-router-dom'
import Modal from 'react-modal'

export default function AlertaDeSaida({ history, exibirPrompt }) {
  const [modal, setModal] = useState(false)
  const [ultimaUrl, setUltimaUrl] = useState(history.location)
  const [mudarRota, setMudarRota] = useState(false)

  const exibirModal = url => {
    setModal(true)
    setUltimaUrl(url)
  }

  const fecharModal = () => {
    setUltimaUrl(history.location)
    setModal(false)
  }

  const alertar = useCallback(evento => {
    if (exibirPrompt) {
      evento.preventDefault()
      evento.returnValue = ''
    }
  }, [exibirPrompt])

  useEffect(() => {
    window.addEventListener('beforeunload', alertar)
    return () => { window.removeEventListener('beforeunload', alertar) }
  }, [exibirPrompt, alertar])

  const bloquearRota = (proximaUrl) => {
    const trocarDeRota = proximaUrl.pathname !== ultimaUrl.pathname

    if (!mudarRota && trocarDeRota) {
      exibirModal(proximaUrl.pathname)
      return false
    }
    return true
  }

  const confirmarMudancaDeRota = () => {
    setModal(false)
    setMudarRota(true)
  }

  useEffect(() => {
    if (mudarRota && ultimaUrl) {
      history.push(ultimaUrl)
    }
  }, [mudarRota, ultimaUrl, history])

  return (
    <>
      <Prompt
        when={exibirPrompt}
        message={location => bloquearRota(location)}
      />
      <Modal
        isOpen={modal}
        className='modal modal-alerta'
      >
        <div className='form alerta-prontuario'>
          <h2 className='titulo'>Sair da Página?</h2>
          <hr className='separator mt-0 p-0'></hr>
          <fieldset className='pt-0'>
            <div>
              O prontuário possui dados que não foram salvos, ao sair
              <strong> as informações serão perdidas.</strong><br />
              Tem certeza que deseja sair?
          </div>
            <div className='list-btn'>
              <button className='button --light' onClick={fecharModal}>Não</button>
              <button className='button --danger' onClick={confirmarMudancaDeRota}>Sim</button>
            </div>
          </fieldset>
        </div>
      </Modal>
    </>
  )
}