import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'
import {
  DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
} from '../tipos'

const marcouItemNaListaDeOpcoesMultiplasDaTarefa = (identificadorDaTarefa, lista, item) => ({
  type: MARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  identificadorDaTarefa,
  lista,
  item
})

const erroAoMarcarItemNaListaDeOpcoesMultiplasDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  erro,
  parametros,
})

export const marcarItemNaListaDeOpcoesMultiplasDaTarefa = (identificadorDaTarefa, lista, item) => async dispatch => {
  dispatch({ type: MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA })

  try {
    const resultado = await api.marcarItemNaListaDeOpcoesMultiplasDaTarefa(identificadorDaTarefa, lista, item)

    dispatch(marcouItemNaListaDeOpcoesMultiplasDaTarefa(identificadorDaTarefa, lista, item))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoMarcarItemNaListaDeOpcoesMultiplasDaTarefa(erro, { identificadorDaTarefa, lista, item }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao marcar o item na lista de opções múltiplas da tarefa.')))

    return false
  }
}

const desmarcouItemNaListaDeOpcoesMultiplasDaTarefa = (identificadorDaTarefa, lista, item) => ({
  type: DESMARCOU_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  identificadorDaTarefa,
  lista,
  item
})

const erroAoDesmarcarItemNaListaDeOpcoesMultiplasDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  erro,
  parametros,
})

export const desmarcarItemNaListaDeOpcoesMultiplasDaTarefa = (identificadorDaTarefa, lista, item) => async dispatch => {
  dispatch({ type: DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA })

  try {
    const resultado = await api.desmarcarItemNaListaDeOpcoesMultiplasDaTarefa(identificadorDaTarefa, lista, item)

    dispatch(desmarcouItemNaListaDeOpcoesMultiplasDaTarefa(identificadorDaTarefa, lista, item))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDesmarcarItemNaListaDeOpcoesMultiplasDaTarefa(erro, { identificadorDaTarefa, lista, item }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao desmarcar o item na lista de opções múltiplas da tarefa.')))

    return false
  }
}