import {
  LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS
} from '../../acoes/tipos'

export default (state, action) => {
  switch (action.type) {
    case LISTOU_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS: {
      return action.profissionais.map(x => ({...x, nome: x.tratamento ? x.tratamento + ' ' + x.nome: x.nome}))
    }

    default: {
      return state
    }
  }
}