import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Diagnosticos(props) {
  const {
    adicionarDiagnostico,
    alterarDiagnostico,
    cids10,
    diagnostico,
    filtros,
    history,
    listarCids10,
    listarDiagnosticos,
    match,
    ordenacao,
    paginaDeDados,
    recuperarDiagnosticoPeloIdentificador,
    removerDiagnostico,
  } = props

  useEffect(() => { listarCids10() }, [listarCids10])

  const salvar = async formulario => {    
    const dados = {
      ...formulario,
      cid10: formulario.semCid10 ? null : formulario.cid10,
      status: formulario.aprovado ? 'aprovado' : 'nao_aprovado'
    }

    const acao = dados.identificador ? alterarDiagnostico : adicionarDiagnostico
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/diagnosticos`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_DIAGNOSTICOS]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarDiagnosticos}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_DIAGNOSTICO]}>
              <Formulario
                {...props}
                salvar={salvar}
                diagnostico={diagnostico}
                cids10={cids10}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_DIAGNOSTICO]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarDiagnosticoPeloIdentificador}
                salvar={salvar}
                diagnostico={diagnostico}
                remover={removerDiagnostico}
                cids10={cids10}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}