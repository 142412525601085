import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TIPOS_DE_UNIDADE,
  LISTOU_TIPOS_DE_UNIDADE,
  ERRO_AO_LISTAR_TIPOS_DE_UNIDADE
} from './tipos'

const listouTiposDeUnidade = tiposDeUnidade => ({
  type: LISTOU_TIPOS_DE_UNIDADE,
  tiposDeUnidade
})

const erroAoListarTiposDeUnidade = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_UNIDADE,
  erro
})

export const listarTiposDeUnidade = () => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_UNIDADE })

  try {
    const resultado = await api.listarTiposDeUnidade()

    dispatch(listouTiposDeUnidade(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeUnidade(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de unidade.')))
  }
}