import React, { useEffect } from 'react'
import { Formulario as FormularioDoFormik, MultiplaEscolha, MultiplaSelecao } from '../../../../formik/formulario'
import { contemValor } from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'

import {
  ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  LISTAR_COGNICOES_DOS_PACIENTES,
  LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
  LISTAR_COMORBIDADES_CARDIOVASCULARES,
  LISTAR_COMORBIDADES_ENDOCRINAS,
  LISTAR_COMORBIDADES_GASTROINTESTINAIS,
  LISTAR_COMORBIDADES_NEUROLOGICAS,
  LISTAR_COMORBIDADES_ONCOLOGICAS,
  LISTAR_COMORBIDADES_OSTEOMUSCULARES,
  LISTAR_COMORBIDADES_PSIQUIATRICAS,
  LISTAR_COMORBIDADES_PULMONARES,
  LISTAR_COMORBIDADES_RENAIS,
  LISTAR_COMORBIDADES_REUMATOLOGICAS,
  LISTAR_CONDICOES_DOS_PACIENTES,
  LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  LISTAR_ESCALAS_DE_FRAGILIDADE,
  LISTAR_ESTADOS_DE_DEAMBULACAO,
  LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  LISTAR_VACINAS_EM_DIA
} from '../../../../../acoes/tipos'

const obrigatorio = valor => {
  if (contemValor(valor)) {
    if (Array.isArray(valor) && valor.length === 0) {
      return 'Obrigatório'
    }

    return undefined
  }

  return 'Obrigatório'
}

const opcoesDesabilitadas = (valores, itens, valorParaComparacao) => {
  const opcoes = itens.map(x => x.codigo)

  if (valores.includes(valorParaComparacao)) {
    return opcoes.filter(x => x !== valorParaComparacao)
  } else if (valores.length > 0) {
    return opcoes.filter(x => x === valorParaComparacao)
  } else {
    return []
  }
}

const renderizarTitulo = valor => valor.nome
const renderizarDescricao = valor => valor.descricao

export default function Formulario({ selecionado, valores, fechar, ...rest }) {
  const salvar = async (valores, { resetForm }) => {
    const {
      paciente,
      usuario,
      ...formulario
    } = valores

    const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
      ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
      LISTAR_VACINAS_EM_DIA,
      LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
      LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
      LISTAR_ESTADOS_DE_DEAMBULACAO,
      LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
      LISTAR_CONDICOES_DOS_PACIENTES,
      LISTAR_COMORBIDADES_REUMATOLOGICAS,
      LISTAR_COMORBIDADES_RENAIS,
      LISTAR_COMORBIDADES_PULMONARES,
      LISTAR_COMORBIDADES_PSIQUIATRICAS,
      LISTAR_COMORBIDADES_OSTEOMUSCULARES,
      LISTAR_COMORBIDADES_ONCOLOGICAS,
      LISTAR_COMORBIDADES_NEUROLOGICAS,
      LISTAR_COMORBIDADES_GASTROINTESTINAIS,
      LISTAR_COMORBIDADES_ENDOCRINAS,
      LISTAR_COMORBIDADES_CARDIOVASCULARES,
      LISTAR_COGNICOES_DOS_PACIENTES,
      LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
      LISTAR_ESCALAS_DE_FRAGILIDADE,
      LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE
    ]}>
      <FormularioDoFormik
        reinicializar={false}
        acao={salvar}
        valoresIniciais={valores}
      >
        {({ values, setFieldValue }) => (
          <fieldset>
            <h2 className="form-title">{selecionado ? 'Alterar Questionário do Idoso' : 'Adicionar Questionário do Idoso'}</h2>
            <r-grid columns-md={6} columns-lg={12} class="componente-fisioterapia">
              <Cabecalho/>
              <EscalaDeFragilidade escalaDeFragilidade={values.escalaDeFragilidade} escalasDeFragilidade={rest.escalasDeFragilidade} setFieldValue={setFieldValue}/>
              <PacienteMoraComQuem moraComQuem={values.moraComQuem} comQuemOsPacientesMoram={rest.comQuemOsPacientesMoram} setFieldValue={setFieldValue}/>
              <MedicamentosEmUso medicamentosEmUso={values.medicamentosEmUso} usosDeMedicamentosPelosPacientes={rest.usosDeMedicamentosPelosPacientes} setFieldValue={setFieldValue}/>
              <VacinasEmDia vacinasEmDia={values.vacinasEmDia} vacinasEmDiaDosPacientes={rest.vacinasEmDia} setFieldValue={setFieldValue}/>
              <Cognicao cognicao={values.cognicao} cognicoesDosPacientes={rest.cognicoesDosPacientes} setFieldValue={setFieldValue}/>
              <Condicoes condicoes={values.condicoes} condicoesDosPacientes={rest.condicoesDosPacientes} setFieldValue={setFieldValue}/>
              <Deambulacao deambulacao={values.deambulacao} estadosDeDeambulacao={rest.estadosDeDeambulacao} setFieldValue={setFieldValue}/>
              <QuedasNosUltimosDozeMeses quedasNosUltimosDozeMeses={values.quedasNosUltimosDozeMeses} quedasNosUltimosDozeMesesDosPacientes={rest.quedasNosUltimosDozeMeses} setFieldValue={setFieldValue}/>
              <DispositivosEmUso dispositivosEmUso={values.dispositivosEmUso} dispositivosEmUsoDosPacientes={rest.dispositivosEmUsoDosPacientes} setFieldValue={setFieldValue}/>
              <EstadoTerminal/>
              <NecessidadeDeControleDeSintomas/>
              <PossuiComorbidades/>
              <ComorbidadesCardiovasculares comorbidadesCardiovasculares={values.comorbidadesCardiovasculares} comorbidadesCardiovascularesDosPacientes={rest.comorbidadesCardiovasculares} setFieldValue={setFieldValue}/>
              <ComorbidadesPulmonares comorbidadesPulmonares={values.comorbidadesPulmonares} comorbidadesPulmonaresDosPacientes={rest.comorbidadesPulmonares} setFieldValue={setFieldValue}/>
              <ComorbidadesNeurologicas comorbidadesNeurologicas={values.comorbidadesNeurologicas} comorbidadesNeurologicasDosPacientes={rest.comorbidadesNeurologicas} setFieldValue={setFieldValue}/>
              <ComorbidadesPsiquiatricas comorbidadesPsiquiatricas={values.comorbidadesPsiquiatricas} comorbidadesPsiquiatricasDosPacientes={rest.comorbidadesPsiquiatricas} setFieldValue={setFieldValue}/>
              <ComorbidadesRenais comorbidadesRenais={values.comorbidadesRenais} comorbidadesRenaisDosPacientes={rest.comorbidadesRenais} setFieldValue={setFieldValue}/>
              <ComorbidadesEndocrinas comorbidadesEndocrinas={values.comorbidadesEndocrinas} comorbidadesEndocrinasDosPacientes={rest.comorbidadesEndocrinas} setFieldValue={setFieldValue}/>
              <ComorbidadesReumatologicas comorbidadesReumatologicas={values.comorbidadesReumatologicas} comorbidadesReumatologicasDosPacientes={rest.comorbidadesReumatologicas} setFieldValue={setFieldValue}/>
              <ComorbidadesGastrointestinais comorbidadesGastrointestinais={values.comorbidadesGastrointestinais} comorbidadesGastrointestinaisDosPacientes={rest.comorbidadesGastrointestinais} setFieldValue={setFieldValue}/>
              <ComorbidadesOncologicas comorbidadesOncologicas={values.comorbidadesOncologicas} comorbidadesOncologicasDosPacientes={rest.comorbidadesOncologicas} setFieldValue={setFieldValue}/>
              <ComorbidadesOsteomusculares comorbidadesOsteomusculares={values.comorbidadesOsteomusculares} comorbidadesOsteomuscularesDosPacientes={rest.comorbidadesOsteomusculares} setFieldValue={setFieldValue}/>
            </r-grid>
            <div className="list-btn">
              <button type="button" className="button --light" onClick={() => fechar()}>Fechar</button>
              <button type="submit" className="button --primary">{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="cabecalho">
      <div>
        <strong>QUESTIONÁRIO DO IDOSO</strong>
      </div>
    </r-cell>
  )
}

function EscalaDeFragilidade({ escalasDeFragilidade, escalaDeFragilidade, setFieldValue }) {
  useEffect(() => {
    if (!escalaDeFragilidade) {
      setFieldValue('escalaDeFragilidade', null)
    }
  }, [setFieldValue, escalaDeFragilidade])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="escalaDeFragilidade"
        opcoes={escalasDeFragilidade}
        pergunta="Qual é o Clinical Frailty Scale (CFS) do paciente? *"
        renderizarTitulo={renderizarTitulo}
        renderizarDescricao={renderizarDescricao}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function PacienteMoraComQuem({ comQuemOsPacientesMoram, moraComQuem, setFieldValue }) {
  useEffect(() => {
    if (!moraComQuem) {
      setFieldValue('moraComQuem', null)
    }
  }, [setFieldValue, moraComQuem])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="moraComQuem"
        opcoes={comQuemOsPacientesMoram}
        pergunta="Paciente mora com quem? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function MedicamentosEmUso({ medicamentosEmUso, usosDeMedicamentosPelosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!medicamentosEmUso) {
      setFieldValue('medicamentosEmUso', null)
    }
  }, [setFieldValue, medicamentosEmUso])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="medicamentosEmUso"
        opcoes={usosDeMedicamentosPelosPacientes}
        pergunta="Quantidade de medicamentos utilizados? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function VacinasEmDia({ vacinasEmDia, vacinasEmDiaDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!vacinasEmDia) {
      setFieldValue('vacinasEmDia', [])
    }
  }, [setFieldValue, vacinasEmDia])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="vacinasEmDia"
        opcoes={vacinasEmDiaDosPacientes}
        pergunta="Marque as vacinas do paciente que estão em dia: *"
        renderizarTitulo={renderizarTitulo}
        valores={vacinasEmDia}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(vacinasEmDia, vacinasEmDiaDosPacientes, 'nenhuma')}
      />
    </r-cell>
  )
}

function Cognicao({ cognicao, cognicoesDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!cognicao) {
      setFieldValue('cognicao', null)
    }
  }, [setFieldValue, cognicao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="cognicao"
        opcoes={cognicoesDosPacientes}
        pergunta="Cognição? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function Condicoes({ condicoes, condicoesDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!condicoes) {
      setFieldValue('condicoes', [])
    }
  }, [setFieldValue, condicoes])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="condicoes"
        opcoes={condicoesDosPacientes}
        pergunta="Paciente apresenta alguma dessas condições? *"
        renderizarTitulo={renderizarTitulo}
        valores={condicoes}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(condicoes, condicoesDosPacientes, 'nenhuma_condicao')}
      />
    </r-cell>
  )
}

function Deambulacao({ deambulacao, estadosDeDeambulacao, setFieldValue }) {
  useEffect(() => {
    if (!deambulacao) {
      setFieldValue('deambulacao', null)
    }
  }, [setFieldValue, deambulacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="deambulacao"
        opcoes={estadosDeDeambulacao}
        pergunta="Sobre deambulação (indicar a situação predominante) *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function QuedasNosUltimosDozeMeses({ quedasNosUltimosDozeMeses, quedasNosUltimosDozeMesesDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!quedasNosUltimosDozeMeses) {
      setFieldValue('quedasNosUltimosDozeMeses', null)
    }
  }, [setFieldValue, quedasNosUltimosDozeMeses])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="quedasNosUltimosDozeMeses"
        opcoes={quedasNosUltimosDozeMesesDosPacientes}
        pergunta="Queda nos últimos 12 meses: *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function DispositivosEmUso({ dispositivosEmUso, dispositivosEmUsoDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!dispositivosEmUso) {
      setFieldValue('dispositivosEmUso', [])
    }
  }, [setFieldValue, dispositivosEmUso])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="dispositivosEmUso"
        opcoes={dispositivosEmUsoDosPacientes}
        pergunta="Paciente faz uso de algum dispositivo? *"
        renderizarTitulo={renderizarTitulo}
        valores={dispositivosEmUso}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(dispositivosEmUso, dispositivosEmUsoDosPacientes, 'nao_usa_dispositivos')}
      />
    </r-cell>
  )
}

function EstadoTerminal() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="estadoTerminal"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta="Paciente está em estado terminal (Sobrevida estimada menor que 12 meses)? *"
        informacaoAdicional="Sugestão de ferramenta: SPICT-BR (Supportive and Palliative Care Indicators Tool) "
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function NecessidadeDeControleDeSintomas() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="necessidadeDeControleDeSintomas"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta="Tem necessidade de controle de sintomas no momento? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
      />
    </r-cell>
  )
}

function PossuiComorbidades() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <p>Assinale a(s) comorbidade(s) do paciente:</p>
    </r-cell>
  )
}

function ComorbidadesCardiovasculares({ comorbidadesCardiovasculares, comorbidadesCardiovascularesDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesCardiovasculares) {
      setFieldValue('comorbidadesCardiovasculares', [])
    }
  }, [setFieldValue, comorbidadesCardiovasculares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesCardiovasculares"
        opcoes={comorbidadesCardiovascularesDosPacientes}
        pergunta="Cardiovascular"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesCardiovasculares}
      />
    </r-cell>
  )
}

function ComorbidadesPulmonares({ comorbidadesPulmonares, comorbidadesPulmonaresDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesPulmonares) {
      setFieldValue('comorbidadesPulmonares', [])
    }
  }, [setFieldValue, comorbidadesPulmonares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPulmonares"
        opcoes={comorbidadesPulmonaresDosPacientes}
        pergunta="Pulmonar"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesPulmonares}
      />
    </r-cell>
  )
}

function ComorbidadesNeurologicas({ comorbidadesNeurologicas, comorbidadesNeurologicasDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesNeurologicas) {
      setFieldValue('comorbidadesNeurologicas', [])
    }
  }, [setFieldValue, comorbidadesNeurologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesNeurologicas"
        opcoes={comorbidadesNeurologicasDosPacientes}
        pergunta="Neurológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesNeurologicas}
      />
    </r-cell>
  )
}

function ComorbidadesPsiquiatricas({ comorbidadesPsiquiatricas, comorbidadesPsiquiatricasDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesPsiquiatricas) {
      setFieldValue('comorbidadesPsiquiatricas', [])
    }
  }, [setFieldValue, comorbidadesPsiquiatricas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPsiquiatricas"
        opcoes={comorbidadesPsiquiatricasDosPacientes}
        pergunta="Psiquiátrica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesPsiquiatricas}
      />
    </r-cell>
  )
}

function ComorbidadesRenais({ comorbidadesRenais, comorbidadesRenaisDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesRenais) {
      setFieldValue('comorbidadesRenais', [])
    }
  }, [setFieldValue, comorbidadesRenais])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesRenais"
        opcoes={comorbidadesRenaisDosPacientes}
        pergunta="Renal"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesRenais}
      />
    </r-cell>
  )
}

function ComorbidadesEndocrinas({ comorbidadesEndocrinas, comorbidadesEndocrinasDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesEndocrinas) {
      setFieldValue('comorbidadesEndocrinas', [])
    }
  }, [setFieldValue, comorbidadesEndocrinas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesEndocrinas"
        opcoes={comorbidadesEndocrinasDosPacientes}
        pergunta="Endócrina"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesEndocrinas}
      />
    </r-cell>
  )
}

function ComorbidadesReumatologicas({ comorbidadesReumatologicas, comorbidadesReumatologicasDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesReumatologicas) {
      setFieldValue('comorbidadesReumatologicas', [])
    }
  }, [setFieldValue, comorbidadesReumatologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesReumatologicas"
        opcoes={comorbidadesReumatologicasDosPacientes}
        pergunta="Reumatológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesReumatologicas}
      />
    </r-cell>
  )
}

function ComorbidadesGastrointestinais({ comorbidadesGastrointestinais, comorbidadesGastrointestinaisDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesGastrointestinais) {
      setFieldValue('comorbidadesGastrointestinais', [])
    }
  }, [setFieldValue, comorbidadesGastrointestinais])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesGastrointestinais"
        opcoes={comorbidadesGastrointestinaisDosPacientes}
        pergunta="Gastrointestinal"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesGastrointestinais}
      />
    </r-cell>
  )
}

function ComorbidadesOncologicas({ comorbidadesOncologicas, comorbidadesOncologicasDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesOncologicas) {
      setFieldValue('comorbidadesOncologicas', [])
    }
  }, [setFieldValue, comorbidadesOncologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOncologicas"
        opcoes={comorbidadesOncologicasDosPacientes}
        pergunta="Oncológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesOncologicas}
      />
    </r-cell>
  )
}

function ComorbidadesOsteomusculares({ comorbidadesOsteomusculares, comorbidadesOsteomuscularesDosPacientes, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesOsteomusculares) {
      setFieldValue('comorbidadesOsteomusculares', [])
    }
  }, [setFieldValue, comorbidadesOsteomusculares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOsteomusculares"
        opcoes={comorbidadesOsteomuscularesDosPacientes}
        pergunta="Osteomuscular"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesOsteomusculares}
      />
    </r-cell>
  )
}