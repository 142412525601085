import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA,
  LISTOU_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA,
  ERRO_AO_LISTAR_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA
} from '../tipos'

const listouRespostasAPressaoNoLocalDaHemorragia = respostasPressaoHemorragia => ({
  type: LISTOU_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA,
  respostasPressaoHemorragia
})

const erroAoListarRespostasAPressaoNoLocalDaHemorragia = erro => ({
  type: ERRO_AO_LISTAR_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA,
  erro
})

export const listarRespostasAPressaoNoLocalDaHemorragia = () => async dispatch => {
  dispatch({ type: LISTAR_RESPOSTAS_A_PRESSAO_NO_LOCAL_DA_HEMORRAGIA })

  try {
    const resultado = await api.listarRespostasAPressaoNoLocalDaHemorragia()

    dispatch(listouRespostasAPressaoNoLocalDaHemorragia(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarRespostasAPressaoNoLocalDaHemorragia(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as respostas à pressão no local da hemorragia.')))
  }
}
