import api from '../api-narcissus'

const listarProfissoesParaONps = () => api.get('/profissoes?tamanhoDaPagina=10000&ordem=nome')
const habilitarProfissoesParaONps = profissoes => api.put('/profissoes/habilitadas', profissoes)
const desabilitarProfissaoParaONps = profissao => api.put('/profissoes/desabilitada', { profissao: profissao })

export default {
  listarProfissoesParaONps,
  habilitarProfissoesParaONps,
  desabilitarProfissaoParaONps
}