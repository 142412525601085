import { connect } from 'react-redux'
import acoes from '../../../acoes'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { ordemDoServidor } from '../../../bibliotecas/ordenacao'
import ConfirmarAgendamento from './index'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.paciente.foto).forEach(item => {
    fotos[item.paciente.foto] = imagens[item.paciente.foto]
  })

  return fotos
}

const mapStateToProps = state => {
  return {
    agendamento: state.agendamentos.agendamento.agendamento,
    filtros: state.agendamentos.confirmarAgendamento.filtros,
    fotos: recuperarFotos(state.agendamentos.confirmarAgendamento.paginaDeDados.dados, state.imagens),
    ordenacao: state.agendamentos.confirmarAgendamento.ordenacao,
    identificadorDoUsuarioLogado: state.login.logado.identificador,
    paginaDeDados: state.agendamentos.confirmarAgendamento.paginaDeDados,
    profissional: state.agenda.profissionais[state.agenda.selecionado],
    profissionais: converterObjetoParaArray(state.agenda.profissionais).sort(ordemDoServidor),
    motivosDeCancelamento: state.agendamentos.motivosDeCancelamento,
    unidades: converterObjetoParaArray(state.login.logado.unidades),
  }
}

export default connect(mapStateToProps, acoes)(ConfirmarAgendamento)