import React, { useEffect } from 'react'
import moment from 'moment'
import { useProntuario } from '../../contexto'
import { DatePicker, Select } from '../../../formik/formulario'
import Historico from './historico'
import HistoricoPopup from '../historico-popup'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'

export default function AntiHiv({ metadados, historico = [], valor, leitura, exibirDataExame, dataExame, setFieldValue }) {
  const { sorologias, listarResultadosAntiHiv } = useProntuario()

  useEffect(() => { listarResultadosAntiHiv() }, [listarResultadosAntiHiv])

  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame

  const algumCampoInformado = valor && (valor.valor || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)

  useEffect(() => {
    if (exibirDataExame && valor.valor) {
      setFieldValue('antiHiv.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  const resultadosAntiHiv = sorologias.antiHiv

  const validarAntiHiv = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null
    const antiHiv = resultadosAntiHiv && resultadosAntiHiv.length > 0 && resultadosAntiHiv.filter(x => x.codigo === valor.valor)[0].nome

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Anti-HIV: </strong>
            {antiHiv} no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 2
    return 4
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <Select
            nome='antiHiv.valor'
            titulo={`Anti-HIV ${obrigatorio ? '*' : ''}`}
            itens={resultadosAntiHiv}
            campoCodigo='codigo'
            campoDescricao='nome'
            validar={validarAntiHiv}
          >
            {historico.length > 0 &&
              <HistoricoPopup titulo='Anti-HIV'>
                <Historico historico={historico} />
              </HistoricoPopup>
            }
          </Select>
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='antiHiv.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}