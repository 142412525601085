import React from 'react'
import * as Yup from 'yup'
import { Formulario as FormularioDoFormik, Input } from '../../formik/formulario'
import { CANCELAR_ATENDIMENTO } from '../../../acoes/tipos'
import Spinner from '../../spinner'
import { formatarDataParaFormatoLocal, formatarHoraParaFormatoLocal } from '../../../bibliotecas/data'

export default function Cancelamento({ identificador, atendimento, cancelar, fechar }) {
  const cancelarAtendimento = async dados => {
    const cancelou = await cancelar(identificador, atendimento, dados)

    if (cancelou) {
      fechar()
    }
  }

  const dadosDoAtendimento = {
    tipo: atendimento.tipo.nome,
    data: formatarDataParaFormatoLocal(atendimento.inicio),
    hora: formatarHoraParaFormatoLocal(atendimento.inicio),
    profissional: atendimento.usuario.nome,
    profissao: atendimento.usuario.profissao ? atendimento.usuario.profissao.nome : ''
  }

  return (
    <Spinner operacoes={[CANCELAR_ATENDIMENTO]}>
      <FormularioDoFormik
        valoresIniciais={{
          status: 'cancelado_apos_finalizar',
          observacoes: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          observacoes: Yup.string().required('Obrigatório.'),
        })}
        acao={cancelarAtendimento}
      >
        <fieldset>
          <h2 className='form-title'>Cancelar atendimento finalizado</h2>
          <div className='form-choice-alerta mb-1'>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <pre className='form-choice-alerta__item'>
              O <strong>cancelamento</strong> do atendimento {dadosDoAtendimento.tipo} realizado no dia <strong>{dadosDoAtendimento.data}</strong> às <strong>{dadosDoAtendimento.hora}</strong>
              {" "}por {dadosDoAtendimento.profissional} {dadosDoAtendimento.profissao ? `- ${dadosDoAtendimento.profissao} ` : ' '}
              <strong>não poderá ser desfeito após a confirmação</strong>.
            </pre>
          </div>
          <Input
            as='textarea'
            tabIndex={2}
            nome='observacoes'
            titulo='Motivo do cancelamento *'
          />
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
            <button type='submit' className='button --danger'>Cancelar</button>
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}