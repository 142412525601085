import api from './api-major'

const listarEstabelecimentosDeSaude = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroNome = filtros.nome ? `&nome=${filtros.nome}` : ''
  const parametroStatus = filtros.status !== undefined && filtros.status.length === 1 ? `&status=${filtros.status[0]}` : ''
  const parametroRedePropria = filtros.redePropria !== undefined && filtros.redePropria.length === 1 ? `&redePropria=${filtros.redePropria[0] === 'Sim'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=10000'

  return api.get(`/estabelecimentos-de-saude${paginacao}${tamanhoDaPaginaFormatado}${parametroStatus}${parametroRedePropria}${parametroNome}${ordem}`)
}
const adicionarEstabelecimentoDeSaude = estabelecimento => api.post(`/estabelecimentos-de-saude/`, estabelecimento)
const recuperarEstabelecimentoDeSaudePeloIdentificador = identificador => api.get(`/estabelecimentos-de-saude/${identificador}`)
const alterarEstabelecimentoDeSaude = estabelecimento => api.put(`/estabelecimentos-de-saude/${estabelecimento.identificador}`, estabelecimento)
const removerEstabelecimentoDeSaude = identificador => api.delete(`/estabelecimentos-de-saude/${identificador}`)

export default {
  listarEstabelecimentosDeSaude,
  adicionarEstabelecimentoDeSaude,
  recuperarEstabelecimentoDeSaudePeloIdentificador,
  alterarEstabelecimentoDeSaude,
  removerEstabelecimentoDeSaude
}