import api from './api-major'

const adicionarUnidadeDaEmpresa = unidade => api.post(`/empresa/unidades`, unidade)
const alterarStatusUnidadeDaEmpresa = (identificador, status) => api.put(`/empresa/unidades/${identificador}/status`, { status: status })
const listarUnidadesDaEmpresa = () => api.get(`/empresa/unidades?tamanhoDaPagina=1000&ordem=nome`)
const recuperarEmpresa = () => api.get(`/empresa`)
const removerUnidadeDaEmpresa = identificadorDaUnidade => api.delete(`/empresa/unidades/${identificadorDaUnidade}`)
const salvarEmpresa = empresa => api.put('/empresa', empresa)
const salvarUnidadeDaEmpresa = unidade => api.put(`/empresa/unidades/${unidade.identificador}`, unidade)
const recuperarFotoDaEmpresaLogada = () => api.get(`/empresa/logotipo`, { responseType: 'arraybuffer' })
const recuperarFotoDaEmpresaPorIdentificadorPublico = identificador => api.get(`/empresas/${identificador}/logotipo`, { responseType: 'arraybuffer' })
const adicionarSalaNaUnidade = (identificadorDaUnidade, sala) => api.post(`/empresa/unidades/${identificadorDaUnidade}/salas`, sala)
const listarSalasDaUnidade = identificadorDaUnidade => api.get(`/empresa/unidades/${identificadorDaUnidade}/salas?tamanhoDaPagina=1000&ordem=nome`)
const removerSalaDaUnidade = (identificadorDaUnidade, identificadorDaSala) => api.delete(`/empresa/unidades/${identificadorDaUnidade}/salas/${identificadorDaSala}`)
const alterarSalaDaUnidade = (identificadorDaUnidade, sala) => api.put(`/empresa/unidades/${identificadorDaUnidade}/salas/${sala.identificador}`, sala)
const recuperarSalaDaUnidadePeloIdentificador = (identificadorDaUnidade, identificadorDaSala) => api.get(`/empresa/unidades/${identificadorDaUnidade}/salas/${identificadorDaSala}`)


export default {
  adicionarSalaNaUnidade,
  adicionarUnidadeDaEmpresa,
  alterarSalaDaUnidade,
  alterarStatusUnidadeDaEmpresa,
  listarSalasDaUnidade,
  listarUnidadesDaEmpresa,
  recuperarEmpresa,
  recuperarFotoDaEmpresaLogada,
  recuperarFotoDaEmpresaPorIdentificadorPublico,
  recuperarSalaDaUnidadePeloIdentificador,
  removerSalaDaUnidade,
  removerUnidadeDaEmpresa,
  salvarEmpresa,
  salvarUnidadeDaEmpresa
}