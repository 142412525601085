import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SITUACOES_ASSOCIADAS_A_QUEDA,
  LISTOU_SITUACOES_ASSOCIADAS_A_QUEDA,
  ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_QUEDA
} from '../tipos'

const listouSituacoesAssociadasAQueda = situacoesQueda => ({
  type: LISTOU_SITUACOES_ASSOCIADAS_A_QUEDA,
  situacoesQueda
})

const erroAoListarSituacoesAssociadasAQueda = erro => ({
  type: ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_QUEDA,
  erro
})

export const listarSituacoesAssociadasAQueda = () => async dispatch => {
  dispatch({ type: LISTAR_SITUACOES_ASSOCIADAS_A_QUEDA })

  try {
    const resultado = await api.listarSituacoesAssociadasAQueda()

    dispatch(listouSituacoesAssociadasAQueda(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSituacoesAssociadasAQueda(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as situações associadas à queda.')))
  }
}