import React, { useMemo, useState, useLayoutEffect, useRef, useEffect } from 'react'
import { CartesianGrid, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatarDataParaFormatoLocal, formatarDataParaTimestamp } from '../../../../bibliotecas/data'
import { timestampCrescente } from '../../../../bibliotecas/ordenacao'
import tabelaVazia from '../../../../design/img/empty-data.svg'

export default function GraficoTaxaDeFiltracaoGlomerular({ historico, exibirMensagem = false }) {
  const TooltipCreatinina = (props) => {
    const { active, payload } = props

    if (active) {
      const payloadCreatinina = payload.find(x => x.dataKey === 'taxaDeFiltracaoGlomerular')

      return (
        <div className='tooltip-personalizado'>
          <p className='data'>Data: {formatarDataParaFormatoLocal(payloadCreatinina.payload.data)}</p>
          <p style={{ color: '#d88484' }}>Taxa de Filtração Glomerular: {payloadCreatinina.payload.taxaDeFiltracaoGlomerular} mL/min/1.73m²</p>
          <p style={{ color: '#8884d8' }}>Creatinina: {payloadCreatinina.payload.valor} mg/dL</p>
        </div>
      )
    }

    return null
  }

  const [largura, setLargura] = useState(0)
  const graficos = useRef(null)

  const dados = useMemo(() => historico ? historico.map(x => ({ data: formatarDataParaTimestamp(x.data), valor: x.valor, taxaDeFiltracaoGlomerular: x.taxaDeFiltracaoGlomerular.valor })).sort(timestampCrescente) : [], [historico])
  const [dadosParaGrafico, setDadosParaGrafico] = useState(dados)

  function CustomizacaoDeRotulo({ x, y, stroke, value }) {
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={12} textAnchor='middle'>{dados.length > 9 ? '' : `${value}`}</text>
  }

  useLayoutEffect(() => {
    function updateSize() {
      setLargura(graficos.current ? graficos.current.offsetWidth : '100%')
    }
    window.addEventListener('resize', updateSize);
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    if (largura !== 0 && largura < 500 && dados.length > 9) {
      const ultimosRegistros = dados.slice(-10)
      setDadosParaGrafico(ultimosRegistros)
    } else {
      setDadosParaGrafico(dados)
    }
  }, [largura, dados])

  if (dadosParaGrafico.length === 0 && exibirMensagem) {
    return (
      <div className='table-items__result'>
        <button className='icon-tabela-vazia'>
          <img src={tabelaVazia} alt='tabelaVazia' />
        </button>
        <p>Não existem registros de creatinina.</p>
      </div>
    )
  }

  if (dadosParaGrafico.length === 0 && !exibirMensagem) {
    return null
  }

  return (
    <>
      <label className='pt-3 titulo-grafico-prontuario'>Taxa de Filtração Glomerular</label>
      <div className='grafico' ref={graficos}>
        {dadosParaGrafico.length > 0 &&
          <ResponsiveContainer width={largura}>
            <LineChart
              data={dadosParaGrafico}
              margin={{
                top: 16,
                right: 25,
                left: -10,
                bottom: 8
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='data'
                height={20}
                padding={{ left: 20 }}
                type='number'
                scale='time'
                domain={['dataMin', 'dataMax']}
                fontSize={12}
                tickMargin={5}
                tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString('pt-br', { year: 'numeric', month: '2-digit' })}
              />
              <YAxis fontSize={12} domain={['dataMin - 10', 'dataMax + 10']} />
              <Tooltip content={<TooltipCreatinina dados={historico} />} />
              <Line
                activeDot={{ r: 5 }}
                dataKey='taxaDeFiltracaoGlomerular'
                label={<CustomizacaoDeRotulo />}
                name='Taxa de Filtração Glomerular'
                stroke='#d88484'
                strokeWidth={1.3}
                type='monotone'
              />
            </LineChart>
          </ResponsiveContainer>
        }
        {largura !== 0 && largura < 500 && dadosParaGrafico.length > 9 &&
          <div className='description mt-2'>Visualização dos últimos 10 registros.</div>
        }
      </div>
    </>
  )
}