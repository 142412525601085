import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import CampoDePesquisa from '../campo-de-pesquisa'
import {AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela} from '../tabela'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {LISTAR_PROFISSIONAIS_EXTERNOS} from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao, 10))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = nome => alterarFiltros({ ...filtros, nome })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
            <div className='header-page-tools'>
              <Permissao permissoes={[permissoes.ADICIONAR_PROFISSIONAL_EXTERNO]}>
                <Link
                  to={`/cadastros/profissionais-externos/novo`}
                  className='button --primary --plus'
                >
                  Adicionar
                </Link>
              </Permissao>
              <CampoDePesquisa
                pesquisar={alterarTermo}
                valor={filtros ? filtros.nome : ''}
              />
            </div>
          </div>
          <Spinner operacoes={[LISTAR_PROFISSIONAIS_EXTERNOS]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_PROFISSIONAL_EXTERNO]}>
                    <Link
                      to={`/cadastros/profissionais-externos/${item.identificador}`}
                      className='table-items-expanded__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </Link>
                  </Permissao>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              expandida={true}
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                campoDeDados='nome'
                className='col-xs-8 col-md-10 col-lg-3'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-2'
                nome='Profissão'
                campoDeDados='profissao'
                renderizar={item => item.profissao ? item.profissao : ''}
              />
              <Coluna
                className='col-lg-3'
                nome='Especialidade'
                campoDeDados='especialidades'
                renderizar={item => item.especialidades ? item.especialidades.join(', ') : ''}
              />
              <Coluna
                campoDeDados='ativo'
                className='col-lg-2'
                nome='Ativo'
                filtros={['Sim', 'Não']}
                renderizar={item => <p className='table-items__mobile-value'>{item.ativo ? 'Sim' : 'Não'}</p>}
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-name'
                    campoDeDados='nome'
                    nome='Nome'
                  />
                  <Coluna
                    icone='icon-doctor'
                    nome='Profissão'
                    campoDeDados='profissao'
                    renderizar={item => item.profissao ? item.profissao : ''}
                  />
                  <Coluna
                    icone='icon-graduation-cap'
                    nome='Especialidade'
                    campoDeDados='especialidades'
                    renderizar={item => item.especialidades ? item.especialidades.join(', ') : ''}
                  />
                  <Coluna
                    icone='icon-checked'
                    nome='Ativo'
                    renderizar={item => item.ativo ? 'Sim' : 'Não'}
                  />
                  <Coluna
                    className='table-items-expanded-100__box-item'
                    campoDeDados='observacoes'
                    nome='Observações'
                    renderizar={item => <pre>{item.observacoes}</pre>}
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}