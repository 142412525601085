import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_OPERADORAS,
  LISTOU_OPERADORAS,
  ERRO_AO_LISTAR_OPERADORAS,
  ADICIONOU_OPERADORA,
  ERRO_AO_ADICIONAR_OPERADORA,
  ADICIONAR_OPERADORA,
  RECUPEROU_OPERADORA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  ALTEROU_OPERADORA,
  ERRO_AO_ALTERAR_OPERADORA,
  ALTERAR_OPERADORA,
  REMOVEU_OPERADORA,
  ERRO_AO_REMOVER_OPERADORA,
  REMOVER_OPERADORA,
  LISTOU_OPERADORAS_DO_CADASTRO,
  LISTAR_OPERADORAS_DO_CADASTRO,
  ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO
} from './tipos'

const listouOperadoras = operadoras => ({
  type: LISTOU_OPERADORAS,
  operadoras,
})

const erroAoListarOperadoras = erro => ({
  type: ERRO_AO_LISTAR_OPERADORAS,
  erro,
})

export const listarOperadoras = () => async dispatch => {
  dispatch({ type: LISTAR_OPERADORAS })

  try {
    let resultado = await api.listarOperadoras()

    dispatch(listouOperadoras(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOperadoras(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as operadoras.')))
  }
}

const erroAoListarOperadorasDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO,
  erro,
  parametros
})

const listouOperadorasDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_OPERADORAS_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

export const listarOperadorasDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_OPERADORAS_DO_CADASTRO })

  try {
    const resultado = await api.listarOperadoras(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouOperadorasDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarOperadorasDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as operadoras.')))
  }
}

const adicionouOperadora = operadora => ({
  type: ADICIONOU_OPERADORA,
  operadora
})

const erroAoAdicionarOperadora = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_OPERADORA,
  erro,
  parametros
})

export const adicionarOperadora = operadora => async dispatch => {
  dispatch({ type: ADICIONAR_OPERADORA })

  try {
    const resultado = await api.adicionarOperadora(operadora)

    dispatch(adicionouOperadora(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarOperadora(erro, { operadora }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar a operadora.')))

    return false
  }
}

const recuperouOperadoraPeloIdentificador = operadora => ({
  type: RECUPEROU_OPERADORA_PELO_IDENTIFICADOR,
  operadora
})

const erroAoRecuperarOperadoraPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarOperadoraPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_OPERADORA_PELO_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarOperadoraPeloIdentificador(identificador)

    dispatch(recuperouOperadoraPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarOperadoraPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar a operadora por identificador.')))
  }
}

const alterouOperadora = operadora => ({
  type: ALTEROU_OPERADORA,
  operadora
})

const erroAoAlterarOperadora = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_OPERADORA,
  erro,
  parametros
})

export const alterarOperadora = operadora => async dispatch => {
  dispatch({ type: ALTERAR_OPERADORA })

  try {
    const resultado = await api.alterarOperadora(operadora)

    dispatch(alterouOperadora(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarOperadora(erro, { operadora }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a operadora.')))

    return false
  }
}

const removeuOperadora = operadora => ({
  type: REMOVEU_OPERADORA,
  operadora
})

const erroAoRemoverOperadora = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_OPERADORA,
  erro,
  parametros
})

export const removerOperadora = identificador => async dispatch => {
  dispatch({ type: REMOVER_OPERADORA })

  try {
    const resultado = await api.removerOperadora(identificador)

    dispatch(removeuOperadora(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverOperadora(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover a operadora.')))

    return false
  }
}