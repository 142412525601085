import React, { useState, useEffect, useMemo } from 'react'
import AutenticacaoPassoUm from './autenticacao-passo-um'
import { AutenticacaoPassoDois, ListaDeUnidades } from './unidades/lista'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'

export default function Login({
  logotipos,
  autenticarUsuario,
  listarEmpresasHabilitadasParaOLogin,
  history,
  location,
  recuperarFotoDaEmpresaPorIdentificadorPublico,
  removerImagemDaMemoriaPorIdentificador }) {
  const [credenciais, setCredenciais] = useState(null)
  const [empresas, setEmpresas] = useState([])
  const [unidadeSelecionada, setUnidadeSelecionada] = useState(null)
  const { logout } = useUsuarioLogado()


  useEffect(() => {
    (async () => {
      if (!credenciais || !unidadeSelecionada) return

      const dados = {
        ...credenciais,
        empresa: unidadeSelecionada.empresa.identificador,
        unidade: unidadeSelecionada.identificador,
      }
      const autenticou = await autenticarUsuario(dados)

      if (autenticou) {
        history.push('/')
      }
    })()
  }, [credenciais, unidadeSelecionada, autenticarUsuario, history, location.pathname])

  useEffect(() => {
    (async () => {
      if (!credenciais) return

      const retorno = await listarEmpresasHabilitadasParaOLogin(credenciais.email, credenciais.senha)

      if (!retorno) {
        setCredenciais(null)
        return
      }

      if (retorno.length === 1 && retorno[0].unidades.length === 1) {
        setUnidadeSelecionada({
          empresa: { identificador: retorno[0].identificador },
          identificador: retorno[0].unidades[0].identificador,
        })
      } else {
        setEmpresas(retorno)
      }
    })()
  }, [credenciais, listarEmpresasHabilitadasParaOLogin])

  const unidades = useMemo(() => recuperarUnidades(empresas), [empresas])

  const voltarParaPassoUm = () => {
    logout()
    setEmpresas([])
  }

  return (
    <>
      {!empresas || empresas.length === 0 ?
        (() => (
          <AutenticacaoPassoUm autenticar={setCredenciais} />
        ))() :
        (() => (
          <AutenticacaoPassoDois email={credenciais.email}>
            <ListaDeUnidades
              selecionar={setUnidadeSelecionada}
              selecionada={unidadeSelecionada}
              unidades={unidades}
              voltar={voltarParaPassoUm}
              recuperarFotoDaEmpresaPorIdentificadorPublico={recuperarFotoDaEmpresaPorIdentificadorPublico}
              removerImagemDaMemoriaPorIdentificador={removerImagemDaMemoriaPorIdentificador}
              logotipos={logotipos}
            />
          </AutenticacaoPassoDois>
        ))()
      }
    </>
  )
}

const recuperarUnidades = (empresas = []) => empresas.reduce((acumulado, atual) => acumulado.concat(atual.unidades.map(unidade => ({
  ...unidade,
  empresa: {
    identificador: atual.identificador,
    identificadorPublico: atual.identificadorPublico,
    nome: atual.nome,
    logotipo: atual.logotipo
  },
}))), [])