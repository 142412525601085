import React from 'react'
import { InputSugestao } from '../../../formik/formulario'
import DicaDePesquisa from '../../../dica-de-pesquisa'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import { ehVerdadeiro } from '../../../../bibliotecas/texto';

export default function Avaliacao({ metadados, leitura, valor, tokensDeSugestao = {}, validacaoAoFinalizar = false }) {
  const obrigatorio = ehVerdadeiro(metadados?.obrigatorio)
  const obrigatorioAoFinalizar = ehVerdadeiro(metadados?.obrigatorio_ao_finalizar)
  const rotulo = metadados?.rotulo ? metadados.rotulo : 'Avaliação'

  if (leitura) {
    if (!valor && obrigatorioAoFinalizar) return <li>{rotulo} é obrigatório ao finalizar o atendimento.</li>
    if (!valor || validacaoAoFinalizar) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>{rotulo}</strong>
            <br />
            <div className='font-react-menstion'>{valor}</div>
          </pre>
        </div>
      </r-cell>
    )
  }

  const validar = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  const { dadosDaSugestao, usuariosDaSugestao } = tokensDeSugestao

  return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <DicaDePesquisa dica='Utilize @ ou @@ para menções.' exemplo='@@paciente'>
          <InputSugestao
            as='textarea'
            nome='avaliacao'
            titulo={`${rotulo} ${obrigatorioAoFinalizar ? '**' : (obrigatorio ? '*' : '')}`}
            validar={validar}
            dados={dadosDaSugestao}
            dadosUsuarios={usuariosDaSugestao}
            minHeight={300}
          />
        </DicaDePesquisa>
      </r-cell>
  )
}