import React, { createContext, useContext } from 'react'

const ContextoDoTarefas = createContext({})

export const ProvedorDeContextoDoTarefas = ({children, ...rest}) => {
  return <ContextoDoTarefas.Provider  value={{ ...rest }}>{children}</ContextoDoTarefas.Provider>
}

export const useTarefas = () => {
  return useContext(ContextoDoTarefas)
}