import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
  ADICIONAR_FERIADO,
  ADICIONOU_FERIADO,
  ERRO_AO_ADICIONAR_FERIADO,
  RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  RECUPEROU_FERIADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  SALVAR_FERIADO,
  SALVOU_FERIADO,
  ERRO_AO_SALVAR_FERIADO,
  LISTAR_FERIADOS,
  LISTOU_FERIADOS,
  ERRO_AO_LISTAR_FERIADOS,
  EXCLUIR_FERIADO,
  EXCLUIU_FERIADO,
  ERRO_AO_EXCLUIR_FERIADO,
  SALVAR_SERIE_DE_FERIADOS,
  ERRO_AO_SALVAR_SERIE_DE_FERIADOS,
  SALVOU_SERIE_DE_FERIADOS,
  EXCLUIU_SERIE_DE_FERIADOS,
  ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS,
  EXCLUIR_SERIE_DE_FERIADOS,
} from './tipos'

const adicionouFeriado = feriado => ({
  type: ADICIONOU_FERIADO,
  feriado,
})

const erroAoAdicionarFeriado = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_FERIADO,
  erro,
  parametros
})

export const adicionarFeriado = feriado => async dispatch => {
  dispatch({ type: ADICIONAR_FERIADO })

  try {
    const resultado = await api.adicionarFeriado(feriado)

    dispatch(adicionouFeriado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarFeriado(erro, { feriado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar feriado.')))

    return false
  }
}

const recuperouFeriadoPorIdentificador = feriado => async dispatch => {
  dispatch({ type: RECUPEROU_FERIADO_POR_IDENTIFICADOR, feriado })
}

const erroAoRecuperarFeriadoPorIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarFeriadoPorIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_FERIADO_POR_IDENTIFICADOR })

  try {
    const resultado = await api.recuperarFeriadoPorIdentificador(identificador)

    dispatch(recuperouFeriadoPorIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarFeriadoPorIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o feriado por identificador.')))
  }
}

const salvouFeriado = feriado => ({
  type: SALVOU_FERIADO,
  feriado,
})

const erroAoSalvarFeriado = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_FERIADO,
  erro,
  parametros
})

export const salvarFeriado = feriado => async dispatch => {
  dispatch({ type: SALVAR_FERIADO })

  try {
    const resultado = await api.salvarFeriado(feriado)

    dispatch(salvouFeriado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarFeriado(erro, { feriado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar feriado.')))

    return false
  }
}

const salvouSerieDeFeriados = feriado => ({
  type: SALVOU_SERIE_DE_FERIADOS,
  feriado,
})

const erroAoSalvarSerieDeFeriados = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_SERIE_DE_FERIADOS,
  erro,
  parametros
})

export const salvarSerieDeFeriados = feriado => async dispatch => {
  dispatch({ type: SALVAR_SERIE_DE_FERIADOS })

  try {
    const resultado = await api.salvarSerieDeFeriados(feriado)

    dispatch(salvouSerieDeFeriados(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoSalvarSerieDeFeriados(erro, { feriado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar a série de feriados.')))

    return false
  }
}

const listouFeriados = (filtros, ordenacao, paginaDeDados) => ({
  type: LISTOU_FERIADOS,
  filtros,
  ordenacao,
  paginaDeDados
})

const erroAoListarFeriados = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_FERIADOS,
  erro,
  parametros
})

export const listarFeriados = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_FERIADOS })

  try {
    const resultado = await api.listarFeriados(filtros, pagina, ordenacao, tamanhoDaPagina)

    dispatch(listouFeriados(filtros, ordenacao, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarFeriados(erro, { filtros, pagina, ordenacao, tamanhoDaPagina }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os feriados.')))
  }
}

const excluiuFeriado = identificador => ({
  type: EXCLUIU_FERIADO,
  identificador,
})

const erroAoExcluirFeriado = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_FERIADO,
  erro,
  parametros
})

export const excluirFeriado = identificador => async dispatch => {
  dispatch({ type: EXCLUIR_FERIADO })

  try {
    const resultado = await api.excluirFeriado(identificador)

    dispatch(excluiuFeriado(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirFeriado(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível excluir o feriado.')))

    return false
  }
}

const excluiuSerieDeFeriados = identificador => ({
  type: EXCLUIU_SERIE_DE_FERIADOS,
  identificador,
})

const erroAoExcluirSerieDeFeriados = (erro, parametros) => ({
  type: ERRO_AO_EXCLUIR_SERIE_DE_FERIADOS,
  erro,
  parametros
})

export const excluirSerieDeFeriados = identificador => async dispatch => {
  dispatch({ type: EXCLUIR_SERIE_DE_FERIADOS })

  try {
    const resultado = await api.excluirSerieDeFeriados(identificador)

    dispatch(excluiuSerieDeFeriados(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoExcluirSerieDeFeriados(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível excluir a série de feriados.')))

    return false
  }
}