import React, { useState } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import { formatarDataParaFormatoDiaDaSemana } from '../../../../bibliotecas/data'
import ConfirmacaoDeExclusao from '../../../usuarios/agenda/bloqueio/confirmacao-de-exclusao'
import FormularioBloqueio from '../../../usuarios/agenda/bloqueio/formulario'

const estiloDoModalPopup = {
  content: {
    top: '25%',
    left: '35%',
    right: 'auto',
    bottom: 'auto',
    width: '400px',
    minHeight: '300px',
    padding: '10px',
  }
}

export default function EventoBloqueio(props) {
  const {
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    itens,
    motivosDeBloqueio,
    permissoes,
    profissional
  } = props

  const [exibirPopup, setExibirPopup] = useState(false)

  if (!itens || itens.length === 0) return null

  const aoClicarEvento = (evento) => {
    setExibirPopup(true)
    if (evento.stopPropagation) evento.stopPropagation()
  }

  return (
    <>
      {itens.map((item, index) =>
        <React.Fragment key={`${index}_${item.inicio}`}>
          <div
            key={`${index}_${item.inicio}`}
            className='box-agenda__block-event'
            style={{
              top: `calc(${item.posicionamento.top}px)`,
              height: `calc(${item.posicionamento.height}px)`,
              left: 'auto',
              right: '0'
            }}
            onClick={aoClicarEvento}
          >
            <p className='box-agenda__block-event__time'>{`${item.inicio} - ${item.fim}`}</p>
            <p className='box-agenda__block-event__local'>{item.label}</p>
          </div>

          <Modal
            isOpen={exibirPopup}
            style={estiloDoModalPopup}
            contentLabel='Example Modal'
          >
            <RenderizarPopupBloqueio
              alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              bloqueio={item}
              excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
              motivosDeBloqueio={motivosDeBloqueio}
              fechar={() => setExibirPopup(false)}
              permitirAlterarBloqueioNaAgenda={permissoes.alterarBloqueioNaAgenda}
              permitirExcluirBloqueioNaAgenda={permissoes.excluirBloqueiosNaAgenda}
              profissional={profissional.identificador}
            />
          </Modal>
        </React.Fragment>
      )}
    </>
  )
}

function RenderizarPopupBloqueio(props) {
  const {
    alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    bloqueio,
    excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento,
    motivosDeBloqueio,
    fechar,
    permitirAlterarBloqueioNaAgenda,
    permitirExcluirBloqueioNaAgenda,
    profissional,
  } = props

  const OUTRO_MOTIVO = 'outro'
  const bloqueioNoPassado = moment().isAfter(moment(bloqueio.data), 'days')
  const descricaoDoBloqueio = `${formatarDataParaFormatoDiaDaSemana(bloqueio.data)}, ${moment(bloqueio.data).format('D [de] MMMM [de] YYYY')}`
  const horarioBloqueio = `${bloqueio.inicio} até ${bloqueio.fim}`

  const [exibirModalBloqueio, setExibirModalBloqueio] = useState(false)
  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)

  const excluirBloqueio = async identificadorDoBloqueio => {
    const excluiu = await excluirBloqueioDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(profissional, identificadorDoBloqueio)

    if (excluiu) {
      fecharAlertaDeExclusao()
      fecharBloqueio()
    }
  }

  const excluirSerieDeBloqueio = async identificadorDoBloqueio => {
    const excluiu = await excluirSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento(profissional, identificadorDoBloqueio)

    if (excluiu) {
      fecharAlertaDeExclusao()
      fecharBloqueio()
    }
  }

  function abrirBloqueio() {
    setExibirModalBloqueio(true)
  }

  function fecharBloqueio() {
    setExibirModalBloqueio(false)
  }

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={excluirBloqueio}
          excluirSerie={excluirSerieDeBloqueio}
          fechar={fecharAlertaDeExclusao}
          bloqueio={bloqueio}
          motivos={motivosDeBloqueio}
        />
      </Modal>
      <Modal
        isOpen={exibirModalBloqueio}
        className='modal'
        contentLabel='Example Modal'
      >
        <FormularioBloqueio
          alterar={alterarBloqueioNaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
          alterarSerie={alterarSerieDeBloqueiosDaAgendaDoProfissionalDeSaudeSelecionadoDoAgendamento}
          bloqueio={bloqueio}
          fechar={fecharBloqueio}
          aposFechar={fechar}
          identificadorDoProfissional={profissional}
          motivosDeBloqueio={motivosDeBloqueio}
        />
      </Modal>
      <div className='box-agenda__drop__header'>
        <div />
        <div className='box-agenda__drop__actions'>
          {permitirAlterarBloqueioNaAgenda && !bloqueioNoPassado &&
            <button className='box-agenda__drop__bt-edit' onClick={abrirBloqueio}>
              <i className='icon icon-pencil'></i>
            </button>
          }
          {permitirExcluirBloqueioNaAgenda && !bloqueioNoPassado &&
            <button className='box-agenda__drop__bt-remove' onClick={() => abrirAlertaDeExclusao()}>
              <i className='icon icon-remove'></i>
            </button>
          }
          <button className='box-agenda__drop__bt-close' onClick={fechar}><i className='icon icon-close'></i></button>
        </div>
      </div>
      <div className='box-agenda__drop__body'>
        <h4 className='box-agenda__drop__title'>Detalhes do Bloqueio</h4>
        <time className='box-agenda__drop__time'>
          <span>{descricaoDoBloqueio}</span>
          <span>{horarioBloqueio}</span>
        </time>
        <div className='box-agenda__drop__description'>
          {bloqueio.motivo.codigo !== OUTRO_MOTIVO && <p><strong>Motivo:</strong> {bloqueio.motivo}</p>}
          {bloqueio.observacoes && <p><strong>Observações:</strong> {bloqueio.observacoes}</p>}
        </div>
      </div>
    </>
  )
}