import {
  LISTOU_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO
} from '../../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_SITUACOES_ASSOCIADAS_A_DOR_NO_PEITO: {
      return action.situacoesDorPeito
    }

    default: {
      return state
    }
  }
}
