import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import {
  RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
} from '../../acoes/tipos'

const estadoInicial = {}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO: {
      let novosTiposDeAtendimento = {}

      action.tiposDeAtendimento.forEach(tipoDoUsuario => {
        novosTiposDeAtendimento[tipoDoUsuario.tipo.nome] = {
          identificador: tipoDoUsuario.tipo.identificador,
          nome: tipoDoUsuario.tipo.nome,
          metaDados: tipoDoUsuario.tipo.metaDados,
          ultimaVersao: tipoDoUsuario.tipo.versao,
          privado: tipoDoUsuario.privado,
          versoes: {},
        }
      })

      return {
        ...novosTiposDeAtendimento,
      }
    }

    case RECUPEROU_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR: {
      const tipo = action.tipoDeAtendimento
      let versoes = {}

      tipo.versoes.forEach(versao => {
        versoes[versao.numero] = versao
      })

      return {
        ...state,
        [tipo.nome]: {
          identificador: tipo.identificador,
          nome: tipo.nome,
          versoes,
        }
      }
    }

    case RECUPEROU_VERSAO_DO_TIPO_DE_ATENDIMENTO: {
      let tipo = converterObjetoParaArray(state).find(item => item.identificador === action.identificador)

      if (!tipo) {
        return state
      }

      tipo.versoes = {
        ...tipo.versoes,
        [action.versao.numero]: action.versao
      }

      return {
        ...state,
        [tipo.nome]: tipo,
      }
    }

    default: {
      return state
    }
  }
}