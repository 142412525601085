import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Input } from '../../../formik/formulario'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { formatarDataParaFormatoLocal, formatarHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import { ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function ConfirmarMedicamento({
  alterarStatusDoMedicamentoDoPaciente,
  fechar,
  identificadorDoPaciente,
  medicamento: {
    identificador,
    medicamento,
    concentracao,
    outroMedicamentoEConcentracao,    
    frequencia,
    dataEHora,
    usuario,
    observacoes
  }
}) {
  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_MEDICAMENTO_DO_PACIENTE])
  const podeRemover = usePossuiAsPermissoes([permissoes.REMOVER_MEDICAMENTO_DO_PACIENTE])
  const podeRejeitar = usePossuiAsPermissoes([permissoes.REJEITAR_MEDICAMENTO_DO_PACIENTE])

  const alterarStatusDoMedicamento = async (formulario, operacao) => {
    const dados = {
      ...formulario,
      operacao,
    };
  
    const alterou = await alterarStatusDoMedicamentoDoPaciente(identificadorDoPaciente, identificador, dados)
  
    if (alterou) {
      fechar()
    }
  };
  
  const confirmarMedicamentoDoPaciente = async formulario => {
    await alterarStatusDoMedicamento(formulario, 'confirmar')
  }
  
  const rejeitarMedicamentoDoPaciente = async formulario => {
    await alterarStatusDoMedicamento(formulario, 'rejeitar')
  }
  
  const removerMedicamentoDoPaciente = async formulario => {
    await alterarStatusDoMedicamento(formulario, 'remover')
  }

  return (
    <Spinner operacoes={[ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          observacoes: ''
        }}
      >
        {({ values }) => (
          <fieldset>
            <h2 className='form-title'>Detalhes do Medicamento</h2>
            <div className='form-details'>
              <p><strong>Medicamento/Dosagem:</strong>{' '}{medicamento ? `${medicamento.nome} - ${concentracao.valor}` : outroMedicamentoEConcentracao}</p>
              <p><strong>Posologia:</strong>{' '}{frequencia}</p>
              <p><strong>Usuário:</strong>{' '}{usuario.nome}</p>
              <p><strong>Data:</strong>{' '}{formatarDataParaFormatoLocal(dataEHora)}</p>
              <p><strong>Hora:</strong>{' '}{formatarHoraParaFormatoLocal(dataEHora)}</p>
            </div>
            <r-grid columns-md={6} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  tabIndex={1}
                  nome='observacoes'
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light minw-auto' onClick={() => fechar()}>Fechar</button>
              {podeRejeitar && <button type='button' className='button --danger minw-auto' onClick={() => rejeitarMedicamentoDoPaciente(values)}>Rejeitar</button>}
              {podeRemover && <button type='button' className='button --danger minw-auto' onClick={() => removerMedicamentoDoPaciente(values)}>Remover</button>}
              {podeConfirmar && <button type='button' className='button --primary minw-auto' onClick={() => confirmarMedicamentoDoPaciente(values)}>Confirmar</button>}
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}