import axios from 'axios'
import configuracoes from '../configuracoes/constantes'

const api = axios.create({
  baseURL: configuracoes.urlDoViaCep
})

const recuperarEnderecoPorCep = cep => {
  let configuracao = {
    transformRequest: (data, headers) => {
      delete headers.common.Authorization
      return data
    }
  }

  return api.get(`/${cep}/json`, configuracao)
}

export default {
  recuperarEnderecoPorCep
}
