import { LISTOU_INTENSIDADES_DAS_DORES } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_INTENSIDADES_DAS_DORES: {
      return action.intensidadesDasDores
    }

    default: {
      return state
    }
  }
}