import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import moment from 'moment'
import { formatarDataParaFormatoDiaDaSemana } from '../../../../bibliotecas/data'
import BoxAgendaOver from '../box-agenda-over'
import ConfirmacaoDeExclusao from '../../../usuarios/agenda/instrucao/confirmacao-de-exclusao'
import FormularioInstrucao from '../../../usuarios/agenda/instrucao/formulario'

const estiloDoModalPopup = {
  content: {
    top: '25%',
    left: '35%',
    right: 'auto',
    bottom: 'auto',
    width: '400px',
    minHeight: '300px',
    padding: '10px',
  }
}

export default function EventoInstrucao(props) {
  const {
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    itens,
    permissoes,
    profissional,
    temAgenda
  } = props

  const [indexInstrucao, setIndexInstrucao] = useState(null)
  const [exibirPopup, setExibirPopup] = useState(false)

  if (!itens || itens.length === 0) return null

  function ordenarHorarioFinal(a, b) {
    return a.fim.localeCompare(b.fim)
  }

  itens.sort(ordenarHorarioFinal)
  const item = indexInstrucao !== null && itens[indexInstrucao]

  const aoClicarEvento = (evento, index) => {
    setIndexInstrucao(index)
    setExibirPopup(true)

    if (evento.stopPropagation) evento.stopPropagation()
  }

  const dividirComAgenda = temAgenda ? 2 : 1

  function calculaLarguraBox(index) {
    const left = calculaPosicaoLeft(index)
    return (95 - left) / dividirComAgenda
  }

  function calculaPosicaoLeft(index) {
    const tamanhoSlotDoEvento = 92 / itens.length
    const valor = index * tamanhoSlotDoEvento / dividirComAgenda

    return valor
  }

  return (
    <>
      {itens.map((item, index) =>
        <React.Fragment key={index}>
          <BoxAgendaOver>
            {({ mouseOverStyle }) =>
              <div
                className={`box-agenda__event ${mouseOverStyle}`}
                key={`instrucao.${index}.${item.identificador}`}
                data-tip
                data-for={`info_${item.identificador}`}
                style={{
                  backgroundColor: item.cor.codigo,
                  top: `calc(${item.posicionamento.top}px)`,
                  height: `calc(${item.posicionamento.height}px)`,
                  width: `calc(${calculaLarguraBox(index)}%)`,
                  left: `calc(${calculaPosicaoLeft(index)}%)`,
                  right: 'auto'
                }}
                onClick={(evento) => aoClicarEvento(evento, index)}
              >
                <div className={`box-agenda__event__info`}>
                  <p className={`box-agenda__event__time`} style={{ color: '#FFFFFF' }}>{`${item.inicio} - ${item.fim}`}</p>
                  <p className={`box-agenda__event__local`} style={{ color: '#FFFFFF' }}>{item.label}</p>
                </div>
              </div>
            }
          </BoxAgendaOver>
        </React.Fragment>
      )}
      {item &&
        <>
          <ReactTooltip
            id={`info_${item.identificador}`}
            place='top'
            effect='solid'
            type='info'
            key={item.identificador}
          >
            <span>{item.inicio} - {item.fim} - {item.label}</span>
          </ReactTooltip>
          <Modal
            isOpen={exibirPopup}
            style={estiloDoModalPopup}
            contentLabel='Example Modal'
          >
            <RenderizarPopupInstrucao
              alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento={excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
              fechar={() => setExibirPopup(false)}
              instrucao={item}
              permitirAlterarInstrucaoNaAgenda={permissoes.alterarInstrucaoDeAgendamento}
              permitirExcluirInstrucaoNaAgenda={permissoes.excluirInstrucaoDeAgendamento}
              profissional={profissional.identificador}
            />
          </Modal>
        </>
      }
    </>
  )
}

function RenderizarPopupInstrucao(props) {
  const {
    alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento,
    instrucao,
    fechar,
    permitirAlterarInstrucaoNaAgenda,
    permitirExcluirInstrucaoNaAgenda,
    profissional,
  } = props

  const instrucaoNoPassado = moment().isAfter(moment(instrucao.data), 'days')
  const descricaoDaInstrucao = `${formatarDataParaFormatoDiaDaSemana(instrucao.data)}, ${moment(instrucao.data).format('D [de] MMMM [de] YYYY')}`
  const horarioDaInstrucao = `${instrucao.inicio} até ${instrucao.fim}`

  const [exibirModalInstrucao, setExibirModalInstrucao] = useState(false)
  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)

  const excluirInstrucao = async identificadorDaInstrucao => {
    const excluiu = await excluirInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(profissional, identificadorDaInstrucao)

    if (excluiu) {
      fecharAlertaDeExclusao()
      fecharInstrucao()
      fechar()
    }
  }

  const excluirSerieDeInstrucao = async identificadorDaInstrucao => {
    const excluiu = await excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento(profissional, identificadorDaInstrucao)

    if (excluiu) {
      fecharAlertaDeExclusao()
      fecharInstrucao()
      fechar()
    }
  }

  function abrirInstrucao() {
    setExibirModalInstrucao(true)
  }

  function fecharInstrucao() {
    setExibirModalInstrucao(false)
  }

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={excluirInstrucao}
          excluirSerie={excluirSerieDeInstrucao}
          fechar={fecharAlertaDeExclusao}
          instrucao={instrucao}
        />
      </Modal>
      <Modal
        isOpen={exibirModalInstrucao}
        className='modal'
        contentLabel='Example Modal'
      >
        <FormularioInstrucao
          alterar={alterarInstrucaoDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          alterarSerie={alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaudeSelecionadoDoAgendamento}
          instrucao={instrucao}
          fechar={fecharInstrucao}
          aposFechar={fechar}
          identificadorDoProfissional={profissional}
        />
      </Modal>
      <div className="box-agenda__drop__header">
        <div />
        <div className="box-agenda__drop__actions">
          {permitirAlterarInstrucaoNaAgenda && !instrucaoNoPassado &&
            <button className="box-agenda__drop__bt-edit" onClick={abrirInstrucao}>
              <i className="icon icon-pencil"></i>
            </button>
          }
          {permitirExcluirInstrucaoNaAgenda && !instrucaoNoPassado &&
            <button className='box-agenda__drop__bt-remove' onClick={() => abrirAlertaDeExclusao()}>
              <i className="icon icon-remove"></i>
            </button>
          }
          <button className="box-agenda__drop__bt-close" onClick={fechar}><i className="icon icon-close"></i></button>
        </div>
      </div>
      <div className="box-agenda__drop__body">
        <h4 className="box-agenda__drop__title">Detalhes da Instrução</h4>
        <time className="box-agenda__drop__time">
          <span>{descricaoDaInstrucao}</span>
          <span>{horarioDaInstrucao}</span>
        </time>
        <div className="box-agenda__drop__description">
          <p><strong>Texto:</strong> {instrucao.texto}</p>
          {instrucao.observacoes && <p><strong>Observações:</strong> {instrucao.observacoes}</p>}
        </div>
      </div>
    </>
  )
}