import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'
import PacienteElegivel from './paciente-elegivel'
import Breadcrumb from '../breadcrumb'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../abas'

export default function PlanosDeCuidado(props) {
  const {
    adicionarPlanoDeCuidado,
    planoDeCuidado,
    filtros,
    history,
    listarPlanosDeCuidado,
    match,
    ordenacao,
    paginaDeDados,
    recuperarPlanoDeCuidadoPeloIdentificador,
    removerPlanoDeCuidado,
    alterarPlanoDeCuidado,
    powerBI,
    recuperarTokenDosRelatoriosDoPowerBI,
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
    }

    const acao = dados.identificador ? alterarPlanoDeCuidado : adicionarPlanoDeCuidado
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/planos-de-cuidado`)
    }
  }

  const remover = async item => {
    const removeu = await removerPlanoDeCuidado(item.identificador)

    if (removeu) {
      history.push(`/cadastros/planos-de-cuidado`)
    }
  }

  const voltar = () => {
    history.push(`/cadastros/planos-de-cuidado/lista`)
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_PLANOS_DE_CUIDADO]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarPlanosDeCuidado}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <div className='container'>
              <div className='main-content-form'>
                <div className='header-page-form'>
                  <Breadcrumb />
                </div>
                <Permissao permissoes={[permissoes.ADICIONAR_PLANO_DE_CUIDADO]}>
                  <Formulario
                    {...props}
                    salvar={salvar}
                    planoDeCuidado={planoDeCuidado}
                    voltar={voltar}
                  />
                </Permissao>
              </div>
            </div>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <div className='container'>
              <div className='main-content-form'>
                <div className='header-page-form'>
                  <Breadcrumb />
                </div>
                <Permissao permissoes={[permissoes.ALTERAR_PLANO_DE_CUIDADO]}>
                  <Formulario
                    {...props}
                    recuperarPeloIdentificador={recuperarPlanoDeCuidadoPeloIdentificador}
                    salvar={salvar}
                    planoDeCuidado={planoDeCuidado}
                    remover={remover}
                    voltar={voltar}
                  />
                </Permissao>
              </div>
            </div>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/jornada-da-dor/:identificador`,
          `${match.path}/jornada-da-dor/:identificador/paciente-elegivel`,
        ]}        
        render={
          props => {
            const identificador = props.match.params.identificador
            return (
              <div className='container'>
                <div className='main-content-form'>
                  <div className='header-page-form'>
                    <Breadcrumb />
                  </div>
                  <Abas>
                    <div className='header-page-bar mt-0'>
                      <div className='header-page-nav'>
                        <ListaDeAbas className='tab'>
                          <Aba className='tab-item' aoClicar={() => identificador ? history.push(`${match.url}/jornada-da-dor/${identificador}`) : ''}>
                            Dados Gerais
                          </Aba>
                          <Aba className={`tab-item ${identificador ? '' : 'disabled'}`} aoClicar={() => history.push(`${match.url}/jornada-da-dor/${identificador}/paciente-elegivel`)}>
                            Paciente Elegível
                          </Aba>
                        </ListaDeAbas>
                      </div>
                    </div>
                    <Paineis>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/jornada-da-dor/:identificador`}
                            render={
                              props => (
                                <Permissao permissoes={[permissoes.ALTERAR_PLANO_DE_CUIDADO]}>
                                  <div className='mt-2'>
                                    <Formulario
                                      {...props}
                                      recuperarPeloIdentificador={recuperarPlanoDeCuidadoPeloIdentificador}
                                      salvar={salvar}
                                      planoDeCuidado={planoDeCuidado}
                                      remover={remover}
                                      voltar={voltar}
                                    />
                                  </div>
                                </Permissao>
                              )
                            }
                          />
                        </Switch>
                      </Painel>
                      <Painel>
                        <Switch>
                          <RotaComAutenticacao
                            exact
                            path={`${match.path}/jornada-da-dor/:identificador/paciente-elegivel`}
                            render={() => 
                              <PacienteElegivel 
                                powerBI={powerBI}
                                recuperarTokenDosRelatoriosDoPowerBI={recuperarTokenDosRelatoriosDoPowerBI}
                                voltar={voltar}
                              />
                            }
                          />
                        </Switch>
                      </Painel>
                    </Paineis>
                  </Abas>
                </div>
              </div>
            )
          }
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}