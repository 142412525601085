import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Breadcrumb from '../breadcrumb'
import Modal from 'react-modal'
import CampoDePesquisa from '../campo-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import { formatarDataParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../bibliotecas/data'

import { LISTAR_PEDIDOS_DE_CONTATO, REMOVER_PEDIDO_DE_CONTATO } from '../../acoes/tipos'

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar,
    listarPlanosDeSaude,
    planosDeSaude,
    removerPedidoDeContato
  } = props

  const [ordenacao, setOrdenacao] = useState()
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState()
  const [selecionada, setSelecionada] = useState(null)
  const [exibirExcluir, setExibirExcluir] = useState(false)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao, 10))()
  }, [filtros, pesquisar, pagina, ordenacao])

  useEffect(() => { listarPlanosDeSaude() }, [listarPlanosDeSaude])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = nome => alterarFiltros({ ...filtros, nome })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor
    })
  }

  const excluirPedidoDeContato = async () => {
    const excluiu = await removerPedidoDeContato(selecionada.identificador)
    if (excluiu) {
      fecharExcluir()
      pesquisar(filtros, pagina, ordenacao, 10)
    }
  }

  function abrirExcluir(item) {
    setSelecionada(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionada) {
      setSelecionada(null)
    }
  }

  function recuperarPlanoDeSaude(item) {
    return planosDeSaude.find(x => x.codigo === item).nome
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
            <div className='header-page-tools'>
              <CampoDePesquisa
                pesquisar={alterarTermo}
                valor={filtros ? filtros.nome : ''}
              />
            </div>
          </div>
          <Modal
            isOpen={exibirExcluir}
            contentLabel='Modal para remoção de pedidos de contato'
            className='modal-vacinas'
            style={{ content: { maxWidth: '400px' } }}
          >
            <Remover
              selecionada={selecionada}
              remover={excluirPedidoDeContato}
              fechar={fecharExcluir}
            />
          </Modal>
          <Spinner operacoes={[LISTAR_PEDIDOS_DE_CONTATO]}>
            <Tabela
              acoes={[
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Informações</strong></div>
                      <span>Solicitante: {item.nomeDoSolicitante}</span> <br />
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>,
                item => (
                  <button
                    className='table-items__bt --remove'
                    title='Remover'
                    onClick={() => abrirExcluir(item)}
                    type='button'
                    key={`remove_${item.identificador}`}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                ),
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                campoDeDados='nomeDoPaciente'
                className='col-xs-3 col-lg-3'
                exibirOrdenacao={true}
                principal={true}
                nome='Nome'
              />
              <Coluna
                campoDeDados='sexo'
                className='col-xs-1 col-lg-1'
                nome='Sexo'
              />
              <Coluna
                className='col-xs-2 col-lg-2'
                nome='Data de Nascimento'
                renderizar={item => formatarDataParaFormatoLocal(item.dataDeNascimento)}
              />
              <Coluna
                campoDeDados='telefone'
                className='col-xs-2 col-lg-2'
                nome='Telefone'
              />
              <Coluna
                className='col-xs-3 col-lg-3'
                nome='Plano de saúde'
                renderizar={item => recuperarPlanoDeSaude(item.planoDeSaude)}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

function Remover({ selecionada, remover, fechar }) {
  return (
    <Spinner operacoes={[REMOVER_PEDIDO_DE_CONTATO]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                {selecionada && <h2>{`Tem certeza que deseja remover o pedido de contato?`}</h2>}
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={remover}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}