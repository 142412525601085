import React from 'react'
import * as Yup from 'yup'
import Spinner from '../../../spinner'
import { Formulario as Form, Select, Checkbox } from '../../../formik/formulario'
import { FAZER_DOWNLOAD_DO_ENCAMINHAMENTO } from '../../../../acoes/tipos'

export default function ImpressaoDoEncaminhamento(props) {
  const { fechar, tamanhosDePaginas, imprimir } = props

  const imprimirEncaminhamento = (formulario) => {
    imprimir(formulario.tamanhoDaPagina, formulario.adicionarData)
  }

  return (    
    <Spinner operacoes={[FAZER_DOWNLOAD_DO_ENCAMINHAMENTO]}>
      <Form
        valoresIniciais={{
          tamanhoDaPagina: 'A4',
          adicionarData: true,
        }}
        reinicializar={true}
        acao={imprimirEncaminhamento}
        esquemaDeValidacoes={Yup.object().shape({
          tamanhoDaPagina: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset>
          <h2 className='form-title'>Impressão do Encaminhamento</h2>
          <r-grid columns-md='6' columns-lg='12'>
            <r-cell span={4} span-md='row' span-lg='row'>
              <Select
                nome='tamanhoDaPagina'
                tabIndex={1}
                titulo='Tamanho da Página *'
                itens={tamanhosDePaginas}
                campoCodigo='codigo'
                campoDescricao='codigo'
              />
            </r-cell>            
            <r-cell span={4} span-md='row' span-lg='row'>
              <Checkbox
                nome='adicionarData'
                tabIndex={2}
                titulo='Adicionar Data'
              />
            </r-cell>            
          </r-grid>
          <div className='list-btn mt-4'>
            <button type='button' onClick={() => fechar()} className='button --light'>Sair</button>
            <button type='submit' className='button --primary'>Imprimir</button>
          </div>
        </fieldset>
      </Form>
    </Spinner>    
  )
}