import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, CampoNumerico } from '../../../formik/formulario'
import { deveSerInformado, deveSerUmValorEntre, ehUmNumeroDecimalValido } from '../../../../bibliotecas/validacoes'
import { formatarDataParaFormatoLocal } from '../../../../bibliotecas/data'
import Grafico from './grafico'
import GraficoPopup from '../grafico-popup'

const razaoDeveSerMenorQue20 = valor => {
  if (!ehUmNumeroDecimalValido(valor)) return
  valor = valor ? parseFloat(valor.toString().replace(',', '.')) : valor

  return valor >= 0 && valor <= 20 ? undefined : 'A relação proteína/creatinina deve estar entre [0 ; 20].'
}

export default function RelacaoProteinaCreatinina({ metadados, historico = [], valor, leitura, exibirDataExame, dataExame, setFieldValue }) {
  const ehExame = metadados?.exame === 'true'
  const ehExameComExibirDataExame = ehExame && exibirDataExame

  const algumCampoInformado = valor && (valor.proteinas || valor.creatinina || valor.data)
  const obrigatorio = (metadados?.obrigatorio === 'true') || (algumCampoInformado && !ehExameComExibirDataExame)

  useEffect(() => {
    if (exibirDataExame && (valor.proteinas || valor.creatinina)) {
      setFieldValue('relacaoProteinaCreatinina.data', dataExame)
    }
  }, [setFieldValue, dataExame, exibirDataExame, valor])

  const [razaoProteinaCreatinina, setRazaoProteinaCreatinina] = useState('')

  useEffect(() => {
    if (valor && valor.proteinas && valor.creatinina) {
      const proteinaPossuiInconsistencias = deveSerInformado(valor.proteinas) || deveSerUmValorEntre(valor.proteinas, 3, 600)
      const creatininaPossuiInconsistencias = deveSerInformado(valor.creatinina) || deveSerUmValorEntre(valor.creatinina, 3, 300)

      if (!creatininaPossuiInconsistencias && !proteinaPossuiInconsistencias) {
        setRazaoProteinaCreatinina(Math.round((valor.proteinas / valor.creatinina) * 100) / 100)
      } else {
        setRazaoProteinaCreatinina(null)
      }
    }
  }, [valor])

  const validarProteina = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 3, 600) ||
        razaoDeveSerMenorQue20(razaoProteinaCreatinina) ||
        deveSerInformado(valor)
      )
    }

    return (
      deveSerUmValorEntre(valor, 3, 600) ||
      razaoDeveSerMenorQue20(razaoProteinaCreatinina)
    )
  }

  const validarCreatinina = valor => {
    if (obrigatorio) {
      return (
        deveSerUmValorEntre(valor, 3, 300) ||
        razaoDeveSerMenorQue20(razaoProteinaCreatinina) ||
        deveSerInformado(valor)
      )
    }

    return (
      deveSerUmValorEntre(valor, 3, 300) ||
      razaoDeveSerMenorQue20(razaoProteinaCreatinina)
    )
  }

  const validarData = valor => {
    if (obrigatorio) {
      return deveSerInformado(valor)
    }
  }

  if (leitura) {
    if (!valor) return null

    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            <strong>Relação Proteína/Creatinina: </strong><br />
            Proteína: {valor.proteinas} mg/dL <br />
            Creatinina: {valor.creatinina} mg/dL <br />
            Razão: {valor.razao.toString().replace('.', ',')}  no dia {formatarDataParaFormatoLocal(valor.data)}
          </pre>
        </div>
      </r-cell>
    )
  }

  function calculaLarguraBox() {
    if (ehExameComExibirDataExame) return 4
    return 6
  }

  return (
    <r-cell span={4} span-md={calculaLarguraBox()} span-lg={calculaLarguraBox()}>
      <r-grid columns-md={calculaLarguraBox()} columns-lg={calculaLarguraBox()}>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='relacaoProteinaCreatinina.proteinas'
            titulo={`Proteína ${obrigatorio ? '*' : ''}`}
            escala=' mg/dL'
            validar={validarProteina}
            numeroDeCasasDecimais={2}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Relação Proteína / Creatinina'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        <r-cell span={4} span-md={2} span-lg={2}>
          <CampoNumerico
            nome='relacaoProteinaCreatinina.creatinina'
            titulo={`Creatinina ${obrigatorio ? '*' : ''}`}
            escala=' mg/dL'
            validar={validarCreatinina}
            numeroDeCasasDecimais={2}
          >
            {historico.length > 0 &&
              <GraficoPopup titulo='Relação Proteína / Creatinina'>
                <Grafico historico={historico} />
              </GraficoPopup>
            }
          </CampoNumerico>
        </r-cell>
        {!ehExameComExibirDataExame &&
          <r-cell span={4} span-md={2} span-lg={2}>
            <DatePicker
              nome='relacaoProteinaCreatinina.data'
              titulo={`Data ${obrigatorio ? '*' : ''}`}
              maxDate={moment().toDate()}
              validar={validarData}
            />
          </r-cell>
        }
      </r-grid>
    </r-cell>
  )
}