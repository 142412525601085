import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
  LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
  ERRO_AO_LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
} from './tipos'

const listouExecucoesDoTesteDeFisioterapia = execucoesDoTesteDeFisioterapia => ({
  type: LISTOU_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
  execucoesDoTesteDeFisioterapia,
})

const erroAoListarExecucoesDoTesteDeFisioterapia = erro => ({
  type: ERRO_AO_LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA,
  erro,
})

export const listarExecucoesDoTesteDeFisioterapia = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA })

    try {
      const resultado = await api.listarExecucoesDoTesteDeFisioterapia()

      dispatch(listouExecucoesDoTesteDeFisioterapia(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarExecucoesDoTesteDeFisioterapia(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as execuções do teste de fisioterapia.')))
    }
  }

  acao.meta = {
    cache: LISTAR_EXECUCOES_DO_TESTE_DE_FISIOTERAPIA
  }

  return acao
}