import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_TIPOS_DE_INTERNACAO,
  LISTOU_TIPOS_DE_INTERNACAO,
  ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO,
} from './tipos'

const listouTiposDeInternacao = tiposDeInternacao => ({
  type: LISTOU_TIPOS_DE_INTERNACAO,
  tiposDeInternacao
})

const erroAoListarTiposDeInternacao = erro => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO,
  erro
})

export const listarTiposDeInternacao = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_TIPOS_DE_INTERNACAO })

    try {
      const resultado = await api.listarTiposDeInternacao()

      dispatch(listouTiposDeInternacao(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarTiposDeInternacao(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de internação.')))
    }
  }

  acao.meta = {
    cache: LISTAR_TIPOS_DE_INTERNACAO
  }

  return acao
}