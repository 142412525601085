import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, DatePicker } from '../../../formik/formulario'
import { formatarDataParaFormatoUniversal } from '../../../../bibliotecas/data'
import moment from 'moment'
import {
    LISTAR_MOTIVOS_DE_INTERNACAO,
    LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
    LISTAR_TIPOS_DE_ATENDIMENTO,
    LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
    LISTAR_VACINAS,
    LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE
} from '../../../../acoes/tipos'

export default function Filtros({ filtros, fechar, filtrar, identificador, tiposDeAtendimento, profissoes, vacinas, motivosDeIdaAoProntoAtendimento, motivosDeInternacao }) {
    const pesquisar = async (valores, {resetForm}) => {
        const salvou = await filtrar(identificador, valores)

        if (salvou) {
            resetForm({})
            fechar()
        }
    }

    return (
        <Spinner operacoes={[
            LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE,
            LISTAR_TIPOS_DE_ATENDIMENTO,
            LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
            LISTAR_MOTIVOS_DE_INTERNACAO,
            LISTAR_VACINAS,
            LISTAR_PROFISSOES_PARA_TIMELINE_DO_PACIENTE
        ]}>
        <FormularioDoFormik
            acao={pesquisar}
            valoresIniciais={{
                inicio: formatarDataParaFormatoUniversal(filtros.inicio),
                fim: formatarDataParaFormatoUniversal(filtros.fim),
                tiposDeAtendimento: filtros.tiposDeAtendimento,
                profissoes: filtros.profissoes,
                vacinas: filtros.vacinas,
                motivosDeIdaAoProntoAtendimento: filtros.motivosDeIdaAoProntoAtendimento,
                motivosDeInternacao: filtros.motivosDeInternacao
            }}
        >
            {({values, initialValues}) => (
                <fieldset>
                    <r-grid columns-md={6} columns-lg={12} className='align-end'>
                        <r-cell span={4} span-md={3} span-lg={6}>
                            <DatePicker
                                nome='inicio'
                                tabIndex={1}
                                maxDate={moment(values.fim).toDate()}
                                titulo='Início'
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={6}>
                            <DatePicker
                                nome='fim'
                                tabIndex={2}
                                minDate={moment(values.inicio).toDate()}
                                maxDate={moment(initialValues.fim).toDate()}
                                titulo='Fim'
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={12}>
                            <Select
                                nome='tiposDeAtendimento'
                                campoCodigo='identificador'
                                campoDescricao='nome'
                                itens={tiposDeAtendimento}
                                tabIndex={3}
                                titulo='Tipos de Atendimento'
                                isMulti
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={12}>
                            <Select
                                nome='profissoes'
                                campoCodigo='identificador'
                                campoDescricao='nome'
                                itens={profissoes}
                                tabIndex={4}
                                titulo='Profissões'
                                isMulti
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={12}>
                            <Select
                                nome='vacinas'
                                campoCodigo='codigo'
                                campoDescricao='nome'
                                itens={vacinas}
                                tabIndex={5}
                                titulo='Vacinas'
                                isMulti
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={12}>
                            <Select
                                nome='motivosDeIdaAoProntoAtendimento'
                                campoCodigo='nome'
                                campoDescricao='nome'
                                itens={motivosDeIdaAoProntoAtendimento}
                                tabIndex={6}
                                titulo='Motivos de ida ao pronto atendimento'
                                isMulti
                            />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={12}>
                            <Select
                                nome='motivosDeInternacao'
                                campoCodigo='nome'
                                campoDescricao='nome'
                                itens={motivosDeInternacao}
                                tabIndex={7}
                                titulo='Motivos de internação'
                                isMulti
                            />
                        </r-cell>
                        <r-cell span={4} span-md={6} span-lg={12}>
                            <hr className='separator mt-1 mb-1'/>
                            <div className='list-btn'>
                                <button type='button' className='button --light' onClick={() => fechar()}>Fechar
                                </button>
                                <button type='submit' className='button --primary'>Filtrar</button>
                            </div>
                        </r-cell>
                    </r-grid>
                </fieldset>
            )}
        </FormularioDoFormik>
    </Spinner>
    )
}