import bloqueios from './bloqueios'
import feriados from './feriados-trabalhados'
import horarios from './horarios'
import instrucoes from './instrucoes'
import tiposDeAgendamento from './tipos-de-agendamentos'

const estadoInicial = {
  bloqueios: undefined,
  feriadosTrabalhados: undefined,
  horarios: undefined,
  instrucoes: undefined,  
  tiposDeAgendamento: undefined
}

export default (state = estadoInicial, action) => {
  return {
    bloqueios: bloqueios(state.bloqueios, action),
    feriadosTrabalhados: feriados(state.feriadosTrabalhados, action),
    horarios: horarios(state.horarios, action),
    instrucoes: instrucoes(state.instrucoes, action),
    tiposDeAgendamento: tiposDeAgendamento(state.tiposDeAgendamento, action)
  }
}
