import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import Lista from './lista'

export default function SalaDeEspera(props) {
  const {
    atualizarListaDosAgendamentosAguardandoAtendimento,
    filtros,
    fotos,
    identificadorDoProfissional,
    listarAgendamentosAguardandoAtendimento,
    match,
    ordenacao,
    paginaDeDados,
    recuperarFotoDoPacientePorIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    unidadeAtual
  } = props

  useEffect(() => {
    paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.paciente.identificador, item.paciente.foto)
    })

    return () => paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.paciente.foto)
    })
  }, [paginaDeDados.dados, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE]}>
              <Lista
                {...props}
                atualizar={atualizarListaDosAgendamentosAguardandoAtendimento}
                filtros={filtros}
                fotos={fotos}
                identificadorDoProfissional={identificadorDoProfissional}
                pesquisar={listarAgendamentosAguardandoAtendimento}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                unidadeAtual={unidadeAtual}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}`} />
    </Switch>
  )
}