import React from 'react'
import { useTarefas } from '../contexto'
import { ArrowBarDown } from '../../../design/icones'
import constantes from '../../../configuracoes/constantes'

export default function Paginacao({ filtros = {}, identificadorDoTipoDeTarefa }) {
  const { alterarFiltrosDoTarefas } = useTarefas()

  const alterarFiltro = valor => {
    alterarFiltrosDoTarefas({
      ...filtros,
      ...valor,
    }, identificadorDoTipoDeTarefa)
  }

  return (
    <div className='table-items__row mais-tarefas' style={{ cursor: 'pointer' }} onClick={() => alterarFiltro({ limite: filtros.limite + constantes.numeroTarefasPorPagina })}>
      <div className="table-items__data col-xs-1 col-md-1 col-lg-1 justify-content-center">
        <ArrowBarDown size={20} />
      </div>
      <div className="table-items__data col-xs-10 col-md-10 col-lg-10 justify-content-center">
        Próximos registros
      </div>
      <div className="table-items__data col-xs-1 col-md-1 col-lg-1 justify-content-center">
        <ArrowBarDown size={20} />
      </div>
    </div>
  )
}