import React from 'react'
import moment from 'moment'
import { Coluna, Tabela } from '../../../tabela'

export default function HistoricoAntiHbc({ historico }) {
  return (
    <Tabela
      chave='atendimento.identificador'
      className='--secondary my-1'
      dados={historico}
    >
      <Coluna
        className='col-lg-5'
        nome='Data do Anti-HBC'
        principal={true}
        renderizar={item => moment(item.data).format("DD/MM/YYYY")}
      />
      <Coluna
        className='col-lg-6'
        nome='Anti-HBC'
        campoDeDados='valor'
      />
    </Tabela>
  )
}