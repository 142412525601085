import { converterArrayParaObjeto } from '../../bibliotecas/conversao'
import {
  LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  CANCELAR_LOGIN,
} from '../../acoes/tipos'

const estadoInicial = {
  empresas: {}
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_EMPRESAS_HABILITADAS_PARA_O_LOGIN: {
      return {
        ...state,
        empresas: converterArrayParaObjeto(action.empresas),
      }
    }

    case CANCELAR_LOGIN: {
      return estadoInicial
    }

    default: {
      return state
    }
  }
}