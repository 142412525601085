import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'

import {
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
} from '../tipos'

const listouTiposDeAgendamentoDoAgendamento = tiposDeAgendamento => ({
  type: LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  tiposDeAgendamento,
})

const erroAoListarTiposDeAgendamentoDoAgendamento = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  erro,
  parametros,
})

export const listarTiposDeAgendamentoDoAgendamento = profissional => async dispatch => {
  dispatch({ type: LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO })

  try {
    const resultado = await api.listarTiposDeAgendamentoDoAgendamento(profissional)

    dispatch(listouTiposDeAgendamentoDoAgendamento(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarTiposDeAgendamentoDoAgendamento(erro, profissional))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os tipos de agendamento.')))
  }
}