import {
  LISTOU_RECORRENCIAS_DO_AGENDAMENTO
} from '../../acoes/tipos'

const estadoInicial = []

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_RECORRENCIAS_DO_AGENDAMENTO: {
      return action.recorrenciasDoAgendamento
    }

    default: {
      return state
    }
  }
}