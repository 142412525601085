import { 
  ADICIONOU_DIAGNOSTICO,
  ALTEROU_DIAGNOSTICO,
  LISTOU_DIAGNOSTICOS,  
  LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO,   
  RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR,
  REMOVEU_DIAGNOSTICO,  
} from '../acoes/tipos'

let estadoInicial = {
  filtros: {
    aprovado: ['Sim'],
    termo: '',
  },
  itemAtual: null,
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {
    pagina: 1,
    dados: []
  },
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_DIAGNOSTICOS: {
      return {
        ...state,
        filtros: action.filtros,
        itemAtual: null,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case LISTOU_DIAGNOSTICOS_PARA_O_PRONTUARIO: {
      return {
        ...state,       
        paginaDeDados: {
          ...state.paginaDeDados,
          dados: action.diagnosticos,
        }
      }
    }

    case RECUPEROU_DIAGNOSTICO_PELO_IDENTIFICADOR:
    case ALTEROU_DIAGNOSTICO:
    case ADICIONOU_DIAGNOSTICO: {
      return {
        ...state,
        itemAtual: action.diagnostico,        
      }
    }

    case REMOVEU_DIAGNOSTICO: {
      return {
        ...estadoInicial,
        itemAtual: null,
      }
    }

    default: {
      return state
    }
  }
}
