import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import Modal from 'react-modal'
import { setMinutes, setHours } from 'date-fns'
import * as Yup from 'yup'
import { FieldArray } from 'formik'
import Spinner from '../../../spinner'
import { Formulario as Form, DatePicker, Select, Input, HourPicker, Opcoes, CampoNumerico } from '../../../formik/formulario'
import StylesSelect from '../../../formik/select'
import { formatarHoraParaFormatoLocal, formatarDataParaFormatoUniversal, dataIgualOuAnterior } from '../../../../bibliotecas/data'
import { filtroPadrao } from '../../../../bibliotecas/texto'
import ResumoDaRecorrencia from '../resumo-da-recorrencia'
import ConfirmacaoDeExclusao from './confirmacao-de-exclusao'

import {
  ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE
} from '../../../../acoes/tipos'

import { listarTiposDeRepeticaoDeSerieDaAgenda } from '../../../../acoes/tipos-de-repeticao-de-serie-da-agenda'
import { listarTiposDeHorarios } from '../../../../acoes/usuarios/agenda/tipos-de-horarios'
import { listarUnidadesDoUsuarioDoHorarioDaAgenda } from '../../../../acoes/usuarios/agenda/unidades-do-usuario'

const TIPO_ALTERACAO = ['Alterar somente este horário', 'Alterar toda a série']
const DIAS_DE_SEMANA = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export default function FormularioDeHorario(props) {
  const {
    adicionar,
    alterar,
    alterarSerie,
    aposFechar,
    fechar,
    horario = null,
    identificadorDoProfissional,
    remover,
    removerSerie,
    unidades,
  } = props

  const dispatch = useDispatch()
  const tiposDeRepeticaoDeSerieDaAgenda = useSelector(state => state.tiposDeRepeticaoDeSerieDaAgenda)
  const tiposDeHorarios = useSelector(state => state.tiposDeHorarios)

  const [alteraSomenteAOcorrencia, setAlteraSomenteAOcorrencia] = useState(horario ? true : false)
  const [exibirAlertaDeExclusao, setExibirAlertaDeExclusao] = useState(false)
  const [horarioInicio, setHorarioInicio] = useState('')
  const [horarioFim, setHorarioFim] = useState('')
  const [tamanhoDoSlot, setTamanhoDoSlot] = useState('')
  const [tipoDeHorario, setTipoDeHorario] = useState('')
  const [unidade, setUnidade] = useState('')

  useEffect(() => { dispatch(listarTiposDeRepeticaoDeSerieDaAgenda()) }, [dispatch])
  useEffect(() => { dispatch(listarTiposDeHorarios()) }, [dispatch])
  useEffect(() => {
    if (identificadorDoProfissional) {
      dispatch(listarUnidadesDoUsuarioDoHorarioDaAgenda(identificadorDoProfissional))
    }
  }, [identificadorDoProfissional, dispatch])

  function abrirAlertaDeExclusao() {
    setExibirAlertaDeExclusao(true)
  }

  function fecharAlertaDeExclusao() {
    setExibirAlertaDeExclusao(false)
  }

  const removerHorario = () => {
    remover()
  }

  const removerSerieDeHorarios = () => {
    removerSerie()
  }

  const salvarHorarioNaAgenda = async formulario => {
    const dados = {
      ...formulario,
      identificador: formulario.identificador,
      inicioDaSerie: formatarDataParaFormatoUniversal(formulario.inicioDaSerie),
      fimDaSerie: formulario.tipoDeRecorrencia === 'nao_repetir' ? formatarDataParaFormatoUniversal(formulario.inicioDaSerie) : formatarDataParaFormatoUniversal(formulario.fimDaSerie),
      domingo: formulario.semana.includes('Dom'),
      segundaFeira: formulario.semana.includes('Seg'),
      tercaFeira: formulario.semana.includes('Ter'),
      quartaFeira: formulario.semana.includes('Qua'),
      quintaFeira: formulario.semana.includes('Qui'),
      sextaFeira: formulario.semana.includes('Sex'),
      sabado: formulario.semana.includes('Sáb'),
      repeteACada: formulario.repeteACada,
      tipoDeRepeticao: formulario.tipoDeRepeticao,
      observacoes: formulario.observacoes,
    }

    const acao = formulario.identificador ? alterarSerie : adicionar
    const salvou = await acao(identificadorDoProfissional, dados)

    if (salvou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  useEffect(() => {
    const opcao = unidades.map(x => ({ value: x.identificador, label: x.nome }))
    if (unidades.length === 1) {
      setUnidade(opcao[0])
    }
  }, [unidades])

  const options = unidades.map(x => ({ value: x.identificador, label: x.nome }))

  const onChange = option => {
    setUnidade(option)
  }

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  const verificaHora = horarioInicio && horarioFim ? horaIgualOuAnterior(horarioInicio, horarioFim) : true

  const adicionarHorario = (arrayHelpers, setFieldValue) => {
    arrayHelpers.push({ inicio: formatarHoraParaFormatoLocal(new Date(horarioInicio)), fim: formatarHoraParaFormatoLocal(new Date(horarioFim)), tipo: tipoDeHorario, unidade: unidade.value, tamanhoDoSlot: moment.utc(moment.duration(parseInt(tamanhoDoSlot, 10), 'm').asMilliseconds()).format('HH:mm:ss') })

    setHorarioInicio('')
    setHorarioFim('')
    setTamanhoDoSlot('')
    setTipoDeHorario('')
    setUnidade('')
    setFieldValue('tipo', '')
    setFieldValue('tamanhoDoSlot', '')
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeExclusao}
        contentLabel='Modal para alerta de exclusao em serie'
        className='modal-vacinas'
      >
        <ConfirmacaoDeExclusao
          excluir={removerHorario}
          excluirSerie={removerSerieDeHorarios}
          fechar={fecharAlertaDeExclusao}
          horario={horario}
        />
      </Modal>
      {alteraSomenteAOcorrencia
        ?
        <FormularioAlterarHorario
          abrirAlertaDeExclusao={abrirAlertaDeExclusao}
          alterar={alterar}
          aposFechar={aposFechar}
          fechar={fechar}
          horario={horario}
          identificador={identificadorDoProfissional}
          setAlteraSomenteAOcorrencia={setAlteraSomenteAOcorrencia}
          tiposDeHorarios={tiposDeHorarios}
          unidades={unidades}
        />
        :
        <Spinner operacoes={[
          ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
          ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
        ]}>
          <Form
            reinicializar={true}
            valoresIniciais={{
              identificador: horario?.identificador || '',
              inicioDaSerie: horario?.serie?.inicioDaSerie || '',
              fimDaSerie: horario?.serie?.fimDaSerie || '',
              repeteACada: horario?.serie?.repeteACada || 1,
              tipoDeRepeticao: tiposDeRepeticaoDeSerieDaAgenda && horario && horario.serie ? tiposDeRepeticaoDeSerieDaAgenda.find(x => x.nome === horario.serie.tipoDeRepeticao).codigo : 'semana',
              observacoes: horario?.serie?.observacoes || '',
              semana: horario && horario.serie ? construirArrayDeSemana(horario.serie) : DIAS_DE_SEMANA.filter(x => x !== 'Dom' && x !== 'Sáb'),
              tipoDeAlteracao: TIPO_ALTERACAO[1],
              horarios: horario && horario.serie ? horario.serie.horarios.map(x => ({ ...x, unidade: x.unidade.identificador, tamanhoDoSlot: x.tamanhoDoSlot })) : [],
            }}
            acao={salvarHorarioNaAgenda}
            esquemaDeValidacoes={Yup.object().shape({
              inicioDaSerie: Yup.string().required('Obrigatório').nullable(),
              fimDaSerie: Yup.string().required('Obrigatório')
                .nullable()
                .test(
                  "fim_teste",
                  "Deve ser maior que o início.",
                  function (value) {
                    const { inicioDaSerie } = this.parent;
                    return !value || dataIgualOuAnterior(inicioDaSerie, value);
                  }
                ),
              horarios: Yup.array()
                .min(1, 'Pelo menos um horário deve ser informado.')
                .required('Horários são obrigatórios.'),
              semana: Yup.string().required('Obrigatório.'),
              repeteACada: Yup.string().required('Obrigatório').nullable(),
              tamanhoDoSlot: Yup.number()
                .min(5, 'Deve ser maior que 5.')
                .max(240, 'Deve ser menor que 240.')
                .typeError('Deve ser numérico')
                .test(
                  "tamanho_do_slot_teste",
                  "Deve ser um valor múltiplo de 5.",
                  function (value) {
                    return !value || value % 5 === 0
                  }),
              tipoDeRepeticao: Yup.string().required('Obrigatório').nullable(),
            })}
          >
            {({ values, setFieldValue }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{horario ? 'Alterar' : 'Adicionar'} Horário da Agenda</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    {horario &&
                      <r-cell span={4} span-md={12} span-lg={12}>
                        <Opcoes
                          nome='tipoDeAlteracao'
                          opcoes={TIPO_ALTERACAO}
                          onChange={() => setAlteraSomenteAOcorrencia(true)}
                          selecaoUnica={true}
                          classname='encaminhamentos'
                        />
                      </r-cell>
                    }
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <DatePicker
                        nome='inicioDaSerie'
                        tabIndex={1}
                        minDate={(new Date())}
                        titulo='Início do Período *'
                        acoes={[{
                          titulo: 'Hoje',
                          tituloSecundario: 'Hoje',
                          acao: () => setFieldValue('inicioDaSerie', formatarDataParaFormatoUniversal(moment()))
                        }]}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={6}>
                      <DatePicker
                        nome='fimDaSerie'
                        tabIndex={2}
                        isDisabled={!values.inicioDaSerie}
                        minDate={moment(values.inicioDaSerie).toDate()}
                        titulo='Fim do Período *'
                        acoes={[{
                          titulo: 'Mesmo Dia',
                          tituloSecundario: 'Somente um dia',
                          acao: () => setFieldValue('fimDaSerie', values.inicioDaSerie)
                        }, {
                          titulo: 'Final do Mês',
                          tituloSecundario: 'Final do Mês, baseado no Início do Período',
                          acao: () => setFieldValue('fimDaSerie', formatarDataParaFormatoUniversal(moment(values.inicioDaSerie).clone().endOf('month')))
                        }, {
                          titulo: 'Final do Ano',
                          tituloSecundario: 'Final do Ano, baseado no Início do Período',
                          acao: () => setFieldValue('fimDaSerie', formatarDataParaFormatoUniversal(moment(values.inicioDaSerie).clone().endOf('year')))
                        }]}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <CampoNumerico
                        nome='repeteACada'
                        tabIndex={3}
                        titulo='Repete a cada *'
                      />
                    </r-cell>
                    <r-cell span={4} span-md={9} span-lg={9}>
                      <Select
                        titulo='.'
                        nome='tipoDeRepeticao'
                        tabIndex={4}
                        itens={tiposDeRepeticaoDeSerieDaAgenda}
                        campoCodigo='codigo'
                        campoDescricao='nome'
                      />
                    </r-cell>
                    <DiasDaSemana />
                    <ResumoDaRecorrencia {...values} />
                    <r-cell span={2} span-md={2} span-lg={3}>
                      <div className={`form-group ${!verificaHora && 'has-error'}`}>
                        <label className='form-label'>{`Horário *`}</label>
                        <ReactDatePicker
                          nome='inicio'
                          type='text'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption='Hora'
                          dateFormat='HH:mm'
                          onChange={setHorarioInicio}
                          selected={horarioInicio}
                          className='form-select'
                          tabIndex={5}
                        />
                        {!verificaHora && <label className='form-tip'>Deve ser menor do que a hora final.</label>}
                      </div>
                    </r-cell>
                    <r-cell span={2} span-md={2} span-lg={3}>
                      <div className={`form-group ${!verificaHora && 'has-error'}`}>
                        <label className='form-label'>{`Até *`}</label>
                        <ReactDatePicker
                          nome='fim'
                          type='text'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption='Hora'
                          dateFormat='HH:mm'
                          disabled={!horarioInicio}
                          onChange={setHorarioFim}
                          selected={horarioFim}
                          className='form-select'
                          minTime={(new Date(horarioInicio).getTime() + 5 * 60000)}
                          maxTime={setHours(setMinutes(new Date(), 59), 23)}
                          tabIndex={6}
                        />
                        {!verificaHora && <label className='form-tip'>Deve ser maior do que a hora inicial.</label>}
                      </div>
                    </r-cell>
                    <r-cell span={1} span-md={4} span-lg={6}>
                      <Select
                        nome='tipo'
                        titulo='Tipo de Horário *'
                        itens={tiposDeHorarios}
                        campoCodigo='nome'
                        campoDescricao='nome'
                        onChange={setTipoDeHorario}
                        tabIndex={7}
                      />
                    </r-cell>
                    <r-cell span={1} span-md={4} span-lg={6}>
                      <label className='form-label'>{`Unidade *`}</label>
                      <StylesSelect
                        filterOption={(option, rawInput) => filtroPadrao(rawInput, option.label)}
                        key={`${unidades.nome}`}
                        options={options}
                        value={unidade}
                        onChange={onChange}
                        isClearable={true}
                        placeholder={unidade ? unidade.label : ''}
                        tabIndex={8}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={3} span-lg={4}>
                      <CampoNumerico
                        nome='tamanhoDoSlot'
                        tabIndex={9}
                        titulo='Tamanho do Slot *'
                        onChange={setTamanhoDoSlot}
                      />
                    </r-cell>
                    <FieldArray
                      name='horarios'
                      render={arrayHelpers => (
                        <>
                          <r-cell span={2} span-md={4} span-lg={2}>
                            <button
                              disabled={(!horarioInicio || !horarioFim || !unidade || !tamanhoDoSlot) || !verificaHora || !tipoDeHorario}
                              type='button'
                              className={`bt-novo-bloqueio-agenda --primary w-100 mt-24 ${(!horarioInicio || !horarioFim || !unidade || !tamanhoDoSlot) && 'is-disabled'}`}
                              tabIndex={10}
                              onClick={() => adicionarHorario(arrayHelpers, setFieldValue)}
                            >
                              Adicionar Horário
                            </button>
                          </r-cell>
                          {values.horarios && values.horarios.length > 0 &&
                            <r-cell span={4} span-md={12} span-lg={12} >
                              {values.horarios.map((horario, index) => {
                                const identificadorDaUnidade = horario.unidade && horario.unidade.identificador ? horario.unidade.identificador : horario.unidade
                                const nomeDaUnidade = unidades.find(x => x.identificador === parseInt(identificadorDaUnidade))?.nome

                                return (
                                  <div className='form-choice-horario-agenda' key={index}>
                                    <div className='form-choice-horario-agenda__item'>{horario.inicio} às {horario.fim}</div>
                                    <div className='form-choice-horario-agenda__item'>{horario.tipo}</div>
                                    <div className='form-choice-horario-agenda__item'>{nomeDaUnidade}</div>
                                    <div className='form-choice-horario-agenda__item'>Slot: {moment.duration(horario.tamanhoDoSlot, "HH:mm:ss").asMinutes()} minutos</div>
                                    <button className='form-choice-horario-agenda__bt-close' type='button' aria-label='Fechar' onClick={() => arrayHelpers.remove(index)}><i className='icon icon-close'></i></button>
                                  </div>
                                )
                              })}
                            </r-cell>
                          }
                        </>
                      )}
                    />
                    <r-cell span={4} span-md={12} span-lg={12}>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={11}
                        titulo='Observações'
                      />
                    </r-cell>
                  </r-grid>
                  <div className='list-btn'>
                    {horario?.identificador && <button onClick={abrirAlertaDeExclusao} type='button' className='button --danger'>Excluir</button>}
                    <button type='button' onClick={() => fechar()} className='button --light'>Cancelar</button>
                    <button type='submit' className='button --primary' disabled={values.horarios.length === 0}>{horario ? 'Alterar' : 'Adicionar'} Série</button>
                  </div>
                </fieldset>
              </>
            )}
          </Form>
        </Spinner>
      }
    </>
  )
}

function FormularioAlterarHorario({
  abrirAlertaDeExclusao,
  alterar,
  aposFechar,
  horario,
  fechar,
  identificador,
  setAlteraSomenteAOcorrencia,
  tiposDeHorarios,
  unidades
}) {

  const alterarHorario = async formulario => {
    const dadosParaAlterarHorario = {
      identificador: formulario.identificador,
      data: formulario.data,
      inicio: formatarHoraParaFormatoLocal(formulario.horaInicial),
      fim: formatarHoraParaFormatoLocal(formulario.horaFinal),
      tipo: formulario.tipo,
      unidade: formulario.unidade,
      observacoes: formulario.observacoes,
      tamanhoDoSlot: moment.utc(moment.duration(parseInt(formulario.tamanhoDoSlot, 10), 'm').asMilliseconds()).format('HH:mm:ss'),
    }

    const alterou = await alterar(identificador, dadosParaAlterarHorario)

    if (alterou) {
      fechar()
      aposFechar && aposFechar()
    }
  }

  const horaIgualOuAnterior = (horaInicial, horaFinal) => {
    const horaInicialFormatada = formatarHoraParaFormatoLocal(new Date(horaInicial))
    const horaFinalFormatada = formatarHoraParaFormatoLocal(new Date(horaFinal))

    return moment(horaInicialFormatada, 'HH:mm').isBefore(moment(horaFinalFormatada, 'HH:mm'))
  }

  return (
    <Spinner operacoes={[
      ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
      EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE
    ]}>
      <Form
        reinicializar={true}
        valoresIniciais={{
          identificador: horario.identificador || '',
          data: horario.data ? horario.data : '',
          horaInicial: horario.inicio ? moment(horario.inicio, 'HH:mm:ss').toDate() : '',
          horaFinal: horario.fim ? moment(horario.fim, 'HH:mm:ss').toDate() : '',
          tamanhoDoSlot: moment.duration(horario.tamanhoDoSlot, "HH:mm:ss").asMinutes(),
          tipo: horario.tipo,
          unidade: horario.unidade?.identificador,
          observacoes: horario.observacoes || '',
          tipoDeAlteracao: TIPO_ALTERACAO[0]
        }}
        acao={alterarHorario}
        esquemaDeValidacoes={Yup.object().shape({
          data: Yup.string().required('Obrigatório.').nullable(),
          horaInicial: Yup.string().required('Obrigatório.').nullable(),
          horaFinal: Yup.string().required('Obrigatório.')
            .nullable()
            .test(
              "horaFim_teste",
              "Deve ser maior que a hora inicial",
              function (value) {
                const { horaInicial } = this.parent;
                return !value || horaIgualOuAnterior(horaInicial, value);
              }
            ),
          tamanhoDoSlot: Yup.number()
            .min(5, 'Deve ser maior que 5.')
            .max(240, 'Deve ser menor que 240.')
            .typeError('Deve ser numérico')
            .test(
              "tamanho_do_slot_teste",
              "Deve ser um valor múltiplo de 5.",
              function (value) {
                return !value || value % 5 === 0
              }).required('Obrigatório'),
          tipo: Yup.string().required('Obrigatório.'),
          unidade: Yup.string().required('Obrigatório.')
        })}
      >
        {({ values }) => (
          <fieldset>
            <h2 className="form-title">Alterar Horário da Agenda</h2>
            <r-grid columns-md={12} columns-lg={12}>
              {horario.serie &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Opcoes
                    nome='tipoDeAlteracao'
                    opcoes={TIPO_ALTERACAO}
                    onChange={() => setAlteraSomenteAOcorrencia(false)}
                    selecaoUnica={true}
                    classname='encaminhamentos'
                  />
                </r-cell>
              }
              <r-cell span={4} span-md={4} span-lg={6}>
                <DatePicker
                  nome='data'
                  tabIndex={1}
                  minDate={(new Date())}
                  titulo='Data *'
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaInicial'
                  titulo='Hora Inicial *'
                  tabIndex={2}
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={3}>
                <HourPicker
                  nome='horaFinal'
                  titulo='Hora Final *'
                  tabIndex={3}
                  isDisabled={!values.horaInicial}
                  minTime={(new Date(values.horaInicial).getTime() + 5 * 60000)}
                  maxTime={(setHours(setMinutes(new Date(), 59), 23))}
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={5}>
                <Select
                  nome='tipo'
                  titulo='Tipo de Horário *'
                  itens={tiposDeHorarios}
                  campoCodigo='nome'
                  campoDescricao='nome'
                  tabIndex={4}
                />
              </r-cell>
              <r-cell span={4} span-md={4} span-lg={4}>
                <Select
                  nome='unidade'
                  titulo='Unidade *'
                  itens={unidades}
                  campoCodigo='identificador'
                  campoDescricao='nome'
                  tabIndex={5}
                />
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={3}>
                <CampoNumerico
                  nome='tamanhoDoSlot'
                  tabIndex={6}
                  titulo='Tamanho do Slot *'
                  />
              </r-cell>
              <r-cell span={4} span-md={12} span-lg={12}>
                <Input
                  as='textarea'
                  nome='observacoes'
                  tabIndex={7}
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button onClick={abrirAlertaDeExclusao} type='button' className='button --danger'>Excluir</button>
              <button type='button' onClick={() => fechar()} className='button --light'>Voltar</button>
              <button type='submit' className='button --primary'>Alterar Horário</button>
            </div>
          </fieldset>
        )}
      </Form>
    </Spinner>
  )
}

function DiasDaSemana({ onChange }) {
  return (
    <r-cell span={4} span-md={12} span-lg={12}>
      <Opcoes
        titulo='Selecione os dias da Semana *'
        nome='semana'
        opcoes={DIAS_DE_SEMANA}
        opcoesDesabilitadas={[]}
        onChange={onChange}
      />
    </r-cell>
  )
}

const construirArrayDeSemana = dias => {
  let semana = []
  if (dias.domingo) {
    semana.push('Dom')
  }
  if (dias.segundaFeira) {
    semana.push('Seg')
  }
  if (dias.tercaFeira) {
    semana.push('Ter')
  }
  if (dias.quartaFeira) {
    semana.push('Qua')
  }
  if (dias.quintaFeira) {
    semana.push('Qui')
  }
  if (dias.sextaFeira) {
    semana.push('Sex')
  }
  if (dias.sabado) {
    semana.push('Sáb')
  }

  return semana
}