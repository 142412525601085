import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO
} from '../tipos'

const adicionouPlanoDeCuidadoDoPaciente = planoDeCuidado => ({
  type: ADICIONOU_PLANO_DE_CUIDADO_DO_PACIENTE,
  planoDeCuidado,
})

const erroAoAdicionarPlanoDeCuidadoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarPlanoDeCuidadoDoPaciente = (identificador, planoDeCuidado) => async dispatch => {
  dispatch({ type: ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE })

  try {    
    const resultado = await api.adicionarPlanoDeCuidadoDoPaciente(identificador, planoDeCuidado)
    
    dispatch(adicionouPlanoDeCuidadoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarPlanoDeCuidadoDoPaciente(erro, { identificador, planoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar plano de cuidado do paciente.')))

    return false
  }
}

const alterouPlanoDeCuidadoDoPaciente = planoDeCuidado => ({
  type: ALTEROU_PLANO_DE_CUIDADO_DO_PACIENTE,
  planoDeCuidado
})

const erroAoAlterarPlanoDeCuidadoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  erro,
  parametros,
})

export const alterarPlanoDeCuidadoDoPaciente = (identificadorDoPaciente, planoDeCuidado) => async dispatch => {
  dispatch({ type: ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE })

  try {    
    const resultado = await api.alterarPlanoDeCuidadoDoPaciente(identificadorDoPaciente, planoDeCuidado)
    
    dispatch(alterouPlanoDeCuidadoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPlanoDeCuidadoDoPaciente(erro, { identificadorDoPaciente, planoDeCuidado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar o plano de cuidado do paciente.')))

    return false
  }
}

const listouPlanosDeCuidadoDoPaciente = (identificadorDoPaciente, planosDeCuidado) => ({
  type: LISTOU_PLANOS_DE_CUIDADO_DO_PACIENTE,
  identificadorDoPaciente,
  planosDeCuidado
})

const erroAoListarPlanosDeCuidadoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  erro,
  parametros
})

export const listarPlanosDeCuidadoDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE })

  try {
    const resultado = await api.listarPlanosDeCuidadoDoPaciente(identificadorDoPaciente)

    dispatch(listouPlanosDeCuidadoDoPaciente(identificadorDoPaciente, resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarPlanosDeCuidadoDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar os planos de cuidado do paciente.')))
  }
}

const removeuPlanoDeCuidadoDoPaciente = identificador => ({
  type: REMOVEU_PLANO_DE_CUIDADO_DO_PACIENTE,
  identificador,
})

const erroAoRemoverPlanoDeCuidadoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  erro,
  parametros
})

export const removerPlanoDeCuidadoDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE })

  try {    
    const resultado = await api.removerPlanoDeCuidadoDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(removeuPlanoDeCuidadoDoPaciente(identificadorDoPaciente, identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverPlanoDeCuidadoDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o plano de cuidado do paciente.')))

    return false
  }
}

const recuperouPlanoDeCuidadoDoPaciente = planoDeCuidado => ({
  type: RECUPEROU_PLANO_DE_CUIDADO_DO_PACIENTE, 
  planoDeCuidado
})

const erroAoRecuperarPlanoDeCuidadoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  erro,
  parametros
})

export const recuperarPlanoDeCuidadoDoPaciente = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE })
  try {    
    const resultado = await api.recuperarPlanoDeCuidadoDoPaciente(identificadorDoPaciente, identificador)
    
    dispatch(recuperouPlanoDeCuidadoDoPaciente(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarPlanoDeCuidadoDoPaciente(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o plano de cuidado do paciente.')))
  }
}

const listouMotivosDeSaidaDosPlanosDeCuidado = motivosDeSaidaDosPlanosDeCuidado => ({
  type: LISTOU_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  motivosDeSaidaDosPlanosDeCuidado
})

const erroAoListarMotivosDeSaidaDosPlanosDeCuidado = erro => ({
  type: ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  erro
})

export const listarMotivosDeSaidaDosPlanosDeCuidado = () => async dispatch => {
  dispatch({ type: LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO })
  
  try {
    const resultado = await api.listarMotivosDeSaidaDosPlanosDeCuidado()
    
    dispatch(listouMotivosDeSaidaDosPlanosDeCuidado(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMotivosDeSaidaDosPlanosDeCuidado(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os motivos de saída dos planos de cuidado.')))
  }
}