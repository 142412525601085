import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import moment from 'moment'
import Spinner from '../../spinner'
import * as permissoes from '../../seguranca/permissoes'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import AbasDaAgenda from './abas'
import Cabecalho from '../cabecalho'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import { formatarDataParaFormatoUniversal, formatarDataParaFormatoLocal, formatarDataEHoraParaFormatoLocal } from '../../../bibliotecas/data'
import FormularioDeHorario from './horario/formulario'
import SeletorDeDataDoHorario from './seletor-de-data'
import chroma from 'chroma-js'

import {
  LISTAR_FERIADOS,
  LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE
} from '../../../acoes/tipos'

export default function Horarios(props) {
  const {
    adicionarHorarioDaAgendaDoProfissionalDeSaude,
    alterarHorarioDaAgendaDoProfissionalDeSaude,
    alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude,
    excluirHorarioDaAgendaDoProfissionalDeSaude,
    excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude,
    feriados = [],
    feriadosTrabalhados,
    fotos,
    history,
    listarFeriados,
    listarFeriadosTrabalhadosDoProfissional,
    listarHorariosDaAgendaDoProfissionalDeSaude,
    match,
    recuperarPorIdentificador,
    usuario = {},
    horarios: {
      paginaDeDados,
      unidades,
      ...restanteHorarios
    },
  } = props

  const { identificador } = match.params
  const [filtros, setFiltros] = useState(restanteHorarios.filtros)
  const [exibirAdicionarHorario, setExibirAdicionarHorario] = useState(false)
  const [exibirAlterarHorario, setExibirAlterarHorario] = useState(false)
  const [horarioSelecionado, setHorarioSelecionado] = useState('')
  const diasDaSemana = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"]

  const podeAdicionarAgenda = usePossuiAsPermissoes([permissoes.ALTERAR_CONFIGURACAO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE])

  useEffect(() => {
    listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)
  }, [listarHorariosDaAgendaDoProfissionalDeSaude, identificador, filtros])

  useEffect(() => {
    listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)
  }, [listarHorariosDaAgendaDoProfissionalDeSaude, identificador, filtros])

  useEffect(() => {
    listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)
  }, [listarHorariosDaAgendaDoProfissionalDeSaude, identificador, filtros])

  useEffect(() => {
    listarFeriadosTrabalhadosDoProfissional(identificador)
  }, [listarFeriadosTrabalhadosDoProfissional, identificador])

  useEffect(() => {
    listarFeriados({ ano: moment(filtros.data).year().toString() }, 1, { ordem: "+", nome: "data" }, 1000)
  }, [listarFeriados, filtros])

  const pesquisar = data => {
    if (data) {
      setFiltros({
        data: formatarDataParaFormatoUniversal(data)
      })
    }
  }

  const remover = async () => {
    const removeu = await excluirHorarioDaAgendaDoProfissionalDeSaude(identificador, horarioSelecionado.identificador)

    if (removeu) {
      fecharAlterar()
      listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)
    }

    return removeu
  }

  const removerSerie = async () => {
    const removeu = await excluirSerieDeHorariosDaAgendaDoProfissionalDeSaude(identificador, horarioSelecionado.identificador)

    if (removeu) {
      fecharAlterar()
      listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)
    }

    return removeu
  }

  function abrirAdicionar() {
    setExibirAdicionarHorario(true)
  }

  function fecharAdicionar() {
    setExibirAdicionarHorario(false)
  }

  function abrirAlterar(horarioSelecionado) {
    setExibirAlterarHorario(true)
    setHorarioSelecionado(horarioSelecionado)
  }

  function fecharAlterar() {
    setExibirAlterarHorario(false)
    setHorarioSelecionado('')
  }

  const preencherTabela = dados => {
    if (!dados || dados.length === 0) return null

    const dataDoAgendamentoDoMes = dados[0].data
    const semanas = {}
    const diaDoMes = moment(dataDoAgendamentoDoMes).startOf('month')
    const ultimoDiaDoMes = moment(dataDoAgendamentoDoMes).endOf('month')

    while (diaDoMes.isBefore(ultimoDiaDoMes) || diaDoMes.isSame(ultimoDiaDoMes, 'day')) {
      const semanaDoAno = diaDoMes.week()
      const diaDaSemana = diaDoMes.format('ddd').toUpperCase()

      if (!semanas[semanaDoAno]) {
        semanas[semanaDoAno] = {}
      }

      if (!semanas[semanaDoAno][diaDaSemana]) {
        semanas[semanaDoAno][diaDaSemana] = []
      }

      semanas[semanaDoAno][diaDaSemana].push(formatarDataParaFormatoLocal(diaDoMes))
      diaDoMes.add(1, 'day')
    }

    const semanasOrdenadas = Object.keys(semanas).sort((semanaA, semanaB) => {
      const diaA = diasDaSemana
        .map((dia) => semanas[semanaA][dia])
        .find((registro) => registro) || []

      const diaB = diasDaSemana
        .map((dia) => semanas[semanaB][dia])
        .find((registro) => registro) || []

      const dataA = moment(diaA[0], 'DD/MM/YYYY')
      const dataB = moment(diaB[0], 'DD/MM/YYYY')

      return dataA.isBefore(dataB) ? -1 : 1
    })

    dados.forEach(registro => {
      const semanaDoAno = moment(registro.data).week()
      const diaDaSemana = moment(registro.data).format('ddd').toUpperCase()

      semanas[semanaDoAno][diaDaSemana].push(registro)
    })

    return semanasOrdenadas.map((semana, index) => (
      <tr key={index}>
        {diasDaSemana.map((dia, index) => {
          const registro = semanas[semana][dia]

          if (!registro) {
            return <td key={index} />
          }

          const diaAtual = registro[0]
          const ehHoje = moment(diaAtual, 'DD/MM/YYYY').isSame(moment(), 'day')

          feriados.forEach(function (item, index, object) {
            var ehTrabalhado = feriadosTrabalhados.some(x => x.feriado.data === item.data)

            if (ehTrabalhado) {
              object.splice(index, 1)
            }
          })

          const ehFeriado = feriados.some(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))

          return (
            <td key={index} className={`${ehHoje ? 'dia-atual-destaque' : ''} ${ehFeriado ? 'feriado-destaque' : ''}`}>
              {renderizarHorario(registro)}
            </td>
          )
        })}
      </tr>
    ))
  }

  const renderizarHorario = (registro) => {
    const diaAtual = registro[0]
    const ehHoje = moment(diaAtual, 'DD/MM/YYYY').isSame(moment(), 'day')
    const ehFeriado = feriados.some(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))
    const feriado = feriados.find(x => moment(x.data).isSame(moment(diaAtual, 'DD/MM/YYYY'), 'date'))
    const horarios = Array.from(registro.values()).slice(1)

    return (
      <>
        <span title={`${ehFeriado ? feriado.nome : ''}`} className={`${ehHoje ? 'dia-atual' : ''}`}>{ehFeriado ? `${diaAtual} (Feriado)` : diaAtual}</span>
        {horarios.map(x => {
          const color = chroma(x.unidade.cor)
          const corDaFonte = chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

          return (
            <div
              className='calendar-week__event'
              style={{ backgroundColor: x.unidade.cor, cursor: 'pointer' }}
              key={x.identificador}
              onClick={() => abrirAlterar(x)}
            >
              <div className='calendar-week__event__time' style={{ color: corDaFonte, display: 'flex', justifyContent: 'space-between' }}>
                <p className='calendar-week__event__time'>{`${moment(x.inicio, 'HH:mm:ss').format('HH:mm')} às ${moment(x.fim, 'HH:mm:ss').format('HH:mm')}`}</p>
                <span className='calendar-week__event__local' key='informacao' data-tip data-for={`info_${x.identificador}`}>
                  <i className='icon icon-info' style={{ color: corDaFonte }}></i>
                  <ReactTooltip
                    id={`info_${x.identificador}`}
                    place='right'
                    effect='solid'
                    type='info'
                    key={x.identificador}
                    className='unity-infos__item'
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>Última alteração</strong></div>
                    <span>Usuário: {x.ultimaAlteracao.usuario.nome}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(x.ultimaAlteracao.dataEHora)}</span>
                  </ReactTooltip>
                </span>
              </div>
              <p className='calendar-week__event__local font-weight-bolder' style={{ color: corDaFonte }}>{x.tipo}</p>
              <p className='calendar-week__event__local' style={{ color: corDaFonte }}>{x.unidade.nome}</p>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarHorario}
        className='modal modal-horario-agenda'
      >
        <FormularioDeHorario
          adicionar={adicionarHorarioDaAgendaDoProfissionalDeSaude}
          aposFechar={() => listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)}
          fechar={fecharAdicionar}
          identificadorDoProfissional={identificador}
          unidades={unidades}
        />
      </Modal>
      <Modal
        isOpen={exibirAlterarHorario}
        className='modal modal-horario-agenda'
      >
        <FormularioDeHorario
          alterar={alterarHorarioDaAgendaDoProfissionalDeSaude}
          alterarSerie={alterarSerieDeHorariosDaAgendaDoProfissionalDeSaude}
          aposFechar={() => listarHorariosDaAgendaDoProfissionalDeSaude(identificador, filtros)}
          fechar={fecharAlterar}
          horario={horarioSelecionado}
          identificadorDoProfissional={identificador}
          remover={remover}
          removerSerie={removerSerie}
          unidades={unidades}
        />
      </Modal>
      <Spinner operacoes={[LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE, LISTAR_FERIADOS, LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL]}>
        {({ processando }) => (
          <FormularioDoFormik
            processando={processando}
          >
            <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
            <div className='mt-2 mb-4'>
              <div>
                <fieldset className='mt-1'>
                  <h2 className='form-title'>Agenda</h2>
                  <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
                    <r-cell span={4} span-md={6} span-lg={9}>
                      <AbasDaAgenda identificador={identificador} url={match.url} usuario={usuario} />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={3} class='d-sm-none d-lg-flex justify-content-end'>
                      {podeAdicionarAgenda && <button type='button' className='button --primary w-100' onClick={abrirAdicionar}>Adicionar</button>}
                    </r-cell>
                  </r-grid>
                  <div className='d-flex align-items-center justify-content-between mt-3 mb-2 mb-lg-2 mb-sm-3'>
                    <h2 className='form-title mb-0'>Horários da Agenda</h2>
                    {podeAdicionarAgenda && <button type='button' className='button --primary d-none d-sm-block d-lg-none' onClick={abrirAdicionar}>Adicionar</button>}
                  </div>
                  <r-grid columns-md={6} columns-lg={12}>
                    <r-cell span={4} span-md={3} span-lg={3}>
                      <SeletorDeDataDoHorario
                        data={filtros.data}
                        pesquisar={pesquisar}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={6} span-lg={12}>
                      <div className='calendar-week'>
                        <div className='calendar-week__wrapper'>
                          <div className='calendar-week__content'>
                            <table className='calendar-week__table'>
                              <thead className='calendar-week__header'>
                                <tr>
                                  {diasDaSemana.map((x, index) => (
                                    <th key={index}>
                                      {x}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className='calendar-week__body dias-calendario-configuaracao-da-agenda'>
                                {preencherTabela(paginaDeDados.dados)}
                              </tbody>
                              {!processando && paginaDeDados?.dados?.length === 0 &&
                                <tbody className='calendar-week__body dias-calendario-configuaracao-da-agenda'>
                                  <tr>
                                    <td colSpan='7'>
                                      <p>Não há horários de agenda do profissional.</p>
                                    </td>
                                  </tr>
                                </tbody>
                              }
                            </table>
                          </div>
                        </div>
                      </div>
                    </r-cell>
                  </r-grid>
                </fieldset>
              </div>
              <div className='list-btn'>
                <button
                  className='button --light'
                  type='button'
                  onClick={() => history.push(`/configuracoes/usuarios`)}
                >
                  Voltar
                </button>
              </div>
            </div>
          </FormularioDoFormik>
        )}
      </Spinner>
    </>
  )
}