import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function MotivosDeIdaAoProntoAtendimento(props) {
  const {
    adicionarMotivoDeIdaAoProntoAtendimento,
    motivo,
    filtros,
    history,
    listarMotivosDeIdaAoProntoAtendimentoDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador,
    removerMotivoDeIdaAoProntoAtendimento,
    alterarMotivoDeIdaAoProntoAtendimento
  } = props

  const salvar = async formulario => {
    const dados = {
      ...formulario,
      status: formulario.status ? 'aprovado' : 'nao_aprovado'
    }

    const acao = dados.identificador ? alterarMotivoDeIdaAoProntoAtendimento : adicionarMotivoDeIdaAoProntoAtendimento
    const executou = await acao(dados)

    if (executou) {
      history.push(`/cadastros/motivos-de-ida-ao-pronto-atendimento`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarMotivosDeIdaAoProntoAtendimentoDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/novo`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]}>
              <Formulario
                {...props}
                salvar={salvar}
                motivo={motivo}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarMotivoDeIdaAoProntoAtendimentoPeloIdentificador}
                salvar={salvar}
                motivo={motivo}
                remover={removerMotivoDeIdaAoProntoAtendimento}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}