import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import * as Yup from 'yup'
import Spinner from '../spinner'
import {Formulario as FormularioDoFormik, Input, Toglle} from '../formik/formulario'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import {
  ADICIONAR_PROFISSAO,
  ALTERAR_PROFISSAO,
  RECUPERAR_PROFISSAO_POR_IDENTIFICADOR
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    profissao = {},
    recuperarPorIdentificador,
    salvar,
    history,
    remover
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_PROFISSAO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_PROFISSAO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_PROFISSAO])

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
    }
  }, [identificador, recuperarPorIdentificador])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir a profissão ${profissao.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => remover(profissao.identificador)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[RECUPERAR_PROFISSAO_POR_IDENTIFICADOR, ADICIONAR_PROFISSAO, ALTERAR_PROFISSAO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificador: profissao.identificador || '',
          nome: profissao.nome || '',
          conselho: profissao.conselho || '',
          academica: profissao.academica,
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          conselho: Yup.string().required('Obrigatório')
        })}
      >
        <fieldset className='mt-1'>
          <h2 className='form-title'>Dados Gerais</h2>
          <r-grid columns-md={12} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={6}>
              <Input
                type='text'
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            <r-cell span={4} span-md={5} span-lg={5}>
              <Input
                type='text'
                nome='conselho'
                tabIndex={2}
                titulo='Conselho *'
              />
            </r-cell>
            <r-cell span={4} span-md={1} span-lg={1}>
                <Toglle
                    className='form-toggle-2'
                    nome='academica'
                    titulo= 'Acadêmica'
                    tabIndex={3}
                />
            </r-cell>
          </r-grid>
        </fieldset>
        {remover ?
          <div className='list-btn-unidades'>
            {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
            {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
            <button onClick={() => history.push(`/cadastros/profissoes`)} type='button' className='button --light'>Voltar</button>
          </div>
          :
          <div className='list-btn'>
            <button onClick={() => history.push(`/cadastros/profissoes`)} type='button' className='button --light'>Voltar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        }
      </FormularioDoFormik>
    </Spinner>
  )
}