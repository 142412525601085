import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Report } from 'powerbi-report-component'
import {
  ATENDIMENTOS_MENSAIS,
  CONFIRMACOES_DE_AGENDAMENTOS,
  GESTAO_DE_ENCAMINHAMENTOS,
  GESTAO_DA_AGENDA,
  INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO,
  INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR,
  INDICADOR_DE_REINTERNACAO,
  INDICADORES_CONTRATUAIS_UN,
  INDICADORES_DE_DESFECHO_CLINICO,
  INDICADORES_DE_IMPACTO,
  INDICADORES_DE_MANUTENCAO_DO_CUIDADO,
  INDICADORES_DEMOGRAFICOS,
  INDICADORES_DO_PERFIL_DE_FRAGILIDADE,
  INDICADORES_OPERACIONAIS,
  VOLUMETRIA,
  NAVEGACAO_MEDICA,
  NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO,
  NPS,
  PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA,
  PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN,
  PLANO_DE_CUIDADOS_CONTRATUAL_UN,
  PLANO_DE_CUIDADOS_ENFERMAGEM,
  PLANO_DE_CUIDADOS_MEDICO,
  PLANO_DE_CUIDADOS_UN,
  RESOLUTIVIDADE_DO_PRONTO_CUIDAR,
  VACINACAO,
} from '../../../acoes/integracoes/relatorios-do-power-bi'

export default function Dashboard({ relatorios }) {
  const { dashboard } = useParams()
  const history = useHistory()
  const { token, url, identificador } = relatorios[deParaRelatoriosDoPowerBI(dashboard)] || {}

  if (!token || !url || !identificador) {
    return (
      <div className='container'>
        <fieldset>
          <h2 style={{fontSize:15}}>Dashboard indisponível</h2>
        </fieldset>
      </div>
    )
  }

  return (
    <>
      <Report
        tokenType='Embed'
        accessToken={token}
        embedUrl={url}
        embedId={identificador}
        reportMode='view'
        permissions='View'
        style={{
          height: '800px'
        }}
        extraSettings={{
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        }}
      />
      <div className='list-btn'>
        <button onClick={() => history.goBack()} type='button' className='button --light'>Voltar</button>
      </div>
    </>
  )
}

function deParaRelatoriosDoPowerBI(url) {
  switch (url) {
    case 'atendimentos-mensais': return ATENDIMENTOS_MENSAIS
    case 'confirmacoes-de-agendamentos': return CONFIRMACOES_DE_AGENDAMENTOS
    case 'gestao-de-encaminhamentos': return GESTAO_DE_ENCAMINHAMENTOS
    case 'gestao-da-agenda': return GESTAO_DA_AGENDA
    case 'indicador-de-ida-ao-pronto-atendimento': return INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO
    case 'indicador-de-internacao-por-fratura-de-femur': return INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR
    case 'indicador-de-reinternacao': return INDICADOR_DE_REINTERNACAO
    case 'indicadores-contratuais-un': return INDICADORES_CONTRATUAIS_UN
    case 'indicadores-de-desfecho-clinico': return INDICADORES_DE_DESFECHO_CLINICO
    case 'indicador-de-internacao-clinica': return INDICADORES_DE_IMPACTO
    case 'indicadores-de-manutencao-do-cuidado': return INDICADORES_DE_MANUTENCAO_DO_CUIDADO
    case 'indicadores-demograficos': return INDICADORES_DEMOGRAFICOS
    case 'indicadores-do-perfil-de-fragilidade': return INDICADORES_DO_PERFIL_DE_FRAGILIDADE
    case 'indicadores-operacionais': return INDICADORES_OPERACIONAIS
    case 'volumetria': return VOLUMETRIA
    case 'navegacao-medica': return NAVEGACAO_MEDICA
    case 'net-promoter-score': return NPS
    case 'notificacoes-de-internacoes-e-idas-ao-pronto-atendimento': return NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO
    case 'plano-de-cuidados-de-consultas-medicas': return PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA
    case 'plano-de-cuidados-de-consultas-medicas-un': return PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN
    case 'plano-de-cuidados-contratual-un': return PLANO_DE_CUIDADOS_CONTRATUAL_UN
    case 'plano-de-cuidados-enfermagem': return PLANO_DE_CUIDADOS_ENFERMAGEM
    case 'plano-de-cuidados-medico': return PLANO_DE_CUIDADOS_MEDICO
    case 'plano-de-cuidados-un': return PLANO_DE_CUIDADOS_UN
    case 'resolutividade-do-pronto-cuidar': return RESOLUTIVIDADE_DO_PRONTO_CUIDAR
    case 'vacinacao': return VACINACAO
    default: return null
  }
}