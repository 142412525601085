import api from '../api-major'
import constantes from '../../configuracoes/constantes'

const adicionarPaciente = paciente => api.post('/pacientes', paciente)

const recuperarPacientePorIdentificador = identificador => api.get(`/pacientes/${identificador}`)

const recuperarPacientePorIdentificadorPublico = identificadorPublico => api.get(`/pacientes/${identificadorPublico}`)

const listarPacientes = (filtros, pagina, ordenacao, tamanhoDaPagina = 10) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros && filtros.termo ? `&termo=${filtros.termo}` : ''
  const parametroStatus = filtros && filtros.ativo && filtros.ativo.length === 1 ? `&ativos=${filtros.ativo[0] === 'Ativo'}` : ''
  const ordem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=-identificador'
  const tamanhoDaPaginaFormatodo = `&tamanhoDaPagina=${tamanhoDaPagina}`

  return api.get(`/pacientes${paginacao}${tamanhoDaPaginaFormatodo}${parametroTermo}${parametroStatus}${ordem}`)
}

const recuperarFotoDoPacientePorIdentificador = (identificador, tamanho = constantes.tamanhoDaFoto.miniatura) => api.get(`/pacientes/${identificador}/foto?tamanho=${tamanho}`, { responseType: 'arraybuffer' })
const salvarPaciente = paciente => api.put('/pacientes/' + paciente.identificador, paciente)
const adicionarTagNoPaciente = (identificadorDoPaciente, tag) => api.post(`/pacientes/${identificadorDoPaciente}/tags`, { tag })
const removerTagDoPaciente = (identificadorDoPaciente, tag) => api.delete(`/pacientes/${identificadorDoPaciente}/tags/${tag}`)
const listarTagsDePacientes = () => api.get('/tags/ativas/pacientes?ordem=texto&pagina=1&tamanhoDaPagina=1000000')

export default {
  adicionarTagNoPaciente,
  adicionarPaciente,
  listarPacientes,
  recuperarFotoDoPacientePorIdentificador,
  recuperarPacientePorIdentificador,
  recuperarPacientePorIdentificadorPublico,
  removerTagDoPaciente,
  listarTagsDePacientes,
  salvarPaciente
}
