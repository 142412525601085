import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import {adicionarNotificacoesDaApi} from '../notificacoes'

import {
  ALTERAR_CONFIGURACAO_DO_USUARIO,
  ALTEROU_CONFIGURACAO_DO_USUARIO,
  ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO,
  RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
} from '../tipos'

const alterouConfiguracoesDoUsuario = configuracoes => ({
  type: ALTEROU_CONFIGURACAO_DO_USUARIO,
  configuracoes,
})

const erroAoAlterarConfiguracoesDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO,
  erro,
  parametros
})

export const alterarConfiguracoesDoUsuario = configuracao => async dispatch => {
  dispatch({ type: ALTERAR_CONFIGURACAO_DO_USUARIO })

  try {
    const resultado = await api.alterarConfiguracoesDoUsuario(configuracao)

    dispatch(alterouConfiguracoesDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarConfiguracoesDoUsuario(erro, { configuracao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar as configurações do usuário.')))

    return false
  }
}

const recuperouConfiguracoesDoUsuarioLogado = configuracoes => ({
  type: RECUPEROU_CONFIGURACOES_DO_USUARIO_LOGADO,
  configuracoes,
})

const erroAoRecuperarConfiguracoesDoUsuarioLogado = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  erro,
  parametros
})

export const recuperarConfiguracoesDoUsuarioLogado = () => async dispatch => {
  dispatch({ type: RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO })

  try {
    const resultado = await api.recuperarConfiguracoesDoUsuarioLogado()

    dispatch(recuperouConfiguracoesDoUsuarioLogado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarConfiguracoesDoUsuarioLogado(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar as configurações do usuário logado.')))
  }
}

const recuperouConfiguracoesVigentesDoUsuarioLogado = configuracoesVigentes => ({
  type: RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  configuracoesVigentes,
})

const erroAoRecuperarConfiguracoesVigentesDoUsuarioLogado = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  erro,
  parametros
})

export const recuperarConfiguracoesVigentesDoUsuarioLogado = () => async dispatch => {
  dispatch({ type: RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO })

  try {
    const resultado = await api.recuperarConfiguracoesVigentesDoUsuarioLogado()

    dispatch(recuperouConfiguracoesVigentesDoUsuarioLogado(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarConfiguracoesVigentesDoUsuarioLogado(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar as configurações vigentes do usuário logado.')))
  }
}

const recuperouConfiguracoesDoUsuario = (configuracoes, identificadorDoUsuario) => ({
  type: RECUPEROU_CONFIGURACOES_DO_USUARIO,
  configuracoes,
  identificadorDoUsuario
})

const erroAoRecuperarConfiguracoesDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO,
  erro,
  parametros
})

export const recuperarConfiguracoesDoUsuario = identificadorDoUsuario => async dispatch => {
  dispatch({ type: RECUPERAR_CONFIGURACOES_DO_USUARIO })

  try {
    const resultado = await api.recuperarConfiguracoesDoUsuario(identificadorDoUsuario)

    dispatch(recuperouConfiguracoesDoUsuario(resultado.data.dados, identificadorDoUsuario))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarConfiguracoesDoUsuario(erro, { identificadorDoUsuario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar as configurações do usuário.')))
  }
}

const recuperouConfiguracoesVigentesDoUsuario = configuracoesVigentes => ({
  type: RECUPEROU_CONFIGURACOES_VIGENTES_DO_USUARIO,
  configuracoesVigentes
})

const erroAoRecuperarConfiguracoesVigentesDoUsuario = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  erro,
  parametros
})

export const recuperarConfiguracoesVigentesDoUsuario = (identificadorDoUsuario, unidadeDoUsuarioLogado) => async dispatch => {
  dispatch({ type: RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO })

  try {
    const resultado = await api.recuperarConfiguracoesVigentesDoUsuario(identificadorDoUsuario, unidadeDoUsuarioLogado)

    dispatch(recuperouConfiguracoesVigentesDoUsuario(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarConfiguracoesVigentesDoUsuario(erro, { identificadorDoUsuario, unidadeDoUsuarioLogado }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar as configurações vigentes do usuário.')))
  }
}