import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT
} from '../tipos'

const listouFrequenciasNaEscalaZarit = frequenciasNaEscalaZarit => ({
  type: LISTOU_FREQUENCIAS_NA_ESCALA_ZARIT,
  frequenciasNaEscalaZarit,
})

const erroAoListarFrequenciasNaEscalaZarit = erro => ({
  type: ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  erro,
})

export const listarFrequenciasNaEscalaZarit = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT })

    try {
      const resultado = await api.listarFrequenciasNaEscalaZarit()

      dispatch(listouFrequenciasNaEscalaZarit(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarFrequenciasNaEscalaZarit(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as frequências na escala Zarit.')))
    }
  }

  acao.meta = {
    cache: LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT
  }

  return acao
}

const listouAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit = avaliacoesDaSobrecargaDoCuidador => ({
  type: LISTOU_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  avaliacoesDaSobrecargaDoCuidador,
})

const erroAoListarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit = erro => ({
  type: ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  erro,
})

export const listarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT })

    try {
      const resultado = await api.listarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit()

      dispatch(listouAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarAvaliacoesDaSobrecargaDoCuidadorNaEscalaZarit(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as avaliações da sobrecarga do cuidador na escala Zarit.')))
    }
  }

  acao.meta = {
    cache: LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT
  }

  return acao
}