import React, { useState, useEffect, useRef } from 'react'
import { inverterCor } from '../../bibliotecas/cor'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'

export default function InputCor({ field: { name, value, ...rest }, form: { setFieldValue }, tabIndex }) {
  const [corDeFundo, setCorDeFundo] = useState(null)
  const [exibir, setExibir] = useState(false)
  const popover = useRef(null)
  const input = useRef(null)
  const cores = ['#E74C3C', '#9B59B6', '#87D932', '#F39C12', '#4482FF', '#704622', '#F1C40F', '#44C2FF', '#0956B4',
    '#F277C8', '#1ABC9C', '#5B5B5B', '#C4C340', '#ECEAB1', '#F2D088', '#838A7B', '#9D434D', '#C05D43', '#CC9D86']

  useCliqueForaDoElemento(popover, () => ocultar())

  useEffect(() => {
    if (value) {
      setCorDeFundo(inverterCor(value))
    } else {
      setCorDeFundo(null)
    }
  }, [value])

  const ocultar = () => {
    if (!exibir) return

    setExibir(false)
    input.current.blur()
  }

  const selecionar = cor => {
    setFieldValue(name, cor)
    ocultar()
  }

  const estilo = {
    backgroundColor: value,
    color: corDeFundo,
    cursor: 'pointer',
  }

  return (
    <>
      <input
        {...rest}
        onBlur={dados => !exibir && rest.onBlur(dados)}
        name={name}
        onFocus={() => setExibir(true)}
        ref={input}
        style={estilo}
        tabIndex={tabIndex}
        value={value}
      />
      <div className={`popover-color ${exibir && 'is-active'}`} ref={popover}>
        <p className='popover-color__title'>Cores</p>
        <div className='popover-color__grid'>
          {cores.map(cor => (
            <div
              key={cor}
              className='popover-color__item'
              onClick={() => selecionar(cor)}
              style={{ cursor: 'pointer' }}
            >
              <div
                className='popover-color__item__swatch'
                style={{ backgroundColor: cor || '#F8F8F8' }}
              />
              <p className='popover-color__item__value'>{cor}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}