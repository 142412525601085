import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import SlidingPane from 'react-sliding-pane'
import Spinner from '../spinner'
import {useTarefas} from './contexto'
import Agendamento from './campos/agendamento'
import Atendimento from './campos/atendimento'
import Categoria from './campos/categoria'
import Comentarios from './campos/comentarios'
import Paciente from './campos/paciente'
import Data from './campos/data'
import Descricao from './campos/descricao'
import Encaminhamento from './campos/encaminhamento'
import EscalaAmbientalDeRiscoDeQueda from './campos/escala-ambiental-de-risco-de-queda'
import Informacao from './campos/informacao'
import Ivcf from './campos/ivcf'
import Responsavel from './campos/responsavel'
import Urgencia from './campos/urgencia'
import ListaDeOpcoesMultiplas from './campos/lista-de-opcoes-multiplas'
import Momento from './campos/momento'
import MotivoDoCancelamento from './campos/motivo-do-cancelamento'
import NumeroInteiro from './campos/numero-inteiro'
import TelefoneDeContato from './campos/telefone-de-contato'
import Texto from './campos/texto'
import TextoLongo from './campos/texto-longo'
import Prazo from './campos/prazo'
import Atividades from './campos/atividades'
import {CardCabecalho, CardEscrita, CardLeitura} from './card'
import {subTitle, title} from './campos/atividades/cabecalho'
import {formatarDataParaFormatoUniversal} from '../../bibliotecas/data'

import {
  DEFINIR_CATEGORIA_DA_TAREFA,
  DEFINIR_DATA_DA_TAREFA,
  DEFINIR_DESCRICAO_DA_TAREFA,
  DEFINIR_MOMENTO_DA_TAREFA,
  DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIR_PACIENTE_DA_TAREFA,
  DEFINIR_PRAZO_DA_TAREFA,
  DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA,
  DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA,
  DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA,
  DEFINIR_TEXTO_DA_TAREFA,
  DEFINIR_TEXTO_LONGO_DA_TAREFA,
  DEFINIR_URGENCIA_DA_TAREFA,
  DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  LISTAR_CATEGORIAS_DO_TAREFAS,
  LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  LISTAR_PACIENTES_DO_TAREFAS,
  LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  LISTAR_URGENCIAS_DO_TAREFAS,
  MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
} from '../../acoes/tipos'

export default function Formulario({ match, limparTarefaAtual, tarefa = {}, registrarTarefa, removerImagensDoTarefas, origemProntuario = false, fechar, abaAtual }) {
  const { identificador } = match.params
  const identiticadorDaTarefaAtual = origemProntuario ? tarefa.identificador : identificador
  const tipo = tarefa && tarefa.tipo
  const statusDaTarefa = tarefa && tarefa.status

  const abaParaVoltar = abaAtual || 'minhas'

  const tarefasRef = useRef(null)
  const [exibirAtividades, setExibirAtividades] = useState(false)

  function tamanhoPainel() {
    if (tarefasRef.current && tarefasRef.current.offsetWidth <= 1024) return '100%'
    return '875px'
  }

  const configuracao = useMemo(() => {
    if (!tipo) return null
    if (!tipo.status) return null
    if (!statusDaTarefa) return null

    let componentesFiltrados = []
    let transicoes = []

    let componentesLeitura = []
    let componentesEscrita = []
    let componentesEmModoLeitura = []

    const componentesMultiplos = ['lista_de_opcoes_multiplas', 'texto', 'texto_longo', 'numero_inteiro', 'momento', 'data', 'informacao']

    const statusOrdenados = tipo.status.filter(x => x.nome === statusDaTarefa)
      .concat(tipo.status.filter(x => x.nome !== statusDaTarefa))

    statusOrdenados.forEach(status => {
      const ehOMesmoStatusDaTarefa = status.nome === statusDaTarefa

      if (ehOMesmoStatusDaTarefa) {
        status.transicoes.forEach(x => {
          const transicao = {
            ...x,
            tipo: statusOrdenados.find(t => t.nome === x.status) ? statusOrdenados.find(t => t.nome === x.status).tipo : ''
          }

          transicoes.push(transicao)
        })
      }

      status.componentes.forEach(x => {
        const ehComponenteMultiplo = componentesMultiplos.includes(x.nome)
        const componenteJahFoiFiltrado = componentesFiltrados.find(y => ehComponenteMultiplo ? y.configuracoes.nome === x.configuracoes.nome : y.nome === x.nome)

        if (!componenteJahFoiFiltrado) {
          const componente = {
            ...x,
            configuracoes: {
              ...x.configuracoes,
              obrigatorio: x.configuracoes.obrigatorio === 'true' || (x.configuracoes.minimo && x.configuracoes.minimo !== 0),
              leitura: !ehOMesmoStatusDaTarefa,
              exibicao: x.configuracoes.exibicao ? x.configuracoes.exibicao : '',
              preCadastro: x.configuracoes.pre_cadastro ? x.configuracoes.pre_cadastro === 'true' : false,
              exibirTelefones: x.configuracoes.exibir_telefones ? x.configuracoes.exibir_telefones === 'true' : false,
              copiar: x.configuracoes.copiar ? x.configuracoes.copiar === 'true' : false,
              linkProAgendamento: x.configuracoes.link_pro_agendamento ? x.configuracoes.link_pro_agendamento === 'true' : false,
            }
          }

          if (componente.configuracoes.leitura) {
            componentesLeitura.push(componente)
          } else if (componente.configuracoes.exibicao === 'modo_leitura') {
            componente.configuracoes.leitura = true
            componentesEmModoLeitura.push(componente)
          } else if (componente.configuracoes.exibicao !== 'oculto') {
            componentesEscrita.push(componente)
          }

          componentesFiltrados.push(componente)
        }
      })
    })

    Array.prototype.push.apply(componentesLeitura, componentesEmModoLeitura)

    return { componentesLeitura, componentesEscrita, transicoes }
  }, [tipo, statusDaTarefa])

  useEffect(() => {
    if (!origemProntuario) {
      const desregistrar = registrarTarefa(identiticadorDaTarefaAtual)

      return () => {
        removerImagensDoTarefas()
        desregistrar()
        limparTarefaAtual()
      }
    }
  }, [identiticadorDaTarefaAtual, registrarTarefa, limparTarefaAtual, removerImagensDoTarefas, origemProntuario])

  const contexto = useTarefas()

  const definirResponsavelDaTarefa = (identificadorDaTarefa, valor) => {
    const tipoDoResponsavel = typeof valor === 'string' && valor.split("__")[0]
    const tipo = tipoDoResponsavel ? tipoDoResponsavel : tarefa.responsavel?.tipo

    const identificadorDoResponsavel = typeof valor === 'string' && valor.split("__")[1]
    const identificador = identificadorDoResponsavel ? identificadorDoResponsavel : null

    if (tipo === "profissional") {
      return contexto.definirProfissionalResponsavelDaTarefa(identificadorDaTarefa, identificador)
    }

    if (tipo === "setor") {
      return  contexto.definirSetorResponsavelDaTarefa(identificadorDaTarefa, identificador)
    }
  }

  function renderizarComponente(componente) {
    if (!componente) return null

    switch (componente.nome) {
      case 'agendamento':
        return (
          <>
            <Agendamento
              {...componente.configuracoes}
              key={componente.nome}
              valor={tarefa.agendamentos}
              paciente={tarefa.paciente}
            />
          </>
        )
      case 'atendimento':
        return (
          <>
            <Atendimento
              {...componente.configuracoes}
              key={componente.nome}
              valor={tarefa.atendimentos}
            />
          </>
        )
      case 'categoria':
        return (
          <Spinner operacoes={[DEFINIR_CATEGORIA_DA_TAREFA, LISTAR_CATEGORIAS_DO_TAREFAS]}>
            <Categoria
              {...componente.configuracoes}
              definir={valor => contexto.definirCategoriaDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.categoria}
            />
          </Spinner>
        )
      case 'comentarios':
        return (
          <>
            <Comentarios
              key={componente.nome}
              identificadorDaTarefa={identiticadorDaTarefaAtual}
              statusDaTarefa={statusDaTarefa}
              tipoDaTarefa={tipo}
              valor={tarefa.comentarios}
              ehFormulario={true}
            />
          </>
        )
      case 'data':
        return (
          <Spinner operacoes={[DEFINIR_DATA_DA_TAREFA]}>
            <Data
              {...componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.datas[componente.configuracoes.nome]}
              definir={valor => contexto.definirDataDaTarefa(tarefa.identificador, componente.configuracoes.nome, formatarDataParaFormatoUniversal(valor))}
            />
          </Spinner>
        )
      case 'descricao':
        return (
          <Spinner operacoes={[DEFINIR_DESCRICAO_DA_TAREFA]}>
            <Descricao
              {...componente.configuracoes}
              definir={valor => contexto.definirDescricaoDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.descricao}
            />
          </Spinner>
        )
      case 'encaminhamento':
        return (
          <>
            <Encaminhamento
              {...componente.configuracoes}
              key={componente.nome}
              valor={tarefa.encaminhamentos}
            />
          </>
        )
      case 'escala_ambiental_de_risco_de_queda':
        return (
          <>
            <EscalaAmbientalDeRiscoDeQueda
              {...componente.configuracoes}
              key={componente.nome}
              valor={tarefa.escalasAmbientaisDeRiscoDeQueda}
            />
          </>
        )
      case 'informacao':
        return <><Informacao
          {...componente.configuracoes}
          key={componente.configuracoes.texto} /></>
      case 'ivcf':
        return (
          <>
            <Ivcf
              {...componente.configuracoes}
              key={componente.nome}
              valor={tarefa.ivcfs}
            />
          </>
        )
      case 'lista_de_opcoes_multiplas':
        return (
          <Spinner operacoes={[MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA, DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA]}>
            <ListaDeOpcoesMultiplas
              {...componente.configuracoes}
              configuracoes={componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.listasDeOpcoesMultiplas[componente.configuracoes.nome]}
              marcar={(lista, item) => contexto.marcarItemNaListaDeOpcoesMultiplasDaTarefa(tarefa.identificador, lista, item)}
              desmarcar={(lista, item) => contexto.desmarcarItemNaListaDeOpcoesMultiplasDaTarefa(tarefa.identificador, lista, item)}
            />
          </Spinner>
        )
      case 'momento':
        return (
          <Spinner operacoes={[DEFINIR_MOMENTO_DA_TAREFA]}>
            <Momento
              {...componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.momentos[componente.configuracoes.nome]}
              definir={valor => contexto.definirMomentoDaTarefa(tarefa.identificador, componente.configuracoes.nome, valor)}
            />
          </Spinner>
        )
      case 'motivo_de_cancelamento':
        return (
          <Spinner operacoes={[DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA, LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS]}>
            <MotivoDoCancelamento
              {...componente.configuracoes}
              definir={valor => contexto.definirMotivoDoCancelamentoDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.motivoDeCancelamento}
            />
          </Spinner>
        )
      case 'numero_inteiro':
        return (
          <Spinner operacoes={[DEFINIR_NUMERO_INTEIRO_DA_TAREFA]}>
            <NumeroInteiro
              {...componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.numerosInteiros[componente.configuracoes.nome]}
              definir={valor => contexto.definirNumeroInteiroDaTarefa(tarefa.identificador, componente.configuracoes.nome, valor)}
            />
          </Spinner>
        )
      case 'paciente':
        return (
          <Spinner operacoes={[DEFINIR_PACIENTE_DA_TAREFA, LISTAR_PACIENTES_DO_TAREFAS]}>
            <Paciente
              {...componente.configuracoes}
              definir={valor => contexto.definirPacienteDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.paciente}
            />
          </Spinner>
        )
      case 'prazo':
        return (
          <Spinner operacoes={[DEFINIR_PRAZO_DA_TAREFA]}>
            <Prazo
              {...componente.configuracoes}
              definir={valor => contexto.definirPrazoDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.prazo}
              criadaEm={tarefa.criadaEm}
            />
          </Spinner>
        )
      case 'responsavel':
        return (
          <Spinner operacoes={[DEFINIR_PROFISSIONAL_RESPONSAVEL_DA_TAREFA, LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS, DEFINIR_SETOR_RESPONSAVEL_DA_TAREFA, LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS]}>
            <Responsavel
              {...componente.configuracoes}
              definir={valor => definirResponsavelDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.responsavel}
              valorPaciente={tarefa.paciente}
            />
          </Spinner>
        )
      case 'telefone_de_contato':
        return (
          <Spinner operacoes={[DEFINIR_TELEFONE_DE_CONTATO_DA_TAREFA]}>
            <TelefoneDeContato
              {...componente.configuracoes}
              definir={valor => contexto.definirTelefoneDeContatoDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.telefoneDeContato}
            />
          </Spinner>
        )
      case 'texto':
        return (
          <Spinner operacoes={[DEFINIR_TEXTO_DA_TAREFA]}>
            <Texto
              {...componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.textos[componente.configuracoes.nome]}
              definir={valor => contexto.definirTextoDaTarefa(tarefa.identificador, componente.configuracoes.nome, valor)}
            />
          </Spinner>
        )
      case 'texto_longo':
        return (
          <Spinner operacoes={[DEFINIR_TEXTO_LONGO_DA_TAREFA]}>
            <TextoLongo
              {...componente.configuracoes}
              key={componente.configuracoes.nome}
              valor={tarefa.textosLongos[componente.configuracoes.nome]}
              definir={valor => contexto.definirTextoLongoDaTarefa(tarefa.identificador, componente.configuracoes.nome, valor)}
            />
          </Spinner>
        )

      case 'urgencia':
        return (
          <Spinner operacoes={[DEFINIR_URGENCIA_DA_TAREFA, LISTAR_URGENCIAS_DO_TAREFAS]}>
            <Urgencia
              {...componente.configuracoes}
              definir={valor => contexto.definirUrgenciaDaTarefa(tarefa.identificador, valor)}
              key={componente.nome}
              valor={tarefa.urgencia}
            />
          </Spinner>
        )
      default:
        break
    }
  }

  if (!configuracao) {
    return null
  }

  const comentarioEhEscrita = configuracao.componentesEscrita.find(x => x.nome === 'comentarios')
  const comentarioEhLeitura = configuracao.componentesLeitura.find(x => x.nome === 'comentarios')

  return (
    <div className='form-tarefas'>
      <div className='cards card justify-content-between mb-2'>
        <CardCabecalho
          identificador={identiticadorDaTarefaAtual}
          tarefa={tarefa}
          tipo={tipo}
          status={statusDaTarefa}
          configuracao={configuracao}
          alterarStatus={contexto.alterarStatusDaTarefa}
          onClickAtividades={() => setExibirAtividades(true)}
          fechar={fechar}
        />
      </div>
      <div className='cards'>
        <CardLeitura
          classname='card'
          chave='leitura'
          componentes={configuracao.componentesLeitura.filter(x => x.nome !== 'comentarios')}
          renderizar={renderizarComponente}
        />
        <CardEscrita
          classname='card'
          chave='escrita'
          componentes={configuracao.componentesEscrita.filter(x => x.nome !== 'comentarios')}
          renderizar={renderizarComponente}
        />
        {comentarioEhEscrita &&
          <CardEscrita
            classname='card'
            chave='escrita'
            componentes={configuracao.componentesEscrita.filter(x => x.nome === 'comentarios')}
            renderizar={renderizarComponente}
          />
        }
        {comentarioEhLeitura &&
          <CardLeitura
            classname='card'
            chave='leitura'
            componentes={configuracao.componentesLeitura.filter(x => x.nome === 'comentarios')}
            renderizar={renderizarComponente}
          />
        }
      </div>
      {!fechar &&
        <div className='list-btn'>
          <Link
            to={`/tarefas/${match.params.identificadorDoTipoDeTarefa}/${abaParaVoltar}`}
            className='button --light'
            title='Voltar para a Lista de Tarefas'
          >
            Voltar
          </Link>
        </div>
      }
      <SlidingPane
        isOpen={exibirAtividades}
        width={tamanhoPainel()}
        onRequestClose={() => setExibirAtividades(false)}
        closeIcon={<i className='icon icon-close'></i>}
        title={title(tarefa)}
        subtitle={subTitle(tarefa)}
        className='main-footer-2'
      >
        <Atividades
          fotos={contexto.fotos}
          identificadorDaTarefa={identiticadorDaTarefaAtual}
          itens={contexto.atividades && contexto.atividades[identiticadorDaTarefaAtual] && contexto.atividades[identiticadorDaTarefaAtual].atividades}
          recuperarFotoDoUsuarioPorIdentificador={contexto.recuperarFotoDoUsuarioPorIdentificador}
          registrarFirestoreDeAtividadesDaTarefa={contexto.registrarFirestoreDeAtividadesDaTarefa}
          responsaveis={contexto.profissionaisResponsaveis}
        />
      </SlidingPane>
    </div>
  )
}