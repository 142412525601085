import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { filtroPadrao } from '../../bibliotecas/texto'
import { Formulario as FormularioDoFormik, Input, Toglle, Select, Checkbox } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR,
  ALTERAR_DIAGNOSTICO,
  ADICIONAR_DIAGNOSTICO,
  REMOVER_DIAGNOSTICO,
  LISTAR_CIDS10
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    diagnostico,
    salvar,
    recuperarPeloIdentificador,
    remover,
    cids10
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_DIAGNOSTICO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_DIAGNOSTICO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_DIAGNOSTICO])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/diagnosticos`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o diagnóstico ${diagnostico.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(diagnostico)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[RECUPERAR_DIAGNOSTICO_PELO_IDENTIFICADOR, ALTERAR_DIAGNOSTICO, ADICIONAR_DIAGNOSTICO, REMOVER_DIAGNOSTICO, LISTAR_CIDS10]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: diagnostico.identificador || '',
              nome: diagnostico.nome || '',
              aprovado: diagnostico.status === 'aprovado' ? true : false,
              cid10: diagnostico.cid10 ? diagnostico.cid10.codigo : undefined,
              semCid10: diagnostico.semCid10
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
              cid10: Yup.string()
                .when('semCid10', {
                  is: val => !val,
                  then: Yup.string().required('Obrigatório')
                })
            })}
          >
            {({ errors, touched, values }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>{diagnostico.identificador ? 'Alterar Diagnóstico' : 'Adicionar Diagnóstico'}</h2>
                  <r-grid columns-md='12'>
                    <r-cell span={6} span-md={12} span-lg={12}>
                      <Toglle
                        nome='aprovado'
                        tabIndex={1}
                        titulo='Aprovado'
                      />
                      {(errors.aprovado && touched.aprovado) ? <label className='form-error'>{errors.aprovado}</label> : ''}
                    </r-cell>
                    <r-cell span={6} span-md={12} span-lg={12}>
                      <Input
                        type='text'
                        nome='nome'
                        tabIndex={2}
                        titulo='Nome *'
                      />
                    </r-cell>
                    <r-cell span={6} span-md={12} span-lg={12}>
                      <Checkbox
                        nome='semCid10'
                        tabIndex={3}
                        titulo='Este diagnóstico não tem CID-10'
                      />
                    </r-cell>
                    {!values.semCid10 &&
                      <r-cell span={6} span-md={12} span-lg={12}>
                        <Select
                          nome='cid10'
                          titulo='CID-10'
                          tabIndex={4}
                          itens={cids10.map(x => ({ codigo: x.codigo, nome: `${x.codigo} - ${x.nome}` }))}
                          campoCodigo='codigo'
                          campoDescricao='nome'
                          noOptionsMessage='Digite 3 caracteres para pesquisar'
                          filterOption={(option, inputValue) => {
                            if (inputValue.length >= 3) {
                              return filtroPadrao(inputValue, option.label)
                            }
                          }}

                        />
                      </r-cell>
                    }
                  </r-grid>
                </fieldset>
                {remover ?
                  <div className='list-btn-unidades'>
                    {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                    {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  </div>
                  :
                  <div className='list-btn'>
                    <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                    {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
                  </div>
                }
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}