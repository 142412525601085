import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE
} from '../../../tipos'

const listouAvaliacoesFisicasEFuncionaisDoPaciente = (identificadorDoPaciente, avaliacoes) => ({
  type: LISTOU_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  identificadorDoPaciente,
  avaliacoes
})

const erroAoListarAvaliacoesFisicasEFuncionaisDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  erro,
  parametros
})

export const listarAvaliacoesFisicasEFuncionaisDoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE })

  try {
    const resultado = await api.listarAvaliacoesFisicasEFuncionaisDoPaciente(identificadorDoPaciente)

    dispatch(listouAvaliacoesFisicasEFuncionaisDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarAvaliacoesFisicasEFuncionaisDoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as avaliações físicas e funcionais do paciente.')))
  }
}

const adicionouAvaliacaoFisicaEFuncionalDoPaciente = avaliacao => ({
  type: ADICIONOU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  avaliacao
})

const erroAoAdicionarAvaliacaoFisicaEFuncionalDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarAvaliacaoFisicaEFuncionalDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE })

  try {
    const resultado = await api.adicionarAvaliacaoFisicaEFuncionalDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(adicionouAvaliacaoFisicaEFuncionalDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesFisicasEFuncionaisDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarAvaliacaoFisicaEFuncionalDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar avaliação física e funcional do paciente.')))

    return false
  }
}

const alterouAvaliacaoFisicaEFuncionalDoPaciente = avaliacao => ({
  type: ALTEROU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  avaliacao
})

const erroAoAlterarAvaliacaoFisicaEFuncionalDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  erro,
  parametros
})

export const alterarAvaliacaoFisicaEFuncionalDoPaciente = (identificadorDoPaciente, avaliacao) => async dispatch => {
  dispatch({ type: ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE })

  try {
    const resultado = await api.alterarAvaliacaoFisicaEFuncionalDoPaciente(identificadorDoPaciente, avaliacao)

    dispatch(alterouAvaliacaoFisicaEFuncionalDoPaciente(resultado.data.dados))
    dispatch(listarAvaliacoesFisicasEFuncionaisDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarAvaliacaoFisicaEFuncionalDoPaciente(erro, { avaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar avaliação física e funcional do paciente.')))

    return false
  }
}

const removeuAvaliacaoFisicaEFuncionalDoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverAvaliacaoFisicaEFuncionalDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  erro,
  parametros
})

export const removerAvaliacaoFisicaEFuncionalDoPaciente = (identificadorDoPaciente, identificadorDaAvaliacao) => async dispatch => {
  dispatch({ type: REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE })

  try {
    const resultado = await api.removerAvaliacaoFisicaEFuncionalDoPaciente(identificadorDoPaciente, identificadorDaAvaliacao)

    dispatch(removeuAvaliacaoFisicaEFuncionalDoPaciente(identificadorDoPaciente))
    dispatch(listarAvaliacoesFisicasEFuncionaisDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverAvaliacaoFisicaEFuncionalDoPaciente(erro, { identificadorDoPaciente, identificadorDaAvaliacao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover avaliação física e funcional do paciente.')))

    return false
  }
}