import api from '../../api-major'

const adicionarInfeccaoDoTratoUrinarioDoPaciente = (identificador, infeccaoDoTratoUrinario) => api.post(`/pacientes/${identificador}/infeccoes-do-trato-urinario`, infeccaoDoTratoUrinario)
const alterarInfeccaoDoTratoUrinarioDoPaciente = (identificador, infeccaoDoTratoUrinario) => api.put(`/pacientes/${identificador}/infeccoes-do-trato-urinario/${infeccaoDoTratoUrinario.identificador}`, infeccaoDoTratoUrinario)
const removerInfeccaoDoTratoUrinarioDoPaciente = (identificador, identificadorDaInfeccaoDoTratoUrinario) => api.delete(`/pacientes/${identificador}/infeccoes-do-trato-urinario/${identificadorDaInfeccaoDoTratoUrinario}`)
const listarInfeccoesDoTratoUrinarioDoPaciente = identificador => api.get(`/pacientes/${identificador}/infeccoes-do-trato-urinario`)

export default { 
  adicionarInfeccaoDoTratoUrinarioDoPaciente,
  alterarInfeccaoDoTratoUrinarioDoPaciente,
  removerInfeccaoDoTratoUrinarioDoPaciente,
  listarInfeccoesDoTratoUrinarioDoPaciente
}