import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  LISTAR_MEDICOS,
  LISTOU_MEDICOS,
  ERRO_AO_LISTAR_MEDICOS
} from './tipos'

const listouMedicos = medicos => ({
  type: LISTOU_MEDICOS,
  medicos,
})

const erroAoListarMedicos = erro => ({
  type: ERRO_AO_LISTAR_MEDICOS,
  erro,
})

export const listarMedicos = () => async dispatch => {
  dispatch({ type: LISTAR_MEDICOS })

  try {
    const resultado = await api.listarMedicos()

    dispatch(listouMedicos(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarMedicos(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os medicos.')))
  }
}