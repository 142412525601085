import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  LISTAR_CASPS_16_DO_PACIENTE,
  LISTOU_CASPS_16_DO_PACIENTE,
  ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE,
  ADICIONAR_CASP_16_DO_PACIENTE,
  ADICIONOU_CASP_16_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE,
  ALTERAR_CASP_16_DO_PACIENTE,
  ALTEROU_CASP_16_DO_PACIENTE,
  ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE,
  REMOVER_CASP_16_DO_PACIENTE,
  REMOVEU_CASP_16_DO_PACIENTE,
  ERRO_AO_REMOVER_CASP_16_DO_PACIENTE
} from '../../../tipos'

const listouCasps16DoPaciente = (identificadorDoPaciente, casps16) => ({
  type: LISTOU_CASPS_16_DO_PACIENTE,
  identificadorDoPaciente,
  casps16
})

const erroAoListarCasps16DoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE,
  erro,
  parametros
})

export const listarCasps16DoPaciente = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_CASPS_16_DO_PACIENTE })

  try {
    const resultado = await api.listarCasps16DoPaciente(identificadorDoPaciente)

    dispatch(listouCasps16DoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCasps16DoPaciente(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os CASPs do paciente.')))
  }
}

const adicionouCasp16DoPaciente = casp16 => ({
  type: ADICIONOU_CASP_16_DO_PACIENTE,
  casp16
})

const erroAoAdicionarCasp16DoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarCasp16DoPaciente = (identificadorDoPaciente, casp16) => async dispatch => {
  dispatch({ type: ADICIONAR_CASP_16_DO_PACIENTE })

  try {
    const resultado = await api.adicionarCasp16DoPaciente(identificadorDoPaciente, casp16)

    dispatch(adicionouCasp16DoPaciente(resultado.data.dados))
    dispatch(listarCasps16DoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarCasp16DoPaciente(erro, { casp16 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao adicionar CASP-16 do paciente.')))

    return false
  }
}

const alterouCasp16DoPaciente = casp16 => ({
  type: ALTEROU_CASP_16_DO_PACIENTE,
  casp16
})

const erroAoAlterarCasp16DoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE,
  erro,
  parametros
})

export const alterarCasp16DoPaciente = (identificadorDoPaciente, casp16) => async dispatch => {
  dispatch({ type: ALTERAR_CASP_16_DO_PACIENTE })

  try {
    const resultado = await api.alterarCasp16DoPaciente(identificadorDoPaciente, casp16)

    dispatch(alterouCasp16DoPaciente(resultado.data.dados))
    dispatch(listarCasps16DoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarCasp16DoPaciente(erro, { casp16 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao alterar CASP-16 do paciente.')))

    return false
  }
}

const removeuCasp16DoPaciente = identificadorDoPaciente => ({
  type: REMOVEU_CASP_16_DO_PACIENTE,
  identificadorDoPaciente
})

const erroAoRemoverCasp16DoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_CASP_16_DO_PACIENTE,
  erro,
  parametros
})

export const removerCasp16DoPaciente = (identificadorDoPaciente, identificadorDoCasp16) => async dispatch => {
  dispatch({ type: REMOVER_CASP_16_DO_PACIENTE })

  try {
    const resultado = await api.removerCasp16DoPaciente(identificadorDoPaciente, identificadorDoCasp16)

    dispatch(removeuCasp16DoPaciente(identificadorDoPaciente))
    dispatch(listarCasps16DoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverCasp16DoPaciente(erro, { identificadorDoPaciente, identificadorDoCasp16 }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Erro ao remover CASP-16 do paciente.')))

    return false
  }
}