import {
  LISTOU_SALAS_DA_UNIDADE,
  RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
} from '../../acoes/tipos'

const estadoInicial = {
  itens: [],
  selecionada: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_SALAS_DA_UNIDADE: {
      return {
        itens: action.salas,
        selecionada: {},
      }

    }

    case RECUPEROU_SALA_DA_UNIDADE_PELO_IDENTIFICADOR: {
      return {
        ...state,
        selecionada: action.sala,
      }
    }

    default: {
      return state
    }
  }
}