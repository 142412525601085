import api from '../../api'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import notificacao from '../../bibliotecas/notificacao'

import {
  INICIAR_ATENDIMENTO,
  INICIOU_ATENDIMENTO,
  ERRO_AO_INICIAR_ATENDIMENTO,
  RECUPERAR_ATENDIMENTO,
  RECUPEROU_ATENDIMENTO,
  ERRO_AO_RECUPERAR_ATENDIMENTO,
  RECUPERAR_ATENDIMENTO_INICIADO,
  RECUPEROU_ATENDIMENTO_INICIADO,
  ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO,
  SALVAR_SECAO_DO_ATENDIMENTO,
  SALVOU_SECAO_DO_ATENDIMENTO,
  CANCELAR_ATENDIMENTO,
  CANCELOU_ATENDIMENTO,
  ERRO_AO_CANCELAR_ATENDIMENTO,
  ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO,
  FINALIZAR_ATENDIMENTO,
  FINALIZOU_ATENDIMENTO,
  ERRO_AO_FINALIZAR_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
} from '../tipos'

const iniciouAtendimento = (identificadorDoPaciente, atendimento) => ({
  type: INICIOU_ATENDIMENTO,
  atendimento,
  identificadorDoPaciente,
})

const erroAoIniciarAtendimento = (erro, parametros) => ({
  type: ERRO_AO_INICIAR_ATENDIMENTO,
  erro,
  parametros,
})

export const iniciarAtendimento = (identificadorDoPaciente, dadosDoAtendimento) => {
  const resultado = async dispatch => {
    dispatch({ type: INICIAR_ATENDIMENTO })

    try {
      const resultado = await api.iniciarAtendimento(identificadorDoPaciente, dadosDoAtendimento)
      const atendimento = resultado.data.dados

      dispatch(iniciouAtendimento(identificadorDoPaciente, atendimento))
      dispatch(adicionarNotificacoesDaApi(resultado))

      return atendimento.identificador
    } catch (erro) {
      dispatch(erroAoIniciarAtendimento(erro, { identificadorDoPaciente, dadosDoAtendimento }))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível iniciar o atendimento.')))

      return null
    }
  }

  resultado.meta = {
    debounce: {
      time: 300,
      key: INICIAR_ATENDIMENTO,
    }
  }

  return resultado
}

const recuperouAtendimento = (identificadorDoPaciente, atendimento) => ({
  type: RECUPEROU_ATENDIMENTO,
  atendimento,
  identificadorDoPaciente,
})

const erroAoRecuperarAtendimento = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ATENDIMENTO,
  erro,
  parametros,
})

export const recuperarAtendimento = (identificadorDoPaciente, identificador) => async dispatch => {
  dispatch({ type: RECUPERAR_ATENDIMENTO })

  try {
    const resultado = await api.recuperarAtendimento(identificadorDoPaciente, identificador)

    dispatch(recuperouAtendimento(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarAtendimento(erro, { identificadorDoPaciente, identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o atendimento.')))
  }
}

const recuperouAtendimentoIniciado = () => async dispatch => {
  dispatch({ type: RECUPEROU_ATENDIMENTO_INICIADO })
}

const erroAoRecuperarAtendimentoIniciado = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO,
  erro,
  parametros,
})

export const recuperarAtendimentoIniciado = identificadorDoPaciente => async dispatch => {
  dispatch({ type: RECUPERAR_ATENDIMENTO_INICIADO })

  try {
    const resultado = await api.listarAtendimentosIniciados()

    if (resultado.data.dados && resultado.data.dados.dados.length > 0) {
      const atendimento = resultado.data.dados.dados.find(x => x.paciente.identificador === parseInt(identificadorDoPaciente, 10))

      if (atendimento) {
        dispatch(recuperarAtendimento(atendimento.paciente.identificador, atendimento.identificador))
      }
    }

    dispatch(recuperouAtendimentoIniciado())
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarAtendimentoIniciado(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar o atendimento iniciado.')))
  }
}

const salvouSecaoDoAtendimento = (identificadorDoPaciente, secaoDoAtendimento) => ({
  type: SALVOU_SECAO_DO_ATENDIMENTO,
  identificadorDoPaciente,
  secaoDoAtendimento,
})

const erroAoSalvarSecaoDoAtendimento = (erro, parametros) => ({
  type: ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO,
  erro,
  parametros,
})

export const salvarSecaoDoAtendimento = (identificadorDoPaciente, atendimento, dadosDaSecao) => async dispatch => {
  dispatch({ type: SALVAR_SECAO_DO_ATENDIMENTO })

  try {
    const resultado = await api.salvarSecaoDoAtendimento(atendimento, dadosDaSecao)

    dispatch(salvouSecaoDoAtendimento(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data.dados
  } catch (erro) {
    dispatch(erroAoSalvarSecaoDoAtendimento(erro, { identificadorDoPaciente, atendimento, dadosDaSecao }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível salvar a seção do atendimento.')))

    return null
  }
}

const cancelouAtendimento = (identificadorDoPaciente, atendimento, status) => ({
  type: CANCELOU_ATENDIMENTO,
  identificadorDoPaciente,
  atendimento,
  status
})

const erroAoCancelarAtendimento = (erro, parametros) => ({
  type: ERRO_AO_CANCELAR_ATENDIMENTO,
  erro,
  parametros,
})

export const cancelarAtendimento = (identificadorDoPaciente, atendimento, dadosDoCancelamento) => async dispatch => {
  dispatch({ type: CANCELAR_ATENDIMENTO })

  try {
    const resultado = await api.cancelarAtendimento(atendimento, dadosDoCancelamento)

    dispatch(cancelouAtendimento(identificadorDoPaciente, resultado.data.dados, dadosDoCancelamento.status))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoCancelarAtendimento(erro, { atendimento, dadosDoCancelamento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível cancelar o atendimento.')))

    return false
  }
}

const finalizouAtendimento = (identificadorDoPaciente, atendimento) => ({
  type: FINALIZOU_ATENDIMENTO,
  atendimento,
  identificadorDoPaciente,
})

const erroAoFinalizarAtendimento = (erro, parametros) => ({
  type: ERRO_AO_FINALIZAR_ATENDIMENTO,
  erro,
  parametros,
})

export const finalizarAtendimento = (identificadorDoPaciente, atendimento) => async dispatch => {
  dispatch({ type: FINALIZAR_ATENDIMENTO })

  try {
    const resultado = await api.finalizarAtendimento(atendimento)

    dispatch(finalizouAtendimento(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoFinalizarAtendimento(erro, { atendimento }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível finalizar o atendimento.')))

    return false
  }
}

const fezDownloadDoAnexoDoAtendimento = (identificadorDoAnexo, bytesDoAnexo) => ({
  type: FEZ_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  identificadorDoAnexo,
  bytesDoAnexo
})

const erroAoFazerDownloadDoAnexoDoAtendimento = (erro, identificadorDoAnexo) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
  identificadorDoAnexo,
  erro
})

export const fazerDownloadDoAnexoDoAtendimento = (identificadorDoPaciente, identificadorDoAtendimento, identificadorDoAnexo) => async dispatch => {
  dispatch({
    type: FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO,
    identificadorDoAnexo
  })

  try {
    const resultado = await api.fazerDownloadDoAnexoDoAtendimento(identificadorDoPaciente, identificadorDoAtendimento, identificadorDoAnexo)

    dispatch(fezDownloadDoAnexoDoAtendimento(identificadorDoAnexo, resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoAnexoDoAtendimento(erro, identificadorDoAnexo))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do anexo do atendimento.')))
    return null
  }
}