import { connect } from 'react-redux'
import acoes from '../../../acoes'
import { ProvedorDeContextoDoTarefas } from './index'

const mapStateToProps = state => {
  const recuperarFotos = (dados = [], imagens = {}) => {
    let fotos = {}

    dados.filter(x => x.foto && imagens[x.foto]).forEach(item => {
      fotos[item.identificadorPublico] = imagens[item.foto]
    })

    return fotos
  }

  const recuperarAbaAtual = (filtros, tipoDaTarefaAtual) => {
    if (filtros) {
      const filtrosDaTarefa = filtros[tipoDaTarefaAtual]
      const abaAtual = filtrosDaTarefa && filtrosDaTarefa.abaAtual

      return abaAtual
    }

    return filtros
  }

  const recuperarFiltros = (filtros, tipoDaTarefaAtual) => {
    if (filtros) {
      const filtrosDaTarefa = filtros[tipoDaTarefaAtual]
      const filtrosDaAba = filtrosDaTarefa && filtrosDaTarefa[filtrosDaTarefa.abaAtual]

      return filtrosDaAba
    }

    return filtros
  }

  const recuperarPacientesDoFiltro = filtros => {
    if (filtros && filtros.pacientes) return filtros.pacientes

    return []
  }

  const recuperarTermoDoFiltro = filtros => {
    if (filtros && filtros.termo) return filtros.termo

    return ''
  }

  return {
    abaAtual: recuperarAbaAtual(state.tarefas.filtrosAtuais, state.tarefas.tipoDaTarefaAtual),
    atividades: state.tarefas.atividades,
    categorias: state.tarefas.categorias,
    enfermeiros: state.tarefas.enfermeiros,
    medicos: state.tarefas.medicos,
    motivosDeCancelamento: state.tarefas.motivosDeCancelamento,
    pacientes: state.tarefas.pacientes,
    pacientesDosFiltros: recuperarPacientesDoFiltro(recuperarFiltros(state.tarefas.filtrosAtuais, state.tarefas.tipoDaTarefaAtual)),
    fotos: recuperarFotos(state.tarefas.profissionaisResponsaveis, state.imagens),
    profissionaisResponsaveis: state.tarefas.profissionaisResponsaveis,
    setoresResponsaveis: state.tarefas.setoresResponsaveis,
    termoDoFiltroDePacientes: recuperarTermoDoFiltro(recuperarFiltros(state.tarefas.filtrosAtuais, state.tarefas.tipoDaTarefaAtual)),
    tiposDeTarefa: state.tarefas.tipos,
    urgencias: state.tarefas.urgencias,
    tipoDaTarefaAtual: state.tarefas.tipoDaTarefaAtual,
    semTipoSelecionado: state.tarefas.tipoDaTarefaAtual && state.tarefas.tipoDaTarefaAtual === 'todas',
    dashboardDaTarefaAtual: state.tarefas.dashboardDaTarefaAtual,
    sexos: state.sexos
  }
}

const acoesDoTarefas = {
  adicionarComentarioDaTarefa: acoes.adicionarComentarioDaTarefa,
  adicionarPreCadastroDoPacienteDaTarefa: acoes.adicionarPreCadastroDoPacienteDaTarefa,
  alterarFiltrosDoTarefas: acoes.alterarFiltrosDoTarefas,
  alterarComentarioDaTarefa: acoes.alterarComentarioDaTarefa,
  alterarStatusDaTarefa: acoes.alterarStatusDaTarefa,
  alterarTermoDoFiltroDePacientesDoTarefas: acoes.alterarTermoDoFiltroDePacientesDoTarefas,
  definirAbaAtual: acoes.definirAbaAtual,
  definirCategoriaDaTarefa: acoes.definirCategoriaDaTarefa,
  definirDataDaTarefa: acoes.definirDataDaTarefa,
  definirDescricaoDaTarefa: acoes.definirDescricaoDaTarefa,
  definirMomentoDaTarefa: acoes.definirMomentoDaTarefa,
  definirMotivoDoCancelamentoDaTarefa: acoes.definirMotivoDoCancelamentoDaTarefa,
  definirNumeroInteiroDaTarefa: acoes.definirNumeroInteiroDaTarefa,
  definirPacienteDaTarefa: acoes.definirPacienteDaTarefa,
  definirPrazoDaTarefa: acoes.definirPrazoDaTarefa,
  definirTipoDaTarefaAtual: acoes.definirTipoDaTarefaAtual,
  definirTextoDaTarefa: acoes.definirTextoDaTarefa,
  definirTextoLongoDaTarefa: acoes.definirTextoLongoDaTarefa,
  definirDashboardDaTarefaAtual: acoes.definirDashboardDaTarefaAtual,
  definirProfissionalResponsavelDaTarefa: acoes.definirProfissionalResponsavelDaTarefa,
  definirSetorResponsavelDaTarefa: acoes.definirSetorResponsavelDaTarefa,
  definirTelefoneDeContatoDaTarefa: acoes.definirTelefoneDeContatoDaTarefa,
  definirUrgenciaDaTarefa: acoes.definirUrgenciaDaTarefa,
  listarCategoriasDoTarefas: acoes.listarCategoriasDoTarefas,
  listarMotivosDeCancelamentoDoTarefas: acoes.listarMotivosDeCancelamentoDoTarefas,
  listarPacientesDoTarefas: acoes.listarPacientesDoTarefas,
  listarPacientesDosFiltrosDoTarefas: acoes.listarPacientesDosFiltrosDoTarefas,
  listarProfissionaisResponsaveisDoTarefas: acoes.listarProfissionaisResponsaveisDoTarefas,
  listarSetoresResponsaveisDoTarefas: acoes.listarSetoresResponsaveisDoTarefas,
  listarSexos: acoes.listarSexos,
  listarUrgenciasDoTarefas: acoes.listarUrgenciasDoTarefas,
  marcarItemNaListaDeOpcoesMultiplasDaTarefa: acoes.marcarItemNaListaDeOpcoesMultiplasDaTarefa,
  desmarcarItemNaListaDeOpcoesMultiplasDaTarefa: acoes.desmarcarItemNaListaDeOpcoesMultiplasDaTarefa,
  recuperarFotoDoUsuarioPorIdentificador: acoes.recuperarFotoDoUsuarioPorIdentificador,
  registrarFirestoreDeAtividadesDaTarefa: acoes.registrarFirestoreDeAtividadesDaTarefa,
  removerComentarioDaTarefa: acoes.removerComentarioDaTarefa,
  recuperarPacientePorIdentificadorPublico: acoes.recuperarPacientePorIdentificadorPublico
}

export default connect(mapStateToProps, acoesDoTarefas)(ProvedorDeContextoDoTarefas)