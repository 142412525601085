import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_SETOR_PELO_IDENTIFICADOR,
  ALTERAR_SETOR,
  ADICIONAR_SETOR,
  REMOVER_SETOR
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    setor,
    salvar,
    recuperarPeloIdentificador,
    remover
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_SETOR])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_SETOR])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_SETOR])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/configuracoes/setores`)
    }
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o setor: ${setor.nome}?`,
      buttons: [{
        className:'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(setor)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[RECUPERAR_SETOR_PELO_IDENTIFICADOR, ALTERAR_SETOR, ADICIONAR_SETOR, REMOVER_SETOR]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: setor.identificador || '',
              nome: setor.nome || '',
              observacoes: setor.observacoes || ''
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório'),
            })}
          >
            <fieldset>
              <h2 className='form-title'>{setor.identificador ? 'Alterar Setor' : 'Adicionar Setor'}</h2>
              <r-grid columns-md={12} columns-lg={12}>
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Input
                    type='text'
                    nome='nome'
                    tabIndex={1}
                    titulo='Nome *'
                  />
                </r-cell>
                <r-cell span={4} span-md={12} span-lg={12}>
                  <Input
                      as='textarea'
                      nome='observacoes'
                      tabIndex={2}
                      titulo='Observações'
                  />
                </r-cell>
              </r-grid>
            </fieldset>
            {remover ?
              <div className='list-btn-unidades'>
                {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
              </div>
              :
              <div className='list-btn'>
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
              </div>
            }
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}