import { renderizarComponente } from './renderizar-componente'

export const renderizarComponenteObrigatorio = (componentes = [], atendimento) => {
  const tipos = componentesObrigatoriosAoFinalizarAtendimento(componentes)

  if (tipos.length > 0) {
    return tipos.map(componente => renderizarComponente({ ...componentes.find(c => c.tipo === componente), validacaoAoFinalizar: true }, atendimento, 'finalizado_obrigatorio'))
  }

  return null
}

const componentesObrigatoriosAoFinalizarAtendimento = (componentes = []) => {
  let comps = []

  const filtrados = componentes.filter(x => x)

  filtrados.filter(x => x).forEach((componente) => {
    if (componente.metadados && componente.metadados.obrigatorio_ao_finalizar === 'true') {
      comps.push(componente.tipo)
    }
  })

  return comps
}