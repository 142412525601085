import React from 'react'
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert'
import { XLg } from '../../design/icones'

const topicosDeErros = situacao => {
  if (situacao.erro) {
    const topicos = situacao.erro.match(/(.+?(\|){3}(?:\s|$))/g)

    return topicos ? topicos : [situacao.erro]
  }

  if (situacao.erros) {
    return situacao.erros
  }

  return []
}

export function ComponenteLeitura(props) {
  const { icone, titulo, valor, renderizar = null } = props

  return (
    <div className='card-componente'>
      {icone && typeof icone === 'string' && <i className={`icon ${icone}`}></i>}
      {icone && typeof icone === 'object' && <div className='icon'>{icone}</div>}
      <label className='titulo'>{titulo}</label>
      {renderizar ? renderizar() : <div className='valor'>{valor}</div>}
    </div>
  )
}

export function CardCabecalho(props) {
  const { identificador, tarefa, tipo, status, configuracao, alterarStatus, fechar } = props

  const componentesObrigatorios = configuracao.componentesEscrita.filter(x => x.configuracoes.obrigatorio).length
  const tarefaPossuiErro = !tarefa.situacao.valida
  const erros = topicosDeErros(tarefa.situacao)
  const pendenciasCumpridas = (componentesObrigatorios - erros.length) > 0 ? componentesObrigatorios - erros.length : 0

  const confirmarEAlterar = transicao => {
    let statusParaTransitar = transicao.status

    if (transicao.status === '__status_anterior__') statusParaTransitar = 'Status anterior'

    const mensagem = transicao.configuracoes.mensagem_de_confirmacao || `Tem certeza que deseja alterar o status da tarefa para ${statusParaTransitar}?`

    confirmAlert({
      title: 'Confirmação',
      message: mensagem,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => alterarStatus(identificador, transicao.status)
      }, {
        label: 'Não'
      }]
    })
  }

  const configuracoesDoBotao = (transicao, chave) => {
    const validar = typeof transicao.configuracoes.validar_status_anterior === 'string' ? (transicao.configuracoes.validar_status_anterior.toLowerCase() === 'true') : false
    const transicaoAutomatica = typeof transicao.configuracoes.automatica === 'string' ? (transicao.configuracoes.automatica.toLowerCase() === 'true') : false

    if (transicaoAutomatica) {
      return
    }

    let classname = 'w-sm-100 '

    if (validar && tarefaPossuiErro) {
      classname += 'is-disabled '
    }

    if (transicao.tipo && transicao.tipo === 'cancelamento') {
      classname += 'button --danger'
    } else {
      classname += 'button --primary'
    }

    const tituloDoBotao = transicao.configuracoes.titulo || transicao.configuracoes.rotulo

    return (
      <button
        key={chave}
        type='button'
        className={classname}
        title={tituloDoBotao}
        onClick={() => confirmarEAlterar(transicao)}
      >
        {transicao.configuracoes.rotulo}
      </button>
    )
  }

  const botoesFixos = () => {
    return (
      <>
        {fechar &&
          <button
            type='button'
            title='Sair'
            className='button --light w-sm-100 p-1'
            onClick={() => fechar()}
          >
            <i className='icon'><XLg /></i>
          </button>
        }
      </>
    )
  }

  return (
    <>
      <div className='card-status'>
        <div className='tipo'><strong>{tipo.nome}</strong></div>
        <div className='tipo'>
          <span>
            <strong>Status: </strong>{status === '__status_anterior__' ? 'Status anterior' : status}
            <button
              data-tip
              type='button'
              className='botao-historico'
              onClick={props.onClickAtividades}
              title='Histórico'
            >
              <i className='icon icon-history'></i>
            </button>
          </span>
        </div>
        <div className='tipo'><strong>Criada por: </strong>{tarefa.criador.nome}</div>
      </div>
      <div className='card-status botoes-transicao'>
        <>
          <button
            data-tip
            data-for='alerta'
            key='botao-alerta'
            type='button'
            className={`button ${erros.length === 0 ? '--light' : '--alert'} w-sm-100 alerta`}
          >
            <span>
              <i className={`icon ${erros.length === 0 ? 'icon-check' : 'icon-attention-circled'}`} />
              {componentesObrigatorios > 0 && <>{`${pendenciasCumpridas}/${componentesObrigatorios}`}</>}
            </span>
          </button>
          {tarefaPossuiErro &&
            <ReactTooltip
              id='alerta'
              place='bottom'
              effect='solid'
              type='warning'
              className='tooltip-alerta'
              key='alerta'
            >
              <div><strong>Pendências</strong></div>
              <ul>{erros.map((x, index) => <li key={index}>{x}</li>)}</ul>
            </ReactTooltip>
          }
        </>
        {configuracao.transicoes.map((transicao, index) => configuracoesDoBotao(transicao, index))}
        {botoesFixos()}
      </div>
    </>
  )
}

export function CardLeitura(props) {
  const { classname, chave, componentes, renderizar } = props
  if (componentes.length === 0) return null

  function renderizarComponente(componente) {
    const child = renderizar(componente)

    if (!child) return null

    const valorEhArray = Array.isArray(child.props.children.props.valor)
    const possuiValor = valorEhArray ? child.props.children.props.valor.length > 0 : child.props.children.props.valor

    if (!possuiValor && possuiValor !== 0) return null
    return child
  }

  const componentesRenderizaveis = componentes.map(componente => renderizarComponente(componente))
  if (componentesRenderizaveis.every(x => x === null)) return null

  return (
    <div className='card-agrupamento'>
      <div className={classname}>
        {componentesRenderizaveis.map((componente, index) =>
          <React.Fragment key={`${chave}_${index}`}>
            {componente}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export function CardEscrita(props) {
  const { classname, chave, componentes, renderizar } = props
  if (componentes.length === 0) return null

  function renderizarComponente(componente) {
    const child = renderizar(componente)
    return <div className={classname}>{child}</div>
  }

  const componentesRenderizaveis = componentes.map(componente => renderizarComponente(componente))
  if (componentesRenderizaveis.every(x => x === null)) return null

  return (
    <div className='card-agrupamento'>
      {componentesRenderizaveis.map((componente, index) =>
        <React.Fragment key={`${chave}_${index}`}>
          {componente}
        </React.Fragment>
      )}
    </div>
  )
}