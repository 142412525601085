import { useEffect } from "react";
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'

const useAtalhos = (cursor, statusDaSecaoAtalho) => {
  const atalhoEvolucao = event => event.shiftKey && event.altKey && event.code === 'KeyE'
  const atalhoConduta = event => event.shiftKey && event.altKey && event.code === 'KeyC'
  const atalhoSalvar = event => event.ctrlKey && event.code === 'KeyS'
  const atalhoMemed = event => event.shiftKey && event.altKey && event.code === 'KeyM'
  const atalhoFinalizar = event => event.shiftKey && event.altKey && event.code === 'KeyF'
  const { usuario } = useUsuarioLogado()
  const ehMedico = usuario.profissao?.conselho?.nome === 'CRM'

  useEffect(() => {
    const atalhos = (event) => {   
      switch(true){
        case atalhoEvolucao(event):
          event.preventDefault()
          if(!scrollEvolucao.current){
            return
          }
          if (!document.getElementById('primeira-aba').className.includes('is-active')){            
            document.getElementById('primeira-aba').click()
          }
          scrollEvolucao.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
          break

        case atalhoConduta(event):
          event.preventDefault()
          if(!scrollConduta.current){
            return
          }
          if (!document.getElementById('primeira-aba').className.includes('is-active')){            
            document.getElementById('primeira-aba').click()
          }
          scrollConduta.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
          break

        case atalhoSalvar(event):
          event.preventDefault()
          if(statusDaSecaoAtalho && statusDaSecaoAtalho.length > 0){
            document.getElementById('salvar').click()
          }
          break
        
        case atalhoFinalizar(event):
          event.preventDefault()
          if(statusDaSecaoAtalho && statusDaSecaoAtalho.length === 0){      
            document.getElementById('finalizar').click() 
          }         
          break

        case ehMedico && atalhoMemed(event):
          event.preventDefault()
          document.getElementById('abrirMemed').click()
          break

        default:
          break
      }
    }

    if(cursor){
      cursor.addEventListener('keydown', atalhos)
      return () => cursor.removeEventListener('keydown', atalhos)
    }else{
      document.addEventListener('keydown', atalhos)
      return () => document.removeEventListener('keydown', atalhos)
    }
  }, [cursor, statusDaSecaoAtalho, ehMedico])
}

export default useAtalhos
export const scrollEvolucao = { current: null }
export const scrollConduta = { current: null }