import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'
import SlidingPane from 'react-sliding-pane'
import { Link, useParams } from 'react-router-dom'
import Modal from 'react-modal'
import StickyBox from 'react-sticky-box'
import { WithContext as ReactTags } from 'react-tag-input'
import { AgrupamentoDeColunasDinamico, Coluna, LinhaExpandida, Tabela } from '../tabela'
import { useTarefas } from './contexto'
import { subTitle, title } from './campos/atividades/cabecalho'
import Comentarios from './campos/comentarios'
import Atividades from './campos/atividades'
import Filtros from './filtros'
import Tipo from '../barra-de-tarefas/cards/campos/tipo'
import Paginacao from './paginacao'
import moment from 'moment'
import chroma from 'chroma-js'
import constantes from '../../configuracoes/constantes'
import useCliqueForaDoElemento from '../../bibliotecas/clique-fora-do-elemento'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { alterarEspacoPorUnderline, removerAcentos } from '../../bibliotecas/texto'
import {
  diferencaEntreDataEAtualNoFormatoSegundosENanosegundosReduzido,
  formatarSegundosENanosegundosParaFormatoLocal,
  formatarSegundosENanosegundosParaMoment
} from '../../bibliotecas/data'

import {
  Alarm,
  Calendario,
  Chat,
  Diagram,
  ExclamationOctagon,
  FileText,
  Hash,
  History,
  Number,
  Phone,
  PlusCircle, Refer,
  Responsavel,
  Stethoscope,
  Tag,
  Undo,
  User,
  XOctagon
} from '../../design/icones'

export default function Lista({ filtros, itens, removerImagensDoTarefas, alturaAbas }) {
  const {
    abaAtual,
    alterarFiltrosDoTarefas,
    atividades,
    dashboardDaTarefaAtual,
    definirDashboardDaTarefaAtual,
    definirTipoDaTarefaAtual,
    fotos,
    listarProfissionaisResponsaveisDoTarefas,
    profissionaisResponsaveis,
    recuperarFotoDoUsuarioPorIdentificador,
    registrarFirestoreDeAtividadesDaTarefa,
    semTipoSelecionado,
    tiposDeTarefa,
    tipoDaTarefaAtual,
  } = useTarefas()

  const { identificadorDoTipoDeTarefa } = useParams()

  useEffect(() => {
    if (!tipoDaTarefaAtual && identificadorDoTipoDeTarefa) {
      definirTipoDaTarefaAtual(identificadorDoTipoDeTarefa)
    }
  }, [definirTipoDaTarefaAtual, identificadorDoTipoDeTarefa, tipoDaTarefaAtual])

  useEffect(() => {
    if (!dashboardDaTarefaAtual) {
      const tarefa = tiposDeTarefa[identificadorDoTipoDeTarefa]
      const nomeDoDashboard = tarefa ? 'TAREFA_DE_'.concat((alterarEspacoPorUnderline(removerAcentos(tarefa.nome))).toUpperCase()) : ''

      definirDashboardDaTarefaAtual(nomeDoDashboard)
    }
  }, [definirDashboardDaTarefaAtual, identificadorDoTipoDeTarefa, tiposDeTarefa, dashboardDaTarefaAtual])

  useEffect(() => {
    if (tipoDaTarefaAtual && !filtros && (tiposDeTarefa[tipoDaTarefaAtual] || semTipoSelecionado)) {
      alterarFiltrosDoTarefas({ ...filtros }, identificadorDoTipoDeTarefa)
    }
  }, [alterarFiltrosDoTarefas, identificadorDoTipoDeTarefa, filtros, tipoDaTarefaAtual, tiposDeTarefa, semTipoSelecionado])

  useEffect(() => {
    if (tipoDaTarefaAtual && !filtros && (tiposDeTarefa[tipoDaTarefaAtual] || semTipoSelecionado)) {
      const tipo = tiposDeTarefa[tipoDaTarefaAtual] || {}
      const tipoCompleto = tipo && tiposDeTarefa[tipo.identificador]
      const ultimaVersao = tipoCompleto && tipoCompleto.versoes[tipoCompleto.versoes.length - 1]
      let filtrosDisponiveis = ultimaVersao && ultimaVersao.configuracoes.filtros ? ultimaVersao.configuracoes.filtros.split(',') : []

      if (filtrosDisponiveis.includes('finalizada') || semTipoSelecionado) {
        const filtrosIniciais = { ...filtros, finalizada: 'false' }

        alterarFiltrosDoTarefas(filtrosIniciais, identificadorDoTipoDeTarefa)
      }
    }
  }, [alterarFiltrosDoTarefas, identificadorDoTipoDeTarefa, filtros, tipoDaTarefaAtual, tiposDeTarefa, semTipoSelecionado])

  useEffect(() => {
    if (!profissionaisResponsaveis) {
      listarProfissionaisResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarProfissionaisResponsaveisDoTarefas, profissionaisResponsaveis])

  const listaTarefas = converterObjetoParaArray(itens)
  const responsaveisComFoto = profissionaisResponsaveis ? profissionaisResponsaveis.filter(x => x.foto).map(f => ({ identificador: f.identificador, identificadorPublico: f.identificadorPublico, foto: f.foto })) : []

  const tarefasRef = useRef(null)
  const arrayDeFiltros = useRef([])

  const [exibirFiltros, setExibirFiltros] = useState(false)
  const [exibirComentarios, setExibirComentarios] = useState(false)
  const [exibirAtividades, setExibirAtividades] = useState(false)
  const [identificadorDaTarefa, setIdentificadorDaTarefa] = useState('')
  const [statusDaTarefa, setStatusDaTarefa] = useState(null)
  const [tipoDaTarefa, setTipoDaTarefa] = useState(null)
  const [horarioAtual, setHorarioAtual] = useState(moment())
  const [listaDeFotos, setListaDeFotos] = useState([])

  useEffect(() => {
    if (listaTarefas && listaTarefas.length > 0 && responsaveisComFoto.length > 0) {
      const responsaveisNaListaComFoto = responsaveisComFoto.filter(x => listaTarefas.some(i => i.responsavel && i.responsavel.identificador === x.identificadorPublico))

      if (!responsaveisNaListaComFoto.every(x => listaDeFotos.find(i => i.identificadorPublico === x.identificadorPublico))) {
        setListaDeFotos(responsaveisNaListaComFoto)
      }
    }
  }, [listaTarefas, responsaveisComFoto, listaDeFotos])

  useEffect(() => {
    if (listaDeFotos.length > 0) {
      listaDeFotos.forEach(async item => {
        await recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
      })
    }
  }, [recuperarFotoDoUsuarioPorIdentificador, listaDeFotos])

  const abrirComentarios = item => {
    setIdentificadorDaTarefa(item.identificador)
    setStatusDaTarefa(item.status)
    setTipoDaTarefa(item.tipo)
    setExibirComentarios(true)
  }

  const abrirAtividades = item => {
    setIdentificadorDaTarefa(item.identificador)
    setExibirAtividades(true)
  }

  const temComentarioPorTipoDeTarefa = tarefa => {
    if (!tarefa.tipo) return false

    let existeComponenteComentario = false
    const tipo = tiposDeTarefa[tarefa.tipo.identificador]

    tipo && tipo.versoes.forEach((item) => {
      const status = item.status
      status.forEach(status => {
        existeComponenteComentario = status.componentes.some(x => x.nome === 'comentarios')
      })
    })

    return existeComponenteComentario
  }

  const renderizarColunas = useCallback((componente, index) => {
    const { nome, configuracoes, corDoTipo } = componente
    const key = nome + '.' + index

    switch (nome) {
      case 'categoria':
        return <Coluna key={key} icone={<Tag />} nome={configuracoes?.rotulo ?? 'CATEGORIA'} renderizar={item => item.categoria && item.categoria.nome} />

      case 'criador':
        return <Coluna key={key} icone={<PlusCircle />} nome={configuracoes?.rotulo ?? 'CRIADA POR'} renderizar={item => item.criador.nome} />

      case 'data_do_atendimento':
        return <Coluna key={key} icone={<Calendario />} nome={configuracoes?.rotulo} renderizar={item => item.momentos && formatarSegundosENanosegundosParaFormatoLocal(item.momentos[nome])} />

      case 'descricao':
        return <Coluna key={key} icone={<FileText />} nome={configuracoes?.rotulo ?? 'DESCRIÇÃO'} renderizar={item => item.descricao && <RenderizarTexto relato={item.descricao} identificador={item.identificador} />} />

      case 'encaminhamento':
        return <Coluna key={key} icone={<Refer size={26} />} nome='ENCAMINHADO PARA' renderizar={item => item.encaminhamentos.length > 0 && item.encaminhamentos[0].encaminhadoPara} />

      case 'motivo_de_cancelamento':
        return <Coluna key={key} icone={<XOctagon />} nome={configuracoes?.rotulo ?? 'MOTIVO DE CANCELAMENTO'} renderizar={item => item.motivoDeCancelamento && item.motivoDeCancelamento.nome} />

      case 'nota_da_clinica':
      case 'nota_do_profissional':
        return <Coluna key={key} icone={<Number />} nome={configuracoes?.rotulo} renderizar={item => item.numerosInteiros && item.numerosInteiros[nome]} />

      case 'paciente':
        return <Coluna key={key} icone={<User />} nome={configuracoes?.rotulo ?? 'PACIENTE'} renderizar={item => item.paciente && <RenderizarPaciente identificador={item.identificador} nome={item.paciente.nome} exibirTelefones={configuracoes?.exibir_telefones ?? false} telefones={item.paciente.telefones ?? []} />} />

      case 'prazo':
        return <Coluna key={key} icone={<Alarm />} nome='PRAZO' renderizar={item => renderizarPrazo(item, horarioAtual)} />

      case 'profissional_do_atendimento':
        return <Coluna key={key} icone={<Stethoscope />} nome={configuracoes?.rotulo} renderizar={item => item.textos && <RenderizarNome nome={item.textos[nome]} identificador={item.textos[nome] + '_' + index} />} />

      case 'responsavel':
        return <Coluna key={key} icone={<Responsavel />} nome={configuracoes?.rotulo ?? 'RESPONSÁVEL'} renderizar={item => item.responsavel && <RenderizarNome nome={item.responsavel.nome} identificador={item.responsavel.identificador} />} />

      case 'status':
        return <Coluna key={key} icone={<Diagram />} nome={configuracoes?.rotulo ?? 'STATUS'} campoDeDados='status' />

      case 'telefone_de_contato':
        return <Coluna key={key} icone={<Phone />} nome={configuracoes?.rotulo ?? 'TELEFONE DE CONTATO'} campoDeDados='telefoneDeContato' />

      case 'tipo':
        return <Coluna key={key} icone={<Hash />} nome='TIPO' renderizar={item => <Tipo valor={item.tipo.nome} cor={corDoTipo} origemTarefas={true} />} />

      case 'urgencia':
        return <Coluna key={key} icone={<ExclamationOctagon />} nome='URGÊNCIA' renderizar={item => item.urgencia && <RenderizarUrgencia nome={item.urgencia.nome} codigo={item.urgencia.codigo} />} />

      default:
        break
    }
  }, [horarioAtual])

  const colunasPorTarefa = useMemo(() => {
    function componentesPorTipoDeTarefa(tarefa, tiposDeTarefa) {
      if (!tarefa.tipo) return []

      const tipo = tiposDeTarefa[tarefa.tipo.identificador]
      const versao = tipo ? tipo.versoes.find(x => x.numero === tarefa.tipo.versao) : null
      const ehCriacaoAutomatica = versao && versao.configuracoes.automatica ? versao.configuracoes.automatica === 'true' : false

      let lista = [{ nome: 'status' }]

      if (!ehCriacaoAutomatica)
      lista.push({ nome: 'criador' })

      if (semTipoSelecionado) {      
        lista.unshift({ nome: 'tipo', corDoTipo: versao?.configuracoes?.cor })
      }

      tipo && tipo.versoes.forEach((item) => {
        item.status.forEach((status) => {
          status.componentes.forEach((componente) => {
            if (componente.configuracoes.nome) {
              lista.push({ nome: componente.configuracoes.nome, configuracoes: componente.configuracoes })
            } else {
              if (componente.nome === 'paciente') {
                if (semTipoSelecionado) {
                  lista.splice(2, 0, { ...componente })
                } else {
                  lista.splice(1, 0, { ...componente })
                }
              } else {
                lista.push({ ...componente })
              }
            }
          })
        })
      })

      lista.push({ nome: 'prazo' })

      return lista.reduce((unique, item) => unique.some(u => u.nome === item.nome) ? unique : [...unique, item], [])
    }

    let dados = {}

    tiposDeTarefa && listaTarefas.forEach(tarefa =>
      dados[tarefa.identificador] = componentesPorTipoDeTarefa(tarefa, tiposDeTarefa).map((c, index) => renderizarColunas(c, index)))

    return dados
  }, [listaTarefas, tiposDeTarefa, renderizarColunas, semTipoSelecionado])

  function tamanhoPainel() {
    if (tarefasRef.current && tarefasRef.current.offsetWidth <= 1024) return '100%'
    return '875px'
  }

  useEffect(() => {
    const interval = setInterval(() => setHorarioAtual(moment()), 60000)
    return () => clearInterval(interval)
  }, [setHorarioAtual])

  useEffect(() => { return () => removerImagensDoTarefas() }, [removerImagensDoTarefas])

  const recuperarFiltrosSelecionados = filtros => {
    if (!filtros) return []

    const filtroNaoEhDispensavel = (chave, valor) => {
      if (chave === 'termo' || chave === 'limite' || chave === 'pacientes' || chave === 'filtros' || chave === 'tiposParaExibir') return false
      if (Array.isArray(valor)) return valor.length > 0

      return chave === 'finalizada' ? valor : (valor && valor !== '')
    }

    const textoDoFiltro = (chave, valor) => {
      switch (chave) {
        case 'categoria':
          return <RecuperarFiltroDeCategoria valor={valor} />
        case 'finalizada':
          return <RecuperarFiltroDeFinalizada valor={valor} />
        case 'paciente':
          return <RecuperarFiltroDePaciente valor={valor} />
        case 'responsavel':
          return <RecuperarFiltroDeResponsavel valor={valor} />
        case 'status':
          return <> <Diagram size={20} />{`${valor}`}</>
        default:
          return `${chave}: ${valor}`
      }
    }

    return Object.keys(filtros).filter(key => filtroNaoEhDispensavel(key, filtros[key])).map(key => ({ id: key, text: textoDoFiltro(key, filtros[key]) }))
  }

  useEffect(() => {
    arrayDeFiltros.current = recuperarFiltrosSelecionados(filtros, tiposDeTarefa, tipoDaTarefaAtual)

    if (abaAtual === 'minhas') {
      arrayDeFiltros.current = arrayDeFiltros.current.filter(x => x.id !== 'responsavel')
    }
  }, [abaAtual, filtros, tiposDeTarefa, tipoDaTarefaAtual])

  const removerFiltroSelecionado = index => {
    const filtro = arrayDeFiltros.current[index] && arrayDeFiltros.current[index].id

    let novosFiltros = filtros
    delete novosFiltros[filtro]

    alterarFiltrosDoTarefas({ ...novosFiltros }, identificadorDoTipoDeTarefa)
  }

  const mostrarBotaoFiltrar = () => {
    if (semTipoSelecionado) return true

    const tipo = tiposDeTarefa[tipoDaTarefaAtual] || {}
    const tipoCompleto = tipo && tiposDeTarefa[tipo.identificador]
    const ultimaVersao = tipoCompleto && tipoCompleto.versoes[tipoCompleto.versoes.length - 1]
    const filtrosDisponiveis = ultimaVersao && ultimaVersao.configuracoes.filtros ? ultimaVersao.configuracoes.filtros.split(',') : []

    return filtrosDisponiveis.length > 0
  }

  const abrirFiltros = () => setExibirFiltros(true)
  const fecharFiltros = () => setExibirFiltros(false)

  return (
    <>
      <Modal
        isOpen={exibirFiltros}
        className='modal'
        contentLabel='Modal para selecionar filtros'
      >
        <Filtros
          filtrosAtuais={filtros}
          tipos={tiposDeTarefa}
          fechar={fecharFiltros}
        />
      </Modal>
      {mostrarBotaoFiltrar() &&
       <StickyBox className='filtros' offsetTop={alturaAbas}>
         <div className='header-page-tools d-inline-block'>
           <button className='button --primary --plus-short w-sm-100' onClick={abrirFiltros}>
             Filtrar
             <i className='icon icon-filter' />
           </button>
           <ReactTags
             classNames={{
               tags: 'tags-filtros-tarefas',
               tag: 'tag-filtros-tarefas',
               tagInput: 'tag-input-tarefas',
             }}
             delimiters={[188, 13]}
             allowDeleteFromEmptyInput={false}
             allowDragDrop={false}
             handleDelete={removerFiltroSelecionado}
             tags={arrayDeFiltros.current}
           />
         </div>
       </StickyBox>
      }
      <div ref={tarefasRef} className='fieldset-lista-de-tarefas'>
        <Tabela
          acoes={[
            item => (
              <Link
                to={`/tarefas/${tipoDaTarefaAtual}/tarefa/${item.identificador}`}
                className='table-items-expanded__bt --edit'
                title='Editar'
                key={item.identificador}
              >
                <i className='icon icon-pencil' />
              </Link>
            ),
            item => (temComentarioPorTipoDeTarefa(item) &&
              <div
                onClick={() => abrirComentarios(item)}
                className='table-items-expanded__bt --edit-fixed'
                title='Comentários' key={`${item.identificador}_comentario`}
              >
                <Chat />
                {item.comentarios && item.comentarios.length > 0 &&
                  <span className='updates-count'>{item.comentarios.length}</span>
                }
              </div>
            ),
            item => (
              <button
                onClick={() => abrirAtividades(item)}
                className='table-items-expanded__bt --edit-fixed'
                title='Atividades' key={`${item.identificador}_atividade`}
              >
                <History />
              </button>
            )
          ]}
          chave='identificador'
          className='table-items'
          expandida={true}
          dados={listaTarefas}
          titulo={false}
          exibirBotaoMais={false}
        >
          <LinhaExpandida className='col-xs-10 col-lg-12'>
            <AgrupamentoDeColunasDinamico
              colunas={colunasPorTarefa}
              chave='identificador'
              className='table-items-expanded__box-item-tarefas'
            />
          </LinhaExpandida>
        </Tabela>
        {listaTarefas.length > 0 && listaTarefas.length % constantes.numeroTarefasPorPagina === 0 &&
          <Paginacao filtros={filtros} identificadorDoTipoDeTarefa={identificadorDoTipoDeTarefa} />
        }
        <SlidingPane
          isOpen={exibirComentarios}
          width={tamanhoPainel()}
          onRequestClose={() => setExibirComentarios(false)}
          closeIcon={<i className='icon icon-close' />}
          title={itens[identificadorDaTarefa] && itens[identificadorDaTarefa].descricao}
          subtitle={itens[identificadorDaTarefa] && itens[identificadorDaTarefa].status}
        >
          <Comentarios
            identificadorDaTarefa={identificadorDaTarefa}
            statusDaTarefa={statusDaTarefa}
            tipoDaTarefa={tipoDaTarefa}
            valor={itens[identificadorDaTarefa] && itens[identificadorDaTarefa].comentarios}
          />
        </SlidingPane>
        <SlidingPane
          isOpen={exibirAtividades}
          width={tamanhoPainel()}
          onRequestClose={() => setExibirAtividades(false)}
          closeIcon={<i className='icon icon-close'></i>}
          title={itens[identificadorDaTarefa] && title(itens[identificadorDaTarefa])}
          subtitle={itens[identificadorDaTarefa] && subTitle(itens[identificadorDaTarefa])}
          className='main-footer-2'
        >
          <Atividades
            fotos={fotos}
            identificadorDaTarefa={identificadorDaTarefa}
            itens={atividades && atividades[identificadorDaTarefa] && atividades[identificadorDaTarefa].atividades}
            recuperarFotoDoUsuarioPorIdentificador={recuperarFotoDoUsuarioPorIdentificador}
            registrarFirestoreDeAtividadesDaTarefa={registrarFirestoreDeAtividadesDaTarefa}
            responsaveis={profissionaisResponsaveis}
          />
        </SlidingPane>
      </div>
    </>
  )
}

function renderizarPrazo(item, horarioAtual) {
  const { prazo, finalizadaEm, identificador } = item
  if (!prazo && horarioAtual) return null

  const prazoFormatado = formatarSegundosENanosegundosParaMoment(prazo)
  const finalizadaEmFormatado = formatarSegundosENanosegundosParaMoment(finalizadaEm)

  let valor = diferencaEntreDataEAtualNoFormatoSegundosENanosegundosReduzido(prazo)
  let atrasado = prazoFormatado.isSameOrBefore(horarioAtual)

  if (finalizadaEm) {
    valor = finalizadaEmFormatado.from(prazoFormatado, true)
    atrasado = prazoFormatado.isSameOrBefore(finalizadaEmFormatado)
  }

  if (valor.includes('anos')) {
    return
  }

  return (
    <>
      <span
        className={`assistence-end-task assistence-end__time-task ${(atrasado ? 'atrasado' : '')} ${finalizadaEm ? 'concluida' : ''}`}
        data-tip
        data-for={`prazo_${identificador}`}
      >
        {valor}
      </span>
      <ReactTooltip
        id={`prazo_${identificador}`}
        place='bottom'
        effect='solid'
        type={atrasado ? 'error' : 'success'}
        className='tooltip-nome'
        key={`prazo_${identificador}`}
      >
        <div>{`Prazo: ${prazoFormatado.format('DD/MM/YYYY HH:mm:ss')}`}</div>
      </ReactTooltip>
    </>
  )
}

function RenderizarTexto({ relato, identificador }) {
  const [exibirTooltipInfo, setExibirTooltipInfo] = useState(false)
  const [alturaDoTooltip, setAlturaDoTooltip] = useState(0)
  const [top, setTop] = useState('90%')
  const referenciaDoRelato = useRef(null)
  const posicaoAEsquerda = referenciaDoRelato.current && referenciaDoRelato.current.offsetLeft

  const tooltip = ReactDOM.findDOMNode(referenciaDoRelato.current)

  useEffect(() => {
    if (exibirTooltipInfo && tooltip && alturaDoTooltip > 0) {
      const posicaoVertical = tooltip.getBoundingClientRect().y
      const precisaReposicionar = posicaoVertical + alturaDoTooltip > window.innerHeight
      const reposicionaAcima = -(alturaDoTooltip + 6) + 'px'
      const reposicionaAbaixo = (tooltip.getBoundingClientRect().height * 2) + 'px'

      return precisaReposicionar ? setTop(reposicionaAcima) : setTop(reposicionaAbaixo)
    }
  }, [tooltip, exibirTooltipInfo, alturaDoTooltip])

  const elementosDoTooltip = useCallback(elemento => {
    if (elemento) {
      setAlturaDoTooltip(elemento.getBoundingClientRect().height)
    }
  }, [])

  useCliqueForaDoElemento(referenciaDoRelato, () => setExibirTooltipInfo(false))

  if (!relato) return null

  return (
    <>
      <div
        data-tip
        data-for={`info_relato_paciente_${identificador}`}
        onClick={() => setExibirTooltipInfo(true)}
        style={{ cursor: 'pointer' }}
        ref={referenciaDoRelato}
      >
        <pre className='table-items__mobile-value-tarefas'>{relato}</pre>
      </div>
      {exibirTooltipInfo &&
        <div className='tooltip__drop custom-scrollbar tooltip-descricao' ref={elementosDoTooltip} style={{ left: posicaoAEsquerda, top: top }}>
          <div className='tooltip__drop__body'>
            <div className='tooltip__drop__title'>
              Relato
              <button
                className='tooltip__drop__bt-close'
                type='button'
                onClick={() => setExibirTooltipInfo(false)}>
                <i className='icon-task icon-close'></i></button>
            </div>
            <div className='tooltip__drop__description'>
              <span><pre>{relato}</pre></span>
            </div>
          </div>
        </div>
      }
    </>
  )
}

function RenderizarNome({ nome, identificador }) {
  return (
    <>
      <div
        data-tip
        data-for={`nome_${identificador}`}
      >
        <div className='table-items__mobile-value-tarefas'>{nome}</div>
      </div>
      <ReactTooltip
        id={`nome_${identificador}`}
        place='bottom'
        effect='solid'
        type='info'
        className='tooltip-nome'
        key={`nome_${identificador}`}
      >
        <div>{nome}</div>
      </ReactTooltip>
    </>
  )
}

function RenderizarPaciente({ identificador, nome, exibirTelefones, telefones }) {
  const renderizarTelefones = () => {
    function ordenarTelefones(a, b) {
      return a.proprietario.localeCompare(b.proprietario)
    }

    return (
      <>
        <div>
          <hr className='separator__tooltip_paciente'/>
          <strong>Telefones:</strong>
        </div>
        {telefones.sort(ordenarTelefones).map((item, index) =>
          <React.Fragment key={index}>
          <span>
            <strong>
              {item.proprietario}
              {item.proprietario !== 'Paciente' &&
                <>{' '}{item.nome}{' ('}{item.grauDeRelacao}{')'}</>
              }
            </strong>
            {': '}
            {item.numero}
          </span>
            <br/>
          </React.Fragment>
        )}
      </>
    )
  }

  return (
    <>
      <div
        data-tip
        data-for={`nome_${identificador}`}
      >
        <div className='table-items__bcolor paciente-tarefas'>{nome}</div>
      </div>
      <ReactTooltip
        id={`nome_${identificador}`}
        place='bottom'
        effect='solid'
        type='info'
        className='tooltip-nome'
        key={`nome_${identificador}`}
      >
        <div>{nome}</div>
        {exibirTelefones && telefones.length > 0 && renderizarTelefones()}
      </ReactTooltip>
    </>
  )
}

function RenderizarUrgencia({ nome, codigo }) {
  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo.toUpperCase()) {
      case 'VERMELHO':
        return '#BD3035'
      case 'LARANJA':
        return '#ED7A17'
      case 'AMARELO':
        return '#F9C72D'
      case 'VERDE':
        return '#70C968'
      default:
        return ''
    }
  }

  const corDaFonte = cor => {
    const color = chroma(cor)
    return chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
  }

  return (
    <span
      className='table-items__bcolor urgencia-tarefas'
      style={{background: corDaUrgencia(codigo), color: corDaFonte(corDaUrgencia(codigo))}}
    >
      {nome}
    </span>
  )
}

function RecuperarFiltroDeCategoria({valor}) {
  const {listarCategoriasDoTarefas, categorias} = useTarefas()

  useEffect(() => {
    if (!categorias || categorias.length === 0) {
      listarCategoriasDoTarefas()
    }
  }, [listarCategoriasDoTarefas, categorias])

  if (categorias && categorias.find(x => x.codigo === valor)) {
    const nome = categorias.find(x => x.codigo === valor).nome
    return <><Tag size={20} /> {nome}</>
  }

  return <><Tag size={20} /> {valor}</>
}

function RecuperarFiltroDeFinalizada({ valor }) {
  if (valor === 'true') {
    return <><i className='icon-checked' /> Finalizadas </>
  }

  return <><Undo size={18} /> Não Finalizadas</>
}

function RecuperarFiltroDePaciente({ valor }) {
  const { listarPacientesDosFiltrosDoTarefas, pacientesDosFiltros, termoDoFiltroDePacientes, tipoDaTarefaAtual } = useTarefas()

  useEffect(() => {
    if (termoDoFiltroDePacientes) {
      listarPacientesDosFiltrosDoTarefas({ termo: termoDoFiltroDePacientes }, 1, { nome: 'nome', ordem: '+' }, 50, tipoDaTarefaAtual)
    }
  }, [listarPacientesDosFiltrosDoTarefas, termoDoFiltroDePacientes, tipoDaTarefaAtual])

  if (pacientesDosFiltros && pacientesDosFiltros.find(x => x.identificador === valor)) {
    const nome = pacientesDosFiltros.find(x => x.identificador === valor).nome
    return <><User size={18} /> {nome}</>
  }

  return <><User size={18} /> {valor}</>
}

function RecuperarFiltroDeResponsavel({ valor }) {
  const {
    listarProfissionaisResponsaveisDoTarefas,
    profissionaisResponsaveis,
    listarSetoresResponsaveisDoTarefas,
    setoresResponsaveis,
  } = useTarefas()

  useEffect(() => {
    if (!profissionaisResponsaveis) {
      listarProfissionaisResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarProfissionaisResponsaveisDoTarefas, profissionaisResponsaveis])

  useEffect(() => {
    if (!setoresResponsaveis) {
      listarSetoresResponsaveisDoTarefas({}, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarSetoresResponsaveisDoTarefas, setoresResponsaveis])

  if (profissionaisResponsaveis?.find(x => x.identificadorPublico === valor)) {
    const nome = profissionaisResponsaveis.find(x => x.identificadorPublico === valor).nome
    return <><Responsavel size={20} /> {nome}</>
  }

  if (setoresResponsaveis?.find(x => x.identificador === valor)) {
    const nome = setoresResponsaveis.find(x => x.identificador === valor).nome
    return <><Responsavel size={20} /> {nome}</>
  }

  return <><Responsavel size={20} /> {valor}</>
}