import React from 'react'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import { Formulario as FormularioDoFormik, Select } from '../../formik/formulario'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

import {
  FAZER_DOWNLOAD_DE_PACIENTES,
  LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES
} from '../../../acoes/tipos'

export default function Formulario(props) {
  const {
    celulas,
    download,
    enfermeiros,
    filtrosIniciais,
    medicos,
    operadoras,
    programas,
    limparFiltrosDoRelatorioDePacientes
  } = props

  const todasOperadoras = 'todas'
  const todosProgramas = 'todos'

  const limparFiltros = async resetForm => {
    await limparFiltrosDoRelatorioDePacientes()
    resetForm({})
  }

  return (
    <div className='formulario-de-filtros'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[FAZER_DOWNLOAD_DE_PACIENTES,
            LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
            LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
            LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
            LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
            LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES]}>
            {({ processando }) => (
              <div className={`box-agenda ${processando && 'is-loading'} relatorios`}>
                <FormularioDoFormik
                  acao={download}
                  valoresIniciais={{
                    celula: filtrosIniciais.celula || '',
                    enfermeiroDeReferencia: filtrosIniciais.enfermeiroDeReferencia || '',
                    medicoDeReferencia: filtrosIniciais.medicoDeReferencia || '',
                    operadora: filtrosIniciais.operadora ? filtrosIniciais.operadora : todasOperadoras,
                    programa: filtrosIniciais.programa ? filtrosIniciais.programa : todosProgramas,
                  }}
                >
                  {({ resetForm }) => (
                    <>
                      <fieldset>
                        <h2 className='form-title'>Pacientes</h2>
                        <r-grid columns-md={12} columns-lg={12}>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            <Select
                              nome='operadora'
                              tabIndex={1}
                              titulo='Operadora'
                              itens={operadoras}
                              campoCodigo='identificador'
                              campoDescricao='nome'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            <Select
                              nome='programa'
                              tabIndex={1}
                              titulo='Programa'
                              itens={programas}
                              campoCodigo='identificador'
                              campoDescricao='nome'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={2} span-lg={2}>
                            <Select
                              nome='celula'
                              tabIndex={2}
                              titulo='Célula'
                              itens={celulas}
                              campoCodigo='nome'
                              campoDescricao='nome'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={5} span-lg={5}>
                            <Select
                              nome='medicoDeReferencia'
                              itens={medicos}
                              tabIndex={3}
                              titulo='Médico(a) de Referência'
                              campoCodigo='nome'
                              campoDescricao='label'
                            />
                          </r-cell>
                          <r-cell span={4} span-md={5} span-lg={5}>
                            <Select
                              nome='enfermeiroDeReferencia'
                              itens={enfermeiros}
                              tabIndex={4}
                              titulo='Enfermeiro(a) de Referência'
                              campoCodigo='nome'
                              campoDescricao='label'
                            />
                          </r-cell>
                        </r-grid>
                      </fieldset>
                      <div className='list-btn'>
                        <button type='button' onClick={() => limparFiltros(resetForm)} className='button --light'>Limpar</button>
                        <Permissao permissoes={[permissoes.FAZER_DOWNLOAD_DE_PACIENTES]}>
                          <button type='submit' className='button --primary'>Gerar</button>
                        </Permissao>
                      </div>
                    </>
                  )}
                </FormularioDoFormik>
              </div>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}