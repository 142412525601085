import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTERAR_PLANO_DE_CUIDADO,
  ADICIONAR_PLANO_DE_CUIDADO,
  REMOVER_PLANO_DE_CUIDADO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    planoDeCuidado,
    salvar,
    recuperarPeloIdentificador,
    remover,
    voltar
  } = props

  const { identificador } = match.params

  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_PLANO_DE_CUIDADO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_PLANO_DE_CUIDADO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_PLANO_DE_CUIDADO])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  useEffect(() => {
    if (identificador && planoDeCuidado.nome === 'Jornada da Dor') {
      history.push(`/cadastros/planos-de-cuidado/jornada-da-dor/${identificador}`)
    }
  }, [identificador, planoDeCuidado.nome, history])

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o plano de cuidado ${planoDeCuidado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => remover(planoDeCuidado)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR, ALTERAR_PLANO_DE_CUIDADO, ADICIONAR_PLANO_DE_CUIDADO, REMOVER_PLANO_DE_CUIDADO]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          identificador: planoDeCuidado.identificador || '',
          nome: planoDeCuidado.nome || '',
          observacoes: planoDeCuidado.observacoes || ''
        }}
        acao={salvar}
        esquemaDeValidacoes={Yup.object().shape({
          nome: Yup.string().required('Obrigatório'),
          tamanhoDoNumeroDaCarteira: Yup.number()
            .typeError('Dever ser numérico')
            .positive('Deve ser positivo')
            .integer('Deve ser inteiro')
        })}
      >
        <fieldset>
          <h2 className='form-title'>{planoDeCuidado.identificador ? 'Alterar Plano de Cuidado' : 'Adicionar Plano de Cuidado'}</h2>
          <r-grid columns-md='12'>
            <r-cell span={6} span-md={12} span-lg={8}>
              <Input
                type='text'
                nome='nome'
                tabIndex={1}
                titulo='Nome *'
              />
            </r-cell>
            <r-cell span={6} span-md={12} span-lg={12}>
              <Input
                as='textarea'
                nome='observacoes'
                tabIndex={2}
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
        </fieldset>
        {identificador ?
          <div className='list-btn-unidades'>
            {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
            {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
            <button onClick={voltar} type='button' className='button --light'>Voltar</button>
          </div>
          :
          <div className='list-btn'>
            <button onClick={voltar} type='button' className='button --light'>Voltar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        }
      </FormularioDoFormik>
    </Spinner>
  )
}