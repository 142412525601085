import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS,
  LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS,
  ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS
} from '../tipos'

const listouSituacoesAssociadasAAlteracoesGastrointestinais = situacoesGastrointestinais => ({
  type: LISTOU_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS,
  situacoesGastrointestinais
})

const erroAoListarSituacoesAssociadasAAlteracoesGastrointestinais = erro => ({
  type: ERRO_AO_LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS,
  erro
})

export const listarSituacoesAssociadasAAlteracoesGastrointestinais = () => async dispatch => {
  dispatch({ type: LISTAR_SITUACOES_ASSOCIADAS_A_ALTERACOES_GASTROINTESTINAIS })

  try {
    const resultado = await api.listarSituacoesAssociadasAAlteracoesGastrointestinais()

    dispatch(listouSituacoesAssociadasAAlteracoesGastrointestinais(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarSituacoesAssociadasAAlteracoesGastrointestinais(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as situações associadas à alterações gastrointestinais.')))
  }
}
