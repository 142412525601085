import api from './api-major'

const listarProfissoes = (filtros = {}, pagina, ordenacao, academica, tamanhoDaPagina) => {
  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroTermo = filtros.termo ? `&nome=${filtros.termo}` : ''
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+nome'
  const parametroAcademica = filtros.academica !== undefined && filtros.academica.length === 1 ? `&academica=${filtros.academica[0] === 'Sim'}` : ''
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'
  
  return api.get(`/profissoes${paginacao}${tamanhoDaPaginaFormatado}${parametroAcademica}${parametroTermo}${parametroOrdem}`)
}

const listarProfissoesMultidisciplinares = () => api.get('/profissoes/multi-disciplinares?tamanhoDaPagina=100000&ordem=+nome&academicas=false&ativas=true')
const recuperarProfissaoPorIdentificador = identificador => api.get(`/profissoes/${identificador}`)
const recuperarProfissaoDaEnfermagem = () =>  api.get('/profissoes/enfermeiro')
const recuperarProfissaoMedica = () => api.get('/profissoes/medico')
const adicionarProfissao = profissao => api.post('/profissoes', profissao)
const alterarProfissao = profissao => api.put(`/profissoes/${profissao.identificador}`, profissao)
const removerProfissao = identificador => api.delete(`/profissoes/${identificador}`)
const recuperarEspecialidadePorIdentificador = (identificadorDaProfissao, identificador) => api.get(`/profissoes/${identificadorDaProfissao}/especialidades/${identificador}`)
const listarEspecialidades = identificadorDaProfissao => api.get(`/profissoes/${identificadorDaProfissao}/especialidades?tamanhoDaPagina=100000&ordem=+nome`)
const adicionarEspecialidade = (identificadorDaProfissao, especialidade) => api.post(`/profissoes/${identificadorDaProfissao}/especialidades`, especialidade)
const alterarEspecialidade = (identificadorDaProfissao, especialidade) => api.put(`/profissoes/${identificadorDaProfissao}/especialidades/${especialidade.identificador}`, especialidade)
const removerEspecialidade = (identificadorDaProfissao, especialidade) => api.delete(`/profissoes/${identificadorDaProfissao}/especialidades/${especialidade}`)

export default {
  adicionarEspecialidade,
  adicionarProfissao,
  alterarEspecialidade,
  alterarProfissao,
  listarEspecialidades,
  listarProfissoes,
  listarProfissoesMultidisciplinares,
  recuperarEspecialidadePorIdentificador,
  recuperarProfissaoPorIdentificador,
  recuperarProfissaoDaEnfermagem,
  recuperarProfissaoMedica,
  removerEspecialidade,
  removerProfissao,
}