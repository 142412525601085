import React, { useMemo } from 'react'
import { Formulario as FormularioDoFormik } from '../../formik/formulario'
import { useTarefas } from '../contexto'
import Responsavel from './responsavel'
import Status from './status'
import Paciente from './paciente'
import Finalizada from './finalizada'
import Categoria from './categoria'

export default function Filtros({ tipos = {}, filtrosAtuais = {}, fechar }) {
  const { alterarFiltrosDoTarefas, tipoDaTarefaAtual, abaAtual, semTipoSelecionado, termoDoFiltroDePacientes, pacientesDosFiltros } = useTarefas()

  const tipo = tipos[tipoDaTarefaAtual] || {}
  const tipoCompleto = tipo && tipos[tipo.identificador]
  const ultimaVersao = tipoCompleto && tipoCompleto.versoes[tipoCompleto.versoes.length - 1]
  let filtrosDisponiveis = ultimaVersao && ultimaVersao.configuracoes.filtros ? ultimaVersao.configuracoes.filtros.split(',') : []

  if (semTipoSelecionado) {
    filtrosDisponiveis = ['finalizada', 'paciente', 'responsavel']
  }

  const alterar = formulario => {
    alterarFiltrosDoTarefas(formulario, tipoDaTarefaAtual)
    fechar()
  }

  const fabricaDeFiltros = (tipo, valores, setFieldValue)  => {
    const existeFiltro = filtro => { return (tipo === filtro) }

    const alterarFiltroPaciente = valor => {
      if (valor) {
        setFieldValue('termo', termoDoFiltroDePacientes)
        setFieldValue('pacientes', pacientesDosFiltros)
      }

      setFieldValue('paciente', valor)
    }

    switch (true) {
      case existeFiltro('categoria'):
        return (
          <Categoria
            alterar={valor => setFieldValue('categoria', valor)}
            valor={valores.categoria}
          />
        )
      case existeFiltro('finalizada'):
        return (
          <Finalizada
            alterar={valor => setFieldValue('finalizada', valor)}
            valor={valores.finalizada}
          />
        )
      case existeFiltro('paciente'):
        return (
          <Paciente
            alterar={valor => alterarFiltroPaciente(valor)}
            valor={valores.paciente}
          />
        )
      case existeFiltro('responsavel'):
        return (
          <Responsavel
            alterar={valor => setFieldValue('responsavel', valor)}
            valor={valores.responsavel}
            filtroPaciente={valores.paciente}
          />
        )
      case existeFiltro('status'):
        return (
          <Status
            alterar={valor => setFieldValue('status', valor)}
            status={tipos ? tipos[tipoDaTarefaAtual] : []}
            valor={valores.status}
          />
        )
      default:
        break
    }
  }

  const valoresIniciais = useMemo(() => recuperarValoresIniciais(filtrosDisponiveis, filtrosAtuais), [filtrosDisponiveis, filtrosAtuais])

  const limparFiltros = (resetForm, valores) => {
    if (abaAtual === 'minhas') {
      resetForm({ values: { responsavel: valores.responsavel, status: [] } })
    } else {
      resetForm({ values: { status: [] } })
    }
  }

  return (
    <FormularioDoFormik
      valoresIniciais={valoresIniciais}
      acao={alterar}
    >
      {({ values, setFieldValue, resetForm }) => (
          <fieldset>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={3} span-md={5} span-lg={11}>
              <h2 className='form-title'>Adicionar filtros</h2>
            </r-cell>
            <r-cell span={1} span-md={1} span-lg={1}>
              <button type='button' className='text-right text-dark w-100' onClick={fechar}>
                <i className='icon icon-close'></i>
              </button>
            </r-cell>
          </r-grid>
          <r-grid columns-md={6} columns-lg={12} class='filtro-de-tarefas'>
            {filtrosDisponiveis.map((filtro, index) =>
              <r-cell span={4} span-md={6} span-lg={12} key={index}>
                {fabricaDeFiltros(filtro, values, setFieldValue)}
              </r-cell>
            )}
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => limparFiltros(resetForm, values)}>Limpar</button>
            <button type='submit' className='button --primary'>Filtrar</button>
          </div>
        </fieldset>
      )}
    </FormularioDoFormik>
  )
}

function recuperarValoresIniciais(filtros, filtrosAplicados) {
  if (filtros.length === 0 || !filtrosAplicados) return {}

  let valores = {}
  valores.limite = filtrosAplicados.limite

  filtros.forEach(tipo  => {
    const existeFiltro = filtro => { return tipo.includes(filtro) }

    switch (true) {
      case existeFiltro('categoria'):
        valores.categoria = filtrosAplicados.categoria || ''
        break
      case existeFiltro('finalizada'):
        valores.finalizada = filtrosAplicados.finalizada
        break
      case existeFiltro('paciente'):
        valores.paciente = filtrosAplicados.paciente || ''
        valores.pacientes = filtrosAplicados.pacientes || []
        valores.termo = filtrosAplicados.termo
        break
      case existeFiltro('responsavel'):
        valores.responsavel = filtrosAplicados.responsavel || ''
        break
      case existeFiltro('status'):
        valores.status = filtrosAplicados.status || []
        break
      default:
        break;
    }
  })

  return valores
}