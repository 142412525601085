import React, {useEffect} from 'react'
import Spinner from '../spinner'
import {RECUPERAR_AVALIACAO} from '../../acoes/tipos'

export default function Agradecimento(props) {
  const {
    avaliacao,
    match,
    recuperarAvaliacao,
    logotipoDaEmpresa
  } = props

  const { identificadorDaEmpresa, identificadorDaAvaliacao } = match.params

  useEffect(() => {
    recuperarAvaliacao(identificadorDaEmpresa, identificadorDaAvaliacao)
  }, [identificadorDaEmpresa, identificadorDaAvaliacao, recuperarAvaliacao])
  
  const paciente = avaliacao ? avaliacao.atendimento.paciente.split(' ')[0] : ''

  return (
    <Spinner operacoes={[RECUPERAR_AVALIACAO]}>
      {({ processando }) => (
        <>
          {processando ?
            <div className='container-nps'>
              <div className='logo'><img src={logotipoDaEmpresa} alt='Logotipo da empresa' /></div>
              <h2>Carregando...</h2>
            </div>
            :
            <div className='container-nps'>
              <div className='logo'><img src={logotipoDaEmpresa} alt='Logotipo da empresa' /></div>
              <h2>{`${paciente}, obrigado por nos avaliar!`}</h2>
            </div>
          }
        </>
      )}
    </Spinner>
  )
}