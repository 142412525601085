import { LISTOU_TRATAMENTOS } from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TRATAMENTOS: {
      return action.tratamentos
    }

    default: {
      return state
    }
  }
}