import React from 'react'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import { Refer } from '../../../design/icones'

export default function Encaminhamento({ valor, rotulo }) {
  const icone = <Refer />

  if (valor.length === 0) {
    return (
      <div className='card-componente form-group'>
        <div className='icon'>{icone}</div>
        <div className='componente-select-acao'>
          <label className='titulo'>{rotulo}</label>
        </div>
        <div className='unity-infos agendamentos'>
          <div className='unity-infos__item agendamentos'>
            <p><i className='icon icon-attention-circled' style={{ position: 'inherit' }}></i>O paciente ainda não possui encaminhamentos.</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='card-componente form-group'>
      <div className='icon'>{icone}</div>
      <div className='componente-select-acao'>
        <label className='titulo'>{rotulo}</label>
      </div>
      {valor && valor.map(x => (
        <div key={x.identificador} className='unity-infos agendamentos'>
          <div className='unity-infos__item agendamentos'>
            <p><strong>Data:</strong>{' '}{formatarDataParaFormatoLocal(x.quando)}</p>
            <p><strong>Encaminhado por:</strong>{' '}{x.encaminhadoPor.nome}{', '}{x.encaminhadoPor.profissao}</p>
            <p><strong>Encaminhado para:</strong>{' '}{x.encaminhadoPara}</p>
          </div>
        </div>
      ))}
    </div>
  )
}