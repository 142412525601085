import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from "./formulario";

export default function EspecialidadesMedicasInternas(props) {
  const {
      especialidades,
      match,
      especialidadesMedicasInternas,
      listarEspecialidadesMedicasInternas,
      recuperarProfissaoMedica,
      adicionarEspecialidadeMedicaInterna,
      removerEspecialidadeMedicaInterna,
  } = props

    useEffect(() => { listarEspecialidadesMedicasInternas() }, [listarEspecialidadesMedicasInternas])
    useEffect(() => { recuperarProfissaoMedica() }, [recuperarProfissaoMedica])

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_USUARIOS_BLOQUEADOS, permissoes.LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN]}>
              <Formulario
                {...props}
                especialidadesMedicasInternas={especialidadesMedicasInternas}
                especialidades={especialidades}
                adicionar={adicionarEspecialidadeMedicaInterna}
                remover={removerEspecialidadeMedicaInterna}
                listar={listarEspecialidadesMedicasInternas}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}