import React, { useState } from 'react'
import moment from 'moment'
import { formatarDataParaFormatoDiaDaSemana } from '../../../bibliotecas/data'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'

export default function HorariosLivres(props) {
  const {
    dataBaseHorariosLivres,
    horariosLivres,
    listarHorariosLivres,
    fechar,
    selecionarHorario,
    setFieldValue,
    tipoDeAgendamento,
    unidadeDaEmpresaSelecionada,
    unidades,
  } = props

  const [processando, setProcessando] = useState(false)
  const { tempo } = tipoDeAgendamento
  const exibirMensagem = !horariosLivres || horariosLivres.length === 0
  const horariosLivresDoProfissional = converterObjetoParaArray(horariosLivres)

  const listarHorariosLivresDoProfissional = async (evento, data, tipoDeAgendamento, unidadeDaEmpresaSelecionada) => {
    evento.preventDefault()
    setProcessando(true)
    await listarHorariosLivres(data, tipoDeAgendamento, unidadeDaEmpresaSelecionada)
    setProcessando(false)    
  }

  const renderizarDia = dia => {
    const hojeDiaDaSemana = formatarDataParaFormatoDiaDaSemana(dia.data)
    const dataFormatada = moment(dia.data).format('LL')
    const unidades = converterObjetoParaArray(dia.unidades)

    return (
      <div className="box-hours-free__item" key={dataFormatada}>
        <h4>{hojeDiaDaSemana}, {dataFormatada}</h4>
        {unidades.map(x => renderizarUnidade(x, dataFormatada))}
        <div className="box-hours-free__line"></div>
      </div>
    )
  }

  const renderizarUnidade = (unidade, dataFormatada) => {
    const { horarios, identificador, nome } = unidade
    
    return (
      <div key={identificador+dataFormatada}>
        <h5>{nome}</h5>
        <div className="list-buttom-hours">
          {horarios.map((x, index) => renderizarHorario(x, index, unidade.identificador))}
        </div>
      </div>
    )
  }

  const renderizarHorario = (horario, index, unidade) => {
    return (
      <RenderizarHorario
        key={index}
        horario={horario}
        selecionarHorario={selecionarHorario}
        setFieldValue={setFieldValue}
        unidadeDaEmpresa={unidade}
      />
    )
  }

  return (
    <>
      <div className="box-hours-free__lightbox custom-scrollbar">
        <FiltrosAplicados
          unidades={unidadeDaEmpresaSelecionada ? unidades.filter(x => x.identificador === unidadeDaEmpresaSelecionada) : unidades}
          dataBaseHorariosLivres={dataBaseHorariosLivres}
          duracao={tempo.substring(0, 5)}
        />
        {exibirMensagem &&
          <div className="box-hours-free__item" onClick={fechar}>
            <h4>Não foram encontrados horários livres.</h4>
          </div>
        }
        {!exibirMensagem && horariosLivresDoProfissional.map(x => renderizarDia(x))}

        <div className='proximo-mes'>
          <button type='button' className={`button --primary flex ${processando && 'is-loading'}`}
            onClick={(evento) => {
              const data = dataBaseHorariosLivres ? moment(dataBaseHorariosLivres).add(1, 'M').startOf('month') : moment()
              listarHorariosLivresDoProfissional(evento, data, tipoDeAgendamento.identificador, unidadeDaEmpresaSelecionada)
            }}
          >
            Carregar próximo mês
          </button>
        </div>
      </div>
      <div className="box-agenda__drop__arrow arrow-hours --bottom"></div>
    </>
  )
}

const FiltrosAplicados = ({ unidades = [], dataBaseHorariosLivres, duracao }) => {
  if (unidades.length === 0) {
    return null
  }

  const data = dataBaseHorariosLivres ? moment(dataBaseHorariosLivres) : moment()

  return (
    <>
      <h3>Horários Livres</h3>
      <div className="box-hours-free__header">
        <p><strong>Unidade: </strong><span>{unidades.map(unidade => unidade.nome).join(', ')}</span></p>
        <p><strong>Período: </strong>{moment().format('DD/MM/YYYY')} até {data.endOf('month').format('DD/MM/YYYY')}.</p>
        <p><strong>Duração: </strong>{duracao} min</p>
        <div className="box-hours-free__line"></div>
      </div>
    </>
  )
}

const RenderizarHorario = ({ horario, unidadeDaEmpresa, selecionarHorario, setFieldValue }) => {
  const exibirInstrucoes = horario.instrucoes && horario.instrucoes.length > 0
  const [exibirToltip, setExibirTolTip] = useState(false)

  const renderizarInstrucoes = (instrucoes, exibirToltip) => {
    if (!exibirToltip) return null

    return (
      <div className="box-toltip active">
        <div className="box-toltip__lightbox custom-scrollbar">
          <h3>Observação</h3>
          <div className="box-toltip__content">
            {instrucoes.map((instrucao, index) =>
              <p key={index}>{instrucao}</p>)
            }
          </div>
        </div>
        <div className="box-agenda__drop__arrow --bottom --toltip"></div>
      </div>
    )
  }

  return (
    <div className="list-buttom-toltip">
      {exibirInstrucoes && renderizarInstrucoes(horario.instrucoes, exibirToltip)}
      <button className={`${exibirInstrucoes ? 'purple' : 'green'}`} key={horario.inicio}
        onClick={() => selecionarHorario(horario, unidadeDaEmpresa, setFieldValue)}
        onMouseOver={() => setExibirTolTip(true)} onMouseOut={() => setExibirTolTip(false)}>
        {moment(horario.inicio).format('HH:mm')}
      </button>
    </div>
  )
}