import salas from './salas'

import {
  ATIVOU_UNIDADE_DA_EMPRESA,
  DESATIVOU_UNIDADE_DA_EMPRESA,
  LISTOU_UNIDADES_DA_EMPRESA,
  REMOVEU_UNIDADE_DA_EMPRESA,
  SELECIONAR_UNIDADE,
} from '../../acoes/tipos'

const estadoInicial = {
  itens: [],
  itemAtual: {
    salas: [],
  }
}

const unidades = (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_UNIDADES_DA_EMPRESA: {
      return {
        ...state,
        itens: action.unidades,
      }

    }

    case REMOVEU_UNIDADE_DA_EMPRESA: {
      const unidades = state.itens

      return {
        ...state,
        itens: unidades.filter(x => x.identificador !== action.identificador)
      }
    }

    case SELECIONAR_UNIDADE: {
      if (state.itemAtual && state.itemAtual.identificador === parseInt(action.identificador)) {
        return state
      }

      const unidades = state.itens
      const selecionada = unidades.find(x => x.identificador === parseInt(action.identificador))

      return {
        ...state,
        itemAtual: {
          ...state.itemAtual,
          ...selecionada,
        }
      }
    }

    case DESATIVOU_UNIDADE_DA_EMPRESA:
    case ATIVOU_UNIDADE_DA_EMPRESA: {
      const unidades = state.itens
      const unidadeSelecionada = unidades.findIndex(x => x.identificador === action.identificador)

      unidades.forEach(() => [
        ...unidades,
        unidades[unidadeSelecionada] = {
          ...unidades[unidadeSelecionada],
          ativa: action.ativa
        }
      ])

      return {
        itens: unidades,
      }
    }

    default: {
      return state
    }
  }
}

export default (state = estadoInicial, action) => {
  const dados = unidades(state, action)

  return {
    ...dados,
    itemAtual: {
      ...dados.itemAtual,
      salas: salas(state.itemAtual.salas, action),
    }
  }
}