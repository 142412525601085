import { LISTOU_PERFIS, LISTOU_PERFIS_DO_USUARIO } from '../../acoes/tipos'

const estadoInicial = {
  perfis: {},
  paginaDeDados: {},
  habilitados: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PERFIS: {
      return {
        ...state,
        perfis: action.perfis,
      }
    }

    case LISTOU_PERFIS_DO_USUARIO: {
      return {
        ...state,
        paginaDeDados: action.perfisDoUsuario,
        habilitados: action.perfisDoUsuario.dados.map(x => x.identificador),
        erro: null,
      }
    }

    default: {
      return state
    }
  }
}