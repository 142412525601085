import api from './api-major'

const listarEspecialidadesMedicasInternas = () => api.get('/configuracoes/encaminhamentos/especialidades-medicas-internas')
const adicionarEspecialidadeMedicaInterna = especialidade => api.post('/configuracoes/encaminhamentos/especialidades-medicas-internas', especialidade)
const removerEspecialidadeMedicaInterna = especialidade => api.delete(`/configuracoes/encaminhamentos/especialidades-medicas-internas/${especialidade}`)

export default {
    adicionarEspecialidadeMedicaInterna,
    listarEspecialidadesMedicasInternas,
    removerEspecialidadeMedicaInterna
}