import { connect } from 'react-redux'
import acoes from '../../acoes'
import MotivosDeIdaAoProntoAtendimento from './index'

const mapStateToProps = state => {
  const motivoAtual = state.motivosDeIdaAoProntoAtendimento.itemAtual

  let motivo = {
    nome: '',
    status: true,
  }

  if (motivoAtual) {
    motivo.identificador = motivoAtual.identificador
    motivo.status = motivoAtual.status
    motivo.nome = motivoAtual.nome
  }

  return {
    filtros: state.motivosDeIdaAoProntoAtendimento.filtros,
    ordenacao: state.motivosDeIdaAoProntoAtendimento.ordenacao,
    paginaDeDados: state.motivosDeIdaAoProntoAtendimento.paginaDeDados,
    motivo,
  }
}

export default connect(mapStateToProps, acoes)(MotivosDeIdaAoProntoAtendimento)