import api from '../api-major'

const autenticarMedicoNaMemed = () => {
  return api.get(`/memed/usuario`)
}

const recuperarPrescricaoPdf = (token, identificador) => {
  if (token) {
    return api.get(`/memed/prescricoes/${identificador}/link-do-pdf?token=${token}`)
  } else {
    return api.get(`/memed/prescricoes/${identificador}/link-do-pdf`)
  }
}

const removerPrescricaoNaMemed = identificador => {
  return api.delete(`/memed/prescricoes/${identificador}`)
}

const recuperarHistoricoPrescricaoNaMemed = identificador => {
  return api.get(`/memed/prescricoes?atendimento=${identificador}`)
}

export default {
  autenticarMedicoNaMemed,
  recuperarHistoricoPrescricaoNaMemed,
  recuperarPrescricaoPdf,
  removerPrescricaoNaMemed,
}