import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../formik/formulario'
import * as Yup from 'yup'

import {
  RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ALTERAR_GRUPO_DE_CUIDADO,
  ADICIONAR_GRUPO_DE_CUIDADO,
  REMOVER_GRUPO_DE_CUIDADO,
  ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    alterarStatusDoGrupoDeCuidado,
    match,
    history,
    grupoDeCuidado,
    salvar,
    recuperarPeloIdentificador,
    remover
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_GRUPO_DE_CUIDADO])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_GRUPO_DE_CUIDADO])
  const exibirExcluir = usePossuiAsPermissoes([permissoes.REMOVER_GRUPO_DE_CUIDADO])
  const exibirAtivar = usePossuiAsPermissoes([permissoes.ALTERAR_GRUPO_DE_CUIDADO]) && !grupoDeCuidado.ativo && identificador
  const exibirDesativar = usePossuiAsPermissoes([permissoes.ALTERAR_GRUPO_DE_CUIDADO]) && grupoDeCuidado.ativo && identificador

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const removerSelecionado = async item => {
    const removeu = await remover(item.identificador)

    if (removeu) {
      history.push(`/cadastros/grupos-de-cuidado`)
    }
  }

  const confirmarEAtivar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja ativar o grupo de cuidado ${grupoDeCuidado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => alterarStatusDoGrupoDeCuidado(true)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarEDesativar = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja desativar o grupo de cuidado ${grupoDeCuidado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => alterarStatusDoGrupoDeCuidado(false)
      }, {
        label: 'Não'
      }]
    })
  }

  const confirmarERemover = () => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o grupo de cuidado ${grupoDeCuidado.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(grupoDeCuidado)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[
          RECUPERAR_GRUPO_DE_CUIDADO_PELO_IDENTIFICADOR,
          ALTERAR_GRUPO_DE_CUIDADO,
          ADICIONAR_GRUPO_DE_CUIDADO,
          REMOVER_GRUPO_DE_CUIDADO,
          ALTERAR_STATUS_DO_GRUPO_DE_CUIDADO
        ]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: grupoDeCuidado.identificador || '',
              ativo: grupoDeCuidado.ativo || '',
              nome: grupoDeCuidado.nome || '',
              observacoes: grupoDeCuidado.observacoes || ''
            }}
            acao={salvar}
            esquemaDeValidacoes={Yup.object().shape({
              nome: Yup.string().required('Obrigatório')
            })}
          >
            <fieldset>
              <h2 className='form-title'>{grupoDeCuidado.identificador ? 'Alterar Grupo de Cuidado' : 'Adicionar Grupo de Cuidado'}</h2>
              <r-grid columns-md='12'>
                <r-cell span={6} span-md={12} span-lg={8}>
                  <Input
                    type='text'
                    nome='nome'
                    tabIndex={1}
                    titulo='Nome *'
                  />
                </r-cell>
                {identificador &&
                  <r-cell span={4} span-md={12} span-lg={12}>
                    <Toglle
                      nome='ativo'
                      tabIndex={2}
                      titulo='Ativo'
                      disabled={true}
                    />
                  </r-cell>
                }
                <r-cell span={6} span-md={12} span-lg={12}>
                  <Input
                    as='textarea'
                    nome='observacoes'
                    tabIndex={2}
                    titulo='Observações'
                  />
                </r-cell>
              </r-grid>
            </fieldset>
            {remover ?
              <div className='list-btn-unidades'>
                {exibirExcluir && <button onClick={confirmarERemover} type='button' className='button --danger'>Excluir</button>}
                {exibirDesativar && <button onClick={confirmarEDesativar} type='button' className='button --danger-light'>Desativar</button>}
                {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                {exibirAtivar && <button onClick={confirmarEAtivar} type='button' className='button --light'>Ativar</button>}
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
              </div>
              :
              <div className='list-btn'>
                <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
              </div>
            }
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}