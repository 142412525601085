import React, {useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Lista from './lista'
import queryString from 'query-string'

export default function ConfirmarAgendamento(props) {
  const {
    agendamento,
    confirmarAgendamento,
    cancelarAgendamento,
    excluirAgendamento,
    filtros,
    fotos,
    identificadorDoUsuarioLogado,
    listarAgendamentosParaConfirmacaoDeAgendamento,
    listarProfissionaisDeSaudeDaAgenda,
    listarMotivosDeCancelamentoDosAgendamentos,
    location,
    match,
    ordenacao,
    paginaDeDados,
    profissional,
    profissionais,
    motivosDeCancelamento,
    recuperarFotoDoPacientePorIdentificador,
    recuperarAgendamentoPeloIdentificador,
    removerImagemDaMemoriaPorIdentificador,
    selecionarAgendamentoParaAlteracao,
    selecionarProfissionalDaAgenda,
    unidades
  } = props

  useEffect(() => {
    paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      recuperarFotoDoPacientePorIdentificador(item.paciente.identificador, item.paciente.foto)
    })

    return () => paginaDeDados.dados && paginaDeDados.dados.filter(x => x.paciente.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.paciente.foto)
    })
  }, [paginaDeDados.dados, recuperarFotoDoPacientePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    listarProfissionaisDeSaudeDaAgenda()
    listarMotivosDeCancelamentoDosAgendamentos()
  }, [listarProfissionaisDeSaudeDaAgenda, listarMotivosDeCancelamentoDosAgendamentos])

  const dadosDaQueryString = location.search ? queryString.parse(location.search) : undefined

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE]}>
              <Lista
                {...props}
                agendamento={agendamento}
                confirmarAgendamento={confirmarAgendamento}
                cancelarAgendamento={cancelarAgendamento}
                dadosDaQueryString={dadosDaQueryString}
                excluirAgendamento={excluirAgendamento}
                filtros={filtros}
                fotos={fotos}
                identificadorDoUsuarioLogado={identificadorDoUsuarioLogado}
                ordenacao={ordenacao}
                paginaDeDados={paginaDeDados}
                pesquisar={listarAgendamentosParaConfirmacaoDeAgendamento}
                profissional={profissional}
                profissionais={profissionais}
                motivosDeCancelamento={motivosDeCancelamento}
                recuperarAgendamentoPeloIdentificador={recuperarAgendamentoPeloIdentificador}
                selecionarAgendamentoParaAlteracao={selecionarAgendamentoParaAlteracao}
                selecionarProfissionalDaAgenda={selecionarProfissionalDaAgenda}
                unidades={unidades}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}`} />
    </Switch>
  )
}