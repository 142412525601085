import { useUsuarioLogado } from './provedor-de-autenticacao'

export const usePossuiAsPermissoes = permissoes => {
  const { usuario } = useUsuarioLogado()

  if (!usuario || !permissoes || permissoes.length === 0) return false

  return permissoes.every(x => usuario.permissoes.includes(x))
}

export const usePossuiAlgumaPermissao = permissoes => {
  const { usuario } = useUsuarioLogado()

  if (!usuario || !permissoes || permissoes.length === 0) return false

  return permissoes.some(x => usuario.permissoes.includes(x))
}

export const usePermissaoQueUsuarioPossui = permissoes => {
  const { usuario } = useUsuarioLogado()

  if (!usuario || !permissoes || permissoes.length === 0) return null

  const permissao = permissoes.find(permissao => usuario.permissoes.includes(permissao))

  return permissao || null
}

export default function Permissao({ permissoes, children }) {
  const possui = usePossuiAsPermissoes(permissoes)

  if (!possui) return null

  return children
}