import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import RotaComAutenticacao from '../../seguranca/rota-com-autenticacao'
import Breadcrumb from '../../breadcrumb'
import { Aba, Abas, ListaDeAbas, Paineis, Painel } from '../../abas'
import TiposDeAtendimento from './tipos-de-atendimento'
import Profissoes from './profissoes'
import Respostas from './respostas'
import Avaliacao from './avaliacao'
import Permissao from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'

export default function Habilitacoes(props) {
  const {
    alterarStatusDeUmaResposta,
    habilitarProfissoesParaONps,
    habilitarTiposDeAtendimentoParaONps,
    desabilitarProfissaoParaONps,
    desabilitarTipoDeAtendimentoParaONps,
    history,
    listarAvaliacoesRespondidas,
    listarPacientesDoNps,
    listarProfissionaisDoNps,
    listarProfissoesParaONps,
    listarTiposDeAtendimentoParaONps,
    location,
    match,
    pacientes,
    profissionais,
    profissoes,
    recuperarAvaliacaoRespondida,
    respostas,
    tiposDeAtendimento
  } = props

  const abaAtiva = (props) => {
    const abas = {
      '/tipos-de-atendimento': 0,
      '/profissoes': 1,
      '/respostas': 2
    }

    const url = location.pathname.replace(match.url, '').replace(props.match.params.identificador ? '/' + props.match.params.identificador : '', '')
    return abas[url] || 0
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={[
          `${match.path}/tipos-de-atendimento`,
          `${match.path}/profissoes`,
          `${match.path}/respostas`,
          `${match.path}/respostas/:identificador`,
        ]}
        render={props => (
          <Permissao permissoes={[permissoes.CONFIGURAR_NPS]}>
            <div className='container'>
              <div className='main-content-form'>
                <div className='header-page-form'>
                  <Breadcrumb />
                </div>
                <Abas abaInicial={abaAtiva(props)}>
                  <div className='header-page-bar mt-0'>
                    <div className='header-page-nav'>
                      <ListaDeAbas className='tab'>
                        <Aba className='tab-item disabled' aoClicar={() => history.push(`${match.url}/tipos-de-atendimento`)}>
                          Tipos de Atendimento
                        </Aba>
                        <Aba className='tab-item disabled' aoClicar={() => history.push(`${match.url}/profissoes`)}>
                          Profissões
                        </Aba>
                        <Aba className='tab-item' aoClicar={() => history.push(`${match.url}/respostas`)}>
                          Respostas
                        </Aba>
                      </ListaDeAbas>
                    </div>
                  </div>
                  <Paineis>
                    <Painel>
                      <RotaComAutenticacao
                        exact
                        path={`${match.path}/tipos-de-atendimento`}
                        render={
                          props => (
                            <TiposDeAtendimento
                              {...props}
                              habilitar={habilitarTiposDeAtendimentoParaONps}
                              desabilitar={desabilitarTipoDeAtendimentoParaONps}
                              listarTiposDeAtendimento={listarTiposDeAtendimentoParaONps}
                              tiposDeAtendimento={tiposDeAtendimento}
                            />
                          )
                        }
                      />
                    </Painel>
                    <Painel>
                      <RotaComAutenticacao
                        exact
                        path={`${match.path}/profissoes`}
                        render={
                          props => (
                            <Profissoes
                              {...props}
                              habilitar={habilitarProfissoesParaONps}
                              desabilitar={desabilitarProfissaoParaONps}
                              listarProfissoes={listarProfissoesParaONps}
                              profissoes={profissoes}
                            />
                          )
                        }
                      />
                    </Painel>
                    <Painel>
                      <Switch>
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/respostas`}
                          render={
                            props => (
                              <Respostas
                                {...props}
                                listarPacientes={listarPacientesDoNps}
                                listarProfissionais={listarProfissionaisDoNps}
                                listarRespostas={listarAvaliacoesRespondidas}
                                pacientes={pacientes}
                                profissionais={profissionais}
                                respostas={respostas}
                              />
                            )
                          }
                        />
                        <RotaComAutenticacao
                          exact
                          path={`${match.path}/respostas/:identificador`}
                          render={
                            props => (
                              <Avaliacao
                                {...props}
                                alterar={alterarStatusDeUmaResposta}
                                avaliacao={respostas.itemAtual}
                                recuperar={recuperarAvaliacaoRespondida}
                              />
                            )
                          }
                        />
                      </Switch>
                    </Painel>
                  </Paineis>
                </Abas>
              </div>
            </div>
          </Permissao>
        )}
      />
      <Redirect exact from={match.path} to={`${match.path}/respostas`} />
    </Switch>
  )
}