import React from 'react'
import moment from 'moment'
import Spinner from '../spinner'

import { EXCLUIR_FERIADO, EXCLUIR_SERIE_DE_FERIADOS } from '../../acoes/tipos'

export default function ConfirmacaoDeExclusao({ feriado, fechar, excluir, excluirSerie }) {
  return (
    <Spinner operacoes={[
      EXCLUIR_FERIADO,
      EXCLUIR_SERIE_DE_FERIADOS
    ]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Confirmação de Exclusão de Feriado`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <div className='form-choice-alerta__item'>
                    <ul>
                      {`${feriado.nome} do dia ${moment(feriado.data).format('DD/MM/YYYY')}`}.
                    </ul>
                  </div>

                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Cancelar</button>
                  <button className='button --danger' type='button' onClick={() => excluir(feriado.identificador)}>Excluir o Feriado</button>
                  <button className='button --danger-light' type='button' onClick={() => excluirSerie(feriado.identificador)}>Excluir a Série de Feriados</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}