import api from '../../api-major'

const listarInstrucoesDeAgendamentosDoProfissionalDeSaude = (identificadorDoProfissional, filtros = {}, pagina) => {
  const paginacao = pagina ? `pagina=${pagina}` : 'pagina=1'
  const parametroTamanhoDaPagina = `&tamanhoDaPagina=50`
  const parametroOrdem = filtros && filtros.ordem ? `&ordem=${filtros.ordem}` : '&ordem=data'
  const parametroInicio = filtros && filtros.inicio ? `&inicio=${filtros.inicio}` : ''
  const parametroFim = filtros && filtros.fim ? `&fim=${filtros.fim}` : ''
  const parametroTexto = filtros && filtros.texto ? `&texto=${filtros.texto}` : ''

  return api.get(`/profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes?${paginacao}${parametroOrdem}${parametroTamanhoDaPagina}${parametroInicio}${parametroFim}${parametroTexto}`)
}

const adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, instrucao) => {
  return api.post(`profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes`, instrucao)
}

const alterarInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, instrucao) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes/${instrucao.identificador}`, instrucao)
}

const excluirInstrucaoDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, identificador) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes/${identificador}`)
}

const excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, identificadorDaInstrucao) => {
  return api.delete(`profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes/${identificadorDaInstrucao}/serie`)
}

const alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude = (identificadorDoProfissional, serie) => {
  return api.put(`profissionais-de-saude/${identificadorDoProfissional}/agenda/instrucoes/${serie.identificador}/serie`, serie)
}


export default {
  adicionarInstrucaoDeAgendamentoDoProfissionalDeSaude,
  alterarInstrucaoDeAgendamentoDoProfissionalDeSaude,
  alterarSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude,
  excluirInstrucaoDeAgendamentoDoProfissionalDeSaude,  
  excluirSerieDeInstrucoesDeAgendamentoDoProfissionalDeSaude,
  listarInstrucoesDeAgendamentosDoProfissionalDeSaude,
}   