import { connect } from 'react-redux'
import acoes from '../../acoes'
import Orientacoes from './index'

const mapStateToProps = state => {
  const orientacaoAtual = state.orientacoes.itemAtual

  let orientacao = {
    nome: '',
    arquivos: [],
    observacoes: '',
  }

  if (orientacaoAtual) {
    orientacao.identificador = orientacaoAtual.identificador
    orientacao.nome = orientacaoAtual.nome
    orientacao.arquivos = orientacaoAtual.arquivos
    orientacao.observacoes = orientacaoAtual.observacoes
  }

  return {
    filtros: state.orientacoes.filtros,
    ordenacao: state.orientacoes.ordenacao,
    paginaDeDados: state.orientacoes.paginaDeDados,
    orientacao,
  }
}

export default connect(mapStateToProps, acoes)(Orientacoes)