import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  DEFINIU_NUMERO_INTEIRO_DA_TAREFA,
  ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA
} from '../tipos'

const definiuNumeroInteiroDaTarefa = (identificadorDaTarefa, campo, conteudo) => ({
  identificadorDaTarefa,
  campo,
  conteudo,
  type: DEFINIU_NUMERO_INTEIRO_DA_TAREFA,
})

const erroAoDefinirNumeroInteiroDaTarefa = (erro, parametros) => ({
  type: ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  erro,
  parametros,
})

export const definirNumeroInteiroDaTarefa = (identificadorDaTarefa, campo, conteudo) => async dispatch => {
  dispatch({ type: DEFINIR_NUMERO_INTEIRO_DA_TAREFA })

  try {
    const resultado = await api.definirNumeroInteiroDaTarefa(identificadorDaTarefa, campo, conteudo)

    dispatch(definiuNumeroInteiroDaTarefa(identificadorDaTarefa, campo, conteudo))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoDefinirNumeroInteiroDaTarefa(erro, { identificadorDaTarefa, campo, conteudo }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao definir o número inteiro da tarefa.')))

    return false
  }
}