import {
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
} from '../../../acoes/tipos'

const estadoInicial = {
  habilitados: [],
  filtros: {
    ativo: ['Sim', 'Não']
  },
  ordenacao: { nome: 'nome', ordem: '+' },
  paginaDeDados: {},
  tiposDeAgendamento: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA: {
      const { ordenacao } = action

      return {
        ...state,
        filtros: action.filtros,
        ordenacao,
        paginaDeDados: action.paginaDeDados,
        habilitados: action.paginaDeDados.dados.map(x => x.identificador),
      }
    }

    case LISTOU_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA: {
      let tiposDeAgendamento = {}

      action.paginaDeDados.forEach((tipo, index) => {
        tiposDeAgendamento[tipo.identificador] = {
          ...tipo,
          _ordem: index,
        }
      })

      return {
        ...state,
        tiposDeAgendamento,
      }
    }

    default: {
      return state
    }
  }
}