import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import {
  LISTAR_RESPOSTAS_DO_CASP16,
  LISTOU_RESPOSTAS_DO_CASP16,
  ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16,
} from '../tipos'

const listouRespostasDoCasp16 = respostasDoCasp16 => ({
  type: LISTOU_RESPOSTAS_DO_CASP16,
  respostasDoCasp16,
})

const erroAoListarRespostasDoCasp16 = erro => ({
  type: ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16,
  erro,
})

export const listarRespostasDoCasp16 = () => {
  const acao = async dispatch => {
    dispatch({ type: LISTAR_RESPOSTAS_DO_CASP16 })

    try {
      const resultado = await api.listarRespostasDoCasp16()

      dispatch(listouRespostasDoCasp16(resultado.data.dados.dados))
      dispatch(adicionarNotificacoesDaApi(resultado))
    } catch (erro) {
      dispatch(erroAoListarRespostasDoCasp16(erro))
      dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as respostas do Casp16.')))
    }
  }

  acao.meta = {
    cache: LISTAR_RESPOSTAS_DO_CASP16
  }

  return acao
}